import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBookingManagerApi, IBookingSearchResponse } from 'services/BookingManagerApi';
import {
  GET_BOOKING_REQUEST,
  getBookingSuccessAction,
  getBookingFailureAction,
  GetBookingRequestAction,
} from '../actions';

export function* getBookingSaga(action: GetBookingRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const result: AxiosResponse<IBookingSearchResponse> = yield call(
      bookingManagerApi.bookingSearch,
      {
        filter: { bookingUuid: action.uuid },
        pagination: { limit: 1, offset: 0 }
      }
    );
    
    const booking = result.data.bookings[0];
    if(!booking) {
      throw new Error('Booking not found');
    }
    yield put(getBookingSuccessAction(booking));
  } catch (e) {
    console.error(e);
    yield put(getBookingFailureAction(e));
  }
}

export function* watchGetBookingManager() {
  yield takeLatest([GET_BOOKING_REQUEST], getBookingSaga);
}
