import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { uniq } from 'lodash-es';
import { makeBackendApi, IHotel, IHotelResponse } from 'services/BackendApi';
import {
  getProposalDetailsFailureAction,
  getProposalDetailsSuccessAction,
  GET_PROPOSAL_DETAILS_REQUEST,
  setProposalDownloadHistoryAction,
  setProposalBookingsAction,
  setProposalDetailsSortByAction,
} from '../actions';
import { proposalDetailsUuidSelector } from '../selectors';
import { IGetHotelDetailsResponse, IHeadlineLineItemBreakdownResponse, ITopNavigationData, makeBookingManagerApi } from 'services/BookingManagerApi';
import { EProposalSortBy } from '../model';
import { ESortOrder } from 'store/common/types';
import { IProposalBooking } from '../types';
import { IProposalHistory } from 'services/BookingManagerApi';
import { IProposalSelection } from 'services/BookingManagerApi/types/ProposalSelection';
import { compileProposalBooking } from '../helpers';

export function* getProposalDetailsSaga() {
  try {
    const backendApi = makeBackendApi();
    const bookingManagerApi = makeBookingManagerApi();
    const proposalUuid = yield select(proposalDetailsUuidSelector);

    // At the moment BMS does not have a single endpoint that returns all the data about a given proposal.
    // Therefore, proposal data needs to be get from a bunch of different BMS endpoints,
    // and then merged together into a "ProposalBooking" object that can be used by UI components.
    const proposalDetailsResponse: AxiosResponse<IProposalSelection> = yield call(bookingManagerApi.getProposalSelection, proposalUuid);
    const bookingUuids = proposalDetailsResponse.data.bookingUuids;
    
    const proposalDownloadHistoryResponse: AxiosResponse<IProposalHistory[]> = yield call(bookingManagerApi.getProposalHistory, proposalUuid);
    const proposalDownloadHistory = proposalDownloadHistoryResponse.data;

    yield put(setProposalDownloadHistoryAction(proposalDownloadHistory));

    const hotelDetalsRequests = bookingUuids.map(
      bookingUuid => call(bookingManagerApi.getHotelDetails, bookingUuid)
      )
    const hotelDetalsResponses: AxiosResponse<IGetHotelDetailsResponse>[] = yield all(hotelDetalsRequests);
    const hotelUuids = hotelDetalsResponses.map(item => item.data.hotelDetails.uuid);

    const breakdownRequests = bookingUuids.map(
      bookingUuid => call(bookingManagerApi.getHeadlineLineItemBreakdown, bookingUuid)
    );
    const topNavigationRequests = bookingUuids.map(
      bookingUuid => call(bookingManagerApi.getTopNavigationData, bookingUuid)
    );
    const hotelRequests = uniq(hotelUuids).map(
      hotelUuid => call(backendApi.getHotel, hotelUuid, ['uploads'])
    );
    
    const allResponses = yield all([
      ...breakdownRequests, ...topNavigationRequests, ...hotelRequests
    ]);

    const hotelResponses: AxiosResponse<IHotelResponse>[] = allResponses.slice(2 * bookingUuids.length);
    const hotelsMap: { [key: string]: IHotel } = hotelResponses.reduce((previousValue, currentValue) => {
      const hotel = currentValue.data.data;
      previousValue[hotel.uuid] = hotel;
      return previousValue;
    }, {});
    
    const bookings: IProposalBooking[] = bookingUuids.map((bookingUuid, index) => {
      const bookingBreakdownResponse: IHeadlineLineItemBreakdownResponse = allResponses[index].data;
      const bookingTopNavigation: ITopNavigationData = allResponses[index + bookingUuids.length].data.topNavigationData;
      const hotelUuid = hotelUuids[index];
      const hotel = hotelsMap[hotelUuid];    
      
      return compileProposalBooking(bookingBreakdownResponse, bookingTopNavigation, hotel);
    })

    yield put(setProposalBookingsAction(bookings));
    yield put(setProposalDetailsSortByAction(EProposalSortBy.CREATED, ESortOrder.ASC));
    yield put(getProposalDetailsSuccessAction(proposalDetailsResponse.data));
  } catch (e) {
    yield put(getProposalDetailsFailureAction(e));
  }
}

export function* watchGetProposalDetails() {
  yield takeLatest([GET_PROPOSAL_DETAILS_REQUEST], getProposalDetailsSaga);
}
