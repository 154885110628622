import React, { CSSProperties } from 'react';
import classnames from 'classnames';
import { IValueLabelPair } from '../../interfaces';

export const Select = ({
  value,
  options,
  onChange,
  className,
  wrapperClassName,
  disabled,
  styles,
}: {
  value: string | null;
  options: IValueLabelPair[];
  onChange: Function;
  className?: string;
  wrapperClassName?: string;
  disabled?: boolean;
  styles?: CSSProperties;
}) => {
  return (
    <div className={classnames('relative', wrapperClassName)}>
      <select
        className={`font-pt-sans default:pl-3 default:pt-2 default:pb-2 default:uppercase block border border-solid border-gray-40 text-black bg-white cursor-pointer default:min-h-35px default:max-h-35px outline-none ${className}`}
        style={{
          appearance: 'none',
          borderRadius: '0',
          ...styles,
        }}
        disabled={disabled}
        value={value === null ? 'null' : value}
        onChange={e => {
          if (e.target.value === 'null') {
            return onChange({
              target: {
                value: null,
              },
            });
          }
          return onChange(e);
        }}
      >
        {options &&
          options.map(option => {
            if (option.value === null) {
              return (
                <option key={`${option.value}-${option.label}`} value={'null'} disabled={option.disabled}>
                  {option.label}
                </option>
              );
            }
            return (
              <option key={`${option.value}-${option.label}`} value={option.value} disabled={option.disabled}>
                {option.label}
              </option>
            );
          })}
      </select>
      <div className="absolute flex justify-center items-center top-0 right-0 max-h-35px min-h-35px min-w-35px max-w-35px cursor-pointer border-l border-solid border-l-gray-40 pointer-events-none">
        <i className="fas fa-chevron-down relative top-1px text-gray-100"></i>
      </div>
    </div>
  );
};
