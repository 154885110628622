import { BookingBuilderRequest, ENetworkRequestStatus, GuestAges } from 'services/BackendApi';

export interface IEditRequestAccommodations {
  guestAges: GuestAges;
  repeatCustomer: boolean;
  startDate: string;
  endDate: string;
  modified: boolean;
  remove?: boolean;
  notSaved?: boolean;
}

export interface IRequestSubdomain {
  request?: BookingBuilderRequest;
  editRequestAccommodations: IEditRequestAccommodations[];
  networkRequests: {
    requestSent: ENetworkRequestStatus;
  };
}

export const requestInitialState: IRequestSubdomain = {
  request: undefined,
  editRequestAccommodations: [],
  networkRequests: {
    requestSent: ENetworkRequestStatus.IDLE,
  },
};
