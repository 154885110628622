
import { takeLatest, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { BACK_TO_SEARCH_RESULTS } from '../actions';
import * as FastSearchActions from 'store/modules/fastSearch/actions';
import * as AgentsActions from 'store/modules/agents/actions';

export function* backToSearchResultsSaga(action: any) {
  yield put(FastSearchActions.restoreOriginalQueryAction());
  yield put(AgentsActions.restoreOriginalSelectionAction());
  yield put(push('/search/beta'));
}

export function* watchBackToSearchResults() {
  yield takeLatest(
    BACK_TO_SEARCH_RESULTS,
    backToSearchResultsSaga
  );
}
