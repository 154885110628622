import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { VerticalLine } from 'ui/VerticalLine';
import { theme } from '../../../../tailwind.config';
import { Table as BaseTable } from 'ui/Table';
import { ESortOrder } from 'store/common/types';
import { Radio } from 'ui/Radio';
import * as Actions from 'store/modules/liveRates/actions';
import * as Selectors from 'store/modules/liveRates/selectors';
import { ELiveRatesSettingsListFilterType } from 'services/BackendApi/types/LiveRatesInternal';
import { enqueueNotification } from 'store/modules/ui';
import CopyIcon from 'ui/Icons/copy.component.svg';
import CheckIcon from 'ui/Icons/check.component.svg';
import classnames from 'classnames';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import FluidButton from 'ui/FluidButton';
import Checkbox from 'ui/Checkbox';

interface ILiveRatesSettingPageParams {
  stayUuid: string;
  filterType: ELiveRatesSettingsListFilterType;
}

const internalRoomsColumns = [
  {
    id: 'name',
    label: 'name',
    width: '100px',
  },
  {
    id: 'externalHotelId',
    label: 'External ID.',
    width: '100px',
  },
];

const externalRoomsColumns = [
  {
    id: 'name',
    label: 'name',
    width: '200px',
  },
  {
    id: 'externalHotelId',
    label: 'External ID.',
    width: '100px',
  },
  {
    id: 'mapped',
    label: 'Mapped',
    width: '50px',
  },
];

export const LiveRatesSettingPage = () => {
  const match = useRouteMatch<ILiveRatesSettingPageParams>();
  const stayUuid = match.params.stayUuid;
  const filterType = match.params.filterType;

  const internalRoomsList = useSelector(Selectors.internalRoomsListSelector);
  const externalRoomsList = useSelector(Selectors.externalRoomsListSelector);

  const internalRoomsFilter = useSelector(Selectors.internalRoomsFilterSelector);
  const isAttemptingToChangeInternalRoomsFilterTo = useSelector(
    Selectors.isAttemptingToChangeInternalRoomsFilterToSelector
  );
  const externalRoomsFilter = useSelector(Selectors.externalRoomsFilterSelector);

  const internalRoomsErrors = useSelector(Selectors.internalRoomsErrorsSelector);
  const internalRoomsErrorMessages = useSelector(Selectors.internalRoomsUniqueErrorMessagesSelector);

  const stayInformation = useSelector(Selectors.stayInformationSelector);
  const networkRequests = useSelector(Selectors.requestSelector);

  const hasUnsavedChanges = useSelector(Selectors.hasUnsavedChangesSelector);
  const showUnsavedChangesModal = useSelector(Selectors.showUnsavedChangesModalSelector);
  const dispatch = useDispatch();

  const isInternalRoomsLoading = networkRequests.settingsPageStayAndInternalRoomsGet === ENetworkRequestStatus.PENDING;
  const isExternalRoomsLoading =
    isInternalRoomsLoading || networkRequests.settingsPageExternalRoomsGet === ENetworkRequestStatus.PENDING;

  const isSaveButtonLoading = networkRequests.settingsPageSave === ENetworkRequestStatus.PENDING;

  const internalRoomsRows = internalRoomsList.map(ir => {
    return {
      id: ir.name,
      cells: [
        {
          id: 'name',
          value: ir.name,
        },
        {
          id: 'externalHotelId',
          value: (
            <span>
              <input
                value={ir.externalId || ''}
                onChange={e => {
                  dispatch(Actions.settingsPageActions.setInternalRoomExternalId(ir.uuid, e.target.value));
                }}
                className={classnames(
                  'w-[90%] h-[37px] px-10px py-8px outline-none border border-solid font-pt-sans text-15px text-black',
                  {
                    'bg-ivory border-gray-40': !internalRoomsErrors.find(e => e.uuid === ir.uuid),
                    'bg-red-25 border-red-95': internalRoomsErrors.find(e => e.uuid === ir.uuid),
                  }
                )}
              />
            </span>
          ),
        },
      ],
    };
  });

  const externalRoomsRows = externalRoomsList.map(er => {
    return {
      id: er.externalId,
      cells: [
        {
          id: 'name',
          value: er.name,
        },
        {
          id: 'externalHotelId',
          value: (
            <span className="flex flex-row items-center">
              {er.externalId}
              <span
                onClick={() => {
                  navigator.clipboard.writeText(er.externalId || '');
                  dispatch(
                    enqueueNotification({
                      message: `Copied '${er.externalId}' to clipboard`,
                      options: { variant: 'success' },
                    })
                  );
                }}
                className="text-brown-prime h-20px cursor-pointer"
              >
                <CopyIcon className="w-[20px] fill-brown-prime" />
              </span>
            </span>
          ),
        },
        {
          id: 'mapped',
          value: <span className="text-black">{er.isMapped ? <CheckIcon className="w-[20px]" /> : null}</span>,
        },
      ],
    };
  });

  // on mount, if we have a filter type, set it in the store
  // if we don't, default to MAPPED
  useEffect(() => {
    if (filterType) {
      dispatch(Actions.settingsPageActions.setInternalRoomsFilter(filterType));
    } else {
      dispatch(Actions.settingsPageActions.setInternalRoomsFilter(ELiveRatesSettingsListFilterType.MAPPED));
    }
  }, []);

  // on a new stay UUID, fetch the data
  useEffect(() => {
    if (stayUuid) {
      dispatch(Actions.settingsPageActions.getStayAndInternalRoomsRequestAction(stayUuid));
      dispatch(Actions.settingsPageActions.getExternalRoomsRequestAction(stayUuid));
    }
    return () => {
      // reset the data back to initial state
      dispatch(Actions.settingsPageActions.clearInternalRoomsErrors());
    };
  }, [stayUuid]);

  return (
    <div className="live-rates-mapping-list container w-full max-w-1280px mx-auto font-pt-sans text-black space-y-20px">
      {/* handles if they have unsaved changes and the user tries to leave route */}
      <LeaveWithoutSavingModal
        title={`You have made changes to this page, and not saved. If you leave this page now, these changes will be lost.`}
        when={hasUnsavedChanges}
      />
      {/* handles if they have unsaved changes and the user tries to change the filter */}
      {showUnsavedChangesModal && (
        <ConfirmationModal
          className="leave-without-saving z-30"
          type={EConfirmationModalType.WARNING}
          isOpen
          title={`You have made changes to this page, and not saved.
           If you leave this page now, these changes will be lost.`}
          message={'Are you sure you want to leave this page?'}
          confirmButtonLabel={'Leave'}
          cancelButtonLabel={'Cancel'}
          onConfirm={() => {
            dispatch(Actions.settingsPageActions.setInternalRoomsFilter(isAttemptingToChangeInternalRoomsFilterTo));
            dispatch(Actions.settingsPageActions.setHasUnsavedChanges(false));
            dispatch(Actions.settingsPageActions.setShowUnsavedChangesModal(false));
            // and then SOMEHOW reset the unsaved changes state
            dispatch(Actions.settingsPageActions.resetInternalRoomsToOriginalState());
            dispatch(Actions.settingsPageActions.clearInternalRoomsErrors());
          }}
          onCancel={() => {
            dispatch(Actions.settingsPageActions.setShowUnsavedChangesModal(false));
          }}
        />
      )}

      <h1 className="title font-noe-display font-normal text-black my-0">
        <span className="text-4xl leading-46px">Live Rate Mappings</span>
        <span className="text-26px leading-33px"> - {stayInformation?.name}</span>
      </h1>

      <span className="text-16px leading-20px">
        Map internal to external rooms using the product ID provided by the External Supplier, shown in the list from
        the right side.
      </span>

      <div className="flex space-x-[30px] items-center px-10px py-15px border border-solid border-gray-20">
        <span className="text-brown-prime">
          External Supplier: <span className="text-black">{stayInformation?.externalSystem}</span>
        </span>
        <VerticalLine height="22px" color={theme.colors['gray-20']} />
        <span className="text-brown-prime">
          External ID: <span className="text-black">{stayInformation?.externalId}</span>
        </span>
        <label
          className={`enable-liverates-for-ta inline-flex font-pt-sans text-base leading-100px text-black cursor-pointer mr-5`}
        >
          <Checkbox 
            checked={stayInformation?.liveRatesForTAs}
            onChange={ (e: React.FormEvent<HTMLInputElement>) =>{
              dispatch(Actions.settingsPageActions.setEnabledLiveRatesForTA(e.currentTarget.checked))
            }} 
          />
          <span className="text-base leading-21px ml-2">Enabled for travel partners</span>
        </label>
      </div>

      {/* the tables */}
      <div className="flex space-x-[60px]">
        {/* the internal rooms table */}
        <div className="flex flex-col space-y-4 w-1/2">
          <span className="font-bold text-17px leading-22px">Internal Rooms</span>
          <div className="internal-rooms-radios flex space-x-20px items-center">
            <label className="internal-rooms-radio-mapped flex flex-row space-x-2 items-center cursor-pointer">
              <Radio
                checked={internalRoomsFilter === ELiveRatesSettingsListFilterType.MAPPED}
                onClick={() => {
                  dispatch(
                    Actions.settingsPageActions.attemptSetInternalRoomsFilterRequest(
                      ELiveRatesSettingsListFilterType.MAPPED
                    )
                  );
                }}
              />
              <span className="text-16px leading-20px">Mapped</span>
            </label>
            <label className="internal-rooms-radio-not-mapped flex flex-row space-x-2 items-center cursor-pointer">
              <Radio
                checked={internalRoomsFilter === ELiveRatesSettingsListFilterType.NOT_MAPPED}
                onClick={() => {
                  dispatch(
                    Actions.settingsPageActions.attemptSetInternalRoomsFilterRequest(
                      ELiveRatesSettingsListFilterType.NOT_MAPPED
                    )
                  );
                }}
              />
              <span className="text-16px leading-20px">Not Mapped</span>
            </label>
            <label className="internal-rooms-radio-both flex flex-row space-x-2 items-center cursor-pointer">
              <Radio
                checked={internalRoomsFilter === ELiveRatesSettingsListFilterType.BOTH}
                onClick={() => {
                  dispatch(
                    Actions.settingsPageActions.attemptSetInternalRoomsFilterRequest(
                      ELiveRatesSettingsListFilterType.BOTH
                    )
                  );
                }}
              />
              <span className="text-16px leading-20px">Both</span>
            </label>
          </div>
          <div className="internal-rooms-table-wrapper max-h-[650px] overflow-y-scroll">
            <BaseTable
              className="internal-rooms-table"
              columns={internalRoomsColumns}
              rows={internalRoomsRows}
              sortBy={'name'}
              sortOrder={'asc' as ESortOrder}
              onSort={() => {}}
              messageWhenEmpty={isInternalRoomsLoading ? 'Loading' : 'No results'}
              loading={isInternalRoomsLoading}
            />
          </div>
        </div>

        {/* the external rooms table */}
        <div className="flex flex-col space-y-4 w-1/2">
          <span className="font-bold text-17px leading-22px">External Rooms</span>
          <div className="external-rooms-radios flex space-x-20px items-center">
            <label className="external-rooms-radio-mapped flex flex-row space-x-2 items-center cursor-pointer">
              <Radio
                checked={externalRoomsFilter === ELiveRatesSettingsListFilterType.MAPPED}
                onClick={() => {
                  dispatch(Actions.settingsPageActions.setExternalRoomsFilter(ELiveRatesSettingsListFilterType.MAPPED));
                }}
              />
              <span className="text-16px leading-20px">Mapped</span>
            </label>
            <label className="external-rooms-radio-not-mapped flex flex-row space-x-2 items-center cursor-pointer">
              <Radio
                checked={externalRoomsFilter === ELiveRatesSettingsListFilterType.NOT_MAPPED}
                onClick={() => {
                  dispatch(
                    Actions.settingsPageActions.setExternalRoomsFilter(ELiveRatesSettingsListFilterType.NOT_MAPPED)
                  );
                }}
              />
              <span className="text-16px leading-20px">Not Mapped</span>
            </label>
            <label className="external-rooms-radio-both flex flex-row space-x-2 items-center cursor-pointer">
              <Radio
                checked={externalRoomsFilter === ELiveRatesSettingsListFilterType.BOTH}
                onClick={() => {
                  dispatch(Actions.settingsPageActions.setExternalRoomsFilter(ELiveRatesSettingsListFilterType.BOTH));
                }}
              />
              <span className="text-16px leading-20px">Both</span>
            </label>
          </div>
          <div className="external-rooms-table-wrapper max-h-[650px] overflow-y-scroll">
            <BaseTable
              className="external-rooms-table"
              columns={externalRoomsColumns}
              rows={externalRoomsRows}
              sortBy={'name'}
              sortOrder={'asc' as ESortOrder}
              onSort={() => {}}
              messageWhenEmpty={isExternalRoomsLoading ? 'Loading' : 'No results'}
              loading={isExternalRoomsLoading}
            />
          </div>
        </div>
      </div>

      {/* if we have any error messages, render them out */}
      {internalRoomsErrorMessages.length > 0 &&
        internalRoomsErrorMessages.map(e => (
          <span key={e} className="block text-red-95 text-13px leading-14px pt-sans">
            {e}
          </span>
        ))}

      <FluidButton
        type="primary"
        onClick={() => {
          dispatch(Actions.settingsPageActions.saveListsRequest());
        }}
        disabled={isInternalRoomsLoading || isExternalRoomsLoading || isSaveButtonLoading}
        isLoading={isSaveButtonLoading}
      >
        Save
      </FluidButton>
    </div>
  );
};
