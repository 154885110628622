import React, { useEffect } from 'react';

/**
 * Hook to get the raw URL parameters
 */
export function useRawUrlParams<T>(): T {
  const search = window.location.search;
  // do the initial params...
  const urlParams = new URLSearchParams(search);
  const [params, setParams] = React.useState<any>(Object.fromEntries(urlParams.entries()));

  // ...and then listen for changes
  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlParams.entries());
    setParams(params);
  }, [search]);

  return params;
}
