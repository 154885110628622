import React, { useCallback } from 'react';
import classnames from 'classnames';
import { RadioListBoxItem } from './RadioListBoxItem';
import { IRadioListBoxOption } from './types';

interface IRadioListBoxProps {
  id: string;
  label?: string;
  value: string | null;
  options: IRadioListBoxOption[];
  textWhenEmpty: string;
  className?: string;
  labelClassName?: string;
  onChange: (value: string) => void;
  errorMessage?: string | null;
  errorClassName?: string;
}

export const RadioListBox: React.FC<IRadioListBoxProps> = React.memo((
  { className = '', id, label, labelClassName = '', value, options, textWhenEmpty = '', onChange, errorMessage, errorClassName = '' }
) => {
  const isError = !!errorMessage || errorMessage === '';
  const radioListBoxClassname = classnames(
    className,
    'overflow-y-auto flex flex-col gap-[10px] border border-solid p-[5px] pl-[10px]',
    {
      'border-gray-20 bg-white': !isError,
      'border-red-95 bg-red-25': isError,
    }
  );
  
  const handleSelect = useCallback((value: string) => {
    onChange(value);
  }, [onChange]);

  return (
    <div className="radio-listbox-container flex flex-col gap-[5px]">
      {label && <label className={classnames('default:text-black default:font-pt-sans default:text-13px default:leading-17px default:tracking-2xs', labelClassName)}>{label}</label>}
      <div className={radioListBoxClassname}>
        {options.map(option => {
          const checked = value === option.value;
          return (
            <RadioListBoxItem id={id} key={option.value} onSelect={handleSelect} checked={checked} {...option} />
          );
        })}
        {options.length === 0 && (
          <p className="text-when-empty m-0 font-pt-sans text-black text-[15px] leading-[19px] italic p-[3px]">{textWhenEmpty}</p>
        )}
      </div>
      {errorMessage && (
        <p className={`font-normal text-red-95 text-13px leading-2xs font-pt-sans m-0 ${errorClassName}`}>
          {errorMessage}
        </p>
      )}
    </div>
  );
});
