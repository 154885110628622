import { lensPath, over, partialRight, pipe } from 'ramda';
import { subDays } from 'date-fns';

export const SEARCH_QUERY_UPDATE = 'SEARCH_QUERY_UPDATE';
export const SEARCH_FILTERS_RESET = 'SEARCH_FILTERS_RESET';
export const SEARCH_RESULTS = 'SEARCH_RESULTS';
export const SEARCH_BY_NAME = 'SEARCH_BY_NAME';
export const SEARCH_BY_QUERY = 'SEARCH_BY_QUERY';

export const SEARCH_OPTIONS_REQUEST = 'SEARCH/SEARCH_OPTIONS_REQUEST';
export const SEARCH_OPTIONS_SUCCESS = 'SEARCH/SEARCH_OPTIONS_SUCCESS';
export const SEARCH_OPTIONS_FAILURE = 'SEARCH/SEARCH_OPTIONS_FAILURE';

/**
 * Set search query action
 *
 * @param {object} payload
 * @returns {object}
 */
export const setSearchQuery = payload => ({
  type: SEARCH_QUERY_UPDATE,
  payload,
});

/**
 * Search filters reset action
 *
 * @param {object} payload
 * @returns {object}
 */
export const searchFiltersReset = payload => ({
  type: SEARCH_FILTERS_RESET,
  payload,
});

/**
 * Search query object
 *
 * @param {object} filters
 * @returns {Object}
 */
export const sanitizePriceRange = filters => {
  const sanitizedPrices = filters.prices ? filters.prices.map(v => (v === '' || v === 0 ? undefined : v)) : [];

  return {
    ...filters,
    // Fun fact: It's prices here, and in the middleware. But it's priceRange in the back end.
    prices: {
      min: sanitizedPrices[0],
      max: sanitizedPrices[1],
    },
  };
};

export const subDaysFromPayload = over(
  lensPath(['dates', 'endDate']),
  pipe(input => new Date(input), partialRight(subDays, [1]))
);

export const searchOptionsRequestAction = () => ({
  type: SEARCH_OPTIONS_REQUEST,
});

export const searchOptionsSuccessAction = response => ({
  type: SEARCH_OPTIONS_SUCCESS,
  payload: { ...response.options.data.data },
});

export const searchOptionsFailureAction = error => ({
  type: SEARCH_OPTIONS_FAILURE,
  payload: { error },
});
