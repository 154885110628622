import { AxiosResponse } from 'axios';
import { call, takeLatest, select, put } from 'redux-saga/effects';
import {
  UpdateCompanyLogoAction,
  UPDATE_COMPANY_LOGO,
  fetchCompanyLogoSuccessAction,
  fetchCompanyLogoFailureAction,
  setCompanyLogoNetworkPostLogoAction,
} from '../actions';
import { ENetworkRequestStatus, IUploadResponse, makeBackendApi } from 'services/BackendApi';
import { selectedTaSelector } from '../../agents';
import { enqueueNotification } from '../../ui';

export function* updateCompanyLogoSaga(action: UpdateCompanyLogoAction) {
  try {
    yield put(setCompanyLogoNetworkPostLogoAction(ENetworkRequestStatus.PENDING));
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const result: AxiosResponse<IUploadResponse> = yield call(backendApi.uploadFile, action.formData);
    yield put(fetchCompanyLogoSuccessAction(result.data.data));
    yield put(setCompanyLogoNetworkPostLogoAction(ENetworkRequestStatus.SUCCESS));
    yield put(
      enqueueNotification({
        message: 'Company logo has been updated',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(fetchCompanyLogoFailureAction(e));
    yield put(setCompanyLogoNetworkPostLogoAction(ENetworkRequestStatus.ERROR));
    yield put(
      enqueueNotification({
        message: 'Error updating company logo',
        options: { variant: 'success' },
      })
    );
  }
}

export function* watchUpdateCompanyLogo() {
  yield takeLatest(UPDATE_COMPANY_LOGO, updateCompanyLogoSaga);
}
