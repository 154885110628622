import { ICompanyIncentiveResponse, EIncentiveAmountType } from 'services/BookingManagerApi/types';

export const TOGGLE_COMPANY_INCENTIVES = 'companyIncentives/TOGGLE_COMPANY_INCENTIVES';

export const FETCH_COMPANY_INCENTIVES_REQUEST = 'companyIncentives/FETCH_COMPANY_INCENTIVES_REQUEST';
export const FETCH_COMPANY_INCENTIVES_SUCCESS = 'companyIncentives/FETCH_COMPANY_INCENTIVES_SUCCESS';
export const FETCH_COMPANY_INCENTIVES_FAILURE = 'companyIncentives/FETCH_COMPANY_INCENTIVES_FAILURE';

export const SET_COMPANY_INCENTIVE_TYPE = 'companyIncentives/SET_COMPANY_INCENTIVE_TYPE';
export const SET_COMPANY_INCENTIVE_AMOUNT = 'companyIncentives/SET_COMPANY_INCENTIVE_AMOUNT';
export const SET_COMPANY_INCENTIVE_START_DATE = 'companyIncentives/SET_COMPANY_INCENTIVE_START_DATE ';
export const SET_COMPANY_INCENTIVE_NOTES = 'companyIncentives/SET_COMPANY_INCENTIVE_NOTES';

export const UPSERT_COMPANY_INCENTIVE_REQUEST = 'companyIncentives/UPSERT_COMPANY_INCENTIVE_REQUEST';
export const UPSERT_COMPANY_INCENTIVE_SUCCESS = 'companyIncentives/UPSERT_COMPANY_INCENTIVE_SUCCESS';
export const UPSERT_COMPANY_INCENTIVE_FAILURE = 'companyIncentives/UPSERT_COMPANY_INCENTIVE_FAILURE';

export const DELETE_COMPANY_INCENTIVE_REQUEST = 'companyIncentives/DELETE_COMPANY_INCENTIVE_REQUEST';
export const DELETE_COMPANY_INCENTIVE_SUCCESS = 'companyIncentives/DELETE_COMPANY_INCENTIVE_SUCCESS';
export const DELETE_COMPANY_INCENTIVE_FAILURE = 'companyIncentives/DELETE_COMPANY_INCENTIVE_FAILURE';

export type ToggleCompanyIncentivesAction = ReturnType<typeof toggleCompanyIncentivesAction>;
export const toggleCompanyIncentivesAction = () => ({
  type: TOGGLE_COMPANY_INCENTIVES as typeof TOGGLE_COMPANY_INCENTIVES
});

export type FetchCompanyIncentivesRequestAction = ReturnType<typeof fetchCompanyIncentivesRequestAction>;
export const fetchCompanyIncentivesRequestAction = () => ({
  type: FETCH_COMPANY_INCENTIVES_REQUEST as typeof FETCH_COMPANY_INCENTIVES_REQUEST
});

export type FetchCompanyIncentivesSuccessAction = ReturnType<typeof fetchCompanyIncentivesSuccessAction>;
export const fetchCompanyIncentivesSuccessAction = (incentive: ICompanyIncentiveResponse) => ({
  type: FETCH_COMPANY_INCENTIVES_SUCCESS as typeof FETCH_COMPANY_INCENTIVES_SUCCESS,
  incentive
});

export type FetchCompanyIncentivesFailureAction = ReturnType<typeof fetchCompanyIncentivesFailureAction>;
export const fetchCompanyIncentivesFailureAction = (error: Error) => ({
  type: FETCH_COMPANY_INCENTIVES_FAILURE as typeof FETCH_COMPANY_INCENTIVES_FAILURE,
  error
});

export type SetCompanyIncentiveTypeAction = ReturnType<typeof setCompanyIncentiveTypeAction >;
export const setCompanyIncentiveTypeAction = (type: EIncentiveAmountType) => ({
  type: SET_COMPANY_INCENTIVE_TYPE as typeof SET_COMPANY_INCENTIVE_TYPE,
  amountType: type
});

export type SetCompanyIncentiveAmountAction = ReturnType<typeof setCompanyIncentiveAmountAction >;
export const setCompanyIncentiveAmountAction = (amount: number) => ({
  type: SET_COMPANY_INCENTIVE_AMOUNT as typeof SET_COMPANY_INCENTIVE_AMOUNT,
  amount
});

export type SetCompanyIncentiveStartDateAction = ReturnType<typeof setCompanyIncentiveStartDateAction >;
export const setCompanyIncentiveStartDateAction = (startDate: string) => ({
  type: SET_COMPANY_INCENTIVE_START_DATE as typeof SET_COMPANY_INCENTIVE_START_DATE,
  startDate
});

export type SetCompanyIncentiveNotesAction = ReturnType<typeof setCompanyIncentiveNotesAction >;
export const setCompanyIncentiveNotesAction = (notes: string) => ({
  type: SET_COMPANY_INCENTIVE_NOTES as typeof SET_COMPANY_INCENTIVE_NOTES,
  notes
});

export type UpsertCompanyIncentivesRequestAction = ReturnType<typeof upsertCompanyIncentivesRequestAction>;
export const upsertCompanyIncentivesRequestAction = () => ({
  type: UPSERT_COMPANY_INCENTIVE_REQUEST as typeof UPSERT_COMPANY_INCENTIVE_REQUEST
});

export type UpsertCompanyIncentivesSuccessAction = ReturnType<typeof upsertCompanyIncentivesSuccessAction>;
export const upsertCompanyIncentivesSuccessAction = () => ({
  type: UPSERT_COMPANY_INCENTIVE_SUCCESS as typeof UPSERT_COMPANY_INCENTIVE_SUCCESS
});

export type UpsertCompanyIncentivesFailureAction = ReturnType<typeof upsertCompanyIncentivesFailureAction>;
export const upsertCompanyIncentivesFailureAction = (error: Error) => ({
  type: UPSERT_COMPANY_INCENTIVE_FAILURE as typeof UPSERT_COMPANY_INCENTIVE_FAILURE,
  error
});

export type DeleteCompanyIncentivesRequestAction = ReturnType<typeof deleteCompanyIncentivesRequestAction>;
export const deleteCompanyIncentivesRequestAction = () => ({
  type: DELETE_COMPANY_INCENTIVE_REQUEST as typeof DELETE_COMPANY_INCENTIVE_REQUEST
});

export type DeleteCompanyIncentivesSuccessAction = ReturnType<typeof deleteCompanyIncentivesSuccessAction>;
export const deleteCompanyIncentivesSuccessAction = () => ({
  type: DELETE_COMPANY_INCENTIVE_SUCCESS as typeof DELETE_COMPANY_INCENTIVE_SUCCESS
});

export type DeleteCompanyIncentivesFailureAction = ReturnType<typeof deleteCompanyIncentivesFailureAction>;
export const deleteCompanyIncentivesFailureAction = (error: Error) => ({
  type: DELETE_COMPANY_INCENTIVE_FAILURE as typeof DELETE_COMPANY_INCENTIVE_FAILURE,
  error
});

export type CompanyIncentivesAction =
 | ToggleCompanyIncentivesAction
 | FetchCompanyIncentivesRequestAction
 | FetchCompanyIncentivesSuccessAction
 | FetchCompanyIncentivesFailureAction
 | SetCompanyIncentiveTypeAction
 | SetCompanyIncentiveAmountAction
 | SetCompanyIncentiveStartDateAction
 | SetCompanyIncentiveNotesAction
 | UpsertCompanyIncentivesRequestAction
 | UpsertCompanyIncentivesSuccessAction
 | UpsertCompanyIncentivesFailureAction
 | DeleteCompanyIncentivesRequestAction
 | DeleteCompanyIncentivesSuccessAction
 | DeleteCompanyIncentivesFailureAction;