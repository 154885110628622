import { IExternalIds } from 'services/BookingManagerApi';

export const GET_REQUEST = 'bookingManager/externalIds/GET_REQUEST';
export const GET_SUCCESS = 'bookingManager/externalIds/GET_SUCCESS';
export const GET_FAILURE = 'bookingManager/externalIds/GET_FAILURE';

export const UPDATE_REQUEST = 'bookingManager/externalIds/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'bookingManager/externalIds/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'bookingManager/externalIds/UPDATE_FAILURE';

export const SET_TRAVEL_PARTNER_REF = 'bookingManager/externalIds/SET_TRAVEL_PARTNER_REF';

export type GetRequestAction = ReturnType<typeof getRequestAction>;
export const getRequestAction = () => ({
  type: GET_REQUEST as typeof GET_REQUEST,
});

export type GetSuccessAction = ReturnType<typeof getSuccessAction>;
export const getSuccessAction = (value: IExternalIds) => ({
  type: GET_SUCCESS as typeof GET_SUCCESS,
  value
});

export type GetFailureAction = ReturnType<typeof getFailureAction>;
export const getFailureAction = (error: string) => ({
  type: GET_FAILURE as typeof GET_FAILURE,
  error,
});

export type UpdateRequestAction = ReturnType<typeof updateRequestAction>;
export const updateRequestAction = () => ({
  type: UPDATE_REQUEST as typeof UPDATE_REQUEST,
});

export type UpdateSuccessAction = ReturnType<typeof updateSuccessAction>;
export const updateSuccessAction = (value: IExternalIds) => ({
  type: UPDATE_SUCCESS as typeof UPDATE_SUCCESS,
  value,
});

export type UpdateFailureAction = ReturnType<typeof updateFailureAction>;
export const updateFailureAction = (error: string) => ({
  type: UPDATE_FAILURE as typeof UPDATE_FAILURE,
  error,
});

export type SetTravelPartnerRefAction = ReturnType<typeof setTravelPartnerRefAction>;
export const setTravelPartnerRefAction = (value: string) => ({
  type: SET_TRAVEL_PARTNER_REF as typeof SET_TRAVEL_PARTNER_REF,
  value
});

export type Any =
  | GetRequestAction
  | GetSuccessAction
  | GetFailureAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | SetTravelPartnerRefAction;
