import { ETaskPriority, ETaskStatus } from 'services/TaskManagementApi/types/TaskManagementResponse';
import * as Yup from 'yup';

export const taskFormValidationSchema = Yup.object().shape({
  subject: Yup.string().required('Field is required'),
  category: Yup.string().nullable(true).required('Field is required'),
  bookingRef: Yup.string(),
  description: Yup.string().nullable(true),
  status: Yup.string().required('Field is required'),
  priority: Yup.string().required('Field is required'),
  teamId: Yup.string().nullable(true).required('Field is required'),
  assigneeId: Yup.string().nullable(true),
  startByDate: Yup.date().nullable(true),
  endDate: Yup.date().required('Field is required'),
  endTime: Yup.string().nullable(true).required('Field is required'),
});

export interface ITaskFormValues {
  subject: string;
  category: string | null;
  bookingRef: string;
  description: string | null;
  status: ETaskStatus;
  priority: ETaskPriority;
  teamId: string | null;
  assigneeId: string | null | undefined;
  startByDate: string | null;
  endDate: string;
  endTime: string | null;
}