import { call, takeLatest, select, put, delay } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { IUser, makeBackendApi } from 'services/BackendApi';
import { selectedTaSelector } from '../../agents';
import { GET_MENTION_USERS_REQUEST, getMentionListFailureAction, GetMentionListRequestAction, getMentionListSuccessAction, getUsersFailureAction, getUsersSuccessAction } from '../actions';
import { enqueueNotification } from 'store/modules/ui';

export function* getMentionListSaga(action: GetMentionListRequestAction) {
  yield delay(700);
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const mentionUsers: AxiosResponse<{ data: Pick<IUser, 'uuid' | 'firstName' | 'lastName' | 'email' | 'type'>[] }> = yield call(backendApi.searchUsersSimple, '','');

    yield put(getMentionListSuccessAction(mentionUsers.data.data));
  } catch (e) {
    yield put(getMentionListFailureAction(e));
    yield put(enqueueNotification({ message: 'Can not get users mention list', options: { variant: 'error' } }));
  }
}

export function* watchGetMentionListSaga() {
  yield takeLatest(
    GET_MENTION_USERS_REQUEST,
    getMentionListSaga
  );
}
