import * as React from 'react';
import classNames from 'classnames';

import { IBookingComment } from 'services/BookingManagerApi/types';
import { formatDateTimeDisplay } from 'utils';
import SectionTitle from 'ui/SectionTitle';

interface ICommentsOverviewProps {
  comments: IBookingComment[];
  isLoading: boolean;
  isError: boolean;
}

const renderEmptyMessage = () => {
  return <div className="info empty flex items-center mt-4">This booking has no comments.</div>;
};

const renderComments = (comments: IBookingComment[]) => {
  const commentsToDisplay = comments.slice(0, 10);

  return commentsToDisplay.map((comment: IBookingComment, commentIndex: number) => {
    const rowClassName: string = classNames({
      item: true,
      'pt-4 pb-3 font-pt-sans border-b border-solid border-b-gray-40': true,
      'border-b-0': commentsToDisplay.length - 1 === commentIndex,
    });

    const displayedCommentTypeMap = {
      public: 'public',
      private: 'internal',
    };

    return (
      <div key={comment.uuid} className={rowClassName} data-testid="overviewComment">
        <div className="flex justify-between">
          <div className="date text-gray-100 text-sm leading-xs" data-testid="overviewCommentDate">
            {formatDateTimeDisplay(comment.updatedAt)}
          </div>
          <div className="bg-teal-20 pl-1 pr-1">
            <span className="type text-xs leading-2xs uppercase text-brown-140" data-testid="commentOverviewType">
              {displayedCommentTypeMap[comment.type]}
            </span>
          </div>
        </div>
        <div className="pt-3" data-testid="overviewCommentText">
          <span className="content whitespace-pre-wrap text-sm leading-xs text-black break-words">{comment.text}</span>
        </div>
      </div>
    );
  });
};

export const CommentsOverview: React.FC<ICommentsOverviewProps> = ({ comments, isLoading, isError }) => {
  const containerClassName = `
    flex items-center justify-center min-h-150px font-pt-sans
    p-5 border border-solid border-gray-40 shadow-pe1 font-pt-sans"
  `;

  if (isLoading) {
    return (
      <div className="comments-overview pb-10">
        <div className={`${containerClassName} text-lg text-black`} data-testid="commentsOverviewLoading">
          <span className="info loading">Loading...</span>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="comments-overview pb-10">
        <div className={`${containerClassName} text-sm text-red-100`} data-testid="commentsOverviewError">
          <span className="info error">Failed to load Comments</span>
        </div>
      </div>
    );
  }

  return (
    <div className="comments-overview pb-10">
      <div className="p-5 border border-solid border-gray-40 shadow-pe1 font-pt-sans">
        <SectionTitle>Comments</SectionTitle>
        <div className="list">{comments.length ? renderComments(comments) : renderEmptyMessage()}</div>
      </div>
    </div>
  );
};

export default CommentsOverview;
