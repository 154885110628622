import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import { pureUiTheme } from 'pureUi/pureUiTheme';
import ImageLoader from 'pureUi/ImageLoader';
import { getCurrencySymbol, formatPrice } from 'utils';
import { Heading2 } from 'styles';
import { useTranslation } from 'react-i18next';
import LinkButton from 'components/LinkButton';
import ResultBadge from 'pureUi/ResultBadge';
import { IDisplayTotalsBreakdown, HotelResult } from 'services/BackendApi';
import { flatten } from 'ramda';
import Star from 'ui/Icons/star.component.svg';
import { theme } from '../../../tailwind.config';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { AvailabilityTag, EAvailabilityTagStatus } from 'ui/AvailabilityTag/AvailabilityTag';

export interface SearchResultHotelProps extends React.HTMLProps<HTMLDivElement> {
  result: HotelResult;
  showHighlights: boolean;
  onToggleHighlights: (id: string) => void;
  onNavigateToHotel: (hotelUuid: string, hotelName: string) => void;
}
export const SearchResultHotel = memo((props: SearchResultHotelProps) => {
  const {
    result,
    showHighlights,
    onToggleHighlights,
    onNavigateToHotel,
    onClick,
    className,
    ...otherProps
  } = props;
  const { t } = useTranslation();
  const { dynamicParameters } = useDynamicParameters();

  const currencySymbol =
    result.bookingBuilder !== false
      ? getCurrencySymbol(result.bookingBuilder.response.currency)
      : getCurrencySymbol(result.defaultCurrency);

  const offerCount = result.bookingBuilder === false ? 0 : result.bookingBuilder.response.appliedOfferNames.length;
  const priceAfterDiscounts =
    result.bookingBuilder === false ? 0 : result.bookingBuilder.response.totals.totalForPricedItems;
  const priceBeforeDiscounts =
    result.bookingBuilder === false ? 0 : result.bookingBuilder.response.totals.totalBeforeDiscount;
  const isPreferred = result.preferred;
  const onRequest =
    result.bookingBuilder === false ? false : result.bookingBuilder.response.totals.oneOrMoreItemsOnRequest;
  const appliedOffers = result.bookingBuilder === false ? [] : result.bookingBuilder.response.appliedOfferNames;
  const displayTotals: IDisplayTotalsBreakdown | undefined =
    result.bookingBuilder === false ? undefined : result.bookingBuilder.response.displayTotals;
  const bookingBuilderUploads = result.bookingBuilder === false ? [] : result.bookingBuilder.response.uploads;

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onNavigateToHotel(result.uuid, result.name);
  };

  const featuredUpload =
    props.result.uploads.find(upload => upload.tag === 'featuredPhoto') ||
    props.result.uploads.find(upload => upload.tag === 'photo') ||
    bookingBuilderUploads.find(upload => upload.url.match(/\.(gif|jpg|jpeg|tiff|png)$/i));

  const safeUpload = featuredUpload ? featuredUpload : { url: 'no-img', displayName: 'No Image' };

  const getPriceBasedOnInfo = useCallback(
    (breakdown: IDisplayTotalsBreakdown): JSX.Element => {
      const accom = breakdown.blocks
        .filter(b => b.blockType === 'Accommodations')
        .map(a => {
          const title = a.header;
          const extras = a.items.map(e => {
            return `${e.title}: ${e.labels.join(', ')}`;
          });
          return [title, extras];
        });

      const trans = breakdown.blocks
        .filter(b => b.blockType === 'Transfers')
        .map(t => {
          return t.items.map(i => i.labels.map(l => `${l} (${i.title})`));
        });

      const listItems = [...flatten(accom), ...flatten(trans)];

      return (
        <div className="tooltip-content">
          <p>Price Based On</p>
          <ul>
            {listItems.map(i => (
              <li key={i}>{i}</li>
            ))}
          </ul>
        </div>
      );
    },
    [props.result]
  );

  const getAppliedOffersInfo = useCallback(
    (appliedOffers: string[]): JSX.Element => {
      return (
        <div className="tooltip-content">
          <p>Applied Offers</p>
          <ul>
            {appliedOffers.map(offer => (
              <li key={offer}>{offer}</li>
            ))}
          </ul>
        </div>
      );
    },
    [props.result]
  );

  const hasOfferChangedPrice = priceAfterDiscounts && priceAfterDiscounts !== priceBeforeDiscounts;

  const availabilityStatus = result.hasRoomWithStaticAvailability
    ? EAvailabilityTagStatus.AVAILABLE
    : EAvailabilityTagStatus.REQUEST;

  const availabilityLabel = availabilityStatus === EAvailabilityTagStatus.AVAILABLE ? 'AVAILABLE' : 'ON REQUEST';

  return (
    <div
      id={result.uuid}
      className={`search-result ${className}`}
      key={result.name}
      onClick={handleClick}
      {...otherProps}
    >
      <ImageLoader aspectRatio="11:7" alt={safeUpload!.displayName} src={safeUpload!.url}>
        <>
          {isPreferred && <span className="badge pref">Preferred</span>}
          <div className="stack">
            <div className="stackColumn">
              {onRequest && <ResultBadge type="text" label={`On Request`} />}
              {!onRequest && offerCount === 0 && priceAfterDiscounts > 0 && (
                <ResultBadge type="price" label={`${currencySymbol}${formatPrice(priceAfterDiscounts)}`}>
                  {displayTotals && getPriceBasedOnInfo(displayTotals)}
                </ResultBadge>
              )}
              {!onRequest && offerCount > 0 && priceAfterDiscounts > 0 && (
                <ResultBadge type="price" label={`${currencySymbol}${formatPrice(priceAfterDiscounts)}`}>
                  {displayTotals && getPriceBasedOnInfo(displayTotals)}
                </ResultBadge>
              )}
              {!onRequest && offerCount > 0 && hasOfferChangedPrice && (
                <ResultBadge type="strikethrough" label={`${currencySymbol}${formatPrice(priceBeforeDiscounts)}`} />
              )}
            </div>
            <div className="stackColumn">
              {!onRequest && offerCount > 0 && (
                <ResultBadge type="offer" label={`${offerCount} ${offerCount > 1 ? 'Offers' : 'Offer'}`}>
                  {getAppliedOffersInfo(appliedOffers)}
                </ResultBadge>
              )}
            </div>
          </div>
        </>
      </ImageLoader>
      <div className="details">
        <div className="flex flex-row items-center justify-between">
          <Heading2 className="hotelName">{result.name}</Heading2>
          <AvailabilityTag availabilityStatus={availabilityStatus} className="static-rates-availability-tag">
            <span className="whitespace-nowrap">{availabilityLabel}</span>
          </AvailabilityTag>
        </div>
        <div className="flex flex-row items-center justify-between border border-solid border-gray-20 border-r-0 border-l-0 py-10px">
          <span className="starRating">
            <Star className="mr-5px" fill={theme.colors['brown-100']} />
            <span>{result.starRating} star</span>
          </span>
        </div>

        <ul className="overview">
          {result.overview.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>

        {!showHighlights && (
          <LinkButton
            aria-label={t('labels.seeHighlights')}
            data-role="linkButton.showHighlights"
            title={t('labels.showHighlights')}
            role="button"
            onClick={() => onToggleHighlights(result.uuid)}
          >
            + {t('labels.seeHighlights')}
          </LinkButton>
        )}
        {showHighlights && (
          <LinkButton
            aria-label={t('labels.hideHighlights')}
            data-role="linkButton.hideHighlights"
            title={t('labels.hideHighlights')}
            role="button"
            onClick={() => onToggleHighlights(result.uuid)}
          >
            - {t('labels.hideHighlights')}
          </LinkButton>
        )}
        {showHighlights && (
          <ul className="highlights">
            {result.highlights && result.highlights.map(highlight => <li key={highlight}>{highlight}</li>)}
          </ul>
        )}
      </div>
    </div>
  );
});

export default styled(SearchResultHotel)`
  position: relative;
  background-color: ${pureUiTheme.colorRoles.areaBackground};
  cursor: pointer;

  .details {
    padding: 1rem;
  }

  .hotelName {
    font-size: 20px;
  }

  .starRating {
    display: flex;
    align-items: center;
    color: ${pureUiTheme.colors.gold};
    font-family: 'HurmeGeometricSans2';
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    & > span {
      margin-right: 10px;
    }
  }

  /* Split this out into it's own component? */
  .overview,
  .highlights {
    list-style: none;
    margin: 28px 0;
    padding: 0;
    column-count: 2;
    font-size: 12px;
    text-transform: uppercase;
    font-family: 'HurmeGeometricSans2';
    color: ${pureUiTheme.colors.black};
    & > li {
      margin-bottom: 10px;
    }
  }

  .badge.pref {
    padding: 0 10px;
    line-height: 35px;
    font-family: 'HurmeGeometricSans2';
    font-size: 14px;
    text-transform: uppercase;
    color: ${pureUiTheme.colors.white};
    text-align: center;

    position: absolute;
    top: 0;
    left: 22px;
    font-weight: 600;
    background-color: ${pureUiTheme.colors.gold};
  }

  .stack {
    position: absolute;
    bottom: 22px;
    left: 22px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.5rem;
  }

  .stackColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    & > * {
      margin-bottom: 5px;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  .tooltip-content {
    text-align: left;
    padding: 10px;
    font-size: 12px;
  }

  .tooltip-content p {
    font-weight: bold;
    margin: 5px 10px 5px 25px;
    line-height: 14px;
  }

  .tooltip-content ul {
    margin: 5px 10px 5px 25px;
    padding: 0;
    line-height: 20px;
  }

  .tooltip-content ul li {
    margin-bottom: 5px;
  }
`;
