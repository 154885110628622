import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cognitoFlowRequestAction } from 'store/modules/cognito/actions';
import { LoadingBar } from 'ui/NetworkStatusBar';

export const CognitoCallback = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(cognitoFlowRequestAction(history));
  }, []);

  return (
    <div className="mt-[200px]">
      <LoadingBar />
    </div>
  );
};
