import { saveAs } from 'file-saver';
import { call, takeLatest, debounce, select, put, delay } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  makeBackendApi,
  ITravelAgentResponse,
  ITravelAgent,
  ICsvResponse,
  IInternalUsersFilter,
  ENetworkRequestStatus,
  ETravelAgentStatus,
} from 'services/BackendApi';

import { IInternalUsersListPagination } from './model';

import {
  GET_REQUEST,
  SET_SEARCH_FILTER,
  SET_COUNTRY_CODE_FILTER,
  SET_PAGE,
  SET_PER_PAGE,
  getRequestAction,
  getSuccessAction,
  getFailureAction,
  SET_STATUS_FILTER,
  DELETE_REQUEST,
  deleteSuccessAction,
  deleteFailureAction,
  CSV_REQUEST,
  csvSuccessAction,
  csvFailureAction,
  SET_USER_ROLE_FILTER,
  setRequestAction,
  SET_SELECTED_SR,
  getRequestSelectedSRTAsAction,
  getSuccessSelectedSRTAsAction,
  getFailureSelectedSRTAsAction,
  checkAllSRTAsAction,
  SET_TA_FILTER_NAME,
  SET_TA_FILTER_COUNTRY,
  POST_REQUEST_SELECTED_SR_TAS,
  postSuccessSelectedSRTAsAction,
  postFailureSelectedSRTAsAction,
  RESET_IS_ASSIGNED_TAB,
  SET_IS_ASSIGNED_TAB,
  unCheckAllSRTAsAction,
  CONFIRM_CHANGES,
  resetIsAssignedTabAction,
  setIsAssignedTabAction,
  closeConfirmationModalAction,
  getIdleSelectedSRTAsAction,
  resetSelectedSRAction,
  SET_EMAIL_FILTER,
} from './actions';

import * as Selectors from './selectors';

import { enqueueNotification } from '../ui';

function* successNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'success' } }));
}

function* errorNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'error' } }));
}

export function* getRequestSaga() {
  const backendApi = makeBackendApi();

  const filter: IInternalUsersFilter = yield select(Selectors.filterSelector);
  const pagination: IInternalUsersListPagination = yield select(Selectors.paginationSelector);

  yield put(setRequestAction(ENetworkRequestStatus.PENDING));

  try {
    const res: AxiosResponse<ITravelAgentResponse> = yield call(backendApi.getInternalUsersList, filter, {
      offset: pagination.page * pagination.perPage,
      limit: pagination.perPage,
    });

    yield put(getSuccessAction(res.data.data, res.data.meta.total));
  } catch (e) {
    yield put(getFailureAction(e.message));
  }
}

export function* getSRAssignedTAsSaga() {
  const backendApi = makeBackendApi();
  const filter = yield select(Selectors.filterRequestTAsSelector);
  const isAssigned = yield select(Selectors.isAssignedTabTASelector);

  if (isAssigned) {
    if (filter.search && filter.search?.length < 3) {
      return undefined;
    }
  }
  else {
    if ((filter.search === undefined || filter.search?.length < 3) && (filter.countryCode === undefined || filter.countryCode === null)) {
      yield put(getIdleSelectedSRTAsAction());
      return undefined;
    }
  }

  yield put(getRequestSelectedSRTAsAction());

  try {
    const res: AxiosResponse<ITravelAgentResponse> = yield call(
      backendApi.getTravelAgentList,
      { ...filter, status: ETravelAgentStatus.VERIFIED },
      undefined,
      undefined,
      { field: 'createdAt', order: 'desc' },
      isAssigned
    );
    yield put(getSuccessSelectedSRTAsAction(res.data.data));

    if (isAssigned) {
      yield put(checkAllSRTAsAction());
    }
    else {
      yield put(unCheckAllSRTAsAction());
    }
  } catch (e) {
    yield put(getFailureSelectedSRTAsAction(e.message));
    yield errorNotification(e.message);
  }
}

export function* postSRAssignedTAsSaga() {
  const backendApi = makeBackendApi();
  const data = yield select(Selectors.selectedTAsSelector);
  const selectedRS = yield select(Selectors.selectedRSSelector);
  const isAssigned = yield select(Selectors.isAssignedTabTASelector);


  try {
    const res: AxiosResponse<ITravelAgentResponse> = yield call(
      backendApi.updateUserAssignments,
      selectedRS.uuid,
      data,
      true,
      isAssigned
    );

    yield put(postSuccessSelectedSRTAsAction());
    yield put(
      enqueueNotification({
        message: 'Assignment list updated successfully.',
        options: { variant: 'success' },
      })
    );
    yield put(closeConfirmationModalAction());
    yield put(getRequestAction());
  } catch (e) {
    yield put(postFailureSelectedSRTAsAction(e.message));
    yield errorNotification(e.message);
  }
}

export function* deleteRequestSaga() {
  // TODO
  const backendApi = makeBackendApi();

  const toDelete: ITravelAgent = yield select(Selectors.toDeleteSelector);

  try {
    yield call(backendApi.deleteUser, toDelete.uuid);

    yield put(deleteSuccessAction());
    yield successNotification('Travel Agent deleted.');

    yield put(getRequestAction());
  } catch (e) {
    yield put(deleteFailureAction(e.message));
    yield errorNotification('Failed to delete Travel Agent.');
  }
}

export function* csvRequestSaga() {
  const backendApi = makeBackendApi();
  const f: IInternalUsersFilter = yield select(Selectors.filterSelector);

  try {
    const res: AxiosResponse<ICsvResponse> = yield call(backendApi.getInternalUsersCsv, f);
    saveAs(res.data.data.signedUrl);

    yield put(csvSuccessAction());
    yield successNotification('CSV created and downloaded.');
  } catch (e) {
    yield put(csvFailureAction(e.message));
    yield errorNotification('Failed to download CSV.');
  }
}

export function* confirmChangesSaga() {
  const isAssigned = yield select(Selectors.isAssignedTabTASelector);
  try {
    yield call(postSRAssignedTAsSaga);
    if (isAssigned) {
      yield put(resetIsAssignedTabAction());
    } else {
      yield put(setIsAssignedTabAction());
    }
  } catch (error) {
    yield errorNotification(error.message);
  }
}

export function* submitTAsSaga() {
  yield call(postSRAssignedTAsSaga);
  yield put(resetSelectedSRAction());
}

export function* watchInternalUsersListSaga() {
  yield takeLatest([GET_REQUEST, SET_COUNTRY_CODE_FILTER, SET_STATUS_FILTER, SET_EMAIL_FILTER], getRequestSaga);

  yield takeLatest([CSV_REQUEST], csvRequestSaga);

  yield takeLatest([DELETE_REQUEST], deleteRequestSaga);

  yield debounce(200, [SET_USER_ROLE_FILTER, SET_SEARCH_FILTER, SET_PAGE, SET_PER_PAGE], getRequestSaga);
}

export function* getSelectedSRTAs() {
  yield takeLatest([SET_SELECTED_SR, SET_TA_FILTER_NAME, SET_TA_FILTER_COUNTRY, SET_IS_ASSIGNED_TAB, RESET_IS_ASSIGNED_TAB], getSRAssignedTAsSaga);
  yield takeLatest([POST_REQUEST_SELECTED_SR_TAS], submitTAsSaga);
  yield takeLatest([CONFIRM_CHANGES], confirmChangesSaga);

}
