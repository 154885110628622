import { createSelector } from 'reselect';
import { TCurrencyCode } from 'interfaces';
import { IDepositAccountDomain, ILedgerDomain, ISorting } from './model';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { getCurrencySymbol } from 'utils';

export const ledgerDomain = (state: any): ILedgerDomain => state.ledger;

export const ledgerSelectedCompanySelector = createSelector(ledgerDomain, domain => domain.selectedCompany);
export const ledgerDepositStatementTotalsErrorSelector = createSelector(ledgerDomain, domain => domain.depositTotalsError);
export const ledgerDepositStatementTotalsSelector = createSelector(ledgerDomain, domain => domain.depositTotals);
export const ledgerDepositTotalsLoadingSelector = createSelector(ledgerDomain, domain => domain.depositTotalsLoad);
export const ledgerBookingStatementTotalsError = createSelector(ledgerDomain, domain => domain.bookingTotalsError);
export const ledgerBookingStatementTotalsSelector = createSelector(ledgerDomain, domain => domain.bookingTotals);

export const ledgerTotalsLoading = createSelector(ledgerDomain, domain => ({
  booking: domain.bookingTotalsLoad,
  deposit: domain.depositTotalsLoad,
}));

export const ledgerBookingLedgerSelector = createSelector(ledgerDomain, domain => domain.bookingsByCurrency);

export const ledgerBookingLedgerCurrencySelector = createSelector(
  ledgerDomain,
  domain => domain.bookingsByCurrency.currency
);

export const ledgerBookingLedgerRowsSelector = createSelector(
  ledgerDomain,
  domain => domain.bookingsByCurrency.data?.rows || []
);

export const ledgerBookingLedgerSortSelector = createSelector(ledgerDomain, domain => ({
  field: domain.bookingsByCurrency.sortBy,
  order: domain.bookingsByCurrency.sortOrder,
}));

export const ledgerBookingLedgerFilterSelector = createSelector(
  ledgerDomain,
  domain => domain.bookingsByCurrency.filter
);

export const ledgerBookingLedgerPageSelector = createSelector(
  ledgerDomain,
  domain => domain.bookingsByCurrency.currentPage
);

export const ledgerBookingLedgerItemsPerPageSelector = createSelector(
  ledgerDomain,
  domain => domain.bookingsByCurrency.itemsPerPage
);

export const ledgerBookingLedgerPageCountSelector = createSelector(ledgerDomain, domain => {
  const itemsPerPage = domain.bookingsByCurrency.itemsPerPage;
  const totalResults = domain.bookingsByCurrency.data?.pagination.totalRows;

  return itemsPerPage && totalResults ? Math.ceil(totalResults / itemsPerPage) : 0;
});

export const ledgerBookingLedgerCsvExportSelector = createSelector(
  ledgerBookingLedgerSelector,
  domain => domain.csvExport
);

export const ledgerBookingLedgerCsvExportUuidSelector = createSelector(
  ledgerBookingLedgerCsvExportSelector,
  exp => exp.exportUuid
);

export const ledgerBookingLedgerCsvExportLoading = createSelector(
  ledgerBookingLedgerCsvExportSelector,
  exp => exp.request === ENetworkRequestStatus.PENDING || !!exp.exportUuid
);

export const ledgerBookingLedgerPdfSelector = createSelector(ledgerBookingLedgerSelector, domain => domain.pdf);

export const ledgerBookingLedgerPdfGenerationInProgress = createSelector(
  ledgerBookingLedgerPdfSelector,
  pdf => pdf.requests.generate === ENetworkRequestStatus.PENDING
);

export const ledgerBookingLedgerDownloadHistoryInProgress = createSelector(
  ledgerBookingLedgerPdfSelector,
  pdf => pdf.requests.downloadHistory === ENetworkRequestStatus.PENDING
);

export const ledgerBookingLedgerDownloadedPdfs = createSelector(
  ledgerBookingLedgerPdfSelector,
  pdf => pdf.downloadedPdfs
);

export const summaryCsvExport = createSelector(ledgerDomain, domain => domain.summaryCsvExport);

export const ledgerBookingLedgerCsvAggregateExportSelector = createSelector(
  summaryCsvExport,
  domain => domain.bookingLedger
);

export const ledgerBookingLedgerCsvAggregateExportUuidSelector = createSelector(
  ledgerBookingLedgerCsvAggregateExportSelector,
  exp => exp.exportUuid
);

export const ledgerBookingLedgerCsvAggregateExportLoading = createSelector(
  ledgerBookingLedgerCsvAggregateExportSelector,
  exp => exp.request === ENetworkRequestStatus.PENDING || !!exp.exportUuid
);

export const depositAccountSelector = createSelector(ledgerDomain, domain => domain.depositAccount);
export const depositAccountDataSelector = createSelector(
  depositAccountSelector,
  (domain: IDepositAccountDomain) => domain.data
);
export const depositAccountCurrencySelector = createSelector(
  depositAccountSelector,
  (domain: IDepositAccountDomain) => domain.currency
);
export const depositAccountCurrencySymbolSelector = createSelector(
  depositAccountSelector,
  (domain: IDepositAccountDomain) => getCurrencySymbol(domain.currency as TCurrencyCode)
);
export const depositAccountRequestsSelector = createSelector(
  depositAccountSelector,
  (domain: IDepositAccountDomain) => domain.requests
);
export const depositAccountPaginationSelector = createSelector(
  depositAccountSelector,
  (domain: IDepositAccountDomain) => domain.pagination
);
export const depositAccountSortingSelector = createSelector(
  depositAccountSelector,
  (domain: IDepositAccountDomain): ISorting => domain.sorting
);

export const depositFilterFromDateSelector = createSelector(ledgerDomain, domain => domain.depositFilterFromDate);
export const depositFilterToDateSelector = createSelector(ledgerDomain, domain => domain.depositFilterToDate);
export const depositFilterStatusSelector = createSelector(ledgerDomain, domain => domain.depositFilterStatus);
export const depositFilterBookingRefSelector = createSelector(
  ledgerDomain,
  domain => domain.depositFilterBookingRef
);
export const depositAccountFilterSelector = createSelector(
  ledgerDomain,
  domain => domain.depositAccount.filter
);
export const depositAccountCsvExportSelector = createSelector(depositAccountSelector, domain => domain.csvExport);

export const depositAccountCsvExportUuidSelector = createSelector(
  depositAccountCsvExportSelector,
  exp => exp.exportUuid
);

export const depositAccountCsvExportLoading = createSelector(
  depositAccountCsvExportSelector,
  exp => exp.request === ENetworkRequestStatus.PENDING || !!exp.exportUuid
);

export const depositAccountCsvAggregateExportSelector = createSelector(
  summaryCsvExport,
  domain => domain.depositAccount
);

export const depositAccountCsvAggregateExportUuidSelector = createSelector(
  depositAccountCsvAggregateExportSelector,
  exp => exp.exportUuid
);

export const depositAccountCsvAggregateExportLoading = createSelector(
  depositAccountCsvAggregateExportSelector,
  exp => exp.request === ENetworkRequestStatus.PENDING || !!exp.exportUuid
);

export const depositAccountPdfSelector = createSelector(depositAccountSelector, domain => domain.pdf);

export const depositAccountPdfGenerationInProgress = createSelector(
  depositAccountPdfSelector,
  pdf => pdf.requests.generate === ENetworkRequestStatus.PENDING
);

export const depositAccountDownloadHistoryInProgress = createSelector(
  depositAccountPdfSelector,
  pdf => pdf.requests.downloadHistory === ENetworkRequestStatus.PENDING
);

export const depositAccountDownloadedPdfs = createSelector(depositAccountPdfSelector, pdf => pdf.downloadedPdfs);

export const summaryPdfSelector = createSelector(ledgerDomain, domain => domain.summaryPdf);

export const summaryPdfGenerationInProgress = createSelector(
  summaryPdfSelector,
  pdf => pdf.requests.generate === ENetworkRequestStatus.PENDING
);

export const summaryDownloadHistoryInProgress = createSelector(
  summaryPdfSelector,
  pdf => pdf.requests.downloadHistory === ENetworkRequestStatus.PENDING
);

export const summaryDownloadedPdfs = createSelector(summaryPdfSelector, pdf => pdf.downloadedPdfs);

export const statementFilterFromDateSelector = createSelector(ledgerDomain, domain => domain.statementFilterFromDate);
export const statementFilterToDateSelector = createSelector(ledgerDomain, domain => domain.statementFilterToDate);
export const statementFilterTypesSelector = createSelector(ledgerDomain, domain => domain.statementFilterTypes);
export const statementFilterStatusSelector = createSelector(ledgerDomain, domain => domain.statementFilterStatus);
export const statementFilterBookingRefsSelector = createSelector(
  ledgerDomain,
  domain => domain.statementFilterBookingRefs
);

export const statementFilterAmountMinSelector = createSelector(ledgerDomain, domain => domain.statementFilterAmountMin);
export const statementFilterAmountMaxSelector = createSelector(ledgerDomain, domain => domain.statementFilterAmountMax);

export const bcfSelector = createSelector(ledgerDomain, domain => domain.balanceCarriedForward);
export const isBCFModalOpenSelector = createSelector(bcfSelector, domain => domain.isBCFModalOpen);
export const initialBCFRowExistsSelector = createSelector(bcfSelector, domain => domain.initialBCFRowExists);
export const firstRowDateSelector = createSelector(bcfSelector, domain => domain.firstRowDate);
export const bcfRequestsSelector = createSelector(bcfSelector, domain => domain.requests);
export const initialBCFRowLoadingSelector = createSelector(
  bcfRequestsSelector,
  domain => domain.initialBCFRowLoad === ENetworkRequestStatus.PENDING
);
export const bcfRowCreateSelector = createSelector(bcfRequestsSelector, domain => domain.bcfRowCreate);
export const bcfDataSelector = createSelector(bcfSelector, domain => domain.data);

export const depositAccountModalSelector = createSelector(depositAccountSelector, domain => domain.depositAccountModal);
export const depositAccountRowUuidToDeleteSelector = createSelector(
  depositAccountSelector,
  domain => domain.depositAccountRowUuidToDelete
);

export const bookingLedgerRequestSelector = createSelector(
  ledgerDomain,
  domain => domain.bookingsByCurrency.request
);

export const isDeleteDARModalOpenSelector = createSelector(
  depositAccountSelector,
  domain => domain.isDeleteDARModalOpen
);
