export const ProductTypes = Object.freeze({
  ACCOMMODATION: 'Accommodation',
  MEAL_PLAN: 'Meal Plan',
  TRANSFER: 'Transfer',
  GROUND_SERVICE: 'Ground Service',
  FINE: 'Fine',
  SUPPLEMENT: 'Supplement',
  BESPOKE: 'Bespoke',
});

export const BreakdownBlockTypes = Object.freeze({
  ACCOMMODATION: 'Accommodations',
  TRANSFER: 'Transfers',
  GROUND_SERVICE: 'Ground Services',
  ADDON: 'Addons',
});

export const BookingStatusTypes = Object.freeze({
  POTENTIAL: 'potential',
  REQUESTED: 'requested',
  CONFIRMED: 'confirmed',
  CANCELLED: 'cancelled',
  ENQUIRY: 'enquiry',
  COMPLETED: 'completed',
  DISCARDED: 'discarded',
});

export const AuthTypes = Object.freeze({
  TA: 'ta',
  SR: 'sr',
  ADMIN: 'admin',
  RL: 'rl',
  FINANCE: 'finance',
});

export const Occassions = Object.freeze({
  HONEYMOON: 'honeymoon',
  BIRTHDAY: 'birthday',
  ANNIVERSARY: 'anniversary',
  WEDDING: 'wedding',
});

export const UserStatusTypes = Object.freeze({
  PENDING: 'pending',
  REJECTED: 'rejected',
  ACCEPTED: 'accepted',
  VERIFIED: 'verified',
});
