import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const DownloadIcon: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors['brown-100'], width = 18, height = 19 }) => (
    <svg width={width} height={height} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 7.25L14.25 7.25L9 12.5L3.75 7.25L6.75 7.25L6.75 2.75L11.25 2.75V7.25ZM3.75 15.5L3.75 14L14.25 14V15.5L3.75 15.5Z"
        fill={fill}
      />
    </svg>
  )
);


export default DownloadIcon;
