import { IFinanceRow } from './FinanceResponse';
import { IBookingComment } from './BookingManagerComments';

export enum EPurchaseCostReviewStatus {
  INITIAL = 'initial',
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING_CANCELLATION = 'pending cancellation',
  CANCELLED = 'cancelled',
}

export interface IPurchaseCostReviewResponse {
  bookingUuid: string;
  version: number;
  createdAt: string;
  updatedAt: string;
  status: EPurchaseCostReviewStatus;
  financePurchaseRow?: IFinanceRow | null;
  comment?: IBookingComment | null;
}

export interface IPurchaseCostReviewPatchRequest {
  status: EPurchaseCostReviewStatus;
  financePurchaseRow?: IFinanceRow | null;
  comment?: Pick<IBookingComment, "text"> | null;
}
