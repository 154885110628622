import { useEffect, useRef } from 'react';

/**
 *
 * @param value any value to keep track of
 * @param setInitially if `true`, the initial value will be set to the ref. If not, the first render will be `undefined`
 * @returns
 */
export const usePrevious = <T>(value: T, setInitially?: boolean): T | undefined => {
  let ref;
  if (setInitially) {
    ref = useRef<T>(value);
  } else {
    ref = useRef<T>();
  }
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
