import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { makeBookingManagerApi } from 'services/BookingManagerApi';
import { ICompanyIncentiveResponse } from 'services/BookingManagerApi/types';
import { enqueueNotification } from 'store/modules/ui';
import { companyDataSelector } from 'store/modules/companyInfo/selectors';

import * as Actions from './actions';
import { incentiveSelector } from './selectors';

function *successNotification(message: string) {
  yield put(
    enqueueNotification({ message, options: { variant: 'success' }})
  );
}

function* errorNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'error' } }));
}

// eslint-disable-next-line no-unused-vars
export function* getIncentiveSaga(_action: Actions.FetchCompanyIncentivesRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const { uuid: companyUuid } = yield select(companyDataSelector);
    const result: AxiosResponse<ICompanyIncentiveResponse> = yield call(bookingManagerApi.getCompanyIncentives, companyUuid);
    yield put(Actions.fetchCompanyIncentivesSuccessAction(result.data));
  } catch (e) {
    yield put(Actions.fetchCompanyIncentivesFailureAction(e));
    if (e.response.status !== 404) {
      yield errorNotification('Error fetching Incentives.');
    } 
  }
}

// eslint-disable-next-line no-unused-vars
export function* upsertIncentiveSaga(action: Actions.UpsertCompanyIncentivesRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const { uuid: companyUuid } = yield select(companyDataSelector);
    const incentive = yield select(incentiveSelector);

    const result: AxiosResponse<void> = yield call(
      bookingManagerApi.upsertCompanyIncentives,
      companyUuid,
      incentive
    );

    if (result.status === 201) {
      yield put(Actions.upsertCompanyIncentivesSuccessAction());
      yield put(Actions.fetchCompanyIncentivesRequestAction());
      yield successNotification('Incentives has beed updated.');
    }
  } catch (e) {
    yield put(Actions.upsertCompanyIncentivesFailureAction(e));
    yield errorNotification('Error updating Incentives.');
  }
}

// eslint-disable-next-line no-unused-vars
export function* deleteIncentiveSaga(_action: Actions.FetchCompanyIncentivesRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const { uuid: companyUuid } = yield select(companyDataSelector);
    const result: AxiosResponse<ICompanyIncentiveResponse> = yield call(bookingManagerApi.deleteCompanyIncentives, companyUuid);
    if (result.status === 200) {
      yield put(Actions.deleteCompanyIncentivesSuccessAction());
      yield put(Actions.fetchCompanyIncentivesRequestAction());
      yield successNotification('Incentives has been disabled.');
    }
  } catch (e) {
    yield put(Actions.deleteCompanyIncentivesFailureAction(e));
    yield errorNotification('Error disabling Incentives.');
  }
}

export function* watchCompanyIncentives() {
  yield takeLatest(Actions.FETCH_COMPANY_INCENTIVES_REQUEST, getIncentiveSaga);
  yield takeLatest(Actions.UPSERT_COMPANY_INCENTIVE_REQUEST, upsertIncentiveSaga);
  yield takeLatest(Actions.DELETE_COMPANY_INCENTIVE_REQUEST, deleteIncentiveSaga);
}

