import { EDepositAccountRowType, EFinanceRowTypes } from 'services/BookingManagerApi';
import { IBookingLedgerRow } from './model';
import {
  adjustmentBalanceCarriedForwardTypes,
  initialBalanceCarriedForwardTypes,
} from 'ui/BalanceCarriedForwardModal/type_options';

export const initialDepositAccountRowTypes = [
  EDepositAccountRowType.Initial_Balance_Carried_Forward_Positive,
  EDepositAccountRowType.Initial_Balance_Carried_Forward_Negative,
];

export const adjustmentDepositAccountRowTypes = [
  EDepositAccountRowType.Adjustment_Balance_Carried_Forward_Positive,
  EDepositAccountRowType.Adjustment_Balance_Carried_Forward_Negative,
];

export const usdDepositAccountRowTypes = [
  EDepositAccountRowType.Transfer_Between_EUR_USD_Deposit_IN,
  EDepositAccountRowType.Transfer_Between_USD_EUR_Deposit_OUT,
];

export const eurDepositAccountRowTypes = [
  EDepositAccountRowType.Transfer_Between_EUR_USD_Deposit_IN,
  EDepositAccountRowType.Transfer_Between_USD_EUR_Deposit_OUT,
];

export const isDepositAccountRowPositive = (depositAccountRowType: EDepositAccountRowType) => {
  return [
    EDepositAccountRowType.Transfer_Out,
    EDepositAccountRowType.Initial_Balance_Carried_Forward_Positive,
    EDepositAccountRowType.Adjustment_Balance_Carried_Forward_Positive,
    EDepositAccountRowType.Finance_Adjustment_Positive,
    EDepositAccountRowType.Automatic_Credit_From_Booking_OUT,
    EDepositAccountRowType.Transfer_Between_Travel_Companies_OUT,
    EDepositAccountRowType.Transfer_Between_EUR_USD_Deposit_OUT,
    EDepositAccountRowType.Credit_Note_For_Travel_Partner_OUT,
  ].includes(depositAccountRowType);
};

export const isDepositAccountRowNegative = (depositAccountRowType: EDepositAccountRowType) => {
  return [
    EDepositAccountRowType.Transfer_In,
    EDepositAccountRowType.Initial_Balance_Carried_Forward_Negative,
    EDepositAccountRowType.Adjustment_Balance_Carried_Forward_Negative,
    EDepositAccountRowType.Finance_Adjustment_Negative,
    EDepositAccountRowType.Automatic_Deposit_Transfer_IN,
    EDepositAccountRowType.Automatic_Credit_From_Booking_IN,
    EDepositAccountRowType.Transfer_Between_Travel_Companies_IN,
    EDepositAccountRowType.Transfer_Between_USD_EUR_Deposit_IN,
    EDepositAccountRowType.Credit_Note_For_Travel_Partner_IN,
  ].includes(depositAccountRowType);
};

export const isDepositAccountRowInitial = (depositAccountRowType: EDepositAccountRowType) => {
  return initialDepositAccountRowTypes.includes(depositAccountRowType);
};

export const isDepositAccountRowAdjustment = (depositAccountRowType: EDepositAccountRowType) => {
  return adjustmentDepositAccountRowTypes.includes(depositAccountRowType);
};

export const isManualBookingLedgerRow = (row: IBookingLedgerRow) => {
  return [
    EFinanceRowTypes.Initial_Balance_Carried_Forward_Positive,
    EFinanceRowTypes.Initial_Balance_Carried_Forward_Negative,
    EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Positive,
    EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Negative,
  ].includes(row.type);
};

export const isAdjustmentType = (type: EFinanceRowTypes): boolean => {
  return adjustmentBalanceCarriedForwardTypes.map(obj => obj.value).includes(type);
};

export const isInitialType = (type: EFinanceRowTypes) => {
  return initialBalanceCarriedForwardTypes.map(obj => obj.value).includes(type);
};
