import React from 'react';

const Loading: React.FC = React.memo(() => {
  return (
    <div className="flex justify-center items-center" style={{ width: 'calc(100% - 30px)' }}>
      <i className="text-lg fas fa-circle-notch fa-spin text-gray-80"></i>
    </div>
  );
});

export default Loading;
