import React from 'react';

import { StandardModal } from 'pureUi/Modal';
import { Radio } from 'ui/Radio';
import FluidButton from 'ui/FluidButton';
import { EPdfLogo } from 'store/modules/bookingManager/subdomains/breakdown/model';
import { ModalV1 } from 'ui/ModalV1';

interface RenderQuoteModalProps {
  isQuoteDownloading?: boolean;
  downloadQuoteButtonTitle?: string;
  hasCompanyLogo?: boolean;
  selectedLogoOption: EPdfLogo | null;
  onDownloadQuote: () => void;
  onQuoteWithMarginOptionSelect: () => void;
  onQuoteWithoutMarginOptionWithLogoSelect: () => void;
  onQuoteWithoutMarginOptionWithoutLogoSelect: () => void;
  onClose: () => void;
}

export const QuoteMarginSelector = (props: RenderQuoteModalProps) => {
  const {
    isQuoteDownloading,
    downloadQuoteButtonTitle = 'Generate',
    hasCompanyLogo = false,
    selectedLogoOption,
    onClose,
    onDownloadQuote,
    onQuoteWithMarginOptionSelect,
    onQuoteWithoutMarginOptionWithLogoSelect,
    onQuoteWithoutMarginOptionWithoutLogoSelect,
  } = props;

  return (
    <ModalV1
      className="max-w-[500px]"
      title="Recipient"
      onClose={onClose}
    >
      <label className="block cursor-pointer mb-2" onClick={onQuoteWithMarginOptionSelect}>
        <Radio onClick={onQuoteWithMarginOptionSelect} checked={selectedLogoOption === EPdfLogo.MAIN_COMPANY_LOGO} />
        <span className="ml-2 mr-5">Travel Partner</span>
      </label>

      {hasCompanyLogo && (
        <label className="block cursor-pointer mb-6" onClick={onQuoteWithoutMarginOptionWithLogoSelect}>
          <Radio
            onClick={onQuoteWithoutMarginOptionWithLogoSelect}
            checked={selectedLogoOption === EPdfLogo.TA_LOGO}
          />
          <span className="ml-2 mr-5">Final Client (includes margin)</span>
        </label>
      )}

      {!hasCompanyLogo && (
        <label className="block cursor-pointer mb-6" onClick={onQuoteWithoutMarginOptionWithoutLogoSelect}>
          <Radio
            onClick={onQuoteWithoutMarginOptionWithoutLogoSelect}
            checked={selectedLogoOption === EPdfLogo.NO_LOGO}
          />
          <span className="ml-2 mr-5">Final Client (includes margin)</span>
        </label>
      )}

      <FluidButton
        type="primary"
        className="download hover:bg-bistre py-12px transition-all ease-in-out w-full"
        onClick={onDownloadQuote}
        isLoading={isQuoteDownloading}
        textClassName="font-hurmegeometric-sans font-semibold text-[14px] uppercase"
        border={false}
        rounded={false}
        heightLimited={false}
      >
        {downloadQuoteButtonTitle}
      </FluidButton>
    </ModalV1>
  );
};

export default QuoteMarginSelector;
