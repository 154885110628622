import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  IBespokeNegotiationResponse,
  makeBookingManagerApi,
} from 'services/BookingManagerApi';
import { enqueueNotification } from 'store/modules/ui';
import {
  getFailureAction,
  GetRequestAction,
  getSuccessAction,
  GET_REQUEST,
  updateFailureAction,
  UpdateRequestAction,
  updateSuccessAction,
  UPDATE_REQUEST
} from './actions';

import { bookingUuidSelector } from '../../selectors';
import { updatePayloadSelector } from './selectors';

function* successNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'success' } }));
}

function* errorNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'error' } }));
}

export function* getSaga(action: GetRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IBespokeNegotiationResponse> = yield call(
      bookingManagerApi.getBespokeNegotiation,
      bookingUuid
    );
    yield put(getSuccessAction(result.data.bespokeNegotiation));
  } catch (e) {
    yield put(getFailureAction(e.message));
    yield errorNotification('Failed to fetch bespoke negotiation');
  }
}

export function* updateSaga(action: UpdateRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);
    const payload = yield select(updatePayloadSelector);

    const result: AxiosResponse<IBespokeNegotiationResponse> = yield call(
      bookingManagerApi.updateBespokeNegotiation,
      bookingUuid,
      payload
    );
    yield put(updateSuccessAction(result.data.bespokeNegotiation));
  } catch (e) {
    yield put(updateFailureAction(e.message));
    yield errorNotification('Failed to update bespoke negotiation');
  }
}

export function* watchBookingManagerBespokeNegotiation() {
  yield takeLatest(GET_REQUEST, getSaga);
  yield takeLatest(UPDATE_REQUEST, updateSaga);
}
