import styled from 'styled-components';
import { pureUiTheme } from 'pureUi/pureUiTheme';

export const WarningBanner = styled.div`
    padding: 10px 20px;
    background-color: ${pureUiTheme.colorRoles.warningBackground};
    border: ${pureUiTheme.colorRoles.warningBorder} 1px solid;
    color: ${pureUiTheme.colorRoles.warningText};
`;

export const ErrorBanner = styled.div`
    padding: 10px 20px;
    background-color: ${pureUiTheme.colorRoles.errorBackground};
    border: ${pureUiTheme.colorRoles.errorBorder} 1px solid;
    color: ${pureUiTheme.colorRoles.errorText};
`;

export const InfoBanner = styled.div`
    padding: 10px 20px;
    background-color: ${pureUiTheme.colorRoles.informationBackground};
    border: ${pureUiTheme.colorRoles.informationBorder} 1px solid;
    color: ${pureUiTheme.colors.black};
`;