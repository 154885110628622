import { ENetworkRequestStatus } from 'services/BackendApi';
import { IGlobalStatementDomain } from './types';
import { exportCsvInitialState } from 'store/modules/exportCsv/model';

export const initialState: IGlobalStatementDomain = {
  tableData: {
    responseRows: [],
  },

  totals: [],

  sortBy: 'companyName',
  sortOrder: 'asc',
  pageNumber: 0,
  itemsPerPage: 10,
  totalResultCount: 0,

  filter: {},
  network: {
    tableDataLoad: ENetworkRequestStatus.IDLE,
    totalsLoad: ENetworkRequestStatus.IDLE,
  },
  ...exportCsvInitialState,
};
