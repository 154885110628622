import { IOwnershipTravelAgentMovements, IOwnershipPatchBody } from 'services/BookingManagerApi';
import { ISalesRepresentative, ENetworkRequestStatus } from 'services/BackendApi';

export interface IOwnershipSubdomain {
  assignedSalesRepresentatives: ISalesRepresentative[] | null;
  travelPartnerMovements: IOwnershipTravelAgentMovements[] | null;
  ownershipData: IOwnershipPatchBody | null;
  request: {
    getOwnership: ENetworkRequestStatus;
    patchOwnership: ENetworkRequestStatus;
    getAssignedSalesRepresentatives: ENetworkRequestStatus;
    getTravelPartnerMovements: ENetworkRequestStatus;
  };
}

export const initialState: IOwnershipSubdomain = {
  assignedSalesRepresentatives: null,
  travelPartnerMovements: null,
  ownershipData: null,
  request: {
    getOwnership: ENetworkRequestStatus.IDLE,
    patchOwnership: ENetworkRequestStatus.IDLE,
    getAssignedSalesRepresentatives: ENetworkRequestStatus.IDLE,
    getTravelPartnerMovements: ENetworkRequestStatus.IDLE,
  },
};
