import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import PencilIcon from '../../../ui/Icons/pencil.component.svg';
import TrashIcon from '../../../ui/Icons/trash.component.svg';
import { SvgIcon } from '../../../ui/SvgIcon';
import { theme } from '../../../../tailwind.config';
import * as CompanyInfoActions from 'store/modules/companyInfo/actions';
import * as CompanyInfoSelectors from 'store/modules/companyInfo/selectors';
import { FilePicker } from 'ui/FilePicker';
import { StyledRadio } from 'pureUi/StyledRadio';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { LoadingBar } from 'ui/NetworkStatusBar';

export interface ICompanyLogoProps {
  companyUuid: string;
  isEditMode: boolean;
}

export const CompanyLogo = (props: ICompanyLogoProps) => {
  const { companyUuid, isEditMode } = props;

  const companyLogoInfo = useSelector(CompanyInfoSelectors.companyLogoInfoSelector);
  const companyLogoNetwork = useSelector(CompanyInfoSelectors.companyLogoNetworkSelector);
  const companyLogoPosition = useSelector(CompanyInfoSelectors.logoPositionSelector);

  const dispatch = useDispatch();

  const handleFileSelect = useCallback(
    (files: File[]) => {
      if (files.length > 0) {
        const logo = files[0];
        const formData = new FormData();
        formData.append('file', logo);
        formData.append('ownerType', 'Company');
        formData.append('ownerUuid', companyUuid);
        formData.append('tag', 'CompanyLogo');
        dispatch(CompanyInfoActions.updateCompanyLogoAction(formData));
      }
    },
    [companyUuid]
  );

  const onCompanyLogoEditButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      document.getElementById('company-logo-change')?.click();
    },
    [companyUuid]
  );

  const onCompanyLogoRemoveButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      dispatch(CompanyInfoActions.removeCompanyLogoAction(companyUuid));
    },
    [companyUuid]
  );

  useEffect(() => {
    dispatch(CompanyInfoActions.fetchCompanyLogoAction(companyUuid));
  }, [companyUuid]);

  return (
    <div className="company-logo w-full font-pt-sans">
      <p className="title text-black-true font-bold text-17px leading-md mb-0">Company Logo</p>
      <p className="description text-black text-15px leading-sm mt-5px mb-0">
        Optional company logo image for this company.
      </p>

      {companyLogoInfo === null && companyLogoNetwork.fetchLogo === ENetworkRequestStatus.PENDING && <LoadingBar />}

      {companyLogoInfo && (
        <div
          className={classnames('mt-5 bg-brown-10 border border-solid border-gray-40 w-full py-10 px-8', {
            'opacity-50 cursor-wait':
              companyLogoNetwork.deleteLogo === ENetworkRequestStatus.PENDING ||
              companyLogoNetwork.postLogo === ENetworkRequestStatus.PENDING,
          })}
        >
          <div className="flex flex-row items-center">
            <img
              style={{
                maxHeight: '200px',
              }}
              src={companyLogoInfo.url + '?isIcon=true'}
            />

            {isEditMode && (
              <div className="flex flex-col ml-8 text-black">
                <div className="flex flex-row items-center mb-5">
                  <input
                    id="company-logo-change"
                    type="file"
                    hidden={true}
                    onChange={e => {
                      if (e.currentTarget.files === null) {
                        return;
                      }
                      const files: File[] = [...e.currentTarget.files];
                      handleFileSelect(files);
                    }}
                    disabled={!isEditMode}
                  />
                  <button
                    onClick={onCompanyLogoEditButtonClick}
                    className="bg-transparent border-none p-0 cursor-pointer"
                  >
                    <SvgIcon
                      IconComponent={PencilIcon}
                      width="30px"
                      height="30px"
                      defaultFill={theme.colors['white-true']}
                      hoverFill={theme.colors['gray-30']}
                      activeFill={theme.colors['ivory']}
                    />
                  </button>

                  <button
                    onClick={onCompanyLogoRemoveButtonClick}
                    className="bg-transparent border-none p-0 ml-10px cursor-pointer"
                  >
                    <SvgIcon
                      IconComponent={TrashIcon}
                      width="30px"
                      height="30px"
                      defaultFill={theme.colors['white-true']}
                      hoverFill={theme.colors['gray-30']}
                      activeFill={theme.colors['ivory']}
                    />
                  </button>
                </div>

                <span className="mb-10px">Company logo position in proposal:</span>
                <div className="flex flex-row items-center">
                  <div className="flex">
                    <label className="flex flex-row items-center cursor-pointer">
                      <StyledRadio
                        className="company-logo-position-left"
                        type="radio"
                        checked={companyLogoPosition === 'left'}
                        onChange={() => {
                          dispatch(CompanyInfoActions.updateCompanyLogoPositionAction(companyUuid, 'left'));
                        }}
                      />
                      <span className="surrogate relative inline-block w-4 h-4 bg-ivory border border-solid border-gray-40 rounded-full" />
                      <span className="ml-2">Left</span>
                    </label>
                  </div>
                  <div className="flex ml-5">
                    <label className="flex flex-row items-center cursor-pointer">
                      <StyledRadio
                        className="company-logo-position-right"
                        type="radio"
                        checked={companyLogoPosition === 'right'}
                        onChange={() => {
                          dispatch(CompanyInfoActions.updateCompanyLogoPositionAction(companyUuid, 'right'));
                        }}
                      />
                      <span className="surrogate relative inline-block w-4 h-4 bg-ivory border border-solid border-gray-40 rounded-full" />
                      <span className="ml-2">Right</span>
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {companyLogoInfo == null && isEditMode && companyLogoNetwork.fetchLogo !== ENetworkRequestStatus.PENDING && (
        <>
          <div
            className={classnames({
              'opacity-50': companyLogoNetwork.postLogo === ENetworkRequestStatus.PENDING,
            })}
          >
            <FilePicker
              showAttachFileLabel={false}
              isAttachmentRequired={false}
              uploadName={undefined}
              onFileSelect={handleFileSelect}
              onFileRemove={() => {
                dispatch(CompanyInfoActions.removeCompanyLogoAction(companyUuid));
              }}
              isLoading={companyLogoNetwork.postLogo === ENetworkRequestStatus.PENDING}
              acceptString="image/*"
            />
          </div>
          <p className="description text-gray-80 text-15px leading-sm mt-5px mb-0">
            Files might be in JPG or PNG format. Recomended: Transparent PNG.
          </p>
        </>
      )}
    </div>
  );
};
