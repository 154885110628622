import { initialState } from './model';
import { IGlobalStatementDomain } from './types';
import * as Actions from './actions';
import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi';
import * as ExportCsvPollingReducerFunctions from 'store/modules/exportCsv/reducer';

export const globalStatementReducer = (
  state: IGlobalStatementDomain = initialState,
  action: Actions.GlobalStatementAction
): IGlobalStatementDomain => {
  switch (action.type) {
    case Actions.TOTALS_REQUEST:
      return produce(state, draftState => {
        draftState.network.totalsLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.TOTALS_SUCCESS:
      return produce(state, draftState => {
        draftState.totals = action.totals;
        draftState.network.totalsLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.TOTALS_FAILURE:
      return produce(state, draftState => {
        draftState.network.totalsLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.TABLE_DATA_REQUEST:
      return produce(state, draftState => {
        draftState.network.tableDataLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.TABLE_DATA_SUCCESS:
      return produce(state, draftState => {
        draftState.tableData = action.tableData;
        draftState.network.tableDataLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.TABLE_DATA_FAILURE:
      return produce(state, draftState => {
        draftState.network.tableDataLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_NETWORK_STATE:
      return produce(state, draftState => {
        draftState.network[action.networkItem] = action.state;
        return draftState;
      });

    case Actions.SET_PAGE_NUMBER:
      return produce(state, draftState => {
        draftState.pageNumber = action.pageNum;
        return draftState;
      });

    case Actions.SET_TOTAL_RESULT_COUNT:
      return produce(state, draftState => {
        draftState.totalResultCount = action.totalResultCount;
        return draftState;
      });

    case Actions.SET_ITEMS_PER_PAGE:
      return produce(state, draftState => {
        draftState.itemsPerPage = action.itemsPerPage;
        return draftState;
      });

    case Actions.SET_SORT_BY:
      return produce(state, draftState => {
        draftState.sortBy = action.sortBy;
        return draftState;
      });

    case Actions.SET_SORT_ORDER:
      return produce(state, draftState => {
        draftState.sortOrder = action.sortOrder;
        return draftState;
      });

    case Actions.SET_TP_COUNTRY:
      return produce(state, draftState => {
        draftState.filter.tpCountry = action.tpCountry;
        draftState.pageNumber = 0;
        return draftState;
      });
    case Actions.SET_TRAVEL_COMPANY:
      return produce(state, draftState => {
        draftState.filter.travelCompanyUuid = action.travelCompany;
        draftState.pageNumber = 0;
        return draftState;
      });
    case Actions.SET_AMOUNT_MIN:
      return produce(state, draftState => {
        draftState.filter.amountMinCents = action.amountMinCents;
        draftState.pageNumber = 0;
        return draftState;
      });
    case Actions.SET_AMOUNT_MAX:
      return produce(state, draftState => {
        draftState.filter.amountMaxCents = action.amountMaxCents;
        draftState.pageNumber = 0;
        return draftState;
      });
    case Actions.RESET_FILTERS:
      return produce(state, draftState => {
        draftState.filter = {
          ...initialState.filter,
        };
        draftState.pageNumber = 0;
        return draftState;
      });

    case Actions.TRIGGER_EXPORT_CSV_REQUEST:
      return ExportCsvPollingReducerFunctions.triggerRequest(state);

    case Actions.TRIGGER_EXPORT_CSV_SUCCESS:
      return ExportCsvPollingReducerFunctions.triggerSuccess(state, action.exportUuid);

    case Actions.TRIGGER_EXPORT_CSV_FAILURE:
      return ExportCsvPollingReducerFunctions.triggerFailure(state);

    case Actions.POLL_EXPORT_CSV_REQUEST:
      return ExportCsvPollingReducerFunctions.pollRequest(state);

    case Actions.POLL_EXPORT_CSV_SUCCESS:
      return ExportCsvPollingReducerFunctions.pollSuccess(state, action.signedUrl);

    case Actions.POLL_EXPORT_CSV_CONTINUE:
      return ExportCsvPollingReducerFunctions.pollContinue(state);

    case Actions.POLL_EXPORT_CSV_FAILURE:
      return ExportCsvPollingReducerFunctions.pollFailure(state);

    case Actions.POLL_EXPORT_CSV_CANCEL:
      return ExportCsvPollingReducerFunctions.pollCancel(state);

    default:
      return state;
  }
};
