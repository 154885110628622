import { BookingBuilderRequest } from 'services/BackendApi/types/OffersSearchResponse';
import { EUserType } from 'services/BackendApi/types/AuthResponse';

// TODO many of these can be decommissioned and use cases instead
// use the interfaces from src/services/BackendApi/types/OffersSearchResponse.ts
export interface BookingBuilderProduct {
  uuid: string;
  name: string;
  type: string;
  category: string;
}

export interface BookingBuilderAvailableSubProductSet {
  products: BookingBuilderProduct[];
  isOnRequestOrPartiallyOnRequest: boolean;
  total: string;
  totalBeforeDiscount: string;
  mandatory: boolean;
  breakdown: any[];
  selected: boolean;
}

export interface BookingBuilderAvailableProductSetsAccommodation {
  products: BookingBuilderProduct[];
  total: string;
  totalBeforeDiscount: string;
  mandatory: boolean;
  selected: boolean;
  isOnRequestOrPartiallyOnRequest: boolean;
  breakdown: any[];
  availableSubProductSets: {
    'Meal Plan': BookingBuilderAvailableSubProductSet[];
    Supplement: BookingBuilderAvailableSubProductSet[];
  };
}

export interface BookingBuilderAvailableProductSets {
  Accommodation: BookingBuilderAvailableProductSetsAccommodation[];
}

export interface RequestedBuildAccommodationSubProduct {
  uuid: string;
}

export interface RequestedBuildAccommodation {
  uuid: string;
  wedding: boolean;
  anniversary: boolean;
  birthday: boolean;
  honeymoon: boolean;
  repeatCustomer: boolean;
  subProducts: {
    'Meal Plan': RequestedBuildAccommodationSubProduct[];
  };
  startDate: string;
  endDate: string;
  guestAges: {
    numberOfAdults: number;
    agesOfAllChildren: number[];
  };
  availableToInstantBook: boolean;
}

export interface LodgingSummary extends RequestedBuildAccommodation {
  index: number;
  title: string;
  hotelUuid: string;
  nightsBreakdown: string;
  mealPlanBreakdown: string;
  occupancyBreakdown: string;
  occasionsBreakdown: string;
  liveRate?: {
    externalMealPlanCode: string;
    externalMealPlanDescription: string;
    externalRateId: string;
  };
}

export interface IValueLabelPair {
  value: string | null;
  label: string;
  disabled?: boolean;
}

export type IReduxDomainStatus = 'SUCCESS' | 'LOADING' | 'IDLE' | 'ERROR';

export interface IPureUiModalView {
  onClose: Function;
  className?: string;
  children: string | JSX.Element | JSX.Element[];
}

export interface IBookingInformation {
  guestTitle?: string;
  guestFirstName?: string;
  guestLastName?: string;
  isRepeatGuest?: boolean;
  flightArrivalNumber?: string;
  flightDepartureNumber?: string;
  flightArrivalDate?: string;
  flightDepartureDate?: string;
  taMarginType?: string;
  taMarginAmount?: string;
  specialRequests?: string[];
  comments?: string;
  proposalUuid?: string;
  travelAgentUserUuid?: string;
}

export interface IBookingAttributes {
  bookingHash: string;
  bookingBuild: BookingBuilderRequest;
  bookingInformation: IBookingInformation;
  status: string;
  placeHolds: boolean;
  proposalUuid?: string;
}

export interface IBookingGuestInformationFormValidation {
  guestTitle?: String[];
  guestFirstName?: String[];
  guestLastName?: String[];
  flightArrivalNumber?: String[];
  flightDepartureNumber?: String[];
  flightArrivalDate?: String[];
  flightDepartureDate?: String[];
  specialRequests?: String[];
  comments?: String[];
}

export interface IBookingGuestInformationForm {
  bookingGuestFormValues: IBookingInformation;
  onValueChange: Function;
  sections?: {
    guestInfo?: boolean;
    flightInfo?: boolean;
    specialRequests?: boolean;
    comments?: boolean;
  };
  validation?: IBookingGuestInformationFormValidation;
}

export interface IReviewBookingSchema {
  overrideTotal?: string;
  bookingComments?: string;
  internalComments?: string;
  status: string;
}

export interface IDateRange {
  startDate: string | undefined;
  endDate: string | undefined;
}

export type EBankAccount = string;

import * as currenciesRawData from './config/data/currencies.json';
export type TCurrenciesDefinition = typeof currenciesRawData;
export type TCurrencyCode = keyof TCurrenciesDefinition;

export interface ICurrencyDefinition {
  symbol: string;
  name: string;
  symbol_native: string;
  decimal_digits: number;
  rounding: number;
  code: TCurrencyCode;
  name_plural: string;
}

import countriesRawData from './config/data/countries.json';
export type TCountriesDefinition = typeof countriesRawData;
export type TCountryCode = keyof TCountriesDefinition;
export interface ICountryDefinition {
  code: TCurrencyCode;
  name: string;
  isDestination?: boolean;
}

export enum EInventoryReferenceL1 {
  RESERVATION = 'RESERVATION',
  CONTRACTING = 'CONTRACTING',
  FINANCES = 'FINANCES',
  OPERATIONS = 'OPERATIONS',
  USERS = 'USERS',
  ADMIN = 'ADMIN',
  BANKING = 'BANKING',
  BUSINESS = 'BUSINESS',
  MESSAGING = 'MESSAGING',
  CRM = 'CRM',
}

export enum EInventoryReferenceL2 {
  DASHBOARD = 'DASHBOARD',
  STAYS = 'STAYS',
  TRANSPORTATION = 'TRANSPORTATION',
  SERVICES = 'SERVICES',
  SEARCH = 'SEARCH',
  PROPOSALS = 'PROPOSALS',
  ENQUIRIES_AND_BOOKINGS = 'ENQUIRIES_AND_BOOKINGS',
  STATEMENTS = 'STATEMENTS',
  TRAVEL_COMPANIES = 'TRAVEL_COMPANIES',
  TRAVEL_AGENTS = 'TRAVEL_AGENTS',
  INTERNAL_USERS = 'INTERNAL_USERS',
  TASKS = 'TASKS',
  BASKET = 'BASKET',
  // TODO. Remove this part when real content for Level 3 is introduced.
  // As the menu is released, we only have 2 levels in hierarchy.
  // Use the option below to see how a menu with 3 levels is working.
  //DUMMYL2 = 'DUMMYL2',
}

export enum EInventoryReferenceL3 {
  HOTELS = 'HOTELS',
  RATE_BREAKS = 'RATE_BREAKS',
  OFFERS = 'OFFERS',
  LIVE_RATES = 'LIVE_RATES',
  TRANSFERS = 'TRANSFERS',
  GROUND_SERVICES = 'GROUND_SERVICES',
  INVENTORY_STAYS = 'INVENTORY_STAYS',
  INVENTORY_SUPPLIERS = 'INVENTORY_SUPPLIERS',
  ANCILLARY_SUPPLIERS = 'ANCILLARY_SUPPLIERS',
  ANCILLARY_ACTIVITIES = 'ANCILLARY_ACTIVITIES',
  ANCILLARY_TRANSFERS = 'ANCILLARY_TRANSFERS',
  ANCILLARY_GROUND_SERVICES = 'ANCILLARY_GROUND_SERVICES',
  // TODO. Remove this part when real content for Level 3 is introduced.
  // As the menu is released, we only have 2 levels in hierarchy.
  // Use the option below to see how a menu with 3 levels is working.
  //DUMMYL3 = 'DUMMYL3',
}

export type InventoryReference = EInventoryReferenceL1 | EInventoryReferenceL2 | EInventoryReferenceL3 | null;

export interface IOptionLabelPair {
  label: string;
  value: InventoryReference;
}

export interface IAuthorizable {
  allowedViewRoles: EUserType[];
}

export interface IAdressable {
  urlRedirect?: null | (() => string);
  urlGroup?: null | (() => string[]);
}

export interface IInventoryOption extends IOptionLabelPair, IAuthorizable, IAdressable {
  isExternal?: boolean;
  icon?: string; // reference to an existing class
  iconHtml?: JSX.Element; // the DOM object itself
  iconHtmlBig?: JSX.Element;
  iconHtmlHomepage?: JSX.Element;
  iconAlign?: 'left' | 'right';
  isDivider?: boolean;
  isHidden?: (userType?: EUserType) => boolean;
  options?: IInventoryOption[];
}

export interface IInventoryOptionBespoke extends IInventoryOption {
  path: InventoryReference[];
}

export enum BasketRateTypes {
  STATIC = 'static',
  LIVE = 'live',
  FREE_SALE = 'free_sale',
}
