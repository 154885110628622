import { IProposalsListItem } from 'services/BackendApi';
import { BookingSearchItem } from 'services/BookingManagerApi';
import { ChecklistAction } from './subdomains/checklist/actions';
import { DownloadsAction } from './subdomains/downloads/actions';
import { UploadsAction } from './subdomains/uploads/actions';
import { CommentsSubdomainAction } from './subdomains/comments/actions';
import { IBookingModel } from './sagas/bookingTransforms/BookingModel';
import { GuestInformationAction } from './subdomains/guestInformation/actions';
import { BreakdownAction } from './subdomains/breakdown/actions';
import { DashboardAction } from './subdomains/dashboard/actions';
import { BookingStatusOptionsSubdomainAction } from './subdomains/bookingStatusOptions/actions';
import { VoucherAction } from './subdomains/voucher/actions';
import { FinanceAction } from './subdomains/finance/actions';
import { TaLogoAction } from './subdomains/taLogo/actions';
import { HotelDetailsAction } from './subdomains/hotelDetails/actions';
import { OwnershipAction } from './subdomains/ownership/actions';
import { PurchaseCostReviewAction } from './subdomains/purchaseCostReview/actions';
import { SettingsAction } from './subdomains/bookingSettings/actions';
import { Any as BespokeNegotiationAction } from './subdomains/bespokeNegotiation/actions';
import { Any as ExternalIdsAction } from './subdomains/externalIds/actions';

export const GET_BOOKING_REQUEST = 'bookingManager/GET_BOOKING_REQUEST';
export const GET_BOOKING_SUCCESS = 'bookingManager/GET_BOOKING_SUCCESS';
export const GET_BOOKING_FAILURE = 'bookingManager/GET_BOOKING_FAILURE';

export const REQUEST_TO_BOOK_REQUEST = 'bookingManager/REQUEST_TO_BOOK_REQUEST';
export const REQUEST_TO_BOOK_SUCCESS = 'bookingManager/REQUEST_TO_BOOK_SUCCESS';
export const REQUEST_TO_BOOK_FAILURE = 'bookingManager/REQUEST_TO_BOOK_FAILURE';

export const CANCEL_REQUEST = 'bookingManager/CANCEL_REQUEST';
export const CANCEL_SUCCESS = 'bookingManager/CANCEL_SUCCESS';
export const CANCEL_FAILURE = 'bookingManager/CANCEL_FAILURE';

export const CONFIRM_REQUEST = 'bookingManager/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'bookingManager/CONFIRM_SUCCESS';
export const CONFIRM_FAILURE = 'bookingManager/CONFIRM_FAILURE';

// Proposals
export const SET_PRODUCT_BREAKDOWN_TABLE_EXPANDED_UUID = 'bookingManager/SET_PRODUCT_BREAKDOWN_TABLE_EXPANDED_UUID';
export const REMOVE_PRODUCT_BREAKDOWN_TABLE_EXPANDED_UUID =
  'bookingManager/REMOVE_PRODUCT_BREAKDOWN_TABLE_EXPANDED_UUID';

export const GET_AVAILABLE_PROPOSALS_REQUEST = 'bookingManager/GET_AVAILABLE_PROPOSALS_REQUEST';
export const GET_AVAILABLE_PROPOSALS_SUCCESS = 'bookingManager/GET_AVAILABLE_PROPOSALS_SUCCESS';
export const GET_AVAILABLE_PROPOSALS_FAILURE = 'bookingManager/GET_AVAILABLE_PROPOSALS_FAILURE';

export const SELECT_PROPOSAL_FROM_AVAILABLE_PROPOSALS = 'bookingManager/SELECT_PROPOSAL_FROM_AVAILABLE_PROPOSALS';

export type GetBookingRequestAction = ReturnType<typeof getBookingRequestAction>;
export const getBookingRequestAction = (uuid: string) => ({
  type: GET_BOOKING_REQUEST as typeof GET_BOOKING_REQUEST,
  uuid,
});

export type GetBookingSuccessAction = ReturnType<typeof getBookingSuccessAction>;
export const getBookingSuccessAction = (booking: BookingSearchItem) => ({
  type: GET_BOOKING_SUCCESS as typeof GET_BOOKING_SUCCESS,
  booking,
});

export type GetBookingFailureAction = ReturnType<typeof getBookingFailureAction>;
export const getBookingFailureAction = (error: string) => ({
  type: GET_BOOKING_FAILURE as typeof GET_BOOKING_FAILURE,
  error,
});

// Request to Book actions
export type RequestToBookRequestAction = ReturnType<typeof requestToBookRequestAction>;
export const requestToBookRequestAction = (booking: IBookingModel) => ({
  type: REQUEST_TO_BOOK_REQUEST as typeof REQUEST_TO_BOOK_REQUEST,
  booking,
});
export type RequestToBookSuccessAction = ReturnType<typeof requestToBookSuccessAction>;
export const requestToBookSuccessAction = (booking: IBookingModel) => ({
  type: REQUEST_TO_BOOK_SUCCESS as typeof REQUEST_TO_BOOK_SUCCESS,
  booking,
});
export type RequestToBookFailureAction = ReturnType<typeof requestToBookFailureAction>;
export const requestToBookFailureAction = error => ({
  type: REQUEST_TO_BOOK_FAILURE as typeof REQUEST_TO_BOOK_FAILURE,
  error,
});

// Cancel actions
export type CancelRequestAction = ReturnType<typeof cancelRequestAction>;
export const cancelRequestAction = (booking: IBookingModel) => ({
  type: CANCEL_REQUEST as typeof CANCEL_REQUEST,
  booking,
});
export type CancelSuccessAction = ReturnType<typeof cancelSuccessAction>;
export const cancelSuccessAction = (booking: IBookingModel) => ({
  type: CANCEL_SUCCESS as typeof CANCEL_SUCCESS,
  booking,
});
export type CancelFailureAction = ReturnType<typeof cancelFailureAction>;
export const cancelFailureAction = error => ({
  type: CANCEL_FAILURE as typeof CANCEL_FAILURE,
  error,
});

// confirm actions
export type ConfirmRequestAction = ReturnType<typeof confirmRequestAction>;
export const confirmRequestAction = (uuid: string) => ({
  type: CONFIRM_REQUEST as typeof CONFIRM_REQUEST,
  uuid,
});
export type ConfirmSuccessAction = ReturnType<typeof confirmSuccessAction>;
export const confirmSuccessAction = (booking: IBookingModel) => ({
  type: CONFIRM_SUCCESS as typeof CONFIRM_SUCCESS,
  booking,
});
export type ConfirmFailureAction = ReturnType<typeof confirmFailureAction>;
export const confirmFailureAction = error => ({
  type: CONFIRM_FAILURE as typeof CONFIRM_FAILURE,
  error,
});

// ui
export type SetProductBreakdownTableExpandedUuidAction = ReturnType<typeof setProductBreakdownTableExpandedUuidAction>;
export const setProductBreakdownTableExpandedUuidAction = (productType: string, uuid: string) => ({
  type: SET_PRODUCT_BREAKDOWN_TABLE_EXPANDED_UUID as typeof SET_PRODUCT_BREAKDOWN_TABLE_EXPANDED_UUID,
  productType,
  uuid,
});

export type RemoveProductBreakdownTableExpandedUuidAction = ReturnType<
  typeof removeProductBreakdownTableExpandedUuidAction
>;
export const removeProductBreakdownTableExpandedUuidAction = (productType: string, uuid: string) => ({
  type: REMOVE_PRODUCT_BREAKDOWN_TABLE_EXPANDED_UUID as typeof REMOVE_PRODUCT_BREAKDOWN_TABLE_EXPANDED_UUID,
  productType,
  uuid,
});

// proposal
export type GetAvailableProposalsRequestAction = ReturnType<typeof getAvailableProposalsRequestAction>;
export const getAvailableProposalsRequestAction = () => ({
  type: GET_AVAILABLE_PROPOSALS_REQUEST as typeof GET_AVAILABLE_PROPOSALS_REQUEST,
});

export type GetAvailableProposalsSuccessAction = ReturnType<typeof getAvailableProposalsSuccessAction>;
export const getAvailableProposalsSuccessAction = (availableProposals: IProposalsListItem[]) => ({
  type: GET_AVAILABLE_PROPOSALS_SUCCESS as typeof GET_AVAILABLE_PROPOSALS_SUCCESS,
  availableProposals,
});

export type GetAvailableProposalsFailureAction = ReturnType<typeof getAvailableProposalsFailureAction>;
export const getAvailableProposalsFailureAction = (error: string) => ({
  type: GET_AVAILABLE_PROPOSALS_FAILURE as typeof GET_AVAILABLE_PROPOSALS_FAILURE,
  error,
});

export type BookingManagerAction =
  | GetBookingRequestAction
  | GetBookingSuccessAction
  | GetBookingFailureAction
  | RequestToBookRequestAction
  | RequestToBookSuccessAction
  | RequestToBookFailureAction
  | CancelRequestAction
  | CancelSuccessAction
  | CancelFailureAction
  | ConfirmRequestAction
  | ConfirmSuccessAction
  | ConfirmFailureAction
  | SetProductBreakdownTableExpandedUuidAction
  | RemoveProductBreakdownTableExpandedUuidAction
  | ChecklistAction
  | CommentsSubdomainAction
  | DownloadsAction
  | UploadsAction
  | GetAvailableProposalsRequestAction
  | GetAvailableProposalsSuccessAction
  | GetAvailableProposalsFailureAction
  | GuestInformationAction
  | BreakdownAction
  | DashboardAction
  | BookingStatusOptionsSubdomainAction
  | VoucherAction
  | FinanceAction
  | TaLogoAction
  | HotelDetailsAction
  | OwnershipAction
  | PurchaseCostReviewAction
  | SettingsAction
  | BespokeNegotiationAction
  | ExternalIdsAction;
