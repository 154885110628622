import React from 'react';
import { Multiselect } from 'ui/Multiselect';
import { ELiveRatesExternalSystem } from 'services/BackendApi/types/LiveRatesInternal';

export interface iFilterProps {
  onChangeName: (name: string) => void;
  onChangeExternalSystem: (externalSystem: ELiveRatesExternalSystem | undefined) => void;
  nameFilterValue: string | undefined;
  externalSystemFilterValue: ELiveRatesExternalSystem | undefined;
  onNewMappingModalOpen: () => void;
}

export const Filter = (props: iFilterProps) => {
  const {
    onChangeName,
    onChangeExternalSystem,
    nameFilterValue,
    externalSystemFilterValue,
    onNewMappingModalOpen,
  } = props;

  return (
    <div>
      <span className="font-pt-sans font-bold text-17px leading-22px mb-10px">Filter Results</span>

      <div className="filters-controlers flex flex-row items-end justify-between">
        <div className="filters flex flex-row items-center space-x-4">
          <div>
            <span className="block font-pt-sans text-13px leading-16px mb-1">Stay</span>
            <input
              value={nameFilterValue || ''}
              onChange={e => {
                onChangeName(e.target.value);
              }}
              className="w-[300px] h-[37px] px-10px py-8px outline-none border border-solid border-gray-40 bg-ivory font-pt-sans text-15px text-black"
            />
          </div>
          <div>
            <span className="block font-pt-sans text-13px leading-16px mb-1">External Supplier</span>
            <Multiselect
              className="w-[300px] bg-ivory"
              itemsClassname="bg-ivory"
              itemCtaClassName="hover:bg-gray-10"
              hideCheckboxes={true}
              isIncludeClearButton={true}
              isCloseOnSelect={true}
              isSingleSelectMode={true}
              onUpdate={sv => {
                if (sv[0] !== undefined) {
                  onChangeExternalSystem(sv[0] as ELiveRatesExternalSystem);
                } else {
                  onChangeExternalSystem(undefined);
                }
              }}
              selectedValues={externalSystemFilterValue !== undefined ? [externalSystemFilterValue] : []}
              options={[
                {
                  value: ELiveRatesExternalSystem.DERBYSOFT,
                  label: 'DerbySoft',
                },
                {
                  value: ELiveRatesExternalSystem.ILLUSIONS,
                  label: 'Illusions',
                },
              ]}
            />
          </div>
        </div>

        <button
          className="w-[142px] block border border-solid border-brown-prime hover:border-brown-hard text-center cursor-pointer bg-brown-100 hover:bg-brown-hard font-bold font-pt-sans text-white text-16px leading-20px h-37px rounded"
          onClick={onNewMappingModalOpen}
        >
          + New Mapping
        </button>
      </div>
    </div>
  );
};
