import { call, takeLatest, select, put } from 'redux-saga/effects';
import { IGetExportResponse, IPostExportResponse, makeBookingManagerApi } from 'services/BookingManagerApi';
import { AxiosResponse } from 'axios';
import * as Actions from './actions';
import * as Selectors from './selectors';
import {
  IGlobalGrandTotalsFilter,
  IGlobalStatementQuery,
  IGlobalStatementTableDataResponse,
  IGlobalStatementTotalsRow,
} from './types';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { saveAs } from 'file-saver';
import { enqueueNotification } from 'store/modules/ui/actions';
import { formatDateTimeIncludeTimezoneDisplay } from 'utils';

import * as ExportCsvPollingSaga from 'store/modules/exportCsv/saga';
import { TCountryCode } from 'interfaces';

export function* getGlobalStatementTableData() {
  try {
    yield put(Actions.setNetworkStateAction('tableDataLoad', ENetworkRequestStatus.PENDING));
    const bookingManagerApi = makeBookingManagerApi();
    const query: IGlobalStatementQuery = yield select(Selectors.tableDataQuerySelector);
    const result: AxiosResponse<IGlobalStatementTableDataResponse> = yield call(
      bookingManagerApi.globalStatementTableData,
      query
    );
    yield put(
      Actions.tableDataSuccessAction({
        responseRows: result.data.rows,
      })
    );
    yield put(Actions.setTotalResultCountAction(result.data.pagination.totalRows));
  } catch (e) {
    yield put(Actions.tableDataFailureAction());
  }
}

export function* getTotalsData() {
  try {
    yield put(Actions.setNetworkStateAction('totalsLoad', ENetworkRequestStatus.PENDING));
    const bookingManagerApi = makeBookingManagerApi();
    const query: IGlobalStatementQuery = yield select(Selectors.tableDataQuerySelector);

    const { companyCountryCode, netBalanceCentsMin, netBalanceCentsMax } = query.filter;

    const grandTotalsFilter: IGlobalGrandTotalsFilter = {
      companyCountryCode: companyCountryCode as TCountryCode,
      netBalanceCentsMin,
      netBalanceCentsMax,
    };

    const result: AxiosResponse<IGlobalStatementTotalsRow[]> = yield call(
      bookingManagerApi.globalStatementTotalsData,
      grandTotalsFilter
    );
    yield put(Actions.totalsSuccessAction(result.data));
  } catch (e) {
    yield put(Actions.totalsFailureAction());
  }
}

export function* postTriggerExport() {
  const bookingManagerApi = makeBookingManagerApi();
  const query: IGlobalStatementQuery = yield select(Selectors.tableDataQuerySelector);

  yield ExportCsvPollingSaga.postTriggerExportCsv(
    bookingManagerApi.postGlobalStatementExportCsv,
    Actions.triggerExportCsvSuccessAction,
    Actions.triggerExportCsvFailureAction,
    query.filter
  );
}

export function* getPollExportCsv() {
  const bookingManagerApi = makeBookingManagerApi();
  const exportCsvNamespace = yield select(Selectors.exportCsvNamespaceSelector);

  yield ExportCsvPollingSaga.getPollExportCsv(
    bookingManagerApi.getGlobalStatementExportCsv,
    `Global Statement Export - ${formatDateTimeIncludeTimezoneDisplay(new Date())}`,
    exportCsvNamespace.exportUuid,
    Actions.pollExportCsvSuccessAction,
    Actions.pollExportCsvFailureAction,
    Actions.pollExportCsvContinueAction
  );
}

export function* watchGlobalStatementSaga() {
  yield takeLatest(
    [
      Actions.TABLE_DATA_REQUEST,
      Actions.SET_PAGE_NUMBER,
      Actions.SET_SORT_ORDER,
      Actions.SET_SORT_BY,
      Actions.SET_ITEMS_PER_PAGE,
      Actions.SET_AMOUNT_MIN,
      Actions.SET_AMOUNT_MAX,
      Actions.RESET_FILTERS,
      Actions.SET_TP_COUNTRY,
      Actions.SET_TRAVEL_COMPANY,
    ],
    getGlobalStatementTableData
  );

  yield takeLatest(
    [
      Actions.TOTALS_REQUEST,
      Actions.SET_AMOUNT_MIN,
      Actions.SET_AMOUNT_MAX,
      Actions.SET_TP_COUNTRY,
      Actions.RESET_FILTERS,
    ],
    getTotalsData
  );

  yield takeLatest([Actions.TRIGGER_EXPORT_CSV_REQUEST], postTriggerExport);
  yield takeLatest([Actions.POLL_EXPORT_CSV_REQUEST], getPollExportCsv);
}
