import React, { useMemo } from 'react';
import classNames from 'classnames';
import { IMultiselectValueLabelPair, Multiselect } from '../Multiselect';

interface IRowTypeSelectProps {
  selectedValue: string | null;
  isSelectedValuePositive: boolean;
  isSelectedValueNegative: boolean;
  options: IMultiselectValueLabelPair[];
  onSelect: (selectedValues: string[]) => void;
  className?: string;
  disabled?: boolean;
}

export const RowTypeSelect: React.FC<IRowTypeSelectProps> = React.memo(
  ({
    selectedValue,
    options,
    onSelect,
    isSelectedValuePositive,
    isSelectedValueNegative,
    className,
    disabled = false,
  }) => {
    const selectedValues = useMemo(() => (selectedValue ? [selectedValue] : []), [selectedValue]);
    const transactionLabelClassName = classNames('w-2/6 block ml-2 p-2 text-sm', {
      'bg-green-40': isSelectedValuePositive,
      'bg-red-40': isSelectedValueNegative,
    });
    let transactionLabel;
    if (isSelectedValuePositive) {
      transactionLabel = '+ Positive Transaction';
    }
    if (isSelectedValueNegative) {
      transactionLabel = '- Negative Transaction';
    }
    const typeClassName = 'block mb-1 text-black text-base font-bold leading-21px tracking-2xs font-pt-sans';

    return (
      <div className={classNames(className, 'row-type-select')}>
        <span className={typeClassName}>Type</span>
        <div className="flex flex-row mt-5px mb-5">
          <Multiselect
            className="w-4/6"
            isSingleSelectMode={true}
            hideCheckboxes={true}
            isCloseOnSelect={true}
            options={options}
            itemsToDisplayBeforeScroll={5}
            itemsClassname="bg-white"
            itemCtaClassName="hover:bg-teal-40"
            selectedValues={selectedValues}
            onUpdate={onSelect}
            disabled={disabled}
          />
          <span className={transactionLabelClassName}>{transactionLabel}</span>
        </div>
      </div>
    );
  }
);
