import React, { useCallback, useRef } from 'react';
import classnames from 'classnames';

import { HidingTooltip } from 'ui/Tooltip';
import { useIconColours } from 'hooks/useIconColours';

import { IIconProps } from './index';

interface ICircleIconButtonWithTooltipProps {
  link?: string;
  tooltipText: string;
  Icon: React.FC<IIconProps>;
  className?: string;
}

export const CircleIconButtonWithTooltip: React.FC<ICircleIconButtonWithTooltipProps> = React.memo(
  ({ link, Icon, tooltipText, className }) => {
    const ref = useRef<HTMLAnchorElement>(null);
    const renderTooltip = useCallback(
      () => (
        <div
          className="font-pt-sans text-13px leading-17px text-black"
          style={{ maxWidth: '170px', padding: '5px 7px' }}
        >
          {tooltipText}
        </div>
      ),
      [tooltipText]
    );

    const { background, border, icon } = useIconColours(ref, {
      disabled: !link,
      bg: 'white',
      bgHover: 'gray-30',
      bgDisabled: 'ivory',
      bgActive: 'ivory',
      border: 'brown-100',
      borderDisabled: 'light-gray',
      icon: 'brown-100',
      iconHover: 'brown-hard',
      iconDisabled: 'light-gray',
      iconActive: 'brown-100',
    });

    return (
      <HidingTooltip renderTooltipContent={renderTooltip} position="bottom" elementClassname="ml-1">
        <a
          href={link}
          className={classnames(className, { 'cursor-pointer': !!link })}
          target="_blank"
          rel="noopener noreferrer"
          ref={ref}
        >
          <Icon background={background} border={border} icon={icon} />
        </a>
      </HidingTooltip>
    );
  }
);
