import React from 'react';
import { StyledRadio } from 'pureUi/StyledRadio';
import classNames from 'classnames';

export interface IRadioProps {
  isDisabled?: boolean;
  checked: boolean;
  color?: string;
  onClick?: () => void;
  className?: string;
}

export const Radio = (props: IRadioProps) => {
  return (
    <div className="inline cursor-pointer">
      <StyledRadio
        disabled={props.isDisabled || false}
        type="radio"
        color={props.color}
        checked={props.checked}
        onChange={props.onClick}
      />
      <span
        style={{
          transform: 'translateY(2px)',
        }}
        className={classNames(
          `surrogate relative inline-block w-4 h-4 bg-ivory border border-solid border-gray-40 rounded-full`,
          props.className
        )}
      />
    </div>
  );
};
