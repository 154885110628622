import { AxiosResponse } from 'axios';
import { call, takeLatest, put, select } from 'redux-saga/effects';

import { makeBackendApi } from 'services/BackendApi';
import { ICompanyDocumentsResponse } from 'services/BackendApi/types/CompanyDocumentsResponse';
import { enqueueNotification } from 'store/modules/ui/actions';
import { companyDataSelector } from 'store/modules/companyInfo/selectors';

import * as Actions from './actions';
import {
  companyDocumentsSortFieldSelector,
  companyDocumentsSortOrderSelector,
  deleteCompanyDocumentSelector,
  newDocumentPayloadSelector,
} from './selectors';

// eslint-disable-next-line no-unused-vars
export function* fetchCompanyDocumentsSaga(action: Actions.FetchCompanyDocumentsRequestAction) {
  try {
    const backendApi = makeBackendApi();
    const { uuid: companyUuid } = yield select(companyDataSelector);
    const sortField = yield select(companyDocumentsSortFieldSelector);
    const sortOrder = yield select(companyDocumentsSortOrderSelector);

    const result: AxiosResponse<ICompanyDocumentsResponse> = yield call(
      backendApi.getCompanyDocumentsData,
      companyUuid,
      sortField,
      sortOrder
    );

    yield put(Actions.fetchCompanyDocumentsSuccessAction(result.data.data.documents));
  } catch (e) {
    yield put(Actions.fetchCompanyDocumentsFailureAction(e));
  }
}

// eslint-disable-next-line no-unused-vars
export function* postCompanyDocumentSaga(action: Actions.UploadCompanyDocumentRequestAction) {
  try {
    const backendApi = makeBackendApi();
    const payload = yield select(newDocumentPayloadSelector);
    const { uuid: companyUuid } = yield select(companyDataSelector);

    const formData = new FormData();
    formData.append('file', payload.file);
    formData.append('tag', payload.tag);
    formData.append('displayName', payload.displayName);
    formData.append('ownerType', 'Company');
    formData.append('ownerUuid', companyUuid);

    yield call(backendApi.postCompanyDocument, companyUuid, formData);
    yield put(Actions.uploadCompanyDocumentSuccessAction());
  } catch (e) {
    yield put(Actions.uploadCompanyDocumentFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: `There was an error uploading a file: ${e.message}`,
        options: { variant: 'error' },
      })
    );
  }
}

// eslint-disable-next-line no-unused-vars
export function* deleteCompanyDocumentSaga(action: Actions.DeleteCompanyDocumentRequestAction) {
  try {
    const backendApi = makeBackendApi();
    const { uuid: companyUuid } = yield select(companyDataSelector);
    const deleteCompanyFile = yield select(deleteCompanyDocumentSelector);

    yield call(backendApi.deleteCompanyDocument, companyUuid, deleteCompanyFile.uuid);
    yield put(Actions.deleteCompanyDocumentSuccessAction());
  } catch (e) {
    yield put(Actions.deleteCompanyDocumentFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: `There was an error uploading a company document: ${e.message}`,
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchCompanyDocumentsSaga() {
  yield takeLatest(
    [
      Actions.FETCH_COMPANY_DOCUMENTS_REQUEST,
      Actions.UPLOAD_COMPANY_DOCUMENT_SUCCESS,
      Actions.DELETE_COMPANY_DOCUMENT_SUCCESS,
      Actions.SET_COMPANY_DOCUMENTS_SORT_FIELD,
    ],
    fetchCompanyDocumentsSaga
  );

  yield takeLatest([Actions.UPLOAD_COMPANY_DOCUMENT_REQUEST], postCompanyDocumentSaga);
  yield takeLatest([Actions.DELETE_COMPANY_DOCUMENT_REQUEST], deleteCompanyDocumentSaga);
}
