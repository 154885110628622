import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const MapIcon: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors['brown-100'], background = 'transparent' }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ backgroundColor: background }}
    >
      <path
        d="M0.5 2.75L5.75 0.5L10.25 2.75L14.9772 0.72425C15.0343 0.699788 15.0966 0.689877 15.1584 0.695407C15.2202 0.700937 15.2797 0.721735 15.3316 0.755934C15.3834 0.790133 15.4259 0.836663 15.4553 0.891348C15.4847 0.946034 15.5001 1.00716 15.5 1.06925V13.25L10.25 15.5L5.75 13.25L1.02275 15.2758C0.965685 15.3002 0.90344 15.3101 0.841599 15.3046C0.779759 15.2991 0.720259 15.2783 0.66844 15.2441C0.616621 15.2099 0.574104 15.1633 0.544705 15.1087C0.515306 15.054 0.499945 14.9928 0.5 14.9307V2.75ZM11 13.5463L14 12.2607V2.77475L11 4.06025V13.5463ZM9.5 13.448V4.052L6.5 2.552V11.948L9.5 13.448ZM5 11.9398V2.45375L2 3.73925V13.2252L5 11.9398Z"
        fill={fill}
      />
    </svg>
  )
);

export default MapIcon;
