import { RefObject, useState, useLayoutEffect } from 'react';

export const useInitialDimensions = (elementRef: RefObject<HTMLElement>): DOMRect | undefined => {
  const [dimensions, setDimensions] = useState<DOMRect | undefined>(undefined);

  useLayoutEffect(() => {
    const dimensions = elementRef.current?.getBoundingClientRect();
    setDimensions(dimensions);
  }, [elementRef]);
  
  return dimensions;
};
