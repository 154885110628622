
import { call, takeLatest, select, put } from 'redux-saga/effects';
import { makeBackendApi } from 'services/BackendApi';
import { 
  CreateCompanyDepartmentRequestAction,
  closeCreateDepartmentModalAction, 
  createCompanyDepartmentFailureAction, 
  createCompanyDepartmentSuccessAction,
  fetchCompanyDepartmentsRequestAction,
} from '../actions';
import { CREATE_COMPANY_DEPARTMENT_REQUEST } from '../actions';
import { enqueueNotification } from 'store/modules/ui';
import { companyDataSelector } from 'store/modules/companyInfo';

function* createDepartmentSaga(action: CreateCompanyDepartmentRequestAction) {
  try {
    const backendApi = makeBackendApi();
    yield call(
      backendApi.postCompanyDepartment,
      action.companyUuid,
      action.departmentName,
      action.responsibleName,
      action.emails,
    );

    yield put(createCompanyDepartmentSuccessAction());

    const company = yield select(companyDataSelector);
    yield put(fetchCompanyDepartmentsRequestAction(company.uuid));
    yield put(closeCreateDepartmentModalAction());
    yield put(enqueueNotification({
      message: 'Company department added.',
      options: { variant: 'success' },
    }));
  } catch (e) {
    yield put(createCompanyDepartmentFailureAction(e));
    yield put(enqueueNotification({
      message: 'There was an error creating company department',
      options: { variant: 'error' },
    }));
  }
}

export function* watchCreateDepartmentSaga() {
  yield takeLatest(
    [CREATE_COMPANY_DEPARTMENT_REQUEST],
    createDepartmentSaga
  );
}
