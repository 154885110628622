import React, { useCallback } from 'react';
import { Link } from 'ui/Link';

import { HidingTooltip } from 'ui/Tooltip';
import RightIcon from 'ui/Icons/right.component.svg';
import { SvgIcon } from 'ui/SvgIcon';
import { theme } from '../../../tailwind.config';
import classNames from 'classnames';

interface IGoToDetailsProps {
  className?: string;
  link: string;
}

export const GoToDetails: React.FC<IGoToDetailsProps> = React.memo(({ className, link }) => {
  const renderTooltip = useCallback(
    () => (
      <div
        className="font-pt-sans text-13px leading-17px text-black z-10"
        style={{ padding: '5px 7px', width: '86px' }}
      >
        Go to Details
      </div>
    ),
    []
  );

  return (
    <HidingTooltip renderTooltipContent={renderTooltip} position="bottom">
      <div className={classNames(className, 'mx-auto')}>
        <Link className="go-to-details" to={link}>
          <span className="cursor-pointer">
            <SvgIcon
              IconComponent={RightIcon}
              defaultFill={theme.colors['white']}
              hoverFill={theme.colors['gray-30']}
              activeFill={theme.colors['ivory']}
            />
          </span>
        </Link>
      </div>
    </HidingTooltip>
  );
});
