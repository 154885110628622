import React from 'react';
import styled from 'styled-components';

export interface ISlideToggleProps {
  id: string;
  className?: string;
  iconClassName?: string; // used in conjunction with fontawesome
  onActivate: () => void;
  onDeactivate: () => void;
  checked: boolean;
  additionalClassName?: string;
  disableToggling?: boolean;
  labelClassName?: string;
  buttonClassName?: string;
  onClick?: () => void;
}

const SlideToggleComponent = (props: ISlideToggleProps) => {
  const disableToggling = props.disableToggling || false;

  const { labelClassName = '', buttonClassName = '', onClick=()=>{} } = props;

  return (
    <div onClick={onClick} className={`${props.className} ${props.additionalClassName || ''}`}>
      <input
        checked={props.checked}
        onChange={event => {
          if (disableToggling) {
            return;
          }
          if (event.target.checked) {
            props.onActivate();
          } else {
            props.onDeactivate();
          }
        }}
        className="react-switch-checkbox"
        id={props.id}
        type="checkbox"
      />
      <label className={`react-switch-label ${labelClassName}`} htmlFor={props.id}>
        <span className={`react-switch-button ${buttonClassName}`}>
          {props.iconClassName && <i className={`${props.iconClassName} fa-sm`}></i>}
        </span>
      </label>
    </div>
  );
};

export const SlideToggle = styled(SlideToggleComponent)`
  padding-left: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 48px;

  .react-switch-checkbox {
    position: absolute;
  }

  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 40px;
    height: 17px;
    border-radius: 100px;
    position: relative;
    transition: background-color 0.2s;
  }

  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: -4px;
    left: -2px;
    width: 26px;
    height: 25px;
    border-radius: 45px;
    transition: 0.2s;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }

  .react-switch-label .react-switch-button i {
    color: white;
    margin-left: 8px;
    margin-top: 7px;
    display: none;
  }

  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 20px);
  }

  .react-switch-checkbox:checked + .react-switch-label .react-switch-button i {
    display: inline-block;
  }
`;
