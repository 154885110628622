import { ITaskAPI, ITask } from "./types/TaskManagementResponse";

export const APITaskToFETask = (apiTask: ITaskAPI): ITask => ({
  id: apiTask.id,
  key: apiTask.key,
  subject: apiTask.subject,
  description: apiTask.description,
  status: apiTask.status,
  priority: apiTask.priority,
  startBy: apiTask.startBy,
  deadline: apiTask.deadline,
  category: apiTask.category,
  team: apiTask.team,
  assignee: apiTask.assignee,
  booking: apiTask.booking,
  reporter: apiTask.createdBy,
  createdAt: apiTask.createdAt,
  updatedAt: apiTask.updatedAt,
  updatedBy: apiTask.updatedBy,
  reminders: apiTask.reminders,
});
