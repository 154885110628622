import React, { useMemo } from 'react';
import classNames from 'classnames';
import { taskPriorities } from './common';
import { ETaskPriority } from 'services/TaskManagementApi/types/TaskManagementResponse';

export interface ITaskPriorityProps {
  className?: string;
  value: ETaskPriority;
}

export const TaskPriority: React.FC<ITaskPriorityProps> = (props) => {
  const status = useMemo(
    () => taskPriorities.find(x => x.value === props.value),
    [props.value]
  );

  return (
    <div className={classNames(props.className, 'flex items-center justify-between w-full')}>
      <span>{status?.label ?? ''}</span>
      <div className="indicator" style={{
        height: '14px',
        width: '14px',
        borderRadius: '50%',
        backgroundColor: status?.color || '#000'
      }}/>
    </div>
  )
};
