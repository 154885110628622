import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import {
  IBookingInformationReservationTeamData,
  ISpecialRequests,
  ITopNavigationData,
} from 'services/BookingManagerApi';
import { EBookingType } from 'services/BookingManagerApi/types/BookingTypeResponse';
import { ITask } from 'services/TaskManagementApi/types/TaskManagementResponse';

export interface IBookingManagerDashboardSubdomain {
  specialRequests: ISpecialRequests | null;
  topNavigationData: ITopNavigationData | null;
  bookingInformationReservationTeamData: IBookingInformationReservationTeamData | null;
  isSpecialRequestsEditedWithoutSaving: boolean;
  bookingType: EBookingType | null;
  bookingTasks: ITask[] | null;
  networkRequests: IBookingManagerDashboardSubdomainNetworkRequests;
}

export interface IBookingManagerDashboardSubdomainNetworkRequests {
  specialRequestsLoad: ENetworkRequestStatus;
  paymentTermsUpdate: ENetworkRequestStatus;
  cancellationPolicyUpdate: ENetworkRequestStatus;
  specialRequestsUpdate: ENetworkRequestStatus;
  topNavigationDataLoad: ENetworkRequestStatus;
  bookingTypeLoad: ENetworkRequestStatus;
  bookingTypeUpdate: ENetworkRequestStatus;
  topNavigationDataInlineLoad: ENetworkRequestStatus;
  bookingTasksLoad: ENetworkRequestStatus;
}

export const initialState: IBookingManagerDashboardSubdomain = {
  specialRequests: null,
  topNavigationData: null,
  bookingInformationReservationTeamData: null,
  isSpecialRequestsEditedWithoutSaving: false,
  bookingType: null,
  bookingTasks: null,
  networkRequests: {
    specialRequestsLoad: ENetworkRequestStatus.IDLE,
    topNavigationDataLoad: ENetworkRequestStatus.IDLE,
    paymentTermsUpdate: ENetworkRequestStatus.IDLE,
    cancellationPolicyUpdate: ENetworkRequestStatus.IDLE,
    specialRequestsUpdate: ENetworkRequestStatus.IDLE,
    bookingTypeLoad: ENetworkRequestStatus.IDLE,
    bookingTypeUpdate: ENetworkRequestStatus.IDLE,
    topNavigationDataInlineLoad: ENetworkRequestStatus.IDLE,
    bookingTasksLoad: ENetworkRequestStatus.IDLE,
  },
};
