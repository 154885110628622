import React from 'react';

interface IVerticalLineProps {
  height: string;
  color: string;
  borderWidth?: number;
}

export const VerticalLine: React.FC<IVerticalLineProps> = ({ height, color, borderWidth = 1 }) => {
  return (
    <div
      style={{ height, borderColor: color, borderWidth: `${borderWidth}px` }}
      className="border-l border-solid"
    ></div>
  );
};
