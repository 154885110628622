import React from 'react';
import { map } from 'ramda';
import hash from 'object-hash';

const materialIconFontsUrl = 'https://fonts.googleapis.com/icon?family=Material+Icons';

export const links = [
  {
    rel: 'stylesheet',
    href: materialIconFontsUrl,
  },
];

const renderLink = props => <link key={hash(props)} {...props} />;

const rendered = map(renderLink, links);

export default rendered;
