import { ENetworkRequestStatus, IUploadFileInfo } from 'services/BackendApi';

export interface ITaLogoSubdomain {
  taLogo: IUploadFileInfo | undefined;
  networkRequests: ITaLogoSubdomainNetworkRequests;
}

export interface ITaLogoSubdomainNetworkRequests {
  taLogo: ENetworkRequestStatus;
}

export const initialState: ITaLogoSubdomain = {
  taLogo: undefined,
  networkRequests: {
    taLogo: ENetworkRequestStatus.IDLE,
  },
};
