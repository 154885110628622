import { createSelector } from 'reselect';
import { IInventoryHeaderDomain } from './model';

export const domainSelector = (state: any): IInventoryHeaderDomain => state.inventoryHeader;

export const isMenuOpenSelector = createSelector(domainSelector, (domain: IInventoryHeaderDomain) => domain.isMenuOpen);

export const getCurrentPathOptionsSelector = createSelector(
  domainSelector,
  (domain: IInventoryHeaderDomain) => domain.currentPathOptions
);

export const getCurrentURLSelector = createSelector(
  domainSelector,
  (domain: IInventoryHeaderDomain) => domain.currentURL
);
export const getLastSetURLSelector = createSelector(
  domainSelector,
  (domain: IInventoryHeaderDomain) => domain.lastSetUrl
);

export const getHeaderBookingsSelector = createSelector(
  domainSelector,
  (domain: IInventoryHeaderDomain) => domain.bookings
);

export const loadingHeaderBookingsSelector = createSelector(
  domainSelector,
  (domain: IInventoryHeaderDomain) => domain.networkRequests.fetchingBookings,
);