export interface IWriteToSessionStorageOptions {
  overwrite?: boolean;
}

export const writeToSessionStorage = (key: string, value: string, options: IWriteToSessionStorageOptions = {}) => {
  const { overwrite = true } = options;

  if (!overwrite) {
    if (window.sessionStorage.getItem(key) !== null) {
      return;
    }
  }
  window.sessionStorage.setItem(key, value);
};

export const readFromSessionStorage = (key: string) => {
  return window.sessionStorage.getItem(key);
};
