import { takeLatest, select, put, call } from 'redux-saga/effects';
import * as CompanyInfoActions from '../actions';
import * as CompanyInfoSelectors from '../selectors';
import * as BootstrapSelectors from 'store/modules/bootstrap/selectors';
import * as BootstrapActions from 'store/modules/bootstrap/actions';
import { makeBackendApi } from 'services/BackendApi';
import { enqueueNotification } from '../../ui';
import { isTA } from 'store/modules/auth';

export function* getHoteListDataSaga(action: CompanyInfoActions.GetHotelListDataRequestAction) {
  try {
    const company = yield select(CompanyInfoSelectors.companyDataSelector);
    const isTaUser = yield select(isTA);

    if (company === null || isTaUser) {
      return;
    }

    const allHotels = yield select(BootstrapSelectors.getBootstrapHotelsSelector);

    const backendApi = makeBackendApi();

    const { uuid } = company;
    const result = yield call(backendApi.getHotelListData, uuid);

    yield put(CompanyInfoActions.getHotelListDataSuccessAction(allHotels, result.data));
  } catch (e) {
    yield put(CompanyInfoActions.getHotelListDataFailureAction());
    yield put(
      enqueueNotification({
        message: 'Error fetching company hotel list data',
        options: { variant: 'error' },
      })
    );
  }
}

export function* postHoteListDataSaga(action: CompanyInfoActions.PostHotelListDataRequestAction) {
  try {
    const backendApi = makeBackendApi();

    const disabledHotelUuids = yield select(CompanyInfoSelectors.hotelListDisabledHotelsSelector);
    const { uuid } = yield select(CompanyInfoSelectors.companyDataSelector);

    yield call(backendApi.postHotelListData, uuid, disabledHotelUuids);
    yield put(CompanyInfoActions.postHotelListDataSuccessAction());
    yield put(
      enqueueNotification({
        message: 'Hotel List has been updated successfully.',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(CompanyInfoActions.postHotelListDataFailureAction());
    yield put(
      enqueueNotification({
        message: 'Error updating company hotel list data',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchHotelListSaga() {
  yield takeLatest(
    // NOTE needs to fire on bootstrap success too, in case the user deep links to the hotel list page
    [CompanyInfoActions.GET_HOTEL_LIST_DATA_REQUEST, BootstrapActions.BOOTSTRAP_APP_SUCCESS],
    getHoteListDataSaga
  );
  yield takeLatest(CompanyInfoActions.POST_HOTEL_LIST_DATA_REQUEST, postHoteListDataSaga);
}
