import { createSelector } from 'reselect';
import { bookingConfirmationDomain } from '../../selectors';

export const paymentSubdomainSelector = createSelector(
  bookingConfirmationDomain,
  domain => domain.payment
);

export const paymentMethodSelector = createSelector(
  paymentSubdomainSelector,
  domain => domain.paymentMethod
);
