import { ENetworkRequestStatus, ISalesRepresentative } from 'services/BackendApi';

export interface ISRDomain {
  list: ISalesRepresentative[];
  request: {
    getList: ENetworkRequestStatus;
  }
};

export const initialState: ISRDomain = {
  list: [],
  request: {
    getList: ENetworkRequestStatus.IDLE,
  }
};
