import React from 'react';
import styled  from 'styled-components';

// z-index just needs to be bigger than header's
const GlobalBanner = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height:100px;
  text-align: center;
  background-color: #F3F3F3;
  color: #736A65;
  z-index: 11;
`;

export default GlobalBanner;
