import {
  EBespokeNegotiationAffectedItems,
  EBespokeNegotiationApplication,
  EBespokeNegotiationDiscountType,
  IBespokeNegotiationItem,
  IBespokeNegotiationLead
} from 'services/BookingManagerApi';
import { ENetworkRequestStatus } from 'services/BackendApi';

interface Editing {
  version?: string;
  lead: IBespokeNegotiationLead | null;
  discount: {
    type: EBespokeNegotiationDiscountType | null;
    amount: number | null;
  };
  appliedTo: EBespokeNegotiationApplication[];
  affectedItems: EBespokeNegotiationAffectedItems[];
}

export interface IBespokeNegotiationSubdomain {
  items: IBespokeNegotiationItem[] | null;
  editing: Editing | null;
  saveAsNew: boolean;
  request: {
    get: ENetworkRequestStatus;
    patch: ENetworkRequestStatus;
  };
}

export const initialState: IBespokeNegotiationSubdomain = {
  items: null,
  editing: null,
  saveAsNew: false,
  request: {
    get: ENetworkRequestStatus.IDLE,
    patch: ENetworkRequestStatus.IDLE,
  },
};

export const editingInitState = (): Editing => ({
  version: '1',
  lead: null,
  discount: {
    type: null,
    amount: null
  },
  appliedTo: [],
  affectedItems: []
});
