import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import {
  ENetworkRequestStatus,
  EUploadTag,
  IContentEntity,
  IUploadFileInfo,
  makeBackendApi,
} from 'services/BackendApi';
import { Link } from 'ui/Link';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { SimpleTabs } from 'ui/SimpleTabs';
import { ContentEntityDetailsTab } from './ContentEntityDetailsTab';
import produce from 'immer';
import { enqueueNotification } from 'store/modules/ui';
import { useModal } from 'hooks/useModal';
import FluidButton from 'ui/FluidButton';
import { UploadModal } from '../HotelAdmin/components/UploadModal';
import { Uploads } from 'containers/HotelAdmin/components/Uploads';

export const Edit = () => {
  const match = useRouteMatch<{ contentEntityUuid: string }>();
  const contentEntityUuid = match.params.contentEntityUuid;

  const backendApi = makeBackendApi();
  const dispatch = useDispatch();

  const [contentEntity, setContentEntity] = useState<IContentEntity | null>(null);
  const [draftContentEntity, setDraftContentEntity] = useState<IContentEntity | null>(null);

  const [contentEntityRequest, setContentEntityRequest] = useState<ENetworkRequestStatus>(ENetworkRequestStatus.IDLE);

  const uploadModalData = useModal();

  const updateDraftHotelValue = (field, value) => {
    const newContentEntity = produce(draftContentEntity!, _draftContentEntity => {
      _draftContentEntity[field] = value;
    });
    setDraftContentEntity(newContentEntity);
  };

  const retrieveContentEntity = async () => {
    try {
      const res = await backendApi.contentAdminGetContentEntity(contentEntityUuid);
      setContentEntity({
        ...res.data.data,
      });
      setDraftContentEntity({
        ...res.data.data,
      });
    } catch (error) {
      console.error('error', error);
    }
  };

  const patchContentEntity = async (updatedData: Partial<IContentEntity>) => {
    setContentEntityRequest(ENetworkRequestStatus.PENDING);
    try {
      await backendApi.contentAdminPatchContentEntity(contentEntity!.uuid, updatedData);
      setContentEntityRequest(ENetworkRequestStatus.SUCCESS);
      dispatch(
        enqueueNotification({
          message: `Content updated successfully`,
          options: { variant: 'success' },
        })
      );
    } catch (error) {
      console.error('error', error);
      setContentEntityRequest(ENetworkRequestStatus.ERROR);
    }
  };

  const handleSetFeaturedPhoto = async (featuredPhoto: IUploadFileInfo) => {
    setContentEntityRequest(ENetworkRequestStatus.PENDING);
    try {
      await backendApi.hotelAdminSetFeaturedPhoto(featuredPhoto);
      await retrieveContentEntity();
      setContentEntityRequest(ENetworkRequestStatus.SUCCESS);
      dispatch(
        enqueueNotification({
          message: `Featured Photo updated successfully`,
          options: { variant: 'success' },
        })
      );
    } catch (error) {
      console.error(error);
      setContentEntityRequest(ENetworkRequestStatus.ERROR);
    }
  };

  const handleDeleteUpload = async (upload: IUploadFileInfo) => {
    setContentEntityRequest(ENetworkRequestStatus.PENDING);

    try {
      await backendApi.deleteUpload(upload.uuid);
      await retrieveContentEntity();
      setContentEntityRequest(ENetworkRequestStatus.SUCCESS);
      dispatch(
        enqueueNotification({
          message: `Image deleted successfully`,
          options: { variant: 'success' },
        })
      );
    } catch (error) {
      console.error(error);
      setContentEntityRequest(ENetworkRequestStatus.ERROR);
      dispatch(
        enqueueNotification({
          message: `Failed to delete image`,
          options: { variant: 'error' },
        })
      );
    }
  };

  useEffect(() => {
    retrieveContentEntity();
  }, [contentEntityUuid]);

  if (!contentEntity || !draftContentEntity) {
    return (
      <div className="container w-1280px mx-auto">
        <LoadingBar />
      </div>
    );
  }

  return (
    <div className="container w-1280px mx-auto">
      <Link className="font-pt-sans underline hover:decoration-brown-prime" to="/content-admin">
        Back to Contents
      </Link>
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">
        Content - <span className="text-[26px]">Editing "{contentEntity.title}"</span>
      </h1>

      <SimpleTabs
        className={classNames({
          'pointer-events-none opacity-50': contentEntityRequest === ENetworkRequestStatus.PENDING,
        })}
        tabConfig={[
          {
            title: 'Content Details',
            name: 'content-details',
            styles: 'w-[350px]',
            renderContent: () => (
              <ContentEntityDetailsTab
                contentEntity={draftContentEntity}
                onUpdate={(field, val) => {
                  updateDraftHotelValue(field, val);
                }}
                onCta={async () => {
                  await patchContentEntity(draftContentEntity);
                }}
                ctaLabel="Update Content Entity"
                mode="edit"
              />
            ),
          },
          {
            title: 'Images',
            name: 'images',
            styles: 'w-[350px]',
            renderContent: () => (
              <div className="w-full flex flex-col space-y-4">
                <Uploads
                  className="o:grid-cols-3"
                  uploads={contentEntity.uploads!}
                  setFeaturedPhoto={handleSetFeaturedPhoto}
                  deleteUpload={handleDeleteUpload}
                />
                <FluidButton
                  type="secondary"
                  className="w-[200px] !mt-4 self-start"
                  onClick={async () => {
                    const modalResult = await uploadModalData.openModal();
                    // then upload the file
                    if (modalResult) {
                      const formData = new FormData();
                      formData.append('file', modalResult.file);
                      formData.append('tag', 'photo');
                      formData.append('displayName', modalResult.name);

                      formData.append('ownerUuid', contentEntity.uuid);
                      formData.append('ownerType', 'Content');

                      backendApi.uploadFile(formData).then(async res => {
                        await retrieveContentEntity();
                        dispatch(
                          enqueueNotification({
                            message: `Upload successful`,
                            options: { variant: 'success' },
                          })
                        );
                      });
                    }
                  }}
                >
                  Add Image
                </FluidButton>
              </div>
            ),
          },
        ]}
      />

      {uploadModalData.isOpen && (
        <UploadModal
          onConfirm={uploadModalData.handleConfirm}
          onClose={uploadModalData.handleCancel}
          tags={[
            { value: EUploadTag.PHOTO, label: 'Photo' },
            { value: EUploadTag.FEATURED_PHOTO, label: 'Featured Photo' },
          ]}
          acceptString="image/png, image/gif, image/jpeg"
        />
      )}
    </div>
  );
};
