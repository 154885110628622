import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { isNil } from 'lodash-es';

import { ENetworkRequestStatus, EUserType, ITravelAgent } from 'services/BackendApi';
import * as Actions from 'store/modules/travelAgentList/actions';
import * as Selectors from 'store/modules/travelAgentList/selectors';

import { LoadingBar, ErrorBar } from 'ui/NetworkStatusBar';
import { BackLink } from 'ui/BackLink';
import { Pagination } from 'pureUi/Pagination';

import { Filter } from './Filter';
import { Controls } from './Controls';
import { Table } from './Table';
import { getCurrentUserType } from 'store/modules/auth';
import { MassAssignModal } from './MassAssignModal';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { PageTitle } from 'ui/PageTitle';
import { DeleteTAModal } from './DeleteTAModal';

export const List: React.FC = () => {
  const request = useSelector(Selectors.requestSelector);
  const list = useSelector(Selectors.listSelector);
  const pagination = useSelector(Selectors.paginationSelector);
  const toDelete = useSelector(Selectors.toDeleteSelector);
  const taHasBookings = useSelector(Selectors.taHasBookingsSelector);
  const massAssignSrs = useSelector(Selectors.massAssignSrsSelector);
  const userType = useSelector(getCurrentUserType) as EUserType;
  const isMassAssignModalVisible = useSelector(Selectors.massAssignModalVisibleSelector);
  const isMassAssignConfirmModalVisible = useSelector(Selectors.massAssignConfirmModalVisibleSelector);
  const massAssignRequest = useSelector(Selectors.massAssignRequestSelector);

  const isInitialized = !isNil(pagination.total);
  const isLoading = request.get === ENetworkRequestStatus.PENDING;
  const isError = request.get === ENetworkRequestStatus.ERROR;

  const pageCount = useMemo(
    () => (!isNil(pagination.total) ? Math.ceil(pagination.total / pagination.perPage) : null),
    [pagination.total, pagination.perPage]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.getRequestAction(userType));
    dispatch(Actions.getSalesRepsRequestAction());
  }, [dispatch, userType]);

  const handleDelete = useCallback((x: ITravelAgent) => {
    dispatch(Actions.setToDeleteAction(x));
    dispatch(Actions.taHasBookingsRequestAction(x.uuid));
  }, [dispatch]);

  const handleDeleteConfirm = useCallback(() => dispatch(Actions.deleteRequestAction()), [dispatch]);

  const handleDeleteCancel = useCallback(() => dispatch(Actions.setToDeleteAction(null)), [dispatch]);

  const handlePageSelect = useCallback(page => dispatch(Actions.setPageAction(page - 1)), [dispatch]);

  const handlePerPageChange = useCallback(perPage => dispatch(Actions.setPerPageAction(perPage)), [dispatch]);

  const closeMassAssignModal = useCallback(() => {
    dispatch(Actions.setMassAssignModalVisibleAction(false));
  }, [dispatch]);

  const handleMassAssignCancel = useCallback(() => {
    dispatch(Actions.setMassAssignModalVisibleAction(false));
    dispatch(Actions.setMassAssignConfirmModalVisibleAction(false));
  }, [dispatch]);

  const handleMassAssignConfirm = useCallback(() => {
    dispatch(Actions.massAssignRequestAction());
  }, [massAssignSrs, dispatch]);

  return (
    <div className="travel-agent-list w-1280px mx-auto">
      <BackLink type="back">Back</BackLink>
      <PageTitle
        primaryText="Travel Agents"
        secondaryCondition={isInitialized && !isLoading}
        secondaryText={`(${getResultsText(pagination?.total ?? 0)})`}
        loadingCondition={isLoading}
        loadingText="Loading ..."
      />
      {isError && <ErrorBar />}
      {!isError && !isInitialized && <LoadingBar />}
      {!isError && isInitialized && (
        <>
          <div className="filter flex justify-between items-center mt-5">
            <Filter />
          </div>
          <div className="controls flex justify-between items-center mt-10">
            <Controls listLength={list ? list.length : 0} />
          </div>
          <div className="mt-5">
            <Table className="mt-20px" travelAgents={list || []} onDelete={handleDelete} loading={isLoading} />
          </div>
          {(list || []).length >= 1 && (
            <Pagination
              className="pagination mt-40px"
              onPageSelect={handlePageSelect}
              pageCount={pageCount || 0}
              currentPage={pagination.page + 1}
              itemsPerPage={pagination.perPage}
              onItemsPerPageChange={handlePerPageChange}
            />
          )}
        </>
      )}
      {!!toDelete && (
        <DeleteTAModal
          taHasBookings={taHasBookings}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          isContentLoading={request.taHasBookings === ENetworkRequestStatus.PENDING}
          isDeleteInProgress={request.delete === ENetworkRequestStatus.PENDING}
        />
      )}
      {isMassAssignModalVisible && <MassAssignModal onClose={closeMassAssignModal} />}
      {isMassAssignConfirmModalVisible && (
        <ConfirmationModal
          className="mass-assign-confirm-modal"
          type={EConfirmationModalType.WARNING}
          isOpen
          title={`You are going to assign the selected Sales Representatives to the ${pagination.total} Travel Agents in the list.`}
          message="Do you want to continue?"
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          onConfirm={handleMassAssignConfirm}
          isConfirmLoading={massAssignRequest === ENetworkRequestStatus.PENDING}
          onCancel={handleMassAssignCancel}
        />
      )}
    </div>
  );
};

const getResultsText = (count: number): string => {
  if (!count) {
    return 'no results found';
  }
  if (count === 1) {
    return '1 result found';
  }
  return `${count} results found`;
};
