import React from 'react';
import { useTranslation } from 'react-i18next';
import { map, partial, prop } from 'ramda';
import classNames from 'classnames';

import Checkbox from 'ui/Checkbox';

import { propTypes, defaultProps } from './OccasionsSelect.props';

const renderCheckbox = (t, { onChange, selected }, value) => (
  <label
    className={classNames(
      `occasions-select-checkbox-${value}`,
      'flex items-center cursor-pointer w-fit font-hurmegeometric-sans pr-2'
    )}
  >
    <Checkbox
      checked={prop(value, selected)}
      key={value}
      name={`occasions[${value}]`}
      onChange={() => onChange(value)}
      wrapperClassName="mr-2"
      checkedClassName="-top-2px"
      color="brown-prime"
      borderColor="brown-prime"
      borderWidth={2}
      left={'2px'}
      rounded
    />
    <span>{t(`labels.occasions.${value}`)}</span>
  </label>
);

export const OccasionsSelect = ({ occasions, ...props }) => {
  const { t } = useTranslation();
  return map(partial(renderCheckbox, [t, props]), occasions);
};

OccasionsSelect.propTypes = propTypes;
OccasionsSelect.defaultProps = defaultProps;

export default OccasionsSelect;
