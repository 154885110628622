import React, { useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { Formik, FormikProps, Field } from 'formik';

import { ECompanyType, EMarketingSource, ENetworkRequestStatus, ICompany } from 'services/BackendApi';
import FluidButton from 'ui/FluidButton';
import FormLabel from 'ui/FormLabel';
import { TextInput } from 'ui/TextInput';

import SingleSelect from 'ui/SingleSelect';
import { TravelCompanyFormValidationSchema } from './formValidation';
import { useDispatch, useSelector } from 'react-redux';
import { companyTypeOptions, countryOptions, marketingSourceOptions } from './helpers';
import {
  companyCreateNetworkSelector,
  companyUpdateNetworkSelector,
  createCompanyRequestAction,
  initCompanySaveStatusAction,
  updateCompanyRequestAction,
} from 'store/modules/companyInfo';
import { ICompanyLogoPosition } from 'store/modules/companyInfo/model';
import { useHistory } from 'react-router';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { DecimalInput } from 'ui/stateful/DecimalInput';
import classnames from 'classnames';
import { EUITextAreaResizeType, UITextArea } from 'ui/UITextArea';
import * as CompanyInfoSelectors from 'store/modules/companyInfo/selectors';

export interface ITravelCompanyFormProps {
  company?: ICompany;
  children?: React.ReactNode;
  isEditMode?: boolean;
}

export interface ITravelCompanyFormValues {
  uuid: string | null;
  companyId: string;
  companyName: string;
  countryCode: string | null;
  address: string;
  phone: string;
  website: string;
  marketingSource: EMarketingSource | null;
  salesRepresentative: string | null;
  companyType: ECompanyType | null;
  comment: string;
  logoPosition: ICompanyLogoPosition | null;
  serviceChargeOverride: string | null;
}

export const TravelCompanyForm: React.FC<ITravelCompanyFormProps> = ({ children, company, isEditMode }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const companyCreateNetwork = useSelector(companyCreateNetworkSelector);
  const companyUpdateNetwork = useSelector(companyUpdateNetworkSelector);

  const isSaving =
    companyCreateNetwork === ENetworkRequestStatus.PENDING || companyUpdateNetwork === ENetworkRequestStatus.PENDING;
  const isAlreadySaved = companyCreateNetwork === ENetworkRequestStatus.SUCCESS;

  useEffect(() => {
    dispatch(initCompanySaveStatusAction());
  }, []);

  const companyExternalMarkupPercentage = useSelector(CompanyInfoSelectors.companyExternalMarkupPercentageSelector);

  const initialValues: ITravelCompanyFormValues = {
    uuid: company?.uuid ?? null,
    companyId: company?.companyId ?? '',
    companyName: company?.name ?? '',
    countryCode: company?.countryCode ?? '',
    address: company?.address ?? '',
    phone: company?.phoneNumber ?? '',
    website: company?.website ?? '',
    marketingSource: company?.marketingSource ?? null,
    salesRepresentative: company?.salesRepresentative ?? '',
    companyType: company?.companyType ?? null,
    comment: company?.comment ?? '',
    logoPosition: company?.logoPosition ?? 'left',
    serviceChargeOverride: company?.serviceChargeOverride ?? null,
  };

  const handleCreateCompanyCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleFormSubmit = useCallback(
    (formValues: ITravelCompanyFormValues) => {
      const travelCompany: Partial<ICompany> = {
        companyId: formValues.companyId,
        name: formValues.companyName,
        countryCode: formValues.countryCode ?? '',
        address: formValues.address,
        phoneNumber: formValues.phone || null,
        website: formValues.website || null,
        marketingSource: formValues.marketingSource,
        companyType: formValues.companyType,
        comment: formValues.comment || null,
        salesRepresentative: formValues.salesRepresentative || null,
        logoPosition: formValues.logoPosition,
        serviceChargeOverride: formValues.serviceChargeOverride,
        externalMarkupPercentage: companyExternalMarkupPercentage ? companyExternalMarkupPercentage.toString() : null,
      };
      if (company) {
        travelCompany.uuid = company.uuid;
        dispatch(updateCompanyRequestAction(company.uuid, travelCompany));
      } else {
        dispatch(createCompanyRequestAction(travelCompany, history));
      }
    },
    [dispatch, company, history, companyExternalMarkupPercentage]
  );

  return (
    <div className="travel-company-form">
      <p className="mt-5 mb-0 font-pt-sans text-black-true font-bold text-17px leading-22px">Company Info</p>
      <Formik
        initialValues={initialValues}
        validationSchema={TravelCompanyFormValidationSchema}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {(form: FormikProps<ITravelCompanyFormValues>) => {
          return (
            <form onSubmit={form.handleSubmit}>
              <div className="travel-company-form">
                <div className="first-row flex gap-20px mt-5 max-w-1170px">
                  <Field
                    as={TextInput}
                    type="text"
                    name="companyId"
                    id="companyId"
                    className="companyId w-150px"
                    label="Company ID"
                    disabled={!isEditMode || !!company}
                    errorMessage={form.touched.companyId && form.errors.companyId ? form.errors.companyId : null}
                    errorClassName="companyId-error"
                  />

                  <Field
                    as={TextInput}
                    type="text"
                    name="companyName"
                    id="companyName"
                    className="companyName w-300px"
                    label="Company Name"
                    disabled={!isEditMode}
                    errorMessage={form.touched.companyName && form.errors.companyName ? form.errors.companyName : null}
                    errorClassName="companyName-error"
                  />

                  <Field name="countryCode">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="country"
                        label="Country"
                        className="country w-200px"
                        value={value}
                        options={countryOptions}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                        showSelectedOption
                        maxVisibleItems={10}
                        disabled={!isEditMode}
                        errorMessage={
                          form.touched.countryCode && form.errors.countryCode ? form.errors.countryCode : null
                        }
                        errorClassName="countryCode-error"
                      />
                    )}
                  </Field>

                  <Field
                    as={TextInput}
                    type="text"
                    name="address"
                    id="address"
                    className="address flex-1"
                    label="Address"
                    disabled={!isEditMode}
                    errorMessage={form.touched.address && form.errors.address ? form.errors.address : null}
                    errorClassName="address-error"
                  />
                </div>
                <div className="second-row flex gap-20px mt-5">
                  <Field
                    as={TextInput}
                    type="text"
                    name="phone"
                    id="phone"
                    className="phone w-200px"
                    label="Phone"
                    disabled={!isEditMode}
                    errorMessage={form.touched.phone && form.errors.phone ? form.errors.phone : null}
                    errorClassName="phone-error"
                  />

                  <Field
                    as={TextInput}
                    type="text"
                    name="website"
                    id="website"
                    className="website w-290px"
                    label="Website"
                    disabled={!isEditMode}
                    errorMessage={form.touched.website && form.errors.website ? form.errors.website : null}
                    errorClassName="website-error"
                  />

                  <Field name="marketingSource">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="marketingSource"
                        label="Marketing Source"
                        className="marketingSource w-200px"
                        value={value}
                        options={marketingSourceOptions}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                        showSelectedOption
                        maxVisibleItems={12}
                        labelWhenNothingSelected="Select ..."
                        disabled={!isEditMode}
                      />
                    )}
                  </Field>

                  <Field
                    as={TextInput}
                    type="text"
                    name="salesRepresentative"
                    id="salesRepresentative"
                    className="salesRepresentative w-200px"
                    label="Sales Representative"
                    disabled={!isEditMode}
                    errorMessage={
                      form.touched.salesRepresentative && form.errors.salesRepresentative
                        ? form.errors.salesRepresentative
                        : null
                    }
                    errorClassName="salesRepresentative-error"
                  />
                  <Field name="companyType">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="companyType"
                        label="Company Type"
                        className="companyType w-200px"
                        value={value}
                        options={companyTypeOptions}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                        showSelectedOption
                        maxVisibleItems={7}
                        disabled={!isEditMode}
                      />
                    )}
                  </Field>
                  <Field name="serviceChargeOverride">
                    {({ field: { name, value }, form: { setFieldValue } }) => {
                      return (
                        <div className="relative flex flex-col serviceChargeOverride w-200px">
                          <label className="text-black font-pt-sans text-13px leading-17px tracking-2xs">
                            Custom Service Charge
                          </label>
                          <DecimalInput
                            className={classnames(
                              'text-15px leading-19px py-7px px-10px mt-5px font-pt-sans outline-none text-black border border-solid border-gray-40 bg-ivory'
                            )}
                            value={value === null ? '' : value}
                            allowNull={true}
                            decimalPlaces={2}
                            onBlur={value => {
                              setFieldValue(name, value === null ? value : value.toString());
                            }}
                            disabled={!isEditMode}
                          />
                          <span className="absolute font-pt-sans top-29px right-10px text-gray-80 select-none pointer-events-none">
                            U$S/€
                          </span>
                        </div>
                      );
                    }}
                  </Field>
                </div>
                <div className="third-row flex flex-col mt-5">
                  <Field name="comment">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <FormLabel className="comment-label" text="Comments">
                        <UITextArea
                          className="comment"
                          value={value}
                          resizable={EUITextAreaResizeType.BOTH}
                          id="comment"
                          onChange={value => {
                            setFieldValue(name, value);
                          }}
                          disabled={!isEditMode}
                        />
                      </FormLabel>
                    )}
                  </Field>

                  {children ? children : null}

                  <div className="flex items-center mt-5">
                    {!form.values.uuid && (
                      <FluidButton
                        className="new-company-cancel-button mr-10px"
                        onClick={handleCreateCompanyCancel}
                        type="secondary"
                        disabled={isSaving || isAlreadySaved || !isEditMode}
                      >
                        Cancel
                      </FluidButton>
                    )}

                    {isEditMode && (
                      <FluidButton
                        type="primary"
                        className="new-company-confirm-button"
                        submit
                        isLoading={isSaving}
                        disabled={!form.isValid || isAlreadySaved}
                      >
                        Save
                      </FluidButton>
                    )}
                  </div>
                </div>
              </div>
              <LeaveWithoutSavingModal
                title={
                  'You have made changes to this page, and not saved. If you leave this page now, these changes will be lost.'
                }
                when={form.dirty && !isAlreadySaved && !isSaving}
                confirmButtonLabel="Yes"
                cancelButtonLabel="No"
              />
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
