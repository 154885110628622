import { createSelector } from 'reselect';
import { ICompanyInfo } from './model';
import { ENetworkRequestStatus, ICompany, IUploadFileInfo } from 'services/BackendApi';
import * as BootstrapSelectors from 'store/modules/bootstrap/selectors';

export const domainSelector = (state: any) => state.companyInfo;

export const companyLogoUrlSelector = createSelector(
  domainSelector,
  (domain: ICompanyInfo): string => domain.companyLogoInfo?.url || ''
);

export const companyLogoInfoSelector = createSelector(
  domainSelector,
  (domain: ICompanyInfo): IUploadFileInfo | null => domain.companyLogoInfo
);

export const canUploadLogoSelector = createSelector(
  domainSelector,
  (domain: ICompanyInfo): boolean => !domain.isProcessingRequest && !domain.error
);

export const logoPositionSelector = createSelector(
  domainSelector,
  (domain: ICompanyInfo): ICompanyInfo['companyLogoPosition'] => domain.companyLogoPosition
);

export const companyDataSelector = createSelector(
  domainSelector,
  (domain: ICompanyInfo): ICompany | null => domain.company
);

export const companyLoadSelector = createSelector(
  domainSelector,
  (domain: ICompanyInfo): ENetworkRequestStatus => domain.companyLoad
);

export const companyLogoNetworkSelector = createSelector(
  domainSelector,
  (domain: ICompanyInfo) => domain.companyLogoNetwork
);

export const hotelListDataSelector = createSelector(domainSelector, (domain: ICompanyInfo) => domain.hotelListData);

export const hotelListDataHotelsActivatedCountSelector = createSelector(
  domainSelector,
  (domain: ICompanyInfo) => domain.hotelListData.activeHotels.length
);

export const hotelListDestinationsSelector = createSelector(
  hotelListDataSelector,
  BootstrapSelectors.getBootstrapHotelsSelector,
  BootstrapSelectors.getBootstrapCountriesSelector,
  (hotelListData, hotels, countries) => {
    const destinations = countries.filter(c => c.isDestination);
    // work out how many hotels there are in total for each destination
    const hotelTotalCountPerDestination = {};
    destinations.forEach(d => {
      hotelTotalCountPerDestination[d.code] = hotels.filter(h => h.countryCode === d.code).length;
    });

    // now add up how many we have actually active for each destination
    const hotelActiveCountPerDestination = {};
    destinations.forEach(d => {
      hotelActiveCountPerDestination[d.code] = hotelListData.activeHotels.filter(h => h.countryCode === d.code).length;
    });

    // now return an object where if the totals match, true. else, false. for each destination.
    // also include an 'indeterminate' if SOME of them are checked but NOT ALL
    const returnObject = {};
    destinations.forEach(d => {
      returnObject[d.code] = {
        checked:
          hotelTotalCountPerDestination[d.code] === hotelActiveCountPerDestination[d.code] &&
          hotelTotalCountPerDestination[d.code] > 0,
        indeterminate:
          hotelListData.activeHotels.some(h => h.countryCode === d.code) &&
          hotelTotalCountPerDestination[d.code] !== hotelActiveCountPerDestination[d.code],
      };
    });

    return returnObject;
  }
);

export const hotelListDisabledHotelsSelector = createSelector(
  hotelListDataSelector,
  BootstrapSelectors.getBootstrapHotelsSelector,
  (hotelListData, allHotels) => {
    const disabledHotelUuids: string[] = [];

    const allHotelUuids = allHotels.map(h => h.uuid);
    const selectedHotelUuids = hotelListData.activeHotels.map(h => h.uuid);

    allHotelUuids.forEach(hotelUuid => {
      if (!selectedHotelUuids.includes(hotelUuid)) {
        disabledHotelUuids.push(hotelUuid);
      }
    });

    return disabledHotelUuids;
  }
);

export const hotelListDataNetworkSelector = createSelector(
  domainSelector,
  (domain: ICompanyInfo) => domain.hotelListDataNetwork
);

export const companyCreateNetworkSelector = createSelector(
  domainSelector,
  (domain: ICompanyInfo) => domain.companyNetwork.create
);

export const companyUpdateNetworkSelector = createSelector(
  domainSelector,
  (domain: ICompanyInfo) => domain.companyNetwork.update
);

export const travelCompanyEditModeSelector = createSelector(
  domainSelector,
  (domain: ICompanyInfo) => domain.isEditMode
);

export const companyExternalMarkupPercentageSelector = createSelector(
  domainSelector,
  (domain: ICompanyInfo) => domain.companyExternalMarkupPercentage
);

export const depositAccountBalanceLoadSelector = createSelector(
  domainSelector,
  (domain: ICompanyInfo) => domain.depositAccountBalanceLoad
);
