import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const FileIcon: React.FC<IIconProps> = React.memo(
  ({ background = 'white', border = 'brown-100', icon = 'brown-100' }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1C7.705 1 1 7.705 1 16C1 24.295 7.705 31 16 31C24.295 31 31 24.295 31 16C31 7.705 24.295 1 16 1Z"
        fill={theme.colors[background]}
        stroke={theme.colors[border]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.928 13.2688C21.8912 13.184 21.8455 13.1032 21.781 13.0344L17.281 8.2344C17.2165 8.1656 17.1407 8.1168 17.0612 8.0776C17.0388 8.0664 17.0148 8.06 16.9908 8.0512C16.9278 8.0288 16.8633 8.0144 16.7965 8.0104C16.78 8.0088 16.7657 8 16.75 8H11.5C10.6727 8 10 8.7176 10 9.6V22.4C10 23.2824 10.6727 24 11.5 24H20.5C21.3273 24 22 23.2824 22 22.4V13.6C22 13.5832 21.9917 13.568 21.9902 13.5504C21.9865 13.48 21.9738 13.4112 21.9528 13.344C21.9445 13.3184 21.9377 13.2936 21.928 13.2688ZM17.5 12.8V10.7312L19.4395 12.8H17.5ZM11.5 9.6H16V13.6C16 14.0424 16.3352 14.4 16.75 14.4H20.5L20.5015 22.4H11.5V9.6Z"
        fill={theme.colors[icon]}
      />
    </svg>
  )
);

export default FileIcon;
