import React, { FC, useCallback } from 'react';
import { EPurchaseCostReviewStatus } from 'services/BookingManagerApi/types/PurchaseCostReviewResponse';
import { PurchaseCostReviewStatus } from 'ui/PurchaseCostReviewStatus';
import { HidingTooltip } from 'ui/Tooltip';

const pcrLabels = {
  [EPurchaseCostReviewStatus.PENDING]: 'Reviewing',
  [EPurchaseCostReviewStatus.APPROVED]: 'Approved',
  [EPurchaseCostReviewStatus.REJECTED]: 'Rejected',
};

export const PurchaseCostReviewIndicator: FC<{ status: EPurchaseCostReviewStatus }> = ({ status }) => {
  const renderTooltip = useCallback(() => <div className="p-1">{pcrLabels[status]}</div>, [status]);

  return (
    <HidingTooltip renderTooltipContent={renderTooltip} position="bottom">
      <PurchaseCostReviewStatus status={status} />
    </HidingTooltip>
  );
};
