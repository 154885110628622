import React, { FormEvent } from 'react';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { TextInput } from 'ui/TextInput';

interface IProposalListFiltersProps {
  nameFilter: string;
  clientFilter: string;
  isSr: boolean;
  selectedTravelAgentUuid: string | null;
  travelAgentSelectOptions: ISingleSelectOption[];
  onNameFilterChange: (e: FormEvent<HTMLInputElement>) => void;
  onClientFilterChange: (e: FormEvent<HTMLInputElement>) => void;
  onTAFilterChange: (value: string) => void;
}

export const ProposalListFilters: React.FC<IProposalListFiltersProps> = React.memo(({ 
  nameFilter, clientFilter, isSr, selectedTravelAgentUuid, travelAgentSelectOptions, onNameFilterChange, onClientFilterChange, onTAFilterChange
}) => {
  return (
    <div className="proposal-list-filters mt-5 mb-5">
        <p className="proposal-list-filters-header font-pt-sans font-bold text-17px leading-22px">Filter Results</p>
        <div className="flex gap-20px mt-10px">
          <TextInput
            id="proposal-name-filter"
            label="Proposal Name"
            className="proposal-name-filter min-w-250px"
            value={nameFilter}
            onChange={onNameFilterChange}
          />

          {isSr && (
            <SingleSelect
              fieldId="proposal-ta-filter"
              label="Travel Agent"
              className="proposal-ta-filter min-w-[400px]"
              value={selectedTravelAgentUuid ?? ''}
              options={travelAgentSelectOptions as ISingleSelectOption[]}
              onChange={onTAFilterChange}
              maxVisibleItems={6}
            />
          )}

          <TextInput
            id="proposal-client-filter"
            label="Client"
            className="proposal-client-filter min-w-250px"
            value={clientFilter}
            onChange={onClientFilterChange}
          />
        </div>
      </div>
  );
});