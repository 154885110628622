import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import * as Yup from 'yup';

import Checkbox from 'ui/Checkbox';
import { TextInput } from 'ui/TextInput';
import { GeneralModal } from 'ui/GeneralModal';
import { Warning } from 'ui/Warning';
import { getAuthStatus, logIn } from 'store/modules/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentWidth } from 'effects';
import { ILoginFormValues } from './types';
import { Field, Formik, FormikProps } from 'formik';
import { ForgotPasswordModal } from 'ui/ForgotPasswordModal';
import { Status } from 'store/common';

export interface iLoginModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

const loginFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email address is invalid')
    .required('This field is required'),
  password: Yup.string().required('This field is required'),
  rememberMe: Yup.boolean(),
  authenticationStrategy: Yup.string(),
});

export const LoginModal = (props: iLoginModalProps) => {
  const { isOpen, onClose, onSuccess } = props;

  const dispatch = useDispatch();
  const authStatus = useSelector(getAuthStatus);
  const loading = authStatus === Status.SENDING;
  const { isMobile } = useCurrentWidth();
  const modalWidthClassname = classnames('flex justify-center', {
    'w-330px': isMobile,
    'w-400px': !isMobile,
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [isForgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);

  const initialValues: ILoginFormValues = {
    email: '',
    password: '',
    rememberMe: false,
    authenticationStrategy: 'jwt',
  };

  const handleFormSubmit = useCallback(
    (loginFormValues: ILoginFormValues) => {
      const { email, password, rememberMe } = loginFormValues;
      setLoginErrorMessage('');
      dispatch(logIn({ email, password, rememberMe }));
      setFormSubmitted(true);
    },
    [dispatch]
  );

  const handleForgotPasswordLinkClick = useCallback(() => {
    setLoginErrorMessage('');
    setForgotPasswordModalOpen(true);
    setFormSubmitted(false);
  }, []);
  const onForgotPasswordModalClose = useCallback(() => setForgotPasswordModalOpen(false), []);

  useEffect(() => {
    if (authStatus === Status.ERROR && formSubmitted) {
      setLoginErrorMessage('Email or password invalid');
    }

    if (authStatus === Status.SUCCESS && formSubmitted) {
      onSuccess && onSuccess();
    }
  }, [authStatus, onSuccess, formSubmitted]);

  const renderContent = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={loginFormValidationSchema}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {(form: FormikProps<ILoginFormValues>) => {
          return (
            <form onSubmit={form.handleSubmit}>
              {loginErrorMessage && <Warning message={loginErrorMessage} />}
              <Field
                as={TextInput}
                name="email"
                type="text"
                id="login-modal-email"
                className="login-modal-email mt-25px"
                label="Email"
                errorMessage={form.touched.email && form.errors.email ? form.errors.email : null}
                errorClassName="login-modal-email-error"
              />

              <Field
                as={TextInput}
                name="password"
                type="password"
                id="login-modal-password"
                className="login-modal-password mt-25px"
                label="Password"
                errorMessage={form.touched.password && form.errors.password ? 'This field is required' : null}
                errorClassName="login-modal-password-error"
              />

              <Field name="rememberMe">
                {({ field: { name, value }, form: { setFieldValue } }) => (
                  <div className="login-modal-rememberme flex mt-25px items-center">
                    <Checkbox checked={value} onClick={() => setFieldValue(name, !value)} />
                    <label className="text-black text-13px leading-17px tracking-2xs font-pt-sans ml-2">
                      Remember me
                    </label>
                  </div>
                )}
              </Field>

              <button
                id="loginSubmitCta"
                className="font-pt-sans bg-brown-prime w-full h-10 border-none mt-25px px-33px text-white-true tracking-wider hover:bg-brown-hard active:bg-brown-hard cursor-pointer"
                disabled={loading}
              >
                Sign in
              </button>

              <div className="login-modal-forgot-password-link flex justify-center items-center">
                <span
                  className="forgot-password-link mt-15px text-15px leading-19px text-brown-prime visited:text-brown-prime font-pt-sans underline cursor-pointer"
                  onClick={handleForgotPasswordLinkClick}
                >
                  Forgot Your Password?
                </span>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  };

  if (!isOpen) {
    return null;
  }

  if (isForgotPasswordModalOpen) {
    return <ForgotPasswordModal isOpen onClose={onForgotPasswordModalClose} />;
  }

  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      modalWindowClassName={modalWidthClassname}
      shouldCloseByClickingOutside
    >
      <div
        className={classnames(
          'login-modal flex justify-center pt-55px pb-65px w-screen max-w-330px',
          modalWidthClassname
        )}
      >
        <div className="w-250px">
          <div className="flex flex-col items-center">
            {!loading && (
              <p className="login-modal-title text-black-text text-4xl leading-54px font-noe-display mt-5px my-0">
                Sign In
              </p>
            )}
          </div>

          {loading && <p className="mt-5 text-center text-black text-base leading-xl">Loading ...</p>}

          {!loading && renderContent()}
        </div>
      </div>
    </GeneralModal>
  );
};
