import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Actions from './actions';
import { IInternalUsersListDomain, initialState } from './model';
import { update } from 'utils/immutable';

export const travelAgentListReducer = (
  state: IInternalUsersListDomain = initialState,
  action: Actions.Any
): IInternalUsersListDomain => {
  switch (action.type) {
    case Actions.GET_REQUEST:
      return update(state, s => {
        s.request.get = ENetworkRequestStatus.PENDING;
      });

    case Actions.GET_SUCCESS:
      return update(state, s => {
        s.request.get = ENetworkRequestStatus.SUCCESS;
        s.list = action.internalUsers;
        s.pagination.total = action.total;
      });

    case Actions.GET_FAILURE:
      return update(state, s => (s.request.get = ENetworkRequestStatus.ERROR));

    case Actions.CSV_REQUEST:
      return update(state, s => (s.request.csv = ENetworkRequestStatus.PENDING));

    case Actions.CSV_SUCCESS:
      return update(state, s => (s.request.csv = ENetworkRequestStatus.SUCCESS));

    case Actions.CSV_FAILURE:
      return update(state, s => (s.request.csv = ENetworkRequestStatus.ERROR));

    case Actions.DELETE_REQUEST:
      return update(state, s => (s.request.delete = ENetworkRequestStatus.PENDING));

    case Actions.DELETE_SUCCESS:
      return update(state, s => {
        s.request.delete = ENetworkRequestStatus.SUCCESS;
        s.toDelete = null;
      });

    case Actions.DELETE_FAILURE:
      return update(state, s => {
        s.request.delete = ENetworkRequestStatus.ERROR;
      });

    case Actions.SET_USER_ROLE_FILTER:
      return update(state, s => {
        s.pagination.page = 0;
        s.filter.userRole = action.value;
      });

    case Actions.SET_SEARCH_FILTER:
      return update(state, s => {
        s.pagination.page = 0;
        s.filter.search = action.value;
      });

    case Actions.SET_EMAIL_FILTER:
      return update(state, s => {
        s.pagination.page = 0;
        s.filter.email = action.value;
      });

    case Actions.SET_COUNTRY_CODE_FILTER:
      return update(state, s => {
        s.pagination.page = 0;
        s.filter.countryCode = action.value;
      });

    case Actions.SET_STATUS_FILTER:
      return update(state, s => {
        s.pagination.page = 0;
        s.filter.status = action.value;
      });

    case Actions.SET_PAGE:
      return update(state, s => {
        s.pagination.page = action.value;
      });

    case Actions.SET_PER_PAGE:
      return update(state, s => {
        s.pagination.page = 0;
        s.pagination.perPage = action.value;
      });

    case Actions.SET_TO_DELETE:
      return update(state, s => {
        s.toDelete = action.value;
      });

    case Actions.SET_REQUEST:
      return update(state, s => {
        s.request.get = action.net;
      });

    case Actions.SET_SELECTED_SR:
      return update(state, s => {
        s.assignTAModal.selectedSR = action.sr;
        s.assignTAModal.filter.salesRepUuid = action.sr.uuid;
      });

    case Actions.RESET_SELECTED_SR:
      return update(state, s => {
        s.assignTAModal = initialState.assignTAModal;
      });

    case Actions.GET_IDLE_SELECTED_SR_TAS:
      return update(state, s => {
        s.assignTAModal.getRequest = ENetworkRequestStatus.IDLE;
        s.assignTAModal.assignedTAs = [];
      });

    case Actions.GET_REQUEST_SELECTED_SR_TAS:
      return update(state, s => {
        s.assignTAModal.getRequest = ENetworkRequestStatus.PENDING;
        s.assignTAModal.assignedTAs = [];
      });

    case Actions.GET_SUCESS_SELECTED_SR_TAS:
      return update(state, s => {
        s.assignTAModal.getRequest = ENetworkRequestStatus.SUCCESS;
        s.assignTAModal.assignedTAs = action.data
      });

    case Actions.GET_FAILURE_SELECTED_SR_TAS:
      return update(state, s => {
        s.assignTAModal.getRequest = ENetworkRequestStatus.ERROR;
      });

    case Actions.POST_REQUEST_SELECTED_SR_TAS:
      return update(state, s => {
        s.assignTAModal.postRequest = ENetworkRequestStatus.PENDING;
      });

    case Actions.POST_SUCESS_SELECTED_SR_TAS:
      return update(state, s => {
        s.assignTAModal.postRequest = ENetworkRequestStatus.SUCCESS;
      });

    case Actions.POST_FAILURE_SELECTED_SR_TAS:
      return update(state, s => {
        s.assignTAModal.postRequest = ENetworkRequestStatus.ERROR;
      });

    case Actions.ADD_SELECTED_SR_TAS:
      return update(state, s => {
        s.assignTAModal.data.push(action.uuid);
        if(s.assignTAModal.data.length === s.assignTAModal.assignedTAs.length) s.assignTAModal.checkAll = true;
      });

    case Actions.REMOVE_SELECTED_SR_TAS:
      return update(state, s => {
        s.assignTAModal.data = s.assignTAModal.data.filter((element) => {
          return element !== action.uuid;
        });
        if (s.assignTAModal.data.length !== s.assignTAModal.assignedTAs.length) {
          s.assignTAModal.checkAll = false;
        }
      });

    case Actions.SET_SELECTED_SR_TAS:
      return update(state, s => {
        s.assignTAModal.data = action.uuidsList;
      });

    case Actions.CHECK_ALL_SR_TAS:
      return update(state, s => {
        s.assignTAModal.checkAll = true;
        s.assignTAModal.data = s.assignTAModal.assignedTAs.map(AT => AT.uuid);
      });

    case Actions.UNCHECK_ALL_SR_TAS:
      return update(state, s => {
        s.assignTAModal.checkAll = false;
        s.assignTAModal.data = [];
      });

    case Actions.SET_TA_FILTER_NAME:
      return update(state, s => {
        s.assignTAModal.filter.search = action.str;
      });

    case Actions.SET_TA_FILTER_COUNTRY:
      return update(state, s => {
        s.assignTAModal.filter.countryCode = action.countryCode;
      });

    case Actions.SET_IS_ASSIGNED_TAB:
      return update(state, s => {
        s.assignTAModal.isAssignedTab = true;
      });

    case Actions.RESET_IS_ASSIGNED_TAB:
      return update(state, s => {
        s.assignTAModal.isAssignedTab = false;
        s.assignTAModal.assignedTAs = [];
      });

    case Actions.OPEN_CONFIRMATION_MODAL:
      return update(state, s => {
        s.assignTAModal.confirmationModal = true;
      });

    case Actions.CLOSE_CONFIRMATION_MODAL:
      return update(state, s => {
        s.assignTAModal.confirmationModal = false;
      });

    case Actions.CONFIRM_CHANGES:
      return update(state, s => {
        s.assignTAModal.getRequest = ENetworkRequestStatus.PENDING;
      });

    default:
      return state;
  }
};

export default travelAgentListReducer;
