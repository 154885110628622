export interface IInventoryStaysFilter {
  name?: string | null;
  type?: EInventoryStayListingItemType | null;
  is_enabled?: boolean | null;
  country_iso?: string | null;
  rate_type?: EInventoryStayListingRateType | null;
  payment_type?: EInventoryStayListingContractPaymentType | null;
  page: number;
  page_total?: number;
  page_size: number;
  order_by: 'public_id' | 'name' | 'contract_incentives' | 'contract_expires';
  order_type: 'asc' | 'desc';
}

export enum EInventoryStayListingItemType {
  PURE_ESCAPES = 'Pure Escapes',
  HOTEL = 'Hotel',
  YACHT = 'Yacht',
  TRAIN = 'Train',
  TERMINAL = 'Terminal',
  OTHER = 'Other',
}

export enum EInventoryStayListingRating {
  FIVE_PLUS_STARS = '5+ STARS',
  FIVE_STARS = '5 STARS',
}

export enum EInventoryStayListingRateType {
  STATIC_LIVE = 'Static and Live',
  STATIC = 'Static',
  LIVE = 'Live',
}

export enum EInventoryStayListingContractPaymentType {
  MONTHLY_INV = 'Monthly Inv',
  CREDIT = 'Credit',
  PREPAY = 'PrePay',
}

export enum EInventoryStayListingTagType {
  BEST_FOR = 'BestFor',
  AMENITY = 'Amenity',
  OTHER = 'Other',
  MEDIA = 'Media',
  REGION = 'Region',
  OCCASION = 'Occasion',
}

export interface EInventoryStayListingTag { 
  type: EInventoryStayListingTagType; 
  values: { 
    name: string; 
    type: EInventoryStayListingTagType; 
    uuid: string; 
  }[] 
}

export enum EInventoryStayListingMarkupChargeApproach {
  PER_BOOKING = 'Per booking',
  PER_NIGHT = 'Per night',
}

export enum EInventoryStayListingMarket {
  WORLDWIDE = 'Worldwide',
  RESTRICTED = 'Restricted',
}

// see https://github.com/pure-escapes/inventory-valuation-workspace/blob/main/inventory/gateway/general/src/schemas/services/search-contracting.yml
export interface IInventoryStayListingItem {
  uuid: string;
  public_id: string;
  name: string;
  type: EInventoryStayListingItemType;
  rating: EInventoryStayListingRating;
  region: string;
  country_iso: string;
  enabled: boolean;
  supplier_id: string;
  description: string;
  enabled_for_travel_companies: boolean;
  preferred: boolean;
  additional_info: string;
  tags: EInventoryStayListingTag[];
  overview: string | null;
  highlights: string | null;
  updated_at: string;
  created_at: string;
  additional_data: {
    type_details: {} | any;
    rate_type: EInventoryStayListingRateType;
    contracting: {
      contract_incentives: boolean;
      contract_expires: string;
      contract_payment_type: EInventoryStayListingContractPaymentType;
      default_markup_charge_approach: EInventoryStayListingMarkupChargeApproach;
      default_markup_is_percentage: boolean;
      default_markup_value: number;
      market: EInventoryStayListingMarket;
    };
    geographic: {
      country: string;
      country_iso: string;
    };
  };
}

export interface IInventoryStayForCreating {
  name: string;
  type: EInventoryStayListingItemType | null;
  public_id: string;
  rating: EInventoryStayListingRating | null;
  country_iso: string | null;
  region: string | null;
  description: string;
  additional_info: string;
  supplier_id: string | null;
  enabled: boolean;
  preferred: boolean;
}

export interface IInventoryStayForSaving {
  uuid: string;
  name: string;
  type: EInventoryStayListingItemType | null;
  public_id: string;
  rating: EInventoryStayListingRating | null;
  country_iso: string | null;
  region: string | null;
  description: string;
  additional_info: string;
  supplier_id: string | null;
  enabled: boolean;
  enabled_for_travel_companies: boolean;
  preferred: boolean;
  overview: string;
  highlights: string;
  bestForFilters: string[];
  amenityFilters: string[];
  otherFilters: string[];
}

export interface IInventoryTag {
  name: string;
  uuid: string;
}