import { initialState, IPurchaseCostReviewSubdomain } from './model';
import * as Actions from './actions';
import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';

const purchaseCostReviewReducer = (state: IPurchaseCostReviewSubdomain = initialState, action: Actions.PurchaseCostReviewAction) => {
  switch (action.type) {
    case Actions.GET_PURCHASE_COST_REVIEW_REQUEST:
      return produce(state, draftState => {
        draftState.request.getPurchaseCostReview = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_PURCHASE_COST_REVIEW_SUCCESS:
      return produce(state, draftState => {
        draftState.request.getPurchaseCostReview = ENetworkRequestStatus.SUCCESS;
        
        draftState.status = action.response.status;
        draftState.financePurchaseRow = action.response.financePurchaseRow || null;
        draftState.comment = action.response.comment || null;

        return draftState;
      });

    case Actions.GET_PURCHASE_COST_REVIEW_FAILURE:
      return produce(state, draftState => {
        draftState.request.getPurchaseCostReview = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.PATCH_PURCHASE_COST_REVIEW_REQUEST:
      return produce(state, draftState => {
        draftState.request.patchPurchaseCostReview = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.PATCH_PURCHASE_COST_REVIEW_SUCCESS:
      return produce(state, draftState => {
        draftState.request.patchPurchaseCostReview = ENetworkRequestStatus.SUCCESS;
        
        draftState.status = action.response.status;
        draftState.financePurchaseRow = action.response.financePurchaseRow || null;
        draftState.comment = action.response.comment || null;
        
        draftState.requestedStatus = null;
        draftState.request.uploadFile = ENetworkRequestStatus.IDLE;

        return draftState;
      });

    case Actions.PATCH_PURCHASE_COST_REVIEW_FAILURE:
      return produce(state, draftState => {
        draftState.request.patchPurchaseCostReview = ENetworkRequestStatus.ERROR;
        
        draftState.requestedStatus = null;
        draftState.request.uploadFile = ENetworkRequestStatus.IDLE;

        return draftState;
      });
    
    case Actions.REQUEST_STATUS_CHANGE:
      return produce(state, draftState => {
        draftState.requestedStatus = action.requestedStatus;
        return draftState;
      });
    
    case Actions.CANCEL_STATUS_CHANGE:
      return produce(state, draftState => {
        draftState.requestedStatus = null;
        draftState.request.uploadFile = ENetworkRequestStatus.IDLE;
        
        return draftState;
      });
    
    case Actions.UPLOAD_FILE_REQUEST:
      return produce(state, draftState => {
        draftState.request.uploadFile = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.UPLOAD_FILE_SUCCESS:
      return produce(state, draftState => {
        draftState.request.uploadFile = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.UPLOAD_FILE_FAILURE:
      return produce(state, draftState => {
        draftState.request.uploadFile = ENetworkRequestStatus.ERROR;
        return draftState;
      });
  
    default:
      return state;
  }
};

export default purchaseCostReviewReducer;
