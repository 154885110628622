import { createSelector } from 'reselect';
import { IInternalUserDomain } from './model';

export const domainSelector = (state: any): IInternalUserDomain => state.internalUser;

export const internalUserSelector = createSelector(
  domainSelector,
  (domain: IInternalUserDomain) => domain.internalUser
);

export const internalUserCreateRequestSelector = createSelector(
  domainSelector,
  (domain: IInternalUserDomain) => domain.request.create
);

export const internalUserGetRequestSelector = createSelector(
  domainSelector,
  (domain: IInternalUserDomain) => domain.request.get
);
