import React, { useMemo, useReducer } from 'react';
import * as Yup from 'yup';
import { StandardModal } from 'pureUi/Modal';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'store/modules/companyMemberships/actions';
import * as Selectors from 'store/modules/companyMemberships/selectors';
import * as DepartmentsSelectors from 'store/modules/companyDepartments/selectors';
import * as CompanyInfoSelectors from 'store/modules/companyInfo/selectors';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { useCurrentWidth } from 'effects';
import SingleSelect from 'ui/SingleSelect';
import FluidButton from 'ui/FluidButton';
import { Radio } from 'ui/Radio';
import { FilePicker } from 'ui/FilePicker';
import { ICompanyMembershipDataPayload } from 'store/modules/companyMemberships/model';
import { CircleIconButton } from 'ui/CircleIconButton';
import { deleteIcon } from 'ui/Icons';
import { isBlank } from 'utils';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';
import { UITextArea } from 'ui/UITextArea';

interface IModalReducerAction {
  type:
    | 'name'
    | 'status'
    | 'newLogoFile'
    | 'removeLogoFile'
    | 'contactEmail'
    | 'contactPhone'
    | 'contactInfo'
    | 'isEmailValid'
    | 'benefitsDescription'
    | 'companyDepartmentUuid';
  payload: any;
}

interface IModalState {
  membershipPayload: ICompanyMembershipDataPayload;
  isEmailValid?: boolean;
}

function editModalReducer(state: IModalState, action: IModalReducerAction): IModalState {
  switch (action.type) {
    case 'name':
    case 'status':
    case 'contactEmail':
    case 'contactPhone':
    case 'contactInfo':
    case 'benefitsDescription':
    case 'companyDepartmentUuid':
      if (action.payload === '') {
        return {
          ...state,
          membershipPayload: {
            ...state.membershipPayload,
            [action.type]: null,
          },
        };
      }
      return {
        ...state,
        membershipPayload: {
          ...state.membershipPayload,
          [action.type]: action.payload,
        },
      };
    case 'newLogoFile':
      const formData = new FormData();
      formData.append('file', action.payload.files[0]);
      formData.append('ownerType', 'CompanyMembership');
      formData.append('tag', 'CompanyMembershipLogo');
      return {
        ...state,
        membershipPayload: {
          ...state.membershipPayload,
          logoFormData: formData,
        },
      };
    case 'removeLogoFile':
      let newState = {
        ...state,
        membershipPayload: {
          ...state.membershipPayload,
          logoUrl: undefined,
          logoFormData: undefined,
        },
      };
      // if they've removed a logo but we already had one, we need to set a flag to tell the backend to remove it
      if (!isBlank(state.membershipPayload.logoUrl)) {
        newState.membershipPayload.hasRemovedLogo = true;
      }
      return newState;
    case 'isEmailValid':
      return {
        ...state,
        isEmailValid: action.payload,
      };
    default:
      return state;
  }
}

export const EditMembershipModal = ({ companyUuid }: { companyUuid: string }) => {
  const dispatch = useDispatch();

  const membership = useSelector(Selectors.membershipToEditSelector)!;
  const requests = useSelector(Selectors.companyMembershipsRequestsSelector);
  const company = useSelector(CompanyInfoSelectors.companyDataSelector);
  const departments = useSelector(DepartmentsSelectors.companyDepartmentsSelector);

  // if we've somehow not got a company... bad things have happened
  if (!company) {
    throw new Error('Trying to load membership modal without a company');
  }

  const isLoading = requests.membershipSave === ENetworkRequestStatus.PENDING;

  const { isMobile, isMobileLandscape, isTablet, isDesktop, isDesktopHD } = useCurrentWidth();

  const modalWidthClassname = classnames({
    'max-w-330px': isMobile || isMobileLandscape,
    'max-w-480px': isTablet || isDesktop || isDesktopHD,
    'min-h-270px': !isLoading,
  });

  const [modalState, modalDispatch] = useReducer(editModalReducer, {
    isEmailValid:
      membership && !isBlank(membership.contactEmail)
        ? Yup.string()
            .email()
            .isValidSync(membership.contactEmail)
        : undefined,
    membershipPayload: {
      companyUuid: company.uuid,
      uuid: membership ? membership.uuid : undefined,
      name: membership ? membership.name : '',
      status: membership ? membership.status : false,
      logoUrl: membership ? membership.logoUrl : undefined,
      contactEmail: membership ? membership.contactEmail : '',
      contactPhone: membership ? membership.contactPhone : '',
      contactInfo: membership ? membership.contactInfo : '',
      benefitsDescription: membership ? membership.benefitsDescription : '',
      companyDepartmentUuid: membership?.companyDepartment?.uuid,
      hasRemovedLogo: false,
    },
  });

  // we can save if we're not loading and if we have a name...
  let canSave =
    !isLoading && modalState.membershipPayload.name !== '' && modalState.membershipPayload.name !== null && modalState.membershipPayload.name !== undefined;
  //...and if we have an email, it's valid
  if (!isBlank(modalState.membershipPayload.contactEmail)) {
    canSave = canSave && !!modalState.isEmailValid;
  }

  const departmentsOptions = useMemo(
    () =>
      departments?.map(x => ({
        label: x.name,
        value: x.uuid,
      })),
    [departments]
  );

  return (
    <StandardModal
      className={`company-membership-modal-${membership ? 'edit' : 'create'} font-pt-sans z-11`}
      frameClassName="px-35px py-25px rounded"
      showCloseButton={true}
      removePadding={true}
      closeOnOutsideClick={true}
      onClose={() => {
        dispatch(Actions.setMembershipToEditAction(null));
        dispatch(Actions.closeCreateMembershipModalAction());
      }}
    >
      <div className={classnames('default:w-screen', modalWidthClassname)}>
        <h3 className="m-0 font-noe-display mb-25px font-normal text-black text-21px">
          {membership ? 'Edit Membership' : 'Add Membership'}
        </h3>
        <p className="font-pt-sans text-15px leading-22px text-black mb-0">
          Complete the information to be included in documents issued to final clients.
        </p>

        <div className={classnames({ 'max-w-250px': isMobile || isMobileLandscape })}>
          <div className="flex flex-col gap-20px mt-25px">
            {/* name and status */}
            <div className="flex flex-row space-x-20px">
              {/* name */}
              <label className="membership-name flex flex-col space-y-5px w-full">
                <span className="font-pt-sans font-bold text-base">Membership Name</span>
                <input
                  className="w-full min-h-35px px-10px py-8px bg-ivory border border-solid border-gray-40 font-pt-sans text-15px"
                  type="text"
                  value={modalState.membershipPayload.name || ''}
                  onChange={e => {
                    modalDispatch({ type: 'name', payload: e.target.value });
                  }}
                />
              </label>

              {/* status */}
              <div className="membership-status flex flex-col space-y-5px w-full">
                <span className="font-pt-sans font-bold text-base">Status</span>
                <div className="flex">
                  <label
                    className="membership-status-active cursor-pointer flex min-h-35px items-center"
                    onClick={() => {
                      modalDispatch({ type: 'status', payload: true });
                    }}
                  >
                    <Radio
                      onClick={() => {
                        modalDispatch({ type: 'status', payload: true });
                      }}
                      checked={modalState.membershipPayload.status === true}
                    />
                    <span className="ml-1 mr-5">Active</span>
                  </label>
                  <label
                    className="membership-status-inactive cursor-pointer flex min-h-35px items-center"
                    onClick={() => {
                      modalDispatch({ type: 'status', payload: false });
                    }}
                  >
                    <Radio
                      onClick={() => {
                        modalDispatch({ type: 'status', payload: false });
                      }}
                      checked={modalState.membershipPayload.status === false}
                    />
                    <span className="ml-1 mr-5">Inactive</span>
                  </label>
                </div>
              </div>
            </div>

            {/* department */}
            <label className="department flex flex-col space-y-5px">
              <span className="font-pt-sans font-bold text-base">Department</span>
              <SingleSelect
                fieldId="department"
                value={modalState.membershipPayload.companyDepartmentUuid || undefined}
                options={departmentsOptions || []}
                onChange={value => {
                  modalDispatch({ type: 'companyDepartmentUuid', payload: value });
                }}
                showSelectedOption
                maxVisibleItems={3}
                labelWhenNothingSelected="Select ..."
              />
            </label>

            {/* logo */}
            {!isBlank(modalState.membershipPayload.logoUrl) && (
              <div className="membership-logo flex flex-col space-y-5px w-full">
                <span className="font-pt-sans font-bold text-base">Logo</span>
                <div className="bg-brown-10 flex flex-row items-center justify-between p-10px">
                  <img src={modalState.membershipPayload.logoUrl} className="w-100px h-100px" />
                  <div className="logo-actions flex items-center relative space-x-5px">
                    <HidingTextTooltip width="57px" tooltipContent="Delete">
                      <CircleIconButton
                        type="secondary"
                        className="delete-action mx-1"
                        iconClass="inline relative left-0 top-1px w-12px h-12px"
                        iconSrc={deleteIcon}
                        onClick={() => {
                          modalDispatch({
                            type: 'removeLogoFile',
                            payload: {},
                          });
                        }}
                      />
                    </HidingTextTooltip>
                  </div>
                </div>
              </div>
            )}

            {/* upload a new logo */}
            {isBlank(modalState.membershipPayload.logoUrl) && (
              <div className="membership-logo flex flex-col space-y-5px w-full">
                <span className="font-pt-sans font-bold text-base">Logo</span>
                <FilePicker
                  className="file-picker block w-full"
                  showAttachFileLabel={false}
                  isAttachmentRequired={false}
                  uploadName={undefined}
                  onFileSelect={(files: File[]) => {
                    modalDispatch({
                      type: 'newLogoFile',
                      payload: {
                        files,
                        companyUuid,
                      },
                    });
                  }}
                  onFileRemove={() => {
                    modalDispatch({
                      type: 'removeLogoFile',
                      payload: {},
                    });
                  }}
                  isLoading={false}
                  acceptString="image/*"
                />
                <span className="block mt-5px font-pt-sans text-13px text-gray-80">
                  Files might be in JPG or PNG format. Recomended: transparent PNG.
                </span>
              </div>
            )}

            {/* contact email and contact phone */}
            <div className="flex flex-row space-x-20px">
              {/* contact email */}
              <label className="membership-contact-email flex flex-col space-y-5px w-full relative">
                <span className="font-pt-sans font-bold text-base">Contact Email</span>
                <input
                  className={classnames('w-full min-h-35px px-10px py-8px border border-solid font-pt-sans text-15px', {
                    'border-red-95 bg-red-25': modalState.isEmailValid === false,
                    'border-gray-40 bg-ivory': modalState.isEmailValid !== false,
                  })}
                  type="text"
                  value={modalState.membershipPayload.contactEmail || ''}
                  onChange={e => {
                    modalDispatch({ type: 'contactEmail', payload: e.target.value });

                    if (isBlank(e.target.value)) {
                      modalDispatch({ type: 'isEmailValid', payload: undefined });
                    } else {
                      const isEmailValid = Yup.string()
                        .email()
                        .isValidSync(e.target.value);
                      modalDispatch({ type: 'isEmailValid', payload: isEmailValid });
                    }
                  }}
                />
                {modalState.isEmailValid === false && (
                  <span className="font-pt-sans text-13px text-red-95">Must be valid email</span>
                )}
              </label>

              {/* contact phone */}
              <div className="membership-contact-phone flex flex-col space-y-5px w-full">
                <span className="font-pt-sans font-bold text-base">Contact Phone</span>
                <input
                  className="w-full min-h-35px px-10px py-8px bg-ivory border border-solid border-gray-40 font-pt-sans text-15px"
                  type="text"
                  value={modalState.membershipPayload.contactPhone || ''}
                  onChange={e => {
                    modalDispatch({ type: 'contactPhone', payload: e.target.value });
                  }}
                />
              </div>
            </div>

            {/* contact info */}
            <label className="membership-contact-info flex flex-col space-y-5px">
              <span className="font-pt-sans font-bold text-base">Contact Info</span>
              <input
                className="w-full min-h-35px px-10px py-8px bg-ivory border border-solid border-gray-40 font-pt-sans text-15px"
                type="text"
                value={modalState.membershipPayload.contactInfo || ''}
                onChange={e => {
                  modalDispatch({ type: 'contactInfo', payload: e.target.value });
                }}
              />
            </label>

            {/* benefits description */}
            <label className="membership-benefits-description flex flex-col space-y-5px">
              <span className="font-pt-sans font-bold text-base">Benefits Description</span>
              <UITextArea
                rows={2} 
                className="benefits-description" 
                value={modalState.membershipPayload.benefitsDescription || ''} 
                onChange={value => {
                  modalDispatch({ type: 'benefitsDescription', payload: value });
                }}
                style={{
                  height: '100px',
                }}
              />
            </label>

            <div className="membership-buttons flex gap-10px mt-25px">
              <FluidButton
                className="membership-cancel"
                type="secondary"
                onClick={() => {
                  // maybe if we're editing, we need to clear something out?
                  dispatch(Actions.setMembershipToEditAction(null));
                  dispatch(Actions.closeCreateMembershipModalAction());
                }}
              >
                Cancel
              </FluidButton>
              <FluidButton
                className="membership-save"
                type="primary"
                onClick={() => {
                  if (membership) {
                    dispatch(Actions.editCompanyMembershipRequestAction(modalState.membershipPayload));
                  } else {
                    dispatch(Actions.createCompanyMembershipRequestAction(modalState.membershipPayload));
                  }
                }}
                isLoading={isLoading}
                disabled={!canSave}
              >
                Save
              </FluidButton>
            </div>
          </div>
        </div>
      </div>
    </StandardModal>
  );
};
