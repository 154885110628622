import { IImportModel, initialState, IImportUiState, EImportEntity } from './model';
import {
  ImportAction,
  IMPORT_REQUEST,
  IMPORT_SUCCESS,
  IMPORT_FAILURE,
  GET_IMPORT_STATUS_SUCCESS,
  GET_IMPORT_STATUS_FAILURE,
  OPEN_IMPORT_CONFIRMATION_MODAL,
  CONFIRM_IMPORT_INTENT,
  CANCEL_IMPORT_INTENT,
  IMPORT_SUCCESS_WITH_MESSAGE,
  IMPORT_PAGE_UNLOADED,
} from './actions';

export const importReducer = (entity: EImportEntity) => (
  state: IImportModel = initialState,
  action: ImportAction
): IImportModel => {
  if (action.entity !== entity) {
    return state;
  }

  switch (action.type) {
    //------------------- import action -------------------------------

    case IMPORT_REQUEST:
      return {
        ...state,
        importRequestIsPending: true,
        error: null,
      };

    case IMPORT_SUCCESS:
      return {
        ...state,
        importRequestIsPending: false,
        error: null,
        latestStatus: action.status ? action.status : state.latestStatus,
        workbookId: action.workbookId ? action.workbookId : state.workbookId,
        message: undefined,
      };

    case IMPORT_FAILURE:
      return {
        ...state,
        importRequestIsPending: false,
        error: action.error,
        message: undefined,
      };

    case IMPORT_SUCCESS_WITH_MESSAGE:
      return {
        ...state,
        importRequestIsPending: false,
        importConfirmedWaitingForLatestStatus: false,
        error: null,
        message: action.message,
      };
    //------------------- get import status action -------------------------------

    case GET_IMPORT_STATUS_SUCCESS:
      return {
        ...state,
        error: null,
        latestStatus: action.status,
        workbookId: action.workbookId,
        importConfirmedWaitingForLatestStatus: false,
      };

    case GET_IMPORT_STATUS_FAILURE:
      return {
        ...state,
        error: action.error,
        importConfirmedWaitingForLatestStatus: false,
      };

    //------------------- UI actions -------------------------------

    case OPEN_IMPORT_CONFIRMATION_MODAL:
      return {
        ...state,
        uiState: {
          ...state.uiState,
          confirmationModalOpen: true,
        },
      };
    case CONFIRM_IMPORT_INTENT:
      return {
        ...state,
        importRequestIsPending: true,
        importConfirmedWaitingForLatestStatus: true,
        uiState: {
          ...state.uiState,
          confirmationModalOpen: false,
        },
      };
    case CANCEL_IMPORT_INTENT:
      return {
        ...state,
        importConfirmedWaitingForLatestStatus: false,
        uiState: {
          ...state.uiState,
          confirmationModalOpen: false,
        },
      };

    default:
      return state;
  }
};
