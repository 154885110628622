import { ENetworkRequestStatus, Upload } from 'services/BackendApi';

export interface IDownloadsSubdomain {
  downloads: Upload[] | null;
  networkRequests: IDownloadsSubdomainNetworkRequests;
}

export interface IDownloadsSubdomainNetworkRequests {
  downloadsLoad: ENetworkRequestStatus;
}

export const initialState: IDownloadsSubdomain = {
  downloads: null,
  networkRequests: {
    downloadsLoad: ENetworkRequestStatus.IDLE,
  },
};
