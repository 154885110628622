import React from 'react';
import { IIconProps } from 'ui/CircleIconButton';
import { theme } from '../../../../tailwind.config';

export const VideoIcon: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors['brown-100'], background = 'transparent' }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ backgroundColor: background }}
    >
      <path 
        d="M1.5 2.99475C1.50137 2.79778 1.58018 2.60926 1.71938 2.46991C1.85859 2.33056 2.04704 2.25157 2.244 2.25H15.756C16.167 2.25 16.5 2.58375 16.5 2.99475V15.0052C16.4986 15.2022 16.4198 15.3907 16.2806 15.5301C16.1414 15.6694 15.953 15.7484 15.756 15.75H2.244C2.04661 15.7498 1.85737 15.6712 1.71787 15.5316C1.57836 15.392 1.5 15.2026 1.5 15.0052V2.99475ZM6 3.75V14.25H12V3.75H6ZM3 3.75V5.25H4.5V3.75H3ZM13.5 3.75V5.25H15V3.75H13.5ZM3 6.75V8.25H4.5V6.75H3ZM13.5 6.75V8.25H15V6.75H13.5ZM3 9.75V11.25H4.5V9.75H3ZM13.5 9.75V11.25H15V9.75H13.5ZM3 12.75V14.25H4.5V12.75H3ZM13.5 12.75V14.25H15V12.75H13.5Z"
        fill={fill}
      />
    </svg>
  )
);

export default VideoIcon;
