import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { omit } from 'lodash-es';
import { injectJwtTokenIntoHeaders } from 'services/tokenLocalStorage';

import qs from 'qs';
import { EInventoryStayListingItemType, EInventoryStayListingRating, IInventoryContactForSaving as IInventoryContactForSaving, IInventoryStayForCreating, IInventoryStayForSaving, IInventoryStaysFilter, IInventorySupplierForSaving, IInventorySupplierSearchContractingParams } from './types';

export class InventoryApiService<T extends AxiosInstance> {
  client: T;

  constructor(client: T) {
    this.client = client;
  }

  getCountries = async (isDestination = true): Promise<AxiosResponse> => {
    const endpoint = `values/countries`;
    return this.client.get(endpoint, {
      params: {
        is_destination: isDestination,
      },
    });
  };

  getCurrencies = async (): Promise<AxiosResponse> => {
    const endpoint = `values/currencies`;
    return this.client.get(endpoint);
  };

  getInventoryStays = async (filter: IInventoryStaysFilter): Promise<AxiosResponse> => {
    const endpoint = `services/contracting`;
    return this.client.get(endpoint, {
      params: {
        ...filter,
      },
    });
  };
  
  getInventoryStayRatings = async (stayType: EInventoryStayListingItemType): Promise<AxiosResponse> => {
    const endpoint = `values/ratings/${stayType}`;
    return this.client.get(endpoint);
  };
  
  getInventoryStayRegions = async (): Promise<AxiosResponse> => {
    const endpoint = `tags/Region`;
    return this.client.get(endpoint);
  };

  getStayFiltersBestfor = async (): Promise<AxiosResponse> => {
    const endpoint = `tags/BestFor`;
    return this.client.get(endpoint);
  };
  
  getStayFiltersAmenity = async (): Promise<AxiosResponse> => {
    const endpoint = `tags/Amenity`;
    return this.client.get(endpoint);
  };
  
  getStayFiltersOther = async (): Promise<AxiosResponse> => {
    const endpoint = `tags/Other`;
    return this.client.get(endpoint);
  };

  searchInventorySuppliersSimple = async (): Promise<AxiosResponse> => {
    const endpoint = `suppliers/simple`;
    return this.client.get(endpoint);
  };

  searchInventorySuppliersContracting = async (params: IInventorySupplierSearchContractingParams): Promise<AxiosResponse> => {
    const endpoint = `suppliers/contracting`;
    return this.client.get(endpoint, { params });
  };

  postInventoryStay = async (stayForSaving: IInventoryStayForCreating): Promise<AxiosResponse> => {
    const endpoint = `services`;
    return this.client.post(endpoint, stayForSaving);
  };
  
  putInventoryStay = async (stayForSaving: IInventoryStayForSaving): Promise<AxiosResponse> => {
    const endpoint = `services/${stayForSaving.uuid}`;
    return this.client.put(endpoint, omit(stayForSaving, ['uuid']));
  };

  postInventorySupplier = async (supplierForSaving: IInventorySupplierForSaving): Promise<AxiosResponse> => {
    const endpoint = `suppliers`;
    return this.client.post(endpoint, supplierForSaving);
  };

  putInventorySupplier = async (supplierForSaving: IInventorySupplierForSaving): Promise<AxiosResponse> => {
    const endpoint = `suppliers/${supplierForSaving.uuid}`;
    return this.client.put(endpoint, supplierForSaving);
  };

  getInventorySupplier = async (supplierUuid: string): Promise<AxiosResponse> => {
    const endpoint = `suppliers/${supplierUuid}`;
    return this.client.get(endpoint);
  };

  addContactsToSupplier = async (supplierUuid: string, contactUuids: string[]): Promise<AxiosResponse> => {
    const endpoint = `suppliers/${supplierUuid}`;
    const config = {
      headers: {
        'Content-Type': 'application/json-patch+json'
      }
    };
    return this.client.patch(endpoint, [
      {
        op: 'add',
        path: '/contacts',
        value: contactUuids,
      }, 
    ], config);
  };

  deleteContactsFromSupplier = async (supplierUuid: string): Promise<AxiosResponse> => {
    const endpoint = `suppliers/${supplierUuid}`;
    const config = {
      headers: {
        'Content-Type': 'application/json-patch+json'
      }
    };
    return this.client.patch(endpoint, [
      {
        op: 'remove',
        path: '/contacts',
      }, 
    ], config);
  };

  postInventoryContact = async (contactForSaving: IInventoryContactForSaving): Promise<AxiosResponse> => {
    const endpoint = `contacts`;
    return this.client.post(endpoint, contactForSaving);
  };
  
  linkTagsToStay = async (stayUuid: string, tagUuids: string[]): Promise<AxiosResponse> => {
    const endpoint = `services/${stayUuid}`;
    const config = {
      headers: {
        'Content-Type': 'application/json-patch+json'
      }
    };
    return this.client.patch(endpoint, [
      {
        op: 'add',
        path: '/tags',
        value: tagUuids,
      }, 
    ], config);
  };

  deleteTagsFromStay = async (stayUuid: string): Promise<AxiosResponse> => {
    const endpoint = `services/${stayUuid}`;
    const config = {
      headers: {
        'Content-Type': 'application/json-patch+json'
      }
    };
    return this.client.patch(endpoint, [
      {
        op: 'remove',
        path: '/tags',
      }, 
    ], config);
  };

  getInventoryStay = async (stayUuid: string): Promise<AxiosResponse> => {
    const endpoint = `services/${stayUuid}`;
    return this.client.get(endpoint);
  };
}

export const makeInventoryApi = (): InventoryApiService<AxiosInstance> => {
  const headers: any = {};

  const client = axios.create({
    baseURL: process.env.INVENTORY_BASE_URL,
    headers: injectJwtTokenIntoHeaders(headers),
    withCredentials: true,
    paramsSerializer: params => {
      return qs.stringify(params);
    },
  });

  return new InventoryApiService<AxiosInstance>(client);
};
