import { IProposal, IProposalPdfVersion, IPreviewParams } from 'services/BackendApi';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { IBookingComment, BookingSearchItem } from 'services/BookingManagerApi';
import { IChecklistSubdomain, initialState as checklistSubdomainInitialState } from './subdomains/checklist/model';
import { IDownloadsSubdomain, initialState as downloadsSubdomainInitialState } from './subdomains/downloads/model';
import { IUploadsSubdomain, initialState as uploadsSubdomainInitialState } from './subdomains/uploads/model';
import { IOwnershipSubdomain, initialState as ownershipSubdomainInitialState } from './subdomains/ownership/model';
import {
  IGuestInformationSubdomain,
  initialState as guestInformationInitialState,
} from './subdomains/guestInformation/model';
import { initialState as voucherSubdomainInitialState } from './subdomains/voucher/model';
import { IVoucherSubdomain } from './subdomains/voucher/types';
import { initialState as financeSubdomainInitialState } from './subdomains/finance/model';
import { IBookingManagerFinanceSubdomain } from './subdomains/finance/types';
import { ITaLogoSubdomain, initialState as taLogoSubdomainInitialState } from './subdomains/taLogo/model';

import { initialState as hotelDetailsSubdomainInitialState } from './subdomains/hotelDetails/model';
import { IBookingManagerHotelDetailsSubdomain } from './subdomains/hotelDetails/types';
import {
  IPurchaseCostReviewSubdomain,
  initialState as purchaseCostReviewSubdomainInitialState,
} from './subdomains/purchaseCostReview/model';

import {
  IBookingManagerBreakdownSubdomain,
  initialState as bookingManagerBreakdownSubdomainInitialState,
} from './subdomains/breakdown/model';
import { IBookingManagerDashboardSubdomain, initialState as bookingManagerDashboardSubdomainInitialState } from './subdomains/dashboard/model';
import {
  initialState as bookingStatusOptionsInitialState,
  IBookingStatusOptionsSubdomain,
} from './subdomains/bookingStatusOptions/model';

import { ISettingsSubdomain, initialState as settingsSubdomainInitialState } from './subdomains/bookingSettings/model';
import {
  IBespokeNegotiationSubdomain,
  initialState as bespokeNegotiationSubdomainInitialState,
} from './subdomains/bespokeNegotiation/model';
import {
  IExternalIdsSubdomain,
  initialState as externalIdsSubdomainInitialState,
} from './subdomains/externalIds/model';

export interface IBookingManagerCommentsSubdomain {
  comments: IBookingComment[];

  // UI
  publicCommentDraft: string;
  privateCommentDraft: string;

  // network statuses
  addBookingCommentLoad: ENetworkRequestStatus;
  getBookingCommentsLoad: ENetworkRequestStatus;

  // booking comment load statuses
  bookingCommentUuidsLoading: string[];

  // booking comments being edited
  bookingCommentUuidsEditing: string[];

  // the drafts of the booking comments being edited
  bookingCommentUuidsEditingDrafts: {
    [key: string]: string;
  };
}

export interface IBookingManagerDomain {
  booking: BookingSearchItem | null;
  commentsSubdomain: IBookingManagerCommentsSubdomain;
  proposal?: IProposal;
  availableProposals: IProposal[];
  checklistSubdomain: IChecklistSubdomain;
  downloadsSubdomain: IDownloadsSubdomain;
  uploadsSubdomain: IUploadsSubdomain;
  guestInformation: IGuestInformationSubdomain;
  networkRequests: IBookingManagerDomainNetworkRequests;
  uiState: IBookingManagerDomainUi;
  breakdownSubdomain: IBookingManagerBreakdownSubdomain;
  dashboardSubdomain: IBookingManagerDashboardSubdomain;
  bookingStatusOptionsSubdomain: IBookingStatusOptionsSubdomain;
  voucherSubdomain: IVoucherSubdomain;
  financeSubdomain: IBookingManagerFinanceSubdomain;
  taLogoSubdomain: ITaLogoSubdomain;
  hotelDetailsSubdomain: IBookingManagerHotelDetailsSubdomain;
  ownershipSubdomain: IOwnershipSubdomain;
  purchaseCostReviewSubdomain: IPurchaseCostReviewSubdomain;
  settingsSubdomain: ISettingsSubdomain;
  bespokeNegotiationSubdomain: IBespokeNegotiationSubdomain;
  externalIdsSubdomain: IExternalIdsSubdomain;
}

export interface IBookingManagerDomainUi {
  selectedProposalPdfVersion?: IProposalPdfVersion;
  isIncludeCompanyLogoOnGenerate: boolean;
  toProposalEmailAddress: string;
  productTableBreakdownExpandedUuid: {
    transfer: string[];
    'ground service': string[];
    addon: string[];
  };
  proposal: {
    selectedProposalUuid: string;
    newProposalName: string;
    shouldCreateProposal: boolean;
    previewParams: IPreviewParams;
  };
}

export interface IBookingManagerDomainNetworkRequests {
  bookingLoad: ENetworkRequestStatus;
  proposalLoad: ENetworkRequestStatus;
  requestToBook: ENetworkRequestStatus;
  confirm: ENetworkRequestStatus;
  cancel: ENetworkRequestStatus;
  availableProposalsLoad: ENetworkRequestStatus;
}

export const initialState: IBookingManagerDomain = {
  booking: null,
  checklistSubdomain: checklistSubdomainInitialState,
  downloadsSubdomain: downloadsSubdomainInitialState,
  uploadsSubdomain: uploadsSubdomainInitialState,
  voucherSubdomain: voucherSubdomainInitialState,
  financeSubdomain: financeSubdomainInitialState,
  taLogoSubdomain: taLogoSubdomainInitialState,
  hotelDetailsSubdomain: hotelDetailsSubdomainInitialState,
  ownershipSubdomain: ownershipSubdomainInitialState,
  settingsSubdomain: settingsSubdomainInitialState,
  purchaseCostReviewSubdomain: purchaseCostReviewSubdomainInitialState,
  bespokeNegotiationSubdomain: bespokeNegotiationSubdomainInitialState,
  externalIdsSubdomain: externalIdsSubdomainInitialState,
  commentsSubdomain: {
    comments: [],
    publicCommentDraft: '',
    privateCommentDraft: '',
    addBookingCommentLoad: ENetworkRequestStatus.IDLE,
    getBookingCommentsLoad: ENetworkRequestStatus.IDLE,
    bookingCommentUuidsLoading: [],
    bookingCommentUuidsEditing: [],
    bookingCommentUuidsEditingDrafts: {},
  },
  guestInformation: guestInformationInitialState,
  availableProposals: [],
  networkRequests: {
    bookingLoad: ENetworkRequestStatus.IDLE,
    proposalLoad: ENetworkRequestStatus.IDLE,
    requestToBook: ENetworkRequestStatus.IDLE,
    confirm: ENetworkRequestStatus.IDLE,
    cancel: ENetworkRequestStatus.IDLE,
    availableProposalsLoad: ENetworkRequestStatus.IDLE,
  },
  breakdownSubdomain: bookingManagerBreakdownSubdomainInitialState,
  dashboardSubdomain: bookingManagerDashboardSubdomainInitialState,
  bookingStatusOptionsSubdomain: bookingStatusOptionsInitialState,
  uiState: {
    productTableBreakdownExpandedUuid: {
      transfer: [],
      'ground service': [],
      addon: [],
    },
    selectedProposalPdfVersion: undefined,
    isIncludeCompanyLogoOnGenerate: false,
    toProposalEmailAddress: '',
    proposal: {
      selectedProposalUuid: '',
      newProposalName: '',
      shouldCreateProposal: false,
      previewParams: {
        attributesToRewrite: {
          guestFirstName: undefined,
          guestLastName: undefined,
          guestTitle: undefined,
          comments: undefined,
        },
        shouldUseCompanyLogo: false,
      },
    },
  },
};
