import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Actions from './actions';
import { IInventoryHeaderDomain, initialState } from './model';
import { produce } from 'immer';
import { clone } from 'lodash-es';

export const inventoryHeaderReducer = (
  state: IInventoryHeaderDomain = initialState,
  action: Actions.TInventoryHeaderActions
): IInventoryHeaderDomain => {
  switch (action.type) {
    case Actions.SET_IS_MENU_OPEN:
      return produce(state, draftState => {
        draftState.isMenuOpen = action.val;
        return draftState;
      });

    case Actions.SET_INVENTORY_OPTION_SELECTED:
      return produce(state, draftState => {
        draftState.currentPathOptions = [...action.path];
        draftState.lastSetPathOptions = [...action.path];
        draftState.currentURL = action.url;
        draftState.lastSetUrl = action.url;
        return draftState;
      });

    case Actions.RESET_INVENTORY_OPTION_SELECTED:
      return {
        ...initialState,
        lastSetPathOptions: clone(state.lastSetPathOptions),
        lastSetUrl: clone(state.lastSetUrl),
        bookings: clone(state.bookings),
        networkRequests: clone(state.networkRequests),
      };

    case Actions.SEARCH_BOOKINGS_REQUEST:
      return produce(state, draftState => {
        draftState.bookings = [];
        draftState.networkRequests.fetchingBookings = ENetworkRequestStatus.PENDING;
        return draftState;
      });
  
    case Actions.SEARCH_BOOKINGS_SUCCESS:
      return produce(state, draftState => {
        draftState.bookings = action.bookings;
        draftState.networkRequests.fetchingBookings = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });
  
    case Actions.SEARCH_BOOKINGS_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.fetchingBookings = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    default:
      return state;
  }
};

export default inventoryHeaderReducer;
