import { createSelector } from 'reselect';
import { ICompanyDepartmentDomain, ICompanyDepartmentRequests } from './model';
import { ICompanyDepartment } from 'services/BookingManagerApi/types/CompanyDepartmentsResponse';

export const domainSelector = (state: any) => state.companyDepartments;

export const companyDepartmentsSelector = createSelector(
  domainSelector,
  (domain: ICompanyDepartmentDomain): ICompanyDepartment[] | null => domain.departments
);

export const companyDepartmentsRequestsSelector = createSelector(
  domainSelector,
  (domain: ICompanyDepartmentDomain): ICompanyDepartmentRequests => domain.requests
);

export const createDepartmentModalSelector = createSelector(
  domainSelector,
  (domain: ICompanyDepartmentDomain): boolean => domain.modals.isCreateDepartmentModalOpen
);

export const editDepartmentModalSelector = createSelector(
  domainSelector,
  (domain: ICompanyDepartmentDomain): boolean => domain.modals.isEditDepartmentModalOpen
);

export const deleteDepartmentModalSelector = createSelector(
  domainSelector,
  (domain: ICompanyDepartmentDomain): boolean => domain.modals.isDeleteDepartmentModalOpen
);

export const uuidToDeleteSelector = createSelector(
  domainSelector,
  (domain: ICompanyDepartmentDomain): string | null => domain.uuidToDelete
);

export const departmentToEditSelector = createSelector(
  domainSelector,
  (domain: ICompanyDepartmentDomain): ICompanyDepartment | null => domain.departmentToEdit
);
