import { ENetworkRequestStatus } from 'services/BackendApi';
import { IHotelAccommodation, THotelAccommodationViewMode } from './types';

export interface IHotelAccommodationSubdomain {
  activeAccommodationTab: number;
  activeAccommodationViewMode: THotelAccommodationViewMode;
  accommodationLimit: number;
  accommodations: IHotelAccommodation[];
  editAccommodationsModalOpen: boolean;
  networkRequests: {
    accommodationsLoad: ENetworkRequestStatus;

    // indexed by accommodation UUID
    accommodationUpdating: { [key: string]: ENetworkRequestStatus };
  };
  hotelAccommodationUuidsOpen: string[];
  haveModifiedAccommodationWithoutUpdating: boolean;
  showMixRateTypesAlert: boolean;
}

export const hotelAccommodationInitialState: IHotelAccommodationSubdomain = {
  activeAccommodationTab: 0,
  activeAccommodationViewMode: 'accommodation',
  accommodationLimit: 9,
  accommodations: [],
  editAccommodationsModalOpen: false,
  networkRequests: {
    accommodationsLoad: ENetworkRequestStatus.IDLE,
    accommodationUpdating: {},
  },
  hotelAccommodationUuidsOpen: [],
  haveModifiedAccommodationWithoutUpdating: false,
  showMixRateTypesAlert: true,
};
