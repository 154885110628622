import { call, takeLatest, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  GET_HOTELS_BY_CURRENCY_REQUEST,
  getHotelsByCurrencyFailureAction,
  GetHotelsByCurrencyRequestAction,
  getHotelsByCurrencySuccessAction,
} from '../actions';

import { IHotel, makeBackendApi } from 'services/BackendApi';

export function* getHotelsByCurrencySaga(action: GetHotelsByCurrencyRequestAction) {
  try {
    const backendApi = makeBackendApi();

    const result: AxiosResponse<IHotel[]> = yield call(
      backendApi.getHotelsByCurrency,
      action.currency
    );

    yield put(getHotelsByCurrencySuccessAction(result.data));
  } catch (e) {
    yield put(getHotelsByCurrencyFailureAction(e));
  }
}

export function* watchHotelsByCurrency() {
  yield takeLatest([GET_HOTELS_BY_CURRENCY_REQUEST], getHotelsByCurrencySaga);
}
