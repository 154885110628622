import { ENetworkRequestStatus } from 'services/BackendApi';
import { EProposalLang, EProposalLogo, EProposalTemplate, Proposal } from 'services/BookingManagerApi';
import { IProposalSelection } from 'services/BookingManagerApi/types/ProposalSelection';

export interface Guest {
  title: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface HotelComment {
  hotelUuid: string;
  name: string;
  content: string | null;
}

export interface FormData {
  template: EProposalTemplate;
  lang: EProposalLang;
  name: string | null;
  logo: EProposalLogo | null;
  taMarginIncluded: boolean;
  guest: Guest;
  generalComments: string | null;
  otherComments: string | null;
  hotelComments: HotelComment[];
}

export interface IlogoOption {
  value: EProposalLogo | null;
  label: string;
}

export interface IProposalGenerateDomain {
  proposalSelection: IProposalSelection | null;
  proposal: Proposal | null;
  bookingUuids: string[];
  formData: FormData;
  request: {
    selection: ENetworkRequestStatus;
    get: ENetworkRequestStatus;
    save: ENetworkRequestStatus;
    download: ENetworkRequestStatus;
  };
  logoOptions: IlogoOption[];
}

export const formDataInitState = (): FormData => ({
  template: EProposalTemplate.PROPOSAL,
  lang: EProposalLang.EN,
  name: null,
  logo: EProposalLogo.COMPANY,
  taMarginIncluded: false,
  guest: {
    title: null,
    firstName: null,
    lastName: null
  },
  generalComments: null,
  otherComments: null,
  hotelComments: [],
});

export const initialState: IProposalGenerateDomain = {
  proposalSelection: null,
  proposal: null,
  bookingUuids: [],
  formData: formDataInitState(),
  request: {
    selection: ENetworkRequestStatus.IDLE,
    get: ENetworkRequestStatus.IDLE,
    save: ENetworkRequestStatus.IDLE,
    download: ENetworkRequestStatus.IDLE,
  },
  logoOptions: [
    { value: EProposalLogo.COMPANY, label: 'Company Logo' },
    { value: EProposalLogo.MAIN_COMPANY, label: 'Main Company Logo' },
    { value: null, label: 'None' },
  ]
};
