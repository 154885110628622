import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import { TextInput } from 'ui/TextInput';
import { useCurrentWidth } from 'effects';
import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ENetworkRequestStatus } from 'services/BackendApi';
import {
  companyDepartmentsRequestsSelector,
  createCompanyDepartmentRequestAction,
  editCompanyDepartmentRequestAction,
} from 'store/modules/companyDepartments';
import { validateEmailList, sanitizeEmailList } from 'store/modules/companyDepartments/model';
import { StandardModal } from 'pureUi/Modal';
import FluidButton from 'ui/FluidButton';
import { companyDataSelector } from 'store/modules/companyInfo';
import FormLabel from 'ui/FormLabel';
import { DepartmentFormValidationSchema } from './departmentFormValidation';
import { ICompanyDepartment } from 'services/BookingManagerApi/types/CompanyDepartmentsResponse';
import classNames from 'classnames';
import { UITextArea } from 'ui/UITextArea';
import { theme } from '../../../../../tailwind.config';

export interface IEditDepartmentModalProps {
  department: ICompanyDepartment | null;
  onCancel: () => void;
}

const DEPARTMENT_NEW = 'new_department';

export interface IEditDepartmentFormValues {
  companyUuid: string;
  departmentUuid: string;
  departmentName: string;
  responsibleName: string;
  emails: string;
}

const labelStyle: React.CSSProperties = {
  fontSize: '16px',
  fontWeight: 'bold',
};

export const EditDepartmentModal = (props: IEditDepartmentModalProps) => {
  const { department, onCancel } = props;

  const dispatch = useDispatch();
  const company = useSelector(companyDataSelector)!;
  const requests = useSelector(companyDepartmentsRequestsSelector);
  const isLoading =
    requests.departmentSave === ENetworkRequestStatus.PENDING ||
    requests.departmentCreate === ENetworkRequestStatus.PENDING;

  const { isMobile, isMobileLandscape, isTablet, isDesktop, isDesktopHD } = useCurrentWidth();
  const modalWidthClassname = classnames('flex justify-center', {
    'max-w-330px': isMobile || isMobileLandscape,
    'max-w-480px': isTablet || isDesktop || isDesktopHD,
    'min-h-270px': !isLoading,
  });
  const departmentModalContentClassname = classnames('department-modal-content flex flex-col flex-1');

  const initialValues: IEditDepartmentFormValues = {
    departmentUuid: department?.uuid ?? DEPARTMENT_NEW,
    departmentName: department?.name ?? '',
    responsibleName: department?.responsibleName ?? '',
    emails: department?.emails ?? '',
    companyUuid: department?.companyUuid ?? company?.uuid,
  };

  const handleFormSubmit = useCallback(
    (departmentFormValues: IEditDepartmentFormValues, formikHelpers: FormikHelpers<IEditDepartmentFormValues>) => {
      const { departmentUuid, departmentName, responsibleName, emails, companyUuid } = departmentFormValues;
      const sEmails = sanitizeEmailList(emails);
      if (department) {
        dispatch(
          editCompanyDepartmentRequestAction(departmentUuid, companyUuid, departmentName, responsibleName, sEmails)
        );
      } else {
        dispatch(createCompanyDepartmentRequestAction(companyUuid, departmentName, responsibleName, sEmails));
      }

      formikHelpers.setSubmitting(false);
    },
    []
  );

  const fieldContainerClassname = classnames('grid grid-cols-1 gap-x-5');

  return (
    <StandardModal
      className="mass-assign-modal-container font-pt-sans"
      frameClassName="px-35px py-25px rounded"
      showCloseButton={true}
      removePadding={true}
      closeOnOutsideClick={true}
      onClose={onCancel}
    >
      <div className={classnames('department-modal flex justify-center w-screen', modalWidthClassname)}>
        <div className={departmentModalContentClassname}>
          <h3 className="m-0 font-noe-display font-normal text-black text-21px">
            {department ? 'Edit Department' : 'Add Department'}
          </h3>
          <p className="font-pt-sans text-15px leading-22px text-black mb-0">
            Complete the information of the department of the Company:
          </p>

          <div className={classnames({ 'max-w-250px': isMobile || isMobileLandscape })}>
            <Formik
              initialValues={initialValues}
              validationSchema={DepartmentFormValidationSchema}
              enableReinitialize={true}
              validateOnBlur={false}
              onSubmit={handleFormSubmit}
            >
              {(form: FormikProps<IEditDepartmentFormValues>) => {
                return (
                  <form onSubmit={form.handleSubmit}>
                    <div className={fieldContainerClassname}>
                      <div className="flex gap-20px mt-25px">
                        <Field
                          as={TextInput}
                          type="text"
                          name="departmentName"
                          id="departmentName"
                          className="departmentName flex-1"
                          label="Department Name"
                          labelStyle={labelStyle}
                          errorMessage={
                            form.touched.departmentName && form.errors.departmentName
                              ? form.errors.departmentName
                              : null
                          }
                          errorClassName="departmentName-error"
                        />
                        <Field
                          as={TextInput}
                          type="text"
                          name="responsibleName"
                          id="responsibleName"
                          className="responsibleName flex-1"
                          label="Responsible Name"
                          labelStyle={labelStyle}
                          errorMessage={
                            form.touched.responsibleName && form.errors.responsibleName
                              ? form.errors.responsibleName
                              : null
                          }
                          errorClassName="responsibleName-error"
                        />
                      </div>

                      <div className="flex mt-25px">
                        <Field name="emails" validate={validateEmailList}>
                          {({ field: { name, value }, form: { setFieldValue } }) => {
                            const showError = form.touched.emails && !!form.errors.emails;
                            return (
                              <FormLabel className="emails-label w-full" textWrapperStyle={labelStyle} text="Emails">
                                <UITextArea
                                  rows={2} 
                                  className="department-emails max-h-70px" 
                                  value={value} 
                                  onChange={value => {
                                    setFieldValue(name, value);
                                  }}
                                  errorMessage={showError ? form.errors.emails : undefined}
                                />
                              </FormLabel>
                            );
                          }}
                        </Field>
                      </div>
                      <p className="font-pt-sans text-13px leading-18px text-gray-100 mb-0 mt-5px">
                        Add multiple email addresses separating addresses with &quot;, &quot; sign.
                      </p>
                      <p className="font-pt-sans text-13px leading-18px text-gray-100 mb-0 mt-5px">
                        <span className="underline">Sample.</span> name1@mail.com, name2@mail.com
                      </p>

                      <div className="department-buttons flex gap-10px mt-25px">
                        <FluidButton className="department-cancel" type="secondary" onClick={onCancel}>
                          Cancel
                        </FluidButton>
                        <FluidButton
                          className="department-save"
                          type="primary"
                          onClick={() => form.submitForm()}
                          isLoading={isLoading}
                          disabled={!form.isValid || isLoading}
                        >
                          Save
                        </FluidButton>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </StandardModal>
  );
};
