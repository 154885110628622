import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import SingleDateInput from 'pureUi/SingleDateInput';
import { addDays, addYears, subDays } from 'date-fns';
import { PriceAsCentsInput } from 'ui/stateful/PriceAsCentsInput';
import FluidButton from 'ui/FluidButton';
import SingleSelect from 'ui/SingleSelect';
import { formatDate } from 'utils';
import { getDepositAccountTypeOptions } from './depositAccountTypeOptions';
import { FilePicker } from '../FilePicker';
import { RowTypeSelect } from '../RowTypeSelect';
import { colors } from 'pureUi/pureUiTheme';
import PredictiveTextInput from 'pureUi/PredictiveTextInput';
import { searchCompanyByNameAction, showCompanyDropdownAction } from 'store/modules/agents/actions';
import {
  depositAccountCurrencySelector,
  depositAccountCurrencySymbolSelector,
  depositAccountModalSelector,
} from 'store/modules/ledger/selectors';
import {
  companiesSelector,
  isFetchingCompaniesSelector,
  companyNameSearchSelector,
  showCompanyDropdownSelector,
  companyNamesSelector,
} from 'store/modules/agents/selectors';
import { getCompaniesRequestAction } from 'store/modules/agents/actions';
import { useSelector } from 'react-redux';
import { IDepositAccountRowForSave } from 'store/modules/ledger/model';
import { EDepositAccountRowType } from 'services/BookingManagerApi';
import {
  isDepositAccountRowAdjustment,
  isDepositAccountRowInitial,
  isDepositAccountRowNegative,
  isDepositAccountRowPositive,
} from 'store/modules/ledger/utils';
import { ENetworkRequestStatus } from 'services/BackendApi';
import PiggyBankIcon from '../Icons/piggyBank.component.svg';
import { theme } from '../../../tailwind.config';
import TextInput from 'pureUi/TextInput';
import { EBankAccount } from 'interfaces';
import { ICompany } from 'services/BackendApi';
import { getDepositPaymentMethodsRequestAction } from 'store/modules/ledger/actions';

export interface IDepositAccountModalProps {
  onSubmit: (newRowWithoutFiles: IDepositAccountRowForSave, files: File[]) => void;
  isSubmitButtonLoading: boolean;
  depositAccountBalance: string;
  depositAccountBalanceLoadingStatus: ENetworkRequestStatus;
  isModalLoading: boolean;
  onClose: () => void;
  selectedCompanyName: string;
}

export const StyledSingleDateInput = styled(SingleDateInput)`
  .pseudoSelect {
    height: 35px;
    background-color: ${colors.ivory};
  }
`;

export const DepositAccountModal: React.FC<IDepositAccountModalProps> = ({
  onClose,
  isSubmitButtonLoading,
  depositAccountBalance,
  depositAccountBalanceLoadingStatus,
  isModalLoading,
  onSubmit,
  selectedCompanyName,
}) => {
  const dispatch = useDispatch();
  const depositAccountModal = useSelector(depositAccountModalSelector);
  const currency = useSelector(depositAccountCurrencySelector);
  const currencySymbol = useSelector(depositAccountCurrencySymbolSelector);
  const initialBCFRowLoading = depositAccountModal.requests.initialBCFRowLoad === ENetworkRequestStatus.PENDING;
  const preselectedType = depositAccountModal.data?.type;
  const isEditMode = preselectedType !== undefined;
  const paymentMethods  = depositAccountModal.paymentMethods;
  const paymentMethodsLoading = depositAccountModal.requests.getPaymentMethods === ENetworkRequestStatus.PENDING;
  const companies = useSelector(companiesSelector) || [];
  const companiesNames = useSelector(companyNamesSelector).filter(cn => cn !== selectedCompanyName);
  const companyNameSearch = useSelector(companyNameSearchSelector);
  const showCompanyDropdown = useSelector(showCompanyDropdownSelector);
  const fetchingCompanies = useSelector(isFetchingCompaniesSelector);
  const [selectedReceiverCompany, setSelectedReceiverCompany] = useState<ICompany | null>(null);

  const [stateRowType, setStateRowType] = useState<EDepositAccountRowType | null>(preselectedType ?? null);
  const [stateDescription, setDescription] = useState<string>(depositAccountModal.data?.notes || '');
  const [stateDate, setDate] = useState<string | null>(depositAccountModal.data?.date || null);
  const [stateAmount, setAmount] = useState<number>(depositAccountModal.data?.amountCents || 0);
  const [stateUploadName, setUploadName] = useState<string | null>(depositAccountModal.data?.uploadName || null);
  const [stateFiles, setStateFiles] = useState<File[]>([]);
  const [stateBankAccount, setBankAccount] = useState<EBankAccount | null>(
    depositAccountModal.data?.bankAccount || null
  );

  const initialBCFRowExists =
    depositAccountModal.initialBCFRowExists || (!!preselectedType && isDepositAccountRowAdjustment(preselectedType));
  const typeOptions = useMemo(() => {
    // OWA-5134. Remove the 'transfer between TCs (IN)' as now will be automatically created.
    return getDepositAccountTypeOptions(initialBCFRowExists, currency).filter(
      e => e.value !== EDepositAccountRowType.Transfer_Between_Travel_Companies_IN
    );
  }, [currency, initialBCFRowExists]);

  const paymentMethodOptions = useMemo(() =>
    paymentMethods.list?.map(x => ({
      value: x.name,
      label: x.name,
    })) || [],
    [paymentMethods.list]
  );

  const selectedPaymentMethod = useMemo(() =>
    paymentMethods.list?.find(x => x.name === stateBankAccount),
    [paymentMethods.list, stateBankAccount]
  );

  const minDate =
    !!stateRowType &&
    !isDepositAccountRowInitial(stateRowType) &&
    initialBCFRowExists &&
    depositAccountModal.firstRowDate
      ? addDays(new Date(depositAccountModal.firstRowDate), 1)
      : addYears(new Date(), -150);
  const maxDate =
    !!stateRowType &&
    isDepositAccountRowInitial(stateRowType) &&
    !initialBCFRowExists &&
    depositAccountModal.firstRowDate
      ? subDays(new Date(depositAccountModal.firstRowDate), 1)
      : addYears(new Date(), 150);

  const isDateSet = stateDate != null;
  const isReceiverCompanyPending =
    stateRowType === EDepositAccountRowType.Transfer_Between_Travel_Companies_OUT && !selectedReceiverCompany;
  let allMandatoryFieldsSet = isDateSet && !!stateRowType && !!stateDescription && !isReceiverCompanyPending;
  // these types require the bank account to be set
  if (
    (stateRowType === EDepositAccountRowType.Transfer_In || stateRowType === EDepositAccountRowType.Transfer_Out) &&
    stateBankAccount === null
  ) {
    allMandatoryFieldsSet = false;
  }
  const isSubmitButtonDisabled = useMemo(() => {
    return isSubmitButtonLoading || isModalLoading || !allMandatoryFieldsSet;
  }, [allMandatoryFieldsSet, isModalLoading, isSubmitButtonLoading]);

  useEffect(() => {
    const currentDateAfterMaxDate = stateDate && new Date(stateDate) > maxDate;
    const currentDateBeforeMinDate = stateDate && new Date(stateDate) < minDate;
    if (currentDateBeforeMinDate || currentDateAfterMaxDate) {
      setDate(null);
    }
  }, [maxDate, minDate, stateDate]);

  useEffect(() => {
    if (!companies) {
      dispatch(getCompaniesRequestAction());
    }
  }, [companies, dispatch]);

  useEffect(() => {
    dispatch(getDepositPaymentMethodsRequestAction());
  }, []);

  const handleSearchCompanyByName = useCallback(
    (value: string) => {
      dispatch(searchCompanyByNameAction(value));
    },
    [dispatch]
  );

  const handleCompanyDropdownChange = useCallback(
    value => {
      dispatch(showCompanyDropdownAction(value));
    },
    [dispatch]
  );

  const handleChange = useCallback(
    (value: string) => {
      dispatch(searchCompanyByNameAction(value));
    },
    [dispatch]
  );

  const handleSelect = useCallback(
    name => {
      const selectedCompany = companies.find(c => c.name === name) || null;
      handleChange(selectedCompany?.name || '');
      if (selectedCompany?.uuid) {
        setSelectedReceiverCompany(selectedCompany);
      } else {
        setSelectedReceiverCompany(null); // Clear the Company selection when All Companies is selected
      }
    },
    [companies, handleChange]
  );

  const handleBlur = useCallback(() => {
    handleCompanyDropdownChange(false);
    if (!companyNameSearch) {
      handleSelect('All Companies');
    }
  }, [handleCompanyDropdownChange, handleSelect, companyNameSearch]);

  const handleFileRemove = useCallback(() => {
    setUploadName(null);
  }, []);

  const handleFileSelect = useCallback((files: File[]) => {
    setStateFiles(files);
    const newUploadName = files.length > 0 ? files[0].name : null;
    setUploadName(newUploadName);
  }, []);

  const handleRowTypeSelect = useCallback((values: EDepositAccountRowType[]) => {
    setStateRowType(values[0]);
  }, []);

  const handleAddClick = useCallback(() => {
    if (!stateDate || !stateRowType) {
      return;
    }

    const fileNotChanged = stateUploadName && stateUploadName === depositAccountModal.data?.uploadName;
    const newRow: IDepositAccountRowForSave = {
      uuid: depositAccountModal.data?.uuid,
      date: stateDate,
      type: stateRowType,
      amountCents: stateAmount,
      uploadUuid: fileNotChanged ? depositAccountModal.data?.uploadUuid : undefined,
      uploadName: fileNotChanged ? depositAccountModal.data?.uploadName : undefined,
      uploadUrl: fileNotChanged ? depositAccountModal.data?.uploadUrl : undefined,
      notes: stateDescription,
      bankAccount: stateBankAccount?.toString(),
      paymentReceiverCompanyUuid: selectedReceiverCompany?.uuid,
    };

    onSubmit(newRow, stateFiles);
  }, [
    stateDate,
    stateRowType,
    stateUploadName,
    depositAccountModal.data,
    stateAmount,
    stateDescription,
    stateBankAccount,
    onSubmit,
    stateFiles,
    selectedReceiverCompany,
  ]);

  const handleDescriptionChange = useCallback(e => {
    setDescription(e.currentTarget.value);
  }, []);

  const handlePaymentMethodChange = useCallback((bankAccount: EBankAccount) => {
    setBankAccount(bankAccount);
  }, []);
  
  return (
    <div>
      <h3 className="modal-title m-0 font-noe-display mb-4 font-normal text-21px">Add Deposit Row</h3>

      <div className="deposit-balance-container flex flex-col mt-20px mb-20px border-solid border-b border-b-gray-20 pb-10px">
        <p className="deposit-balance-title font-pt-sans font-bold text-base leading-21px text-black m-0">
          {currency} {currencySymbol} Deposit Balance
        </p>
        <p className="deposit-balance-number font-pt-sans font-bold text-19px leading-25px text-black mt-8px mb-0 flex items-center">
          {depositAccountBalanceLoadingStatus === ENetworkRequestStatus.PENDING && (
            <i className="fas fa-circle-notch fa-spin text-brown-140"></i>
          )}
          {depositAccountBalanceLoadingStatus === ENetworkRequestStatus.SUCCESS && (
            <>
              <PiggyBankIcon fill={theme.colors['black']} />
              <span className="deposit-account-balance ml-5px">{depositAccountBalance}</span>
            </>
          )}
        </p>
      </div>

      <RowTypeSelect
        className="deposit-account-row-type"
        selectedValue={stateRowType}
        isSelectedValuePositive={stateRowType ? isDepositAccountRowPositive(stateRowType) : false}
        isSelectedValueNegative={stateRowType ? isDepositAccountRowNegative(stateRowType) : false}
        options={typeOptions}
        onSelect={handleRowTypeSelect}
      />

      {stateRowType === EDepositAccountRowType.Transfer_Between_Travel_Companies_OUT && (
        <label className="deposit-account-search-company">
          <span>Travel Company</span>
          {fetchingCompanies ? (
            <span>Loading companies...</span>
          ) : (
            <PredictiveTextInput
              placeholder="Select company..."
              value={companyNameSearch}
              onChange={e => handleSearchCompanyByName(e.currentTarget.value)}
              options={[['All Companies'].concat(companiesNames)]}
              onOptionSelect={handleSelect}
              showDropDown={showCompanyDropdown}
              onFocus={() => handleCompanyDropdownChange(true)}
              onBlur={handleBlur}
            />
          )}
        </label>
      )}

      {(stateRowType === EDepositAccountRowType.Transfer_Out ||
        stateRowType === EDepositAccountRowType.Transfer_In) && (
        <div className="flex mt-5">
          <label className="deposit-account-bank-account block w-full">
            <SingleSelect
              fieldId="payment-method"
              className="payment-method"
              inputClassName="py-[1px]"
              label="Payment Method"
              labelClassName="text-[16px] leading-[21px] font-bold"
              value={stateBankAccount || undefined}
              options={paymentMethodOptions}
              onChange={handlePaymentMethodChange}
              loading={paymentMethodsLoading}
              maxVisibleItems={5}
              hasLegacyLook
            />
            {!!selectedPaymentMethod?.warning && (
              <div className="mt-[5px] min-h-[100px] max-h-[100px] overflow-y-scroll max-w-fit border border-solid border-gray-20 rounded p-4 font-pt-sans leading-xs text-13px text-black">
                {selectedPaymentMethod.warning}
              </div>
            )}
          </label>
        </div>
      )}

      <div className="flex mt-5">
        <label className="deposit-account-description block w-full">
          <span className="block mb-5px text-black text-base font-bold leading-21px tracking-2xs font-pt-sans">
            Description
          </span>
          <TextInput
            value={stateDescription}
            inputClassName="deposit-account-description-input"
            onChange={handleDescriptionChange}
          />
        </label>
      </div>

      {/* date and amount */}
      <div className="deposit-account-date-container flex mt-5">
        <label style={{ width: '122px' }}>
          <span className="block mb-5px text-black text-base font-bold leading-21px tracking-2xs font-pt-sans">
            Date
          </span>

          {isModalLoading || initialBCFRowLoading ? (
            <div className="date-loading inline-flex justify-center items-center">
              <i className="text-xl fas fa-circle-notch fa-spin text-gray-30" />
            </div>
          ) : (
            <StyledSingleDateInput
              className="deposit-account-row-date"
              value={stateDate ? new Date(stateDate) : null}
              onChange={value => {
                setDate(formatDate(value));
              }}
              showYearDropdown
              enablePastDates
              minDate={minDate}
              maxDate={maxDate}
            />
          )}
        </label>

        <label className="deposit-account-row-amount w-116px block ml-20px">
          <span className="block mb-5px text-black text-base font-bold leading-21px tracking-2xs font-pt-sans">
            Amount
          </span>
          <PriceAsCentsInput
            cents={Math.abs(stateAmount)}
            className="deposit-amount focus:outline-gray-80 p-2 border border-solid border-gray-40 min-h-35px w-full font-pt-sans text-sm bg-ivory"
            disabled={false}
            onBlurUpdate={v => setAmount(v)}
          />
        </label>
      </div>

      <FilePicker
        isAttachmentRequired={false}
        uploadName={depositAccountModal.data?.uploadName}
        onFileSelect={handleFileSelect}
        onFileRemove={handleFileRemove}
      />

      <div className="deposit-account-buttons flex justify-start mt-25px">
        <FluidButton type="secondary" className="deposit-account-modal-cancel" onClick={onClose}>
          Cancel
        </FluidButton>
        <FluidButton
          type="primary"
          className="deposit-account-modal-save ml-10px"
          isLoading={isSubmitButtonLoading}
          disabled={isSubmitButtonDisabled}
          onClick={handleAddClick}
        >
          {isEditMode ? 'Save' : 'Add'}
        </FluidButton>
      </div>
    </div>
  );
};
