import { IHotelNameItem, HotelNameResponse as Hotel, CountryNameResponse as Country } from 'services/BackendApi';
import { BookingSearchItem, EPurchaseCostReviewStatus, ERateSource, ISalesRepresentative } from 'services/BookingManagerApi';
import { EBookingType } from 'services/BookingManagerApi/types/BookingTypeResponse';

import { BookingListProductTypesFilter, DateRangeTypes, ExportType } from './model';

export const GET_BOOKING_LIST_REQUEST = 'bookingList/GET_BOOKING_LIST_REQUEST';
export const GET_BOOKING_LIST_SUCCESS = 'bookingList/GET_BOOKING_LIST_SUCCESS';
export const GET_BOOKING_LIST_FAILURE = 'bookingList/GET_BOOKING_LIST_FAILURE';

export const GET_DESTINATION_LIST_REQUEST = 'bookingList/GET_DESTINATION_LIST_REQUEST';
export const GET_DESTINATION_LIST_SUCCESS = 'bookingList/GET_DESTINATION_LIST_SUCCESS';
export const GET_DESTINATION_LIST_FAILURE = 'bookingList/GET_DESTINATION_LIST_FAILURE';

export const GET_SALES_REP_LIST_REQUEST = 'bookingList/GET_SALES_REP_LIST_REQUEST';
export const GET_SALES_REP_LIST_SUCCESS = 'bookingList/GET_SALES_REP_LIST_SUCCESS';
export const GET_SALES_REP_LIST_FAILURE = 'bookingList/GET_SALES_REP_LIST_FAILURE';

export const SET_HUMAN_READABLE_ID_FILTER = 'bookingList/SET_HUMAN_READABLE_ID_FILTER';
export const SET_GUEST_NAME_FILTER = 'bookingList/SET_GUEST_NAME_FILTER';
export const SET_TRAVEL_PARTNER_REF_FILTER = 'bookingList/SET_TRAVEL_PARTNER_REF_FILTER';

export const SET_PAGE_NUMBER = 'bookingList/SET_PAGE_NUMBER';
export const SET_ITEMS_PER_PAGE = 'bookingList/SET_ITEMS_PER_PAGE';
export const SET_SORT = 'bookingList/SET_SORT';
export const SET_TRAVEL_AGENT_UUID = 'bookingList/SET_TRAVEL_AGENT_UUID';
export const SET_TRAVEL_AGENT_COUNTRY_CODE = 'bookingList/SET_TRAVEL_AGENT_COUNTRY_CODE';
export const SET_SALES_REPRESENTATIVE_UUID = 'bookingList/SET_SALES_REPRESENTATIVE_UUID';
export const SET_COMPANY_UUID = 'bookingList/SET_COMPANY_UUID';
export const SET_COMPANY_NAME_SEARCH = 'bookingList/SET_COMPANY_NAME_SEARCH';
export const SET_DESTINATION = 'bookingList/SET_DESTINATION';
export const SET_STATUSES = 'bookingList/SET_STATUSES';
export const SET_BOOKING_TYPE = 'bookingList/SET_BOOKING_TYPE';
export const SET_PURCHASE_COST_REVIEW_STATUS = 'bookingList/SET_PURCHASE_COST_REVIEW_STATUS';
export const SET_ONLY_SHOW_WITH_INVOICE_FILTER = 'bookingList/SET_ONLY_SHOW_WITH_INVOICE_FILTER';
export const SET_IS_VIEW_ONLY_ASSIGNED_TO_ME_FILTER = 'bookingList/SET_IS_VIEW_ONLY_ASSIGNED_TO_ME_FILTER';
export const SET_DATE_RANGE_TYPE = 'bookingList/SET_DATE_RANGE_TYPE';
export const SET_DATE_RANGE_VALUE = 'bookingList/SET_DATE_RANGE_VALUE';
export const CLEAR_DATE_RANGE_FILTER = 'bookingList/CLEAR_DATE_RANGE_FILTER';
export const SET_PRODUCT_TYPES_FILTER = 'bookingList/SET_PRODUCT_TYPES_FILTER';
export const SET_PRODUCT_CONFIRMATION_NUMBER_FILTER = 'bookingList/SET_PRODUCT_CONFIRMATION_NUMBER_FILTER';

export const RESET_FILTERS = 'bookingList/RESET_FILTERS';

// export stuff
// triggering the export
export const TRIGGER_BOOKING_LIST_EXPORT_REQUEST = 'bookingList/TRIGGER_BOOKING_LIST_EXPORT_REQUEST';
export const TRIGGER_BOOKING_LIST_EXPORT_SUCCESS = 'bookingList/TRIGGER_BOOKING_LIST_EXPORT_SUCCESS';
export const TRIGGER_BOOKING_LIST_EXPORT_FAILURE = 'bookingList/TRIGGER_BOOKING_LIST_EXPORT_FAILURE';

// we start polling the GET endpoint
export const POLL_BOOKING_LIST_EXPORT_REQUEST = 'bookingList/POLL_BOOKING_LIST_EXPORT_REQUEST';
export const POLL_BOOKING_LIST_EXPORT_SUCCESS = 'bookingList/POLL_BOOKING_LIST_EXPORT_SUCCESS';
export const POLL_BOOKING_LIST_EXPORT_CONTINUE = 'bookingList/POLL_BOOKING_LIST_EXPORT_CONTINUE';
export const POLL_BOOKING_LIST_EXPORT_FAILURE = 'bookingList/POLL_BOOKING_LIST_EXPORT_FAILURE';

export const CANCEL_BOOKING_LIST_EXPORT = 'bookingList/CANCEL_BOOKING_LIST_EXPORT';
export const SET_ONLY_SHOW_WITH_GROUND_SERVICES_FILTER = 'bookingList/SET_ONLY_SHOW_WITH_GROUND_SERVICES_FILTER';
export const SET_HAS_PROFORMA_CREATED_FILTER = 'bookingList/SET_HAS_PROFORMA_CREATED_FILTER';
export const SET_HAS_INVOICE_CREATED_FILTER = 'bookingList/SET_HAS_INVOICE_CREATED_FILTER';
export const SET_SHOW_FULLY_PAID_BY_TA_FILTER = 'bookingList/SET_SHOW_FULLY_PAID_BY_TA_FILTER';
export const SET_HAS_OVERDUE_PAYMENTS_FILTER = 'bookingList/SET_HAS_OVERDUE_PAYMENTS_FILTER';
export const SET_ONLY_SHOW_MY_BOOKINGS_FILTER = 'bookingList/SET_ONLY_SHOW_MY_BOOKINGS_FILTER';
export const SET_RATE_SOURCE_FILTER = 'bookingList/SET_RATE_SOURCE_FILTER';
export const SET_HAS_HOTEL_COMMISSION_AFTER_CHECKOUT_FILTER =
  'bookingList/SET_HAS_HOTEL_COMMISSION_AFTER_CHECKOUT_FILTER';

export const SET_IS_REFETCHING_BOOKINGS = 'bookingList/SET_IS_REFETCHING_BOOKINGS';

export type GetBookingListRequestAction = ReturnType<typeof getBookingListRequestAction>;
export const getBookingListRequestAction = () => ({
  type: GET_BOOKING_LIST_REQUEST as typeof GET_BOOKING_LIST_REQUEST,
});

export type GetBookingListSuccessAction = ReturnType<typeof getBookingListSuccessAction>;
export const getBookingListSuccessAction = (bookings: BookingSearchItem[], totalResults: number) => ({
  type: GET_BOOKING_LIST_SUCCESS as typeof GET_BOOKING_LIST_SUCCESS,
  bookings,
  totalResults,
});

export type GetBookingListFailureAction = ReturnType<typeof getBookingListFailureAction>;
export const getBookingListFailureAction = (error: string) => ({
  type: GET_BOOKING_LIST_FAILURE as typeof GET_BOOKING_LIST_FAILURE,
  error,
});

export type GetDestinationListRequestAction = ReturnType<typeof getDestinationListRequestAction>;
export const getDestinationListRequestAction = () => ({
  type: GET_DESTINATION_LIST_REQUEST as typeof GET_DESTINATION_LIST_REQUEST,
});

export type GetDestinationListSuccessAction = ReturnType<typeof getDestinationListSuccessAction>;
export const getDestinationListSuccessAction = (hotels: Hotel[], countries: Country[]) => ({
  type: GET_DESTINATION_LIST_SUCCESS as typeof GET_DESTINATION_LIST_SUCCESS,
  hotels,
  countries,
});

export type GetDestinationListFailureAction = ReturnType<typeof getDestinationListFailureAction>;
export const getDestinationListFailureAction = (error: any) => ({
  type: GET_DESTINATION_LIST_FAILURE as typeof GET_DESTINATION_LIST_FAILURE,
  error,
});

export type GetSalesRepListRequestAction = ReturnType<typeof getSalesRepListRequestAction>;
export const getSalesRepListRequestAction = () => ({
  type: GET_SALES_REP_LIST_REQUEST as typeof GET_SALES_REP_LIST_REQUEST,
});

export type GetSalesRepListSuccessAction = ReturnType<typeof getSalesRepListSuccessAction>;
export const getSalesRepListSuccessAction = (salesRepList: ISalesRepresentative[]) => ({
  type: GET_SALES_REP_LIST_SUCCESS as typeof GET_SALES_REP_LIST_SUCCESS,
  salesRepList,
});

export type GetSalesRepListFailureAction = ReturnType<typeof getSalesRepListFailureAction>;
export const getSalesRepListFailureAction = (error: any) => ({
  type: GET_SALES_REP_LIST_FAILURE as typeof GET_SALES_REP_LIST_FAILURE,
  error,
});

export type SetHumanReadableIdFilterAction = ReturnType<typeof setHumanReadableIdFilterAction>;
export const setHumanReadableIdFilterAction = (humanReadableIdFilter: string | null) => ({
  type: SET_HUMAN_READABLE_ID_FILTER as typeof SET_HUMAN_READABLE_ID_FILTER,
  humanReadableIdFilter,
});

export type SetGuestNameFilterAction = ReturnType<typeof setGuestNameFilterAction>;
export const setGuestNameFilterAction = (guestNameFilter: string | null) => ({
  type: SET_GUEST_NAME_FILTER as typeof SET_GUEST_NAME_FILTER,
  guestNameFilter,
});

export type SetTravelPartnerRefFilterAction = ReturnType<typeof setTravelPartnerRefFilterAction>;
export const setTravelPartnerRefFilterAction = (travelPartnerRefFilter: string | null) => ({
  type: SET_TRAVEL_PARTNER_REF_FILTER as typeof SET_TRAVEL_PARTNER_REF_FILTER,
  travelPartnerRefFilter,
});

export type SetPageNumberAction = ReturnType<typeof setPageNumberAction>;
export const setPageNumberAction = (pageNumber: number) => ({
  type: SET_PAGE_NUMBER as typeof SET_PAGE_NUMBER,
  pageNumber,
});

export type SetItemsPerPageAction = ReturnType<typeof setItemsPerPageAction>;
export const setItemsPerPageAction = (itemsPerPage: number) => ({
  type: SET_ITEMS_PER_PAGE as typeof SET_ITEMS_PER_PAGE,
  itemsPerPage,
});

export type SetSortAction = ReturnType<typeof setSortAction>;
export const setSortAction = (sortBy: keyof BookingSearchItem, sortOrder: 'asc' | 'desc') => ({
  type: SET_SORT as typeof SET_SORT,
  sortBy,
  sortOrder,
});

export type SetSelectedTravelAgentAction = ReturnType<typeof setSelectedTravelAgentAction>;
export const setSelectedTravelAgentAction = (travelAgentUuid: string) => ({
  type: SET_TRAVEL_AGENT_UUID as typeof SET_TRAVEL_AGENT_UUID,
  travelAgentUuid,
});

export type SetSelectedTravelAgentCountryCodeAction = ReturnType<typeof setSelectedTravelAgentCountryCodeAction>;
export const setSelectedTravelAgentCountryCodeAction = (travelAgentCountryCode: string) => ({
  type: SET_TRAVEL_AGENT_COUNTRY_CODE as typeof SET_TRAVEL_AGENT_COUNTRY_CODE,
  travelAgentCountryCode,
});

export type SetSelectedBookingTypeAction = ReturnType<typeof setSelectedBookingTypeAction>;
export const setSelectedBookingTypeAction = (bookingType: string) => ({
  type: SET_BOOKING_TYPE as typeof SET_BOOKING_TYPE,
  bookingType,
});

export type SetSelectedCompanyAction = ReturnType<typeof setSelectedCompanyAction>;
export const setSelectedCompanyAction = (companyUuid: string) => ({
  type: SET_COMPANY_UUID as typeof SET_COMPANY_UUID,
  companyUuid,
});

export type SetCompanyNameAction = ReturnType<typeof setCompanyNameAction>;
export const setCompanyNameAction = (companyNameSearch: string) => ({
  type: SET_COMPANY_NAME_SEARCH as typeof SET_COMPANY_NAME_SEARCH,
  companyNameSearch,
});

export type SetSelectedDestinationAction = ReturnType<typeof setSelectedDestinationAction>;
export const setSelectedDestinationAction = (destination: string) => ({
  type: SET_DESTINATION as typeof SET_DESTINATION,
  destination,
});

export type SetOnlyShowWithInvoiceFilterAction = ReturnType<typeof setOnlyShowWithInvoiceFilterAction>;
export const setOnlyShowWithInvoiceFilterAction = (onlyShowWithInvoiceFilter: boolean) => ({
  type: SET_ONLY_SHOW_WITH_INVOICE_FILTER as typeof SET_ONLY_SHOW_WITH_INVOICE_FILTER,
  onlyShowWithInvoiceFilter,
});

export type SetOnlyShowWithGroundServicesFilterAction = ReturnType<typeof setOnlyShowWithGroundServicesFilterAction>;
export const setOnlyShowWithGroundServicesFilterAction = (onlyShowWithGroundServicesFilter: boolean | null) => ({
  type: SET_ONLY_SHOW_WITH_GROUND_SERVICES_FILTER as typeof SET_ONLY_SHOW_WITH_GROUND_SERVICES_FILTER,
  onlyShowWithGroundServicesFilter,
});

export type SetHasOverduePaymentsFilterAction = ReturnType<typeof setHasOverduePaymentsFilterAction>;
export const setHasOverduePaymentsFilterAction = (hasOverduePaymentsFilter: boolean | null) => ({
  type: SET_HAS_OVERDUE_PAYMENTS_FILTER as typeof SET_HAS_OVERDUE_PAYMENTS_FILTER,
  hasOverduePaymentsFilter,
});

export type SetHasHotelCommissionAfterCheckoutFilterAction = ReturnType<
  typeof setHasHotelCommissionAfterCheckoutFilterAction
>;
export const setHasHotelCommissionAfterCheckoutFilterAction = (
  hasHotelCommissionAfterCheckoutFilter: boolean | null
) => ({
  type: SET_HAS_HOTEL_COMMISSION_AFTER_CHECKOUT_FILTER as typeof SET_HAS_HOTEL_COMMISSION_AFTER_CHECKOUT_FILTER,
  hasHotelCommissionAfterCheckoutFilter,
});

export type SetOnlyShowMyBookingsFilterAction = ReturnType<typeof setOnlyShowMyBookingsFilterAction>;
export const setOnlyShowMyBookingsFilterAction = (onlyShowMyBookingsFilter: boolean | null) => ({
  type: SET_ONLY_SHOW_MY_BOOKINGS_FILTER as typeof SET_ONLY_SHOW_MY_BOOKINGS_FILTER,
  onlyShowMyBookingsFilter,
});

export type SetRateSourceFilterAction = ReturnType<typeof setRateSourceFilterAction>;
export const setRateSourceFilterAction = (rateSourceFilter: ERateSource[] | null) => ({
  type: SET_RATE_SOURCE_FILTER as typeof SET_RATE_SOURCE_FILTER,
  rateSourceFilter,
});

export type SetHasProformaCreatedFilterAction = ReturnType<typeof setHasProformaCreatedFilterAction>;
export const setHasProformaCreatedFilterAction = (hasProformaCreated: boolean | null) => ({
  type: SET_HAS_PROFORMA_CREATED_FILTER as typeof SET_HAS_PROFORMA_CREATED_FILTER,
  hasProformaCreated,
});

export type SetHasInvoiceCreatedFilterAction = ReturnType<typeof setHasInvoiceCreatedFilterAction>;
export const setHasInvoiceCreatedFilterAction = (hasInvoiceCreated: boolean | null) => ({
  type: SET_HAS_INVOICE_CREATED_FILTER as typeof SET_HAS_INVOICE_CREATED_FILTER,
  hasInvoiceCreated,
});

export type SetFullyPaidByTaFilterAction = ReturnType<typeof setFullyPaidByTaFilterAction>;
export const setFullyPaidByTaFilterAction = (showFullyPaidByTaFilter: boolean | null) => ({
  type: SET_SHOW_FULLY_PAID_BY_TA_FILTER as typeof SET_SHOW_FULLY_PAID_BY_TA_FILTER,
  showFullyPaidByTaFilter,
});

export type SetIsViewOnlyAssignedToMeFilterAction = ReturnType<typeof setIsViewOnlyAssignedToMeFilterAction>;
export const setIsViewOnlyAssignedToMeFilterAction = (isViewOnlyAssignedToMeFilter: boolean) => ({
  type: SET_IS_VIEW_ONLY_ASSIGNED_TO_ME_FILTER as typeof SET_IS_VIEW_ONLY_ASSIGNED_TO_ME_FILTER,
  isViewOnlyAssignedToMeFilter,
});

export type SetDateRangeTypeAction = ReturnType<typeof setDateRangeTypeAction>;
export const setDateRangeTypeAction = (dateRangeType: DateRangeTypes) => ({
  type: SET_DATE_RANGE_TYPE as typeof SET_DATE_RANGE_TYPE,
  dateRangeType,
});

export type SetDateRangeValueAction = ReturnType<typeof setDateRangeValueAction>;
export const setDateRangeValueAction = (startDate: string, endDate: string) => ({
  type: SET_DATE_RANGE_VALUE as typeof SET_DATE_RANGE_VALUE,
  startDate,
  endDate,
});

export type ClearDateRangeFilterAction = ReturnType<typeof clearDateRangeFilterAction>;
export const clearDateRangeFilterAction = () => ({
  type: CLEAR_DATE_RANGE_FILTER as typeof CLEAR_DATE_RANGE_FILTER,
});

export type SetSelectedStatusesAction = ReturnType<typeof setSelectedStatusesAction>;
export const setSelectedStatusesAction = (statuses: string[]) => ({
  type: SET_STATUSES as typeof SET_STATUSES,
  statuses,
});

export type SetSelectedPurchaseCostReviewStatusAction = ReturnType<typeof setSelectedPurchaseCostReviewStatusAction>;
export const setSelectedPurchaseCostReviewStatusAction = (status: EPurchaseCostReviewStatus | null) => ({
  type: SET_PURCHASE_COST_REVIEW_STATUS as typeof SET_PURCHASE_COST_REVIEW_STATUS,
  status,
});

export type SetSelectedSalesRepresentativeAction = ReturnType<typeof setSelectedSalesRepresentativeAction>;
export const setSelectedSalesRepresentativeAction = (salesRepresentativeUuid: string) => ({
  type: SET_SALES_REPRESENTATIVE_UUID as typeof SET_SALES_REPRESENTATIVE_UUID,
  salesRepresentativeUuid,
});

export type TriggerBookingListExportRequestAction = ReturnType<typeof triggerBookingListExportRequestAction>;
export const triggerBookingListExportRequestAction = (exportType: ExportType) => ({
  type: TRIGGER_BOOKING_LIST_EXPORT_REQUEST as typeof TRIGGER_BOOKING_LIST_EXPORT_REQUEST,
  exportType,
});

export type TriggerBookingListExportSuccessAction = ReturnType<typeof triggerBookingListExportSuccessAction>;
export const triggerBookingListExportSuccessAction = (exportUuid: string) => ({
  type: TRIGGER_BOOKING_LIST_EXPORT_SUCCESS as typeof TRIGGER_BOOKING_LIST_EXPORT_SUCCESS,
  exportUuid,
});

export type TriggerBookingListExportFailureAction = ReturnType<typeof triggerBookingListExportFailureAction>;
export const triggerBookingListExportFailureAction = () => ({
  type: TRIGGER_BOOKING_LIST_EXPORT_FAILURE as typeof TRIGGER_BOOKING_LIST_EXPORT_FAILURE,
});

export type PollBookingListExportRequestAction = ReturnType<typeof pollBookingListExportRequestAction>;
export const pollBookingListExportRequestAction = () => ({
  type: POLL_BOOKING_LIST_EXPORT_REQUEST as typeof POLL_BOOKING_LIST_EXPORT_REQUEST,
});

export type PollBookingListExportSuccessAction = ReturnType<typeof pollBookingListExportSuccessAction>;
export const pollBookingListExportSuccessAction = (signedUrl: string) => ({
  type: POLL_BOOKING_LIST_EXPORT_SUCCESS as typeof POLL_BOOKING_LIST_EXPORT_SUCCESS,
  signedUrl,
});

export type PollBookingListExportContinueAction = ReturnType<typeof pollBookingListExportContinueAction>;
export const pollBookingListExportContinueAction = () => ({
  type: POLL_BOOKING_LIST_EXPORT_CONTINUE as typeof POLL_BOOKING_LIST_EXPORT_CONTINUE,
});

export type PollBookingListExportFailureAction = ReturnType<typeof pollBookingListExportFailureAction>;
export const pollBookingListExportFailureAction = () => ({
  type: POLL_BOOKING_LIST_EXPORT_FAILURE as typeof POLL_BOOKING_LIST_EXPORT_FAILURE,
});

export type CancelBookingListExportAction = ReturnType<typeof cancelBookingListExportAction>;
export const cancelBookingListExportAction = () => ({
  type: CANCEL_BOOKING_LIST_EXPORT as typeof CANCEL_BOOKING_LIST_EXPORT,
});

export type SetIsRefetchingBookingsAction = ReturnType<typeof setIsRefetchingBookingsAction>;
export const setIsRefetchingBookingsAction = (newValue: boolean) => ({
  type: SET_IS_REFETCHING_BOOKINGS as typeof SET_IS_REFETCHING_BOOKINGS,
  newValue,
});

export type SetSelectedProductTypesFilterAction = ReturnType<typeof setSelectedProductTypesFilterAction>;
export const setSelectedProductTypesFilterAction = (productTypes: BookingListProductTypesFilter[]) => ({
  type: SET_PRODUCT_TYPES_FILTER as typeof SET_PRODUCT_TYPES_FILTER,
  productTypes,
});

export type SetProductConfirmationNumberFilterAction = ReturnType<typeof setProductConfirmationNumberFilterAction>;
export const setProductConfirmationNumberFilterAction = (productConfirmationNumber: string) => ({
  type: SET_PRODUCT_CONFIRMATION_NUMBER_FILTER as typeof SET_PRODUCT_CONFIRMATION_NUMBER_FILTER,
  productConfirmationNumber,
});

export type ResetFiltersAction = ReturnType<typeof resetFiltersAction>;
export const resetFiltersAction = () => ({
  type: RESET_FILTERS as typeof RESET_FILTERS,
});

export type BookingsListAction =
  | GetBookingListRequestAction
  | GetBookingListSuccessAction
  | GetBookingListFailureAction
  | GetDestinationListRequestAction
  | GetDestinationListSuccessAction
  | GetDestinationListFailureAction
  | GetSalesRepListRequestAction
  | GetSalesRepListSuccessAction
  | GetSalesRepListFailureAction
  | SetHumanReadableIdFilterAction
  | SetGuestNameFilterAction
  | SetTravelPartnerRefFilterAction
  | SetPageNumberAction
  | SetItemsPerPageAction
  | SetSortAction
  | SetSelectedTravelAgentAction
  | SetSelectedTravelAgentCountryCodeAction
  | SetSelectedCompanyAction
  | SetSelectedSalesRepresentativeAction
  | SetSelectedDestinationAction
  | SetSelectedBookingTypeAction
  | SetSelectedStatusesAction
  | SetSelectedPurchaseCostReviewStatusAction
  | TriggerBookingListExportRequestAction
  | TriggerBookingListExportSuccessAction
  | TriggerBookingListExportFailureAction
  | PollBookingListExportRequestAction
  | PollBookingListExportSuccessAction
  | PollBookingListExportContinueAction
  | PollBookingListExportFailureAction
  | CancelBookingListExportAction
  | SetOnlyShowWithInvoiceFilterAction
  | SetIsViewOnlyAssignedToMeFilterAction
  | SetDateRangeTypeAction
  | SetDateRangeValueAction
  | ClearDateRangeFilterAction
  | SetOnlyShowWithGroundServicesFilterAction
  | SetHasProformaCreatedFilterAction
  | SetHasInvoiceCreatedFilterAction
  | SetFullyPaidByTaFilterAction
  | SetHasOverduePaymentsFilterAction
  | SetOnlyShowMyBookingsFilterAction
  | SetRateSourceFilterAction
  | SetIsRefetchingBookingsAction
  | SetSelectedProductTypesFilterAction
  | SetProductConfirmationNumberFilterAction
  | ResetFiltersAction
  | SetHasHotelCommissionAfterCheckoutFilterAction
  | SetCompanyNameAction;
