import { call, takeLatest, select, put } from 'redux-saga/effects';
import {
  PatchCompanyRequestAction,
  patchCompanySuccessAction,
  patchCompanyFailureAction,
  PATCH_COMPANY_REQUEST,
} from '../actions';
import { ICompanyResponse, makeBackendApi } from 'services/BackendApi';
import { selectedTaSelector } from '../../agents';
import { enqueueNotification } from '../../ui';
import { AxiosResponse } from 'axios';

export function* patchCompanySaga(action: PatchCompanyRequestAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);

    const companyResponse: AxiosResponse<ICompanyResponse> = yield call(backendApi.patchCompany, action.companyUuid, action.company);
    yield put(patchCompanySuccessAction(companyResponse.data.data));
    yield put(enqueueNotification({ message: 'TA settings has beed updated successfully.', options: { variant: 'success' } }));
  } catch (e) {
    yield put(patchCompanyFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'Failed to update company information',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchPatchCompanySaga() {
  yield takeLatest(PATCH_COMPANY_REQUEST, patchCompanySaga);
}
