import React from 'react';
import { Price } from 'ui/Price';
import classnames from 'classnames';
import { TCurrencyCode } from 'interfaces';

interface IProductLineWithPriceProps {
  name: string;
  total?: number | string | null;
  totalBeforeDiscount?: number | string | null;
  currencyCode: TCurrencyCode;
  isOnRequest?: boolean;
  className?: string;
}

export const ProductLineWithPrice: React.FC<IProductLineWithPriceProps> = React.memo(({ 
  name, total, totalBeforeDiscount, currencyCode, isOnRequest, className 
 }) => {
  const priceClassName = "font-hurmegeometric-sans text-15px leading-18px text-right mt-5px m-0";
  const titleClassName = classnames("product-title font-hurmegeometric-sans text-13px leading-16px  uppercase mb-0 mt-5px max-w-670px text-flint");

  return (
    <div className={classnames(className, "product-line")}>
      <div className="first-line flex items-start justify-between">
        <div className="product-text-container">
          <p className={titleClassName}>
            {name}
          </p>
        </div>
        <div className="product-price">
          <Price
            total={total}
            totalBeforeDiscount={totalBeforeDiscount}
            currencyCode={currencyCode}
            isOnRequest={isOnRequest}
            totalClassName={`${priceClassName} text-flint`}
            totalBeforeDiscountClassName={`${priceClassName} text-gray-80 line-through`}
            totalAfterDiscountClassName={`${priceClassName} text-red-15`}
            isOnRequestClassName={`${priceClassName} text-flint`}
          />
        </div>
      </div>
    </div>
  );
});
