import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'ui/Link';

export interface IBackLink {
  href?: string;
  type: 'internal' | 'external' | 'back';
  className?: string;
  children?: React.ReactNode;
}

export const BackLink: React.FC<IBackLink> = ({ children, href, type, className = '' }) => {
  const backLinkClass =
    'back-link text-brown-100 hover:text-brown-140 visited:text-brown-100 text-15px font-bold leading-19px underline cursor-pointer font-pt-sans select-none';

  const history = useHistory();
  const handleNavigateBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <div className={classNames('back-link-container mb-10px', className)}>
      {type === 'external' && (
        <a className={backLinkClass} href={href}>
          {children}
        </a>
      )}
      {type === 'internal' && href && (
        <Link to={href} type="internal">
          <span className={`inline-block ${backLinkClass}`}>{children}</span>
        </Link>
      )}
      {type === 'back' && (
        <a className={backLinkClass} onClick={handleNavigateBack}>
          {children}
        </a>
      )}
    </div>
  );
};
