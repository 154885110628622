import React, { Fragment, useCallback } from 'react';
import Home from 'pages/Home';
import { ResetPasswordModal } from 'ui/ResetPasswordModal';

import { propTypes } from './SetPassword.props';

export const SetPassword = ({ history, match, ...props }) => {
  const { params: token } = match;
  const onClose = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <Fragment>
      <Home history={history} match={match} {...props} />
      <ResetPasswordModal token={token.token} isOpen onClose={onClose} />
    </Fragment>
  );
};

SetPassword.propTypes = propTypes;

export default SetPassword;
