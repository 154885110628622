import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Actions from './actions';
import { initialState, ITaskManagementDomain } from './model';
import { generateStableReminderUuid } from './helpers';
import { ITaskReminder } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { uniqBy } from 'lodash-es';

export const taskManagementReducer = (
  state: ITaskManagementDomain = initialState,
  action: Actions.TaskManagementAction
): ITaskManagementDomain => {
  switch (action.type) {
    case Actions.SET_ID_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          id: action.value,
        },
      };

    case Actions.SET_CATEGORY_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          categories: action.value,
        },
      };

    case Actions.SET_STATUS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          statuses: action.value,
        },
      };

    case Actions.SET_TEAM_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          team: action.value,
        },
      };

    case Actions.SET_ASSIGNEE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          assignee: action.value,
        },
      };

    case Actions.SET_PRIORITY_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          priority: action.value,
        },
      };

    case Actions.SET_DATES_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          dates: action.value,
        },
      };

    case Actions.SET_BOOKING_REF_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          bookingRef: action.value,
        },
      };

    case Actions.SET_GUEST_NAME_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          guestName: action.value,
        },
      };

    case Actions.SET_REPORTER_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          reporter: action.value,
        },
      };

    case Actions.GET_FILTER_INFO_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          categories: [],
          teams: [],
        },
        requests: {
          ...state.requests,
          getFilterInfo: ENetworkRequestStatus.PENDING,
        },
      };

    case Actions.GET_FILTER_INFO_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          categories: action.categories,
          teams: action.teams,
        },
        requests: {
          ...state.requests,
          getFilterInfo: ENetworkRequestStatus.SUCCESS,
        }
      };

    case Actions.GET_FILTER_INFO_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          categories: [],
          teams: [],
        },
        requests: {
          ...state.requests,
          getFilterInfo: ENetworkRequestStatus.ERROR,
        },
      };

    case Actions.GET_USERS_REQUEST:
      if (action.typeOfUser === 'reporter') {
        return {
          ...state,
          data: {
            ...state.data,
            reporters: [],
          },
          requests: {
            ...state.requests,
            getReporters: ENetworkRequestStatus.PENDING,
          },
        };
      } else if (action.typeOfUser === 'assignee') {
        return {
          ...state,
          data: {
            ...state.data,
            assignees: [],
          },
          requests: {
            ...state.requests,
            getAssignees: ENetworkRequestStatus.PENDING,
          },
        };
      } else {
        return {
          ...state,
          data: {
            ...state.data,
            reporters: [],
            assignees: [],
          },
          requests: {
            ...state.requests,
            getReporters: ENetworkRequestStatus.PENDING,
            getAssignees: ENetworkRequestStatus.PENDING,
          },
        };
      }


    case Actions.GET_USERS_SUCCESS:
      const userCache = uniqBy([ ...action.users, ...state.data.userCache], 'uuid'); 

      if (action.typeOfUser === 'reporter') {
        return {
          ...state,
          data: {
            ...state.data,
            reporters: action.users,
            userCache
          },
          requests: {
            ...state.requests,
            getReporters: ENetworkRequestStatus.SUCCESS,
          }
        };
      } else if (action.typeOfUser === 'assignee') {
        return {
          ...state,
          data: {
            ...state.data,
            assignees: action.users,
            userCache
          },
          requests: {
            ...state.requests,
            getAssignees: ENetworkRequestStatus.SUCCESS,
          },
        };
      } else {
        return {
          ...state,
          data: {
            ...state.data,
            reporters: action.users,
            assignees: action.users,
            userCache
          },
          requests: {
            ...state.requests,
            getReporters: ENetworkRequestStatus.SUCCESS,
            getAssignees: ENetworkRequestStatus.SUCCESS,
          },
        };
      }

    case Actions.GET_USERS_FAILURE:
      if (action.typeOfUser === 'reporter') {
        return {
          ...state,
          data: {
            ...state.data,
            reporters: [],
          },
          requests: {
            ...state.requests,
            getReporters: ENetworkRequestStatus.ERROR,
          },
        };
      } else if (action.typeOfUser === 'assignee') {
        return {
          ...state,
          data: {
            ...state.data,
            assignees: [],
          },
          requests: {
            ...state.requests,
            getAssignees: ENetworkRequestStatus.ERROR,
          },
        };
      } else {
        return {
          ...state,
          data: {
            ...state.data,
            assignees: [],
            reporters: [],
          },
          requests: {
            ...state.requests,
            getReporters: ENetworkRequestStatus.ERROR,
            getAssignees: ENetworkRequestStatus.ERROR,
          },
        };
      }

    case Actions.GET_TASKS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          results: [],
          tableLoading: true,
        },
        requests: {
          ...state.requests,
          getTasks: ENetworkRequestStatus.PENDING,
        },
      };
    case Actions.GET_TASKS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          results: action.tasks,
          tableLoading: false,
        },
        pagination: {
          ...state.pagination,
          totalItems: action.totalResults,
        },
        requests: {
          ...state.requests,
          getTasks: ENetworkRequestStatus.SUCCESS,
        },
      };
    case Actions.GET_TASKS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          results: [],
          tableLoading: false,
        },
        requests: {
          ...state.requests,
          getTasks: ENetworkRequestStatus.ERROR,
        },
      };
    case Actions.SET_PAGE_NUMBER:
      return {
        ...state,
        data: {
          ...state.data,
          results: [],
          tableLoading: true,
        },
        pagination: {
          ...state.pagination,
          currentPage: action.pageNumber,
        },
      };
    case Actions.SET_SORT:
      return {
        ...state,
        data: {
          ...state.data,
          tableLoading: true,
        },
        sort: {
          sortBy: action.sortBy,
          sortOrder: action.sortOrder,
        }
      };
    case Actions.SET_ITEMS_PER_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          tableLoading: true,
        },
        pagination: {
          ...state.pagination,
          currentPage: 0,
          itemsPerPage: action.itemsPerPage,
        }
      };
    case Actions.RESET_FILTERS:
      return {
        ...initialState,
      };
    case Actions.DELETE_TASK_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          deleteTask: ENetworkRequestStatus.PENDING,
        }
      };
    case Actions.DELETE_TASK_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          deleteTask: ENetworkRequestStatus.SUCCESS,
        }
      };
    case Actions.DELETE_TASK_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          deleteTask: ENetworkRequestStatus.ERROR,
        }
      };
    case Actions.CREATE_TASK_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          createTask: ENetworkRequestStatus.PENDING,
        }
      };
    case Actions.CREATE_TASK_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          createTask: ENetworkRequestStatus.SUCCESS,
        }
      };
    case Actions.CREATE_TASK_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          createTask: ENetworkRequestStatus.ERROR,
        }
      };

    case Actions.EDIT_TASK_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          editTask: ENetworkRequestStatus.PENDING,
        }
      };
    case Actions.EDIT_TASK_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          editTask: ENetworkRequestStatus.SUCCESS,
        }
      };
    case Actions.EDIT_TASK_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          editTask: ENetworkRequestStatus.ERROR,
        }
      };

    case Actions.GET_SELECTED_TASK_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          getTask: ENetworkRequestStatus.PENDING,
        }
      };
    case Actions.GET_SELECTED_TASK_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          getTask: ENetworkRequestStatus.SUCCESS,
        },
        data: {
          ...state.data,
          selectedTask: action.task,
          bookingRefs: action.task.booking ? [action.task.booking.humanReadableId] : [],
        }
      };
    case Actions.GET_SELECTED_TASK_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          getTask: ENetworkRequestStatus.ERROR,
        }
      };
    case Actions.CREATE_TASK_COMMENT_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          createTaskComment: ENetworkRequestStatus.SUCCESS,
        }
      };
    case Actions.CREATE_TASK_COMMENT_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          createTaskComment: ENetworkRequestStatus.PENDING,
        }
      };
    case Actions.ADD_FOCUSED_COMMENT:
      return {
        ...state,
        data: {
          ...state.data,
          focusedComments: [...state.data.focusedComments, action.commentUuid],
        }
      };
    case Actions.REMOVE_FOCUSED_COMMENT:
      return {
        ...state,
        data: {
          ...state.data,
          focusedComments: state.data.focusedComments.filter(item => item !== action.commentUuid),
        }
      };
    case Actions.CREATE_TASK_COMMENT_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          createTaskComment: ENetworkRequestStatus.ERROR,
        }
      };
    case Actions.LIST_TASK_COMMENTS_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          listComments: ENetworkRequestStatus.PENDING,
        }
      };
    case Actions.LIST_TASK_COMMENTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          comments: action.taskComments,
        },
        requests: {
          ...state.requests,
          listComments: ENetworkRequestStatus.SUCCESS,
        }
      };
    case Actions.LIST_TASK_COMMENTS_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          listComments: ENetworkRequestStatus.ERROR,
        }
      };
    case Actions.DELETE_COMMENT_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          deleteComment: ENetworkRequestStatus.PENDING,
        }
      };
    case Actions.DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          deleteComment: ENetworkRequestStatus.SUCCESS,
        }
      };
    case Actions.DELETE_COMMENT_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          deleteComment: ENetworkRequestStatus.ERROR,
        }
      };
    case Actions.SET_SELECTED_COMMENT_ID:
      return {
        ...state,
        data: {
          ...state.data,
          selectedCommentId: action.commentId
        }
      };
      case Actions.ADD_TASK_REMINDER:
        return {
          ...state,
          data: {
            ...state.data,
            reminders: [ ...state.data.reminders, action.taskReminder ],
          },
        };
      case Actions.CHANGE_TASK_REMINDER:
        return {
          ...state,
          data: {
            ...state.data,
            reminders: state.data.reminders.map(item => {
              if (item.id === action.uuid) {
                return {
                  ...item,
                  channel: action.channel ? action.channel : item.channel,
                  offset: action.offset ?? item.offset,
                  offsetUnit: action.offsetUnit ? action.offsetUnit : item.offsetUnit,
                }
              } else {
                return item;
              }
            }),
          },
        };
      case Actions.DELETE_TASK_REMINDER:
        return {
          ...state,
          data: {
            ...state.data,
            reminders: state.data.reminders.filter(item => item.id !== action.uuid),
          },
        };
      case Actions.CLEAR_TASK_REMINDERS:
        return {
          ...state,
          data: {
            ...state.data,
            reminders: [],
          },
        };
      case Actions.LIST_TASK_REMINDERS_SUCCESS:
        return {
          ...state,
          data: {
            ...state.data,
            reminders: action.taskReminders,
          },
        };
      case Actions.OPEN_UPLOAD_TASK_ATTACHMENT_MODAL:
        return {
          ...state,
          data: {
            ...state.data,
            attachments: {
              ...state.data.attachments,
              uploadAttachmentModalOpen: true,
              newAttachment: {
                payload: {
                  file: null,
                  displayName: null,
                  tag: null,
                },
                request: ENetworkRequestStatus.IDLE,
                error: null,
              },
            }
          }
        };
  
      case Actions.CLOSE_UPLOAD_TASK_ATTACHMENT_MODAL:
        return {
          ...state,
          data: {
            ...state.data,
            attachments: {
              ...state.data.attachments,
              uploadAttachmentModalOpen: false,
              newAttachment: {
                ...state.data.attachments.newAttachment,
                payload: null,
              },
            }
          }
        };

    case Actions.SET_ATTACHMENT_TO_UPLOAD:
      return {
        ...state,
        data: {
          ...state.data,
          attachments: {
            ...state.data.attachments,
            newAttachment: {
              ...state.data.attachments.newAttachment,
              payload: {
                ...state.data.attachments.newAttachment.payload,
                file: action.document,
                displayName: action.document.name,
                tag: state.data.attachments.newAttachment.payload?.tag ?? null,
              },
              request: ENetworkRequestStatus.IDLE,
              error: null,
            },
          }
        },
      };

    case Actions.SET_ATTACHMENT_DISPLAY_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          attachments: {
            ...state.data.attachments,
            newAttachment: {
              ...state.data.attachments.newAttachment,
              payload: {
                ...state.data.attachments.newAttachment.payload,
                file: state.data.attachments.newAttachment.payload?.file ?? null,
                displayName: action.displayName,
                tag: state.data.attachments.newAttachment.payload?.tag ?? null,
              },
              request: ENetworkRequestStatus.IDLE,
              error: null,
            },
          }
        }
      };

    case Actions.UPLOAD_ATTACHMENT_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          attachments: {
            ...state.data.attachments,
            newAttachment: {
              ...state.data.attachments.newAttachment,
              request: ENetworkRequestStatus.PENDING,
              error: null,
            },
          }
        },
      };

    case Actions.UPLOAD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          attachments: {
            ...state.data.attachments,
            newAttachment: {
              ...state.data.attachments.newAttachment,
              request: ENetworkRequestStatus.SUCCESS,
              error: null,
            },
            uploadAttachmentModalOpen: false,
          }
        },
      };

    case Actions.UPLOAD_ATTACHMENT_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          attachments: {
            ...state.data.attachments,
            newAttachment: {
              ...state.data.attachments.newAttachment,
              request: ENetworkRequestStatus.ERROR,
              error: action.error,
            },
            uploadAttachmentModalOpen: false,
          }
        },
      };

    case Actions.GET_MENTION_USERS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          mentionList: [],
        },
        requests: {
          ...state.requests,
          getMentionList: ENetworkRequestStatus.PENDING,
        },
      };

    case Actions.GET_MENTION_USERS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          mentionList: action.users,
        },
        requests: {
          ...state.requests,
          getMentionList: ENetworkRequestStatus.SUCCESS,
        }
      };

    case Actions.GET_MENTION_USERS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          mentionList: [],
        },
        requests: {
          ...state.requests,
          getMentionList: ENetworkRequestStatus.ERROR,
        },
      };

    case Actions.LIST_ATTACHMENTS_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          listAttachments: ENetworkRequestStatus.PENDING,
        },
      };

    case Actions.LIST_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          listAttachments: ENetworkRequestStatus.SUCCESS,
        },
        data: {
          ...state.data,
          attachments: {
            ...state.data.attachments,
            attachments: action.attachments,
          }
        },
      };

    case Actions.LIST_ATTACHMENTS_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          listAttachments: ENetworkRequestStatus.ERROR,
        },
      };

    case Actions.DELETE_ATTACHMENT_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          deleteAttachment: ENetworkRequestStatus.PENDING,
        }
      };

    case Actions.DELETE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          deleteAttachment: ENetworkRequestStatus.SUCCESS,
        },
        data: {
          ...state.data,
          selectedAttachmentId: null,
        }
      };
    case Actions.DELETE_ATTACHMENT_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          deleteAttachment: ENetworkRequestStatus.ERROR,
        }
      };
    case Actions.VALIDATE_TASK_REMINDERS:
      // eslint-disable-next-line no-case-declarations
      const duplicatedReminderIds: string[] = [];
      // eslint-disable-next-line no-case-declarations
      const iteratedReminderIds: string[] = [];
      state.data.reminders.forEach((reminder: ITaskReminder) => {
        const reminderId = generateStableReminderUuid(reminder);
        if (iteratedReminderIds.includes(reminderId)) {
          duplicatedReminderIds.push(reminderId);
        }
        iteratedReminderIds.push(reminderId);
      });
      return {
        ...state,
        data: {
          ...state.data,
          duplicatedReminderIds,
        }
      };
    case Actions.CLEAN_DUPLICATED_TASK_REMINDER_IDS:
      return {
        ...state,
        data: {
          ...state.data,
          duplicatedReminderIds: [],
        }
      };
    case Actions.SET_SELECTED_ATTACHMENT_ID:
      return {
        ...state,
        data: {
          ...state.data,
          selectedAttachmentId: action.attachmentId
        }
      };
    case Actions.SEARCH_BOOKING_REF_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          bookingRefs: [],
        },
        requests: {
          ...state.requests,
          searchBookingRefs: ENetworkRequestStatus.PENDING,
        },
      };
    case Actions.SEARCH_BOOKING_REF_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          bookingRefs: action.bookingRefs,
        },
        requests: {
          ...state.requests,
          searchBookingRefs: ENetworkRequestStatus.SUCCESS,
        },
      };
    case Actions.SEARCH_BOOKING_REF_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          searchBookingRefs: ENetworkRequestStatus.ERROR,
        },
      };
    default:
      return state;
  }
};
