import React from 'react';
import Checkbox from 'ui/Checkbox';
import classnames from 'classnames';

export interface ICheckboxOption {
  name: string;
  value: boolean;
  label: string;
}

interface ICheckboxListProps {
  options: ICheckboxOption[];
  onClick: (optionName: string, optionValue: boolean) => void;
  disabled?: boolean;
}

export const CheckboxList: React.FC<ICheckboxListProps> = ({ options, onClick, disabled = false }) => {
  return (
    <div className="checkbox-container flex flex-wrap gap-20px">
      {options.map(option => (
        <label
          key={`option-${option.name}`}
          className={classnames(
            `label-${option.name} inline-flex font-pt-sans text-15px leading-19px select-none cursor-pointer text-black`
          )}
          onClick={() => !disabled && onClick(option.name, !option.value)}
        >
          <Checkbox className={`option-${option.name}`} checked={option.value} readOnly disabled={disabled} />
          <span className="ml-2">{option.label}</span>
        </label>
      ))}
    </div>
  );
};
