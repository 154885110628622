import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

import {
  closeBookingStatusModalAction,
  GET_BOOKING_STATUS_OPTIONS_REQUEST,
  getBookingStatusOptionsFailureAction,
  getBookingStatusOptionsSuccessAction,
  openBookingStatusModalAction,
  SET_BOOKING_STATUS_REQUEST,
  setBookingStatusFailureAction,
  setBookingStatusRequestAction,
  SetBookingStatusRequestAction,
  setBookingStatusSuccessAction,
  TRY_SETTING_BOOKING_STATUS,
  TrySettingBookingStatusAction,
  POST_CANCELLATION_INVOICE_REQUEST,
  PostCancellationInvoiceRequestAction,
  postCancellationInvoiceSuccessAction,
  closeCancellationInvoiceModalAction,
  postCancellationInvoiceFailureAction,
  SET_CANCELLATION_MODAL_AMOUNT_CENTS,
  SET_CANCELLATION_MODAL_PERCENTAGE,
  setCancellationModalOutstandingBalanceCentsAction,
  setCancellationModalAmountCentsAction,
  setCancellationModalDataAction,
  setCancellationModalPercentageAction,
  openCancellationInvoiceModalAction,
} from './actions';
import { bookingUuidSelector } from '../../selectors';
import { IHeadlineLineItemBreakdown, ITopNavigationData, makeBookingManagerApi } from 'services/BookingManagerApi';
import {
  EBookingStatus,
  IBookingStatusOptionsResponse,
  IBookingStatusResponse,
} from 'services/BookingManagerApi/types/BookingStatusResponse';
import {
  getTopNavigationDataRequestAction,
  updateTopNavigationStateHistoryAction,
  updateTopNavigationBookingStatusAction,
} from '../dashboard/actions';
import { enqueueNotification } from '../../../ui';
import { formatDate, roundToDecimalPlaces } from '../../../../../utils';
import { topNavigationDataDashboardSelector } from '../dashboard/selectors';
import {
  headlineLineItemBreakdownSelector,
  isHeadlineBreakdownEditedWithoutSavingSelector,
} from '../breakdown/selectors';
import { saveAndUpdateHeadlineBreakdownSaga } from '../breakdown/sagas/saga';
import * as BreakdownActions from '../breakdown/actions';
import { cancellationModalDataSelector } from './selectors';
import { IBookingStatusCancellationModalData } from './model';
import { financeDocumentBalanceSelector, financeDocumentSelector } from '../finance/selectors';
import { isProformaOrInvoiceRow } from '../finance/utils';
import { IFinanceDocument, IFinanceDocumentBalance } from '../finance/types';
import { getNewAmountFromPercentageOfOriginal } from 'containers/BookingManagerBreakdown/InvoiceModal';

export function* getBookingStatusOptionsSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IBookingStatusOptionsResponse> = yield call(
      bookingManagerApi.getBookingStatusOptionsData,
      bookingUuid
    );

    yield put(getBookingStatusOptionsSuccessAction(result.data.availableOptions));
  } catch (e) {
    yield put(getBookingStatusOptionsFailureAction(e));
  }
}

export function* trySettingBookingStatusSaga(action: TrySettingBookingStatusAction) {
  const { bookingStatus } = action;
  const today = formatDate(new Date());
  const topNavigationData: ITopNavigationData = yield select(topNavigationDataDashboardSelector);

  switch (bookingStatus) {
    case EBookingStatus.REQUESTED:
      yield put(setBookingStatusRequestAction(bookingStatus));
      break;
    case EBookingStatus.CONFIRMED:
      yield put(setBookingStatusRequestAction(bookingStatus));
      break;
    case EBookingStatus.DISCARDED:
      yield put(
        openBookingStatusModalAction({
          title: 'If you discard the booking, no further actions should be performed on this booking.',
          message: 'Are you sure you want to continue?',
          confirmButtonLabel: 'Discard',
          cancelButtonLabel: 'Exit',
          nextBookingStatus: bookingStatus,
        })
      );
      break;
    case EBookingStatus.CANCELLED:
      const breakdownData: IHeadlineLineItemBreakdown | null = yield select(headlineLineItemBreakdownSelector);
      // if we currently have ANY live accommodations in our breakdown, we actually
      // want to trigger the new breakdown flow, @see OWA-3720
      if (breakdownData?.Accommodation.items.some(a => a.externalBooking && a.externalBooking.status !== 'enquiry')) {
        // trigger the new flow
        yield put(BreakdownActions.setLiveAccommodationCancellationWarningModalIsOpenAction(true));
      } else {
        yield put(setCancellationModalAmountCentsAction(breakdownData?.SubtotalCents || 0));
        yield put(setCancellationModalPercentageAction(100));
        yield put(openCancellationInvoiceModalAction(bookingStatus));
      }
      break;
    case EBookingStatus.COMPLETED:
      if (today <= topNavigationData.departureDate!) {
        yield put(
          openBookingStatusModalAction({
            title: 'The vacation has not yet finished.',
            message: 'Are you sure you want to continue?',
            confirmButtonLabel: 'YES',
            cancelButtonLabel: 'NO',
            nextBookingStatus: bookingStatus,
          })
        );
      } else {
        yield put(setBookingStatusRequestAction(bookingStatus));
      }
      break;
    default:
      return;
  }
}

export function* setBookingStatusSaga(action: SetBookingStatusRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const isHeadlineBreakdownEditedWithoutSaving = yield select(isHeadlineBreakdownEditedWithoutSavingSelector);

    // Save the breakdown
    if (isHeadlineBreakdownEditedWithoutSaving) {
      yield call(saveAndUpdateHeadlineBreakdownSaga);
    }

    // Change booking status
    const result: AxiosResponse<IBookingStatusResponse> = yield call(
      bookingManagerApi.setBookingStatus,
      bookingUuid,
      action.bookingStatus
    );

    // Update top navigation with the state history and status
    yield put(updateTopNavigationStateHistoryAction(result.data.stateHistory));
    yield put(updateTopNavigationBookingStatusAction(result.data.status));

    // Close modal
    yield put(closeBookingStatusModalAction());

    // Get next booking status options
    yield call(getBookingStatusOptionsSaga);

    yield put(setBookingStatusSuccessAction());

    // Show notification with success
    yield put(
      enqueueNotification({
        message: 'The booking status was successfully changed',
        options: { variant: 'success' },
      })
    );

    if(result.data.status === EBookingStatus.COMPLETED) {
      const fsResult = yield call(
        bookingManagerApi.getFinanceSales,
        bookingUuid
      );
      const doc = (fsResult?.data?.rows || []).find(row => {
        return row.rowType === 'Automatic Invoice to Travel Agent';
      });
      if(doc) {
        const url = doc.uploadUrl;
        saveAs(url);
      }
    }
  } catch (e) {
    // Close modal
    yield put(closeBookingStatusModalAction());

    // Show error in the sticky bar
    yield put(setBookingStatusFailureAction(e));

    // Show notification with error
    yield put(
      enqueueNotification({
        message: 'There was an error updating booking status',
        options: { variant: 'error' },
      })
    );

    // Update top navigation with the state history
    yield put(getTopNavigationDataRequestAction());

    // Get next booking status options
    yield call(getBookingStatusOptionsSaga);
  }
}

export function* postCancellationInvoiceRequestSaga(action: PostCancellationInvoiceRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const cancellationModalData: IBookingStatusCancellationModalData = yield select(cancellationModalDataSelector);

    if (cancellationModalData.bookingStatus === null) {
      return;
    }

    // Change booking status
    const result: AxiosResponse<IBookingStatusResponse> = yield call(
      bookingManagerApi.postCancellationInvoice,
      bookingUuid,
      {
        status: cancellationModalData.bookingStatus,
        invoiceDueDate: {
          date: cancellationModalData.dueDate,
          amountCents: cancellationModalData.amountCents,
          percentage: cancellationModalData.percentage,
          isConfirmed: false,
        },
      }
    );

    // 3. Download Invoice that was generated
    const { upload } = result.data;
    saveAs(upload.url, upload.filename);

    // Update top navigation with the state history and status
    // yield put(updateTopNavigationStateHistoryAction(result.data.stateHistory));
    // yield put(updateTopNavigationBookingStatusAction(result.data.status));

    // Close modal
    yield put(closeCancellationInvoiceModalAction());

    // Get next booking status options
    yield call(getBookingStatusOptionsSaga);
    yield put(getTopNavigationDataRequestAction());

    yield put(postCancellationInvoiceSuccessAction());

    // Show notification with success
    yield put(
      enqueueNotification({
        message: 'The booking status was successfully changed',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    // Close modal
    yield put(closeCancellationInvoiceModalAction());

    // Show error in the sticky bar
    yield put(postCancellationInvoiceFailureAction(e));

    // Show notification with error
    yield put(
      enqueueNotification({
        message: 'There was an error generating the cancellation invoice and updating the booking status',
        options: { variant: 'error' },
      })
    );

    // Update top navigation with the state history
    yield put(getTopNavigationDataRequestAction());

    // Get next booking status options
    yield call(getBookingStatusOptionsSaga);
  }
}

export function* recalculateCancellationModalOutstandingBalance() {
  const financeDocument: IFinanceDocument = yield select(financeDocumentSelector);
  const financeDocumentBalance: IFinanceDocumentBalance = yield select(financeDocumentBalanceSelector);

  const cancellationModalData: IBookingStatusCancellationModalData = yield select(cancellationModalDataSelector);
  const { amountCents } = cancellationModalData;

  const latestProformaOrInvoiceRow = financeDocument.rows.find(fr => isProformaOrInvoiceRow(fr));

  if (!latestProformaOrInvoiceRow || latestProformaOrInvoiceRow.amountCents == null) {
    return;
  }

  const outstandingBalanceCents =
    financeDocumentBalance.totalOutstandingCents - latestProformaOrInvoiceRow.amountCents + amountCents;

  yield put(setCancellationModalOutstandingBalanceCentsAction(outstandingBalanceCents));

  const breakdownData: IHeadlineLineItemBreakdown | null = yield select(headlineLineItemBreakdownSelector);

  if (!breakdownData || !breakdownData.SubtotalCents) {
    return;
  }
  const newPercentage = (amountCents * 100) / breakdownData.SubtotalCents;
  yield put(
    setCancellationModalDataAction({
      percentage: roundToDecimalPlaces(newPercentage, 2),
    })
  );
}

export function* recalculateCancellationModalAmountCentsFromPercentage() {
  const breakdownData: IHeadlineLineItemBreakdown | null = yield select(headlineLineItemBreakdownSelector);
  const cancellationModalData: IBookingStatusCancellationModalData = yield select(cancellationModalDataSelector);
  const { percentage } = cancellationModalData;

  if (!breakdownData || !breakdownData.SubtotalCents) {
    return;
  }

  const newAmountCents = getNewAmountFromPercentageOfOriginal(breakdownData.SubtotalCents, percentage);

  yield put(setCancellationModalAmountCentsAction(newAmountCents));
}

export function* watchBookingStatusOptionsSaga() {
  yield all([
    takeLatest([GET_BOOKING_STATUS_OPTIONS_REQUEST], getBookingStatusOptionsSaga),
    takeLatest([TRY_SETTING_BOOKING_STATUS], trySettingBookingStatusSaga),
    takeLatest([SET_BOOKING_STATUS_REQUEST], setBookingStatusSaga),
    takeLatest([POST_CANCELLATION_INVOICE_REQUEST], postCancellationInvoiceRequestSaga),
    takeLatest([SET_CANCELLATION_MODAL_AMOUNT_CENTS], recalculateCancellationModalOutstandingBalance),
    takeLatest([SET_CANCELLATION_MODAL_PERCENTAGE], recalculateCancellationModalAmountCentsFromPercentage),
  ]);
}
