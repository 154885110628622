import styled from 'styled-components';
import { pureUiTheme } from '../pureUiTheme';

export const GuestDetailsOverviewStyles = styled.div`
    padding-bottom: 40px;

    .table {
      border: 1px solid ${pureUiTheme.colors.gray40};
      box-shadow: 0 4px 4px ${pureUiTheme.colors.gray10};
      
      .header {
        display: grid;
        grid-template-columns: 5fr 3fr 3fr 6fr;
        align-items: center;
        padding: 0 20px;
        height: 46px;
        text-transform: uppercase;
        
        .columnTitle {
          line-height: 14px;
          padding: 0 5px;
          letter-spacing: 0.04em;

          &:last-of-type {
            padding-right: 0;
          }
        }
      }
      
      .content {
        display: grid;
        padding: 0 20px;
        border-top: 1px solid ${pureUiTheme.colors.gray40};
        border-bottom: 1px solid ${pureUiTheme.colors.gray40};
        
        &.cells {
          grid-template-columns: 5fr 3fr 3fr 6fr;
        }
        
        .tableCell {
          line-height: 24px;
          min-height: 46px;
          display: flex;
          align-items: center;
          padding: 0 5px;

          &:nth-of-type(4n) {
            padding-right: 0;
          }

          &:nth-of-type(1), &:nth-of-type(4n+1) {
            padding-left: 0;
          }

          border-bottom: 1px solid ${pureUiTheme.colors.gray40};
          font-size: 15px;

          &.lastRow {
            border-bottom: none;
          }
          
          &:nth-of-type(2n+2) {
            text-align: right;
          }
        }
      }
      
      .footer {
        display: flex;
        align-items: center;
        padding-left: 20px;
        height: 50px;
        
        a {
          font-weight: 700;
          font-size: 15px;
          line-height: 25px;
          text-decoration: underline;
          color: ${pureUiTheme.colors.brownPrime};
          cursor: pointer;
        }
      }
    }
`;

