import { createSelector } from 'reselect';
import { ICompanyMembershipsDomain, ICompanyMembershipsRequests } from './model';
import { ICompanyMembership } from 'services/BookingManagerApi/types/CompanyMemberships';

export const domainSelector = (state: any) => state.companyMemberships;

export const companyMembershipsSelector = createSelector(
  domainSelector,
  (domain: ICompanyMembershipsDomain): ICompanyMembership[] | null => domain.memberships
);

export const companyMembershipsRequestsSelector = createSelector(
  domainSelector,
  (domain: ICompanyMembershipsDomain): ICompanyMembershipsRequests => domain.requests
);

export const isMembershipModalOpenSelector = createSelector(
  domainSelector,
  (domain: ICompanyMembershipsDomain): boolean => domain.modals.isMembershipModalOpen
);

export const deleteMembershipModalSelector = createSelector(
  domainSelector,
  (domain: ICompanyMembershipsDomain): boolean => domain.modals.isDeleteMembershipModalOpen
);

export const uuidToDeleteSelector = createSelector(
  domainSelector,
  (domain: ICompanyMembershipsDomain): string | null => domain.uuidToDelete
);

export const membershipToEditSelector = createSelector(
  domainSelector,
  (domain: ICompanyMembershipsDomain): ICompanyMembership | null => domain.membershipToEdit
);
