import React from 'react';
import classNames from 'classnames';

export interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  wrapperClassName?: string;
  indeterminate?: boolean;
  // props added to support legacy checkbox OWA 3982
  color?: string;
  borderColor?: string;
  borderWidth?: number;
  rounded?: boolean;
  large?: boolean;
  left?: string;
  checkedClassName?: string;
}

const Checkbox = (props: CheckboxProps) => {
  const {
    checked,
    className,
    wrapperClassName = '',
    indeterminate = false,
    disabled,
    color = 'teal-100',
    borderColor = 'gray-40',
    borderWidth = 1,
    rounded = false,
    checkedClassName,
    large = false,
    left = '1px',
    ...checkboxProps
  } = props;

  const minSizeClasses = {
    [`min-w-5`]: !large,
    [`min-h-5`]: !large,
    [`min-w-6`]: large,
    [`min-h-6`]: large,
  };
  const maxSizeClasses = {
    [`max-w-5`]: !large,
    [`max-h-5`]: !large,
    [`max-w-6`]: large,
    [`max-h-6`]: large,
  };
  const sizeClasses = { ...minSizeClasses, ...maxSizeClasses };

  const checkboxWrapperClassName = classNames(wrapperClassName, {
    'border-solid relative': true,
    'bg-gray-10': disabled,
    'bg-ivory': !checked,
    [`border-${borderWidth}`]: true,
    [`border-${borderColor}`]: true,
    [`bg-${color}`]: checked,
    rounded,
    ...sizeClasses,
  });

  const checkMarkClassName = classNames(
    {
      invisible: !checked,
      hidden: indeterminate,
      'visible flex justify-center items-center relative': checked,
      [`-left-${left}`]: true,
      ...minSizeClasses,
    },
    checkedClassName
  );

  const indeterminateBoxClassName = classNames({
    invisible: !indeterminate,
    'visible flex justify-center items-center relative': indeterminate,
    [`-left-${left}`]: true,
    ...minSizeClasses,
  });

  return (
    <div className={checkboxWrapperClassName}>
      <span className={checkMarkClassName}>
        <i
          className={classNames('fas fa-check', {
            'text-gray-40': disabled,
            'text-gray-10': !disabled,
            'text-sm': !large,
            'text-base': large,
          })}
        ></i>
      </span>
      <span className={indeterminateBoxClassName}>
        <i
          style={{
            marginTop: '-1px',
            transform: 'scale(0.7)',
          }}
          className={classNames('fas fa-square text-teal-40 text-sm', {
            'text-sm': !large,
            'text-base': large,
          })}
        ></i>
      </span>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        className={classNames(
          className,
          'm-0 p-0 cursor-pointer appearance-none absolute top-0 bottom-0 right-0 left-0 opacity-0',
          { ...sizeClasses }
        )}
        {...checkboxProps}
      />
    </div>
  );
};

export default Checkbox;
