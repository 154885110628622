import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const ExclamationIcon: React.FC<IIconProps> = React.memo(({ icon = 'red-95' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.666504 9.00008C0.666504 4.40008 4.39984 0.666748 8.99984 0.666748C13.5998 0.666748 17.3332 4.40008 17.3332 9.00008C17.3332 13.6001 13.5998 17.3334 8.99984 17.3334C4.39984 17.3334 0.666504 13.6001 0.666504 9.00008ZM9.83317 8.16675V13.1667H8.1665V8.16675H9.83317ZM8.99984 15.6667C5.32484 15.6667 2.33317 12.6751 2.33317 9.00008C2.33317 5.32508 5.32484 2.33341 8.99984 2.33341C12.6748 2.33341 15.6665 5.32508 15.6665 9.00008C15.6665 12.6751 12.6748 15.6667 8.99984 15.6667ZM9.83317 4.83342V6.50008H8.1665V4.83342H9.83317Z"
      fill={theme.colors[icon]}
    />
  </svg>
));

export default ExclamationIcon;
