import React from 'react';
import styled, { css } from 'styled-components';
import { pureUiTheme } from '../pureUiTheme';
import { Link, LinkProps } from 'react-router-dom';

// hardcoded material-ui icons SVGs
export const AddIcon = () => {
  return (
    <svg
      className="icon"
      width="24"
      height="24"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      role="presentation"
    >
      <path fill="currentColor" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
    </svg>
  );
};
export const BackIcon = () => {
  return (
    <svg
      className="icon"
      width="24"
      height="24"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      role="presentation"
    >
      <path fill="currentColor" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
    </svg>
  );
};
export const CloseIcon = () => {
  return (
    <svg
      className="icon"
      width="24"
      height="24"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      role="presentation"
    >
      <path
        fill="currentColor"
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      ></path>
    </svg>
  );
};
export const DuplicateIcon = () => {
  return (
    <svg
      className="icon"
      width="24"
      height="24"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      role="presentation"
    >
      <path
        fill="currentColor"
        d="M16 8h-2v3h-3v2h3v3h2v-3h3v-2h-3zM2 12c0-2.79 1.64-5.2 4.01-6.32V3.52C2.52 4.76 0 8.09 0 12s2.52 7.24 6.01 8.48v-2.16C3.64 17.2 2 14.79 2 12zm13-9c-4.96 0-9 4.04-9 9s4.04 9 9 9 9-4.04 9-9-4.04-9-9-9zm0 16c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z"
      ></path>
    </svg>
  );
};
export const EditIcon = () => {
  return (
    <svg
      className="icon"
      width="24"
      height="24"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      role="presentation"
    >
      <path
        fill="currentColor"
        d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
      ></path>
    </svg>
  );
};
export const ForwardIcon = () => {
  return (
    <svg
      className="icon"
      width="24"
      height="24"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      role="presentation"
    >
      <path fill="currentColor" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
    </svg>
  );
};
export const RemoveIcon = () => {
  return (
    <svg
      className="icon"
      width="24"
      height="24"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      role="presentation"
    >
      <path fill="currentColor" d="M19 13H5v-2h14v2z"></path>
    </svg>
  );
};
export const GetAppIcon = () => {
  return (
    <svg className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" aria-hidden="true" role="presentation">
      <path
        d="M5.01562 18H18.9844V20.0156H5.01562V18ZM18.9844 9L12 15.9844L5.01562 9H9V3H15V9H18.9844Z"
        fill="currentColor"
      />
    </svg>
  );
};
export const RefreshIcon = () => {
  return (
    <svg
      className="icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      focusable="false"
      aria-hidden="true"
      role="presentation"
    >
      <path
        fill="currentColor"
        d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"
      ></path>
    </svg>
  );
};

export const buttonStates = css`
  transition: all 0.15s ease-out;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${pureUiTheme.colors.marine};
  }

  &:active {
    background-color: ${pureUiTheme.colors.black};
    box-shadow: 0 0 0 8px ${pureUiTheme.colors.lightBlue};
  }
`;

export const baseButtonStyles = css`
  position: relative;
  display: block;
  line-height: 35px;
  font-family: 'HurmeGeometricSans2';
  font-size: 14px;
  font-weight: 600;
  background-color: ${pureUiTheme.colors.gold};
  color: ${pureUiTheme.colors.white};
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0 0 0 5px transparent;
  flex-shrink: 1;
  flex-grow: 1;

  &:hover:enabled {
    background-color: ${pureUiTheme.colors.teal};
  }

  &:disabled {
    cursor: default;
    opacity: 0.75;
    background-color: ${pureUiTheme.colors.goldOpacity};
    border-color: ${pureUiTheme.colors.goldDark};
  }

  ${buttonStates}
`;

export const PrimaryButton = styled.button`
  ${baseButtonStyles}
`;

export const SecondaryButton = styled.button`
  ${baseButtonStyles}
  background-color: transparent;
  border: ${pureUiTheme.colors.goldBorder} 1px solid;
  color: ${pureUiTheme.colors.goldBorder};

  &:hover:enabled {
    color: ${pureUiTheme.colors.white};
  }

  &:disabled {
    cursor: default;
    opacity: 0.75;
  }
`;

export const PrimaryButtonSmall = styled.button`
  ${baseButtonStyles}
  height: 27px;
  line-height: 27px;
`;

export const PrimaryButtonTall = styled.button`
  ${baseButtonStyles}
  height: 70px;
`;

// @ts-ignore
export const PrimaryButtonTallAltColor = styled(PrimaryButtonTall)`
  background-color: ${pureUiTheme.colors.grayDarker};

  &:disabled {
    cursor: default;
    opacity: 0.75;
    background-color: ${pureUiTheme.colors.grayDarker};
    border-color: ${pureUiTheme.colors.grayDarker};
  }
`;

export const RoundedIconButton = styled(PrimaryButton)`
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 100px;

  .material-icons {
    top: 0;
    bottom: 0;
    height: 100%;
  }
`;

export const CloseButton = (props: any) => (
  // @ts-ignore
  <RoundedIconButton {...props}>
    <CloseIcon />
  </RoundedIconButton>
);

export const DownloadButton = (props: any) => (
  // @ts-ignore
  <RoundedIconButton {...props}>
    <GetAppIcon />
  </RoundedIconButton>
);

export const RefreshButton = (props: any) => (
  // @ts-ignore
  <RoundedIconButton {...props}>
    <RefreshIcon />
  </RoundedIconButton>
);

export const IconButton = styled.button`
  transition: all 0.15s ease-out;
  font-size: 0; /* Fixes flash of text bug in material UI */
  border: none;
  background: transparent;
  color: ${pureUiTheme.colors.gold};
  box-shadow: 0 0 0 5px transparent;

  &:hover {
    color: ${pureUiTheme.colors.goldDark};
  }

  ${buttonStates}
`;

export const Tab = styled.button`
  background-color: transparent;
  border: none;
  line-height: 35px;
  font-family: 'HurmeGeometricSans2';
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  display: block;
  flex-grow: 1;
  text-transform: uppercase;
`;

export const LinkButton = styled(Link)<LinkProps>`
  ${baseButtonStyles};
  text-align: center;
  color: ${pureUiTheme.colors.white} !important; /* Something somewhere is leaking styles for link colors */
`;

export const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;

  button {
    margin-right: 10px;
  }

  button:last-of-type {
    margin-right: 0;
  }
`;

export const ButtonSpacer = styled.div.attrs(() => ({
  'aria-hidden': 'true',
}))`
  flex-grow: 1;
`;

export interface IActionButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  action: 'add' | 'remove' | 'close' | 'edit' | 'duplicate' | 'forward' | 'back';
  disabled?: boolean;
}

export const ActionButtonComponent = (props: IActionButtonProps) => {
  return (
    <button {...props}>
      <span>
        {props.action === 'add' && <AddIcon />}
        {props.action === 'remove' && <RemoveIcon />}
        {props.action === 'close' && <CloseIcon />}
        {props.action === 'edit' && <EditIcon />}
        {props.action === 'duplicate' && <DuplicateIcon />}
        {props.action === 'forward' && <ForwardIcon />}
        {props.action === 'back' && <BackIcon />}
        <span className="children">{props.children}</span>
      </span>
    </button>
  );
};

const actionButtonStyles = css`
  display: inline-block;
  color: ${pureUiTheme.colors.gold};
  border: 1px solid ${pureUiTheme.colors.gold};
  border-radius: 100px;
  box-shadow: 0 0 0 5px transparent;
  cursor: pointer;
  background: white;

  & > span {
    display: flex;
    align-items: center;
  }

  .children {
    flex-grow: 1;
    padding-right: 5px;
  }

  .icon {
    top: 0;
    bottom: 0;
    height: 100%;
    margin: 0 8px 0 0;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 0 2px ${pureUiTheme.colors.teal};
  }

  &:disabled {
    opacity: 0.5;
  }

  &:active {
    color: ${pureUiTheme.colors.teal};
    border-color: ${pureUiTheme.colors.teal};
  }
`;

export const ActionButton = styled(ActionButtonComponent)`
  ${actionButtonStyles}
`;

export interface IActionLinkButtonProps extends IActionButtonProps {
  to: string;
}

export const ActionLinkButtonComponent = (props: IActionLinkButtonProps) => {
  const { to, className } = props;

  return (
    <a href={to} className={className}>
      <span>
        {props.action === 'add' && <AddIcon />}
        {props.action === 'remove' && <RemoveIcon />}
        {props.action === 'close' && <CloseIcon />}
        {props.action === 'edit' && <EditIcon />}
        {props.action === 'duplicate' && <DuplicateIcon />}
        {props.action === 'forward' && <ForwardIcon />}
        {props.action === 'back' && <BackIcon />}
        <span className="children">{props.children}</span>
      </span>
    </a>
  );
};

export const ActionLinkButton = styled(ActionLinkButtonComponent)`
  ${actionButtonStyles}
  font-size: 13px;
  padding-right: 16px;
  span svg.icon {
    margin-left: 8px;
  }
`;
