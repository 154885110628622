import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { CircleIconButton } from '../CircleIconButton';

enum EFilePickerStates {
  LOADED_WITH_UPLOAD_NAME_PROP,
  EMPTY_FILE_PICKER,
  SELECTED_NEW_FILE,
}

interface ISelectedFileInputProps {
  fileName: string;
  onClick: () => void;
}

const SelectedFileInput = React.memo((props: ISelectedFileInputProps) => {
  return (
    <div className="bg-brown-prime-10 border border-solid border-gray-40 p-2 flex items-center justify-between">
      <span className="w-9/10">{props.fileName}</span>
      <CircleIconButton type="secondary" iconClass="far fa-trash-alt" onClick={props.onClick} />
    </div>
  );
});

interface IFilePickerProps {
  isAttachmentRequired: boolean;
  uploadName?: string;
  onFileRemove: () => void;
  onFileSelect: (files: File[]) => void;
  isLoading?: boolean;
  showAttachFileLabel?: boolean;
  acceptString?: string;
  className?: string; // the class we put round the entire thing
}

export const FilePicker: React.FC<IFilePickerProps> = React.memo(
  ({
    isAttachmentRequired,
    uploadName,
    onFileRemove,
    onFileSelect,
    isLoading = false,
    showAttachFileLabel = true,
    acceptString,
    className = 'file-picker block w-full mt-25px',
  }) => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [filePickerState, setFilePickerState] = useState<EFilePickerStates>(
      uploadName != undefined ? EFilePickerStates.LOADED_WITH_UPLOAD_NAME_PROP : EFilePickerStates.EMPTY_FILE_PICKER
    );

    const onDrop = useCallback(acceptedFiles => {
      if (acceptedFiles.length <= 0) {
        return;
      }
      setFilePickerState(EFilePickerStates.SELECTED_NEW_FILE);
      setSelectedFiles(acceptedFiles);
      onFileSelect(acceptedFiles);
    }, []);

    const extraOptions = {};

    if (acceptString !== undefined) {
      extraOptions['accept'] = acceptString;
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop, ...extraOptions });

    const handleChange = useCallback(() => {
      if (filePickerState === EFilePickerStates.LOADED_WITH_UPLOAD_NAME_PROP) {
        setFilePickerState(EFilePickerStates.EMPTY_FILE_PICKER);
        onFileRemove();
      }

      if (selectedFiles.length >= 1 && filePickerState === EFilePickerStates.SELECTED_NEW_FILE) {
        const newSelectedFiles = selectedFiles.filter(f => f.name !== selectedFiles[0].name);
        if (newSelectedFiles.length <= 0) {
          setFilePickerState(EFilePickerStates.EMPTY_FILE_PICKER);
        }
        setSelectedFiles(newSelectedFiles);
        onFileSelect(newSelectedFiles);
      }
    }, [filePickerState, selectedFiles, onFileRemove, onFileSelect]);

    // if we're loading, just short circuit a quick loading spinner
    if (isLoading) {
      return (
        <div className="outline-none italic bg-brown-prime-10 text-gray-100 border-2 border-gray-40 text-center border-dashed p-4 text-sm">
          <span>Loading...</span>
        </div>
      );
    }

    return (
      <div className={className}>
        {showAttachFileLabel && (
          <span className="mb-5px text-black text-base font-bold leading-21px tracking-2xs font-pt-sans flex items-center">
            <span>Attach file</span>
            <span className="font-normal ml-1 text-sm">{isAttachmentRequired ? '(Required)' : '(Optional)'}</span>
          </span>
        )}

        {uploadName && filePickerState === EFilePickerStates.LOADED_WITH_UPLOAD_NAME_PROP && (
          <SelectedFileInput fileName={uploadName} onClick={handleChange} />
        )}

        {selectedFiles.length >= 1 && filePickerState === EFilePickerStates.SELECTED_NEW_FILE && (
          <SelectedFileInput fileName={selectedFiles[0].name} onClick={handleChange} />
        )}

        {filePickerState === EFilePickerStates.EMPTY_FILE_PICKER && (
          <div
            className="cursor-pointer outline-none italic bg-brown-prime-10 text-gray-100 border-2 border-gray-40 text-center border-dashed p-4 text-sm"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <span className="block">Drag & drop a file here</span>
            <span className="block">
              or <span className="file-picker-select underline text-brown-80">select from your computer</span>
            </span>
          </div>
        )}
      </div>
    );
  }
);
