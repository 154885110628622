import { hotelInitialState, IHotelSubdomain } from './model';
import * as Actions from './actions';
import { ENetworkRequestStatus } from 'services/BackendApi';

export const bookingBuilderHotelReducer = (
  state: IHotelSubdomain = hotelInitialState,
  action: Actions.BookingBuilderHotelAction
): IHotelSubdomain => {
  switch (action.type) {
    case Actions.SET_HOTEL:
      return {
        ...hotelInitialState,
        hotel: action.hotel,
      };

    case Actions.UNSET_HOTEL:
      return {
        ...hotelInitialState,
        hotel: undefined,
        networkRequests: {
          hotelLoad: ENetworkRequestStatus.IDLE,
        }
      };

    case Actions.GET_HOTEL_REQUEST:
      return {
        ...state,
        hotel: undefined,
        networkRequests: {
          hotelLoad: ENetworkRequestStatus.PENDING,
        }
      };

    case Actions.GET_HOTEL_SUCCESS:
      return {
        ...state,
        hotel: action.hotel,
        networkRequests: {
          hotelLoad: ENetworkRequestStatus.SUCCESS,
        }
      };

    case Actions.GET_HOTEL_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        networkRequests: {
          hotelLoad: ENetworkRequestStatus.ERROR,
        }
      };

    default:
      return state;
  }
};
