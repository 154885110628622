import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { makeBookingManagerApi } from 'services/BookingManagerApi';
import { formatDateDisplay, getCurrencyBySymbol } from 'utils';
import * as HotelDetailsSelectors from 'store/modules/bookingManager/subdomains/hotelDetails/selectors';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { AddActivityModal } from '../../ui/AddActivityModal/AddActivityModal';
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import * as DashboardSelectors from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import { IValueLabelPair, TCountryCode, TCurrencyCode } from 'interfaces';
import { isNil } from 'lodash-es';
import { IHeadlineLineItemBreakdownComponent } from '../../ui/HeadlineLineItemBreakdown';
import { IMultiselectValueLabelPair } from 'ui/Multiselect';
import { ENetworkRequestStatus, IGuestAges } from 'services/BackendApi';
import {
  AncillaryProductWithRates,
  AncillaryProductWithRatesWithUserData,
  AncillaryRatedResult,
  isAncillaryRatedResult,
  isAncillaryRatedResultPerUnit,
  SelectedAncillarySearchResult,
} from 'services/BookingManagerApi/types/AncillaryService';
import { flatten } from 'lodash-es';
import * as axios from 'axios';
import {
  generateBreakdownItemDataForAncillaryProduct,
  getErrorIdsMissingFlexibleAttributes,
} from 'ui/AddAncillarySharedComponents/utils';

export interface IAddActivityModalContainerProps {
  bookingUuid: string;
  bookingCurrencySymbol: IHeadlineLineItemBreakdownComponent['bookingCurrency'];
}

const GA_FLOW_TAG = 'act-modal';

export const AddActivityModalContainer = (props: IAddActivityModalContainerProps) => {
  const { dynamicParameters } = useDynamicParameters();
  const activity = useSelector(BreakdownSelectors.breakdownAddActivitySelector);
  const dispatch = useDispatch();
  const hotelDetails = useSelector(HotelDetailsSelectors.hotelDetailsSelector);
  const topNavigationData = useSelector(DashboardSelectors.topNavigationDataDashboardSelector);
  const bookingManagerApi = makeBookingManagerApi();
  const existingCancellationPolicies = useSelector(BreakdownSelectors.cancellationPoliciesSelector);
  const existingPaymentTerms = useSelector(BreakdownSelectors.paymentTermsSelector);

  const currencyData = getCurrencyBySymbol(props.bookingCurrencySymbol!);
  const currencyCode = currencyData?.code;
  const { isAddActivityModalOpen } = activity;
  const [countryCode, setCountryCode] = React.useState<TCountryCode>(hotelDetails.countryCode! as TCountryCode);
  const [startAndEndDate, setStartAndEndDate] = React.useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: topNavigationData?.arrivalDate as string,
    endDate: topNavigationData?.departureDate as string,
  });
  const [addActivityCountries, setAddActivityCountries] = useState<any[]>([]);
  const [addActivityTypes, setAddActivityTypes] = useState<any[]>([]);
  const [regions, setRegions] = useState<IMultiselectValueLabelPair[]>([]);
  const [selectedActivityRegionId, setSelectedActivityRegionId] = useState<string>('-1'); // defaults to -1 to represent "All"
  const [selectedActivityFilterIds, setSelectedActivityFilterIds] = useState<string[]>(['-1']); // defaults to -1 to represent "All"
  const [selectedSearchResult, setSelectedSearchResult] = useState<SelectedAncillarySearchResult | null>(null);

  const [guestAges, setGuestAges] = useState<IGuestAges>({
    numberOfAdults: topNavigationData?.guestCount! - topNavigationData!.childrenAges.length,
    agesOfAllChildren: topNavigationData!.childrenAges,
  });

  const [selectedActivityOrSupplier, setSelectedActivityOrSupplier] = useState<{
    id: string;
    type: 'activity' | 'supplier';
  } | null>(null);

  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [errorIds, setErrorIds] = useState<string[]>([]);

  const [getSearchResultsNetworkStatus, setGetSearchResultsNetworkStatus] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [getTypesResultsNetworkStatus, setGetTypesResultsNetworkStatus] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [getRegionsResultsNetworkStatus, setGetRegionsResultsNetworkStatus] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [getActivityProductNetwork, setGetActivityProductNetwork] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );

  // we need state variables for the given instance of a search
  const [searchInstanceVars, setSearchInstanceVars] = useState<{
    startAndEndDate?: { startDate: string; endDate: string };
    guestAges?: IGuestAges;
  }>({});

  // this is the one that comes down from the API, and is the one that we'll be updating
  const [selectedActivityProduct, setSelectedActivityProduct] = useState<AncillaryProductWithRatesWithUserData | null>(
    null
  );

  // when we mount, we need to get the countries for the activities
  useEffect(() => {
    async function fetchCountries() {
      const c = await bookingManagerApi.getAncillaryActivitiesCountries(currencyCode);
      setAddActivityCountries(c.data);
    }
    fetchCountries();
  }, [currencyCode]);

  // when the country changes, we need to get new regions
  useEffect(() => {
    async function fetchRegions() {
      setGetRegionsResultsNetworkStatus(ENetworkRequestStatus.PENDING);
      try {
        const r = await bookingManagerApi.getAncillaryActivitiesRegions({
          currencyCode,
          countryCode,
        });
        setRegions(r.data.map(r => ({ value: r.id.toString(), label: r.name })));
        setGetRegionsResultsNetworkStatus(ENetworkRequestStatus.SUCCESS);
      } catch (e) {
        setGetRegionsResultsNetworkStatus(ENetworkRequestStatus.ERROR);
        setRegions([]);
      }
    }

    if (isAddActivityModalOpen) {
      fetchRegions();
    }
  }, [isAddActivityModalOpen, countryCode, currencyCode]);

  // when region or date changes, get new types
  useEffect(() => {
    async function fetchActivitiesTypes() {
      if (isNil(startAndEndDate.startDate) || isNil(startAndEndDate.endDate) || isNil(selectedActivityRegionId)) {
        return;
      }
      if (!isAddActivityModalOpen) {
        return;
      }
      setGetTypesResultsNetworkStatus(ENetworkRequestStatus.PENDING);
      try {
        const t = await bookingManagerApi.getAncillaryActivitiesFilterIds({
          currencyCode,
          countryCode,
          startDate: startAndEndDate.startDate,
          endDate: startAndEndDate.endDate,
          regionId: selectedActivityRegionId,
        });
        setAddActivityTypes(t.data.map(t => ({ value: t.id.toString(), label: t.value })));
        setGetTypesResultsNetworkStatus(ENetworkRequestStatus.SUCCESS);
      } catch (e) {
        setGetTypesResultsNetworkStatus(ENetworkRequestStatus.ERROR);
        setAddActivityTypes([]);
      }
    }

    fetchActivitiesTypes();
  }, [isAddActivityModalOpen, startAndEndDate, selectedActivityRegionId, countryCode]);

  const handleSearchButtonClick = async () => {
    if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
      // @ts-ignore
      window.gtag('event', `${GA_FLOW_TAG}_search`, {});
    }
    setGetSearchResultsNetworkStatus(ENetworkRequestStatus.PENDING);
    setSelectedSearchResult(null);
    if (isNil(startAndEndDate.startDate) || isNil(startAndEndDate.endDate)) {
      return;
    }
    try {
      const searchResults = await bookingManagerApi.getAncillaryActivitiesSearchResults({
        bookingUuid: props.bookingUuid,
        startDate: startAndEndDate.startDate,
        endDate: startAndEndDate.endDate,
        numberOfAdults: guestAges.numberOfAdults,
        agesOfAllChildren: guestAges.agesOfAllChildren,
        regionId: parseInt(selectedActivityRegionId),
        countryCode,
        filterValueIds: selectedActivityFilterIds.map(f => parseInt(f)),
        supplierId:
          selectedActivityOrSupplier?.type === 'supplier' ? parseInt(selectedActivityOrSupplier.id) : undefined,
        baseProductId:
          selectedActivityOrSupplier?.type === 'activity' ? parseInt(selectedActivityOrSupplier.id) : undefined,
      });

      setGetSearchResultsNetworkStatus(ENetworkRequestStatus.SUCCESS);
      setSearchResults(searchResults.data.results);

      setSearchInstanceVars({
        startAndEndDate,
        guestAges,
      });
    } catch (e) {
      setGetSearchResultsNetworkStatus(ENetworkRequestStatus.ERROR);
      setSearchResults([]);
    }
  };

  const handleCloseModal = () => {
    if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
      // @ts-ignore
      window.gtag('event', `${GA_FLOW_TAG}_close`, {});
    }
    dispatch(BreakdownActions.closeAddActivityModalAction());
    // also reset all the data
    setSelectedActivityRegionId('-1');
    setSelectedActivityFilterIds(['-1']);
    setSelectedActivityOrSupplier(null);
    setSearchResults([]);
    setSelectedSearchResult(null);
    setErrorIds([]);
    // Set default booking data
    setGuestAges({
      numberOfAdults: topNavigationData?.guestCount! - topNavigationData!.childrenAges.length,
      agesOfAllChildren: topNavigationData!.childrenAges,
    });
    setStartAndEndDate({
      startDate: topNavigationData?.arrivalDate as string,
      endDate: topNavigationData?.departureDate as string,
    });
  };

  const handleSetSelectedActivityOrSupplier = d => {
    if (isNil(d)) {
      setSelectedActivityOrSupplier(null);
      return;
    }
    setSelectedActivityOrSupplier({ id: d.id, type: d.type });
  };

  const handleActivityOrSupplierSelectOnLookup = async (
    searchTerm: string,
    signal: AbortSignal,
    cancelTokenSource: axios.CancelTokenSource
  ) => {
    // if any of the data is null, things are still loading, error out
    if (
      isNil(startAndEndDate.startDate) ||
      isNil(startAndEndDate.endDate) ||
      isNil(currencyCode) ||
      isNil(countryCode) ||
      isNil(selectedActivityRegionId)
    ) {
      return [];
    }

    const as = await bookingManagerApi.getAncillaryActivitiesActivityOrSupplier({
      currencyCode,
      countryCode,
      startDate: startAndEndDate.startDate,
      endDate: startAndEndDate.endDate,
      regionId: selectedActivityRegionId,
      filterValueIds: selectedActivityFilterIds,
      searchTerm,
      signal,
      cancelTokenSource,
    });

    const options: IMultiselectValueLabelPair[] = [];

    as.data.products.forEach(p => {
      options.push({ value: `activity-${p.id.toString()}`, label: p.name });
    });

    as.data.suppliers.forEach(s => {
      options.push({ value: `supplier-${s.id.toString()}`, label: s.name });
    });

    return options;
  };

  // retrieves the activity product for the current state
  // and updates the selectedActivityProduct state as well as the
  // network state variables
  const retrieveAndUpdateSelectedActivityProduct = async (
    selectedSearchResult: SelectedAncillarySearchResult,
    perUnitQuantityOverride?: number,
    retainUserData?: boolean
  ) => {
    if (
      isNil(searchInstanceVars.startAndEndDate) ||
      isNil(selectedSearchResult) ||
      isNil(searchInstanceVars.guestAges)
    ) {
      return;
    }
    setGetActivityProductNetwork(ENetworkRequestStatus.PENDING);
    try {
      const t = await bookingManagerApi.getAncillaryRatesProduct({
        bookingUuid: props.bookingUuid,
        dates: [searchInstanceVars.startAndEndDate.startDate, searchInstanceVars.startAndEndDate.endDate],
        numberOfAdults: searchInstanceVars.guestAges.numberOfAdults,
        agesOfAllChildren: searchInstanceVars.guestAges.agesOfAllChildren,
        baseProductId: parseInt(selectedSearchResult!.baseProductId),
        variantId: selectedSearchResult!.variantId ? parseInt(selectedSearchResult!.variantId) : undefined,
        perUnitQuantityOverride,
      });

      // the selected rate is:
      // if we already have a selected product, its the one with the same date
      // if we dont, its the first one that has a rate
      let selectedRateDateIndex = selectedActivityProduct
        ? Object.values(t.data.rates).findIndex(
            r => r.date === selectedActivityProduct!.userData?.rate?.date && r.hasRateForDate
          )
        : Object.values(t.data.rates).findIndex(r => r.hasRateForDate);

      // if, after that, we still dont have a selectedRateDateIndex
      // (probably because we already had a product, but we couldn't get a matching new rate)
      // then just select the first one that does have a rate after all
      if (selectedRateDateIndex === -1) {
        selectedRateDateIndex = Object.values(t.data.rates).findIndex(r => r.hasRateForDate);
      }

      const selectedRate = Object.values(t.data.rates)[selectedRateDateIndex] as AncillaryRatedResult;

      const originalUserData = selectedActivityProduct?.userData;

      if (retainUserData && originalUserData) {
        setSelectedActivityProduct({
          product: t.data.product,
          rates: t.data.rates,
          userData: {
            ...originalUserData,

            // this is "set unit count to perUnitQuantityOverride if we have it...
            // ...if we dont, set it to the rates quantity; if we dont have THAT,
            // ...set it to undefined"
            unitCount: perUnitQuantityOverride
              ? perUnitQuantityOverride
              : isAncillaryRatedResultPerUnit(selectedRate)
              ? selectedRate.quantity
              : undefined,

            // update the rate, but keep the original selected date
            rate: selectedRate,
          },
        });
      } else {
        setSelectedActivityProduct({
          product: t.data.product,
          rates: t.data.rates,
          userData: {
            rate: selectedRate,
            optionSelections: {},
            unitCount: isAncillaryRatedResultPerUnit(selectedRate) ? selectedRate.quantity : undefined,
          },
        });
      }
      setGetActivityProductNetwork(ENetworkRequestStatus.SUCCESS);
    } catch (e) {
      setSelectedActivityProduct(null);
      setGetActivityProductNetwork(ENetworkRequestStatus.ERROR);
    }
  };

  const handleAddActivity = () => {
    if (isNil(selectedActivityProduct) || !isAncillaryRatedResult(selectedActivityProduct.userData.rate!)) {
      return;
    }

    const newErrorIds = getErrorIdsMissingFlexibleAttributes(selectedActivityProduct);

    setErrorIds(newErrorIds);

    if (newErrorIds.length > 0) {
      return;
    }

    const {
      title,
      tertiaryText,
      purchaseCostCents,
      saleCostCents,
      flexibleAttributesNonValidatedSelections,
    } = generateBreakdownItemDataForAncillaryProduct(selectedActivityProduct, guestAges);

    dispatch(
      BreakdownActions.addHeadlineLineItemAction(
        'Bespoke.items',
        {
          title,
          tertiaryText,
          saleCostCents,
          purchaseCostCents,
        },
        {
          isNew: true,
          product: selectedActivityProduct.product,
          rateForDate: selectedActivityProduct.userData.rate!,
          userSelections: {
            selectedDate: selectedActivityProduct.userData.rate!.date,
            numberOfAdults: searchInstanceVars.guestAges!.numberOfAdults,
            agesOfAllChildren: searchInstanceVars.guestAges!.agesOfAllChildren,
            baseProductId: selectedActivityProduct.product.baseProductId,
            variantId: !isNil(selectedActivityProduct.product.variantId)
              ? selectedActivityProduct.product.variantId
              : undefined,
            perUnitQuantityOverride: selectedActivityProduct.userData.unitCount,
            flexibleAttributesNonValidatedSelections,
            selectionHash: selectedActivityProduct.userData.rate!.hash,
          },
        }
      )
    );

    dispatch(
      BreakdownActions.setCancellationPoliciesAction(`${
        isNil(existingCancellationPolicies) ? '' : existingCancellationPolicies
      }${isNil(existingCancellationPolicies) || existingCancellationPolicies === '' ? '' : '\n\n'}Activity: ${title}
${selectedActivityProduct.userData.rate.rateCancellationPolicy}`)
    );
    dispatch(
      BreakdownActions.setPaymentTermsAction(`${isNil(existingPaymentTerms) ? '' : existingPaymentTerms}${
        isNil(existingPaymentTerms) || existingPaymentTerms === '' ? '' : '\n\n'
      }Activity: ${title}
${selectedActivityProduct.userData.rate.ratePaymentTerms}`)
    );

    handleCloseModal();
  };

  const handleSetSelectedSearchResult = async (selectedSearchResult: SelectedAncillarySearchResult | null) => {
    if (isNil(selectedSearchResult)) {
      setSelectedActivityProduct(null);
      return;
    }

    setSelectedSearchResult(selectedSearchResult);
    await retrieveAndUpdateSelectedActivityProduct(selectedSearchResult);
  };

  const handleUpdateActivityProductWithUserData = async (
    data: Partial<AncillaryProductWithRatesWithUserData['userData']>
  ) => {
    setSelectedActivityProduct({
      ...selectedActivityProduct!,
      userData: {
        ...selectedActivityProduct!.userData,
        ...data,
      },
    });

    // if they've just updated the unit count...
    if (!isNil(data['unitCount']) && !isNil(selectedSearchResult)) {
      // ...retrieve the product again
      await retrieveAndUpdateSelectedActivityProduct(selectedSearchResult, data['unitCount'], true);
    }
  };

  return (
    <AddActivityModal
      isOpen={isAddActivityModalOpen}
      currencySymbol={props.bookingCurrencySymbol!}
      onClose={handleCloseModal}
      getSearchResultsNetwork={getSearchResultsNetworkStatus}
      onSearchButtonClick={handleSearchButtonClick}
      searchResults={searchResults}
      countryCode={countryCode}
      setCountryCode={setCountryCode}
      countries={addActivityCountries.map(c => ({ value: c.iso_code, label: c.name }))}
      selectedRegionId={selectedActivityRegionId}
      setSelectedRegionId={setSelectedActivityRegionId}
      selectedSearchResult={selectedSearchResult}
      setSelectedSearchResult={(selectedSearchResult: SelectedAncillarySearchResult | null) => {
        handleSetSelectedSearchResult(selectedSearchResult);
      }}
      regions={regions}
      typesOptions={addActivityTypes}
      selectedFilterIds={selectedActivityFilterIds}
      setSelectedFilterIds={setSelectedActivityFilterIds}
      selectedActivityOrSupplier={selectedActivityOrSupplier}
      setSelectedActivityOrSupplier={handleSetSelectedActivityOrSupplier}
      activityOrSupplierSelectOnLookup={handleActivityOrSupplierSelectOnLookup}
      startAndEndDate={startAndEndDate}
      setStartAndEndDate={setStartAndEndDate}
      guestAges={guestAges}
      searchInstanceVars={searchInstanceVars}
      setGuestAges={(a, b) => {
        setGuestAges({ numberOfAdults: a, agesOfAllChildren: b ? b : [] });
      }}
      getTypesResultsNetworkStatus={getTypesResultsNetworkStatus}
      getRegionsResultsNetworkStatus={getRegionsResultsNetworkStatus}
      errorIds={errorIds}
      handleAddActivity={handleAddActivity}
      selectedActivityProduct={selectedActivityProduct}
      updateSelectedActivityProductWithUserData={handleUpdateActivityProductWithUserData}
      getActivityProductNetwork={getActivityProductNetwork}
    />
  );
};
