import { ENetworkRequestStatus, Upload } from 'services/BackendApi';

export interface INewUploadPayload {
  file: File | null;
  displayName: string | null;
  tag: string | null;
}

export interface IUploadsSubdomain {
  uploads: {
    list: Upload[] | null;
    request: ENetworkRequestStatus;
  };
  newUpload: {
    payload: INewUploadPayload | null;
    privacyAck: boolean;
    request: ENetworkRequestStatus;
    error: string | null;
  };
  deleteUpload: {
    uuid: string | null;
    request: ENetworkRequestStatus;
  };
  canDeleteList: string[];
}

export const initialState: IUploadsSubdomain = {
  uploads: {
    list: null,
    request: ENetworkRequestStatus.IDLE,
  },
  newUpload: {
    payload: null,
    privacyAck: false,
    request: ENetworkRequestStatus.IDLE,
    error: null,
  },
  deleteUpload: {
    uuid: null,
    request: ENetworkRequestStatus.IDLE,
  },
  canDeleteList: [],
};
