import React, { useState, useCallback, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Formik, FormikProps, Field } from 'formik';
import {
  ENetworkRequestStatus,
  EUserType,
  IInternalUser,
  EInternalUserStatus,
  ETravelAgentStatus,
} from 'services/BackendApi';
import FluidButton from 'ui/FluidButton';
import { TextInput } from 'ui/TextInput';
import SingleSelect from 'ui/SingleSelect';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';

import { titleOptions } from 'utils/titles';
import { userTypesOptions } from 'utils/userTypes';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { countryOptions, EInternalUserFormMode } from './helpers';
import { getInternalUserFormValidationSchema } from './formValidation';
import * as InternalUserActions from 'store/modules/internalUser/actions';
import { internalUserCreateRequestSelector } from 'store/modules/internalUser/selectors';
import { UserStatusSelect } from 'ui/UserStatusSelect';

export interface IInternalUserFormProps {
  internalUser?: IInternalUser;
}

const titleOptionsWithoutNoTitle = titleOptions.slice(1);
export interface IInternalUserFormValues {
  uuid: string | null;
  title: string;
  iuFirstName: string;
  iuLastName: string;
  countryCode: string | null;
  companyUuid: string | null;
  iuEmail: string;
  iuPassword: string;
  mobileNumber: string | null;
  status: EInternalUserStatus | null;
  type: EUserType | null;
}

const buildInternalUserDataBasedOnFormValues = (
  formValues: IInternalUserFormValues,
): Partial<IInternalUser> => {
  const newInternalUSerData: Partial<IInternalUser> = {
    title: formValues.title,
    firstName: formValues.iuFirstName ?? '',
    lastName: formValues.iuLastName,
    countryCode: formValues.countryCode ?? undefined,
    mobileNumber: formValues.mobileNumber || null,
    status: formValues.status ?? undefined,
    type: formValues.type ?? undefined,
    email: formValues.iuEmail,
  };
  if (formValues.iuPassword) {
    newInternalUSerData.password = formValues.iuPassword;
  }

  return newInternalUSerData;
};

export const InternalUserForm: React.FC<IInternalUserFormProps> = React.memo(props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { internalUser } = props;

  const [showRejectWarning, setShowRejectWarning] = useState(false);

  const internalUserCreateNetwork = useSelector(internalUserCreateRequestSelector);

  const isSaving = internalUserCreateNetwork === ENetworkRequestStatus.PENDING;
  const isAlreadySaved = internalUserCreateNetwork === ENetworkRequestStatus.SUCCESS;
  
  const mode = internalUser?.uuid ? EInternalUserFormMode.EDIT : EInternalUserFormMode.CREATE;

  const notifications = []; // TO-DO

  const initialValues: IInternalUserFormValues = {
    uuid: null,
    title: internalUser?.title || titleOptionsWithoutNoTitle[0].value,
    iuFirstName: internalUser?.firstName || '',
    iuLastName: internalUser?.lastName || '',
    countryCode: internalUser?.countryCode || null,
    companyUuid: internalUser?.companyUuid || null,
    iuEmail: internalUser?.email || '',
    iuPassword: '',
    mobileNumber: internalUser?.mobileNumber || '',
    status: internalUser?.status || EInternalUserStatus.VERIFIED,
    type: internalUser?.type || null,
  };

  const handleFormSubmit = useCallback(
    (formValues: IInternalUserFormValues) => {
      const newInternalUserData: Partial<IInternalUser> = buildInternalUserDataBasedOnFormValues(
        formValues,
      );

      if (internalUser) {
        dispatch(
          InternalUserActions.updateInternalUserRequestAction(internalUser.uuid, newInternalUserData, notifications)
        );
      } else {
        dispatch(InternalUserActions.createInternalUserRequestAction(newInternalUserData, notifications, history));
      }
    },
    [dispatch, history, internalUser, notifications]
  );

  const handleCancelButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <div className="travel-company-form">
      <p className="mt-5 mb-0 font-pt-sans text-black-true font-bold text-17px leading-22px">User Info</p>
      <Formik
        initialValues={initialValues}
        validationSchema={getInternalUserFormValidationSchema(mode)}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {(form: FormikProps<IInternalUserFormValues>) => {
          const disableSaveButton = !(form.dirty && !isAlreadySaved && !isSaving);
          const showLeaveWithoutSavingModal = form.dirty && !isAlreadySaved && !isSaving;
          return (
            <form onSubmit={form.handleSubmit} autoComplete="off">
              <div className="travel-agent-form">
                <div className="first-row flex gap-20px mt-5">
                  <Field name="type">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="type"
                        label="User Role"
                        className="title w-250px"
                        value={value}
                        showSelectedOption
                        options={userTypesOptions}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                        errorMessage={form.touched.type && form.errors.type ? form.errors.type : null}
                        errorClassName="type-error"
                      />
                    )}
                  </Field>

                  <Field name="title">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="title"
                        label="Title"
                        className="title w-150px"
                        value={value ?? titleOptionsWithoutNoTitle[0].value}
                        showSelectedOption
                        options={titleOptionsWithoutNoTitle}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </Field>

                  <Field
                    as={TextInput}
                    type="text"
                    name="iuFirstName"
                    id="iuFirstName"
                    className="iuFirstName w-200px"
                    label="First Name"
                    errorMessage={form.touched.iuFirstName && form.errors.iuFirstName ? form.errors.iuFirstName : null}
                    errorClassName="firstName-error"
                  />

                  <Field
                    as={TextInput}
                    type="text"
                    name="iuLastName"
                    id="iuLastName"
                    className="iuLastName w-200px"
                    label="Last Name"
                    errorMessage={form.touched.iuLastName && form.errors.iuLastName ? form.errors.iuLastName : null}
                    errorClassName="lastName-error"
                  />

                  <Field name="countryCode">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="country"
                        label="Country"
                        className="country w-200px"
                        value={value}
                        options={countryOptions}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                        showSelectedOption
                        maxVisibleItems={10}
                        errorMessage={
                          form.touched.countryCode && form.errors.countryCode ? form.errors.countryCode : null
                        }
                        errorClassName="countryCode-error"
                      />
                    )}
                  </Field>
                </div>

                <div className="second-row flex gap-20px mt-5">
                  <Field
                    as={TextInput}
                    type="text"
                    name="mobileNumber"
                    id="mobile"
                    className="mobile w-150px"
                    label="Mobile Phone"
                    errorMessage={
                      form.touched.mobileNumber && form.errors.mobileNumber ? form.errors.mobileNumber : null
                    }
                    errorClassName="mobile-error"
                  />

                  <Field
                    as={TextInput}
                    type="text"
                    name="iuEmail"
                    id="iuEmail"
                    disabled={!!internalUser}
                    className="iuEmail w-200px"
                    label="Email"
                    autoComplete="off"
                    errorMessage={form.touched.iuEmail && form.errors.iuEmail ? form.errors.iuEmail : null}
                    errorClassName="email-error"
                  />

                  {internalUser && (
                    <Field name="status">
                      {({ field: { name, value }, form: { setFieldValue } }) => (
                        <UserStatusSelect
                          fieldId="status"
                          label="Status"
                          className="status w-200px font-pt-sans"
                          value={value}
                          allowedStatuses={[ETravelAgentStatus.VERIFIED, ETravelAgentStatus.REJECTED]}
                          onChange={value => {
                            setFieldValue(name, value);
                          }}
                          errorMessage={form.touched.status && form.errors.status ? form.errors.status : null}
                          errorClassName="status-error"
                        />
                      )}
                    </Field>
                  )}

                  {internalUser && internalUser.newlyCreatedTemporaryPassword && (
                    <div className="flex flex-col">
                      <label
                        className="flex text-black font-pt-sans text-13px leading-17px tracking-2xs mb-[10px] gap-[5px]"
                        htmlFor="temp-password-show"
                      >
                        <span>New Temporary Password</span>
                      </label>
                      <div className="flex items-center relative">
                        <span className="font-pt-sans text-black text-16px">
                          {internalUser.newlyCreatedTemporaryPassword}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="third-row flex flex-col mt-5">
                  <div className="flex items-center mt-5">
                    {!form.values.uuid && (
                      <FluidButton
                        className="cancel-button mr-10px"
                        onClick={handleCancelButton}
                        type="secondary"
                        disabled={isSaving || isAlreadySaved}
                      >
                        Cancel
                      </FluidButton>
                    )}

                    <FluidButton
                      type="primary"
                      className="confirm-button"
                      onClick={() => {
                        // AC #18 on https://pureescapes.atlassian.net/wiki/spaces/PEOWA/pages/2463891507/Internal+users#US-Edit-Internal-users
                        if (
                          (internalUser?.assignedTravelAgents || []).length >= 1 &&
                          form.values.status === EInternalUserStatus.REJECTED
                        ) {
                          setShowRejectWarning(true);
                        } else {
                          form.handleSubmit();
                        }
                      }}
                      // submit
                      disabled={disableSaveButton}
                      isLoading={isSaving}
                    >
                      Save
                    </FluidButton>
                  </div>
                </div>
              </div>
              {showRejectWarning && (
                <ConfirmationModal
                  className="leave-without-saving z-30"
                  type={EConfirmationModalType.WARNING}
                  title={`The user has Travel Agents assigned.
                If you reject the user, the assignments will be deleted.`}
                  message={'Are you sure you want to proceed?'}
                  confirmButtonLabel={'Confirm'}
                  cancelButtonLabel={'Cancel'}
                  onConfirm={() => form.handleSubmit()}
                  onCancel={() => setShowRejectWarning(false)}
                />
              )}
              <LeaveWithoutSavingModal
                title="You have made changes to this page, and not saved. If you leave this page now, these changes will be lost."
                when={showLeaveWithoutSavingModal}
                confirmButtonLabel="Yes"
                cancelButtonLabel="No"
              />
            </form>
          );
        }}
      </Formik>
    </div>
  );
});
