import React from 'react';
import classNames from 'classnames';

export interface IFixedButtonProps {
  type: 'primary' | 'secondary';
  text: string;
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
}

export const FixedButton = (props: IFixedButtonProps) => {
  const defaultButtonClassName = `
    ${props.className || ''} flex justify-center items-center rounded min-w-200px min-h-35px border border-solid 
    font-pt-sans text-base font-bold leading-21px tracking-wider outline-none p-0
  `;

  const { isLoading = false, disabled = false, type = 'primary' } = props;

  const primaryButtonClassName = `
    ${defaultButtonClassName} bg-brown-100 border-brown-100 hover:bg-brown-120`;

  const secondaryButtonClassName = `
    ${defaultButtonClassName} bg-white-true text-brown-100 border-brown-100 hover:bg-brown-20`;

  const className = type === 'primary' ? primaryButtonClassName : secondaryButtonClassName;

  return (
    <button
      className={classNames(className, {
        'cursor-pointer': !disabled && !isLoading,
        'hover:bg-gray-20 bg-gray-20 text-gray-80 border-gray-20': disabled && type === 'primary',
        'text-ivory': !disabled && type === 'primary',
        'text-gray-80': disabled && type === 'primary',
        'hover:bg-brown-20': !disabled && !isLoading && type === 'secondary',
        'border-gray-60': disabled && type === 'secondary',
      })}
      disabled={disabled}
      onClick={props.onClick}
    >
      {isLoading ? (
        <span>
          <i className="text-xl fas fa-circle-notch fa-spin"></i>
        </span>
      ) : (
        <span>{props.text}</span>
      )}
    </button>
  );
};

export default FixedButton;
