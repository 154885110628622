import { useMemo, RefObject } from 'react';

import { useHover } from './useHover';
import { usePressed } from './usePressed';

interface IUseIconColourProps {
  disabled?: boolean;
  bg: string;
  bgHover: string;
  bgDisabled: string;
  bgActive: string;
  border: string;
  borderDisabled: string;
  icon: string;
  iconHover: string;
  iconDisabled: string;
  iconActive: string;
}

export const useIconColours = (ref: RefObject<HTMLElement>, {
  disabled,
  bg,
  bgHover,
  bgDisabled,
  bgActive,
  border,
  borderDisabled,
  icon,
  iconHover,
  iconDisabled,
  iconActive,
}: IUseIconColourProps) => {
  const isHovered = useHover(ref);
  const isPressed = usePressed(ref);

  const backgroundColour = useMemo(() => {
    if (disabled) {
      return bgDisabled;
    }

    if (isPressed) {
      return bgActive;
    }

    return isHovered ? bgHover : bg;
  }, [disabled, isPressed, isHovered, bgHover, bg, bgDisabled, bgActive]);

  const borderColour = useMemo(() => (disabled ? borderDisabled : border), [disabled, border, borderDisabled]);

  const iconColour = useMemo(() => {
    if (disabled) {
      return iconDisabled;
    }

    if (isPressed) {
      return iconActive;
    }

    return isHovered ? iconHover : icon;
  }, [disabled, icon, iconActive, iconDisabled, iconHover, isHovered, isPressed]);

  return { background: backgroundColour, border: borderColour, icon: iconColour };
};
