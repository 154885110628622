import React, { useCallback } from 'react';
import classnames from 'classnames';

export interface IAsyncSearchDropdownOption {
  label?: string;
  value?: string;
  renderItem?: (className?: string) => React.ReactElement;
}

export interface IAsyncSearchDropdownOptionsProps {
  value?: string;
  options: IAsyncSearchDropdownOption[];
  maxVisibleItems?: number;
  onChoose: (value?: string) => void;
}

const AsyncSearchDropdownOptions: React.FC<IAsyncSearchDropdownOptionsProps> = React.memo(
  ({ options, value, maxVisibleItems, onChoose }) => {
    const optionsHeight = maxVisibleItems ? { style: { maxHeight: `${maxVisibleItems * 35}px` } } : {};

    const select = useCallback(
      (optionId: string) => {
        onChoose(optionId);
      },
      [onChoose]
    );

    return (
      <div className="flex flex-col overflow-y-auto select-none options-container" {...optionsHeight}>
        {options.length === 0 ? (
          <span className="no-results flex items-center px-10px min-h-35px">
            <span className="flex font-pt-sans py-2 text-15px leading-19px">No items</span>
          </span>
        ) : (
          options.map(option => (
            <span
              key={option.value}
              className={classnames(`value-${option.value} flex items-center hover:bg-teal-40 px-10px min-h-35px`)}
              onClick={() => select(option.value ?? '')}
            >
              {option.renderItem ? (
                option.renderItem(classnames({ 'font-bold': option.value === value }))
              ) : (
                <span
                  className={classnames('font-pt-sans py-2 text-15px leading-19px', {
                    'font-bold': option.value === value,
                  })}
                >
                  {option.label}
                </span>
              )}
            </span>
          ))
        )}
      </div>
    );
  }
);

export default AsyncSearchDropdownOptions;
