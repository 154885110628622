import React, { Fragment } from 'react';
import { compose } from 'redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import ImageUploading from 'react-images-uploading';

import Label from 'pureUi/Label';
import TextInput from 'pureUi/TextInput';
import { Text, Heading } from 'pureUi/typography';
import { PrimaryButton, SecondaryButton } from 'pureUi/Buttons';
import RadioButton from 'pureUi/RadioButton';
import { withCompanyInfo, IWithCompanyInfoProps } from 'hoc/WithCompanyInfo';
import { CompanyDetailsFormStyles } from './CompanyDetailsFormStyles';

export class CompanyDetailsForm extends React.PureComponent<CompanyDetailsFormProps, {}> {
  componentDidMount() {
    this.props.fetchCompanyLogo(this.props.user.company.uuid);
  }

  render() {
    const {
      user,
      t,
      companyLogoUrl,
      handleCompanyLogoChange,
      canUploadLogo,
      handleRemoveLogo,
      logoPosition,
      handleSetLogoPosition,
    } = this.props;

    const companyData = user.company;
    const companyLogoPosition = logoPosition || companyData.logoPosition;

    return (
      <CompanyDetailsFormStyles>
        <Heading className="heading" level="h1">
          <>{t('labels.companyDetails')}</>
        </Heading>
        <div className="grid">
          <div className="formInputs">
            <Label className="label" text={t('labels.company.name')}>
              <TextInput value={companyData.name} disabled={true} textTransform={'inherit'} />
            </Label>
            <Label className="label" text={t('labels.company.country')}>
              <TextInput value={companyData.countryCode} disabled={true} textTransform={'inherit'} />
            </Label>
            <Label className="label" text={t('labels.company.phone')}>
              <TextInput value={companyData.phoneNumber} disabled={true} textTransform={'inherit'} />
            </Label>
            <Label className="label" text={t('labels.company.billingAddress')}>
              <TextInput value={companyData.address} disabled={true} textTransform={'inherit'} />
            </Label>
            <Label className="label" text={t('labels.company.website')}>
              <TextInput value={companyData.website} disabled={true} textTransform={'inherit'} />
            </Label>
          </div>
          <div className="imageSettings">
            <Fragment>
              <Label className="label" text={t('labels.company.uuid')}>
                <Heading level="h3">{companyData.uuid}</Heading>
              </Label>
              <Label className="label" text={t('labels.company.logo')}>
                <ImageUploading
                  onChange={handleCompanyLogoChange}
                  maxFileSize={10 * 1024 * 1024}
                  acceptType={['jpg', 'gif', 'png']}
                >
                  {({ imageList, onImageUpload, onImageRemoveAll }) => {
                    return (
                      <div>
                        <div className="logoContainer">
                          {companyLogoUrl ? (
                            <img className="preview" src={companyLogoUrl} />
                          ) : (
                            <Text className="noLogo">Click here to choose a file</Text>
                          )}
                        </div>
                        {companyLogoUrl && (
                          <Fragment>
                            <PrimaryButton
                              className="uploadLogoButton"
                              onClick={onImageUpload}
                              disabled={!canUploadLogo}
                            >
                              Change Logo
                            </PrimaryButton>
                            <SecondaryButton onClick={handleRemoveLogo} className="removeLogoButton">
                              Remove Logo
                            </SecondaryButton>
                          </Fragment>
                        )}
                        <Text>Files must be JPG or PNG format. Recommended format: Transparent PNG</Text>
                      </div>
                    );
                  }}
                </ImageUploading>
              </Label>
              {companyLogoUrl && (
                <Label className="label" text={t('labels.company.logoPosition')}>
                  <RadioButton
                    name="left"
                    value="left"
                    data-role="radioTrue"
                    checked={companyLogoPosition === 'left'}
                    onChange={handleSetLogoPosition}
                  />{' '}
                  Left
                  <RadioButton
                    name="right"
                    value="right"
                    data-role="radioTrue"
                    checked={companyLogoPosition === 'right'}
                    onChange={handleSetLogoPosition}
                  />{' '}
                  Right
                </Label>
              )}
            </Fragment>
          </div>
        </div>
      </CompanyDetailsFormStyles>
    );
  }
}

export interface CompanyDetailsFormProps extends WithTranslation, IWithCompanyInfoProps {
  user: any;
  isSr: boolean;
  className: string;
}

export const CompanyDetailsFormConnected = compose(withTranslation(), withCompanyInfo)(CompanyDetailsForm);
