import React from 'react';
import { isAncillaryRatedResult } from 'services/BookingManagerApi/types/AncillaryService';
import { formatPrice } from 'utils';

export interface IAddAncillaryRightHandPanelTotalCostProps {
  currencySymbol: string;
  price: string;
  isOnRequest: boolean;
}

export const AddAncillaryRightHandPanelTotalCost = (props: IAddAncillaryRightHandPanelTotalCostProps) => {
  return (
    <div>
      {/* {props.selectedProduct.userData.rate && isAncillaryRatedResult(props.selectedProduct.userData.rate) && ( */}
      <div className="total-tp-cost-wrapper flex items-center justify-between">
        <span className="text-gray-100 uppercase text-xs leading-16px w-1/2">Total TP Cost</span>
        <span className="bg-teal-20 w-1/2 flex items-center pt-3px px-10px justify-end h-35px text-[19px] leading-[25px] font-bold">
          {!props.isOnRequest && (
            <>
              {props.currencySymbol}
              {formatPrice(props.price)}
            </>
          )}
          {props.isOnRequest && <>{'On Request'}</>}
        </span>
      </div>
      {/* )} */}
    </div>
  );
};
