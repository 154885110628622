import * as Yup from 'yup';

export const GuestInformationFormValidationSchema = Yup.object().shape({
  guests: Yup.array().of(
    Yup.object().shape({
      isLeadGuest: Yup.boolean().required(),
      uuid: Yup.string().nullable(true),
      title: Yup.string(),
      firstName: Yup.string().required('Field is required'),
      lastName: Yup.string().when(['isLeadGuest'], {
        is: (val) => val === true,
        then: (schema: Yup.StringSchema) => schema.required('Field is required'),
        otherwise: (schema: Yup.StringSchema) => schema.nullable(true),
      }),
      dateOfBirth: Yup.date().nullable(true),
      passportNo: Yup.string().nullable(true),
      arrivalDate: Yup.date().nullable(true),
      arrivalFlightNo: Yup.string().nullable(true),
      departureDate: Yup.date().nullable(true),
      departureFlightNo: Yup.string().nullable(true),
    })
  ),
});
