import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import * as Yup from 'yup';

import { TextInput } from 'ui/TextInput';
import { GeneralModal } from 'ui/GeneralModal';
import { Warning } from 'ui/Warning';
import { getAuthStatus, resetPassword } from 'store/modules/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentWidth } from 'effects';
import { IForgotPasswordFormValues } from './types';
import { Field, Formik, FormikProps } from 'formik';
import { Status } from 'store/common';

export interface iForgotPasswordModalProps {
  isOpen: boolean;
  onClose?: () => void;
}

const forgotPasswordFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email address is invalid')
    .required('This field is required'),
});

export const ForgotPasswordModal = (props: iForgotPasswordModalProps) => {
  const { isOpen, onClose } = props;

  const dispatch = useDispatch();
  const authStatus = useSelector(getAuthStatus);
  const loading = authStatus === Status.SENDING;
  const { isMobile } = useCurrentWidth();
  const modalWidthClassname = classnames('flex justify-center', {
    'w-330px': isMobile,
    'w-442px': !isMobile,
  });

  const [success, setSuccess] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const initialValues: IForgotPasswordFormValues = {
    email: '',
  };

  const handleFormSubmit = useCallback(
    (forgotPasswordFormValues: IForgotPasswordFormValues) => {
      const { email } = forgotPasswordFormValues;
      setErrorMessage('');
      setSuccess(false);
      dispatch(resetPassword({ email }));
      setFormSubmitted(true);
    },
    [dispatch]
  );

  const handleClose = useCallback(() => {
    setErrorMessage('');
    onClose && onClose();
  }, [onClose]);

  useEffect(() => {
    if (authStatus === Status.SENDING) {
      setSuccess(false);
    }
    if (authStatus === Status.ERROR && formSubmitted) {
      setErrorMessage('Could not request password reset');
    }
    if (authStatus === Status.SUCCESS && formSubmitted) {
      setSuccess(true);
    }
  }, [authStatus, formSubmitted]);

  const renderTitle = (isSuccess: boolean) => {
    return isSuccess ? (
      <p className="modal-title text-black text-center text-4xl leading-54px font-noe-display mt-5px my-0">
        Reset Done
      </p>
    ) : (
      <p className="modal-title text-black text-center text-4xl leading-54px font-noe-display mt-5px my-0">
        Reset Your Password
      </p>
    );
  };

  const renderDescription = (isSuccess: boolean) => {
    return isSuccess ? (
      <React.Fragment>
        <p className="modal-description mt-25px text-center text-black text-base mb-0 leading-xl font-pt-sans">
          Your password has been reset. You will receive an email to verify your details.
        </p>
        <p className="modal-signature-text mt-25px text-brown-prime text-lg leading-23px text-center font-pt-sans">
          The Pure Escapes Team
        </p>
      </React.Fragment>
    ) : (
      <p className="modal-description mt-25px mb-0 text-center text-black text-base leading-xl font-pt-sans">
        Enter your email address and we will send you a link to reset your password:
      </p>
    );
  };

  const renderContent = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={forgotPasswordFormValidationSchema}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {(form: FormikProps<IForgotPasswordFormValues>) => {
          return (
            <form onSubmit={form.handleSubmit} className="flex justify-center">
              <div className="min-w-250px max-w-250px ml-auto mr-auto">
                <Field
                  as={TextInput}
                  name="email"
                  type="text"
                  id="forgot-password-modal-email"
                  className="forgot-password-modal-email mt-25px"
                  label="Email"
                  errorMessage={form.touched.email && form.errors.email ? form.errors.email : null}
                  errorClassName="forgot-password-modal-email-error"
                />

                <button
                  id="forgotPasswordSubmitCta"
                  className="font-pt-sans bg-brown-prime w-full h-10 border-none mt-25px px-33px text-white-true tracking-wider hover:bg-brown-hard active:bg-brown-hard cursor-pointer"
                  disabled={loading}
                >
                  Reset Password
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  };

  if (!isOpen) {
    return null;
  }

  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={handleClose}
      modalWindowClassName={modalWidthClassname}
      shouldCloseByClickingOutside
    >
      <div
        className={classnames(
          'forgot-password-modal flex justify-center pt-55px pb-65px w-screen max-w-330px',
          modalWidthClassname
        )}
      >
        <div
          className={classnames('forgot-password-modal-content', {
            'max-w-300px': isMobile,
            'max-w-312px': !isMobile,
          })}
        >
          <div className="forgot-password-modal-title flex flex-col items-center">
            {!loading && renderTitle(success)}
          </div>

          {errorMessage && <Warning message={errorMessage} />}

          {!loading && renderDescription(success)}

          {loading && <p className="mt-5 text-center text-black text-base leading-xl">Requesting Password Reset ...</p>}

          {!loading && !success && renderContent()}
        </div>
      </div>
    </GeneralModal>
  );
};
