import * as React from 'react';
import { useClickOutside } from 'react-click-outside-hook';

interface IDatePickerWrapperProps {
  className?: string;
  renderDateSelectionInput: () => React.ReactElement;
  renderDatePicker: () => React.ReactElement;
  isDatePickerVisible: boolean;
  onSetDatePickerVisible: (isDatePickerVisible: boolean) => void;
  shouldKeepVisibleIfClickOutside?: boolean;
}

export const DatePickerWrapper: React.FC<IDatePickerWrapperProps> = props => {
  const { shouldKeepVisibleIfClickOutside = false } = props;
  const [ref, hasClickedOutside] = useClickOutside();

  if (hasClickedOutside === true && !shouldKeepVisibleIfClickOutside) {
    props.onSetDatePickerVisible(false);
  }

  return (
    <div ref={ref}>
      {props.renderDateSelectionInput()}
      {props.isDatePickerVisible && <div className="calendars absolute">{props.renderDatePicker()}</div>}
    </div>
  );
};

export default DatePickerWrapper;
