import React from 'react';

export interface IModalHeadingProps {
  children: React.ReactNode;
}

export const ModalHeading = ({ children }) => {
  return (
    <h3 className="m-0 font-noe-display font-normal text-black text-[21px] leading-[27px]">{children}</h3>
  );
};
