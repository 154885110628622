/**
 * For any changes to this file, please also update
 * https://github.com/pure-escapes/booking-managaer-service -> common-lib/cancellation-policy-composer.ts
 */

import { uniqBy } from "ramda";

export interface ExpenseInfoForRoom {
  isRefundable: boolean | null;
  isLive: boolean | null;
  manualCancellationPolicy?: string[];
  expenseDeadlines: ExpenseDeadline[];
}

export interface ExpenseDeadline {
  deadline?: string;
  amount?: number | null;
}

export interface CancellationPolicyComposerOptions {
  prependLines?: string[];
  appendLines?: string[];
  currencySymbol: string;
  placeholderForFree?: string;
}

/**
 * convert a string or number representation of a number to a string with commas every 3 digits
 * written pure here so it can be shared without dependencies
 * @param num number or string
 * @returns
 */
const formatAmountToInteger = (num: number | string): string => {
  // Convert num to a string if it's not already
  const numStr: string = typeof num === 'number' ? num.toString() : num;

  // Split the string into integer and decimal parts
  const [integerPart] = numStr.split('.');

  // Add commas every three digits from the right
  const formattedIntegerPart: string = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return formattedIntegerPart;
};

/**
 * format an iso date to a string in the format 'dd/mm/yyyy'
 * written pure here so it can be shared without dependencies
 * @param inputDate iso date
 * @param daysToSubtract number of days to subtract (optional)
 * @returns
 */
const format = (inputDate, daysToSubtract = 0): string => {
  const dateParts = inputDate.split('-'); // Split the input date by '-'
  const year = Number.parseInt(dateParts[0]);
  const month = Number.parseInt(dateParts[1]) - 1; // Month is zero-based in Date object
  const day = Number.parseInt(dateParts[2]);

  const dateObject = new Date(year, month, day);

  // Subtract days
  dateObject.setDate(dateObject.getDate() - daysToSubtract);

  // Get the updated day, month, and year
  const updatedDay = dateObject.getDate();
  const updatedMonth = dateObject.getMonth() + 1; // Month is zero-based, so we add 1
  const updatedYear = dateObject.getFullYear();

  // Pad single digit month and day with leading zero
  const formattedMonth = String(updatedMonth).padStart(2, '0');
  const formattedDay = String(updatedDay).padStart(2, '0');

  // Return formatted date string
  return `${formattedDay}/${formattedMonth}/${updatedYear}`;
};

export const composeCancellationPolicyFromRoomExpenseInfo = (
  expenseInfoForRoom: ExpenseInfoForRoom,
  options: CancellationPolicyComposerOptions
): string[] => {
  const { expenseDeadlines: deadlines, manualCancellationPolicy = [] } = expenseInfoForRoom;
  const { currencySymbol, placeholderForFree = '0 (FREE)' } = options;
  const policyLines: string[] = [];

  if (options.prependLines && (deadlines.length > 0 || manualCancellationPolicy?.length > 0)) {
    options.prependLines.forEach(line => {
      policyLines.push(line);
    });
  }

  if (deadlines.length > 0) {
    policyLines.push(
      `Cancel before ${format(deadlines[0].deadline, expenseInfoForRoom.isLive ? 2 : 0)} cost is ${placeholderForFree}*`
    );
  }

  // then loop over the others, if we have enough to be worth looping over
  if (deadlines.length >= 2) {
    deadlines.forEach((d, dIdx) => {
      if (deadlines[dIdx + 1] != null && (d.amount || 0) > 0) {
        if (d.amount === 0 || d.amount === null || d.amount === undefined) {
          policyLines.push(
            `Cancel from ${format(d.deadline, expenseInfoForRoom.isLive ? 2 : 0)} to ${format(
              deadlines[dIdx + 1].deadline,
              expenseInfoForRoom.isLive ? 2 : 0
            )} cost needs to be requested*`
          );
        } else {
          policyLines.push(
            `Cancel from ${format(d.deadline, expenseInfoForRoom.isLive ? 2 : 0)} to ${format(
              deadlines[dIdx + 1].deadline,
              expenseInfoForRoom.isLive ? 2 : 0
            )} cost is ${currencySymbol}${formatAmountToInteger(d.amount!)}*`
          );
        }
      }
    });
  }

  // then add 1 final one for the last index item
  if (deadlines.length > 0) {
    const lastIndex = deadlines.length - 1;

    if (
      deadlines[lastIndex].amount === 0 ||
      deadlines[lastIndex].amount === null ||
      deadlines[lastIndex].amount === undefined
    ) {
      policyLines.push(
        `Cancel after ${format(
          deadlines[lastIndex].deadline,
          expenseInfoForRoom.isLive ? 2 : 0
        )} cost needs to be requested*`
      );
    } else {
      policyLines.push(
        `Cancel after ${format(
          deadlines[lastIndex].deadline,
          expenseInfoForRoom.isLive ? 2 : 0
        )} cost is ${currencySymbol}${formatAmountToInteger(deadlines[lastIndex].amount!)}*`
      );
    }
  }

  if (manualCancellationPolicy.length > 0) {
    policyLines.push(...manualCancellationPolicy);
  }

  if (policyLines.length === 0 && !expenseInfoForRoom.isRefundable) {
    policyLines.push('Cancellation Policy On Request');
  }

  // we append extra lines only if theyve sent some original deadlines anyway
  if (options.appendLines && (deadlines.length > 0 || manualCancellationPolicy?.length > 0)) {
    options.appendLines.forEach(line => {
      policyLines.push(line);
    });
  }

  return policyLines;
};

export const extractCancellationPolicies = (products: any[]) => {
  return uniqBy(a => a, products.map(product => product.cancellationPolicy).filter(Boolean))
}

export const isRefundable  = (info: ExpenseInfoForRoom): boolean | null => {
  if(info.isRefundable !== null && info.isRefundable !== undefined) {
    return info.isRefundable;
  }
  return info?.expenseDeadlines?.every(x => {
    if(!x.deadline || !x.amount) {
      return true
    }
    const deadline = new Date(x.deadline.split(' ')[0]);
    const safeDeadline = deadline.getTime() - 2 * 24 * 3600 * 1000;
    return Date.now() < safeDeadline;
  });
};
