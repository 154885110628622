import { EUserType } from './AuthResponse';
import { ISalesRepresentative } from './SalesRepresentativesResponse';
import { INotification } from './Notification';
import { ICompany } from './CompaniesResponse';

export enum ETravelAgentStatus {
  PENDING = 'pending',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  VERIFIED = 'verified',
}

export enum ETravelAgentCreatedBy {
  SR = 'sr',
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export interface ITravelAgent {
  uuid: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  title: string;
  phoneNumber: string | null;
  mobileNumber: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  postalCode: string | null;
  countryCode: string | null;
  status: ETravelAgentStatus;
  isExistingPartner: boolean;
  agreeToTerms: boolean;
  type: EUserType;
  receiveEmailAlerts: boolean;
  companyUuid: string | null;
  companySignupInfo: {
    name: string;
    countryCode: string;
  } | null;
  company?: ICompany;
  companyBookingManager: boolean;
  companyTaManager: boolean;
  companyRole: string | null;
  comment: string | null;
  credentialsSent: string | null;
  createdAt: string;
  updatedAt: string;
  createdBy?: ISalesRepresentative;
  assignedSalesRepresentatives: ISalesRepresentative[];
  assignedTravelAgents: {
    uuid: string;
    email: string;
    title: string;
    firstName: string;
    lastName: string;
  }[];
  notifications?: INotification[];
  canUseApp?: boolean;
  taCanReadFinance?: boolean;
  newlyCreatedTemporaryPassword?: string;
}

export interface ITravelAgentFilter {
  salesRepUuid: string | null;
  search: string | null;
  email: string | null;
  countryCode: string | null;
  status: ETravelAgentStatus | null;
  companyUuid: string | null;
  createdBy: ETravelAgentCreatedBy | null;
}

export interface IUserFilter {
  type?: string;
  countryCode?: string;
  status?: ETravelAgentStatus;
  'firstName,lastName,email:ilike'?: string;
}

export interface ITravelAgentResponseMeta {
  type: string;
  total: number;
  associations: string[];
}

export interface ITravelAgentResponse {
  data: ITravelAgent[];
  meta: ITravelAgentResponseMeta;
}

export interface ITravelAgentViaUuidResponse {
  data: ITravelAgent;
  meta: ITravelAgentResponseMeta;
}

export interface IOneTravelAgentResponseMeta {
  type: string;
  id: string;
  associations: string[];
}

export interface IOneTravelAgentResponse {
  data: ITravelAgent;
  meta: IOneTravelAgentResponseMeta;
}

export interface IEmailExistsResponse {
  valid: boolean;
}

export enum ERequesterType {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
  SR = 'sr',
}

export interface IEmailExistsResponse {
  valid: boolean;
}
