import { AppliedMealPlan } from "services/BackendApi";
import { ITopNavigationData } from "services/BookingManagerApi";
import { rateStringToCents } from "utils";
import { IStaticRate } from "./MealPlanDropdown/types";

export const calculateGuestAgesFromTopNavigationData = (topNavigationData: ITopNavigationData | null) => {
  if (topNavigationData === null) {
    return {
      numberOfAdults: 0,
      agesOfAllChildren: [],
    }
  }

  const guestCount = topNavigationData?.guestCount ?? 0;
  const childrenCount = topNavigationData?.childrenAges?.length ?? 0;
  const numberOfAdults = Math.abs(guestCount - childrenCount);
  const agesOfAllChildren = topNavigationData?.childrenAges ?? [];
  
  return {
    numberOfAdults,
    agesOfAllChildren,
  };
}

export const convertMealPlanToStaticRate = (item: AppliedMealPlan | undefined): IStaticRate => {
  if (!item) {
    return {} as IStaticRate;
  }
  return {
    mealPlan: {
      uuids: item.products.map(mealPlan => mealPlan.uuid),
      names: item.products.map(mealPlan => mealPlan.name),
      isOnRequest: item.isOnRequestOrPartiallyOnRequest,
      totalBeforeDiscountCents: rateStringToCents(item.totalBeforeDiscount),
      totalCents: rateStringToCents(item.total),
    }
  } as IStaticRate;
};
