import { call, takeLatest, debounce, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { omit, sortBy } from 'lodash-es';

import { EInventoryStayListingTagType, IInventoryStaysFilter, makeInventoryApi } from 'services/InventoryAPI';

import * as Actions from './actions';
import { GetRatingsRequestAction } from './actions';

import * as Selectors from './selectors';

import { enqueueNotification } from '../ui';
import { ENetworkRequestStatus } from 'services/BackendApi';

export function* getRequestSaga() {
  const inventoryApi = makeInventoryApi();

  const filter: IInventoryStaysFilter = yield select(Selectors.filterSelector);

  yield put(Actions.listActions.setListingPageGet(ENetworkRequestStatus.PENDING));

  try {
    const res: AxiosResponse = yield call(inventoryApi.getInventoryStays, omit(filter, ['page_total']));
    yield put(Actions.listActions.getSuccessAction(res.data.results, res.data.total_items));
    yield put(Actions.listActions.setListingPageGet(ENetworkRequestStatus.SUCCESS));
  } catch (e) {
    yield put(Actions.listActions.getFailureAction(e.message));
    yield put(Actions.listActions.setListingPageGet(ENetworkRequestStatus.ERROR));
    yield put(enqueueNotification({
      message: 'There was a problem getting inventory stays.',
      options: { variant: 'error' },
    }));
  }
}

export function* getStayRequestSaga(action: Actions.GetStayRequestAction) {
  const inventoryApi = makeInventoryApi();
  
  try {
    const res: AxiosResponse = yield call(inventoryApi.getInventoryStay, action.stayUuid);
    const regionTag = res.data.tags
      .find(tag => tag.type === EInventoryStayListingTagType.REGION);
    const stay = {
      ...res.data,
      region: regionTag?.values?.length > 0 ? regionTag?.values[0]?.uuid : undefined,
    };
    yield put(Actions.createEditActions.getSuccessAction(stay));
  } catch (e) {
    yield put(Actions.createEditActions.getFailureAction(e.message));
    yield put(enqueueNotification({
      message: 'There was a problem loading inventory stay.',
      options: { variant: 'error' },
    }));
  }
}

export function* getRatingsRequestSaga(action: GetRatingsRequestAction) {
  const inventoryApi = makeInventoryApi();

  try {
    const res: AxiosResponse = yield call(inventoryApi.getInventoryStayRatings, action.stayType);
    const ratings = res.data.results
      .map(rating => rating.name);
    const sortedRatings = sortBy(ratings, 'name');

    yield put(Actions.createEditActions.getRatingsSuccessAction(sortedRatings));
    if (ratings?.length === 0) {
      yield put(enqueueNotification({
        message: 'The list of ratings is empty for this type of stay.',
        options: { variant: 'error' },
      }));
    }
  } catch (e) {
    yield put(Actions.createEditActions.getRatingsFailureAction(e.message));
    yield put(enqueueNotification({
      message: 'There was a problem getting ratings.',
      options: { variant: 'error' },
    }));
  }
}

export function* getRegionsRequestSaga() {
  const inventoryApi = makeInventoryApi();

  try {
    const res: AxiosResponse = yield call(inventoryApi.getInventoryStayRegions);
    const regions = sortBy(res.data.results, 'name');

    yield put(Actions.createEditActions.getRegionsSuccessAction(regions));
    if (regions?.length === 0) {
      yield put(enqueueNotification({
        message: 'There are no regions available.',
        options: { variant: 'error' },
      }));
    }
  } catch (e) {
    yield put(Actions.createEditActions.getRegionsFailureAction(e.message));
    yield put(enqueueNotification({
      message: 'There was a problem getting regions.',
      options: { variant: 'error' },
    }));
  }
}

export function* getFiltersRequestSaga(type: EInventoryStayListingTagType) {
  const inventoryApi = makeInventoryApi();

  const urlMapping = {
    [EInventoryStayListingTagType.BEST_FOR]: inventoryApi.getStayFiltersBestfor,
    [EInventoryStayListingTagType.AMENITY]: inventoryApi.getStayFiltersAmenity,
    [EInventoryStayListingTagType.OTHER]: inventoryApi.getStayFiltersOther,
  };
  const url = urlMapping[type];
  const successActionMapping = {
    [EInventoryStayListingTagType.BEST_FOR]: Actions.createEditActions.getBestforFiltersSuccessAction,
    [EInventoryStayListingTagType.AMENITY]: Actions.createEditActions.getAmenityFiltersSuccessAction,
    [EInventoryStayListingTagType.OTHER]: Actions.createEditActions.getOtherFiltersSuccessAction,
  };
  const errorActionMapping = {
    [EInventoryStayListingTagType.BEST_FOR]: Actions.createEditActions.getBestforFiltersFailureAction,
    [EInventoryStayListingTagType.AMENITY]: Actions.createEditActions.getAmenityFiltersFailureAction,
    [EInventoryStayListingTagType.OTHER]: Actions.createEditActions.getOtherFiltersFailureAction,
  };
  
  try {
    const res: AxiosResponse = yield call(url);
    const tags = sortBy(res.data.results, 'name');

    yield put(successActionMapping[type](tags));
    if (tags?.length === 0) {
      yield put(enqueueNotification({
        message: 'There are no filters available.',
        options: { variant: 'error' },
      }));
    }
  } catch (e) {
    yield put(errorActionMapping[type](e.message));
    yield put(enqueueNotification({
      message: 'There was a problem getting filters.',
      options: { variant: 'error' },
    }));
  }
}

export function* getBestforFiltersRequestSaga() {
  yield call(getFiltersRequestSaga, EInventoryStayListingTagType.BEST_FOR);
}

export function* getAmenityFiltersRequestSaga() {
  yield call(getFiltersRequestSaga, EInventoryStayListingTagType.AMENITY);
}

export function* getOtherFiltersRequestSaga() {
  yield call(getFiltersRequestSaga, EInventoryStayListingTagType.OTHER);
}

export function* postInventoryStayRequestSaga(action: Actions.PostStayRequestAction) {
  const inventoryApi = makeInventoryApi();

  try {
    const stayForSaving = omit(action.stay, ['region']);
    // if you don't send region equals to Worldwide, inventory returns an error
    const response = yield call(inventoryApi.postInventoryStay, { ...stayForSaving, region: 'Worldwide' });
    const stayUuid = response.data?.uuid;
    yield call(inventoryApi.linkTagsToStay, stayUuid, action.stay.region ? [action.stay.region] : []);

    yield put(Actions.createEditActions.postStaySuccessAction());
    yield put(enqueueNotification({
      message: 'Stay created successfully.',
      options: { variant: 'success' },
    }));
    yield call(action.history.push, '/inventory/stays');
  } catch (e) {
    yield put(Actions.createEditActions.postStayFailureAction(e.message));
    if (Number(e?.response?.status) === 422) {
      yield put(enqueueNotification({
        message: 'This name has already been registered.',
        options: { variant: 'error' },
      }));
    } else {
      yield put(enqueueNotification({
        message: 'There was a problem creating a new stay.',
        options: { variant: 'error' },
      }));
    }
  }
}

export function* putInventoryStayRequestSaga(action: Actions.PutStayRequestAction) {
  const inventoryApi = makeInventoryApi();

  try {
    const stayUuid = action.stay.uuid;
    // if you don't send region equals to Worldwide, inventory returns an error
    const stayForSaving = { ...omit(action.stay, ['region']), region: 'Worldwide' };
    yield call(inventoryApi.putInventoryStay, stayForSaving);
    
    const regionTag = action.stay.region ? [action.stay.region] : [];
    const tags = [
      ...stayForSaving.bestForFilters,
      ...stayForSaving.amenityFilters,
      ...stayForSaving.otherFilters,
      ...regionTag,
    ].filter(x => !!x);
    yield call(inventoryApi.deleteTagsFromStay, stayUuid);
    yield call(inventoryApi.linkTagsToStay, stayUuid, tags);

    yield put(Actions.createEditActions.putStaySuccessAction());
    yield put(enqueueNotification({
      message: 'Stay updated successfully.',
      options: { variant: 'success' },
    }));
    yield put(Actions.createEditActions.getRequestAction(stayUuid));
  } catch (e) {
    yield put(Actions.createEditActions.putStayFailureAction(e.message));
    if (Number(e?.response?.status) === 422) {
      yield put(enqueueNotification({
        message: 'This name has already been registered.',
        options: { variant: 'error' },
      }));
    } else {
      yield put(enqueueNotification({
        message: 'There was a problem updating a new stay.',
        options: { variant: 'error' },
      }));
    }
  }
}

export function* searchInventorySuppliersRequestSaga() {
  const inventoryApi = makeInventoryApi();

  try {
    const res: AxiosResponse = yield call(inventoryApi.searchInventorySuppliersSimple);
    const sortedSuppliers = sortBy(res.data.results, 'name')

    yield put(Actions.listActions.searchSuppliersSuccessAction(sortedSuppliers));
  } catch (e) {
    yield put(Actions.listActions.searchSuppliersFailureAction(e.message));
    yield put(enqueueNotification({
      message: 'There was a problem getting suppliers.',
      options: { variant: 'error' },
    }));
  }
}

export function* watchInventoryStaysSaga() {
  yield takeLatest(
    [
      Actions.LIST_ACTIONS.GET_REQUEST,
      Actions.LIST_ACTIONS.SET_FILTER_COUNTRY_CODE,
      Actions.LIST_ACTIONS.SET_FILTER_IS_ENABLED,
      Actions.LIST_ACTIONS.SET_FILTER_PAYMENT,
      Actions.LIST_ACTIONS.SET_FILTER_RATES,
      Actions.LIST_ACTIONS.SET_SORT_DATA,
    ],
    getRequestSaga
  );

  yield debounce(
    200,
    [Actions.LIST_ACTIONS.SET_FILTER_NAME, Actions.LIST_ACTIONS.SET_PAGE, Actions.LIST_ACTIONS.SET_PER_PAGE],
    getRequestSaga
  );

  yield takeLatest([ Actions.CREATE_EDIT_ACTIONS.GET_RATINGS_REQUEST ], getRatingsRequestSaga);
  yield takeLatest([ Actions.CREATE_EDIT_ACTIONS.GET_REGIONS_REQUEST ], getRegionsRequestSaga);
  yield takeLatest([ Actions.CREATE_EDIT_ACTIONS.GET_STAY_REQUEST ], getStayRequestSaga);
  yield takeLatest([ Actions.CREATE_EDIT_ACTIONS.POST_STAY_REQUEST ], postInventoryStayRequestSaga);
  yield takeLatest([ Actions.CREATE_EDIT_ACTIONS.PUT_STAY_REQUEST ], putInventoryStayRequestSaga);
  yield takeLatest([ Actions.LIST_ACTIONS.SEARCH_SUPPLIERS_REQUEST ], searchInventorySuppliersRequestSaga);
  yield takeLatest([ Actions.CREATE_EDIT_ACTIONS.GET_BEST_FOR_FILTERS_REQUEST ], getBestforFiltersRequestSaga);
  yield takeLatest([ Actions.CREATE_EDIT_ACTIONS.GET_AMENITY_FILTERS_REQUEST ], getAmenityFiltersRequestSaga);
  yield takeLatest([ Actions.CREATE_EDIT_ACTIONS.GET_OTHER_FILTERS_REQUEST ], getOtherFiltersRequestSaga);
}
