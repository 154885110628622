import React from 'react';
import { IBookingComment } from 'services/BookingManagerApi';
import styled from 'styled-components';
import { formatDateDisplay } from 'utils';
import classNames from 'classnames';
import { CircleIconButton } from 'ui/CircleIconButton';
import { UITextArea } from 'ui/UITextArea';

interface IBookingCommentProps {
  key: number | string;
  comment: IBookingComment;
  className?: string; // from styled
  canEdit?: boolean; // shows the edit and delete buttons
  isLoading?: boolean; // makes it greyed out and disables the buttons
  editingDraft?: string; // the current draft of this comment, if its being edited
  isEditing?: boolean; // is currently being edited (swaps out text with textarea, etc.)

  onStartEdit: (comment: IBookingComment) => void;
  onCancelEdit: (comment: IBookingComment) => void;
  onCompleteEdit: (comment: IBookingComment, newText: string) => void;
  onEditDraftChange: (comment: IBookingComment, draft: string) => void;
  onDelete: (comment: IBookingComment) => void;
}

export const BookingCommentComponent = (props: IBookingCommentProps) => {
  const shouldShowEdited: boolean = !!(props.comment.updatedAt && props.comment.updatedAt !== props.comment.createdAt);

  return (
    <div
      key={props.comment.uuid || props.key}
      className={classNames('w-full p-4 mb-2 bg-brown-10', {
        'opacity-75': props.isLoading,
      })}
    >
      <div className="flex text-xs text-gray-100 mb-4">
        <span>
          {formatDateDisplay(props.comment.createdAt)}
          {shouldShowEdited && (
            <React.Fragment> (Edited on {formatDateDisplay(props.comment.updatedAt)})</React.Fragment>
          )}
        </span>
        <span className="ml-2 mr-2">|</span>
        <span>
          {props.comment.authorName} (<a href={`mailto:${props.comment.authorEmail}`}>{props.comment.authorEmail}</a>)
        </span>
      </div>
      <div>
        {(props.isEditing && (
          <UITextArea
            rows={4} 
            value={props.editingDraft ?? ''} 
            onChange={value => {
              props.onEditDraftChange(props.comment, value);
            }}
          />
        )) || <span className="content whitespace-pre-wrap text-base break-words">{props.comment.text}</span>}
      </div>

      {props.canEdit && (
        <div className="mt-4">
          {(props.isEditing && (
            <React.Fragment>
              <CircleIconButton
                type="secondary"
                className="mr-2"
                onClick={() => {
                  if (props.editingDraft && props.editingDraft !== '') {
                    props.onCompleteEdit(props.comment, props.editingDraft);
                  }
                }}
                iconClass="fas fa-check"
              />

              <CircleIconButton
                type="secondary"
                className="mr-2"
                onClick={() => {
                  props.onCancelEdit(props.comment);
                }}
                iconClass="fas fa-times"
              />
            </React.Fragment>
          )) || (
            <CircleIconButton
              type="secondary"
              className="mr-2"
              onClick={() => props.onStartEdit(props.comment)}
              iconClass="fas fa-pen"
            />
          )}

          <CircleIconButton type="secondary" onClick={() => props.onDelete(props.comment)} iconClass="fas fa-trash" />
        </div>
      )}
    </div>
  );
};

export const BookingComment = styled(BookingCommentComponent)`
  &:last-of-type {
    margin-bottom: 0px;
  }
`;
