import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const AvailabilityCalendarIcon: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors['brown-100'], stroke = theme.colors['teal-100'] }) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0833 11.9165H5.5V15.5832H10.0833V11.9165Z" fill={stroke} />
      <path
        d="M15.5834 2.74984H19.25C19.4932 2.74984 19.7263 2.84641 19.8982 3.01832C20.0701 3.19023 20.1667 3.42339 20.1667 3.6665V18.3332C20.1667 18.5763 20.0701 18.8094 19.8982 18.9814C19.7263 19.1533 19.4932 19.2498 19.25 19.2498H2.75004C2.50693 19.2498 2.27377 19.1533 2.10186 18.9814C1.92995 18.8094 1.83337 18.5763 1.83337 18.3332V3.6665C1.83337 3.42339 1.92995 3.19023 2.10186 3.01832C2.27377 2.84641 2.50693 2.74984 2.75004 2.74984H6.41671V0.916504H8.25004V2.74984H13.75V0.916504H15.5834V2.74984ZM18.3334 8.24984V4.58317H15.5834V6.4165H13.75V4.58317H8.25004V6.4165H6.41671V4.58317H3.66671V8.24984H18.3334ZM18.3334 10.0832H3.66671V17.4165H18.3334V10.0832Z"
        fill={fill}
      />
    </svg>
  )
);

export default AvailabilityCalendarIcon;
