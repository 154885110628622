import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ENetworkRequestStatus, ISalesRepresentative } from 'services/BackendApi';
import { allSRsSelector, getAllSRsRequestSelector } from 'store/modules/sr/selectors';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import SingleSelect from 'ui/SingleSelect';
import { renderFullName } from 'utils/guestInformation';
import { CheckboxList, ICheckboxOption } from './CheckboxList';
import { isAdmin as isAdminSelector } from 'store/modules/auth';

export enum SR_ASSIGNMENT_TYPE {
  ASSIGN_ALL = 'Assign all',
  ASSIGN_INDIVIDUALLY = 'Individually assign',
};

export const dropdownOptions = [
  {
    label: SR_ASSIGNMENT_TYPE.ASSIGN_INDIVIDUALLY,
    value: SR_ASSIGNMENT_TYPE.ASSIGN_INDIVIDUALLY,
  },
  {
    label: SR_ASSIGNMENT_TYPE.ASSIGN_ALL,
    value: SR_ASSIGNMENT_TYPE.ASSIGN_ALL,
  },
];

interface ITravelAgentSRAssignmentProps {
  assignedSrs: ISalesRepresentative[];
  onChange: (srUuids: ISalesRepresentative[]) => void;
}

export const TravelAgentSRAssignment: React.FC<ITravelAgentSRAssignmentProps> = React.memo(({
  assignedSrs,
  onChange,
}) => {
  const assignedSrUuids = assignedSrs.map(item => item.uuid);
  const [typeOfAssignment, setTypeOfAssignment] = useState(SR_ASSIGNMENT_TYPE.ASSIGN_INDIVIDUALLY);
  const isAdmin = useSelector(isAdminSelector);

  const allSRsLoadStatus = useSelector(getAllSRsRequestSelector);
  let allSRs = useSelector(allSRsSelector) ?? [];
  if (allSRs.length < assignedSrs.length) {
    allSRs = assignedSrs;
  }

  useEffect(() => {
    const assignmentType = allSRs.length === assignedSrUuids.length && isAdmin
      ? SR_ASSIGNMENT_TYPE.ASSIGN_ALL
      : SR_ASSIGNMENT_TYPE.ASSIGN_INDIVIDUALLY;

    setTypeOfAssignment(assignmentType);
  }, [allSRs.length])

  const srAssignmentOptions = allSRs.map(sr => {
    const checkboxOption: ICheckboxOption = {
      label: renderFullName(sr.title, sr.firstName, sr.lastName),
      name: sr.uuid,
      value: assignedSrUuids.includes(sr.uuid),
    };
    return checkboxOption;
  });

  const handleClick = useCallback((optionUuid: string, optionChecked: boolean) => {
    const newAssignedSrUuids = optionChecked
      ? [ ...assignedSrUuids, optionUuid ]
      : assignedSrUuids.filter(uuid => uuid !== optionUuid);
    const newAssignedSrs = allSRs.filter(
      item => newAssignedSrUuids.includes(item.uuid)
    );
    onChange(newAssignedSrs);
  }, [assignedSrUuids, onChange]);
  
  const handleDropdownChange = useCallback((value: SR_ASSIGNMENT_TYPE) => {
    setTypeOfAssignment(value);
    if (value === SR_ASSIGNMENT_TYPE.ASSIGN_ALL) {
      onChange(allSRs);
    }
  }, [allSRs, onChange]);

  if (allSRsLoadStatus === ENetworkRequestStatus.PENDING) {
    return (
      <div className="sr-assignment-container flex justify-center items-center min-h-270px w-full">
        <LoadingBar />
      </div>
    );
  }

  if (allSRsLoadStatus === ENetworkRequestStatus.ERROR) {
    return (
      <div className="sr-assignment-container flex justify-center items-center min-h-270px w-full">
        <ErrorBar />
      </div>
    )
  }
  
  return (
    <div className="sr-assignment-container pt-10 px-5 pb-30px font-pt-sans">
      <p className="m-0 font-bold text-black-true text-17px leading-22px">SR Assignment</p>

      <SingleSelect
        fieldId="sr-assignment"
        className="sr-assignment mt-5 w-200px"
        value={typeOfAssignment}
        showSelectedOption
        options={dropdownOptions}
        onChange={handleDropdownChange}
        disabled={!isAdmin}
      />

      {typeOfAssignment === SR_ASSIGNMENT_TYPE.ASSIGN_INDIVIDUALLY && (
        <div className="checkbox-container mt-5">
          <CheckboxList options={srAssignmentOptions} onClick={handleClick} disabled={!isAdmin} />
        </div>
      )}
    </div>
  );
});
