import React, { useEffect } from 'react';
import classNames from 'classnames';
import { IMultiselectProps, Multiselect } from 'ui/Multiselect';
import UiCheckbox from 'ui/Checkbox';
import { Radio } from 'ui/Radio';
import { useDebounce } from 'hooks/useDebounce';
import FluidButton from 'ui/FluidButton';
import { IAgeName, IOccupancyLimit } from 'services/BackendApi';
import { produce } from 'immer';
import { TCountryCode } from 'interfaces';
import { IBootstrapCountry } from 'store/modules/bootstrap/model';
import { isBlank } from 'utils';
import { usePrevious } from 'hooks/usePrevious';
import Editor from 'pureUi/Editor';

export const markupTypes = [
  { value: 'flat', label: 'Flat' },
  { value: 'percentage', label: 'Percentage' },
];

export const categoryTypes = [
  { value: 'perAccommodationProduct', label: 'Per accomodation' },
  { value: 'perBooking', label: 'Per booking' },
  { value: 'perNight', label: 'Per night' },
  { value: 'perPerson', label: 'Per person' },
  { value: 'perPersonPerNight', label: 'Per person per night' },
];

export const transferCategoryTypes = [
  { value: 'perBooking', label: 'Per booking' },
  { value: 'perPerson', label: 'Per person' },
];

export const groundServiceCategoryTypes = [
  { value: 'perBooking', label: 'Per booking' },
  { value: 'perPerson', label: 'Per person' },
];

export const mealPlanCategoryTypes = [{ value: 'perPersonPerNight', label: 'Per person per night' }];

export const Input = ({
  label,
  value,
  onChange,
  onDebounceChange,
  className,
  labelClassName,
  inputClassName,
  debounceMs = 1000,
  disabled,
}: {
  label?: string | React.ReactNode;
  value: string;
  onChange?: (newVal: string) => void;
  onDebounceChange?: (newVal: string) => void;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  debounceMs?: number;
  disabled?: boolean;
}) => {
  if (!onDebounceChange && !onChange) {
    throw new Error('onDebounceChange or onChange must be provided');
  }
  const [searchTerm, setSearchTerm, debouncedSearchTerm] = useDebounce(value, debounceMs);

  const prevDebouncedTerm = usePrevious(debouncedSearchTerm, true);

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  useEffect(() => {
    if (onDebounceChange && prevDebouncedTerm !== debouncedSearchTerm) {
      onDebounceChange(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  return (
    <label className={classNames('flex flex-col space-y-1', className)}>
      {label && <span className={classNames('text-sm', labelClassName)}>{label}</span>}
      <input
        autoComplete="off"
        data-lpignore="true"
        value={searchTerm}
        className={classNames(
          'focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 font-pt-sans min-h-37px max-h-37px',
          inputClassName
        )}
        type="text"
        disabled={disabled}
        onChange={e => {
          setSearchTerm(e.target.value);
          if (onChange) {
            onChange(e.target.value);
          }
        }}
      />
    </label>
  );
};

export const Dropdown = ({
  label,
  values,
  options,
  onChange,
  className,
  labelClassName,
  dropdownClassName,
  multiselectProps,
}: {
  label: string | React.ReactNode;
  values: string[];
  options: IMultiselectProps['options'];
  onChange: (selectedValues: string[]) => void;
  multiselectProps?: Partial<IMultiselectProps>;
  className?: string;
  labelClassName?: string;
  dropdownClassName?: string;
}) => {
  return (
    <label className={classNames('flex flex-col space-y-1', className)}>
      <span className={classNames('text-sm', labelClassName)}>{label}</span>
      {options.length <= 0 && <span className="min-h-35px border border-solid border-gray-40"></span>}
      {options.length > 0 && (
        <Multiselect
          className={classNames('bg-white', dropdownClassName)}
          itemsClassname="bg-white"
          itemCtaClassName="hover:bg-gray-10"
          onUpdate={sv => {
            if (options.length === 0) {
              return;
            }
            onChange(sv);
          }}
          options={options}
          selectedValues={values}
          {...multiselectProps}
        />
      )}
    </label>
  );
};

export const TextArea = ({
  label,
  value,
  onChange,
  rows = 3,
}: {
  label: string | React.ReactNode;
  value: string;
  onChange: (newVal: string) => void;
  rows?: number;
}) => {
  return (
    <label className="flex flex-col space-y-1">
      <span className="text-sm">{label}</span>
      <textarea
        value={value}
        className={classNames(
          'focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 font-pt-sans'
        )}
        rows={rows}
        onChange={e => onChange(e.target.value)}
      />
    </label>
  );
};

export const Checkbox = ({
  label,
  value,
  onChange,
  className,
}: {
  label: string | React.ReactNode;
  value: boolean;
  onChange: (newVal: boolean) => void;
  className?: string;
}) => {
  return (
    <label className={classNames('flex flex-col space-y-1', className)}>
      <span className="text-sm">{label}</span>
      <UiCheckbox
        checked={value}
        onChange={e => {
          onChange(e.currentTarget.checked);
        }}
      />
    </label>
  );
};

export const CheckboxCollection = ({
  label,
  collection,
  onChange,
}: {
  label: string | React.ReactNode;
  collection: any[];
  onChange: any;
}) => {
  return (
    <div className="flex flex-col space-y-1">
      <span className="text-sm">{label}</span>
      <div className="flex flex-wrap items-center gap-4">
        {collection.map(item => {
          return (
            <label key={item.label} className="flex flex-row items-center gap-2">
              <UiCheckbox
                checked={item.isChecked}
                onChange={e => {
                  onChange(item.label, e.currentTarget.checked);
                }}
              />
              <span>{item.label}</span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export const RadioCollection = ({
  label,
  onChange,
  collection,
  disabled,
}: {
  label?: string | React.ReactNode;
  onChange: (newCheckedVal: string | null | undefined) => void;
  collection: {
    isChecked: boolean;
    value: string | null | undefined;
    label: string;
  }[];
  disabled?: boolean;
}) => {
  return (
    <div className={classNames('font-pt-sans flex flex-col space-y-1', { 'opacity-50 pointer-events-none': disabled })}>
      {label && <span className="text-sm">{label}</span>}
      <div className="flex flex-col space-y-1">
        {collection.map((item, index) => (
          <label className="block" key={item.label}>
            <Radio checked={item.isChecked} onClick={() => onChange(item.value)} />
            <span className="ml-2">{item.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export const CrudList = ({
  label,
  collection,
  className,
  labelClassName,
  inputClassName,
  onChange,
}: {
  label?: string | React.ReactNode;
  collection: string[];
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  onChange: (newCollection: any[]) => void;
}) => {
  return (
    <fieldset className={classNames('p-0 m-0 flex flex-col space-y-4 relative w-full', className)}>
      {label && <legend className={classNames('text-sm', labelClassName)}>{label}</legend>}
      <div className="grid grid-cols-1 gap-6">
        {collection.map((item, index) => {
          return (
            <div key={index} className="flex flex-row space-x-2">
              <input
                className={classNames(
                  'focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 font-pt-sans min-h-37px max-h-37px w-full',
                  inputClassName
                )}
                type="text"
                value={item}
                onChange={e => onChange(collection.map((i, iIndex) => (iIndex === index ? e.target.value : i)))}
              />
              <button
                className="rounded-full w-6 h-6 bg-brown-60 hover:bg-brown-80 text-white mt-2 text-xs cursor-pointer"
                onClick={() => onChange(collection.filter(i => i !== item))}
              >
                &#x2715;
              </button>
            </div>
          );
        })}
      </div>
      <FluidButton type="secondary" className="w-[100px]" onClick={() => onChange([...collection, ''])}>
        Add
      </FluidButton>
    </fieldset>
  );
};

export const AgeGroups = ({
  label,
  collection,
  className,
  labelClassName,
  inputClassName,
  onChange,
}: {
  label?: string | React.ReactNode;
  collection: IAgeName[];
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  onChange: (newCollection: any[]) => void;
}) => {
  return (
    <fieldset className={'border border-solid border-gray-40 p-2 flex flex-col space-y-2'}>
      <legend className={labelClassName}>{label}</legend>

      {collection.map((ageGroup, ageGroupIndex) => {
        return (
          <div key={ageGroupIndex} className="flex flex-row space-x-2 items-center">
            <Input
              label={'Name'}
              value={ageGroup.name}
              onChange={newVal => {
                const newAges = produce(collection, draftCollection => {
                  draftCollection[ageGroupIndex].name = newVal;
                });
                onChange(newAges);
              }}
            />
            <Input
              label={'From'}
              value={ageGroup.ageFrom?.toString() || ''}
              onChange={newVal => {
                const newAges = produce(collection, draftCollection => {
                  if (newVal === '') {
                    draftCollection[ageGroupIndex].ageFrom = undefined;
                  } else {
                    draftCollection[ageGroupIndex].ageFrom = parseInt(newVal);
                  }
                });
                onChange(newAges);
              }}
            />
            <Input
              label={'To'}
              value={ageGroup.ageTo?.toString() || ''}
              onChange={newVal => {
                const newAges = produce(collection, draftCollection => {
                  if (newVal === '') {
                    draftCollection[ageGroupIndex].ageTo = undefined;
                  } else {
                    draftCollection[ageGroupIndex].ageTo = parseInt(newVal);
                  }
                });
                onChange(newAges);
              }}
            />

            <button
              className="rounded-full w-6 h-6 bg-brown-60 hover:bg-brown-80 text-white mt-5 text-xs cursor-pointer"
              onClick={() => {
                const newAges = produce(collection, draftCollection => {
                  draftCollection.splice(ageGroupIndex, 1);
                });
                onChange(newAges);
              }}
            >
              &#x2715;
            </button>
          </div>
        );
      })}

      <FluidButton
        type="secondary"
        className="w-[200px] self-start !mt-4"
        onClick={() => {
          const newAges = produce(collection, draftCollection => {
            draftCollection.push({
              name: '',
              ageFrom: 0,
              ageTo: 0,
            });
          });
          onChange(newAges);
        }}
      >
        Add Age Group
      </FluidButton>
    </fieldset>
  );
};

export const OccupancyLimits = ({
  label,
  collection,
  className,
  labelClassName,
  inputClassName,
  onChange,
}: {
  label?: string | React.ReactNode;
  collection: IOccupancyLimit[];
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  onChange: (newCollection: any[]) => void;
}) => {
  return (
    <fieldset className={'border border-solid border-gray-40 p-2 flex flex-col space-y-2'}>
      <legend className={labelClassName}>{label}</legend>

      {collection
        .sort((a, b) => (a.name === 'default' ? -1 : b.name === 'default' ? 1 : 0)) // put default at the front
        .map((occupancyLimit, occupancyLimitIndex) => {
          return (
            <div key={occupancyLimitIndex} className="flex flex-row space-x-2 items-center">
              <Input
                label={'Name'}
                value={occupancyLimit.name}
                inputClassName={classNames({
                  'opacity-50': occupancyLimit.name === 'default',
                })}
                disabled={occupancyLimit.name === 'default'}
                onChange={newVal => {
                  const newAges = produce(collection, draftCollection => {
                    draftCollection[occupancyLimitIndex].name = newVal;
                  });
                  onChange(newAges);
                }}
              />
              <Input
                label={'Minimum'}
                value={occupancyLimit.minimum?.toString() || ''}
                onChange={newVal => {
                  const newAges = produce(collection, draftCollection => {
                    draftCollection[occupancyLimitIndex].minimum = isBlank(newVal) ? undefined : parseInt(newVal);
                  });
                  onChange(newAges);
                }}
              />
              <Input
                label={'Maximum'}
                value={occupancyLimit.maximum?.toString() || ''}
                onChange={newVal => {
                  const newAges = produce(collection, draftCollection => {
                    draftCollection[occupancyLimitIndex].maximum = isBlank(newVal) ? undefined : parseInt(newVal);
                  });
                  onChange(newAges);
                }}
              />

              {occupancyLimit.name !== 'default' && (
                <button
                  className="rounded-full w-6 h-6 bg-brown-60 hover:bg-brown-80 text-white mt-5 text-xs cursor-pointer"
                  onClick={() => {
                    onChange(
                      produce(collection, draftCollection => {
                        draftCollection.splice(occupancyLimitIndex, 1);
                      })
                    );
                  }}
                >
                  &#x2715;
                </button>
              )}
            </div>
          );
        })}

      <FluidButton
        type="secondary"
        className="w-[200px] self-start !mt-4"
        onClick={() => {
          const newAges = produce(collection, draftCollection => {
            draftCollection.push({
              name: '',
              minimum: 0,
              maximum: 0,
            });
          });
          onChange(newAges);
        }}
      >
        Add Occupancy Limit
      </FluidButton>
    </fieldset>
  );
};

export const CountryList = ({
  label,
  onChange,
  className,
  labelClassName,
  bootstrapCountries,
  selectedCountries,
}: {
  label?: string | React.ReactNode;
  onChange: (newCountryList: IBootstrapCountry[]) => void;
  className?: string;
  labelClassName?: string;
  bootstrapCountries: IBootstrapCountry[];
  selectedCountries: IBootstrapCountry[];
}) => {
  // get the distinct regions from the countries
  const regions = new Set(bootstrapCountries.map(bc => bc.region).filter(Boolean));
  const selectedCountryCodes = selectedCountries.map(c => c.code);
  const mapOfIsSelected = bootstrapCountries.reduce((acc, bc) => {
    acc[bc.code] = selectedCountryCodes.includes(bc.code);
    return acc;
  }, {});

  return (
    <label className={classNames('flex flex-col space-y-1', className)}>
      <span className={classNames('text-sm', labelClassName)}>{label}</span>

      <Checkbox
        label={'All countries'}
        className="o:flex-row o:space-y-0 space-x-2"
        value={selectedCountries.length === bootstrapCountries.length}
        onChange={val => {
          if (val) {
            onChange(bootstrapCountries);
          } else {
            onChange([]);
          }
        }}
      />
      {Array.from(regions).map(region => {
        return (
          <details key={region}>
            <summary>{region}</summary>
            <div className="grid grid-cols-5 gap-2">
              {bootstrapCountries
                .filter(bc => bc.region === region)
                .map(bc => {
                  return (
                    <Checkbox
                      label={bc.name}
                      className="o:flex-row o:space-y-0 space-x-2"
                      key={bc.code}
                      value={mapOfIsSelected[bc.code]}
                      onChange={val => {
                        if (val) {
                          onChange([...selectedCountries, bc]);
                        } else {
                          onChange(selectedCountries.filter(c => c.code !== bc.code));
                        }
                      }}
                    />
                  );
                })}
            </div>
          </details>
        );
      })}
    </label>
  );
};

export const Select = ({
  label,
  value,
  onChange,
  options,
}: {
  label: string | React.ReactNode;
  value: string;
  onChange: (newVal: string) => void;
  options: {
    value: string;
    label: string;
  }[];
}) => {
  return (
    <label className="flex flex-col space-y-1">
      <span className="text-sm">{label}</span>
      <select
        className="border border-solid border-gray-40 p-2 font-pt-sans"
        value={value}
        onChange={e => onChange(e.target.value)}
      >
        {options.map(option => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </label>
  );
};

export const RichTextEditor = ({
  label,
  value,
  onChange,
}: {
  label: string;
  value: string;
  onChange: (newVal: string) => void;
}) => {
  return (
    <div className="flex flex-col space-y-1">
      <span className="text-sm">{label}</span>
      <Editor
        text={value}
        handleEditorChange={content => {
          onChange(content);
        }}
        options={[]}
        mentionAbility={false}
      />
    </div>
  );
};
