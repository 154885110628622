import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

import {
  makeBookingManagerApi,
  BookingSearchFilter,
  IPostExportResponse,
  IGetExportResponse,
} from 'services/BookingManagerApi';
import {
  exportUuidSelector,
  selectedStatusesSelector,
  bookingsListQuerySelector,
  exportTypeSelector,
} from 'store/modules/bookingsList/selectors';
import {
  TRIGGER_BOOKING_LIST_EXPORT_REQUEST,
  POLL_BOOKING_LIST_EXPORT_REQUEST,
  pollBookingListExportSuccessAction,
  triggerBookingListExportSuccessAction,
  triggerBookingListExportFailureAction,
  pollBookingListExportFailureAction,
  pollBookingListExportContinueAction,
} from 'store/modules/bookingsList/actions';
import { enqueueNotification } from 'store/modules/ui/actions';
import { formatDateTimeIncludeTimezoneDisplay } from 'utils';
import { ExportType } from '../model';

export function* triggerBookingListExportSaga(action: any) {
  try {
    const bookingManagerApi = makeBookingManagerApi();

    const query = yield select(bookingsListQuerySelector);
    const exportType = yield select(exportTypeSelector);

    const { filter }: { filter: BookingSearchFilter } = query;

    let result: AxiosResponse<IPostExportResponse> = (undefined as unknown) as AxiosResponse<IPostExportResponse>;
    if (exportType === ExportType.SrExport) {
      result = yield call(bookingManagerApi.postBookingListSrExport, filter);
    } else {
      result = yield call(bookingManagerApi.postBookingListExport, filter, exportType);
    }

    if (result.status === 200) {
      yield put(triggerBookingListExportSuccessAction(result.data.exportUuid));
    } else {
      yield put(triggerBookingListExportFailureAction());
      yield put(
        enqueueNotification({
          message: 'There was a problem triggering the export',
          options: { variant: 'error' },
        })
      );
    }
  } catch (e) {
    yield put(triggerBookingListExportFailureAction());
    yield put(
      enqueueNotification({
        message: 'There was a problem triggering the export',
        options: { variant: 'error' },
      })
    );
  }
}

export function* pollBookingListExportSaga(action: any) {
  try {
    const bookingManagerApi = makeBookingManagerApi();

    const status = yield select(selectedStatusesSelector);

    const exportUuid = yield select(exportUuidSelector);
    const result: AxiosResponse<IGetExportResponse> = yield call(bookingManagerApi.getBookingListExport, exportUuid);

    switch (result.data.status) {
      case 200:
        yield put(pollBookingListExportSuccessAction(result.data.data.signedUrl));
        saveAs(result.data.data.signedUrl, `${status} Bookings - ${formatDateTimeIncludeTimezoneDisplay(new Date())}`);
        break;
      case 404:
      case 422:
        // theres been an issue somewhere - stop everything
        yield put(pollBookingListExportFailureAction());
        yield put(
          enqueueNotification({
            message: 'There was a problem downloading the export',
            options: { variant: 'error' },
          })
        );
        break;
      case 202:
      default:
        // deliberately nothing - we keep polling
        yield put(pollBookingListExportContinueAction());
        break;
    }
  } catch (e) {
    // something in the poll broke for some reason - exit out
    yield put(pollBookingListExportFailureAction());
    yield put(
      enqueueNotification({
        message: 'There was a problem downloading the export',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchExportBookingListSaga() {
  yield takeLatest([TRIGGER_BOOKING_LIST_EXPORT_REQUEST], triggerBookingListExportSaga);
  yield takeLatest([POLL_BOOKING_LIST_EXPORT_REQUEST], pollBookingListExportSaga);
}
