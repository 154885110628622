import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { TaskList } from './TaskList';
import { TaskCreate } from './TaskCreate';
import { TaskEdit } from './TaskEdit';

export interface TaskManagementRoutingProps extends RouteComponentProps {}

export const TaskManagementRoutingComponent = (props: TaskManagementRoutingProps) => {
  return (
    <Switch>
      <Route exact path={props.match.path} component={TaskList} />
      <Route exact path={`${props.match.path}/create`} component={TaskCreate} />
      <Route exact path={`${props.match.path}/:taskId/edit`} component={TaskEdit} />
    </Switch>
  );
};

export const TaskManagementRouting = compose(withRouter)(TaskManagementRoutingComponent);
