import React from 'react';
import { EImportEntity } from '../../store/modules/importer/model';
import { ancillaryGroundServicesImportDomainSelector } from '../../store/modules/ancillaryGroundServicesImport/selectors';
import { ancillaryGroundServicesRatesImportDomainSelector } from '../../store/modules/ancillaryGroundServicesRatesImport/selectors';
import { useSelector } from 'react-redux';
import { AncillaryImporterTemplateContainer } from '../AncillaryImporterTemplating/importer';
import { SimpleTabs } from 'ui/SimpleTabs';

export const AncillaryGroundServicesImporterContainer = () => {
  const groundServicesImportData = useSelector(ancillaryGroundServicesImportDomainSelector);
  const groundServicesRatesImportData = useSelector(ancillaryGroundServicesRatesImportDomainSelector);

  return (
    <>
      <div className="container w-full max-w-1280px mx-auto space-y-20px">
        <h1 className="font-normal font-noe-display text-[36px] leading-46px">
          Products - <span className="text-[26px]">Ground Services</span>
        </h1>
      </div>
      <SimpleTabs
        tabConfig={[
          {
            title: 'Ground Services',
            name: 'ground-services',
            styles: 'min-w-150px',
            renderContent: () => (
              <AncillaryImporterTemplateContainer
                importerEntity={EImportEntity.ANCILLARY_GROUND_SERVICES}
                importerData={groundServicesImportData}
                importerDomainName="Ground Services"
                importerPageTitle={<h2 className="font-bold font-pt-sans text-[17px] leading-22px">Ground Services</h2>}
              />
            ),
          },
          {
            title: 'Rates',
            name: 'ground-services-rates',
            styles: 'min-w-150px',
            renderContent: () => (
              <AncillaryImporterTemplateContainer
                importerEntity={EImportEntity.ANCILLARY_GROUND_SERVICES_RATES}
                importerData={groundServicesRatesImportData}
                importerDomainName="Ground Services Rates"
                importerPageTitle={<h2 className="font-bold font-pt-sans text-[17px] leading-22px">Rates</h2>}
              />
            ),
          },
        ]}
      />
    </>
  );
};
