import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { last } from 'lodash-es';
import { formatPrice } from 'utils';
import { EBespokeNegotiationDiscountType } from 'services/BookingManagerApi/types';

import * as Actions from 'store/modules/bookingManager/subdomains/bespokeNegotiation/actions';
import * as Selectors from 'store/modules/bookingManager/subdomains/bespokeNegotiation/selectors';
import { bookingCurrencySymbolSelector } from 'store/modules/bookingManager/selectors';

import { CircleIconButton } from 'ui/CircleIconButton';
import { BespokeNegotiationModal } from './BespokeNegotiationModal';

export const BespokeNegotiation: React.FC = () => {
  const dispatch = useDispatch();

  const isModalOpen = !!useSelector(Selectors.editingSelector);
  const isLoading = useSelector(Selectors.getLoadingSelector);
  const items = useSelector(Selectors.itemsSelector);
  const currencySymbol = useSelector(bookingCurrencySymbolSelector);

  const formatted = useMemo(() => {
    const discount = last(items)?.discount;
    if (isLoading) {
      return '';
    }
    if (!discount) {
      return 'None';
    }
    if (discount.type === EBespokeNegotiationDiscountType.FLAT) {
      return `${currencySymbol} ${formatPrice(discount.amount / 100)}`;
    }
    return `${discount.amount / 100}%`;
  }, [isLoading, items, currencySymbol]);

  const handleEdit = useCallback(() => dispatch(Actions.openModalAction()), [dispatch]);

  return (
    <div className="bespoke-negotiation flex items-center">
      <div className="value text-gray-100 bg-gray-10 p-9px min-h-39px flex-1">{formatted}</div>
      <CircleIconButton
        type="secondary"
        className="bespoke-negotiation-edit-button ml-10px"
        disabled={isLoading}
        iconClass={isLoading ? 'fas fa-circle-notch fa-spin' : 'fas fa-pen'}
        onClick={handleEdit}
      />
      {isModalOpen && <BespokeNegotiationModal />}
    </div>
  );
};
