import React from 'react';
import classnames from 'classnames';

interface IErrorMessageLineProps {
  children: string;
  className?: string;
}

export const ErrorMessageLine: React.FC<IErrorMessageLineProps> = React.memo(({ children, className = '' }) => {
  return (
    <span className={classnames('block uppercase text-xs font-[600] leading-14px text-[#FF0000] font-hurmegeometric-sans', className)}>
      {children}
    </span>
  );
});