import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const InfoTransparentIcon: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors['gray-80'], height = 18, width = 18 }) => (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666626 8.99996C0.666626 4.39996 4.39996 0.666626 8.99996 0.666626C13.6 0.666626 17.3333 4.39996 17.3333 8.99996C17.3333 13.6 13.6 17.3333 8.99996 17.3333C4.39996 17.3333 0.666626 13.6 0.666626 8.99996ZM9.83329 8.16663V13.1666H8.16663V8.16663H9.83329ZM8.99996 15.6666C5.32496 15.6666 2.33329 12.675 2.33329 8.99996C2.33329 5.32496 5.32496 2.33329 8.99996 2.33329C12.675 2.33329 15.6666 5.32496 15.6666 8.99996C15.6666 12.675 12.675 15.6666 8.99996 15.6666ZM9.83329 4.83329V6.49996H8.16663V4.83329H9.83329Z"
        fill={fill}
      />
    </svg>
  )
);
