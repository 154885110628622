import { call, put, takeLatest, select } from 'redux-saga/effects';
import { pick } from 'ramda';
import * as BookingBuilderActions from '../actions';
import { PROPOSALS_NEW, PROPOSALS_ADD } from '../../proposals/actions';
import { makeBackendApi } from 'services/BackendApi';

import { bookingBuilderResponseHotelUuidSelector } from 'store/modules/bookingBuilder';
import * as BookingBuilderSelectors from '../selectors';
import { pendingProposalsLatestSelector } from '../../proposalsList/subdomains/pendingProposals/selectors';
import { selectedTaSelector } from '../../agents';

export function* initializeBookingBuilderSaga(action: BookingBuilderActions.InitializeBookingBuilderAction) {
  try {
    const latestBookingOperation = yield select(BookingBuilderSelectors.latestBookingOperationSelector);

    //update booking guest info from latest pending proposal if this was user's last action
    if ([PROPOSALS_ADD, PROPOSALS_NEW].includes(latestBookingOperation)) {
      const latestPendingProposal = yield select(pendingProposalsLatestSelector);

      if (latestPendingProposal) {
        yield put(
          BookingBuilderActions.updateBookingGuestInformationAction(
            pick(['guestTitle', 'guestFirstName', 'guestLastName'], latestPendingProposal)
          )
        );
      }
    }

    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);

    // if you're here looking for the "copy the booking build from search
    // into the booking builder", you're not going to find it
    // see https://pureescapes.atlassian.net/browse/OWA-3988

    const hotel = yield call(backendApi.getHotel, action.hotelUuid);

    yield put(BookingBuilderActions.createStubBookingBuilderAction(hotel));
  } catch (e) {
    console.info(e);
    yield put(BookingBuilderActions.initializeBookingBuilderFailureAction());
  }
}

export function* watchInitializeBookingBuilder() {
  yield takeLatest(BookingBuilderActions.INITIALIZE_BOOKING_BUILDER, initializeBookingBuilderSaga);
}
