import React, { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'lodash-es';

import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Selectors from 'store/modules/liveRates/selectors';
import * as Actions from 'store/modules/liveRates/actions';

import { LoadingBar, ErrorBar } from 'ui/NetworkStatusBar';
import { Pagination } from 'pureUi/Pagination';
import { Filter } from './Filter';
import { Table } from './Table';
import { ILiveRatesListingItem } from 'services/BackendApi/types/LiveRatesInternal';
import { MapStayModal } from '../LiveRatesCreate/MapStayModal';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';

export const LiveRatesListingPage: React.FC = () => {
  const request = useSelector(Selectors.requestSelector);
  const items = useSelector(Selectors.itemsSelector);
  const filter = useSelector(Selectors.filterSelector);
  const toDelete = useSelector(Selectors.toDeleteSelector);
  const isNewMappingModalOpen = useSelector(Selectors.isNewMappingModalOpenSelector);

  const isInitialized = !isNil(filter.page_total);

  const isError = request.listingPageGet === ENetworkRequestStatus.ERROR;

  const pageCount = useMemo(
    () => (!isNil(filter.page_total) ? Math.ceil(filter.page_total / filter.page_size) : null),
    [filter.page_total, filter.page_size]
  );

  const isTableLoading = request.listingPageGet === ENetworkRequestStatus.PENDING;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.listActions.getRequestAction());
  }, [dispatch]);
  const handleDelete = useCallback((x: ILiveRatesListingItem) => dispatch(Actions.listActions.setToDeleteAction(x)), [
    dispatch,
  ]);

  const handleDeleteConfirm = useCallback(() => dispatch(Actions.listActions.deleteRequestAction()), [dispatch]);

  const handleDeleteCancel = useCallback(() => dispatch(Actions.listActions.setToDeleteAction(null)), [dispatch]);

  const handlePageSelect = useCallback(page => dispatch(Actions.listActions.setPaginationPage(page)), [dispatch]);

  const handlePerPageChange = useCallback(perPage => dispatch(Actions.listActions.setPaginationItemsPerPage(perPage)), [
    dispatch,
  ]);

  const handleNewMappingModalOpen = useCallback(() => {
    dispatch(Actions.createActions.openNewMappingModalAction());
  }, [dispatch]);

  const handleNewMappingModalClose = useCallback(() => {
    dispatch(Actions.createActions.closeNewMappingModalAction());
  }, [dispatch]);

  return (
    <div className="live-rates-mapping-list container w-full max-w-1280px mx-auto font-pt-sans text-black">
      <div className="flex flex-row items-center justify-between">
        <h1 className="title font-noe-display font-normal text-black my-0">
          <span className="text-4xl leading-46px">Live Rate Mappings</span>
          {isInitialized && <span className="text-26px leading-33px"> ({getResultsText(filter.page_total!)})</span>}
        </h1>
      </div>
      {isError && <ErrorBar />}
      {!isError && !isInitialized && <LoadingBar />}
      {!isError && isInitialized && (
        <>
          <Filter
            nameFilterValue={filter.nameFilter}
            externalSystemFilterValue={filter.externalSystem}
            onChangeName={n => {
              dispatch(Actions.listActions.setFilterName(n));
            }}
            onChangeExternalSystem={es => {
              dispatch(Actions.listActions.setFilterExternalSystem(es));
            }}
            onNewMappingModalOpen={handleNewMappingModalOpen}
          />
          <div className="mt-5">
            <Table className="mt-20px" items={items || []} onDelete={handleDelete} loading={isTableLoading} />
          </div>
          {(items || []).length >= 1 && (
            <Pagination
              className="pagination mt-40px"
              onPageSelect={handlePageSelect}
              pageCount={pageCount || 0}
              currentPage={filter.page}
              itemsPerPage={filter.page_size}
              onItemsPerPageChange={handlePerPageChange}
              isLoading={isTableLoading}
            />
          )}
        </>
      )}
      {!!toDelete && (
        <ConfirmationModal
          type={EConfirmationModalType.WARNING}
          className="delete-modal"
          title="By deleting the mapping this stay will no longer offer live rates and all the rooms mappings will be deleted."
          message="Are you sure want to delete this item?"
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          isConfirmLoading={request.deletingItem === ENetworkRequestStatus.PENDING}
        />
      )}
      {isNewMappingModalOpen && <MapStayModal onCancel={handleNewMappingModalClose} />}
    </div>
  );
};

const getResultsText = (count: number): string => {
  if (!count) {
    return 'no results found';
  }
  if (count === 1) {
    return '1 result found';
  }
  return `${count} results found`;
};
