import { ENetworkRequestStatus } from 'services/BackendApi';
import { ICompanyMembership } from 'services/BookingManagerApi/types/CompanyMemberships';

export interface ICompanyMembershipDataPayload extends Partial<ICompanyMembership> {
  logoFormData?: FormData; // for doing the logo upload
  hasRemovedLogo?: boolean; // for removing a logo
  companyDepartmentUuid?: string;
}

export interface ICompanyMembershipsRequests {
  membershipListLoad: ENetworkRequestStatus;
  membershipSave: ENetworkRequestStatus; // used for creating AND editing
  membershipDelete: ENetworkRequestStatus;
}

export interface ICompanyMembershipsDomain {
  memberships: ICompanyMembership[] | null; // the list of memberships
  uuidToDelete: string | null;
  membershipToEdit: ICompanyMembership | null;
  requests: ICompanyMembershipsRequests;
  modals: {
    isMembershipModalOpen: boolean; // used for creating AND editing
    isDeleteMembershipModalOpen: boolean;
  };
}

export const initialState: ICompanyMembershipsDomain = {
  memberships: null,
  uuidToDelete: null,
  membershipToEdit: null,
  requests: {
    membershipListLoad: ENetworkRequestStatus.IDLE,
    membershipSave: ENetworkRequestStatus.IDLE,
    membershipDelete: ENetworkRequestStatus.IDLE,
  },
  modals: {
    isMembershipModalOpen: false,
    isDeleteMembershipModalOpen: false,
  },
};
