import { ICompany } from 'services/BackendApi';

export const GET_REQUEST = 'companyList/GET_REQUEST';
export const GET_SUCCESS = 'companyList/GET_SUCCESS';
export const GET_FAILURE = 'companyList/GET_FAILURE';

export const SET_SEARCH_FILTER = 'companyList/SET_SEARCH_FILTER';
export const SET_COUNTRY_CODE_FILTER = 'companyList/SET_COUNTRY_CODE_FILTER';

export const SET_PAGE = 'companyList/SET_PAGE';
export const SET_PER_PAGE = 'companyList/SET_PER_PAGE';

export const TRAVEL_COMPANY_CSV_REQUEST = 'companyList/TRAVEL_COMPANY_CSV_REQUEST';
export const TRAVEL_COMPANY_CSV_REQUEST_SUCCESS = 'companyList/TRAVEL_COMPANY_CSV_REQUEST_SUCCESS';
export const TRAVEL_COMPANY_CSV_REQUEST_ERROR = 'companyList/TRAVEL_COMPANY_CSV_REQUEST_ERROR';

export type GetRequestAction = ReturnType<typeof getRequestAction>;
export const getRequestAction = () => ({
  type: GET_REQUEST as typeof GET_REQUEST
});

export type GetSuccessAction = ReturnType<typeof getSuccessAction>;
export const getSuccessAction = (companies: ICompany[], total: number) => ({
  type: GET_SUCCESS as typeof GET_SUCCESS,
  companies,
  total
});

export type GetFailureAction = ReturnType<typeof getFailureAction>;
export const getFailureAction = (error: string) => ({
  type: GET_FAILURE as typeof GET_FAILURE,
  error,
});

export type SetSearchFilterAction = ReturnType<typeof setSearchFilterAction>;
export const setSearchFilterAction = (value: string | null) => ({
  type: SET_SEARCH_FILTER as typeof SET_SEARCH_FILTER,
  value
});

export type SetCountryCodeFilterAction = ReturnType<typeof setCountryCodeFilterAction>;
export const setCountryCodeFilterAction = (value: string | null) => ({
  type: SET_COUNTRY_CODE_FILTER as typeof SET_COUNTRY_CODE_FILTER,
  value
});

export type SetPageAction = ReturnType<typeof setPageAction>;
export const setPageAction = (value: number) => ({
  type: SET_PAGE as typeof SET_PAGE,
  value
});

export type SetPerPageAction = ReturnType<typeof setPerPageAction>;
export const setPerPageAction = (value: number) => ({
  type: SET_PER_PAGE as typeof SET_PER_PAGE,
  value
});

export type TravelCompanyCsvRequestAction = ReturnType<typeof travelCompanyCsvRequestAction>;
export const travelCompanyCsvRequestAction = () => ({
  type: TRAVEL_COMPANY_CSV_REQUEST as typeof TRAVEL_COMPANY_CSV_REQUEST
})

export type TravelCompanyCsvRequestActionSuccess = ReturnType<typeof travelCompanyCsvRequestActionSuccess>;
export const travelCompanyCsvRequestActionSuccess = () => ({
  type: TRAVEL_COMPANY_CSV_REQUEST_SUCCESS as typeof TRAVEL_COMPANY_CSV_REQUEST_SUCCESS
})

export type TravelCompanyCsvRequestActionError = ReturnType<typeof travelCompanyCsvRequestActionError>;
export const travelCompanyCsvRequestActionError = () => ({
  type: TRAVEL_COMPANY_CSV_REQUEST_ERROR as typeof TRAVEL_COMPANY_CSV_REQUEST_ERROR
})

export type Any =
  | GetRequestAction
  | GetSuccessAction
  | GetFailureAction
  | SetSearchFilterAction
  | SetCountryCodeFilterAction
  | SetPageAction
  | SetPerPageAction
  | TravelCompanyCsvRequestAction
  | TravelCompanyCsvRequestActionSuccess
  | TravelCompanyCsvRequestActionError
  ;
