
export enum EProposalTemplate {
  PROPOSAL = 'proposal',
  ITINERARY = 'itinerary',
}

export enum EProposalLang {
  EN = 'en',
  RU = 'ru',
}

export enum EProposalLogo {
  COMPANY = 'company',
  MAIN_COMPANY = 'main_company',
}

export interface ProposalGuest {
  title: string | null;
  firstName: string;
  lastName: string;
}

export interface ProposalHotelComment {
  hotelUuid: string;
  content: string | null;
}

export interface ProposalRequest {
  proposalSelectionUuid: string;
  bookingUuids: string[];
  
  template: EProposalTemplate;
  lang: EProposalLang;
  name: string;
  logo: EProposalLogo | null;
  taMarginIncluded: boolean;

  guest: ProposalGuest;
  
  generalComments: string | null;
  otherComments: string | null;
  hotelComments: ProposalHotelComment[];
}

export interface Proposal extends ProposalRequest {
  version: number;
  uuid: string;
  pdfUrl: string;
  googleDocId?: string | null;
  createdAt?: string;
  updatedAt?: string;
}

export interface IProposalHistory {
  proposalSelectionUuid: string; 
  pdfUrl: string;
  name: string;
  uuid: string;
  email?: string;
}

export interface ProposalDocDraftRequest {
  googleDocId?: string | null;
}

export interface ProposalDocDraftResponse {
  googleDocId: string
}

export interface ProposalDocCommitRequest {
  googleDocId: string
}
