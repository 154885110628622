import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import classNames from 'classnames';
import { BookingHotelBasicInfo } from './BookingHotelBasicInfo';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { BookingHotelExpandableInfo } from './BookingHotelExpandableInfo';
import { useCurrentWidth } from 'effects';
import { BookingBuilder, IHotel } from 'services/BackendApi';
import { LodgingSummary } from 'interfaces';
import * as HotelSelectors from 'store/modules/hotel/selectors';
import { useSelector } from 'react-redux';

interface IBookingHotelProps {
  booking: BookingBuilder;
  lodgingSummaries: LodgingSummary[];
  hotel: IHotel;
  setRedirectLocation: React.Dispatch<React.SetStateAction<string | null>>;
  setShouldShowLeaveAlert: Dispatch<SetStateAction<boolean>>;
  shouldShowLeaveAlert: boolean;
  className?: string;
}

export const BookingHotel: React.FC<IBookingHotelProps> = React.memo(({ booking, lodgingSummaries, className, setShouldShowLeaveAlert, setRedirectLocation }) => {
  const { currentWidth } = useCurrentWidth();
  
  const uploads = useSelector(HotelSelectors.hotelPhotosSelector);
  const featuredPhoto =
    uploads?.find(upload => upload.tag === 'featuredPhoto') ||
    uploads?.find(upload => upload.tag === 'photo') ||
    uploads?.find(upload => upload.url.match(/\.(gif|jpg|jpeg|tiff|png)$/i));

  const [isMoreInfoOpen, setMoreInfoOpen] = useState(false);
  const toggleMoreInfo = useCallback(() => {
    setMoreInfoOpen(!isMoreInfoOpen);
  }, [isMoreInfoOpen, setMoreInfoOpen]);

  return (
    <div className={classNames('booking-accommodation bg-gray-3 p-[20px]', className)}>
      <div className={classNames('booking-accommodation-header flex gap-[30px]', className)}>
        {currentWidth > 1240 ? (
          <div className="booking-accommodation-image w-[196px] h-[147px]">
            <img
              className="block w-full h-full object-cover"
              src={featuredPhoto?.url}
              alt="Hotel photo"
            />
          </div>
        ) : null}

        <BookingHotelBasicInfo
          booking={booking}
          isMoreInfoOpen={isMoreInfoOpen}
          toggleMoreInfo={toggleMoreInfo}
          setRedirectLocation={setRedirectLocation}
          setShouldShowLeaveAlert={setShouldShowLeaveAlert}
        />
      </div>

      {/* @ts-ignore */}
      <AnimateHeight
        duration={300}
        height={isMoreInfoOpen ? 'auto' : 0}
        className={classnames('accordion-area', {})}
      >
        <BookingHotelExpandableInfo booking={booking} lodgingSummaries={lodgingSummaries} />
      </AnimateHeight>
    </div>
	);
});