import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ENetworkRequestStatus, EUploadTag } from 'services/BackendApi';
import {
  newDocumentPayloadSelector,
  newDocumentValidationSelector,
  newDocumentLoadSelector,
} from 'store/modules/companyDocuments/selectors';
import {
  closeUploadCompanyDocumentModalAction,
  setCompanyDocumentDisplayNameAction,
  setCompanyDocumentTagAction,
  setCompanyDocumentToUploadAction,
  uploadCompanyDocumentRequestAction,
} from 'store/modules/companyDocuments/actions';
import { StandardModal } from 'pureUi/Modal';
import FluidButton from 'ui/FluidButton';
import { Radio } from 'ui/Radio';

interface IUploadFileModalProps {
  isOpen: boolean;
}

export const UploadFileModal: React.FC<IUploadFileModalProps> = React.memo(({ isOpen }) => {
  const newUploadLoad = useSelector(newDocumentLoadSelector);
  const newUploadPayload = useSelector(newDocumentPayloadSelector);
  const newUploadValidation = useSelector(newDocumentValidationSelector);
  const dispatch = useDispatch();

  const handleSetFileToUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.currentTarget.files) {
        return;
      }

      dispatch(setCompanyDocumentToUploadAction(e.currentTarget.files[0]));
    },
    [dispatch]
  );

  const handleSetFileDisplayName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setCompanyDocumentDisplayNameAction(e.currentTarget.value));
    },
    [dispatch]
  );

  const handleSetFileTag = useCallback(
    (tag: string) => {
      dispatch(setCompanyDocumentTagAction(tag));
    },
    [dispatch]
  );

  const handleCloseClick = useCallback(() => {
    dispatch(closeUploadCompanyDocumentModalAction());
  }, [dispatch]);

  const handleUploadFile = useCallback(() => {
    dispatch(uploadCompanyDocumentRequestAction());
  }, [dispatch]);

  if (!isOpen || !newUploadPayload) {
    return null;
  }

  return (
    <StandardModal
      className="items-start bg-black-true-transparent-60 z-11"
      frameClassName={`pt-4 px-8 pb-8 border border-solid text-black border-black-true font-pt-sans overflow-y-hidden w-470px`}
      showCloseButton={false}
      closeOnOutsideClick={true}
      onClose={handleCloseClick}
      removePadding
    >
      <div
        className="text-base text-brown-100 absolute top-4 right-4 cursor-pointer outline-none"
        onClick={handleCloseClick}
      >
        <i className="fas fa-times"></i>
      </div>
      <h3 className="font-noe-display font-normal text-21px leading-29px mt-0 mb-0">Upload New File</h3>

      <div className="text-base text-black" onClick={e => e.stopPropagation()}>
        <div className="file-form-item flex items-center mt-25px">
          <label className="select-file inline-block font-bold text-sm text-brown-100 tracking-wider py-2 px-3 border border-solid border-brown-100 rounded bg-white-true hover:bg-brown-20 cursor-pointer transition-bg-color duration-200">
            <input onChange={handleSetFileToUpload} type="file" className="hidden" />
            Select a file
          </label>
          {newUploadPayload.file && !!newUploadValidation.file.length && (
            <span className="validation-error ml-4 text-sm text-red-100">{newUploadValidation.file[0]}</span>
          )}
        </div>

        <div className="file-title flex flex-col mt-25px">
          <span className="font-bold">Title of document</span>
          <input
            onChange={handleSetFileDisplayName}
            value={newUploadPayload.displayName ?? undefined}
            type="text"
            className="filename text-15px bg-ivory border border-solid border-gray-40 px-3 py-2 w-full outline-none focus:border-gray-80 transition-border-color duration-200 mt-5px"
          />
        </div>

        <div className="file-category flex flex-row flex-wrap mt-25px">
          <span className="font-bold block w-full">File Category</span>
          <label className="flex cursor-pointer items-end mt-8px" onClick={() => {}}>
            <Radio
              checked={newUploadPayload.tag === EUploadTag.OTHER_GENERAL}
              onClick={() => handleSetFileTag(EUploadTag.OTHER_GENERAL)}
            />
            <span className="ml-2">General</span>
          </label>

          <label className="flex cursor-pointer items-end mt-8px ml-20px" onClick={() => {}}>
            <Radio
              checked={newUploadPayload.tag === EUploadTag.OTHER_INTERNAL}
              onClick={() => handleSetFileTag(EUploadTag.OTHER_INTERNAL)}
            />
            <span className="ml-2">Internal</span>
          </label>
        </div>
      </div>

      <div className="flex mt-8">
        <FluidButton
          type="primary"
          isLoading={newUploadLoad === ENetworkRequestStatus.PENDING}
          disabled={
            newUploadLoad === ENetworkRequestStatus.PENDING ||
            Object.values(newUploadValidation).some(v => v.length > 0)
          }
          onClick={handleUploadFile}
        >
          Upload File
        </FluidButton>
      </div>
    </StandardModal>
  );
});

export default UploadFileModal;
