import React, { useCallback } from 'react';
import classnames from 'classnames';

import countries from 'config/data/countries.json';
import ArrowDown from 'ui/Icons/components/ArrowDown.component';
import Star from 'ui/Icons/star.component.svg';
import { theme } from '../../../tailwind.config';
import { Price } from 'ui/Price';
import { PlainDates } from './PlainDates';
import format from 'date-fns/format';
import { PencilIcon } from 'ui/Icons/components/Pencil.component';
import { ERateType, RateTag } from 'ui/RateTag/RateTag';
import { useCurrentWidth } from 'effects';
import { addDays } from 'date-fns';
import { pluralize } from 'utils/string';
import { BookingBuilder } from 'services/BackendApi';
import { useHistory } from 'react-router';
import { History } from 'history';
import CopyIcon from 'ui/Icons/copy.component.svg';
import { useDispatch } from 'react-redux';
import { enqueueNotification } from 'store/modules/ui';
import { EditButton } from './components/EditButton';
import { CopyButton } from './components/CopyButton';
import { offsetDate } from 'utils';

interface IBookingAccommodationInfoProps {
  booking: BookingBuilder;
  isMoreInfoOpen: boolean;
  toggleMoreInfo: () => void;
  setShouldShowLeaveAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setRedirectLocation: React.Dispatch<React.SetStateAction<string | null>>;
}

export const BookingHotelBasicInfo: React.FC<IBookingAccommodationInfoProps> = React.memo(
  ({ booking, isMoreInfoOpen, toggleMoreInfo, setShouldShowLeaveAlert, setRedirectLocation }) => {
  const { currentWidth } = useCurrentWidth();
  const dispatch = useDispatch();
  const history = useHistory<History.LocationState>();
  const priceClassName = 'font-hurmegeometric-sans text-19px leading-20px font-semibold text-right mt-5px m-0';
  const isOnRequest = booking.response.totals.oneOrMoreItemsOnRequest;
  const hotel = booking.response.hotel;
  const numberOfRooms = booking.response.availableProductSets.Accommodation?.length ?? 0;
  const numberOfGuests = booking.request.guestAges.numberOfAdults + (booking.request.guestAges.agesOfAllChildren?.length ?? 0);
  const numberOfTransfers = booking.response.availableProductSets.Transfer?.filter(item => item.selected).length ?? 0; 
  const numberOfGroundServices = booking.response.availableProductSets['Ground Service']?.filter(item => item.selected).length ?? 0;
  const numberOfOtherItems = (booking.response.availableProductSets.Supplement?.filter(item => item.selected).length ?? 0) + (booking.response.availableProductSets.Fine?.filter(item => item.selected).length ?? 0);
  const arrowClassname = 'more-information w-6 h-6 flex justify-center items-center inline-block hover:bg-gray-40 active:bg-gray-40';
  const isLiveRate = !!booking.response.potentialBooking.Accommodation[0].isLiveRate;
  const startDate = booking.request.startDate;
  const endDate = format(addDays(offsetDate(booking.request.endDate), 1), 'yyyy-MM-dd');

  return (
    <div className="booking-accommodation-info flex-1 min-h-147px">
      <div className="booking-accommodation-info-content flex-1 font-hurmegeometric-sans">
        
        <div className="center-line flex justify-between ">
          <div className="center-line-left">
            <p className="hotel-stars flex items-center m-0">
              <span className="inline-flex">
                <Star fill={theme.colors['brown-100']} />
              </span>
              <span className="font-hurmegeometric-sans text-xs leading-14px text-brown-100 uppercase ml-5px relative top-1px">
                {hotel.starRating} STAR
              </span>
            </p>
            <p className="hotel-name font-noe-display text-xl leading-28px text-brown-100 tracking-1px mt-3px m-0">
              {hotel.name}
            </p>
            <p className="hotel-region font-hurmegeometric-sans text-sm leading-17px text-flint uppercase mt-3px m-0">
              {hotel.region}
            </p>
            <div className="dates-line flex justify-start mt-15px">
              <PlainDates checkInDate={startDate} checkOutDate={endDate} />
              <p className="booking-guests font-hurmegeometric-sans text-13px leading-16px text-flint uppercase ml-5 m-0">
                {numberOfGuests} {pluralize(numberOfGuests, 'GUEST')}
              </p>
              {currentWidth > 900 ? (
                <p className="booking-rooms font-hurmegeometric-sans text-13px leading-16px text-flint uppercase ml-5 m-0">
                  {numberOfRooms} {pluralize(numberOfRooms, 'ROOM')}
                </p>
              ) : null}
            </div>
          </div>
          <div className="center-line-right flex flex-col justify-end items-end">
            <div className="flex items-center gap-[8px] mb-[10px]">
              <EditButton
                caption="edit"
                hotel={hotel}
                setRedirectLocation={setRedirectLocation}
                setShouldShowLeaveAlert={setShouldShowLeaveAlert}
                className="booking-accommodation-edit"
              />
              <CopyButton booking={booking} />
            </div>
            <RateTag rateType={isLiveRate ? ERateType.LIVE_RATE : ERateType.STATIC_RATE} />
            <div className="flex flex-row items-center justify-end mt-[5px]">
              <p className="total-cost ml-1 font-hurmegeometric-sans text-13px leading-16px text-flint uppercase font-bold m-0">
                TOTAL COST 
              </p>
            </div>

            <Price
              total={booking.response.totals.total}
              totalBeforeDiscount={booking.response.totals.totalBeforeDiscount}
              currencyCode={booking.response.currency}
              isOnRequest={isOnRequest}
              totalClassName={`${priceClassName} text-black`}
              totalBeforeDiscountClassName={`font-hurmegeometric-sans text-15px leading-18px text-right mt-5px m-0 text-gray-80 line-through`}
              totalAfterDiscountClassName={`${priceClassName} text-red-15`}
              isOnRequestClassName={`${priceClassName} text-black`}
            />
          </div>
        </div>

        
        
        <div className="bottom-line flex justify-between mt-[10px]">
          <div className="bottom-line-left flex">
            {numberOfTransfers > 0 ? (
              <p className="booking-transfers font-hurmegeometric-sans text-13px leading-16px text-flint uppercase m-0">
                {numberOfTransfers} {pluralize(numberOfTransfers, 'TRANSFER')}
              </p>
            ) : null}
            {numberOfGroundServices > 0 ? (
              <p className="booking-ground-services font-hurmegeometric-sans text-13px leading-16px text-flint uppercase m-0 ml-[20px]">
                {numberOfGroundServices} {pluralize(numberOfGroundServices, 'GROUND SERVICE')}
              </p>
            ) : null}
            {numberOfOtherItems > 0 ? (
              <p className="booking-other-items font-hurmegeometric-sans text-13px leading-16px text-flint uppercase ml-[20px] m-0">
              {numberOfOtherItems} {pluralize(numberOfOtherItems, 'OTHER ITEM')}
            </p>
            ) : null}
          </div>
          <div className="bottom-line-right">
            <span className={arrowClassname} onClick={toggleMoreInfo}>
              <span
                className={classnames(
                  'flex justify-center items-center w-full h-full transform-transition transition-ease-in-out duration-300 cursor-pointer',
                  { 'rotate-180': isMoreInfoOpen }
                )}
                onClick={toggleMoreInfo}
              >
                <ArrowDown icon={theme?.colors!['black']} />
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});
