import { isEmpty } from 'lodash-es';
import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { enqueueNotification } from '../ui';
import {
  makeBackendApi,
  IPostCredentialsInfoItem,
  ICredentialsInfo,
  IGetCredentialsInfoResponse
} from 'services/BackendApi';

import {
  GET_REQUEST,
  SET_FILTER_TA_UUID,
  SET_FILTER_COMPANY_UUID,
  SET_FILTER_SENT,
  SET_SORT_DIRECTION,
  SET_SORT_FIELD,
  getRequestAction,
  getSuccessAction,
  getFailureAction,
  POST_REQUEST,
  postSuccessAction,
  postFailureAction,
} from './actions';

import {
  filtersSelector,
  sortFieldSelector,
  sortDirectionSelector,
  modifiedSelector
} from './selectors';

export function* getRequestSaga() {
  const backendApi = makeBackendApi();

  const filters = yield select(filtersSelector);
  const sortField = yield select(sortFieldSelector)
  const sortDirection = yield select(sortDirectionSelector)

  try {
    const res: AxiosResponse<IGetCredentialsInfoResponse> = yield call(
      backendApi.getCredentialsInfo,
      {
        filters: !isEmpty(filters) ? filters : undefined,
        sortField,
        sortDirection
       }
    );

    yield put(getSuccessAction(res.data.data));
  } catch (e) {
    yield put(getFailureAction(e.message));
  }
}

export function* postRequestSaga() {
  const backendApi = makeBackendApi();

  const modified: ICredentialsInfo[] = yield select(modifiedSelector);
  
  const credentials: IPostCredentialsInfoItem[] = modified.map(x => ({
    uuid: x.uuid,
    sent: x.sent,
    date: x.date || undefined
  }));

  try {
    yield call(
      backendApi.postCredentialsInfo,
      { credentials }
    );

    yield put(postSuccessAction());
    yield put(getRequestAction());
    yield put(
      enqueueNotification({
        message: 'TA history has been updated',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(postFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Error updating TA history',
        options: { variant: 'success' },
      })
    );
  }
}

export function* watchCredentialsInfoSaga() {
  yield takeLatest([
    GET_REQUEST,
    SET_FILTER_TA_UUID,
    SET_FILTER_COMPANY_UUID,
    SET_FILTER_SENT,
    SET_SORT_DIRECTION,
    SET_SORT_FIELD
  ], getRequestSaga);
  
  yield takeLatest(
    POST_REQUEST,
    postRequestSaga
  );
}
