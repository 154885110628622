import React from 'react';
import { IHotel, ISeason } from 'services/BackendApi';
import SingleDateInput from 'pureUi/SingleDateInput';
import { addYears } from 'date-fns';
import FluidButton from 'ui/FluidButton';
import * as Inputs from '../Inputs';
import { produce } from 'immer';
import { isNil } from 'lodash-es';
import { formatDate } from 'utils';

export interface iSeasonProps {
  hotel: IHotel;
  season: Partial<ISeason>;
  onUpdate: (field: keyof ISeason, value: any) => void;
  onCta: () => void;
  ctaLabel: string;
}

export const Season = (props: iSeasonProps) => {
  const { season } = props;

  const addNewTimePeriod = () => {
    const updatedSeasonDates = produce(season.seasonDates, draftSeasonDates => {
      draftSeasonDates!.push({
        validFrom: '',
        validTo: '',
        seasonUuid: props.season.uuid,
      });
    });

    props.onUpdate('seasonDates', updatedSeasonDates);
  };

  return (
    <div className="py-3 flex flex-col space-y-3 w-full">
      <fieldset className="border borders-solid border-gray-20 p-4">
        <legend className="text-sm text-gray-100">Season Details</legend>
        <Inputs.Input
          label="Name"
          value={season.name || ''}
          onChange={val => {
            props.onUpdate('name', val);
          }}
        />
      </fieldset>

      <fieldset className="border borders-solid border-gray-20 p-4">
        <legend className="text-sm text-gray-100">Time Periods</legend>

        <div className="flex flex-col space-y-4">
          {(season.seasonDates || []).map((sd, seasonDateIndex) => {
            return (
              <div key={sd.uuid} className="flex space-x-2 items-end">
                <label>
                  {seasonDateIndex === 0 && <span className="text-sm text-gray-100">From</span>}
                  <SingleDateInput
                    value={sd.validFrom !== '' ? new Date(sd.validFrom) : null}
                    onChange={value => {
                      props.onUpdate(
                        'seasonDates',
                        produce(season.seasonDates, draftSeasonDates => {
                          if (isNil(draftSeasonDates)) {
                            draftSeasonDates = [];
                          }
                          // const seasonDateIndex = draftSeasonDates.findIndex(_sd => _sd.uuid === sd.uuid);
                          if (isNil(seasonDateIndex)) {
                            return;
                          }
                          draftSeasonDates[seasonDateIndex].validFrom = formatDate(value);
                        })
                      );
                    }}
                    showYearDropdown
                    enablePastDates
                    minDate={addYears(new Date(), -150)}
                    maxDate={addYears(new Date(), 150)}
                    className="min-w-[200px]"
                  />
                </label>

                <span className="mb-[10px]">-</span>

                <label>
                  {seasonDateIndex === 0 && <span className="text-sm text-gray-100">To</span>}
                  <SingleDateInput
                    value={sd.validTo !== '' ? new Date(sd.validTo) : null}
                    onChange={value => {
                      props.onUpdate(
                        'seasonDates',
                        produce(season.seasonDates, draftSeasonDates => {
                          if (isNil(draftSeasonDates)) {
                            draftSeasonDates = [];
                          }
                          // const seasonDateIndex = draftSeasonDates.findIndex(_sd => _sd.uuid === sd.uuid);
                          if (isNil(seasonDateIndex)) {
                            return;
                          }
                          draftSeasonDates[seasonDateIndex].validTo = formatDate(value);
                        })
                      );
                    }}
                    showYearDropdown
                    enablePastDates
                    minDate={addYears(new Date(), -150)}
                    maxDate={addYears(new Date(), 150)}
                    className="min-w-[200px]"
                  />
                </label>
                <button
                  className="bg-gray-40 rounded-full w-6 h-6 mb-2"
                  onClick={() => {
                    props.onUpdate(
                      'seasonDates',
                      produce(season.seasonDates, draftSeasonDates => {
                        draftSeasonDates!.splice(seasonDateIndex, 1);
                      })
                    );
                  }}
                >
                  X
                </button>
              </div>
            );
          })}
        </div>

        <FluidButton type="secondary" className="w-[200px] mt-3" onClick={addNewTimePeriod}>
          Add Time Period
        </FluidButton>
      </fieldset>

      <FluidButton
        type="primary"
        className="w-[200px] self-end"
        onClick={() => {
          props.onCta();
        }}
      >
        {props.ctaLabel}
      </FluidButton>
    </div>
  );
};
