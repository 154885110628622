import { differenceInYears } from 'date-fns';
import { IGuest } from 'services/BackendApi';
import {
    FormikErrors,
    FormikTouched,
} from 'formik';

export interface IFormValues {
    guests: IGuest[];
}

export const getAge = (guest: IGuest) => {
    if (!guest.dateOfBirth) {
        return;
    }
    const upperDate = guest.arrivalDate ? new Date(guest.arrivalDate) : new Date();
    return differenceInYears(upperDate, new Date(guest.dateOfBirth));
};

export const createEmptyGuest = (): IGuest => ({
    isLeadGuest: false,
    title: '',
    firstName: '',
    lastName: '',
    dateOfBirth: undefined,
    arrivalDate: undefined,
    arrivalFlightNo: '',
    departureDate: undefined,
    departureFlightNo: '',
});

export const makeIsFieldErrorFunction = (
    errors: FormikErrors<IFormValues>,
    touched: FormikTouched<IFormValues>,
    guestIndex: number
  ) => (fieldName: string) => {
    return (
      touched.guests &&
      touched.guests[guestIndex] &&
      touched.guests[guestIndex][fieldName] &&
      errors.guests &&
      errors.guests[guestIndex] &&
      errors.guests[guestIndex][fieldName]
    );
}