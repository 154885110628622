import React, { useMemo } from 'react';
import { ELiveRatesExternalSystem, ILiveRatesListingItem } from 'services/BackendApi/types/LiveRatesInternal';
import { Table as BaseTable } from 'ui/Table';

import { useDispatch, useSelector } from 'react-redux';

import { ITravelAgent } from 'services/BackendApi';
import { formatDateDisplay } from 'utils';
// import { HidingTooltip } from 'ui/Tooltip';

import * as Actions from 'store/modules/liveRates/actions';
import * as Selectors from 'store/modules/liveRates/selectors';
import { ILiveRatesDomain } from 'store/modules/liveRates/model';
import { ESortOrder } from 'store/common/types';
import { CircleIconButton } from 'ui/CircleIconButton';
import { Link } from 'ui/Link';
import { HidingTooltip } from 'ui/Tooltip';
import { settingsBrown as settingsIcon } from 'ui/Icons';

import { getCurrentUserType } from 'store/modules/auth';
import { EUserType } from 'services/BackendApi';

const externalSystemNameMap = {
  [ELiveRatesExternalSystem.ALLOTZ]: 'Allotz',
  [ELiveRatesExternalSystem.DERBYSOFT]: 'Derbysoft',
  [ELiveRatesExternalSystem.ILLUSIONS]: 'Illusions',
};

export interface iTableProps {
  items: ILiveRatesListingItem[];
  onDelete: (x) => void;
  loading: boolean;
  className?: string;
}

const columns = [
  {
    id: 'stay',
    label: 'stay',
    width: '100px',
    fieldToSortBy: 'name',
  },
  {
    id: 'externalSystem',
    label: 'External Supplier',
    width: '100px',
    fieldToSortBy: 'externalSystem',
  },
  {
    id: 'externalHotelId',
    label: 'External ID.',
    width: '100px',
    fieldToSortBy: 'externalHotelId',
  },
  {
    id: 'internalRoomsCount',
    label: 'Internal Rooms',
    width: '50px',
    className: 'text-center',
  },
  {
    id: 'externalRoomsCount',
    label: 'External Rooms',
    width: '50px',
    className: 'text-center hidden lg:table-cell',
  },
  {
    id: 'mappedRoomsCount',
    label: 'Mapped Rooms',
    width: '50px',
    className: 'text-center hidden lg:table-cell',
  },
  {
    id: 'actions',
    label: '',
    width: '100px',
  },
];

export const Table = (props: iTableProps) => {
  const { items, onDelete, loading, className } = props;
  const userType = useSelector(getCurrentUserType) as EUserType;

  // const canEdit = admin || rl;

  const canEdit = true;
  const canDelete = [EUserType.ADMIN, EUserType.RL].includes(userType);

  const rows = useMemo(() => {
    return props.items.map(x => {
      const actions = (
        <div className="actions flex items-center justify-end relative">
          <div className="sep w-4px h-30px rounded-full bg-gray-10"></div>
          {canEdit && (
            <HidingTooltip
              position="bottom"
              renderTooltipContent={() => <span className="text-sm ml-2 px-1 py-2px">Settings</span>}
            >
              <Link to={`/live-rates/${x.uuid}/settings`}>
                <CircleIconButton
                  type="secondary"
                  className="settings-action ml-10px"
                  iconClass="inline relative left-0 top-0 w-12px h-12px text-brown-prime"
                  iconSrc={settingsIcon}
                />
              </Link>
            </HidingTooltip>
          )}
          {canDelete && (
            <HidingTooltip
              position="bottom"
              renderTooltipContent={() => <span className="text-sm px-1 py-2px">Delete</span>}
            >
              <CircleIconButton
                onClick={() => {
                  onDelete(x);
                }}
                type="secondary"
                className="delete ml-10px"
                iconClass="far fa-trash-alt block text-brown-80 relative left-0 -top-1px w-12px h-12px"
              />
            </HidingTooltip>
          )}
        </div>
      );

      return {
        id: x.uuid,
        cells: [
          {
            id: 'stay',
            value: x.name,
            title: x.name,
            className: `cell-name pl-4`,
          },
          {
            id: 'externalSystem',
            value: externalSystemNameMap[x.externalSystem],
            className: `cell-external-system pl-4`,
          },
          {
            id: 'externalHotelId',
            value: x.externalHotelId,
            className: 'cell-hotel-id pl-4',
          },
          {
            id: 'internalRoomsCount',
            value: x.internalRoomsCount,
            className: 'cell-internal-rooms-count text-center',
          },
          {
            id: 'externalRoomsCount',
            value: x.externalRoomsCount,
            className: 'cell-external-rooms-count text-center hidden lg:table-cell',
          },
          {
            id: 'mappedRoomsCount',
            value: x.mappedRoomsCount,
            className: 'cell-mapped-rooms-count text-center hidden lg:table-cell',
          },
          {
            id: 'actions',
            value: actions,
          },
        ],
      };
    });
  }, [items]);

  const sortBy = useSelector(Selectors.sortBySelector);
  const sortOrder = useSelector(Selectors.sortOrderSelector);

  const dispatch = useDispatch();

  return (
    <BaseTable
      columns={columns}
      rows={rows}
      sortBy={sortBy}
      sortOrder={sortOrder as ESortOrder}
      onSort={(
        newSortBy: ILiveRatesDomain['listingPage']['filter']['order_by'],
        newSortOrder: ILiveRatesDomain['listingPage']['filter']['order_type']
      ) => {
        dispatch(Actions.listActions.setSortData(newSortBy, newSortOrder));
      }}
      messageWhenEmpty="No results"
      loading={props.loading}
    />
  );
};
