import React, { useMemo } from 'react';

import PiggyBankIcon from 'ui/Icons/piggyBank.gray.component.svg';
import { HidingTooltip } from 'ui/Tooltip';

export enum ETransactionSymbolType {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NONE = 'NONE',
}

interface ITransactionSymbolProps {
  transactionType: ETransactionSymbolType;
}

export const TransactionSymbol: React.FC<ITransactionSymbolProps> = React.memo(({ transactionType }) => {
  const Classes = useMemo(
    () => ({
      transaction: 'block leading-17px text-13px font-bold text-center h-19px w-19px rounded cursor-pointer',
      positive: 'bg-teal-20',
      negative: 'bg-red-20',
    }),
    []
  );

  if (transactionType === ETransactionSymbolType.POSITIVE) {
    const renderSignTooltip = () => <span className="block capitalize px-1 py-5px text-13px">Positive</span>;

    return (
      <HidingTooltip renderTooltipContent={renderSignTooltip} position="bottom">
        <span className={`${Classes.transaction} ${Classes.positive}`}>+</span>
      </HidingTooltip>
    );
  }

  if (transactionType === ETransactionSymbolType.NEGATIVE) {
    const renderSignTooltip = () => <span className="block capitalize px-1 py-5px text-13px">Negative</span>;

    return (
      <HidingTooltip renderTooltipContent={renderSignTooltip} position="bottom">
        <span className={`${Classes.transaction} ${Classes.negative}`}>-</span>
      </HidingTooltip>
    );
  }

  return null;
});

interface ITransactionSymbolColumnProps {
  isDepositRow: boolean;
  transactionType: ETransactionSymbolType;
  className?: string;
}

export const TransactionSymbolColumn: React.FC<ITransactionSymbolColumnProps> = React.memo(
  ({ transactionType, isDepositRow, className }) => {
    return (
      <td className={`${className} relative flex flex-col h-56px items-center justify-center px-2`}>
        <div className="flex flex-col">
          <TransactionSymbol transactionType={transactionType} />
          {isDepositRow && (
            <span className="w-28px h-19px mt-1">
              <PiggyBankIcon />
            </span>
          )}
        </div>
      </td>
    );
  }
);
