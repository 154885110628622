import { ITravelAgent, ENetworkRequestStatus, ICompany } from 'services/BackendApi';

export interface ITravelAgentDomain {
  travelAgent: ITravelAgent | null;
  taCompanies: {
    taCompaniesModalVisible: boolean;
    companyCountry: string | null;
    companyName: string;
    filteredCompanies: ICompany[] | null;
    selectedCompany: string | null;
  },
  request: {
    get: ENetworkRequestStatus;
    create: ENetworkRequestStatus;
    update: ENetworkRequestStatus;
  };
}

export const initialState: ITravelAgentDomain = {
  travelAgent: null,
  taCompanies: {
    taCompaniesModalVisible: false,
    companyCountry: null,
    companyName: '',
    filteredCompanies: null,
    selectedCompany: null,
  },
  request: {
    get: ENetworkRequestStatus.IDLE,
    create: ENetworkRequestStatus.IDLE,
    update: ENetworkRequestStatus.IDLE,
  },
};
