import { ESortOrder, IPagination } from "store/common/types";

export interface IProposalSelection {
	uuid: string;
	name: string;
	bookingUuids: string[];
	numberOfHotels: number;
	travelAgentTitle?: string;
	travelAgentUuid: string;
	travelAgentFirstName: string;
	travelAgentLastName: string;
	guestTitle?: string;
	guestFirstName: string;
	guestLastName: string;
	guestFullName: string;
	earliestBookingArrival: Date;
	latestBookingDeparture: Date;
	createdAt: string;
	updatedAt: string;
	taMarginIncluded?: boolean;
}

export enum EProposalSelectionSortableKey {
	NAME = 'name',
	TRAVEL_DATES = 'travelDates',
	GUEST_NAME = 'guestName',
	CREATED_AT = 'createdAt',
	UPDATED_AT = 'updatedAt',
	TRAVEL_AGENT_NAME = 'travelAgentName',
}

export interface IProposalSelectionListSort {
	key: EProposalSelectionSortableKey;
	order: ESortOrder;
}

export interface IProposalSelectionListFilter {
	name?: string;
	travelAgentUuid?: string;
	clientName?: string;
}

export interface IProposalSelectionListRequest {
	pagination?: IPagination;
	sort?: IProposalSelectionListSort;
	filter?: IProposalSelectionListFilter;
}

export interface IProposalSelectionListResponse {
	pagination: IPagination;
	count: number;
	proposalSelections: IProposalSelection[];
}

