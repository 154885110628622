export const isStringNilOrBlank = (stringVal: string | null | undefined) => {
  return stringVal === null || stringVal === undefined || stringVal === '';
};

export const isNil = (val: any | null | undefined): boolean => {
  return val === null || val === undefined;
};

// https://github.com/sindresorhus/escape-string-regexp
export const  escapeStringRegexp = (s: string) => {
	if (typeof s !== 'string') {
		throw new TypeError('Expected a string');
	}

	// Escape characters with special meaning either inside or outside character sets.
	// Use a simple backslash escape when it’s always valid, and a `\xnn` escape when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
	return s
		.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
		.replace(/-/g, '\\x2d');
}

export const pluralize = (quantity, word) => {
	return quantity === 1 ? word : `${word}s`;
}
