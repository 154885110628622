import { CredentialsInfoSortDirection, CredentialsInfoSortField, ICredentialsInfo } from 'services/BackendApi';

export const GET_REQUEST = 'credentialsInfo/GET_REQUEST';
export const GET_SUCCESS = 'credentialsInfo/GET_SUCCESS';
export const GET_FAILURE = 'credentialsInfo/GET_FAILURE';

export const SET_FILTER_TA_UUID = 'credentialsInfo/SET_FILTER_TA_UUID';
export const SET_FILTER_COMPANY_UUID = 'credentialsInfo/SET_FILTER_COMPANY_UUID';
export const SET_FILTER_SENT = 'credentialsInfo/SET_FILTER_SENT';

export const SET_SORT_FIELD = 'credentialsInfo/SET_SORT_FIELD';
export const SET_SORT_DIRECTION = 'credentialsInfo/SET_SORT_DIRECTION';

export const POST_REQUEST = 'credentialsInfo/POST_REQUEST';
export const POST_SUCCESS = 'credentialsInfo/POST_SUCCESS';
export const POST_FAILURE = 'credentialsInfo/POST_FAILURE';

export const SET_SENT = 'credentialsInfo/SET_SENT';

export type GetRequestAction = ReturnType<typeof getRequestAction>;
export const getRequestAction = () => ({
  type: GET_REQUEST as typeof GET_REQUEST
});

export type GetSuccessAction = ReturnType<typeof getSuccessAction>;
export const getSuccessAction = (data: ICredentialsInfo[]) => ({
  type: GET_SUCCESS as typeof GET_SUCCESS,
  data
});

export type GetFailureAction = ReturnType<typeof getFailureAction>;
export const getFailureAction = (error: string) => ({
  type: GET_FAILURE as typeof GET_FAILURE,
  error,
});

export type SetFilterTaUuidAction = ReturnType<typeof setFilterTaUuidAction>;
export const setFilterTaUuidAction = (value?: string) => ({
  type: SET_FILTER_TA_UUID as typeof SET_FILTER_TA_UUID,
  value
});

export type SetFilterCompanyUuidAction = ReturnType<typeof setFilterCompanyUuidAction>;
export const setFilterCompanyUuidAction = (value?: string) => ({
  type: SET_FILTER_COMPANY_UUID as typeof SET_FILTER_COMPANY_UUID,
  value
});

export type SetFilterSentAction = ReturnType<typeof setFilterSentAction>;
export const setFilterSentAction = (value?: boolean) => ({
  type: SET_FILTER_SENT as typeof SET_FILTER_SENT,
  value
});

export type SetSortFieldAction = ReturnType<typeof setSortFieldAction>;
export const setSortFieldAction = (value: CredentialsInfoSortField) => ({
  type: SET_SORT_FIELD as typeof SET_SORT_FIELD,
  value
});

export type SetSortDirectionAction = ReturnType<typeof setSortDirectionAction>;
export const setSortDirectionAction = (value: CredentialsInfoSortDirection) => ({
  type: SET_SORT_DIRECTION as typeof SET_SORT_DIRECTION,
  value
});

export type PostRequestAction = ReturnType<typeof postRequestAction>;
export const postRequestAction = () => ({
  type: POST_REQUEST as typeof POST_REQUEST
});

export type PostSuccessAction = ReturnType<typeof postSuccessAction>;
export const postSuccessAction = () => ({
  type: POST_SUCCESS as typeof POST_SUCCESS,
});

export type PostFailureAction = ReturnType<typeof postFailureAction>;
export const postFailureAction = (error: string) => ({
  type: POST_FAILURE as typeof POST_FAILURE,
  error,
});

export type SetSentAction = ReturnType<typeof setSentAction>;
export const setSentAction = (uuid: string, value?: string) => ({
  type: SET_SENT as typeof SET_SENT,
  uuid,
  value
});

export type Any =
  | GetRequestAction
  | GetSuccessAction
  | GetFailureAction
  | SetFilterTaUuidAction
  | SetFilterCompanyUuidAction
  | SetFilterSentAction
  | SetSortFieldAction
  | SetSortDirectionAction
  | PostRequestAction
  | PostSuccessAction
  | PostFailureAction
  | SetSentAction
  ;
