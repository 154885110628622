import React, { useCallback } from 'react';
import CircleCloseIcon from 'ui/Icons/circleClose.component.svg';

interface BookingRefInputProps {
  bookingRef: string;
  index?: number;
  onChange: (newValue: string) => void;
  onCrossButtonClick: () => void;
}

export const BookingRefInput: React.FC<BookingRefInputProps> = ({
  bookingRef,
  index = 0,
  onChange,
  onCrossButtonClick,
}) => {
  const isCrossButtonVisible = index > 0 || (index === 0 && bookingRef.length >= 1);
  const handleChange = useCallback(e => onChange(e.target.value), [onChange]);
  const inputClassName = `
    booking-ref-input w-190px font-pt-sans text-black text-15px py-2 pl-2 pr-6 border border-solid
    border-gray-40 focus:outline-teal-100
  `;

  return (
    <div className="relative">
      <input onChange={handleChange} value={bookingRef} className={inputClassName} type="text" />
      {isCrossButtonVisible && (
        <span className="block absolute top-11px right-6px">
          <CircleCloseIcon
            onClick={onCrossButtonClick}
            className="block cursor-pointer fill-brown-80 hover:fill-brown-120"
          />
        </span>
      )}
    </div>
  );
};
