import { TCurrencyCode } from 'interfaces';
//import {IAccommodation} from 'services/BackendApi/types/OffersSearchResponse';

export enum EBlockType {
  Accommodations = 'Accommodations',
  Addons = 'Addons',
  GroundServices = 'Ground Services',
  Transfers = 'Transfers',
}

export enum EBlockItemType {
  MealPlan = 'Meal Plan',
}

export enum EMarkupType {
  PERCENTAGE = 'percentage',
  FLAT = 'flat',
}

export enum EMealPlan {
  AllInclusive = 'AI',
  BreakfastBoard = 'BB',
  FullBoard = 'FB',
  HalfBoard = 'HB',
}

export enum EProductCategory {
  PerPersonPerNight = 'perPersonPerNight',
  PerAccommodationProduct = 'perAccommodationProduct',
  PerBooking = 'perBooking',
  PerNight = 'perNight',
  PerPerson = 'perPerson',
}

export interface IBookingModel {
  uuid: string;
  checkInDate: string;
  checkOutDate: string;
  numAdults: number;
  agesOfAllChildren: number[];
  guestTitle: string;
  guestFirstName: string;
  guestLastName: string;
  guestEmail: null;
  isRepeatGuest: boolean;
  specialRequests: any[];
  comments: null;
  taMarginType: string;
  taMarginAmount: string;
  status: string;
  flightArrivalDate: null;
  flightArrivalNumber: null;
  flightDepartureDate: null;
  flightDepartureNumber: null;
  travelAgentUserUuid: string;
  proposalUuid: string | null;
  breakdown: IBookingBreakdown;
  bookingHash: string;
  hotelName: string;
  hotelUuid: string;
  overrideTotal: null;
  bookingComments: null;
  stateHistory: StateHistory[];
  humanReadableId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  clientUuid: null;
  internalComments?: string[] | null;
  travelAgent: ITravelAgent;
}

export interface ITravelAgent {
  uuid: string;
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  phoneNumber: string;
  mobileNumber: string;
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  countryCode: string;
  status: string;
  isExistingPartner: boolean;
  agreeToTerms: boolean;
  type: string;
  receiveEmailAlerts: boolean;
  companyUuid: string;
  companySignupInfo: {
    name: string;
    countryCode: string;
  };
  createdAt: string;
  updatedAt: string;
}

export interface IBookingBreakdown {
  canBeBooked: boolean;
  mustStop: boolean;
  errors: any[];
  currency: TCurrencyCode;
  bookingHash: string;
  potentialBooking: IPotentialBooking;
  availableProductSets: IAvailableProductSets;
  textOnlyOffersPerLodging: Array<any[]>;
  appliedOfferNames: string[];
  uploads: IUpload[];
  displayTotals: IDisplayTotals;
  totals: ITotals;
  minimumNightsReview: boolean;
  hotel: IBookingBreakdownHotel;
  requestedBuild: IRequestedBuild;
  internalRateInformation?: IInternalRateInformation;
}

export interface IAvailableProductSets {
  Accommodation: IAvailableProduct[];
  Supplement: IFine[];
  Transfer: IAvailableProductSetsTransfer[];
  'Ground Service': IGroundService[];
  Fine: IFine[];
}

export interface ISubProducts {
  'Meal Plan'?: IMealPlan[] | IProductUuidReference[];
  Supplement?: ISupplement[] | IProductUuidReference[];
}

export interface IAvailableProduct {
  products: IProduct[];
  isOnRequestOrPartiallyOnRequest: boolean;
  total: string | null;
  totalBeforeDiscount: string | null;
  breakdown: IAccommodation[];
  mandatory: boolean;
  selected: boolean;
  availableSubProductSets?: ISubProducts;
  occupancyResult?: IOccupancyResult;
}

export interface IMealPlan {
  products: IProduct[];
  isOnRequestOrPartiallyOnRequest: boolean;
  total: string | null;
  totalBeforeDiscount: string | null;
  breakdown: IBreakdown[];
  mandatory: boolean;
  selected: boolean;
  internalRateInformation?: IInternalRateInformation;
}

export interface IProductUuidReference {
  uuid: string;
}

export interface IInternalRateInformation {
  total: string | null;
  totalBeforeDiscount: string | null;

  totalCents?: number;
  totalBeforeDiscountCents?: number;
  rate?: string;
  markupType?: EMarkupType;
  markupAmount?: string;
  totalForPricedItemsCents?: number;
  totalBeforeDiscountForPricedItemsCents?: number;
  totalForPricedItems?: string;
  totalBeforeDiscountForPricedItems?: string;
}

export interface IBreakdown {
  title: string;
  product: IProduct;
  rateUuid: string;
  accommodationProductRateUuid: string | null;
  rateType: ERateType;
  quantity: number;
  dates: string[];
  offers: any[];
  singlePrice: string | null;
  isOnRequest: boolean;
  totalCents: number | null;
  total: string | null;
  totalBeforeDiscountCents: number | null;
  totalBeforeDiscount: string | null;
  paymentTerms?: string | null;
  cancellationPolicy?: string | null;
  internalRateInformation?: IInternalRateInformation;
}

export interface IOfferApplication {
  datesCovered: string[];
  datesApplied: string[];
  discountPercentage?: number;
  quantity: number;
  offer: IOffer;
}

export interface IOffer {
  uuid: string;
  name: string;
  order: number;
  termsAndConditions: string;
  furtherInformation: string | null;
}

export interface IProduct {
  meta: any;
  uuid: string;
  name: string;
  type: string;
  category: EProductCategory;
  options: IProductOptions;
  ownerType: string | null;
  ownerUuid: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface IMealPlanMeta {
  categoryType: EMealPlan;
}

export interface IProductOptions {
  ages?: IAgeRange[];
  isOneWay?: boolean;
  capacity?: number;
  isGenericProduct?: boolean;
  agesDefinedBy?: string;
  genericIdentifier?: string;
  occupancy?: IOccupancy;
}

export interface IAgeRange {
  name: string;
  ageFrom: number;
  ageTo: number;
}

export interface ISupplement {
  products: IProduct[];
  isOnRequestOrPartiallyOnRequest: boolean;
  total: string | null;
  totalBeforeDiscount: string | null;
  breakdown: IBreakdown[];
  mandatory: boolean;
  selected: boolean;
  internalRateInformation?: IInternalRateInformation;
}

export interface IAccommodation {
  title: string;
  product: IProduct;
  rateUuid: string;
  accommodationProductRateUuid: string | null;
  rateType: ERateType;
  quantity: number;
  dates: string[];
  offers: IOfferApplication[];
  singlePrice: string | null;
  isOnRequest: boolean;
  totalCents: number | null;
  total: string | null;
  totalBeforeDiscountCents: number | null;
  totalBeforeDiscount: string | null;
  subProducts?: IBreakdownSubProducts;
  paymentTerms?: string | null;
  cancellationPolicy?: string | null;
  minimumNights?: number | null;
  internalRateInformation?: IInternalRateInformation;
}

export interface IOccupancy {
  standardOccupancy: number;
  maximumPeople: number;
  limits: ILimit[];
}

export interface ILimit {
  name: string;
  maximum: number;
  minimum: number;
}

export enum ERateType {
  SPR = 'SPR',
  SPAR = 'SPAR',
}

export interface IBreakdownSubProducts {
  'Meal Plan': IBreakdown[];
  Supplement: IBreakdown[];
}

export interface IOccupancyResult {
  permitted: boolean;
  requestedAgeGroups: IRequestedAgeGroups;
  ageRanges: IAgeRange[];
  occupancy: IOccupancy;
  remainingStandardOccupancy: number;
  extraPersonSupplementsRequired: IExtraPersonSupplementsRequired;
}

export interface IExtraPersonSupplementsRequired {
  [key: string]: number;
}

export interface IRequestedAgeGroups {
  [key: string]: number;
}

export interface IFine {
  products: IProduct[];
  isOnRequestOrPartiallyOnRequest: boolean;
  total: string | null;
  totalBeforeDiscount: string | null;
  breakdown: IBreakdown[];
  mandatory: boolean;
  selected: boolean;
}

export interface IGroundService {
  products: IProduct[];
  isOnRequestOrPartiallyOnRequest: boolean;
  total: string | null;
  totalBeforeDiscount: string | null;
  breakdown: IGroundServiceBreakdown[];
  mandatory: boolean;
  selected: boolean;
}

export interface IGroundServiceBreakdown {
  title?: string;
  product: IProduct;
  rateUuid: string;
  accommodationProductRateUuid?: string | null;
  rateType: ERateType;
  quantity: number;
  dates: string[];
  offers: any[];
  singlePrice: string | null;
  isOnRequest?: boolean;
  totalCents: number | null;
  total?: string | null;
  totalBeforeDiscountCents: number | null;
  totalBeforeDiscount: string | null;
  paymentTerms?: string | null;
  cancellationPolicy: string | null;
  internalRateInformation?: IInternalRateInformation;
}

export interface IAvailableProductSetsTransfer {
  products: IProduct[];
  isOnRequestOrPartiallyOnRequest: boolean;
  total: string | null;
  totalBeforeDiscount: string | null;
  breakdown: ITransferBreakdown[];
  mandatory: boolean;
  selected: boolean;
  meta?: TransferMeta;
}

export interface ITransferBreakdown {
  title: string;
  product: IProduct;
  rateUuid: string;
  accommodationProductRateUuid: string | null;
  rateType: ERateType;
  quantity: number;
  dates: string[];
  offers: IOfferApplication[];
  singlePrice: string | null;
  isOnRequest: boolean;
  totalCents: number | null;
  total: string | null;
  totalBeforeDiscountCents: number | null;
  totalBeforeDiscount: string | null;
  paymentTerms: string | null;
  cancellationPolicy: string | null;
  internalRateInformation?: IInternalRateInformation;
}

export interface TransferMeta {
  direction: string;
}

export interface IDisplayTotals {
  blocks: IDisplayTotalBlock[];
  appliedOfferNames: string[];
  totals: ITotals;
}

export interface IDisplayTotalBlock {
  header: string;
  items: IBlockItem[];
  blockType: EBlockType;
}

export interface IBlockItem {
  title: string;
  labels: string[];
  isOnRequestOrPartiallyOnRequest: boolean;
  total: string | null;
  totalBeforeDiscount: string | null;
  offers: string[];
}

export interface ITotals {
  oneOrMoreItemsOnRequest: boolean;
  totalForPricedItemsCents: number;
  totalBeforeDiscountForPricedItemsCents: number;
  totalForPricedItems: string;
  totalBeforeDiscountForPricedItems: string;
  total: string | null;
  totalBeforeDiscount: string | null;
  internalRateInformation?: IInternalRateInformation;
}

export interface IBookingBreakdownHotel {
  uuid: string;
  name: string;
  description: string | null;
  email: string | null;
  phoneNumber: string | null;
  address: string | null;
  countryCode: string;
  region: string;
  starRating: string;
  defaultCurrency: TCurrencyCode;
  amenities: string[] | null;
  highlights: string[] | null;
  additionalInfo: string | null;
  policiesAndRestrictions: string[] | null;
  suitableForHoneymooners: boolean;
  preferred: boolean;
  overview: string[] | null;
  filters: string[] | string;
  inLoveWith: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface IPotentialBooking {
  Accommodation: IAccommodation[];
  Supplement: IBookingProduct[];
  Transfer: IBookingProduct[];
  'Ground Service': IBookingProduct[];
  Fine: IBookingProduct[];
}

export interface IBookingProduct {
  title: string;
  product: IProduct;
  rateUuid: string;
  accommodationProductRateUuid: string | null;
  rateType: ERateType;
  quantity: number;
  dates: string[];
  offers: IOfferApplication[];
  singlePrice: string | null;
  isOnRequest: boolean;
  totalCents: number | null;
  total: string | null;
  totalBeforeDiscountCents: number | null;
  totalBeforeDiscount: string | null;
  paymentTerms: string | null;
  cancellationPolicy: string | null;
  internalRateInformation?: IInternalRateInformation;
}

export interface IRequestedBuild {
  hotelUuid: string;
  startDate: string;
  endDate: string;
  Accommodation?: IRequestedBuildAccommodation[];
  Transfer?: Transfer[];
  'Ground Service'?: any[];
  Supplement?: any[];
  Fine?: any[];
  customItems?: any[];
  guestAges: IGuestAges;
}

export interface IRequestedBuildAccommodation {
  uuid: string;
  startDate: string;
  endDate: string;
  repeatCustomer: boolean;
  guestAges: IGuestAges;
  subProducts: ISubProducts;
  // These properties are fun.
  honeymoon?: boolean;
  anniversary?: boolean;
  wedding?: boolean;
  birthday?: boolean;
}

export interface IGuestAges {
  numberOfAdults: number;
  agesOfAllChildren?: number[];
}

export interface Transfer {
  uuid: string;
}

export interface IUpload {
  uuid: string;
  filename: string;
  displayName: string;
  url: string;
  size: string;
  tag: string;
  ownerType: string | null;
  ownerUuid: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface IFormattedHold {
  productName: string;
  expiresOn: string;
}

export interface StateHistory {
  status: string;
  timestamp: string;
}
