import React from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import { formatDateDisplayFourDigitYear } from 'utils';
import { Multiselect, IMultiselectProps } from 'ui/Multiselect';
import FluidButton from 'ui/FluidButton';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import { GuestAgesPicker } from 'ui/GuestAgesPicker/GuestAgesPicker';
import { format } from 'date-fns';
import DateRangeInput from 'pureUi/DateRangeInput';
import styled from 'styled-components';
import classNames from 'classnames';
import { ErrorBar } from 'ui/NetworkStatusBar';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import AirplaneIcon from 'ui/Icons/airplane.component.svg';
import CalendarIcon from 'ui/Icons/calendar-today.component.svg';
import { SvgIcon } from 'ui/SvgIcon';
import { isNil } from 'lodash-es';
import {
  AncillaryProductWithRatesWithUserData,
  AncillaryRatesProductsSearchItem,
  LocationDirectionWithCountry,
  LocationFromSearchLocationsWithType,
  SelectedAncillarySearchResult,
} from 'services/BookingManagerApi/types/AncillaryService';
import { theme } from '../../../tailwind.config';
import { AddAncillarySearchResultsTable } from 'ui/AddAncillarySharedComponents/AddAncillarySearchResultsTable';
import { AddAncillaryRightHandPanel } from 'ui/AddAncillarySharedComponents/AddAncillaryRightHandPanel';
import { IGuestAges } from 'services/BackendApi';

export interface IAddGroundServiceModalProps {
  isOpen: boolean;
  currencySymbol: string;
  onClose: () => void;
  onSearchButtonClick: () => void;

  airport: LocationDirectionWithCountry | null;
  handleSelectAirport: (pickupFromLocationCompositeId: string) => void;
  locationOptions: IMultiselectProps['options'];

  date: string | null;
  setDate: (date: string) => void;

  typesOptions: IMultiselectProps['options'];

  selectedFilterIds: string[];
  setSelectedFilterIds: (filterIds: string[]) => void;
  selectedGroundServiceOrSupplier: {
    id: string;
    type: 'ground_service' | 'supplier';
  } | null;

  setSelectedGroundServiceOrSupplier: (
    arg: {
      id: string;
      type: 'ground_service' | 'supplier';
    } | null
  ) => void;

  groundServiceOrSupplierSelectOnLookup: IMultiselectProps['onLookupSearch'];

  guestAges: IGuestAges;
  setGuestAges: (numberOfAdults: number, agesOfAllChildren?: number[]) => void;

  searchResults?: AncillaryRatesProductsSearchItem[];
  selectedSearchResult: SelectedAncillarySearchResult | null;
  setSelectedSearchResult: (selectedSearchResult: SelectedAncillarySearchResult | null) => void;
  selectedGroundServiceProduct: AncillaryProductWithRatesWithUserData | null; // the full details from the api for the search result

  updateSelectedGroundServiceProductWithUserData: (
    data: Partial<AncillaryProductWithRatesWithUserData['userData']>
  ) => void;

  handleAddGroundService: () => void;

  errorIds: string[];

  searchInstanceVars: {
    date?: string | null;
    guestAges?: IGuestAges;
    airport?: LocationDirectionWithCountry | null;
  };

  pickupTime: string;
  setPickupTime: (time: string) => void;

  getSearchResultsNetwork: ENetworkRequestStatus;
  getTypesResultsNetworkStatus: ENetworkRequestStatus;
  getGroundServiceProductNetwork: ENetworkRequestStatus;
  getLocationsNetwork: ENetworkRequestStatus;
}

// @ts-ignore Styled component errors
const StyledDateRangeInput = styled(DateRangeInput)`
  .pseudoSelect {
    background: ${theme.colors['ivory']};
    height: 37px;
    border-color: ${theme.colors['gray-40']};
  }
  .displayString {
    text-transform: none;
    font-family: 'PT Sans', sans-serif;
  }
`;

/**
 * Renders the add ground service modal
 * This component doesn't use any state internally, it's all passed in as props
 * Which means you probably don't want to use this component directly, and you're actually wanting
 * to use the AddGroundServiceModalContainer component
 */

export const AddGroundServiceModal = (props: IAddGroundServiceModalProps) => {
  const { guestAges, setGuestAges, getSearchResultsNetwork = ENetworkRequestStatus.IDLE } = props;

  if (!props.isOpen) {
    return null;
  }

  // let selectedDates: string[] = [];

  const showRightPanel = !isNil(props.selectedSearchResult);

  const searchInstanceVarsPickupFromLocationName = props.locationOptions.find(
    location => location.value === props.searchInstanceVars?.airport?.compositeId
  )?.label;

  return (
    <GeneralModal
      onClose={props.onClose}
      modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-clip"
      shouldCloseByClickingOutside={false}
    >
      {/* the modal title and search fields */}
      <h2 className="m-0 p-0 font-noe-display text-21px leading-27px font-normal mb-25px">Add Ground Service</h2>

      {/* this is an extremely specific height for a reason */}
      <div className="all-panels flex space-x-[30px] overflow-x-hidden flex-grow h-[calc(100%-65px)]">
        <div
          className={classNames('left-panel flex flex-col flex-shrink space-y-25px', {
            'w-[calc(100%-321px-35px)]': showRightPanel,
          })}
        >
          <div className="search-and-filters">
            <div
              className={classNames('filters-and-search space-y-15px font-pt-sans', {
                'o:opacity-50 pointer-events-none': getSearchResultsNetwork === ENetworkRequestStatus.PENDING,
              })}
            >
              <div className="flex space-x-20px">
                {/* Pickup From */}
                <label
                  className={classNames({
                    'w-[180px]': showRightPanel,
                    'w-full': !showRightPanel,
                  })}
                >
                  <span className="font-bold text-black mb-5px block">Airport *</span>
                  <Multiselect
                    options={props.locationOptions}
                    disabled={props.getLocationsNetwork === ENetworkRequestStatus.PENDING}
                    className={classNames('bg-ivory', {
                      'o:opacity-50 pointer-events-none': props.getLocationsNetwork === ENetworkRequestStatus.PENDING,
                    })}
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-10"
                    onUpdate={values => {
                      props.handleSelectAirport(values[0]);
                    }}
                    selectedValues={props.airport ? [props.airport.compositeId] : []}
                    isEnableFuzzySearch
                    isCloseOnSelect
                    isSingleSelectMode
                    hideCheckboxes
                    hideDropdownArrow
                    hideNonMatchingOptions={true}
                  />
                </label>

                {/* Date */}
                <label className="w-[190px]">
                  <span className="font-bold text-black mb-5px block">Date *</span>
                  <DatePickerStateProvider
                    isSingleDateSelection={true}
                    defaultSelectedDates={props.date ? [new Date(props.date).toISOString()] : []}
                    onDateChange={dates => {
                      props.setDate(format(new Date(dates[0]), 'yyyy-MM-dd'));
                    }}
                    placeholder="Select date"
                    render={(params: IDatePickerSateParams) => {
                      return (
                        <StyledDateRangeInput
                          displayString={params.displayString}
                          currentDate={params.datePickerCurrentDate}
                          selectedDates={[...params.selectedDates]}
                          onDayClick={params.handleDayClick}
                          onDayMouseOver={params.handleDateMouseOver}
                          showDatePicker={params.showDatePicker}
                          onNextClick={params.incrementDate}
                          onPrevClick={params.decrementDate}
                          onMouseDown={params.toggleDatePicker}
                          onClickOutside={params.hideDatePicker}
                          showTotalNights={false}
                          enablePastDates
                          noPortal
                        />
                      );
                    }}
                  />
                </label>

                {/* People */}
                <label className="w-[260px]">
                  <span className="font-bold text-black mb-5px block">People *</span>
                  <GuestAgesPicker
                    numberOfAdults={guestAges.numberOfAdults}
                    agesOfAllChildren={guestAges.agesOfAllChildren}
                    onUpdate={(numberOfAdults, agesOfAllChildren) => {
                      setGuestAges(numberOfAdults, agesOfAllChildren || []);
                    }}
                  />
                </label>
              </div>

              <div className="flex space-x-20px">
                {/* Type */}
                <label className="w-[260px]">
                  <span className="font-bold text-black mb-5px block">Type</span>
                  <Multiselect
                    options={props.typesOptions}
                    className={classNames('bg-ivory', {
                      'o:opacity-50 pointer-events-none':
                        props.getTypesResultsNetworkStatus === ENetworkRequestStatus.PENDING,
                    })}
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-10"
                    disabled={props.getTypesResultsNetworkStatus === ENetworkRequestStatus.PENDING}
                    onUpdate={values => {
                      props.setSelectedFilterIds(values);
                    }}
                    staticOptions={[
                      {
                        value: '-1',
                        label: 'All',
                      },
                    ]}
                    selectedValues={isNil(props.selectedFilterIds) ? [] : props.selectedFilterIds}
                    hideDropdownArrow
                  />
                </label>

                {/* Supplier or Service Name */}
                <label
                  className={classNames('w-[750px]', {
                    'opacity-30': isNil(props.date),
                  })}
                >
                  <span className="font-bold text-black mb-5px block">Supplier or Service Name</span>
                  <Multiselect
                    options={[]}
                    disabled={isNil(props.date)}
                    className="bg-ivory"
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-10"
                    selectedValues={
                      isNil(props.selectedGroundServiceOrSupplier)
                        ? []
                        : [`${props.selectedGroundServiceOrSupplier.type}-${props.selectedGroundServiceOrSupplier.id}`]
                    }
                    onUpdate={values => {
                      if (values.length === 0) {
                        props.setSelectedGroundServiceOrSupplier(null);
                        return;
                      }
                      const [type, id] = values[0].split('-');
                      props.setSelectedGroundServiceOrSupplier({ id, type: type as 'ground_service' | 'supplier' });
                    }}
                    hideDropdownArrow
                    isEnableLookupSearch
                    onLookupSearch={props.groundServiceOrSupplierSelectOnLookup}
                    isCloseOnSelect={true}
                    isSingleSelectMode={true}
                    hideCheckboxes={true}
                    noOptionsAvailableText="Please type and search to lookup ground services or suppliers"
                  />
                </label>
              </div>

              <FluidButton
                disabled={isNil(props.airport) || isNil(props.date)}
                onClick={props.onSearchButtonClick}
                type="primary"
                isLoading={getSearchResultsNetwork === ENetworkRequestStatus.PENDING}
              >
                Search
              </FluidButton>
            </div>
          </div>

          {getSearchResultsNetwork === ENetworkRequestStatus.ERROR && (
            <ErrorBar message="Failed to load search results" ctaCaption="Please reload the page and try again" />
          )}

          <AddAncillarySearchResultsTable
            className="flex-grow h-[100px]"
            currencySymbol={props.currencySymbol}
            handleSelectSearchResult={(baseProductId, variantId) => {
              props.setSelectedSearchResult({ baseProductId, variantId });
            }}
            primaryColumnId="ground-service"
            primaryColumnName="Ground Service"
            searchResults={props.searchResults}
            selectedSearchResult={props.selectedSearchResult}
            isLoading={getSearchResultsNetwork === ENetworkRequestStatus.PENDING}
            baseProductNameRenderer={searchResult => searchResult.baseProductName}
          />
        </div>

        {showRightPanel && (
          <AddAncillaryRightHandPanel
            domainName="Ground Service"
            currencySymbol={props.currencySymbol}
            errorIds={props.errorIds}
            handleAddProduct={props.handleAddGroundService}
            selectedProduct={props.selectedGroundServiceProduct}
            slotContent={
              <React.Fragment>
                {searchInstanceVarsPickupFromLocationName && (
                  <div className="flex flex-col space-y-2">
                    <span className="flex items-center space-x-2">
                      <SvgIcon className="fill-black" width="16px" height="16px" IconComponent={AirplaneIcon} />
                      <span>{searchInstanceVarsPickupFromLocationName}</span>
                    </span>
                  </div>
                )}

                {/* right hand date */}
                {props.searchInstanceVars.date && (
                  <span className="flex items-center space-x-2">
                    <SvgIcon width="16px" height="16px" IconComponent={CalendarIcon} defaultFill="#413E3B" />

                    <span>{formatDateDisplayFourDigitYear(props.searchInstanceVars.date)}</span>
                  </span>
                )}

                <label className="w-1/2 mr-4">
                  <div className="flex">
                    <span className="block mb-1 text-black text-16px leading-20px tracking-2xs font-pt-sans font-bold">
                      Time
                    </span>
                  </div>
                  <input
                    onChange={e => {
                      props.setPickupTime(e.currentTarget.value);
                    }}
                    type="time"
                    className={classNames(
                      'arrival-time-input p-2  border border-solid border-gray-40 min-h-35px max-h-35px w-full',
                      {
                        'bg-red-25 border-red-95': props.errorIds.includes('pickupTime'),
                        'bg-ivory border-gray-40': !props.errorIds.includes('pickupTime'),
                      }
                    )}
                    value={props.pickupTime}
                  />
                </label>
              </React.Fragment>
            }
            updateSelectedProductWithUserData={props.updateSelectedGroundServiceProductWithUserData}
            productLoad={props.getGroundServiceProductNetwork}
          />
        )}
      </div>
    </GeneralModal>
  );
};
