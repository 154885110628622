import produce from 'immer';
import { createReducer } from 'store/utils';
import { initialState, IStaticRatesAvailabilityDomain } from './types';
import * as Actions from './actions';
import { ENetworkRequestStatus } from 'services/BackendApi';

export default createReducer(
  {
    [Actions.FETCH_STATIC_RATES_AVAILABILITY_REQUEST]: (
      state: IStaticRatesAvailabilityDomain,
      action: ReturnType<typeof Actions.fetchStaticRatesAvailabilityRequestAction>
    ): IStaticRatesAvailabilityDomain =>
      produce(state, x => {
        x.single = {
          accommodationUuid: action.accommodationUuid,
          startDate: action.startDate,
          endDate: action.endDate,
          availableDates: null,
          unavailableDates: null,
          requestDates: null,
        };
        x.networkRequests.single = ENetworkRequestStatus.PENDING;
        return x;
      }),
    [Actions.FETCH_STATIC_RATES_AVAILABILITY_SUCCESS]: (
      state: IStaticRatesAvailabilityDomain,
      action: ReturnType<typeof Actions.fetchStaticRatesAvailabilitySuccessAction>
    ): IStaticRatesAvailabilityDomain =>
      produce(state, draftState => {
        const index = draftState.aggregate?.accommodationsAggregates.findIndex(
          aa => aa.accommodationUuid === action.accommodationUuid
        );

        if (index === undefined || !draftState.aggregate) {
          return;
        }

        draftState.aggregate.accommodationsAggregates[index] = {
          ...action.availability,
          accommodationUuid: action.accommodationUuid,
        };

        return draftState;
      }),
    [Actions.FETCH_STATIC_RATES_AVAILABILITY_FAILURE]: (
      state: IStaticRatesAvailabilityDomain,
      action: ReturnType<typeof Actions.fetchStaticRatesAvailabilityFailureAction>
    ): IStaticRatesAvailabilityDomain =>
      produce(state, x => {
        x.error = action.errorMessage;
        x.networkRequests.single = ENetworkRequestStatus.ERROR;
        return x;
      }),
    [Actions.FETCH_STATIC_RATES_AVAILABILITY_AGGREGATE_REQUEST]: (
      state: IStaticRatesAvailabilityDomain,
      action: ReturnType<typeof Actions.fetchStaticRatesAvailabilityAggregateRequestAction>
    ): IStaticRatesAvailabilityDomain =>
      produce(state, x => {
        x.aggregate = {
          startDate: action.startDate,
          endDate: action.endDate,
          accommodationsAggregates: action.accommodationUuids.map(uuid => ({
            accommodationUuid: uuid,
            availableDates: null,
            unavailableDates: null,
            requestDates: null,
          })),
        };
        x.networkRequests.aggregate = ENetworkRequestStatus.PENDING;
        return x;
      }),
    [Actions.FETCH_STATIC_RATES_AVAILABILITY_AGGREGATE_SUCCESS]: (
      state: IStaticRatesAvailabilityDomain,
      action: ReturnType<typeof Actions.fetchStaticRatesAvailabilityAggregateSuccessAction>
    ): IStaticRatesAvailabilityDomain =>
      produce(state, x => {
        action.availabilities.forEach((data, idx) => {
          const a = state.aggregate!.accommodationsAggregates[idx];
          a.availableDates = data.availableDates;
          a.unavailableDates = data.unavailableDates;
          a.requestDates = data.requestDates;
        });
        x.networkRequests.aggregate = ENetworkRequestStatus.SUCCESS;
        return x;
      }),
    [Actions.FETCH_STATIC_RATES_AVAILABILITY_AGGREGATE_FAILURE]: (
      state: IStaticRatesAvailabilityDomain,
      action: ReturnType<typeof Actions.fetchStaticRatesAvailabilityAggregateFailureAction>
    ): IStaticRatesAvailabilityDomain =>
      produce(state, x => {
        x.error = action.errorMessage;
        x.networkRequests.aggregate = ENetworkRequestStatus.ERROR;
        return x;
      }),

    [Actions.RESET_STATIC_RATES_AVAILABILITY_DOMAIN]: (
      state: IStaticRatesAvailabilityDomain,
      action: ReturnType<typeof Actions.resetStaticRatesAvailabilityDomainAction>
    ): IStaticRatesAvailabilityDomain =>
      produce(state, draftState => {
        draftState.aggregate = null;
        draftState.single = null;
        draftState.networkRequests.aggregate = ENetworkRequestStatus.IDLE;
        draftState.networkRequests.single = ENetworkRequestStatus.IDLE;
        draftState.error = null;
      }),
  },
  initialState
);
