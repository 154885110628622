import { EBookingStatus } from 'services/BookingManagerApi/types/BookingStatusResponse';
import { IBookingStatusCancellationModalData, IBookingStatusModalData, IBookingStatusOptionObject } from './model';

export const GET_BOOKING_STATUS_OPTIONS_REQUEST = 'bookingManager/GET_BOOKING_STATUS_OPTIONS_REQUEST';
export const GET_BOOKING_STATUS_OPTIONS_SUCCESS = 'bookingManager/GET_BOOKING_STATUS_OPTIONS_SUCCESS';
export const GET_BOOKING_STATUS_OPTIONS_FAILURE = 'bookingManager/GET_BOOKING_STATUS_OPTIONS_FAILURE';

export const TRY_SETTING_BOOKING_STATUS = 'bookingManager/TRY_SETTING_BOOKING_STATUS';
export const SET_BOOKING_STATUS_REQUEST = 'bookingManager/SET_BOOKING_STATUS_REQUEST';
export const SET_BOOKING_STATUS_SUCCESS = 'bookingManager/SET_BOOKING_STATUS_SUCCESS';
export const SET_BOOKING_STATUS_FAILURE = 'bookingManager/SET_BOOKING_STATUS_FAILURE';

export const OPEN_BOOKING_STATUS_MODAL = 'bookingManager/OPEN_BOOKING_STATUS_MODAL';
export const CLOSE_BOOKING_STATUS_MODAL = 'bookingManager/CLOSE_BOOKING_STATUS_MODAL';

export const OPEN_CANCELLATION_INVOICE_MODAL = 'bookingManager/OPEN_CANCELLATION_INVOICE_MODAL';
export const CLOSE_CANCELLATION_INVOICE_MODAL = 'bookingManager/CLOSE_CANCELLATION_INVOICE_MODAL';

export const SET_CANCELLATION_MODAL_DUE_DATE = 'bookingManager/SET_CANCELLATION_MODAL_DUE_DATE';
export const SET_CANCELLATION_MODAL_PERCENTAGE = 'bookingManager/SET_CANCELLATION_MODAL_PERCENTAGE';
export const SET_CANCELLATION_MODAL_AMOUNT_CENTS = 'bookingManager/SET_CANCELLATION_MODAL_AMOUNT_CENTS';
export const SET_CANCELLATION_MODAL_OUTSTANDING_BALANCE_CENTS =
  'bookingManager/SET_CANCELLATION_MODAL_OUTSTANDING_BALANCE_CENTS';

export const SET_CANCELLATION_MODAL_DATA = 'bookingManager/SET_CANCELLATION_MODAL_DATA';

export const POST_CANCELLATION_INVOICE_REQUEST = 'bookingManager/POST_CANCELLATION_INVOICE_REQUEST';
export const POST_CANCELLATION_INVOICE_SUCCESS = 'bookingManager/POST_CANCELLATION_INVOICE_SUCCESS';
export const POST_CANCELLATION_INVOICE_FAILURE = 'bookingManager/POST_CANCELLATION_INVOICE_FAILURE';

export type GetBookingStatusOptionsRequestAction = ReturnType<typeof getBookingStatusOptionsRequestAction>;
export const getBookingStatusOptionsRequestAction = () => ({
  type: GET_BOOKING_STATUS_OPTIONS_REQUEST as typeof GET_BOOKING_STATUS_OPTIONS_REQUEST,
});

export type GetBookingStatusOptionsSuccessAction = ReturnType<typeof getBookingStatusOptionsSuccessAction>;
export const getBookingStatusOptionsSuccessAction = (bookingStatusOptions: IBookingStatusOptionObject[]) => ({
  type: GET_BOOKING_STATUS_OPTIONS_SUCCESS as typeof GET_BOOKING_STATUS_OPTIONS_SUCCESS,
  bookingStatusOptions,
});

export type GetBookingStatusOptionsFailureAction = ReturnType<typeof getBookingStatusOptionsFailureAction>;
export const getBookingStatusOptionsFailureAction = (error: string) => ({
  type: GET_BOOKING_STATUS_OPTIONS_FAILURE as typeof GET_BOOKING_STATUS_OPTIONS_FAILURE,
  error,
});

export type TrySettingBookingStatusAction = ReturnType<typeof trySettingBookingStatusAction>;
export const trySettingBookingStatusAction = (bookingStatus: EBookingStatus) => ({
  type: TRY_SETTING_BOOKING_STATUS as typeof TRY_SETTING_BOOKING_STATUS,
  bookingStatus,
});

export type SetBookingStatusRequestAction = ReturnType<typeof setBookingStatusRequestAction>;
export const setBookingStatusRequestAction = (bookingStatus: EBookingStatus) => ({
  type: SET_BOOKING_STATUS_REQUEST as typeof SET_BOOKING_STATUS_REQUEST,
  bookingStatus,
});

export type SetBookingStatusSuccessAction = ReturnType<typeof setBookingStatusSuccessAction>;
export const setBookingStatusSuccessAction = () => ({
  type: SET_BOOKING_STATUS_SUCCESS as typeof SET_BOOKING_STATUS_SUCCESS,
});

export type SetBookingStatusFailureAction = ReturnType<typeof setBookingStatusFailureAction>;
export const setBookingStatusFailureAction = (error: string) => ({
  type: SET_BOOKING_STATUS_FAILURE as typeof SET_BOOKING_STATUS_FAILURE,
  error,
});

export type OpenBookingStatusModalAction = ReturnType<typeof openBookingStatusModalAction>;
export const openBookingStatusModalAction = (bookingStatusModalData: Omit<IBookingStatusModalData, 'isOpen'>) => ({
  type: OPEN_BOOKING_STATUS_MODAL as typeof OPEN_BOOKING_STATUS_MODAL,
  bookingStatusModalData,
});

export type CloseBookingStatusModalAction = ReturnType<typeof closeBookingStatusModalAction>;
export const closeBookingStatusModalAction = () => ({
  type: CLOSE_BOOKING_STATUS_MODAL as typeof CLOSE_BOOKING_STATUS_MODAL,
});

export type OpenCancellationInvoiceModalAction = ReturnType<typeof openCancellationInvoiceModalAction>;
export const openCancellationInvoiceModalAction = (bookingStatus: EBookingStatus) => ({
  type: OPEN_CANCELLATION_INVOICE_MODAL as typeof OPEN_CANCELLATION_INVOICE_MODAL,
  bookingStatus,
});

export type CloseCancellationInvoiceModalAction = ReturnType<typeof closeCancellationInvoiceModalAction>;
export const closeCancellationInvoiceModalAction = () => ({
  type: CLOSE_CANCELLATION_INVOICE_MODAL as typeof CLOSE_CANCELLATION_INVOICE_MODAL,
});

export type SetCancellationModalDueDateAction = ReturnType<typeof setCancellationModalDueDateAction>;
export const setCancellationModalDueDateAction = (dueDate: string) => ({
  type: SET_CANCELLATION_MODAL_DUE_DATE as typeof SET_CANCELLATION_MODAL_DUE_DATE,
  dueDate,
});

export type SetCancellationModalPercentageAction = ReturnType<typeof setCancellationModalPercentageAction>;
export const setCancellationModalPercentageAction = (percentage: number) => ({
  type: SET_CANCELLATION_MODAL_PERCENTAGE as typeof SET_CANCELLATION_MODAL_PERCENTAGE,
  percentage,
});

export type SetCancellationModalAmountCentsAction = ReturnType<typeof setCancellationModalAmountCentsAction>;
export const setCancellationModalAmountCentsAction = (amountCents: number) => ({
  type: SET_CANCELLATION_MODAL_AMOUNT_CENTS as typeof SET_CANCELLATION_MODAL_AMOUNT_CENTS,
  amountCents,
});

export type SetCancellationModalOutstandingBalanceCentsAction = ReturnType<
  typeof setCancellationModalOutstandingBalanceCentsAction
>;
export const setCancellationModalOutstandingBalanceCentsAction = (outstandingBalanceCents: number) => ({
  type: SET_CANCELLATION_MODAL_OUTSTANDING_BALANCE_CENTS as typeof SET_CANCELLATION_MODAL_OUTSTANDING_BALANCE_CENTS,
  outstandingBalanceCents,
});

export type SetCancellationModalDataAction = ReturnType<typeof setCancellationModalDataAction>;
export const setCancellationModalDataAction = (data: Partial<IBookingStatusCancellationModalData>) => ({
  type: SET_CANCELLATION_MODAL_DATA as typeof SET_CANCELLATION_MODAL_DATA,
  data,
});

export type PostCancellationInvoiceRequestAction = ReturnType<typeof postCancellationInvoiceRequestAction>;
export const postCancellationInvoiceRequestAction = () => ({
  type: POST_CANCELLATION_INVOICE_REQUEST as typeof POST_CANCELLATION_INVOICE_REQUEST,
});

export type PostCancellationInvoiceSuccessAction = ReturnType<typeof postCancellationInvoiceSuccessAction>;
export const postCancellationInvoiceSuccessAction = () => ({
  type: POST_CANCELLATION_INVOICE_SUCCESS as typeof POST_CANCELLATION_INVOICE_SUCCESS,
  // successData,
});

export type PostCancellationInvoiceFailureAction = ReturnType<typeof postCancellationInvoiceFailureAction>;
export const postCancellationInvoiceFailureAction = (error: string) => ({
  type: POST_CANCELLATION_INVOICE_FAILURE as typeof POST_CANCELLATION_INVOICE_FAILURE,
  error,
});

export type BookingStatusOptionsSubdomainAction =
  | GetBookingStatusOptionsRequestAction
  | GetBookingStatusOptionsSuccessAction
  | GetBookingStatusOptionsFailureAction
  | TrySettingBookingStatusAction
  | SetBookingStatusRequestAction
  | SetBookingStatusSuccessAction
  | SetBookingStatusFailureAction
  | OpenBookingStatusModalAction
  | CloseBookingStatusModalAction
  | OpenCancellationInvoiceModalAction
  | CloseCancellationInvoiceModalAction
  | SetCancellationModalDueDateAction
  | SetCancellationModalPercentageAction
  | SetCancellationModalAmountCentsAction
  | SetCancellationModalOutstandingBalanceCentsAction
  | SetCancellationModalDataAction
  | PostCancellationInvoiceRequestAction
  | PostCancellationInvoiceSuccessAction
  | PostCancellationInvoiceFailureAction;
