import React, { useCallback } from 'react';
import { Link } from 'ui/Link';
import { IndicatorByStatus as BookingStatusIndicator } from 'pureUi/BookingStatus';
import { HidingTooltip } from 'ui/Tooltip';

interface ITransactionBookingColumnProps {
  bookingUuid?: string;
  bookingReference?: string;
  bookingStatus?: string;
  className?: string;
  canViewBooking?: boolean;
}

export const TransactionBookingColumn: React.FC<ITransactionBookingColumnProps> = React.memo(
  ({ bookingUuid, bookingReference, bookingStatus, className, canViewBooking }) => {
    const bookingUuidExists = !!bookingUuid && !!bookingReference;
    const renderTooltipContent = useCallback(() => <span className="capitalize p-1 text-13px">{bookingStatus}</span>, [
      bookingStatus,
    ]);

    return (
      <td className={`${className} px-2 relative`}>
        {bookingUuidExists && (
          <span className="w-full flex items-center justify-between pr-4">
            {(canViewBooking === true || canViewBooking === undefined) && (
              <Link to={`/booking-manager/${bookingUuid}`}>
                <span className="underline hover:no-decoration hover:text-black active:underline active:text-black-true">
                  {bookingReference}
                </span>
              </Link>
            )}
            {canViewBooking === false && <span className="text-black-true">{bookingReference}</span>}
            {bookingStatus && (
              <HidingTooltip renderTooltipContent={renderTooltipContent} position="bottom" tooltipClassname="mt-4">
                <BookingStatusIndicator className="block cursor-pointer" status={bookingStatus} />
              </HidingTooltip>
            )}
          </span>
        )}
      </td>
    );
  }
);
