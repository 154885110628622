import { call, takeLatest, all, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import * as Actions from './actions';
import { IInventoryCurrenciesResponse, makeInventoryApi } from 'services/InventoryAPI';
import { IInventoryCountriesResponse } from 'services/InventoryAPI/types/countries';

export function* getInventoryBootstrapRequestSaga() {
  const inventoryApi = makeInventoryApi();

  try {
    const [destinationsResponse, allCountriesResponse, currenciesResponse]: [
      AxiosResponse<IInventoryCountriesResponse>, AxiosResponse<IInventoryCountriesResponse>, AxiosResponse<IInventoryCurrenciesResponse>
    ] = yield all([
      call(inventoryApi.getCountries),
      call(inventoryApi.getCountries, false),
      call(inventoryApi.getCurrencies)
    ]);
    yield put(Actions.inventoryBootstrapSuccessAction(
      destinationsResponse.data.results, 
      allCountriesResponse.data.results,
      currenciesResponse.data.results
    ));
  } catch (e) {
    yield put(Actions.inventoryBootstrapFailureAction('Error getting inventory bootstrap countries'));
  }
}

export function* watchInventoryBootstrapSaga() {
  yield takeLatest([Actions.BOOTSTRAP_APP_REQUEST], getInventoryBootstrapRequestSaga);
}
