import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import {
  companyDataSelector,
  companyLoadSelector,
  fetchCompanyDataRequestAction,
  travelCompanyEditModeSelector,
  updateCompanyExternalMarkupPercentageAction,
} from 'store/modules/companyInfo';
import * as CompanyInfoActions from 'store/modules/companyInfo/actions';

import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { BackLink } from 'ui/BackLink';

import { setTravelCompanyAction } from 'store/modules/companyHotelMarkup';
import { setTravelCompanyEditModeAction } from 'store/modules/companyInfo';
import { TravelCompanyForm } from '../TravelCompanyForm';
import { CompanySettingsTabs } from './CompanySettingsTabs';
import { PageTitle } from 'ui/PageTitle';

import { getCurrentUserType } from 'store/modules/auth';
import { EUserType } from 'services/BackendApi';

interface ITravelCompanySettingsRouteParams {
  companyUuid: string;
}

export const TravelCompanySettings: React.FC = () => {
  const match = useRouteMatch<ITravelCompanySettingsRouteParams>();
  const companyUuid = match.params.companyUuid;
  const company = useSelector(companyDataSelector);
  const companyLoad = useSelector(companyLoadSelector);
  const userType = useSelector(getCurrentUserType) as EUserType;
  const isEditMode = useSelector(travelCompanyEditModeSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTravelCompanyEditModeAction(userType === EUserType.ADMIN));
    dispatch(setTravelCompanyAction(companyUuid));
    dispatch(fetchCompanyDataRequestAction(companyUuid));

    // We clear the company logo info when we change the company/load this component.
    // This is because, from what I can make out, the _tenant_ company logo is *initially* stored
    // in the same redux domain as the company we're editing. It's not very clear, but I believe this
    // is why if you load the Company Logo tab, you get a "flash" of the tenant company logo before
    // the actual company logo loads. This line of code fixes that.
    // TODO fully separate the tenant company info from the company we're editing
    dispatch(CompanyInfoActions.clearCompanyLogoInfo());
    return () => {
      dispatch(updateCompanyExternalMarkupPercentageAction(null));
    };
  }, [companyUuid, dispatch]);

  return (
    <div className="company-settings-page w-1280px mx-auto">
      <BackLink type="internal" href="/travel-companies">
        Back to Partner Company Results
      </BackLink>
      <PageTitle primaryText="Settings" secondaryCondition={!!company?.name} secondaryText={` - ${company?.name}`} />
      {companyLoad === ENetworkRequestStatus.PENDING && (
        <div className="mt-5">
          <LoadingBar />
        </div>
      )}
      {companyLoad === ENetworkRequestStatus.ERROR && (
        <div className="mt-5">
          <ErrorBar />
        </div>
      )}
      {company && (
        <TravelCompanyForm company={company} isEditMode={isEditMode}>
          <CompanySettingsTabs companyUuid={companyUuid} isEditMode={isEditMode} />
        </TravelCompanyForm>
      )}
    </div>
  );
};
