import { ENetworkRequestStatus, ICompany, IUploadFileInfo } from 'services/BackendApi';
import { IBootstrapHotel } from '../bootstrap/model';

export type ICompanyLogoPosition = 'left' | 'right';

export interface ICompnayInfoHotelListData {
  activeHotels: IBootstrapHotel[];
  hasEditedWithoutSaving: boolean;
}

export interface ICompanyInfo {
  company: ICompany | null;
  companyLogoInfo: IUploadFileInfo | null;
  companyLogoPosition: ICompanyLogoPosition | null;
  companyLoad: ENetworkRequestStatus;
  isProcessingRequest: boolean;
  error: Error | null;
  isEditMode: boolean;
  companyLogoNetwork: {
    postLogo: ENetworkRequestStatus;
    deleteLogo: ENetworkRequestStatus;
    fetchLogo: ENetworkRequestStatus;
  };
  hotelListData: ICompnayInfoHotelListData;
  hotelListDataNetwork: {
    getHotelListData: ENetworkRequestStatus;
    postHotelListData: ENetworkRequestStatus;
  };
  companyNetwork: {
    create: ENetworkRequestStatus;
    update: ENetworkRequestStatus;
  };
  depositAccountBalanceLoad: ENetworkRequestStatus;
  companyExternalMarkupPercentage: string | null;
}

export const initialState: ICompanyInfo = {
  company: null,
  companyLogoInfo: null,
  companyLogoPosition: null,
  companyLoad: ENetworkRequestStatus.PENDING,
  isProcessingRequest: false,
  error: null,
  isEditMode: false,
  companyLogoNetwork: {
    postLogo: ENetworkRequestStatus.IDLE,
    deleteLogo: ENetworkRequestStatus.IDLE,
    fetchLogo: ENetworkRequestStatus.IDLE,
  },
  hotelListData: {
    activeHotels: [],
    hasEditedWithoutSaving: false,
  },
  hotelListDataNetwork: {
    getHotelListData: ENetworkRequestStatus.IDLE,
    postHotelListData: ENetworkRequestStatus.IDLE,
  },
  companyNetwork: {
    create: ENetworkRequestStatus.IDLE,
    update: ENetworkRequestStatus.IDLE,
  },
  depositAccountBalanceLoad: ENetworkRequestStatus.IDLE,
  companyExternalMarkupPercentage: null,
};
