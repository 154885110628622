import React from 'react';
import FluidButton from 'ui/FluidButton';
import Warning from 'ui/Icons/warning.component.svg';
import { theme } from '../../../tailwind.config';

export interface IInsufficientFundsWarningProps {
  message1: string;
  message2?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel: string;
  onCancel: () => void;
  onConfirm?: () => void;
}

export const InsufficientFundsWarning = React.memo(({ onConfirm, onCancel, message1, message2, confirmButtonLabel, cancelButtonLabel }: IInsufficientFundsWarningProps) => {
  const classname = 'insufficient-funds-warning flex flex-col rounded-lg p-10 absolute m-auto z-50 top-0 bottom-0 left-0 right-0 bg-white w-9/10 border-[3px] border-solid border-yellow-advert space-y-30px';
  const height = onConfirm ? '240px' : '190px';
  return (
    <div
      style={{ height }}
      className={classname}
    >
      <div className="flex flex-row items-center">
        <div className="icon mr-15px">
          <Warning fill={theme.colors['yellow-advert']} />
        </div>
        <span className="font-bold text-lg leading-sm">
          {message1}
        </span>
      </div>
      {message2 && <span>{message2}</span>}
      <div className="flex flex-row items-center space-x-20px">
        <FluidButton className="w-200px" type={onConfirm ? 'secondary' : 'primary'} onClick={onCancel}>
          {cancelButtonLabel}
        </FluidButton>
        {onConfirm && (
          <FluidButton className="w-200px" type="primary" onClick={onConfirm}>
            {confirmButtonLabel}
          </FluidButton>
        )}
      </div>
    </div>
  );
});
