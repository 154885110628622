import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBackendApi, IUploadListResponse, IUploadResponse } from 'services/BackendApi';
import { enqueueNotification } from 'store/modules/ui/actions';
import { IDeletionCheckResponse, makeBookingManagerApi } from 'services/BookingManagerApi';
import {
  GET_UPLOADS_REQUEST,
  getUploadsRequestAction,
  GetUploadsRequestAction,
  getUploadsSuccessAction,
  getUploadsFailureAction,
  NEW_UPLOAD_REQUEST,
  NewUploadRequestAction,
  newUploadSuccessAction,
  newUploadFailureAction,
  DELETE_UPLOAD_REQUEST,
  DeleteUploadRequestAction,
  deleteUploadSuccessAction,
  deleteUploadFailureAction,
} from './actions';

import { newUploadPayloadSelector, deleteUploadUuidSelector } from './selectors';
import { bookingUuidSelector } from '../../selectors';
import { selectedTaSelector } from '../../../agents';

export function* getUploadsSaga(action: GetUploadsRequestAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const bookingManagerApi = makeBookingManagerApi();

    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IUploadListResponse> = yield call(
      backendApi.getUploadsForBooking,
      bookingUuid,
      action.tag
    );

    const fileUuids = result.data.data.map(f => f.uuid);

    const deletionCheckResult: AxiosResponse<IDeletionCheckResponse> = yield call(
      bookingManagerApi.postDeletionCheck,
      bookingUuid,
      fileUuids
    );

    const canDeleteUuids = deletionCheckResult.data.uuidList.filter(x => x.canDelete).map(x => x.uuid);

    yield put(getUploadsSuccessAction(result.data.data, canDeleteUuids));
  } catch (e) {
    yield put(getUploadsFailureAction(e));
  }
}

export function* newUploadSaga(action: NewUploadRequestAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);

    const bookingUuid = yield select(bookingUuidSelector);
    const payload = yield select(newUploadPayloadSelector);

    const formData = new FormData();
    formData.append('file', payload.file);
    formData.append('tag', payload.tag);
    formData.append('displayName', payload.displayName);
    formData.append('ownerType', 'Booking');
    formData.append('ownerUuid', bookingUuid);

    const result: AxiosResponse<IUploadResponse> = yield call(backendApi.uploadFile, formData);

    yield put(newUploadSuccessAction(result.data.data));
    yield put(getUploadsRequestAction());
  } catch (e) {
    yield put(newUploadFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: `There was an error uploading a file: ${e.message}`,
        options: { variant: 'error' },
      })
    );
  }
}

export function* deleteUploadSaga(action: DeleteUploadRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();

    const uploadUuid = yield select(deleteUploadUuidSelector);
    const bookingUuid = yield select(bookingUuidSelector);

    yield call(bookingManagerApi.deleteUpload, bookingUuid, uploadUuid);

    yield put(deleteUploadSuccessAction());
    yield put(getUploadsRequestAction());
  } catch (e) {
    yield put(deleteUploadFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: `There was an error deleting the upload: ${e.message}`,
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchBookingManagerUploads() {
  yield takeLatest(GET_UPLOADS_REQUEST, getUploadsSaga);
  yield takeLatest(NEW_UPLOAD_REQUEST, newUploadSaga);
  yield takeLatest(DELETE_UPLOAD_REQUEST, deleteUploadSaga);
}
