import React, { useState } from 'react';
import { ITableRow } from './types';
import classnames from 'classnames';
import { TableSubRow } from './TableSubRow';
import { TableExpandSubrowButton } from './TableExpandSubrowButton';

interface ITableRowProps {
  row: ITableRow;
  rowIndex: number;
  evenColor: string;
}

export const TableRow: React.FC<ITableRowProps> = React.memo(({ row, rowIndex, evenColor }) => {
  const visibleCells = row.cells.filter(cell => !cell.isHidden);
  const subRows = row.subRows || [];
  const isEven = rowIndex % 2 === 0;

  const [isExpanded, setIsExpanded] = useState(false);

  const rowClassname = classnames(
    `id-${row.id} h-15 border border-solid border-gray-20 text-15px border-l-0 border-r-0`,
    row.className,
    {
      'border-b-0': isExpanded,
      [`bg-${evenColor}`]: isEven,
    }
  );

  return (
    <>
      <tr
        key={row.id}
        className={rowClassname}
      >
        {visibleCells.map((cell, cellIndex) => {
          const cellValue = cell.renderValue ? cell.renderValue(isExpanded) : cell.value;
          return (
            <td key={cell.id} className={classnames('px-2', cell.className)} title={cell.title}>
              {subRows.length > 0 && cellIndex === 0 ? (
                <TableExpandSubrowButton isExpanded={isExpanded} setIsExpanded={setIsExpanded}>
                  <span className={cell.contentClassName}>{cellValue}</span>
                </TableExpandSubrowButton>
              ) : (
                <span className={cell.contentClassName}>{cellValue}</span>
              )}
            </td>
          );
        })}
      </tr>
      {isExpanded && subRows.map((subRow, subrowIndex) => {
          return (
            <TableSubRow
              key={`id-subrow-${row.id}-${subrowIndex}`}
              subRow={subRow}
              isEven={isEven}
              evenColor={evenColor}
            />
          );
        })}
    </>
  );
});
