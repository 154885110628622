import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router';
import styled from 'styled-components';

interface ITabsProps {
  tabConfig: {
    title: string;
    route: string;
    styles: string;
    renderContent: () => React.ReactElement;
  }[];
  routeContainerClassname?: string; // the class names to apply to the container that wraps all routes children
}

const StyledNavLink = styled(NavLink)`
  &.not-active,
  &.not-active:hover {
    color: #000;
  }

  &.active,
  &.active:hover {
    color: #70d6ba;
  }
`;

export const RouterTabs: React.FC<ITabsProps> = props => {
  const {
    tabConfig,
    routeContainerClassname = 'flex border border-solid border-gray-40 border-t-0 pt-15px pb-30px px-5',
  } = props;
  const match = useRouteMatch();

  const getRoute = (path: string): string => `${match?.url}/${path}`;

  return (
    <div className="tabs w-1280px mx-auto pt-25px pb-35px">
      <div className="tag-selector flex mt-5">
        {tabConfig.map(spec => (
          <StyledNavLink
            key={spec.title}
            to={getRoute(spec.route)}
            replace
            className={`${spec.title} font-pt-sans text-base text-center border border-solid border-gray-40 border-r-0 last-of-type:border-r rounded rounded-b-none p-4 not-active ${spec.styles}`}
            activeClassName="border-b-0 font-bold text-teal-100 active"
          >
            {spec.title}
          </StyledNavLink>
        ))}
        <div className="w-full border-t-0 border-r-0 border-l-0 border-b border-solid border-gray-40" />
      </div>
      <Switch>
        {tabConfig.map(spec => (
          <Route key={spec.route} exact path={getRoute(spec.route)}>
            <div className={routeContainerClassname}>{spec.renderContent()}</div>
          </Route>
        ))}
        <Route path={`${match?.path}`}>
          <Redirect to={getRoute(tabConfig[0].route)} />
        </Route>
      </Switch>
    </div>
  );
};
