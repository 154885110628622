import { createSelector } from 'reselect';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { IDownloadsSubdomain, IDownloadsSubdomainNetworkRequests } from './model';
import { downloadsSubdomainSelector } from '../../selectors';

export const downloadsSelector = createSelector(
  downloadsSubdomainSelector,
  (subdomain: IDownloadsSubdomain) => subdomain.downloads
);

export const networkRequestsSelector = createSelector(
  downloadsSubdomainSelector,
  (subdomain: IDownloadsSubdomain) => subdomain.networkRequests
);

export const downloadsLoadFailedSelector = createSelector(
  networkRequestsSelector,
  (requests: IDownloadsSubdomainNetworkRequests) => requests.downloadsLoad === ENetworkRequestStatus.ERROR
);
