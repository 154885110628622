import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { EBookingStatus } from 'services/BookingManagerApi/types/BookingStatusResponse';

export interface IBookingStatusOptionObject {
  enabled: boolean;
  value: EBookingStatus;
  message?: string;
}

export interface IBookingStatusModalData {
  isOpen: boolean;
  title: string;
  message: string;
  confirmButtonLabel: string;
  cancelButtonLabel: string;
  nextBookingStatus: EBookingStatus;
}

export interface IBookingStatusCancellationModalData {
  bookingStatus: EBookingStatus | null;
  dueDate: string;
  percentage: number;
  amountCents: number;
  // costToTaCents: number;
  outstandingBalanceCents: number;
}

export interface IBookingStatusOptionsSubdomain {
  bookingStatusOptions: IBookingStatusOptionObject[] | null;
  networkRequests: IBookingStatusOptionsSubdomainNetworkRequests;
  modal: IBookingStatusModalData;
  isCancellationModalOpen: boolean;
  cancellationModalData: IBookingStatusCancellationModalData;
}

export interface IBookingStatusOptionsSubdomainNetworkRequests {
  bookingStatusOptionsLoad: ENetworkRequestStatus;
  postCancellationInvoiceLoad: ENetworkRequestStatus;
}

export const initialState: IBookingStatusOptionsSubdomain = {
  bookingStatusOptions: null,
  networkRequests: {
    bookingStatusOptionsLoad: ENetworkRequestStatus.IDLE,
    postCancellationInvoiceLoad: ENetworkRequestStatus.IDLE,
  },
  modal: {
    isOpen: false,
    title: '',
    message: '',
    confirmButtonLabel: '',
    cancelButtonLabel: '',
    nextBookingStatus: EBookingStatus.ENQUIRY,
  },
  isCancellationModalOpen: false,
  cancellationModalData: {
    bookingStatus: null,
    dueDate: '',
    percentage: 0,
    amountCents: 0,
    // costToTaCents: 0,
    outstandingBalanceCents: 0,
  },
};
