import { EIncentiveAmountType } from 'services/BookingManagerApi';
import { createSelector } from 'store/utils';
import { EAlertType } from 'ui/Alert';

import { ICompanyIncentivesDomain } from './model';

export const domainSelector = (state: any): ICompanyIncentivesDomain => state.companyIncentives;

export const requestSelector = createSelector(domainSelector, domain => domain.request);
export const errorSelector = createSelector(domainSelector, domain => domain.error);
export const incentivesEnabledSelector = createSelector(
  domainSelector,
  domain => domain.changes?.enabled ?? domain.enabled
);
export const incentiveAmountSelector = createSelector(
  domainSelector,
  domain => domain.changes?.amount ?? domain.amount
);
export const incentiveAmountTypeSelector = createSelector(
  domainSelector,
  domain => domain.changes?.amountType ?? domain.amountType
);
export const incentiveStartDateSelector = createSelector(
  domainSelector,
  domain => domain.changes?.startDate ?? domain.startDate
);
export const incentiveNotesSelector = createSelector(domainSelector, domain => domain.changes?.notes ?? domain.notes);
export const dirtySelector = createSelector(domainSelector, domain => {
  if (domain.changes?.enabled === false && domain.enabled === false) {
    return false;
  }

  return Object.keys(domain.changes ?? {}).some(field => domain.changes?.[field] !== domain[field]);
});
export const incentiveSelector = createSelector(
  incentiveAmountSelector,
  incentiveAmountTypeSelector,
  incentiveStartDateSelector,
  incentiveNotesSelector,
  (amount, amountType, startDate, notes) => ({ amount, type: amountType, startDate, notes })
);

export const fieldValidationSelector = createSelector(
  incentiveAmountSelector,
  incentiveAmountTypeSelector,
  incentiveStartDateSelector,
  (amount, amountType, startDate) => {
    if (amount !== null && amount <= 0) {
      return false;
    }

    if (amountType === null) {
      return false;
    }

    if (startDate === null) {
      return false;
    }

    if (amountType === EIncentiveAmountType.PERCENTAGE && amount && amount > 1) {
      return false;
    }

    return true;
  }
);
