export const SET_LATEST_APP_VERSION = 'app/SET_LATEST_APP_VERSION';
export const VISIBILITY_CHANGE = 'app/VISIBILITY_CHANGE';

export type SetLatestAppVersion = ReturnType<typeof setLatestAppVersion>;
export const setLatestAppVersion = (latestAppVersion: string, isAppDeprecated: boolean) => ({
  type: SET_LATEST_APP_VERSION as typeof SET_LATEST_APP_VERSION,
  latestAppVersion,
  isAppDeprecated
});

export type VisibilityChange = ReturnType<typeof visibilityChange>;
export const visibilityChange = (visible: boolean) => ({
  type: VISIBILITY_CHANGE as typeof VISIBILITY_CHANGE,
  visible
});


export type AppAction =
 | SetLatestAppVersion
 | VisibilityChange
