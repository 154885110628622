import * as React from 'react';

interface ISectionTitleProps {
  className?: string;
  children?: React.ReactNode;
  testId?: string;
}

export const SectionTitle: React.FC<ISectionTitleProps> = ({ children, className, testId }) => {
  return (
    <div data-testid={testId || ''} className={`section-title ${className ? className : ''}`}>
      <span
        className={`font-noe-display text-sm leading-2xs tracking-xs uppercase text-black-true border-solid border-b-2 border-teal-100`}
      >
        {children}
      </span>
    </div>
  );
};

export default SectionTitle;
