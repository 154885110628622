import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  makeBackendApi,
  ICompanyInvoiceAddresseeResponse,
  ICompanyInvoiceAddresseeListResponse
} from 'services/BackendApi';
import { enqueueNotification } from 'store/modules/ui';

import {
  GET_REQUEST,
  GetRequestAction,
  getSuccessAction,
  getFailureAction,
  UPDATE_REQUEST,
  UpdateRequestAction,
  updateSuccessAction,
  updateFailureAction,
} from './actions';

import { editableSelector } from './selectors';

function* successNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'success' } }));
}

function* errorNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'error' } }));
}

export function* getRequestSaga(action: GetRequestAction) {
  const backendApi = makeBackendApi();

  try {
    const res: AxiosResponse<ICompanyInvoiceAddresseeListResponse> = yield call(
      backendApi.getCompanyInvoiceAddressee,
      action.companyUuid
    );

    yield put(getSuccessAction(res.data.data[0] || null));
  } catch (e) {
    yield put(getFailureAction(e.message));
    yield errorNotification('Failed to fetch company invoice addressee');
  }
}

export function* updateRequestSaga(action: UpdateRequestAction) {
  const backendApi = makeBackendApi();
  const payload = yield select(editableSelector);

  try {
    const res: AxiosResponse<ICompanyInvoiceAddresseeResponse> = yield call(
      backendApi.upsertCompanyInvoiceAddressee,
      action.companyUuid,
      payload
    );

    yield put(updateSuccessAction(res.data.data));
    yield successNotification('Company invoice addressee has beed updated successfully');
  } catch (e) {
    yield put(updateFailureAction(e.message));
    yield errorNotification('Failed to update company invoice addressee');
  }
}


export function* watchCompanyInvoiceAddresseeSaga() {
  yield takeLatest(GET_REQUEST, getRequestSaga);
  yield takeLatest(UPDATE_REQUEST, updateRequestSaga);
}
