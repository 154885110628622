export interface IUploadFileInfo {
  uuid: string;
  filename: string;
  displayName: string;
  url: string;
  size: string;
  tag: EUploadTag;
  ownerType: string;
  ownerUuid: string;
  createdAt: string;
  updatedAt: string;
}

export interface IUploadResponse {
  meta: {
    type: string;
  };
  data: IUploadFileInfo;
}

export interface IUploadCloudFileInfo {
  filename: string;
  url: string;
  size: string;
}

export interface IUploadCloudResponse {
  meta: {
    type: string;
  };
  data: IUploadCloudFileInfo;
}

export interface IUploadListResponse {
  meta: {
    type: string;
  };
  data: IUploadFileInfo[];
}

export enum EHotelUploadTags {
  'photo',
  'featuredPhoto',
  'floorPlan',
  'brochure',
  'cancellationPolicy',
  'termsAndConditions',
}

export enum EUploadTag {
  FLOOR_PLAN = 'floorPlan',
  BROCHURE = 'brochure',
  PHOTO = 'photo',
  FEATURED_PHOTO = 'featuredPhoto',
  ACCOMMODATION_INFORMATION = 'accommodationInformation',
  VOUCHER = 'voucher',
  ITINERARY = 'itinerary',
  OTHER_INTERNAL = 'otherInternal',
  OTHER_GENERAL = 'otherGeneral',
  BREAKDOWN = 'breakdown',

  FINANCE_SALES = 'financeSales',
  FINANCE_SALES_INVOICE = 'financeSalesInvoice',
  FINANCE_SALES_PROFORMA_INVOICE = 'financeSalesProformaInvoice',
  FINANCE_SALES_PROFORMA_INVOICE_FINAL_CLIENT = 'financeSalesProformaInvoiceFinalClient',
  FINANCE_SALES_MANUAL_PROFORMA_INVOICE = 'financeSalesManualProformaInvoice',
  FINANCE_SALES_MANUAL_PROFORMA_INVOICE_FINAL_CLIENT = 'financeSalesManualProformaInvoiceFinalClient',

  FINANCE_PURCHASE = 'financePurchase',
  BOOKING_CANCELLATION_INVOICE = 'bookingCancellationInvoice',
  BALANCE_CARRIED_FORWARD_DOCUMENT = 'balanceCarriedForwardDocument',
  DEPOSIT_ACCOUNT_DOCUMENT = 'depositAccountDocument',
  INSTANT_BOOK_HOTEL_CONFIRMATION = 'instantBookHotelConfirmation',

  RESORT_MAP = 'resortMap',
}
