import { useEffect, useRef, useState } from 'react';

/**
 * State and functions to handle pagination variables
 */
export function usePagination({ initialPage = 0, initialPerPage = 10, initialTotal = 0 }) {
  const [page, setPage] = useState(initialPage);
  const [perPage, setPerPage] = useState(initialPerPage);
  const [total, setTotal] = useState(initialTotal);

  const pageCount = Math.ceil(total / perPage);

  return {
    page,
    perPage,
    total,
    pageCount,
    setPage,
    setPerPage,
    setTotal,
  };
}
