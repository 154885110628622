import { ICompany } from 'services/BackendApi';

/* TODO: this types are generated by JSON
 * refactoring with further usage is required
 * - check what fields are not required '?'.
 * - maybe there is no need in different user interfaces based on 'IBasicUser'
 */
export interface ICompanySignupInfo {
  name: string;
  countryCode: string;
}

export interface IAssignment {
  salesRepresentativeUserUuid: string;
  travelAgentUserUuid: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IBasicUser {
  uuid: string;
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  phoneNumber: string;
  mobileNumber: string;
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  countryCode: string;
  status: string;
  isExistingPartner: boolean;
  agreeToTerms: boolean;
  type: string;
  receiveEmailAlerts: boolean;
  companyUuid: string;
  companySignupInfo: ICompanySignupInfo;
  createdAt: Date;
  updatedAt: Date;
  company?: ICompany;
}

export interface IAssignedTA extends IBasicUser {
  assignment: IAssignment;
}

export interface ISR extends IBasicUser {
  assignedTravelAgents: IAssignedTA[];
}

// TODO: this module is in draft version.
// It should replace 'auth' module in long run.
export interface IUserDomain {
  authorizedUser: IBasicUser | null,
}

export const initialState: IUserDomain = {
  authorizedUser: null,
};
