import axios, { AxiosInstance, AxiosResponse } from 'axios';
import qs from 'qs';
import { ANALYTICS_SESSION_UUID_KEY, PARAMETERS_PROVIDER_URL } from 'config';
import { readFromSessionStorage } from 'services/windowSessionStorage';

export enum ParameterProviderEndpoints {
  WEBAPP_FRONTEND = 'webapp-frontend',
}

export class ParametersProviderApiService<T extends AxiosInstance> {
  client: T;

  constructor(client: T) {
    this.client = client;
  }

  getDynamicParameters = async (): Promise<AxiosResponse> => {
    const endpoint = ParameterProviderEndpoints.WEBAPP_FRONTEND;
    const promise = this.client.get(endpoint);
    return promise;
  };
}

export const makeParametersProviderApi = (): ParametersProviderApiService<AxiosInstance> => {
  const sessionUuid = readFromSessionStorage(ANALYTICS_SESSION_UUID_KEY);
  const headers: any = {
    'x-session-id': sessionUuid
  };

  const client = axios.create({
    baseURL: PARAMETERS_PROVIDER_URL,
    headers,
    paramsSerializer: params => {
      return qs.stringify(params);
    },
  });

  return new ParametersProviderApiService<AxiosInstance>(client);
};
