import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { BasketPage } from './BasketPage';

export interface IBasketRoutingProps extends RouteComponentProps {}

export const BasketRouting = compose(withRouter)((props: IBasketRoutingProps) => {
  return (
    <Switch>
      <Route path={`/`} component={BasketPage} />
    </Switch>
  );
});
