import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBackendApi, ITravelAgentResponse } from 'services/BackendApi';
import {
  getTravelAgentsByCompanyIdSuccessAction,
  getTravelAgentsByCompanyIdFailureAction,
  SelectedCompanyChangeAction,
  SELECTED_COMPANY_CHANGE,
} from '../actions';
import { selectedTaSelector } from '../selectors';
import * as AuthSelectors from 'store/modules/auth';

export function* getTravelAgentBycompanyIdRequestSaga(action: SelectedCompanyChangeAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const companyUuid = action.value && action.value.uuid;
    const isAdmin = yield select(AuthSelectors.isAdmin);

    if (companyUuid) {
      const result: AxiosResponse<ITravelAgentResponse> = yield call(
        backendApi.getTravelAgentsByCompanyId,
        companyUuid,
        isAdmin
      );
      yield put(getTravelAgentsByCompanyIdSuccessAction(result.data.data));
    }
  } catch (e) {
    yield put(getTravelAgentsByCompanyIdFailureAction(e.message));
  }
}

export function* watchGetTravelAgentBycompanyIdRequest() {
  yield takeLatest([SELECTED_COMPANY_CHANGE], getTravelAgentBycompanyIdRequestSaga);
}
