import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ENetworkRequestStatus } from 'services/BackendApi';
import { EBookingStatus } from 'services/BookingManagerApi/types/BookingStatusResponse';

import { bookingCurrencySymbolSelector } from 'store/modules/bookingManager/selectors';
import * as BookingStatusOptionsSlectors from 'store/modules/bookingManager/subdomains/bookingStatusOptions/selectors';
import * as BookingStatusOptionsActions from 'store/modules/bookingManager/subdomains/bookingStatusOptions/actions';
import { IBookingStatusOptionObject } from 'store/modules/bookingManager/subdomains/bookingStatusOptions/model';
import { headlineLineItemBreakdownSelector } from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import { cancellationPoliciesSelector } from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import { formatDate } from 'utils';

import FluidButton from 'ui/FluidButton';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { StandardModal } from 'pureUi/Modal';

import StickyBar from './StickyBar';
import { CancellationDueDate } from './CancellationDueDate';
import { BookingStatusButton } from './BookingStatusButton';

export interface IBookingStatusCtaCollectionProps {
  options: IBookingStatusOptionObject[] | null;
  isLoading: boolean;
  isError: boolean;
}

export const BookingStatusCtaCollection: React.FC<IBookingStatusCtaCollectionProps> = ({
  options,
  isLoading,
  isError,
}) => {
  const dispatch = useDispatch();
  const bookingStatusModalData = useSelector(BookingStatusOptionsSlectors.bookingStatusModalSelector);
  const isCancellationModalOpen = useSelector(BookingStatusOptionsSlectors.isCancellationModalOpenSelector);
  const cancellationPolicies = useSelector(cancellationPoliciesSelector);
  const cancellationModalData = useSelector(BookingStatusOptionsSlectors.cancellationModalDataSelector);
  const breakdownData = useSelector(headlineLineItemBreakdownSelector);
  const bookingCurrencySymbol = useSelector(bookingCurrencySymbolSelector);
  const networkRequests = useSelector(BookingStatusOptionsSlectors.bookingStatusOptionsNetworkRequestsSelector);

  const handleConfirmBookingStatusModal = useCallback(() => {
    dispatch(BookingStatusOptionsActions.setBookingStatusRequestAction(bookingStatusModalData.nextBookingStatus));
  }, [bookingStatusModalData.nextBookingStatus, dispatch]);

  const handleCancelBookingStatusModal = useCallback(() => {
    dispatch(BookingStatusOptionsActions.closeBookingStatusModalAction());
  }, [dispatch]);

  const renderButtons = (options: IBookingStatusOptionObject[]) => {
    const buttonTextMap = {
      [EBookingStatus.REQUESTED]: 'Request Booking',
      [EBookingStatus.CONFIRMED]: 'Confirm Booking',
      [EBookingStatus.DISCARDED]: 'Discard Booking',
      [EBookingStatus.CANCELLED]: 'Cancel Booking',
      [EBookingStatus.COMPLETED]: 'Complete Booking',
    };

    return options
      .filter((bookingStatusOption: IBookingStatusOptionObject) => {
        return Object.keys(buttonTextMap).includes(bookingStatusOption.value);
      })
      .map((bookingStatusOption: IBookingStatusOptionObject, index: number) => {
        const buttonType = index === 0 ? 'primary' : 'secondary';

        return (
          <BookingStatusButton
            className={bookingStatusOption.value}
            key={bookingStatusOption.value}
            type={buttonType}
            bookingStatus={bookingStatusOption.value}
            text={buttonTextMap[bookingStatusOption.value]}
            disabled={!bookingStatusOption.enabled}
            message={bookingStatusOption.message}
          />
        );
      });
  };

  if (options === null || (options && options.length === 0)) {
    return null;
  }

  if (isLoading) {
    return (
      <StickyBar className="booking-status-ctas z-1">
        <span className="info loading">Loading booking statuses...</span>
      </StickyBar>
    );
  }

  if (isError) {
    return (
      <StickyBar className="booking-status-ctas z-1">
        <span className="info error text-red-100">Failed to load booking status options</span>
      </StickyBar>
    );
  }

  return (
    <React.Fragment>
      <StickyBar className="booking-status-ctas z-1">{renderButtons(options)}</StickyBar>
      <ConfirmationModal
        className="booking-status-update"
        type={EConfirmationModalType.WARNING}
        isOpen={bookingStatusModalData.isOpen}
        title={bookingStatusModalData.title}
        message={bookingStatusModalData.message}
        confirmButtonLabel={bookingStatusModalData.confirmButtonLabel}
        cancelButtonLabel={bookingStatusModalData.cancelButtonLabel}
        onConfirm={handleConfirmBookingStatusModal}
        onCancel={handleCancelBookingStatusModal}
      />

      {isCancellationModalOpen && (
        <StandardModal
          className={'purchase-cost-review-details-modal font-pt-sans z-11 text-black'}
          frameClassName="w-778px px-9 py-8 border border-solid"
          showCloseButton={true}
          removePadding={true}
          closeOnOutsideClick={false}
          overflowY="visible"
          onClose={() => {
            dispatch(BookingStatusOptionsActions.closeCancellationInvoiceModalAction());
            dispatch(BookingStatusOptionsActions.setCancellationModalDueDateAction(''));
            dispatch(BookingStatusOptionsActions.setCancellationModalPercentageAction(0));
            dispatch(BookingStatusOptionsActions.setCancellationModalAmountCentsAction(0));
          }}
        >
          <h2 className="font-noe-display">Cancellation Fees</h2>
          <CancellationDueDate
            bookingCurrencySymbol={bookingCurrencySymbol}
            dueDate={cancellationModalData.dueDate}
            percentage={cancellationModalData.percentage}
            amountCents={cancellationModalData.amountCents}
            initialOutstandingCents={breakdownData?.SubtotalCents}
            outstandingBalanceCents={cancellationModalData.outstandingBalanceCents}
            onChangeDate={(date: Date) => {
              dispatch(BookingStatusOptionsActions.setCancellationModalDueDateAction(formatDate(date)));
            }}
            onChangePercentage={(percentage: number) => {
              dispatch(BookingStatusOptionsActions.setCancellationModalPercentageAction(percentage));
            }}
            setAmountCents={(e: any) => {
              dispatch(BookingStatusOptionsActions.setCancellationModalAmountCentsAction(e));
            }}
          />

          <div className="rounded text-13px flex flex-col border border-solid border-light-gray mt-3 mb-8 py-3 px-5">
            <span className="font-bold">Cancellation Policies</span>
            <span>{cancellationPolicies}</span>
          </div>

          <FluidButton
            type="primary"
            className="m-auto min-w-150px"
            isLoading={networkRequests.postCancellationInvoiceLoad === ENetworkRequestStatus.PENDING}
            disabled={cancellationModalData.dueDate === ''}
            onClick={() => {
              dispatch(BookingStatusOptionsActions.postCancellationInvoiceRequestAction());
            }}
          >
            Generate Invoice
          </FluidButton>
        </StandardModal>
      )}
    </React.Fragment>
  );
};

export default BookingStatusCtaCollection;
