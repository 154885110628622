import React from 'react';
import {
  AncillaryProductWithRatesWithUserData,
  isAncillaryRatedResult,
  isAncillaryRatedResultPerUnit,
} from 'services/BookingManagerApi/types/AncillaryService';
import UserIcon from 'ui/Icons/person-alt.component.svg';
import { AddAncillaryRightHandPanelOptions } from './AddAncillaryRightHandPanelOptions';
import { formatPrice } from 'utils';
import classNames from 'classnames';
import FluidButton from 'ui/FluidButton';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { isNil } from 'lodash-es';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

import { getDisplayNameForAncillaryProductResult } from './utils';
import { AddAncillaryRightHandPanelUnitCounter } from './AddAncillaryRightHandPanelUnitCounter';
import { AddAncillaryRightHandPanelTotalCost } from './AddAncillaryRightHandPanelTotalCost';

const GA_FLOW_TAG = 'act-modal';

export interface IAddAncillaryRightHandPanelProps {
  selectedProduct: AncillaryProductWithRatesWithUserData | null;
  errorIds: string[];
  currencySymbol: string;
  slotContent: React.ReactNode;
  updateSelectedProductWithUserData: (data: Partial<AncillaryProductWithRatesWithUserData['userData']>) => void;
  handleAddProduct: () => void;
  productLoad: ENetworkRequestStatus;
  domainName: string;
  className?: string;
  productNamePrefix?: string;
}

export const AddAncillaryRightHandPanel = (props: IAddAncillaryRightHandPanelProps) => {
  const { dynamicParameters } = useDynamicParameters();
  return (
    <div
      className={classNames(
        'right-panel font-pt-sans border-solid border-1 border-gray-40 rounded-[4px] p-20px flex flex-col w-[321px] space-y-20px',
        props.className
      )}
    >
      {props.productLoad === ENetworkRequestStatus.PENDING && <LoadingBar />}

      {props.productLoad === ENetworkRequestStatus.ERROR && <ErrorBar />}

      {props.productLoad === ENetworkRequestStatus.SUCCESS && !isNil(props.selectedProduct) && (
        <React.Fragment>
          {/* product and/or variant name, and guest ages */}
          <div className="your-choice flex flex-col space-y-5px">
            <span className="text-xs text-gray-100 uppercase">Your Choice</span>
            {/* product or product and variant name */}
            <span className="text-17px leading-22px text-black font-bold">
              {props.productNamePrefix ? `${props.productNamePrefix} ` : null}
              {getDisplayNameForAncillaryProductResult(props.selectedProduct.product)}
            </span>
            {/* user counts */}
            <div className="flex items-center space-x-5px">
              <UserIcon className="w-[16px] h-[16px] stroke-black fill-black" />
              <span>x{props.selectedProduct.userData.rate?.numberOfAdults}</span>
              {props.selectedProduct.userData.rate?.agesOfAllChildren &&
                props.selectedProduct.userData.rate?.agesOfAllChildren.length >= 1 && (
                  <>
                    <UserIcon className="w-[12px] h-[12px] stroke-black fill-black" />
                    <span>x{props.selectedProduct.userData.rate?.agesOfAllChildren.length}</span>
                  </>
                )}
            </div>
          </div>

          {/* each modal has some of its own stuff that gets 
            inserted (activity has date picker, transfer has time, etc.) */}
          {props.slotContent}

          <AddAncillaryRightHandPanelOptions
            errorIds={props.errorIds}
            selectedProduct={props.selectedProduct}
            updateSelectedProductWithUserData={props.updateSelectedProductWithUserData}
          />

          {/* the bottom stuff - unit count, price, and add button */}
          <div className="space-y-10px pt-10px">
            {/* the "Confirm Units" inputs */}
            <AddAncillaryRightHandPanelUnitCounter
              selectedProduct={props.selectedProduct}
              updateSelectedProductWithUserData={props.updateSelectedProductWithUserData}
            />

            {/* if we've selected a rate, show the price, or "on request" */}
            {props.selectedProduct.userData.rate && isAncillaryRatedResult(props.selectedProduct.userData.rate) && (
              <AddAncillaryRightHandPanelTotalCost
                price={props.selectedProduct.userData.rate?.price!}
                isOnRequest={props.selectedProduct.userData.rate.rateIsOnRequest}
                currencySymbol={props.currencySymbol}
              />
            )}

            <FluidButton
              onClick={() => {
                props.handleAddProduct();
              }}
              className="block w-full"
              type="primary"
            >
              Add {props.domainName}
            </FluidButton>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
