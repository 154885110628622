import uniq from 'lodash/uniq';

import { BookingBuilderResponse, ENetworkRequestStatus } from 'services/BackendApi';
import { EBlockType, EProductCategory } from 'store/modules/bookingManager/sagas/bookingTransforms/BookingModel';

import { ERoomRateType } from '../../model';
import { IBasketRoom, IBasketSubdomain, basketInitialState, IBasketSupplement } from './model';
import * as Actions from './actions';
import { produce } from 'immer';

const getRoomsFromResponse = (
  rooms: Array<IBasketRoom | null>,
  response: BookingBuilderResponse
): Array<IBasketRoom | null> => {
  const returnedRooms = rooms
    .filter(room => room !== null)
    .map((room: IBasketRoom, idx) => {
      const accomodation = response.availableProductSets.Accommodation.filter(product => product.selected)[idx];
      const mealPlan = accomodation.availableSubProductSets['Meal Plan'].filter(mp => mp.selected)[0];
      const accomTotals = response.displayTotals.blocks.filter(block => block.blockType === EBlockType.Accommodations)[
        idx
      ];
      const appliedOffers = uniq(accomTotals.items.flatMap(blockItem => blockItem.offers));

      return {
        ...room,
        appliedOffers,
        total: accomTotals.items.reduce((acc, curr) => acc + Number(curr.total), 0.0).toFixed(2),
        totalBeforeDiscount: accomTotals.items
          .reduce((acc, curr) => acc + Number(curr.totalBeforeDiscount), 0.0)
          .toFixed(2),
        category: accomodation.products[0].meta.categoryType,
        mealPlan: mealPlan
          ? {
              title: mealPlan.products[0].name,
              description: mealPlan.products[0].meta.description ?? '',
              total: mealPlan.total,
              totalBeforeDiscount: mealPlan.totalBeforeDiscount,
            }
          : { title: 'N/A', description: 'N/A', total: 'N/A', totalBeforeDiscount: 'N/A' },
      };
    });

  const newRooms: Array<IBasketRoom | null> = new Array(rooms.length).fill(null);
  const basketRoomIndexes = rooms.map((room, idx) => (room === null ? -1 : idx)).filter(idx => idx !== -1);
  basketRoomIndexes.forEach((roomIndex, idx) => {
    newRooms[roomIndex] = returnedRooms[idx];
  });
  return newRooms;
};

const getSupplementsFromResponse = (response: BookingBuilderResponse): IBasketSupplement[] =>
  response.availableProductSets.Supplement.filter(supplement => supplement.selected).map(supplement => {
    const supplementProduct = supplement.products[0];
    const { name, category, uuid } = supplementProduct;
    const singlePrice = supplement.breakdown[0].singlePrice;
    const total = supplement.breakdown.reduce((acc, curr) => acc + Number(curr.total), 0.0).toFixed(2);

    return {
      uuid,
      name,
      category: category as EProductCategory,
      singlePrice,
      total,
      isOnRequestOrPartiallyOnRequest: supplement.isOnRequestOrPartiallyOnRequest,
    };
  });

export const bookingBuilderBasketReducer = (
  state: IBasketSubdomain = basketInitialState,
  action: Actions.BookingBuilderBasketAction
): IBasketSubdomain => {
  switch (action.type) {
    case Actions.SET_BASKET_HOTEL:
      return { ...state, hotelUuid: action.hotelUuid };
    case Actions.ADD_ROOM_TO_BASKET: {
      const addedRooms = [...state.rooms];
      addedRooms[action.roomIndex] = action.room;
      const newState = { ...state, rooms: addedRooms };

      if (state.rateType === null) {
        newState.rateType = action.room.externalRateId ? ERoomRateType.LIVE : ERoomRateType.STATIC;
      }

      return newState;
    }
    case Actions.REMOVE_ROOM_FROM_BASKET: {
      const rooms = state.rooms.map((room, roomIndex) => (roomIndex === action.roomIndex ? null : room));

      const newState = { ...state, rooms };
      if (rooms.filter(room => room !== null).length === 0) {
        newState.rateType = null;
      }

      return newState;
    }
    case Actions.CLEAR_ALL_ITEMS_FROM_BASKET:
      const rooms = new Array(action.accommodationsAmount).fill(null);
      return {
        ...state,
        rooms,
        supplements: [],
        total: null,
        totalBeforeDiscount: null,
        response: null,
        rateType: null,
      };
    case Actions.SYNC_BASKET_ACCOMMODATIONS: {
      let rooms: (IBasketRoom | null)[] = [];

      rooms = state.rooms.filter((_room, roomIndex) => !action.accommodationsToRemove.includes(roomIndex));

      if (action.accommodationsAmount === state.rooms.length) {
        return { ...state, rooms };
      }

      return {
        ...state,
        rooms: Array.from(new Array(action.accommodationsAmount)).map((_r, rIndex) => rooms[rIndex] || null),
      };
    }
    case Actions.UPDATE_BASKET_REQUEST:
      return {
        ...state,
        rooms: state.rooms.map(room => {
          if (room === null) {
            return null;
          }

          return { ...room, appliedOffers: [], total: null, totalBeforeDiscount: null };
        }),
        supplements: state.supplements.map(supplement => ({ ...supplement, total: null, singlePrice: null })),
        total: null,
        totalBeforeDiscount: null,
        networkRequests: {
          ...state.networkRequests,
          basketUpdating: ENetworkRequestStatus.PENDING,
        },
      };
    case Actions.UPDATE_BASKET_SUCCESS:
      return {
        ...state,
        response: action.response,
        rooms: getRoomsFromResponse(state.rooms, action.response),
        supplements: getSupplementsFromResponse(action.response),
        total: action.response.totals.total,
        totalBeforeDiscount: action.response.totals.totalBeforeDiscount,
        bookingHash: action.response.bookingHash ? action.response.bookingHash : '',
        networkRequests: {
          ...state.networkRequests,
          basketUpdating: ENetworkRequestStatus.SUCCESS,
        },
      };
    case Actions.UPDATE_BASKET_FAILURE:
      return {
        ...basketInitialState,
        hotelUuid: state.hotelUuid,
        response: null,
        networkRequests: {
          ...state.networkRequests,
          basketUpdating: ENetworkRequestStatus.ERROR,
        },
      };
    case Actions.SET_IS_REQUEST_BOOKING_MODAL_OPEN:
      return produce(state, draftState => {
        draftState.isRequestBookingModalOpen = action.val;
        return draftState;
      });

    case Actions.UPDATE_REQUEST_BOOKING_MODAL_DATA:
      return produce(state, draftState => {
        draftState.requestBookingModalData = {
          ...draftState.requestBookingModalData,
          ...action.data,
        };
        return draftState;
      });

    case Actions.REQUEST_BOOKING_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.requestBooking = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.REQUEST_BOOKING_SUCCESS:
      return produce(state, draftState => {
        draftState.networkRequests.requestBooking = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.REQUEST_BOOKING_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.requestBooking = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    default:
      return state;
  }
};
