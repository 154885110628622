import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import classnames from 'classnames';

import {
  statementFilterSetFromDateAction,
  statementFilterSetToDateAction,
  statementFilterSetStatusAction,
  statementFilterAddBookingRefAction,
  statementFilterSetBookingRefAction,
  statementFilterDeleteBookingRefAction,
  statementFilterSetTypesAction,
  statementFilterResetAction,
  statementFilterUpdateResultsAction,
  statementFilterSetAmountMinAction,
  statementFilterSetAmountMaxAction,
} from 'store/modules/ledger/actions';

import {
  statementFilterBookingRefsSelector,
  statementFilterFromDateSelector,
  statementFilterStatusSelector,
  statementFilterToDateSelector,
  statementFilterTypesSelector,
  statementFilterAmountMinSelector,
  statementFilterAmountMaxSelector,
} from 'store/modules/ledger/selectors';

import SingleDateInput from 'pureUi/SingleDateInput';
import { formatDate } from 'utils';
import classNames from 'classnames';
import { Multiselect } from 'ui/Multiselect';
import FluidButton from 'ui/FluidButton';

import { filterTypeOptions } from './FilterTypeOptions';
import { BookingRefInput } from './BookingRefInput';
import { theme } from '../../../../tailwind.config';
import PlusIcon from 'ui/Icons/plus.component.svg';
import { PriceAsCentsInput } from 'ui/stateful/PriceAsCentsInput';
import { SvgIcon } from 'ui/SvgIcon';
import InfoIcon from 'ui/Icons/components/Info.component';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';

interface IBookingLedgerFilterProps {
  isOpen: boolean;
}

export const FILTERS_ANIMATION_DURATION = 300;

export const BookingLedgerFilter: React.FC<IBookingLedgerFilterProps> = ({ isOpen }) => {
  const dispatch = useDispatch();

  const statementFilterFromDate = useSelector(statementFilterFromDateSelector);
  const statementFilterToDate = useSelector(statementFilterToDateSelector);
  const statementFilterTypes = useSelector(statementFilterTypesSelector);
  const statementFilterStatus = useSelector(statementFilterStatusSelector);
  const statementFilterBookingRefs = useSelector(statementFilterBookingRefsSelector);

  const statementFilterAmountMin = useSelector(statementFilterAmountMinSelector);
  const statementFilterAmountMax = useSelector(statementFilterAmountMaxSelector);

  const isAmountMinMaxError =
    statementFilterAmountMax !== null &&
    statementFilterAmountMin !== null &&
    statementFilterAmountMin > statementFilterAmountMax;

  const contentHeight = useMemo(() => (isOpen ? 'auto' : 0), [isOpen]);
  const addBookingRefButtonClass = `
    add-booking-ref w-5 h-5 rounded-full flex justify-center items-center border border-solid
    border-brown-prime cursor-pointer hover:bg-brown-20
  `;

  const handleAddBookingRefClick = useCallback(() => {
    dispatch(statementFilterAddBookingRefAction());
  }, [dispatch]);

  const handleResetFilters = useCallback(() => {
    dispatch(statementFilterResetAction());
    dispatch(statementFilterUpdateResultsAction());
  }, [dispatch]);

  const handleSearchClick = useCallback(() => {
    dispatch(statementFilterUpdateResultsAction());
  }, [dispatch]);

  return (
    // @ts-ignore
    <AnimateHeight className="animate-height" duration={FILTERS_ANIMATION_DURATION} height={contentHeight}>
      <div className="booking-filter px-10px py-15px bg-ivory mb-5 rounded mx-15px">
        <h2 className="text-17px font-bold mt-0">Filters and Search Options</h2>

        <div className="flex items-end flex-wrap">
          <div className="mr-5 mb-15px">
            <span className="block text-13px pb-5px">Start Date</span>
            <SingleDateInput
              value={statementFilterFromDate !== null ? new Date(statementFilterFromDate) : null}
              onChange={value => {
                dispatch(statementFilterSetFromDateAction(formatDate(value)));
              }}
              showYearDropdown
              enablePastDates={true}
              internalClassName={classNames('from-date', {
                dateInputField: true,
                dateNotSelected: statementFilterFromDate === null,
              })}
            />
          </div>

          <div className="mr-5 mb-15px">
            <span className="block text-13px pb-5px">End Date</span>
            <SingleDateInput
              value={statementFilterToDate !== null ? new Date(statementFilterToDate) : null}
              onChange={value => {
                dispatch(statementFilterSetToDateAction(formatDate(value)));
              }}
              showYearDropdown
              enablePastDates={true}
              internalClassName={classNames('to-date', {
                dateInputField: true,
                dateNotSelected: statementFilterToDate === null,
              })}
            />
          </div>

          {/* type */}
          <div className="mr-5 mb-15px">
            <span className="block text-13px pb-5px">Type</span>
            <Multiselect
              className="type-select w-420px bg-white text-15px"
              hideCheckboxes
              itemCtaClassName="hover:bg-teal-20 text-left"
              itemsClassname="bg-white"
              labelClassName="text-ellipsis overflow-hidden whitespace-nowrap"
              onUpdate={selectedValues => {
                dispatch(statementFilterSetTypesAction(selectedValues));
              }}
              options={filterTypeOptions}
              selectedValues={statementFilterTypes}
            />
          </div>

          {/* statuses */}
          <div className="mr-5 mb-15px">
            <span className="block text-13px pb-5px">Statuses</span>
            <Multiselect
              className="type-select min-w-400px bg-white text-15px"
              itemCtaClassName="hover:bg-teal-20 text-left"
              itemsClassname="bg-white"
              onUpdate={selectedValues => {
                dispatch(statementFilterSetStatusAction(selectedValues));
              }}
              options={[
                {
                  value: 'requested',
                  label: 'Requested',
                  iconHtml: <i className="fas fa-circle text-status-requested"></i>,
                  iconAlign: 'end',
                },
                {
                  value: 'confirmed',
                  label: 'Confirmed',
                  iconHtml: <i className="fas fa-circle text-status-confirmed"></i>,
                  iconAlign: 'end',
                },
                {
                  value: 'cancelled',
                  label: 'Cancelled',
                  iconHtml: <i className="fas fa-circle text-status-cancelled"></i>,
                  iconAlign: 'end',
                },
                {
                  value: 'completed',
                  label: 'Completed',
                  iconHtml: <i className="fas fa-circle text-status-completed"></i>,
                  iconAlign: 'end',
                },
              ]}
              selectedValues={statementFilterStatus == null ? [] : statementFilterStatus}
            />
          </div>

          {/* amount min and max */}
          <div className="mr-5 mb-15px flex flex-row items-center">
            <label className="block">
              <span className="block leading-2xs font-pt-sans text-13px text-black">Amount Min.</span>
              <PriceAsCentsInput
                className="bg-white global-statement-amount-min mt-5px h-37px w-100px border border-solid border-gray-40 text-15px px-10px py-2 focus:outline-gray-80"
                isAllowUndefined={true}
                disabled={false}
                onBlurUpdate={val => {
                  if (val !== statementFilterAmountMin) {
                    dispatch(statementFilterSetAmountMinAction(val));
                  }
                }}
                cents={statementFilterAmountMin !== null ? statementFilterAmountMin : undefined}
              />
            </label>

            <span className="mx-5px mt-4">-</span>

            <label className="block">
              <div className="flex justify-between leading-2xs items-center">
                <span className="font-pt-sans text-13px text-black">Amount Max.</span>
                <HidingTextTooltip
                  tooltipContent="These amounts filter the current booking balance."
                  position="right"
                  tooltipClassname="mt-25px"
                  width="112px"
                >
                  <span className="cursor-pointer h-4">
                    <SvgIcon
                      className="w-18px"
                      IconComponent={InfoIcon}
                      defaultFill={theme.colors['gray-80']}
                      hoverFill={theme.colors['gray-100']}
                      activeFill={theme.colors['gray-100']}
                    />
                  </span>
                </HidingTextTooltip>
              </div>
              <PriceAsCentsInput
                className={classnames(
                  'bg-white global-statement-amount-max mt-5px h-37px w-100px border border-solid text-15px px-10px py-2 focus:outline-gray-80',
                  {
                    'border-gray-40': !isAmountMinMaxError,
                    'border-red-95 bg-red-25': isAmountMinMaxError,
                  }
                )}
                isAllowUndefined={true}
                disabled={false}
                onBlurUpdate={val => {
                  if (val !== statementFilterAmountMax) {
                    dispatch(statementFilterSetAmountMaxAction(val));
                  }
                }}
                cents={statementFilterAmountMax !== null ? statementFilterAmountMax : undefined}
              />
            </label>
          </div>

          {statementFilterBookingRefs.map((br, brIndex) => {
            return (
              <div key={brIndex} className="booking-ref-inputs mr-5px mb-15px">
                <span className="block text-13px pb-5px">Booking Ref.</span>
                <BookingRefInput
                  bookingRef={br}
                  index={brIndex}
                  onChange={newValue => {
                    dispatch(statementFilterSetBookingRefAction(brIndex, newValue));
                  }}
                  onCrossButtonClick={() => {
                    if (brIndex === 0) {
                      dispatch(statementFilterSetBookingRefAction(brIndex, ''));
                    } else {
                      dispatch(statementFilterDeleteBookingRefAction(brIndex));
                    }
                  }}
                />
              </div>
            );
          })}

          <div className="ml-5px mb-6">
            <HidingTextTooltip
              tooltipContent="Add Booking Ref."
              position="bottom"
              tooltipContentClassname="add-booking-ref-tooltip px-[7px] py-[5px]"
            >
              <span className={addBookingRefButtonClass} onClick={handleAddBookingRefClick}>
                <PlusIcon fill={theme.colors['brown-prime']} />
              </span>
            </HidingTextTooltip>
          </div>
        </div>

        <div className="flex flex-row">
          <FluidButton className="reset-filters mr-2" type="secondary" onClick={handleResetFilters}>
            Reset Filters
          </FluidButton>
          <FluidButton className="search" type="primary" onClick={handleSearchClick}>
            Search
          </FluidButton>
        </div>
      </div>
    </AnimateHeight>
  );
};
