import React from 'react';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';

interface IDeleteTAModalProps {  
  onConfirm: () => void;
  onCancel: () => void;
  taHasBookings: boolean;
  isContentLoading?: boolean;
  isDeleteInProgress?: boolean;
}

export const DeleteTAModal: React.FC<IDeleteTAModalProps> = ({
  onConfirm, onCancel, taHasBookings, isContentLoading, isDeleteInProgress
}) => {
  if (isContentLoading) {
    return (
      <ConfirmationModal
        type={EConfirmationModalType.WARNING}
        className="delete-modal"
        isContentLoading={isContentLoading}
      />
    );
  }

  if (taHasBookings) {
    return (
      <ConfirmationModal
        type={EConfirmationModalType.WARNING}
        className="delete-modal"
        title="This TA can not be deleted."
        message="The TA has already operated in the app, it can not be deleted."
        confirmButtonLabel="OK"
        onConfirm={onCancel}
      />
    );
  }

  return (
    <ConfirmationModal
      type={EConfirmationModalType.WARNING}
      className="delete-modal"
      title="You are going to delete a Travel Agent."
      message="Are you sure want to delete this item?"
      confirmButtonLabel="Yes"
      cancelButtonLabel="No"
      onConfirm={onConfirm}
      onCancel={onCancel}
      isConfirmLoading={isDeleteInProgress}
      isContentLoading={isContentLoading}
    />
  );
};
