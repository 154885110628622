import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'ui/Link';

import AnimateHeight from 'react-animate-height';
import { formatDateDisplay } from 'utils';
import countries from 'config/data/countries.json';
import ArrowDown from 'ui/Icons/arrowDown.component.svg';
import Star from 'ui/Icons/star.component.svg';
import { theme } from '../../../tailwind.config';
import Checkbox from 'ui/Checkbox';
import { Price } from 'ui/Price';
import { PlainDates } from './PlainDates';
import { ProposalMoreInfo } from './ProposalMoreInfo';
import { IProposalBooking } from 'store/modules/proposalDetails/types';
import { SvgIcon } from 'ui/SvgIcon';
import InfoIcon from 'ui/Icons/info.full.component.svg';
import { HidingTooltip } from 'ui/Tooltip';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';

interface IProposalBookingInfoProps {
  booking: IProposalBooking;
  checkboxChecked: boolean;
  onToggleCheckbox: (selected: boolean) => void;
}

export const ProposalBookingInfo: React.FC<IProposalBookingInfoProps> = React.memo(
  ({ booking, checkboxChecked, onToggleCheckbox }) => {
    const priceClassName = 'font-hurmegeometric-sans text-19px leading-20px font-bold text-right mt-5px m-0';
    const isOnRequest = booking.totals.oneOrMoreItemsOnRequest;
    const hotel = booking.hotel;
    const numberOfRooms = booking.Accommodation?.length ?? 0;
    const numberOfGuests = booking.guestCount;
    const numberOfTransfers = booking.Transfer?.length ?? 0;
    const arrowClassname =
      'more-information w-6 h-6 flex justify-center items-center inline-block cursor-pointer hover:bg-gray-20 active:bg-gray-20';

    const [isMoreInfoOpen, setMoreInfoOpen] = useState(false);
    const toggleMoreInfo = useCallback(() => {
      setMoreInfoOpen(!isMoreInfoOpen);
    }, [isMoreInfoOpen, setMoreInfoOpen]);

    const handleCheckboxClick = useCallback(() => {
      onToggleCheckbox(!checkboxChecked);
    }, [checkboxChecked, onToggleCheckbox]);

    const offersApplied = booking.totals.total !== booking.totals.totalBeforeDiscount;
    const includesMargin = (booking.totals.marginPercentage || 0) > 0;

    return (
      <div className="proposal-info bg-gray-3 py-15px px-30px flex-1 min-h-175px">
        <div className="proposal-info-content flex-1 font-hurmegeometric-sans">
          <div className="top-line flex items-center justify-between">
            <p className="top-line-left font-hurmegeometric-sans text-13px leading-16px uppercase text-gold-light m-0">
              <span className="font-bold">CREATED ON:&nbsp;</span>
              <span className="font-normal">{formatDateDisplay(booking.createdAt)}</span>
            </p>
            <div className="top-line-right flex items-center gap-10px">
              <Link to={`/booking-manager/${booking.uuid}/overview`} target="_blank">
                <span className="inline-block text-brown-100 text-13px leading-16px underline cursor-pointer font-pt-sans hover:no-decoration active:no-decoration">
                  {booking.humanReadableId}
                </span>
              </Link>
              <Checkbox checked={checkboxChecked} onChange={handleCheckboxClick} />
            </div>
          </div>

          <div className="center-line flex justify-between mt-15px">
            <div className="center-line-left">
              <p className="hotel-stars flex items-center m-0">
                <span className="inline-flex">
                  <Star fill={theme.colors['brown-100']} />
                </span>
                <span className="font-hurmegeometric-sans text-xs leading-14px text-brown-100 uppercase ml-5px relative top-1px">
                  {hotel.starRating} STAR
                </span>
              </p>
              <p className="hotel-name font-noe-display text-xl leading-28px text-brown-100 tracking-1px mt-3px m-0">
                {hotel.name}
              </p>
              <p className="hotel-region font-hurmegeometric-sans text-sm leading-17px text-flint uppercase mt-3px m-0">
                {hotel.region}, {countries[hotel.countryCode].name}
              </p>
            </div>
            <div className="center-line-right">
              <div className="flex flex-row items-center justify-end">
                {!isOnRequest && includesMargin && (
                  <HidingTextTooltip 
                    tooltipContent="The amount includes the margin" 
                    position="left" 
                    width="200px"
                    tooltipWrapperClassname="margin-warning-tooltip text-flint"
                  >
                    <SvgIcon
                      className="margin-info-icon"
                      key={'icon'}
                      IconComponent={InfoIcon}
                      width="16px"
                      height="16px"
                      defaultFill={theme.colors['gray-80']}
                      hoverFill={theme.colors['gray-80']}
                      activeFill={theme.colors['gray-80']}
                    />
                  </HidingTextTooltip>
                )}

                <p className="total-cost ml-1 font-hurmegeometric-sans text-13px leading-16px text-flint uppercase font-bold m-0">
                  TOTAL COST TO YOU
                </p>
              </div>

              <Price
                total={booking.totals.total}
                totalBeforeDiscount={booking.totals.totalBeforeDiscount}
                currencyCode={booking.currency}
                isOnRequest={isOnRequest}
                totalClassName={`${priceClassName} text-flint`}
                totalBeforeDiscountClassName={`${priceClassName} text-gold-light line-through`}
                totalAfterDiscountClassName={`${priceClassName} text-red-15`}
                isOnRequestClassName={`${priceClassName} text-gold-light`}
              />
            </div>
          </div>

          <div className="bottom-line flex justify-between mt-15px">
            <div className="bottom-line-left flex">
              <PlainDates checkInDate={booking.checkInDate!} checkOutDate={booking.checkOutDate!} />
              <p className="booking-guests font-hurmegeometric-sans text-13px leading-16px text-flint uppercase ml-5 m-0">
                {numberOfGuests} {numberOfGuests === 1 ? 'GUEST' : 'GUESTS'}
              </p>
              <p className="booking-rooms font-hurmegeometric-sans text-13px leading-16px text-flint uppercase ml-5 m-0">
                {numberOfRooms} {numberOfRooms === 1 ? 'ROOM' : 'ROOMS'}
              </p>
              <p className="booking-transfers font-hurmegeometric-sans text-13px leading-16px text-flint uppercase ml-5 m-0">
                {numberOfTransfers} {numberOfTransfers === 1 ? 'TRANSFER' : 'TRANSFERS'}
              </p>
            </div>
            <div className="bottom-line-right">
              <span className={arrowClassname} onClick={toggleMoreInfo}>
                <span
                  className={classnames(
                    'flex justify-center items-center w-full h-full transform-transition transition-ease-in-out duration-300',
                    { 'rotate-180': isMoreInfoOpen }
                  )}
                  onClick={toggleMoreInfo}
                >
                  <ArrowDown fill={theme?.colors!['black']} />
                </span>
              </span>
            </div>
          </div>

          {/* @ts-ignore */}
          <AnimateHeight
            duration={300}
            height={isMoreInfoOpen ? 'auto' : 0}
            className={classnames(`accordion-area`, {})}
          >
            <ProposalMoreInfo booking={booking} />
          </AnimateHeight>
        </div>
      </div>
    );
  }
);
