import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  makeBookingManagerApi,
  IPaymentTermsResponse,
  IHeadlineBreakdownVersionListVersion,
} from 'services/BookingManagerApi';
import { GET_PAYMENT_TERMS_REQUEST, getPaymentTermsSuccessAction, getPaymentTermsFailureAction } from '../actions';

import { bookingUuidSelector } from '../../../selectors';
import * as BreakdownSelectors from '../selectors';
import * as BreakdownActions from '../actions';
export function* getPaymentTermsSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);
    const selectedVersion: IHeadlineBreakdownVersionListVersion | null = yield select(
      BreakdownSelectors.headlineBreakdownSelectedVersionSelector
    );

    const result: AxiosResponse<IPaymentTermsResponse> = yield call(
      bookingManagerApi.getPaymentTerms,
      bookingUuid,
      selectedVersion?.timestamp
    );

    yield put(getPaymentTermsSuccessAction(result.data.paymentTerms));
  } catch (e) {
    yield put(getPaymentTermsFailureAction(e));
  }
}

export function* watchBookingManagerPaymentTerms() {
  yield takeLatest(
    [GET_PAYMENT_TERMS_REQUEST, BreakdownActions.SET_HEADLINE_BREAKDOWN_SELECTED_VERSION],
    getPaymentTermsSaga
  );
}
