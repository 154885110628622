import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Actions from './actions';
import { update } from 'utils/immutable';
import { IInventorySuppliersDomain, initialState } from './model';

export const inventorySuppliersReducer = (
  state: IInventorySuppliersDomain = initialState,
  action: Actions.InventorySuppliersAction
): IInventorySuppliersDomain => {
  switch (action.type) {
    case Actions.LIST_REQUEST:
      return update(state, s => {
        s.request.list = ENetworkRequestStatus.PENDING;
      });

    case Actions.LIST_SUCCESS:
      return update(state, s => {
        s.request.list = ENetworkRequestStatus.SUCCESS;
        s.list.items = action.value;
        s.list.count = action.count;
      });
    
    case Actions.LIST_FAILURE:
      return update(state, s => {
        s.request.list = ENetworkRequestStatus.ERROR;
      });

    case Actions.SET_FILTER_ID:
      return update(state, s => {
        s.list.filter.public_id = action.value;
        s.list.filter.page = 1;
      });

    case Actions.SET_FILTER_NAME:
      return update(state, s => {
        s.list.filter.name = action.value;
        s.list.filter.page = 1;
      });

    case Actions.SET_FILTER_COUNTRY_CODE:
      return update(state, s => {
        s.list.filter.country_iso = action.value;
        s.list.filter.page = 1;
      });

    case Actions.SET_ORDER:
      return update(state, s => {
        s.list.filter.order_by = action.orderBy;
        s.list.filter.order_type = action.orderType;
        s.list.filter.page = 1;
      });

    case Actions.SET_PAGE:
      return update(state, s => {
        s.list.filter.page = action.value
      });

    case Actions.SET_PER_PAGE:
      return update(state, s => {
        s.list.filter.page_size = action.value;
        s.list.filter.page = 1;
      });

    case Actions.GET_SUPPLIER_REQUEST:
      return update(state, s => {
        s.request.supplierGet = ENetworkRequestStatus.PENDING;
      });

    case Actions.GET_SUPPLIER_SUCCESS:
      return update(state, s => {
        s.request.supplierGet = ENetworkRequestStatus.SUCCESS;
        s.supplier = action.supplier;
      });

    case Actions.GET_SUPPLIER_FAILURE:
      return update(state, s => {
        s.request.supplierGet = ENetworkRequestStatus.ERROR;
      });

    case Actions.RESET_SUPPLIER:
      return update(state, s => {
        s.supplier = null;
        s.request.supplierGet = ENetworkRequestStatus.IDLE;
      });
  
    case Actions.GET_SUPPLIER_BY_PUBLIC_ID_REQUEST:
      return update(state, s => {
        s.request.supplierForStayGet = ENetworkRequestStatus.PENDING;
      });

    case Actions.GET_SUPPLIER_BY_PUBLIC_ID_SUCCESS:
      return update(state, s => {
        s.request.supplierForStayGet = ENetworkRequestStatus.SUCCESS;
      });

    case Actions.GET_SUPPLIER_BY_PUBLIC_ID_FAILURE:
      return update(state, s => {
        s.request.supplierForStayGet = ENetworkRequestStatus.ERROR;
      });

    case Actions.POST_SUPPLIER_REQUEST:
      return update(state, s => {
        s.request.supplierPost = ENetworkRequestStatus.PENDING;
      });

    case Actions.POST_SUPPLIER_SUCCESS:
      return update(state, s => {
        s.request.supplierPost = ENetworkRequestStatus.SUCCESS;
      });

    case Actions.POST_SUPPLIER_FAILURE:
      return update(state, s => {
        s.request.supplierPost = ENetworkRequestStatus.ERROR;
      });

    case Actions.PUT_SUPPLIER_REQUEST:
      return update(state, s => {
        s.request.supplierPut = ENetworkRequestStatus.PENDING;
      });

    case Actions.PUT_SUPPLIER_SUCCESS:
      return update(state, s => {
        s.request.supplierPut = ENetworkRequestStatus.SUCCESS;
      });

    case Actions.PUT_SUPPLIER_FAILURE:
      return update(state, s => {
        s.request.supplierPut = ENetworkRequestStatus.ERROR;
      });
  
    default:
      return state;
  }
};

export default inventorySuppliersReducer;
