import React, { useRef, useReducer, useMemo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Transition } from 'react-transition-group';
import { BaseTooltip } from './BaseTooltip';

interface IHidingTooltipProps {
  children: React.ReactNode;
  renderTooltipContent: () => React.ReactNode;
  position: TooltipPosition;
  width?: string;
  tooltipClassname?: string;
  tooltipWrapperClassname?: string;
  elementClassname?: string;
  testId?: string;
  showShadow?: boolean;
  onRenderCallback?: () => void;
}

interface ITooltipCoords {
  x: number;
  y: number;
}

export type TooltipPosition =
  | 'top'
  | 'bottom'
  | 'bottom-right'
  | 'bottom-left'
  | 'left'
  | 'left-top'
  | 'left-bottom'
  | 'right'
  | 'right-top'
  | 'right-bottom'
  ;

interface ITooltipState {
  visible: boolean;
  coords?: ITooltipCoords;
}

interface ITooltipAction {
  type: 'HIDE_TOOLTIP' | 'SHOW_TOOLTIP';
  coords?: ITooltipCoords;
}

const getTooltipCoordsByPosition = (position: TooltipPosition, tooltipAnchorElement: HTMLDivElement, tooltipElement: HTMLDivElement) => {
  const TOOLTIP_OFFSET_TOP = 7;
  const TOOLTIP_OFFSET_BOTTOM = 2;
  const TOOLTIP_OFFSET_LEFT = 7;
  const TOOLTIP_OFFSET_RIGHT = 5;

  const bodyRect = document.body.getBoundingClientRect();
  const tooltipAnchorRect = tooltipAnchorElement.getBoundingClientRect();
  const tooltipRect = tooltipElement.getBoundingClientRect();

  // This is to account for when a modal is open. See src/ui/GeneralModal/index.tsx
  const bodyTopValue = parseInt(document.body.style.top || '0');

  const offsetY = tooltipAnchorRect.top - bodyRect.y - window.scrollY + bodyTopValue;
  const offsetX = tooltipAnchorRect.left - bodyRect.x - window.scrollX;

  switch (position) {
    case 'top':
    default:
      return {
        x: offsetX - tooltipRect.width / 2 + tooltipAnchorRect.width / 2,
        y: offsetY - tooltipRect.height - TOOLTIP_OFFSET_TOP,
      };
    case 'left':
      return {
        x: offsetX - tooltipRect.width - TOOLTIP_OFFSET_LEFT,
        y: offsetY + tooltipAnchorRect.height / 2 - tooltipRect.height / 2,
      };
    case 'left-top':
      return {
        x: offsetX - tooltipRect.width - TOOLTIP_OFFSET_LEFT,
        y: offsetY,
      };
    case 'left-bottom':
      return {
        x: offsetX - tooltipRect.width - TOOLTIP_OFFSET_LEFT,
        y: offsetY + tooltipAnchorRect.height + TOOLTIP_OFFSET_BOTTOM,
      };
    case 'right':
      return {
        x: offsetX + tooltipAnchorRect.width + TOOLTIP_OFFSET_RIGHT,
        y: offsetY + tooltipAnchorRect.height / 2 - tooltipRect.height / 2,
      };
    case 'right-top':
      return {
        x: offsetX + tooltipAnchorRect.width + TOOLTIP_OFFSET_RIGHT,
        y: offsetY,
      };
    case 'right-bottom':
      return {
        x: offsetX + tooltipAnchorRect.width + TOOLTIP_OFFSET_RIGHT,
        y: offsetY + tooltipAnchorRect.height + TOOLTIP_OFFSET_BOTTOM,
      };
    case 'bottom':
      return {
        x: offsetX - tooltipRect.width / 2 + tooltipAnchorRect.width / 2,
        y: offsetY + tooltipAnchorRect.height + TOOLTIP_OFFSET_BOTTOM,
      };
    case 'bottom-right':
      return {
        x: offsetX + tooltipAnchorRect.width / 2,
        y: offsetY + tooltipAnchorRect.height + TOOLTIP_OFFSET_BOTTOM,
      };
    case 'bottom-left':
      return {
        x: offsetX - tooltipAnchorRect.width * 1.5,
        y: offsetY + tooltipAnchorRect.height + TOOLTIP_OFFSET_BOTTOM,
      };
  }

  // THESE ARE THE OLD WAYS OF CALCULATING THE POSITION OF THE TOOLTIP
  switch (position) {
    case 'top':
      return {
        x: tooltipAnchorElement.offsetLeft - tooltipElement.clientWidth / 2 + tooltipAnchorElement.clientWidth / 2,
        y: tooltipAnchorElement.offsetTop - tooltipElement.clientHeight - TOOLTIP_OFFSET_TOP,
      };
    case 'bottom':
      return {
        x: tooltipAnchorElement.offsetLeft - tooltipElement.clientWidth / 2 + tooltipAnchorElement.clientWidth / 2,
        y: tooltipAnchorElement.offsetTop + tooltipAnchorElement.clientHeight + TOOLTIP_OFFSET_BOTTOM,
      };
    case 'bottom-right':
      return {
        x: tooltipAnchorElement.offsetLeft,
        y: tooltipAnchorElement.offsetTop + tooltipAnchorElement.clientHeight + TOOLTIP_OFFSET_BOTTOM,
      };
    case 'left':
      return {
        x: tooltipAnchorElement.offsetLeft - tooltipElement.clientWidth - TOOLTIP_OFFSET_LEFT,
        y: tooltipAnchorElement.offsetTop + tooltipAnchorElement.clientHeight / 2 - tooltipElement.clientHeight / 2,
      };
    case 'right':
      return {
        x: tooltipAnchorElement.offsetLeft + tooltipAnchorElement.clientWidth + TOOLTIP_OFFSET_RIGHT,
        y: tooltipAnchorElement.offsetTop + tooltipAnchorElement.clientHeight / 2 - tooltipElement.clientHeight / 2,
      };
    case 'right-bottom':
      return {
        x: tooltipAnchorElement.offsetLeft + tooltipAnchorElement.clientWidth + TOOLTIP_OFFSET_RIGHT,
        y: tooltipAnchorElement.offsetTop,
      };
    case 'bottom-left':
      return {
        x: tooltipAnchorElement.offsetLeft - tooltipElement.clientWidth + tooltipAnchorElement.clientWidth,
        y: tooltipAnchorElement.offsetTop + tooltipAnchorElement.clientHeight + TOOLTIP_OFFSET_BOTTOM,
      };
    case 'left-bottom':
      return {
        x: tooltipAnchorElement.offsetLeft - tooltipElement.clientWidth - TOOLTIP_OFFSET_LEFT,
        y: tooltipAnchorElement.offsetTop,
      };
    default:
      return {
        x: tooltipAnchorElement.offsetLeft - tooltipElement.clientWidth / 2 + tooltipAnchorElement.clientWidth / 2,
        y: tooltipAnchorElement.offsetTop + tooltipElement.clientHeight + 2,
      };
  }
};

const reducer = (state: ITooltipState, action: ITooltipAction) => {
  switch (action.type) {
    case 'HIDE_TOOLTIP':
      return { ...state, visible: false };
    case 'SHOW_TOOLTIP':
      return { visible: true, coords: action.coords };
  }
};

export const HidingTooltip: React.FC<IHidingTooltipProps> = React.memo((
  { children, renderTooltipContent, position, tooltipClassname, elementClassname = '', tooltipWrapperClassname = '', testId = 'hiding-tool-tip', showShadow = true, width, onRenderCallback }
) => {
  const [state, dispatch] = useReducer(
    reducer, { visible: false, coords: undefined }
  );
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleMouseLeave = () => {
    dispatch({ type: 'HIDE_TOOLTIP' });
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!tooltipRef.current) {
      return;
    }
    const element = event.currentTarget.children[0] as HTMLDivElement;
    if (!element) {
      throw new Error('Tooltip element is not found');
    }
    const tooltip = tooltipRef.current;
    const tooltipCoords = getTooltipCoordsByPosition(position, element, tooltip);
    dispatch({ type: 'SHOW_TOOLTIP', coords: tooltipCoords });
  };

  const tooltipDefaultStyle = useMemo(() => ({ transition: 'opacity 300ms ease-in-out', opacity: 0 }), []);
  const tooltipTransitionStyles = useMemo(
    () => ({
      entering: { opacity: 1, zIndex: 1 },
      entered: { opacity: 1, zIndex: 1 },
      exiting: { opacity: 0, zIndex: 1 },
      exited: { opacity: 0, zIndex: -1 },
    }),
    []
  );

  useEffect(() => {
    onRenderCallback ? onRenderCallback() : null;
  }, []);

  return (
    <div data-testid={testId} className={`relative tooltip-wrapper ${tooltipWrapperClassname}`}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`element-with-tooltip flex ${elementClassname}`}
      >
        {children}
      </div>
      <div className="tooltip">
        <Transition in={state.visible} appear timeout={300}>
          {tooltipAnimationState => (
            <BaseTooltip
              wrapperClassName={`border border-solid border-gray-40 rounded bg-white ${
                showShadow ? 'shadow-util1' : ''
              } ${tooltipClassname || ''}`}
              position={state.coords}
              tooltipRef={tooltipRef}
              style={{ ...tooltipDefaultStyle, ...tooltipTransitionStyles[tooltipAnimationState] }}
              width={width}
            >
              {renderTooltipContent()}
            </BaseTooltip>
          )}
        </Transition>
      </div>
    </div>
  );
});

HidingTooltip.displayName = 'HidingTooltip';
