import React, { useCallback, CSSProperties } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import * as Actions from 'store/modules/internalUsersList/actions';
import * as Selectors from 'store/modules/internalUsersList/selectors';

import { FormLabel } from 'ui/FormLabel';
import TextInput from 'pureUi/TextInput';
import { CountrySelect } from './countrySelect';
import { UserRoleSelect } from './userRoleSelect';
import { UserStatusSelect } from 'ui/UserStatusSelect';
import { ETravelAgentStatus } from 'services/BackendApi';

export interface FilterProps {
  className?: string;
}

const textInputStyles: CSSProperties = {
  fontFamily: "'PT Sans', sans-serif",
  height: '35px',
  fontSize: '16px',
  backgroundColor: '#FBFBFA',
};

const spanTextInputStyles: CSSProperties = {
  borderColor: '#D5D2D1',
};

export const Filter: React.FC<FilterProps> = props => {
  const value = useSelector(Selectors.filterSelector);

  const dispatch = useDispatch();

  const handleSearch = useCallback(e => dispatch(Actions.setSearchFilterAction(e.target.value || null)), [dispatch]);

  const handleEmail = useCallback(e => dispatch(Actions.setEmailFilterAction(e.target.value || null)), [dispatch]);

  const handleCountryCodeChange = useCallback(v => dispatch(Actions.setCountryCodeFilterAction(v)), [dispatch]);

  const handleStatusChange = useCallback(v => dispatch(Actions.setStatusFilterAction(v)), [dispatch]);

  const handleUserRoleChange = useCallback(v => dispatch(Actions.setUserRoleFilterAction(v)), [dispatch]);

  return (
    <div className={classNames(props.className, 'filter font-pt-sans')}>
      <p className="text-17px text-black font-bold leading-22px m-0">Filter Results</p>
      <div className="mt-10px flex items-end">
        <FormLabel className="country-code inline-block w-200px" text="User Role">
          <UserRoleSelect value={value.userRole} onChange={handleUserRoleChange} />
        </FormLabel>
        <FormLabel className="search inline-block w-200px ml-20px" text="Name">
          <TextInput
            style={textInputStyles}
            spanStyle={spanTextInputStyles}
            value={value.search || ''}
            onChange={handleSearch}
          />
        </FormLabel>
        <FormLabel className="country-code inline-block w-200px ml-20px" text="Country">
          <CountrySelect value={value.countryCode} onChange={handleCountryCodeChange} />
        </FormLabel>
        <FormLabel className="email inline-block w-220px ml-20px" text="Email">
          <TextInput
            style={textInputStyles}
            spanStyle={spanTextInputStyles}
            value={value.email || ''}
            onChange={handleEmail}
          />
        </FormLabel>
        <FormLabel className="status inline-block w-150px ml-20px" text="Status">
          <UserStatusSelect
            value={value.status}
            allowedStatuses={[ETravelAgentStatus.VERIFIED, ETravelAgentStatus.REJECTED]}
            onChange={handleStatusChange}
            labelWhenNothingSelected="All statuses" 
          />
        </FormLabel>
      </div>
    </div>
  );
};
