import React from 'react';
import { Helmet } from 'react-helmet';

export interface IWoopraSnippetProps {
  currentUserEmail: string;
}

export const WoopraSnippet = ({ currentUserEmail }: IWoopraSnippetProps) => {
  return (
    // @ts-ignore
    <Helmet>
      <script type="text/javascript">
        {`
          woopra && woopra.identify({
            email: "${currentUserEmail}"
          });
        
          // The identify code should be added before the "track()" function
          woopra && woopra.track();
        `}
      </script>
    </Helmet>
  );
};
