import React from 'react';
import {
  IHeadlineLineItemBreakdown,
  IHeadlineLineItemBreakdownLineItem,
  ITopNavigationData,
} from 'services/BookingManagerApi';
import { formatPrice, roundToDecimalPlaces } from 'utils';
import { EBookingManageRoutes } from 'containers/BookingManager/routes';
import styled from 'styled-components';
import classNames from 'classnames';
import { formatDateDisplay, getNumberOfDays } from 'utils/date';
import { isAfter } from 'date-fns';
import { calculateItemsPurchaseCostSubtotalCents, calculateItemsSaleCostSubtotalCents } from './utils';
import { HidingTooltip } from 'ui/Tooltip';

interface IOverviewHeadlineBreakdownProps {
  headlineLineItemBreakdown: IHeadlineLineItemBreakdown;
  topNavigationData: ITopNavigationData;
  bookingCurrencySymbol: string;
  isSr: boolean;
  isTaManager: boolean;
  isTa: boolean;
}

interface IReadOnlyProductBreakdown {
  className?: string;
  title: string;
  items: IHeadlineLineItemBreakdownLineItem[];
  preDiscountSubtotalCents: number;
  subtotalCents: number;
  purchaseCostSubtotalCents: number;
  bookingCurrencySymbol: string;
  isSr: boolean;
  isTaManager: boolean;
}

/**
 * we use a styled row here, so that the very last row, regardless
 * of the product type, will have some bottom padding inside of its td's
 */
const StyledRow = styled.tr`
  &:last-of-type {
    td {
      padding-bottom: 20px;
    }
  }
`;

const condPositiveSymbol = (value: number) => {
  return value && value > 0 ? '+' : null;
};

const bookingDatesAndNightRange = (topNavigationData: ITopNavigationData) => {
  if (
    !topNavigationData.arrivalDate ||
    topNavigationData.arrivalDate === '' ||
    !topNavigationData.departureDate ||
    topNavigationData.departureDate === ''
  ) {
    return '';
  }
  const numberOfNights =
    // @ts-ignore `getNumberOfDays` is defined with ramda nonsense that TS can't understand
    getNumberOfDays({
      from: new Date(topNavigationData.arrivalDate),
      to: new Date(topNavigationData.departureDate),
    });

  const datesAndRange = `${formatDateDisplay(topNavigationData.arrivalDate)} - ${formatDateDisplay(
    topNavigationData.departureDate
  )} / ${numberOfNights} N`;
  return datesAndRange;
};

const ReadOnlyProductBreakdown = (props: IReadOnlyProductBreakdown) => {
  return (
    <React.Fragment>
      <tr className={classNames(props.className, 'summary')}>
        <td className="pl-5 pt-5">
          <span className="title font-bold text-black">{props.title}</span>
        </td>
        <td></td>
        <td className="purchase-cost text-right pr-5 pt-5 align-top text-black">
          {props.isSr && (
            <span>
              {props.bookingCurrencySymbol}
              {formatPrice(props.purchaseCostSubtotalCents / 100)}
            </span>
          )}
        </td>
        <td className="cost-to-ta text-right pr-5 pt-5 align-top text-black">
          {props.bookingCurrencySymbol}
          {formatPrice(props.subtotalCents / 100)}
        </td>
      </tr>
      {props.items.map((lineItem, index) => (
        <StyledRow key={`${lineItem.title}${index}`} className={classNames(props.className, 'item')}>
          <td className="pl-5">
            <span className="title block text-black text-base">{lineItem.title}</span>
            <span className="details block text-black text-base">{lineItem.tertiaryText}</span>
          </td>
          <td colSpan={2}></td>
          <td className="pr-5">
            <InternalAndExternalSalesCostBreakdown
              isSr={props.isSr}
              isTaManager={props.isTaManager}
              bookingCurrencySymbol={props.bookingCurrencySymbol}
              lineItem={lineItem}
            />
          </td>
        </StyledRow>
      ))}
    </React.Fragment>
  );
};

const ReadOnlyProductBreakdownFinanceAdjustment = (props: IReadOnlyProductBreakdown) => {
  return (
    <React.Fragment>
      <tr className={classNames(props.className, 'summary')}>
        <td className="pl-5 pt-5 pb-5">
          <span className="title font-bold text-black">{props.title}</span>
        </td>
        <td></td>
        <td className="purchase-cost text-right pr-5 pt-5 align-top text-black">
          {props.isSr && (
            <span>
              {props.bookingCurrencySymbol}
              {formatPrice(props.purchaseCostSubtotalCents / 100)}
            </span>
          )}
        </td>
        <td className="cost-to-ta text-right pr-5 pt-5 align-top text-black"></td>
      </tr>
    </React.Fragment>
  );
};

// See https://pureescapes.atlassian.net/browse/OWA-5690
const InternalAndExternalSalesCostBreakdown = ({
  isSr,
  isTaManager,
  bookingCurrencySymbol,
  lineItem,
}: {
  isSr: boolean;
  isTaManager: boolean;
  bookingCurrencySymbol: string;
  lineItem: IHeadlineLineItemBreakdownLineItem;
}) => {
  return (
    <>
      {(isSr && lineItem.internalMarkupAmountCents && (
        <span className="flex items-center space-x-[5px] justify-end mt-[3px] w-120px">
          <span className="text-13px leading-16px text-gray-100">
            {condPositiveSymbol(lineItem.internalMarkupAmountCents)} {bookingCurrencySymbol}{' '}
            {formatPrice(lineItem.internalMarkupAmountCents! / 100)}
          </span>
          <HidingTooltip
            position="bottom"
            renderTooltipContent={() => {
              return <span className="block text-sm text-center py-2px w-[110px]">Internal Mark-up</span>;
            }}
          >
            <span className="w-[25px] uppercase bg-gray-10 text-center text-12px">In</span>
          </HidingTooltip>
        </span>
      )) ||
        null}
      {((isSr || isTaManager) && lineItem.companyExternalMarkupAmountCents && (
        <span className="flex items-center space-x-[5px] justify-end mt-[3px] w-120px">
          <span className="text-13px leading-16px text-gray-100">
            {condPositiveSymbol(lineItem.companyExternalMarkupAmountCents)} {bookingCurrencySymbol}{' '}
            {formatPrice(lineItem.companyExternalMarkupAmountCents! / 100)}
          </span>
          <HidingTooltip
            position="bottom"
            renderTooltipContent={() => {
              return <span className="block text-sm text-center py-2px w-[110px]">External Mark-up</span>;
            }}
          >
            <span className="w-[25px] uppercase bg-gray-10 text-center text-12px">Ex</span>
          </HidingTooltip>
        </span>
      )) ||
        null}
    </>
  );
};

export const InternalUserFooter = ({
  bookingCurrencySymbol,
  purchaseCostSubtotalCents,
  subtotalCents,
  ourNetProfitCents,
  tpMarkupAppliedCents,
}) => {
  const ourNetProfitPercentage =
    purchaseCostSubtotalCents === 0
      ? 100
      : roundToDecimalPlaces((ourNetProfitCents / purchaseCostSubtotalCents) * 100, 2);
  const tpMarkupAppliedPercentage =
    purchaseCostSubtotalCents === 0
      ? 100
      : roundToDecimalPlaces((tpMarkupAppliedCents / (purchaseCostSubtotalCents + ourNetProfitCents)) * 100, 2);
  return (
    <>
      <tr>
        <td className="pl-5">
          <span className="uppercase text-gray-100 text-13px leading-16px">Total Cost</span>
        </td>
        <td></td>
        <td className="p-5 text-right">
          <span className="purchase-cost font-bold">
            {bookingCurrencySymbol}
            {formatPrice(purchaseCostSubtotalCents! / 100)}
          </span>
        </td>
        <td className="p-5 py-2 text-right">
          <span className="cost-to-ta font-bold">
            {bookingCurrencySymbol}
            {formatPrice(subtotalCents! / 100)}
          </span>
        </td>
      </tr>
      <tr>
        <td className="pl-5">
          <span className="uppercase text-gray-100 text-13px leading-16px">Our Net Profit</span>
        </td>
        <td>{/* deliberately empty */}</td>
        <td>
          {/* percentage */}
          <span
            className={classNames('our-net-profit-percentage block pr-5 text-right', {
              'text-green-100': (ourNetProfitCents || 0) > 0,
              'text-red-100': (ourNetProfitCents || 0) < 0,
            })}
          >
            {ourNetProfitPercentage}%
          </span>
        </td>
        <td className="pr-5 py-2 text-right">
          {/* amount */}
          <span
            className={classNames('our-net-profit-cents font-bold', {
              'text-green-100': ourNetProfitCents > 0,
              'text-red-100': ourNetProfitCents < 0,
            })}
          >
            {condPositiveSymbol(ourNetProfitCents)} {bookingCurrencySymbol}
            {formatPrice(ourNetProfitCents! / 100)}
          </span>
        </td>
      </tr>

      {/* "tp mark up applied" row */}
      <tr>
        <td className="pl-5">
          <span className="uppercase text-gray-100 text-13px leading-16px">TP Mark-Up Applied</span>
        </td>
        <td>{/* deliberately empty */}</td>
        <td>
          {/* percentage */}
          <span className="block pr-5 text-right">{tpMarkupAppliedPercentage}%</span>
        </td>
        <td className="pr-5 py-2 text-right">
          {/* amount */}
          <span className={classNames('tp-markup-applied font-bold')}>
            {condPositiveSymbol(tpMarkupAppliedCents)} {bookingCurrencySymbol}
            {formatPrice(tpMarkupAppliedCents! / 100)}
          </span>
        </td>
      </tr>
      <tr>
        <td colSpan={4} className="p-5">
          <a
            className="edit text-brown-120 hover:text-brown-140 font-bold underline"
            href={EBookingManageRoutes.Breakdown}
          >
            Edit breakdown
          </a>
        </td>
      </tr>
    </>
  );
};

export const TaFooter = ({ bookingCurrencySymbol, subtotalCents }) => {
  return (
    <>
      <tr>
        <td className="p-5">
          <a
            className="edit text-brown-120 hover:text-brown-140 font-bold underline"
            href={EBookingManageRoutes.Breakdown}
          >
            View breakdown
          </a>
        </td>
        <td></td>
        <td className="p-5 text-right"></td>
        <td className="p-5 text-right">
          <span className="cost-to-ta font-bold">
            {bookingCurrencySymbol}
            {formatPrice(subtotalCents! / 100)}
          </span>
        </td>
      </tr>
    </>
  );
};

export const TaManagerFooter = ({
  bookingCurrencySymbol,
  purchaseCostSubtotalCents,
  subtotalCents,
  ourNetProfitCents,
  tpMarkupAppliedCents,
}) => {
  const tpMarkupAppliedPercentage =
    purchaseCostSubtotalCents === 0
      ? 100
      : roundToDecimalPlaces((tpMarkupAppliedCents / (purchaseCostSubtotalCents + ourNetProfitCents)) * 100, 2);

  return (
    <>
      <tr>
        <td className="pl-5">
          <span className="uppercase text-gray-100 text-13px leading-16px">Total Cost</span>
        </td>
        <td colSpan={2}></td>
        <td className="p-5 py-2 text-right">
          <span className="cost-to-ta font-bold">
            {bookingCurrencySymbol}
            {formatPrice(subtotalCents! / 100)}
          </span>
        </td>
      </tr>
      <tr>
        <td className="pl-5">
          <span className="uppercase text-gray-100 text-13px leading-16px">TP Mark-Up Applied</span>
        </td>
        <td>{/* deliberately empty */}</td>
        <td>
          {/* percentage */}
          <span className="block pr-5 text-right">{tpMarkupAppliedPercentage}%</span>
        </td>
        <td className="pr-5 py-2 text-right">
          {/* amount */}
          <span className="cost-to-ta font-bold">
            {condPositiveSymbol(tpMarkupAppliedCents)} {bookingCurrencySymbol}
            {formatPrice(tpMarkupAppliedCents! / 100)}
          </span>
        </td>
      </tr>
      <tr>
        <td colSpan={4} className="p-5">
          <a
            className="edit text-brown-120 hover:text-brown-140 font-bold underline"
            href={EBookingManageRoutes.Breakdown}
          >
            Edit breakdown
          </a>
        </td>
      </tr>
    </>
  );
};

export const OverviewHeadlineBreakdown = (props: IOverviewHeadlineBreakdownProps) => {
  const containerClassName = `
    overview-headline-breakdown
    min-h-150px font-pt-sans mb-8
    border border-solid border-gray-40 shadow-pe1 font-pt-sans"
  `;

  return (
    <div className={containerClassName}>
      <table className="w-full" style={{ borderSpacing: '0' }}>
        <thead className="bg-brown-10">
          <tr>
            <th className="p-5 text-left">
              <span className="hotel-name block font-bold text-base text-black">
                {props.topNavigationData.hotelName}
              </span>
              <span className="stay-dates block text-base text-black font-normal">
                {bookingDatesAndNightRange(props.topNavigationData)}
              </span>
            </th>

            <th></th>

            <th className="text-right pl-5 pr-5 pt-5 align-top">
              {props.isSr && <span className="purchase-cost uppercase text-gray-100 text-xs">Purchase Cost</span>}
            </th>

            <th className="text-right p-5 pt-5 align-top">
              <span className="cost-to-ta uppercase text-gray-100 text-xs">Sale Cost</span>
            </th>
          </tr>
        </thead>

        <tbody>
          {/* accommodation */}
          {props.headlineLineItemBreakdown.Accommodation.items.map(accomItem => {
            const { isActive, holdHours, expires } = accomItem.hold || { isActive: false };
            const hasExpired = Boolean(expires && isAfter(new Date(), new Date(expires)));
            const permanentlyHeld = isActive === true && holdHours == null && expires == null;
            const showHoldIcon = permanentlyHeld || (isActive && !hasExpired);

            let accomRows = [
              <tr key={`accom-${accomItem.title}-${accomItem.tertiaryText}`} className="accommodation item">
                <td className="pl-5 pt-5 relative">
                  <span className="name block font-bold text-black">{accomItem.title}</span>
                  <span className="details block text-black">{accomItem.tertiaryText}</span>
                </td>
                <td className="pt-5 pr-5 align-top">
                  {showHoldIcon && (
                    <span className="held fa-stack" style={{ verticalAlign: 'top' }}>
                      <i className="text-teal-20 fas fa-circle fa-stack-2x"></i>
                      <i className="text-teal-100 fas fa-thumbtack fa-stack-1x"></i>
                    </span>
                  )}
                </td>
                <td className="text-right pt-5 pr-5 align-top">
                  {props.isSr && (
                    <span className="purchase-cost text-black">
                      {props.bookingCurrencySymbol}
                      {formatPrice(accomItem.purchaseCostCents! / 100)}
                    </span>
                  )}
                </td>
                <td className="text-right pt-5 pr-5 align-top">
                  <span className="cost-to-ta text-black">
                    {props.bookingCurrencySymbol}
                    {formatPrice(accomItem.saleCostCents! / 100)}
                  </span>

                  <InternalAndExternalSalesCostBreakdown
                    isSr={props.isSr}
                    isTaManager={props.isTaManager}
                    bookingCurrencySymbol={props.bookingCurrencySymbol}
                    lineItem={accomItem}
                  />
                </td>
              </tr>,
            ];

            if (accomItem['Meal Plan'].items.length >= 1) {
              const mealPlanPurchaseCostSubtotalCents = calculateItemsPurchaseCostSubtotalCents(
                accomItem['Meal Plan'].items
              );
              const mealPlanSubtotalCents = calculateItemsSaleCostSubtotalCents(accomItem['Meal Plan'].items);

              accomRows = accomRows.concat([
                <tr
                  key={`meal-plan-summary-${accomItem.title}-${accomItem.tertiaryText}`}
                  className="meal-plan summary"
                >
                  <td className="pl-5 pt-5">
                    <span className="title font-bold text-black">Meal Plan</span>
                  </td>
                  <td></td>
                  <td className="purchase-cost text-right pl-5 pt-5 pr-5 align-top text-black">
                    {props.isSr && (
                      <span>
                        {props.bookingCurrencySymbol}
                        {formatPrice(mealPlanPurchaseCostSubtotalCents / 100)}
                      </span>
                    )}
                  </td>
                  <td className="cost-to-ta text-right pl-5 pt-5 pr-5 align-top text-black">
                    {props.bookingCurrencySymbol}
                    {formatPrice(mealPlanSubtotalCents / 100)}
                  </td>
                </tr>,
              ]);
            }

            accomRows = accomRows.concat(
              accomItem['Meal Plan'].items.map(mealPlan => {
                return (
                  <tr key={`meal-plan-item-${accomItem.title}-${accomItem.tertiaryText}`} className="meal-plan item">
                    <td className="pl-5" colSpan={3}>
                      <span className="title block text-black">{mealPlan.title}</span>
                      <span className="details block text-black">{mealPlan.tertiaryText}</span>
                    </td>
                    <td className="pr-5">
                      <InternalAndExternalSalesCostBreakdown
                        isSr={props.isSr}
                        isTaManager={props.isTaManager}
                        bookingCurrencySymbol={props.bookingCurrencySymbol}
                        lineItem={mealPlan}
                      />
                    </td>
                  </tr>
                );
              })
            );

            if (accomItem.Supplement.items.length >= 1) {
              const epsPurchaseCostSubtotalCents = calculateItemsPurchaseCostSubtotalCents(accomItem.Supplement.items);
              const epsSubtotalCents = calculateItemsSaleCostSubtotalCents(accomItem.Supplement.items);

              accomRows = accomRows.concat([
                <tr
                  key={`supplement-summary-${accomItem.title}-${accomItem.tertiaryText}`}
                  className="supplement summary"
                >
                  <td className="pl-5 pt-5">
                    <span className="title font-bold text-black">Extra Person Supplement</span>
                  </td>
                  <td></td>

                  <td className="purchase-cost text-right pl-5 pr-5 pt-5 align-top text-black">
                    {props.isSr && (
                      <span>
                        {props.bookingCurrencySymbol}
                        {formatPrice(epsPurchaseCostSubtotalCents / 100)}
                      </span>
                    )}
                  </td>
                  <td className="cost-to-ta text-right pl-5 pr-5 pt-5 align-top text-black">
                    {props.bookingCurrencySymbol}
                    {formatPrice(epsSubtotalCents / 100)}
                  </td>
                </tr>,
              ]);

              accomRows = accomRows.concat(
                accomItem.Supplement.items.map(supplement => {
                  return (
                    <tr
                      key={`supplement-item-${accomItem.title}-${accomItem.tertiaryText}`}
                      className="supplement item"
                    >
                      <td className="pl-5" colSpan={2}>
                        <span className="title block text-black">{supplement.title}</span>
                        <span className="details block text-black">{supplement.tertiaryText}</span>
                      </td>
                      <td>{/* deliberately blank */}</td>
                      <td className="pr-5">
                        <InternalAndExternalSalesCostBreakdown
                          isSr={props.isSr}
                          isTaManager={props.isTaManager}
                          bookingCurrencySymbol={props.bookingCurrencySymbol}
                          lineItem={supplement}
                        />
                      </td>
                    </tr>
                  );
                })
              );
            }

            return accomRows;
          })}

          {/* transfers */}
          {props.headlineLineItemBreakdown.Transfer.items.length >= 1 && (
            <ReadOnlyProductBreakdown
              className="transfer"
              title="Transfer"
              bookingCurrencySymbol={props.bookingCurrencySymbol}
              items={props.headlineLineItemBreakdown.Transfer.items}
              preDiscountSubtotalCents={props.headlineLineItemBreakdown.Transfer.preDiscountSubtotalCents}
              subtotalCents={props.headlineLineItemBreakdown.Transfer.subtotalCents}
              purchaseCostSubtotalCents={props.headlineLineItemBreakdown.Transfer.purchaseCostSubtotalCents || 0}
              isSr={props.isSr}
              isTaManager={props.isTaManager}
            />
          )}

          {/* ground services */}
          {props.headlineLineItemBreakdown['Ground Service'].items.length >= 1 && (
            <ReadOnlyProductBreakdown
              className="ground-service"
              title="Ground Service"
              bookingCurrencySymbol={props.bookingCurrencySymbol}
              items={props.headlineLineItemBreakdown['Ground Service'].items}
              preDiscountSubtotalCents={props.headlineLineItemBreakdown['Ground Service'].preDiscountSubtotalCents}
              subtotalCents={props.headlineLineItemBreakdown['Ground Service'].subtotalCents}
              isSr={props.isSr}
              isTaManager={props.isTaManager}
              purchaseCostSubtotalCents={
                props.headlineLineItemBreakdown['Ground Service'].purchaseCostSubtotalCents || 0
              }
            />
          )}

          {/* supplement */}
          {props.headlineLineItemBreakdown.Supplement.items.length >= 1 && (
            <ReadOnlyProductBreakdown
              className="supplement"
              title="Supplement"
              bookingCurrencySymbol={props.bookingCurrencySymbol}
              items={props.headlineLineItemBreakdown.Supplement.items}
              preDiscountSubtotalCents={props.headlineLineItemBreakdown.Supplement.preDiscountSubtotalCents}
              subtotalCents={props.headlineLineItemBreakdown.Supplement.subtotalCents}
              isSr={props.isSr}
              isTaManager={props.isTaManager}
              purchaseCostSubtotalCents={props.headlineLineItemBreakdown.Supplement.purchaseCostSubtotalCents || 0}
            />
          )}

          {/* fine */}
          {props.headlineLineItemBreakdown.Fine.items.length >= 1 && (
            <ReadOnlyProductBreakdown
              className="fine"
              title="Additional Hotel Charges"
              bookingCurrencySymbol={props.bookingCurrencySymbol}
              items={props.headlineLineItemBreakdown.Fine.items}
              preDiscountSubtotalCents={props.headlineLineItemBreakdown.Fine.preDiscountSubtotalCents}
              subtotalCents={props.headlineLineItemBreakdown.Fine.subtotalCents}
              isSr={props.isSr}
              isTaManager={props.isTaManager}
              purchaseCostSubtotalCents={props.headlineLineItemBreakdown.Fine.purchaseCostSubtotalCents || 0}
            />
          )}

          {/* bespoke */}
          {props.headlineLineItemBreakdown.Bespoke.items.length >= 1 && (
            <ReadOnlyProductBreakdown
              className="bespoke"
              title="Activities"
              bookingCurrencySymbol={props.bookingCurrencySymbol}
              items={props.headlineLineItemBreakdown.Bespoke.items}
              preDiscountSubtotalCents={props.headlineLineItemBreakdown.Bespoke.preDiscountSubtotalCents}
              subtotalCents={props.headlineLineItemBreakdown.Bespoke.subtotalCents}
              isSr={props.isSr}
              isTaManager={props.isTaManager}
              purchaseCostSubtotalCents={props.headlineLineItemBreakdown.Bespoke.purchaseCostSubtotalCents || 0}
            />
          )}

          {/* finance adjustment */}
          {!props.isTa && (props.headlineLineItemBreakdown['Finance Adjustment']?.items?.length || 0) >= 1 && (
            <ReadOnlyProductBreakdownFinanceAdjustment
              className="finance-adjustment"
              title="Finance Adjustment"
              bookingCurrencySymbol={props.bookingCurrencySymbol}
              items={props.headlineLineItemBreakdown['Finance Adjustment']?.items ?? []}
              preDiscountSubtotalCents={0}
              subtotalCents={0}
              isSr={props.isSr}
              isTaManager={props.isTaManager}
              purchaseCostSubtotalCents={
                props.headlineLineItemBreakdown['Finance Adjustment']?.purchaseCostSubtotalCents ?? 0
              }
            />
          )}
        </tbody>

        <tfoot className="bg-brown-10">
          {!props.isSr && !props.isTaManager && (
            <TaFooter
              bookingCurrencySymbol={props.bookingCurrencySymbol}
              subtotalCents={props.headlineLineItemBreakdown.SubtotalCents}
            />
          )}
          {props.isSr && !props.isTaManager && (
            <>
              <InternalUserFooter
                bookingCurrencySymbol={props.bookingCurrencySymbol}
                purchaseCostSubtotalCents={props.headlineLineItemBreakdown.PurchaseCostSubtotalCents}
                subtotalCents={props.headlineLineItemBreakdown.SubtotalCents}
                ourNetProfitCents={props.headlineLineItemBreakdown.InternalMarkupAmountSubtotalCents}
                tpMarkupAppliedCents={props.headlineLineItemBreakdown.CompanyExternalMarkupAmountSubtotalCents}
              />
            </>
          )}
          {props.isTaManager && (
            <>
              <TaManagerFooter
                bookingCurrencySymbol={props.bookingCurrencySymbol}
                purchaseCostSubtotalCents={props.headlineLineItemBreakdown.PurchaseCostSubtotalCents}
                subtotalCents={props.headlineLineItemBreakdown.SubtotalCents}
                tpMarkupAppliedCents={props.headlineLineItemBreakdown.CompanyExternalMarkupAmountSubtotalCents}
                ourNetProfitCents={props.headlineLineItemBreakdown.InternalMarkupAmountSubtotalCents}
              />
            </>
          )}
        </tfoot>
      </table>
    </div>
  );
};
