import { ITransfersSubdomain, transfersInitialState } from './model';
import * as Actions from './actions';
import { produce } from 'immer';
import { ISelectedTransfer, ETransferDirection } from './types';
import { flatten, reject, uniq } from 'ramda';

/**
 * we check all the transfers to see if we need to add any missing `in` returns.
 * e.g room 0 shares its `return out` with room 1 - room 1 now needs to add a `return in` for that matching transfer
 */
const addMissingReturnInTransfers = (draftState: ITransfersSubdomain) => {
  const roomIndexes = uniq(flatten(draftState.selectedTransfers.map(s => s.roomIdxs)));

  // find any rooms that have a return in that DONT have an out
  let missingIns: number[] = [];
  roomIndexes.forEach(roomIndex => {
    if (
      draftState.selectedTransfers.some(
        st => st.isReturn && st.roomIdxs.includes(roomIndex) && st.direction === ETransferDirection.OUT
      ) &&
      !draftState.selectedTransfers.some(
        st => st.isReturn && st.roomIdxs.includes(roomIndex) && st.direction === ETransferDirection.IN
      )
    ) {
      missingIns.push(roomIndex);
    }
  });

  missingIns = uniq(flatten(missingIns));

  // for each missing out, find the in, and then add a new out for that matching in
  missingIns.forEach(outRoomIndex => {
    const matchingOut = draftState.selectedTransfers.find(
      st => st.isReturn && st.roomIdxs.includes(outRoomIndex) && st.direction === ETransferDirection.OUT
    );
    if (matchingOut) {
      draftState.selectedTransfers.push({
        direction: ETransferDirection.IN,
        isReturn: true,
        roomIdxs: [outRoomIndex],
        uuid: matchingOut?.uuid,
      });
    }
  });

  return draftState;
};

/**
 * sister to the above, but in the other direction
 */
const addMissingReturnOutTransfers = (draftState: ITransfersSubdomain) => {
  const roomIndexes = uniq(flatten(draftState.selectedTransfers.map(s => s.roomIdxs)));

  // find any rooms that have a return in that DONT have an out
  let missingOuts: number[] = [];
  roomIndexes.forEach(roomIndex => {
    if (
      draftState.selectedTransfers.some(
        st => st.isReturn && st.roomIdxs.includes(roomIndex) && st.direction === ETransferDirection.IN
      ) &&
      !draftState.selectedTransfers.some(
        st => st.isReturn && st.roomIdxs.includes(roomIndex) && st.direction === ETransferDirection.OUT
      )
    ) {
      missingOuts.push(roomIndex);
    }
  });
  missingOuts = uniq(flatten(missingOuts));

  // for each missing out, find the in, and then add a new out for that matching in
  missingOuts.forEach(outRoomIndex => {
    const matchingIn = draftState.selectedTransfers.find(
      st => st.isReturn && st.roomIdxs.includes(outRoomIndex) && st.direction === ETransferDirection.IN
    );
    if (matchingIn) {
      draftState.selectedTransfers.push({
        direction: ETransferDirection.OUT,
        isReturn: true,
        roomIdxs: [outRoomIndex],
        uuid: matchingIn?.uuid,
      });
    }
  });

  return draftState;
};

const removeRedundantStandaloneReturnInTransfers = (draftState: ITransfersSubdomain) => {
  const roomIndexes = uniq(flatten(draftState.selectedTransfers.map(s => s.roomIdxs)));

  let arePartOfShareAndHaveStandalone: number[] = [];

  // find all the room indexes that are part of a share transfer out && have a transfer return out thats just them
  roomIndexes.forEach(roomIndex => {
    if (
      draftState.selectedTransfers.some(
        st =>
          st.direction === ETransferDirection.IN &&
          st.isReturn &&
          st.roomIdxs.includes(roomIndex) &&
          st.roomIdxs.length >= 2 // its part of a share...
      ) &&
      draftState.selectedTransfers.some(
        st =>
          st.direction === ETransferDirection.IN &&
          st.isReturn &&
          st.roomIdxs.includes(roomIndex) &&
          st.roomIdxs.length === 1 // ...and also has its own
      )
    ) {
      arePartOfShareAndHaveStandalone.push(roomIndex);
    }
  });
  arePartOfShareAndHaveStandalone = uniq(flatten(arePartOfShareAndHaveStandalone));

  // for each of these, remove the standalone
  arePartOfShareAndHaveStandalone.forEach(roomIndex => {
    draftState.selectedTransfers = draftState.selectedTransfers.filter(
      st =>
        st.direction === ETransferDirection.IN &&
        st.isReturn &&
        st.roomIdxs.includes(roomIndex) &&
        st.roomIdxs.length === 1
    );
  });

  return draftState;
};

const removeRedundantStandaloneReturnOutTransfers = (draftState: ITransfersSubdomain) => {
  const roomIndexes = uniq(flatten(draftState.selectedTransfers.map(s => s.roomIdxs)));

  let arePartOfShareAndHaveStandalone: number[] = [];

  // find all the room indexes that are part of a share transfer out && have a transfer return out thats just them
  roomIndexes.forEach(roomIndex => {
    if (
      draftState.selectedTransfers.some(
        st =>
          st.direction === ETransferDirection.OUT &&
          st.isReturn &&
          st.roomIdxs.includes(roomIndex) &&
          st.roomIdxs.length >= 2 // its part of a share...
      ) &&
      draftState.selectedTransfers.some(
        st =>
          st.direction === ETransferDirection.OUT &&
          st.isReturn &&
          st.roomIdxs.includes(roomIndex) &&
          st.roomIdxs.length === 1 // ...and also has its own
      )
    ) {
      arePartOfShareAndHaveStandalone.push(roomIndex);
    }
  });
  arePartOfShareAndHaveStandalone = uniq(flatten(arePartOfShareAndHaveStandalone));

  // for each of these, remove the standalone
  arePartOfShareAndHaveStandalone.forEach(roomIndex => {
    draftState.selectedTransfers = draftState.selectedTransfers.filter(
      st =>
        st.direction === ETransferDirection.OUT &&
        st.isReturn &&
        st.roomIdxs.includes(roomIndex) &&
        st.roomIdxs.length === 1
    );
  });

  return draftState;
};

const addTransfer = (action: Actions.AddTransferAction, draftState: ITransfersSubdomain) => {
  switch (action.isReturn) {
    case true:
      draftState = addReturnTransfer(action, draftState);

      // if something has been shared, we need to...
      draftState = addMissingReturnInTransfers(draftState);
      draftState = addMissingReturnOutTransfers(draftState);

      // and if a share has just been completed, we need to
      draftState = removeRedundantStandaloneReturnInTransfers(draftState);
      draftState = removeRedundantStandaloneReturnOutTransfers(draftState);

      break;
    case false:
      addNonReturnTransfer(action, draftState);
      break;
  }
};

const shareReturnTransfer = (action: Actions.AddTransferAction, draftState: ITransfersSubdomain) => {
  const { direction, roomIndexes } = action;

  // loop over all action.roomIndexes
  // remove roomIndexes that include roomIndexes from action
  // AND has same direction
  roomIndexes.forEach(roomIndex => {
    draftState.selectedTransfers.forEach(st => {
      if (direction === st.direction) {
        const transferIndex = st.roomIdxs.indexOf(roomIndex);
        if (transferIndex >= 0) {
          st.roomIdxs.splice(st.roomIdxs.indexOf(roomIndex), 1);
        }
      }
    });
  });

  // cleanup
  // remove existing transerfs that has no rooms assigned
  draftState.selectedTransfers = draftState.selectedTransfers.filter(st => st.roomIdxs.length > 0);

  // add return 1/2 direction transfer
  draftState.selectedTransfers.push({
    uuid: action.transferUuid,
    roomIdxs: roomIndexes,
    isReturn: true,
    direction: direction as ETransferDirection,
  });

  return draftState;
};

const addReturnTransfer = (action: Actions.AddTransferAction, draftState: ITransfersSubdomain) => {
  const { roomIndexes, isViaShare } = action;

  if (isViaShare) {
    return shareReturnTransfer(action, draftState);
  }

  // loop over all action.roomIndexes and remove other items that include the index
  roomIndexes.forEach(roomIndex => {
    draftState.selectedTransfers.forEach(st => {
      const transferIndex = st.roomIdxs.indexOf(roomIndex);
      if (transferIndex >= 0) {
        st.roomIdxs.splice(st.roomIdxs.indexOf(roomIndex), 1);
      }
    });
  });

  // cleanup
  // remove existing transerfs that has no rooms assigned
  draftState.selectedTransfers = draftState.selectedTransfers.filter(st => st.roomIdxs.length > 0);

  // create transfer object common properties, direction will be injected
  const transfer = { uuid: action.transferUuid, roomIdxs: roomIndexes, isReturn: true };

  // add transfer in
  draftState.selectedTransfers.push({ ...transfer, direction: ETransferDirection.IN });
  // add transfer out
  draftState.selectedTransfers.push({ ...transfer, direction: ETransferDirection.OUT });

  return draftState;
};

const addNonReturnTransfer = (action: Actions.AddTransferAction, draftState: ITransfersSubdomain) => {
  const { direction, roomIndexes } = action;

  let oneDirectionRemoved = false;
  // loop over all action.roomIndexes
  // remove roomIndexes that include roomIndexes from action
  // AND has same direction
  roomIndexes.forEach(roomIndex => {
    draftState.selectedTransfers.forEach(st => {
      const transferIndex = st.roomIdxs.indexOf(roomIndex);
      if (transferIndex < 0) {
        return;
      }

      if (st.direction === direction) {
        st.roomIdxs.splice(st.roomIdxs.indexOf(roomIndex), 1);
        oneDirectionRemoved = true;
      }
    });
  });

  // cleanup
  // remove other return transfers in case the other direction can be found
  if (oneDirectionRemoved) {
    draftState.selectedTransfers
      .filter(st => st.isReturn && action.roomIndexes.some(idx => st.roomIdxs.includes(idx)))
      .forEach(st => {
        action.roomIndexes
          .filter(idx => st.roomIdxs.includes(idx))
          .forEach(i => {
            st.roomIdxs.splice(st.roomIdxs.indexOf(i), 1);
          });
      });
  }

  // remove existing transerfs that has no rooms assigned
  draftState.selectedTransfers = draftState.selectedTransfers.filter(st => st.roomIdxs.length > 0);

  // add new transfer
  draftState.selectedTransfers.push({
    uuid: action.transferUuid,
    direction: direction as ETransferDirection,
    roomIdxs: roomIndexes,
    isReturn: false,
  });

  return draftState;
};

export const bookingBuilderTransfersReducer = (
  state: ITransfersSubdomain = transfersInitialState,
  action: Actions.BookingBuilderTransfersAction
): ITransfersSubdomain => {
  switch (action.type) {
    case Actions.ADD_TRANSFER:
      return produce(state, draftState => addTransfer(action, draftState));
    case Actions.DELETE_TRANSFER:
      return produce(state, draftState => {
        if (action.isViaShare) {
          draftState.selectedTransfers = draftState.selectedTransfers.map(transfer => {
            if (transfer.uuid !== action.transferUuid) {
              return transfer;
            }

            if (transfer.direction !== action.direction) {
              return transfer;
            }

            if (transfer.roomIdxs.includes(action.roomIndexes[0])) {
              transfer.roomIdxs.splice(transfer.roomIdxs.indexOf(action.roomIndexes[0]), 1);
            }

            return transfer;
          });
        } else if (action.isReturn) {
          draftState.selectedTransfers = draftState.selectedTransfers.filter(
            transfer =>
              transfer.uuid !== action.transferUuid ||
              action.roomIndexes.every(roomIndex => !transfer.roomIdxs.includes(roomIndex))
          );
        } else {
          draftState.selectedTransfers = draftState.selectedTransfers.filter(
            transfer =>
              transfer.uuid !== action.transferUuid ||
              action.roomIndexes.every(roomIndex => !transfer.roomIdxs.includes(roomIndex)) ||
              action.direction !== transfer.direction
          );
        }

        // if we're unticking a return share, we need to create an item for the thing we just removed
        if (action.isReturn && action.isViaShare && action.sharedWithRoomIndex != null) {
          draftState.selectedTransfers.push({
            direction: action.direction,
            isReturn: true,
            uuid: action.transferUuid,
            roomIdxs: [action.sharedWithRoomIndex],
          });
        }

        return draftState;
      });

    case Actions.DELETE_ALL_TRANSFERS:
      return produce(state, draftState => {
        draftState.selectedTransfers = [];
        return draftState;
      });

    case Actions.SET_ROOM_INDEXES_ON_TRANSFER:
      return produce(state, draftState => {
        const stIndex = draftState.selectedTransfers.findIndex(s => s.uuid === action.transferUuid);

        const stNew: ISelectedTransfer = {
          ...draftState.selectedTransfers[stIndex],
          roomIdxs: [...action.roomIndexes],
        };

        draftState.selectedTransfers[stIndex] = {
          ...stNew,
        };
        return draftState;
      });

    case Actions.SYNC_SELECTED_TRANSFERS:
      return produce(state, draftState => {
        draftState.selectedTransfers = action.data.availableProductSets.Transfer.filter(t => t.selected).map(t => ({
          direction: t.meta.direction,
          isReturn: t.meta.returnTransfer ?? false,
          roomIdxs: t.roomIdxs,
          uuid: t.products[0].uuid,
        }));
        return draftState;
      });

    case Actions.HANDLE_ADD_ROOM_SELECTED_TRANSFERS:
      return produce(state, draftState => {
        const transfersWithNewIndexes = draftState.selectedTransfers.map(st => {
          return {
            ...st,
            roomIdxs: st.roomIdxs.map(roomIndex => {
              if (roomIndex >= action.indexAdded) {
                return roomIndex + 1;
              }
              return roomIndex;
            }),
          };
        });
        draftState.selectedTransfers = transfersWithNewIndexes;
        return draftState;
      });

    case Actions.HANDLE_REMOVE_ROOM_SELECTED_TRANSFERS:
      return produce(state, draftState => {
        const transfersWithNewIndexes = draftState.selectedTransfers.map(st => {
          return {
            ...st,
            roomIdxs: st.roomIdxs
              // first remove the index
              .filter(roomIndex => roomIndex !== action.indexRemoved)
              // then re-index
              .map(roomIndex => {
                if (roomIndex > action.indexRemoved) {
                  return roomIndex - 1;
                }
                return roomIndex;
              }),
          };
        });
        draftState.selectedTransfers = transfersWithNewIndexes;
        // now we remove any selected transfers that have got NO room indexes
        draftState.selectedTransfers = draftState.selectedTransfers.filter(st => st.roomIdxs.length >= 1);
        return draftState;
      });
    default:
      return state;
  }
};
