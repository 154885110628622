import { makeBackendApi } from 'services/BackendApi';
import { successAction, errorFromResponse, genericAction } from 'store/common';
import { authCheck } from 'store/modules/auth/actions';
import { enqueueNotification } from 'store/modules/ui/actions';

export const USER_UPDATE = 'USER_UPDATE';

export const updateInternalUser = (id, payload) => async dispatch => {
  dispatch(genericAction(USER_UPDATE, { id, ...payload }));

  try {
    const {
      data: { data },
    } = await makeBackendApi().updateInternalUser(id, payload);

    dispatch(authCheck());
    dispatch(successAction(USER_UPDATE, data));
    dispatch(enqueueNotification({ message: 'User updated successfully.', options: { variant: 'success' } }));
  } catch (e) {
    dispatch(errorFromResponse(USER_UPDATE, e, 'There was a problem updating user.'));
  }
};
