import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector, OutputSelector } from 'reselect';

import {
  canUploadLogoSelector,
  companyLogoUrlSelector,
  logoPositionSelector,
  fetchCompanyLogoAction,
  updateCompanyLogoAction,
  removeCompanyLogoAction,
  updateCompanyLogoPositionAction,
} from 'store/modules/companyInfo';
import { getCurrentUser } from 'store/modules/auth';

// declare just what I need in this hoc.
// Also would make it easier to create full IUser interface in future (if we need such)
interface IUserShard {
  companyUuid: string;
  company: {
    uuid: string;
    name: string;
    countryCode: string;
    address: string;
    phoneNumber: string;
    website: string;
  };
}

export interface IStateToProps {
  companyLogoUrl: ReturnType<typeof companyLogoUrlSelector>;
  canUploadLogo: ReturnType<typeof canUploadLogoSelector>;
  logoPosition: ReturnType<typeof logoPositionSelector>;
  user: IUserShard; // if use `ReturnType<typeof getCurrentUser>` - it would fail due to JS/TS mess
}

const mapStateToProps = createStructuredSelector({
  companyLogoUrl: companyLogoUrlSelector,
  canUploadLogo: canUploadLogoSelector,
  logoPosition: logoPositionSelector,
  user: getCurrentUser as OutputSelector<any, IUserShard, (res: any) => IUserShard>, // hacks for TS
});

const actionCreators = {
  fetchCompanyLogo: fetchCompanyLogoAction,
  updateLogo: updateCompanyLogoAction,
  removeLogo: removeCompanyLogoAction,
  updateCompanyLogoPosition: updateCompanyLogoPositionAction,
};
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);
type IDispatchToProps = typeof actionCreators;

export interface IWithCompanyInfoProps extends IStateToProps, IDispatchToProps {
  handleCompanyLogoChange: () => void;
  handleRemoveLogo: () => void;
  handleSetLogoPosition: () => void;
}

// ----------------------------------------------------------
// For testing purposes, create the class with a function so
// we can test the unconnected version
// ----------------------------------------------------------
export const makeWithCompanyInfo = (WrappedComponent: any) =>
  class WithCompanyInfoData extends React.Component<IWithCompanyInfoProps, {}> {
    static displayName = `WithCompanyInfo(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    handleCompanyLogoChange = files => {
      const logo = files[0].file;
      const formData = new FormData();
      formData.append('file', logo);
      formData.append('ownerType', 'Company');
      formData.append('ownerUuid', this.props.user.companyUuid);
      formData.append('tag', 'CompanyLogo');
      this.props.updateLogo(formData);
    };

    handleRemoveLogo = () => {
      this.props.removeLogo(this.props.user.companyUuid);
    };

    handleSetLogoPosition = e => {
      const companyUuid = this.props.user.companyUuid;
      const position = e.target.value;
      this.props.updateCompanyLogoPosition(companyUuid, position);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleCompanyLogoChange={this.handleCompanyLogoChange}
          handleRemoveLogo={this.handleRemoveLogo}
          handleSetLogoPosition={this.handleSetLogoPosition}
        />
      );
    }
  };

export const withCompanyInfo = WrappedComponent => {
  const instance = makeWithCompanyInfo(WrappedComponent);
  const withConnect = connect<IStateToProps, IDispatchToProps, IWithCompanyInfoProps>(
    mapStateToProps,
    mapDispatchToProps
  );

  // @ts-ignore
  const composed = compose(withConnect)(instance);

  return hoistNonReactStatics(composed, WrappedComponent);
};
