import { call, takeLatest, put, select } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import * as Selectors from './selectors';
import * as Actions from './actions';

import { makeBookingManagerApi, Proposal, ProposalDocDraftResponse } from 'services/BookingManagerApi';
import { AxiosResponse } from 'axios';
import { enqueueNotification } from '../ui';

export function* getRequestSaga(action: Actions.GetRequestAction) {
  const api = makeBookingManagerApi();

  try {
    const proposal: AxiosResponse<Proposal> = yield call(
      api.getProposal,
      action.uuid
    );
    yield put(Actions.getSuccessAction(proposal.data));
  } catch (e) {
    yield put(Actions.getFailureAction());
  }
}

export function* draftRequestSaga(action: Actions.DraftRequestAction) {
  const api = makeBookingManagerApi();
  
  const proposal: Proposal | null = yield select(Selectors.proposal)

  try {
    const res: AxiosResponse<ProposalDocDraftResponse> = yield call(
      // @ts-ignore
      api.getProposalDocDraft,
      proposal?.uuid || action.proposalUuid,
      proposal?.googleDocId
    );

    yield put(Actions.draftSuccessAction(res.data.googleDocId));
  } catch (e) {
    yield put(Actions.draftFailureAction());
  }
}

export function* commitRequestSaga(action: Actions.CommitRequestAction) {
  const api = makeBookingManagerApi();
  
  const proposal: Proposal = yield select(Selectors.proposal)
  const googleDocDraftId: string = yield select(Selectors.googleDocDraftId)

  try {
    const res: AxiosResponse<Proposal> = yield call(
      api.commitProposalDoc,
      proposal.uuid,
      googleDocDraftId
    );

    saveAs(res.data.pdfUrl);
    yield put(Actions.commitSuccessAction(res.data));
    
    enqueueNotification({
      message: 'PDF document created sucessfully.',
      options: { variant: 'success' },
    });

  } catch (e) {
    yield put(Actions.commitFailureAction());
    yield put(
      enqueueNotification({
        message: 'Error creating document. Please, try later.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchProposalEditorSaga() {
  yield takeLatest(Actions.GET_REQUEST, getRequestSaga);
  yield takeLatest(Actions.DRAFT_REQUEST, draftRequestSaga);
  yield takeLatest(Actions.COMMIT_REQUEST, commitRequestSaga);
}
