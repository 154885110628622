import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { List } from './List';
import { Create } from './Create';
import { Edit } from './Edit';

export interface TravelAgentsRoutingProps extends RouteComponentProps {}

export const TravelAgentsRoutingComponent = (props: TravelAgentsRoutingProps) => {
  return (
    <Switch>
      <>
        <Route path={`${props.match.path}/`} component={List} exact />
        <Route
          path={`${props.match.path}/create`}
          component={Create}
          exact
        />
        <Route
          path={`${props.match.path}/:travelAgentUuid/edit`}
          component={Edit}
        />
      </>
    </Switch>
  );
};

export const TravelAgentsRouting = compose(withRouter)(TravelAgentsRoutingComponent);
