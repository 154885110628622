import { ENetworkRequestStatus } from 'services/BackendApi';
import { EIncentiveAmountType } from 'services/BookingManagerApi';

export interface ICompanyIncentivesDomain {
  enabled: boolean;
  amount: number | null;
  amountType: EIncentiveAmountType | null;
  startDate: string | null;
  notes?: string;
  request: {
    get: ENetworkRequestStatus;
    post: ENetworkRequestStatus;
    delete: ENetworkRequestStatus;
  };
  error?: Error;
  changes?: {
    enabled?: boolean;
    amount?: number;
    amountType?: EIncentiveAmountType;
    startDate?: string;
    notes?: string;
  }
}

export interface ICreateCompanyIncentiveParams {
  amount: number;
  amountType: EIncentiveAmountType;
  startDate: string;
  notes?: string;
}

export const initialState: ICompanyIncentivesDomain = {
  enabled: false,
  amount: 0,
  amountType: EIncentiveAmountType.PERCENTAGE,
  startDate: null,
  request: {
    get: ENetworkRequestStatus.IDLE,
    post: ENetworkRequestStatus.IDLE,
    delete: ENetworkRequestStatus.IDLE,
  }
}
