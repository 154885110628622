import React from 'react';
import classNames from 'classnames';
import { CircleIconButton } from '../CircleIconButton';
import SingleDateInput from 'pureUi/SingleDateInput';
import { PriceAsCentsInput } from '../stateful/PriceAsCentsInput';
import { DecimalInput } from '../stateful/DecimalInput';
import { formatDate } from '../../utils';

export interface IManualInvoiceModalDueDateRowProps {
  className?: string;
  date: string | null;
  amountCents: number;
  currencySymbol: string;
  isPriceEnabled: boolean;
  onUpdateAmountCents: (newAmountCents: number) => void;
  onUpdateDate: (newDate: string) => void;
  balance: number
}

export const ManualInvoiceModalDueDateRow = (props: IManualInvoiceModalDueDateRowProps) => {
  return (
    <div className={classNames('flex items-baseline gap-3', props.className)}>
      <span className="w-120px ml-1 mb-1">
        <SingleDateInput
          value={props.date && props.date !== '' ? new Date(props.date) : null}
          onChange={value => {
            props.onUpdateDate(formatDate(value));
          }}
          showYearDropdown
          enablePastDates={true}
          internalClassName={classNames({
            dateInputField: true,
            dateNotSelected: props.date === null,
          })}
          shouldKeepVisibleIfClickOutside={true}
        />
      </span>
      <span className="w-2/6 ml-4 ">
        <span className='flex justify-center items-center'>
          <span className="mr-2 text-sm">{props.currencySymbol}</span>
          <PriceAsCentsInput
            className={classNames(
              'w-full focus:outline-gray-80 text-black text-base text-right border border-solid p-2',
              {
                'bg-gray-20 border-gray-20': props.isPriceEnabled,
                'bg-white border-gray-40': !props.isPriceEnabled && !(props.amountCents <= 0 || props.amountCents > props.balance),
                'border border-red-95 bg-red-25': props.amountCents <= 0 || props.amountCents > props.balance,
              }
            )}
            disabled={props.isPriceEnabled}
            cents={props.amountCents || 0}
            onBlurUpdate={val => {
              props.onUpdateAmountCents(val);
            }}
          />
        </span>
        <div className={classNames('text-red-95 text-13px ml-15px',{
          'hidden': props.amountCents <= props.balance
        })}>
          Must be lower than balance.
        </div>
        <div className={classNames('text-red-95 text-13px ml-15px',{
          'hidden': props.amountCents > 0 ,
        })}>
          Must be greater than zero.
        </div>
      </span>
      <span
        style={{
          width: '80px',
        }}
        className=" flex justify-end"
      >
      </span>
    </div>
  );
};
