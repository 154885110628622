import * as React from 'react';
import { useCallback } from 'react';
import classNames from 'classnames';
import { UITextArea } from 'ui/UITextArea';

interface ITextBoxProps {
  text: string;
  buttonVisible: boolean;
  disabled: boolean;
  isSaving?: boolean;
  className?: string;
  onTextChange?: (text: string) => void;
  updateHandler?: (s: string) => void;
}

export const TextBox: React.FC<ITextBoxProps> = ({
  className,
  text,
  updateHandler,
  buttonVisible,
  disabled,
  isSaving,
  onTextChange,
}) => {
  const buttonClassName = classNames({
    'mt-4 flex flex-row items-center space-x-1 font-bold text-sm leading-sm py-2 px-4 rounded bg-white text-brown-100 border border-solid border-brown-100 outline-none inline-block cursor-pointer': true,
    'text-gray-80 bg-gray-20 cursor-default border-none': isSaving,
    'hover:bg-brown-20': !isSaving,
  });
  const handleUpdateClick = useCallback(() => {
    if (updateHandler) {
      updateHandler(text);
    }
  }, [text, updateHandler]);

  return (
    <>
      <UITextArea
        value={text}
        onChange={value => {
          onTextChange && onTextChange(value);
        }}
        disabled={disabled}
        className={`${className ?? ''}`}
      />
      {buttonVisible && (
        <button className={classNames('save', buttonClassName)} disabled={isSaving} onClick={handleUpdateClick}>
          {isSaving && (
            <svg
              className="animate-spin h-5 w-5 text-brown-100"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          <span>Save</span>
        </button>
      )}
    </>
  );
};
