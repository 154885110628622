import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'ui/Link';

import classNames from 'classnames';
import * as Selectors from 'store/modules/rateBreak/selectors';
import * as Actions from 'store/modules/rateBreak/actions';

import { getBootstrapHotelsSelector } from 'store/modules/bootstrap/selectors';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { LoadingBar } from 'ui/NetworkStatusBar';
import FluidButton from 'ui/FluidButton';
import Checkbox from 'ui/Checkbox';
import { Pagination } from 'pureUi/Pagination';
import { Multiselect } from 'ui/Multiselect';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { PageTitle } from 'ui/PageTitle';
import { BlockingOverlay } from 'ui/BlockingOverlay';

export const RateBreakList = () => {
  const dispatch = useDispatch();

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

  const uuidsForDeletion = useSelector(Selectors.rateBreakUuidsForDeletionSelector);
  const rateBreakList = useSelector(Selectors.rateBreakListSelector);
  const networkRequests = useSelector(Selectors.networkRequestsSelector);
  const bootstrapHotels = useSelector(getBootstrapHotelsSelector);
  const filterHotelUuids = useSelector(Selectors.rateBreakListingFilterHotelUuidsSelector);

  const paginationCurrentPage = useSelector(Selectors.rateBreakListingPaginationCurrentPageSelector);
  const totalResultsCount = useSelector(Selectors.rateBreakListingTotalResultsCountSelector);
  const paginationItemsPerPage = useSelector(Selectors.rateBreakListingPaginationItemsPerPageSelector);

  const totalPages = Math.ceil(totalResultsCount / paginationItemsPerPage);

  // on mount
  useEffect(() => {
    dispatch(Actions.getRateBreakListRequestAction());
    // we should also clear the form, for safeties sake.
    dispatch(Actions.clearFormAction());
  }, [dispatch]);

  const handleItemsPerPageChange = useCallback(
    (itemsPerPage: number) => {
      dispatch(Actions.setItemsPerPage(itemsPerPage));
    },
    [dispatch]
  );

  const isRatesLoading = networkRequests.getRateBreakList === ENetworkRequestStatus.PENDING;
  const isRatesDeleting = networkRequests.deleteRateBreakList === ENetworkRequestStatus.PENDING;

  return (
    <div className="container mx-auto max-w-1280px font-pt-sans">
      <div className="flex justify-between items-center mb-9">
        <PageTitle
          primaryText="Rate Breaks"
          secondaryCondition={!isRatesLoading}
          secondaryText={`(${totalResultsCount} results found)`}
          loadingCondition={isRatesDeleting}
          loadingText="Loading ..."
        />

        <div className="w-1/4">
          <span className="text-13px">Filter Results</span>
          <Multiselect
            className="hotel-filter-selector"
            itemsClassname="bg-white"
            itemCtaClassName="hover:bg-gray-10"
            placeholderText={'All hotels'}
            options={bootstrapHotels.map(bh => {
              return {
                value: bh.uuid,
                label: bh.name,
              };
            })}
            onUpdate={sv => {
              dispatch(Actions.setFilterHotelUuids(sv));
            }}
            selectedValues={filterHotelUuids}
            isCloseOnSelect
            isIncludeClearButton
            isSingleSelectMode
            hideCheckboxes
          />
        </div>
      </div>

      {!isRatesLoading && (
        <div className="flex items-center justify-between mb-4">
          <FluidButton
            className="delete-selected-button"
            disabled={uuidsForDeletion.length <= 0}
            type="secondary"
            onClick={() => setIsConfirmDeleteModalOpen(true)}
          >
            <span>Delete Selected</span>
            <i className="far fa-trash-alt ml-2"></i>
          </FluidButton>
          <Link to="/rate-breaks/create">
            <FluidButton type="primary" className="add-rate-break-button">
              <span>Add Rate Break</span>
            </FluidButton>
          </Link>
        </div>
      )}

      {isRatesLoading && <LoadingBar />}

      {(!isRatesLoading && (
        <BlockingOverlay showWhen={isRatesDeleting}>
          <table
            className={classNames('table table-xs table-fixed w-full border-collapse text-left font-pt-sans', {
              'opacity-30': isRatesDeleting,
            })}
          >
            <thead>
              <tr className="bg-ivory border border-solid border-gray-40 shadow-pe1 h-10 font-bold text-17px text-black">
                <th id="id-header" style={{ width: '100px' }} className="pl-8">
                  {/* checkbox */}
                </th>

                <th id="id-hotel-name" style={{ width: '270px' }}>
                  Hotel Name
                </th>

                <th id="createdAt-header" style={{ width: '270px' }}>
                  Rate Break
                </th>

                <th id="dates-header">{/* name */}</th>

                <th id="hotel-header">{/* button */}</th>
              </tr>
            </thead>

            <tbody>
              {rateBreakList.map(rateBreakListItem => {
                return (
                  <tr
                    key={rateBreakListItem.uuid}
                    className={classNames(`rate-break-row ${rateBreakListItem.uuid} h-15 text-15px`, {
                      'bg-teal-15': uuidsForDeletion.includes(rateBreakListItem.uuid),
                      'even:bg-ivory': !uuidsForDeletion.includes(rateBreakListItem.uuid),
                    })}
                  >
                    <td className="pl-6">
                      <label className="w-full h-full">
                        <Checkbox
                          checked={uuidsForDeletion.includes(rateBreakListItem.uuid)}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.checked) {
                              dispatch(Actions.setUuidsForDeletion([...uuidsForDeletion, rateBreakListItem.uuid]));
                            } else {
                              dispatch(
                                Actions.setUuidsForDeletion(
                                  uuidsForDeletion.filter(uuid => uuid !== rateBreakListItem.uuid)
                                )
                              );
                            }
                          }}
                        />
                      </label>
                    </td>
                    <td>{rateBreakListItem.hotel.name}</td>
                    <td>{rateBreakListItem.discountPercentage}%</td>
                    <td>{rateBreakListItem.name}</td>
                    <td>
                      <Link to={`/rate-breaks/${rateBreakListItem.uuid}/edit`}>
                        <FluidButton type="secondary" className="rate-break-edit-button float-right mr-8">
                          <span>Edit</span>
                          <i className="fas fa-pen ml-2"></i>
                        </FluidButton>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {rateBreakList.length >= 1 && (
            <Pagination
              className="pagination justify-center mt-12"
              onPageSelect={pn => {
                dispatch(Actions.setCurrentPaginationPage(pn));
              }}
              pageCount={totalPages}
              currentPage={paginationCurrentPage}
              itemsPerPage={paginationItemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </BlockingOverlay>
      )) ||
        null}

      <ConfirmationModal
        className="delete-confirmation-modal"
        type={EConfirmationModalType.WARNING}
        isOpen={isConfirmDeleteModalOpen}
        title={`Delete selected rate breaks`}
        message={`Are you sure you want to delete the selected rate breaks?
          This cannot be undone!`}
        confirmButtonLabel="Delete"
        cancelButtonLabel="Cancel"
        onConfirm={() => {
          dispatch(Actions.deleteRateBreakListRequestAction());
          setIsConfirmDeleteModalOpen(false);
        }}
        onCancel={() => {
          setIsConfirmDeleteModalOpen(false);
        }}
      />
    </div>
  );
};
