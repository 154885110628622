import { cloneDeep, pick } from 'lodash-es';
import { update } from 'utils/immutable';
import { IProposalGenerateDomain, initialState, formDataInitState } from './model';
import * as Actions from './actions';
import { ENetworkRequestStatus } from 'services/BackendApi';

export const proposalGenerateReducer = (
  state: IProposalGenerateDomain = initialState,
  action: Actions.Any
): IProposalGenerateDomain => {

  switch (action.type) {

    case Actions.INIT_FORM:
      return update(state, s => {
        s.proposal = null;
        s.proposalSelection = null;
        s.formData = formDataInitState();
        s.bookingUuids = action.bookingUuids;
      });

    case Actions.SET_TEMPLATE:
      return update(state, s => s.formData.template = action.value);

    case Actions.SET_LANG:
      return update(state, s => s.formData.lang = action.value);

    case Actions.SET_NAME:
      return update(state, s => s.formData.name = action.value || null);

    case Actions.SET_LOGO:
      return update(state, s => s.formData.logo = action.value);

    case Actions.TOGGLE_TA_MARGIN_INCLUDED:
      return update(state, s => s.formData.taMarginIncluded = !s.formData.taMarginIncluded);

    case Actions.SET_GUEST_TITLE:
      return update(state, s => s.formData.guest.title = action.value);

    case Actions.SET_GUEST_FIRST_NAME:
      return update(state, s => s.formData.guest.firstName = action.value || null);

    case Actions.SET_GUEST_LAST_NAME:
      return update(state, s => s.formData.guest.lastName = action.value || null);

    case Actions.SET_GENERAL_COMMENTS:
      return update(state, s => s.formData.generalComments = action.value || null);

    case Actions.SET_OTHER_COMMENTS:
      return update(state, s => s.formData.otherComments = action.value || null);

    case Actions.SET_ALL_HOTEL_COMMENTS:
      return update(state, s => {
        s.formData.hotelComments = action.value;
      });

    case Actions.SET_HOTEL_COMMENTS:
      return update(state, s => {
        s.formData.hotelComments[action.idx].content = action.value;
      });

    case Actions.GET_REQUEST:
      return update(state, s => s.request.get = ENetworkRequestStatus.PENDING);

    case Actions.GET_SUCCESS:
      return update(state, s => {
        s.proposal = action.value;
        s.formData = {
          ...cloneDeep(
            pick(
              action.value,
              ['template', 'lang', 'name', 'logo', 'taMarginIncluded', 'guest', 'generalComments', 'otherComments']
            )
          ),
          hotelComments: []
        };
        s.request.get = ENetworkRequestStatus.SUCCESS;
      });

    case Actions.GET_FAILURE:
      return update(state, s => s.request.get = ENetworkRequestStatus.ERROR);

    case Actions.GET_SELECTION_REQUEST:
      return update(state, s => s.request.selection = ENetworkRequestStatus.PENDING);

    case Actions.GET_SELECTION_SUCCESS:
      return update(state, s => {
        s.proposalSelection = action.value;
        s.request.selection = ENetworkRequestStatus.SUCCESS;
      });

    case Actions.GET_SELECTION_FAILURE:
      return update(state, s => s.request.selection = ENetworkRequestStatus.ERROR);

    case Actions.SAVE_REQUEST:
      return update(state, s => s.request.save = ENetworkRequestStatus.PENDING);

    case Actions.SAVE_SUCCESS:
      return update(state, s => {
        s.proposal = action.value;
        s.request.save = ENetworkRequestStatus.SUCCESS;
      });

    case Actions.SAVE_FAILURE:
      return update(state, s => s.request.save = ENetworkRequestStatus.ERROR);

    case Actions.DOWNLOAD_REQUEST:
      return update(state, s => s.request.download = ENetworkRequestStatus.PENDING);

    case Actions.DOWNLOAD_SUCCESS:
      return update(state, s => s.request.download = ENetworkRequestStatus.SUCCESS);

    case Actions.DOWNLOAD_FAILURE:
      return update(state, s => s.request.download = ENetworkRequestStatus.ERROR);

    case Actions.SET_PROPOSAL_LOGO_OPTIONS:
      return update(state,s => s.logoOptions = action.logoOptions);

    default:
      return state;
  }
};

export default proposalGenerateReducer;
