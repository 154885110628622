import React from 'react';
import styled from 'styled-components';
import { IImportData, IImportStatus } from 'services/BackendApi';
import ReportItem from './ReportItem';

export interface ReportProps {
  className?: string;
  data: IImportData;
  status: IImportStatus['status'];
}

export const StyledReportItem = styled(ReportItem)`
  margin: 20px 0;
`;

export const ErrorMessage = styled.span``;

const ImporterErrorReport = (props: ReportProps) => {
  const { data } = props;
  let { report, error } = data;

  return (
    <div className="font-pt-sans">
      {report && Array.isArray(report) && report.map(item => <StyledReportItem key={item.key} data={item} />)}
      {report && !Array.isArray(report) && <StyledReportItem data-role={'report-item'} key={'report'} data={report} />}
    </div>
  );
};

export default ImporterErrorReport;
