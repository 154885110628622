import { JWT_KEEPALIVE_INTERVAL } from 'config';

import { getBearerTokenFromLocalStorage } from '../../../../services/tokenLocalStorage'


export function keepAliveJWTSaga(interval: number = JWT_KEEPALIVE_INTERVAL) {
  const iv = setInterval(async () => {
    try {
      // Keep the session JWT alive
      getBearerTokenFromLocalStorage();
    } catch (e) {
      // more advanced error handling may be required in future
      console.warn(e);
    }
  }, interval);
  // The subscriber must return an unsubscribe function
  return () => {
    clearInterval(iv)
  }
}
