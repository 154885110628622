import { prop, values, mergeDeepRight, pathOr, forEach } from 'ramda';

import { successAction } from 'store/common';
import { index } from 'store/modules/indexes/actions';

import { getHotelsEntities } from './selectors';
import schema from './schema';

export const HOTELS = 'HOTELS';
export const HOTEL = 'HOTEL';

/**
 * Set hotels
 *
 * Sets hotels into state from difference sources. Triggers
 * a lunr reindex when there are new hotels.
 *
 * @param {object} data
 * @returns {Function}
 */
export const setHotels = data => (dispatch, getState) => {
  const prevData = getHotelsEntities(getState());
  const hotels = mergeDeepRight(prevData, pathOr({}, ['entities', 'hotels'], data));

  // Re-index all the hotels
  dispatch(
    index({
      index: 'hotels',
      ref: prop('id', schema),
      fields: prop('index', schema),
      data: values(hotels),
    })
  );

  // Run through the hotels, and if there are booking builder objects
  // then trigger a BOOKING_POPULATE_BULK action to pre-populate the booking
  // for the hotels
  const bookingData = {};
  forEach(({ bookingBuilder, name, uuid }) => {
    if (!bookingBuilder) return;
    const { request: requestedBuild, response } = bookingBuilder;
    bookingData[uuid] = { hotelUuid: uuid, hotelName: name, breakdown: { requestedBuild, ...response } };
  }, values(hotels));

  dispatch(successAction(HOTELS, data));
};
