import * as Actions from './actions';
import { ICompanyDepartmentDomain, initialState } from './model';
import { ENetworkRequestStatus } from 'services/BackendApi';

export const companyDepartmentsReducer = (
  state: ICompanyDepartmentDomain = initialState,
  action: Actions.CompanyDepartmentsAction
): ICompanyDepartmentDomain => {
  switch (action.type) {
    case Actions.FETCH_COMPANY_DEPARTMENTS_REQUEST:
      return {
        ...state,
        departments: null,
        requests: {
          ...state.requests,
          departmentListLoad: ENetworkRequestStatus.PENDING,
        },
      };

    case Actions.FETCH_COMPANY_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: action.departments,
        requests: {
          ...state.requests,
          departmentListLoad: ENetworkRequestStatus.SUCCESS,
        },
      };

    case Actions.FETCH_COMPANY_DEPARTMENTS_FAILURE:
      return {
        ...state,
        departments: [],
        requests: {
          ...state.requests,
          departmentListLoad: ENetworkRequestStatus.ERROR,
        },
      };

    case Actions.RESET_COMPANY_DEPARTMENTS:
      return {
        ...state,
        departments: null,
      };

    case Actions.CREATE_COMPANY_DEPARTMENT_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          departmentCreate: ENetworkRequestStatus.PENDING,
        },
      };

    case Actions.CREATE_COMPANY_DEPARTMENT_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          departmentCreate: ENetworkRequestStatus.SUCCESS,
        },
      };

    case Actions.CREATE_COMPANY_DEPARTMENT_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          departmentCreate: ENetworkRequestStatus.ERROR,
        },
      };

    case Actions.EDIT_COMPANY_DEPARTMENT_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          departmentSave: ENetworkRequestStatus.PENDING,
        },
      };

    case Actions.EDIT_COMPANY_DEPARTMENT_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          departmentSave: ENetworkRequestStatus.SUCCESS,
        },
      };

    case Actions.EDIT_COMPANY_DEPARTMENT_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          departmentSave: ENetworkRequestStatus.ERROR,
        },
      };

    case Actions.DELETE_COMPANY_DEPARTMENT_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          departmentDelete: ENetworkRequestStatus.PENDING,
        },
      };

    case Actions.DELETE_COMPANY_DEPARTMENT_SUCCESS:
      return {
        ...state,
        requests: {
          ...state.requests,
          departmentDelete: ENetworkRequestStatus.SUCCESS,
        },
      };

    case Actions.DELETE_COMPANY_DEPARTMENT_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          departmentDelete: ENetworkRequestStatus.ERROR,
        },
      };

    case Actions.SET_UUID_TO_DELETE:
      return {
        ...state,
        uuidToDelete: action.uuidToDelete,
      };

    case Actions.SET_DEPARTMENT_TO_EDIT:
      return {
        ...state,
        departmentToEdit: action.department,
      };

    case Actions.OPEN_CREATE_DEPARTMENT_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          isCreateDepartmentModalOpen: true,
        },
      };
      
    case Actions.CLOSE_CREATE_DEPARTMENT_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          isCreateDepartmentModalOpen: false,
        },
      };

    default:
      return state;
  }
};

export default companyDepartmentsReducer;
