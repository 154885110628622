import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  isTA as isTaSelector,
  isAdmin as isAdminSelector,
  isFinanceUser as isFinanceUserSelector,
  getCompanySelector,
} from 'store/modules/auth/selectors';
import { getCompaniesRequestAction } from 'store/modules/agents/actions';
import { companiesSelector, isFetchingCompaniesSelector } from 'store/modules/agents/selectors';
import {
  ledgerSelectedCompanySelector,
  ledgerTotalsLoading,
  ledgerDepositStatementTotalsSelector,
  ledgerBookingStatementTotalsSelector,
  summaryPdfGenerationInProgress,
  summaryDownloadHistoryInProgress,
  summaryDownloadedPdfs,
  depositAccountCsvAggregateExportLoading,
  ledgerBookingLedgerCsvAggregateExportLoading,
} from 'store/modules/ledger/selectors';
import { tcBookingStatementTotalsSelector } from 'store/modules/tcBookingStatement/selectors';
import {
  selectCompanyAction,
  depositStatementTotalsRequestAction,
  bookingStatementTotalsRequestAction,
  summaryGeneratePdfRequestAction,
  summaryDownloadPdfHistoryRequestAction,
  depositAccountPostAggregateExportRequestAction,
  bookingLedgerPostAggregateExportRequestAction,
} from 'store/modules/ledger/actions';
import { tcBookingStatementRequestTotalsAction } from 'store/modules/tcBookingStatement/actions';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { LedgerSummaryHeader, LedgerSummaryTable, Controls } from 'ui/Ledger';
import { PdfDownloadHistory } from './PdfDownloadHistory';
import { ENetworkRequestStatus } from 'services/BackendApi';

interface ILedgerSummaryProps {}

interface ILedgerSummaryParams {
  companyUuid?: string;
}

export const LedgerSummaryContainer: React.FC<ILedgerSummaryProps> = () => {
  const dispatch = useDispatch();
  const params: ILedgerSummaryParams = useParams();
  const companies = useSelector(companiesSelector);
  const isTa = useSelector(isTaSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isFinanceUser = useSelector(isFinanceUserSelector);
  const currentCompany = useSelector(getCompanySelector) as { uuid: string; name: string };
  const selectedCompany = useSelector(ledgerSelectedCompanySelector);
  const fetchingCompanies = useSelector(isFetchingCompaniesSelector);
  const fetchingTotals = useSelector(ledgerTotalsLoading);
  const depositTotals = useSelector(ledgerDepositStatementTotalsSelector);
  const tcBookingStatementTotals = useSelector(tcBookingStatementTotalsSelector);
  const pdfGenerationInProgress = useSelector(summaryPdfGenerationInProgress);
  const downloadHistoryInProgress = useSelector(summaryDownloadHistoryInProgress);
  const downloadHistoryPdfs = useSelector(summaryDownloadedPdfs);

  const bookingLedgerCsvExportLoading = useSelector(ledgerBookingLedgerCsvAggregateExportLoading);
  const depositAccountCsvExportLoading = useSelector(depositAccountCsvAggregateExportLoading);

  const [isDownloadHistoryOpen, openDownloadHistory] = useState(false);
  const downloadHistory = useMemo(() => ({ handler: openDownloadHistory, isOpen: isDownloadHistoryOpen }), [
    isDownloadHistoryOpen,
  ]);

  const { companyUuid } = params;

  // when the company uuid changes, set the selected company uuid
  useEffect(() => {
    if (companyUuid) {
      dispatch(selectCompanyAction(companyUuid));
    }
  }, [companyUuid]);

  useEffect(() => {
    if (isTa) {
      return;
    }

    if (!companies) {
      dispatch(getCompaniesRequestAction());
    }
  }, [companies, dispatch, isTa]);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(depositStatementTotalsRequestAction(selectedCompany));
      dispatch(bookingStatementTotalsRequestAction(selectedCompany));
      dispatch(tcBookingStatementRequestTotalsAction(selectedCompany));
      dispatch(summaryDownloadPdfHistoryRequestAction(selectedCompany));
    }
  }, [selectedCompany, dispatch]);

  const options = useMemo(() => {
    if (isTa) {
      return [{ value: currentCompany.uuid, label: currentCompany.name }];
    }

    if (fetchingCompanies) {
      return [{ value: '', label: 'Loading companies', disabled: true }];
    }

    if (!companies || companies.length === 0) {
      return [{ value: '', label: 'No Companies', disabled: true }];
    }

    return companies.map(c => ({
      value: c.uuid,
      label: c.name,
    }));
  }, [isTa, currentCompany, companies, fetchingCompanies]);

  const handleSelectCompany = useCallback(
    value => {
      dispatch(selectCompanyAction(value));
    },
    [dispatch]
  );

  const handleGeneratePdf = useCallback(() => {
    dispatch(summaryGeneratePdfRequestAction());
  }, []);

  const generatePdf = useMemo(() => ({ handler: handleGeneratePdf, loading: pdfGenerationInProgress }), [
    pdfGenerationInProgress,
  ]);

  const handleDepositAccountCsvExport = useCallback(() => {
    dispatch(depositAccountPostAggregateExportRequestAction());
  }, []);

  const handleBookingLedgerCsvExport = useCallback(() => {
    dispatch(bookingLedgerPostAggregateExportRequestAction());
  }, []);

  const csvExport = useMemo(
    () =>
      isAdmin || isFinanceUser
        ? [
            {
              handler: handleDepositAccountCsvExport,
              loading: depositAccountCsvExportLoading,
              label: 'Deposits Account',
            },
            {
              handler: handleBookingLedgerCsvExport,
              loading: bookingLedgerCsvExportLoading,
              label: 'Bookings Statement',
            },
          ]
        : undefined,
    [isAdmin, isFinanceUser, depositAccountCsvExportLoading, bookingLedgerCsvExportLoading]
  );

  useEffect(() => {
    if (!companyUuid && !selectedCompany && options.length > 0 && options[0].value.length > 0) {
      dispatch(selectCompanyAction(options[0].value));
    }
  }, [dispatch, options, selectedCompany]);

  const selectedCompanyName = useMemo(() => {
    if (isTa) {
      return currentCompany.name;
    }
    return companies?.find(company => company.uuid === selectedCompany)?.name;
  }, [isTa, companies, selectedCompany]);

  const isLoading =
    fetchingTotals.deposit === ENetworkRequestStatus.PENDING ||
    fetchingTotals.booking === ENetworkRequestStatus.PENDING;
  const loaded =
    fetchingTotals.deposit === ENetworkRequestStatus.SUCCESS &&
    fetchingTotals.booking === ENetworkRequestStatus.SUCCESS;

  return (
    <div className="ledger-summary-container container mx-auto max-w-1280px font-pt-sans text-black">
      <LedgerSummaryHeader
        selectedCompany={selectedCompany}
        selectedCompanyName={selectedCompanyName}
        handleSelectCompany={handleSelectCompany}
        companyOptions={options}
      />
      {isLoading && <LoadingBar />}
      {loaded && (
        <>
          <Controls generatePdf={generatePdf} downloadHistory={downloadHistory} csvExport={csvExport} />
          <PdfDownloadHistory
            pdfs={downloadHistoryPdfs}
            loading={downloadHistoryInProgress}
            isOpen={isDownloadHistoryOpen}
          />
          <LedgerSummaryTable
            selectedCompany={selectedCompany}
            depositTotals={depositTotals}
            tcBookingStatementTotals={tcBookingStatementTotals}
          />
        </>
      )}
    </div>
  );
};
