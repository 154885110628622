import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  makeBookingManagerApi,
  ICancellationPolicyResponse,
  IHeadlineBreakdownVersionListVersion,
} from 'services/BookingManagerApi';

import * as BreakdownSelectors from '../selectors';
import * as BreakdownActions from '../actions';

import { bookingUuidSelector } from '../../../selectors';

export function* getCancellationPoliciesSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const selectedVersion: IHeadlineBreakdownVersionListVersion | null = yield select(
      BreakdownSelectors.headlineBreakdownSelectedVersionSelector
    );

    const result: AxiosResponse<ICancellationPolicyResponse> = yield call(
      bookingManagerApi.getCancellationPolicy,
      bookingUuid,
      selectedVersion?.timestamp
    );

    yield put(BreakdownActions.getCancellationPoliciesSuccessAction(result.data.cancellationPolicies));
  } catch (e) {
    yield put(BreakdownActions.getCancellationPoliciesFailureAction(e));
  }
}

export function* watchBookingManagerCancellationPolicy() {
  yield takeLatest(
    [BreakdownActions.GET_CANCELLATION_POLICIES_REQUEST, BreakdownActions.SET_HEADLINE_BREAKDOWN_SELECTED_VERSION],
    getCancellationPoliciesSaga
  );
}
