import { ITravelAgent } from 'services/BackendApi';

export const GET_REQUEST = 'finance-company-settings/GET_REQUEST';
export const GET_SUCCESS = 'finance-company-settings/GET_SUCCESS';
export const GET_FAILURE = 'finance-company-settings/GET_FAILURE';

export const SET_TOUCHED = 'finance-company-settings/SET_TOUCHED';
export const TOGGLE_DISABLE_TA_ACCESS_TO_FINANCE = 'finance-company-settings/TOGGLE_DISABLE_TA_ACCESS_TO_FINANCE';
export const TOGGLE_ENABLE_INSTANT_BOOKING = 'finance-company-settings/TOGGLE_ENABLE_INSTANT_BOOKING';
export const SET_CREDIT_LIMIT_USD = 'finance-company-settings/SET_CREDIT_LIMIT_USD';
export const SET_CREDIT_LIMIT_EUR = 'finance-company-settings/SET_CREDIT_LIMIT_EUR';
export const SAVE_CHANGES = 'finance-company-settings/SAVE_CHANGES';

export type GetRequestAction = ReturnType<typeof getRequestAction>;
export const getRequestAction = (companyUuid: string) => ({
  type: GET_REQUEST as typeof GET_REQUEST,
  companyUuid,
});

export type GetSuccessAction = ReturnType<typeof getSuccessAction>;
export const getSuccessAction = (travelAgents: ITravelAgent[]) => ({
  type: GET_SUCCESS as typeof GET_SUCCESS,
  travelAgents,
});

export type GetFailureAction = ReturnType<typeof getFailureAction>;
export const getFailureAction = (error: string) => ({
  type: GET_FAILURE as typeof GET_FAILURE,
  error,
});

export type SetTouchedAction = ReturnType<typeof setTouchedAction>;
export const setTouchedAction = (isTouched: boolean) => ({
  type: SET_TOUCHED as typeof SET_TOUCHED,
  isTouched,
});

export type ToggleDisableTaAccessToFinanceAction = ReturnType<typeof toggleDisableTaAccessToFinanceAction>;
export const toggleDisableTaAccessToFinanceAction = () => ({
  type: TOGGLE_DISABLE_TA_ACCESS_TO_FINANCE as typeof TOGGLE_DISABLE_TA_ACCESS_TO_FINANCE,
});

export type ToggleEnableInstantBookingAction = ReturnType<typeof toggleEnableInstantBookingAction>;
export const toggleEnableInstantBookingAction = () => ({
  type: TOGGLE_ENABLE_INSTANT_BOOKING as typeof TOGGLE_ENABLE_INSTANT_BOOKING,
});

export type SetCreditLimitUsdAction = ReturnType<typeof setCreditLimitUsdAction>;
export const setCreditLimitUsdAction = (amount: number) => ({
  type: SET_CREDIT_LIMIT_USD as typeof SET_CREDIT_LIMIT_USD,
  amount,
});

export type SetCreditLimitEurAction = ReturnType<typeof setCreditLimitEurAction>;
export const setCreditLimitEurAction = (amount: number) => ({
  type: SET_CREDIT_LIMIT_EUR as typeof SET_CREDIT_LIMIT_EUR,
  amount,
});

export type SaveChangesAction = ReturnType<typeof saveChangesAction>;
export const saveChangesAction = (companyUuid: string) => ({
  type: SAVE_CHANGES as typeof SAVE_CHANGES,
  companyUuid
});

export type Any =
  | GetRequestAction
  | GetSuccessAction
  | GetFailureAction
  | ToggleDisableTaAccessToFinanceAction
  | ToggleEnableInstantBookingAction
  | SetCreditLimitUsdAction
  | SetCreditLimitEurAction
  | SetTouchedAction
  | SaveChangesAction;
