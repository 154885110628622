import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { StandardModal } from 'pureUi/Modal';
import FluidButton from 'ui/FluidButton';

import { closeTaMovementsModalAction } from 'store/modules/bookingManager/subdomains/bookingSettings/actions';
import { IOwnershipTravelAgentMovements } from 'services/BookingManagerApi';

interface ITravelAgentMovementsModalProps {
  isOpen: boolean;
  movements: IOwnershipTravelAgentMovements[];
}

export const TravelAgentMovementsModal: React.FC<ITravelAgentMovementsModalProps> = React.memo(
  ({ isOpen, movements }) => {
    const dispatch = useDispatch();

    const handleCloseClick = useCallback(() => {
      dispatch(closeTaMovementsModalAction());
    }, [dispatch]);

    if (!isOpen) {
      return null;
    }

    return (
      <StandardModal
        className="items-start bg-black-true-transparent-60 z-11"
        frameClassName={`pt-4 px-8 pb-8 border border-solid text-black border-black-true font-pt-sans overflow-y-hidden`}
        showCloseButton={false}
        closeOnOutsideClick={true}
        onClose={handleCloseClick}
        removePadding
      >
        <div
          className="text-base text-brown-100 absolute top-4 right-4 cursor-pointer outline-none"
          onClick={handleCloseClick}
        >
          <i className="fas fa-times"></i>
        </div>

        <h3 className="font-noe-display font-normal text-21px leading-29px mt-0 mb-0">Travel Partner Movements</h3>

        <div className="my-4">
          <table className="ta-movements-table table table-xs table-fixed border-collapse text-left font-pt-sans">
            <thead>
              <tr className="bg-ivory border border-solid border-gray-40 rounded shadow-pe1 h-9 uppercase">
                <th scope="col">
                  <span
                    className="group flex font-normal items-center px-2 justify-between h-8 rounded text-xs text-brown-100"
                    style={{ width: '90px' }}
                  >
                    Date
                  </span>
                </th>
                <th scope="col">
                  <span
                    className="group flex font-normal items-center px-2 justify-between h-8 rounded text-xs text-brown-100"
                    style={{ width: '170px' }}
                  >
                    Company
                  </span>
                </th>
                <th scope="col">
                  <span
                    className="group flex font-normal items-center px-2 justify-between h-8 rounded text-xs text-brown-100"
                    style={{ width: '150px' }}
                  >
                    Travel Partner
                  </span>
                </th>
                <th scope="col">
                  <span
                    className="group flex font-normal items-center px-2 justify-between h-8 rounded text-xs text-brown-100"
                    style={{ width: '150px' }}
                  >
                    Modified By
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {movements.map((mov, movementIndex) => (
                <tr
                  key={`movement|${mov.bookingUuid}|${movementIndex}`}
                  className="text-13px odd:bg-ivory"
                  style={{ height: '42px' }}
                >
                  <td className={`ta-movements-table-row-date-${movementIndex}  px-2 py-1`}>{mov.updatedAt}</td>
                  <td className={`ta-movements-table-row-company-${movementIndex} px-2 py-1`}>
                    <div style={{ maxWidth: '95%' }} className="overflow-hidden text-ellipsis whitespace-nowrap">
                      {mov.companyName}
                    </div>
                  </td>
                  <td className={`ta-movements-table-row-travel-agent-${movementIndex} px-2 py-1`}>
                    <div style={{ maxWidth: '95%' }} className="overflow-hidden text-ellipsis whitespace-nowrap">
                      {mov.taInfo.title} {mov.taInfo.firstName} {mov.taInfo.lastName}
                    </div>
                  </td>
                  <td className={`ta-movements-table-row-modifiedBy-${movementIndex} px-2 py-1`}>
                    <div style={{ maxWidth: '95%' }} className="overflow-hidden text-ellipsis whitespace-nowrap">
                      {mov.modifiedBy
                        ? `${mov.modifiedBy?.title} ${mov.modifiedBy?.firstName} ${mov.modifiedBy?.lastName}`
                        : '---'}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex mt-8">
          <FluidButton className="close-ta-movements-modal-button" type="secondary" onClick={handleCloseClick}>
            Close
          </FluidButton>
        </div>
      </StandardModal>
    );
  }
);
