import React from 'react';

import { propTypes, defaultProps } from './ContextMenu.props';
import { DropDownMenu } from '@pure-escapes/webapp-ui-components';

export const ContextMenu = ({ children, title, className }) => (
  <DropDownMenu
    className={className}
    showArrow={false}
    title={
      title || (
        <svg
          width="24"
          height="24"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="MoreVertIcon"
          tabIndex="-1"
          title="MoreVert"
        >
          <path
            fill="currentColor"
            d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
          ></path>
        </svg>
      )
    }
  >
    {children}
  </DropDownMenu>
);

ContextMenu.propTypes = propTypes;
ContextMenu.defaultProps = defaultProps;

export default ContextMenu;
