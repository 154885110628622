import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ENetworkRequestStatus } from 'services/BackendApi';
import {
  tcBookingStatementselectCompanyAction,
  tcBookingStatementRequestAction,
  triggerExportCsvRequestAction,
  pollExportCsvRequestAction,
  tcBookingStatementDownloadPdfAction,
  tcBookingStatementDownloadPdfHistoryRequestAction,
} from 'store/modules/tcBookingStatement/actions';
import {
  tcBookingStatementSelector,
  tcBookingStatementCsvExportLoading,
  exportCsvNamespaceSelector,
  statementPdfGenerationInProgress,
  statementDownloadedPdfs,
  statementDownloadHistoryInProgress,
  tcBookingStatementRequestSelector,
} from 'store/modules/tcBookingStatement/selectors';
import { companiesSelector } from 'store/modules/agents/selectors';
import {
  isInternalUser as isSRSelector,
  isAdmin as isAdminSelector,
  isFinanceUser as isFinanceUserSelector,
} from 'store/modules/auth/selectors';
import { TCBookingLedgerTable, Controls } from 'ui/TCBookingStatementLedger';
import { Poller } from '../../Poller';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { TCurrencyCode } from 'interfaces';

import { PdfDownloadHistory, DOWNLOAD_PDF_HISTORY_ANIMATION_DURATION } from './PdfDownloadHistory';
import { TCBookingLedgerFilter, FILTERS_ANIMATION_DURATION } from './TCBookingLedgerFilter';
import { TCBookingLedgerHeadline } from './TCBookingLedgerHeadline';

import { delay } from 'utils';

interface ITCBookingLedgerProps {
  companyUuid: string;
  currency: TCurrencyCode;
}

const TCBookingStatementLedger: React.FC<ITCBookingLedgerProps> = React.memo(props => {
  const dispatch = useDispatch();

  const { companyUuid } = props;
  const currencyCode: TCurrencyCode = props.currency.toUpperCase() as TCurrencyCode;

  const isAdmin = useSelector(isAdminSelector);
  const isFinanceUser = useSelector(isFinanceUserSelector);
  const isSR = useSelector(isSRSelector);

  const tcBookingStatementLedger = useSelector(tcBookingStatementSelector);
  const csvExportLoading = useSelector(tcBookingStatementCsvExportLoading);
  const exportCsvNamespace = useSelector(exportCsvNamespaceSelector);

  const companies = useSelector(companiesSelector);

  const [isFiltersDetailOpen, setIsFiltersDetailOpen] = useState(false);
  const [isDownloadPdfHistoryDetailOpen, setIsDownloadPdfHistoryDetailOpen] = useState(false);

  const { data } = tcBookingStatementLedger;

  useEffect(() => {
    if (companyUuid) {
      dispatch(tcBookingStatementselectCompanyAction(companyUuid));
    }
  }, [companyUuid]);

  useEffect(() => {
    if (currencyCode) {
      dispatch(tcBookingStatementRequestAction(currencyCode));
    }
  }, [currencyCode]);

  useEffect(() => {
    if (companyUuid && currencyCode) {
      dispatch(tcBookingStatementDownloadPdfHistoryRequestAction(companyUuid, currencyCode));
    }
  }, [companyUuid, currencyCode]);

  const toggleFilters = useCallback(
    async (isOpen: boolean) => {
      if (isDownloadPdfHistoryDetailOpen) {
        setIsDownloadPdfHistoryDetailOpen(false);
        await delay(DOWNLOAD_PDF_HISTORY_ANIMATION_DURATION);
      }
      setIsFiltersDetailOpen(isOpen);
    },
    [setIsFiltersDetailOpen, isDownloadPdfHistoryDetailOpen]
  );

  const handleCsvExport = useCallback(() => {
    dispatch(triggerExportCsvRequestAction());
  }, []);

  const filters = useMemo(() => ({ handler: toggleFilters, isOpen: isFiltersDetailOpen }), [
    toggleFilters,
    isFiltersDetailOpen,
  ]);

  const pdfGenerationInProgress = useSelector(statementPdfGenerationInProgress);
  const downloadHistoryInProgress = useSelector(statementDownloadHistoryInProgress);
  const downloadHistoryPdfs = useSelector(statementDownloadedPdfs);
  const tcBookingStatementRequests = useSelector(tcBookingStatementRequestSelector);
  const isDataRefetching = tcBookingStatementRequests.updateFilters === ENetworkRequestStatus.PENDING;

  const handleGeneratePdf = useCallback(() => {
    dispatch(tcBookingStatementDownloadPdfAction());
  }, []);

  const generatePdf = useMemo(
    () => ({
      handler: handleGeneratePdf,
      loading: pdfGenerationInProgress,
    }),
    [pdfGenerationInProgress]
  );

  const toggleDownloadPdfHistory = useCallback(
    async (isOpen: boolean) => {
      if (isFiltersDetailOpen) {
        setIsFiltersDetailOpen(false);
        await delay(FILTERS_ANIMATION_DURATION);
      }
      setIsDownloadPdfHistoryDetailOpen(isOpen);
    },
    [isFiltersDetailOpen]
  );

  const downloadHistory = useMemo(
    () => ({ handler: toggleDownloadPdfHistory, isOpen: isDownloadPdfHistoryDetailOpen }),
    [toggleDownloadPdfHistory, isDownloadPdfHistoryDetailOpen]
  );

  const csvExport = useMemo(
    () => (isAdmin || isFinanceUser || isSR ? [{ handler: handleCsvExport, loading: csvExportLoading }] : undefined),
    [isAdmin, isFinanceUser, isSR, csvExportLoading]
  );

  return (
    <>
      <Poller
        exportUuid={exportCsvNamespace.exportUuid}
        isWaitingForExport={exportCsvNamespace.isWaitingForExport}
        pollRequestFunction={() => dispatch(pollExportCsvRequestAction())}
        pollExportLoad={exportCsvNamespace.pollExportCsvLoad}
      />
      <div className="booking-ledger-container container mx-auto max-w-1280px font-pt-sans text-black">
        {tcBookingStatementLedger?.tcBookingStatementLoad === ENetworkRequestStatus.PENDING && <LoadingBar />}
        {tcBookingStatementLedger?.tcBookingStatementLoad === ENetworkRequestStatus.SUCCESS && data && (
          <>
            <Controls
              generatePdf={generatePdf}
              filters={filters}
              csvExport={csvExport}
              downloadHistory={downloadHistory}
            />

            <TCBookingLedgerFilter isOpen={isFiltersDetailOpen} />
            <PdfDownloadHistory
              pdfs={downloadHistoryPdfs}
              loading={downloadHistoryInProgress}
              isOpen={isDownloadPdfHistoryDetailOpen}
            />
            <TCBookingLedgerHeadline
              invoiceAmountTotal={data.invoiceAmountTotal}
              paidAmountTotal={data.paidAmountTotal}
              balanceToPayTotal={data.balanceToPayTotal}
              overdueTotal={data.overdueTotal}
              currency={currencyCode}
            />

            <TCBookingLedgerTable
              tcBookingStatementRows={data.rows}
              currency={currencyCode}
              tableLoading={isDataRefetching}
            />
          </>
        )}
      </div>
    </>
  );
});

export { TCBookingStatementLedger };
