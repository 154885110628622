import React from 'react';
import { EInvoiceAddresseeType } from 'store/modules/bookingManager/subdomains/breakdown/model';
import FluidButton from 'ui/FluidButton';
import { Radio } from 'ui/Radio';

export interface IInvoiceTPOrClient {
  handleSetTravelPartnerInvoice: () => void;
  handleSetFinalClientInvoice: () => void;
  handleEditAddressee: () => void;
  invoiceAddresseeType: string;
  isSupervisor: boolean;
  edit: string;
  invoiceType: string;
}

export function InvoiceTPOrClient(props: IInvoiceTPOrClient) {
  return (
    <div className="mt-[30px] flex items-center justify-between">
      <div className="choose-tp-or-client">
        <label
          className="inline-flex font-pt-sans text-base leading-xs text-black cursor-pointer"
          onClick={props.handleSetTravelPartnerInvoice}
        >
          <Radio
            onClick={props.handleSetTravelPartnerInvoice}
            checked={props.invoiceAddresseeType === EInvoiceAddresseeType.TRAVEL_PARTNER}
          />
          <span className="ml-1 mr-5">Travel Partner (TP)</span>
        </label>
        {props.isSupervisor && props.invoiceType === 'manual' && (
          <label
            className="inline-flex font-pt-sans text-base leading-xs text-black cursor-pointer"
            onClick={props.handleSetFinalClientInvoice}
          >
            <Radio
              onClick={props.handleSetFinalClientInvoice}
              checked={props.invoiceAddresseeType === EInvoiceAddresseeType.FINAL_CLIENT}
            />
            <span className="ml-1 mr-5">Final Client</span>
          </label>
        )}
      </div>

      <div>
        <FluidButton type="secondary" className="edit-addressee" onClick={props.handleEditAddressee}>
          Edit Addressee <img src={props.edit} width="13" height="13" alt="Edit" />
        </FluidButton>
      </div>
    </div>
  );
}
