import React, { useEffect, useState } from 'react';
import { HotelDetailsTab } from './components/hotel-edit-tabs/HotelDetails';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ENetworkRequestStatus, IHotel, makeBackendApi } from 'services/BackendApi';
import { Link } from 'ui/Link';
import { enqueueNotification } from 'store/modules/ui';
import { produce } from 'immer';
import * as BootstrapSelectors from 'store/modules/bootstrap/selectors';
import * as _ from 'lodash-es';

export const Create = () => {
  const [filtersCategories, setFiltersCategories] = useState<any[]>([]);
  const [starRatings, setStarRatings] = useState<string[]>([]);
  const [draftHotel, setDraftHotel] = useState<Partial<IHotel>>({
    name: '',
    description: '',
    // email: '',
    // phoneNumber: '',
    // address: '',
    countryCode: '',
    region: '',
    starRating: '',
    defaultCurrency: 'USD',
    amenities: [],
    highlights: [],
    overview: [],
    filters: [],
    // inLoveWith: '',
    // createdAt: '',
    // updatedAt: '',
    hotelId: '',
    enabled: true,
    disabledForTa: false,
    // reservationEmails: '',

    uploads: [],
  });

  const backendApi = makeBackendApi();
  const [createHotelRequest, setCreateHotelRequest] = useState(ENetworkRequestStatus.IDLE);

  const dispatch = useDispatch();
  useEffect(() => {
    backendApi.hotelAdminGetOptions().then(res => {
      setFiltersCategories(res.data.data.filtersCategories.map(fc => fc.filters).flat());
      setStarRatings(res.data.data.starRatings);
    });
  }, []);

  return (
    <div className="container w-1280px mx-auto">
      <Link to="/hotel-admin">Back to Hotels</Link>
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">
        Products - <span className="text-[26px]">Hotels - Creating new hotel</span>
      </h1>
      <HotelDetailsTab
        hotel={draftHotel}
        onUpdate={(field, val) => {
          setDraftHotel({
            ...produce(draftHotel, _draftHotel => {
              _.set(_draftHotel, field, val);
            }),
          });
        }}
        filtersCategories={filtersCategories}
        starRatings={starRatings}
        onCta={async () => {
          setCreateHotelRequest(ENetworkRequestStatus.PENDING);
          try {
            const newHotel = await backendApi.hotelAdminPostHotel(draftHotel);
            setCreateHotelRequest(ENetworkRequestStatus.SUCCESS);
            dispatch(
              enqueueNotification({
                message: `Hotel created successfully`,
                options: { variant: 'success' },
              })
            );
            window.location.href = `/hotel-admin/${newHotel.data.data.uuid}/edit`;
          } catch (error) {
            setCreateHotelRequest(ENetworkRequestStatus.ERROR);
            dispatch(
              enqueueNotification({
                message: 'Error creating hotel',
                options: { variant: 'error' },
              })
            );
          }
        }}
        networkRequest={createHotelRequest}
        setFeaturedPhoto={() => {}}
        deleteUpload={() => {}}
        ctaLabel="Create Hotel"
        mode="create"
      />
    </div>
  );
};
