import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { saveAs } from 'file-saver';

import { ENetworkRequestStatus, Upload } from 'services/BackendApi';
import { CompanyDocumentsSortField, TagLabel } from 'store/modules/companyDocuments/model';
import * as CompanyDocumentsActions from 'store/modules/companyDocuments/actions';
import {
  companyDocumentsSelector,
  companyDocumentsLoadSelector,
  uploadDocumentModalOpenSelector,
  deleteCompanyDocumentSelector,
  companyDocumentsSortFieldSelector,
  companyDocumentsSortOrderSelector,
} from 'store/modules/companyDocuments/selectors';
import { formatDateDisplay } from 'utils';
import { theme } from '../../../../tailwind.config';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import FluidButton from 'ui/FluidButton';
import { SvgIcon } from 'ui/SvgIcon';
import FileIcon from 'ui/Icons/file.component.svg';
import DownloadIcon from 'ui/Icons/download.component.svg';
import TrashIcon from 'ui/Icons/trash.component.svg';
import { StandardModal } from 'pureUi/Modal';

import { UploadFileModal } from './UploadFileModal';

export interface IDeleteConfirmationModalProps {
  isOpen: boolean;
  status: ENetworkRequestStatus;
}

export interface ICompanyDocumentsProps {
  isEditMode: boolean;
}

const DeleteConfirmationModal: React.FC<IDeleteConfirmationModalProps> = ({ isOpen, status }) => {
  const isLoading = status === ENetworkRequestStatus.PENDING;
  const dispatch = useDispatch();

  if (!isOpen) {
    return null;
  }

  return (
    <StandardModal
      className="delete-modal"
      frameClassName="w-500px px-9 py-8 border-4 border-solid border-yellow-50 rounded-xl"
      showCloseButton={false}
      removePadding={true}
    >
      <div className="flex justify-between items-center">
        <span className="fa-stack text-lg">
          <i className="fas fa-circle fa-stack-2x text-yellow-50"></i>
          <i className="fas fa-exclamation fa-stack-1x text-white"></i>
        </span>
        <div className="w-5/6">
          <h1 className="text-black text-lg font-pt-sans m-0">Are you sure you want to delete this file?</h1>
        </div>
      </div>

      <div className="flex justify-between mt-6">
        <button
          className="confirm block w-full mr-3 border-solid hover:bg-brown-120 cursor-pointer border-2 border-brown-100 hover:border-brown-120 text-base font-pt-sans p-2 rounded font-bold bg-brown-100 text-white tracking-wider outline-none disabled:text-gray-80 disabled:bg-gray-20 disabled:border-gray-20 disabled:cursor-default"
          onClick={() => dispatch(CompanyDocumentsActions.deleteCompanyDocumentRequestAction())}
          disabled={isLoading}
        >
          Delete
        </button>
        <button
          className="cancel block w-full ml-3 border-solid hover:bg-brown-20 cursor-pointer border-2 border-brown-100 text-base font-pt-sans p-2 rounded font-bold bg-white text-brown-100 tracking-wider outline-none disabled:text-gray-80 disabled:border-gray-20 disabled:bg-white disabled:cursor-default"
          onClick={() => dispatch(CompanyDocumentsActions.cancelDeleteCompanyDocumentAction())}
        >
          Cancel
        </button>
      </div>
    </StandardModal>
  );
};

export const CompanyDocuments: React.FC<ICompanyDocumentsProps> = ( {isEditMode} ) => {
  const companyDocuments: Upload[] = useSelector(companyDocumentsSelector);
  const companyDocumentsLoad = useSelector(companyDocumentsLoadSelector);
  const companyDocumentsSortField = useSelector(companyDocumentsSortFieldSelector);
  const companyDocumentsSortOrder = useSelector(companyDocumentsSortOrderSelector);
  const isUploadDocumentModalOpen = useSelector(uploadDocumentModalOpenSelector);
  const deleteDocument = useSelector(deleteCompanyDocumentSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CompanyDocumentsActions.fetchCompanyDocumentsRequestAction());
  }, [dispatch]);

  const handleSort = useCallback(
    (sortBy: CompanyDocumentsSortField) => () => {
      const newSortOrder = sortBy === companyDocumentsSortField && companyDocumentsSortOrder === 'asc' ? 'desc' : 'asc';
      dispatch(CompanyDocumentsActions.setCompanyDocumentsSortFieldAction(sortBy, newSortOrder));
    },
    [companyDocumentsSortField, companyDocumentsSortOrder, dispatch]
  );

  const handleUploadFile = useCallback(() => {
    dispatch(CompanyDocumentsActions.openUploadCompanyDocumentModalAction());
  }, [dispatch]);

  const handleSetDeleteCompanyDocument = useCallback(
    (fileUuid: string) => () => {
      dispatch(CompanyDocumentsActions.setDeleteCompanyDocumentAction(fileUuid));
    },
    [dispatch]
  );

  const renderSortIcon = (newSortField: CompanyDocumentsSortField) => {
    return (
      <span
        style={{ marginRight: '-18px', width: '38px' }}
        className={classNames('fa-stack fa-xs', {
          'group-hover:text-gray-40 text-ivory': newSortField !== companyDocumentsSortField,
        })}
      >
        <i
          style={{ position: 'relative', top: '-5px' }}
          className={classNames('fas fa-stack-1x fa-chevron-up', {
            'text-gray-100': companyDocumentsSortOrder === 'asc' && newSortField === companyDocumentsSortField,
            'text-gray-40': companyDocumentsSortOrder === 'desc' && newSortField === companyDocumentsSortField,
          })}
        ></i>
        <i
          style={{ position: 'relative', top: '-12px' }}
          className={classNames('fas fa-stack-1x fa-chevron-down', {
            'text-gray-40': companyDocumentsSortOrder === 'asc' && newSortField === companyDocumentsSortField,
            'text-gray-100': companyDocumentsSortOrder === 'desc' && newSortField === companyDocumentsSortField,
          })}
        ></i>
      </span>
    );
  };

  const renderHeaderSpan = (
    headerLabel: string,
    headerSortByKey?: CompanyDocumentsSortField,
    sortBy?: CompanyDocumentsSortField
  ) => {
    if (headerSortByKey === undefined) {
      return (
        <span className="group flex font-normal items-center px-2 justify-between h-8">
          <span>{headerLabel.toUpperCase()}</span>
        </span>
      );
    }

    return (
      <span
        className={classNames(
          'group flex font-normal items-center px-2 justify-between h-8 hover:bg-gray-10 cursor-pointer rounded',
          {
            rounded: sortBy === headerSortByKey,
            'bg-gray-15': sortBy === headerSortByKey,
          }
        )}
      >
        <span>{headerLabel}</span>
        {renderSortIcon(headerSortByKey)}
      </span>
    );
  };

  return (
    <div className="company-uploads w-full font-pt-sans">
      <p className="title text-black-true font-bold text-17px leading-md mb-0">Uploads and Other Documents</p>
      <p className="description text-black text-15px leading-sm mt-5px mb-0">
        Internal documentation of the Travel Partner.
      </p>

      {companyDocumentsLoad === ENetworkRequestStatus.PENDING && (
        <div className="mt-20px">
          <LoadingBar />
        </div>
      )}

      {companyDocumentsLoad === ENetworkRequestStatus.ERROR && (
        <div className="mt-20px">
          <ErrorBar />
        </div>
      )}

      {companyDocumentsLoad === ENetworkRequestStatus.SUCCESS && companyDocuments?.length > 0 && (
        <table className="company-uploads-table table table-xs table-fixed border-collapse text-left font-pt-sans mt-15px w-full">
          <thead>
            <tr className="additional-markups-table-headers bg-ivory border border-solid border-gray-40 shadow-pe2 h-10 uppercase text-xs text-gray-120 leading-16px tracking-2xs">
              <th id="createdAt-header" className="pl-4 w-200px" onClick={handleSort('createdAt')}>
                {renderHeaderSpan('Date of Creation', 'createdAt', companyDocumentsSortField)}
              </th>

              <th id="fileName-header" style={{ minWidth: '400px' }} onClick={handleSort('displayName')}>
                {renderHeaderSpan('File title', 'displayName', companyDocumentsSortField)}
              </th>

              <th id="category-header" style={{ minWidth: '150px' }} onClick={handleSort('tag')}>
                {renderHeaderSpan('Category', 'tag', companyDocumentsSortField)}
              </th>

              <th className="w-170px">{/* actions */}</th>
            </tr>
          </thead>
          <tbody>
            {companyDocuments &&
              companyDocuments.map((item, index) => (
                <tr
                  key={`company-upload-${item.ownerUuid}-${index}`}
                  className="h-50px text-black even:bg-ivory text-15px border-solid border-b border-b-gray-20"
                >
                  <td className="creation-date pl-6 text-ellipsis overflow-hidden whitespace-nowrap">
                    {formatDateDisplay(item.createdAt)}
                  </td>
                  <td className="display-name pl-2 text-ellipsis overflow-hidden whitespace-nowrap">
                    {item.displayName}
                  </td>
                  <td className="category pl-2 text-ellipsis overflow-hidden whitespace-nowrap">
                    {TagLabel[item.tag]}
                  </td>

                  <td className="actions px-2 text-right">
                    <div className="flex items-center">
                      <div className="w-1 h-30px bg-brown-15 rounded mr-15px"></div>
                      {isEditMode && (<div className="open-company-document-button cursor-pointer inline-block mx-auto mt-1">
                        <a onClick={() => {window.open(item.url)}}>
                          <SvgIcon
                            IconComponent={FileIcon}
                            defaultFill={theme.colors['white']}
                            hoverFill={theme.colors['gray-30']}
                            activeFill={theme.colors['ivory']}
                          />
                        </a>
                      </div>)}

                      <div className="download-company-document-button cursor-pointer inline-block mx-auto mt-1">
                        <a onClick={() => {saveAs(item.url, item.filename)}}>
                          <SvgIcon
                            IconComponent={DownloadIcon}
                            defaultFill={theme.colors['white']}
                            hoverFill={theme.colors['gray-30']}
                            activeFill={theme.colors['ivory']}
                          />
                        </a>
                      </div>

                      {isEditMode && (<div className="delete-company-document-button cursor-pointer inline-block mx-auto mt-1">
                        <a onClick={ () => {handleSetDeleteCompanyDocument(item.uuid)}}>
                          <SvgIcon
                            IconComponent={TrashIcon}
                            defaultFill={theme.colors['white']}
                            hoverFill={theme.colors['gray-30']}
                            activeFill={theme.colors['ivory']}
                          />
                        </a>
                      </div>)}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      {isEditMode && (<FluidButton 
        type="primary" 
        className="upload-company-document mt-20px" 
        onClick={handleUploadFile}
      >
        Upload file
      </FluidButton>)}

      <UploadFileModal isOpen={isUploadDocumentModalOpen} />
      <DeleteConfirmationModal isOpen={!!deleteDocument.uuid} status={deleteDocument.request} />
    </div>
  );
};

export default CompanyDocuments;
