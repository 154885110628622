import { IHotelAccommodationSubdomain, hotelAccommodationInitialState } from './model';
import * as Actions from './actions';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { produce } from 'immer';
import { uniq } from 'ramda';
export const bookingBuilderHotelAccommodationReducer = (
  state: IHotelAccommodationSubdomain = hotelAccommodationInitialState,
  action: Actions.BookingBuilderHotelAccommodationAction
): IHotelAccommodationSubdomain => {
  switch (action.type) {
    case Actions.GET_HOTEL_ACCOMMODATION_REQUEST: {
      return {
        ...state,
        networkRequests: {
          ...state.networkRequests,
          accommodationsLoad: ENetworkRequestStatus.PENDING,
        },
      };
    }
    case Actions.GET_HOTEL_ACCOMMODATION_SUCCESS: {
      return {
        ...state,
        accommodations: action.hotelAccommodations,
        networkRequests: {
          ...state.networkRequests,
          accommodationsLoad: ENetworkRequestStatus.SUCCESS,
        },
      };
    }
    case Actions.GET_HOTEL_ACCOMMODATION_FAILURE: {
      return {
        ...state,
        networkRequests: {
          ...state.networkRequests,
          accommodationsLoad: ENetworkRequestStatus.ERROR,
        },
      };
    }

    case Actions.UPDATE_ACCOMMODATION_RESPONSE_REQUEST: {
      return produce(state, draftState => {
        draftState.networkRequests.accommodationUpdating[action.accommodationUuid] = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }
    case Actions.UPDATE_ACCOMMODATION_RESPONSE_SUCCESS: {
      return produce(state, draftState => {
        const index = draftState.accommodations.findIndex(
          accom => accom.response.availableProductSets.Accommodation[0].products[0].uuid === action.accommodationUuid
        );
        draftState.accommodations[index].response = action.bookingBuilderResponse;
        draftState.networkRequests.accommodationUpdating[action.accommodationUuid] = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });
    }
    case Actions.UPDATE_ACCOMMODATION_RESPONSE_FAILURE: {
      return produce(state, draftState => {
        draftState.networkRequests.accommodationUpdating[action.accommodationUuid] = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    case Actions.CLEAR_ACCOMMODATIONS: {
      return produce(state, draftState => {
        draftState.accommodations = [];
        return draftState;
      });
    }

    case Actions.SET_ACCOMMODATION_START_DATE: {
      return produce(state, draftState => {
        const index = draftState.accommodations.findIndex(
          accom => accom.response.availableProductSets.Accommodation[0].products[0].uuid === action.accommodationUuid
        );
        draftState.accommodations[index].request.startDate = action.startDate;
        draftState.accommodations[index].request.Accommodation[0].startDate = action.startDate;
        return draftState;
      });
    }

    case Actions.SET_ACCOMMODATION_END_DATE: {
      return produce(state, draftState => {
        const index = draftState.accommodations.findIndex(
          accom => accom.response.availableProductSets.Accommodation[0].products[0].uuid === action.accommodationUuid
        );

        draftState.accommodations[index].request.endDate = action.endDate;
        draftState.accommodations[index].request.Accommodation[0].endDate = action.endDate;
        return draftState;
      });
    }

    case Actions.SET_NUMBER_OF_ADULTS: {
      return produce(state, draftState => {
        const index = draftState.accommodations.findIndex(
          accom => accom.response.availableProductSets.Accommodation[0].products[0].uuid === action.accommodationUuid
        );
        draftState.accommodations[index].request.guestAges.numberOfAdults = action.numberOfAdults;
        draftState.accommodations[index].request.Accommodation[0].guestAges.numberOfAdults = action.numberOfAdults;
        return draftState;
      });
    }

    case Actions.SET_AGES_OF_ALL_CHILDREN: {
      return produce(state, draftState => {
        const index = draftState.accommodations.findIndex(
          accom => accom.response.availableProductSets.Accommodation[0].products[0].uuid === action.accommodationUuid
        );
        draftState.accommodations[index].request.guestAges.agesOfAllChildren = action.agesOfAllChildren;
        draftState.accommodations[index].request.Accommodation[0].guestAges.agesOfAllChildren =
          action.agesOfAllChildren;
        return draftState;
      });
    }

    case Actions.SET_SELECTED_MEAL_PLAN_UUID: {
      return produce(state, draftState => {
        const index = draftState.accommodations.findIndex(
          accom => accom.response.availableProductSets.Accommodation[0].products[0].uuid === action.accommodationUuid
        );
        draftState.accommodations[index].request.Accommodation[0].subProducts = {
          'Meal Plan': action.selectedMealPlanUuids.map(uuid => {
            return {
              uuid,
            };
          }),
        };
        draftState.haveModifiedAccommodationWithoutUpdating = true;
        return draftState;
      });
    }

    case Actions.TOGGLE_OCCASION: {
      return produce(state, draftState => {
        const accommodation = draftState.accommodations.find(
          accom => accom.response.availableProductSets.Accommodation[0].products[0].uuid === action.accommodationUuid
        );
        if (accommodation !== undefined) {
          accommodation.request.Accommodation[0][action.occasionType] = action.isChecked;
        }
        return draftState;
      });
    }

    case Actions.SET_ACTIVE_ACCOMMODATION_TAB: {
      return produce(state, draftState => {
        draftState.activeAccommodationTab = action.accommodationTabIndex;
        return draftState;
      });
    }

    case Actions.SET_ACTIVE_ACCOMMODATION_VIEW_MODE: {
      return produce(state, draftState => {
        draftState.activeAccommodationViewMode = action.accommodationViewMode;
        return draftState;
      });
    }

    case Actions.OPEN_EDIT_ACCOMMODATIONS_MODAL: {
      return { ...state, editAccommodationsModalOpen: true };
    }

    case Actions.CLOSE_EDIT_ACCOMMODATIONS_MODAL: {
      return { ...state, editAccommodationsModalOpen: false };
    }

    case Actions.ADD_ACCOMMODATION_TO_IS_OPEN:
      return produce(state, draftState => {
        draftState.hotelAccommodationUuidsOpen.push(action.accommodationUuid);
        draftState.hotelAccommodationUuidsOpen = uniq(draftState.hotelAccommodationUuidsOpen);
      });

    case Actions.REMOVE_ACCOMMODATION_FROM_IS_OPEN:
      return produce(state, draftState => {
        draftState.hotelAccommodationUuidsOpen = draftState.hotelAccommodationUuidsOpen.filter(
          uuid => uuid !== action.accommodationUuid
        );
      });

    case Actions.CLEAR_ALL_ACCOMMODATION_FROM_IS_OPEN:
      return produce(state, draftState => {
        draftState.hotelAccommodationUuidsOpen = [];
      });

    case Actions.REPLACE_OPEN_ACCOMMODATIONS:
      return produce(state, draftState => {
        draftState.hotelAccommodationUuidsOpen = action.openAccommodationUuids;
      });

    case Actions.SET_MODIFIED_WITHOUT_UPDATING:
      return produce(state, draftState => {
        draftState.haveModifiedAccommodationWithoutUpdating = action.isModifiedWithoutUpdating;
      });

    case Actions.CLOSE_MIX_RATE_TYPES_ALERT:
      return produce(state, draftState => {
        draftState.showMixRateTypesAlert = false;
      });

    default:
      return state;
  }
};
