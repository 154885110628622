import * as React from 'react';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, RouteChildrenProps, withRouter } from 'react-router';

import { ENetworkRequestStatus } from 'services/BackendApi/types';

import {
  downloadsSelector,
  downloadsLoadFailedSelector,
  networkRequestsSelector,
} from 'store/modules/bookingManager/subdomains/downloads/selectors';

import { getDownloadsRequestAction } from 'store/modules/bookingManager/subdomains/downloads/actions';

import FileNav from 'pureUi/FileNav';
import FilePreview from 'pureUi/FilePreview';
import { RefreshButton } from 'pureUi/Buttons';
import { LoadingBar } from 'ui/NetworkStatusBar';

import { BookingManagerDownloadsStyles } from './BookingManagerDownloadsStyles';

export class BookingManagerDownloads extends React.Component<IBookingManagerDownloadsProps, {}> {
  componentDidMount() {
    this.props.getDownloadsRequest();
  }

  getRoute = (downloadUuid: string): string => `${this.props.match?.url}/view/${downloadUuid}`;

  renderError = () => (
    <div className="error">
      <p>Failed to load downloads</p>
      <RefreshButton onClick={this.props.getDownloadsRequest} />
    </div>
  );

  renderEmptyMessage = () => (
    <div className="info">
      <p>There is no downloads associated with this booking</p>
    </div>
  );

  isLoadingDownloads = () => this.props.networkRequest.downloadsLoad === ENetworkRequestStatus.PENDING;

  renderDownloads = () => {
    const { downloads, match } = this.props;
    return (
      <div className="downloads">
        <Switch>
          {(downloads || []).map(f => (
            <Route key={f.uuid} path={this.getRoute(f.uuid)}>
              <div className="preview-container">
                <FilePreview className="preview" file={f} />
              </div>
            </Route>
          ))}
          <Route
            exact
            path={match?.url}
            render={() => <Redirect to={this.getRoute(downloads?.[0].uuid as string)} />}
          />
        </Switch>
        <div className="list-container">
          <FileNav className="list" files={downloads || []} basePath={`${match?.url}/view`} />
        </div>
      </div>
    );
  };

  render() {
    const { downloads, downloadsLoadFailed } = this.props;

    if (this.isLoadingDownloads()) {
      return <LoadingBar />;
    }

    return (
      <BookingManagerDownloadsStyles>
        {downloadsLoadFailed && this.renderError()}
        {downloads && !downloads.length && this.renderEmptyMessage()}
        {downloads && !!downloads.length && this.renderDownloads()}
      </BookingManagerDownloadsStyles>
    );
  }
}

export type StateToProps = ReturnType<typeof mapStateToProps>;
export type DispatchToProps = typeof actionCreators;

export interface IRouteParams {
  downloadUuid: string;
}
export interface IBookingManagerDownloadsProps
  extends StateToProps,
    DispatchToProps,
    RouteChildrenProps<IRouteParams> {}

const mapStateToProps = createStructuredSelector({
  downloads: downloadsSelector,
  downloadsLoadFailed: downloadsLoadFailedSelector,
  networkRequest: networkRequestsSelector,
});

const actionCreators = {
  getDownloadsRequest: getDownloadsRequestAction,
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

// -----------------------------------------------------------------------------
// Connected
// -----------------------------------------------------------------------------
const withConnect = connect<StateToProps, DispatchToProps, IBookingManagerDownloadsProps>(
  mapStateToProps,
  mapDispatchToProps
);

export const BookingManagerDownloadsConnected = compose(withConnect, withRouter)(BookingManagerDownloads);
export default BookingManagerDownloadsConnected;
