import { pick, pipe, prop, propOr, isEmpty, filter, merge } from 'ramda';

import { createSelector } from 'store/utils';
import { getEntities, getArg } from 'store/common';

/**
 * Get hotels selector
 *
 * @param {object}
 * @returns {object}
 */
export const getHotels = prop('hotels');

/**
 * Get uploads selector
 *
 * Returns all uploads for all hotels
 *
 * @param {object}
 * @returns {object}
 */
const getUploads = pipe(getHotels, getEntities, prop('uploads'));

export const getHotelsEntities = pipe(getHotels, getEntities, prop('hotels'));

/**
 * Get hotels status selector
 *
 * @param {object}
 * @param {string}
 * @returns {object}
 */
export const getHotel = createSelector([getArg(1), getHotelsEntities], prop);

/**
 * Get hotel name selector
 *
 * @param {object}
 * @param {string}
 * @returns {string}
 */
export const getHotelName = createSelector(getHotel, prop('name'));

/**
 * Get hotel default currency
 *
 * Returns the currency symbol for the default currency attached to the hotel
 *
 * @param {object}
 * @param {string}
 * @returns {string}
 */
export const getHotelDefaultCurrency = createSelector(getHotel, prop('defaultCurrency'));

export const getHotelsFilesByTag = fileTag => (hotel, uploads) => {
  if (!hotel || !uploads || isEmpty(hotel) || isEmpty(uploads)) {
    return {};
  }

  return filter(
    upload => upload.ownerType === 'Hotel' && upload.tag === fileTag && upload.ownerUuid === hotel.uuid,
    uploads
  );
};

/**
 * Get hotels photos selector
 *
 * @param {object}
 * @param {string}
 * @returns {object}
 */
export const getHotelsPhotos = createSelector([getHotel, getUploads], (hotel, uploads) =>
  getHotelsFilesByTag('photo')(hotel, uploads)
);

/**
 * Get hotels resort map plus brochures selector
 *
 * @param {object}
 * @param {string}
 * @returns {object}
 */
export const getHotelsBrochures = createSelector([getHotel, getUploads], (hotel, uploads) => {
  const resortMap = getHotelsFilesByTag('resortMap')(hotel, uploads);
  const brochures = pick(propOr([], 'brochures', hotel), uploads);

  return merge(resortMap, brochures);
});
