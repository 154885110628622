import React from 'react';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { Link } from 'ui/Link';

import Checkbox from 'ui/Checkbox';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import DateRangeInput from 'pureUi/DateRangeInput';
import { IWithBootstrapDataProps, withBootstapData } from 'hoc/WithBootstrapData';

import {
  rateBreakNameSelector,
  rateBreakPercentageSelector,
  selectedHotelUuidSelector,
  selectedRateApplicationSelector,
  availableHotelAccommodationsSelector,
  stayBetweenDateRangesSelector,
  isPreDiscountSelector,
  discountExtraPersonSupplementSelector,
  excludeServiceAndValueTaxesSelector,
  selectedHotelAccommodationUuidsSelector,
  selectedTravelAgentCountryCodesSelector,
  networkRequestsSelector,
  rateBreakUuidSelector,
  selectedHotelNameSelector,
  selectedCountriesCountPerRegionSelector,
  selectedHotelCountryCodeSelector,
  rateBreakHasChangesSelector,
} from 'store/modules/rateBreak/selectors';

import {
  setRateBreakUuidAction,
  setRateBreakNameAction,
  setRateBreakPercentageAction,
  setSelectedHotelUuidAction,
  setSelectedRateApplicationAction,
  addNewStayBetweenAction,
  deleteStayBetweenAction,
  setStayBetweenSelectedDates,
  setIsPreDiscountAction,
  setDiscountExtraPersonSupplementAction,
  setExcludeServiceAndValueTaxesAction,
  toggleSelectedAccommodationUuidAction,
  toggleSelectedTaCountryCodeAction,
  resetSelectedAccommodationUuidsAction,
  resetSelectedTaCountriesAction,
  saveRateBreakRequestAction,
  clearFormAction,
  getRateBreakRequestAction,
  setCurrentPaginationPage,
  setRateBreakHasChangesAction,
} from 'store/modules/rateBreak/actions';

import { bootstrapCountriesByRegionSelector } from 'store/modules/bootstrap/selectors';
import { Multiselect } from 'ui/Multiselect';
import { ERateApplicationTypes } from 'store/modules/rateBreak/types';
import { Accordian, AccordianSection } from 'pureUi/Accordian';
import { without } from 'ramda';
import { CircleIconButton } from 'ui/CircleIconButton';
import FluidButton from 'ui/FluidButton';
import { DecimalInput } from 'ui/stateful/DecimalInput';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { LoadingBar, ErrorBar } from 'ui/NetworkStatusBar';
import classNames from 'classnames';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';

export class RateBreakFormContainer extends React.Component<IRateBreakEditProps, IRateBreakEditState> {
  constructor(props) {
    super(props);

    this.state = {
      openKeys: [],
    };
  }

  componentDidMount() {
    const isEditMode = this.props.match.path.includes('edit');

    // if we're in edit mode and we've just mounted, use the UUID from the url
    // to load the rate break (which will save everything into the store)
    if (isEditMode && this.props.match.params.uuid != null) {
      this.props.setRateBreakUuidAction(this.props.match.params.uuid);
      this.props.getRateBreakRequestAction();
    }
  }

  handleDiscardChanges = () => {
    this.props.setCurrentPaginationPage(1);
    this.props.history.push('/rate-breaks');
  };

  render() {
    const isEditMode = this.props.match.path.includes('edit');
    // calculate the vat based on SERVICE_AND_VALUE_TAXES value.
    const parameters = ParameterService.getParameters();
    const vatPercentage = parameters.SERVICE_AND_VALUE_TAXES
      ? ((parameters.SERVICE_AND_VALUE_TAXES - 1) * 100).toFixed(1)
      : 0;

    // if we're not currently in editing mode but we DO now have a UUID (for example, we've just
    // created and saved a rate break), throw them to editing mode
    if (!isEditMode && this.props.rateBreakUuid) {
      return <Redirect to={`/rate-breaks/${this.props.rateBreakUuid}/edit`} />;
    }

    // if we've hit the edit URL but we don't have a UUID loaded, go back to create
    if (
      isEditMode &&
      this.props.rateBreakUuid === undefined &&
      this.props.networkRequests.getRateBreak === ENetworkRequestStatus.IDLE
    ) {
      return <Redirect to={`/rate-breaks/create`} />;
    }

    const isSaveButtonDisabled =
      this.props.stayBetweenDateRanges.length <= 0 ||
      this.props.stayBetweenDateRanges.every(sb => sb.selectedDates.length <= 0) ||
      this.props.rateBreakName === '' ||
      this.props.selectedHotelUuid === null ||
      (this.props.selectedHotelCountryCode === 'MV' && this.props.selectedRateApplication === null) ||
      !this.props.rateBreakHasChanges;

    const isDiscardButtonDisabled = !this.props.rateBreakHasChanges;

    if (isEditMode && this.props.networkRequests.getRateBreak === ENetworkRequestStatus.ERROR) {
      return (
        <div className="container mx-auto max-w-1280px font-pt-sans">
          <Link to="/rate-breaks">
            <span className="inline-block cursor-pointer font-bold mb-2 underline text-brown-100">
              Back to Rate Breaks
            </span>
          </Link>

          <h1 className="font-noe-display text-3xl font-normal p-0 mb-10 mt-0 mx-0">Rate Break</h1>

          <ErrorBar />
        </div>
      );
    }

    if (
      isEditMode &&
      (this.props.networkRequests.getRateBreak === ENetworkRequestStatus.PENDING ||
        this.props.bootstrapHotels.length <= 0)
    ) {
      return (
        <div className="container mx-auto max-w-1280px font-pt-sans">
          <Link to="/rate-breaks">
            <span className="inline-block cursor-pointer font-bold mb-2 underline text-brown-100">
              Back to Rate Breaks
            </span>
          </Link>

          <h1 className="font-noe-display text-3xl font-normal p-0 mb-10 mt-0 mx-0">Rate Break</h1>

          <LoadingBar />
        </div>
      );
    }

    return (
      <div className="container mx-auto max-w-1280px font-pt-sans">
        <Link to="/rate-breaks">
          <span className="back-to-rate-breaks inline-block cursor-pointer font-bold mb-2 underline text-brown-100">
            Back to Rate Breaks
          </span>
        </Link>

        {this.props.rateBreakUuid == null && (
          <h1 className="font-noe-display text-3xl font-normal p-0 mb-10 mt-0 mx-0">Adding New Rate Break</h1>
        )}

        {this.props.rateBreakUuid != null && (
          <h1 className="font-noe-display text-3xl font-normal p-0 mb-10 mt-0 mx-0">
            Rate Break for <span className="font-pt-sans font-bold">{this.props.selectedHotelName}</span>
          </h1>
        )}

        {/* basic info (name, hotel, percentage, green tax, pre discount) */}
        <section>
          <div className="flex justify-between">
            <label className="block w-full">
              <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Rate Name</span>
              <input
                type="text"
                className="p-2 bg-ivory border border-solid border-gray-40 focus:outline-gray-80 min-h-35px w-full"
                value={this.props.rateBreakName}
                onChange={e => {
                  this.props.setRateBreakNameAction(e.target.value);
                }}
              />
            </label>

            {!isEditMode && (
              <label className="block w-full mx-4">
                <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
                  Hotel Name
                </span>
                <Multiselect
                  className="hotel-select focus:outline-gray-80"
                  itemsClassname="bg-white"
                  itemCtaClassName="hover:bg-gray-10"
                  hideCheckboxes={true}
                  options={this.props.bootstrapHotels.map((hotel, i) => {
                    return {
                      value: hotel.uuid,
                      label: hotel.name,
                    };
                  })}
                  isCloseOnSelect={true}
                  isSingleSelectMode={true}
                  onUpdate={selectedValues => {
                    if (selectedValues.length <= 0) {
                      this.props.setSelectedHotelUuidAction(null);
                    } else {
                      this.props.setSelectedHotelUuidAction(selectedValues[0]);
                    }
                  }}
                  selectedValues={this.props.selectedHotelUuid ? [this.props.selectedHotelUuid] : []}
                />
              </label>
            )}

            <label className="block w-full mx-4">
              <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
                Rate Break Percentage (%)
              </span>
              <span className="flex items-center">
                <DecimalInput
                  min={0}
                  max={100}
                  value={this.props.rateBreakPercentage || 0}
                  className="p-2 w-13 bg-ivory border border-solid border-gray-40 focus:outline-gray-80 min-h-35px"
                  decimalPlaces={2}
                  onBlur={updatedValue => {
                    this.props.setRateBreakPercentageAction(updatedValue as number);
                  }}
                />

                <span className="text-13px text-gray-100 ml-2">Including 2 decimal places (##.##)</span>
              </span>
            </label>

            <label className="block w-full">
              {this.props.selectedHotelCountryCode === 'MV' && (
                <React.Fragment>
                  <span className="block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
                    Green Tax Discount Approach
                  </span>
                  <Multiselect
                    className="green-tax-select focus:outline-gray-80"
                    itemsClassname="bg-white"
                    itemCtaClassName="hover:bg-gray-10"
                    hideCheckboxes={true}
                    options={[
                      {
                        value: ERateApplicationTypes.discountBeforeGreenTax,
                        label: 'Discount Before Green Tax',
                      },
                      {
                        value: ERateApplicationTypes.discountWithGreenTaxAsMinimum,
                        label: 'Discount With Green Tax As Minimum',
                      },
                      {
                        value: ERateApplicationTypes.discountWithGreenTax,
                        label: 'Discount With Green Tax',
                      },
                    ]}
                    isCloseOnSelect={true}
                    isSingleSelectMode={true}
                    onUpdate={selectedValues => {
                      if (selectedValues.length <= 0) {
                        this.props.setSelectedRateApplicationAction(null);
                      } else {
                        this.props.setSelectedRateApplicationAction(selectedValues[0] as ERateApplicationTypes);
                      }
                    }}
                    selectedValues={this.props.selectedRateApplication ? [this.props.selectedRateApplication] : []}
                  />
                </React.Fragment>
              )}
            </label>
          </div>

          <div className="mt-4">
            <label className="block text-black text-13px leading-14px tracking-2xs font-pt-sans">Pre-discount</label>
            <label className="flex items-center mt-2">
              <Checkbox
                checked={this.props.isPreDiscount}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.props.setIsPreDiscountAction(e.target.checked);
                }}
              />
              <span className="ml-2 cursor-pointer text-13px text-gray-100">
                By checking the box, the rate break is applied to the rate amount before offers. If left unchecked the
                rate break is applied to the rate amount after offers.
              </span>
            </label>
          </div>

          <div className="mt-4">
            <label className="block text-black text-13px leading-14px tracking-2xs font-pt-sans">
              Also Apply Rate Break to Extra Person Supplements
            </label>
            <label className="flex items-center mt-2">
              <Checkbox
                checked={this.props.discountExtraPersonSupplement}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.props.setDiscountExtraPersonSupplementAction(e.target.checked);
                }}
              />
              <span className="ml-2 cursor-pointer text-13px text-gray-100">
                By checking the box, the rate break is applied to any extra person supplements as well as the room rate.
              </span>
            </label>
          </div>

          <div className="mt-4">
            <label className="block text-black text-13px leading-14px tracking-2xs font-pt-sans">
              Apply Rate Break to amount without Service and Value Added Taxes
            </label>
            <label className="flex items-center mt-2">
              <Checkbox
                checked={this.props.excludeServiceAndValueTaxes}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.props.setExcludeServiceAndValueTaxesAction(e.target.checked);
                }}
              />
              <span className="ml-2 cursor-pointer text-13px text-gray-100">
                By checking the box, the rate break is applied to the amount without the {vatPercentage}% Service and
                VAT.
              </span>
            </label>
          </div>
        </section>

        {/* stay between date ranges */}
        <section>
          <h2 className="mt-15 font-pt-sans text-17px font-bold">
            Stay Between <span className="font-normal">(Required)</span>
          </h2>

          <div className="flex flex-wrap">
            {this.props.stayBetweenDateRanges.map(sb => {
              return (
                <div
                  key={sb.uuid}
                  className="flex items-center justify-between w-1/4 mr-8 mb-4 border-solid border-brown-15 border-4 border-t-0 border-l-0 border-b-0"
                >
                  <DatePickerStateProvider
                    isSingleDateSelection={false}
                    defaultSelectedDates={sb.selectedDates}
                    onDateChange={dateTimeStrings => {
                      this.props.setStayBetweenSelectedDates(sb.uuid, dateTimeStrings);
                    }}
                    render={(params: IDatePickerSateParams) => (
                      <DateRangeInput
                        noPortal
                        totalNights={params.totalNights}
                        className="serachBarDateRangeInput"
                        displayString={params.displayString}
                        currentDate={params.datePickerCurrentDate}
                        selectedDates={params.selectedDates}
                        onDayClick={params.handleDayClick}
                        showDatePicker={params.showDatePicker}
                        onNextClick={params.incrementDate}
                        onPrevClick={params.decrementDate}
                        onMouseDown={params.toggleDatePicker}
                        onClickOutside={params.hideDatePicker}
                      />
                    )}
                  />

                  <CircleIconButton
                    type="secondary"
                    className="mr-3 ml-2"
                    onClick={() => {
                      this.props.deleteStayBetweenAction(sb.uuid);
                    }}
                    iconClass="fas fa-times"
                  />
                </div>
              );
            })}
          </div>

          <FluidButton type="secondary" className="mt-4 mb-4" onClick={() => this.props.addNewStayBetweenAction()}>
            Add Date Range
          </FluidButton>
        </section>

        <hr className="border border-solid border-gray-40" />

        {/* accommodation products */}
        <section>
          <div className="flex items-center justify-between">
            <h2 className="font-pt-sans text-17px font-bold">Accommodation Products</h2>

            {this.props.availableHotelAccommodations.length >= 1 && (
              <span className="flex items-center">
                <span className="text-15px text-brown-100">
                  {this.props.selectedHotelAccommodationUuids.length <= 0 && (
                    <span>Rate Break Applies to Any Accommodation Product</span>
                  )}

                  {this.props.selectedHotelAccommodationUuids.length >= 1 && (
                    <span>
                      Rate Break Applies to{' '}
                      <span className="font-bold">{this.props.selectedHotelAccommodationUuids.length}</span>{' '}
                      Accommodation {this.props.selectedHotelAccommodationUuids.length > 1 ? 'Products' : 'Product'}
                    </span>
                  )}
                </span>
                <FluidButton
                  className="ml-2"
                  type="secondary"
                  onClick={() => this.props.resetSelectedAccommodationUuidsAction()}
                >
                  Reset Selection
                </FluidButton>
              </span>
            )}
          </div>

          {this.props.networkRequests.getHotelAccommodations === ENetworkRequestStatus.PENDING && (
            <span className="block mb-9">
              <LoadingBar />
            </span>
          )}

          {this.props.networkRequests.getHotelAccommodations === ENetworkRequestStatus.IDLE &&
            this.props.availableHotelAccommodations.length <= 0 && (
              <span className="block text-13px text-gray-100 mb-9">
                Please select a hotel to select hotel accommodation products
              </span>
            )}

          {this.props.availableHotelAccommodations.length >= 1 && (
            <div className="mt-4">
              <div className="flex flex-wrap">
                {this.props.availableHotelAccommodations.map(accom => {
                  return (
                    <label key={accom.uuid} className="flex items-center w-1/3 mb-3">
                      <Checkbox
                        checked={this.props.selectedHotelAccommodationUuids.includes(accom.uuid)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          this.props.toggleSelectedAccommodationUuidAction(accom.uuid, e.target.checked);
                        }}
                      />
                      <span className="cursor-pointer ml-2 ">{accom.name}</span>
                    </label>
                  );
                })}
              </div>
              <span className="mt-5 block text-13px text-gray-100">
                If no accommodation types are specified, this rate break will be available to bookings for any
                accommodation type.
              </span>
              <span className="block text-13px text-gray-100 mb-9">
                If accommodation types are not relevant to this rate break, leave this section empty.
              </span>
            </div>
          )}
        </section>

        <hr className="border border-solid border-gray-40" />

        {/* travel agent countries */}
        <section>
          <div className="flex items-center justify-between mb-4">
            <h2 className="font-pt-sans text-17px font-bold">Travel Agent Countries</h2>

            <span className="flex items-center">
              <span className="text-15px text-brown-100">
                {this.props.selectedTravelAgentCountryCodes.length <= 0 && <span>Available to any country</span>}

                {this.props.selectedTravelAgentCountryCodes.length >= 1 && (
                  <span>
                    Available to <span className="font-bold">{this.props.selectedTravelAgentCountryCodes.length}</span>{' '}
                    {this.props.selectedTravelAgentCountryCodes.length === 1 ? 'country' : 'countries'}
                  </span>
                )}
              </span>
              <FluidButton
                className="ml-2"
                type="secondary"
                onClick={() => this.props.resetSelectedTaCountriesAction()}
              >
                Reset Selection
              </FluidButton>
            </span>
          </div>

          <Accordian>
            {Object.keys(this.props.bootstrapCountriesByRegion).map(region => {
              const isAccordianSectionOpen = this.state.openKeys.includes(region);
              return (
                <AccordianSection
                  key={region}
                  className={classNames({
                    'odd:bg-ivory': !isAccordianSectionOpen,
                    'bg-teal-15': isAccordianSectionOpen,
                  })}
                  title={region}
                  isOpen={isAccordianSectionOpen}
                  onClick={() => {
                    if (isAccordianSectionOpen) {
                      this.setState({
                        openKeys: without([region], this.state.openKeys),
                      });
                    } else {
                      this.setState({
                        openKeys: [...this.state.openKeys, region],
                      });
                    }
                  }}
                  suffix={`${this.props.selectedCountriesCountPerRegion[region]} countries`}
                >
                  <div className="flex flex-wrap py-4 px-8">
                    {this.props.bootstrapCountriesByRegion[region].map(country => {
                      return (
                        <label key={country.code} className="flex items-center w-1/3 mb-3">
                          <Checkbox
                            checked={this.props.selectedTravelAgentCountryCodes.includes(country.code)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              this.props.toggleSelectedTaCountryCodeAction(country.code, e.target.checked);
                            }}
                          />
                          <span className="cursor-pointer ml-2 ">{country.name}</span>
                        </label>
                      );
                    })}
                  </div>
                </AccordianSection>
              );
            })}
          </Accordian>
        </section>

        <section className="mt-4">
          {!isEditMode && (
            <FluidButton
              isLoading={this.props.networkRequests.saveRateBreak === ENetworkRequestStatus.PENDING}
              disabled={isSaveButtonDisabled}
              className="m-auto"
              type="primary"
              onClick={() => this.props.saveRateBreakRequestAction()}
            >
              Create
            </FluidButton>
          )}

          {isEditMode && (
            <div className="flex items-center justify-center">
              <FluidButton
                className="mr-2"
                type="secondary"
                onClick={this.handleDiscardChanges}
                disabled={isDiscardButtonDisabled}
              >
                Discard Changes
              </FluidButton>
              <FluidButton
                className="ml-2"
                isLoading={this.props.networkRequests.saveRateBreak === ENetworkRequestStatus.PENDING}
                disabled={isSaveButtonDisabled}
                type="primary"
                onClick={() => this.props.saveRateBreakRequestAction()}
              >
                Update Changes
              </FluidButton>
            </div>
          )}
        </section>
      </div>
    );
  }
}

// -----------------------------------------------------------------------------
// Prop Typings
// -----------------------------------------------------------------------------
export type StateToProps = ReturnType<typeof mapStateToProps>;
export type DispatchToProps = typeof actionCreators;

export interface IRouteParams {
  uuid: string;
}

export interface IRateBreakEditProps
  extends StateToProps,
    DispatchToProps,
    IWithBootstrapDataProps,
    RouteComponentProps<IRouteParams> {
  className?: string;
}

export interface IRateBreakEditState {
  openKeys: string[];
}

const mapStateToProps = createStructuredSelector({
  rateBreakName: rateBreakNameSelector,
  rateBreakPercentage: rateBreakPercentageSelector,
  selectedHotelUuid: selectedHotelUuidSelector,
  selectedRateApplication: selectedRateApplicationSelector,
  availableHotelAccommodations: availableHotelAccommodationsSelector,
  stayBetweenDateRanges: stayBetweenDateRangesSelector,
  isPreDiscount: isPreDiscountSelector,
  discountExtraPersonSupplement: discountExtraPersonSupplementSelector,
  excludeServiceAndValueTaxes: excludeServiceAndValueTaxesSelector,
  selectedHotelAccommodationUuids: selectedHotelAccommodationUuidsSelector,
  bootstrapCountriesByRegion: bootstrapCountriesByRegionSelector,
  selectedTravelAgentCountryCodes: selectedTravelAgentCountryCodesSelector,
  networkRequests: networkRequestsSelector,
  rateBreakUuid: rateBreakUuidSelector,
  selectedHotelName: selectedHotelNameSelector,
  selectedCountriesCountPerRegion: selectedCountriesCountPerRegionSelector,
  selectedHotelCountryCode: selectedHotelCountryCodeSelector,
  rateBreakHasChanges: rateBreakHasChangesSelector,
});

const actionCreators = {
  setRateBreakUuidAction,
  setRateBreakNameAction,
  setRateBreakPercentageAction,
  setSelectedHotelUuidAction,
  setSelectedRateApplicationAction,
  addNewStayBetweenAction,
  deleteStayBetweenAction,
  setStayBetweenSelectedDates,
  setIsPreDiscountAction,
  setDiscountExtraPersonSupplementAction,
  setExcludeServiceAndValueTaxesAction,
  toggleSelectedAccommodationUuidAction,
  toggleSelectedTaCountryCodeAction,
  resetSelectedAccommodationUuidsAction,
  resetSelectedTaCountriesAction,
  saveRateBreakRequestAction,
  clearFormAction,
  getRateBreakRequestAction,
  setCurrentPaginationPage,
  setRateBreakHasChangesAction,
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

// -----------------------------------------------------------------------------
// Connected
// -----------------------------------------------------------------------------
const withConnect = connect<StateToProps, DispatchToProps, IRateBreakEditProps>(mapStateToProps, mapDispatchToProps);

export const RateBreakFormContainerConnected = compose(
  withConnect,
  withRouter,
  withBootstapData()
)(RateBreakFormContainer);
