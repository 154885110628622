import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import * as Yup from 'yup';

import { TextInput } from 'ui/TextInput';
import { GeneralModal } from 'ui/GeneralModal';
import { Warning } from 'ui/Warning';
import { getAuthError, getAuthStatus, signUp } from 'store/modules/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentWidth } from 'effects';
import { Field, Formik, FormikProps } from 'formik';
import { Status } from 'store/common';
import { Link } from 'ui/Link';
import { titleOptions } from 'utils/titles';
import { Radio } from 'ui/Radio';
import { FormLabel } from 'ui/FormLabel';
import countries from 'config/data/countries.json';
import Checkbox from 'ui/Checkbox';
import SingleSelect from 'ui/SingleSelect';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { COGNITO_ENABLE_FLOW, REDIRECT_COGNITO_URI_IF_LOCALHOST } from 'config';

export interface iSignupModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

const countryOptions = Object.values(countries).map(country => ({
  value: country.code,
  label: country.name,
}));

const signupFormValidationSchema = Yup.object().shape({
  title: Yup.string().required('This field is required').nullable(),
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  email: Yup.string()
    .email('Email address is invalid')
    .required('This field is required'),
  mobileNumber: Yup.string().required('This field is required'),
  phoneNumber: Yup.string().required('This field is required'),
  companyName: Yup.string()
    .min(5, 'Company Name should be at least 5 characters long')
    .required('This field is required'),
  companyCountryCode: Yup.string().required('This field is required'),
  isExistingPartner: Yup.boolean(),
  agreeToTerms: Yup.boolean().oneOf([true], 'Must accept Terms and Conditions'),
});

export interface iSignupFormValues {
  title: string | null;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  phoneNumber: string;
  companyName: string;
  companyCountryCode: string;
  isExistingPartner: boolean;
  agreeToTerms: boolean;
}

export const SignupModal = (props: iSignupModalProps) => {
  const { isOpen, onClose, onSuccess } = props;
  const { dynamicParameters } = useDynamicParameters();
  const titleSlicedOptions = titleOptions.slice(1);

  const dispatch = useDispatch();
  const [signupErrorMessage, setSignupErrorMessage] = useState<string | React.ReactElement>('');
  const authError: any = useSelector(getAuthError);
  const signupErrors = authError?.data?.errors;

  const [success, setSuccess] = useState(false);
  const authStatus = useSelector(getAuthStatus);
  const loading = authStatus === Status.SENDING;
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { isMobile, isMobileLandscape, isTablet, isDesktop, isDesktopHD } = useCurrentWidth();
  const modalWidthClassname = classnames('flex justify-center', {
    'w-330px': isMobile || isMobileLandscape,
    'w-670px': isTablet,
    'w-940px': isDesktop || isDesktopHD,
    'max-w-480px': loading || success,
  });
  const signupModalContentClassname = classnames('signup-modal-content flex flex-col items-center', {
    'max-w-300px': isMobile || isMobileLandscape,
    'max-w-520px': isTablet,
    'max-w-790px': isDesktop || isDesktopHD,
  });
  const emailAlreadyInUse =
    Array.isArray(signupErrors) && signupErrors[0]?.detail === 'That email address is already registered';

  const initialValues: iSignupFormValues = {
    title: null,
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    phoneNumber: '',
    companyName: '',
    companyCountryCode: '',
    isExistingPartner: false,
    agreeToTerms: false,
  };

  const handleFormSubmit = useCallback(
    (signupFormValues: iSignupFormValues) => {
      const {
        title,
        firstName,
        lastName,
        email,
        mobileNumber,
        phoneNumber,
        isExistingPartner,
        companyName,
        companyCountryCode,
        agreeToTerms,
      } = signupFormValues;
      setSuccess(false);
      setSignupErrorMessage('');
      dispatch(
        signUp({
          title,
          firstName,
          lastName,
          email,
          mobileNumber,
          phoneNumber,
          isExistingPartner,
          agreeToTerms,
          companySignupInfo: {
            name: companyName,
            countryCode: companyCountryCode,
          },
        })
      );
      setFormSubmitted(true);
    },
    [dispatch]
  );

  useEffect(() => {
    if (authStatus === Status.ERROR && formSubmitted) {
      setSuccess(false);
      setSignupErrorMessage('There was an error during sign up. Please try again');
    }

    if (authStatus === Status.SUCCESS && formSubmitted) {
      setSuccess(true);
      onSuccess && onSuccess();
    }
  }, [authStatus, onSuccess, formSubmitted]);

  const fieldContainerClassname = classnames('grid gap-x-5', {
    'grid-cols-1': isMobile || isMobileLandscape,
    'grid-cols-2': isTablet,
    'grid-cols-3': isDesktop || isDesktopHD,
  });

  const buttonClassName = classnames(
    'font-pt-sans bg-brown-prime w-full h-10 border-none px-33px text-white-true tracking-wider',
    'hover:bg-brown-hard active:bg-brown-hard cursor-pointer max-w-220px',
    { 'mt-20px': isMobile, 'mt-25px': !isMobile }
  );

  const emailAlreadyInUseMessage = ( COGNITO_ENABLE_FLOW ? (
    <span>
      The email supplied is already in use. Would you like to &nbsp;
      <a href={`${dynamicParameters.COGNITO_DOMAIN}/login?client_id=${dynamicParameters.COGNITO_CLIENT_ID}&response_type=code&scope=email+openid&redirect_uri=${location.host === 'localhost:8080' ? REDIRECT_COGNITO_URI_IF_LOCALHOST : dynamicParameters.COGNITO_CALLBACK_FOR_SIGNINUP }`} className="text-brown-prime underline">
        Login
      </a>{' '}
      ?
    </span>
    ) : (
      <span>
      The email supplied is already in use. Would you like to &nbsp;
      <Link to={'/login'} className="text-brown-prime underline">
        Login
      </Link>{' '}
      ?
    </span> 
    )
  );

  const renderContent = (isVisible: boolean) => {
    return (
      <div className={classnames('pb-65px', { hidden: !isVisible, 'max-w-250px': isMobile || isMobileLandscape })}>
        <Formik
          initialValues={initialValues}
          validationSchema={signupFormValidationSchema}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
        >
          {(form: FormikProps<iSignupFormValues>) => {
            return (
              <form onSubmit={form.handleSubmit}>
                {emailAlreadyInUse && <Warning message={emailAlreadyInUseMessage} />}
                {signupErrorMessage && !emailAlreadyInUse && <Warning message={signupErrorMessage} />}

                <div className={fieldContainerClassname}>
                  <Field name="title">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="title"
                        label="Title"
                        className={classnames('title', { 'mt-20px': isMobile, 'mt-25px': !isMobile })}
                        value={value}
                        showSelectedOption
                        options={titleSlicedOptions}
                        labelWhenNothingSelected="Select..."
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                        errorMessage={form.touched.title && form.errors.title ? form.errors.title : null}
                        errorClassName="title-error"
                      />
                    )}
                  </Field>

                  <Field
                    as={TextInput}
                    type="text"
                    name="firstName"
                    id="firstName"
                    className={classnames('firstName', { 'mt-20px': isMobile, 'mt-25px': !isMobile })}
                    label="First Name"
                    errorMessage={form.touched.firstName && form.errors.firstName ? form.errors.firstName : null}
                    errorClassName="firstName-error"
                  />

                  <Field
                    as={TextInput}
                    type="text"
                    name="lastName"
                    id="lastName"
                    className={classnames('lastName', { 'mt-20px': isMobile, 'mt-25px': !isMobile })}
                    label="Last Name"
                    errorMessage={form.touched.lastName && form.errors.lastName ? form.errors.lastName : null}
                    errorClassName="firstName-error"
                  />

                  <Field
                    as={TextInput}
                    type="email"
                    name="email"
                    id="email"
                    className={classnames('email', { 'mt-20px': isMobile, 'mt-25px': !isMobile })}
                    label="Email"
                    errorMessage={form.touched.email && form.errors.email ? form.errors.email : null}
                    errorClassName="firstName-error"
                    warning={emailAlreadyInUse}
                  />

                  <Field
                    as={TextInput}
                    type="text"
                    name="mobileNumber"
                    id="mobileNumber"
                    className={classnames('mobileNumber', { 'mt-20px': isMobile, 'mt-25px': !isMobile })}
                    label="Mobile"
                    errorMessage={
                      form.touched.mobileNumber && form.errors.mobileNumber ? form.errors.mobileNumber : null
                    }
                    errorClassName="firstName-error"
                  />

                  <Field
                    as={TextInput}
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    className={classnames('phoneNumber', { 'mt-20px': isMobile, 'mt-25px': !isMobile })}
                    label="Landline"
                    errorMessage={form.touched.phoneNumber && form.errors.phoneNumber ? form.errors.phoneNumber : null}
                    errorClassName="firstName-error"
                  />

                  <Field
                    as={TextInput}
                    type="text"
                    name="companyName"
                    id="companyName"
                    className={classnames('companyName', { 'mt-20px': isMobile, 'mt-25px': !isMobile })}
                    label="Company Name"
                    errorMessage={form.touched.companyName && form.errors.companyName ? form.errors.companyName : null}
                    errorClassName="companyName-error"
                  />

                  <Field name="companyCountryCode">
                    {({ field: { name, value }, form: { setFieldValue } }) => (
                      <SingleSelect
                        fieldId="companyCountryCode"
                        label="Company Country"
                        maxVisibleItems={6}
                        className={classnames('companyCountryCode', { 'mt-20px': isMobile, 'mt-25px': !isMobile })}
                        value={value}
                        options={countryOptions}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                        errorMessage={
                          form.touched.companyCountryCode && form.errors.companyCountryCode
                            ? form.errors.companyCountryCode
                            : null
                        }
                        errorClassName="companyCountryCode-error"
                      />
                    )}
                  </Field>

                  <Field name="isExistingPartner">
                    {({ field: { name, value }, form: { setFieldValue } }) => {
                      const handleExistingPartnerYesClick = () => setFieldValue(name, true);
                      const handleExistingPartnerNoClick = () => setFieldValue(name, false);
                      return (
                        <FormLabel
                          className={classnames('title', { 'mt-20px': isMobile, 'mt-25px': !isMobile })}
                          text="Are you an existing partner?"
                        >
                          <div className="flex mt-5px">
                            <label
                              className="inline-flex font-pt-sans items-center cursor-pointer"
                              onClick={handleExistingPartnerYesClick}
                            >
                              <Radio onClick={handleExistingPartnerYesClick} checked={value} />
                              <span className="ml-1 mr-5">Yes</span>
                            </label>

                            <label
                              className="inline-flex font-pt-sans items-center cursor-pointer ml-20px"
                              onClick={handleExistingPartnerNoClick}
                            >
                              <Radio onClick={handleExistingPartnerNoClick} checked={!value} />
                              <span className="ml-1 mr-5">No</span>
                            </label>
                          </div>
                        </FormLabel>
                      );
                    }}
                  </Field>
                </div>

                <div
                  className={classnames('border-t border-solid border-gray-20 mt-30px', {
                    'mt-30px': isMobile,
                    'mt-35px': !isMobile,
                  })}
                ></div>

                <Field name="agreeToTerms">
                  {({ field: { name, value }, form: { setFieldValue } }) => (
                    <div
                      className={classnames('signup-modal-agreeToTerms', { 'mt-20px': isMobile, 'mt-25px': !isMobile })}
                    >
                      <div className="flex items-center">
                        <Checkbox
                          checked={value}
                          onChange={() => setFieldValue(name, !value)}
                          wrapperClassName={classnames({
                            'border-red-95': form.touched.agreeToTerms && form.errors.agreeToTerms,
                          })}
                        />
                        <label className="font-pt-sans text-black text-16px leading-21px tracking-2xs ml-2">
                          Accept the
                          <a href={dynamicParameters.BOOKING_TERMS_URL}>
                            <span className="mx-3px text-brown-prime underline hover:no-decoration">
                              Terms and Conditions
                            </span>
                          </a>
                        </label>
                      </div>
                      {form.touched.agreeToTerms && form.errors.agreeToTerms && (
                        <p className="text-red-95 text-13px leading-2xs font-pt-sans mt-5px">
                          Must accept Terms and Conditions
                        </p>
                      )}
                    </div>
                  )}
                </Field>

                <div className="flex flex-col items-center">
                  <button id="loginSubmitCta" className={buttonClassName} disabled={loading}>
                    Send Request
                  </button>

                  <p className="font-pt-sans mt-25px text-gray-80 text-13px leading-21px w-full">
                    Your request will be verified by our team. Upon a successful approval you will be able to 
                    sign in to your account.
                  </p>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  };

  if (!isOpen) {
    return null;
  }

  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      modalWindowClassName={modalWidthClassname}
      shouldCloseByClickingOutside
    >
      <div className={classnames('signup-modal flex justify-center pt-55px pb-50px', modalWidthClassname)}>
        <div className={signupModalContentClassname}>
          <div className="signup-modal-title flex flex-col items-center">
            {!loading && !success && (
              <p className="signup-modal-title text-black text-4xl leading-54px text-center font-noe-display mt-5px my-0">
                Create an Account
              </p>
            )}
            {!loading && success && (
              <p className="signup-modal-title text-black text-4xl leading-54px text-center font-noe-display mt-5px my-0">
                Thank you
              </p>
            )}
          </div>

          {loading && (
            <p className="font-pt-sans mt-5 text-center text-black text-base leading-xl">Sending Request ...</p>
          )}

          {!loading && success && (
            <p className="font-pt-sans max-w-350px mt-25px text-black text-base leading-xl text-center">
              Thank you for your interest. Your request will be verified shortly. Upon successful approval you will
              receive an email to verify your details.
            </p>
          )}

          {renderContent(!loading && !success)}
        </div>
      </div>
    </GeneralModal>
  );
};
