import * as Actions from './actions';
import { ICompanyInfo, ICompanyLogoPosition, initialState } from './model';
import { ENetworkRequestStatus } from '../../../services/BackendApi';
import { produce } from 'immer';
import { uniqBy } from 'ramda';

export const companyInfoReducer = (
  state: ICompanyInfo = initialState,
  action: Actions.CompanyInfoAction
): ICompanyInfo => {
  switch (action.type) {
    case Actions.FETCH_COMPANY_LOGO:
      return {
        ...state,
        companyLogoNetwork: {
          ...state.companyLogoNetwork,
          fetchLogo: ENetworkRequestStatus.PENDING,
        },
      };
    case Actions.FETCH_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        companyLogoInfo: action.companyLogoInfo,
        companyLogoNetwork: {
          ...state.companyLogoNetwork,
          fetchLogo: ENetworkRequestStatus.SUCCESS,
        },
      };
    case Actions.FETCH_COMPANY_LOGO_FAILURE:
      return {
        ...state,
        companyLogoNetwork: {
          ...state.companyLogoNetwork,
          fetchLogo: ENetworkRequestStatus.ERROR,
        },
        error: action.error,
      };
    case Actions.UPDATE_COMPANY_LOGO_POSITION_SUCCESS:
      return {
        ...state,
        companyLogoPosition: action.position,
      };
    case Actions.UPDATE_COMPANY_LOGO:
      return {
        ...state,
        isProcessingRequest: true,
      };
    case Actions.REMOVE_COMPANY_LOGO:
      return {
        ...state,
        isProcessingRequest: true,
      };
    case Actions.REMOVE_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        isProcessingRequest: false,
        companyLogoInfo: null,
      };
    case Actions.FETCH_COMPANY_DATA_REQUEST:
      return {
        ...state,
        company: null,
        companyLoad: ENetworkRequestStatus.PENDING,
      };
    case Actions.FETCH_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        company: action.companyData,
        companyLogoPosition: action.companyData.logoPosition as ICompanyLogoPosition,
        companyLoad: ENetworkRequestStatus.SUCCESS,
        companyExternalMarkupPercentage: action.companyData.externalMarkupPercentage,
      };
    case Actions.FETCH_COMPANY_DATA_FAILURE:
      return {
        ...state,
        companyLoad: ENetworkRequestStatus.ERROR,
        error: action.error,
      };
    case Actions.FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_REQUEST:
      return {
        ...state,
        depositAccountBalanceLoad: ENetworkRequestStatus.PENDING,
      };
    case Actions.FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_SUCCESS:
      return {
        ...state,
        company: state.company
          ? { ...state.company, eurCreditLimit: action.eurCreditLimit, usdCreditLimit: action.usdCreditLimit }
          : null,
        depositAccountBalanceLoad: ENetworkRequestStatus.SUCCESS,
      };
    case Actions.FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_FAILURE:
      return {
        ...state,
        depositAccountBalanceLoad: ENetworkRequestStatus.ERROR,
      };
    case Actions.SET_COMPANY_LOGO_NETWORK_POST_LOGO:
      return {
        ...state,
        companyLogoNetwork: {
          ...state.companyLogoNetwork,
          postLogo: action.newNetworkStatus,
        },
      };
    case Actions.SET_COMPANY_LOGO_NETWORK_DELETE_LOGO:
      return {
        ...state,
        companyLogoNetwork: {
          ...state.companyLogoNetwork,
          deleteLogo: action.newNetworkStatus,
        },
      };
    case Actions.SET_ACTIVE_HOTEL:
      return produce(state, draftState => {
        if (action.isActive) {
          draftState.hotelListData.activeHotels.push({
            ...action.hotel,
          });
        } else {
          const idx = draftState.hotelListData.activeHotels.findIndex(ah => ah.uuid === action.hotel.uuid);
          if (idx > -1) {
            draftState.hotelListData.activeHotels.splice(idx, 1);
          }
        }

        draftState.hotelListData.activeHotels = uniqBy(h => h.uuid, draftState.hotelListData.activeHotels);
        draftState.hotelListData.hasEditedWithoutSaving = true;

        return draftState;
      });

    case Actions.SET_ACTIVE_DESTINATION:
      return produce(state, draftState => {
        if (action.isActive) {
          // find all hotels for this code, and add them all
          const hotelsForCode = action.allPossibleHotels.filter(h => h.countryCode === action.countryCode);
          draftState.hotelListData.activeHotels = draftState.hotelListData.activeHotels.concat(hotelsForCode);
        } else {
          // remove all the hotels for this code
          draftState.hotelListData.activeHotels = draftState.hotelListData.activeHotels.filter(
            h => h.countryCode !== action.countryCode
          );
        }

        draftState.hotelListData.activeHotels = uniqBy(h => h.uuid, draftState.hotelListData.activeHotels);
        draftState.hotelListData.hasEditedWithoutSaving = true;
        return draftState;
      });

    case Actions.GET_HOTEL_LIST_DATA_REQUEST:
      return produce(state, draftState => {
        draftState.hotelListDataNetwork.getHotelListData = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_HOTEL_LIST_DATA_SUCCESS:
      return produce(state, draftState => {
        draftState.hotelListData.activeHotels = [...action.allHotels].filter(
          hotel => !action.disabledHotelUuids.includes(hotel.uuid)
        );
        draftState.hotelListDataNetwork.getHotelListData = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_HOTEL_LIST_DATA_FAILURE:
      return produce(state, draftState => {
        draftState.hotelListDataNetwork.getHotelListData = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.POST_HOTEL_LIST_DATA_REQUEST:
      return produce(state, draftState => {
        draftState.hotelListDataNetwork.postHotelListData = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.POST_HOTEL_LIST_DATA_SUCCESS:
      return produce(state, draftState => {
        draftState.hotelListDataNetwork.postHotelListData = ENetworkRequestStatus.SUCCESS;
        draftState.hotelListData.hasEditedWithoutSaving = false;
        return draftState;
      });

    case Actions.POST_HOTEL_LIST_DATA_FAILURE:
      return produce(state, draftState => {
        draftState.hotelListDataNetwork.postHotelListData = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.CREATE_COMPANY_REQUEST:
      return produce(state, draftState => {
        draftState.companyNetwork.create = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.CREATE_COMPANY_SUCCESS:
      return produce(state, draftState => {
        draftState.companyNetwork.create = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.CREATE_COMPANY_FAILURE:
      return produce(state, draftState => {
        draftState.companyNetwork.create = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.UPDATE_COMPANY_REQUEST:
      return produce(state, draftState => {
        draftState.companyNetwork.update = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.UPDATE_COMPANY_SUCCESS:
      return produce(state, draftState => {
        draftState.companyNetwork.update = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.UPDATE_COMPANY_FAILURE:
      return produce(state, draftState => {
        draftState.companyNetwork.update = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.INIT_COMPANY_SAVE_STATUS:
      return produce(state, draftState => {
        draftState.companyNetwork.create = ENetworkRequestStatus.IDLE;
        draftState.companyNetwork.update = ENetworkRequestStatus.IDLE;
        return draftState;
      });

    case Actions.SET_TRAVEL_COMPANY_EDIT_MODE:
      return produce(state, draftState => {
        draftState.isEditMode = action.isEditMode;
        return draftState;
      });
    case Actions.UPDATE_COMPANY_EXTERNAL_MARKUP_PERCENTAGE:
      return produce(state, draftState => {
        draftState.companyExternalMarkupPercentage = action.val;
        return draftState;
      });

    case Actions.CLEAR_COMPANY_LOGO_INFO:
      return {
        ...state,
        companyLogoInfo: null,
      };

    default:
      return state;
  }
};

export default companyInfoReducer;
