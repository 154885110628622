import { RefObject, useEffect } from 'react';


export default function useOnClickOutside(ref, handler, exceptions: RefObject<any>[] = []) {
  useEffect(
    () => {
      if (handler) {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          // Check if the clicked element is within any of the exception elements

          if (exceptions.some(exception => exception?.current?.contains(event.target))) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mouseup", listener);
        document.addEventListener("touchend", listener);
        return () => {
          document.removeEventListener("mouseup", listener);
          document.removeEventListener("touchend", listener);
        };
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because the passed-in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler, exceptions]
  );
}
