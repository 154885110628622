import React from "react";
import { ETaskPriority } from "services/TaskManagementApi/types/TaskManagementResponse";

export interface ITaskListPriorityProps {
  priority: ETaskPriority;
}

export const TaskListPriority = ({ priority }: ITaskListPriorityProps) => {
  const primaryColor = priority === ETaskPriority.LOW ? 'bg-status-confirmed' : (priority === ETaskPriority.MEDIUM ? 'bg-inv-yellow' : 'bg-status-cancelled')
  const secondaryColor = 'bg-gray-20';
  return (
    <div className="h-2 justify-center items-center gap-[3px] inline-flex">
      <div className={`w-2 h-2 ${primaryColor} rounded-full`} />
      <div className={`w-2 h-2 ${priority !== ETaskPriority.LOW ? primaryColor : secondaryColor} rounded-full`} />
      <div className={`w-2 h-2 ${priority === ETaskPriority.HIGH ? primaryColor : secondaryColor} rounded-full`} />
    </div>
  )
}