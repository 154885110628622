import * as Actions from './actions';
import { IActingOnBehalfOfDomain, initialState } from './model';
import { produce } from 'immer';

export const actingOnBehalfOfReducer = (
  state: IActingOnBehalfOfDomain = initialState,
  action: Actions.TActingOnBehalfOfActions
): IActingOnBehalfOfDomain => {
  switch (action.type) {
    case Actions.SET_ACTING_ON_BEHALF_OF_UUID:
      return produce(state, draftState => {
        draftState.aoboUuid = action.uuid;
        return draftState;
      });

    case Actions.SET_ACTING_ON_BEHALF_OF_ROLE:
      return produce(state, draftState => {
        draftState.aoboRole = action.role;
        return draftState;
      });

    case Actions.SET_ACTING_ON_BEHALF_OF_USER:
      return produce(state, draftState => {
        if (action.user === null) {
          draftState.aoboUuid = null;
          draftState.aoboRole = null;
          return draftState;
        }
        draftState.aoboUuid = action.user.uuid;
        draftState.aoboRole = action.user.role;
        return draftState;
      });

    case Actions.SET_ACTING_ON_BEHALF_OF_DATA:
      return produce(state, draftState => {
        if (action.user === null) {
          draftState.aoboUuid = null;
          draftState.aoboRole = null;
          draftState.aoboAssociatedTaUuid = null;
          return draftState;
        }
        draftState.aoboUuid = action.user.uuid;
        draftState.aoboRole = action.user.role;
        draftState.aoboAssociatedTaUuid = action.user.taUuid;
        return draftState;
      });

    default:
      return state;
  }
};

export default actingOnBehalfOfReducer;
