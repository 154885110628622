import { createSelector } from 'reselect';

import { UPLOAD_FILE_SIZE_LIMIT } from 'config';
import { ENetworkRequestStatus, Upload } from 'services/BackendApi';

import { CompanyDocumentsSortField, CompanyDocumentsSortOrder, ICompanyDocumentsDomain, IDeleteDocument } from './model';

export const domainSelector = (state: any) => state.companyDocuments;

export const companyDocumentsSelector = createSelector(
  domainSelector,
  (domain: ICompanyDocumentsDomain): Upload[] => domain.documents
);

export const companyDocumentsLoadSelector = createSelector(
  domainSelector,
  (domain: ICompanyDocumentsDomain): ENetworkRequestStatus => domain.documentsLoad
);

export const uploadDocumentModalOpenSelector = createSelector(
  domainSelector,
  (domain: ICompanyDocumentsDomain): boolean => domain.uploadDocumentModalOpen
);

export const newDocumentSelector = createSelector(
  domainSelector,
  (domain: ICompanyDocumentsDomain) => domain.newDocument
);

export const newDocumentPayloadSelector = createSelector(newDocumentSelector, newUpload => newUpload.payload);

export const newDocumentLoadSelector = createSelector(
  domainSelector,
  (domain: ICompanyDocumentsDomain): ENetworkRequestStatus => domain.newDocument.request
);

export const newDocumentErrorSelector = createSelector(
  domainSelector,
  (domain: ICompanyDocumentsDomain): Error | null => domain.newDocument.error
);

export const newDocumentFileValidationSelector = createSelector(newDocumentPayloadSelector, payload => {
  if (!payload) {
    return [];
  }

  if (!payload.file) {
    return ['Required'];
  }

  if (payload.file.size > UPLOAD_FILE_SIZE_LIMIT) {
    return ['File size exceeds 4MB limit'];
  }

  return [];
});

export const newDocumentDisplayNameValidationSelector = createSelector(newDocumentPayloadSelector, payload =>
  !payload || payload.displayName ? [] : ['Required']
);

export const newDocumentTagValidationSelector = createSelector(newDocumentPayloadSelector, payload =>
  !payload || payload.tag ? [] : ['Required']
);

export const newDocumentValidationSelector = createSelector(
  newDocumentFileValidationSelector,
  newDocumentDisplayNameValidationSelector,
  newDocumentTagValidationSelector,
  (file, displayName, tag) => ({ file, displayName, tag })
);

export const deleteCompanyDocumentSelector = createSelector(
  domainSelector,
  (domain: ICompanyDocumentsDomain): IDeleteDocument => domain.deleteDocument
);

export const companyDocumentsSortFieldSelector = createSelector(
  domainSelector,
  (domain: ICompanyDocumentsDomain): CompanyDocumentsSortField | undefined => domain.documentsSortField
);

export const companyDocumentsSortOrderSelector = createSelector(
  domainSelector,
  (domain: ICompanyDocumentsDomain): CompanyDocumentsSortOrder | undefined => domain.documentsSortOrder
);
