import { AxiosResponse } from 'axios';
import { makeParametersProviderApi } from './ParametersProviderApi';
import { SECONDS } from 'utils';
import { PARAMETERS_REFRESH_INTERVAL_SECONDS } from 'config';
import { IDynamicParametersResponse } from './types';
import * as Sentry from '@sentry/browser';

export class ParameterService {
  // this should be "private static data", but TypeScript does not allow that
  // because TypeScript authors decided that all static members should always be public
  static data: IDynamicParametersResponse | null = null;
  static intervalId: any = null;
  
  static getParameters(): IDynamicParametersResponse {
    if (this.data === null) {
      return {};
    }
    return this.data;
  }

  static async fetchParameters() {
    const parametersProviderApi = makeParametersProviderApi();
    const dynamicParametersResponse: AxiosResponse = await parametersProviderApi.getDynamicParameters();
    if (dynamicParametersResponse?.data && Object.keys(dynamicParametersResponse.data).length > 0) {
      this.data = dynamicParametersResponse.data;
    }
    Sentry.setTag('tenant', this.data?.TENANT || 'not set');
    return dynamicParametersResponse.data;
  }

  static startPeriodicRefresh() {
    this.intervalId = setInterval(
      () => this.fetchParameters(), PARAMETERS_REFRESH_INTERVAL_SECONDS * SECONDS,
    )
  }

  static stopPeriodicRefresh() {
    clearInterval(this.intervalId);
    this.intervalId = null;
  }
}