import { createSelector } from 'reselect';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { IProposalEditorDomain } from './model';

export const proposalEditorDomain = (state: any): IProposalEditorDomain => state.proposalEditor;

export const proposal = createSelector(
  proposalEditorDomain,
  domain => domain.proposal
);

export const googleDocDraftId = createSelector(
  proposalEditorDomain,
  domain => domain.googleDocDraftId
);

export const request = createSelector(
  proposalEditorDomain,
  domain => domain.request
);

export const initLoading = createSelector(
  proposal, googleDocDraftId, request,
  (proposal, googleDocDraftId, request) => 
    (!proposal || !googleDocDraftId) && 
    (request.get !== ENetworkRequestStatus.ERROR || request.draft !== ENetworkRequestStatus.ERROR)
);

export const initError = createSelector(
  proposal, googleDocDraftId, request,
  (proposal, googleDocDraftId, request) => 
    (!proposal || !googleDocDraftId) && 
    (request.get === ENetworkRequestStatus.ERROR || request.draft === ENetworkRequestStatus.ERROR)
);
