import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'ui/Link';

import UsdIcon from 'ui/Icons/currency.usd.component.svg';
import EurIcon from 'ui/Icons/currency.eur.component.svg';
import PiggyBankIcon from 'ui/Icons/piggyBank.component.svg';
import { Pagination } from 'pureUi/Pagination';
import { Multiselect } from 'ui/Multiselect';
import FluidButton from 'ui/FluidButton';
import * as GlobalStatementActions from 'store/modules/globalStatement/actions';
import * as GlobalStatementSelectors from 'store/modules/globalStatement/selectors';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { formatPriceCents, getCurrencySymbol } from 'utils';
import { PriceAsCentsInput } from 'ui/stateful/PriceAsCentsInput';
import { getBootstrapCountriesSelector } from 'store/modules/bootstrap/selectors';
import * as AgentsSelectors from 'store/modules/agents/selectors';
import * as AgentsActions from 'store/modules/agents/actions';
import { TCountryCode } from 'interfaces';
import { Poller } from '../Poller';
import { SvgIcon } from 'ui/SvgIcon';
import RightIcon from 'ui/Icons/right.component.svg';
import InfoIcon from 'ui/Icons/Info.component.svg';
import classNames from 'classnames';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { theme } from '../../../tailwind.config';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';

export const GlobalStatement = () => {
  const dispatch = useDispatch();
  const tableData = useSelector(GlobalStatementSelectors.tableDataSelector);
  const totalsData = useSelector(GlobalStatementSelectors.totalsSelector);
  const filtersData = useSelector(GlobalStatementSelectors.filtersDataSelector);
  const sortOrder = useSelector(GlobalStatementSelectors.sortOrderSelector);
  const network = useSelector(GlobalStatementSelectors.networkSelector);
  const countries = useSelector(getBootstrapCountriesSelector);
  const companies = useSelector(AgentsSelectors.companiesSelector);
  const pageCount = useSelector(GlobalStatementSelectors.pageCountSelector);
  const pageNumber = useSelector(GlobalStatementSelectors.pageNumberSelector);
  const itemsPerPage = useSelector(GlobalStatementSelectors.itemsPerPageSelector);
  const exportCsvNamespace = useSelector(GlobalStatementSelectors.exportCsvNamespaceSelector);

  const isAmountError =
    filtersData.amountMaxCents !== undefined &&
    filtersData.amountMinCents !== undefined &&
    filtersData.amountMinCents > filtersData.amountMaxCents;

  useEffect(() => {
    dispatch(GlobalStatementActions.resetFiltersAction());
    dispatch(GlobalStatementActions.tableDataRequestAction());
    dispatch(GlobalStatementActions.totalsRequestAction());
    dispatch(AgentsActions.getCompaniesRequestAction());

    return () => {
      dispatch(GlobalStatementActions.pollExportCsvCancelAction());
    };
  }, []);

  return (
    <React.Fragment>
      <Poller
        exportUuid={exportCsvNamespace.exportUuid}
        isWaitingForExport={exportCsvNamespace.isWaitingForExport}
        pollRequestFunction={() => dispatch(GlobalStatementActions.pollExportCsvRequestAction())}
        pollExportLoad={exportCsvNamespace.pollExportCsvLoad}
      />

      <div className="ledger-summary container mx-auto max-w-1280px font-pt-sans text-black">
        {/* page title */}
        <div className="ledger-summary-header flex justify-between items-center">
          <div className="ledger-summary-title flex items-baseline">
            <h1 className="global-statement-main-title font-noe-display font-normal text-4xl m-0">Statement Summary</h1>
            <h2 className="global-statement-sub-title ledger-summary-selected-company font-noe-display font-normal text-black text-26px m-0 ml-2">
              / All Companies
            </h2>
          </div>
        </div>

        {/* totals widget, a row for each currency */}
        <div
          style={{
            minHeight: '138px',
          }}
          className={classnames('w-full py-10px shadow-pe4 text-black items-center mb-35px')}
        >
          {network.totalsLoad === ENetworkRequestStatus.PENDING && (
            <span className="block mt-12 opacity-60 italic text-center">
              <i className="loading-placeholder text-xl fas fa-circle-notch fa-spin"></i> Loading...
            </span>
          )}
          {network.totalsLoad === ENetworkRequestStatus.SUCCESS &&
            totalsData.map((total, index) => {
              return (
                <div
                  key={total.currencyCode}
                  className={classnames('currency-total-row flex flex-row w-full justify-between', {
                    'mt-5': index !== 0,
                  })}
                >
                  <div className="flex flex-row ml-25px">
                    <div
                      style={{
                        minWidth: '163px',
                      }}
                      className="flex flex-col"
                    >
                      <span className="font-pt-sans text-xs uppercase text-gray-100 text-center">Invoices</span>
                      <span className="global-statement-invoices-total font-pt-sans font-bold text-21px text-center">
                        {getCurrencySymbol(total.currencyCode)}
                        {formatPriceCents(total.invoicesCents)}
                      </span>
                    </div>

                    <span className="block border border-solid border-t-0 border-r-0 border-b-0 border-gray-20 mx-10"></span>

                    <div
                      style={{
                        minWidth: '158px',
                      }}
                      className="flex flex-col"
                    >
                      <span className="font-pt-sans text-xs uppercase text-gray-100 text-center">
                        Payments & Adjustments
                      </span>
                      <span className="global-statement-payments-total font-pt-sans font-bold text-21px text-center">
                        {getCurrencySymbol(total.currencyCode)}
                        {formatPriceCents(total.paymentsCents)}
                      </span>
                    </div>

                    <span className="block border border-solid border-t-0 border-r-0 border-b-0 border-gray-20 mx-10"></span>

                    <div
                      style={{
                        minWidth: '138px',
                      }}
                      className="flex flex-col"
                    >
                      <span className="font-pt-sans text-xs uppercase text-gray-100 text-center">Net Balance</span>
                      <span className="global-statement-balance-total font-pt-sans font-bold text-21px text-teal-100 text-center">
                        {getCurrencySymbol(total.currencyCode)}
                        {formatPriceCents(total.netBalanceCents)}
                      </span>
                    </div>
                  </div>

                  <div
                    style={{
                      minWidth: '200px',
                    }}
                    className="flex flex-row mr-25px"
                  >
                    <div className="flex flex-col mr-10">
                      <span className="font-pt-sans text-xs uppercase text-gray-100 text-center mb-1">Currency</span>
                      {total.currencyCode === 'USD' && (
                        <SvgIcon
                          IconComponent={UsdIcon}
                          className="w-30px h-30px fill-none global-statement-usd-icon m-auto"
                        />
                      )}
                      {total.currencyCode === 'EUR' && (
                        <SvgIcon
                          IconComponent={EurIcon}
                          className="w-30px h-30px fill-none global-statement-eur-icon m-auto"
                        />
                      )}
                    </div>
                    <div
                      style={{
                        minWidth: '150px',
                      }}
                      className="flex flex-col"
                    >
                      <span className="font-pt-sans text-xs uppercase text-gray-100 text-right">
                        Balance Owed to TP
                      </span>
                      <span className="flex flex-row items-center justify-end mt-5px">
                        <PiggyBankIcon fill={'black'} />
                        <span
                          style={{
                            minWidth: '150px',
                          }}
                          className="global-statement-balanceOwed-total ml-10px font-pt-sans font-bold text-21px text-right"
                        >
                          {getCurrencySymbol(total.currencyCode)}
                          {formatPriceCents(total.balanceOwedCents)}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        {network.tableDataLoad === ENetworkRequestStatus.PENDING && !tableData?.responseRows?.length && (
          <div className="mt-5">
            <LoadingBar />
          </div>
        )}

        {/* filter widget */}
        <div>
          <h2 className="text-black text-17px font-bold font-pt-sans">Filters and Search Options</h2>

          <div className="flex flex-row items-center">
            <label className="block">
              <span className="block leading-2xs font-pt-sans text-13px text-black">Country of TP</span>
              <Multiselect
                className="global-statement-tp-country-select w-300px mt-5px bg-ivory"
                onUpdate={sv => {
                  if (sv[0]) {
                    dispatch(GlobalStatementActions.setTpCountryAction(sv[0] as TCountryCode));
                  } else {
                    dispatch(GlobalStatementActions.setTpCountryAction(undefined));
                  }
                }}
                hideCheckboxes={true}
                isSingleSelectMode={true}
                isCloseOnSelect={true}
                isIncludeClearButton={true}
                isEnableFuzzySearch={true}
                itemsClassname="bg-ivory"
                itemCtaClassName="hover:bg-gray-20"
                options={countries.map(country => ({
                  value: country.code,
                  label: country.name,
                }))}
                selectedValues={filtersData.tpCountry ? [filtersData.tpCountry] : []}
              />
            </label>

            <label className="block ml-5">
              <span className="block leading-2xs font-pt-sans text-13px text-black">Travel Company</span>
              <Multiselect
                className="global-statement-travel-country-select w-300px mt-5px bg-ivory"
                onUpdate={sv => {
                  if (sv[0]) {
                    dispatch(GlobalStatementActions.setTravelCompanyAction(sv[0]));
                  } else {
                    dispatch(GlobalStatementActions.setTravelCompanyAction(undefined));
                  }
                }}
                hideCheckboxes={true}
                isSingleSelectMode={true}
                isCloseOnSelect={true}
                isIncludeClearButton={true}
                isEnableFuzzySearch={true}
                itemsClassname="bg-ivory"
                itemCtaClassName="hover:bg-gray-20"
                options={
                  companies === null
                    ? []
                    : companies.map(company => {
                        return {
                          value: company.uuid,
                          label: company.name,
                        };
                      })
                }
                selectedValues={filtersData.travelCompanyUuid ? [filtersData.travelCompanyUuid] : []}
              />
            </label>

            <label className="block ml-5">
              <span className="block leading-2xs font-pt-sans text-13px text-black">Amount Min.</span>
              <PriceAsCentsInput
                className="global-statement-amount-min mt-5px h-37px w-100px border border-solid border-gray-40 bg-ivory text-15px px-10px py-2 focus:outline-gray-80"
                isAllowUndefined={true}
                disabled={false}
                onBlurUpdate={val => {
                  if (val !== filtersData.amountMinCents) {
                    dispatch(GlobalStatementActions.setAmountMinAction(val));
                  }
                }}
                cents={filtersData.amountMinCents}
              />
            </label>

            <span className="mx-5px mt-4">-</span>

            <label className="block">
              <div className="flex justify-between leading-2xs items-center">
                <span className="font-pt-sans text-13px text-black">Amount Max.</span>
                <HidingTextTooltip
                  tooltipContent="These amounts filter the Net Balances."
                  position="right"
                  width="106px"
                  tooltipClassname="mt-25px"
                >
                  <span className="cursor-pointer h-4">
                    <SvgIcon
                      className="w-18px"
                      IconComponent={InfoIcon}
                      defaultFill={theme.colors['gray-80']}
                      hoverFill={theme.colors['gray-100']}
                      activeFill={theme.colors['gray-100']}
                    />
                  </span>
                </HidingTextTooltip>
              </div>
              <PriceAsCentsInput
                className={classnames(
                  'global-statement-amount-max mt-5px h-37px w-100px border border-solid text-15px px-10px py-2 focus:outline-gray-80',
                  {
                    'border-gray-40 bg-ivory': !isAmountError,
                    'border-red-95 bg-red-25': isAmountError,
                  }
                )}
                isAllowUndefined={true}
                disabled={false}
                onBlurUpdate={val => {
                  if (val !== filtersData.amountMaxCents) {
                    dispatch(GlobalStatementActions.setAmountMaxAction(val));
                  }
                }}
                cents={filtersData.amountMaxCents}
              />
            </label>

            {(isAmountError && (
              <span className="text-13px text-red-95 pt-5 pl-2">Max. must be greater than or equal to Min.</span>
            )) ||
              null}
          </div>

          <div className="mt-15px flex flex-row justify-between items-center">
            <div className="flex flex-row">
              <FluidButton
                className="global-statement-reset-filters-button block"
                onClick={() => {
                  dispatch(GlobalStatementActions.resetFiltersAction());
                }}
                type="secondary"
              >
                Reset Filters
              </FluidButton>

              <FluidButton className="global-statement-search-button block ml-10px" onClick={() => {}} type="primary">
                Search
              </FluidButton>
            </div>

            <FluidButton
              isLoading={
                exportCsvNamespace.triggerExportCsvLoad === ENetworkRequestStatus.PENDING ||
                exportCsvNamespace.triggerExportCsvLoad === ENetworkRequestStatus.SUCCESS ||
                exportCsvNamespace.pollExportCsvLoad === ENetworkRequestStatus.PENDING
              }
              onClick={() => {
                dispatch(GlobalStatementActions.triggerExportCsvRequestAction());
              }}
              className="global-statement-export-csv-button block ml-10px"
              type="secondary"
            >
              Export CSV
            </FluidButton>
          </div>
        </div>

        {/* table itself */}
        <div
          className={classnames('mt-5', {
            'opacity-50 pointer-events-none': network.tableDataLoad === ENetworkRequestStatus.PENDING,
          })}
        >
          <table className="table border-collapse w-full">
            <thead>
              <tr>
                <td> {/* blank */} </td>
                <td> {/* blank */} </td>
                <td
                  colSpan={3}
                  className="font-pt-sans py-3 border border-solid border-gray-20 font-bold text-black text-xs uppercase text-center bg-ivory"
                >
                  bookings statement
                </td>
                <td className="font-pt-sans border py-3 border-solid border-gray-20 font-bold text-black text-xs uppercase text-center bg-ivory">
                  deposits account
                </td>
                <td className="border border-solid border-gray-20 bg-ivory"> {/* blank */} </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{ width: '240px' }}
                  className="table-header company-name font-pt-sans py-3 px-1 border border-solid border-gray-20 text-gray-100 text-xs uppercase bg-ivory"
                >
                  <div
                    onClick={() => {
                      dispatch(GlobalStatementActions.setSortOrderAction(sortOrder === 'asc' ? 'desc' : 'asc'));
                    }}
                    className="table-header-button pl-3 rounded flex flex-row items-center justify-between hover:bg-gray-10 cursor-pointer"
                  >
                    <span>company name</span>
                    <span className="text-black fa-stack fa-lg">
                      <i
                        className={classnames('text-gray-90 fas fa-sort-up fa-stack-1x', {
                          'opacity-60': sortOrder === 'desc',
                        })}
                      ></i>
                      <i
                        className={classnames('text-gray-90 fas fa-sort-down fa-stack-1x', {
                          'opacity-60': sortOrder === 'asc',
                        })}
                      ></i>
                    </span>
                  </div>
                </td>
                <td className="table-header currency font-pt-sans py-3 border border-solid border-gray-20 text-gray-100 text-xs uppercase text-center bg-ivory">
                  currency
                </td>
                <td
                  style={{ width: '210px' }}
                  className="table-header invoices font-pt-sans py-3 border border-solid border-gray-20 text-gray-100 text-xs uppercase text-center bg-ivory"
                >
                  invoices
                </td>
                <td
                  style={{ width: '210px' }}
                  className="table-header payments font-pt-sans py-3 border border-solid border-gray-20 text-gray-100 text-xs uppercase text-center bg-ivory"
                >
                  payments & adjustments
                </td>
                <td
                  style={{ width: '210px' }}
                  className="table-header balance font-pt-sans py-3 border border-solid border-gray-20 text-gray-100 text-xs uppercase text-center bg-ivory"
                >
                  net balance
                </td>
                <td
                  style={{ width: '210px' }}
                  className="table-header balance-owed font-pt-sans py-3 border border-solid border-gray-20 text-gray-100 text-xs uppercase text-center bg-ivory"
                >
                  <div className="relative">
                    <span>balance owed to tp</span>
                    <HidingTextTooltip
                      tooltipContent="If this is a positive amount it means the TP owes money."
                      position="left"
                      tooltipClassname="mt-25px"
                      tooltipContentClassname="normal-case"
                      tooltipWrapperClassname="inline-block float-right mr-1"
                      width="116px"
                    >
                      <span className="cursor-pointer h-4">
                        <SvgIcon
                          className="w-18px"
                          IconComponent={InfoIcon}
                          defaultFill={theme.colors['gray-80']}
                          hoverFill={theme.colors['gray-100']}
                          activeFill={theme.colors['gray-100']}
                        />
                      </span>
                    </HidingTextTooltip>
                  </div>
                </td>
                <td
                  style={{
                    width: '58px',
                  }}
                  className="border border-solid border-gray-20 bg-ivory"
                >
                  {/* blank */}
                </td>
              </tr>
              {tableData.responseRows.length <= 0 && (
                <tr>
                  <td
                    className="border border-solid border-gray-20 align-top py-15px pl-5 font-pt-sans text-17px"
                    colSpan={7}
                  >
                    {network.tableDataLoad === ENetworkRequestStatus.PENDING && (
                      // no rows and we're pending - show we're loading
                      <span className="italic">
                        <i className="loading-placeholder text-xl fas fa-circle-notch fa-spin"></i> Loading...
                      </span>
                    )}
                    {network.tableDataLoad !== ENetworkRequestStatus.PENDING && (
                      // no rows and we're NOT pending - we actually have no data
                      <span className="no-data-placeholder italic">No results</span>
                    )}
                  </td>
                </tr>
              )}
              {tableData.responseRows.map((companyRow, companyIndex) => {
                return companyRow.totals.map((row, index) => {
                  return (
                    <tr
                      key={`${companyRow.companyUuid}-${row.currencyCode}`}
                      className={classNames(
                        'result-row',
                        `company-row-${companyIndex}`,
                        `totals-row-${index}`,
                        `company-${companyRow.companyUuid}`,
                        `currency-${row.currencyCode}`
                      )}
                    >
                      {index === 0 && ( // only render company name on first row, as it has a row span
                        <td
                          className="border border-solid border-gray-20 align-top py-15px pl-5 font-pt-sans text-17px"
                          rowSpan={companyRow.totals.length}
                        >
                          {companyRow.companyName}
                        </td>
                      )}
                      <td className="border border-solid border-gray-20 text-center py-14px">
                        {row.currencyCode === 'USD' && (
                          <SvgIcon IconComponent={UsdIcon} className="w-30px fill-none m-auto" />
                        )}
                        {row.currencyCode === 'EUR' && (
                          <SvgIcon IconComponent={EurIcon} className="w-30px fill-none m-auto" />
                        )}
                      </td>
                      <td className="border border-solid border-gray-20 text-right font-pt-sans text-17px pr-25px">
                        {getCurrencySymbol(row.currencyCode)}
                        {formatPriceCents(row.invoicesCents)}
                      </td>
                      <td className="border border-solid border-gray-20 text-right font-pt-sans text-17px pr-25px">
                        {getCurrencySymbol(row.currencyCode)}
                        {formatPriceCents(row.paymentsCents)}
                      </td>
                      <td className="border border-solid border-gray-20 text-right font-pt-sans text-17px pr-25px">
                        {getCurrencySymbol(row.currencyCode)}
                        {formatPriceCents(row.netBalanceCents)}
                      </td>
                      <td className="border border-solid border-gray-20 bg-teal-20 text-right font-pt-sans text-17px pr-25px">
                        {getCurrencySymbol(row.currencyCode)}
                        {formatPriceCents(row.balanceOwedCents)}
                      </td>
                      {index === 0 && (
                        <td
                          style={{
                            width: '58px',
                          }}
                          className="actions relative border border-solid border-gray-20 text-center"
                          rowSpan={companyRow.totals.length}
                        >
                          <HidingTextTooltip
                            tooltipContent="Go to Details"
                            position="bottom"
                            tooltipContentClassname="px-[7px] py-[5px]"
                          >
                            <div className="m-auto">
                              <Link className="link details" to={`/statement/${companyRow.companyUuid}`}>
                                <SvgIcon
                                  className="icon"
                                  IconComponent={RightIcon}
                                  defaultFill="#ffffff"
                                  hoverFill="#e3dad1"
                                  activeFill="#fbfbfa"
                                />
                              </Link>
                            </div>
                          </HidingTextTooltip>
                        </td>
                      )}
                    </tr>
                  );
                });
              })}
            </tbody>
          </table>
        </div>

        {/* pagination */}
        {!!tableData?.responseRows?.length && (
          <Pagination
            className="pagination mt-35px"
            onPageSelect={pageNum => {
              dispatch(GlobalStatementActions.setPageNumberAction(pageNum - 1));
            }}
            pageCount={pageCount}
            currentPage={pageNumber + 1}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={itemsPerPage => {
              dispatch(GlobalStatementActions.setItemsPerPageAction(itemsPerPage));
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};
