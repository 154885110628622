import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { BookingConfirmationPage } from './BookingConfirmationPage';

export interface IBookingConfirmationProps extends RouteComponentProps {}

export const BookingConfirmationComponent = (props: IBookingConfirmationProps) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/:hotelUuid`} component={BookingConfirmationPage} />
    </Switch>
  );
};

export const BookingConfirmationRouting = compose(withRouter)(BookingConfirmationComponent);
