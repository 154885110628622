import { TCountryCode } from 'interfaces';
import { isEqual, sortBy, reverse } from 'lodash-es';
import { createSelector } from 'reselect';
import { ITravelAgent } from 'services/BackendApi';
import { getCountryName } from 'utils/country';
import { itemsPerPageSelector } from '../proposalsList/selectors';
import { ICompanyManagersDomain, SortableFields, SortOrder } from './model';

export const domainSelector = (state: any): ICompanyManagersDomain => state.companyManagers;

export const travelAgentsSelector = createSelector(domainSelector, domain => domain.travelAgents);

export const savedBookingManagersSelector = createSelector(domainSelector, domain => domain.savedBookingManagers);

export const savedTaManagersSelector = createSelector(domainSelector, domain => domain.savedTaManagers);

export const sortBySelector = createSelector(domainSelector, domain => domain.sortBy);

export const sortOrderSelector = createSelector(domainSelector, domain => domain.sortOrder);

export const sortedListSelector = createSelector(
  travelAgentsSelector,
  sortBySelector,
  sortOrderSelector,
  (list, sortField, sortOrder) => {
    let sorted: ITravelAgent[] = [];
    switch (sortField) {
      case SortableFields.NAME:
        sorted = sortBy(list, ['firstName', 'lastName']);
        break;
      case SortableFields.COUNTRY:
        sorted = sortBy(
          list?.map(item => ({ ...item, countryName: getCountryName(item.countryCode as TCountryCode) })),
          'countryName'
        );
        break;
      case SortableFields.EMAIL:
        sorted = sortBy(list, 'name');
        break;
    }

    return sortOrder === SortOrder.ASC ? sorted : reverse(sorted);
  }
);

export const selectedBookingManagersSelector = createSelector(
  travelAgentsSelector,
  list => new Set(list?.filter(x => x.companyBookingManager).map(x => x.uuid))
);

export const selectedTaManagersSelector = createSelector(
  travelAgentsSelector,
  list => new Set(list?.filter(x => x.companyTaManager).map(x => x.uuid))
);

export const dirtyBookingManagersSelector = createSelector(
  selectedBookingManagersSelector,
  savedBookingManagersSelector,
  (selected, saved) => !isEqual(selected, saved)
);

export const dirtyTaManagersSelector = createSelector(
  selectedTaManagersSelector,
  savedTaManagersSelector,
  (selected, saved) => !isEqual(selected, saved)
);

export const dirtySelector = createSelector(
  dirtyBookingManagersSelector,
  dirtyTaManagersSelector,
  (...args) => args.some(x => x)
);

export const requestSelector = createSelector(domainSelector, domain => domain.request);
