import { createSelector } from 'reselect';
import { IBasketDomain } from './model';

export const domainSelector = (state: any): IBasketDomain => state.basket;

export const basketBuildSelector = createSelector(domainSelector, (domain: IBasketDomain) => domain.basketBuild);

export const shouldShowLeaveWarningInBookingBuilderSelector = createSelector(
  domainSelector,
  (domain: IBasketDomain) => domain.shouldShowLeaveWarningInBookingBuilder
);
