import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { arrowDown, arrowUp } from 'ui/Icons';

interface IDetailsLinkProps {
  isOpen: boolean;
  onClick: (open: boolean) => void;
  text: string;
  className?: string;
}

export const DetailsLink: React.FC<IDetailsLinkProps> = ({ isOpen, text, onClick, className }) => {
  const handleClick = useCallback(() => {
    onClick(!isOpen);
  }, [isOpen, onClick]);

  const arrowIcon = useMemo(() => (isOpen ? arrowUp : arrowDown), [isOpen]);

  const mainClassName = classNames('details-link text-brown-100 text-15px leading-20px cursor-pointer', className);

  return (
    <a className={mainClassName} onClick={handleClick}>
      <span className="details-link-text underline hover:no-decoration active:underline select-none">{text}</span>
      <img
        className="details-link-icon relative -top-2px ml-7px"
        src={arrowIcon}
        width="11"
        height="7"
        alt="More icon"
      />
    </a>
  );
};
