import {
  IBookingStateHistoryItem,
  ISpecialRequests,
  ITopNavigationData,
  EBookingStatus,
} from 'services/BookingManagerApi/types';
import { EBookingType } from 'services/BookingManagerApi/types/BookingTypeResponse';
import { ITask } from 'services/TaskManagementApi/types/TaskManagementResponse';

export const GET_SPECIAL_REQUESTS_REQUEST = 'bookingManager/GET_SPECIAL_REQUESTS_REQUEST';
export const GET_SPECIAL_REQUESTS_SUCCESS = 'bookingManager/GET_SPECIAL_REQUESTS_SUCCESS';
export const GET_SPECIAL_REQUESTS_FAILURE = 'bookingManager/GET_SPECIAL_REQUESTS_FAILURE';
export const GET_TOP_NAVIGATION_DATA_REQUEST = 'bookingManager/GET_TOP_NAVIGATION_DATA_REQUEST';
export const GET_TOP_NAVIGATION_DATA_SUCCESS = 'bookingManager/GET_TOP_NAVIGATION_DATA_SUCCESS';
export const GET_TOP_NAVIGATION_DATA_FAILURE = 'bookingManager/GET_TOP_NAVIGATION_DATA_FAILURE';
export const UPDATE_TOP_NAVIGATION_STATE_HISTORY = 'bookingManager/UPDATE_TOP_NAVIGATION_STATE_HISTORY';
export const UPDATE_TOP_NAVIGATION_BOOKING_STATUS = 'bookingManager/UPDATE_TOP_NAVIGATION_BOOKING_STATUS';
export const UPDATE_SPECIAL_REQUESTS_REQUEST = 'bookingManager/UPDATE_SPECIAL_REQUESTS_REQUEST';
export const UPDATE_SPECIAL_REQUESTS_SUCCESS = 'bookingManager/UPDATE_SPECIAL_REQUESTS_SUCCESS';
export const UPDATE_SPECIAL_REQUESTS_FAILURE = 'bookingManager/UPDATE_SPECIAL_REQUESTS_FAILURE';

export const SET_IS_PAYMENT_TERMS_EDITED_WITHOUT_SAVING = 'bookingManager/SET_IS_PAYMENT_TERMS_EDITED_WITHOUT_SAVING';
export const SET_IS_CANCELLATION_POLICY_EDITED_WITHOUT_SAVING =
  'bookingManager/SET_IS_CANCELLATION_POLICY_EDITED_WITHOUT_SAVING';
export const SET_IS_SPECIAL_REQUESTS_EDITED_WITHOUT_SAVING =
  'bookingManager/SET_IS_SPECIAL_REQUESTS_EDITED_WITHOUT_SAVING';

export const SET_SPECIAL_REQUESTS = 'bookingManager/SET_SPECIAL_REQUESTS';

export const SET_BOOKING_TYPE_REQUEST = 'bookingManager/SET_BOOKING_TYPE_REQUEST';
export const SET_BOOKING_TYPE_SUCCESS = 'bookingManager/SET_BOOKING_TYPE_SUCCESS';
export const SET_BOOKING_TYPE_FAILURE = 'bookingManager/SET_BOOKING_TYPE_FAILURE';
export const GET_BOOKING_TYPE_REQUEST = 'bookingManager/GET_BOOKING_TYPE_REQUEST';
export const GET_BOOKING_TYPE_SUCCESS = 'bookingManager/GET_BOOKING_TYPE_SUCCESS';
export const GET_BOOKING_TYPE_FAILURE = 'bookingManager/GET_BOOKING_TYPE_FAILURE';

export const GET_TOP_NAVIGATION_DATA_INLINE_REQUEST = 'bookingManager/GET_TOP_NAVIGATION_INLINE_DATA_REQUEST';
export const GET_TOP_NAVIGATION_DATA_INLINE_SUCCESS = 'bookingManager/GET_TOP_NAVIGATION_INLINE_DATA_SUCCESS';
export const GET_TOP_NAVIGATION_DATA_INLINE_FAILURE = 'bookingManager/GET_TOP_NAVIGATION_INLINE_DATA_FAILURE';

export const GET_BOOKING_TASKS_REQUEST = 'bookingManager/GET_BOOKING_TASKS_REQUEST';
export const GET_BOOKING_TASKS_SUCCESS = 'bookingManager/GET_BOOKING_TASKS_SUCCESS';
export const GET_BOOKING_TASKS_FAILURE = 'bookingManager/GET_BOOKING_TASKS_FAILURE';

export type GetSpecialRequestsRequestAction = ReturnType<typeof getSpecialRequestsRequestAction>;
export const getSpecialRequestsRequestAction = () => ({
  type: GET_SPECIAL_REQUESTS_REQUEST as typeof GET_SPECIAL_REQUESTS_REQUEST,
});

export type GetSpecialRequestsSuccessAction = ReturnType<typeof getSpecialRequestsSuccessAction>;
export const getSpecialRequestsSuccessAction = (specialRequests: ISpecialRequests) => ({
  type: GET_SPECIAL_REQUESTS_SUCCESS as typeof GET_SPECIAL_REQUESTS_SUCCESS,
  specialRequests,
});

export type GetSpecialRequestsFailureAction = ReturnType<typeof getSpecialRequestsFailureAction>;
export const getSpecialRequestsFailureAction = (error: string) => ({
  type: GET_SPECIAL_REQUESTS_FAILURE as typeof GET_SPECIAL_REQUESTS_FAILURE,
  error,
});

export type UpdateSpecialRequestsRequestAction = ReturnType<typeof updateSpecialRequestsRequestAction>;
export const updateSpecialRequestsRequestAction = (specialRequests: string) => ({
  type: UPDATE_SPECIAL_REQUESTS_REQUEST as typeof UPDATE_SPECIAL_REQUESTS_REQUEST,
  specialRequests,
});

export type UpdateSpecialRequestsSuccessAction = ReturnType<typeof updateSpecialRequestsSuccessAction>;
export const updateSpecialRequestsSuccessAction = (specialRequests: ISpecialRequests) => ({
  type: UPDATE_SPECIAL_REQUESTS_SUCCESS as typeof UPDATE_SPECIAL_REQUESTS_SUCCESS,
  specialRequests,
});

export type UpdateSpecialRequestsFailureAction = ReturnType<typeof updateSpecialRequestsFailureAction>;
export const updateSpecialRequestsFailureAction = (error: string) => ({
  type: UPDATE_SPECIAL_REQUESTS_FAILURE as typeof UPDATE_SPECIAL_REQUESTS_FAILURE,
  error,
});

export type GetTopNavigationDataRequestAction = ReturnType<typeof getTopNavigationDataRequestAction>;
export const getTopNavigationDataRequestAction = () => ({
  type: GET_TOP_NAVIGATION_DATA_REQUEST as typeof GET_TOP_NAVIGATION_DATA_REQUEST,
});

export type GetTopNavigationDataSuccessAction = ReturnType<typeof getTopNavigationDataSuccessAction>;
export const getTopNavigationDataSuccessAction = (
  topNavigationData: ITopNavigationData,
  bookingInformationReservationTeamData
) => ({
  type: GET_TOP_NAVIGATION_DATA_SUCCESS as typeof GET_TOP_NAVIGATION_DATA_SUCCESS,
  topNavigationData,
  bookingInformationReservationTeamData,
});

export type GetTopNavigationDataFailureAction = ReturnType<typeof getTopNavigationDataFailureAction>;
export const getTopNavigationDataFailureAction = (error: string) => ({
  type: GET_TOP_NAVIGATION_DATA_FAILURE as typeof GET_TOP_NAVIGATION_DATA_FAILURE,
  error,
});

export type UpdateTopNavigationStateHistoryAction = ReturnType<typeof updateTopNavigationStateHistoryAction>;
export const updateTopNavigationStateHistoryAction = (stateHistory: IBookingStateHistoryItem[]) => ({
  type: UPDATE_TOP_NAVIGATION_STATE_HISTORY as typeof UPDATE_TOP_NAVIGATION_STATE_HISTORY,
  stateHistory,
});

export type UpdateTopNavigationBookingStatusAction = ReturnType<typeof updateTopNavigationBookingStatusAction>;
export const updateTopNavigationBookingStatusAction = (status: EBookingStatus) => ({
  type: UPDATE_TOP_NAVIGATION_BOOKING_STATUS as typeof UPDATE_TOP_NAVIGATION_BOOKING_STATUS,
  status,
});

export type SetIsPaymentTermsEditedWithoutSavingAction = ReturnType<typeof setIsPaymentTermsEditedWithoutSavingAction>;
export const setIsPaymentTermsEditedWithoutSavingAction = (toggle: boolean) => ({
  type: SET_IS_PAYMENT_TERMS_EDITED_WITHOUT_SAVING as typeof SET_IS_PAYMENT_TERMS_EDITED_WITHOUT_SAVING,
  toggle,
});

export type SetIsCancellationPolicyEditedWithoutSavingAction = ReturnType<
  typeof setIsCancellationPolicyEditedWithoutSavingAction
>;
export const setIsCancellationPolicyEditedWithoutSavingAction = (toggle: boolean) => ({
  type: SET_IS_CANCELLATION_POLICY_EDITED_WITHOUT_SAVING as typeof SET_IS_CANCELLATION_POLICY_EDITED_WITHOUT_SAVING,
  toggle,
});

export type SetIsSpecialRequestsEditedWithoutSavingAction = ReturnType<
  typeof setIsSpecialRequestsEditedWithoutSavingAction
>;
export const setIsSpecialRequestsEditedWithoutSavingAction = (toggle: boolean) => ({
  type: SET_IS_SPECIAL_REQUESTS_EDITED_WITHOUT_SAVING as typeof SET_IS_SPECIAL_REQUESTS_EDITED_WITHOUT_SAVING,
  toggle,
});

export type SetSpecialRequestsAction = ReturnType<typeof setSpecialRequestsAction>;
export const setSpecialRequestsAction = (text: string) => ({
  type: SET_SPECIAL_REQUESTS as typeof SET_SPECIAL_REQUESTS,
  text,
});

export type GetBookingTypeRequestAction = ReturnType<typeof getBookingTypeRequestAction>;
export const getBookingTypeRequestAction = () => ({
  type: GET_BOOKING_TYPE_REQUEST as typeof GET_BOOKING_TYPE_REQUEST,
});

export type GetBookingTypeSuccessAction = ReturnType<typeof getBookingTypeSuccessAction>;
export const getBookingTypeSuccessAction = (bookingType: EBookingType) => ({
  type: GET_BOOKING_TYPE_SUCCESS as typeof GET_BOOKING_TYPE_SUCCESS,
  bookingType,
});

export type GetBookingTypeFailureAction = ReturnType<typeof getBookingTypeFailureAction>;
export const getBookingTypeFailureAction = (error: string) => ({
  type: GET_BOOKING_TYPE_FAILURE as typeof GET_BOOKING_TYPE_FAILURE,
  error,
});

export type SetBookingTypeRequestAction = ReturnType<typeof setBookingTypeRequestAction>;
export const setBookingTypeRequestAction = (bookingType: EBookingType) => ({
  type: SET_BOOKING_TYPE_REQUEST as typeof SET_BOOKING_TYPE_REQUEST,
  bookingType,
});

export type SetBookingTypeSuccessAction = ReturnType<typeof setBookingTypeSuccessAction>;
export const setBookingTypeSuccessAction = (bookingType: EBookingType) => ({
  type: SET_BOOKING_TYPE_SUCCESS as typeof SET_BOOKING_TYPE_SUCCESS,
  bookingType,
});

export type SetBookingTypeFailureAction = ReturnType<typeof setBookingTypeFailureAction>;
export const setBookingTypeFailureAction = (error: string) => ({
  type: SET_BOOKING_TYPE_FAILURE as typeof SET_BOOKING_TYPE_FAILURE,
  error,
});

export type GetBookingTasksRequestAction = ReturnType<typeof getBookingTasksRequestAction>;
export const getBookingTasksRequestAction = () => ({
  type: GET_BOOKING_TASKS_REQUEST as typeof GET_BOOKING_TASKS_REQUEST,
});

export type GetBookingTasksSuccessAction = ReturnType<typeof getBookingTasksSuccessAction>;
export const getBookingTasksSuccessAction = (bookingTasks: ITask[]) => ({
  type: GET_BOOKING_TASKS_SUCCESS as typeof GET_BOOKING_TASKS_SUCCESS,
  bookingTasks,
});

export type GetBookingTasksFailureAction = ReturnType<typeof getBookingTasksFailureAction>;
export const getBookingTasksFailureAction = (error: string) => ({
  type: GET_BOOKING_TASKS_FAILURE as typeof GET_BOOKING_TASKS_FAILURE,
  error,
});

export type GetTopNavigationDataInlineRequestAction = ReturnType<typeof getTopNavigationDataInlineRequestAction>;
export const getTopNavigationDataInlineRequestAction = () => ({
  type: GET_TOP_NAVIGATION_DATA_INLINE_REQUEST as typeof GET_TOP_NAVIGATION_DATA_INLINE_REQUEST,
});

export type DashboardAction =
  | GetSpecialRequestsRequestAction
  | GetSpecialRequestsSuccessAction
  | GetSpecialRequestsFailureAction
  | UpdateSpecialRequestsRequestAction
  | UpdateSpecialRequestsSuccessAction
  | UpdateSpecialRequestsFailureAction
  | GetTopNavigationDataRequestAction
  | GetTopNavigationDataSuccessAction
  | GetTopNavigationDataFailureAction
  | UpdateTopNavigationStateHistoryAction
  | UpdateTopNavigationBookingStatusAction
  | SetIsPaymentTermsEditedWithoutSavingAction
  | SetIsCancellationPolicyEditedWithoutSavingAction
  | SetIsSpecialRequestsEditedWithoutSavingAction
  | GetBookingTypeRequestAction
  | GetBookingTypeSuccessAction
  | GetBookingTypeFailureAction
  | SetBookingTypeRequestAction
  | SetBookingTypeSuccessAction
  | SetBookingTypeFailureAction
  | SetSpecialRequestsAction
  | GetBookingTasksRequestAction
  | GetBookingTasksSuccessAction
  | GetBookingTasksFailureAction
  | GetTopNavigationDataInlineRequestAction;
