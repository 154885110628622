import { BookingBuilderResponse, Occasion } from 'services/BackendApi';
import { ETransferDirection } from './types';

export const ADD_TRANSFER = 'bookingBuilderTransfers/ADD_TRANSFER';
export const DELETE_ALL_TRANSFERS = 'bookingBuilderTransfers/DELETE_ALL_TRANSFERS';
export const DELETE_TRANSFER = 'bookingBuilderTransfers/DELETE_TRANSFER';
export const SET_ROOM_INDEXES_ON_TRANSFER = 'bookingBuilderTransfers/SET_ROOM_INDEXES_ON_TRANSFER';

export const SYNC_SELECTED_TRANSFERS = 'bookingBuilderTransfers/SYNC_SELECTED_TRANSFERS';

export const HANDLE_ADD_ROOM_SELECTED_TRANSFERS = 'bookingBuilderTransfers/HANDLE_ADD_ROOM_SELECTED_TRANSFERS';

export const HANDLE_REMOVE_ROOM_SELECTED_TRANSFERS = 'bookingBuilderTransfers/HANDLE_REMOVE_ROOM_SELECTED_TRANSFERS';

export type AddTransferAction = ReturnType<typeof addTransferAction>;
export const addTransferAction = (
  transferUuid: string,
  roomIndexes: number[],
  isReturn: boolean,
  isViaShare: boolean,
  direction: ETransferDirection,
  sharedWithRoomIndex?: number
) => ({
  type: ADD_TRANSFER as typeof ADD_TRANSFER,
  transferUuid,
  roomIndexes,
  isReturn,
  isViaShare,
  direction,
  sharedWithRoomIndex,
});

export type DeleteAllTransfersAction = ReturnType<typeof deleteAllTransfersAction>;
export const deleteAllTransfersAction = () => ({
  type: DELETE_ALL_TRANSFERS as typeof DELETE_ALL_TRANSFERS,
});

export type DeleteTransferAction = ReturnType<typeof deleteTransferAction>;
export const deleteTransferAction = (
  transferUuid: string,
  roomIndexes: number[],
  isReturn: boolean,
  isViaShare: boolean,
  direction: ETransferDirection,
  sharedWithRoomIndex?: number
) => ({
  type: DELETE_TRANSFER as typeof DELETE_TRANSFER,
  transferUuid,
  roomIndexes,
  isReturn,
  isViaShare,
  direction,
  sharedWithRoomIndex,
});

export type SetRoomIndexesOnTransferAction = ReturnType<typeof setRoomIndexesOnTransferAction>;
export const setRoomIndexesOnTransferAction = (transferUuid: string, roomIndexes: number[]) => ({
  type: SET_ROOM_INDEXES_ON_TRANSFER as typeof SET_ROOM_INDEXES_ON_TRANSFER,
  transferUuid,
  roomIndexes,
});

export type SyncSelectedTransfersAction = ReturnType<typeof syncSelectedTransfersAction>;
export const syncSelectedTransfersAction = (data: BookingBuilderResponse) => ({
  type: SYNC_SELECTED_TRANSFERS as typeof SYNC_SELECTED_TRANSFERS,
  data,
});

export type HandleAddRoomSelectedTransfersAction = ReturnType<typeof handleAddRoomSelectedTransfersAction>;
export const handleAddRoomSelectedTransfersAction = (indexAdded: number) => ({
  type: HANDLE_ADD_ROOM_SELECTED_TRANSFERS as typeof HANDLE_ADD_ROOM_SELECTED_TRANSFERS,
  indexAdded,
});

export type HandleRemoveRoomSelectedTransfersAction = ReturnType<typeof handleRemoveRoomSelectedTransfersAction>;
export const handleRemoveRoomSelectedTransfersAction = (indexRemoved: number) => ({
  type: HANDLE_REMOVE_ROOM_SELECTED_TRANSFERS as typeof HANDLE_REMOVE_ROOM_SELECTED_TRANSFERS,
  indexRemoved,
});

export type BookingBuilderTransfersAction =
  | AddTransferAction
  | DeleteTransferAction
  | DeleteAllTransfersAction
  | SetRoomIndexesOnTransferAction
  | SyncSelectedTransfersAction
  | HandleAddRoomSelectedTransfersAction
  | HandleRemoveRoomSelectedTransfersAction;
