import React, { useMemo } from 'react';
import PiggyBankIcon from 'ui/Icons/piggyBank.gray.component.svg';

interface ITransactionTypeColumnProps {
  transactionType: string;
  isDepositRow: boolean;
  transactionSymbolType: ETransactionSymbolType;
  className?: string;
}

import { HidingTooltip } from 'ui/Tooltip';

export enum ETransactionSymbolType {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NONE = 'NONE',
}

interface ITransactionSymbolProps {
  transactionType: ETransactionSymbolType;
}

export const TransactionSymbol: React.FC<ITransactionSymbolProps> = React.memo(({ transactionType }) => {
  const Classes = useMemo(
    () => ({
      transaction: 'block leading-17px text-13px font-bold text-center h-19px w-19px rounded cursor-pointer',
      positive: 'bg-teal-20',
      negative: 'bg-red-20',
    }),
    []
  );

  if (transactionType === ETransactionSymbolType.POSITIVE) {
    const renderSignTooltip = () => <span className="block capitalize px-1 py-5px text-13px">Positive</span>;

    return (
      <HidingTooltip renderTooltipContent={renderSignTooltip} position="bottom">
        <span className={`${Classes.transaction} ${Classes.positive}`}>+</span>
      </HidingTooltip>
    );
  }

  if (transactionType === ETransactionSymbolType.NEGATIVE) {
    const renderSignTooltip = () => <span className="block capitalize px-1 py-5px text-13px">Negative</span>;

    return (
      <HidingTooltip renderTooltipContent={renderSignTooltip} position="bottom">
        <span className={`${Classes.transaction} ${Classes.negative}`}>-</span>
      </HidingTooltip>
    );
  }

  return null;
});

export const TransactionTypeColumn: React.FC<ITransactionTypeColumnProps> = React.memo(
  ({ transactionType, className, transactionSymbolType, isDepositRow }) => (
    <td className={`${className} px-2`}>
      <div className="flex flex-row items-center space-x-10px">
        <div
          style={{
            minWidth: '30px', // to account for some columns having a deposit icon
          }}
          className="flex flex-col"
        >
          <span className="ml-5px">
            <TransactionSymbol transactionType={transactionSymbolType} />
          </span>
          {isDepositRow && (
            <span className="w-28px h-19px mt-1">
              <PiggyBankIcon />
            </span>
          )}
        </div>
        <span>{transactionType}</span>
      </div>
    </td>
  )
);
