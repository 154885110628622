import React from 'react';
import { EFinanceRowTypes } from 'services/BookingManagerApi/types/FinanceResponse';
import { IMultiselectValueLabelPair } from 'ui/Multiselect';

export const PositiveIcon = (
  <span className="fa-stack">
    <i className="fas fa-square fa-stack-2x text-green-40"></i>
    <i className="fas fa-plus fa-stack-1x text-black"></i>
  </span>
);

export const NegativeIcon = (
  <span className="fa-stack">
    <i className="fas fa-square fa-stack-2x text-red-40"></i>
    <i className="fas fa-minus fa-stack-1x text-black"></i>
  </span>
);

export const getFinanceRowTypeDisplayName = (frType: EFinanceRowTypes) => {
  const financeRowTypeDisplayNames = {
    [EFinanceRowTypes.Manual_Invoice_to_Travel_Agent]: 'Manual Invoice to Travel Partner',
    [EFinanceRowTypes.Manual_Invoice_to_Final_Client]: 'Manual Invoice to Guest',
    [EFinanceRowTypes.Payment_to_Travel_Agent]: 'Payment to Travel Partner',
    [EFinanceRowTypes.Payment_to_Final_Client]: 'Payment to Guest',
    [EFinanceRowTypes.Deposit_Transfer_Out]: 'Deposit Transfer Out',
    [EFinanceRowTypes.Sales_Positive_Adjustment]: 'Positive Adjustment',
    [EFinanceRowTypes.Manual_Cancellation_Fee]: 'Manual Cancellation Fee',
    [EFinanceRowTypes.Bank_Charges_Positive]: 'Bank Charges',
    [EFinanceRowTypes.Credit_Note_For_Guest_Out_Positive]: 'Credit Note for Guest - OUT',
    [EFinanceRowTypes.Credit_Note_For_Travel_Partner_Out_Positive]: 'Credit Note for Travel Partner - OUT',
    [EFinanceRowTypes.Manual_Margin_to_Travel_Agent]: 'Manual Margin to Travel Partner',
    [EFinanceRowTypes.Payment_from_Travel_Agent]: 'Payment from Travel Partner',
    [EFinanceRowTypes.Payment_from_Final_Client]: 'Payment from Guest',
    [EFinanceRowTypes.Payment_from_Final_Client_to_hotel]: 'Payment from Guest to Hotel',
    [EFinanceRowTypes.Deposit_Transfer_In]: 'Deposit Transfer In',
    [EFinanceRowTypes.Sales_Negative_Adjustment]: 'Negative Adjustment',
    [EFinanceRowTypes.Manual_Cancellation_of_Invoice]: 'Manual Cancellation of Invoice',
    [EFinanceRowTypes.Credit_Note_For_Guest_In_Negative]: 'Credit Note for Guest - IN',
    [EFinanceRowTypes.Credit_Note_For_Travel_Partner_In_Negative]: 'Credit Note for Travel Partner - IN',
    [EFinanceRowTypes.Payment_to_Hotel]: 'Payment to Hotel',
    [EFinanceRowTypes.Payment_to_Hotel_from_Final_Client]: 'Payment to Hotel from Guest',
    [EFinanceRowTypes.Payment_to_Hotel_from_Travel_Agent]: 'Payment to Hotel from Travel Partner',
    [EFinanceRowTypes.Purchase_Positive_Adjustment]: 'Positive Adjustment',
    [EFinanceRowTypes.Payment_To_Non_Hotel_Supplier]: 'Payment To Non Hotel Supplier',
    [EFinanceRowTypes.Credit_Note_From_Hotel_Used_Positive]: 'Credit Note from Hotel Used',
    [EFinanceRowTypes.Credit_Note_From_Non_Hotel_Supplier_Used_Positive]: 'Credit Note from Non Hotel Supplier Used',
    [EFinanceRowTypes.Manual_Hotel_Invoice]: 'Manual Hotel Invoice',
    [EFinanceRowTypes.Manual_Invoice_from_Supplier]: 'Manual Invoice from Supplier',
    [EFinanceRowTypes.Purchase_Negative_Adjustment]: 'Negative Adjustment',
    [EFinanceRowTypes.Bank_Charges_Negative]: 'Bank Charges',
    [EFinanceRowTypes.Credit_Note_From_Hotel_Negative]: 'Credit Note from Hotel',
    [EFinanceRowTypes.Credit_Note_From_Non_Hotel_Supplier_Negative]: 'Credit Note from Non Hotel Supplier',
    [EFinanceRowTypes.Breakdown_Finance_Adjustment_Positive]: 'Breakdown Finance Adjustment',
    [EFinanceRowTypes.Breakdown_Finance_Adjustment_Negative]: 'Breakdown Finance Adjustment',
  };

  return financeRowTypeDisplayNames[frType] ?? frType;
};

export const salesPositive: IMultiselectValueLabelPair[] = [
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Manual_Invoice_to_Travel_Agent),
    value: EFinanceRowTypes.Manual_Invoice_to_Travel_Agent,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Manual_Invoice_to_Final_Client),
    value: EFinanceRowTypes.Manual_Invoice_to_Final_Client,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },

  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Payment_to_Travel_Agent),
    value: EFinanceRowTypes.Payment_to_Travel_Agent,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },

  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Payment_to_Final_Client),
    value: EFinanceRowTypes.Payment_to_Final_Client,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Deposit_Transfer_Out),
    value: EFinanceRowTypes.Deposit_Transfer_Out,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Sales_Positive_Adjustment),
    value: EFinanceRowTypes.Sales_Positive_Adjustment,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Manual_Cancellation_Fee),
    value: EFinanceRowTypes.Manual_Cancellation_Fee,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Bank_Charges_Positive),
    value: EFinanceRowTypes.Bank_Charges_Positive,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Credit_Note_For_Guest_Out_Positive),
    value: EFinanceRowTypes.Credit_Note_For_Guest_Out_Positive,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Credit_Note_For_Travel_Partner_Out_Positive),
    value: EFinanceRowTypes.Credit_Note_For_Travel_Partner_Out_Positive,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
];

export const salesNegative: IMultiselectValueLabelPair[] = [
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Manual_Margin_to_Travel_Agent),
    value: EFinanceRowTypes.Manual_Margin_to_Travel_Agent,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Payment_from_Travel_Agent),
    value: EFinanceRowTypes.Payment_from_Travel_Agent,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Payment_from_Final_Client),
    value: EFinanceRowTypes.Payment_from_Final_Client,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Payment_from_Final_Client_to_hotel),
    value: EFinanceRowTypes.Payment_from_Final_Client_to_hotel,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Deposit_Transfer_In),
    value: EFinanceRowTypes.Deposit_Transfer_In,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Sales_Negative_Adjustment),
    value: EFinanceRowTypes.Sales_Negative_Adjustment,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },

  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Manual_Cancellation_of_Invoice),
    value: EFinanceRowTypes.Manual_Cancellation_of_Invoice,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  // removed as of OWA-4442
  // definition kept for posterity
  // {
  //   label: 'Manual Credit Note',
  //   value: EFinanceRowTypes.Manual_Credit_Note,
  //   iconHtml: NegativeIcon,
  //   iconAlign: 'end',
  // },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Bank_Charges_Negative),
    value: EFinanceRowTypes.Bank_Charges_Negative,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Credit_Note_For_Guest_In_Negative),
    value: EFinanceRowTypes.Credit_Note_For_Guest_In_Negative,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Credit_Note_For_Travel_Partner_In_Negative),
    value: EFinanceRowTypes.Credit_Note_For_Travel_Partner_In_Negative,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
];

export const sales: IMultiselectValueLabelPair[] = [...salesPositive, ...salesNegative];

export const purchase: IMultiselectValueLabelPair[] = [
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Payment_to_Hotel),
    value: EFinanceRowTypes.Payment_to_Hotel,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Payment_to_Hotel_from_Final_Client),
    value: EFinanceRowTypes.Payment_to_Hotel_from_Final_Client,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Payment_to_Hotel_from_Travel_Agent),
    value: EFinanceRowTypes.Payment_to_Hotel_from_Travel_Agent,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Purchase_Positive_Adjustment),
    value: EFinanceRowTypes.Purchase_Positive_Adjustment,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Bank_Charges_Positive),
    value: EFinanceRowTypes.Bank_Charges_Positive,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Payment_To_Non_Hotel_Supplier),
    value: EFinanceRowTypes.Payment_To_Non_Hotel_Supplier,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Credit_Note_From_Hotel_Used_Positive),
    value: EFinanceRowTypes.Credit_Note_From_Hotel_Used_Positive,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Credit_Note_From_Non_Hotel_Supplier_Used_Positive),
    value: EFinanceRowTypes.Credit_Note_From_Non_Hotel_Supplier_Used_Positive,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Breakdown_Finance_Adjustment_Positive),
    value: EFinanceRowTypes.Breakdown_Finance_Adjustment_Positive,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  // negative
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Manual_Hotel_Invoice),
    value: EFinanceRowTypes.Manual_Hotel_Invoice,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Manual_Invoice_from_Supplier),
    value: EFinanceRowTypes.Manual_Invoice_from_Supplier,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Purchase_Negative_Adjustment),
    value: EFinanceRowTypes.Purchase_Negative_Adjustment,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Bank_Charges_Negative),
    value: EFinanceRowTypes.Bank_Charges_Negative,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Credit_Note_From_Hotel_Negative),
    value: EFinanceRowTypes.Credit_Note_From_Hotel_Negative,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Credit_Note_From_Non_Hotel_Supplier_Negative),
    value: EFinanceRowTypes.Credit_Note_From_Non_Hotel_Supplier_Negative,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: getFinanceRowTypeDisplayName(EFinanceRowTypes.Breakdown_Finance_Adjustment_Negative),
    value: EFinanceRowTypes.Breakdown_Finance_Adjustment_Negative,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
];
