import { call, takeLatest, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBackendApi } from 'services/BackendApi';

import {
  GET_POSSIBLE_NOTIFICATIONS_REQUEST,
  GetPossibleNotificationsRequestAction,
  getPossibleNotificationsSuccessAction,
  getPossibleNotificationsFailureAction,
  GET_ENABLED_NOTIFICATIONS_REQUEST,
  GetEnabledNotificationsRequestAction,
  getEnabledNotificationsSuccessAction,
  getEnabledNotificationsFailureAction,
  SET_NOTIFICATIONS_FOR_COMPANY_USERS_REQUEST,
  SetNotificationsForCompanyUsersRequestAction,
  setNotificationsForCompanyUsersFailureAction,
  setNotificationsForCompanyUsersSuccessAction,
} from './actions';
import { IEnabledNotificationsResponse, IPossibleNotificationsResponse } from 'services/BackendApi/types/Notification';
import { enqueueNotification } from '../ui';
import * as CompanyManagersActions from 'store/modules/companyManagers/actions';

export function* getEnabledNotificationsSaga(action: GetEnabledNotificationsRequestAction) {
  const backendApi = makeBackendApi();

  try {
   const enabledNotificationsResponse: AxiosResponse<IEnabledNotificationsResponse> = yield call(
     backendApi.getEnabledNotificationsForUser, action.userUuid
   );

    yield put(getEnabledNotificationsSuccessAction(enabledNotificationsResponse.data.data.notifications));
  } catch (e) {
    yield put(getEnabledNotificationsFailureAction(e.message));
  }
}

export function* getPossibleNotificationsSaga(action: GetPossibleNotificationsRequestAction) {
  const backendApi = makeBackendApi();

  try {
    const possibleNotificationsResponse: AxiosResponse<IPossibleNotificationsResponse> = yield call(
      backendApi.getPossibleNotificationsForRole, action.userType
    );

    yield put(getPossibleNotificationsSuccessAction(possibleNotificationsResponse.data.data.notifications));
  } catch (e) {
    yield put(getPossibleNotificationsFailureAction(e.message));
  }
}

export function* setNotificationsForAllCompanyUsers(action: SetNotificationsForCompanyUsersRequestAction) {
  const backendApi = makeBackendApi();

  try {
    yield call(
      backendApi.setNotificationsForAllCompanyUsers, action.companyUuid, action.role, action.notificationCodes, action.enabled
    );

    yield put(setNotificationsForCompanyUsersSuccessAction());
    yield put(CompanyManagersActions.getRequestAction(action.companyUuid));
    yield put(enqueueNotification({
      message: 'Notification Settings updated.',
      options: { variant: 'success' },
    }));
  } catch (e) {
    yield put(setNotificationsForCompanyUsersFailureAction(e.message));
    yield put(enqueueNotification({
      message: 'There was a problem updating Notification Settings.',
      options: { variant: 'error' },
    }));
  }
}

export function* watchNotificationsSaga() {
  yield takeLatest(GET_POSSIBLE_NOTIFICATIONS_REQUEST, getPossibleNotificationsSaga);
  yield takeLatest(GET_ENABLED_NOTIFICATIONS_REQUEST, getEnabledNotificationsSaga);
  yield takeLatest(SET_NOTIFICATIONS_FOR_COMPANY_USERS_REQUEST, setNotificationsForAllCompanyUsers);
}
