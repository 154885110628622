import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const CloseX: React.FC<IIconProps> = React.memo(({ icon = 'brown-100' }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.99232 8.13104C6.8174 8.13104 6.64248 8.04968 6.51129 7.92765L1.17628 2.51748C0.913901 2.27341 0.957631 1.90731 1.22001 1.66324C1.48239 1.41918 1.87595 1.45985 2.13833 1.70392L7.47335 7.07341C7.73572 7.31748 7.69199 7.72426 7.42962 7.92765C7.29843 8.04968 7.16724 8.13104 6.99232 8.13104Z"
      fill={theme.colors[icon]}
    />
    <path
      d="M1.6573 13.5006C1.48238 13.5006 1.3512 13.4599 1.22001 13.3379C0.957629 13.0938 0.957629 12.7277 1.17628 12.4837L6.51129 7.11417C6.77367 6.87011 7.16724 6.87011 7.42962 7.0735C7.69199 7.31756 7.69199 7.68367 7.47335 7.92773L2.13833 13.2972C2.00714 13.4193 1.83222 13.5006 1.6573 13.5006Z"
      fill={theme.colors[icon]}
    />
    <path
      d="M6.99232 8.13104C6.8174 8.13104 6.68622 8.09047 6.55503 7.96844C6.29265 7.72437 6.29265 7.35824 6.51129 7.11417L11.8463 1.70403C12.1087 1.45996 12.5023 1.45996 12.7646 1.66335C13.0707 1.90742 13.0707 2.27352 12.8084 2.51759L7.47335 7.92773C7.34216 8.04977 7.16724 8.13104 6.99232 8.13104Z"
      fill={theme.colors[icon]}
    />
    <path
      d="M12.3273 13.5006C12.1524 13.5006 11.9775 13.4193 11.8463 13.2972L6.51129 7.92765C6.24892 7.68358 6.29265 7.27691 6.55503 7.07352C6.8174 6.82945 7.2547 6.87013 7.47335 7.1142L12.8084 12.4837C13.0707 12.7278 13.027 13.1345 12.7646 13.3379C12.6334 13.46 12.5023 13.5006 12.3273 13.5006Z"
      fill={theme.colors[icon]}
    />
    <path
      d="M6.99232 8.13104C6.8174 8.13104 6.64248 8.04968 6.51129 7.92765M6.99232 8.13104C7.16724 8.13104 7.29843 8.04968 7.42962 7.92765C7.69199 7.72426 7.73572 7.31748 7.47335 7.07341L2.13833 1.70392C1.87595 1.45985 1.48239 1.41918 1.22001 1.66324C0.957631 1.90731 0.913901 2.27341 1.17628 2.51748L6.51129 7.92765M6.99232 8.13104C6.8174 8.13104 6.68622 8.09047 6.55503 7.96844C6.29265 7.72437 6.29265 7.35824 6.51129 7.11417M6.99232 8.13104C7.16724 8.13104 7.34216 8.04977 7.47335 7.92773M6.51129 7.92765L11.8463 13.2972C11.9775 13.4193 12.1524 13.5006 12.3273 13.5006C12.5023 13.5006 12.6334 13.46 12.7646 13.3379C13.027 13.1345 13.0707 12.7278 12.8084 12.4837L7.47335 7.1142C7.2547 6.87013 6.8174 6.82945 6.55503 7.07352C6.29265 7.27691 6.24892 7.68358 6.51129 7.92765ZM6.51129 7.11417L1.17628 12.4837C0.957629 12.7277 0.957629 13.0938 1.22001 13.3379C1.3512 13.4599 1.48238 13.5006 1.6573 13.5006C1.83222 13.5006 2.00714 13.4193 2.13833 13.2972L7.47335 7.92773M6.51129 7.11417C6.77367 6.87011 7.16724 6.87011 7.42962 7.0735C7.69199 7.31756 7.69199 7.68367 7.47335 7.92773M6.51129 7.11417L11.8463 1.70403C12.1087 1.45996 12.5023 1.45996 12.7646 1.66335C13.0707 1.90742 13.0707 2.27352 12.8084 2.51759L7.47335 7.92773"
      stroke={theme.colors[icon]}
    />
  </svg>
));

export default CloseX;
