import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Actions from './actions';
import { ICompanyListDomain, initialState } from './model';
import { update } from 'utils/immutable';

export const companyListReducer = (
  state: ICompanyListDomain = initialState,
  action: Actions.Any
): ICompanyListDomain => {
  switch (action.type) {

    case Actions.GET_REQUEST:
      return update(state, s => {
        s.request.get = ENetworkRequestStatus.PENDING;
        s.list = null;
      });

    case Actions.GET_SUCCESS:
      return update(state, s => {
        s.request.get = ENetworkRequestStatus.SUCCESS;
        
        s.list = action.companies;
        s.pagination.total = action.total
      });

    case Actions.GET_FAILURE:
      return update(state, s => s.request.get = ENetworkRequestStatus.ERROR);
    
    case Actions.SET_SEARCH_FILTER:
      return update(state, s => {
        s.pagination.page = 0;
        s.filter.search = action.value;
        s.request.get = ENetworkRequestStatus.PENDING;
      });
    
    case Actions.SET_COUNTRY_CODE_FILTER:
      return update(state, s => {
        s.pagination.page = 0;
        s.filter.countryCode = action.value;
        s.request.get = ENetworkRequestStatus.PENDING;
      });
      
    case Actions.SET_PAGE:
      return update(state, s => {
        s.pagination.page = action.value;
        s.request.get = ENetworkRequestStatus.PENDING;
      });
    
    case Actions.SET_PER_PAGE:
      return update(state, s => {
        s.pagination.page = 0;
        s.pagination.perPage = action.value;
        s.request.get = ENetworkRequestStatus.PENDING;
      });

    case Actions.TRAVEL_COMPANY_CSV_REQUEST:
      return update(state, s => s.request.csv = ENetworkRequestStatus.PENDING);

    case Actions.TRAVEL_COMPANY_CSV_REQUEST_SUCCESS:
      return update(state, s => s.request.csv = ENetworkRequestStatus.SUCCESS);

    case Actions.TRAVEL_COMPANY_CSV_REQUEST_ERROR:
      return update(state, s => s.request.csv = ENetworkRequestStatus.ERROR);
    
    default:
      return state;
  }
};

export default companyListReducer;