import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { List } from './List';

export interface CredentialsInfoRoutingProps extends RouteComponentProps {}

export const CredentialsInfoRoutingComponent = (props: CredentialsInfoRoutingProps) => {
  return (
    <Switch>
      <Route exact path={props.match.path} component={List} />
    </Switch>
  );
};

export const CredentialsInfoRouting = compose(withRouter)(CredentialsInfoRoutingComponent);
