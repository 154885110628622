import { connect } from 'react-redux';
import { pipe } from 'ramda';

import { getBookingCurrencySymbol, updateBooking } from 'store/modules/bookings';

import { setIsBookingSummarySectionCollapsed } from 'store/modules/ui';

import {
  updateTransferAction,
  updateGroundServiceAction,
  updateSupplementAction,
  updateFineAction,
  updateTAMarginTypeAction,
  updateTAMarginAmountAction,
  updateIsTAMarginAppliedAction,
  updateBookingGuestInformationAction,
  saveCustomItemAction,
  removeCustomItemAction,
} from 'store/modules/bookingBuilder';

import {
  showCustomItemFormAction,
  hideCustomItemFormAction,
  updateCustomItemNameAction,
  updateCustomItemTotalAction,
  updateCustomItemDescriptionAction,
  updateCustomItemCountsAsMealPlanAction,
  updateCustomItemCountsAsTransferAction,
} from 'store/modules/bookingBuilder/subdomains/customItem/actions';

export const mapStateToProps = (state, { id }) => {
  return {
    currencySymbol: getBookingCurrencySymbol(state, id),
  };
};

export const mapDispatchToProps = dispatch => ({
  updateBooking: pipe(updateBooking, dispatch),
  setIsBookingSummarySectionCollapsed: pipe(setIsBookingSummarySectionCollapsed, dispatch),
  updateTransferAction: pipe(updateTransferAction, dispatch),
  updateGroundServiceAction: pipe(updateGroundServiceAction, dispatch),
  updateSupplementAction: pipe(updateSupplementAction, dispatch),
  updateFineAction: pipe(updateFineAction, dispatch),
  updateTAMarginTypeAction: pipe(updateTAMarginTypeAction, dispatch),
  updateTAMarginAmountAction: pipe(updateTAMarginAmountAction, dispatch),
  updateIsTAMarginAppliedAction: pipe(updateIsTAMarginAppliedAction, dispatch),
  updateBookingGuestInformationAction: pipe(updateBookingGuestInformationAction, dispatch),
  customItemActions: [
    ['showForm', showCustomItemFormAction],
    ['hideForm', hideCustomItemFormAction],
    ['updateName', updateCustomItemNameAction],
    ['updateTotal', updateCustomItemTotalAction],
    ['updateDescription', updateCustomItemDescriptionAction],
    ['updateCountsAsMealPlan', updateCustomItemCountsAsMealPlanAction],
    ['updateCountsAsTransfer', updateCustomItemCountsAsTransferAction],
    ['save', saveCustomItemAction],
    ['remove', removeCustomItemAction],
  ].reduce(
    (acc, [key, action]) => ({
      ...acc,
      [key]: pipe(action, dispatch),
    }),
    {}
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
