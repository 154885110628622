import { call, select, takeLatest, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { AxiosResponse } from 'axios';
import { makeBackendApi, IInternalUserResponse, IOneInternalUserResponse, ICompaniesResponse } from 'services/BackendApi';

import * as InternalUserActions from './actions';

import { IEnabledNotification } from 'services/BackendApi/types/Notification';
import { enqueueNotification } from '../ui';
import { getDynamicParametersSelector } from '../bootstrap/selectors';
import { IDynamicParametersResponse } from 'services/ParametersProviderApi';

function* successNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'success' } }));
}

function* errorNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'error' } }));
}

export function* createRequestSaga(action: InternalUserActions.CreateInternalUserRequestAction) {
  const backendApi = makeBackendApi();
  const notifications: IEnabledNotification[] = action.notifications.map(notification => {
    return {
      code: notification.code,
      enabled: notification.enabled ?? false,
      ccToDepartmentsUuids: notification.ccToDepartmentsUuids || [],
    };
  });

  try {
    const emailValidationResponse: AxiosResponse<IInternalUserResponse> = yield call(
      backendApi.getInternalUsersByEmail,
      action.internalUser.email ?? ''
    );
    if (emailValidationResponse.data.data.length > 0) {
      throw new Error('This email has already been registered');
    }

    const dynamicParameters: IDynamicParametersResponse = yield select(getDynamicParametersSelector);
    const query = {
      'filter[company][companyId]': dynamicParameters.MAIN_COMPANY_CODE,
    };
    const companiesResponse: AxiosResponse<ICompaniesResponse> = yield call(
      backendApi.getCompanies,
      query
    );
    const mainCompany = Array.isArray(companiesResponse?.data?.data) && companiesResponse?.data?.data?.length > 0
      ? companiesResponse.data.data[0]
      : null;

    const internalUserData = {
      ...action.internalUser,
      companyUuid: mainCompany?.uuid ?? null,
    };
    const internalUserResponse: AxiosResponse<IOneInternalUserResponse> = yield call(
      backendApi.createInternalUser,
      internalUserData,
      notifications
    );
    const createdInternalUser = internalUserResponse.data.data;

    yield put(InternalUserActions.createInternalUserSuccessAction(createdInternalUser));
    yield successNotification('New user created successfully');
    yield put(InternalUserActions.resetInternalUserAction());
    yield put(push('/users'));
  } catch (e) {
    yield put(InternalUserActions.createInternalUserFailureAction(e.message));
    yield errorNotification(e.message ?? 'Failed to create a new user');
  }
}

export function* getRequestSaga(action: InternalUserActions.GetInternalUserRequestAction) {
  const backendApi = makeBackendApi();

  try {
    const internalUserResponse: AxiosResponse = yield call(backendApi.getInternalUserViaUuid, action.internalUserUuid);

    yield put(InternalUserActions.getInternalUserSuccessAction(internalUserResponse.data.data));
  } catch (e) {
    yield put(InternalUserActions.getInternalUserFailureAction(e.message));
    yield errorNotification('Failed to fetch Internal User.');
  }
}

export function* updateRequestSaga(action: InternalUserActions.UpdateInternalUserRequestAction) {
  const backendApi = makeBackendApi();
  const notifications: IEnabledNotification[] = action.notifications.map(notification => {
    return {
      code: notification.code,
      enabled: notification.enabled ?? false,
      ccToDepartmentsUuids: notification.ccToDepartmentsUuids || [],
    };
  });

  try {
    const internalUserResponse: AxiosResponse = yield call(
      backendApi.updateInternalUser,
      action.internalUserUuid,
      action.internalUser,
      notifications
    );
    const updatedInternalUser = internalUserResponse.data.data;

    yield put(InternalUserActions.updateInternalUserSuccessAction(updatedInternalUser));
    yield successNotification('Internal User updated.');
    yield put(InternalUserActions.getInternalUserRequestAction(updatedInternalUser.uuid));
  } catch (e) {
    yield put(InternalUserActions.updateInternalUserFailureAction(e.message));
    yield errorNotification('Failed to update Internal User.');
  }
}

export function* watchInternalUserSaga() {
  yield takeLatest(InternalUserActions.CREATE_REQUEST, createRequestSaga);

  yield takeLatest(InternalUserActions.GET_REQUEST, getRequestSaga);

  yield takeLatest(InternalUserActions.UPDATE_REQUEST, updateRequestSaga);
}
