import { ENetworkRequestStatus, ETravelAgentStatus } from 'services/BackendApi';
import { EInventoryStayListingItemType, EInventoryStayListingRating, IInventoryTag, IInventoryStayForCreating, IInventoryStayListingItem, IInventoryStayForSaving } from 'services/InventoryAPI';
import { IInventoryStaysDomain } from './model';
import { History } from 'history';

export const LIST_ACTIONS = {
  GET_REQUEST: 'inventoryStays/list/GET_REQUEST' as const,
  GET_SUCCESS: 'inventoryStays/list/GET_SUCCESS' as const,
  GET_FAILURE: 'inventoryStays/list/GET_FAILURE' as const,
  
  SET_FILTER_COUNTRY_CODE: 'inventoryStays/list/SET_FILTER_COUNTRY_CODE' as const,
  SET_FILTER_NAME: 'inventoryStays/list/SET_FILTER_NAME' as const,
  SET_FILTER_RATES: 'inventoryStays/list/SET_FILTER_RATES' as const,
  SET_FILTER_PAYMENT: 'inventoryStays/list/SET_FILTER_PAYMENT' as const,
  SET_FILTER_IS_ENABLED: 'inventoryStays/list/SET_FILTER_IS_ENABLED' as const,
  
  SET_LISTING_PAGE_GET: 'inventoryStays/list/SET_LISTING_PAGE_GET' as const,
  SET_PAGE: 'inventoryStays/SET_PAGE' as const,
  SET_PER_PAGE: 'inventoryStays/SET_PER_PAGE' as const,
  
  SET_SORT_DATA: 'inventoryStays/SET_SORT_DATA' as const,
  SET_SORT_BY: 'inventoryStays/SET_SORT_BY' as const,
  SET_SORT_ORDER: 'inventoryStays/SET_SORT_ORDER' as const,

  SEARCH_SUPPLIERS_REQUEST: 'inventoryStays/SEARCH_SUPPLIERS_REQUEST' as const,
  SEARCH_SUPPLIERS_SUCCESS: 'inventoryStays/SEARCH_SUPPLIERS_SUCCESS' as const,
  SEARCH_SUPPLIERS_FAILURE: 'inventoryStays/SEARCH_SUPPLIERS_FAILURE' as const,
};

export const CREATE_EDIT_ACTIONS = {
  GET_STAY_REQUEST: 'inventoryStays/createEdit/GET_STAY_REQUEST' as const,
  GET_STAY_SUCCESS: 'inventoryStays/createEdit/GET_STAY_SUCCESS' as const,
  GET_STAY_FAILURE: 'inventoryStays/createEdit/GET_STAY_FAILURE' as const,
  RESET_STAY: 'inventoryStays/createEdit/RESET_STAY' as const,

  POST_STAY_REQUEST: 'inventoryStays/createEdit/POST_STAY_REQUEST' as const,
  POST_STAY_SUCCESS: 'inventoryStays/createEdit/POST_STAY_SUCCESS' as const,
  POST_STAY_FAILURE: 'inventoryStays/createEdit/POST_STAY_FAILURE' as const,

  PUT_STAY_REQUEST: 'inventoryStays/createEdit/PUT_STAY_REQUEST' as const,
  PUT_STAY_SUCCESS: 'inventoryStays/createEdit/PUT_STAY_SUCCESS' as const,
  PUT_STAY_FAILURE: 'inventoryStays/createEdit/PUT_STAY_FAILURE' as const,

  GET_REGIONS_REQUEST: 'inventoryStays/createEdit/GET_REGIONS_REQUEST' as const,
  GET_REGIONS_SUCCESS: 'inventoryStays/createEdit/GET_REGIONS_SUCCESS' as const,
  GET_REGIONS_FAILURE: 'inventoryStays/createEdit/GET_REGIONS_FAILURE' as const,

  GET_RATINGS_REQUEST: 'inventoryStays/createEdit/GET_RATINGS_REQUEST' as const,
  GET_RATINGS_SUCCESS: 'inventoryStays/createEdit/GET_RATINGS_SUCCESS' as const,
  GET_RATINGS_FAILURE: 'inventoryStays/createEdit/GET_RATINGS_FAILURE' as const,

  GET_BEST_FOR_FILTERS_REQUEST: 'inventoryStays/createEdit/GET_BEST_FOR_FILTERS_REQUEST' as const,
  GET_BEST_FOR_FILTERS_SUCCESS: 'inventoryStays/createEdit/GET_BEST_FOR_FILTERS_SUCCESS' as const,
  GET_BEST_FOR_FILTERS_FAILURE: 'inventoryStays/createEdit/GET_BEST_FOR_FILTERS_FAILURE' as const,
  GET_AMENITY_FILTERS_REQUEST: 'inventoryStays/createEdit/GET_AMENITY_FILTERS_REQUEST' as const,
  GET_AMENITY_FILTERS_SUCCESS: 'inventoryStays/createEdit/GET_AMENITY_FILTERS_SUCCESS' as const,
  GET_AMENITY_FILTERS_FAILURE: 'inventoryStays/createEdit/GET_AMENITY_FILTERS_FAILURE' as const,
  GET_OTHER_FILTERS_REQUEST: 'inventoryStays/createEdit/GET_OTHER_FILTERS_REQUEST' as const,
  GET_OTHER_FILTERS_SUCCESS: 'inventoryStays/createEdit/GET_OTHER_FILTERS_SUCCESS' as const,
  GET_OTHER_FILTERS_FAILURE: 'inventoryStays/createEdit/GET_OTHER_FILTERS_FAILURE' as const,
};

export type ListActions = ReturnType<
  | typeof listActions.getRequestAction
  | typeof listActions.getSuccessAction
  | typeof listActions.getFailureAction
  | typeof listActions.setFilterCountryCode
  | typeof listActions.setFilterName
  | typeof listActions.setFilterRates
  | typeof listActions.setFilterPayment
  | typeof listActions.setFilterIsEnabled
  | typeof listActions.setListingPageGet
  | typeof listActions.setPaginationPage
  | typeof listActions.setPaginationItemsPerPage
  // | typeof listActions.setSortBy
  // | typeof listActions.setSortOrder
  | typeof listActions.setSortData
  | typeof listActions.searchSuppliersRequestAction
  | typeof listActions.searchSuppliersSuccessAction
  | typeof listActions.searchSuppliersFailureAction
>;

export type SearchSuppliersRequestAction = ReturnType<typeof listActions.searchSuppliersRequestAction>;

export type CreateEditActions = ReturnType<
  | typeof createEditActions.getRequestAction
  | typeof createEditActions.getSuccessAction
  | typeof createEditActions.getFailureAction
  | typeof createEditActions.resetAction
  | typeof createEditActions.postStayRequestAction
  | typeof createEditActions.postStaySuccessAction
  | typeof createEditActions.postStayFailureAction
  | typeof createEditActions.putStayRequestAction
  | typeof createEditActions.putStaySuccessAction
  | typeof createEditActions.putStayFailureAction
  | typeof createEditActions.getRatingsRequestAction
  | typeof createEditActions.getRatingsSuccessAction
  | typeof createEditActions.getRatingsFailureAction
  | typeof createEditActions.getRegionsRequestAction
  | typeof createEditActions.getRegionsSuccessAction
  | typeof createEditActions.getRegionsFailureAction
  | typeof createEditActions.getBestforFiltersRequestAction
  | typeof createEditActions.getBestforFiltersSuccessAction
  | typeof createEditActions.getBestforFiltersFailureAction
  | typeof createEditActions.getAmenityFiltersRequestAction
  | typeof createEditActions.getAmenityFiltersSuccessAction
  | typeof createEditActions.getAmenityFiltersFailureAction
  | typeof createEditActions.getOtherFiltersRequestAction
  | typeof createEditActions.getOtherFiltersSuccessAction
  | typeof createEditActions.getOtherFiltersFailureAction
>;

export type GetRatingsRequestAction = ReturnType<typeof createEditActions.getRatingsRequestAction>;
export type GetBestforFiltersRequestAction = ReturnType<typeof createEditActions.getBestforFiltersRequestAction>;
export type GetAmenityFiltersRequestAction = ReturnType<typeof createEditActions.getAmenityFiltersRequestAction>;
export type GetOtherFiltersRequestAction = ReturnType<typeof createEditActions.getOtherFiltersRequestAction>;
export type PostStayRequestAction = ReturnType<typeof createEditActions.postStayRequestAction>;
export type PutStayRequestAction = ReturnType<typeof createEditActions.putStayRequestAction>;
export type GetStayRequestAction = ReturnType<typeof createEditActions.getRequestAction>;

export const listActions = {
  getRequestAction: () => ({
    type: LIST_ACTIONS.GET_REQUEST,
  }),
  getSuccessAction: (data: IInventoryStayListingItem[], total: number) => ({
    type: LIST_ACTIONS.GET_SUCCESS,
    data,
    total,
  }),
  getFailureAction: (error: string) => ({
    type: LIST_ACTIONS.GET_FAILURE,
    error,
  }),

  setFilterCountryCode: (countryCode: string | undefined) => ({
    type: LIST_ACTIONS.SET_FILTER_COUNTRY_CODE,
    countryCode,
  }),
  setFilterName: (name: string | undefined) => ({
    type: LIST_ACTIONS.SET_FILTER_NAME,
    name,
  }),
  setFilterRates: (rates: string | undefined) => ({
    type: LIST_ACTIONS.SET_FILTER_RATES,
    rates,
  }),
  setFilterPayment: (payment: string | undefined) => ({
    type: LIST_ACTIONS.SET_FILTER_PAYMENT,
    payment,
  }),
  setFilterIsEnabled: (isEnabled: boolean | undefined) => ({
    type: LIST_ACTIONS.SET_FILTER_IS_ENABLED,
    isEnabled,
  }),

  setListingPageGet: (val: ENetworkRequestStatus) => ({
    type: LIST_ACTIONS.SET_LISTING_PAGE_GET,
    val,
  }),

  setPaginationPage: (val: number) => ({
    type: LIST_ACTIONS.SET_PAGE,
    val,
  }),
  setPaginationItemsPerPage: (val: number) => ({
    type: LIST_ACTIONS.SET_PER_PAGE,
    val,
  }),

  setSortData: (
    sortBy: IInventoryStaysDomain['listingPage']['filter']['order_by'],
    sortOrder: IInventoryStaysDomain['listingPage']['filter']['order_type']
  ) => ({
    type: LIST_ACTIONS.SET_SORT_DATA,
    sortBy,
    sortOrder,
  }),

  searchSuppliersRequestAction: () => ({
    type: LIST_ACTIONS.SEARCH_SUPPLIERS_REQUEST,
  }),
  searchSuppliersSuccessAction: (suppliers: any[]) => ({
    type: LIST_ACTIONS.SEARCH_SUPPLIERS_SUCCESS,
    suppliers,
  }),
  searchSuppliersFailureAction: (error: string) => ({
    type: LIST_ACTIONS.SEARCH_SUPPLIERS_FAILURE,
    error,
  }),
};

export const createEditActions = {
  getRequestAction: (stayUuid: string) => ({
    type: CREATE_EDIT_ACTIONS.GET_STAY_REQUEST,
    stayUuid,
  }),
  getSuccessAction: (stay: IInventoryStayListingItem) => ({
    type: CREATE_EDIT_ACTIONS.GET_STAY_SUCCESS,
    stay,
  }),
  getFailureAction: (error: string) => ({
    type: CREATE_EDIT_ACTIONS.GET_STAY_FAILURE,
    error,
  }),
  resetAction: () => ({
    type: CREATE_EDIT_ACTIONS.RESET_STAY,
  }),

  postStayRequestAction: (stay: IInventoryStayForCreating, history: History) => ({
    type: CREATE_EDIT_ACTIONS.POST_STAY_REQUEST,
    stay,
    history,
  }),
  postStaySuccessAction: () => ({
    type: CREATE_EDIT_ACTIONS.POST_STAY_SUCCESS,
  }),
  postStayFailureAction: (error: string) => ({
    type: CREATE_EDIT_ACTIONS.POST_STAY_FAILURE,
    error,
  }),

  putStayRequestAction: (stay: IInventoryStayForSaving) => ({
    type: CREATE_EDIT_ACTIONS.PUT_STAY_REQUEST,
    stay,
  }),
  putStaySuccessAction: () => ({
    type: CREATE_EDIT_ACTIONS.PUT_STAY_SUCCESS,
  }),
  putStayFailureAction: (error: string) => ({
    type: CREATE_EDIT_ACTIONS.PUT_STAY_FAILURE,
    error,
  }),

  getRegionsRequestAction: () => ({
    type: CREATE_EDIT_ACTIONS.GET_REGIONS_REQUEST,
  }),
  getRegionsSuccessAction: (data: IInventoryTag[]) => ({
    type: CREATE_EDIT_ACTIONS.GET_REGIONS_SUCCESS,
    data,
  }),
  getRegionsFailureAction: (error: string) => ({
    type: CREATE_EDIT_ACTIONS.GET_REGIONS_FAILURE,
    error,
  }),

  getRatingsRequestAction: (stayType: EInventoryStayListingItemType) => ({
    type: CREATE_EDIT_ACTIONS.GET_RATINGS_REQUEST,
    stayType,
  }),
  getRatingsSuccessAction: (data: EInventoryStayListingRating[]) => ({
    type: CREATE_EDIT_ACTIONS.GET_RATINGS_SUCCESS,
    data,
  }),
  getRatingsFailureAction: (error: string) => ({
    type: CREATE_EDIT_ACTIONS.GET_RATINGS_FAILURE,
    error,
  }),

  getBestforFiltersRequestAction: (stayType: EInventoryStayListingItemType) => ({
    type: CREATE_EDIT_ACTIONS.GET_BEST_FOR_FILTERS_REQUEST,
    stayType,
  }),
  getBestforFiltersSuccessAction: (data: any[]) => ({
    type: CREATE_EDIT_ACTIONS.GET_BEST_FOR_FILTERS_SUCCESS,
    data,
  }),
  getBestforFiltersFailureAction: (error: string) => ({
    type: CREATE_EDIT_ACTIONS.GET_BEST_FOR_FILTERS_FAILURE,
    error,
  }),

  getAmenityFiltersRequestAction: (stayType: EInventoryStayListingItemType) => ({
    type: CREATE_EDIT_ACTIONS.GET_AMENITY_FILTERS_REQUEST,
    stayType,
  }),
  getAmenityFiltersSuccessAction: (data: any[]) => ({
    type: CREATE_EDIT_ACTIONS.GET_AMENITY_FILTERS_SUCCESS,
    data,
  }),
  getAmenityFiltersFailureAction: (error: string) => ({
    type: CREATE_EDIT_ACTIONS.GET_AMENITY_FILTERS_FAILURE,
    error,
  }),

  getOtherFiltersRequestAction: (stayType: EInventoryStayListingItemType) => ({
    type: CREATE_EDIT_ACTIONS.GET_OTHER_FILTERS_REQUEST,
    stayType,
  }),
  getOtherFiltersSuccessAction: (data: any[]) => ({
    type: CREATE_EDIT_ACTIONS.GET_OTHER_FILTERS_SUCCESS,
    data,
  }),
  getOtherFiltersFailureAction: (error: string) => ({
    type: CREATE_EDIT_ACTIONS.GET_OTHER_FILTERS_FAILURE,
    error,
  }),
};

export type AllActions = ListActions | CreateEditActions;
