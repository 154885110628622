import styled from 'styled-components';

export const StyledRadio = styled.input`
  appearance: none;
  margin: 0;

  & + .surrogate:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => props.color ?? '#70d6ba'};
    display: none;
  }

  &:checked + .surrogate {
    border: 1px solid ${props => props.color ?? '#70d6ba'};
  }

  &:checked + .surrogate:after {
    display: block;
  }

  &:disabled + .surrogate {
    background: white;
  }

  // specific styling classes to ensure disabled renders properly, based on OWA-3095
  & + .surrogate.disabled:after {
    background-color: #ceccc9 !important;
  }
  &:checked + .surrogate.disabled {
    border-color: #ceccc9 !important;
  }
`;
