import { ENetworkRequestStatus } from 'services/BackendApi/types';
import * as Actions from './actions';
import { ITravelAgentDomain, initialState } from './model';
import { update } from 'utils/immutable';

export const travelAgentReducer = (
  state: ITravelAgentDomain = initialState,
  action: Actions.TravelAgentAction
): ITravelAgentDomain => {
  switch (action.type) {
    case Actions.GET_REQUEST:
      return update(state, s => {
        s.request.get = ENetworkRequestStatus.PENDING;
      });

    case Actions.GET_SUCCESS:
      return update(state, s => {
        s.request.get = ENetworkRequestStatus.SUCCESS;
        s.travelAgent = action.travelAgent;
      });

    case Actions.GET_FAILURE:
      return update(state, s => {
        s.request.get = ENetworkRequestStatus.ERROR;
      });

    case Actions.CREATE_REQUEST:
      return update(state, s => {
        s.request.create = ENetworkRequestStatus.PENDING;
      });

    case Actions.CREATE_SUCCESS:
      return update(state, s => {
        s.request.create = ENetworkRequestStatus.SUCCESS;
      });

    case Actions.CREATE_FAILURE:
      return update(state, s => {
        s.request.create = ENetworkRequestStatus.ERROR;
      });

    case Actions.UPDATE_REQUEST:
      return update(state, s => {
        s.request.update = ENetworkRequestStatus.PENDING;
      });

    case Actions.UPDATE_SUCCESS:
      return update(state, s => {
        s.request.update = ENetworkRequestStatus.SUCCESS;
        s.travelAgent = action.travelAgent;
      });

    case Actions.UPDATE_FAILURE:
      return update(state, s => {
        s.request.update = ENetworkRequestStatus.ERROR;
      });

    case Actions.INIT_TA_SAVE_STATUS:
      return update(state, s => {
        s.request.create = ENetworkRequestStatus.IDLE;
        s.request.update = ENetworkRequestStatus.IDLE;
      });

    case Actions.RESET_TRAVEL_AGENT:
      return update(state, s => {
        s.travelAgent = null;
      });

    case Actions.SET_COMPANY_FILTER_COUNTRY:
      return update(state, s => {
        s.taCompanies.companyCountry = action.countryCode;
      });

    case Actions.SET_COMPANY_FILTER_NAME:
      return update(state, s => {
        s.taCompanies.companyName = action.companyName;
      });

    case Actions.SET_FILTERED_COMPANIES:
      return update(state, s => {
        s.taCompanies.filteredCompanies = action.filteredCompanies;
      });

    case Actions.SET_TA_COMPANIES_MODAL_VISIBLE:
      return update(state, s => {
        s.taCompanies.taCompaniesModalVisible = action.visible;
        if (!action.visible) {
          s.taCompanies.companyCountry = null;
          s.taCompanies.companyName = '';
          s.taCompanies.filteredCompanies = null;
          s.taCompanies.selectedCompany = null;
        }
      });

    case Actions.SET_SELECTED_TA_COMPANY:
      return update(state, s => {
        s.taCompanies.selectedCompany = action.companyUuid;
      });

    default:
      return state;
  }
};

export default travelAgentReducer;
