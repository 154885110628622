import { IInternalUser, ENetworkRequestStatus } from 'services/BackendApi';

export interface IInternalUserDomain {
  internalUser: IInternalUser | null;
  request: {
    create: ENetworkRequestStatus;
    get: ENetworkRequestStatus;
  };
}

export const initialState: IInternalUserDomain = {
  internalUser: null,
  request: {
    create: ENetworkRequestStatus.IDLE,
    get: ENetworkRequestStatus.IDLE,
  },
};
