import React, { useCallback } from 'react';
import classNames from 'classnames';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { TextInput } from 'ui/TextInput';
import { CircleIconButton } from 'ui/CircleIconButton';
import { closeCustom } from 'ui/Icons';
import { ITaskReminder, TReminderChannel, TReminderOffsetUnit } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { changeTaskReminderAction, removeTaskReminderAction } from 'store/modules/taskManagement/actions';
import { useDispatch, useSelector } from 'react-redux';
import { duplicatedReminderIdsSelector } from 'store/modules/taskManagement/selectors';
import { generateStableReminderUuid } from 'store/modules/taskManagement/helpers';

const channelOptions: ISingleSelectOption[] = [{
  value: 'email',
  label: 'By email',
}];
const offsetUnitOptions: ISingleSelectOption[] = [{
  value: 'day',
  label: 'days before',
}, {
  value: 'week',
  label: 'weeks before',
}];

export interface ITaskReminderProps {
  className?: string;
  reminder: ITaskReminder;
}

export const TaskReminder: React.FC<ITaskReminderProps> = (props) => {
  const dispatch = useDispatch();
  const duplicatedReminderIds = useSelector(duplicatedReminderIdsSelector);
  
  const handleReminderRemove = useCallback(() => {
    dispatch(removeTaskReminderAction(props.reminder.id));
  }, [props.reminder.id, dispatch]);

  const handleChannelChange = useCallback((value: TReminderChannel) => {
    dispatch(changeTaskReminderAction({ uuid: props.reminder.id, channel: value }));
  }, [props.reminder.id, dispatch]);
  
  const handleOffsetUnitChange = useCallback((value: TReminderOffsetUnit) => {
    dispatch(changeTaskReminderAction({ uuid: props.reminder.id, offsetUnit: value }));
  }, [props.reminder.id, dispatch]);

  const handleOffsetChange = useCallback(e => {
    const value = parseInt(String(e.target.value).substring(0, 2), 10);
    dispatch(changeTaskReminderAction({ uuid: props.reminder.id, offset: Math.abs(value)}));
  }, [props.reminder.id, dispatch]);

  return (
    <div className={classNames(props.className, 'reminder flex items-center w-full')}>
      <SingleSelect
        fieldId="channel"
        className="channel min-w-[120px]"
        value={props.reminder.channel}
        options={channelOptions ?? []}
        onChange={handleChannelChange}
      />
      <TextInput
        type="number"
        id="offset" 
        value={Math.abs(props.reminder.offset)}
        min={0}
        max={99}
        className='ml-[20px]'
        inputClassName="offset font-pt-sans text-[15px] text-black border-gray-17 focus:border-teal-80 focus:border-2 focus:py-[6px] mt-0 w-[67px]"
        onChange={handleOffsetChange}
        errorMessage={duplicatedReminderIds.includes(generateStableReminderUuid(props.reminder)) ? '' : null}
      />
      <SingleSelect
        fieldId="offsetUnit"
        className="offset-unit flex-1 ml-[6px] min-w-[120px]"
        value={props.reminder.offsetUnit}
        options={offsetUnitOptions ?? []}
        onChange={handleOffsetUnitChange}
      />
      <CircleIconButton
        type="secondary"
        className="delete-button ml-[16px] min-w-[20px] max-w-[20px] h-[20px]"
        iconClass="inline relative left-0 top-0 w-[10px] h-[10px]"
        iconSrc={closeCustom}
        onClick={handleReminderRemove}
      />
    </div>
  )
};
