import { useCallback, useState, useEffect, RefObject } from 'react'

export function usePressed(ref: RefObject<HTMLElement>): boolean {
  const [pressed, setPressed] = useState<boolean>(false)

  const handlePressed = useCallback(() => setPressed(true), []);
  const handleNotPressed = useCallback(() => setPressed(false), []);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('pointerdown', handlePressed);
      node.addEventListener('pointerup', handleNotPressed);
      node.addEventListener('pointerout', handleNotPressed);

      return () => {
        node.removeEventListener('pointerdown', handlePressed);
        node.removeEventListener('pointerup', handleNotPressed);
        node.removeEventListener('pointerout', handleNotPressed);
      };
    }
  }, [handleNotPressed, handlePressed, pressed, ref])

  return pressed;
}
