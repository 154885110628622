import { IHotel, BookingBuilder } from 'services/BackendApi';
import { getDefaultSearchAndBookingStartDate, getDefaultSearchAndBookingEndDate } from '../../utils';

export const makeBookingBuilderStub = (hotelResponse): BookingBuilder => {
  const hotel: IHotel = hotelResponse.data.data;

  return {
    response: {
      displayTotals: {
        blocks: [],
        appliedOfferNames: [],
        totals: {
          oneOrMoreItemsOnRequest: false,
          totalForPricedItemsCents: 0,
          totalBeforeDiscountForPricedItemsCents: 0,
          totalForPricedItems: '0.00',
          totalBeforeDiscountForPricedItems: '0.00',
          total: null,
          totalBeforeDiscount: null,
        },
      },
      availableToHold: false,
      mealPlanSubProductSelections: [],
      canBeBooked: false,
      mustStop: true,
      errors: [],
      currency: hotel.defaultCurrency,
      bookingHash: undefined,
      globalRoomCancellationPolicyText: null,
      isRefundable: null,
      potentialBooking: {
        Accommodation: [],
        Supplement: [],
        Transfer: [],
        'Ground Service': [],
        Fine: [],
      },
      availableProductSets: {
        Accommodation: [],
        Supplement: [],
        Transfer: [],
        'Ground Service': [],
        Fine: [],
      },
      textOnlyOffersPerLodging: [],
      appliedOfferNames: [],
      uploads: [],
      hotel,
      totals: {
        oneOrMoreItemsOnRequest: false,
        totalForPricedItemsCents: 0,
        totalBeforeDiscountForPricedItemsCents: 0,
        totalForPricedItems: '0.00',
        totalBeforeDiscountForPricedItems: '0.00',
        total: '0.00',
        totalBeforeDiscount: '0.00',
      },
      minimumNightsReview: false,
      availableToInstantBook: false,
      expenseInfosGroupedByRoom: [],
    },
    request: {
      startDate: getDefaultSearchAndBookingStartDate(),
      endDate: getDefaultSearchAndBookingEndDate(),
      guestAges: {
        numberOfAdults: 0,
        agesOfAllChildren: [],
      },
      hotelUuid: hotel.uuid,
      Accommodation: [],
      Transfer: [],
      'Ground Service': [],
      Fine: [],
      Supplement: [],
      customItems: [],
    },
    bookingErrors: [],
  };
};

const makeStubAggregateRecord = (title: string) => ({
  title,
  quantity: 0,
  oneOrMoreItemsOnRequest: false,
  totalForPricedItemsCents: 0,
  totalBeforeDiscountForPricedItemsCents: 0,
  totalForPricedItems: '0.00',
  totalBeforeDiscountForPricedItems: '0.00',
  total: '0.00',
  totalBeforeDiscount: '0.00',
  offers: [],
  drilldown: [],
});

export const totalCentsAsString = (total: number): string => (total / 100).toFixed(2);
