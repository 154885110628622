import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';
import { AncillaryTransfersImporterContainer } from './importer';

export interface AncillaryTransfersRoutingProps extends RouteComponentProps {}

export const AncillaryTransfersRouting = (props: AncillaryTransfersRoutingProps) => {
  return (
    <Switch>
      <Route path={[`${props.match.path}/`, `${props.match.path}`]} component={AncillaryTransfersImporterContainer} />
    </Switch>
  );
};

export const RateBreakRouting = compose(withRouter)(AncillaryTransfersRouting);
