import React from 'react';
import { IContentEntity } from 'services/BackendApi';
import * as Inputs from '../HotelAdmin/components/Inputs';
import FluidButton from 'ui/FluidButton';

interface IContentEntityDetailsTabProps {
  contentEntity: Partial<IContentEntity>;
  onUpdate: (field: keyof IContentEntity, value: any) => void;
  onCta: () => void;
  ctaLabel: string;
  mode: 'create' | 'edit';
}

export const ContentEntityDetailsTab = (props: IContentEntityDetailsTabProps) => {
  return (
    <div className="font-pt-sans flex flex-col space-y-4 w-full">
      <Inputs.Input
        label={'Title'}
        value={props.contentEntity.title || ''}
        onChange={val => props.onUpdate('title', val)}
      />

      <Inputs.Input
        label={'Subtitle'}
        value={props.contentEntity.subtitle || ''}
        onChange={val => props.onUpdate('subtitle', val)}
      />

      <Inputs.Dropdown
        label={'Content Type'}
        values={props.contentEntity.type ? [props.contentEntity.type] : []}
        options={[
          {
            value: 'latest-offer',
            label: 'Latest Offer',
          },
          {
            value: 'page',
            label: 'Page',
          },
        ]}
        onChange={sv => {
          if (sv.length <= 0) {
            props.onUpdate('type', null);
          } else {
            props.onUpdate('type', sv[0]);
          }
        }}
        multiselectProps={{
          isSingleSelectMode: true,
          isCloseOnSelect: true,
          hideCheckboxes: true,
        }}
      />

      <Inputs.RichTextEditor
        label="Body"
        value={props.contentEntity.body || ''}
        onChange={value => props.onUpdate('body', value)}
      />

      <Inputs.Input
        label={'CTA Text'}
        value={props.contentEntity.ctaText || ''}
        onChange={val => props.onUpdate('ctaText', val)}
      />

      <Inputs.Input
        label={'CTA URL'}
        value={props.contentEntity.ctaUrl || ''}
        onChange={val => props.onUpdate('ctaUrl', val)}
      />

      {props.mode === 'create' && (
        <>
          <span className="bg-gray-20 p-2">You can upload images after creating the content item.</span>
        </>
      )}

      <FluidButton
        type="primary"
        className="w-[200px] self-end"
        onClick={() => {
          props.onCta();
        }}
      >
        {props.ctaLabel}
      </FluidButton>
    </div>
  );
};
