import styled from 'styled-components';
import TextInput from 'pureUi/TextInput';
import DateRangeInput from 'pureUi/DateRangeInput';
import { theme } from '../../../tailwind.config';

// @ts-ignore
export const StyledTextInput = styled(TextInput)`
  height: 35px;

  & > input {
    color: #413e3b;
    background: #fbfbfa;
    font-family: 'PT Sans';
    font-size: 16px;
    border: 1px solid #d5d2d1;
    text-transform: none;
  }
`;

export const StyledDateRangeInput = styled(DateRangeInput)`
  min-width: 183px;
  .pseudoSelect {
    height: 35px;
    ${props => props.invalid
      ? `border: ${theme.colors['red-95']} 1px solid;
         background-color: ${theme.colors['red-25']};`
      : ''
    }
  }
`;
