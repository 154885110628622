import { call, takeLeading, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  makeCognitoApi,
  ITokenSetResponse,
  ITokenSet,
} from 'services/CognitoApi';

import {
  makeBackendApiWithoutHeaders,
} from 'services/BackendApi';

import {
  saveCognitoTokensWithoutRememberMe,
} from 'services/tokenLocalStorage';

import {
  CognitoFlowRequestAction,
  COGNITO_FLOW_REQUEST,
  cognitoFlowSuccessAction,
  cognitoFlowFailureAction,
} from './actions';

import {
  AUTH_REQUEST,
  AUTH_LOG_IN,
  bindPersistUser,
} from '../auth/actions';

import {
  successAction,
} from '../../common/actions';

import { bootstrapDynamicParametersSaga } from '../bootstrap/sagas/bootstrapAppSaga';

import { EUserType } from 'services/BackendApi';
import { getDynamicParametersSelector } from 'store/modules/bootstrap/selectors';

export function* cognitoFlowSaga(action: CognitoFlowRequestAction) {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  if (!code) {
    return;
  }
  
  yield call(bootstrapDynamicParametersSaga);
  
  const cognitoApi = makeCognitoApi();
  const tokensResponse: AxiosResponse<ITokenSetResponse> = yield call(
    cognitoApi.getTokens,
    code
  );
  const tokens : ITokenSet = {
    accessToken: tokensResponse.data.access_token,
    idToken: tokensResponse.data.id_token,
    refreshToken: tokensResponse.data.refresh_token,
  };

  const backendApi = makeBackendApiWithoutHeaders();
  try {
    yield call(saveCognitoTokensWithoutRememberMe, tokens);
    const welcomeResponse: AxiosResponse = yield call(
      backendApi.callWelcome,
      tokens
    );
    if (welcomeResponse.status === 200) {
      // These actions were being called from 'Actions" in legacy, not from 'saga' like here.
      yield call(bindPersistUser(welcomeResponse.data.data));
      yield put(successAction(AUTH_REQUEST, { user: { ...welcomeResponse.data.data } }));
      yield put(successAction(AUTH_LOG_IN, { user: { ...welcomeResponse.data.data } }));
      yield put(cognitoFlowSuccessAction(welcomeResponse.status));
      // Redirect to homepage by default, to specific if only one option
      const dynamicParameters = yield select(getDynamicParametersSelector);
      const userType = welcomeResponse.data.data.type;
      if (userType === EUserType.RL) {
        window.location.replace(`${dynamicParameters.ADMIN_BASE_URL}/hotels`);
      } else if (userType === EUserType.TA) {
        window.location.replace('/filters');
        //yield call(action.history.push, '/filters');
      } else {
        yield call(action.history.push, '/');
      }
    }
  } catch (e) {
    yield put(cognitoFlowFailureAction(e?.response?.status, e?.response?.data?.data?.message ?? ''));
    // Redirect to login error page
    yield call(action.history.push, '/login-error');
  }
}

export function* watchCognitoSaga() {
  yield takeLeading([ COGNITO_FLOW_REQUEST ], cognitoFlowSaga);
}
