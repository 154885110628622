import { TCurrencyCode } from 'interfaces';
import { BookingBuilderResponse, ENetworkRequestStatus, IProduct } from 'services/BackendApi';

export interface IHAPFloorPlan {
  createdAt: string;
  deletedAt: string | null;
  displayName: string;
  filename: string;
  ownerType: string;
  ownerUuid: string;
  size: string;
  tag: string;
  updatedAt: string;
  url: string;
  uuid: string;
}

export interface IHAPAccommodationInformation extends IHAPFloorPlan {}

export interface IHAPStaticRateResponseObjectResult {
  ageSet: {
    numberOfAdults: number;
    agesOfAllChildren: number[];
  };
  build: BookingBuilderResponse;
  isStandardOccupancy: boolean;
}

export interface IHAPProduct extends IProduct<any> {
  vimeoVideoId: string | null;
  meta: {
    description: string;
    size: number;
    amenities: string[];
    categoryType: string;
    moreInformation?: string;
  };
}
export interface IHAPStaticRateResponseObject {
  floorPlan: IHAPFloorPlan | undefined;
  accommodationInformation: IHAPAccommodationInformation | undefined;
  product: IHAPProduct;
  results: IHAPStaticRateResponseObjectResult[];
}

export interface IHAPFormattedAccommodationProductStaticRate {
  uuid: string;
  guestAges: IHAPStaticRateResponseObjectResult['ageSet'];
  totals: BookingBuilderResponse['totals'];
  totalGuests: number;
  isStandardOccupancy: boolean;
  isDisabled: boolean;
  defaultMealPlan: { uuid: string }[];
  dates: string[];
  datesCount: number;
  appliedOfferNames: string[];
  potentialBooking: BookingBuilderResponse['potentialBooking'];
  globalRoomCancellationPolicyText: BookingBuilderResponse['globalRoomCancellationPolicyText'];
  isRefundable: BookingBuilderResponse['isRefundable'];
  canBeBooked: boolean;
  availableToInstantBook: boolean;
  expenseInfosGroupedByRoom: any[];
  rateUuid: string;
}

// src/store/modules/hotelAccommodationProducts/reducer.ts converts raw response objects
// into these "easier to manage"
export interface IHAPFormattedAccommodationProduct {
  uuid: string;
  title: string;
  description: string;
  amenities: string[];
  moreInformation?: string;
  size: number;
  ages: any;
  occupancy: any;
  categoryType: string;
  vimeoVideoId: string | null;
  floorPlans: IHAPStaticRateResponseObject['floorPlan'][];
  accommodationInformation: IHAPStaticRateResponseObject['accommodationInformation'][];
  category: string;
  staticRates: IHAPFormattedAccommodationProductStaticRate[];
}

export interface IHAPFormattedAccommodationProductWithLiveRates extends IHAPFormattedAccommodationProduct {
  liveRates: any;
}

export interface IHAPFormattedAccommodationProductWithLiveRatesWithBookingComRates
  extends IHAPFormattedAccommodationProductWithLiveRates {
  bookingComRate?: {
    accommodationUuid: string;
    finalPriceCents: number;
    mealPlanCode: string;
    cancellationPolicy: string;
  };
}

export interface IHotelAccommodationProductsDomain {
  formattedAccommodationProducts: IHAPFormattedAccommodationProduct[];
  liveRatesData: any[];
  bookingComRatesData: any[];
  loadStatus: ENetworkRequestStatus;
  liveRatesCurrency: TCurrencyCode | null;
  error: string | null;
  isLiveRateLoadingError: boolean;
}

export const initialState: IHotelAccommodationProductsDomain = {
  formattedAccommodationProducts: [],
  liveRatesData: [],
  bookingComRatesData: [],
  liveRatesCurrency: null,
  loadStatus: ENetworkRequestStatus.IDLE,
  error: null,
  isLiveRateLoadingError: false,
};
