import { TCountryCode, TCurrencyCode } from 'interfaces';
import { IUpload } from 'store/modules/bookingManager/sagas/bookingTransforms/BookingModel';
import { IAgeName, IOccupancy, IOffer } from './OfferResponse';
import { IUploadFileInfo } from './UploadResponse';
import { IBootstrapCountry } from 'store/modules/bootstrap/model';

export interface ISeasonDate {
  uuid?: string;
  createdAt?: string;
  seasonUuid?: string;
  updatedAt?: string;
  validFrom: string; // "2024-01-01"
  validTo: string;
}
export interface ISeason {
  createdAt: string;
  hotelUuid: string;
  name: string;
  seasonDates: ISeasonDate[];
  seasonalProductRates: any[];
  updatedAt: string;
  uploads: any[];
  uuid: string;
}
export interface IHotel {
  liveRatesForTAs: boolean;
  uuid: string;
  name: string;
  description: string;
  email: string;
  phoneNumber: string;
  address: string;
  countryCode: string;
  region: string;
  starRating: string;
  defaultCurrency: TCurrencyCode;
  amenities: string[];
  highlights: string[];
  additionalInfo: string | null;
  policiesAndRestrictions: string[] | null;
  suitableForHoneymooners: boolean;
  preferred: boolean;
  overview: string[];
  filters: string[];
  inLoveWith: string | null;
  createdAt: string;
  updatedAt: string;
  hotelId: string;
  enabled: boolean;
  disabledForTa: boolean;
  reservationEmails: string;

  // Add associations here as optional properties
  accommodationProducts?: IAccommodationProduct[];
  fineProducts?: IProduct<{}>[];
  transferProducts?: IProduct<ITransferProductOptions>[];
  groundServiceProducts?: IProduct<IGroundServiceProductOptions>[];
  mealPlanProducts?: IProduct<IMealPlanProductOptions>[];
  supplementProducts?: IProduct<any>[];
  offers?: IOffer<any>[];
  uploads?: IUploadFileInfo[];

  seasons: ISeason[];

  externalSystem?: string | null;
  externalHotelId?: string | null;
  externalSupplierId?: string | null;
  externalCurrency?: TCurrencyCode | null;
  externalCurrencyExchangeRate?: string | null;

  fuelCharge: string | null;
  location: string | null;
}

export interface IHotelResponse {
  data: IHotel;
}

export interface ISeasonalProductAddonRate {
  uuid: string;
  seasonalProductRateUuid: string;
  productUuid: string;
  isOnRequest: boolean;
  rate: string;
  markupType: 'flat' | 'percentage';
  markupAmount: string;
  alternateRates: {
    [key: string]: {
      markupAmount: string;
      markupType: 'flat' | 'percentage';
      rate: string;
    };
  };
  createdAt: string;
  updatedAt: string;
  product: {
    uuid: string;
    name: string;
    type: string;
    category: string;
    options: {
      isGenericProduct: true;
      agesDefinedBy: 'Accommodation';
      genericIdentifier: 'extraPerson';
    };
    meta: {};
    ownerType: null;
    ownerUuid: null;
    externalProductId: null;
    externalMarkupType: null;
    externalMarkupAmount: null;
    vimeoVideoId: null;
    createdAt: '2020-04-23T09:36:24.651Z';
    updatedAt: '2020-04-23T09:36:24.651Z';
  };
  uploads: [];
}

export interface ISeasonalProductRate {
  uuid: string;
  seasonUuid: string;
  productUuid: string;
  isOnRequest: boolean;
  rate: string;
  markupType: 'flat' | 'percentage';
  markupAmount: string;
  alternateRates: any | null;
  internalDescription: null;
  minimumAdvance: number;
  minimumNights: number;
  paymentTerms: string;
  daysToArrivalPayablePercentage: string;
  cancellationType: any | null;
  manualCancellationPolicy: string;
  cancellationPolicy: string;
  createdAt: string;
  updatedAt: string;

  // allCountries: boolean;

  seasonalProductAddonRates?: ISeasonalProductAddonRate[];
  countries?: IBootstrapCountry[];
  uploads?: any[];
}

export interface IAccommodationProduct extends IProduct<IAccomodationProductOptions> {
  seasonalProductRates: ISeasonalProductRate[];
  meta: {
    categoryType: string;
    amenities: string[];
    description: string;
    moreInformation: string;
    size: number;
  };
}

export enum EMealPlanCategory {
  RO = 'RO',
  BB = 'BB',
  HB = 'HB',
  FB = 'FB',
  AI = 'AI',
}

export interface IMealPlanProduct extends IProduct<IMealPlanProductOptions> {
  meta: {
    categoryType: EMealPlanCategory;
    description: string;
  };
}

export interface ITransferProduct extends IProduct<ITransferProductOptions> {}

export interface ISupplementProduct extends IProduct<any> {}

export interface IFineProduct extends IProduct<any> {}

export interface IGroundServiceProduct extends IProduct<IGroundServiceProductOptions> {}

export interface IProduct<T> {
  uuid: string;
  name: string;
  type: string;
  category: string;
  options: T;
  meta: any;
  ownerType: string;
  ownerUuid: string;
  createdAt: string;
  updatedAt: string;

  externalMarkupAmount: string | null;
  externalMarkupType: 'flat' | 'percentage' | null;
  externalProductId: string | null;
}

export interface ITransferProductOptions {
  isOneWay: boolean;
  capacity?: number;
  ages?: IAgeName[];
}

export interface IMealPlanProductOptions {
  ages: IAgeName[];
}

export interface IAccomodationProductOptions {
  ages: IAgeName[];
  occupancy: IOccupancy;
  mealPlanToUseStandardOccupancyMinimum?: boolean;
}

export interface IHotelAccommodationsSearchRequest {
  startDate: string;
  endDate: string;
  numberOfAdults: number;
  agesOfAllChildren: number[];
  hotelUuid: string;
}

export interface IGroundServiceProductOptions {
  ages: IAgeName[];
}

export interface IHotelResponse {
  data: IHotel;
  meta: {
    type: string;
    id: string;
    associations: string[];
  };
}

export interface ISupplementOptions {
  ages: IAgeName[];
}
