import { rootSagaFactory } from '../importer/sagas';
import { EImportEntity } from '../importer/model';

import { staticRatesAvailabilityImportDomainSelector } from './selectors';

export default rootSagaFactory({
  entity: EImportEntity.STATIC_RATES_AVAILABILITY,
  domainSelector: staticRatesAvailabilityImportDomainSelector,
  importApiCall: backendApi => backendApi.importStaticRatesAvailability(),
  importStatusApiCall: backendApi => backendApi.getStaticRatesAvailabilityImportStatus(),
});
