import * as BookingBuilderUtils from 'utils/bookingBuilder';
import { saveAs } from 'file-saver';
import { enquiryCreatedNotificationMessage } from './common';
import { EPdfLogo } from 'store/modules/bookingManager/subdomains/breakdown/model';
import { BookingStatusTypes } from 'config/enums';

export const handleSaveBooking = async ({
  bookingDomain,
  backendApi,
  clientCountryCode,
  selectedCompanyMembership,
  setShouldShowLeaveAlert,
  setRedirectLocation,
}) => {
  const attr = BookingBuilderUtils.getBookingsEndpointAttributesForBookingDomain({ bookingDomain });

  try {
    const booking = await backendApi.createBooking({
      bookingAttributes: attr,
      clientCountryCode,
      selectedCompanyMembership,
    });
    setShouldShowLeaveAlert(false);
    setRedirectLocation(`/booking-manager/${booking.uuid}`);
  } catch (e) {
    throw new Error(e);
  }
};

export const handleDownloadQuote = async props => {
  const {
    bookingDomain,
    backendApi,
    bookingManagerApi,
    enqueueNotification,
    clientCountryCode,
    quoteMrginLogoOption,
    selectedCompanyMembership,
  } = props;

  try {
    const attr = BookingBuilderUtils.getBookingsEndpointAttributesForBookingDomain({ bookingDomain });

    const booking = await backendApi.createBooking({
      bookingAttributes: attr,
      clientCountryCode,
      selectedCompanyMembership,
    });

    enqueueNotification({
      message: enquiryCreatedNotificationMessage(booking),
      options: { variant: 'success' },
    });

    let upload = { url: '', filename: '' };
    if (quoteMrginLogoOption === EPdfLogo.MAIN_COMPANY_LOGO || quoteMrginLogoOption === null) {
      const { data } = await bookingManagerApi.generateBreakdownPdfForTa(booking.uuid);
      upload = data;
    } else {
      const { data } = await bookingManagerApi.generateBreakdownPdfForClient(booking.uuid, quoteMrginLogoOption);
      upload = data;
    }

    saveAs(upload.url, upload.filename);
  } catch (err) {
    enqueueNotification({
      message: 'Failed to generate a quote to download',
      options: { variant: 'error' },
    });

    throw err;
  }
};

export const handleRequestBooking = async ({
  bookingDomain,
  backendApi,
  liveRatePricesModalData = false,
  clientCountryCode,
  selectedCompanyMembership,
  isInstantBook = false,
  bbv1AddBookingError,
  bbv1ClearBookingErrors,
  setShouldShowLeaveAlert,
  setRedirectLocation,
  paymentMethod,
}) => {
  const attr = {
    ...BookingBuilderUtils.getBookingsEndpointAttributesForBookingDomain({
      bookingDomain,
      bookingStatus: BookingStatusTypes.REQUESTED,
      paymentMethod,
    }),
    isInstantBook,
  };

  // merge new prices coming from priceCheck endpoint
  const bookingAttributes = !liveRatePricesModalData
    ? attr
    : {
        ...attr,
        bookingBuild: {
          ...attr.bookingBuild,
          Accommodation: attr.bookingBuild!.Accommodation.map((acc, index) => ({
            ...acc,
            liveRate: {
              ...acc.liveRate,
              amount: liveRatePricesModalData[index].newPrice ?? liveRatePricesModalData[index].prevPrice,
            },
          })),
        },
      };

  try {
    bbv1ClearBookingErrors();
    const booking = await backendApi.createBooking({
      bookingAttributes,
      clientCountryCode,
      selectedCompanyMembership,
    });

    setShouldShowLeaveAlert(false);
    setRedirectLocation(`/booking-manager/${booking.uuid}`);
  } catch (e) {
    e.response.data.errors?.map(error => bbv1AddBookingError(error));
    throw e;
  }
};
