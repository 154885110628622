import React from 'react';
import { EUserType } from 'services/BackendApi';
import { RouteProps, Route, Redirect } from 'react-router';
import { withAuthentication } from 'hoc';
import { COGNITO_ENABLE_FLOW, REDIRECT_COGNITO_URI_IF_LOCALHOST } from 'config';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { makeCognitoApi } from 'services/CognitoApi';

export const shouldRedirect = (props: AuthRouteOwnProps) => {
  const { allow, deny, isAuthenticated } = props;
  const role = props.role as EUserType;

  return !isAuthenticated
    || allow && !allow.includes(role)
    || deny && deny.includes(role)
    || false;

};

export const AuthRoute = (props: AuthRouteProps) => {
  const { render, component: Component, children, ...rest } = props;
  const { dynamicParameters } = useDynamicParameters();
  
  const authRender = renderProps => {
    const { history } = renderProps;

    if(shouldRedirect(props)) {
      if (COGNITO_ENABLE_FLOW) {
        // Logout in AWS so this browser/device won't be able to use AWS cookie anymore
        const cognitoApi = makeCognitoApi();
        cognitoApi.logout(); // not async
        // Don't redirect as AWS will redirect browser anyway and sinchronously
        return;
      } else {
        return <Redirect to={(history.length > 2 ? '/' : '/login')} />;
      }
    }

    if(render){
      return render(renderProps);
    }

    if(Component){
      return <Component {...renderProps} />;
    }

    return children;
  };

  // @ts-ignore
  return <Route render={authRender} {...rest}/>;
};

// -----------------------------------------------------------------------------
// Prop Typings
// -----------------------------------------------------------------------------

interface AuthRouteOwnProps {
  allow?: EUserType[];
  deny?:  EUserType[];
  
  isAuthenticated: boolean;
  role?: string | null;
}

export interface AuthRouteProps extends AuthRouteOwnProps, RouteProps { 
}

const AuthRouteConnected = withAuthentication(AuthRoute);
export default AuthRouteConnected;