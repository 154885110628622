import React, { useCallback } from 'react';
import { HidingTooltip } from 'ui/Tooltip';

export interface TooltipProps {
  content: string;
  children?: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = props => {
  const render = useCallback(
    () => <span className="block text-black text-13px text-center p-1">{props.content}</span>,
    [props.content]
  );

  return (
    <HidingTooltip renderTooltipContent={render} position="bottom">
      {props.children}
    </HidingTooltip>
  );
};
