import { takeLatest, select, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ISearchQuery, NameSearchResponseData, HotelNameResponse } from 'services/BackendApi';
import qs from 'qs';
import * as Actions from '../actions';
import * as Selectors from '../selectors';

import * as FastSearchActions from 'store/modules/fastSearch/actions';
import * as AgentsActions from 'store/modules/agents/actions';

import * as AuthSelectors from 'store/modules/auth/selectors';

export function* executeSaga(action: Actions.ExecuteAction) {
  try {
    const searchQuery: ISearchQuery = yield select(Selectors.offersQuerySelector);
    yield put(FastSearchActions.populateQueryAction(searchQuery));

    // we also need to update the agents selected company membership with this
    // search ones, as they may have updated it
    const tcm = yield select(Selectors.travelCompanyMembershipSelector);
    yield put(AgentsActions.setSelectedCompanyMembershipAction(tcm));

    const isSR = yield select(AuthSelectors.isInternalUser);
    if (isSR) {
      const selectedCompany = yield select(Selectors.selectedCompanySelector);
      yield put(AgentsActions.selectedCompanyChangeAction(selectedCompany));

      const selectedTA = yield select(Selectors.selectedTaSelector);
      yield put(AgentsActions.selectedTaChangeAction(selectedTA));
    }

    const hotel: HotelNameResponse | null = yield select(Selectors.hotelSelector);

    const page = hotel ? `/hotels/${hotel.uuid}` : '/search/beta';
    yield put(push(`${page}?${qs.stringify(searchQuery)}`));
  } catch (e) {
    console.log(e);
  }
}

export function* watchExecute() {
  yield takeLatest(Actions.EXECUTE, executeSaga);
}
