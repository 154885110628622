
import { call, select, takeLatest, put } from 'redux-saga/effects';
import { makeBackendApi } from 'services/BackendApi';
import { 
  DELETE_COMPANY_DEPARTMENT_REQUEST,
  DeleteCompanyDepartmentRequestAction,
  deleteCompanyDepartmentFailureAction,
  deleteCompanyDepartmentSuccessAction,
  fetchCompanyDepartmentsRequestAction,
  setUuidToDeleteAction,
} from '../actions';
import { enqueueNotification } from 'store/modules/ui';
import { companyDataSelector } from 'store/modules/companyInfo';

function* deleteDepartmentSaga(action: DeleteCompanyDepartmentRequestAction) {
  try {
    const backendApi = makeBackendApi();
    yield call(
      backendApi.deleteCompanyDepartment,
      action.departmentUuid,
    );

    yield put(deleteCompanyDepartmentSuccessAction());
    yield put(setUuidToDeleteAction(null));
    const company = yield select(companyDataSelector);
    yield put(fetchCompanyDepartmentsRequestAction(company.uuid));
    yield put(enqueueNotification({
      message: 'Company department deleted successfully.',
      options: { variant: 'success' },
    }));
  } catch (e) {
    yield put(deleteCompanyDepartmentFailureAction(e));
    yield put(setUuidToDeleteAction(null));
    yield put(enqueueNotification({
      message: 'There was an error deleting company department',
      options: { variant: 'error' },
    }));
  }
}

export function* watchDeleteDepartmentSaga() {
  yield takeLatest(
    [DELETE_COMPANY_DEPARTMENT_REQUEST],
    deleteDepartmentSaga
  );
}
