import { IBasicUser } from './model';

export const SET_AUTHORIZED_USER = 'user/SET_AUTHORIZED_USER';
export const CLEAR_AUTHORIZED_USER = 'user/CLEAR_AUTHORIZED_USER';

export type SetAuthorizedUserAction = ReturnType<typeof setAuthorizedUserAction>;
export const setAuthorizedUserAction = (user: IBasicUser) => ({
  type: SET_AUTHORIZED_USER as typeof SET_AUTHORIZED_USER,
  user,
});

export type ClearAuthorizedUserAction = ReturnType<typeof clearAuthorizedUserAction>;
export const clearAuthorizedUserAction = () => ({
  type: CLEAR_AUTHORIZED_USER as typeof CLEAR_AUTHORIZED_USER,
});

export type UserAction =
  | SetAuthorizedUserAction
  | ClearAuthorizedUserAction;
