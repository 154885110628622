export enum EUserType {
  TA = 'ta',
  SR = 'sr',
  ADMIN = 'admin',
  RL = 'rl',
  FINANCE = 'finance',
}

export interface IUser {
  uuid: string;
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  phoneNumber: string;
  mobileNumber: string;
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  status: string;
  isExistingPartner: boolean;
  agreeToTerms: boolean;
  type: string;
  receiveEmailAlerts: boolean;
  companyUuid: string;
  createdAt: string;
  updatedAt: string;
  taCanReadFinance: boolean;
}