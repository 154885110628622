import styled from 'styled-components';

import Header from 'containers/Header';

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: ${props => (props.isAppVersionDeprecated ? '100px' : '0')};
`;

export const LayoutHeader = styled(Header)`
  top: ${props => (props.isAppVersionDeprecated ? '100px' : '0')}
    ${props => props.theme.breakpoints.desktop`
    top: 0;
  `};
`;

export const LayoutChildren = styled.div`
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const LayoutContent = styled.div`
  flex: 1;
`;
