import { call, takeLatest, debounce, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import {
  makeBackendApi,
  ICompaniesResponse,
  ICsvResponse
} from 'services/BackendApi';
import { enqueueNotification } from 'store/modules/ui';

import {
  ICompanyListFilter,
  ICompanyListPagination
} from './model';

import {
  GET_REQUEST,
  SET_SEARCH_FILTER,
  SET_COUNTRY_CODE_FILTER,
  SET_PAGE,
  SET_PER_PAGE,
  TRAVEL_COMPANY_CSV_REQUEST,
  getSuccessAction,
  getFailureAction,
  travelCompanyCsvRequestActionSuccess,
  travelCompanyCsvRequestActionError,
} from './actions';

import {
  filterSelector,
  paginationSelector
} from './selectors';

export function* getRequestSaga() {
  const backendApi = makeBackendApi();

  const f: ICompanyListFilter = yield select(filterSelector);
  const p: ICompanyListPagination = yield select(paginationSelector);

  const query = {
    'page[offset]': p.page * p.perPage,
    'page[limit]': p.perPage,
    'filter[company][name:ilike]': f.search ? f.search : undefined,
    'filter[company][countryCode]': f.countryCode ? f.countryCode : undefined
  };

  try {
    const res: AxiosResponse<ICompaniesResponse> = yield call(
      backendApi.getCompanies,
      query
    );

    yield put(getSuccessAction(res.data.data, res.data.meta.total));
  } catch (e) {
    yield put(getFailureAction(e.message));
  }
}

function *successNotification(message: string) {
  yield put(
    enqueueNotification({ message, options: { variant: 'success' }})
  );
}

function *errorNotification(message: string) {
  yield put(
    enqueueNotification({ message, options: { variant: 'error' }})
  );
}
export function* getTravelCompaniesCsv() {
  const backendApi = makeBackendApi();
  const filter: ICompanyListFilter = yield select(filterSelector);

  try {
    const res: AxiosResponse<ICsvResponse> = yield call(backendApi.getTravelCompaniesCsv, filter);
    saveAs(res.data.data.signedUrl);

    yield put(travelCompanyCsvRequestActionSuccess());
    yield successNotification('CSV created and downloaded.');
  } catch (e) {
    yield put(travelCompanyCsvRequestActionError()); // Use e.message to show error
    yield errorNotification('Failed to download CSV.');
  }
}

export function* watchCompanyListSaga() {
  yield takeLatest([
    GET_REQUEST,
    SET_COUNTRY_CODE_FILTER,
  ], getRequestSaga);

  yield debounce(200, [
    SET_SEARCH_FILTER,
    SET_PAGE,
    SET_PER_PAGE,
  ], getRequestSaga);

  yield takeLatest([
    TRAVEL_COMPANY_CSV_REQUEST,
  ], getTravelCompaniesCsv)
}
