import React, { useMemo, useCallback } from 'react';

import { HidingTooltip } from 'ui/Tooltip';
import InfoIcon from 'ui/Icons/Info.component.svg';
import UsdIcon from 'ui/Icons/currency.usd.component.svg';
import EurIcon from 'ui/Icons/currency.eur.component.svg';
import { TCurrencyCode } from 'interfaces';
import { formatPriceCents, getCurrencySymbol } from 'utils';
import PiggyBankIcon from 'ui/Icons/piggyBank.component.svg';
import { theme } from '../../../tailwind.config';
import { SvgIcon } from 'ui/SvgIcon';

interface IBookingLedgerHeadline {
  currency: TCurrencyCode;
  balanceOwed: number;
  depositsHeld: number;
  netBalance: number;
  accountBalance: number;
}

export const BookingLedgerHeadline: React.FC<IBookingLedgerHeadline> = React.memo(
  ({ currency, balanceOwed, depositsHeld, netBalance, accountBalance }) => {
    const formatAmount = useCallback(amount => `${getCurrencySymbol(currency)} ${formatPriceCents(amount)}`, [
      currency,
    ]);

    const renderDepositsTooltip = () => {
      return (
        <span className={'block normal-case text-black text-13px w-170px p-10px tracking-normal leading-17px'}>
          These are funds held in the booking that have not yet been transferred to{' '}
          <span className="text-brown-100">Deposit Account</span>.
        </span>
      );
    };

    const renderCurrencyIcon = useCallback(() => {
      switch (currency) {
        case 'USD':
          return <SvgIcon IconComponent={UsdIcon} className="w-30px h-30px fill-none" />;
        case 'EUR':
          return <SvgIcon IconComponent={EurIcon} className="w-30px h-30px fill-none" />;

        default:
          return null;
      }
    }, [currency]);

    return (
      <div className="booking-ledger-headline flex w-full h-20 py-10px text-black items-center mb-35px">
        <div
          className={`booking-ledger-headline-balance-owed flex flex-col pl-15px pr-30px justify-center border-r border-solid border-r-gray-20`}
        >
          <span className={'text-xs text-gray-100 tracking-2xs uppercase'}>Invoices</span>
          <span className={'text-21px text-center font-bold mt-5px'}>{formatAmount(balanceOwed)}</span>
        </div>

        <div
          className={`booking-ledger-headline-deposits-held flex flex-col px-30px justify-center border-r border-solid border-r-gray-20`}
        >
          <span className={`${'text-xs text-gray-100 tracking-2xs uppercase'} flex items-center relative`}>
            <span className="mr-1">Payments & Adjustments</span>
            <HidingTooltip renderTooltipContent={renderDepositsTooltip} position="right" tooltipClassname="mt-9">
              <span className="cursor-pointer h-4">
                <SvgIcon IconComponent={InfoIcon} className="w-18px fill-gray-80" />
              </span>
            </HidingTooltip>
          </span>
          <span className={'text-21px text-left font-bold mt-5px'}>{formatAmount(depositsHeld)}</span>
        </div>

        <div className="flex grow justify-between items-center">
          <div className="left-side">
            <div className={`booking-ledger-headline-net-balance-owed flex flex-col px-30px justify-center`}>
              <span className={'text-xs text-gray-100 tracking-2xs uppercase'}>Net Balance</span>
              <span className={`text-teal-100 ${'text-21px text-center font-bold mt-5px'}`}>
                {formatAmount(netBalance)}
              </span>
            </div>
          </div>

          <div className="right-side flex">
            <div className={`booking-ledger-headline-currency flex flex-col px-30px justify-center`}>
              <span className={'text-xs text-gray-100 tracking-2xs uppercase'}>Currency</span>
              <span className={'flex text-center font-bold mt-2px m-auto'}>{renderCurrencyIcon()}</span>
            </div>
            <div
              className={`booking-ledger-headline-account-balance text-right flex flex-col pl-30px pr-15px justify-center`}
            >
              <span className={'text-xs text-gray-100 tracking-2xs uppercase'}>Deposit account balance</span>
              <div className="deposit-account-balance flex items-center gap-5px">
                <span className="relative flex top-3px">
                  <PiggyBankIcon fill={theme.colors['black']} />
                </span>
                <span className={'text-21px font-bold mt-5px text-right'}>{formatAmount(accountBalance)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
