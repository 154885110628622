import React from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import * as Inputs from '../Inputs';
import FluidButton from 'ui/FluidButton';
import { IAccommodationProduct, IHotel, ISeason } from 'services/BackendApi';
import { Room } from '../child-product-forms/Room';
import { produce } from 'immer';
import * as _ from 'lodash-es';
import { validateRoom } from 'containers/HotelAdmin/validation';
const { enqueueNotification } = require('store/modules/ui');
import { useDispatch } from 'react-redux';
export const CreateRoomModal = ({
  onConfirm,
  onClose,
  hotel,
}: {
  onConfirm: (data: any) => void;
  onClose: () => void;
  hotel: IHotel;
}) => {
  const dispatch = useDispatch();
  const [draftRoom, setDraftRoom] = React.useState<Partial<IAccommodationProduct>>({
    externalMarkupType: null,
    category: 'perNight',
    meta: {
      description: '',
      size: 0,
      categoryType: '',
      amenities: [],
      moreInformation: '',
    },
    options: {
      occupancy: {
        standardOccupancy: 0,
        maximumPeople: 0,
        limits: [
          {
            name: 'default',
            minimum: 0,
            maximum: 0,
          },
        ],
      },
      ages: [],
    },
  });

  return (
    <GeneralModal
      onClose={() => onClose()}
      modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-scroll font-pt-sans"
      shouldCloseByClickingOutside={false}
    >
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">Create new Room</h1>
      <Room
        room={draftRoom}
        onUpdate={(field, value) => {
          const newRoom = produce(draftRoom, _draftRoom => {
            _.set(_draftRoom, field, value);
          });
          setDraftRoom({
            ...newRoom,
          });
        }}
        onCta={() => {
          const validation = validateRoom(draftRoom);

          if (validation.isValid === false) {
            dispatch(
              enqueueNotification({
                message: `Room "${draftRoom.name}" is not valid: ${validation.message}`,
                options: { variant: 'warning' },
              })
            );
            return;
          }
          onConfirm(draftRoom);
        }}
        ctaLabel="Create Room"
      />
    </GeneralModal>
  );
};
