import { call, put, select, takeLeading } from 'redux-saga/effects';
import { AUTH_COUNTRY_CODE, AUTH_LOG_OUT, AUTH_ROLE, AUTH_TOKEN, AUTH_USER, AUTH_USERNAME, authLogOut, authReset } from './actions';
import { selectedTaSelector } from '../agents';
import { makeBackendApi } from 'services/BackendApi';
import { makeCognitoApi } from 'services/CognitoApi';
import { COGNITO_ENABLE_FLOW } from 'config';
import { successAction } from 'store/common';
import { LOGOUT_REQUEST, LogoutRequestAction, logoutFailureAction } from './authenticationActions';

export function* logoutSaga(action: LogoutRequestAction) {
  try {
    
    // Here we set the FE in 'sending' mode
    yield put(authLogOut(action.token));

    // We logout in BE. Session is terminated in BE. BE does a "AdminUserGlobalSignOut".
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    try {
      yield call(backendApi.logout);
    } catch(error) {
      // webapp-admin might have wiped the tokens
      // We should still logout in AWS and redirect to pre-login, no reason to hang
    }

    // Start clearing local storage
    // User info
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_USER);
    localStorage.removeItem(AUTH_COUNTRY_CODE);
    localStorage.removeItem(AUTH_ROLE);
    localStorage.removeItem(AUTH_USERNAME);  
    // Cognito or Legacy auhtentication info
    window.localStorage.removeItem('AUTH_JWT'); // Common for Legacy and Cognito
    if (COGNITO_ENABLE_FLOW) {
      window.localStorage.removeItem('ID_TOKEN');
      window.localStorage.removeItem('REFRESH_TOKEN');
    }

    yield put(successAction(AUTH_LOG_OUT, {})); 

    // We set FE from 'sending' to 'idle' mode
    yield put(authReset());
    
    // Logout user in AWS. Then AWS redirects user's browser.
    const cognitoApi = makeCognitoApi();
    cognitoApi.logout(); // not async
    // Don't redirect as AWS will redirect browser anyway and sinchronously

  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`error: ${e.message}`);
    yield put(logoutFailureAction(e));
  }
}

export function* watchAuthenticationSaga() {
  yield takeLeading([LOGOUT_REQUEST], logoutSaga);
}
