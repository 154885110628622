import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  GET_TA_LOGO_REQUEST,
  getTaLogoSuccessAction,
  getTaLogoFailureAction,
} from './actions';

import { ICompanyLogoResponse } from 'services/BackendApi/types';
import { makeBackendApi } from 'services/BackendApi';
import { bookingSelector } from '../../selectors';
import { selectedTaSelector } from '../../../agents';

export function* getTaLogoSaga() {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const booking = yield select(bookingSelector);
    const travelAgentCompanyUuid = booking?.travelAgentCompanyUuid || selectedTa?.companyUuid;
    if (travelAgentCompanyUuid) {
      const result: AxiosResponse<ICompanyLogoResponse> = yield call(
        backendApi.getCompanyLogo,
        travelAgentCompanyUuid
      );
  
      yield put(getTaLogoSuccessAction(result.data.data));
    }
  } catch (e) {
    yield put(getTaLogoFailureAction(e));
  }
}

export function* watchBookingManagerTaLogoSaga() {
  yield takeLatest([GET_TA_LOGO_REQUEST], getTaLogoSaga);
}
