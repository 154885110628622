import { ModalModes } from 'containers/SummaryForm/const';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as ProposalsSelectors from 'store/modules/proposals/selectors';
import * as ProposalsSelectorsV2 from 'store/modules/proposalsList/selectors';
import * as ProposalsActions from 'store/modules/proposals/actions';
import { convertArrayToKeyValueObject } from 'utils';

export const useAddBookingToProposal = ({
  setRequestInProgress,
  backendApi,
  containsLiveRates,
  openModalRatesWarning,
  closeModalRatesWarning,
  setShouldShowLeaveAlert,
  setRedirectLocation,
}) => {
  const history = useHistory();
  const [isProposalModalOpen, setIsProposalModalOpen] = useState(false);
  const proposalsV2 = useSelector(ProposalsSelectorsV2.proposalsSelector);
  const isProposalDownloading = useSelector(ProposalsSelectorsV2.requestPendingSelector);
  const proposals = convertArrayToKeyValueObject(proposalsV2, 'uuid', 'name');
  const proposalStatus = useSelector(ProposalsSelectors.getProposalsStatus);
  const dispatch = useDispatch();

  const handleProposalOnSuccess = useCallback(() => history.push('/search/beta'), [history]);
  const handleOnProposalModalClose = useCallback(() => setIsProposalModalOpen(false), []);

  const handleAddToProposalButton = useCallback(() => {
    setIsProposalModalOpen(true);
    closeModalRatesWarning();
  }, [closeModalRatesWarning]);

  const handleAddToProposalTrigger = useCallback(() => {
    if (containsLiveRates) {
      openModalRatesWarning(ModalModes.PROPOSAL);
    } else {
      handleAddToProposalButton();
    }
  }, [containsLiveRates, handleAddToProposalButton, openModalRatesWarning]);

  const handleAddToProposal = useCallback(
    (proposalUuid, bookingUuid) => {
      dispatch(ProposalsActions.addToProposal(proposalUuid, bookingUuid));
      setRequestInProgress(false);
      setShouldShowLeaveAlert(false);
    },
    [dispatch, setRequestInProgress, setShouldShowLeaveAlert]
  );

  const handleCreateNewProposal = useCallback(
    (name, bookingId) => {
      dispatch(ProposalsActions.createNewProposal(name, bookingId, backendApi));
      setShouldShowLeaveAlert(false);
    },
    [backendApi, dispatch, setShouldShowLeaveAlert]
  );

  return {
    handleAddToProposalButton,
    handleCreateNewProposal,
    handleAddToProposal,
    handleAddToProposalTrigger,
    proposals,
    proposalStatus,
    isProposalDownloading,
    isProposalModalOpen,
    handleProposalOnSuccess,
    handleOnProposalModalClose,
  };
};
