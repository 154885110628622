import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import * as BreakdownActions from '../actions';
import { makeBackendApi } from 'services/BackendApi';
import { bookingCurrencySymbolSelector } from 'store/modules/bookingManager/selectors';
import { ICurrencyExchangeQuery, ICurrencyExchangeResponse } from 'services/BackendApi/types/CurrencyExchange';
import { getCurrencyBySymbol } from 'utils';
import { getExchangeCurrencyFailureAction, getExchangeCurrencySuccessAction } from '../actions';
import { exchangeCurrencyCodeSelector } from '../selectors';
import { TCurrencyCode } from 'interfaces';

function* fetchExchangeRate(toCurrencyCode: TCurrencyCode) {
  try {
    const backendApi = makeBackendApi();
    const bookingCurrencySymbol = yield select(bookingCurrencySymbolSelector);
    const query: ICurrencyExchangeQuery = { 
      from: getCurrencyBySymbol(bookingCurrencySymbol).code, 
      to: toCurrencyCode 
    };
    const result: AxiosResponse<ICurrencyExchangeResponse> = yield call(backendApi.getExchangeRate, query);
    yield put(getExchangeCurrencySuccessAction(result.data.data.exchangeRate, result.data.data.timestamp));
  } catch {
    yield put(getExchangeCurrencyFailureAction());
  }
}

export function* getExchangeRateSaga(action: BreakdownActions.GetExchangeCurrencyRequestAction) {
  yield* fetchExchangeRate(action.currencyCode);
}

export function* refreshExchangeRateSaga() {
  const exchangeCurrencyCode = yield select(exchangeCurrencyCodeSelector);
  yield* fetchExchangeRate(exchangeCurrencyCode);
}

export function* watchGetExchangeRateSaga() {
  yield takeLatest(
    [BreakdownActions.GET_EXCHANGE_CURRENCY_REQUEST],
    getExchangeRateSaga
  );
  yield takeLatest(
    [BreakdownActions.REFRESH_EXCHANGE_CURRENCY],
    refreshExchangeRateSaga
  );
}
