import React, { useCallback, FocusEvent, CSSProperties } from 'react';
import styled from 'styled-components';
import { pureUiTheme } from 'pureUi/pureUiTheme';

export type InputMode = 'none' | 'text' | 'Standard' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';
export type TextTransform = 'inherit' | 'uppercase'; // add more as needed

export interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
  children?: JSX.Element | JSX.Element[] | null | undefined;
  inputmode?: InputMode; // Looks like the TS definitions missed this one
  textTransform?: TextTransform;
  spanStyle?: CSSProperties;
  spanClassName?: string;
  inputClassName?: string;
}
const TextInputComponent = (props: TextInputProps) => {
  const { className, children, type, spanStyle, spanClassName, onFocus, inputClassName, textTransform, ...inputProps } = props;

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      e.target.select();
      if (props.onFocus) {
        props.onFocus(e);
      }
    },
    [props.onFocus]
  );

  return (
    <div className={className}>
      <input className={inputClassName} onFocus={handleFocus} type="text" {...inputProps} />
      <div className="children">{children}</div>
      <span style={spanStyle} className={`surrogate ${spanClassName ?? ''}`} />
    </div>
  );
};

export const TextInput = styled(TextInputComponent)<TextInputProps>`
  position: relative;
  display: flex;
  flex-direction: row;

  input {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 11px;
    font-family: 'HurmeGeometricSans2';
    font-size: 14px;
    width: 100%;
    color: ${pureUiTheme.colors.black};
    border: none;
  }

  input::placeholder {
    color: ${pureUiTheme.colors.gray};
    text-transform: uppercase;
  }

  input:focus,
  input:active {
    outline: none;
  }

  .children {
    flex-shrink: 1;
  }

  .surrogate {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.15s ease-out;
    box-shadow: 0 0 0 5px transparent;
    pointer-events: none;
    border: ${pureUiTheme.colorRoles.lightGreyBorder} 1px solid;
  }

  input:focus ~ .surrogate,
  input:active ~ .surrogate {
    box-shadow: 0 0 0 2px ${pureUiTheme.colors.marine};
  }
`;

export default TextInput;
