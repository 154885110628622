import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash-es';
import classNames from 'classnames';
import {
  EBespokeNegotiationDiscountType,
  EBespokeNegotiationApplication,
  EBespokeNegotiationAffectedItems,
} from 'services/BookingManagerApi/types';

import * as Actions from 'store/modules/bookingManager/subdomains/bespokeNegotiation/actions';
import * as Selectors from 'store/modules/bookingManager/subdomains/bespokeNegotiation/selectors';

import { StandardModal } from 'pureUi/Modal';
import { Radio } from 'ui/Radio';
import Checkbox from 'ui/Checkbox';
import FluidButton from 'ui/FluidButton';
import { Multiselect } from 'ui/Multiselect';
import { PriceAsCentsInput } from 'ui/stateful/PriceAsCentsInput';

export const BespokeNegotiationModal: React.FC = () => {
  const dispatch = useDispatch();

  const handleClose = useCallback(() => dispatch(Actions.closeModalAction()), [dispatch]);

  return (
    <StandardModal
      className="bespoke-negotiation-modal font-pt-sans z-11 text-black"
      frameClassName="w-570px px-35px pt-25px pb-30px rounded shadow-pe4"
      removePadding
      showCloseButton
      closeOnOutsideClick
      onClose={handleClose}
    >
      <h3 className="title mt-1 mb-25px font-noe-display font-normal text-21px">Bespoke Negotiation</h3>
      <Section className="description text-base leading-sm">
        This booking has an individually negotiated discounts in course. <br />
        Review here the bespoke negotiation details:
      </Section>
      <Section className="negotiation flex">
        <div className="version w-200px">
          <Label>Negotiation</Label>
          <VersionSelect />
        </div>
        <div className="lead flex-1 ml-20px">
          <Label>Negotiation Lead</Label>
          <LeadSelect />
        </div>
      </Section>
      <Section className="dicount">
        <Label>Discount</Label>
        <Discount />
      </Section>
      <Section className="applied-to">
        <Label>Discount Applied To</Label>
        <DiscountApplication />
      </Section>
      <Section className="items-affected">
        <Label>Items Affected</Label>
        <DiscountItems />
      </Section>
      <Separator />
      <Section className="save-as-new">
        <SaveAsNew />
      </Section>
      <Controls />
    </StandardModal>
  );
};

const Section: React.FC<{ className?: string; children?: React.ReactNode }> = props => (
  <div className={classNames(props.className, 'section mb-25px')}>{props.children}</div>
);

interface ILabelProps {
  className?: string;
  children?: React.ReactNode;
}
const Label = (props: ILabelProps) => (
  <label className="inline-block text-base font-pt-sans font-bold tracking-2xs leading-21px mb-5px">
    {props.children}
  </label>
);

const Separator: React.FC = () => <div className="min-h-1px w-full bg-gray-20 mb-25px" />;

interface IChecklistProps<T extends string> {
  options: { value: T; label: string }[];
  checked: T[];
  onClick: (x: T) => void;
  disabled?: boolean;
}

type TChecklist = <T extends string>(p: IChecklistProps<T>) => React.ReactElement<IChecklistProps<T>>;

const Checklist: TChecklist = props => (
  <div className="checklist flex flex-wrap gap-x-5 gap-y-[16px] mt-5px">
    {props.options.map(x => (
      <label
        className={classNames('inline-flex font-pt-sans text-base select-none', {
          'cursor-pointer text-black': !props.disabled,
          'pointer-events-none text-gray-100': props.disabled,
        })}
        onClick={() => props.onClick(x.value)}
      >
        <Checkbox
          className={`option-${x.value}`}
          checked={props.checked.includes(x.value)}
          readOnly
          disabled={props.disabled}
        />
        <span className="ml-2">{x.label}</span>
      </label>
    ))}
  </div>
);

const VersionSelect: React.FC = () => {
  const dispatch = useDispatch();

  const items = useSelector(Selectors.itemsSelector);
  const versions = useSelector(Selectors.versionsSelector);
  const editing = useSelector(Selectors.editingSelector);
  const disabled = !items?.length;

  const options = useMemo(
    () =>
      versions.map(x => ({
        value: x as string,
        label: `Negotiation V${x}`,
      })),
    [versions]
  );

  const handleSelect = useCallback(([x]) => dispatch(Actions.selectVersionAction(x)), [dispatch]);

  const contentClass = classNames('font-pt-sans text-15px', {
    'text-gray-100': disabled,
    'text-black': !disabled,
  });

  return (
    <Multiselect
      className={classNames('version-select', {
        'bg-gray-10 pointer-events-none': disabled,
        'bg-ivory': !disabled,
      })}
      itemsClassname="bg-ivory"
      itemCtaClassName="hover:bg-teal-40"
      labelClassName={contentClass}
      itemContentClassName={contentClass}
      selectedItemContentClassName="font-bold"
      options={options}
      isCloseOnSelect
      hideCheckboxes
      isSingleSelectMode
      onUpdate={handleSelect}
      selectedValues={[editing?.version as string]}
      disabled={disabled}
    />
  );
};

const LeadSelect: React.FC = () => {
  const dispatch = useDispatch();

  const leads = useSelector(Selectors.leadsSelector);
  const editing = useSelector(Selectors.editingSelector);

  const options = useMemo(
    () =>
      leads.map(x => ({
        value: x.uuid,
        label: x.email,
      })),
    [leads]
  );

  const handleSelect = useCallback(
    ([selected]) => dispatch(Actions.selectLeadAction(leads.find(x => x.uuid === selected)!)),
    [dispatch, options]
  );

  const contentClass = 'font-pt-sans text-15px text-black';

  return (
    <Multiselect
      className="lead-select bg-ivory"
      itemsClassname="bg-ivory"
      itemCtaClassName="hover:bg-teal-40"
      labelClassName={contentClass}
      itemContentClassName={contentClass}
      selectedItemContentClassName="font-bold"
      options={options}
      isCloseOnSelect
      hideCheckboxes
      isSingleSelectMode
      onUpdate={handleSelect}
      selectedValues={editing?.lead ? [editing.lead.uuid] : []}
    />
  );
};

const Discount: React.FC = () => {
  const dispatch = useDispatch();

  const editing = useSelector(Selectors.editingSelector);

  const handleAmountInputBlur = useCallback(x => dispatch(Actions.setDiscountAmountAction(x)), [dispatch]);

  const amount = isNil(editing?.discount.amount) ? undefined : Math.abs(editing!.discount.amount);

  const options = useMemo(
    () => [
      { value: EBespokeNegotiationDiscountType.PERCENTAGE, label: 'Percentage (%)' },
      { value: EBespokeNegotiationDiscountType.FLAT, label: 'Flat Rate' },
    ],
    []
  );

  const handleTypeChange = x => dispatch(Actions.setDiscountTypeAction(x));

  return (
    <div className="flex">
      <PriceAsCentsInput
        className="discount-amount max-w-150px focus:outline-gray-80 p-2 border border-solid border-gray-40 h-35px w-full font-pt-sans text-base text-black bg-ivory"
        cents={amount}
        disabled={false}
        onBlurUpdate={handleAmountInputBlur}
      />
      <div className="dicount-type flex items-center">
        {options.map(x => (
          <label
            className="inline-flex font-pt-sans text-base text-black cursor-pointer ml-20px"
            onClick={() => handleTypeChange(x.value)}
          >
            <Radio checked={x.value === editing?.discount.type} />
            <span className="ml-2">{x.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

const DiscountApplication: React.FC = () => {
  const dispatch = useDispatch();

  const editing = useSelector(Selectors.editingSelector);

  const options = useMemo(
    () => [
      { value: EBespokeNegotiationApplication.PURCHASE_COST, label: 'Purchase Cost' },
      { value: EBespokeNegotiationApplication.COST_TO_TRAVEL_PARTNER, label: 'Cost to Travel Partner' },
    ],
    []
  );

  const handleClick = useCallback(x => dispatch(Actions.toggleApplicationAction(x)), [dispatch]);

  return <Checklist options={options} checked={editing!.appliedTo} onClick={handleClick} />;
};

const DiscountItems: React.FC = () => {
  const dispatch = useDispatch();

  const editing = useSelector(Selectors.editingSelector);

  const options = useMemo(
    () => [
      { value: EBespokeNegotiationAffectedItems.ACCOMMODATIONS, label: 'Accommodations' },
      { value: EBespokeNegotiationAffectedItems.MEAL_PLANS, label: 'Meal Plans' },
      { value: EBespokeNegotiationAffectedItems.EXTRA_PERSON_SUPPLEMENTS, label: 'Extra Person Supplements' },
      { value: EBespokeNegotiationAffectedItems.TRANSFERS, label: 'Transfers' },
      { value: EBespokeNegotiationAffectedItems.SUPPLEMENTS, label: 'Supplements' },
      { value: EBespokeNegotiationAffectedItems.EXTRA_ITEMS, label: 'Activities' },
    ],
    []
  );

  const handleClick = useCallback(x => dispatch(Actions.toggleAffectedItemsAction(x)), [dispatch]);

  return <Checklist options={options} checked={editing!.affectedItems} onClick={handleClick} />;
};

const SaveAsNew: React.FC = () => {
  const dispatch = useDispatch();

  const value = useSelector(Selectors.saveAsNewSelector);
  const items = useSelector(Selectors.itemsSelector);

  const options = useMemo(() => [{ value: '_', label: 'Save As New Version' }], []);

  const handleClick = useCallback(() => dispatch(Actions.toggleSaveAsNewAction()), [dispatch]);

  return <Checklist options={options} checked={value ? ['_'] : []} onClick={handleClick} disabled={!items?.length} />;
};

const Controls: React.FC = () => {
  const dispatch = useDispatch();

  const canSubmit = useSelector(Selectors.canSubmitSelector);
  const loading = useSelector(Selectors.updateLoadingSelector);

  const handleCancel = useCallback(() => dispatch(Actions.closeModalAction()), [dispatch]);

  const handleConfirm = useCallback(() => dispatch(Actions.updateRequestAction()), [dispatch]);

  return (
    <div className="flex">
      <FluidButton className="cancel" type="secondary" onClick={handleCancel}>
        Cancel
      </FluidButton>
      <FluidButton
        className="confirm ml-10px"
        type="primary"
        isLoading={loading}
        disabled={!canSubmit}
        onClick={handleConfirm}
      >
        Save
      </FluidButton>
    </div>
  );
};
