import React from 'react';
import { ILedgerTotals } from 'store/modules/ledger/model';
import { TADepositAccountRow } from './TADepositAccountRow';
import { getCurrencySymbol } from 'utils';
import { ENetworkRequestStatus } from 'services/BackendApi';
interface ITADepositAccount {
  totals: ILedgerTotals[],
  creditLimitUsd: string | null,
  creditLimitEur: string | null,
  loading: ENetworkRequestStatus,
}
export const TADepositAccount: React.FC<ITADepositAccount> = ({
  totals,
  creditLimitUsd,
  creditLimitEur,
  loading,
}) => {
  const thereIsCredit = Number(creditLimitUsd) !== 0 || Number(creditLimitEur) !== 0;
  const balanceUsd = totals[0]?.netTotal;
  const balanceEur = totals[1]?.netTotal;  
  return (
    <div className="TADepositAccount p-2.5 bg-white rounded-[3px] flex-col gap-2.5 inline-flex font-pt-sans min-w-[210px]">
      <div className="text-black text-sm font-bold">Available Funds:</div>
      <div className="flex-col gap-[5px] flex">
        <div className="pb-[5px] gap-2.5 inline-flex text-gray-100 text-xs font-normal">
          <div className="w-[125px]">AVAILABILITY</div>
          {thereIsCredit && <div className="w-[90px]">CREDIT LIMIT</div>}
        </div>
        <TADepositAccountRow
          balance={balanceUsd}
          creditLimit={creditLimitUsd}
          symbol={getCurrencySymbol('USD')}
          thereIsCredit={thereIsCredit}
          loading={loading !== ENetworkRequestStatus.SUCCESS && loading !== ENetworkRequestStatus.IDLE}
        />
        <div className="h-[0px] border border-gray-10"></div>
        <TADepositAccountRow
          balance={balanceEur}
          creditLimit={creditLimitEur}
          symbol={getCurrencySymbol('EUR')}
          thereIsCredit={thereIsCredit}
          loading={loading !== ENetworkRequestStatus.SUCCESS && loading !== ENetworkRequestStatus.IDLE}
        />
      </div>
    </div>
  )
}