import { useState } from 'react';

type UseModalReturn = {
  isOpen: boolean;
  openModal: () => Promise<any | null>;
  handleConfirm: (data: any) => void;
  handleCancel: () => void;
};

export const useModal = (): UseModalReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const [resolveCallback, setResolveCallback] = useState<((value: any | null) => void) | null>(null);

  const openModal = () => {
    return new Promise<any | null>(resolve => {
      setIsOpen(true);
      setResolveCallback(() => resolve);
    });
  };

  const handleConfirm = (data: any) => {
    if (resolveCallback) resolveCallback(data);
    setIsOpen(false);
  };

  const handleCancel = () => {
    if (resolveCallback) resolveCallback(null);
    setIsOpen(false);
  };

  return { isOpen, openModal, handleConfirm, handleCancel };
};
