import React, { useState, useCallback } from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import classnames from 'classnames';
import FluidButton from 'ui/FluidButton';
import { useTranslation } from 'react-i18next';
import { formatPrice, calculatePercentage } from 'utils';
import { useCurrentWidth } from 'effects';
import { CloseXComponent as CloseXIcon } from 'ui/Icons';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { isNil } from 'lodash-es';

export interface IAddTACommissionModalProps {
  title?: string;
  onClose: () => void;
  marginAmount: number;
  totalAmount: number;
  marginPercent: number;
  currencySymbol: string;
  textClassname?: string;
  onSave: (a: number) => void;
  isLoading: boolean;
}

export const AddTACommissionModal = ({
  title,
  onClose,
  marginAmount,
  totalAmount,
  marginPercent,
  currencySymbol,
  textClassname = '',
  onSave,
  isLoading,
}: IAddTACommissionModalProps) => {
  const { t } = useTranslation();
  const { isMobile } = useCurrentWidth();
  const loading = false;
  const [currentMarginPercent, setCurrentMarginPercent] = useState(marginPercent.toFixed(2)); 
  const [calculatedAmount, setCalculatedAmount] = useState(marginAmount); 

  const handlePercentChange = useCallback((e) => {
    const value = (isNil(e.target.value) || e.target.value === '') ? Number(0).toFixed(2) : parseFloat(e.target.value);
    setCurrentMarginPercent(e.target.value);
    setCalculatedAmount(calculatePercentage(totalAmount, value));
  }, [totalAmount]);

  const handleClickSave = useCallback(() => {
    onSave(calculatedAmount);
  }, [calculatedAmount, onSave]);


  const modalWidthClassname = classnames('flex justify-center', isMobile ? 'w-[330px]' : 'w-[430px]');
  
  return (
    <GeneralModal
      modalWindowClassName={modalWidthClassname}
      shouldCloseByClickingOutside={false}
      onClose={onClose}
      isCloseButtonVisible={false}
    >
      <div className={classnames('commission-modal text-black flex justify-center p-10 w-screen', modalWidthClassname)}>
        <div className={classnames('commission-modal-content flex flex-col flex-1')}>
          <div className="commission-modal-title flex items-center justify-between">
            <p className="commission-modal-title text-black text-21px leading-27px font-noe-display mt-5px my-0">
              Add Commission
            </p>
            <div
              className="modal-close-button bg-ivory w-14px h-14px flex justify-center items-center cursor-pointer"
              onClick={onClose}
            >
              <CloseXIcon />
            </div>
          </div>

          {loading && (
            <span className="modal-loading mt-5 flex justify-center">
              <LoadingBar />
            </span>
          )}
          <span
            className={classnames(
              'block mt-15px mb-15px text-12px text-xs leading-xs font-hurmegeometric-sans test-bistre',
              textClassname
            )}
          >
            {`${t("labels.addCommission")}`}
          </span>
          <div className="flex flex-row items-center space-x-10px">
            <input
              type="number"
              name="taMarginAmount"
              value={currentMarginPercent}
              min={0}
              onChange={handlePercentChange}
              placeholder="Percentage"
              className="hover:outline outline-teal-80 hover:outline-2 no-webkit-arrows font-hurmegeometric-sans border border-solid border-gray-17 p-11px w-100px h-39px text-14px leading-16px"
            />
            <span className="font-hurmegeometric-sans text-xs text-flint">%</span>
            <span className="font-hurmegeometric-sans text-xs text-gray-17">|</span>
            <span className="calculated-info text-xs font-hurmegeometric-sans text-bistre">
              Your margin will be{' '}
              <span className="amount font-bold text-sm">{`${currencySymbol}${formatPrice(calculatedAmount)}`}</span>
            </span>
          </div>
    
          <div className="flex flex-row items-center mt-25px">
    
            <FluidButton
              className="request-booking-modal-request-booking-button w-full"
              isLoading={isLoading}
              disabled={parseFloat(currentMarginPercent) === marginPercent}
              onClick={handleClickSave}
              type="primary"
              rounded={false}
            >
              <span className="uppercase">Save</span>
            </FluidButton>
          </div>
        </div>
      </div>
    </GeneralModal>
  );
};
