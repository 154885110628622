import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FluidButton from 'ui/FluidButton';
import { ENetworkRequestStatus, ICompany, ITravelAgent } from 'services/BackendApi/types';
import { StandardModal } from 'pureUi/Modal';
import { TextInput } from 'ui/TextInput';
import { CountrySelect } from 'ui/CountrySelect';
import { AssignTAModalRow } from './AssignTAModalRow';
import { AssignTAModalHeader } from './AssignTAModalHeader';
import countries from 'config/data/countries.json';
import * as InternalUsersListSelectors from 'store/modules/internalUsersList/selectors';
import * as InternalUsersListActions from 'store/modules/internalUsersList/actions';
import { SlideToggle } from 'ui/SlideToggle';
import classNames from 'classnames';
import { LoadingBar } from 'ui/NetworkStatusBar';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { CloseBoldIcon } from 'ui/Icons/components/CloseBold.component';
import { theme } from '../../../../../tailwind.config';


export const AssignTAModal = React.memo(() => {

  const dispatch = useDispatch();
  const assignTAs = useSelector(InternalUsersListSelectors.assignedTAsSelector);
  const selectedRS = useSelector(InternalUsersListSelectors.selectedRSSelector);
  const selectedTAs = useSelector(InternalUsersListSelectors.selectedTAsSelector);
  const checkAllTAs = useSelector(InternalUsersListSelectors.checkAllTASelector);
  const nameFilterTAs = useSelector(InternalUsersListSelectors.nameFilterTAsSelector);
  const countryFilterTAs = useSelector(InternalUsersListSelectors.countryFilterTAsSelector);
  const isAssignedTab = useSelector(InternalUsersListSelectors.isAssignedTabTASelector);
  const getRequest = useSelector(InternalUsersListSelectors.getRequestTAsSelector);
  const postRequest = useSelector(InternalUsersListSelectors.postRequestTAsSelector);
  const isConfirmationModal = useSelector(InternalUsersListSelectors.isConfirmationModalTASelector);

  const handleCountryCodeChange = useCallback((countryCode: string) => {
    dispatch(InternalUsersListActions.setTAFilterCountryAction(countryCode));
  }, []);

  const handleTANameChange = useCallback(
    e => {
      if (e.target.value === '') {
        dispatch(InternalUsersListActions.setTAFilterNameAction(undefined));
      }
      else dispatch(InternalUsersListActions.setTAFilterNameAction(e.target.value));
    },
    [dispatch]
  );

  const handleAddTravelAgent = useCallback((travelAgent: ITravelAgent) => {
    dispatch(InternalUsersListActions.addSelectedSRTAsAction(travelAgent.uuid));
  }, []);

  const handleRemoveTravelAgent = useCallback((travelAgent: ITravelAgent) => {
    dispatch(InternalUsersListActions.removeSelectedSRTAsAction(travelAgent.uuid));
  }, []);

  const handleCheckAllTravelAgents = useCallback(() => {
    dispatch(InternalUsersListActions.checkAllSRTAsAction());
  }, [dispatch]);

  const handleUnCheckAllTravelAgents = useCallback(() => {
    dispatch(InternalUsersListActions.unCheckAllSRTAsAction());
  }, [dispatch]);

  const handleSave = useCallback(() => {
    dispatch(InternalUsersListActions.postRequestSelectedSRTAsAction());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(InternalUsersListActions.resetSelectedSRAction());
  }, [dispatch]);

  const handleConfirmChanges = useCallback(() => {
    dispatch(InternalUsersListActions.confirmChangesAction());
  }, [dispatch]);

  const handleCloseConfirmationModal = useCallback(() => {
    dispatch(InternalUsersListActions.closeConfirmationModalAction());
  }, [dispatch]);

  const handleDeclineChanges = useCallback(() => {
    handleCloseConfirmationModal();
    if (isAssignedTab) dispatch(InternalUsersListActions.resetIsAssignedTabAction());
    else dispatch(InternalUsersListActions.setIsAssignedTabAction());
  }, [isAssignedTab, dispatch]);

  return (
    <StandardModal
      className="travel-companies-modal-container font-pt-sans"
      frameClassName="w-540px px-9 py-8 border border-solid"
      showCloseButton={false}
      removePadding
      closeOnOutsideClick
    >
      <div className="travel-companies-modal relative">
        <div className='flex justify-between items-center'>
          <h3 className="m-0 font-noe-display font-normal text-black text-21px">Assignments</h3>
          <CloseBoldIcon onClick={handleClose} className="cursor-pointer" fill={theme.colors['brown-100']} />
        </div>
        <h3 className="m-0 font-noe-display mb-25px font-normal text-black text-21px">{selectedRS?.firstName} {selectedRS?.lastName} (Sales Rep.)</h3>
        <div className="travel-companies-dropdowns flex items-center gap-0">
          <span
            className={classNames('font-pt-sans text-15px uppercase', {
              'font-bold': isAssignedTab === true,
            })}
          >
            Assigned
          </span>
          <SlideToggle id='user-slide-toggle'
            className="user-slide-toggle mx-2"
            labelClassName={'bg-teal-40'}
            buttonClassName={'bg-teal-100'}
            disableToggling={false}
            checked={!isAssignedTab}
            onActivate={() => {
              dispatch(InternalUsersListActions.resetIsAssignedTabAction());
            }}
            onDeactivate={() => {
              dispatch(InternalUsersListActions.setIsAssignedTabAction());
            }}
            onClick={() => {
              if (isAssignedTab) {
                if (selectedTAs.length !== assignTAs.length) dispatch(InternalUsersListActions.openConfirmationModalAction());
                else dispatch(InternalUsersListActions.resetIsAssignedTabAction());
              }
              else {
                if (selectedTAs.length !== 0 && assignTAs.length) dispatch(InternalUsersListActions.openConfirmationModalAction());
                else dispatch(InternalUsersListActions.setIsAssignedTabAction());
              }
            }}
          />
          <span
            className={classNames('font-pt-sans text-15px uppercase', {
              'font-bold': isAssignedTab !== true,
            })}
          >
            Not Assigned
          </span>
        </div>
        <p className="font-pt-sans text-12px leading-21px text-black m-0 mt-25px">By selecting "Assigned" you can see the list of current assignments and delete any of them. With "Not assigned" option use the filters to get available options to assign to current user.</p>

        <div className="travel-companies-dropdowns flex items-center gap-20px">
          <div className="travel-companies-dropdowns-company">
            <p className="font-pt-sans text-16px leading-21px text-black m-0 font-bold mt-25px">Name</p>
            <TextInput
              type="text"
              id="travel-company-name"
              value={nameFilterTAs}
              className="travel-company-name w-250px"
              onChange={handleTANameChange}
            />
          </div>
          <div className="travel-companies-dropdowns-country max-w-200px">
            <p className="font-pt-sans text-16px leading-21px text-black m-0 font-bold mt-25px mb-5px">Country</p>
            <CountrySelect
              value={countryFilterTAs ?? null}
              onChange={handleCountryCodeChange}
              labelWhenNothingSelected="All"
              className="w-200px"
            />
          </div>
        </div>

        <div className="mt-25px border border-solid border-gray-20 shadow-pe2">
          <AssignTAModalHeader assignedTAs={assignTAs} onCheckAll={handleCheckAllTravelAgents} onUnCheckAll={handleUnCheckAllTravelAgents} isCheckAll={checkAllTAs} />
          <div className="assign-ta-rows max-h-320px min-h-320px overflow-y-scroll pl-3 pr-6">
            {getRequest === ENetworkRequestStatus.PENDING ? (
              <div className='relative top-120px'>
                <LoadingBar />
              </div>
            ) : (
              getRequest === ENetworkRequestStatus.SUCCESS && assignTAs?.length === 0 ? (
                <p className="font-pt-sans text-black text-15px leading-19px italic m-0 mt-2">
                  There are no Travel Agents that match.
                </p>
              ) : getRequest === ENetworkRequestStatus.IDLE ? (
                <p className="font-pt-sans text-black text-15px leading-19px  m-0 italic">
                  Use the filters to get results.
                </p>
              ) : getRequest === ENetworkRequestStatus.ERROR && (
                <p className="font-pt-sans text-red-100 text-15px leading-19px  m-0 italic">
                  Something went wrong.
                </p>
              )
            )}
            {assignTAs.length > 0 && assignTAs?.map(AT => (
              <AssignTAModalRow
                key={AT.uuid}
                country={AT.countryCode && countries[AT.countryCode]}
                travelAgent={AT}
                enabled={selectedTAs.includes(AT.uuid)}
                onAdd={handleAddTravelAgent}
                onRemove={handleRemoveTravelAgent}
              />
            ))}
          </div>
        </div>

        <div className="travel-companies-buttons flex gap-10px mt-25px">
          <FluidButton className="travel-companies-cancel" type="secondary" onClick={handleClose}>
            Cancel
          </FluidButton>
          <FluidButton
            className="travel-companies-save"
            type="primary"
            isLoading={postRequest === ENetworkRequestStatus.PENDING}
            onClick={handleSave}
            disabled={isAssignedTab ? assignTAs.length === selectedTAs.length : selectedTAs.length === 0}
          >
            Done
          </FluidButton>
        </div>
      </div>
      {isConfirmationModal && <ConfirmationModal
        className="finance-table-delete-warning"
        type={EConfirmationModalType.WARNING}
        isOpen
        title={`You made changes in the Assignments`}
        message="Do you want to Save changes?"
        confirmButtonLabel="Yes"
        cancelButtonLabel="No"
        isConfirmLoading={postRequest === ENetworkRequestStatus.PENDING}
        onConfirm={handleConfirmChanges}
        onCancel={handleDeclineChanges}
      />}
    </StandardModal>
  );
});
