import React, { useRef } from 'react';
import classNames from 'classnames';

import { useSticky } from 'hooks/useSticky';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

export const StickyBar: React.FC<Props> = React.memo(({ className, children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isSticky] = useSticky(containerRef);

  return (
    <div
      ref={containerRef}
      className={classNames(className, 'sticky-bar absolute left-0 right-0 min-h-70px mt-10 bg-gray-40')}
    >
      <div
        className={classNames({
          'flex justify-center min-h-70px bg-gray-40 w-full': true,
          'fixed bottom-0 left-0 right-0': isSticky,
        })}
      >
        <div className="main flex pt-4 pb-4 w-1280px min-h-70px pl-208px bg-gray-40">{children}</div>
      </div>
    </div>
  );
});

export default StickyBar;
