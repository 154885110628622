import React from 'react';
import classNames from 'classnames';
import { CircleIconButton } from '../CircleIconButton';
import SingleDateInput from 'pureUi/SingleDateInput';
import { PriceAsCentsInput } from '../stateful/PriceAsCentsInput';
import { DecimalInput } from '../stateful/DecimalInput';
import { formatDate } from '../../utils';

export interface IInvoiceModalDueDateRowProps {
  className?: string;
  index: number;
  date: string | null;
  percentage: number;
  amountCents: number;
  currencySymbol: string;
  isConfirmed: boolean;
  allOtherDates: string[];
  onUpdateAmountCents: (index, newAmountCents: number) => void;
  onUpdatePercentage: (index, newPercent: number) => void;
  onUpdateDate: (index, newDate: string) => void;
  onClickDelete: (index: number) => void;
  onClickRemove: (index: number) => void;
  onClickConfirm: (index: number) => void;
}

export const InvoiceModalDueDateRow = (props: IInvoiceModalDueDateRowProps) => {
  return (
    <div className={classNames('flex items-center justify-around', props.className)}>
      <span className="w-90px text-sm">{props.index + 1}st Due Date</span>
      <span className="w-120px">
        <SingleDateInput
          value={props.date && props.date !== '' ? new Date(props.date) : null}
          onChange={value => {
            props.onUpdateDate(props.index, formatDate(value));
          }}
          disableDates={props.allOtherDates}
          showYearDropdown
          enablePastDates={true}
          internalClassName={classNames({
            dateInputField: true,
            dateNotSelected: props.date === null,
          })}
        />
      </span>
      <span className="w-1/4 min-w-170px ml-4 flex justify-center items-center">
        <span className="text-sm mr-2">Percentage (%)</span>
        <DecimalInput
          className={classNames('w-16 focus:outline-gray-80 text-black text-base text-right border border-solid p-2', {
            'bg-gray-20 border-gray-20': props.isConfirmed,
            'bg-white border-gray-40': !props.isConfirmed,
          })}
          decimalPlaces={2}
          disabled={props.isConfirmed}
          onBlur={val => {
            props.onUpdatePercentage(props.index, val as number);
          }}
          value={props.percentage || 0}
        />
        {/* <input type="text"
          onBlur=
          /> */}
      </span>
      <span className="w-1/6 ml-4 flex justify-center items-center">
        <span className="mr-2 text-sm">{props.currencySymbol}</span>
        <PriceAsCentsInput
          className={classNames(
            'w-full focus:outline-gray-80 text-black text-base text-right border border-solid p-2',
            {
              'bg-gray-20 border-gray-20': props.isConfirmed,
              'bg-white border-gray-40': !props.isConfirmed,
            }
          )}
          disabled={props.isConfirmed}
          cents={props.amountCents || 0}
          onBlurUpdate={val => {
            props.onUpdateAmountCents(props.index, val);
          }}
        />
      </span>
      <span
        style={{
          width: '80px',
        }}
        className=" flex justify-end"
      >
        {props.isConfirmed === false && (
          <React.Fragment>
            <CircleIconButton
              className="mr-2"
              iconClass="fas fa-times"
              onClick={() => {
                props.onClickRemove(props.index);
              }}
              type={'secondary'}
            />
            <CircleIconButton
              disabled={props.date === null || props.percentage === 0 || props.amountCents === 0}
              iconClass="fas fa-check"
              type={'primary'}
              onClick={() => {
                props.onClickConfirm(props.index);
              }}
            />
          </React.Fragment>
        )}
        {props.isConfirmed === true && (
          <CircleIconButton
            iconClass="fas fa-trash-alt"
            type={'secondary'}
            onClick={() => {
              props.onClickDelete(props.index);
            }}
          />
        )}
      </span>
    </div>
  );
};
