import React, { useCallback } from 'react';
import classnames from 'classnames';

import { Radio } from 'ui/Radio';

interface IRadioListBoxItemProps {
  id: string;
  value: string;
  label: string;
  checked: boolean;
  onSelect: (value: string) => void;
}

export const RadioListBoxItem: React.FC<IRadioListBoxItemProps> = (
  { id, value, label, checked, onSelect }
) => {
  const idClassName = [id, ...value.split(' ')].join('-').toLowerCase();
  const labelClassname = classnames(
    idClassName,
    'flex gap-[8px] cursor-pointer items-center p-[3px]',
    { 'bg-green-25': checked }
  );
  const handleClick = useCallback(() => {
    onSelect(value);
  }, [value, onSelect]);
  const noop = useCallback(() => {}, []);
  
  return (
    <label className={labelClassname} onClick={handleClick}>
      <Radio checked={checked} onClick={noop} />
      <span className="font-pt-sans text-black text-[15px] leading-[19px]">{label}</span>
    </label>
  );
};
