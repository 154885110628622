import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import * as AgentsSelectors from 'store/modules/agents/selectors';
import { getTaFullName } from 'store/utils';
import { useSelector } from 'react-redux';
import { TextInput } from 'ui/TextInput';
import { ITravelAgent } from 'services/BackendApi';

interface ILabelProps {
  children: React.ReactNode;
}

const Label: React.FC<ILabelProps> = ({ children }) => (
  <div className="self-stretch text-gray-100 text-xs font-normal font-hurmegeometric-sans uppercase">{children}</div>
);

interface BasketTravelAgentInfo {
  travelAgent: Partial<ITravelAgent> | null;
  className?: string;
}

export const BasketTravelAgentInfo: React.FC<BasketTravelAgentInfo> = React.memo(({ className = '' }) => {
  const travelAgent = useSelector(AgentsSelectors.selectedTaSelector);

  if (!travelAgent) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="booking-travel-agent-heading font-hurmegeometric-sans text-[12px] leading-[14px] font-bold text-flint mt-[30px] pb-[10px] border-b border-gray-20">
        TRAVEL AGENT INFO
      </div>
      <div className="booking-travel-agent-form flex flex-col gap-[10px] overflow-auto mt-[20px]">
        <div className="booking-travel-agent-firstname flex-col justify-start items-start gap-[5px] inline-flex">
          <Label>NAME</Label>
          <TextInput
            data-lpignore="true"
            id="guest-first-name"
            value={getTaFullName(travelAgent)}
            className="w-full"
            inputClassName="font-hurmegeometric-sans text-[14px] text-gray-80 border-gray-17 focus:border-teal-80 focus:border-2 focus:py-[6px]"
            disabled
          />
        </div>
      </div>
    </div>
  );
});
