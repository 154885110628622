import { TCountryCode } from 'interfaces';
import { ENetworkRequestStatus } from 'services/BackendApi';
import {
  IGlobalStatementTableData,
  IGlobalStatementResponseRow,
  IGlobalStatementTotalsRow,
  IGlobalStatementDomain,
} from './types';

export const TOTALS_REQUEST = 'globalStatement/TOTALS_REQUEST';
export const TOTALS_SUCCESS = 'globalStatement/TOTALS_SUCCESS';
export const TOTALS_FAILURE = 'globalStatement/TOTALS_FAILURE';

export const TABLE_DATA_REQUEST = 'globalStatement/TABLE_DATA_REQUEST';
export const TABLE_DATA_SUCCESS = 'globalStatement/TABLE_DATA_SUCCESS';
export const TABLE_DATA_FAILURE = 'globalStatement/TABLE_DATA_FAILURE';

export const TRIGGER_EXPORT_CSV_REQUEST = 'globalStatement/TRIGGER_EXPORT_CSV_REQUEST';
export const TRIGGER_EXPORT_CSV_SUCCESS = 'globalStatement/TRIGGER_EXPORT_CSV_SUCCESS';
export const TRIGGER_EXPORT_CSV_FAILURE = 'globalStatement/TRIGGER_EXPORT_CSV_FAILURE';

export const POLL_EXPORT_CSV_REQUEST = 'globalStatement/POLL_EXPORT_CSV_REQUEST';
export const POLL_EXPORT_CSV_SUCCESS = 'globalStatement/POLL_EXPORT_CSV_SUCCESS';
export const POLL_EXPORT_CSV_CONTINUE = 'globalStatement/POLL_EXPORT_CSV_CONTINUE';
export const POLL_EXPORT_CSV_FAILURE = 'globalStatement/POLL_EXPORT_CSV_FAILURE';
export const POLL_EXPORT_CSV_CANCEL = 'globalStatement/POLL_EXPORT_CSV_CANCEL';

export const SET_NETWORK_STATE = 'globalStatement/SET_NETWORK_STATE';

export const SET_PAGE_NUMBER = 'globalStatement/SET_PAGE_NUMBER';
export const SET_ITEMS_PER_PAGE = 'globalStatement/SET_ITEMS_PER_PAGE';
export const SET_TOTAL_RESULT_COUNT = 'globalStatement/SET_TOTAL_RESULT_COUNT';

export const SET_SORT_BY = 'globalStatement/SET_SORT_BY';
export const SET_SORT_ORDER = 'globalStatement/SET_SORT_ORDER';

export const RESET_FILTERS = 'globalStatement/RESET_FILTERS';
export const SET_TP_COUNTRY = 'globalStatement/SET_TP_COUNTRY';
export const SET_TRAVEL_COMPANY = 'globalStatement/SET_TRAVEL_COMPANY';
export const SET_AMOUNT_MIN = 'globalStatement/SET_AMOUNT_MIN';
export const SET_AMOUNT_MAX = 'globalStatement/SET_AMOUNT_MAX';

export type TotalsRequestAction = ReturnType<typeof totalsRequestAction>;
export const totalsRequestAction = () => ({
  type: TOTALS_REQUEST as typeof TOTALS_REQUEST,
});

export type TotalsSuccessAction = ReturnType<typeof totalsSuccessAction>;
export const totalsSuccessAction = (totals: IGlobalStatementTotalsRow[]) => ({
  type: TOTALS_SUCCESS as typeof TOTALS_SUCCESS,
  totals,
});

export type TotalsFailureAction = ReturnType<typeof totalsFailureAction>;
export const totalsFailureAction = () => ({
  type: TOTALS_FAILURE as typeof TOTALS_FAILURE,
});

export type TableDataRequestAction = ReturnType<typeof tableDataRequestAction>;
export const tableDataRequestAction = () => ({
  type: TABLE_DATA_REQUEST as typeof TABLE_DATA_REQUEST,
});

export type TableDataSuccessAction = ReturnType<typeof tableDataSuccessAction>;
export const tableDataSuccessAction = (tableData: IGlobalStatementTableData) => ({
  type: TABLE_DATA_SUCCESS as typeof TABLE_DATA_SUCCESS,
  tableData,
});

export type TableDataFailureAction = ReturnType<typeof tableDataFailureAction>;
export const tableDataFailureAction = () => ({
  type: TABLE_DATA_FAILURE as typeof TABLE_DATA_FAILURE,
});

export type TriggerExportCsvRequestAction = ReturnType<typeof triggerExportCsvRequestAction>;
export const triggerExportCsvRequestAction = () => ({
  type: TRIGGER_EXPORT_CSV_REQUEST as typeof TRIGGER_EXPORT_CSV_REQUEST,
});

export type TriggerExportCsvSuccessAction = ReturnType<typeof triggerExportCsvSuccessAction>;
export const triggerExportCsvSuccessAction = (exportUuid: string) => ({
  type: TRIGGER_EXPORT_CSV_SUCCESS as typeof TRIGGER_EXPORT_CSV_SUCCESS,
  exportUuid,
});

export type TriggerExportCsvFailureAction = ReturnType<typeof triggerExportCsvFailureAction>;
export const triggerExportCsvFailureAction = () => ({
  type: TRIGGER_EXPORT_CSV_FAILURE as typeof TRIGGER_EXPORT_CSV_FAILURE,
});

export type PollExportCsvRequestAction = ReturnType<typeof pollExportCsvRequestAction>;
export const pollExportCsvRequestAction = () => ({
  type: POLL_EXPORT_CSV_REQUEST as typeof POLL_EXPORT_CSV_REQUEST,
});

export type PollExportCsvSuccessAction = ReturnType<typeof pollExportCsvSuccessAction>;
export const pollExportCsvSuccessAction = (signedUrl: string) => ({
  type: POLL_EXPORT_CSV_SUCCESS as typeof POLL_EXPORT_CSV_SUCCESS,
  signedUrl,
});

export type PollExportCsvContinueAction = ReturnType<typeof pollExportCsvContinueAction>;
export const pollExportCsvContinueAction = () => ({
  type: POLL_EXPORT_CSV_CONTINUE as typeof POLL_EXPORT_CSV_CONTINUE,
});

export type PollExportCsvFailureAction = ReturnType<typeof pollExportCsvFailureAction>;
export const pollExportCsvFailureAction = () => ({
  type: POLL_EXPORT_CSV_FAILURE as typeof POLL_EXPORT_CSV_FAILURE,
});

export type PollExportCsvCancelAction = ReturnType<typeof pollExportCsvCancelAction>;
export const pollExportCsvCancelAction = () => ({
  type: POLL_EXPORT_CSV_CANCEL as typeof POLL_EXPORT_CSV_CANCEL,
});

// filter stuff

export type SetNetworkStateAction = ReturnType<typeof setNetworkStateAction>;
export const setNetworkStateAction = (
  networkItem: keyof IGlobalStatementDomain['network'],
  state: ENetworkRequestStatus
) => ({
  type: SET_NETWORK_STATE as typeof SET_NETWORK_STATE,
  networkItem,
  state,
});

export type SetPageNumberAction = ReturnType<typeof setPageNumberAction>;
export const setPageNumberAction = (pageNum: number) => ({
  type: SET_PAGE_NUMBER as typeof SET_PAGE_NUMBER,
  pageNum,
});

export type SetItemsPerPageAction = ReturnType<typeof setItemsPerPageAction>;
export const setItemsPerPageAction = (itemsPerPage: number) => ({
  type: SET_ITEMS_PER_PAGE as typeof SET_ITEMS_PER_PAGE,
  itemsPerPage,
});

export type SetTotalResultCountAction = ReturnType<typeof setTotalResultCountAction>;
export const setTotalResultCountAction = (totalResultCount: number) => ({
  type: SET_TOTAL_RESULT_COUNT as typeof SET_TOTAL_RESULT_COUNT,
  totalResultCount,
});

export type SetSortByAction = ReturnType<typeof setSortByAction>;
export const setSortByAction = (sortBy: keyof IGlobalStatementResponseRow) => ({
  type: SET_SORT_BY as typeof SET_SORT_BY,
  sortBy,
});

export type SetSortOrderAction = ReturnType<typeof setSortOrderAction>;
export const setSortOrderAction = (sortOrder: 'asc' | 'desc') => ({
  type: SET_SORT_ORDER as typeof SET_SORT_ORDER,
  sortOrder,
});

export type SetTpCountryAction = ReturnType<typeof setTpCountryAction>;
export const setTpCountryAction = (tpCountry: TCountryCode | undefined) => ({
  type: SET_TP_COUNTRY as typeof SET_TP_COUNTRY,
  tpCountry,
});

export type SetTravelCompanyAction = ReturnType<typeof setTravelCompanyAction>;
export const setTravelCompanyAction = (travelCompanyUuid: string | undefined) => ({
  type: SET_TRAVEL_COMPANY as typeof SET_TRAVEL_COMPANY,
  travelCompany: travelCompanyUuid,
});

export type SetAmountMinAction = ReturnType<typeof setAmountMinAction>;
export const setAmountMinAction = (amountMinCents: number) => ({
  type: SET_AMOUNT_MIN as typeof SET_AMOUNT_MIN,
  amountMinCents,
});

export type SetAmountMaxAction = ReturnType<typeof setAmountMaxAction>;
export const setAmountMaxAction = (amountMaxCents: number) => ({
  type: SET_AMOUNT_MAX as typeof SET_AMOUNT_MAX,
  amountMaxCents,
});

export type ResetFiltersAction = ReturnType<typeof resetFiltersAction>;
export const resetFiltersAction = () => ({
  type: RESET_FILTERS as typeof RESET_FILTERS,
});

export type GlobalStatementAction =
  | PollExportCsvRequestAction
  | PollExportCsvSuccessAction
  | PollExportCsvContinueAction
  | PollExportCsvFailureAction
  | PollExportCsvCancelAction
  | TriggerExportCsvRequestAction
  | TriggerExportCsvSuccessAction
  | TriggerExportCsvFailureAction
  | SetNetworkStateAction
  | TotalsRequestAction
  | TotalsSuccessAction
  | TotalsFailureAction
  | TableDataRequestAction
  | TableDataSuccessAction
  | TableDataFailureAction
  | SetPageNumberAction
  | SetItemsPerPageAction
  | SetTotalResultCountAction
  | SetSortByAction
  | SetSortOrderAction
  | SetTpCountryAction
  | SetTravelCompanyAction
  | SetAmountMinAction
  | SetAmountMaxAction
  | ResetFiltersAction;
