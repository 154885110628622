import React, { useLayoutEffect, useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BackLink } from 'ui/BackLink';
import { PageTitle } from 'ui/PageTitle';
import FluidButton from 'ui/FluidButton';

import * as Selectors from 'store/modules/proposalEditor/selectors';
import * as Actions from 'store/modules/proposalEditor/actions';

import { ENetworkRequestStatus } from 'services/BackendApi';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';

interface IProposalRouteParams {
  proposalUuid: string;
}

export const ProposalEditorPage: React.FC = React.memo(() => {
  const dispatch = useDispatch();

  const match = useRouteMatch<IProposalRouteParams>();
  const { proposalUuid } = match.params;

  const proposal = useSelector(Selectors.proposal);
  const googleDocDraftId = useSelector(Selectors.googleDocDraftId);
  const request = useSelector(Selectors.request);

  const initLoading = useSelector(Selectors.initLoading);
  const initError = useSelector(Selectors.initError);

  const url = useMemo(
    () => (googleDocDraftId ? `https://docs.google.com/document/d/${googleDocDraftId}/edit` : undefined),
    [googleDocDraftId]
  );

  useLayoutEffect(() => {
    dispatch(Actions.getRequestAction(proposalUuid));
    dispatch(Actions.draftRequestAction(proposalUuid));
  }, [dispatch, proposalUuid]);

  const handleReset = useCallback(() => dispatch(Actions.draftRequestAction()), [dispatch]);

  const handleDownload = useCallback(() => dispatch(Actions.commitRequestAction()), [dispatch]);

  if (initLoading) {
    return (
      <div className="mt-5">
        <LoadingBar />
      </div>
    );
  }

  if (initError) {
    return (
      <div className="mt-5">
        <ErrorBar />
      </div>
    );
  }

  const backHref = `/proposals-v2/${proposal?.proposalSelectionUuid}/generate?proposal=${proposal?.uuid}`;

  return (
    <div className="proposal-editor container mx-auto max-w-1280px font-pt-sans text-black">
      <BackLink type="internal" href={backHref}>
        Back to proposal
      </BackLink>
      <PageTitle primaryText="Generate Proposal PDF" />
      <div className="content border border-solid border-gray-40 mt-8 p-5">
        <iframe className="editor w-full border-0 min-h-screen" src={url} />
        <div className="controls flex mt-30px">
          <FluidButton
            onClick={handleReset}
            type="secondary"
            className="reset"
            isLoading={request.draft === ENetworkRequestStatus.PENDING}
            disabled={request.commit === ENetworkRequestStatus.PENDING}
          >
            Reset Document
          </FluidButton>
          <FluidButton
            onClick={handleDownload}
            className="download ml-10px"
            type="primary"
            isLoading={request.commit === ENetworkRequestStatus.PENDING}
            disabled={request.draft === ENetworkRequestStatus.PENDING}
          >
            Download PDF
          </FluidButton>
        </div>
      </div>
    </div>
  );
});
