import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Actions from './actions';
import { IBootstrapDomain, initialState } from './model';
import { update } from 'utils/immutable';
import { TCountryCode } from 'interfaces';
import { EInventoryStayListingContractPaymentType, EInventoryStayListingRateType } from 'services/InventoryAPI';

export const inventoryStaysReducer = (
  state: IBootstrapDomain = initialState,
  action: Actions.InventoryBoostrapDomainAction
): IBootstrapDomain => {
  switch (action.type) {
    case Actions.BOOTSTRAP_APP_REQUEST:
      return update(state, s => {
        s.request.getCountries = ENetworkRequestStatus.PENDING;
        s.request.getAllCountries = ENetworkRequestStatus.PENDING;
        s.request.currenciesGet = ENetworkRequestStatus.PENDING;
      });

    case Actions.BOOTSTRAP_APP_SUCCESS:
      return update(state, s => {
        s.request.getCountries = ENetworkRequestStatus.SUCCESS;
        s.request.getAllCountries = ENetworkRequestStatus.SUCCESS;
        s.request.currenciesGet = ENetworkRequestStatus.SUCCESS;
        s.countries = action.countries;
        s.allCountries = action.allCountries;
        s.currencies = action.currencies;
      });

    case Actions.BOOTSTRAP_APP_FAILURE:
      return update(state, s => {
        s.request.getCountries = ENetworkRequestStatus.ERROR;
        s.request.getAllCountries = ENetworkRequestStatus.ERROR;
        s.request.currenciesGet = ENetworkRequestStatus.ERROR;
        s.countries = [];
        s.allCountries = [];
        s.currencies = [];
      });

    default:
      return state;
  }
};

export default inventoryStaysReducer;
