import React from 'react';

interface ITransactionDateColumnProps {
  date: string;
  formatter: (date: string) => string;
  className?: string;
}

export const TransactionDateColumn: React.FC<ITransactionDateColumnProps> = React.memo(
  ({ date, formatter, className }) => {
    return (
      <td className={`${className}`}>
        <span>{formatter(date)}</span>
      </td>
    );
  }
);
