import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import FixedButton from 'ui/FixedButton';
import { EBookingStatus } from 'services/BookingManagerApi/types/BookingStatusResponse';
import { trySettingBookingStatusAction } from 'store/modules/bookingManager/subdomains/bookingStatusOptions/actions';

export interface IBookingStatusButtonProps {
  className?: string;
  type: 'primary' | 'secondary';
  bookingStatus: EBookingStatus;
  text: string;
  onClickOverride?: () => void;
  disabled?: boolean;
  message?: string;
}

export const BookingStatusButton: React.FC<IBookingStatusButtonProps> = ({
  className,
  type,
  bookingStatus,
  text,
  onClickOverride,
  disabled,
  message,
}) => {
  const dispatch = useDispatch();

  const handleBookingStatusChange = useCallback(() => {
    dispatch(trySettingBookingStatusAction(bookingStatus));
  }, [bookingStatus, dispatch]);

  const isNotAnError = (disabled === false && message !== undefined);
  // if button is NOT disabled and there is a message, put the message green and above
  // @see OWA-3720
  return (
    <ButtonWithMessage 
      text={text}
      type={type}
      disabled={disabled}
      className={classNames(className, 'booking-status mr-4')}
      onClick={onClickOverride ? onClickOverride : handleBookingStatusChange}
      message={message}
      isErrorMessage={!isNotAnError}
    />
  );
};

const ButtonWithMessage = ({text, type, disabled, className, onClick, message, isErrorMessage = true}) => {
  return (
    <div className="flex flex-col mr-1">
      {!isErrorMessage && message !== undefined && (
        <span className="absolute top-0 text-green-message text-13px leading-16px">{message}</span>
      )}
      <FixedButton
        text={text}
        type={type}
        disabled={disabled}
        className={classNames(className, 'booking-status mr-4')}
        onClick={onClick}
      />
      {isErrorMessage && message !== undefined && <span className="mt-1 text-red-95 text-sm">{message}</span>}
    </div>
  );
}