import React from 'react';
import { ITravelAgent } from 'services/BackendApi';
import { CheckboxList } from './CheckboxList';
import { isAdmin as isAdminSelector } from 'store/modules/auth';
import { useSelector } from 'react-redux';

interface ITravelAgentOtherOptionsProps {
  travelAgentData: Partial<ITravelAgent>;
  onClick: (fieldName: string, fieldValue: boolean) => void;
}

export const TravelAgentOtherOptions: React.FC<ITravelAgentOtherOptionsProps> = React.memo(
  ({ travelAgentData, onClick }) => {
    const isAdmin = useSelector(isAdminSelector);
    const travelAgentOptions = [
      {
        label: 'Is existing partner',
        name: 'isExistingPartner',
        value: travelAgentData.isExistingPartner ?? false,
      },
      {
        label: 'Share Bookings',
        name: 'companyBookingManager',
        value: travelAgentData.companyBookingManager ?? false,
      },
      {
        label: 'Travel Agent Manager',
        name: 'companyTaManager',
        value: travelAgentData.companyTaManager ?? false,
      },
      {
        label: 'Access Finance Workspace',
        name: 'taCanReadFinance',
        value: travelAgentData.taCanReadFinance ?? false,
      },
    ];

    return (
      <div className="other-options-container pt-10 px-5 pb-30px font-pt-sans">
        <p className="m-0 font-bold text-black-true text-17px leading-22px">Other Options</p>
        <p className="m-0 mt-5px text-black text-15px leading-19px">
          Please indicate whether this Agent should share their bookings with other members of their travel company and
          also whether they should be the Travel manager. If you click Travel manager they will be able to see all other
          agents bookings within their company.
        </p>

        <div className="checkbox-container mt-5">
          <CheckboxList options={travelAgentOptions} onClick={onClick} disabled={!isAdmin} />
        </div>
      </div>
    );
  }
);
