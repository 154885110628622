export const titleOptions = [
  {
    label: "No Title",
    value: "No Title",
  },
  {
    label: "Master",
    value: "Master",
  },
  {
    label: "Miss",
    value: "Miss",
  },
  {
    label: "Mr.",
    value: "Mr.",
  },
  {
    label: "Mrs.",
    value: "Mrs.",
  },
  {
    label: "Ms.",
    value: "Ms.",
  },
];