export const UI_ENQUEUE_NOTIFICATION = 'UI_ENQUEUE_NOTIFICATION';
export const UI_REMOVE_NOTIFICATION = 'UI_REMOVE_NOTIFICATION';
export const SET_IS_BOOKING_SUMMARY_SECTION_COLLAPSED = 'SET_IS_BOOKING_SUMMARY_SECTION_COLLAPSED';
export const UI_ENQUEUE_NOTIFICATION_MULTIPLE = 'UI_ENQUEUE_NOTIFICATION_MULTIPLE';

/**
 * Enqueue notification action
 *
 * @param {object} notification
 * @returns {object}
 */
export const enqueueNotification = notification => ({
  type: UI_ENQUEUE_NOTIFICATION,
  payload: {
    key: new Date().getTime() + Math.random(),
    ...notification,
  },
});

// TODO at some point, just make `enqueueNotification` take a single notification or an array of notifications
export const enqueueNotificationMultiple = notifications => ({
  type: UI_ENQUEUE_NOTIFICATION_MULTIPLE,
  payload: {
    key: new Date().getTime() + Math.random(),
    notifications,
  },
});

/**
 * Remove notification
 *
 * @param {object} payload
 * @returns {object}
 */
export const removeNotification = payload => ({
  type: UI_REMOVE_NOTIFICATION,
  payload,
});

export const setIsBookingSummarySectionCollapsed = payload => {
  return {
    type: SET_IS_BOOKING_SUMMARY_SECTION_COLLAPSED,
    payload,
  };
};
