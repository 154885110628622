import React from 'react';
import * as Inputs from '../Inputs';
import FluidButton from 'ui/FluidButton';
import { ITransferProduct } from 'services/BackendApi';
import { isNil } from 'lodash-es';

export const Transfer = ({
  transfer,
  onUpdate,
  onCta,
  ctaLabel,
}: {
  transfer: Partial<ITransferProduct>;
  onUpdate: (field: keyof any, value: any) => void;
  onCta: () => void;
  ctaLabel: string;
}) => {
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Input
        label={'Name'}
        onChange={val => {
          onUpdate('name', val);
        }}
        value={transfer.name || ''}
      />

      <Inputs.TextArea
        label={'Description'}
        onChange={val => {
          console.log('val', val);
          onUpdate('meta.description', val);
        }}
        value={transfer.meta.description}
        rows={5}
      />

      <Inputs.RadioCollection
        label="Category"
        collection={[...Inputs.transferCategoryTypes].map(mt => {
          return {
            isChecked: transfer.category === mt.value,
            value: mt.value,
            label: mt.label,
          };
        })}
        onChange={val => {
          onUpdate('category', val);
        }}
      />

      <Inputs.Checkbox
        label={'One way'}
        value={transfer.options?.isOneWay || false}
        onChange={val => {
          onUpdate('options', {
            ...transfer.options,
            isOneWay: val,
          });
        }}
      />

      {(transfer.category === 'perPerson' || transfer.category === 'perPersonPerNight') && (
        <Inputs.AgeGroups
          label={'Age Groups'}
          collection={transfer.options?.ages || []}
          onChange={newList => {
            onUpdate('options', {
              ...transfer.options,
              ages: newList,
            });
          }}
        />
      )}

      {transfer.category === 'perBooking' && (
        <Inputs.Input
          label={'Capacity'}
          onChange={val => {
            onUpdate('options.capacity', val === '' ? undefined : parseInt(val));
          }}
          value={transfer.options?.capacity ? transfer.options!.capacity.toString() : ''}
        />
      )}

      <FluidButton
        type="primary"
        className="w-[200px] self-end"
        onClick={() => {
          onCta();
        }}
      >
        {ctaLabel}
      </FluidButton>
    </div>
  );
};
