import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const TimesIcon: React.FC<IIconProps> = React.memo(({ width = 8, height = 9, icon = theme.colors['brown-100'] }) => (
  <svg width={width} height={height} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.50922 4.48865L7.77872 2.21915C8.07376 1.94681 8.07376 1.49291 7.77872 1.22057L7.27943 0.721277C7.00709 0.426241 6.55319 0.426241 6.28085 0.721277L4.01135 2.99078L1.71915 0.721277C1.44681 0.426241 0.992908 0.426241 0.720567 0.721277L0.221277 1.22057C-0.0737589 1.49291 -0.0737589 1.94681 0.221277 2.21915L2.49078 4.48865L0.221277 6.78085C-0.0737589 7.05319 -0.0737589 7.50709 0.221277 7.77943L0.720567 8.27872C0.992908 8.57376 1.44681 8.57376 1.71915 8.27872L4.01135 6.00922L6.28085 8.27872C6.55319 8.57376 7.00709 8.57376 7.27943 8.27872L7.77872 7.77943C8.07376 7.50709 8.07376 7.05319 7.77872 6.78085L5.50922 4.48865Z"
      fill={icon}
    />
  </svg>
));

export default TimesIcon;