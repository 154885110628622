import { call, takeLatest, select, put } from 'redux-saga/effects';
import { makeBackendApi } from 'services/BackendApi';
import qs from 'qs';
import * as Actions from '../actions';
import * as AgentsSelectors from 'store/modules/agents/selectors';
import * as AuthSelectors from 'store/modules/auth/selectors';
import * as FastSearchSelectors from 'store/modules/fastSearch/selectors';

export function* initializeQuerySaga(action: Actions.InitializeQueryAction) {
  try {
    const queryObj = yield qs.parse(action.queryString);
    const sanitizedQuery = yield call(makeBackendApi().sanitizQueryObject, queryObj);
    yield put(Actions.populateQueryAction(sanitizedQuery));
    yield put(Actions.clearExtendedQueryAction());

    yield initializeNameSearchResults(sanitizedQuery.name);

    const selectedTcm = yield select(AgentsSelectors.selectedCompanyMembershipSelector);
    yield put(Actions.setTravelCompanyMembershipAction(selectedTcm));

    const isSR = yield select(AuthSelectors.isInternalUser);
    if (isSR) {
      yield initializeCompanies();
      yield initializeTravelAgents();
    }
  } catch (e) {
    console.log(e);
  }
}

export function* initializeNameSearchResults(s: string) {
  const xs = yield select(FastSearchSelectors.nameSearchResultsRawSelector);
  if (xs) {
    yield put(Actions.namesSearchSuccessAction(xs));
  } else {
    yield put(Actions.destinationChangeAction(s));
  }
}

export function* initializeCompanies() {
  const xs = yield select(AgentsSelectors.companiesSelector);
  if (xs) {
    yield put(Actions.getCompaniesSuccessAction(xs));
  } else {
    yield put(Actions.getCompaniesRequestAction());
  }

  const x = yield select(AgentsSelectors.selectedCompanySelector);
  if (x) {
    yield put(Actions.selectedCompanyChangeAction(x));
  }
}

export function* initializeTravelAgents() {
  const xs = yield select(AgentsSelectors.selectedCompanyAgents);
  if (xs) {
    yield put(Actions.getTravelAgentsByCompanyIdSuccessAction(xs));
  }
  const x = yield select(AgentsSelectors.selectedTaSelector);
  if (x) {
    yield put(Actions.selectedTaChangeAction(x));
  }
}

export function* watchInitializeQuery() {
  yield takeLatest(Actions.INITIALIZE_QUERY, initializeQuerySaga);
}
