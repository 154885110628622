import { ICompany, ITravelAgent } from 'services/BackendApi';
import { ICompanyMembership } from 'services/BookingManagerApi/types/CompanyMemberships';

export const GET_TRAVEL_AGENTS_REQUEST = 'agentsModule/GET_TRAVEL_AGENTS_REQUEST';
export const GET_TRAVEL_AGENTS_SUCCESS = 'agentsModule/GET_TRAVEL_AGENTS_SUCCESS';
export const GET_TRAVEL_AGENTS_FAILURE = 'agentsModule/GET_TRAVEL_AGENTS_FAILURE';
export const GET_TRAVEL_AGENTS_BY_COMPANY_ID_SUCCESS = 'agentsModule/GET_TRAVEL_AGENTS_BY_COMPANY_ID_SUCCESS';
export const GET_TRAVEL_AGENTS_BY_COMPANY_ID_FAILURE = 'agentsModule/GET_TRAVEL_AGENTS_BY_COMPANY_ID_FAILURE';
export const SELECTED_TA_CHANGE = 'agentsModule/SELECTED_TA_CHANGE';
export const SHOW_TA_DROPDOWN = 'agentsModule/SHOW_TA_DROPDOWN';
export const SEARCH_TA_BY_NAME = 'agentsModule/SEARCH_TA_BY_NAME';
export const GET_COMPANIES_REQUEST = 'agentsModule/GET_COMPANIES_REQUEST';
export const GET_COMPANIES_SUCCESS = 'agentsModule/GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILURE = 'agentsModule/GET_COMPANIES_FAILURE';
export const SELECTED_COMPANY_CHANGE = 'agentsModule/SELECTED_COMPANY_CHANGE';
export const CLEAR_SELECTED_TA = 'agentsModule/CLEAR_SELECTED_TA';
export const SHOW_COMPANY_DROPDOWN = 'agentsModule/SHOW_COMPANY_DROPDOWN';
export const SEARCH_COMPANY_BY_NAME = 'agentsModule/SEARCH_COMPANY_BY_NAME';
export const LOAD_STATE = 'agentsModule/LOAD_STATE';

export const LOAD_SELECTED_TA_VIA_UUID_REQUEST = 'agentsModule/LOAD_SELECTED_TA_VIA_UUID_REQUEST';
export const LOAD_SELECTED_TA_VIA_UUID_SUCCESS = 'agentsModule/LOAD_SELECTED_TA_VIA_UUID_SUCCESS';
export const LOAD_SELECTED_TA_VIA_UUID_FAILURE = 'agentsModule/LOAD_SELECTED_TA_VIA_UUID_FAILURE';

export const SAVE_ORIGINAL_SELECTION = 'agentsModule/SAVE_ORIGINAL_SELECTION';
export const RESTORE_ORIGINAL_SELECTION = 'agentsModule/RESTORE_ORIGINAL_SELECTION';

export const GET_MEMBERSHIPS_REQUEST = 'agentsModule/GET_MEMBERSHIPS_REQUEST';
export const GET_MEMBERSHIPS_SUCCESS = 'agentsModule/GET_MEMBERSHIPS_SUCCESS';
export const GET_MEMBERSHIPS_FAILURE = 'agentsModule/GET_MEMBERSHIPS_FAILURE';
export const SET_SELECTED_COMPANY_MEMBERSHIP = 'agentsModule/SET_SELECTED_COMPANY_MEMBERSHIP';

export type GetTravelAgentRequestAction = ReturnType<typeof getTravelAgentsRequestAction>;
export const getTravelAgentsRequestAction = (forceLoad?: boolean) => ({
  type: GET_TRAVEL_AGENTS_REQUEST as typeof GET_TRAVEL_AGENTS_REQUEST,
  forceLoad,
});

export type GetTravelAgentSuccessAction = ReturnType<typeof getTravelAgentsSuccessAction>;
export const getTravelAgentsSuccessAction = (agents: ITravelAgent[]) => ({
  type: GET_TRAVEL_AGENTS_SUCCESS as typeof GET_TRAVEL_AGENTS_SUCCESS,
  agents,
});

export type GetTravelAgentFailureAction = ReturnType<typeof getTravelAgentsFailureAction>;
export const getTravelAgentsFailureAction = (error: string) => ({
  type: GET_TRAVEL_AGENTS_FAILURE as typeof GET_TRAVEL_AGENTS_FAILURE,
  error,
});

export type SelectedTaChangeAction = ReturnType<typeof selectedTaChangeAction>;
export const selectedTaChangeAction = (value: ITravelAgent | null) => ({
  type: SELECTED_TA_CHANGE as typeof SELECTED_TA_CHANGE,
  value,
});

export type ShowTaDropdownAction = ReturnType<typeof showTaDropdownAction>;
export const showTaDropdownAction = (value: boolean) => ({
  type: SHOW_TA_DROPDOWN as typeof SHOW_TA_DROPDOWN,
  value,
});

export type SearchTaByNameAction = ReturnType<typeof searchTaByNameAction>;
export const searchTaByNameAction = (value: string) => ({
  type: SEARCH_TA_BY_NAME as typeof SEARCH_TA_BY_NAME,
  value,
});

export type GetCompaniesRequestAction = ReturnType<typeof getCompaniesRequestAction>;
export const getCompaniesRequestAction = () => ({
  type: GET_COMPANIES_REQUEST as typeof GET_COMPANIES_REQUEST,
});

export type GetCompaniesSuccessAction = ReturnType<typeof getCompaniesSuccessAction>;
export const getCompaniesSuccessAction = (companies: ICompany[]) => ({
  type: GET_COMPANIES_SUCCESS as typeof GET_COMPANIES_SUCCESS,
  companies,
});

export type GetCompaniesFailureAction = ReturnType<typeof getCompaniesFailureAction>;
export const getCompaniesFailureAction = (error: string) => ({
  type: GET_COMPANIES_FAILURE as typeof GET_COMPANIES_FAILURE,
  error,
});

export type SelectedCompanyChangeAction = ReturnType<typeof selectedCompanyChangeAction>;
export const selectedCompanyChangeAction = (value: ICompany | null) => ({
  type: SELECTED_COMPANY_CHANGE as typeof SELECTED_COMPANY_CHANGE,
  value,
});

export type ClearSelectedTaAction = ReturnType<typeof clearSelectedTaAction>;
export const clearSelectedTaAction = () => ({
  type: CLEAR_SELECTED_TA as typeof CLEAR_SELECTED_TA,
});

export type ShowCompanyDropdownAction = ReturnType<typeof showCompanyDropdownAction>;
export const showCompanyDropdownAction = (value: boolean) => ({
  type: SHOW_COMPANY_DROPDOWN as typeof SHOW_COMPANY_DROPDOWN,
  value,
});

export type SearchCompanyByNameAction = ReturnType<typeof searchCompanyByNameAction>;
export const searchCompanyByNameAction = (value: string) => ({
  type: SEARCH_COMPANY_BY_NAME as typeof SEARCH_COMPANY_BY_NAME,
  value,
});

export type GetTravelAgentsByCompanyIdSuccessAction = ReturnType<typeof getTravelAgentsByCompanyIdSuccessAction>;
export const getTravelAgentsByCompanyIdSuccessAction = (agents: ITravelAgent[]) => ({
  type: GET_TRAVEL_AGENTS_BY_COMPANY_ID_SUCCESS as typeof GET_TRAVEL_AGENTS_BY_COMPANY_ID_SUCCESS,
  agents,
});

export type GetTravelAgentsByCompanyIdRequestAction = ReturnType<typeof getTravelAgentsByCompanyIdFailureAction>;
export const getTravelAgentsByCompanyIdFailureAction = (error: string) => ({
  type: GET_TRAVEL_AGENTS_BY_COMPANY_ID_FAILURE as typeof GET_TRAVEL_AGENTS_BY_COMPANY_ID_FAILURE,
  error,
});

export type LoadStateAction = ReturnType<typeof loadStateAction>;
export const loadStateAction = (travelAgentUuid: string) => ({
  type: LOAD_STATE as typeof LOAD_STATE,
  travelAgentUuid,
});

export type LoadSelectedTaViaUuidRequestAction = ReturnType<typeof loadSelectedTaViaUuidRequestAction>;
export const loadSelectedTaViaUuidRequestAction = (tauuid: string) => ({
  type: LOAD_SELECTED_TA_VIA_UUID_REQUEST as typeof LOAD_SELECTED_TA_VIA_UUID_REQUEST,
  tauuid,
});

export type LoadSelectedTaViaUuidSuccessAction = ReturnType<typeof loadSelectedTaViaUuidSuccessAction>;
export const loadSelectedTaViaUuidSuccessAction = (selectedTa: any) => ({
  type: LOAD_SELECTED_TA_VIA_UUID_SUCCESS as typeof LOAD_SELECTED_TA_VIA_UUID_SUCCESS,
  selectedTa,
});

export type LoadSelectedTaViaUuidFailureAction = ReturnType<typeof loadSelectedTaViaUuidFailureAction>;
export const loadSelectedTaViaUuidFailureAction = (error: string) => ({
  type: LOAD_SELECTED_TA_VIA_UUID_FAILURE as typeof LOAD_SELECTED_TA_VIA_UUID_FAILURE,
  error,
});

export type SaveOriginalSelectionAction = ReturnType<typeof saveOriginalSelectionAction>;
export const saveOriginalSelectionAction = () => ({
  type: SAVE_ORIGINAL_SELECTION as typeof SAVE_ORIGINAL_SELECTION,
});

export type RestoreOriginalSelectionAction = ReturnType<typeof restoreOriginalSelectionAction>;
export const restoreOriginalSelectionAction = () => ({
  type: RESTORE_ORIGINAL_SELECTION as typeof RESTORE_ORIGINAL_SELECTION,
});

export type GetMembershipsRequestAction = ReturnType<typeof getMembershipsRequestAction>;
export const getMembershipsRequestAction = (
  companyUuid: string,
  resetSelectedToFirst = true,
  setInBookingBuilder = false
) => ({
  type: GET_MEMBERSHIPS_REQUEST as typeof GET_MEMBERSHIPS_REQUEST,
  companyUuid,
  resetSelected: resetSelectedToFirst,
  setInBookingBuilder,
});

export type GetMembershipsSuccessAction = ReturnType<typeof getMembershipsSuccessAction>;
export const getMembershipsSuccessAction = (memberships: ICompanyMembership[], resetSelectedToFirst = true) => ({
  type: GET_MEMBERSHIPS_SUCCESS as typeof GET_MEMBERSHIPS_SUCCESS,
  memberships,
  resetSelected: resetSelectedToFirst,
});

export type GetMembershipsFailureAction = ReturnType<typeof getMembershipsFailureAction>;
export const getMembershipsFailureAction = () => ({
  type: GET_MEMBERSHIPS_FAILURE as typeof GET_MEMBERSHIPS_FAILURE,
});

export type SetSelectedCompanyMembershipAction = ReturnType<typeof setSelectedCompanyMembershipAction>;
export const setSelectedCompanyMembershipAction = (companyMembership: ICompanyMembership | null) => ({
  // OWA-5724
  type: SET_SELECTED_COMPANY_MEMBERSHIP as typeof SET_SELECTED_COMPANY_MEMBERSHIP,
  companyMembership,
});

export type AgentsModuleAction =
  | GetTravelAgentRequestAction
  | GetTravelAgentSuccessAction
  | GetTravelAgentFailureAction
  | SelectedTaChangeAction
  | ShowTaDropdownAction
  | SearchTaByNameAction
  | GetCompaniesRequestAction
  | GetCompaniesSuccessAction
  | GetCompaniesFailureAction
  | SelectedCompanyChangeAction
  | ShowCompanyDropdownAction
  | SearchCompanyByNameAction
  | ClearSelectedTaAction
  | GetTravelAgentsByCompanyIdSuccessAction
  | GetTravelAgentsByCompanyIdRequestAction
  | LoadStateAction
  | LoadSelectedTaViaUuidRequestAction
  | LoadSelectedTaViaUuidSuccessAction
  | LoadSelectedTaViaUuidFailureAction
  | SaveOriginalSelectionAction
  | RestoreOriginalSelectionAction
  | GetMembershipsRequestAction
  | GetMembershipsSuccessAction
  | GetMembershipsFailureAction
  | SetSelectedCompanyMembershipAction;
