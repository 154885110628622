import React, { CSSProperties, useCallback } from 'react';
import classNames from 'classnames';

export { CircleIconButtonWithTooltip } from './WithTooltip';

export interface IIconProps {
  background?: string;
  border?: string;
  fill?: string;
  stroke?: string;
  icon?: string;
  width?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
}

export interface ICircleIconButtonProps {
  type: 'primary' | 'secondary';
  iconClass: string;
  iconSrc?: string;
  style?: CSSProperties;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

export const CircleIconButton = (props: ICircleIconButtonProps) => {
  let buttonClassName = classNames(
    `default:m-0 default:rounded-full default:border default:border-solid font-pt-sans flex justify-center items-center default:outline-none default:cursor-pointer`,
    {
      'bg-brown-80 border-brown-80 hover:bg-brown-120': props.type === 'primary' && !props.disabled,
      'bg-white border-brown-80 hover:bg-brown-20': props.type === 'secondary' && !props.disabled,
      'bg-gray-80 border-gray-80': props.type === 'primary' && props.disabled,
      'bg-white border-gray-80': props.type === 'secondary' && props.disabled,
    }
  );

  // hack to make the button adjust with the various possible font awesome sizes
  if (props.iconClass.includes('fa-xs') || props.iconClass.includes('fa-sm')) {
    buttonClassName = `${buttonClassName} w-6 h-6`;
  } else if (props.iconClass.includes('fa-lg')) {
    buttonClassName = `${buttonClassName} w-10 h-10`;
  } else if (props.iconClass.includes('fa-2x')) {
    buttonClassName = `${buttonClassName} w-12 h-12`;
  } else if (props.iconClass.includes('fa-3x')) {
    buttonClassName = `${buttonClassName} w-14 h-14`;
  } else {
    buttonClassName = `${buttonClassName} w-30px h-30px`;
  }

  if (props.className != undefined) {
    buttonClassName = `${buttonClassName} ${props.className}`;
  }

  const iconClass = classNames(`text-sm ${props.iconClass}`, {
    'block text-white': props.type === 'primary',
    'block text-brown-80': props.type === 'secondary' && !props.disabled,
    'block text-gray-80': props.type === 'secondary' && props.disabled,
  });

  const handleClick = useCallback(() => {
    !props.disabled && props.onClick && props.onClick();
  }, [props]);

  return (
    <span
      className={classNames(buttonClassName, {
        'cursor-pointer': !props.disabled,
        'cursor-default opacity-60': props.disabled,
      })}
      style={props.style ?? {}}
      onClick={handleClick}
    >
      {props.iconSrc ? <img className={iconClass} src={props.iconSrc} /> : <i className={iconClass} />}
    </span>
  );
};
