import React, { useCallback } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { PriceAsCentsInput } from '../stateful/PriceAsCentsInput';
import { IHeadlineLineItemBreakdownLineItem } from 'services/BookingManagerApi';
import { toClassName } from './helpers';
import { ClosingProductsRow } from './ClosingProductsRow';
import { RemoveHeadlineButton, EditHeadlineButton } from './HeadlineActionButtons';
import { EmptyHeaderProducts } from './EmptyHeaderProducts';
import { formatDateDisplay } from 'utils';
import classNames from 'classnames';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import { theme } from '../../../tailwind.config';
import { format } from 'date-fns';
import DateRangeInput from 'pureUi/DateRangeInput';
import { Multiselect } from 'ui/Multiselect';
import { isNil } from 'lodash-es';
import { EAncillaryProductType, SuppliersSearchItem } from 'services/BookingManagerApi/types/AncillaryService';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { ProductTypes } from 'config/enums';
// @ts-ignore Styled component errors
const StyledDateRangeInput = styled(DateRangeInput)`
  .pseudoSelect {
    background: ${theme.colors['ivory']};
    height: 37px;
    border-color: ${theme.colors['gray-40']};
  }
  .displayString {
    text-transform: none;
    font-family: 'PT Sans', sans-serif;
  }
`;

interface IGenericProductsBlock {
  pathPrefix?: string;
  className?: string;
  productType: string; // this is what they get stored in the DB as e.g 'Accommodation', 'Bespoke', 'Transfer'
  productDisplayType: string; // this is what we want them to render as e.g Activity is what we render Bespoke as
  lineItems: IHeadlineLineItemBreakdownLineItem[];
  bookingCurrencySymbol?: string | null;
  subTotalCents: number;
  includeTableHeaders?: boolean;
  allowNegative?: boolean;
  setHeadlineLineItemFieldAction: typeof BreakdownActions.setHeadlineLineItemFieldAction;
  removeHeadlineLineItemAction: (removePath: string, index: number) => void;
  isPurchaseCostEditable: boolean;
  isEditable: boolean;
  cta?: React.ReactElement;
  isSR: boolean;
  isAdmin: boolean;
  isFinanceUser: boolean;
  isLiveRate?: boolean;
  titlePlaceholder?: string;
  tertiaryTextPlaceholder?: string;
  isAccommodationSubBlock?: boolean; // if this is true, we need to fire the accommodation modified when user changes prices
  suppliers: SuppliersSearchItem[];
}

// necessary because we dont have placeholder utility classes
const LineItemInput = styled.input`
  &::placeholder {
    font-style: italic;
  }
`;

const NonAncillaryProduct = ({
  lineItem,
  index,
  keyPrefix,
  isEditable,
  setHeadlineLineItemFieldActionCallback,
  pathPrefix,
  productType,
  productDisplayType,
  includeTableHeaders,
  lineItems,
  isPurchaseCostVisible,
  titlePlaceholder,
  tertiaryTextPlaceholder,
  isPurchaseCostDisabled,
  allowNegative,
  isPurchaseCostEditable,
  setIsAccommodationModifiedAction,
  isSalesCostEditable,
  removeHeadlineLineItemAction,
  suppliers,
  isAdmin,
  isSR,
  isFinanceUser,
  isTimeVisible,
}: {
  lineItem: IHeadlineLineItemBreakdownLineItem;
  index: number;
  keyPrefix: string;
  isEditable: boolean;
  setHeadlineLineItemFieldActionCallback: typeof BreakdownActions.setHeadlineLineItemFieldAction;
  pathPrefix: string;
  productType: string;
  productDisplayType: string;
  includeTableHeaders: boolean;
  lineItems: IHeadlineLineItemBreakdownLineItem[];
  isPurchaseCostVisible: boolean;
  titlePlaceholder: string;
  tertiaryTextPlaceholder: string;
  isPurchaseCostDisabled: boolean;
  allowNegative: boolean;
  isPurchaseCostEditable: boolean;
  setIsAccommodationModifiedAction: () => void;
  isSalesCostEditable: boolean;
  removeHeadlineLineItemAction: (removePath: string, index: number) => void;
  suppliers: SuppliersSearchItem[];
  isAdmin: boolean;
  isSR: boolean;
  isFinanceUser: boolean;
  isTimeVisible: boolean;
}) => {
  return (
    <div key={`${keyPrefix}-wrapper`} data-index={index} className="group flex flex-col space-y-[10px]">
      {/* title and supplier and confirmation num */}
      <div
        key={`htop-non-ancillary-${productDisplayType}-${index}`}
        className={`title p-0 non-ancillary-${productDisplayType} w-full flex flex-row items-baseline justify-around`}
      >
        {/* title */}
        <h2 className="text-17px text-left flex-grow m-0">
          {productDisplayType} {index + 1}
        </h2>

        {/* supplier and confirmation num and CTAs */}
        <div className="flex flex-row space-x-4 items-baseline">
          {/* supplier */}
          <span
            // the width is the width of the date, time and purchase cost + spacings between those
            className={classNames('relative w-[382px]', {
              hidden: !isAdmin && !isSR && !isFinanceUser, // dont show the supplier at all if theyre a ta
            })}
          >
            {includeTableHeaders && (
              <span className="hidden group-data-[index='0']:block text-gray-darker text-xs uppercase font-pt-sans text-right absolute left-0 -top-5">
                Supplier
              </span>
            )}
            <Multiselect
              className="bg-white w-full"
              itemsClassname="bg-white"
              itemCtaClassName="hover:bg-gray-10"
              options={suppliers.map(s => ({ label: s.name, value: s.id.toString() }))}
              isCloseOnSelect={true}
              hideCheckboxes={true}
              isSingleSelectMode={true}
              onUpdate={sv => {
                if (sv.length <= 0 || isNil(sv[0])) {
                  setHeadlineLineItemFieldActionCallback(
                    `${pathPrefix}${productType}.items[${index}].supplierData`,
                    undefined
                  );
                }

                const supplierData = suppliers.find(s => s.id === parseInt(sv[0]));

                if (isNil(supplierData)) {
                  return;
                }

                setHeadlineLineItemFieldActionCallback(
                  `${pathPrefix}${productType}.items[${index}].supplierData`,
                  supplierData
                );
              }}
              selectedValues={lineItem.supplierData ? [lineItem.supplierData.id.toString()] : []}
            />
          </span>

          {/* confirmation num */}
          <span className="w-[130px] relative" key={`htop-non-ancillary-${productDisplayType}-${index}-col-1`}>
            {includeTableHeaders && (
              <span className="hidden group-data-[index='0']:block text-gray-darker text-xs uppercase font-pt-sans text-right absolute right-0 -top-5">
                Confirmation N.
              </span>
            )}
            <input
              id={`${keyPrefix}-${index}-confirmation-number`}
              key={`${keyPrefix}-${index}-confirmation-number`}
              placeholder={'Confirmation N.'}
              value={lineItem.itemConfirmationNumber}
              type="text"
              readOnly={!isEditable}
              className={classnames(
                'w-full resort-confirmation-number-input focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 font-pt-sans',
                {
                  'bg-gray-10': isEditable === false,
                }
              )}
              onChange={event => {
                setHeadlineLineItemFieldActionCallback(
                  `${pathPrefix}${productType}.items[${index}].itemConfirmationNumber`,
                  event.target.value
                );
              }}
            />
          </span>

          {/* the remove button */}
          {isEditable === true && (
            <span className="w-[53px] flex items-baseline justify-end">
              <RemoveHeadlineButton
                action={removeHeadlineLineItemAction}
                pathPrefix={pathPrefix}
                productType={productType}
                index={index}
              />
            </span>
          )}
        </div>
      </div>

      {/* row for the title, date, time, purchase cost, cost to tp, etc. */}
      <div
        key={`r-${index}`}
        className={classNames('flex flex-row justify-end items-center space-x-4', {
          'w-[calc(100%-69px)]': isEditable,
          'w-full': !isEditable,
        })}
      >
        {/* title */}
        <span className="flex-grow">
          <label className="hidden group-data-[index='0']:block text-gray-darker text-xs uppercase text-left font-pt-sans mb-5px">
            Title
          </label>
          <LineItemInput
            key={`${keyPrefix}-${index}-title`}
            disabled={isEditable === false}
            className={classnames(
              'focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 w-full font-pt-sans',
              {
                'bg-gray-10': isEditable === false,
              }
            )}
            value={lineItem.title}
            placeholder={titlePlaceholder || 'Line Item Title'}
            onChange={event => {
              setHeadlineLineItemFieldActionCallback(
                `${pathPrefix}${productType}.items[${index}].title`,
                event.target.value
              );
            }}
          />
        </span>

        {/* date */}
        <span className="w-[95px]">
          <label className="hidden group-data-[index='0']:block text-gray-darker text-xs uppercase text-left font-pt-sans mb-5px">
            Date
          </label>
          <DatePickerStateProvider
            isSingleDateSelection={true}
            defaultSelectedDates={[lineItem.selectedDate || '']}
            onDateChange={dates => {
              setHeadlineLineItemFieldActionCallback(
                `${pathPrefix}${productType}.items[${index}].selectedDate`,
                format(new Date(dates[0]), 'yyyy-MM-dd')
              );
            }}
            placeholder="Select date"
            render={(params: IDatePickerSateParams) => {
              return (
                <span className="block">
                  <StyledDateRangeInput
                    disabled={isEditable === false}
                    displayString={params.displayString}
                    currentDate={params.datePickerCurrentDate}
                    selectedDates={[...params.selectedDates]}
                    onDayClick={params.handleDayClick}
                    onDayMouseOver={params.handleDateMouseOver}
                    showDatePicker={params.showDatePicker}
                    onNextClick={params.incrementDate}
                    onPrevClick={params.decrementDate}
                    onMouseDown={params.toggleDatePicker}
                    onClickOutside={params.hideDatePicker}
                    // placeholder={'placeholder test'}
                    showTotalNights={false}
                    enablePastDates
                    noPortal
                  />
                </span>
              );
            }}
          />
        </span>

        {/* time */}
        {isTimeVisible && (
          <span className="w-[125px]">
            <label className="hidden group-data-[index='0']:block text-gray-darker text-xs uppercase text-left font-pt-sans mb-5px">
              Local Time
            </label>
            <input
              onChange={e => {
                const path = !isNil(lineItem.customData)
                  ? `${pathPrefix}${productType}.items[${index}].customData.time`
                  : `${pathPrefix}${productType}.items[${index}].extraData.time`;
                setHeadlineLineItemFieldActionCallback(path, e.currentTarget.value);
              }}
              type="time"
              className={classNames(
                'arrival-time-input p-2 border border-solid border-gray-40 min-h-35px max-h-37px w-full font-pt-sans text-sm'
              )}
              value={lineItem.customData?.time || lineItem.extraData?.time}
              disabled={isEditable === false}
            />
          </span>
        )}

        {/* purchase cost */}
        {isPurchaseCostVisible && (
          <span className="w-[130px]">
            <label className="hidden group-data-[index='0']:block text-gray-darker text-xs uppercase text-right font-pt-sans mb-5px">
              Purchase Cost
            </label>
            {/* cost before discount price */}
            <PriceAsCentsInput
              disabled={isPurchaseCostDisabled}
              allowNegative={allowNegative}
              key={`${keyPrefix}-${index}-purchaseCostCents`}
              className={classnames(
                'focus:outline-gray-80 bg-blue-10  text-black cost-before-purchase-cost border text-base text-right border-solid border-gray-40 p-2 w-full font-pt-sans',
                {
                  'bg-gray-10': isPurchaseCostEditable === false,
                }
              )}
              cents={lineItem.purchaseCostCents || 0}
              onBlurUpdate={val => {
                setHeadlineLineItemFieldActionCallback(
                  `${pathPrefix}${productType}.items[${index}].purchaseCostCents`,
                  val
                );
              }}
              onChange={setIsAccommodationModifiedAction}
            />
          </span>
        )}

        {/* cost to tp */}
        <span className="w-[130px]">
          <label className="hidden group-data-[index='0']:block text-gray-darker text-xs uppercase text-right font-pt-sans mb-5px">
            Cost To TP
          </label>
          <PriceAsCentsInput
            disabled={!isSalesCostEditable}
            allowNegative={allowNegative}
            key={`${keyPrefix}-${index}-saleCostCents`}
            className={classnames(
              'focus:outline-gray-80 text-black cost-to-ta border text-base text-right border-solid border-gray-40 p-2 w-full font-pt-sans',
              {
                'bg-gray-10': !isSalesCostEditable,
              }
            )}
            cents={lineItem.saleCostCents || 0}
            onBlurUpdate={val => {
              return setHeadlineLineItemFieldActionCallback(
                `${pathPrefix}${productType}.items[${index}].saleCostCents`,
                val
              );
            }}
            onChange={setIsAccommodationModifiedAction}
          />
        </span>
      </div>

      {/* row for the description */}
      {/* width is 100% minus purcase cost and cost to tp and spacing  */}
      <div
        className={classNames({
          'w-[calc(100%-69px-130px-16px)]': isEditable,
          'w-[calc(100%-130px-16px)]': !isEditable && isPurchaseCostVisible,
          'w-[calc(100%-105px-16px)]': !isEditable && !isPurchaseCostVisible,
        })}
      >
        <LineItemInput
          disabled={isEditable === false}
          className={classnames(
            'focus:outline-gray-80 text-black details border text-base border-solid border-gray-40 p-2 w-full font-pt-sans',
            {
              'bg-gray-10': isEditable === false,
            }
          )}
          value={lineItem.tertiaryText}
          placeholder={tertiaryTextPlaceholder || 'Line Item Description'}
          onChange={event => {
            setHeadlineLineItemFieldActionCallback(
              `${pathPrefix}${productType}.items[${index}].tertiaryText`,
              event.target.value
            );
          }}
        />
      </div>
    </div>
  );
};

const AncillaryProduct = ({
  lineItem,
  index,
  keyPrefix,
  isEditable,
  setHeadlineLineItemFieldActionCallback,
  pathPrefix,
  productType,
  productDisplayType,
  includeTableHeaders,
  lineItems,
  isPurchaseCostVisible,
  titlePlaceholder,
  tertiaryTextPlaceholder,
  isPurchaseCostDisabled,
  allowNegative,
  isPurchaseCostEditable,
  setIsAccommodationModifiedAction,
  isSalesCostEditable,
  removeHeadlineLineItemAction,
  editHeadlineLineItemAction,
  isAdmin,
  isSR,
  isFinanceUser,
  suppliers,
  isTimeVisible,
}: {
  lineItem: IHeadlineLineItemBreakdownLineItem;
  index: number;
  keyPrefix: string;
  isEditable: boolean;
  setHeadlineLineItemFieldActionCallback: typeof BreakdownActions.setHeadlineLineItemFieldAction;
  pathPrefix: string;
  productType: string;
  productDisplayType: string;
  includeTableHeaders: boolean;
  lineItems: IHeadlineLineItemBreakdownLineItem[];
  isPurchaseCostVisible: boolean;
  titlePlaceholder: string;
  tertiaryTextPlaceholder: string;
  isPurchaseCostDisabled: boolean;
  allowNegative: boolean;
  isPurchaseCostEditable: boolean;
  setIsAccommodationModifiedAction: () => void;
  isSalesCostEditable: boolean;
  removeHeadlineLineItemAction: (removePath: string, index: number) => void;
  editHeadlineLineItemAction: () => void;
  isAdmin: boolean;
  isSR: boolean;
  isFinanceUser: boolean;
  suppliers: SuppliersSearchItem[];
  isTimeVisible: boolean;
}) => {
  const { dynamicParameters } = useDynamicParameters();

  const renderEditButton =
    (dynamicParameters.ENABLE_EDIT_ACTIVITIES_MODAL === true &&
      lineItem.ancillaryData?.product?.productType === EAncillaryProductType.ACTIVITY) ||
    (dynamicParameters.ENABLE_EDIT_GROUND_SERVICES_MODAL === true &&
      lineItem.ancillaryData?.product?.productType === EAncillaryProductType.GROUND_SERVICE) ||
    (dynamicParameters.ENABLE_EDIT_TRANSFERS_MODAL &&
      lineItem.ancillaryData?.product?.productType === EAncillaryProductType.TRANSFER);

  return (
    <div key={`${keyPrefix}-wrapper`} data-index={index} className="group flex flex-col space-y-[10px]">
      {/* title, supplier, confirmation number */}
      <div
        key={`htop-${index}`}
        className={`title p-0 ancillary-${productDisplayType} flex flex-row justify-between items-baseline`}
      >
        <h2 className="text-17px text-left flex-grow m-0">
          {productDisplayType} {index + 1}
        </h2>
        {/* supplier, confirmation, ctas */}
        <div className="flex flex-row space-x-4 items-baseline">
          {/* supplier */}
          <span // the width is the width of the date, time and purchase cost + spacings between those
            className={classNames('relative w-[382px]', {
              hidden: !isAdmin && !isSR && !isFinanceUser, // dont show the supplier at all if theyre a ta
            })}
          >
            {includeTableHeaders && (
              <span className="text-gray-darker text-xs uppercase font-pt-sans hidden group-data-[index='0']:block absolute left-0 -top-5">
                Supplier
              </span>
            )}
            <Multiselect
              className="bg-gray-10 o:cursor-auto"
              primaryCtaClassName="o:cursor-auto"
              itemsClassname="bg-gray-10 o:cursor-auto"
              itemCtaClassName="hover:bg-gray-10 o:cursor-auto"
              options={suppliers.map(s => ({ label: s.name, value: s.id.toString() }))}
              isCloseOnSelect={true}
              hideCheckboxes={true}
              isSingleSelectMode={true}
              disabled={true}
              hideDropdownArrow={true}
              onUpdate={sv => {
                // void
              }}
              selectedValues={
                lineItem.ancillaryData?.product?.supplier
                  ? [lineItem.ancillaryData?.product?.supplier.supplierId.toString()]
                  : []
              }
            />
          </span>

          {/* confirmation number */}
          <span className="w-[130px] relative" key={`htop-non-ancillary-${productDisplayType}-${index}-col-1`}>
            {includeTableHeaders && (
              <span className="text-gray-darker text-xs uppercase font-pt-sans hidden group-data-[index='0']:block absolute right-0 -top-5">
                Confirmation N.
              </span>
            )}
            <input
              id={`${keyPrefix}-${index}-confirmation-number`}
              key={`${keyPrefix}-${index}-confirmation-number`}
              placeholder={'Confirmation N.'}
              value={lineItem.itemConfirmationNumber}
              type="text"
              readOnly={!isEditable}
              className={classnames(
                'resort-confirmation-number-input focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 font-pt-sans w-full',
                {
                  'bg-gray-10': isEditable === false,
                }
              )}
              onChange={event => {
                setHeadlineLineItemFieldActionCallback(
                  `${pathPrefix}${productType}.items[${index}].itemConfirmationNumber`,
                  event.target.value
                );
              }}
            />
          </span>

          {/* the edit and remove buttons */}
          {isEditable === true && (
            <span className="w-[53px] flex items-baseline justify-end space-x-5px">
              {renderEditButton && (
                <EditHeadlineButton
                  action={editHeadlineLineItemAction}
                  pathPrefix={pathPrefix}
                  productType={productType}
                  index={index}
                />
              )}
              <RemoveHeadlineButton
                action={removeHeadlineLineItemAction}
                pathPrefix={pathPrefix}
                productType={productType}
                index={index}
              />
            </span>
          )}
        </div>
      </div>

      {/* title, date, time, etc. */}
      <div
        key={`r-${index}`}
        className={classNames('flex flex-row justify-end items-center space-x-4', {
          'w-[calc(100%-69px)]': isEditable,
          'w-full': !isEditable,
        })}
      >
        {/* title */}
        <span className="flex-grow">
          <label className="hidden group-data-[index='0']:block text-gray-darker text-xs uppercase text-left font-pt-sans mb-5px">
            Title
          </label>
          <LineItemInput
            disabled={true}
            className={classnames(
              'focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 w-full font-pt-sans',
              {
                'bg-gray-10': true,
              }
            )}
            value={lineItem.title}
            placeholder={titlePlaceholder || 'Line Item Title'}
            onChange={event => {
              setHeadlineLineItemFieldActionCallback(
                `${pathPrefix}${productType}.items[${index}].title`,
                event.target.value
              );
            }}
          />
        </span>

        {/* date */}
        <span
          className={classNames({
            'w-[95px]': isTimeVisible,
            'w-[226px]': !isTimeVisible,
          })}
        >
          <label className="hidden group-data-[index='0']:block text-gray-darker text-xs uppercase text-left font-pt-sans mb-5px">
            Date
          </label>
          <LineItemInput
            disabled={true}
            className={classnames(
              'focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 w-full font-pt-sans',
              {
                'bg-gray-10': true,
              }
            )}
            value={formatDateDisplay(lineItem.ancillaryData?.userSelections.selectedDate)}
            placeholder={'Line Item Dates'}
            onChange={event => {
              setHeadlineLineItemFieldActionCallback(
                `${pathPrefix}${productType}.items[${index}].dateString`,
                event.target.value
              );
            }}
          />
        </span>

        {/* time */}
        {isTimeVisible && (
          <span className="w-[125px]">
            <label className="hidden group-data-[index='0']:block text-gray-darker text-xs uppercase text-left font-pt-sans mb-5px">
              Local Time
            </label>
            <input
              disabled={true}
              type="time"
              className={classNames(
                'arrival-time-input p-2 border border-solid border-gray-40 min-h-35px max-h-39px w-full font-pt-sans text-base',
                {
                  'bg-gray-10': true,
                }
              )}
              value={lineItem.ancillaryData?.userSelections.time}
            />
          </span>
        )}

        {/* purchase cost */}
        {isPurchaseCostVisible && (
          <span className="w-[130px]">
            <label className="hidden group-data-[index='0']:block text-gray-darker text-xs uppercase text-right font-pt-sans mb-5px">
              Purchase Cost
            </label>
            {/* cost before discount price */}
            <PriceAsCentsInput
              disabled={isPurchaseCostDisabled}
              allowNegative={allowNegative}
              key={`${keyPrefix}-${index}-purchaseCostCents`}
              className={classnames(
                'focus:outline-gray-80 bg-blue-10  text-black cost-before-purchase-cost border text-base text-right border-solid border-gray-40 p-2 w-full font-pt-sans',
                {
                  'bg-gray-10': isPurchaseCostEditable === false,
                }
              )}
              cents={lineItem.purchaseCostCents || 0}
              onBlurUpdate={val => {
                setHeadlineLineItemFieldActionCallback(
                  `${pathPrefix}${productType}.items[${index}].purchaseCostCents`,
                  val
                );
              }}
              onChange={setIsAccommodationModifiedAction}
            />
          </span>
        )}

        {/* cost to tp */}
        <span className="w-[130px]">
          <label className="hidden group-data-[index='0']:block text-gray-darker text-xs uppercase text-right font-pt-sans mb-5px">
            Cost to TP
          </label>
          <PriceAsCentsInput
            disabled={!isSalesCostEditable}
            allowNegative={allowNegative}
            key={`${keyPrefix}-${index}-saleCostCents`}
            className={classnames(
              'focus:outline-gray-80 text-black cost-to-ta border text-base text-right border-solid border-gray-40 p-2 w-full font-pt-sans',
              {
                'bg-gray-10': !isSalesCostEditable,
              }
            )}
            cents={lineItem.saleCostCents || 0}
            onBlurUpdate={val => {
              setHeadlineLineItemFieldActionCallback(`${pathPrefix}${productType}.items[${index}].saleCostCents`, val);
            }}
            onChange={setIsAccommodationModifiedAction}
          />
        </span>
      </div>

      {/* the tertiary text row */}
      <div
        className={classNames({
          'w-[calc(100%-69px-130px-16px)]': isEditable,
          'w-[calc(100%-130px-16px)]': !isEditable,
          'w-[calc(100%-105px-16px)]': !isEditable && !isPurchaseCostVisible,
        })}
      >
        <span>
          <LineItemInput
            disabled={true}
            className={classnames(
              'focus:outline-gray-80 text-black details border text-base border-solid border-gray-40 p-2 w-full font-pt-sans',
              {
                'bg-gray-10': true,
              }
            )}
            value={lineItem.tertiaryText}
            placeholder={tertiaryTextPlaceholder || 'Line Item Description'}
            onChange={event => {
              setHeadlineLineItemFieldActionCallback(
                `${pathPrefix}${productType}.items[${index}].tertiaryText`,
                event.target.value
              );
            }}
          />
        </span>
      </div>
    </div>
  );
};

export const GenericProductsBlock = (props: IGenericProductsBlock) => {
  const {
    pathPrefix = '',
    className = '',
    lineItems,
    includeTableHeaders = false,
    productType,
    productDisplayType,
    subTotalCents,
    allowNegative,
    setHeadlineLineItemFieldAction,
  } = props;

  const isEditable = props.isEditable && !props.isLiveRate;
  const isPurchaseCostVisible = props.isSR || props.isFinanceUser;
  const isPurchaseCostDisabled = !props.isPurchaseCostEditable || !isEditable;

  let isSalesCostEditable = props.isEditable;
  if (props.isLiveRate) {
    isSalesCostEditable = isSalesCostEditable && (props.isAdmin || props.isSR);
  }

  const dispatch = useDispatch();

  const setIsAccommodationModifiedAction = useCallback(() => {
    if (props.isAccommodationSubBlock) {
      dispatch(BreakdownActions.setIsAccommodationModifiedAction(true));
    }
  }, [dispatch, props.isAccommodationSubBlock]);

  return (
    <div
      className={`product ${toClassName(props.productType)} ${className} w-full flex flex-col space-y-5`}
      key={props.productType}
    >
      {lineItems.map((lineItem, index) => {
        if (lineItem.ancillaryData) {
          return (
            <AncillaryProduct
              key={`ancillary-${productDisplayType}-${index}`}
              lineItem={lineItem}
              index={index}
              keyPrefix={`ancillary-${productDisplayType}-${index}`}
              isEditable={isEditable}
              setHeadlineLineItemFieldActionCallback={setHeadlineLineItemFieldAction}
              pathPrefix={pathPrefix}
              productType={productType}
              productDisplayType={productDisplayType}
              includeTableHeaders={includeTableHeaders}
              lineItems={lineItems}
              isTimeVisible={[ProductTypes.TRANSFER as string, ProductTypes.GROUND_SERVICE as string].includes(
                productType
              )}
              isPurchaseCostVisible={isPurchaseCostVisible}
              titlePlaceholder={props.titlePlaceholder || 'Line Item Title'}
              tertiaryTextPlaceholder={props.tertiaryTextPlaceholder || 'Line Item Description'}
              isPurchaseCostDisabled={isPurchaseCostDisabled}
              allowNegative={allowNegative || false}
              isPurchaseCostEditable={props.isPurchaseCostEditable}
              setIsAccommodationModifiedAction={setIsAccommodationModifiedAction}
              isSalesCostEditable={isSalesCostEditable}
              removeHeadlineLineItemAction={props.removeHeadlineLineItemAction}
              editHeadlineLineItemAction={() => {
                dispatch(BreakdownActions.setCurrentlyEditingLineItemAction(lineItem, index));
              }}
              isAdmin={props.isAdmin}
              isSR={props.isSR}
              isFinanceUser={props.isFinanceUser}
              suppliers={props.suppliers}
            />
          );
        }

        return (
          <NonAncillaryProduct
            suppliers={props.suppliers}
            key={`non-ancillary-${productDisplayType}-${index}`}
            lineItem={lineItem}
            index={index}
            keyPrefix={`non-ancillary-${productDisplayType}-${index}`}
            isEditable={isEditable}
            setHeadlineLineItemFieldActionCallback={setHeadlineLineItemFieldAction}
            pathPrefix={pathPrefix}
            productType={productType}
            productDisplayType={productDisplayType}
            includeTableHeaders={includeTableHeaders}
            lineItems={lineItems}
            isTimeVisible={[ProductTypes.TRANSFER as string, ProductTypes.GROUND_SERVICE as string].includes(
              productType
            )}
            isPurchaseCostVisible={isPurchaseCostVisible}
            titlePlaceholder={props.titlePlaceholder || 'Line Item Title'}
            tertiaryTextPlaceholder={props.tertiaryTextPlaceholder || 'Line Item Description'}
            isPurchaseCostDisabled={isPurchaseCostDisabled}
            allowNegative={allowNegative || false}
            isPurchaseCostEditable={props.isPurchaseCostEditable}
            setIsAccommodationModifiedAction={setIsAccommodationModifiedAction}
            isSalesCostEditable={isSalesCostEditable}
            removeHeadlineLineItemAction={props.removeHeadlineLineItemAction}
            isAdmin={props.isAdmin}
            isSR={props.isSR}
            isFinanceUser={props.isFinanceUser}
          />
        );
      })}

      <ClosingProductsRow
        productDisplayType={productDisplayType}
        subTotalCents={subTotalCents}
        bookingCurrencySymbol={props.bookingCurrencySymbol}
        cta={props.cta}
        hasItems={lineItems.length >= 1}
        isEditable={props.isEditable}
      />
    </div>
  );
};
