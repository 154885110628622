import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBackendApi } from 'services/BackendApi';
import { normalize } from 'normalizr';
import { hotel as hotelSchema } from 'api/schema';

import * as AgentsSelectors from 'store/modules/agents/selectors';
import * as HotelActions from './actions';

export function* getHotelSaga(action: ReturnType<typeof HotelActions.fetchHotelRequestAction>) {
  const selectedTa = yield select(AgentsSelectors.selectedTaSelector);
  const backendApi = makeBackendApi(selectedTa?.uuid);

  try {
    const response: AxiosResponse = yield call(backendApi.getHotel, action.hotelUuid, ['uploads']);
    const normalized = normalize(response.data.data, hotelSchema);
    yield put(HotelActions.fetchHotelSuccessAction(normalized));
  } catch (e) {
    console.error(e);
    yield put(HotelActions.fetchHotelFailureAction('There was a problem fetching this hotel. Please try again.'));
  }
}

export function* watchHotelSaga() {
  yield takeLatest([HotelActions.FETCH_HOTEL_REQUEST], getHotelSaga);
}
