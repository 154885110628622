import React, { useCallback, useMemo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { prop, compose, pick, keys, equals, propOr, pipe, props, join, isEmpty, map } from 'ramda';
import { Form, Section, Status, Loader } from '@pure-escapes/webapp-ui-components';
import { Link } from 'ui/Link';

import { withAuthentication, withUser } from 'hoc';
import { AuthTypes } from 'config/enums';
import { fields, validation } from 'config/forms/settings';

import FluidButton from 'ui/FluidButton';

import connect from './SettingsForm.state';
import { propTypes, defaultProps } from './SettingsForm.props';
import { Settings, Title } from './SettingsForm.styles';
import { isActive } from 'store/common';

import { COGNITO_ENABLE_FLOW } from 'config';

const mapRep = pipe(props(['title', 'firstName', 'lastName']), join(' '));

const renderForm = (t, { formValues, onSubmit }) => (
  <Form initialValues={formValues} onSubmit={onSubmit} enableReinitialize={true} validationSchema={validation}>
    {({ values, ...formProps }) => (
      <Fragment>
        {Form.renderField('title', prop('title', values), prop('title', fields), formProps)}
        {Form.renderField('firstName', prop('firstName', values), prop('firstName', fields), formProps)}
        {Form.renderField('lastName', prop('lastName', values), prop('lastName', fields), formProps)}
        {Form.renderField('email', prop('email', values), prop('email', fields), formProps)}
        {!COGNITO_ENABLE_FLOW && (
          <Link to="/settings/password">
            <FluidButton
              type="primary"
              className="hover:bg-bistre py-12px transition-all ease-in-out mt-4 w-full"
              textClassName="font-hurmegeometric-sans font-semibold text-lg uppercase"
              border={false}
              rounded={false}
              heightLimited={false}
            >
              {t('buttons.updatePassword')}
            </FluidButton>
          </Link>
        )}
        <FluidButton
          type="primary"
          className="hover:bg-bistre py-12px transition-all ease-in-out mt-4 w-full"
          textClassName="font-hurmegeometric-sans font-semibold text-lg uppercase"
          border={false}
          rounded={false}
          heightLimited={false}
          submit
        >
          {t('buttons.updateDetails')}
        </FluidButton>
      </Fragment>
    )}
  </Form>
);

export const SettingsForm = ({ usersStatus, user, isSr, updateMe }) => {
  const { t } = useTranslation();

  const { uuid, status, type } = user;

  const onSubmit = useCallback(
    values => {
      updateMe(uuid, values);
    },
    [updateMe, uuid]
  );

  const formValues = useMemo(() => pick(keys(fields), user), [user]);
  const assignedKey = useMemo(
    () => (equals(AuthTypes.TA, type) ? 'assignedSalesRepresentatives' : 'assignedTravelAgents'),
    [type]
  );
  const assigned = propOr([], assignedKey, user);

  return (
    <Settings>
      <Title>{t('labels.profileDetails')}</Title>
      <Loader isLoading={isActive(usersStatus)} showPrev={true}>
        <div className="grid gap-[32px] grid-cols-1 tablet:grid-cols-2">
          <div>{renderForm(t, { formValues, onSubmit })}</div>
          <div>
            {!isSr && (
              <Fragment>
                {!isEmpty(assigned) && (
                  <Section label={t('labels.accountRep')}>{map(mapRep, assigned).join(', ')}</Section>
                )}
                <Section label={t('labels.accountStatus')}>
                  <Status data-status={status}>{status}</Status>
                </Section>
              </Fragment>
            )}
            <Section label={t('labels.accountRole')}>{t(`labels.${type}`)}</Section>
          </div>
        </div>
      </Loader>
    </Settings>
  );
};

SettingsForm.propTypes = propTypes;
SettingsForm.defaultProps = defaultProps;

export default compose(withAuthentication, withUser, connect)(SettingsForm);
