import React from 'react';
import { ENetworkRequestStatus, IHotel } from 'services/BackendApi';
import * as Inputs from '../Inputs';
import { isUploadAnImage } from 'containers/HotelAdmin/utils';
import { Uploads } from '../Uploads';
import FluidButton from 'ui/FluidButton';
import { validatePoliciesRestrictions } from 'containers/HotelAdmin/validation';
import { enqueueNotification } from 'store/modules/ui';
import { useDispatch } from 'react-redux';
export const PoliciesRestrictionsTab = ({
  hotel,
  onUpdate,
  onPatchHotel,
}: {
  hotel: IHotel;
  onUpdate: (field: keyof IHotel, value: any) => void;
  onPatchHotel: (updatedData: Partial<IHotel>) => void;
}) => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.CrudList
        collection={hotel.policiesAndRestrictions || []}
        className="w-full"
        onChange={newList => {
          onUpdate('policiesAndRestrictions', newList);
        }}
      />
      <hr />
      <FluidButton
        type="primary"
        className="self-end w-[300px] mt-4"
        onClick={() => {
          const validation = validatePoliciesRestrictions(hotel as IHotel);
          if (validation.isValid === false) {
            dispatch(
              enqueueNotification({
                message: `Policies & Restrictions are not valid: ${validation.message}`,
                options: { variant: 'warning' },
              })
            );
            return;
          }
          onPatchHotel({
            policiesAndRestrictions: hotel.policiesAndRestrictions,
          });
        }}
      >
        Update Policies & Restrictions
      </FluidButton>
    </div>
  );
};
