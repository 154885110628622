import styled from 'styled-components';
import { pureUiTheme } from 'pureUi/pureUiTheme';


export const CompanyDetailsFormStyles = styled.section`
    display: block;
    background: ${pureUiTheme.colorRoles.areaBackground};
    padding: 60px 40px;
    width: 100%;

    .heading {
        margin-bottom: 20px;
    }

    .label {
        margin-bottom: 40px;
    }

    .label:last-child {
        margin-bottom: 0;
    }

    .preview {
        object-fit: cover;
        width: 100%;
    }

    .logoContainer {
        background-color: #D4F3EE !important;
        padding: 20px;
        border: white 5px solid;
        box-shadow: 0 0 5px rgba(0,0,0,0.05);
        margin-bottom: 40px;
    }

    .noLogo {
        padding: 0 20px;
        text-align: center;
    }


    .formInputs {
        margin-bottom: 40px;
    }
    
    .uploadLogoButton, .removeLogoButton {
        display: inline;
    }
    
    .removeLogoButton {
        margin-left: 15px;
    }

    @media (min-width: 1024px) {
        .grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas: 'formInputs imageSettings';
            grid-gap: 40px;
        }

        .formInputs { grid-area: formInputs; }
        .imageSettings { grid-area: imageSettings; }
    }
`