import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Actions from './actions';
import { EProposalSortBy, initialState, IProposalDetailsDomain } from './model';
import { sortBy, reverse } from 'lodash-es';
import { ESortOrder } from 'store/common/types';

const proposalDetailsReducer = (
  state: IProposalDetailsDomain = initialState,
  action: Actions.ProposalsDetailsAction
): IProposalDetailsDomain => {
  switch (action.type) {
    case Actions.GET_PROPOSAL_DETAILS_REQUEST:
      return {
        ...state,
        proposalUuid: action.proposalUuid,
        error: null,
        requests: {
          ...state.requests,
          proposalDetailsLoad: ENetworkRequestStatus.PENDING,
        },
        proposal: null,
        bookings: [],
      };

    case Actions.GET_PROPOSAL_DETAILS_SUCCESS:
      return {
        ...state,
        proposal: action.proposal,
        selectedBookingUuids: [],
        requests: {
          ...state.requests,
          proposalDetailsLoad: ENetworkRequestStatus.SUCCESS,
        },
        error: null,
      };

    case Actions.GET_PROPOSAL_DETAILS_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          proposalDetailsLoad: ENetworkRequestStatus.ERROR,
        },
        error: action.error,
        proposal: null,
      };

    case Actions.TOGGLE_SELECTION_PROPOSAL_BOOKING: {
      const bookingWasSelectedPreviously = state.selectedBookingUuids.includes(action.bookingUuid);
      const newSelectedBookingUuids = bookingWasSelectedPreviously
        ? state.selectedBookingUuids.filter(item => item !== action.bookingUuid)
        : state.selectedBookingUuids.concat(action.bookingUuid);

      return {
        ...state,
        selectedBookingUuids: newSelectedBookingUuids,
      };
    }

    case Actions.SET_PROPOSAL_DETAILS_SORT_BY: {
      const sortingFieldMap = {
        [EProposalSortBy.CREATED]: 'createdAt',
        [EProposalSortBy.TOTAL]: 'totals.total',
      }
      const sortedBookings = sortBy(state.bookings, sortingFieldMap[action.sortBy]);
      if (action.sortOrder === ESortOrder.DESC) {
        reverse(sortedBookings);
      }

      return {
        ...state,
        bookings: sortedBookings,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder,
      };
    }

    case Actions.SET_PROPOSAL_BOOKINGS:
      return {
        ...state,
        bookings: action.bookings,
      };

    case Actions.SET_PROPOSAL_DOWNLOAD_HISTORY:
      return {
        ...state,
        proposalDownloadHistory: action.proposalDownloadHistory,
      }

    default:
      return state;
  }
};

export default proposalDetailsReducer;
