export enum ENetworkRequestStatus {
  IDLE = 'IDLE',
  PENDING = 'LOADING',
  SUCCESS = 'LOADED',
  ERROR = 'ERROR',
}

export interface IPage {
  limit: number;
  offset: number;
}
