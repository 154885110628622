import { call, takeLatest, select, put, delay } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import * as ProposalSelectors from '../selectors';
import {
  GET_PROPOSAL_LIST_REQUEST,
  SET_NAME_FILTER,
  SET_CLIENT_FILTER,
  SET_SORT,
  SET_PAGE_NUMBER,
  SET_TRAVEL_AGENT_UUID,
  getProposalListSuccessAction,
  getProposalListFailureAction,
} from '../actions';
import { makeBookingManagerApi } from 'services/BookingManagerApi';
import { IProposalSelectionListResponse } from 'services/BookingManagerApi/types/ProposalSelection';

export function* getProposalsV2ListSaga() {
  try {
    yield delay(700);
    const bookingManagerApi = makeBookingManagerApi();

    const sortField = yield select(ProposalSelectors.sortBySelector);
    const sortOrder = yield select(ProposalSelectors.sortOrderSelector);
    const name = yield select(ProposalSelectors.nameFilterSelector);
    const clientName = yield select(ProposalSelectors.clientFilterSelector);
    const travelAgentUuid = yield select(ProposalSelectors.selectedTravelAgentUuidSelector);
    const itemsPerPage = yield select(ProposalSelectors.itemsPerPageSelector);
    const currentPage = yield select(ProposalSelectors.currentPageSelector);
    const offset = currentPage === -1 ? 0 : currentPage * itemsPerPage;
    const limit = currentPage === -1 ? 0 : itemsPerPage;
    const options = {
      sort: {
        key: sortField,
        order: sortOrder,
      },
      filter: {
        name,
        clientName,
        ...(travelAgentUuid ? { travelAgentUuid } : {}),
      },
      pagination: {
        offset,
        limit,
      },
    };
    const response: AxiosResponse<IProposalSelectionListResponse> = yield call(
      bookingManagerApi.getProposalSelectionList,
      options
    );
    yield put(getProposalListSuccessAction(response.data.proposalSelections, response.data.count));
  } catch (e) {
    yield put(getProposalListFailureAction(e));
  }
}

export function* watchGetProposalsList() {
  yield takeLatest(
    [GET_PROPOSAL_LIST_REQUEST, SET_CLIENT_FILTER, SET_NAME_FILTER, SET_SORT, SET_PAGE_NUMBER, SET_TRAVEL_AGENT_UUID],
    getProposalsV2ListSaga
  );
}
