import { call, takeLatest, select, put } from 'redux-saga/effects';
import { selectedTaSelector } from '../../agents';
import { EDIT_TASK_REQUEST, editTaskFailureAction, EditTaskRequestAction, editTaskSuccessAction } from '../actions';
import { enqueueNotification } from 'store/modules/ui';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';
import { ICreateTaskRequest } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { duplicatedReminderIdsSelector, listTaskRemindersSelector } from '../selectors';

export function* editTaskSaga(action: EditTaskRequestAction) {

  try {
    const selectedTa = yield select(selectedTaSelector);
    const duplicatedReminders = yield select(duplicatedReminderIdsSelector);
    if (duplicatedReminders?.length) {
      yield put(editTaskFailureAction('Check duplicated reminders'));
      return;
    }
    const taskReminders = yield select(listTaskRemindersSelector);
    const adjustedReminders = taskReminders.map(item => ({
      channel: item.channel,
      offsetUnit: item.offsetUnit,
      offset: -Math.abs(item.offset),
    }));


    const taskToEdit: ICreateTaskRequest = {
      assigneeId: (!action.task.assigneeId || action.task.assigneeId === 'all') ? null : action.task.assigneeId,
      bookingHumanReadableId: action.task.bookingRef,
      categoryId: action.task.category ?? '',
      deadline: action.task.endDate + 'T' + action.task.endTime + 'Z',
      description: action.task.description,
      priority: action.task.priority,
      reminders: adjustedReminders,
      startBy: !action.task.startByDate?.length ? null : action.task.startByDate + 'T00:00:00Z',
      status: action.task.status,
      subject: action.task.subject,
      teamId: action.task.teamId ?? '',
    };

    const taskManagementApi = makeTaskManagementApi();
    yield call(taskManagementApi.editTask, action.taskId, taskToEdit);
    yield put(editTaskSuccessAction());
    yield put(enqueueNotification({ message: 'Task updated successfully.', options: { variant: 'success' } }));
    yield call(action.history.push, '/tasks');
  } catch (e) {
    yield put(editTaskFailureAction(e));
    const errorMapping = {
      BOOKING_NOT_FOUND: 'Booking Ref. is invalid',
      CATEGORY_NOT_FOUND: 'Category is invalid',
      TEAM_NOT_FOUND: 'Team is invalid',
      USER_NOT_FOUND: 'User is invalid',
    };
    const errors: { message: string; errorCode: string; }[] = e?.response?.data?.errors ?? [];
    let isErrorDisplayed = false;
    for (const error of errors) {
      const errorMessage = errorMapping[error.errorCode];
      if (errorMessage) {
        isErrorDisplayed = true;
        yield put(enqueueNotification({ message: errorMessage, options: { variant: 'error' } }));
      }
    }
    if (!isErrorDisplayed) {
      yield put(enqueueNotification({ message: 'Error updating task', options: { variant: 'error' } }));
    }
  }
}

export function* watchEditTaskSaga() {
  yield takeLatest(
    [EDIT_TASK_REQUEST],
    editTaskSaga
  );
}
