import React, { useCallback, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { CloseXComponent as CloseXIcon } from 'ui/Icons';

export interface iGeneralModalProps {
  isOpen?: boolean;
  children?: React.ReactNode;
  modalOverlayClassName?: string;
  modalWindowClassName?: string;
  isCloseButtonVisible?: boolean;
  shouldCloseByClickingOutside?: boolean;
  onClose?: () => void;
}

export const GeneralModal = (props: iGeneralModalProps) => {
  const {
    isOpen = true,
    children,
    modalOverlayClassName = '',
    modalWindowClassName = '',
    isCloseButtonVisible = true,
    shouldCloseByClickingOutside = false,
    onClose,
  } = props;

  // ...I tried useScrollLock from usehooks-ts
  // ...I tried the overflow: hidden trick
  // ...I tried the position fixed just on its own trick
  // Basically: I tried a bunch of stuff, and this is the only thing
  //  that worked _consistently_ across all screens, etc.
  useEffect(() => {
    if (isOpen) {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.classList.add('fixed', 'inset-x-0');
    } else {
      const scrollY = document.body.style.top;
      document.body.classList.remove('fixed', 'inset-x-0');
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }

    return () => {
      const scrollY = document.body.style.top;
      document.body.classList.remove('fixed', 'inset-x-0');
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    };
  }, [isOpen]);

  const [isBrowser, setIsBrowser] = useState(false);

  const handleModalClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const handleModalOverlayClick = useCallback(
    e => {
      if (shouldCloseByClickingOutside && e.target === e.currentTarget) {
        handleModalClose();
      }
    },
    [shouldCloseByClickingOutside, handleModalClose]
  );

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const modalContent = (
    <div
      className={classnames(
        'modal-overlay fixed z-2000 bg-black-transparent flex justify-center items-center top-0 left-0 right-0 bottom-0 overflow-y-auto',
        modalOverlayClassName
      )}
      onClick={handleModalOverlayClick}
    >
      <div
        className={classnames(
          'modal-window bg-white shadow-pe5 min-h-70px relative max-h-screen overflow-y-auto',
          modalWindowClassName
        )}
      >
        {isCloseButtonVisible && (
          <div
            className="modal-close-button w-40px h-40px flex justify-center items-center absolute top-15px right-15px cursor-pointer"
            onClick={handleModalClose}
          >
            <CloseXIcon />
          </div>
        )}
        {children}
      </div>
    </div>
  );

  if (!isOpen) {
    return null;
  }

  return isBrowser ? ReactDOM.createPortal(modalContent, document.getElementById('portal-modal') as HTMLElement) : null;
};
