import React, { useCallback, useEffect } from 'react';
import { formatDate } from 'utils';
import { addYears } from 'date-fns';
import { Radio } from 'ui/Radio';
import { StyledSingleDateInput } from './StyledSingleDateInput';

interface ICreditNoteExpiryDateProps {
  question1: string;
  answer1: boolean;
  onAnswer1Change: (answer: boolean) => void;
  question2: string;
  answer2: boolean;
  onAnswer2Change: (answer: boolean) => void;
  date: string | null;
  onDateChange: (date: string | null) => void;
}

export const CreditNoteExpiryDate = React.memo(
  ({
    question1,
    answer1,
    question2,
    answer2,
    date,
    onAnswer1Change,
    onAnswer2Change,
    onDateChange,
  }: ICreditNoteExpiryDateProps) => {
    useEffect(() => {
      return () => {
        onAnswer1Change(false);
        onAnswer2Change(false);
        onDateChange(null);
      };
    }, [onAnswer1Change, onAnswer2Change, onDateChange]);

    const handleAnswer1YesClick = useCallback(() => {
      onAnswer1Change(true);
    }, [onAnswer1Change]);

    const handleAnswer1NoClick = useCallback(() => {
      onAnswer1Change(false);
    }, [onAnswer1Change]);

    const handleAnswer2YesClick = useCallback(() => {
      onAnswer2Change(true);
    }, [onAnswer2Change]);

    const handleAnswer2NoClick = useCallback(() => {
      onAnswer2Change(false);
      onDateChange(null);
    }, [onDateChange, onAnswer2Change]);

    return (
      <div className="credit-note-flow flex mt-25px flex-col gap-25px">
        <div className="credit-note-question-1 flex gap-10px">
          <p className="flex-1 m-0 font-pt-sans text-base leading-21px text-black font-bold">{question1}</p>
          <label
            className="flex font-pt-sans text-base leading-xs text-black cursor-pointer"
            onClick={handleAnswer1YesClick}
          >
            <Radio onClick={handleAnswer1YesClick} checked={answer1} />
            <span className="ml-2">Yes</span>
          </label>
          <label
            className="flex font-pt-sans text-base leading-xs text-black cursor-pointer"
            onClick={handleAnswer1NoClick}
          >
            <Radio onClick={handleAnswer1NoClick} checked={!answer1} />
            <span className="ml-2">No</span>
          </label>
        </div>

        <div className="credit-note-question-2 flex gap-10px mt-15px">
          <p className="flex-1 m-0 font-pt-sans text-base leading-21px text-black font-bold">{question2}</p>
          <label
            className="flex font-pt-sans text-base leading-xs text-black cursor-pointer"
            onClick={handleAnswer2YesClick}
          >
            <Radio onClick={handleAnswer2YesClick} checked={answer2} />
            <span className="ml-2">Yes</span>
          </label>
          <label
            className="flex font-pt-sans text-base leading-xs text-black cursor-pointer"
            onClick={handleAnswer2NoClick}
          >
            <Radio onClick={handleAnswer2NoClick} checked={!answer2} />
            <span className="ml-2">No</span>
          </label>
        </div>

        {answer2 && (
          <label className="credit-note-date inline-flex mt-15px">
            <StyledSingleDateInput
              value={date ? new Date(date) : null}
              onChange={value => {
                onDateChange(formatDate(value));
              }}
              enablePastDates
              minDate={addYears(new Date(), -150)}
              maxDate={addYears(new Date(), 150)}
              placeholder="EXPIRY DATE"
            />
          </label>
        )}

        <div className="border-t border-solid border-gray-20 mt-15px mb-10px"></div>
      </div>
    );
  }
);
