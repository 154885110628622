import { createSelector } from 'reselect';
import { IAssignTAModal, IInternalUsersListDomain } from './model';

export const domainSelector = (state: any): IInternalUsersListDomain => state.internalUsersList;

export const listSelector = createSelector(domainSelector, (domain: IInternalUsersListDomain) => domain.list);

export const filterSelector = createSelector(domainSelector, (domain: IInternalUsersListDomain) => domain.filter);

export const paginationSelector = createSelector(
  domainSelector,
  (domain: IInternalUsersListDomain) => domain.pagination
);

export const toDeleteSelector = createSelector(domainSelector, (domain: IInternalUsersListDomain) => domain.toDelete);

export const requestSelector = createSelector(domainSelector, (domain: IInternalUsersListDomain) => domain.request);

export const assignTAModalSelector = createSelector(domainSelector, (domain: IInternalUsersListDomain) => domain.assignTAModal);

export const assignedTAsSelector = createSelector(assignTAModalSelector, (domain: IAssignTAModal) => domain.assignedTAs);

export const unAssignedTAsSelector = createSelector(assignTAModalSelector, (domain: IAssignTAModal) => domain.unAssignedTAs);

export const selectedTAsSelector = createSelector(assignTAModalSelector, (domain: IAssignTAModal) => domain.data);

export const getRequestTAsSelector = createSelector(assignTAModalSelector, (domain: IAssignTAModal) => domain.getRequest);

export const postRequestTAsSelector = createSelector(assignTAModalSelector, (domain: IAssignTAModal) => domain.postRequest);

export const filterRequestTAsSelector = createSelector(assignTAModalSelector, (domain: IAssignTAModal) => domain.filter);

export const nameFilterTAsSelector = createSelector(filterRequestTAsSelector, (domain: IAssignTAModal['filter']) => domain.search);

export const countryFilterTAsSelector = createSelector(filterRequestTAsSelector, (domain: IAssignTAModal['filter']) => domain.countryCode);

export const selectedRSSelector = createSelector(assignTAModalSelector, (domain: IAssignTAModal) => domain.selectedSR);

export const checkAllTASelector = createSelector(assignTAModalSelector, (domain: IAssignTAModal) => domain.checkAll);

export const isAssignedTabTASelector = createSelector(assignTAModalSelector, (domain: IAssignTAModal) => domain.isAssignedTab);

export const isConfirmationModalTASelector = createSelector(assignTAModalSelector, (domain: IAssignTAModal) => domain.confirmationModal);

