import React, { HTMLProps, ReactNode, useCallback, FormEvent } from 'react';
import styled from 'styled-components';
import { IconButton } from '../Buttons/index';
import { pureUiTheme } from 'pureUi/pureUiTheme';

export interface TabBarCompactProps extends HTMLProps<HTMLDivElement> {
  tabIndex: number;
  children: ReactNode[];
  onIncrementTabIndex: (step: number) => void;
}
const TabBarCompactComponent = (props: TabBarCompactProps) => {
  const handleIncrement = useCallback(
    (step: number) => (e: FormEvent<HTMLButtonElement>): void => {
      props.onIncrementTabIndex(step);
    },
    [props.onIncrementTabIndex]
  );

  const disablePrevButton = props.tabIndex <= 0;
  const disableNextButton = props.tabIndex >= props.children.length - 1;

  return (
    <div className={props.className}>
      <IconButton
        disabled={disablePrevButton}
        className="prevButton"
        title="previous tab"
        onClick={handleIncrement(-1)}
      >
        {/* chevron left */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path
            fillRule="evenodd"
            d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
            clipRule="evenodd"
          />
        </svg>
      </IconButton>

      <IconButton disabled={disableNextButton} className="nextButton" title="next tab" onClick={handleIncrement(1)}>
        {/* chevron right */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path
            fillRule="evenodd"
            d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
            clipRule="evenodd"
          />
        </svg>
      </IconButton>

      <div className="children">{props.children[props.tabIndex]}</div>
    </div>
  );
};

export const TabBarCompact = styled(TabBarCompactComponent)`
  display: grid;
  grid-template-areas: 'prev children next';
  grid-template-columns: 50px 1fr 50px;
  border-bottom: ${pureUiTheme.colors.gold} 1px solid;
  background-color: ${pureUiTheme.colors.whiteish};
  user-select: none;

  .prevButton {
    grid-area: prev;
    text-align: right;
  }
  .nextButton {
    grid-area: next;
    text-align: left;
  }

  .prevButton:disabled,
  .nextButton:disabled {
    opacity: 0;
  }

  .children {
    display: flex;
    flex-direction: column;
    grid-area: children;
  }
`;
