import { EUserType, IInternalUser } from 'services/BackendApi';
import { History } from 'history';
import { INotification } from 'services/BackendApi/types/Notification';

export const CREATE_REQUEST = 'internalUser/CREATE_REQUEST';
export const CREATE_SUCCESS = 'internalUser/CREATE_SUCCESS';
export const CREATE_FAILURE = 'internalUser/CREATE_FAILURE';

export const GET_REQUEST = 'internalUser/GET_REQUEST';
export const GET_SUCCESS = 'internalUser/GET_SUCCESS';
export const GET_FAILURE = 'internalUser/GET_FAILURE';

export const UPDATE_REQUEST = 'internalUser/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'internalUser/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'internalUser/UPDATE_FAILURE';

export const RESET_INTERNAL_USER = 'internalUser/RESET_INTERNAL_USER';

export type CreateInternalUserRequestAction = ReturnType<typeof createInternalUserRequestAction>;
export const createInternalUserRequestAction = (
  internalUser: Partial<IInternalUser>,
  notifications: INotification[],
  history: History
) => ({
  type: CREATE_REQUEST as typeof CREATE_REQUEST,
  internalUser,
  notifications,
  history,
});

export type CreateInternalUserSuccessAction = ReturnType<typeof createInternalUserSuccessAction>;
export const createInternalUserSuccessAction = (internalUser: IInternalUser) => ({
  type: CREATE_SUCCESS as typeof CREATE_SUCCESS,
  internalUser,
});

export type CreateInternalUserFailureAction = ReturnType<typeof createInternalUserFailureAction>;
export const createInternalUserFailureAction = (error: string) => ({
  type: CREATE_FAILURE as typeof CREATE_FAILURE,
  error,
});

export type GetInternalUserRequestAction = ReturnType<typeof getInternalUserRequestAction>;
export const getInternalUserRequestAction = (internalUserUuid: string) => ({
  type: GET_REQUEST as typeof GET_REQUEST,
  internalUserUuid,
});

export type GetInternalUserSuccessAction = ReturnType<typeof getInternalUserSuccessAction>;
export const getInternalUserSuccessAction = (internalUser: IInternalUser) => ({
  type: GET_SUCCESS as typeof GET_SUCCESS,
  internalUser,
});

export type GetInternalUserFailureAction = ReturnType<typeof getInternalUserFailureAction>;
export const getInternalUserFailureAction = (error: string) => ({
  type: GET_FAILURE as typeof GET_FAILURE,
  error,
});

export type UpdateInternalUserRequestAction = ReturnType<typeof updateInternalUserRequestAction>;
export const updateInternalUserRequestAction = (
  internalUserUuid: string,
  internalUser: Partial<IInternalUser>,
  notifications: INotification[]
) => ({
  type: UPDATE_REQUEST as typeof UPDATE_REQUEST,
  internalUserUuid,
  internalUser,
  notifications,
});

export type UpdateInternalUserSuccessAction = ReturnType<typeof updateInternalUserSuccessAction>;
export const updateInternalUserSuccessAction = (internalUser: IInternalUser) => ({
  type: UPDATE_SUCCESS as typeof UPDATE_SUCCESS,
  internalUser,
});

export type UpdateInternalUserFailureAction = ReturnType<typeof updateInternalUserFailureAction>;
export const updateInternalUserFailureAction = (error: string) => ({
  type: UPDATE_FAILURE as typeof UPDATE_FAILURE,
  error,
});

export type ResetInternalUserAction = ReturnType<typeof resetInternalUserAction>;
export const resetInternalUserAction = () => ({
  type: RESET_INTERNAL_USER as typeof RESET_INTERNAL_USER,
});

export type Any =
  | CreateInternalUserRequestAction
  | CreateInternalUserSuccessAction
  | CreateInternalUserFailureAction
  | GetInternalUserRequestAction
  | GetInternalUserSuccessAction
  | GetInternalUserFailureAction
  | UpdateInternalUserRequestAction
  | UpdateInternalUserSuccessAction
  | UpdateInternalUserFailureAction
  | ResetInternalUserAction;
