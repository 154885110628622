export default {
  accept: 'Accept',
  addToProposal: 'Add to proposal',
  amend: 'Amend',
  amendBooking: 'Amend Booking',
  bookAndPay: 'Book & Pay',
  bookNow: 'Request to book',
  bookOnRequest: 'Book on Request',
  bookLive: 'Book Now',
  cancel: 'Cancel',
  edit: 'Edit',
  finalizeAndPay: 'Finalize & Pay',
  forgotten: 'Forgot your password?',
  generateAndSend: 'Generate and Send',
  login: 'Sign In',
  moreInfo: 'More Info',
  passwordReset: 'Reset Your Password',
  refine: 'Refine search results',
  remove: 'Remove',
  removeFilters: 'Remove all filters',
  request: 'Submit Request',
  search: 'Search',
  submit: 'Submit',
  submitBookingRequest: 'Submit Booking Request',
  update: 'Update',
  previewPDF: 'Preview PDF',
  confirmAndRelease: 'Confirm and release',
  bookingConfirm: 'Confirm booking',
  cancelBooking: 'Cancel Booking',
  requestBooking: 'Request Booking',
  updateDetails: 'Update Details',
  updatePassword: 'Update Password',
  saveBooking: 'Save Results',
  downloadQuote: 'Download Quote',
  download: 'Download',
};
