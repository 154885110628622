import { EMassAssignTaSrMode } from 'containers/TravelAgents/List/MassAssignModal';
import {
  ETravelAgentCreatedBy,
  ETravelAgentStatus,
  EUserType,
  ICompany,
  ISalesRepresentative,
  ITravelAgent,
} from 'services/BackendApi';

export const GET_REQUEST = 'travelAgentList/GET_REQUEST';
export const GET_SUCCESS = 'travelAgentList/GET_SUCCESS';
export const GET_FAILURE = 'travelAgentList/GET_FAILURE';

export const DELETE_REQUEST = 'travelAgentList/DELETE_REQUEST';
export const DELETE_SUCCESS = 'travelAgentList/DELETE_SUCCESS';
export const DELETE_FAILURE = 'travelAgentList/DELETE_FAILURE';

export const CSV_REQUEST = 'travelAgentList/CSV_REQUEST';
export const CSV_SUCCESS = 'travelAgentList/CSV_SUCCESS';
export const CSV_FAILURE = 'travelAgentList/CSV_FAILURE';

export const SET_SEARCH_FILTER = 'travelAgentList/SET_SEARCH_FILTER';
export const SET_EMAIL_FILTER = 'travelAgentList/SET_EMAIL_FILTER';
export const SET_COUNTRY_CODE_FILTER = 'travelAgentList/SET_COUNTRY_CODE_FILTER';
export const SET_STATUS_FILTER = 'travelAgentList/SET_STATUS_FILTER';
export const SET_SALES_REP_FILTER = 'travelAgentList/SET_SALES_REP_FILTER';
export const SET_COMPANY_UUID_FILTER = 'travelAgentList/SET_COMPANY_UUID_FILTER';
export const SET_CREATED_BY_FILTER = 'travelAgentList/SET_CREATED_BY_FILTER';

export const SET_SORT_ORDER = 'travelAgentList/SET_SORT_ORDER';

export const SET_PAGE = 'travelAgentList/SET_PAGE';
export const SET_PER_PAGE = 'travelAgentList/SET_PER_PAGE';

export const SET_TO_DELETE = 'travelAgentList/SET_TO_DELETE';

export const GET_SALES_REPS_REQUEST = 'travelAgentList/GET_SALES_REPS_REQUEST';
export const GET_SALES_REPS_SUCCESS = 'travelAgentList/GET_SALES_REPS_SUCCESS';
export const GET_SALES_REPS_FAILURE = 'travelAgentList/GET_SALES_REPS_FAILURE';

export const SET_MASS_ASSIGN_MODAL_VISIBLE = 'travelAgentList/SET_MASS_ASSIGN_MODAL_VISIBLE';
export const SET_MASS_ASSIGN_CONFIRM_MODAL_VISIBLE = 'travelAgentList/SET_MASS_ASSIGN_CONFIRM_MODAL_VISIBLE';
export const SET_MASS_ASSIGN_MODE = 'travelAgentList/SET_MASS_ASSIGN_MODE';
export const SET_MASS_ASSIGN_SRS = 'travelAgentList/SET_MASS_ASSIGN_SRS';
export const INIT_MASS_ASSIGN = 'travelAgentList/INIT_MASS_ASSIGN';

export const MASS_ASSIGN_REQUEST = 'travelAgentList/MASS_ASSIGN_REQUEST';
export const MASS_ASSIGN_SUCCESS = 'travelAgentList/MASS_ASSIGN_SUCCESS';
export const MASS_ASSIGN_FAILURE = 'travelAgentList/MASS_ASSIGN_FAILURE';

export const TA_HAS_BOOKINGS_REQUEST = 'travelAgentList/TA_HAS_BOOKINGS_REQUEST';
export const TA_HAS_BOOKINGS_SUCCESS = 'travelAgentList/TA_HAS_BOOKINGS_SUCCESS';
export const TA_HAS_BOOKINGS_FAILURE = 'travelAgentList/TA_HAS_BOOKINGS_FAILURE';

export type GetRequestAction = ReturnType<typeof getRequestAction>;
export const getRequestAction = (userType: EUserType) => ({
  type: GET_REQUEST as typeof GET_REQUEST,
  userType,
});

export type GetSuccessAction = ReturnType<typeof getSuccessAction>;
export const getSuccessAction = (travelAgents: ITravelAgent[], total: number) => ({
  type: GET_SUCCESS as typeof GET_SUCCESS,
  travelAgents,
  total,
});

export type GetFailureAction = ReturnType<typeof getFailureAction>;
export const getFailureAction = (error: string) => ({
  type: GET_FAILURE as typeof GET_FAILURE,
  error,
});

export type CsvRequestAction = ReturnType<typeof csvRequestAction>;
export const csvRequestAction = () => ({
  type: CSV_REQUEST as typeof CSV_REQUEST,
});

export type CsvSuccessAction = ReturnType<typeof csvSuccessAction>;
export const csvSuccessAction = () => ({
  type: CSV_SUCCESS as typeof CSV_SUCCESS,
});

export type CsvFailureAction = ReturnType<typeof csvFailureAction>;
export const csvFailureAction = (error: string) => ({
  type: CSV_FAILURE as typeof CSV_FAILURE,
  error,
});

export type DeleteRequestAction = ReturnType<typeof deleteRequestAction>;
export const deleteRequestAction = () => ({
  type: DELETE_REQUEST as typeof DELETE_REQUEST,
});

export type DeleteSuccessAction = ReturnType<typeof deleteSuccessAction>;
export const deleteSuccessAction = () => ({
  type: DELETE_SUCCESS as typeof DELETE_SUCCESS,
});

export type DeleteFailureAction = ReturnType<typeof deleteFailureAction>;
export const deleteFailureAction = (error: string) => ({
  type: DELETE_FAILURE as typeof DELETE_FAILURE,
  error,
});

export type SetSearchFilterAction = ReturnType<typeof setSearchFilterAction>;
export const setSearchFilterAction = (value: string | null) => ({
  type: SET_SEARCH_FILTER as typeof SET_SEARCH_FILTER,
  value,
});

export type SetEmailFilterAction = ReturnType<typeof setEmailFilterAction>;
export const setEmailFilterAction = (value: string | null) => ({
  type: SET_EMAIL_FILTER as typeof SET_EMAIL_FILTER,
  value,
});

export type SetCountryCodeFilterAction = ReturnType<typeof setCountryCodeFilterAction>;
export const setCountryCodeFilterAction = (value: string | null) => ({
  type: SET_COUNTRY_CODE_FILTER as typeof SET_COUNTRY_CODE_FILTER,
  value,
});

export type SetCompanyUuidFilterAction = ReturnType<typeof setCompanyUuidFilterAction>;
export const setCompanyUuidFilterAction = (value: string | null) => ({
  type: SET_COMPANY_UUID_FILTER as typeof SET_COMPANY_UUID_FILTER,
  value,
});

export type SetStatusFilterAction = ReturnType<typeof setStatusFilterAction>;
export const setStatusFilterAction = (value: ETravelAgentStatus | null) => ({
  type: SET_STATUS_FILTER as typeof SET_STATUS_FILTER,
  value,
});

export type SetSalesRepFilterAction = ReturnType<typeof setSalesRepFilterAction>;
export const setSalesRepFilterAction = (salesRepUuid: string | null) => ({
  type: SET_SALES_REP_FILTER as typeof SET_SALES_REP_FILTER,
  salesRepUuid,
});

export type SetCreatedByFilterAction = ReturnType<typeof setCreatedByFilterAction>;
export const setCreatedByFilterAction = (value: ETravelAgentCreatedBy | null) => ({
  type: SET_CREATED_BY_FILTER as typeof SET_CREATED_BY_FILTER,
  value,
});

export type SetPageAction = ReturnType<typeof setPageAction>;
export const setPageAction = (value: number) => ({
  type: SET_PAGE as typeof SET_PAGE,
  value,
});

export type SetPerPageAction = ReturnType<typeof setPerPageAction>;
export const setPerPageAction = (value: number) => ({
  type: SET_PER_PAGE as typeof SET_PER_PAGE,
  value,
});

export type SetSortOrderAction = ReturnType<typeof setSortOrderAction>;
export const setSortOrderAction = (value: 'asc' | 'desc') => ({
  type: SET_SORT_ORDER as typeof SET_SORT_ORDER,
  value,
});

export type SetToDeleteAction = ReturnType<typeof setToDeleteAction>;
export const setToDeleteAction = (value: ITravelAgent | null) => ({
  type: SET_TO_DELETE as typeof SET_TO_DELETE,
  value,
});

export type GetSalesRepsRequestAction = ReturnType<typeof getSalesRepsRequestAction>;
export const getSalesRepsRequestAction = () => ({
  type: GET_SALES_REPS_REQUEST as typeof GET_SALES_REPS_REQUEST,
});

export type GetSalesRepsSuccessAction = ReturnType<typeof getSalesRepsSuccessAction>;
export const getSalesRepsSuccessAction = (salesReps: ISalesRepresentative[]) => ({
  type: GET_SALES_REPS_SUCCESS as typeof GET_SALES_REPS_SUCCESS,
  salesReps,
});

export type GetSalesRepsFailureAction = ReturnType<typeof getSalesRepsFailureAction>;
export const getSalesRepsFailureAction = (error: string) => ({
  type: GET_SALES_REPS_FAILURE as typeof GET_SALES_REPS_FAILURE,
  error,
});

export type SetMassAssignModalVisibleAction = ReturnType<typeof setMassAssignModalVisibleAction>;
export const setMassAssignModalVisibleAction = (visible: boolean) => ({
  type: SET_MASS_ASSIGN_MODAL_VISIBLE as typeof SET_MASS_ASSIGN_MODAL_VISIBLE,
  visible,
});

export type SetMassAssignConfirmModalVisibleAction = ReturnType<typeof setMassAssignConfirmModalVisibleAction>;
export const setMassAssignConfirmModalVisibleAction = (visible: boolean) => ({
  type: SET_MASS_ASSIGN_CONFIRM_MODAL_VISIBLE as typeof SET_MASS_ASSIGN_CONFIRM_MODAL_VISIBLE,
  visible,
});

export type SetMassAssignModeAction = ReturnType<typeof setMassAssignModeAction>;
export const setMassAssignModeAction = (mode: EMassAssignTaSrMode) => ({
  type: SET_MASS_ASSIGN_MODE as typeof SET_MASS_ASSIGN_MODE,
  mode,
});

export type InitMassAssignAction = ReturnType<typeof initMassAssignAction>;
export const initMassAssignAction = () => ({
  type: INIT_MASS_ASSIGN as typeof INIT_MASS_ASSIGN,
});

export type SetMassAssignSelectedSrsAction = ReturnType<typeof setMassAssignSelectedSrsAction>;
export const setMassAssignSelectedSrsAction = (srs: string[]) => ({
  type: SET_MASS_ASSIGN_SRS as typeof SET_MASS_ASSIGN_SRS,
  srs,
});

export type MassAssignRequestAction = ReturnType<typeof massAssignRequestAction>;
export const massAssignRequestAction = () => ({
  type: MASS_ASSIGN_REQUEST as typeof MASS_ASSIGN_REQUEST,
});

export type MassAssignSuccessAction = ReturnType<typeof massAssignSuccessAction>;
export const massAssignSuccessAction = () => ({
  type: MASS_ASSIGN_SUCCESS as typeof MASS_ASSIGN_SUCCESS,
});

export type MassAssignFailureAction = ReturnType<typeof massAssignFailureAction>;
export const massAssignFailureAction = (error: string) => ({
  type: MASS_ASSIGN_FAILURE as typeof MASS_ASSIGN_FAILURE,
  error,
});

export type TaHasBookingsRequestAction = ReturnType<typeof taHasBookingsRequestAction>;
export const taHasBookingsRequestAction = (travelAgentUuid: string) => ({
  type: TA_HAS_BOOKINGS_REQUEST as typeof TA_HAS_BOOKINGS_REQUEST,
  travelAgentUuid,
});

export type TaHasBookingsSuccessAction = ReturnType<typeof taHasBookingsSuccessAction>;
export const taHasBookingsSuccessAction = (hasBookings: boolean) => ({
  type: TA_HAS_BOOKINGS_SUCCESS as typeof TA_HAS_BOOKINGS_SUCCESS,
  hasBookings,
});

export type TaHasBookingsFailureAction = ReturnType<typeof taHasBookingsFailureAction>;
export const taHasBookingsFailureAction = (error: string) => ({
  type: TA_HAS_BOOKINGS_FAILURE as typeof TA_HAS_BOOKINGS_FAILURE,
  error,
});

export type TravelAgentListAction =
  | GetRequestAction
  | GetSuccessAction
  | GetFailureAction
  | CsvRequestAction
  | CsvSuccessAction
  | CsvFailureAction
  | DeleteRequestAction
  | DeleteSuccessAction
  | DeleteFailureAction
  | SetSearchFilterAction
  | SetEmailFilterAction
  | SetCountryCodeFilterAction
  | SetStatusFilterAction
  | SetCompanyUuidFilterAction
  | SetPageAction
  | SetMassAssignModalVisibleAction
  | SetMassAssignConfirmModalVisibleAction
  | SetPerPageAction
  | SetToDeleteAction
  | GetSalesRepsRequestAction
  | GetSalesRepsSuccessAction
  | GetSalesRepsFailureAction
  | SetSalesRepFilterAction
  | SetMassAssignModeAction
  | SetMassAssignSelectedSrsAction
  | MassAssignRequestAction
  | MassAssignSuccessAction
  | MassAssignFailureAction
  | SetSalesRepFilterAction
  | InitMassAssignAction
  | SetCreatedByFilterAction
  | SetSortOrderAction
  | TaHasBookingsRequestAction
  | TaHasBookingsSuccessAction
  | TaHasBookingsFailureAction;
