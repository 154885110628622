import React from 'react';
import { EDepositAccountRowType } from 'services/BookingManagerApi';
import { IMultiselectValueLabelPair } from 'ui/Multiselect';
import {
  adjustmentDepositAccountRowTypes,
  eurDepositAccountRowTypes,
  initialDepositAccountRowTypes,
  usdDepositAccountRowTypes,
} from 'store/modules/ledger/utils';
import { PositiveIcon } from '../TransactionIcons/PositiveIcon';
import { NegativeIcon } from '../TransactionIcons/NegativeIcon';
import { TCurrencyCode } from '../../interfaces';

// this mapping is ONLY used by the frontend
// if you want the label to be different in a PDF, etc.
// you need to change the PDF service, for example
export const depositAccountRowTypeLabelMap = {
  [EDepositAccountRowType.Transfer_In]: 'From Travel Partner - Bank Payment (IN)', // name change @see OWA-4350
  [EDepositAccountRowType.Transfer_Out]: 'To Travel Partner - Bank Payment (OUT)', // name change @see OWA-4350
  [EDepositAccountRowType.Transfer_Between_USD_EUR_Deposit_IN]: 'Transfer between USD/EUR Deposit (IN)',
  [EDepositAccountRowType.Transfer_Between_USD_EUR_Deposit_OUT]: 'Transfer between USD/EUR Deposit (OUT)',
  [EDepositAccountRowType.Transfer_Between_EUR_USD_Deposit_IN]: 'Transfer between EUR/USD Deposit (IN)',
  [EDepositAccountRowType.Transfer_Between_EUR_USD_Deposit_OUT]: 'Transfer between EUR/USD Deposit (OUT)',
  [EDepositAccountRowType.Initial_Balance_Carried_Forward_Positive]: 'Initial Balance Carried Forward +',
  [EDepositAccountRowType.Initial_Balance_Carried_Forward_Negative]: 'Initial Balance Carried Forward -',
  [EDepositAccountRowType.Adjustment_Balance_Carried_Forward_Positive]: 'Adjustment to Balance Carried Forward +',
  [EDepositAccountRowType.Adjustment_Balance_Carried_Forward_Negative]: 'Adjustment to Balance Carried Forward -',
  [EDepositAccountRowType.Finance_Adjustment_Positive]: 'Finance Adjustment +',
  [EDepositAccountRowType.Finance_Adjustment_Negative]: 'Finance Adjustment -',
  [EDepositAccountRowType.Automatic_Deposit_Transfer_IN]: 'Automatic Deposit Transfer (IN)',
  [EDepositAccountRowType.Automatic_Credit_From_Booking_IN]: 'Automatic Credit From Booking (IN)',
  [EDepositAccountRowType.Automatic_Credit_From_Booking_OUT]: 'Automatic Credit From Booking (OUT)',
  [EDepositAccountRowType.Transfer_Between_Travel_Companies_IN]: 'Transfer between Travel Companies (IN)',
  [EDepositAccountRowType.Transfer_Between_Travel_Companies_OUT]: 'Transfer between Travel Companies (OUT)',
  [EDepositAccountRowType.Credit_Note_For_Travel_Partner_IN]: 'Credit Note for Travel Partner (IN)',
  [EDepositAccountRowType.Credit_Note_For_Travel_Partner_OUT]: 'Credit Note for Travel Partner (OUT)',
  [EDepositAccountRowType.Amount_Before_From_Date]: 'Net movements before period and Balance Brought Forward',
  [EDepositAccountRowType.Amount_After_To_Date]: 'Net movements after period and Closing Balance',
};

const depositAccountTypeOptions: IMultiselectValueLabelPair[] = [
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Transfer_Out],
    value: EDepositAccountRowType.Transfer_Out,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },

  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Transfer_Between_EUR_USD_Deposit_OUT],
    value: EDepositAccountRowType.Transfer_Between_EUR_USD_Deposit_OUT,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Transfer_Between_USD_EUR_Deposit_OUT],
    value: EDepositAccountRowType.Transfer_Between_USD_EUR_Deposit_OUT,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Initial_Balance_Carried_Forward_Positive],
    value: EDepositAccountRowType.Initial_Balance_Carried_Forward_Positive,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Adjustment_Balance_Carried_Forward_Positive],
    value: EDepositAccountRowType.Adjustment_Balance_Carried_Forward_Positive,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Finance_Adjustment_Positive],
    value: EDepositAccountRowType.Finance_Adjustment_Positive,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Credit_Note_For_Travel_Partner_OUT],
    value: EDepositAccountRowType.Credit_Note_For_Travel_Partner_OUT,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Transfer_In],
    value: EDepositAccountRowType.Transfer_In,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Initial_Balance_Carried_Forward_Negative],
    value: EDepositAccountRowType.Initial_Balance_Carried_Forward_Negative,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Transfer_Between_USD_EUR_Deposit_IN],
    value: EDepositAccountRowType.Transfer_Between_USD_EUR_Deposit_IN,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Transfer_Between_EUR_USD_Deposit_IN],
    value: EDepositAccountRowType.Transfer_Between_EUR_USD_Deposit_IN,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Adjustment_Balance_Carried_Forward_Negative],
    value: EDepositAccountRowType.Adjustment_Balance_Carried_Forward_Negative,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Finance_Adjustment_Negative],
    value: EDepositAccountRowType.Finance_Adjustment_Negative,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Automatic_Credit_From_Booking_IN],
    value: EDepositAccountRowType.Automatic_Credit_From_Booking_IN,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Automatic_Credit_From_Booking_OUT],
    value: EDepositAccountRowType.Automatic_Credit_From_Booking_OUT,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Transfer_Between_Travel_Companies_IN],
    value: EDepositAccountRowType.Transfer_Between_Travel_Companies_IN,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Transfer_Between_Travel_Companies_OUT],
    value: EDepositAccountRowType.Transfer_Between_Travel_Companies_OUT,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: depositAccountRowTypeLabelMap[EDepositAccountRowType.Credit_Note_For_Travel_Partner_IN],
    value: EDepositAccountRowType.Credit_Note_For_Travel_Partner_IN,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
];

export const getDepositAccountTypeOptions = (
  initialBCFRowExists: boolean,
  currency: TCurrencyCode | null
): IMultiselectValueLabelPair[] => {
  return depositAccountTypeOptions.filter(item => {
    const typesToFilterOut: EDepositAccountRowType[] = [];

    if (initialBCFRowExists) {
      typesToFilterOut.push(...initialDepositAccountRowTypes);
    } else {
      typesToFilterOut.push(...adjustmentDepositAccountRowTypes);
    }

    if (currency === 'USD') {
      typesToFilterOut.push(...eurDepositAccountRowTypes);
    } else if (currency === 'EUR') {
      typesToFilterOut.push(...usdDepositAccountRowTypes);
    }

    return !typesToFilterOut.includes(item.value as EDepositAccountRowType);
  });
};
