import React from 'react';
import { formatDateDisplay, numberOfNights } from 'utils';
import { pluralize } from 'utils/string';

interface IPlainDatesProps {
  checkInDate: string;
  checkOutDate: string;
}

export const PlainDates: React.FC<IPlainDatesProps> = ({ checkInDate, checkOutDate }) => {
  return (
    <p className="booking-dates-container font-hurmegeometric-sans text-13px leading-16px text-flint uppercase m-0">
      <span className="booking-dates font-bold">{formatDateDisplay(checkInDate)} - {formatDateDisplay(checkOutDate)}</span>
      <span className="booking-nights bg-teal-20 ml-5px p-2px">{numberOfNights(checkInDate, checkOutDate)} {pluralize(numberOfNights(checkInDate, checkOutDate), 'NIGHT')}</span>
    </p>
  );
}
