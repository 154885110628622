import { IGlobalStatementDomain } from './types';
import { createSelector } from 'reselect';

export const globalStatementDomain = (state: any): IGlobalStatementDomain => state.globalStatement;

export const tableDataSelector = createSelector(globalStatementDomain, domain => {
  // we break it apart so we can reverse the currency list, see https://pureescapes.atlassian.net/browse/OWA-3445?focusedCommentId=25263
  return {
    ...domain.tableData,
    responseRows: domain.tableData.responseRows.map(rr => {
      return {
        ...rr,
        totals: rr.totals.sort((t1, t2) => {
          return t1.currencyCode > t2.currencyCode ? -1 : 1;
        }),
      };
    }),
  };
});
export const totalsSelector = createSelector(globalStatementDomain, domain => {
  // we break it apart so we can reverse the currency list, see https://pureescapes.atlassian.net/browse/OWA-3445?focusedCommentId=25263
  return domain.totals.sort((t1, t2) => {
    return t1.currencyCode > t2.currencyCode ? -1 : 1;
  });
});
export const networkSelector = createSelector(globalStatementDomain, domain => domain.network);
export const pageNumberSelector = createSelector(globalStatementDomain, domain => domain.pageNumber);
export const itemsPerPageSelector = createSelector(globalStatementDomain, domain => domain.itemsPerPage);
export const totalResultCountSelector = createSelector(globalStatementDomain, domain => domain.totalResultCount);

export const pageCountSelector = createSelector(
  itemsPerPageSelector,
  totalResultCountSelector,
  (itemsPerPage, totalResultCount): number => {
    return itemsPerPage && totalResultCount ? Math.ceil(totalResultCount / itemsPerPage) : 0;
  }
);

export const sortBySelector = createSelector(globalStatementDomain, domain => domain.sortBy);
export const sortOrderSelector = createSelector(globalStatementDomain, domain => domain.sortOrder);

export const filtersDataSelector = createSelector(globalStatementDomain, domain => domain.filter);

export const tableDataQuerySelector = createSelector(
  pageNumberSelector,
  itemsPerPageSelector,
  sortBySelector,
  sortOrderSelector,
  filtersDataSelector,
  (pageNumber, itemsPerPage, sortBy, sortOrder, filtersData) => {
    const filter: {
      netBalanceCentsMax?: number;
      netBalanceCentsMin?: number;
      companyUuid?: string;
      companyCountryCode?: string;
    } = {
      netBalanceCentsMin: filtersData.amountMinCents,
      netBalanceCentsMax: filtersData.amountMaxCents,
      companyUuid: filtersData.travelCompanyUuid ? filtersData.travelCompanyUuid : undefined,
      companyCountryCode: filtersData.tpCountry ? filtersData.tpCountry : undefined,
    };

    const query = {
      filter,
      sort: {
        field: sortBy,
        order: sortOrder,
      },
      pagination: {
        offset: pageNumber * itemsPerPage,
        limit: itemsPerPage,
      },
    };

    return query;
  }
);

export const exportCsvNamespaceSelector = createSelector(globalStatementDomain, domain => domain.exportCsvNamespace);
