import { trim }  from 'lodash-es';
import { createSelector } from 'reselect';
import { IProposalGenerateDomain } from './model';

export const proposalGenerateDomain = (state: any): IProposalGenerateDomain => state.proposalGenerate;

export const formData = createSelector(
  proposalGenerateDomain,
  domain => domain.formData
);

export const request = createSelector(
  proposalGenerateDomain,
  domain => domain.request
);

export const proposal = createSelector(
  proposalGenerateDomain,
  domain => domain.proposal
);

export const proposalSelection = createSelector(
  proposalGenerateDomain,
  domain => domain.proposalSelection
);

export const bookingUuids = createSelector(
  proposalGenerateDomain,
  domain => domain.bookingUuids
);

export const formValid = createSelector(
  formData,
  data => !(
    emptyStr(data.name) ||
    emptyStr(data.guest.firstName) ||
    emptyStr(data.guest.lastName)
  )
);

export const proposalLogoOptionsSelector = createSelector(
  proposalGenerateDomain,
  (domain: IProposalGenerateDomain): IProposalGenerateDomain['logoOptions'] => domain.logoOptions
);

const emptyStr = (x: string | null | undefined): boolean => {
  return !trim(x || undefined);
};
