import React from 'react';
import classNames from 'classnames';
import { ENetworkRequestStatus, EUserType } from 'services/BackendApi';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { StyledRadio } from 'pureUi/StyledRadio';
import Checkbox from 'ui/Checkbox';
import { FluidButton } from 'ui/FluidButton';

import {
  newUploadPayloadSelector,
  canSubmitUploadSelector,
  newUploadRequestStatusSelector,
  newUploadErrorSelector,
  newUploadValidationSelector,
  shouldShowNewUploadPrivacyAckSelector,
  newUploadPrivacyAckSelector,
} from 'store/modules/bookingManager/subdomains/uploads/selectors';

import {
  closeUploadFormAction,
  setUploadDisplayNameAction,
  setUploadTagAction,
  setUploadFileAction,
  setPrivacyAckAction,
  newUploadRequestAction,
} from 'store/modules/bookingManager/subdomains/uploads/actions';

export class NewUploadModal extends React.Component<INewUploadModalProps, {}> {
  componentDidMount() {
    const tabsSpec = this.props.tabsSpec;
    if (tabsSpec.length === 1) {
      this.props.setUploadTag(tabsSpec[0].tag);
    }
  }

  renderHeader = () => {
    return (
      <div className="header flex justify-between px-4 mt-2 mb-30px">
        <h1 className="font-noe-display font-normal text-21px leading-27px my-0">Upload New File</h1>
        <i className="fas fa-times text-brown-100 cursor-pointer" onClick={this.props.closeUploadForm} />
      </div>
    );
  };

  renderFormInputs = () => {
    const {
      newUploadPayload,
      newUploadValidation,
      tabsSpec,
      shouldShowNewUploadPrivacyAck,
      setUploadFile,
      setUploadDisplayName,
      setUploadTag,
    } = this.props;

    if (!newUploadPayload) {
      return null;
    }

    return (
      <div className="form-inputs px-4">
        <div className="file-form-item flex items-center mb-6">
          <label className="select-file inline-block font-bold text-sm text-brown-100 tracking-wider py-2 px-3 border border-solid border-brown-100 rounded bg-white-true hover:bg-brown-20 cursor-pointer transition-bg-color duration-200">
            <input
              onChange={e => e.currentTarget.files && setUploadFile(e.currentTarget.files[0])}
              type="file"
              className="hidden"
            />
            Select a file
          </label>
          {newUploadPayload.file && !!newUploadValidation.file.length && (
            <span className="validation-error ml-4 text-sm text-red-100">{newUploadValidation.file[0]}</span>
          )}
        </div>
        <label className="block font-bold text-base leading-21px mb-1">Title of the document</label>
        <input
          onChange={e => setUploadDisplayName(e.currentTarget.value)}
          value={newUploadPayload.displayName || undefined}
          type="text"
          className="filename text-15px bg-ivory border border-solid border-gray-40 px-3 py-2 w-1/2 outline-none focus:border-gray-80 transition-border-color duration-200 mb-6"
        />
        <label className="block font-bold text-base leading-21px mb-10px">Select file category</label>
        <div
          className={classNames(
            'tag-form-item flex flex-wrap justify-between',
            shouldShowNewUploadPrivacyAck ? 'mb-30px' : 'mb-50px'
          )}
        >
          {tabsSpec.map(item => (
            <label
              key={item.tag}
              className={`${item.tag} inline-flex items-center text-base text-black cursor-pointer`}
            >
              <StyledRadio
                className={item.tag}
                type="radio"
                name={item.tag}
                checked={newUploadPayload.tag === item.tag}
                onChange={() => setUploadTag(item.tag)}
              />
              <span className="surrogate relative inline-block w-4 h-4 mr-2 bg-ivory border border-solid border-gray-40 rounded-full" />
              {item.label}
            </label>
          ))}
        </div>
      </div>
    );
  };

  renderPrivacyAck = () => {
    const { newUploadPrivacyAck, setPrivacyAck } = this.props;

    return (
      <div className="privacy-ack bg-brown-15 rounded font-pt-sans px-4 py-5 mb-30px">
        <h3 className="title text-base text-brown-100 leading-21px mt-0 mb-2">
          Privacy of the document <i className="fas fa-exclamation-triangle text-sm" />
        </h3>
        <p className="content text-15px text-black leading-sm mt-0 mb-5">
          <span>
            The <i>Travel Partner</i> linked to this booking will be able to see this document. If you don't want the{' '}
            <i>Travel Partner</i> to see the document, please select{' '}
            <i>
              <b>‘Other Internal’</b>
            </i>
            .<br />
            Is this a document they should be able to see?
          </span>
        </p>
        <label className="controls flex text-base text-black leading-21px cursor-pointer">
          <Checkbox checked={newUploadPrivacyAck} onChange={e => setPrivacyAck(e.currentTarget.checked)} />
          <span className="ml-2">YES, they can see it</span>
        </label>
      </div>
    );
  };

  renderControls = () => {
    const { canSubmitUpload, newUploadRequestStatus } = this.props;

    const isLoading = newUploadRequestStatus === ENetworkRequestStatus.PENDING;

    return (
      <div className="controls flex items-center justify-center">
        <FluidButton
          className="upload mb-3"
          type="primary"
          onClick={this.props.newUploadRequest}
          disabled={!canSubmitUpload || isLoading}
          isLoading={isLoading}
        >
          Upload file
        </FluidButton>
      </div>
    );
  };

  render() {
    const { shouldShowNewUploadPrivacyAck } = this.props;

    return (
      <div
        onClick={this.props.closeUploadForm}
        className="overlay fixed flex items-center justify-center top-0 right-0 bottom-0 left-0 bg-black-true-transparent-60 z-10"
      >
        <div
          onClick={e => e.stopPropagation()}
          className="upload-modal modal bg-white-true rounded w-full max-w-700px p-18px text-black font-pt-sans"
        >
          {this.renderHeader()}
          {this.renderFormInputs()}
          {shouldShowNewUploadPrivacyAck && this.renderPrivacyAck()}
          {this.renderControls()}
        </div>
      </div>
    );
  }
}

export type StateToProps = ReturnType<typeof mapStateToProps>;
export type DispatchToProps = typeof actionCreators;

interface TabSpec {
  tag: string;
  label: string;
  roles: EUserType[];
}

export interface INewUploadModalProps extends StateToProps, DispatchToProps {
  tabsSpec: TabSpec[];
}

const mapStateToProps = createStructuredSelector({
  newUploadPayload: newUploadPayloadSelector,
  newUploadRequestStatus: newUploadRequestStatusSelector,
  newUploadError: newUploadErrorSelector,
  newUploadValidation: newUploadValidationSelector,
  shouldShowNewUploadPrivacyAck: shouldShowNewUploadPrivacyAckSelector,
  newUploadPrivacyAck: newUploadPrivacyAckSelector,
  canSubmitUpload: canSubmitUploadSelector,
});

const actionCreators = {
  closeUploadForm: closeUploadFormAction,
  setUploadDisplayName: setUploadDisplayNameAction,
  setUploadTag: setUploadTagAction,
  setUploadFile: setUploadFileAction,
  setPrivacyAck: setPrivacyAckAction,
  newUploadRequest: newUploadRequestAction,
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

// -----------------------------------------------------------------------------
// Connected
// -----------------------------------------------------------------------------
const withConnect = connect<StateToProps, DispatchToProps, INewUploadModalProps>(mapStateToProps, mapDispatchToProps);

// @ts-ignore
export const NewUploadModalConnected = withConnect(NewUploadModal);
export default NewUploadModalConnected;
