import { ENetworkRequestStatus } from 'services/BackendApi';
import { IEnabledNotification, INotification } from 'services/BackendApi/types/Notification';

export enum EAssignNotificationsAction {
  ASSIGN_TO_ALL = 'ASSIGN_TO_ALL',
  REMOVE_FROM_ALL = 'REMOVE_FROM_ALL',
}

export interface INotificationsDomain {
  possibleNotifications: INotification[] | null;
  enabledNotifications: IEnabledNotification[] | null;
  assignNotificationsAction: EAssignNotificationsAction | null;
  ui: {
    isNotificationSettingsModalVisible: boolean;
    isConfirmationModalVisible: boolean;
  };
  request: {
    getPossibleNotifications: ENetworkRequestStatus;
    getEnabledNotifications: ENetworkRequestStatus;
    setNotificationsForCompanyUsers: ENetworkRequestStatus;
  }
}

export const initialState: INotificationsDomain = {
  possibleNotifications: null,
  enabledNotifications: null,
  assignNotificationsAction: null,
  ui: {
    isNotificationSettingsModalVisible: false,
    isConfirmationModalVisible: false,
  },
  request: {
    getPossibleNotifications: ENetworkRequestStatus.IDLE,
    getEnabledNotifications: ENetworkRequestStatus.IDLE,
    setNotificationsForCompanyUsers: ENetworkRequestStatus.IDLE,
  }
};
