import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';
import { List } from './List';
import { Create } from './Create';
import { Edit } from './Edit';
import { EditChildren } from './EditChildren';

export interface HotelAdminRoutingProps extends RouteComponentProps {}

export const HotelAdminRouting = (props: HotelAdminRoutingProps) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/`} component={List} exact />
      <Route path={`${props.match.path}/create`} component={Create} exact />
      <Route path={`${props.match.path}/:hotelUuid/edit`} component={Edit} />
      <Route path={`${props.match.path}/:hotelUuid/edit-children`} component={EditChildren} />
    </Switch>
  );
};

export const RateBreakRouting = compose(withRouter)(HotelAdminRouting);
