
import { AxiosResponse } from 'axios';
import { call, takeLatest, put } from 'redux-saga/effects';
import { makeBackendApi } from 'services/BackendApi';
import { FETCH_COMPANY_DEPARTMENTS_REQUEST, FetchCompanyDepartmentsRequestAction, fetchCompanyDepartmentsFailureAction, fetchCompanyDepartmentsSuccessAction } from '../actions';
import { ICompanyDepartmentsResponse } from 'services/BookingManagerApi/types/CompanyDepartmentsResponse';

function* fetchDepartmentsSaga(action: FetchCompanyDepartmentsRequestAction) {
  try {
    const backendApi = makeBackendApi();
    const result: AxiosResponse<ICompanyDepartmentsResponse> = yield call(
      backendApi.fetchCompanyDepartments,
      action.companyUuid,
    );

    yield put(fetchCompanyDepartmentsSuccessAction(result.data.data));
  } catch (e) {
    yield put(fetchCompanyDepartmentsFailureAction(e));
  }
}

export function* watchFetchDepartmentsSaga() {
  yield takeLatest(
    [FETCH_COMPANY_DEPARTMENTS_REQUEST],
    fetchDepartmentsSaga
  );
}
