import * as Actions from './actions';
import { IBasketDomain, initialState } from './model';
import { produce } from 'immer';

export const basketReducer = (state: IBasketDomain = initialState, action: Actions.TBasketActions): IBasketDomain => {
  switch (action.type) {
    case Actions.SET_BASKET_BUILD:
      return produce(state, draftState => {
        draftState.basketBuild = action.basketBuild;
        return draftState;
      });

    case Actions.SET_SHOULD_SHOW_LEAVE_WARNING_IN_BOOKING_BUILDER:
      return produce(state, draftState => {
        draftState.shouldShowLeaveWarningInBookingBuilder = action.value;
        return draftState;
      });

    default:
      return state;
  }
};

export default basketReducer;
