import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const PencilIcon: React.FC<IIconProps> = React.memo(
  ({ icon = theme.colors['brown-prime'], height = 18, width = 19 }) => (
    <svg width={width} height={height} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path clipRule="evenodd" d="M15.5325 4.72242C15.825 5.01492 15.825 5.48742 15.5325 5.77992L14.16 7.15242L11.3475 4.33992L12.72 2.96742C13.0125 2.67492 13.485 2.67492 13.7775 2.96742L15.5325 4.72242ZM2.25 16.2499L2.25 13.4374L10.545 5.14242L13.3575 7.95492L5.0625 16.2499H2.25Z" fill={icon}/>
    </svg>
  ),
);
    