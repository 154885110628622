import React, { HTMLProps, useCallback } from 'react';
import styled from 'styled-components';
import { pureUiTheme } from '../pureUiTheme';
import classnames from 'classnames';
import { PAGINATION_DOTS, StringOrNumber, usePageNumbers } from './usePageNumbers';

const BUTTON_WIDTH = 25;
const BUTTON_HEIGHT = 30;
const BUTTON_MARGIN = 5;

export const getStartIndex = (currentPage: number, totalPages: number, maxItems: number): number => {
  const remainingPages = totalPages - currentPage;

  if (currentPage >= maxItems && remainingPages >= maxItems) {
    return currentPage - Math.floor(maxItems / 2) - 1;
  }

  if (currentPage >= maxItems && remainingPages <= maxItems) {
    return totalPages - maxItems;
  }

  return 0;
};

export interface IPaginationProps extends HTMLProps<HTMLDivElement> {
  pageCount: number;
  currentPage: number;
  itemsPerPage: number;
  onPageSelect: (pageNumber: number) => void;
  onItemsPerPageChange: (itemsPerPage: number) => void;
  isLoading?: boolean;
}

export const PaginationComponent = (props: IPaginationProps) => {
  const [pageNumbers] = usePageNumbers(props.pageCount, props.currentPage);
  const { isLoading = false } = props;

  const handleClick = useCallback(
    (pageNumber: number) => () => {
      props.onPageSelect(pageNumber);
    },
    [props.pageCount]
  );

  const handleItemsPerPageClick = useCallback(
    (itemsPerPage: number) => () => {
      if (props.itemsPerPage !== itemsPerPage) {
        props.onItemsPerPageChange(itemsPerPage);
        // Reset to the 1st page
        props.onPageSelect(1);
      }
    },
    [props.onItemsPerPageChange, props.itemsPerPage]
  );

  return (
    <div
      className={classnames(props.className, {
        'opacity-30 pointer-events-none': isLoading,
      })}
    >
      <div className="pagination-left-space"></div>
      <div className="pagination-pages">
        <PaginationButton
          data-role="prev page button"
          title={`Previous page`}
          className="prev"
          disabled={props.currentPage === 1}
          onClick={handleClick(props.currentPage - 1)}
        >
          <i className="fas fa-chevron-left"></i>
        </PaginationButton>

        <div className="dynamicButtons">
          {pageNumbers.map((pageNumber: StringOrNumber, idx: number) => {
            return pageNumber === PAGINATION_DOTS ? (
              <PaginationButton data-role="page button" title="..." key={`${idx}${pageNumber}`}>
                {pageNumber}
              </PaginationButton>
            ) : (
              <PaginationButton
                data-role="page button"
                title={`Go to page ${pageNumber}`}
                onClick={handleClick(Number(pageNumber))}
                key={`${props.currentPage}-${pageNumber}`}
                disabled={pageNumber === props.currentPage}
              >
                {pageNumber}
              </PaginationButton>
            );
          })}
        </div>

        <PaginationButton
          data-role="next page button"
          title="Next Page"
          className="next"
          disabled={props.currentPage === props.pageCount}
          onClick={handleClick(props.currentPage + 1)}
        >
          <i className="fas fa-chevron-right"></i>
        </PaginationButton>
      </div>
      <div className="pagination-items-per-page-container">
        <div
          className={classnames('pagination-items-per-page pagination-show-10', {
            active: props.itemsPerPage === 10,
          })}
          onClick={handleItemsPerPageClick(10)}
        >
          Show 10
        </div>
        <div
          className={classnames('pagination-items-per-page pagination-show-25', {
            active: props.itemsPerPage === 25,
          })}
          onClick={handleItemsPerPageClick(25)}
        >
          Show 25
        </div>
        <div
          className={classnames('pagination-items-per-page pagination-show-50', {
            active: props.itemsPerPage === 50,
          })}
          onClick={handleItemsPerPageClick(50)}
        >
          Show 50
        </div>
      </div>
    </div>
  );
};

export const Pagination = styled(PaginationComponent)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pagination-left-space {
    width: 240px;
  }
  
  .dynamicButtons {
    flex-grow: 2;
  }

  .preMeasure {
    height: 50px;
    overflow: hidden;
  }

  & .pagination-pages {
    display: flex;
  }
  
  .pagination-items-per-page-container {
    display: flex;
  }

   .pagination-items-per-page {
     min-width: 80px;
     height: 30px;
     font-family: 'PT Sans', Arial, sans-serif;
     font-size: 14px;
     line-height: 18px;
     color: ${pureUiTheme.colors.brown100};
     font-weight: 700;
     letter-spacing: 0.06em;
     cursor: pointer;
     display: flex;
     justify-content: center;
     align-items: center;
     border-style: solid;
     border-width: 1px;
     border-color: ${pureUiTheme.colors.brown100};
     user-select: none;

     &.active {
       color: ${pureUiTheme.colors.teal100};
       border-color: ${pureUiTheme.colors.greenDark};
       
       &:hover {
         background: transparent;
         cursor: default;
       }
     }
     
     &:hover {
       background: rgba(227, 218, 209, 0.5);
     }

     &:active {
       background: #F6F6F2;
     }
   }
    
    & .pagination-show-10 {
      border-radius: 4px 0 0 4px;
      border-right-style: ${props => (props.itemsPerPage !== 25 ? 'solid' : 'none')};
    }

    & .pagination-show-25 {
      border-left-style: ${props => (props.itemsPerPage === 25 ? 'solid' : 'none')};
      border-right-style: ${props => (props.itemsPerPage === 25 ? 'solid' : 'none')};
    }

    & .pagination-show-50 {
      border-radius: 0 4px 4px 0;
      border-left-style: ${props => (props.itemsPerPage !== 25 ? 'solid' : 'none')};
    }
  }
`;

export const PaginationButton = styled.button`
  background-color: transparent;
  color: ${pureUiTheme.colors.brown100};
  font-family: 'PT Sans', Arial, sans-serif;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.06em;
  font-weight: bold;
  border: none;
  width: ${BUTTON_WIDTH}px;
  height: ${BUTTON_HEIGHT}px;
  margin: ${BUTTON_MARGIN}px;
  outline: none;
  transition: all 0.15s ease-out;
  padding: 0;
  cursor: ${props => (props.children === PAGINATION_DOTS ? 'default' : 'pointer')};

  &:hover {
    background: ${props => (props.children === PAGINATION_DOTS ? 'transparent' : 'rgba(227, 218, 209, 0.5)')};
  }

  &:disabled {
    cursor: default;
    color: ${pureUiTheme.colors.teal100};
    border-bottom: 3px solid ${pureUiTheme.colors.teal100};
  }

  svg {
    font-size: 25px;
    height: 100%;
    width: 100%;
    fill: ${pureUiTheme.colors.gold};
  }

  &.prev,
  &.next {
    min-width: 9px;
    min-height: 19px;
    border: none;
    color: ${pureUiTheme.colors.brown100};
  }

  &.prev:disabled,
  &.next:disabled {
    color: ${pureUiTheme.colors.grayDepth5};
  }

  & i {
    font-size: 14px;
    line-height: 19px;
  }
`;
