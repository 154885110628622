import React, { useCallback } from 'react';
import Checkbox from 'ui/Checkbox';
import { INotification } from 'services/BackendApi/types/Notification';

interface ICompanyNotificationRowProps {
  notification: INotification;
  selected: boolean;
  onChange: (notificationCode: string, enabled: boolean) => void;
}

export const CompanyNotificationRow: React.FC<ICompanyNotificationRowProps> = React.memo(
  ({ notification, selected, onChange }) => {
    const handleNotificationChange = useCallback(() => {
      onChange(notification.code, !selected);
    }, [selected, notification, onChange]);

    return (
      <tr key={notification.code} className="notification-list-row text-15px">
        <td className="notification-title pl-5 py-4 border-solid border-b border-gray-20">
          <label className="notification-list-row-checkbox-label flex flex-row items-center">
            <Checkbox checked={selected} onClick={handleNotificationChange} />
            <span className="ml-2 font-bold">{notification.title}</span>
          </label>
        </td>
        <td
          className="notification-description pl-5 py-4 border-solid border-b border-gray-20 pr-10"
          title={notification.description}
        >
          {notification.description}
        </td>
      </tr>
    );
  }
);
