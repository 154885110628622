import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENetworkRequestStatus } from 'services/BackendApi';
import {
  isInternalUser as isSrSelector,
  isAdmin as isAdminSelector,
  isFinanceUser as isFinanceUserSelector,
} from 'store/modules/auth/selectors';
import { IInvoiceAddressee, IManualInvoiceDueDate, IPaymentMethod } from 'services/BookingManagerApi/types';
import { EBankAccount } from 'interfaces';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import {
  EInvoiceAddresseeType,
  EInvoiceMutationMode,
  EInvoiceLang,
} from 'store/modules/bookingManager/subdomains/breakdown/model';
import { breakdownNetworkRequestsSelector } from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import { enqueueNotification } from 'store/modules/ui';
import { formatPrice } from 'utils';
import { Radio } from 'ui/Radio';
import FluidButton from 'ui/FluidButton';
import { edit } from 'ui/Icons';
import { GeneralModal } from 'ui/GeneralModal';
import { ModalHeading } from 'ui/GeneralModal/ModalHeading';
import { InvoiceAddresseeText } from './InvoiceAddresseeText';
import SingleSelect from 'ui/SingleSelect';
import { ManualInvoiceModalDueDateRow } from 'ui/ManualInvoiceModalDueDateRow';
import { UITextArea } from 'ui/UITextArea';
import { InvoiceTPOrClient } from './InvoiceTPOrClient';
import Checkbox from 'ui/Checkbox';

export interface IManualInvoiceModal {
  isOpen: boolean;
  mode: EInvoiceMutationMode | null;
  invoiceAddresseeType: EInvoiceAddresseeType;
  invoiceAddressee?: IInvoiceAddressee;
  invoiceAddresseeLoad: ENetworkRequestStatus;
  selectedBankAccount: EBankAccount;
  lang: EInvoiceLang;
  totalCostToClientCents: number;
  SubtotalCents: number;
  bookingCurrencySymbol: string;
  paymentTerms: string;
  cancellationPolicies: string;
  paymentMethods: IPaymentMethod[];
  marginPercentage?: number;
}

export const getNewAmountFromPercentageOfOriginal = (initial: number, percentage: number) => {
  return Math.round((initial / 100) * percentage);
};

// eslint-disable-next-line react/display-name
export const ManualInvoiceModal: React.FC<IManualInvoiceModal> = React.memo(props => {
  const dispatch = useDispatch();
  const breakdownNetworkRequests = useSelector(breakdownNetworkRequestsSelector);

  const isSr = useSelector(isSrSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isFinanceUser = useSelector(isFinanceUserSelector);
  const isSupervisor = isSr || isAdmin || isFinanceUser;

  const { isOpen } = props;
  const [invoiceDueDate, setInvoiceDueDate] = useState<IManualInvoiceDueDate>({
    date: '',
    amountCents: props.totalCostToClientCents,
  });
  const [isAllItem, setIsAllItem] = useState<boolean>(true);
  const [description, setDescription] = useState<string | undefined>(undefined);

  // 6925
  const [amendedCancellationPolicies, setAmendedCancellationPolicies] = useState<string>(props.cancellationPolicies);
  const [isCpCheckTicked, setIsCpCheckTicked] = useState<boolean>(false);

  const handleGenerateInvoiceClick = useCallback(() => {
    dispatch(
      BreakdownActions.generateManualInvoiceRequestAction(
        props.selectedBankAccount,
        { ...invoiceDueDate, amountCents: isAllItem ? undefined : invoiceDueDate.amountCents },
        amendedCancellationPolicies,
        description
      )
    );
  }, [dispatch, props.selectedBankAccount, invoiceDueDate, isAllItem, description, amendedCancellationPolicies]);

  const handleCloseClick = useCallback(() => {
    dispatch(BreakdownActions.closeCreateManualInvoiceModalAction());

    if (props.mode === EInvoiceMutationMode.UPDATE) {
      dispatch(
        enqueueNotification({
          message: 'The breakdown was not updated. Please save new invoice to confirm changes',
          options: { variant: 'warning' },
        })
      );
    }
  }, [dispatch, props.mode]);

  const balance = useMemo(() => {
    const amount =
      props.invoiceAddresseeType === EInvoiceAddresseeType.FINAL_CLIENT
        ? props.totalCostToClientCents
        : props.SubtotalCents;
    setInvoiceDueDate(prevVals => ({ ...prevVals, amountCents: amount }));
    return amount;
  }, [props.invoiceAddresseeType]);

  const handleSetBankAccount = useCallback(
    bankAccount => {
      dispatch(BreakdownActions.setManualInvoiceBankAccountAction(bankAccount));
    },
    [dispatch]
  );

  const handleSetTravelPartnerInvoice = useCallback(() => {
    if (props.invoiceAddresseeType !== EInvoiceAddresseeType.TRAVEL_PARTNER) {
      dispatch(BreakdownActions.setManualInvoiceAddresseeTypeAction(EInvoiceAddresseeType.TRAVEL_PARTNER));
      dispatch(BreakdownActions.getManualInvoiceAddresseeRequestAction());
    }
  }, [dispatch, props.invoiceAddresseeType]);

  const handleSetFinalClientInvoice = useCallback(() => {
    if (props.invoiceAddresseeType !== EInvoiceAddresseeType.FINAL_CLIENT) {
      dispatch(BreakdownActions.setManualInvoiceAddresseeTypeAction(EInvoiceAddresseeType.FINAL_CLIENT));
      dispatch(BreakdownActions.getManualInvoiceAddresseeRequestAction());
    }
  }, [dispatch, props.invoiceAddresseeType]);

  const handleEditAddressee = useCallback(() => {
    dispatch(BreakdownActions.openAddresseeManualModalAction());
  }, [dispatch]);

  const handleSetIsAll = useCallback(() => {
    setIsAllItem(true);
    setInvoiceDueDate(prevValue => ({ ...prevValue, amountCents: balance }));
    setDescription(undefined);
  }, [dispatch, invoiceDueDate]);

  const handleResetIsAll = useCallback(() => {
    setIsAllItem(false);
  }, [dispatch]);

  const handleInvoiceDueDateUpdateAmountCents = (newAmountCents: number) => {
    const newInvoiceDueDates = { ...invoiceDueDate };
    newInvoiceDueDates.amountCents = newAmountCents;
    setInvoiceDueDate(newInvoiceDueDates);
  };

  const handleInvoiceDueDateUpdateDate = (newDate: string) => {
    const newInvoiceDueDates = { ...invoiceDueDate };
    newInvoiceDueDates.date = newDate;
    setInvoiceDueDate(newInvoiceDueDates);
  };

  const paymentMethodOptions = useMemo(
    () =>
      props.paymentMethods.map(x => ({
        value: x.name,
        label: x.name,
      })),
    [props.paymentMethods]
  );

  const selectedPaymentMethod = useMemo(() => props.paymentMethods.find(x => x.name === props.selectedBankAccount), [
    props.paymentMethods,
    props.selectedBankAccount,
  ]);

  const generateDisabled = useMemo(() => {
    // The generate form should be disabled if one of the following happend:
    const firstCase = balance <= 0; // 1- the total amount is 0 or lower
    const secondCase = invoiceDueDate.date === ''; // 2- if the date is not set
    const thirdCase = !isAllItem && invoiceDueDate.amountCents !== undefined ? invoiceDueDate.amountCents <= 0 : false; // 3- if the partial is set and the amount of cents is 0 or lower
    const fourthCase =
      !isAllItem && invoiceDueDate.amountCents !== undefined ? invoiceDueDate.amountCents > balance : false; // 4- if the partial is set and the amount of cents is larger that the amount
    const fifthCase = !isAllItem ? description === undefined : false; // 5- if the partial is set and the description is empty

    // 6925 - if the final client is selected, the user must tick the checkbox
    const sixthCase = props.invoiceAddresseeType === EInvoiceAddresseeType.FINAL_CLIENT && !isCpCheckTicked;

    // OWA-7676 - a bank account must be selected and it might not have been selected by default
    const seventhCase = !props.selectedBankAccount;

    return !!firstCase || !!secondCase || !!thirdCase || !!fourthCase || !!fifthCase || !!sixthCase || !!seventhCase;
  }, [
    invoiceDueDate,
    balance,
    description,
    isAllItem,
    props.invoiceAddresseeType,
    isCpCheckTicked,
    props.selectedBankAccount,
  ]);

  if (!isOpen) {
    return null;
  }

  return (
    <GeneralModal
      onClose={handleCloseClick}
      modalWindowClassName="w-778px px-35px py-25px rounded"
      shouldCloseByClickingOutside
    >
      <ModalHeading>Create Manual Invoice</ModalHeading>

      <InvoiceTPOrClient
        edit={edit}
        handleEditAddressee={handleEditAddressee}
        handleSetFinalClientInvoice={handleSetFinalClientInvoice}
        handleSetTravelPartnerInvoice={handleSetTravelPartnerInvoice}
        invoiceAddresseeType={props.invoiceAddresseeType}
        isSupervisor={isSupervisor}
        invoiceType="manual"
      />

      <InvoiceAddresseeText
        invoiceAddressee={props.invoiceAddressee}
        invoiceAddresseeLoad={props.invoiceAddresseeLoad}
      />

      <SingleSelect
        fieldId="invoice-payment-method"
        label="Payment Method"
        className="invoice-payment-method max-w-[730px] mt-[30px]"
        labelClassName="text-[16px] leading-[21px] font-bold"
        value={props.selectedBankAccount}
        options={paymentMethodOptions}
        onChange={handleSetBankAccount}
        maxVisibleItems={6}
      />

      {!!selectedPaymentMethod?.warning && (
        <div className="mt-[5px] mb-5 min-h-[100px] max-h-[100px] overflow-y-scroll max-w-fit border border-solid border-gray-20 rounded p-4 font-pt-sans leading-xs text-13px text-black">
          {selectedPaymentMethod.warning}
        </div>
      )}

      <p className="font-pt-sans font-bold text-base leading-sm text-black mt-[30px] mb-4">Items</p>
      <div className="flex">
        <label className="mr-6">
          <Radio checked={isAllItem === true} onClick={handleSetIsAll} />
          <span className="ml-2">All</span>
        </label>

        <label className="mr-6">
          <Radio checked={isAllItem === false} onClick={handleResetIsAll} />
          <span className="ml-2">Partial</span>
        </label>
      </div>

      {!isAllItem && (
        <>
          <UITextArea
            errorMessage={description === undefined ? '' : null}
            aboutText="Write a description to the invoice ..."
            value={description ? description : ''}
            onChange={value => {
              if (value) setDescription(value);
              else setDescription(undefined);
            }}
          />
        </>
      )}

      <p className="font-pt-sans font-bold text-base leading-sm text-black mt-[30px] mb-4">Due Dates</p>
      <div className="mb-4 font-pt-sans">
        <div className="flex justify-between items-center bg-brown-10 p-2">
          <span className="text-sm uppercase">Balance</span>
          <span className="font-bold">
            {props.bookingCurrencySymbol}
            {formatPrice(balance / 100)}
          </span>
        </div>

        <div
          style={{
            maxHeight: '155px',
            overflowY: 'auto',
          }}
        >
          <ManualInvoiceModalDueDateRow
            className="pt-4"
            date={invoiceDueDate.date || null}
            amountCents={invoiceDueDate.amountCents || 0}
            currencySymbol={props.bookingCurrencySymbol}
            isPriceEnabled={isAllItem}
            onUpdateAmountCents={newAmountCents => {
              handleInvoiceDueDateUpdateAmountCents(newAmountCents);
            }}
            onUpdateDate={newDate => {
              handleInvoiceDueDateUpdateDate(newDate);
            }}
            balance={balance}
          />
        </div>
      </div>

      {props.invoiceAddresseeType === EInvoiceAddresseeType.FINAL_CLIENT && (
        <div className="mb-4 space-y-5px">
          <span className="font-pt-sans block font-bold text-[16px] leading-20px ">Cancellation Policies</span>

          <div className="font-pt-sans text-13px ">
            <span className="block">
              The final client margin for this booking is{' '}
              <span className="font-bold text-16px">{props.marginPercentage || 0}%</span>.
            </span>
          </div>

          <textarea
            className="border border-gray-40 bg-ivory font-pt-sans text-15px leading-18px w-full active:outline-teal-100 focus:outline-teal-100 p-2"
            rows={5}
            onChange={e => {
              setAmendedCancellationPolicies(e.target.value);
            }}
          >
            {amendedCancellationPolicies}
          </textarea>

          <label className="font-pt-sans flex items-center text-black text-13px space-x-6px">
            <Checkbox
              checked={isCpCheckTicked}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setIsCpCheckTicked(e.target.checked);
              }}
            />
            <span className="block">
              I have read the cancellation policy and included the corresponding margin where necessary
            </span>
          </label>
        </div>
      )}

      {props.totalCostToClientCents > 0 && (
        <div className="border border-solid border-gray-40 font-pt-sans text-[13px] leading-[18px] p-2 text-black mb-6 opacity-70">
          <span className="block font-bold">Payment Terms</span>
          <span className="block">{props.paymentTerms}</span>
        </div>
      )}

      <div className="flex items-center justify-between">
        <FluidButton
          type="primary"
          fixedWidth="220px"
          isLoading={breakdownNetworkRequests.createManualInvoice === ENetworkRequestStatus.PENDING}
          onClick={handleGenerateInvoiceClick}
          disabled={generateDisabled}
        >
          Generate Manual Invoice
        </FluidButton>
        {/* <div className="lang flex items-center">
          <div className="label font-pt-sans text-13px text-black max-w-70px">Invoice language:</div>
          <Multiselect
            className="min-w-120px bg-ivory"
            itemsClassname="bg-ivory"
            onUpdate={handleLang}
            selectedValues={[props.lang]}
            hideCheckboxes={true}
            isSingleSelectMode={true}
            isCloseOnSelect={true}
            options={langOptions}
            dropdownPosition="top"
          />
        </div> */}
      </div>
    </GeneralModal>
  );
});

export default ManualInvoiceModal;
