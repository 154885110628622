import React, { useCallback, CSSProperties } from 'react';
import classNames from 'classnames';
import { ICompanyListFilter } from 'store/modules/companyList/model';
import { FormLabel } from 'ui/FormLabel';
import TextInput from 'pureUi/TextInput';
import { CountrySelect } from './CountrySelect';

export interface FilterProps {
  className?: string;
  value: ICompanyListFilter;
  onSearch: (v: string | null) => void;
  onCountryCodeChange: (v: string | null) => void;
}

const textInputStyles: CSSProperties = {
  fontFamily: "'PT Sans', sans-serif",
  height: '35px',
  fontSize: '16px',
  backgroundColor: '#FBFBFA'
};

const spanTextInputStyles: CSSProperties = {
  borderColor: '#D5D2D1'  
};

export const Filter: React.FC<FilterProps> = (props) => {
  
  const handleSearch = useCallback(
    (e) => props.onSearch(e.target.value || null),
    [props.onSearch]
  );

  return (
    <div className={classNames(props.className, 'filter font-pt-sans')}>
      <p className="text-17px text-black font-bold leading-22px m-0">
        Filter Results
      </p>
      <div className="mt-10px flex items-end">
        <FormLabel className="search inline-block w-300px" text="Company Name">
          <TextInput
            style={textInputStyles}
            spanStyle={spanTextInputStyles}
            value={props.value.search || ''}
            onChange={handleSearch}
          />
        </FormLabel>
        <FormLabel className="country-code inline-block w-200px ml-20px" text="Country">
          <CountrySelect
            value={props.value.countryCode}
            onChange={props.onCountryCodeChange}
          />
        </FormLabel>
      </div>
    </div>
  );
}
