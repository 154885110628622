import { Replay } from '@sentry/replay';
import * as Sentry from '@sentry/browser';

export const initSentry = (sentryDSN, sentryEnv, sentryRelease, sessionUuid) => {
  if (sentryDSN) {
    // Sentry should handle "any uncaught exceptions triggered from your application"
    // https://docs.sentry.io/platforms/javascript/react/?platform=browser
    Sentry.init({
      dsn: sentryDSN,
      environment: sentryEnv,
      release: sentryRelease,
      beforeSend: (event, hint) => {
        const originalError = hint.originalException;
        if (
          originalError &&
          originalError.message &&
          originalError.message.includes('Request failed with status code 405')
        ) {
          // Discard the event by returning null
          return null;
        }

        // Check if it's a 405 which happens when the user is redirected to the callback URL
        const isSpecificError = event.message && event.message.includes('Request failed with status code 405');
        if (isSpecificError) {
          // Discard the event by returning null
          return null;
        }
        event.sessionUuid = sessionUuid;
        return event;
      },
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      integrations: [new Replay()],
    });
    Sentry.setTag('sessionUuid', sessionUuid);
    Sentry.setTag('tenant', 'not set');
  }
};
