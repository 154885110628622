import { IAccommodationAvailability, IAvailability } from './types';

export const FETCH_STATIC_RATES_AVAILABILITY_REQUEST =
  'staticRatesAvailability/FETCH_STATIC_RATES_AVAILABILITY_REQUEST';
export const FETCH_STATIC_RATES_AVAILABILITY_SUCCESS =
  'staticRatesAvailability/FETCH_STATIC_RATES_AVAILABILITY_SUCCESS';
export const FETCH_STATIC_RATES_AVAILABILITY_FAILURE =
  'staticRatesAvailability/FETCH_STATIC_RATES_AVAILABILITY_FAILURE';

export const FETCH_STATIC_RATES_AVAILABILITY_AGGREGATE_REQUEST =
  'staticRatesAvailability/FETCH_STATIC_RATES_AVAILABILITY_AGGREGATE_REQUEST';
export const FETCH_STATIC_RATES_AVAILABILITY_AGGREGATE_SUCCESS =
  'staticRatesAvailability/FETCH_STATIC_RATES_AVAILABILITY_AGGREGATE_SUCCESS';
export const FETCH_STATIC_RATES_AVAILABILITY_AGGREGATE_FAILURE =
  'staticRatesAvailability/FETCH_STATIC_RATES_AVAILABILITY_AGGREGATE_FAILURE';

export const RESET_STATIC_RATES_AVAILABILITY_DOMAIN = 'staticRatesAvailability/RESET_STATIC_RATES_AVAILABILITY_DOMAIN';

export const fetchStaticRatesAvailabilityRequestAction = (
  accommodationUuid: string,
  startDate: string,
  endDate: string
) => ({
  type: FETCH_STATIC_RATES_AVAILABILITY_REQUEST,
  accommodationUuid,
  startDate,
  endDate,
});

export const fetchStaticRatesAvailabilitySuccessAction = (accommodationUuid: string, availability: IAvailability) => ({
  type: FETCH_STATIC_RATES_AVAILABILITY_SUCCESS,
  accommodationUuid,
  availability,
});

export const fetchStaticRatesAvailabilityFailureAction = errorMessage => ({
  type: FETCH_STATIC_RATES_AVAILABILITY_FAILURE,
  errorMessage,
});

export const fetchStaticRatesAvailabilityAggregateRequestAction = (
  accommodationUuids: string[],
  startDate: string,
  endDate: string
) => ({
  type: FETCH_STATIC_RATES_AVAILABILITY_AGGREGATE_REQUEST,
  accommodationUuids,
  startDate,
  endDate,
});

export const fetchStaticRatesAvailabilityAggregateSuccessAction = (availabilities: IAccommodationAvailability[]) => ({
  type: FETCH_STATIC_RATES_AVAILABILITY_AGGREGATE_SUCCESS,
  availabilities,
});

export const fetchStaticRatesAvailabilityAggregateFailureAction = errorMessage => ({
  type: FETCH_STATIC_RATES_AVAILABILITY_AGGREGATE_FAILURE,
  errorMessage,
});

export const resetStaticRatesAvailabilityDomainAction = () => ({
  type: RESET_STATIC_RATES_AVAILABILITY_DOMAIN,
});
