import { AxiosResponse } from 'axios';
import { call, takeLatest, put, select, all } from 'redux-saga/effects';
import {
  FetchCompanyDataRequestAction,
  FETCH_COMPANY_DATA_REQUEST,
  fetchCompanyDataSuccessAction,
  fetchCompanyDataFailureAction,
  FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_REQUEST,
  fetchCompanyDepositAccountBalanceSuccess,
  fetchCompanyDepositAccountBalanceFailure,
} from '../actions';
import { makeBackendApi, ICompanyResponse } from 'services/BackendApi';
import { getCompanySelector, isTA } from 'store/modules/auth';
import { ILedgerTotalsResponse, makeBookingManagerApi } from 'services/BookingManagerApi';
import { TCurrencyCode } from 'interfaces';

const currencyOrder: TCurrencyCode[] = ['USD', 'EUR'];
const sortByCurrencyOrder = (a, b) => {
  if (currencyOrder.indexOf(b.currency) === -1) {
    return -1;
  }
  if (currencyOrder.indexOf(a.currency) > currencyOrder.indexOf(b.currency)) {
    return 1;
  }
  if (currencyOrder.indexOf(a.currency) < currencyOrder.indexOf(b.currency)) {
    return -1;
  }
  return 0;
};


export function* fetchCompanyDataSaga(action: FetchCompanyDataRequestAction) {
  try {
    const backendApi = makeBackendApi();
    const result: AxiosResponse<ICompanyResponse> = yield call(backendApi.fetchCompany, action.companyUuid);
    yield put(fetchCompanyDataSuccessAction(result.data.data));
  } catch (e) {
    yield put(fetchCompanyDataFailureAction(e));
  }
}

export function* fetchCompanyDepositAccountBalanceSaga() {
  try {
    const isTaUser = yield select(isTA);
    const company = yield select(getCompanySelector);
    if (isTaUser && company.enableInstantBooking) {
      const companyUuid: string = company.uuid;
      const bookingManagerApi = makeBookingManagerApi();
      const backendApi = makeBackendApi();
      const calls = [
        call(bookingManagerApi.depositStatementTotals, companyUuid),
        call(backendApi.fetchCompany, companyUuid)
      ];
      const [resultTotals, resultCompany]: [AxiosResponse<ILedgerTotalsResponse>, AxiosResponse<ICompanyResponse>] = yield all(calls.map(c => c));      
      yield put(fetchCompanyDepositAccountBalanceSuccess(resultCompany.data.data.eurCreditLimit, resultCompany.data.data.usdCreditLimit, resultTotals.data.sort(sortByCurrencyOrder)));
    }
  } catch (e) {
    yield put(fetchCompanyDepositAccountBalanceFailure());
  }
}

export function* watchFetchCompanyData() {
  yield takeLatest(FETCH_COMPANY_DATA_REQUEST, fetchCompanyDataSaga);
  yield takeLatest(FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_REQUEST, fetchCompanyDepositAccountBalanceSaga);
}
