import { IDateRange } from 'interfaces';

export const OFFER_ADD_PACKAGE = 'offers/OFFER_ADD_PACKAGE';
export const OFFER_REMOVE_PACKAGE = 'offers/OFFER_REMOVE_PACKAGE';
export const OFFER_SET_PACKAGE_DATE_RANGE = 'offers/OFFER_SET_PACKAGE_DATE_RANGE';
export const OFFER_ADD_PACKAGE_ACCOMMODATION_PRODUCT = 'offers/OFFER_ADD_PACKAGE_ACCOMMODATION_PRODUCT';
export const OFFER_SET_PACKAGE_ACCOMMODATION_PRODUCT = 'offers/OFFER_SET_PACKAGE_ACCOMMODATION_PRODUCT';
export const OFFER_REMOVE_ACCOMMODATION_PRODUCT = 'offers/OFFER_REMOVE_ACCOMMODATION_PRODUCT';
export const OFFER_ADD_ACCOMMODATON_PRODUCT_PACKAGE = 'offers/OFFER_ADD_ACCOMMODATON_PRODUCT_PACKAGE';
export const OFFER_REMOVE_ACCOMMODATON_PRODUCT_PACKAGE = 'offers/OFFER_REMOVE_ACCOMMODATON_PRODUCT_PACKAGE';
export const OFFER_SET_PACKAGE_NIGHTS = 'offers/OFFER_SET_PACKAGE_NIGHTS';
export const OFFER_SET_PACKAGE_RATE = 'offers/OFFER_SET_PACKAGE_RATE';
export const OFFER_SET_PACKAGE_EXTRA_NIGHT_RATE = 'offers/OFFER_SET_PACKAGE_EXTRA_NIGHT_RATE';
export const OFFER_ADD_PACKAGE_EXTRA_PERSON_RATE = 'offers/OFFER_ADD_PACKAGE_EXTRA_PERSON_RATE';
export const OFFER_REMOVE_PACKAGE_EXTRA_PERSON_RATE = 'offers/OFFER_REMOVE_PACKAGE_EXTRA_PERSON_RATE';
export const OFFER_SET_PACKAGE_EXTRA_PERSON_RATE = 'offers/OFFER_SET_PACKAGE_EXTRA_PERSON_RATE';
export const OFFER_SET_PACKAGE_EXTRA_PERSON_RATE_AGENAME = 'offers/OFFER_SET_PACKAGE_EXTRA_PERSON_RATE_AGENAME';
export const OFFER_SET_PACKAGE_EXTRA_PERSON_MAX = 'offers/OFFER_SET_PACKAGE_EXTRA_PERSON_MAX';

export type OfferAddPackageAction = ReturnType<typeof offerAddPackageAction>;
export const offerAddPackageAction = () => ({
  type: OFFER_ADD_PACKAGE as typeof OFFER_ADD_PACKAGE,
});

export type OfferRemovePackageAction = ReturnType<typeof offerRemovePackageAction>;
export const offerRemovePackageAction = (packageUuid: string) => ({
  type: OFFER_REMOVE_PACKAGE as typeof OFFER_REMOVE_PACKAGE,
  packageUuid,
});

export type OfferSetDateRangeAction = ReturnType<typeof offerSetDateRangeAction>;
export const offerSetDateRangeAction = (packageUuid: string, dateRange: IDateRange) => ({
  type: OFFER_SET_PACKAGE_DATE_RANGE as typeof OFFER_SET_PACKAGE_DATE_RANGE,
  packageUuid,
  dateRange,
});

export type OfferAddAccommodationProductAction = ReturnType<typeof offerAddAccommodationProductAction>;
export const offerAddAccommodationProductAction = (packageUuid: string) => ({
  type: OFFER_ADD_PACKAGE_ACCOMMODATION_PRODUCT as typeof OFFER_ADD_PACKAGE_ACCOMMODATION_PRODUCT,
  packageUuid,
});

export type OfferSetAccommodationProductAction = ReturnType<typeof offerSetAccommodationProductAction>;
export const offerSetAccommodationProductAction = (
  packageUuid: string,
  accommodationPackageIndex: number,
  accommodationProductUuid: string
) => ({
  type: OFFER_SET_PACKAGE_ACCOMMODATION_PRODUCT as typeof OFFER_SET_PACKAGE_ACCOMMODATION_PRODUCT,
  packageUuid,
  accommodationPackageIndex,
  accommodationProductUuid,
});

export type OfferRemoveAccommodationProductAction = ReturnType<typeof offerRemoveAccommodationProductAction>;
export const offerRemoveAccommodationProductAction = (packageUuid: string, accommodationPackageIndex: number) => ({
  type: OFFER_REMOVE_ACCOMMODATION_PRODUCT as typeof OFFER_REMOVE_ACCOMMODATION_PRODUCT,
  packageUuid,
  accommodationPackageIndex,
});

export type OfferAddAccommodationProductPackage = ReturnType<typeof offerAddAccommodationProductPackage>;
export const offerAddAccommodationProductPackage = (packageUuid: string, accommodationPackageIndex: number) => ({
  type: OFFER_ADD_ACCOMMODATON_PRODUCT_PACKAGE as typeof OFFER_ADD_ACCOMMODATON_PRODUCT_PACKAGE,
  packageUuid,
  accommodationPackageIndex,
});

export type OfferRemoveAccommodationProductPackage = ReturnType<typeof offerRemoveAccommodationProductPackage>;
export const offerRemoveAccommodationProductPackage = (
  packageUuid: string,
  accommodationPackageIndex: number,
  accommodationProductPackageIndex: Number
) => ({
  type: OFFER_REMOVE_ACCOMMODATON_PRODUCT_PACKAGE as typeof OFFER_REMOVE_ACCOMMODATON_PRODUCT_PACKAGE,
  packageUuid,
  accommodationPackageIndex,
  accommodationProductPackageIndex,
});

export type OfferSetPackageNights = ReturnType<typeof offerSetPackageNights>;
export const offerSetPackageNights = (
  packageUuid: string,
  accommodationPackageIndex: number,
  accommodationProductPackageIndex: number,
  nights: string
) => ({
  type: OFFER_SET_PACKAGE_NIGHTS as typeof OFFER_SET_PACKAGE_NIGHTS,
  packageUuid,
  accommodationPackageIndex,
  accommodationProductPackageIndex,
  nights,
});

export type OfferSetPackageRate = ReturnType<typeof offerSetPackageRate>;
export const offerSetPackageRate = (
  packageUuid: string,
  accommodationPackageIndex: number,
  accommodationProductPackageIndex: number,
  rate: string
) => ({
  type: OFFER_SET_PACKAGE_RATE as typeof OFFER_SET_PACKAGE_RATE,
  packageUuid,
  accommodationPackageIndex,
  accommodationProductPackageIndex,
  rate,
});

export type OfferSetPackageExtraNightRate = ReturnType<typeof offerSetPackageExtraNightRate>;
export const offerSetPackageExtraNightRate = (
  packageUuid: string,
  accommodationPackageIndex: number,
  rate: string
) => ({
  type: OFFER_SET_PACKAGE_EXTRA_NIGHT_RATE as typeof OFFER_SET_PACKAGE_EXTRA_NIGHT_RATE,
  packageUuid,
  accommodationPackageIndex,
  rate,
});

export type OfferAddPackageExtraPersonRate = ReturnType<typeof offerAddPackageExtraPersonRate>;
export const offerAddPackageExtraPersonRate = (packageUuid: string, accommodationPackageIndex: number) => ({
  type: OFFER_ADD_PACKAGE_EXTRA_PERSON_RATE as typeof OFFER_ADD_PACKAGE_EXTRA_PERSON_RATE,
  packageUuid,
  accommodationPackageIndex,
});

export type OfferRemovePackageExtraPersonRate = ReturnType<typeof offerRemovePackageExtraPersonRate>;
export const offerRemovePackageExtraPersonRate = (
  packageUuid: string,
  accommodationPackageIndex: number,
  extraPersonRateIndex: number
) => ({
  type: OFFER_REMOVE_PACKAGE_EXTRA_PERSON_RATE as typeof OFFER_REMOVE_PACKAGE_EXTRA_PERSON_RATE,
  packageUuid,
  accommodationPackageIndex,
  extraPersonRateIndex,
});

export type OfferSetPackageExtraPersonRate = ReturnType<typeof offerSetPackageExtraPersonRate>;
export const offerSetPackageExtraPersonRate = (
  packageUuid: string,
  accommodationPackageIndex: number,
  extraPersonRateIndex: number,
  rate: string
) => ({
  type: OFFER_SET_PACKAGE_EXTRA_PERSON_RATE as typeof OFFER_SET_PACKAGE_EXTRA_PERSON_RATE,
  packageUuid,
  accommodationPackageIndex,
  extraPersonRateIndex,
  rate,
});

export type OfferSetPackageExtraPersonRateAgeName = ReturnType<typeof offerSetPackageExtraPersonRateAgeName>;
export const offerSetPackageExtraPersonRateAgeName = (
  packageUuid: string,
  accommodationPackageIndex: number,
  extraPersonRateIndex: number,
  ageName: string
) => ({
  type: OFFER_SET_PACKAGE_EXTRA_PERSON_RATE_AGENAME as typeof OFFER_SET_PACKAGE_EXTRA_PERSON_RATE_AGENAME,
  packageUuid,
  accommodationPackageIndex,
  extraPersonRateIndex,
  ageName,
});

export type OfferSetPackageExtraPersonMaxAction = ReturnType<typeof offerSetPackageExtraPersonMaxAction>;
export const offerSetPackageExtraPersonMaxAction = (
  packageUuid: string,
  accommodationPackageIndex: number,
  extraPersonRateIndex: number,
  max: string
) => ({
  type: OFFER_SET_PACKAGE_EXTRA_PERSON_MAX as typeof OFFER_SET_PACKAGE_EXTRA_PERSON_MAX,
  packageUuid,
  accommodationPackageIndex,
  extraPersonRateIndex,
  max,
});

export type OfferPackageDiscountAction =
  | OfferAddPackageAction
  | OfferRemovePackageAction
  | OfferSetDateRangeAction
  | OfferAddAccommodationProductAction
  | OfferSetAccommodationProductAction
  | OfferRemoveAccommodationProductAction
  | OfferSetPackageNights
  | OfferSetPackageRate
  | OfferSetPackageExtraNightRate
  | OfferAddPackageExtraPersonRate
  | OfferRemovePackageExtraPersonRate
  | OfferSetPackageExtraPersonRate
  | OfferSetPackageExtraPersonRateAgeName
  | OfferAddAccommodationProductPackage
  | OfferRemoveAccommodationProductPackage
  | OfferSetPackageExtraPersonMaxAction;
