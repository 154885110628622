import styled from 'styled-components';

interface IMarginValues {
  marginLeft: number;
  marginBottom: number;
}

export const Wrapper = styled.div`
  display: inline-block;
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #fd5656;
  line-height: 1;
`;
