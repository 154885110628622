import { call, takeLatest, put, delay } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { GET_SELECTED_TASK_REQUEST, GetSelectedTaskRequestAction, getSelectedTaskFailureAction, getSelectedTaskSuccessAction, listTaskRemindersSuccessAction } from '../actions';
import { enqueueNotification } from 'store/modules/ui';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';
import { ITaskAPI } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { APITaskToFETask } from 'services/TaskManagementApi/TaskManagementUtils';
import { generateStableReminderUuid } from '../helpers';

export function* getTaskSaga(action: GetSelectedTaskRequestAction) {
  yield delay(1000);

  try {
    const taskId = action.taskId;
    const taskManagementApi = makeTaskManagementApi();
    const apiTask: AxiosResponse<ITaskAPI> = yield call(taskManagementApi.getTask, taskId); 
    const task = APITaskToFETask(apiTask.data);
    
    // Backend returns list of reminders without uuids
    const reminders = task.reminders.map(item => ({
      ...item,
      offset: Math.abs(item.offset),
      id: generateStableReminderUuid(item),
    }));
    
    yield put(getSelectedTaskSuccessAction(task));
    yield put(listTaskRemindersSuccessAction(reminders));
  } catch (e) {
    yield put(getSelectedTaskFailureAction(e));
    yield put(enqueueNotification({ message: 'Error while retrieving this task', options: { variant: 'error' } }));
  }
}

export function* watchGetTaskSaga() {
  yield takeLatest(
    [GET_SELECTED_TASK_REQUEST],
    getTaskSaga
  );
}
