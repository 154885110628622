import styled from 'styled-components';

export const BookingManagerStyles = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: center;
  min-height: 87vh;
  max-width: 100%;
  width: 1280px;

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .routeContent {
    flex: 1;
    padding-left: 30px;
  }
`;
