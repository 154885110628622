export enum EBookingType {
  TA_BOOKING = 'ta_booking',
  INTERNAL_TEST = 'internal_test',
  EDUCATIONAL_TRIP = 'educational_trip',
  ADVANCED_PURCHASE = 'advanced_purchase',
  OTHER = 'other',
}

interface IBookingTypeResponse {
  bookingUuid: string;
  bookingType: EBookingType;
  hasManualUpdate: boolean;
  version: number;
  createdAt: string;
  updatedAt: string;
}

export interface IGetBookingTypeResponse extends IBookingTypeResponse {}
export interface ISetBookingTypeResponse extends IBookingTypeResponse {}
