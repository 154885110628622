import { createSelector } from 'reselect';
import {
  errorKey,
  isCurrencyCompliant,
  isInt,
  isPercentageCompliant,
  OfferValidatorResultSet,
  Validator,
  ValidatorFieldError,
  ValidatorFieldResult,
} from './validation';
import {
  combinationModeSelector,
  combinationOfferUuidsSelector,
  uiStateSelector,
} from './subdomains/uiState/selectors';
import {
  offerAccommodationDiscountSelector,
  offerAccommodationProductPrerequisitesRawSelector,
  offerExtraPersonSupplementsSelector,
  offerProductDiscountsSelector,
  offerRequiresGreenTaxApproachSelector,
  offerSelector,
  offerStayBetweenPrerequisitesRawSelector,
  offerStayLengthPrerequisiteSelector,
  offerSteppingApplicationSelector,
  offerSubProductDiscountsSelector,
  packageDiscountsSelector,
} from './subdomains/offer/selectors';
import { ECombinationMode } from './model';
import { isBlank } from 'utils';
import { ESteppingApproach, IUIOfferProductDiscountInstance } from 'services/BackendApi';

export const offerHotelValidationSelector = createSelector(offerSelector, offer =>
  new Validator<OfferValidatorResultSet>(offer)
    .required('hotelUuid', 'Hotel is required')
    .string('hotelUuid', 'Hotel must be a string')
    .results()
);

export const offerNameValidationSelector = createSelector(offerSelector, offer =>
  new Validator<OfferValidatorResultSet>(offer)
    .required('name', 'Name is a required field')
    .string('name', 'Name must be a string')
    .results()
);

export const offerTsAndCsValidationSelector = createSelector(offerSelector, offer =>
  new Validator<OfferValidatorResultSet>(offer)
    .required('termsAndConditions', 'Terms and Conditions are required')
    .string('termsAndConditions', 'Terms and Conditions must be a string')
    .results()
);

export const offerAccommodationProductsPrerequisitesValidationSelector = createSelector(
  offerAccommodationProductPrerequisitesRawSelector,
  offerSelector,
  (accommodationPrerequisites, offer) => {
    // For now, this selector never returns any errors.
    // Leaving in, as we may adjust this in the future, and all the wiring is already in place
    return {
      errors: [],
    } as ValidatorFieldResult<OfferValidatorResultSet>;
  }
);

export const offerPackageDiscountsValidationSelector = createSelector(
  packageDiscountsSelector,
  (packageDiscounts): ValidatorFieldResult<OfferValidatorResultSet> => {
    const errors: ValidatorFieldError<OfferValidatorResultSet>[] = [];

    if(!packageDiscounts) {
      return { errors }
    }

  
    packageDiscounts?.forEach((pd, idx) => {
      if(!pd.dateRange.startDate || !pd.dateRange.endDate) {
        errors.push({
          field: 'packageDiscounts',
          message: 'Daterange is required',
          index: idx,
        });
      }

      if(pd.accommodationPackages.length === 0) {
        errors.push({
          field: 'packageDiscounts',
          message: 'At least 1 accommodaiton product is required',
          index: idx,
        });
      }

      if(pd.accommodationPackages.length > 0) {
        pd.accommodationPackages.map((ap, apIdx) => {
          const apErrorKey = errorKey('pd-ap', idx, apIdx);

          if(isBlank(ap.accommodationProductUuid)) {
            errors.push({
              field: 'packageDiscounts',
              message: `Accommodation Product #${apIdx + 1} - You must specify an accomodation product`,
              index: idx,
              key: apErrorKey
            });
          }

          if(!isBlank(ap.extraNightRate) && !isCurrencyCompliant(ap.extraNightRate!)) {
            errors.push({
              field: 'packageDiscounts',
              message: `Accommodation Product #${apIdx + 1} - Extra night rate must be a number with no more than 2 decimal places`,
              index: idx,
              key: apErrorKey
            });
          }
          
          if(ap.accommodationProductPackages) {
            ap.accommodationProductPackages.map((app, appIdx) => {
              const apErrorKey = errorKey('pd-ap-app', idx, apIdx, appIdx);
  
              if(isBlank(app.nights)) {
                errors.push({
                  field: 'packageDiscounts',
                  message: `Accommodation Product #${apIdx + 1} - Nights #${appIdx + 1} is required`,
                  index: idx,
                  key: apErrorKey
                });
              }
  
              if(app.nights && !isInt(app.nights?.toString())) {
                errors.push({
                  field: 'packageDiscounts',
                  message: `Accommodation Product #${apIdx + 1} - Nights #${appIdx + 1} must be an integer`,
                  index: idx,
                  key: apErrorKey
                });
              }

              if(isBlank(app.packageRate)) {
                errors.push({
                  field: 'packageDiscounts',
                  message: `Accommodation Product #${apIdx + 1} - Rate #${appIdx + 1} is required`,
                  index: idx,
                  key: apErrorKey
                });
              }

              if(!isBlank(app.packageRate) && !isCurrencyCompliant(app.packageRate!)) {
                errors.push({
                  field: 'packageDiscounts',
                  message: `Accommodation Product #${apIdx + 1} - Rate #${appIdx + 1} must be a number with no more than 2 decimal places`,
                  index: idx,
                  key: apErrorKey
                });
              }
            })
          }

          if(ap.extraPersonNightRates) {
            const hadAdultRate = ap.extraPersonNightRates.reduce((acc, next) => {
              return acc ? acc : next.ageName === 'Adult';
            }, false);

            ap.extraPersonNightRates.map((epnr, epnrIdx) => {
              const epnrErrorKey = errorKey('pd-ap-app', idx, apIdx, epnrIdx);
              if(isBlank(epnr.ageName)) {
                errors.push({
                  field: 'packageDiscounts',
                  message: `Accommodation Product #${apIdx + 1} - Age Name #${epnrIdx + 1} is required`,
                  index: idx,
                  key: epnrErrorKey
                });
              }

              if(isBlank(epnr.extraPersonRate)) {
                errors.push({
                  field: 'packageDiscounts',
                  message: `Accommodation Product #${apIdx + 1} - Extra Person Rate #${epnrIdx + 1} is required`,
                  index: idx,
                  key: epnrErrorKey
                });
              }

              if(!isBlank(epnr.extraPersonRate) && !isCurrencyCompliant(epnr.extraPersonRate!)) {
                errors.push({
                  field: 'packageDiscounts',
                  message: `Accommodation Product #${apIdx + 1} - Extra Person Rate #${epnrIdx + 1} must be a number with no more than 2 decimal places`,
                  index: idx,
                  key: epnrErrorKey
                });
              }

              if(!hadAdultRate && !isBlank(epnr.maximumBeforeDefaultRate) && epnr.ageName !== 'Adult') {
                errors.push({
                    field: 'packageDiscounts',
                    message: `Accommodation Product #${apIdx + 1} - You must specify an Adult rate to set 'Max Persons' for any other age name.`,
                    index: idx,
                    key: epnrErrorKey
                  });
              }

              if(!isBlank(epnr.maximumBeforeDefaultRate) && epnr.ageName !== 'Adult' && !isInt(epnr.maximumBeforeDefaultRate as string)) {
                errors.push({
                    field: 'packageDiscounts',
                    message: `Accommodation Product #${apIdx + 1} - Extra Person Rate Max #${epnrIdx + 1} must be an integer`,
                    index: idx,
                    key: epnrErrorKey
                  });
              }

            })
          }
        })
        
      }
    });

    return {
      errors,
    }
  }
)

export const offerStayBetweenPrerequisiteValidationSelector = createSelector(
  offerStayBetweenPrerequisitesRawSelector,
  packageDiscountsSelector,
  (stayBetweens, packageDiscounts) => {
    const errors: ValidatorFieldError<OfferValidatorResultSet>[] = [];

    if(packageDiscounts.length > 0) {
      return {
        errors
      }
    }

    // If staybetween exists, then it must have 1 staerange
    if (stayBetweens && stayBetweens.length <= 0) {
      errors.push({
        field: 'stayBetweenPrerequisite',
        message: 'At least 1 stay between prerequisite must be set',
      });
    }

    // stay between end needs to be after start
    stayBetweens?.forEach((sb, index) => {
      if (sb.startDate !== undefined && sb.endDate !== undefined) {
        if (new Date(sb.startDate) > new Date(sb.endDate)) {
          errors.push({
            field: 'stayBetweenPrerequisite',
            index,
            message: 'Accommodation prerequisites requires at least 1 item',
          });
        }
      }
    });

    return {
      errors,
    } as ValidatorFieldResult<OfferValidatorResultSet>;
  }
);

export const offerStayLengthPrerequisiteValidationSelector = createSelector(
  offerStayLengthPrerequisiteSelector,
  stayLength => {
    const errors: ValidatorFieldError<OfferValidatorResultSet>[] = [];

    const isMaxEmpty = isBlank(stayLength.maximum);
    const isMinEmpty = isBlank(stayLength.minimum);

    if (stayLength) {
      if (stayLength.strictMinMaxStay && isMinEmpty && isMaxEmpty) {
        errors.push({
          field: 'stayLengthPrerequisite',
          message: 'Stay Length strict min/max requires a Minimum and/or Maximum',
        });
      }

      if (!isBlank(stayLength.minimum) && !isInt(stayLength.minimum as string)) {
        errors.push({
          field: 'stayLengthPrerequisite',
          message: 'Minimum value must be an integer',
        });
      }

      if (!isBlank(stayLength.maximum) && !isInt(stayLength.maximum as string)) {
        errors.push({
          field: 'stayLengthPrerequisite',
          message: 'Maximum value must be an integer',
        });
      }

      if (stayLength.minimum && stayLength.maximum) {
        if (parseInt(stayLength.minimum as string, 10) > parseInt(stayLength.maximum as string, 10)) {
          errors.push({
            field: 'stayLengthPrerequisite',
            message: 'Minimum must be greater than or equal to Maximum',
          });
        }
      }
    }

    return {
      errors,
    } as ValidatorFieldResult<OfferValidatorResultSet>;
  }
);

export const offerSteppingValidationSelector = createSelector(offerSteppingApplicationSelector, stepping => {
  const errors: ValidatorFieldError<OfferValidatorResultSet>[] = [];

  if (stepping) {
    if (stepping.everyXNights === undefined) {
      errors.push({
        field: 'stepping',
        message: 'If Stepping is set, Stepping > Every X Nights is required',
      });
    }

    if (parseInt(stepping.everyXNights as string, 10) < 2) {
      errors.push({
        field: 'stepping',
        message: 'everyXNights must be greater than 1',
      });
    }

    if (stepping.applyTo === undefined) {
      errors.push({
        field: 'stepping',
        message: 'If Stepping is set, Stepping > Apply To is required',
      });
    }

    if (stepping.everyXNights == null && stepping.applyTo == null) {
      if (stepping.maximumNights != null) {
        errors.push({
          field: 'stepping',
          message:
            'If Stepping > Every X Nights or Stepping > Apply To is not set, then Stepping > Maximum nights is not settable',
        });
      }

      if (stepping.discountApproach === ESteppingApproach.CHEAPEST) {
        errors.push({
          field: 'stepping',
          message:
            'If Stepping > Every X Nights or Stepping > Apply To is not set, then Stepping > discount cheapest is not settable',
        });
      }
    }
  }

  return {
    errors,
  } as ValidatorFieldResult<OfferValidatorResultSet>;
});

export const discountErrorBuilder = (
  discount: IUIOfferProductDiscountInstance,
  errorFieldKey: keyof OfferValidatorResultSet,
  discountName: string,
  index: number
) => {
  const errors: ValidatorFieldError<OfferValidatorResultSet>[] = [];

  if (discount.products.length <= 0 && !isBlank(discount.discountPercentage)) {
    errors.push({
      field: errorFieldKey,
      index,
      message: `${discountName} discount #${index + 1} - discount percentage cannot be set without at least 1 product`,
    });
  }
  if (isBlank(discount.discountPercentage)) {
    errors.push({
      field: errorFieldKey,
      index,
      message: `${discountName} discount #${index + 1} - discount percentage is required`,
    });
  } else if (!isPercentageCompliant(discount.discountPercentage)) {
    errors.push({
      field: errorFieldKey,
      index,
      message: `${discountName} discount #${index +
        1} - discount percentage must be percentage compliant (number 1 - 100, optional 2 decimal places)`,
    });
  }
  if (discount.maximumQuantity && discount.maximumQuantity?.toString().includes('.')) {
    errors.push({
      field: errorFieldKey,
      index,
      message: `${discountName} discount #${index + 1} - Maximum quantity must be an integer`,
    });
  }

  return errors;
};

export const offerProductDiscountsValidationSelector = createSelector(
  offerProductDiscountsSelector,
  productDiscounts => {
    let errors: ValidatorFieldError<OfferValidatorResultSet>[] = [];

    if (productDiscounts) {
      if (productDiscounts.Fine) {
        productDiscounts.Fine.forEach((discount, index) => {
          errors = [...errors, ...discountErrorBuilder(discount, 'fineDiscounts', 'Fine', index)];
        });
      }
      if (productDiscounts['Ground Service']) {
        productDiscounts['Ground Service'].forEach((discount, index) => {
          errors = [...errors, ...discountErrorBuilder(discount, 'groundServiceDiscounts', 'Ground Service', index)];
        });
      }

      if (productDiscounts.Supplement) {
        productDiscounts.Supplement.forEach((discount, index) => {
          errors = [...errors, ...discountErrorBuilder(discount, 'supplementDiscounts', 'Supplement', index)];
        });
      }
      if (productDiscounts.Transfer) {
        productDiscounts.Transfer.forEach((discount, index) => {
          errors = [...errors, ...discountErrorBuilder(discount, 'transferDiscounts', 'Transfer', index)];
        });
      }
    }

    return {
      errors,
    } as ValidatorFieldResult<OfferValidatorResultSet>;
  }
);

export const offerSubProductDiscountsValidationSelector = createSelector(
  offerSubProductDiscountsSelector,
  subProductDiscounts => {
    let errors: ValidatorFieldError<OfferValidatorResultSet>[] = [];

    if (subProductDiscounts) {
      if (subProductDiscounts['Meal Plan']) {
        subProductDiscounts['Meal Plan'].forEach((discount, index) => {
          errors = [...errors, ...discountErrorBuilder(discount, 'mealPlanDiscounts', 'Meal Plan', index)];
        });
      }
    }

    return {
      errors,
    } as ValidatorFieldResult<OfferValidatorResultSet>;
  }
);

export const offerExtraPersonSupplementValidationSelector = createSelector(
  offerExtraPersonSupplementsSelector,
  offerRequiresGreenTaxApproachSelector,
  (extraPersonSupplementsDiscounts, requiresGreenTax) => {
    let errors: ValidatorFieldError<OfferValidatorResultSet>[] = [];

    extraPersonSupplementsDiscounts.forEach((discount, index) => {
      errors = [
        ...errors,
        ...discountErrorBuilder(discount, 'extraPersonSupplementDiscounts', 'Extra Person Supplement', index),
      ];

      if (requiresGreenTax && !discount.greenTaxDiscountApproach) {
        errors.push({
          field: 'extraPersonSupplementDiscounts',
          index,
          message: `Extra Person Supplement discount #${index + 1} - green tax approach is required`,
        });
      }
    });

    return {
      errors,
    } as ValidatorFieldResult<OfferValidatorResultSet>;
  }
);

export const offerFurtherInformationValidationSelector = createSelector(
  offerSelector,
  uiStateSelector,
  (offer, uiState) => {
    const errors: ValidatorFieldError<OfferValidatorResultSet>[] = [];

    if (uiState.isTextOnly && (!offer.furtherInformation || offer.furtherInformation === '')) {
      errors.push({
        field: 'furtherInformation',
        message: 'If offer is Text Only, Further Information is required',
      });
    }

    return {
      errors,
    } as ValidatorFieldResult<OfferValidatorResultSet>;
  }
);

export const accommodationDiscountValidationSelector = createSelector(
  offerSelector,
  offerRequiresGreenTaxApproachSelector,
  (offer, requiresGreenTax) => {
    const errors: ValidatorFieldError<OfferValidatorResultSet>[] = [];

    if (offer.accommodationProductDiscount && !offer.accommodationProductDiscount.discountPercentage) {
      errors.push({
        field: 'accommodationProductDiscount',
        message: `Accommodation Product discount - discount percentage must be set`,
      });
    }

    if (offer.accommodationProductDiscount && offer.accommodationProductDiscount.discountPercentage) {
      if (!isPercentageCompliant(offer.accommodationProductDiscount.discountPercentage)) {
        errors.push({
          field: 'accommodationProductDiscount',
          message: `Accommodation Product discount - discount percentage must be percentage compliant (number 1 - 100, optional 2 decimal places)`,
        });
      }
    }

    if (
      offer.accommodationProductDiscount &&
      !offer.accommodationProductDiscount.greenTaxDiscountApproach &&
      requiresGreenTax
    ) {
      errors.push({
        field: 'accommodationProductDiscount',
        message: `Green tax approach required`,
      });
    }

    return {
      errors,
    } as ValidatorFieldResult<OfferValidatorResultSet>;
  }
);

export const offerCombinationValidationSelector = createSelector(
  combinationModeSelector,
  combinationOfferUuidsSelector,
  (combinationMode, combinationUuids) => {
    const errors: ValidatorFieldError<OfferValidatorResultSet>[] = [];

    if (
      combinationMode === ECombinationMode.COMBINES_WITH_LIST ||
      combinationMode === ECombinationMode.CANNOT_COMBINE_WITH_LIST
    ) {
      if (combinationUuids.length <= 0) {
        errors.push({
          field: 'combinations',
          message: 'You must select one or more offers',
        });
      }
    }
    return {
      errors,
    } as ValidatorFieldResult<OfferValidatorResultSet>;
  }
);

const offerCombinationErrorCountSelector = createSelector(
  offerCombinationValidationSelector,
  combinationErrors => combinationErrors.errors.length || 0
);

const offerPackageDiscountsErrorCountSelector = createSelector(
  offerPackageDiscountsValidationSelector,
  packageDiscoutErrors => packageDiscoutErrors.errors.length || 0
)

export const offerDetailsValidationSelector = createSelector(
  offerHotelValidationSelector,
  offerNameValidationSelector,
  offerTsAndCsValidationSelector,
  offerFurtherInformationValidationSelector,
  (
    hotelValidatorFieldResult,
    nameValidatorFieldResult,
    tsAndCsValidatorFieldResult,
    furtherInformationValidatorFieldResult
  ) => {
    return {
      errors: [
        ...hotelValidatorFieldResult.errors,
        ...nameValidatorFieldResult.errors,
        ...tsAndCsValidatorFieldResult.errors,
        ...furtherInformationValidatorFieldResult.errors,
      ],
    } as ValidatorFieldResult<OfferValidatorResultSet>;
  }
);

export const offerHasAnyProductDiscounts = createSelector(offerProductDiscountsSelector, productDiscounts => {
  return (
    (productDiscounts.Fine && productDiscounts.Fine?.length >= 1) ||
    (productDiscounts.Transfer && productDiscounts.Transfer?.length >= 1) ||
    (productDiscounts['Ground Service'] && productDiscounts['Ground Service']?.length >= 1) ||
    (productDiscounts.Supplement && productDiscounts.Supplement?.length >= 1)
  );
});

export const offerHasAnySubProductDiscounts = createSelector(offerSubProductDiscountsSelector, subProductDiscounts => {
  return (
    (subProductDiscounts['Meal Plan'] && subProductDiscounts['Meal Plan']?.length >= 1) ||
    (subProductDiscounts.Supplement && subProductDiscounts.Supplement?.length >= 1)
  );
});

export const offerApplicationsIfNotTextOnlyValidationSelector = createSelector(
  uiStateSelector,
  offerAccommodationDiscountSelector,
  offerHasAnyProductDiscounts,
  offerHasAnySubProductDiscounts,
  offerExtraPersonSupplementsSelector,
  packageDiscountsSelector,
  (uiState, accommodationDiscount, hasProductDiscounts, hasSubProductDiscounts, epsDiscounts, packageDiscounts) => {
    const errors: ValidatorFieldError<OfferValidatorResultSet>[] = [];

    if(packageDiscounts.length > 0) {
      return {
        errors
      }
    }

    // const hasAnyProductDiscounts = productDiscounts.Fine && productDiscounts.Fine?.length >= 1;
    // if we're NOT text only...
    if (!uiState.isTextOnly) {
      // ...and we dont have ANY other kinds of applications...
      if (
        accommodationDiscount == null &&
        !hasProductDiscounts &&
        !hasSubProductDiscounts &&
        epsDiscounts.length <= 0
      ) {
        //... then thats an error, and they need to add some applications or make it text only
        errors.push({
          field: 'applications',
          message: 'Non Text Only offers require at least 1 application to be set',
        });
      }
    }

    return {
      errors,
    } as ValidatorFieldResult<OfferValidatorResultSet>;
  }
);

// gets the giant, grouped by object of all validations against the offer
export const offerValidationSelector = createSelector(
  offerDetailsValidationSelector,
  offerAccommodationProductsPrerequisitesValidationSelector,
  offerStayBetweenPrerequisiteValidationSelector,
  offerSteppingValidationSelector,
  offerProductDiscountsValidationSelector,
  offerSubProductDiscountsValidationSelector,
  offerExtraPersonSupplementValidationSelector,
  accommodationDiscountValidationSelector,
  offerStayLengthPrerequisiteValidationSelector,
  offerCombinationValidationSelector,
  offerApplicationsIfNotTextOnlyValidationSelector,
  offerPackageDiscountsValidationSelector,
  (
    detailsValidatorFieldResult,
    accommodationProductValidatorFieldResult,
    stayBetweenValidatorFieldResult,
    steppingValidatorFieldResult,
    productDiscountsValidatorFieldResult,
    subProductDiscountsValidatorFieldResult,
    epsDiscountsValidatorFieldResult,
    accommodationProductDiscountValidatorFieldResult,
    stayLengthValidatorFieldResult,
    combinationValidatorFieldResult,
    applicationsValidatorFieldResult,
    packageDiscountsValidatorFieldResult,
  ) => {
    const groupedBy: OfferValidatorResultSet = {
      hotelUuid: detailsValidatorFieldResult.errors.filter(e => e.field === 'hotelUuid'),
      name: detailsValidatorFieldResult.errors.filter(e => e.field === 'name'),
      termsAndConditions: detailsValidatorFieldResult.errors.filter(e => e.field === 'termsAndConditions'),
      furtherInformation: detailsValidatorFieldResult.errors.filter(e => e.field === 'furtherInformation'),
      accommodationProductsPrerequisite: accommodationProductValidatorFieldResult.errors,
      stayBetweenPrerequisite: stayBetweenValidatorFieldResult.errors,
      stepping: steppingValidatorFieldResult.errors,

      stayLengthPrerequisite: stayLengthValidatorFieldResult.errors,

      accommodationProductDiscount: accommodationProductDiscountValidatorFieldResult.errors,

      // the product discounts, broken up
      fineDiscounts: productDiscountsValidatorFieldResult.errors.filter(e => e.field === 'fineDiscounts'),
      groundServiceDiscounts: productDiscountsValidatorFieldResult.errors.filter(
        e => e.field === 'groundServiceDiscounts'
      ),
      supplementDiscounts: productDiscountsValidatorFieldResult.errors.filter(e => e.field === 'supplementDiscounts'),
      transferDiscounts: productDiscountsValidatorFieldResult.errors.filter(e => e.field === 'transferDiscounts'),

      // sub product discounts, broken up
      extraPersonSupplementDiscounts: epsDiscountsValidatorFieldResult.errors,
      mealPlanDiscounts: subProductDiscountsValidatorFieldResult.errors.filter(e => e.field === 'mealPlanDiscounts'),

      combinations: combinationValidatorFieldResult.errors,

      applications: applicationsValidatorFieldResult.errors,
      packageDiscounts: packageDiscountsValidatorFieldResult.errors

    };
    return groupedBy;
  }
);

// returns a boolean, does the offer details section have validation errors
export const offerHasDetailsValidatorErrorsSelector = createSelector(
  offerHotelValidationSelector,
  offerNameValidationSelector,
  offerTsAndCsValidationSelector,
  offerFurtherInformationValidationSelector,
  (
    hotelValidatorFieldResult,
    nameValidatorFieldResult,
    tsAndCsValidatorFieldResult,
    furtherInformationValidatorFieldResult
  ) => {
    return (
      hotelValidatorFieldResult.errors.length >= 1 ||
      nameValidatorFieldResult.errors.length >= 1 ||
      tsAndCsValidatorFieldResult.errors.length >= 1 ||
      furtherInformationValidatorFieldResult.errors.length >= 1
    );
  }
);

export const offerDetailsValidaitonErrorCountSelector = createSelector(
  offerHotelValidationSelector,
  offerNameValidationSelector,
  offerTsAndCsValidationSelector,
  offerFurtherInformationValidationSelector,
  (
    hotelValidatorFieldResult,
    nameValidatorFieldResult,
    tsAndCsValidatorFieldResult,
    furtherInformationValidatorFieldResult
  ) => {
    return [
      Math.max(0, hotelValidatorFieldResult.errors.length),
      Math.max(0, nameValidatorFieldResult.errors.length),
      Math.max(0, tsAndCsValidatorFieldResult.errors.length),
      Math.max(0, furtherInformationValidatorFieldResult.errors.length),
    ].reduce((acc, next) => acc + next, 0);
  }
);

// returns a boolean, does the offer prerequisites section have validation errors
export const offerHasPrerequisitesValidationErrorsSelector = createSelector(
  offerAccommodationProductsPrerequisitesValidationSelector,
  offerStayBetweenPrerequisiteValidationSelector,
  offerStayLengthPrerequisiteValidationSelector,
  (accommodationProductValidatorFieldResult, stayBetweenValidatorFieldResult, stayLengthValidatorFieldResult) => {
    return (
      accommodationProductValidatorFieldResult.errors.length >= 1 ||
      stayBetweenValidatorFieldResult.errors.length >= 1 ||
      stayLengthValidatorFieldResult.errors.length >= 1
    );
  }
);

export const offerHasPackageDiscountErrorsSelector = createSelector(
  offerPackageDiscountsValidationSelector,
  (validations) => validations.errors.length > 0
)

export const offePrerequisitesValidationErrorCountSelector = createSelector(
  offerAccommodationProductsPrerequisitesValidationSelector,
  offerStayBetweenPrerequisiteValidationSelector,
  offerStayLengthPrerequisiteValidationSelector,
  (accommodationProductValidatorFieldResult, stayBetweenValidatorFieldResult, stayLengthValidatorFieldResult) => {
    return [
      Math.max(0, accommodationProductValidatorFieldResult.errors.length),
      Math.max(0, stayBetweenValidatorFieldResult.errors.length),
      Math.max(0, stayLengthValidatorFieldResult.errors.length),
    ].reduce((acc, next) => acc + next, 0);
  }
);

// returns a boolean, does the offer applications section have validation errors
export const offerHasApplicationsValidationErrorsSelector = createSelector(
  offerSteppingValidationSelector,
  offerExtraPersonSupplementValidationSelector,
  offerProductDiscountsValidationSelector,
  offerSubProductDiscountsValidationSelector,
  accommodationDiscountValidationSelector,
  offerApplicationsIfNotTextOnlyValidationSelector,
  (
    steppingValidatorResult,
    extraPersonSupplementValidatorResult,
    productDiscountsValidatorResult,
    subProductDiscountsValidatorResult,
    accommodationProductDiscountValidatorResult,
    applicationIfNotTextOnlyValidatorResult
  ) => {
    return (
      steppingValidatorResult.errors.length >= 1 ||
      extraPersonSupplementValidatorResult.errors.length >= 1 ||
      productDiscountsValidatorResult.errors.length >= 1 ||
      subProductDiscountsValidatorResult.errors.length >= 1 ||
      accommodationProductDiscountValidatorResult.errors.length >= 1 ||
      applicationIfNotTextOnlyValidatorResult.errors.length >= 1
    );
  }
);

export const offerApplicationsValidationErrorCountSelector = createSelector(
  offerSteppingValidationSelector,
  offerExtraPersonSupplementValidationSelector,
  offerProductDiscountsValidationSelector,
  offerSubProductDiscountsValidationSelector,
  accommodationDiscountValidationSelector,
  offerApplicationsIfNotTextOnlyValidationSelector,
  (
    steppingValidatorResult,
    extraPersonSupplementValidatorResult,
    productDiscountsValidatorResult,
    subProductDiscountsValidatorResult,
    accommodationProductDiscountValidatorResult,
    applicationIfNotTextOnlyValidatorResult
  ) => {
    return [
      Math.max(0, steppingValidatorResult.errors.length),
      Math.max(0, extraPersonSupplementValidatorResult.errors.length),
      Math.max(0, productDiscountsValidatorResult.errors.length),
      Math.max(0, subProductDiscountsValidatorResult.errors.length),
      Math.max(0, accommodationProductDiscountValidatorResult.errors.length),
      Math.max(0, applicationIfNotTextOnlyValidatorResult.errors.length),
    ].reduce((acc, next) => acc + next, 0);
  }
);

export const offerHasCombinationValidationErrorsSelector = createSelector(
  offerCombinationValidationSelector,
  combinationValidatorFieldResult => {
    return combinationValidatorFieldResult.errors.length >= 1;
  }
);

// returns a boolean, does the offer have any validation errors at all
export const offerHasValidationErrorsSelector = createSelector(
  offerHasDetailsValidatorErrorsSelector,
  offerHasPrerequisitesValidationErrorsSelector,
  offerHasApplicationsValidationErrorsSelector,
  offerHasCombinationValidationErrorsSelector,
  offerHasPackageDiscountErrorsSelector,
  (offerHasDetailsErrors, offerHasPrerequisitesErrors, offerHasApplicationsErrors, hasCombinationErrors, hasPackageDiscountErrors) => {
    return offerHasDetailsErrors || offerHasPrerequisitesErrors || offerHasApplicationsErrors || hasCombinationErrors || hasPackageDiscountErrors;
  }
);

// returns a boolean, does the offer have any validation errors at all
export const offerValidationErrorCountSelector = createSelector(
  offerDetailsValidaitonErrorCountSelector,
  offePrerequisitesValidationErrorCountSelector,
  offerApplicationsValidationErrorCountSelector,
  offerCombinationErrorCountSelector,
  offerPackageDiscountsErrorCountSelector,
  (detailsCount, preReqCount, applicationCount, combinationCount, packageCount) =>
    detailsCount + preReqCount + applicationCount + combinationCount + packageCount
);
