import { IProposalsListItem } from 'services/BackendApi';
import { IProposalHistory } from 'services/BookingManagerApi';
import { IProposalSelection } from 'services/BookingManagerApi/types/ProposalSelection';
import { ESortOrder } from 'store/common/types';
import { IBookingModel } from '../bookingManager/sagas/bookingTransforms/BookingModel';
import { EProposalSortBy } from './model';
import { IProposalBooking } from './types';

export const GET_PROPOSAL_DETAILS_REQUEST = 'proposalDetails/GET_PROPOSAL_DETAILS_REQUEST';
export const GET_PROPOSAL_DETAILS_SUCCESS = 'proposalDetails/GET_PROPOSAL_DETAILS_SUCCESS';
export const GET_PROPOSAL_DETAILS_FAILURE = 'proposalDetails/GET_PROPOSAL_DETAILS_FAILURE';
export const TOGGLE_SELECTION_PROPOSAL_BOOKING = 'proposalDetails/TOGGLE_SELECTION_PROPOSAL_BOOKING';
export const GET_PROPOSAL_DETAILS_SORT_BY = 'proposalDetails/GET_PROPOSAL_DETAILS_SORT_BY';
export const SET_PROPOSAL_DETAILS_SORT_BY = 'proposalDetails/SET_PROPOSAL_DETAILS_SORT_BY';
export const SET_PROPOSAL_BOOKINGS = 'proposalDetails/SET_PROPOSAL_BOOKINGS';
export const SET_PROPOSAL_DOWNLOAD_HISTORY = 'proposalDetails/GET_PROPOSAL_DOWNLOAD_HISTORY';

export type GetProposalDetailsRequestAction = ReturnType<typeof getProposalDetailsRequestAction>;
export const getProposalDetailsRequestAction = (proposalUuid: string) => ({
  type: GET_PROPOSAL_DETAILS_REQUEST as typeof GET_PROPOSAL_DETAILS_REQUEST,
  proposalUuid,
});

export type GetProposalDetailsSuccessAction = ReturnType<typeof getProposalDetailsSuccessAction>;
export const getProposalDetailsSuccessAction = (proposal: IProposalSelection) => ({
  type: GET_PROPOSAL_DETAILS_SUCCESS as typeof GET_PROPOSAL_DETAILS_SUCCESS,
  proposal,
});

export type GetProposalDetailsFailureAction = ReturnType<typeof getProposalDetailsFailureAction>;
export const getProposalDetailsFailureAction = (error: any) => ({
  type: GET_PROPOSAL_DETAILS_FAILURE as typeof GET_PROPOSAL_DETAILS_FAILURE,
  error,
});

export type ToggleSelectionProposalBookingAction = ReturnType<typeof toggleSelectionProposalBookingAction>;
export const toggleSelectionProposalBookingAction = (bookingUuid: string) => ({
  type: TOGGLE_SELECTION_PROPOSAL_BOOKING as typeof TOGGLE_SELECTION_PROPOSAL_BOOKING,
  bookingUuid,
});

export type GetProposalDetailsSortByAction = ReturnType<typeof getProposalDetailsSortByAction>;
export const getProposalDetailsSortByAction = () => ({
  type: GET_PROPOSAL_DETAILS_SORT_BY as typeof GET_PROPOSAL_DETAILS_SORT_BY,
});

export type SetProposalDetailsSortByAction = ReturnType<typeof setProposalDetailsSortByAction>;
export const setProposalDetailsSortByAction = (sortBy: EProposalSortBy, sortOrder: ESortOrder) => ({
  type: SET_PROPOSAL_DETAILS_SORT_BY as typeof SET_PROPOSAL_DETAILS_SORT_BY,
  sortBy,
  sortOrder,
});

export type SetProposalDownloadHistory = ReturnType<typeof setProposalDownloadHistoryAction>;
export const setProposalDownloadHistoryAction = (proposalDownloadHistory: IProposalHistory[]) => ({
  type: SET_PROPOSAL_DOWNLOAD_HISTORY as typeof SET_PROPOSAL_DOWNLOAD_HISTORY,
  proposalDownloadHistory,
});

export type SetProposalBookingsAction = ReturnType<typeof setProposalBookingsAction>;
export const setProposalBookingsAction = (bookings: IProposalBooking[]) => ({
  type: SET_PROPOSAL_BOOKINGS as typeof SET_PROPOSAL_BOOKINGS,
  bookings,
});

export type ProposalsDetailsAction =
  | GetProposalDetailsRequestAction
  | GetProposalDetailsSuccessAction
  | GetProposalDetailsFailureAction
  | GetProposalDetailsSortByAction
  | SetProposalDetailsSortByAction
  | SetProposalBookingsAction
  | SetProposalDownloadHistory
  | ToggleSelectionProposalBookingAction;
