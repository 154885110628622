import React from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import * as Inputs from '../Inputs';
import FluidButton from 'ui/FluidButton';
import { IGroundServiceProduct, IHotel } from 'services/BackendApi';
import { produce } from 'immer';
import * as _ from 'lodash-es';
import { GroundService } from '../child-product-forms/GroundService';
import { validateGroundService } from 'containers/HotelAdmin/validation';
import { useDispatch } from 'react-redux';
import { enqueueNotification } from 'store/modules/ui';

export const CreateGroundServiceModal = ({
  onConfirm,
  onClose,
  hotel,
}: {
  onConfirm: (data: any) => void;
  onClose: () => void;
  hotel: IHotel;
}) => {
  const dispatch = useDispatch();
  const [draftGS, setDraftGS] = React.useState<Partial<IGroundServiceProduct>>({
    meta: {
      description: '',
    },
    options: {
      ages: [],
    },
  });

  return (
    <GeneralModal
      onClose={() => onClose()}
      modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-scroll font-pt-sans"
      shouldCloseByClickingOutside={false}
    >
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">Create new Ground Service</h1>
      <GroundService
        groundService={draftGS}
        onUpdate={(field, value) => {
          setDraftGS({
            ...produce(draftGS, _draftRoom => {
              _.set(_draftRoom, field, value);
            }),
          });
        }}
        onCta={() => {
          const validation = validateGroundService(draftGS);
          if (validation.isValid === false) {
            dispatch(
              enqueueNotification({
                message: `Ground Service is not valid: ${validation.message}`,
                options: { variant: 'warning' },
              })
            );
            return;
          }
          onConfirm(draftGS);
        }}
        ctaLabel="Create Ground Service"
      />
    </GeneralModal>
  );
};
