import React from 'react';
import { Loader } from '@pure-escapes/webapp-ui-components';

import i18n from 'config/i18n';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

export const AdminRedirect = () => {
  const { dynamicParameters } = useDynamicParameters();
  window.location.pathname === '/' &&
    dynamicParameters.ADMIN_BASE_URL &&
    window &&
    window.location.replace(dynamicParameters.ADMIN_BASE_URL);
  return <Loader text={i18n.t('messages.redirecting')} />;
};

export default AdminRedirect;
