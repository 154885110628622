import { ICurrencyDefinition, TCurrencyCode } from 'interfaces';
import currencies from '../config/data/currencies.json';

const symbolToCurency = Object.keys(currencies).reduce((acc, cur) => {
  const v = currencies[cur];
  acc[v.symbol] = v;
  return acc;
}, {});

export const getCurrency = (currencyCode: TCurrencyCode): ICurrencyDefinition => {
  return currencies[currencyCode] as ICurrencyDefinition;
};

export const getCurrencySymbol = (currencyCode: TCurrencyCode): string => {
  return getCurrency(currencyCode)?.symbol || '';
};

export const getCurrencyBySymbol = (symbol: string): ICurrencyDefinition => {
  return symbolToCurency[symbol];
};

export const rateStringToCents = rate => Math.round(parseFloat(rate.replace(/,/g, '')) * 100);
