import React from 'react';
import { BackLink } from 'ui/BackLink';
import { InternalUserForm } from '../InternalUserForm';

export const Create: React.FC = () => {
  return (
    <div className="internal-user-create w-1280px mx-auto">
      <BackLink type="internal" href="/users">
        Back to internal users
      </BackLink>
      <h1 className="title font-noe-display font-normal text-black my-0">
        <span className="text-4xl leading-46px">New Internal User</span>
      </h1>
      <InternalUserForm />
    </div>
  );
};
