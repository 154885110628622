import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import classnames from 'classnames';
import { History } from 'history';
import { useHistory } from 'react-router';
import { PencilIcon } from 'ui/Icons/components/Pencil.component';
import { IHotel } from 'services/BackendApi';

interface IEditButtonProps {
  caption: string;
  hotel: IHotel;
  setRedirectLocation: React.Dispatch<React.SetStateAction<string | null>>;
  setShouldShowLeaveAlert: Dispatch<SetStateAction<boolean>>;
  className?: string;
}

export const EditButton: React.FC<IEditButtonProps> = React.memo(({ hotel, className, setRedirectLocation, setShouldShowLeaveAlert, caption }) => {
  const history = useHistory<History.LocationState>();

  const handleEdit = useCallback(() => {
    const searchQuery = window.location.search ?? '';
    setShouldShowLeaveAlert(false);
    setRedirectLocation(`/hotels/${hotel.uuid}${searchQuery}`);
  }, [hotel.uuid, setRedirectLocation, setShouldShowLeaveAlert]);

  return (
    <div className={classnames('booking-accommodation-edit flex justify-end items-center gap-[3px] cursor-pointer', className)} onClick={handleEdit}>
      <PencilIcon />
      <a className="booking-accommodation-edit-label font-hurme-geometric-sans text-[14px] text-brown-prime leading-[17px] underline uppercase">{caption}</a>
    </div>
  );
});