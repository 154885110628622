import React from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import { formatDateDisplayFourDigitYear } from 'utils';
import { Multiselect, IMultiselectProps } from 'ui/Multiselect';
import FluidButton from 'ui/FluidButton';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import { GuestAgesPicker } from 'ui/GuestAgesPicker/GuestAgesPicker';
import { format } from 'date-fns';
import classNames from 'classnames';
import { ErrorBar } from 'ui/NetworkStatusBar';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import PickupLocationIcon from 'ui/Icons/pickuplocation.component.svg';
import DropoffLocationIcon from 'ui/Icons/dropofflocation.component.svg';
import CalendarIcon from 'ui/Icons/calendar-today.component.svg';
import { SvgIcon } from 'ui/SvgIcon';
import { isNil } from 'lodash-es';
import {
  AncillaryProductWithRatesWithUserData,
  AncillaryRatesProductsSearchItem,
  EAncillaryTransferTripType,
  LocationDirectionWithCountry,
  SelectedAncillarySearchResult,
} from 'services/BookingManagerApi/types/AncillaryService';
import { theme } from '../../../tailwind.config';
import { AddAncillarySearchResultsTable } from 'ui/AddAncillarySharedComponents/AddAncillarySearchResultsTable';
import { AddAncillaryRightHandPanel } from 'ui/AddAncillarySharedComponents/AddAncillaryRightHandPanel';
import { iAddTransferModalCustomData } from 'containers/AddTransferModalContainer/AddTransferModalContainer';
import { Radio } from 'ui/Radio';
import { AncillaryStyledDateRangeInput } from 'ui/AddAncillarySharedComponents/utils';
import { IGuestAges } from 'services/BackendApi';

export interface IAddTransferModalProps {
  isOpen: boolean;
  currencySymbol: string;
  onClose: () => void;
  onSearchButtonClick: () => void;
  onCustomTransferAdd: () => void;

  pickupFromLocation: LocationDirectionWithCountry | null;
  handleSelectPickupLocation: (pickupFromLocationCompositeId: string | undefined, isCustom: boolean) => void;
  dropOffLocation: LocationDirectionWithCountry | null;
  handleSelectDropOffLocation: (dropOffLocationCompositeId: string | undefined, isCustom: boolean) => void;
  locationOptions: IMultiselectProps['options'];

  dates: string[];
  setDates: (date: string[]) => void;

  typesOptions: IMultiselectProps['options'];

  selectedFilterIds: string[];
  setSelectedFilterIds: (filterIds: string[]) => void;
  selectedTransferOrSupplier: {
    id: string;
    type: 'transfer' | 'supplier';
  } | null;

  setSelectedTransferOrSupplier: (
    arg: {
      id: string;
      type: 'transfer' | 'supplier';
    } | null
  ) => void;

  transferOrSupplierSelectOnLookup: IMultiselectProps['onLookupSearch'];

  guestAges: IGuestAges;
  setGuestAges: (numberOfAdults: number, agesOfAllChildren?: number[]) => void;

  searchResults?: AncillaryRatesProductsSearchItem[];
  selectedSearchResult: SelectedAncillarySearchResult | null;
  setSelectedSearchResult: (selectedSearchResult: SelectedAncillarySearchResult | null) => void;
  selectedTransferProduct: AncillaryProductWithRatesWithUserData | null; // the full details from the api for the search result

  updateSelectedTransferProductWithUserData: (data: Partial<AncillaryProductWithRatesWithUserData['userData']>) => void;

  handleAddTransfer: () => void;

  errorIds: string[];

  searchInstanceVars: {
    dates?: string[];
    guestAges?: IGuestAges;
    pickupFromLocation?: LocationDirectionWithCountry | null;
    dropOffLocation?: LocationDirectionWithCountry | null;
  };

  pickupTime: string;
  setPickupTime: (time: string) => void;

  getSearchResultsNetwork: ENetworkRequestStatus;
  getTypesResultsNetworkStatus: ENetworkRequestStatus;
  getTransferProductNetwork: ENetworkRequestStatus;
  getLocationsNetwork: ENetworkRequestStatus;

  customTransferData: iAddTransferModalCustomData;
  tripType: EAncillaryTransferTripType;
  setTripType: (tripType: EAncillaryTransferTripType) => void;
  returnTime: string;
  setReturnTime: (time: string) => void;
}

/**
 * Renders the add transfer modal
 * This component doesn't use any state internally, it's all passed in as props
 * Which means you probably don't want to use this component directly, and you're actually wanting
 * to use the AddTransferModalContainer component
 */

export const AddTransferModal = (props: IAddTransferModalProps) => {
  const { guestAges, setGuestAges, getSearchResultsNetwork = ENetworkRequestStatus.IDLE } = props;

  const showRightPanel = !isNil(props.selectedSearchResult);

  const searchInstanceVarsPickupFromLocationName = props.locationOptions.find(
    location => location.value === props.searchInstanceVars?.pickupFromLocation?.compositeId
  )?.label;

  const searchInstanceVarsDropOffLocationName = props.locationOptions.find(
    location => location.value === props.searchInstanceVars?.dropOffLocation?.compositeId
  )?.label;

  if (!props.isOpen) {
    return null;
  }

  let customTransferPickupFrom: string | null = null;
  if (props.customTransferData.pickupFrom) {
    customTransferPickupFrom = props.customTransferData.pickupFrom;
  }
  if (props.pickupFromLocation) {
    // get the label of the pickup location from the locations
    customTransferPickupFrom =
      props.locationOptions.find(location => location.value === props.pickupFromLocation?.compositeId)?.label || null;
  }
  // now do the same for dropoff
  let customTransferDropOff: string | null = null;
  if (props.customTransferData.dropOff) {
    customTransferDropOff = props.customTransferData.dropOff;
  }
  if (props.dropOffLocation) {
    customTransferDropOff =
      props.locationOptions.find(location => location.value === props.dropOffLocation?.compositeId)?.label || null;
  }

  const isAnyCustomTransferDataSet =
    !isNil(props.customTransferData.pickupFrom) || !isNil(props.customTransferData.dropOff);

  return (
    <GeneralModal
      onClose={props.onClose}
      modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-clip"
      shouldCloseByClickingOutside={false}
    >
      {/* the modal title and search fields */}
      <h2 className="m-0 p-0 font-noe-display text-21px leading-27px font-normal mb-25px">Add Transfer</h2>

      {/* this is an extremely specific height for a reason */}
      <div className="all-panels flex space-x-[30px] overflow-x-hidden flex-grow h-[calc(100%-65px)]">
        <div
          className={classNames('left-panel flex flex-col flex-shrink space-y-25px', {
            'w-[calc(100%-300px-35px)]': showRightPanel,
          })}
        >
          <div className="search-and-filters">
            <div
              className={classNames('filters-and-search space-y-15px font-pt-sans', {
                'o:opacity-50 pointer-events-none': getSearchResultsNetwork === ENetworkRequestStatus.PENDING,
              })}
            >
              <div className="flex space-x-20px">
                {/* Pickup From */}
                <label
                  className={classNames({
                    'w-[237px]': showRightPanel,
                    'w-[395px]': !showRightPanel,
                  })}
                >
                  <span className="font-bold text-black mb-5px block">From *</span>

                  <Multiselect
                    id="pickup-from-multiselect"
                    options={props.locationOptions}
                    disabled={props.getLocationsNetwork === ENetworkRequestStatus.PENDING}
                    className={classNames('bg-ivory', {
                      'o:opacity-50 pointer-events-none': props.getLocationsNetwork === ENetworkRequestStatus.PENDING,
                    })}
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-10"
                    onUpdate={(values, isCustom) => {
                      props.handleSelectPickupLocation(values[0], !!isCustom);
                    }}
                    selectedValues={props.pickupFromLocation ? [props.pickupFromLocation.compositeId] : []}
                    isEnableFuzzySearch
                    isCloseOnSelect
                    isSingleSelectMode
                    hideCheckboxes
                    hideDropdownArrow
                    hideNonMatchingOptions
                    isEnableCreateNewButton
                  />
                </label>

                {/* Drop Off */}
                <label
                  className={classNames({
                    'w-[237px]': showRightPanel,
                    'w-[395px]': !showRightPanel,
                  })}
                >
                  <span className="font-bold text-black mb-5px block">To *</span>
                  <Multiselect
                    options={props.locationOptions}
                    disabled={props.getLocationsNetwork === ENetworkRequestStatus.PENDING}
                    className={classNames('bg-ivory', {
                      'o:opacity-50 pointer-events-none': props.getLocationsNetwork === ENetworkRequestStatus.PENDING,
                    })}
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-10"
                    onUpdate={(values, isCustom) => {
                      props.handleSelectDropOffLocation(values[0], !!isCustom);
                    }}
                    selectedValues={props.dropOffLocation ? [props.dropOffLocation.compositeId] : []}
                    isEnableFuzzySearch
                    isCloseOnSelect
                    isSingleSelectMode
                    hideCheckboxes
                    hideDropdownArrow
                    hideNonMatchingOptions
                    isEnableCreateNewButton
                  />
                </label>

                {/* People */}
                <label
                  className={classNames({
                    'w-[186px]': showRightPanel,
                    'w-[200px]': !showRightPanel,
                  })}
                >
                  <span className="font-bold text-black mb-5px block">People *</span>
                  <GuestAgesPicker
                    numberOfAdults={guestAges.numberOfAdults}
                    agesOfAllChildren={guestAges.agesOfAllChildren}
                    onUpdate={(numberOfAdults, agesOfAllChildren) => {
                      setGuestAges(numberOfAdults, agesOfAllChildren || []);
                    }}
                  />
                </label>
              </div>

              {/* trip type and type and supplier or service */}
              <div className={classNames('flex space-x-20px')}>
                {/* trip type */}
                <div className="w-[166px]">
                  <span className="font-bold text-black mb-5px block">Trip Type</span>
                  <div
                    className={classNames('flex mt-3 space-x-3', {
                      // 'space-x-3': !showRightPanel,
                      // 'space-x-1': showRightPanel,
                    })}
                  >
                    <label className="block cursor-pointer">
                      <Radio
                        checked={props.tripType === EAncillaryTransferTripType.ONE_WAY}
                        onClick={() => {
                          props.setTripType(EAncillaryTransferTripType.ONE_WAY);
                        }}
                      />
                      <span className="ml-2">One Way</span>
                    </label>

                    <label className="block cursor-pointer">
                      <Radio
                        checked={props.tripType === EAncillaryTransferTripType.RETURN}
                        onClick={() => {
                          props.setTripType(EAncillaryTransferTripType.RETURN);
                        }}
                      />
                      <span className="ml-2">Return</span>
                    </label>
                  </div>
                </div>

                {/* Dates */}
                <label
                  className={classNames({
                    'w-[102px]': showRightPanel,
                    'w-[170px]': !showRightPanel,
                  })}
                >
                  <span className="font-bold text-black mb-5px block">
                    {props.tripType === EAncillaryTransferTripType.ONE_WAY ? 'Date' : 'Dates'} *
                  </span>
                  <DatePickerStateProvider
                    isSingleDateSelection={props.tripType === EAncillaryTransferTripType.ONE_WAY}
                    defaultSelectedDates={props.dates}
                    onDateChange={dates => {
                      props.setDates(dates.map(d => format(new Date(d), 'yyyy-MM-dd')));
                    }}
                    placeholder={props.tripType === EAncillaryTransferTripType.ONE_WAY ? 'Select Date' : 'Select Dates'}
                    render={(params: IDatePickerSateParams) => {
                      return (
                        <AncillaryStyledDateRangeInput
                          displayString={params.displayString}
                          currentDate={params.datePickerCurrentDate}
                          selectedDates={[...params.selectedDates.map(d => new Date(d).toISOString())]}
                          onDayClick={params.handleDayClick}
                          onDayMouseOver={params.handleDateMouseOver}
                          showDatePicker={params.showDatePicker}
                          onNextClick={params.incrementDate}
                          onPrevClick={params.decrementDate}
                          onMouseDown={params.toggleDatePicker}
                          onClickOutside={params.hideDatePicker}
                          showTotalNights={false}
                          enablePastDates
                          noPortal
                        />
                      );
                    }}
                  />
                </label>

                {/* Type */}
                <label
                  className={classNames({
                    'w-[186px]': showRightPanel,
                    'w-[317px]': !showRightPanel,
                    hidden: isAnyCustomTransferDataSet,
                  })}
                >
                  <span className="font-bold text-black mb-5px block">Type</span>
                  <Multiselect
                    options={props.typesOptions}
                    className={classNames('bg-ivory', {
                      'o:opacity-50 pointer-events-none':
                        props.getTypesResultsNetworkStatus === ENetworkRequestStatus.PENDING,
                    })}
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-10"
                    disabled={props.getTypesResultsNetworkStatus === ENetworkRequestStatus.PENDING}
                    onUpdate={values => {
                      props.setSelectedFilterIds(values);
                    }}
                    staticOptions={[
                      {
                        value: '-1',
                        label: 'All',
                      },
                    ]}
                    selectedValues={isNil(props.selectedFilterIds) ? [] : props.selectedFilterIds}
                    hideDropdownArrow
                  />
                </label>

                {/* Supplier or Service Name */}
                <label
                  className={classNames({
                    hidden: isAnyCustomTransferDataSet,
                    'w-[186px]': showRightPanel,
                    'w-[317px]': !showRightPanel,
                    'opacity-30': isNil(props.dates) || isNil(props.pickupFromLocation) || isNil(props.dropOffLocation),
                  })}
                >
                  <span className="font-bold text-black mb-5px block">Supplier or Service Name</span>
                  <Multiselect
                    options={[]}
                    disabled={isNil(props.dates) || isNil(props.pickupFromLocation) || isNil(props.dropOffLocation)}
                    className="bg-ivory"
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-10"
                    selectedValues={
                      isNil(props.selectedTransferOrSupplier)
                        ? []
                        : [`${props.selectedTransferOrSupplier.type}-${props.selectedTransferOrSupplier.id}`]
                    }
                    onUpdate={values => {
                      if (values.length === 0) {
                        props.setSelectedTransferOrSupplier(null);
                        return;
                      }
                      const [type, id] = values[0].split('-');
                      props.setSelectedTransferOrSupplier({ id, type: type as 'transfer' | 'supplier' });
                    }}
                    hideDropdownArrow
                    isEnableLookupSearch
                    onLookupSearch={props.transferOrSupplierSelectOnLookup}
                    isCloseOnSelect={true}
                    isSingleSelectMode={true}
                    hideCheckboxes={true}
                    noOptionsAvailableText="Please type and search to lookup transfers or suppliers"
                  />
                </label>
              </div>

              {!isAnyCustomTransferDataSet && (
                <FluidButton
                  disabled={isNil(props.pickupFromLocation) || isNil(props.dropOffLocation) || props.dates.length <= 0}
                  onClick={props.onSearchButtonClick}
                  type="primary"
                  isLoading={getSearchResultsNetwork === ENetworkRequestStatus.PENDING}
                >
                  Search
                </FluidButton>
              )}

              {isAnyCustomTransferDataSet && (
                <span className="block text-yellow-100">
                  There are no results for the specified location. Please "Add Transfer" and update the information in
                  the Breakdown
                </span>
              )}

              {isAnyCustomTransferDataSet && (
                <FluidButton
                  disabled={props.dates.length <= 0 || isNil(customTransferPickupFrom) || isNil(customTransferDropOff)}
                  onClick={props.onCustomTransferAdd}
                  type="primary"
                >
                  Add Transfer
                </FluidButton>
              )}
            </div>
          </div>

          {getSearchResultsNetwork === ENetworkRequestStatus.ERROR && (
            <ErrorBar message="Failed to load search results" ctaCaption="Please reload the page and try again" />
          )}

          <AddAncillarySearchResultsTable
            className={classNames('flex-grow h-[100px]', {
              hidden: isAnyCustomTransferDataSet,
            })}
            currencySymbol={props.currencySymbol}
            handleSelectSearchResult={(baseProductId, variantId) => {
              props.setSelectedSearchResult({ baseProductId, variantId });
            }}
            primaryColumnId="transfer"
            primaryColumnName="Transfer"
            searchResults={props.searchResults}
            selectedSearchResult={props.selectedSearchResult}
            isLoading={getSearchResultsNetwork === ENetworkRequestStatus.PENDING}
            baseProductNameRenderer={searchResult => {
              // if we have ANY variants, use the supplier from the first one
              if (!isNil(searchResult.variantsWithRate) && searchResult.variantsWithRate.length >= 1) {
                return `${searchResult.variantsWithRate[0].supplierName}, ${searchResult.baseProductName}`;
              }

              // if no variant, it must be a standard product with rate
              return `${searchResult.productWithRate?.supplierName}, ${searchResult.baseProductName}`;
            }}
          />
        </div>

        {showRightPanel && (
          <AddAncillaryRightHandPanel
            domainName="Transfer"
            className="o:w-[320px]"
            productNamePrefix={props.tripType === EAncillaryTransferTripType.RETURN ? 'Return ' : ''}
            currencySymbol={props.currencySymbol}
            errorIds={props.errorIds}
            handleAddProduct={props.handleAddTransfer}
            selectedProduct={props.selectedTransferProduct}
            slotContent={
              <React.Fragment>
                {/* right hand pickup and dropoff labels */}
                {searchInstanceVarsPickupFromLocationName && searchInstanceVarsDropOffLocationName && (
                  <div className="flex flex-col space-y-2">
                    <span className="flex items-center space-x-2">
                      <SvgIcon className="fill-black" width="16px" height="16px" IconComponent={PickupLocationIcon} />
                      <span>{searchInstanceVarsPickupFromLocationName}</span>
                    </span>
                    <span className="flex items-center space-x-2">
                      <SvgIcon className="fill-black" width="16px" height="16px" IconComponent={DropoffLocationIcon} />
                      <span>{searchInstanceVarsDropOffLocationName}</span>
                    </span>
                  </div>
                )}

                {/* right hand one way date */}
                {props.searchInstanceVars.dates && props.searchInstanceVars.dates.length === 1 && (
                  <span className="flex items-center space-x-2">
                    <SvgIcon className="fill-black" width="16px" height="16px" IconComponent={CalendarIcon} />
                    <span>{formatDateDisplayFourDigitYear(props.searchInstanceVars.dates[0])}</span>
                  </span>
                )}

                {/* right hand return dates */}
                {props.searchInstanceVars.dates && props.searchInstanceVars.dates.length > 1 && (
                  <div className="return-dates flex space-x-5">
                    <span className="flex flex-col space-y-1 w-120px">
                      <span className="block text-black text-16px leading-20px tracking-2xs font-pt-sans font-bold">
                        Pickup Date
                      </span>
                      <span className="flex items-center space-x-2">
                        <SvgIcon className="fill-black" width="16px" height="16px" IconComponent={CalendarIcon} />
                        <span>{formatDateDisplayFourDigitYear(props.searchInstanceVars.dates[0])}</span>
                      </span>
                    </span>

                    <span className="flex flex-col space-y-1 w-120px">
                      <span className="block text-black text-16px leading-20px tracking-2xs font-pt-sans font-bold">
                        Return Date
                      </span>
                      <span className="flex items-center space-x-2">
                        <SvgIcon className="fill-black" width="16px" height="16px" IconComponent={CalendarIcon} />
                        <span>
                          {formatDateDisplayFourDigitYear(
                            props.searchInstanceVars.dates[props.searchInstanceVars.dates.length - 1]
                          )}
                        </span>
                      </span>
                    </span>
                  </div>
                )}

                {/* right hand one way time */}
                {props.searchInstanceVars.dates && props.searchInstanceVars.dates.length === 1 && (
                  <label className="w-120px mr-4">
                    <div className="flex flex-col space-y-1">
                      <span className="block text-black text-16px leading-20px tracking-2xs font-pt-sans font-bold">
                        Time
                      </span>
                      <input
                        onChange={e => {
                          props.setPickupTime(e.currentTarget.value);
                        }}
                        type="time"
                        className={classNames(
                          'arrival-time-input p-2 border border-solid border-gray-40 min-h-35px max-h-35px w-full',
                          {
                            'bg-red-25 border-red-95': props.errorIds.includes('pickupTime'),
                            'bg-ivory border-gray-40': !props.errorIds.includes('pickupTime'),
                          }
                        )}
                        value={props.pickupTime}
                      />
                    </div>
                  </label>
                )}

                {/* right hand return times */}
                {props.searchInstanceVars.dates && props.searchInstanceVars.dates.length > 1 && (
                  <div className="return-times flex space-x-5">
                    <span className="flex flex-col space-y-1 w-120px">
                      <span className="block text-black text-16px leading-20px tracking-2xs font-pt-sans font-bold">
                        Pickup Time
                      </span>
                      <input
                        onChange={e => {
                          props.setPickupTime(e.currentTarget.value);
                        }}
                        type="time"
                        className={classNames(
                          'arrival-time-input p-2  border border-solid border-gray-40 min-h-35px max-h-35px w-full',
                          {
                            'bg-red-25 border-red-95': props.errorIds.includes('pickupTime'),
                            'bg-ivory border-gray-40': !props.errorIds.includes('pickupTime'),
                          }
                        )}
                        value={props.pickupTime}
                      />
                    </span>

                    <span className="flex flex-col space-y-1 w-120px">
                      <span className="block text-black text-16px leading-20px tracking-2xs font-pt-sans font-bold">
                        Return Time
                      </span>
                      <input
                        onChange={e => {
                          props.setReturnTime(e.currentTarget.value);
                        }}
                        type="time"
                        className={classNames(
                          'arrival-time-input p-2  border border-solid border-gray-40 min-h-35px max-h-35px w-full',
                          {
                            'bg-red-25 border-red-95': props.errorIds.includes('returnTime'),
                            'bg-ivory border-gray-40': !props.errorIds.includes('returnTime'),
                          }
                        )}
                        value={props.returnTime}
                      />
                    </span>
                  </div>
                )}
              </React.Fragment>
            }
            updateSelectedProductWithUserData={props.updateSelectedTransferProductWithUserData}
            productLoad={props.getTransferProductNetwork}
          />
        )}
      </div>
    </GeneralModal>
  );
};
