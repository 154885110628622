import React, { useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { map } from 'ramda';
import hash from 'object-hash';
import { getMainCompanySelector } from 'store/modules/bootstrap/selectors';
import Helmet from 'react-helmet';

const renderLink = props => <link key={hash(props)} {...props} />;

export const TitleFavicon = () => {
  const { dynamicParameters } = useDynamicParameters();
  const mainCompanyInfo = useSelector(getMainCompanySelector);
  const title = mainCompanyInfo?.name ?? '';

  const links = [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: dynamicParameters.APPLE_TOUCH_ICON,
    },
    {
      rel: 'icon',
      sizes: '32x32',
      href: dynamicParameters.FAVICON32,
    },
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: dynamicParameters.SVGICON,
    },
  ];

  return (
    <Helmet>
      <title>{title}</title>
      {map(renderLink, links)}
    </Helmet>
  );
};
