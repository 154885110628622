import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getBootstrapCountriesSelector } from 'store/modules/bootstrap/selectors';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';

export interface ICountrySelectProps {
  className?: string;
  value: string | null;
  onChange: (v: string | null) => void;
}

export const CountrySelect: React.FC<ICountrySelectProps> = props => {
  const countries = useSelector(getBootstrapCountriesSelector);
  const countryOptions: ISingleSelectOption[] = useMemo(
    () => countries.map(c => ({ value: c.code, label: `${c.name} (${c.code})` })),
    [countries]
  );

  const handleChange = useCallback((value?: string) => props.onChange(value ?? null), [props.onChange]);

  return (
    <SingleSelect
      fieldId="filterCountry"
      className={props.className}
      value={props.value ?? undefined}
      options={countryOptions}
      onChange={handleChange}
      maxVisibleItems={6}
      showSelectedOption
      labelWhenNothingSelected="All countries"
    />
  );
};
