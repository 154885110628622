import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { EUserType } from 'services/BackendApi/types';
import AuthRoute from 'containers/AuthRoute';
import { LiveRatesListingPage } from './LiveRatesListing';
import { LiveRatesSettingPage } from './LiveRatesSetting';

export interface LiveRatesRoutingProps extends RouteComponentProps {}

export const LiveRatesRoutingComponent = (props: LiveRatesRoutingProps) => {
  return (
    <Switch>
      <Route exact path={props.match.path} component={LiveRatesListingPage} />
      <AuthRoute
        allow={[EUserType.RL, EUserType.ADMIN]}
        exact
        path={`${props.match.path}/:stayUuid/settings`}
        component={LiveRatesSettingPage}
      />
    </Switch>
  );
};

export const LiveRatesRouting = compose(withRouter)(LiveRatesRoutingComponent);
