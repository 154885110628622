import { TCurrencyCode } from 'interfaces';
import { ENetworkRequestStatus, LiveRate } from 'services/BackendApi';

export type LiveRatesSubdomain = {
  liveRates: LiveRate[];
  currency?: TCurrencyCode;
  
  networkRequests: {
    getLiveRates: ENetworkRequestStatus;
  }
};

export const liveRatesInitialState: LiveRatesSubdomain = {
  liveRates: [],
  networkRequests: {
    getLiveRates: ENetworkRequestStatus.IDLE
  }
};
