import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const RoomSizeIcon: React.FC<IIconProps> = React.memo(({ icon = theme.colors['gray-100'] }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 7.5C12 7.15179 11.7054 6.85714 11.3571 6.85714H10.9286C10.5804 6.85714 10.2857 7.17857 10.2857 7.52679L10.3393 9.29464L7.04464 6L10.3393 2.70536L10.2857 4.5C10.2857 4.84821 10.5804 5.14286 10.9286 5.14286H11.3571C11.7054 5.14286 12 4.875 12 4.5V0.857143C12 0.401786 11.5982 0 11.1429 0H7.5C7.125 0 6.85714 0.294643 6.85714 0.642857V1.07143C6.85714 1.41964 7.15179 1.71429 7.5 1.71429L9.29464 1.66071L6 4.95536L2.70536 1.66071L4.47321 1.71429C4.82143 1.71429 5.14286 1.41964 5.14286 1.07143V0.642857C5.14286 0.294643 4.84821 0 4.5 0H0.857143C0.375 0 0 0.401786 0 0.857143V4.5C0 4.875 0.267857 5.14286 0.642857 5.14286H1.04464C1.39286 5.14286 1.6875 4.84821 1.6875 4.5L1.63393 2.70536L4.92857 6L1.63393 9.29464L1.6875 7.52679C1.6875 7.17857 1.39286 6.85714 1.04464 6.85714H0.642857C0.267857 6.85714 0 7.15179 0 7.5V11.1429C0 11.625 0.375 12 0.857143 12H4.5C4.84821 12 5.14286 11.7321 5.14286 11.3571V10.9554C5.14286 10.6071 4.82143 10.3125 4.47321 10.3125L2.70536 10.3661L6 7.07143L9.29464 10.3661L7.5 10.3125C7.15179 10.3125 6.85714 10.6071 6.85714 10.9554V11.3571C6.85714 11.7321 7.125 12 7.5 12H11.1429C11.5982 12 12 11.625 12 11.1429V7.5Z"
      fill={icon}
    />
  </svg>
));

export default RoomSizeIcon;
