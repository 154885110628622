import { IProposalsListItem, IProposalBasicInfo } from 'services/BackendApi/types/ProposalsListResponse';
import { EProposalSelectionSortableKey, IProposalSelection } from 'services/BookingManagerApi/types/ProposalSelection';
import { ESortOrder } from 'store/common/types';

export interface IPendingProposalsSubdomain {
  requestPending: boolean;
  error: any | null;
  count: number;
  latest: IProposalBasicInfo | null;
}

export interface IProposalsListDomain {
  requestPending: boolean;
  tableLoading: boolean;
  error: any | null;
  proposals: IProposalSelection[];
  nameFilter: string;
  clientFilter: string;
  itemsPerPage: number;
  currentPage: number;
  totalResults: number;
  sortBy: EProposalSelectionSortableKey;
  sortOrder: ESortOrder;
  filterFields: string[];
  bookingFields: string[];
  travelAgentUuid: string | null;
  pendingProposals: IPendingProposalsSubdomain;
}

export const initialState: IProposalsListDomain = {
  requestPending: false,
  tableLoading: false,
  error: null,
  proposals: [],
  nameFilter: '',
  clientFilter: '',
  itemsPerPage: 10,
  currentPage: 0,
  sortBy: EProposalSelectionSortableKey.CREATED_AT,
  sortOrder: ESortOrder.DESC,
  totalResults: 0,
  filterFields: ['uuid', 'guestFirstName', 'guestLastName', 'name'],
  bookingFields: ['hotelUuid', 'checkInDate', 'checkOutDate'],
  travelAgentUuid: null,
  pendingProposals: {
    requestPending: false,
    error: null,
    count: 0,
    latest: null
  }
};
