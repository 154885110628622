import React, { useCallback, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { prop, compose } from 'ramda';
import { Form, Title } from '@pure-escapes/webapp-ui-components';

import { withAuthentication } from 'hoc';
import { fields, validation } from 'config/forms/updatePassword';
import { getUpdatePasswordData } from 'store/modules/auth';
import { Status } from 'store/common';
import { enqueueNotification } from 'store/modules/ui';

import FluidButton from 'ui/FluidButton';

import connect from './SettingsPasswordForm.state';
import { propTypes, defaultProps } from './SettingsPasswordForm.props';
import { PasswordReset } from './SettingsPasswordForm.styles';

export const SettingsPasswordForm = ({ updatePassword, onComplete }) => {
  const { t } = useTranslation();
  const updatePasswordData = useSelector(getUpdatePasswordData);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    values => {
      updatePassword(values);
    },
    [updatePassword]
  );

  useEffect(() => {
    if (updatePasswordData.status === Status.SUCCESS) {
      onComplete();
      dispatch(
        enqueueNotification({
          message: 'Password has been updated.',
          options: { variant: 'success' },
        })
      );
    }
    if (updatePasswordData.status === Status.ERROR) {
      dispatch(
        enqueueNotification({
          message: updatePasswordData.error.detail,
          options: { variant: 'error' },
        })
      );
    }
  }, [updatePasswordData, onComplete, dispatch]);

  return (
    <PasswordReset>
      <Form onSubmit={onSubmit} enableReinitialize={true} validationSchema={validation}>
        {({ values, ...formProps }) => (
          <Fragment>
            <Title>{t('form.titles.updateYourPassword')}</Title>
            {Form.renderField(
              'currentPassword',
              prop('currentPassword', values),
              prop('currentPassword', fields),
              formProps
            )}
            {Form.renderField('newPassword', prop('newPassword', values), prop('newPassword', fields), formProps)}
            {Form.renderField(
              'newPasswordConfirm',
              prop('newPasswordConfirm', values),
              prop('newPasswordConfirm', fields),
              formProps
            )}
            <FluidButton
              type="primary"
              className="hover:bg-bistre py-12px transition-all ease-in-out  w-full"
              textClassName="font-hurmegeometric-sans font-semibold text-lg uppercase"
              border={false}
              rounded={false}
              heightLimited={false}
              submit
            >
              {t('buttons.updatePassword')}
            </FluidButton>
          </Fragment>
        )}
      </Form>
    </PasswordReset>
  );
};

SettingsPasswordForm.propTypes = propTypes;
SettingsPasswordForm.defaultProps = defaultProps;

export default compose(withAuthentication, connect)(SettingsPasswordForm);
