import {
  ISearchQuery,
  ICompany,
  ITravelAgent,
  NameSearchResponseData,
  MealPlanNames,
  ErrorResponse,
} from 'services/BackendApi';
import { ICompanyMembership } from 'services/BookingManagerApi/types/CompanyMemberships';

import { getDefaultSearchAndBookingStartDate, getDefaultSearchAndBookingEndDate } from 'store/utils';

export interface IDateRange {
  start: string;
  end: string | null;
}

export interface SearchDomain {
  showNameSearchResults: boolean;
  showLodgingControls: boolean;
  showDatePicker: boolean;
  isFetchingNameSearch: boolean;
  nameSearchResults: NameSearchResponseData | undefined;
  nameSearchRequestError: ErrorResponse | null;
  activeLodgingIndex: number;
  datePickerCurrentDate: string;
  dateSelectionInProgress: boolean;

  showCompanyDropdown: boolean;
  companyNameSearch: string;
  showTaDropdown: boolean;
  taNameSearch: string;

  companies: ICompany[] | null;
  selectedCompany: ICompany | null;
  selectedCompanyAgents: ITravelAgent[] | null;
  selectedTa: Partial<ITravelAgent> | null;

  isFetchingCompanies: boolean;
  isFetchingTA: boolean;

  companyRequestError: string | undefined;
  taRequestError: string | undefined;

  query: ISearchQuery;
  initialized: boolean;

  selectedTravelCompanyMembership: ICompanyMembership | null;
}

export const initialState: SearchDomain = {
  showNameSearchResults: false,
  showLodgingControls: false,
  showDatePicker: false,
  isFetchingNameSearch: false,
  nameSearchResults: undefined,
  nameSearchRequestError: null,
  activeLodgingIndex: 0,
  datePickerCurrentDate: new Date().toISOString(),
  dateSelectionInProgress: false,

  showCompanyDropdown: false,
  companyNameSearch: '',
  showTaDropdown: false,
  taNameSearch: '',

  companies: null,
  selectedCompany: null,
  selectedCompanyAgents: null,
  selectedTa: null,

  isFetchingCompanies: false,
  isFetchingTA: false,

  companyRequestError: undefined,
  taRequestError: undefined,

  query: {
    name: '',
    lodgings: [
      {
        numberOfAdults: 2,
        agesOfAllChildren: [],
        repeatCustomer: false,
      },
    ],
    mealPlanCategories: ['Any' as MealPlanNames],
    regions: [],
    filters: [],
    starRatings: [],
    startDate: getDefaultSearchAndBookingStartDate(),
    endDate: getDefaultSearchAndBookingEndDate(),
    priceRange: { min: undefined, max: undefined },
    clientCountryCode: null,
  },
  initialized: false,
  selectedTravelCompanyMembership: null,
};
