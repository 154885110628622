import * as React from 'react';

export enum EBreakpoints {
  MOBILE = 'mobile',
  TABLET_UP = 'tabletUp',
  DESKTOP_UP = 'desktopUp'
};

export type BreakpointsValues = {
  [key in EBreakpoints]: number;
};

export const breakpoints: BreakpointsValues = {
  [EBreakpoints.MOBILE]: 0,
  [EBreakpoints.TABLET_UP]: 768,
  [EBreakpoints.DESKTOP_UP]: 1000
};

export interface WithBreakpointsProps {
  breakpoints: BreakpointsValues;
  currentBreakpoint: EBreakpoints;
};

export type WithBreakpoints<P = any> = (C: React.ComponentType<P>) => React.ComponentType<P>;

export const breakpointValue = (bp: EBreakpoints): number => breakpoints[bp];
