export interface IHotelModalSubdomain {
  isOpen: boolean;
  accordionItemsStatus: boolean[];
}

export const hotelModalInitialState: IHotelModalSubdomain = {
  isOpen: false,
  accordionItemsStatus: [
    false,
    false,
    false
  ]
};
