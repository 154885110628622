import { breakpoints } from './breakpoints'

export const colors = {
  gold: '#A18265',
  goldDark: '#382A21',
  goldNeutral: '#736A65',
  goldLight: '#9D9591',
  goldBorder: '#816850',
  goldOpacity: 'rgba(161,130,101, 0.75)',
  brownPrime: '#8E7157',
  brown20: '#ECE6E0',
  brown100: '#A08264',
  brownHard: '#836449',
  black: '#373C46',
  blackLight: '#999999',
  blackDarker: '#333333',
  blackSecondary: '#9F9F9F',
  blackText: '#413E3B',

  white: '#FFFFFF',
  whiteish: '#F8F8F8',
  whiteOpacity1: 'rgba(248,248,248,0.85)',
  whiteOpacity2: 'rgba(255,255,255, 0.9)',
  ivory: '#fbfbfa',

  gray: '#EAEAEA',
  grayLight: '#F8F8F8',
  grayMedium: '#E9E8E9',
  grayDark: '#E0E0E0',
  graySecondary: '#E1E1E1',
  grayDarker: '#7b7b7b',
  gray40: '#D5D2D1',
  gray50: '#D1D0D0',
  gray80: '#ABA5A2',
  gray10: '#F4F4F3',

  grayDepth1: '#E5E3E2',
  grayDepth2: '#F3F3F3',
  grayDepth3: '#FAFAFA',
  grayDepth4: '#F4F4F3',
  grayDepth41: '#F1F1F1',
  grayDepth45: '#DCDBDC',
  grayDepth5: '#BBB4B1',

  grayOpacity1: 'rgba(0,0,0,0.35)',

  grayFaded: '#9d9591', // duplicate
  grayNeutral: '#a18265',

  marine: '#56D1BF',
  aqua: '#D4F3EE',

  lightBlue: '#F3F9FB',

  teal: '#57d2bf',
  teal40: '#c6efe3',
  teal80: '#8ddec8',
  teal100: '#70D6BA',

  redFade: '#FD5656',
  yellow: '#f5a623',
  green: '#56d1bf',
  greenLight: '#D6F2E6',
  greenDark: '#30AF8C',
  warmGreen: '#2cc663',
  redLight: '#fed7d7',
};

const colorRoles = {
  lightGreyBorder: colors.grayDark,
  areaBackground: colors.whiteish,
  grayLabel: '#736A65',
  error: colors.redFade,
  warningBackground: '#FEEFB9',
  warningBorder: colors.yellow,
  warningText: colors.yellow,
  errorBackground: '#FFD7D7',
  errorBorder: colors.redFade,
  errorText: colors.redFade,
  informationBackground: '#D1FFF8',
  informationBorder: colors.marine,
  informationText: colors.marine,
};
const typography = {
  sansSerifFont: 'HurmeGeometricSans2, "Open Sans", sans-serif',
  serifFont: 'NoeDisplay, "Open Sans", sans-serif',
  sizes: {
    default: 12,
    less: 13,
    normal: 14,
    mid: 16,
    big: 18,
    bigger: 22,
    link: 11,
  },
  bolder: 800,
  bold: 600,
  normal: 'normal',
  light: 300,
  lighter: 200,
  letterSpacing: {
    wide: 1,
    mid: 0.5,
    narrow: 0.46,
  },
};

const checkboxSize = 23;
const checkboxTittleSize = 6;
const measurements = {
  checkboxSize,
  checkboxTittleSize,
  controlDottOffset: Math.floor(checkboxSize / 2) - Math.floor(checkboxTittleSize / 2),
};

export const pureUiTheme = {
  fontSize: '16px',
  baseUnit: '4px',
  colors,
  colorRoles,
  measurements,
  typography,
  breakpoints
};

export type PureUiTheme = typeof pureUiTheme;
