import React from 'react';

import { StandardModal } from 'pureUi/Modal';
import FixedButton from '../FixedButton';
import classNames from 'classnames';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { GeneralModal } from 'ui/GeneralModal';

export enum EConfirmationModalType {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

interface IConfirmationModalProps {
  className?: string;
  type: EConfirmationModalType;
  isOpen?: boolean;
  title?: string;
  message?: string;
  extraMessage?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  isConfirmLoading?: boolean;
  isCancelLoading?: boolean;
  isContentLoading?: boolean;
  width?: number;
  buttonsWrapperClassname?: string;
}

export const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  className,
  type,
  title,
  message,
  extraMessage,
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm,
  onCancel,
  isOpen = true,
  isConfirmLoading,
  isCancelLoading,
  isContentLoading,
  width = 500,
  buttonsWrapperClassname = '',
}) => {
  if (!isOpen) {
    return null;
  }

  const borderColor = {
    warning: 'border-yellow-50',
    error: 'border-red-90',
    success: 'border-green-90',
  };

  const icon = {
    warning: 'fa-exclamation-circle text-yellow-50',
    error: 'fa-exclamation-triangle text-red-90',
    success: 'fa-check-circle text-green-90',
  };

  const buttonAlignment = confirmButtonLabel && cancelButtonLabel ? 'justify-between' : 'justify-center';

  if (isContentLoading) {
    return (
      <GeneralModal
        modalOverlayClassName={classNames(className, 'items-start bg-black-true-transparent-60 z-11')}
        modalWindowClassName={`w-${width}px px-8 pb-10 pt-8 border-4 border-solid ${borderColor[type]} rounded-xl`}
        shouldCloseByClickingOutside
        isCloseButtonVisible={false}
      >
        <div className="flex items-center justify-center h-180px">
          <LoadingBar />
        </div>
      </GeneralModal>
    )
  }

  return (
    <GeneralModal
      modalOverlayClassName={classNames(className, 'items-start bg-black-true-transparent-60 z-11')}
      modalWindowClassName={`w-${width}px px-8 pb-10 pt-8 border-4 border-solid ${borderColor[type]} rounded-xl`}
      shouldCloseByClickingOutside
      isCloseButtonVisible={false}
    >
      <div className="flex items-center">
        <div>
          <i className={`fas ${icon[type]} text-5xl`}></i>
        </div>
        <div className="ml-5">
          {title && <p className="title font-pt-sans font-bold text-black text-lg leading-lg m-0">{title}</p>}
        </div>
      </div>
      <div className="flex flex-col items-start pt-5 pb-8">
        {message && <p className="message text-base text-black font-pt-sans leading-md m-0">{message}</p>}
        {extraMessage && <p className="message text-base text-black font-pt-sans leading-md m-0">{extraMessage}</p>}
      </div>

      <div className={`flex ${buttonAlignment} ${buttonsWrapperClassname}`}>
        {confirmButtonLabel && onConfirm && (
          <FixedButton
            className="confirm"
            text={confirmButtonLabel}
            type="primary"
            onClick={onConfirm}
            isLoading={isConfirmLoading}
            disabled={isConfirmLoading || isCancelLoading}
          />
        )}
        {cancelButtonLabel && onCancel && (
          <FixedButton
            className="cancel"
            text={cancelButtonLabel}
            type="secondary"
            onClick={onCancel}
            disabled={isConfirmLoading || isCancelLoading}
          />
        )}
      </div>
    </GeneralModal>
  );
};

export default ConfirmationModal;
