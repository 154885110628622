import React from 'react';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import classnames from 'classnames';

interface ITermsAndConditionsProps {
  className?: string;
}

export const TermsAndConditions: React.FC<ITermsAndConditionsProps> = ({ className = '' }) => {
  const { dynamicParameters } = useDynamicParameters();
  return (
    <a
      className={classnames('terms-and-conditions', className)}
      href={dynamicParameters.BOOKING_TERMS_URL}
      rel="noopener noreferrer"
      target="_blank"
    >
      Terms and Conditions
    </a>
  );
};
