import React, { useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { useDispatch, useSelector } from 'react-redux';
import { Table as TableComponent } from 'ui/Table';
import Checkbox from 'ui/Checkbox';
import SingleDateInput from 'pureUi/SingleDateInput';
import { ESortOrder } from 'store/common/types';
import { formatDate, formatDateDisplay } from 'utils';

import {
  requestSelector,
  sortFieldSelector,
  sortDirectionSelector,
  draftListSelector,
} from '../../store/modules/credentialsInfo/selectors';

import { setSortFieldAction, setSortDirectionAction, setSentAction } from '../../store/modules/credentialsInfo/actions';

const StyledSingleDateInput = styled(SingleDateInput)`
  .calendars {
    right: 0;
  }
`;

export const Table: React.FC = () => {
  const request = useSelector(requestSelector);
  const loading = request.get === ENetworkRequestStatus.PENDING;

  const sortField = useSelector(sortFieldSelector);
  const sortDirection = useSelector(sortDirectionSelector);
  const list = useSelector(draftListSelector);

  const columns = useMemo(
    () => [
      {
        id: 'creation-date',
        label: 'Creation Date',
        width: '100px',
        className: 'pl-4',
        fieldToSortBy: 'creationDate',
      },
      {
        id: 'travel-agent',
        label: 'Travel Agent',
        width: '150px',
        fieldToSortBy: 'taName',
      },
      {
        id: 'email',
        label: 'Email',
        width: '150px',
        fieldToSortBy: 'email',
      },
      {
        id: 'company',
        label: 'Company',
        width: '150px',
        fieldToSortBy: 'company',
      },
      {
        id: 'status',
        label: 'Sent',
        width: '50px',
        className: 'pl-4',
      },
      {
        id: 'date',
        label: 'Date',
        width: '150px',
      },
    ],
    []
  );

  const rowItemClassName = 'text-ellipsis overflow-hidden whitespace-nowrap';

  const dispatch = useDispatch();

  const rows = useMemo(
    () =>
      (list || []).map(x => {
        const sent = (
          <Checkbox
            checked={x.sent}
            onChange={e => {
              const { checked } = e.currentTarget;
              dispatch(setSentAction(x.uuid, checked ? formatDate(new Date()) : undefined));
            }}
          />
        );

        const dateSent = x.sent ? (
          <StyledSingleDateInput
            value={x.date ? new Date(x.date) : null}
            onChange={value => {
              dispatch(setSentAction(x.uuid, formatDate(value)));
            }}
            enablePastDates
          />
        ) : null;

        return {
          id: x.uuid,
          cells: [
            {
              id: 'creation-date',
              title: x.creationDate,
              value: formatDateDisplay(x.creationDate),
              className: `pl-7 ${rowItemClassName}`,
            },
            {
              id: 'travel-agent',
              title: x.taName,
              value: x.taName,
              className: rowItemClassName,
            },
            {
              id: 'email',
              title: x.email,
              value: x.email,
              className: rowItemClassName,
            },
            {
              id: 'company',
              title: x.company,
              value: x.company,
              className: rowItemClassName,
            },
            {
              id: 'status',
              title: `${x.sent}`,
              value: sent,
              className: `pl-7 ${rowItemClassName}`,
            },
            {
              id: 'date',
              title: x.date || '',
              value: dateSent,
              className: rowItemClassName,
            },
          ],
        };
      }),
    [list]
  );

  const handleSort = useCallback(
    (field, direction) => {
      dispatch(setSortFieldAction(field));
      dispatch(setSortDirectionAction(direction));
    },
    [dispatch]
  );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      sortBy={sortField}
      sortOrder={(sortDirection as unknown) as ESortOrder}
      onSort={handleSort}
      messageWhenEmpty="No data available."
      loading={loading}
    />
  );
};
