import React from 'react';
import { IHeadlineLineItemBreakdownAccommodationLineItem } from 'services/BookingManagerApi';
import { formatDateTimeIncludeTimezoneDisplay } from 'utils/date';
import { SlideToggle } from '../SlideToggle';
import { Select } from '../Select';
import { isAfter } from 'date-fns';

export interface IBreakdownHoldBarProps {
  accommodationIndex: number;
  hold?: IHeadlineLineItemBreakdownAccommodationLineItem['hold'];
  setBreakdownHold: (accommodationIndex: number, hold?: IHeadlineLineItemBreakdownAccommodationLineItem['hold']) => {};
}

export const BreakdownHoldBar = (props: IBreakdownHoldBarProps) => {
  const { isActive, holdHours, expires } = props.hold || { isActive: false };

  const hasExpired = Boolean(expires && isAfter(new Date(), new Date(expires)));

  const isChecked = (isActive === true && hasExpired === false) || false;

  return (
    <div className="breakdown-hold-bar flex justify-between bg-brown-10">
      <div className="toggle-and-dropdown flex w-4/6">
        <SlideToggle
          id={`slide-toggle-${props.accommodationIndex}`}
          iconClassName="fas fa-thumbtack"
          additionalClassName="toggle m-4 ml-5"
          disableToggling={hasExpired || (isActive === true && expires == null && holdHours == null)}
          checked={isChecked}
          labelClassName={!isChecked ? 'bg-brown-60' : 'bg-teal-40'}
          buttonClassName={!isChecked ? 'bg-brown-100' : 'bg-teal-100'}
          onActivate={() => {
            props.setBreakdownHold(props.accommodationIndex, { isActive: true, holdHours: 24 });
          }}
          onDeactivate={() => {
            props.setBreakdownHold(props.accommodationIndex, { isActive: false });
          }}
        />

        {hasExpired === false && (
          <React.Fragment>
            {(isActive === true && holdHours == null && expires == null && (
              <span className="info permanently-held block text-green-100 justify-self-center self-center">
                Accommodation is permanently held
              </span>
            )) ||
              (holdHours != null && expires == null && holdHours >= 0 && (
                <div className="hold-duration-selector flex items-center">
                  <span className="block text-green-100 mr-10">Accommodation Will Be Held</span>
                  <span className="block mr-2">Hold for:</span>
                  <Select
                    value={String(holdHours)}
                    options={[
                      {
                        label: '1h',
                        value: '1',
                      },
                      {
                        label: '2h',
                        value: '2',
                      },
                      {
                        label: '3h',
                        value: '3',
                      },
                      {
                        label: '4h',
                        value: '4',
                      },
                      {
                        label: '5h',
                        value: '5',
                      },
                      {
                        label: '6h',
                        value: '6',
                      },
                      {
                        label: '12h',
                        value: '12',
                      },
                      {
                        label: '24h',
                        value: '24',
                      },
                      {
                        label: '36h',
                        value: '36',
                      },
                      {
                        label: '72h',
                        value: '72',
                      },
                    ]}
                    onChange={event => {
                      props.setBreakdownHold(props.accommodationIndex, {
                        isActive: true,
                        holdHours: parseInt(event.target.value),
                      });
                    }}
                    className="min-w-100px"
                  />
                </div>
              )) ||
              (expires && (
                <span className="info held block text-teal-100 justify-self-center self-center font-bold">
                  Accommodation Held
                </span>
              )) || (
                <React.Fragment>
                  <span className="block text-black justify-self-center self-center mr-4">Hold Accommodation</span>
                  <span className="info available block text-green-100 justify-self-center self-center">Available</span>
                </React.Fragment>
              )}
          </React.Fragment>
        )}
      </div>

      {expires && hasExpired && (
        <React.Fragment>
          <span className="info expired block justify-self-center self-center mr-4">
            Expired: {formatDateTimeIncludeTimezoneDisplay(expires)}
          </span>
        </React.Fragment>
      )}
      {expires && !hasExpired && (
        <React.Fragment>
          <span className="info expires block justify-self-center self-center mr-4">
            Expires: {formatDateTimeIncludeTimezoneDisplay(expires)}
          </span>
        </React.Fragment>
      )}
    </div>
  );
};
