import React from 'react';
import classNames from 'classnames';
import { formatPriceCents, formatDateDisplay } from 'utils';
import { IFinanceDocument, IFinanceDocumentBalance } from 'store/modules/bookingManager/subdomains/finance/types';
import { isPositiveFinanceRow, isNegativeFinanceRow } from 'store/modules/bookingManager/subdomains/finance/utils';
import { EBookingManageRoutes } from 'containers/BookingManager/routes';
import SectionTitle from 'ui/SectionTitle';
import { find } from 'lodash-es';

export interface FinanceOverviewProps {
  className?: string;
  isLoading: boolean;
  error?: string | null;
  financeDocument: IFinanceDocument;
  financeDocumentBalance: IFinanceDocumentBalance;
  currencySymbol: string;
}

const noData = (props: FinanceOverviewProps) => props.isLoading || props.error || !props.financeDocument.rows.length;

const Header = (props: FinanceOverviewProps) => (
  <div className="header flex px-5 py-3">
    <SectionTitle className="w-1/4">Finance</SectionTitle>
    {!noData(props) && (
      <>
        <div className="credit text-left font-normal uppercase text-xs text-gray-100 w-1/4">Reference</div>
        <div className="credit text-right font-normal uppercase text-xs text-gray-100 w-1/5">Invoice</div>
        <div className="credit text-right font-normal uppercase text-xs text-gray-100 w-1/10"></div>
        <div className="debit text-right font-normal uppercase text-xs text-gray-100 w-1/5">Payment</div>
      </>
    )}
  </div>
);

const Loading = () => (
  <tr>
    <td className="py-3" colSpan={3}>
      <span className="info loading">Loading...</span>
    </td>
  </tr>
);

const Error = props => (
  <tr>
    <td className="py-3" colSpan={3}>
      <span className="info error text-red-100">{props.children}</span>
    </td>
  </tr>
);

const Empty = () => (
  <tr>
    <td className="py-3" colSpan={3}>
      <span className="info empty italic text-gray-80">No rows</span>
    </td>
  </tr>
);

const Content = ({
  financeDocument,
  financeDocumentBalance,
  currencySymbol,
}: {
  financeDocument: IFinanceDocument;
  financeDocumentBalance: IFinanceDocumentBalance;
  currencySymbol: string;
}) => {
  const financeRows = [...financeDocument.rows];
  financeRows.sort((a, b) => (a.date <= b.date ? -1 : 1));

  return (
    <>
      {financeRows.map((item, idx) => (
        <tr key={`${item.date}${idx}`} className="border-solid border-b border-gray-40 last-of-type:border-0">
          <td className="date py-3 w-1/4">{formatDateDisplay(item.date)}</td>
          <td className="date py-3 w-1/4">TODO Reference</td>
          <td className="debit py-3 text-right w-1/5">
            {isNegativeFinanceRow(item) && item.amountCents != undefined && (
              <React.Fragment>
                {currencySymbol}
                {formatPriceCents(item.amountCents)}
              </React.Fragment>
            )}
          </td>
          <td className="text-left w-1/10 px-2">
          </td>
          <td className="credit py-3 text-right w-1/5">
            {isPositiveFinanceRow(item) && item.amountCents != undefined && (
              <React.Fragment>
                {currencySymbol}
                {formatPriceCents(item.amountCents)}
              </React.Fragment>
            )}
          </td>
        </tr>
      ))}
    </>
  );
};

type State = {
  cond: (props: FinanceOverviewProps) => boolean;
  render: (props: FinanceOverviewProps) => any;
};

const states: State[] = [
  {
    cond: (props: FinanceOverviewProps) => props.isLoading,
    render: (props: FinanceOverviewProps) => <Loading />,
  },
  {
    cond: (props: FinanceOverviewProps) => !!props.error,
    render: (props: FinanceOverviewProps) => <Error>{props.error}</Error>,
  },
  {
    cond: (props: FinanceOverviewProps) => !props.financeDocument.rows.length,
    render: (props: FinanceOverviewProps) => <Empty />,
  },
  {
    cond: (props: FinanceOverviewProps) => !!props.financeDocument,
    render: (props: FinanceOverviewProps) => (
      <Content
        currencySymbol={props.currencySymbol}
        financeDocument={props.financeDocument}
        financeDocumentBalance={props.financeDocumentBalance}
      />
    ),
  },
  {
    cond: props => true,
    render: props => null,
  },
];

const Main = (props: FinanceOverviewProps) => {
  const state: State | undefined = find(states, item => item.cond(props));
  if (!state) {
    return null;
  }
  return (
    <div className="main px-5 border-solid border-t border-b border-gray-40">
      <table className="border-collapse w-full table-auto font-pt-sans">
        <tbody>{state.render(props)}</tbody>
      </table>
    </div>
  );
};

const Footer = (props: FinanceOverviewProps) => (
  <div className="footer bg-brown-10 px-5 py-3 flex justify-between">
    <a href={EBookingManageRoutes.Finance} className="view text-brown-120 hover:text-brown-140 font-bold underline">
      View
    </a>
    {!noData(props) && (
      <div className="flex justify-between w-1/2">
        <div className="text-sm text-red-100">Outstanding balance</div>
        <div className="total font-bold">
          {props.currencySymbol}
          {formatPriceCents(props.financeDocumentBalance.totalOutstandingCents)}
        </div>
      </div>
    )}
  </div>
);

export const FinanceOverview = (props: FinanceOverviewProps) => {
  const { className } = props;

  return (
    <div
      className={classNames(
        'finance-overview',
        className,
        'bg-white-true border border-solid border-gray-40 shadow-pe1 font-pt-sans text-black'
      )}
    >
      <Header {...props} />
      <Main {...props} />
      <Footer {...props} />
    </div>
  );
};
