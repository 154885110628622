import { call, takeLatest, put } from 'redux-saga/effects';
import { enqueueNotification } from 'store/modules/ui';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';
import { LIST_TASK_COMMENTS_REQUEST, ListTaskCommentRequestAction, listTaskCommentsFailureAction, listTaskCommentsSuccessAction } from '../actions';

export function* listTaskCommentSaga(action: ListTaskCommentRequestAction) {
  try {
    const taskManagementApi = makeTaskManagementApi();
    const taskCommentsResponse = yield call(taskManagementApi.listTaskComments, action.taskId); 
    yield put(listTaskCommentsSuccessAction(taskCommentsResponse.data));
  } catch (e) {
    yield put(listTaskCommentsFailureAction(e));
    yield put(enqueueNotification({ message: 'Unable to get comments', options: { variant: 'error' } }));
  }
}

export function* watchListTaskCommentsSaga() {
  yield takeLatest(
    [LIST_TASK_COMMENTS_REQUEST],
    listTaskCommentSaga
  );
}
