export enum ELiveRatesExternalSystem {
  DERBYSOFT = 'derbysoft',
  ALLOTZ = 'allotz',
  ILLUSIONS = 'illusions',
  JUMEIRAH = 'jumeirah',
  MOCK = 'mock',
}

export interface IGetLiveRatesListingResponse {
  data: {
    stays: ILiveRatesListingItem[];
  };
  meta: {
    total: number;
  };
}

export interface ILiveRatesListingItem {
  uuid: string;
  name: string;
  externalSystem: ELiveRatesExternalSystem;
  externalHotelId: string;
  internalRoomsCount: number;
  externalRoomsCount: number|string;
  mappedRoomsCount: number;
}

export interface ILiveRatesListingFilter {
  nameFilter: string | undefined;
  externalSystem: ELiveRatesExternalSystem | undefined;
  page: number;
  page_total?: number;
  page_size: number;
  order_by: 'name' | 'externalSystem' | 'externalHotelUuid';
  order_type: 'asc' | 'desc';
}

export enum ELiveRatesSettingsListFilterType {
  MAPPED = 'mapped',
  NOT_MAPPED = 'not_mapped',
  BOTH = 'both',
}

export interface ILiveRatesSettingsInternalRoomsItem {
  uuid: string;
  name: string;
  externalId: string | undefined;
  isMapped: boolean;
  isAmended: boolean;
}

export interface ILiveRatesSettingsInternalRoomItemError {
  uuid: string;
  errorMessage: string;
}

export interface ILiveRatesSettingsExternalRoomsItem {
  // uuid: string;
  name: string;
  externalId: string;
  isMapped: boolean | undefined;
}

export interface ILiveRatesHotel {
  uuid: string;
  name: string;
}

export interface ILiveRatesMappableStay {
  name: string;
  externalHotelId: string;
  externalSystem: ELiveRatesExternalSystem;
  externalSupplierId?: string;
}
