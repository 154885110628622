import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';
import { List } from './List';
import { Create } from './Create';
import { Edit } from './Edit';

export interface ContentsAdminRoutingProps extends RouteComponentProps {}

export const ContentsAdminRouting = (props: ContentsAdminRoutingProps) => {
  return (
    <Switch>
      <Route path={`${props.match.path}/`} component={List} exact />
      <Route path={`${props.match.path}/create`} component={Create} exact />
      <Route path={`${props.match.path}/:contentEntityUuid/edit`} component={Edit} />
    </Switch>
  );
};

export const RateBreakRouting = compose(withRouter)(ContentsAdminRouting);
