import { rootSagaFactory } from '../importer/sagas';
import { EImportEntity } from '../importer/model';

import { ancillaryTransfersRatesImportDomainSelector } from './selectors';

export default rootSagaFactory({
  entity: EImportEntity.ANCILLARY_TRANSFERS_RATES,
  domainSelector: ancillaryTransfersRatesImportDomainSelector,

  importApiCall: (backendApi, bookingManagerApi) => bookingManagerApi.postImportAncillaryTransfersRates(),

  importStatusApiCall: async (backendApi, bookingManagerApi) => {
    const result = await bookingManagerApi.getImportAncillaryTransfersRatesStatus();

    // massage the result into the expected shape
    return {
      ...result,
      data: {
        ...result.data,
        meta: {
          workbookId: result.data.workbookId,
        },
        data: {
          ...result.data,
        },
      },
    };
  },
});
