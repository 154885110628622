import { createSelector } from 'reselect';
import { ISettingsSubdomain } from './model';
import { settingsSubdomainSelector } from '../../selectors';

export const networkSelector = createSelector(
  settingsSubdomainSelector,
  (subdomain: ISettingsSubdomain) => subdomain.network
);

export const taUsersSelector = createSelector(
  settingsSubdomainSelector,
  (subdomain: ISettingsSubdomain) => subdomain.taUsers
);

export const managedBySelector = createSelector(
  settingsSubdomainSelector,
  (subdomain: ISettingsSubdomain) => subdomain.managedBy
);

export const selectedTaCompanyUuidSelector = createSelector(
  settingsSubdomainSelector,
  (subdomain: ISettingsSubdomain) => subdomain.selectedTaCompanyUuid
);

export const selectedTaUserUuidSelector = createSelector(
  settingsSubdomainSelector,
  (subdomain: ISettingsSubdomain) => subdomain.selectedTaUserUuid
);

export const hasEditedWithoutSavingSelector = createSelector(
  settingsSubdomainSelector,
  (subdomain: ISettingsSubdomain) => subdomain.hasEditedWithoutSaving
);

export const isTaMovementsModalOpen = createSelector(
  settingsSubdomainSelector,
  (subdomain: ISettingsSubdomain) => subdomain.taMovementsModalOpen
);
