import classNames from 'classnames';
import React from 'react';

export interface IBackLink {
  primaryText: string;
  secondaryText?: string;
  loadingText?: string;
  primaryClassName?: string;
  secondaryClassName?: string;
  loadingClassName?: string;
  secondaryCondition?: boolean;
  loadingCondition?: boolean;
}

export const PageTitle: React.FC<IBackLink> = ({ 
  primaryText, secondaryText, loadingText, primaryClassName = '', secondaryClassName = '', loadingClassName = '', secondaryCondition, loadingCondition
}) => {
  return (
    <div className="page-title font-noe-display flex items-baseline">
      <h1 className={classNames('primary-title font-noe-display text-4xl leading-46px text-black font-normal m-0', primaryClassName)}>
        {primaryText}
      </h1>
      {secondaryCondition && (
        <h2 className={classNames('secondary-title font-noe-display text-26px leading-33px text-black font-normal m-0 ml-5px', secondaryClassName)}>
          &nbsp;{secondaryText}
        </h2>
      )}
      {loadingCondition && (
        <h2 className={classNames('secondary-title font-noe-display text-26px leading-33px text-black font-normal m-0 ml-5px', loadingClassName)}>
          &nbsp;{loadingText}
        </h2>
      )}
    </div>
  );
}
