import { BookingConfirmationAction } from '../../actions';
import { initialState, PaymentSubdomain } from '../../model';
import * as Actions from './actions';

export const paymentReducer = (state: PaymentSubdomain = initialState.payment, action: BookingConfirmationAction): PaymentSubdomain => {
  switch (action.type) {
    case Actions.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.value,
      };
    
    default:
      return state;

  }
};
