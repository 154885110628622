import { IBootstrapModule } from './model';
import { createSelector } from 'reselect';
import { groupBy } from 'ramda';

export const bootstrapDomainSelector = (state: any) => state.bootstrap as IBootstrapModule;

export const getBootstrapCountriesSelector = createSelector(bootstrapDomainSelector, domain => domain.countries);

export const bootstrapCountriesByRegionSelector = createSelector(getBootstrapCountriesSelector, countries =>
  groupBy(country => country.region, countries || [])
);

export const getBootstrapHotelsSelector = createSelector(bootstrapDomainSelector, domain => domain.hotels);
export const getBootstrapHotelsCountSelector = createSelector(bootstrapDomainSelector, domain => domain.hotels.length);

export const getBootstrapExtraPersonSupplementProductSelector = createSelector(
  bootstrapDomainSelector,
  domain => domain.extraPersonSupplementProduct
);

export const getDynamicParametersSelector = createSelector(bootstrapDomainSelector, domain => domain.dynamicParameters);
export const getMainCompanySelector = createSelector(bootstrapDomainSelector, domain => domain.mainCompany);
export const getMainCompanyLogoSelector = createSelector(bootstrapDomainSelector, domain => domain.mainCompanyLogo);
export const getBootstrapErrorSelector = createSelector(bootstrapDomainSelector, domain => domain.error);
