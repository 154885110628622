import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';
import { AncillaryActivitiesImporterContainer } from './importer';

export interface AncillaryActivitiesRoutingProps extends RouteComponentProps {}

export const AncillaryActivitiesRouting = (props: AncillaryActivitiesRoutingProps) => {
  return (
    <Switch>
      <Route path={[`${props.match.path}/`, `${props.match.path}`]} component={AncillaryActivitiesImporterContainer} />
    </Switch>
  );
};

export const RateBreakRouting = compose(withRouter)(AncillaryActivitiesRouting);
