
export interface IGuest {
  isLeadGuest: boolean;
  uuid?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  passportNo?: string;
  arrivalDate?: string;
  ticketClassArrival?: string;
  arrivalAirportId?: string;
  arrivalAirportIataCode?: string;
  arrivalAirportName?: string;
  arrivalTerminal?: string;
  passportExpirationDate?: string;
  arrivalFlightNo?: string;
  departureDate?: string;
  ticketClassDeparture?: string;
  departureAirportId?: string;
  departureAirportIataCode?: string;
  departureAirportName?: string;
  departureTerminal?: string;
  departureFlightNo?: string;
  arrivalTime?: string;
  departureTime?: string;
  mobilePhone?: string;
  mobilePhoneCode?: string;
  comment?: string;

  arrivalAirlineName?: string | null;
  arrivalAirlineIataCode?: string | null;
  departureAirlineName?: string | null;
  departureAirlineIataCode?: string | null;

  // we need custom to handle the "Other" requirement of the checkbox
  // these fields don't exist on the API, we just use them to populate the
  // none-custom version of these fields when we POST data
  customArrivalAirlineName?: string;
  customArrivalAirlineIataCode?: string;
  customDepartureAirlineName?: string;
  customDepartureAirlineIataCode?: string;

  country?: string;
  arrivalCIPBookingNumber?: string | null;
  departureCIPBookingNumber?: string | null;
  guestType?: 'Mother' | 'Father' | 'Child' | 'Friend' | 'Security' | 'Nanny' | 'Chef' | 'Other Staff' | null;
  manualAge?: number | null;
}

export interface IAirlineListItem {
  name: string;
  iataCode: string;
}

export const CONST_AIRLINE_OTHER_LABEL = 'OTHER';
export const CONST_AIRLINE_OTHER_VALUE = '___other';
