import React, { useCallback, useEffect, useState } from 'react';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { AvailabilityTag, EAvailabilityTagStatus } from 'ui/AvailabilityTag/AvailabilityTag';

interface IAddAccommodationModalAvailabilityProps {
  availability: string;
}

export const AddAccommodationModalAvailability: React.FC<IAddAccommodationModalAvailabilityProps> = ({ availability }) => {
  const staticAvailabilityTextMap = {
    'Available': EAvailabilityTagStatus.AVAILABLE,
    'On Request': EAvailabilityTagStatus.REQUEST,
    'Unavailable': EAvailabilityTagStatus.UNAVAILABLE,
    'Partially Available': EAvailabilityTagStatus.PLAIN,
  };

  if (!(availability in staticAvailabilityTextMap)) {
    return null;
  }

  return (
    <div className="ml-[20px] min-w-[135px] text-right">
      <AvailabilityTag availabilityStatus={staticAvailabilityTextMap[availability]} className="bam-availability-tag o:font-pt-sans">
        {String(availability).toUpperCase()}
      </AvailabilityTag>
    </div>
  )
}
