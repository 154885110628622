import React from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import * as Inputs from '../Inputs';
import FluidButton from 'ui/FluidButton';
import {
  IAccommodationProduct,
  IFineProduct,
  IGroundServiceProduct,
  IHotel,
  IMealPlanProduct,
  ISeason,
  ISeasonalProductAddonRate,
  ISupplementProduct,
  ITransferProduct,
} from 'services/BackendApi';
import { Room } from '../child-product-forms/Room';
import { produce } from 'immer';
import * as _ from 'lodash-es';
import { MealPlan } from '../child-product-forms/MealPlan';
import { Transfer } from '../child-product-forms/Transfer';
import { Supplement } from '../child-product-forms/Supplement';
import { GroundService } from '../child-product-forms/GroundService';
import { Fine } from '../child-product-forms/Fine';
import { SeasonalProductAddonRate } from '../SeasonalProductAddonRate';
import { validateSeasonalProductAddonRate } from 'containers/HotelAdmin/validation';
// import { produce } from 'immer';
import { enqueueNotification } from 'store/modules/ui';
import { useDispatch } from 'react-redux';

export const CreateAddonRateModal = ({
  onConfirm,
  onClose,
  hotel,
  mealPlans,
}: {
  onConfirm: (data: Partial<ISeasonalProductAddonRate>) => void;
  onClose: () => void;
  hotel: IHotel;
  mealPlans: IMealPlanProduct[];
}) => {
  const dispatch = useDispatch();

  const [draftAddonRate, setDraftAddonRate] = React.useState<Partial<ISeasonalProductAddonRate>>({
    isOnRequest: false,
  });

  const [selectedMealPlanUuid, setSelectedMealPlanUuid] = React.useState<string | null>(
    mealPlans.length > 0 ? mealPlans[0].uuid : null
  );

  const selectedMealPlan = mealPlans.find(item => item.uuid === selectedMealPlanUuid);

  return (
    <GeneralModal
      onClose={() => onClose()}
      modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-scroll font-pt-sans"
      shouldCloseByClickingOutside={false}
    >
      <div className="flex flex-col space-y-4">
        <h1 className="font-normal font-noe-display text-[36px] leading-46px">Create new Meal Plan Rate</h1>
        <Inputs.Select
          label="Meal Plan"
          value={selectedMealPlanUuid || ''}
          options={mealPlans.map(item => {
            return {
              value: item.uuid,
              label: item.name,
            };
          })}
          onChange={val => {
            setSelectedMealPlanUuid(val);
          }}
        />

        <Inputs.Checkbox
          label="Is on request"
          value={draftAddonRate.isOnRequest || false}
          onChange={val => {
            setDraftAddonRate({
              ...produce(draftAddonRate, _draft => {
                _.set(_draft, 'isOnRequest', val);
              }),
            });
          }}
        />

        {draftAddonRate.isOnRequest === false && selectedMealPlan && (
          <div>
            <SeasonalProductAddonRate
              addonRate={draftAddonRate}
              ageBrackets={selectedMealPlan.options.ages}
              onUpdate={(field, val) => {
                setDraftAddonRate({
                  ...produce(draftAddonRate, _draft => {
                    _.set(_draft, field, val);
                  }),
                });
              }}
            />
          </div>
        )}

        <FluidButton
          type="primary"
          className="w-[275px] self-end"
          onClick={() => {
            const spar = {
              ...draftAddonRate,
              productUuid: selectedMealPlan?.uuid,
            };
            const validation = validateSeasonalProductAddonRate(spar, selectedMealPlan!.options.ages);
            if (validation.isValid === false) {
              dispatch(
                enqueueNotification({
                  message: `Seasonal product addon rate is not valid: ${validation.message}`,
                  options: { variant: 'warning' },
                })
              );
              return;
            }
            onConfirm(spar);
          }}
        >
          Create Meal Plan Rate
        </FluidButton>
      </div>
    </GeneralModal>
  );
};
