import React, { FC, HTMLProps, RefObject, CSSProperties } from 'react';
import classnames from 'classnames';

export interface BaseTooltipPosition {
  x: number;
  y: number;
}

export interface IBaseTooltipProps extends HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  position?: BaseTooltipPosition;
  width?: string;
  wrapperClassName?: string;
  tooltipRef?: RefObject<HTMLDivElement>;
  style?: CSSProperties;
}

export const BaseTooltip: FC<IBaseTooltipProps> = ({
  position,
  children,
  width = 'auto',
  wrapperClassName,
  tooltipRef,
  style,
}) => {
  const styles: CSSProperties = { width, ...style };
  if (position) {
    styles.top = `${position.y}px`;
    styles.left = `${position.x}px`;
  }

  const classes = classnames('fixed', wrapperClassName);

  return (
    <div style={styles} className={classes} ref={tooltipRef}>
      {children}
    </div>
  );
};
