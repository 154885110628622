import { createSelector } from 'reselect';
import { externalIdsSubdomainSelector } from '../../selectors';

export const requestSelector = createSelector(
  externalIdsSubdomainSelector,
  (subdomain) => subdomain.request
);

export const storedSelector = createSelector(
  externalIdsSubdomainSelector,
  (subdomain) => subdomain.stored
);

export const editingSelector = createSelector(
  externalIdsSubdomainSelector,
  (subdomain) => subdomain.editing
);

export const updatePayloadSelector = createSelector(
  editingSelector,
  (editing) => ({ travelPartnerRef: editing.travelPartnerRef || null })
);
