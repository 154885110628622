import React from 'react';

import { IIconProps } from 'ui/CircleIconButton';
import { theme } from '../../../../tailwind.config';
export const PdfIcon: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors['brown-100'], background = 'transparent' }) => (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ backgroundColor: background }}
    >
      <path
        d="M13 15.5H1C0.801088 15.5 0.610322 15.421 0.46967 15.2803C0.329018 15.1397 0.25 14.9489 0.25 14.75V1.25C0.25 1.05109 0.329018 0.860322 0.46967 0.71967C0.610322 0.579018 0.801088 0.5 1 0.5H13C13.1989 0.5 13.3897 0.579018 13.5303 0.71967C13.671 0.860322 13.75 1.05109 13.75 1.25V14.75C13.75 14.9489 13.671 15.1397 13.5303 15.2803C13.3897 15.421 13.1989 15.5 13 15.5ZM12.25 14V2H1.75V14H12.25ZM4 4.25H10V5.75H4V4.25ZM4 7.25H10V8.75H4V7.25ZM4 10.25H7.75V11.75H4V10.25Z"
        fill={fill}
      />
    </svg>
  )
);

export default PdfIcon;
