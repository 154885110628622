import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const Warning: React.FC<IIconProps> = React.memo(({ width = 28, height = 28, icon = 'yellow-advert' }) => (
  <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 14C28 6.32258 21.6774 0 14 0C6.26613 0 0 6.32258 0 14C0 21.7339 6.26613 28 14 28C21.6774 28 28 21.7339 28 14ZM14 16.8226C15.4113 16.8226 16.5968 18.0081 16.5968 19.4194C16.5968 20.8871 15.4113 22.0161 14 22.0161C12.5323 22.0161 11.4032 20.8871 11.4032 19.4194C11.4032 18.0081 12.5323 16.8226 14 16.8226ZM11.5161 7.50806C11.4597 7.1129 11.7984 6.77419 12.1935 6.77419H15.75C16.1452 6.77419 16.4839 7.1129 16.4274 7.50806L16.0323 15.1855C15.9758 15.5806 15.6935 15.8065 15.3548 15.8065H12.5887C12.25 15.8065 11.9677 15.5806 11.9113 15.1855L11.5161 7.50806Z"
      fill={theme.colors[icon]}
    />
  </svg>
));

export default Warning;
