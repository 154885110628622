import * as uuid from 'uuid';
import { useCallback, useEffect, useState } from 'react';

type GetElemAndParentsFn = (elem: any, parents?: any[]) => any[];
const getElemAndParents: GetElemAndParentsFn = (elem, parents = []) => {
  const parent = elem.parentNode;

  if (parent !== null) {
    return getElemAndParents(parent, [...parents, parent]);
  }

  return parents;
};

export interface UseHideOnClickOutsideReturnType {
  instanceClassname: string;
  id: string;
}

export const useHideOnClickOutside = (setElementVisible: (visible: boolean) => void, elementClassname: string, notifyOnClickOutside?: () => void): UseHideOnClickOutsideReturnType => {
  const [id] = useState<string>(uuid.v4());
  const instanceClassname = `${elementClassname}-${id}`;
  
  const handleDocumentMouseDown = useCallback(
    event => {
      const elemAndParents =
        typeof event.composedPath === 'function' ? event.composedPath() : getElemAndParents(event.target); // workaround for edge version that do not support composedPath OWA-3124

      const containsSelect = elemAndParents.some(domElement => {
        if (!domElement.classList) {
          return false;
        }
        return domElement.classList.contains(instanceClassname);
      });

      if (!containsSelect) {
        setElementVisible(false);
        notifyOnClickOutside && notifyOnClickOutside();
      }
    },
    [instanceClassname, setElementVisible, notifyOnClickOutside]
  );

  // on mount
  // wire up the event handlers
  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentMouseDown, true);
    return () => {
      document.removeEventListener('mousedown', handleDocumentMouseDown, true);
    };
  }, [handleDocumentMouseDown]);

  return { instanceClassname, id };
};
