import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Redirect, RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { ProposalList } from './ProposalList';
import { ProposalDetailsPage } from './ProposalDetailsPage';
import { ProposalGeneratePage } from './ProposalGeneratePage';
import { ProposalEditorPage } from './ProposalEditorPage';

export interface ProposalsRoutingProps extends RouteComponentProps {}

export const ProposalsRoutingComponent = (props: ProposalsRoutingProps) => {
  return (
    <Switch>
      <Route exact path={props.match.path} component={ProposalList} />
      <Route exact path={`${props.match.path}/:proposalUuid/editor`} component={ProposalEditorPage} />
      <Route exact path={`${props.match.path}/:proposalSelectionUuid/generate`} component={ProposalGeneratePage} />
      <Route exact path={`${props.match.path}/:proposalSelectionUuid/:stage`} component={ProposalDetailsPage} />
      <Redirect from={`${props.match.path}/:proposalSelectionUuid/`} to={`${props.match.path}/:proposalSelectionUuid/edit`} />
    </Switch>
  );
};

export const ProposalsRouting = compose(withRouter)(ProposalsRoutingComponent);
