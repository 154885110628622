import { sortBy, partition, concat } from 'lodash-es';
import { createSelector } from 'reselect';
import { ISalesRepresentative, ITravelAgentFilter } from 'services/BackendApi';
import { ITravelAgentListDomain } from './model';

const moveToFront = <T>(xs: T[], p: (T) => boolean): T[] => concat(...partition(xs, x => p(x)));

export const domainSelector = (state: any): ITravelAgentListDomain => state.travelAgentList;

export const filterSelector = createSelector(domainSelector, (domain: ITravelAgentListDomain) => domain.filter);
export const sortSelector = createSelector(domainSelector, (domain: ITravelAgentListDomain) => domain.sort);

export const listSelector = createSelector(
  domainSelector,
  filterSelector,
  (domain: ITravelAgentListDomain, filter: ITravelAgentFilter) =>
    filter.salesRepUuid
      ? domain.list?.map(x => ({
          ...x,
          assignedSalesRepresentatives: moveToFront(
            x.assignedSalesRepresentatives,
            y => y.uuid === filter.salesRepUuid
          ),
        }))
      : domain.list
);

export const paginationSelector = createSelector(domainSelector, (domain: ITravelAgentListDomain) => domain.pagination);

export const toDeleteSelector = createSelector(domainSelector, (domain: ITravelAgentListDomain) => domain.selectedTravelAgent.toDelete);

export const salesRepsSelector = createSelector(domainSelector, (domain: ITravelAgentListDomain) => domain.salesReps);

export const salesRepsOrderedSelector = createSelector(salesRepsSelector, (xs: ISalesRepresentative[] | null) =>
  xs ? sortBy(xs, s => s.firstName.toLowerCase()) : xs
);

export const requestSelector = createSelector(domainSelector, (domain: ITravelAgentListDomain) => domain.request);

export const massAssignRequestSelector = createSelector(
  domainSelector,
  (domain: ITravelAgentListDomain) => domain.request.massAssign
);

export const massAssignModalVisibleSelector = createSelector(
  domainSelector,
  (domain: ITravelAgentListDomain) => domain.massAssign.massAssignModalVisible
);

export const massAssignConfirmModalVisibleSelector = createSelector(
  domainSelector,
  (domain: ITravelAgentListDomain) => domain.massAssign.massAssignConfirmModalVisible
);

export const massAssignModeSelector = createSelector(
  domainSelector,
  (domain: ITravelAgentListDomain) => domain.massAssign.massAssignMode
);

export const massAssignSrsSelector = createSelector(
  domainSelector,
  (domain: ITravelAgentListDomain) => domain.massAssign.selectedSrUuids
);

export const taHasBookingsSelector = createSelector(
  domainSelector,
  (domain: ITravelAgentListDomain) => domain.selectedTravelAgent.hasBookings
);
