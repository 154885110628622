import React, { CSSProperties, SVGProps, useCallback, useState } from 'react';

interface ISvgIconProps {
  IconComponent: React.FC<SVGProps<SVGSVGElement>>;
  className?: string;
  width?: string;
  height?: string;
  defaultFill?: string;
  hoverFill?: string;
  activeFill?: string;
  defaultBackground?: string;
  hoverBackground?: string;
  activeBackground?: string;
}

type IconProps = {
  background?: string;
  fill?: string;
  width?: string;
  height?: string;
};

export const SvgIcon: React.FC<ISvgIconProps> = ({
  IconComponent,
  defaultFill,
  hoverFill = defaultFill,
  activeFill = defaultFill,
  defaultBackground = 'transparent',
  hoverBackground = defaultBackground,
  activeBackground = defaultBackground,
  width,
  height,
  className = '',
}) => {
  const [fill, setFill] = useState(defaultFill);
  const [background, setBackground] = useState(defaultBackground);

  const handleMouseEnter = useCallback(() => {
    setFill(hoverFill);
    setBackground(hoverBackground);
  }, [hoverFill, hoverBackground]);

  const handleMouseLeave = useCallback(() => {
    setFill(defaultFill);
    setBackground(defaultBackground);
  }, [defaultFill, defaultBackground]);

  const handleMouseDown = useCallback(() => {
    setFill(activeFill);
    setBackground(activeBackground);
  }, [activeFill, activeBackground]);

  const handleMouseUp = useCallback(() => {
    setFill(hoverFill);
    setBackground(hoverBackground);
  }, [hoverFill, hoverBackground]);

  if (!IconComponent) {
    return null;
  }

  const iconProps: IconProps = {
    background,
    fill,
  };
  const divStyle: CSSProperties = {};
  if (width !== undefined) {
    iconProps.width = width;
    divStyle.maxWidth = width;
  }
  if (height !== undefined) {
    iconProps.height = height;
    divStyle.maxHeight = height;
  }

  return (
    <div
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      style={divStyle}
    >
      <IconComponent {...iconProps} />
    </div>
  );
};
