import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  getBookingTasksSuccessAction,
  getBookingTasksFailureAction,
  GET_BOOKING_TASKS_REQUEST,
} from '../actions';

import { bookingUuidSelector } from '../../../selectors';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';
import { ESortOrder } from 'store/common/types';
import { ETasksSortableKey, ISearchTasksResponse } from 'services/TaskManagementApi/types/TaskManagementResponse';
import { APITaskToFETask } from 'services/TaskManagementApi/TaskManagementUtils';

export function* getBookingTasksSaga() {
  try {
    const taskManagementApi = makeTaskManagementApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const options = {
      sort: {
        key: ETasksSortableKey.DEADLINE,
        order: ESortOrder.ASC,
      },
      filter: {
        bookingId: bookingUuid,
      },
      pagination: {
        limit: 9999,
        offset: 0,
      },
    };

    const apiTasks: AxiosResponse<ISearchTasksResponse> = yield call(
      taskManagementApi.getTasks,
      options
    );
    const tasks = apiTasks.data?.tasks.map(item => APITaskToFETask(item));

    yield put(getBookingTasksSuccessAction(tasks));
  } catch (e) {
    yield put(getBookingTasksFailureAction(e));
  }
}

export function* watchGetBookingTasksSaga() {
  yield takeLatest([GET_BOOKING_TASKS_REQUEST], getBookingTasksSaga);
}
