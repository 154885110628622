import React from 'react';
import { IDepositAccountSortableItems } from 'services/BookingManagerApi';
import classNames from 'classnames';

interface ITCLedgerTableProps {
  className?: string;
  children?: React.ReactNode;
}

export const TCLedgerTable: React.FC<ITCLedgerTableProps> = React.memo(({ className, children }) => (
  <>
    <div className={`${className} border border-solid border-gray-20 border-l-0 border-r-0`}>
      <table className="table table-xs table-fixed w-full border-collapse text-left font-pt-sans">{children}</table>
    </div>
  </>
));

export const renderSortIcon = (currentSortBy: string, currentSortOrder: string, newSortBy: string) => {
  if (newSortBy !== currentSortBy) {
    return (
      <span className="group-hover:text-gray-40 text-ivory fa-stack fa-lg w-20px ml-5px">
        <i className="fas fa-stack-1x fa-sort-up"></i>
        <i className="fas fa-stack-1x fa-sort-down"></i>
      </span>
    );
  }
  if (currentSortOrder === 'asc') {
    return (
      <span className="fa-stack fa-lg w-20px ml-5px">
        <i className="fas fa-stack-1x fa-sort-up text-gray-100"></i>
        <i className="fas fa-stack-1x fa-sort-down text-gray-40"></i>
      </span>
    );
  } else if (currentSortOrder === 'desc') {
    return (
      <span className="fa-stack fa-lg w-20px ml-5px">
        <i className="fas fa-stack-1x fa-sort-up text-gray-40"></i>
        <i className="fas fa-stack-1x fa-sort-down text-gray-100 "></i>
      </span>
    );
  }
};

interface IRenderHeaderSpan {
  label: string;
  currentSortBy: string;
  currentSortOrder: string;
  sortKey?: IDepositAccountSortableItems;
  labelClasses?: string;
  headerClasses?: string;
}

export const renderHeaderSpan = ({
  label,
  currentSortBy,
  currentSortOrder,
  labelClasses,
  headerClasses,
  sortKey,
}: IRenderHeaderSpan) => {
  const renderLabel = label => <span className={classNames('w-full', labelClasses ?? 'pl-2')}>{label}</span>;

  if (sortKey === undefined) {
    return <span className="group flex font-normal items-center justify-between h-8">{renderLabel(label)}</span>;
  }

  return (
    <span
      className={classNames(
        `group flex font-normal items-center justify-between h-8 hover:bg-gray-10 cursor-pointer rounded ${headerClasses}`,
        {
          'bg-gray-10': currentSortBy === sortKey,
        }
      )}
    >
      {renderLabel(label)}
      {renderSortIcon(currentSortBy, currentSortOrder, sortKey)}
    </span>
  );
};
