import { ISalesRepresentative } from 'services/BackendApi';

export const GET_LIST_REQUEST = 'travelAgentList/GET_LIST_REQUEST';
export const GET_LIST_SUCCESS = 'travelAgentList/GET_LIST_SUCCESS';
export const GET_LIST_FAILURE = 'travelAgentList/GET_LIST_FAILURE';

export type GetSRListRequestAction = ReturnType<typeof getSRListRequestAction>;
export const getSRListRequestAction = () => ({
  type: GET_LIST_REQUEST as typeof GET_LIST_REQUEST
});

export type GetSRListSuccessAction = ReturnType<typeof getSRListSuccessAction>;
export const getSRListSuccessAction = (srList: ISalesRepresentative[]) => ({
  type: GET_LIST_SUCCESS as typeof GET_LIST_SUCCESS,
  srList,
});

export type GetSRListFailureAction = ReturnType<typeof getSRListFailureAction>;
export const getSRListFailureAction = (error: string) => ({
  type: GET_LIST_FAILURE as typeof GET_LIST_FAILURE,
  error,
});

export type SRAction =
  | GetSRListRequestAction
  | GetSRListSuccessAction
  | GetSRListFailureAction
  ;
