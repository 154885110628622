import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  makeBackendApi,
  IProposalsListItem,
} from 'services/BackendApi';
import {
  getAvailableProposalsSuccessAction,
  getAvailableProposalsFailureAction,
  GET_AVAILABLE_PROPOSALS_REQUEST,
} from '../actions';
import { selectedTaSelector } from '../../agents';

export function* getAvailableProposalsSaga() {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
   const result: AxiosResponse<IProposalsListItem[]> = yield call(backendApi.getAvailableProposals);
   yield put(getAvailableProposalsSuccessAction(result.data));
  } catch (e) {
    console.log(e);
    yield put(getAvailableProposalsFailureAction(e));
  }
}

export function* watchGetAvailableProposals() {
  yield takeLatest([GET_AVAILABLE_PROPOSALS_REQUEST], getAvailableProposalsSaga);
}
