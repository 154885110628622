import { EMassAssignTaSrMode } from 'containers/TravelAgents/List/MassAssignModal';
import { ITravelAgent, ITravelAgentFilter, ENetworkRequestStatus, ISalesRepresentative } from 'services/BackendApi';

export interface ITravelAgentListPagination {
  page: number;
  perPage: number;
  total: number | null;
}

export interface ITravelAgentListDomain {
  list: ITravelAgent[] | null;
  salesReps: ISalesRepresentative[] | null;
  filter: ITravelAgentFilter;
  pagination: ITravelAgentListPagination;
  selectedTravelAgent: {
    toDelete: ITravelAgent | null;
    hasBookings: boolean;
  };
  sort: {
    field: string;
    order: 'asc' | 'desc';
  };
  massAssign: {
    massAssignModalVisible: boolean;
    massAssignConfirmModalVisible: boolean;
    massAssignMode: EMassAssignTaSrMode;
    selectedSrUuids: string[];
  };
  request: {
    get: ENetworkRequestStatus;
    delete: ENetworkRequestStatus;
    csv: ENetworkRequestStatus;
    massAssign: ENetworkRequestStatus;
    taHasBookings: ENetworkRequestStatus;
  };
}

export const initialState: ITravelAgentListDomain = {
  list: null,
  salesReps: null,
  sort: {
    field: 'createdAt',
    order: 'desc',
  },
  filter: {
    salesRepUuid: null,
    search: null,
    email: null,
    countryCode: null,
    companyUuid: null,
    status: null,
    createdBy: null,
  },
  pagination: {
    page: 0,
    perPage: 10,
    total: null,
  },
  selectedTravelAgent: {
    toDelete: null,
    hasBookings: false,
  },
  massAssign: {
    massAssignModalVisible: false,
    massAssignConfirmModalVisible: false,
    massAssignMode: EMassAssignTaSrMode.ADD,
    selectedSrUuids: [],
  },
  request: {
    get: ENetworkRequestStatus.IDLE,
    delete: ENetworkRequestStatus.IDLE,
    csv: ENetworkRequestStatus.IDLE,
    massAssign: ENetworkRequestStatus.IDLE,
    taHasBookings: ENetworkRequestStatus.IDLE,
  },
};
