import React, { useCallback, useEffect } from 'react';
import FluidButton from 'ui/FluidButton';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'store/modules/companyMemberships/actions';
import * as DepartmentsActions from 'store/modules/companyDepartments/actions';
import * as Selectors from 'store/modules/companyMemberships/selectors';
import * as DepartmentsSelectors from 'store/modules/companyDepartments/selectors';
import { EditMembershipModal } from './EditMembershipModal';
import { MembershipsTable } from './MembershipsTable';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';

export interface iMembershipsProps {
  companyUuid: string;
  isEditMode: boolean;
}

export const Memberships = (props: iMembershipsProps) => {
  const { companyUuid, isEditMode } = props;
  const dispatch = useDispatch();

  const isCreateDepartmentModalOpen = useSelector(Selectors.isMembershipModalOpenSelector);
  const membershipToEdit = useSelector(Selectors.membershipToEditSelector);
  const memberships = useSelector(Selectors.companyMembershipsSelector);
  const requests = useSelector(Selectors.companyMembershipsRequestsSelector);
  const departmentsRequests = useSelector(DepartmentsSelectors.companyDepartmentsRequestsSelector);
  const uuidToDelete = useSelector(Selectors.uuidToDeleteSelector);

  useEffect(() => {
    dispatch(Actions.fetchCompanyMembershipsRequestAction(companyUuid));
    dispatch(DepartmentsActions.fetchCompanyDepartmentsRequestAction(companyUuid));
    return () => {
      dispatch(Actions.resetCompanyMembershipsAction());
      dispatch(DepartmentsActions.resetCompanyDepartmentsAction());
    };
  }, [companyUuid, dispatch]);

  const initLoading =
    (requests.membershipListLoad === ENetworkRequestStatus.PENDING && memberships === null) ||
    departmentsRequests.departmentListLoad === ENetworkRequestStatus.PENDING;

  return (
    <>
      <div id="company-memberships-wrapper" className="flex flex-col w-full">
        <h1 className="memberships-title font-pt-sans text-black-true font-bold text-17px leading-md mt-25px mb-0">
          Memberships
        </h1>
        <p className="memberships-description font-pt-sans text-black text-15px leading-sm mt-5px mb-0">
          Manage memberships to provide final clients special features.
        </p>

        {initLoading && (
          <div className="mt-20px w-full">
            <LoadingBar />
          </div>
        )}

        {requests.membershipListLoad === ENetworkRequestStatus.ERROR && (
          <div className="mt-20px w-full">
            <ErrorBar />
          </div>
        )}

        {memberships && memberships.length > 0 && <MembershipsTable loading={false} memberships={memberships} isEditMode={isEditMode} />}

        {isEditMode && (<FluidButton
          className="block add-department-button mt-25px w-150px"
          onClick={() => {
            dispatch(Actions.openCreateMembershipModalAction());
          }}
          type="secondary"
        >
          Add Membership
        </FluidButton>)}
      </div>

      {(membershipToEdit || isCreateDepartmentModalOpen) && <EditMembershipModal companyUuid={companyUuid} />}

      {uuidToDelete && (
        <ConfirmationModal
          className="company-membership-delete-warning"
          type={EConfirmationModalType.WARNING}
          isOpen
          title={`You are going to delete a membership`}
          message="Are you sure you want to delete this item?"
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          isConfirmLoading={requests.membershipDelete === ENetworkRequestStatus.PENDING}
          onConfirm={() => {
            dispatch(Actions.deleteCompanyMembershipRequestAction(uuidToDelete, companyUuid));
          }}
          onCancel={() => {
            dispatch(Actions.setUuidToDeleteAction(null));
          }}
        />
      )}
    </>
  );
};
