import React, { FC } from 'react';

import { adult, child } from 'ui/Icons';

interface IRoomHeaderProps {
  name: string;
  category: string;
  adults: number;
  children: number;
}

export const RoomHeader: FC<IRoomHeaderProps> = React.memo(({ name, category, adults, children }) => {
  return (
    <div className="room-title-occupancy-category flex flex-wrap justify-between items-center text-15px">
      <div className="room-title-category w-3/4">
        <span className="room-title font-bold self-start">{name}</span>
        <div className="room-category text-xs text-gray-100 tracking-2xs mt-1 mb-2">{category.toUpperCase()} ROOM</div>
      </div>

      <span className="room-occupancy flex justify-between h-7 border-l border-solid border-gray-40 pl-3 text-13px self-start">
        <span className="room-occupancy-adults flex items-center">
          <img className="room-occupancy-adults-icon relative" src={adult} width="14" height="14" alt="Adult icon" />
          <span className="room-occupancy-adults-number relative ml-3px">{adults}</span>
        </span>

        {children > 0 && (
          <span className="room-occupancy-childs flex items-center ml-2">
            <img className="room-occupancy-childs-icon relative" src={child} width="14" height="14" alt="Child icon" />
            <span className="room-occupancy-childs-number relative ml-3px">{children}</span>
          </span>
        )}
      </span>
    </div>
  );
});
