import { IHotel } from 'services/BackendApi';
import { IHeadlineLineItemBreakdown, IHeadlineLineItemBreakdownResponse, ITopNavigationData } from 'services/BookingManagerApi';
import { IProposalBooking } from './types';

export const compileProposalBooking = (
  bookingBreakdownResponse: IHeadlineLineItemBreakdownResponse, bookingTopNavigation: ITopNavigationData, hotel: IHotel
): IProposalBooking => {
  const bookingBreakdown: IHeadlineLineItemBreakdown = bookingBreakdownResponse.headlineLineItemBreakdown;
  const featuredPhoto = hotel.uploads?.find(upload => upload.tag === 'featuredPhoto')
    || hotel.uploads?.find(upload => upload.tag === 'photo');

  const totalBeforeDiscountWithMargin = Math.round((bookingBreakdown.PreDiscountSubtotalCents || 0) * (1 + (bookingBreakdown.MarginPercentage || 0) / 100));
  
  return {
    uuid: bookingBreakdownResponse.bookingUuid,
    humanReadableId: bookingTopNavigation.bookingReferenceNo,
    guestCount: bookingTopNavigation.guestCount,
    hotel: {
      featuredPhoto: featuredPhoto,
      name: hotel.name,
      region: hotel.region,
      uuid: hotel.uuid,
      starRating: hotel.starRating,
      countryCode: hotel.countryCode,
    },
    currency: bookingBreakdownResponse.currency,
    totals: {
      // BMS does not return oneOrMoreItemsOnRequest in HeadlineLineItemBreakdown
      oneOrMoreItemsOnRequest: false,
      total: bookingBreakdown.TotalCostToClientCents,
      totalBeforeDiscount: totalBeforeDiscountWithMargin,
      marginCents: bookingBreakdown.MarginCalculatedValueCents,
      marginPercentage: bookingBreakdown.MarginPercentage,
    },
    checkInDate: bookingTopNavigation.arrivalDate,
    checkOutDate: bookingTopNavigation.departureDate,
    Accommodation: bookingBreakdown.Accommodation.items.map(item => {
      return {
        title: item.title,
        tertiaryText: item.tertiaryText,
        total: item.saleCostCents,
        totalBeforeDiscount: item.costBeforeDiscountCents,
        isOnRequest: item.isOnRequest,
        'Meal Plan': item['Meal Plan'].items.map(mealPlan => ({
          title: mealPlan.title,
          tertiaryText: mealPlan.tertiaryText,
          total: mealPlan.saleCostCents,
          totalBeforeDiscount: mealPlan.costBeforeDiscountCents,
          isOnRequest: mealPlan.isOnRequest,
        }))
      }
    }),
    Transfer: bookingBreakdown.Transfer.items.map(item => {
      return {
        title: item.title,
        tertiaryText: item.tertiaryText,
        total: item.saleCostCents,
        totalBeforeDiscount: item.costBeforeDiscountCents,
        isOnRequest: item.isOnRequest,
      }
    }),
    'Ground Service': bookingBreakdown['Ground Service'].items.map(item => {
      return {
        title: item.title,
        tertiaryText: item.tertiaryText,
        total: item.saleCostCents,
        totalBeforeDiscount: item.costBeforeDiscountCents,
        isOnRequest: item.isOnRequest,
      }
    }),
    Supplement: bookingBreakdown.Supplement.items.map(item => {
      return {
        title: item.title,
        tertiaryText: item.tertiaryText,
        total: item.saleCostCents,
        totalBeforeDiscount: item.costBeforeDiscountCents,
        isOnRequest: item.isOnRequest,
      }
    }),
    Fine: bookingBreakdown.Fine.items.map(item => {
      return {
        title: item.title,
        tertiaryText: item.tertiaryText,
        total: item.saleCostCents,
        totalBeforeDiscount: item.costBeforeDiscountCents,
        isOnRequest: item.isOnRequest,
      }
    }),
    createdAt: bookingTopNavigation.bookingStateHistory[0].timestamp.slice(
      0, bookingTopNavigation.bookingStateHistory[0].timestamp.indexOf('T')
    ),
  }
}