import React from 'react';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { useInterval } from '../../hooks/useInterval';

export interface IPollerProps {
  isWaitingForExport: boolean;
  exportUuid: string | null;
  pollRequestFunction: () => void;
  pollExportLoad: ENetworkRequestStatus;
}

export const Poller = (props: IPollerProps) => {
  const { isWaitingForExport, exportUuid, pollRequestFunction, pollExportLoad } = props;

  // only run the poller if we're waiting on an export, AND we have an export UUID, AND
  // we're not already currently waiting on a poll response
  const delay =
    isWaitingForExport === true && exportUuid !== null && pollExportLoad !== ENetworkRequestStatus.PENDING
      ? 1000
      : null;

  useInterval(() => {
    pollRequestFunction();
  }, delay);

  // this component returns nothing
  return null;
};
