import { put } from 'redux-saga/effects';
import {
  deleteRememberedToken,
  deleteRememberedUser,
  deleteRememberedCountry,
  deleteRememberedRole,
  deleteRememberedUsername,
  authReset,
  AUTH_LOG_OUT,
} from './actions';
import { successAction } from 'store/common';

export function* clearUser() {
  deleteRememberedToken();
  deleteRememberedUser();
  deleteRememberedCountry();
  deleteRememberedRole();
  deleteRememberedUsername();

  yield put(authReset());
  yield put(successAction(AUTH_LOG_OUT, {}));
}
