import * as React from 'react';
import classNames from 'classnames';
import SectionTitle from 'ui/SectionTitle';
import { TextBox } from 'pureUi/Textbox/Textbox';

interface IBMOverviewTextContainerProps {
  className?: string;
  text: string | null;
  label: string;
  updateHandler?: (s: string) => void;
  editable: boolean;
  isSaving?: boolean;
  onTextChange?: (text: string) => void;
}

export const BMOverviewTextContainer: React.FC<IBMOverviewTextContainerProps> = ({
  className,
  text,
  label,
  updateHandler,
  editable,
  isSaving,
  onTextChange,
}: IBMOverviewTextContainerProps) => {
  return (
    <div className={classNames(
      className,
      'overview-text-container',
      'bg-white-true border border-solid border-gray-40 p-5 mb-10 shadow-pe1'
    )}>
      <SectionTitle className="mb-5">{label}</SectionTitle>
      {text !== null ? (
        <TextBox
          text={text}
          updateHandler={updateHandler}
          onTextChange={onTextChange}
          buttonVisible={editable}
          disabled={!editable}
          isSaving={isSaving}
        />
      ) : (
        <div className="info loading">Loading {label}...</div>
      )}
    </div>
  );
};

export default BMOverviewTextContainer;