import { History } from 'history';

export const COGNITO_FLOW_REQUEST = 'cognito/COGNITO_FLOW_REQUEST';
export const COGNITO_FLOW_SUCCESS = 'cognito/COGNITO_FLOW_SUCCESS';
export const COGNITO_FLOW_FAILURE = 'cognito/COGNITO_FLOW_FAILURE';


export type CognitoFlowRequestAction = ReturnType<typeof cognitoFlowRequestAction>;
export const cognitoFlowRequestAction = (history: History) => ({
  type: COGNITO_FLOW_REQUEST as typeof COGNITO_FLOW_REQUEST,
  history,
});

export type CognitoFlowSuccessAction = ReturnType<typeof cognitoFlowSuccessAction>;
export const cognitoFlowSuccessAction = (status: number) => ({
  type: COGNITO_FLOW_SUCCESS as typeof COGNITO_FLOW_SUCCESS,
  status
});

export type CognitoFlowFailureAction = ReturnType<typeof cognitoFlowFailureAction>;
export const cognitoFlowFailureAction = (status: number, error: string) => ({
  type: COGNITO_FLOW_FAILURE as typeof COGNITO_FLOW_FAILURE,
  status,
  error,
});

export type CognitoAction =
  | CognitoFlowRequestAction
  | CognitoFlowSuccessAction
  | CognitoFlowFailureAction
  ;
