import { IHAPStaticRateResponseObject } from './types';

export const FETCH_HOTEL_ACCOMMODATION_PRODUCTS_REQUEST =
  'hotelAccommodationProducts/FETCH_HOTEL_ACCOMMODATION_PRODUCTS_REQUEST';
export const FETCH_HOTEL_ACCOMMODATION_PRODUCTS_SUCCESS =
  'hotelAccommodationProducts/FETCH_HOTEL_ACCOMMODATION_PRODUCTS_SUCCESS';
export const FETCH_HOTEL_ACCOMMODATION_PRODUCTS_FAILURE =
  'hotelAccommodationProducts/FETCH_HOTEL_ACCOMMODATION_PRODUCTS_FAILURE';

export const RESET_HOTEL_ACCOMMODATION_PRODUCTS_STATE =
  'hotelAccommodationProducts/RESET_HOTEL_ACCOMMODATION_PRODUCTS_STATE';

export const fetchHotelAccommodationProductsRequestAction = (hotelUuid: string) => ({
  type: FETCH_HOTEL_ACCOMMODATION_PRODUCTS_REQUEST,
  hotelUuid,
});

export const fetchHotelAccommodationProductsSuccessAction = (
  hotelAccomData: IHAPStaticRateResponseObject[],
  liveRateData: any,
  bookingComRatesData: any
) => ({
  type: FETCH_HOTEL_ACCOMMODATION_PRODUCTS_SUCCESS,
  hotelAccomData,
  liveRateData,
  bookingComRatesData,
});

export const fetchHotelAccommodationProductsFailureAction = error => ({
  type: FETCH_HOTEL_ACCOMMODATION_PRODUCTS_FAILURE,
  error,
});

export const resetHotelAccommodationProductsStateAction = () => ({
  type: RESET_HOTEL_ACCOMMODATION_PRODUCTS_STATE,
});
