import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { makeBookingManagerApi, IOwnershipTravelPartnerListResponse } from 'services/BookingManagerApi';
import { bookingUuidSelector } from 'store/modules/bookingManager/selectors';
import { enqueueNotification } from 'store/modules/ui';

import {
  GET_TA_USERS_REQUEST,
  GetTaUsersRequestAction,
  getTaUsersSuccessAction,
  getTaUsersFailureAction,
} from './actions';

function* errorNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'error' } }));
}

export function* getTaUsersSaga(action: GetTaUsersRequestAction) {
  try {
    const bookingUuid = yield select(bookingUuidSelector);
    const bookingManagerApi = makeBookingManagerApi();

    const result: AxiosResponse<IOwnershipTravelPartnerListResponse> = yield call(
      bookingManagerApi.getBookingAssignableTravelAgentList,
      bookingUuid
    );

    yield put(getTaUsersSuccessAction(result.data['travelAgents']));
  } catch (e) {
    yield put(getTaUsersFailureAction(e.message));
    yield errorNotification('Failed to fetch assigned sales representatives list');
  }
}

export function* watchBookingManagerSettings() {
  yield takeLatest(GET_TA_USERS_REQUEST, getTaUsersSaga);
}
