import { call, takeLatest, select, put } from 'redux-saga/effects';
import { makeBackendApi } from 'services/BackendApi';
import { offerSelector } from '../subdomains/offer/selectors';
import { uiStateSelector } from '../subdomains/uiState/selectors';
import { IOfferUI } from '../../../../services/BackendApi/types/OfferResponse';
import {
  PUT_OFFER_REQUEST,
  putOfferSuccessAction,
  putOfferFailureAction,
  postOffersOrderRequestAction,
} from '../actions';

import { transformUiOfferToApiOffer, transformApiOfferToUiOffer } from '../utils';
import { IOfferUiState } from '../model';
import { selectedTaSelector } from '../../agents';

export function* putOfferRequestSaga() {
  try {
    const uiOffer: IOfferUI = yield select(offerSelector);
    const uiState: IOfferUiState = yield select(uiStateSelector);
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);

    const { response, error } = yield call(backendApi.putOffer, transformUiOfferToApiOffer(uiOffer, uiState));

    if (response) {
      yield put(putOfferSuccessAction(transformApiOfferToUiOffer(response.data.data)));
      yield put(postOffersOrderRequestAction(uiState.orderedOffersList));
    }

    if (error) {
      yield put(putOfferFailureAction('Put error'));
    }
  } catch (e) {
    yield put(putOfferFailureAction('Put error'));
  }
}

export function* watchPutOfferRequest() {
  yield takeLatest(PUT_OFFER_REQUEST, putOfferRequestSaga);
}
