import { createSelector } from 'reselect';
import { BookingSearchItem } from 'services/BookingManagerApi';
import { IBookingManagerDomain, IBookingManagerDomainNetworkRequests, IBookingManagerDomainUi } from './model';
import { getCurrencyBySymbol } from 'utils';

export const bookingManagerDomain = (state: any): IBookingManagerDomain => state.bookingManager;

export const bookingSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain): BookingSearchItem | null => domain.booking
);

export const networkRequestsSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain): IBookingManagerDomainNetworkRequests => domain.networkRequests
);

export const proposalLoadSelector = createSelector(
  networkRequestsSelector,
  (networkRequests: IBookingManagerDomainNetworkRequests): IBookingManagerDomainNetworkRequests['proposalLoad'] =>
    networkRequests.proposalLoad
);

export const proposalSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain): IBookingManagerDomain['proposal'] => domain.proposal
);

// ============================================================================================

export const bookingLoadSelector = createSelector(
  networkRequestsSelector,
  (networkRequests: IBookingManagerDomainNetworkRequests): IBookingManagerDomainNetworkRequests['bookingLoad'] => {
    return networkRequests.bookingLoad;
  }
);

export const requestToBookSelector = createSelector(
  networkRequestsSelector,
  (networkRequests: IBookingManagerDomainNetworkRequests): IBookingManagerDomainNetworkRequests['requestToBook'] =>
    networkRequests.requestToBook
);

export const confirmSelector = createSelector(
  networkRequestsSelector,
  (networkRequests: IBookingManagerDomainNetworkRequests): IBookingManagerDomainNetworkRequests['confirm'] =>
    networkRequests.confirm
);

export const cancelSelector = createSelector(
  networkRequestsSelector,
  (networkRequests: IBookingManagerDomainNetworkRequests): IBookingManagerDomainNetworkRequests['cancel'] =>
    networkRequests.cancel
);

export const uiSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain): IBookingManagerDomain['uiState'] => domain.uiState
);

export const productBreakdownTableExpandedUuidSelector = createSelector(uiSelector, domainUi => {
  return domainUi.productTableBreakdownExpandedUuid;
});

export const productBreakdownTableExpandedUuidTransfersSelector = createSelector(uiSelector, domainUi => {
  return domainUi.productTableBreakdownExpandedUuid.transfer;
});

export const productBreakdownTableExpandedUuidGroundServicesSelector = createSelector(uiSelector, domainUi => {
  return domainUi.productTableBreakdownExpandedUuid['ground service'];
});

export const productBreakdownTableExpandedUuidAddonsSelector = createSelector(uiSelector, domainUi => {
  return domainUi.productTableBreakdownExpandedUuid.addon;
});

export const selectedProposalPdfVersionSelector = createSelector(uiSelector, domainUi => {
  return domainUi.selectedProposalPdfVersion;
});

export const isIncludeCompanyLogoOnGenerateSelector = createSelector(uiSelector, domainUi => {
  return domainUi.isIncludeCompanyLogoOnGenerate;
});

export const toProposalEmailAddressSelector = createSelector(uiSelector, domainUi => {
  return domainUi.toProposalEmailAddress;
});

export const bookingCurrencySelector = createSelector(bookingSelector, booking => {
  if (!booking) {
    return undefined;
  }
  return getCurrencyBySymbol(booking.currencySymbol).code;
});

export const bookingCurrencySymbolSelector = createSelector(bookingSelector, booking => {
  if (!booking) {
    return null;
  }
  return booking.currencySymbol;
});

export const bookingUuidSelector = createSelector(bookingSelector, booking => booking?.bookingUuid);

export const bookingRefSelector = createSelector(bookingSelector, booking => {
  if (!booking) {
    return null;
  }
  return booking.humanReadableId;
});

export const checklistSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.checklistSubdomain
);

export const bookingCommentsSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.commentsSubdomain
);

export const downloadsSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.downloadsSubdomain
);

export const uploadsSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.uploadsSubdomain
);

export const availableProposalsSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain): IBookingManagerDomain['availableProposals'] => domain.availableProposals
);

export const selectedProposalUuidSelector = createSelector(
  uiSelector,
  (domainUi: IBookingManagerDomainUi) => domainUi.proposal.selectedProposalUuid
);

export const newProposalNameSelector = createSelector(
  uiSelector,
  (domainUi: IBookingManagerDomainUi) => domainUi.proposal.newProposalName
);

export const shouldCreateProposalSelector = createSelector(
  uiSelector,
  (domainUi: IBookingManagerDomainUi) => domainUi.proposal.shouldCreateProposal
);

export const proposalPreviewParamsSelector = createSelector(
  uiSelector,
  (domainUi: IBookingManagerDomainUi) => domainUi.proposal.previewParams
);

export const guestInformationSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.guestInformation
);

export const breakdownSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.breakdownSubdomain
);

export const dashboardSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.dashboardSubdomain
);

export const bookingStatusOptionsSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.bookingStatusOptionsSubdomain
);

export const taLogoSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.taLogoSubdomain
);

export const voucherSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.voucherSubdomain
);

export const financeSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.financeSubdomain
);

export const hotelDetailsSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.hotelDetailsSubdomain
);

export const ownershipSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.ownershipSubdomain
);

export const purchaseCostReviewSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.purchaseCostReviewSubdomain
);

export const settingsSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.settingsSubdomain
);

export const bespokeNegotiationSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.bespokeNegotiationSubdomain
);

export const barCommissionPercentageSelector = createSelector(
  breakdownSubdomainSelector,
  breakdownSubdomain => breakdownSubdomain.barCommissionPercentage
);

export const barCommissionAmountSelector = createSelector(
  breakdownSubdomainSelector,
  breakdownSubdomain => breakdownSubdomain.barCommissionAmount
);

export const barCommissionErrorsSelector = createSelector(
  breakdownSubdomainSelector,
  breakdownSubdomain => breakdownSubdomain.barCommissionErrors
);

export const externalIdsSubdomainSelector = createSelector(
  bookingManagerDomain,
  (domain: IBookingManagerDomain) => domain.externalIdsSubdomain
);
