export interface ICredentialsInfo {
  uuid: string;
  creationDate: string;
  taName: string;
  email: string;
  company: string;
  sent: boolean;
  date?: string | null;
}

export enum CredentialsInfoSortField {
  CREATION_DATE = 'creationDate',
  TA_NAME = 'taName',
  EMAIL = 'email',
  COMPANY = 'company'
}

export enum CredentialsInfoSortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export interface ICredentialsInfoFilters {
  taUuid?: string;
  companyUuid?: string;
  sent?: boolean;
}

export interface IGetCredentialsInfo {
  sortField?: CredentialsInfoSortField;
  sortDirection?: CredentialsInfoSortDirection;
  filters?: ICredentialsInfoFilters; 
}

export interface IGetCredentialsInfoResponse {
  data: ICredentialsInfo[];
}

export interface IPostCredentialsInfoItem {
  uuid: string;
  sent: boolean;
  date?: string;
}

export interface IPostCredentialsInfo {
  credentials: IPostCredentialsInfoItem[];
}
