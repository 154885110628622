export const LOGOUT_REQUEST = 'authentication/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'authentication/LOGOUT_FAILURE';

export type LogoutRequestAction = ReturnType<typeof logoutRequestAction>;
export const logoutRequestAction = (token: string) => ({
  type: LOGOUT_REQUEST as typeof LOGOUT_REQUEST,
  token,
});

export type LogoutSuccessAction = ReturnType<typeof logoutSuccessAction>;
export const logoutSuccessAction = () => ({
  type: LOGOUT_SUCCESS as typeof LOGOUT_SUCCESS,
});

export type LogoutFailureAction = ReturnType<typeof logoutFailureAction>;
export const logoutFailureAction = (error: any) => ({
  type: LOGOUT_FAILURE as typeof LOGOUT_FAILURE,
  error,
});

export type LogoutAction = 
  | LogoutRequestAction 
  | LogoutSuccessAction
  | LogoutFailureAction
;
