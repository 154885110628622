import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBookingManagerApi } from 'services/BookingManagerApi';

import {
  GET_DOWNLOADS_REQUEST,
  GetDownloadsRequestAction,
  getDownloadsSuccessAction,
  getDownloadsFailureAction,
} from './actions';

import { bookingUuidSelector } from  '../../selectors';

export function* getDownloadsSaga(action: GetDownloadsRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);
    
    const result: AxiosResponse = yield call(bookingManagerApi.getDownloads, bookingUuid);
    yield put(getDownloadsSuccessAction(result.data.files));

  } catch (e) {
    yield put(getDownloadsFailureAction(e));
  }
}

export function* watchBookingManagerDownloads() {
  yield takeLatest([GET_DOWNLOADS_REQUEST], getDownloadsSaga);
}
