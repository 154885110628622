import classNames from "classnames";
import React, { useCallback, useState } from "react"
import AnimateHeight from "react-animate-height"
interface IRateInclusions {
  features: string[];
}
export const RateInclusions: React.FC<IRateInclusions> = ({ features }) => {
  const [rateInclusionsIsOpen, setRateInclusionsIsOpen] = useState(false);
  const handleOpenRateInclusions = useCallback(() => setRateInclusionsIsOpen((preVal) => !preVal),[rateInclusionsIsOpen])
  
  return (
    <div className="mt-[5px] mb-[5px]" onClick={handleOpenRateInclusions}>
      <div className="text-brown-prime font-light mb-[5px] cursor-pointer"><span className="underline">Rate Inclusions</span> <i className={classNames("fas fa-sm", { 'fa-chevron-down': !rateInclusionsIsOpen, 'fa-chevron-up': rateInclusionsIsOpen })}></i></div>
      <AnimateHeight duration={300} height={rateInclusionsIsOpen ? 'auto' : 0} className="accordion-area ml-[10px]">
        {features.map(f => (<li className="font-hurmegeometric-sans text-flint text-[12px] marker:text-[8px]">{f}.</li>))}
      </AnimateHeight>
    </div>
  )

}