import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBackendApi, NameSearchResponse } from 'services/BackendApi';
import {
  GET_DESTINATION_LIST_REQUEST,
  GetDestinationListRequestAction,
  getDestinationListSuccessAction,
  getDestinationListFailureAction
} from '../actions';
import { selectedTaSelector } from '../../agents';

export function* getDestinationListSaga(action: GetDestinationListRequestAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const { data: { data } }: AxiosResponse<NameSearchResponse> = yield call(backendApi.getNamesSearch, '');
    
    yield put(getDestinationListSuccessAction(data.hotels, data.countries));
  } catch (e) {
    yield put(getDestinationListFailureAction(e));
  }
}

export function* watchGetDestinationList() {
  yield takeLatest([GET_DESTINATION_LIST_REQUEST], getDestinationListSaga);
}
