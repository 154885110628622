import { EProposalSelectionSortableKey, IProposalSelection } from 'services/BookingManagerApi/types/ProposalSelection';
import { ESortOrder } from 'store/common/types';
import { PendingProposalsAction } from './subdomains/pendingProposals/actions';

export const GET_PROPOSAL_LIST_REQUEST = 'proposalList/GET_PROPOSAL_LIST_REQUEST';
export const GET_PROPOSAL_LIST_SUCCESS = 'proposalList/GET_PROPOSAL_LIST_SUCCESS';
export const GET_PROPOSAL_LIST_FAILURE = 'proposalList/GET_PROPOSAL_LIST_FAILURE';
export const SET_NAME_FILTER = 'proposalList/SET_NAME_FILTER';
export const SET_CLIENT_FILTER = 'proposalList/SET_CLIENT_FILTER';
export const SET_PAGE_NUMBER = 'proposalList/SET_PAGE_NUMBER';
export const SET_SORT = 'proposalList/SET_SORT';
export const SET_TRAVEL_AGENT_UUID = 'proposalList/SET_TRAVEL_AGENT_UUID';
export const SET_ITEMS_PER_PAGE = 'proposalList/SET_ITEMS_PER_PAGE';

// TODO deprecate when Proposals V2 feature is done
export type GetProposalListRequestAction = ReturnType<typeof getProposalListRequestAction>;
export const getProposalListRequestAction = () => ({
  type: GET_PROPOSAL_LIST_REQUEST as typeof GET_PROPOSAL_LIST_REQUEST,
});

export type GetProposalListSuccessAction = ReturnType<typeof getProposalListSuccessAction>;
export const getProposalListSuccessAction = (proposals: IProposalSelection[], totalResults: number) => ({
  type: GET_PROPOSAL_LIST_SUCCESS as typeof GET_PROPOSAL_LIST_SUCCESS,
  proposals,
  totalResults,
});

export type GetProposalListFailureAction = ReturnType<typeof getProposalListFailureAction>;
export const getProposalListFailureAction = (error: any) => ({
  type: GET_PROPOSAL_LIST_FAILURE as typeof GET_PROPOSAL_LIST_FAILURE,
  error,
});

export type SetNameFilterAction = ReturnType<typeof setNameFilterAction>;
export const setNameFilterAction = (filter: string) => ({
  type: SET_NAME_FILTER as typeof SET_NAME_FILTER,
  filter,
});

export type SetClientFilterAction = ReturnType<typeof setClientFilterAction>;
export const setClientFilterAction = (filter: string) => ({
  type: SET_CLIENT_FILTER as typeof SET_CLIENT_FILTER,
  filter,
});

export type SetPageNumberAction = ReturnType<typeof setPageNumberAction>;
export const setPageNumberAction = (pageNumber: number) => ({
  type: SET_PAGE_NUMBER as typeof SET_PAGE_NUMBER,
  pageNumber,
});

export type SetItemsPerPageAction = ReturnType<typeof setItemsPerPageAction>;
export const setItemsPerPageAction = (itemsPerPage: number) => ({
  type: SET_ITEMS_PER_PAGE as typeof SET_ITEMS_PER_PAGE,
  itemsPerPage,
});

export type SetSortAction = ReturnType<typeof setSortAction>;
export const setSortAction = (sortBy: EProposalSelectionSortableKey, sortOrder: ESortOrder) => ({
  type: SET_SORT as typeof SET_SORT,
  sortBy,
  sortOrder,
});

export type SetSelectedTravelAgentAction = ReturnType<typeof setSelectedTravelAgentAction>;
export const setSelectedTravelAgentAction = (travelAgentUuid: string) => ({
  type: SET_TRAVEL_AGENT_UUID as typeof SET_TRAVEL_AGENT_UUID,
  travelAgentUuid,
});

export type ProposalsListAction =
  | GetProposalListRequestAction
  | GetProposalListSuccessAction
  | GetProposalListFailureAction
  | SetNameFilterAction
  | SetClientFilterAction
  | SetPageNumberAction
  | SetItemsPerPageAction
  | SetSortAction
  | SetSelectedTravelAgentAction
  | PendingProposalsAction;
