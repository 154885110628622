import { ENetworkRequestStatus } from 'services/BackendApi';

export interface ICognitoDomain {
  request: {
    callWelcome: ENetworkRequestStatus;
    callWelcomeServerStatus: number | null;
    callWelcomeServerErrorMessage: string | null;
  }
}

export const initialState: ICognitoDomain = {
  request: {
    callWelcome: ENetworkRequestStatus.IDLE,
    callWelcomeServerErrorMessage: null,
    callWelcomeServerStatus: null,
  }
};
