import { IBookingStatusOptionObject } from 'store/modules/bookingManager/subdomains/bookingStatusOptions/model';
import { IBookingStateHistoryItem } from './DashboardResponse';

export interface IBookingStatusOptionsResponse {
  availableOptions: IBookingStatusOptionObject[];
  bookingUuid: string;
}

export enum EBookingStatus {
  ENQUIRY = 'enquiry',
  REQUESTED = 'requested',
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  DISCARDED = 'discarded',
}

export interface IBookingStatusResponse {
  bookingUuid: string;
  status: EBookingStatus;
  stateHistory: IBookingStateHistoryItem[];
  upload: {
    url: string;
    filename: string;
  };
}

export interface IBookingStatusCancellationInvoiceRequestData {
  status: EBookingStatus;
  invoiceDueDate: {
    date: string;
    percentage: number;
    amountCents: number;
    isConfirmed: boolean;
  };
}
