import { ENetworkRequestStatus } from 'services/BackendApi';
import { EIncentiveAmountType } from 'services/BookingManagerApi/types/Incentives';

import * as Actions from './actions'
import { ICompanyIncentivesDomain, initialState } from './model';

export const companyIncentivesReducer = (
  state: ICompanyIncentivesDomain = initialState,
  action: Actions.CompanyIncentivesAction
) => {
  switch (action.type) {
    case Actions.TOGGLE_COMPANY_INCENTIVES:
      return {
        ...state,
        changes: { ...state.changes, enabled: !(state.changes?.enabled ?? state.enabled) }
      }
    case Actions.FETCH_COMPANY_INCENTIVES_REQUEST:
      return {
        ...state,
        request: { ...state.request, get: ENetworkRequestStatus.PENDING }
      }
    case Actions.FETCH_COMPANY_INCENTIVES_SUCCESS:
      return {
        ...state,
        enabled: true,
        amount: action.incentive.amount,
        amountType: action.incentive.type,
        startDate: action.incentive.startDate,
        notes: action.incentive.notes,
        request: {
          ...state.request,
          get: ENetworkRequestStatus.SUCCESS,
        }
      }
    case Actions.FETCH_COMPANY_INCENTIVES_FAILURE:
      return {
        ...initialState,
        request: {
          ...state.request,
          get: ENetworkRequestStatus.ERROR,
        },
        error: action.error
      }
    case Actions.SET_COMPANY_INCENTIVE_TYPE:
      return {
        ...state,
        changes: {
          ...state.changes,
          amountType: action.amountType,
        },
      };
    case Actions.SET_COMPANY_INCENTIVE_AMOUNT:
      return {
        ...state,
        changes: { ...state.changes, amount: action.amount }
      }
    case Actions.SET_COMPANY_INCENTIVE_START_DATE:
      return {
        ...state,
        changes: { ...state.changes, startDate: action.startDate }
      }
    case Actions.SET_COMPANY_INCENTIVE_NOTES:
      return {
        ...state,
        changes: { ...state.changes, notes: action.notes.trim().length > 0 ? action.notes : undefined }
      }
    case Actions.UPSERT_COMPANY_INCENTIVE_REQUEST:
      return {
        ...state,
        request: { ...state.request, post: ENetworkRequestStatus.PENDING }
      }
    case Actions.UPSERT_COMPANY_INCENTIVE_SUCCESS:
      return {
        ...state,
        request: { ...state.request, post: ENetworkRequestStatus.SUCCESS },
        changes: undefined
      }
    case Actions.UPSERT_COMPANY_INCENTIVE_FAILURE:
      return {
        ...state,
        request: { ...state.request, post: ENetworkRequestStatus.ERROR },
        error: action.error,
      }
    case Actions.DELETE_COMPANY_INCENTIVE_REQUEST:
      return {
        ...state,
        request: { ...state.request, delete: ENetworkRequestStatus.PENDING }
      }
    case Actions.DELETE_COMPANY_INCENTIVE_SUCCESS:
      return {
        ...state,
        request: { ...state.request, delete: ENetworkRequestStatus.SUCCESS },
        changes: undefined
      }
    case Actions.DELETE_COMPANY_INCENTIVE_FAILURE:
      return {
        ...state,
        request: { ...state.request, delete: ENetworkRequestStatus.ERROR },
        error: action.error,
      }
    default:
      return state
  }
};
