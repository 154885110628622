import styled from 'styled-components';
import { pureUiTheme } from 'pureUi/pureUiTheme';

export const GuestInformationFormStyles = styled.div`
  .guestForm {
    display: grid;
    padding: 30px 0;
    border-bottom: 1px solid ${pureUiTheme.colors.grayDark};

    &:first-child {
      padding-top: 12px;
    }

    .guestFormRow {
      align-items: start;
      grid-gap: 20px;
      padding: 10px 0;

      .dateInputField > button {
        background: #fbfbfa;
        height: 35px;
        border: 1px solid ${pureUiTheme.colors.gray40};
        padding: 0 8px;

        .displayString {
          font-size: 16px;
          line-height: 19px;
          font-family: 'PT Sans';
          color: ${pureUiTheme.colors.blackText};
          text-transform: capitalize;
        }

        &:after {
          content: '\f133';
          font-family: 'Font Awesome 5 Free';
          font-size: 11px;
          font-weight: 900;
          color: #968f8b;
        }
      }

      .dateNotSelected > button .displayString {
        font-style: italic;
        font-family: 'PT Sans';
        color: #968f8b;
      }

      .textInputField {
        height: 35px;

        & > input {
          background: #fbfbfa;
          font-family: 'PT Sans';
          border: 1px solid ${pureUiTheme.colors.gray40};
        }
      }

      .selectInputField {
        background: #fbfbfa;
        font-family: 'PT Sans';
        height: 35px;
        border: 1px solid ${pureUiTheme.colors.gray40};
        text-transform: capitalize;
        width: 100%;
        padding: 8px 10px;
      }

      .fieldError {
        border: 1px solid ${pureUiTheme.colors.redFade};
      }
    }

    .guestFormThirdRow {
      display: grid;
      grid-template-columns: 150fr 150fr repeat(3, 250fr); // 1050
      grid-gap: 20px;
    }

    .guestFormFourthRow,
    .guestFormFifthRow {
      display: grid;
      grid-template-columns: 150fr 150fr 125fr 375fr 250fr; // 1050
      grid-gap: 20px;
    }

    .labelText {
      text-transform: none;
      margin-bottom: 5px;
    }
  }

  .addGuestButton {
    margin: 20px 0;
    text-transform: none;
    border-radius: 4px;
  }

  .addGuestButtonContainer {
    padding: 20px 0;
    border-bottom: 1px solid ${pureUiTheme.colors.grayDark};
  }

  .submitFormButtonContainer {
    position: relative;
    margin-top: 30px;
    padding-bottom: 30px;
  }

  .sameAsLeadGuestButton {
    align-self: end;
  }

  .removeGuestButton {
    align-self: end;
    justify-self: end;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 35px;
    height: 35px;
    cursor: pointer;
  }

  .errorMessage {
    color: ${pureUiTheme.colors.redFade};
    font-size: 12px;
    line-height: 15px;
    text-transform: none;
    margin-top: 3px;
  }
`;
