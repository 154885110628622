import { call, takeLatest, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBookingManagerApi, ISalesRepresentativesResponse } from 'services/BookingManagerApi';

import { GET_SALES_REP_LIST_REQUEST, getSalesRepListSuccessAction, getSalesRepListFailureAction } from '../actions';

export function* getSalesRepListSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const result: AxiosResponse<ISalesRepresentativesResponse> = yield call(bookingManagerApi.getSalesRepresentatives);
    yield put(getSalesRepListSuccessAction(result.data.srList));
  } catch (e) {
    yield put(getSalesRepListFailureAction(e.message));
  }
}

export function* watchGetSalesRepList() {
   yield takeLatest([GET_SALES_REP_LIST_REQUEST], getSalesRepListSaga);
}
