import React, { useState, useCallback, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FluidButton from 'ui/FluidButton';
import classnames from 'classnames';
import { ENetworkRequestStatus, EUserType } from 'services/BackendApi';
import { StandardModal } from 'pureUi/Modal';
import { getPossibleNotificationsRequestSelector, possibleNotificationsSelector, setNotificationsForCompanyUsersSelector } from 'store/modules/notifications/selectors';
import { getPossibleNotificationsRequestAction, resetNotificationsAction, setNotificationConfirmationModalVisibilityAction, setNotificationSettingsModalVisibilityAction, setNotificationsForCompanyUsersRequestAction } from 'store/modules/notifications/actions';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { CompanyNotificationRow } from './CompanyNotificationRow';
import { EAssignNotificationsAction } from 'store/modules/notifications/model';

export interface INotificationSettingsModalProps {
  selectedNotificationCodes: string[];
  onNotificationSelect: (notificationCode: string) => void;
  onClose: () => void;
}

export const NotificationSettingsModal = (props: INotificationSettingsModalProps) => {
  const { selectedNotificationCodes, onNotificationSelect, onClose } = props;

  const dispatch = useDispatch();
  const possibleNotifications = useSelector(possibleNotificationsSelector);
  const possibleNotificationsRequest = useSelector(getPossibleNotificationsRequestSelector);
  
  const setNotificationsForCompanyUsersRequest = useSelector(setNotificationsForCompanyUsersSelector);
  const isSaving = setNotificationsForCompanyUsersRequest === ENetworkRequestStatus.PENDING;
  const areButtonsDisabled = selectedNotificationCodes.length === 0
    || isSaving;

  useLayoutEffect(() => {
    dispatch(getPossibleNotificationsRequestAction(EUserType.TA));

    return () => {
      dispatch(resetNotificationsAction());
    }
  }, []);

  const handleAssignToAllTA = useCallback(() => {
    dispatch(
      setNotificationConfirmationModalVisibilityAction(true, EAssignNotificationsAction.ASSIGN_TO_ALL)
    );
    dispatch(setNotificationSettingsModalVisibilityAction(false));
  }, [dispatch]);

  const handleRemoveFromAllTA = useCallback(() => {
    dispatch(
      setNotificationConfirmationModalVisibilityAction(true, EAssignNotificationsAction.REMOVE_FROM_ALL)
    );
    dispatch(setNotificationSettingsModalVisibilityAction(false));
  }, [dispatch]);

  const handleModalClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const thClassName = 'font-normal bg-ivory border border-solid border-r-0 border-gray-20 shadow-pe1 uppercase px-5 py-3 text-xs text-gray-120 sticky top-0 text-left z-1';

  return (
    <StandardModal
        className="notification-settings-modal font-pt-sans"
        frameClassName="w-768px px-9 py-8 border border-solid"
        removePadding={true}
        closeOnOutsideClick={true}
        focusTrapActive={false}
        onClose={handleModalClose}
      >
        <div>
          <h3 className="m-0 font-noe-display font-normal mb-6 text-2xl">Notifications Settings</h3>

          {possibleNotificationsRequest === ENetworkRequestStatus.PENDING && (
            <div className="">
              <LoadingBar />
            </div>
          )}

          {possibleNotificationsRequest === ENetworkRequestStatus.ERROR && (
            <div className="">
              <ErrorBar />
            </div>
          )}

          {possibleNotificationsRequest === ENetworkRequestStatus.SUCCESS && !!possibleNotifications && (
            <div className={classnames('mt-5', {
              'opacity-60 cursor-wait pointer-events-none': isSaving,
            })}>
              <table className="ta-notifications-table relative table-fixed w-full border-separate border-spacing-0 font-pt-sans">
                <thead>
                  <tr>
                    <th className={thClassName} style={{ width: '350px' }}>
                      NOTIFICATION
                    </th>
                    <th className={classnames('border-l-0', thClassName)} style={{ width: '300px' }}>
                      DESCRIPTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {possibleNotifications.map(notification => {
                    return (
                      <CompanyNotificationRow 
                        key={notification.code}
                        notification={notification}
                        selected={selectedNotificationCodes.includes(notification.code)}
                        onChange={onNotificationSelect} 
                      />
                    );
                  })}
                </tbody>
              </table>
          </div>
          )}

          <div className="notification-buttons flex gap-10px mt-25px">
            <FluidButton
              type="secondary"
              className="assign-to-all-ta"
              onClick={handleAssignToAllTA}
              disabled={areButtonsDisabled}
            >
              Assign to all TA
            </FluidButton>
            <FluidButton
              type="secondary"
              className="remove-to-all-ta"
              onClick={handleRemoveFromAllTA}
              disabled={areButtonsDisabled}
            >
              Remove from all TA
            </FluidButton>
          </div>
        </div>
      </StandardModal>
  )
};
