import {
  ENetworkRequestStatus,
  ETravelAgentStatus,
  ICompany,
  ITravelAgent,
  tInternalUserRoles,
} from 'services/BackendApi';
import { ISR } from '../user/model';

export const GET_REQUEST = 'internalUsersList/GET_REQUEST';
export const GET_SUCCESS = 'internalUsersList/GET_SUCCESS';
export const GET_FAILURE = 'internalUsersList/GET_FAILURE';

export const DELETE_REQUEST = 'internalUsersList/DELETE_REQUEST';
export const DELETE_SUCCESS = 'internalUsersList/DELETE_SUCCESS';
export const DELETE_FAILURE = 'internalUsersList/DELETE_FAILURE';

export const CSV_REQUEST = 'internalUsersList/CSV_REQUEST';
export const CSV_SUCCESS = 'internalUsersList/CSV_SUCCESS';
export const CSV_FAILURE = 'internalUsersList/CSV_FAILURE';

export const SET_USER_ROLE_FILTER = 'internalUsersList/SET_USER_ROLE_FILTER';
export const SET_SEARCH_FILTER = 'internalUsersList/SET_SEARCH_FILTER';
export const SET_EMAIL_FILTER = 'internalUsersList/SET_EMAIL_FILTER';
export const SET_COUNTRY_CODE_FILTER = 'internalUsersList/SET_COUNTRY_CODE_FILTER';
export const SET_STATUS_FILTER = 'internalUsersList/SET_STATUS_FILTER';

export const SET_PAGE = 'internalUsersList/SET_PAGE';
export const SET_PER_PAGE = 'internalUsersList/SET_PER_PAGE';
export const SET_TO_DELETE = 'internalUsersList/SET_TO_DELETE';

export const SET_REQUEST = 'internalUsersList/SET_REQUEST';

export const SET_SELECTED_SR = 'internalUsersList/SET_SELECTED_SR';
export const RESET_SELECTED_SR = 'internalUsersList/RESET_SELECTED_SR';

export const GET_IDLE_SELECTED_SR_TAS = 'internalUsersList/GET_IDLE_SELECTED_SR_TAS';
export const GET_REQUEST_SELECTED_SR_TAS = 'internalUsersList/GET_REQUEST_SELECTED_SR_TAS';
export const GET_SUCESS_SELECTED_SR_TAS = 'internalUsersList/GET_SUCESS_SELECTED_SR_TAS';
export const GET_FAILURE_SELECTED_SR_TAS = 'internalUsersList/GET_FAILURE_SELECTED_SR_TAS';

export const POST_REQUEST_SELECTED_SR_TAS = 'internalUsersList/POST_REQUEST_SELECTED_SR_TAS';
export const POST_SUCESS_SELECTED_SR_TAS = 'internalUsersList/POST_SUCESS_SELECTED_SR_TAS';
export const POST_FAILURE_SELECTED_SR_TAS = 'internalUsersList/POST_FAILURE_SELECTED_SR_TAS';

export const SET_SELECTED_SR_TAS = 'internalUsersList/SET_SELECTED_SR_TAS';
export const ADD_SELECTED_SR_TAS = 'internalUsersList/ADD_SELECTED_SR_TAS';
export const REMOVE_SELECTED_SR_TAS = 'internalUsersList/REMOVE_SELECTED_SR_TAS';
export const CHECK_ALL_SR_TAS = 'internalUsersList/CHECK_ALL_SR_TAS';
export const UNCHECK_ALL_SR_TAS = 'internalUsersList/UNCHECK_ALL_SR_TAS';

export const SET_TA_FILTER_COUNTRY = 'internalUsersList/SET_TA_FILTER_COUNTRY';
export const SET_TA_FILTER_NAME = 'internalUsersList/SET_TA_FILTER_NAME';

export const SET_IS_ASSIGNED_TAB = 'internalUsersList/SET_IS_ASSIGNED_TAB';
export const RESET_IS_ASSIGNED_TAB = 'internalUsersList/RESET_IS_ASSIGNED_TAB';

export const OPEN_CONFIRMATION_MODAL = 'internalUsersList/OPEN_CONFIRMATION_MODAL';
export const CLOSE_CONFIRMATION_MODAL = 'internalUsersList/CLOSE_CONFIRMATION_MODAL';

export const CONFIRM_CHANGES = 'internalUsersList/CONFIRM_CHANGES';
export const DECLINE_CHANGES = 'internalUsersList/DECLINE_CHANGES';

export type GetRequestAction = ReturnType<typeof getRequestAction>;
export const getRequestAction = () => ({
  type: GET_REQUEST as typeof GET_REQUEST,
});

export type GetSuccessAction = ReturnType<typeof getSuccessAction>;
export const getSuccessAction = (internalUsers: ITravelAgent[], total: number) => ({
  type: GET_SUCCESS as typeof GET_SUCCESS,
  internalUsers,
  total,
});

export type GetFailureAction = ReturnType<typeof getFailureAction>;
export const getFailureAction = (error: string) => ({
  type: GET_FAILURE as typeof GET_FAILURE,
  error,
});

export type CsvRequestAction = ReturnType<typeof csvRequestAction>;
export const csvRequestAction = () => ({
  type: CSV_REQUEST as typeof CSV_REQUEST,
});

export type CsvSuccessAction = ReturnType<typeof csvSuccessAction>;
export const csvSuccessAction = () => ({
  type: CSV_SUCCESS as typeof CSV_SUCCESS,
});

export type CsvFailureAction = ReturnType<typeof csvFailureAction>;
export const csvFailureAction = (error: string) => ({
  type: CSV_FAILURE as typeof CSV_FAILURE,
  error,
});

export type DeleteRequestAction = ReturnType<typeof deleteRequestAction>;
export const deleteRequestAction = () => ({
  type: DELETE_REQUEST as typeof DELETE_REQUEST,
});

export type DeleteSuccessAction = ReturnType<typeof deleteSuccessAction>;
export const deleteSuccessAction = () => ({
  type: DELETE_SUCCESS as typeof DELETE_SUCCESS,
});

export type DeleteFailureAction = ReturnType<typeof deleteFailureAction>;
export const deleteFailureAction = (error: string) => ({
  type: DELETE_FAILURE as typeof DELETE_FAILURE,
  error,
});

export type SetUserRoleFilterAction = ReturnType<typeof setUserRoleFilterAction>;
export const setUserRoleFilterAction = (value: tInternalUserRoles | null) => ({
  type: SET_USER_ROLE_FILTER as typeof SET_USER_ROLE_FILTER,
  value,
});

export type SetSearchFilterAction = ReturnType<typeof setSearchFilterAction>;
export const setSearchFilterAction = (value: string | null) => ({
  type: SET_SEARCH_FILTER as typeof SET_SEARCH_FILTER,
  value,
});

export type SetEmailFilterAction = ReturnType<typeof setEmailFilterAction>;
export const setEmailFilterAction = (value: string | null) => ({
  type: SET_EMAIL_FILTER as typeof SET_EMAIL_FILTER,
  value,
});

export type SetCountryCodeFilterAction = ReturnType<typeof setCountryCodeFilterAction>;
export const setCountryCodeFilterAction = (value: string | null) => ({
  type: SET_COUNTRY_CODE_FILTER as typeof SET_COUNTRY_CODE_FILTER,
  value,
});

export type SetStatusFilterAction = ReturnType<typeof setStatusFilterAction>;
export const setStatusFilterAction = (value: ETravelAgentStatus | null) => ({
  type: SET_STATUS_FILTER as typeof SET_STATUS_FILTER,
  value,
});

export type SetPageAction = ReturnType<typeof setPageAction>;
export const setPageAction = (value: number) => ({
  type: SET_PAGE as typeof SET_PAGE,
  value,
});

export type SetPerPageAction = ReturnType<typeof setPerPageAction>;
export const setPerPageAction = (value: number) => ({
  type: SET_PER_PAGE as typeof SET_PER_PAGE,
  value,
});

export type SetToDeleteAction = ReturnType<typeof setToDeleteAction>;
export const setToDeleteAction = (value: ITravelAgent | null) => ({
  type: SET_TO_DELETE as typeof SET_TO_DELETE,
  value,
});

export type SetRequestAction = ReturnType<typeof setRequestAction>;
export const setRequestAction = (net: ENetworkRequestStatus) => ({
  type: SET_REQUEST as typeof SET_REQUEST,
  net,
});

export type SetSelectedSRAction = ReturnType<typeof setSelectedSRAction>;
export const setSelectedSRAction = (sr: ISR) => ({
  type: SET_SELECTED_SR as typeof SET_SELECTED_SR,
  sr,
});
export type ResetSelectedSRAction = ReturnType<typeof resetSelectedSRAction>;
export const resetSelectedSRAction = () => ({
  type: RESET_SELECTED_SR as typeof RESET_SELECTED_SR,
});

export type GetIdleSelectedSRTAsAction = ReturnType<typeof getIdleSelectedSRTAsAction>;
export const getIdleSelectedSRTAsAction = () => ({
  type: GET_IDLE_SELECTED_SR_TAS as typeof GET_IDLE_SELECTED_SR_TAS,
});

export type GetRequestSelectedSRTAsAction = ReturnType<typeof getRequestSelectedSRTAsAction>;
export const getRequestSelectedSRTAsAction = () => ({
  type: GET_REQUEST_SELECTED_SR_TAS as typeof GET_REQUEST_SELECTED_SR_TAS,
});

export type GetSuccessSelectedSRTAsAction = ReturnType<typeof getSuccessSelectedSRTAsAction>;
export const getSuccessSelectedSRTAsAction = (data: any) => ({
  type: GET_SUCESS_SELECTED_SR_TAS as typeof GET_SUCESS_SELECTED_SR_TAS,
  data
});

export type GetFailureSelectedSRTAsAction = ReturnType<typeof getFailureSelectedSRTAsAction>;
export const getFailureSelectedSRTAsAction = (errorMessage: string) => ({
  type: GET_FAILURE_SELECTED_SR_TAS as typeof GET_FAILURE_SELECTED_SR_TAS,
  errorMessage
});

export type PostRequestSelectedSRTAsAction = ReturnType<typeof postRequestSelectedSRTAsAction>;
export const postRequestSelectedSRTAsAction = () => ({
  type: POST_REQUEST_SELECTED_SR_TAS as typeof POST_REQUEST_SELECTED_SR_TAS,
});

export type PostSuccessSelectedSRTAsAction = ReturnType<typeof postSuccessSelectedSRTAsAction>;
export const postSuccessSelectedSRTAsAction = () => ({
  type: POST_SUCESS_SELECTED_SR_TAS as typeof POST_SUCESS_SELECTED_SR_TAS
});

export type PostFailureSelectedSRTAsAction = ReturnType<typeof postFailureSelectedSRTAsAction>;
export const postFailureSelectedSRTAsAction = (errorMessage: string) => ({
  type: POST_FAILURE_SELECTED_SR_TAS as typeof POST_FAILURE_SELECTED_SR_TAS,
  errorMessage
});

export type AddSelectedSRTAsAction = ReturnType<typeof addSelectedSRTAsAction>;
export const addSelectedSRTAsAction = (uuid: string) => ({
  type: ADD_SELECTED_SR_TAS as typeof ADD_SELECTED_SR_TAS,
  uuid
});

export type SetSelectedSRTAsAction = ReturnType<typeof setSelectedSRTAsAction>;
export const setSelectedSRTAsAction = (uuidsList: string[]) => ({
  type: SET_SELECTED_SR_TAS as typeof SET_SELECTED_SR_TAS,
  uuidsList
});

export type RemoveSelectedSRTAsAction = ReturnType<typeof removeSelectedSRTAsAction>;
export const removeSelectedSRTAsAction = (uuid: string) => ({
  type: REMOVE_SELECTED_SR_TAS as typeof REMOVE_SELECTED_SR_TAS,
  uuid
});

export type CheckAllSRTAsAction = ReturnType<typeof checkAllSRTAsAction>;
export const checkAllSRTAsAction = () => ({
  type: CHECK_ALL_SR_TAS as typeof CHECK_ALL_SR_TAS
});

export type UnCheckAllSRTAsAction = ReturnType<typeof unCheckAllSRTAsAction>;
export const unCheckAllSRTAsAction = () => ({
  type: UNCHECK_ALL_SR_TAS as typeof UNCHECK_ALL_SR_TAS
});

export type SetTAFilterNameAction = ReturnType<typeof setTAFilterNameAction>;
export const setTAFilterNameAction = (str: string | undefined) => ({
  type: SET_TA_FILTER_NAME as typeof SET_TA_FILTER_NAME,
  str
});

export type SetTAFilterCountryAction = ReturnType<typeof setTAFilterCountryAction>;
export const setTAFilterCountryAction = (countryCode: string) => ({
  type: SET_TA_FILTER_COUNTRY as typeof SET_TA_FILTER_COUNTRY,
  countryCode
});

export type SetIsAssignedTabAction = ReturnType<typeof setIsAssignedTabAction>;
export const setIsAssignedTabAction = () => ({
  type: SET_IS_ASSIGNED_TAB as typeof SET_IS_ASSIGNED_TAB,
});

export type ResetIsAssignedTabAction = ReturnType<typeof resetIsAssignedTabAction>;
export const resetIsAssignedTabAction = () => ({
  type: RESET_IS_ASSIGNED_TAB as typeof RESET_IS_ASSIGNED_TAB,
});

export type OpenConfirmationModalAction = ReturnType<typeof openConfirmationModalAction>;
export const openConfirmationModalAction = () => ({
  type: OPEN_CONFIRMATION_MODAL as typeof OPEN_CONFIRMATION_MODAL,
});

export type CloseConfirmationModalAction = ReturnType<typeof closeConfirmationModalAction>;
export const closeConfirmationModalAction = () => ({
  type: CLOSE_CONFIRMATION_MODAL as typeof CLOSE_CONFIRMATION_MODAL,
});

export type ConfirmChangesAction = ReturnType<typeof confirmChangesAction>;
export const confirmChangesAction = () => ({
  type: CONFIRM_CHANGES as typeof CONFIRM_CHANGES,
});

export type DeclineChangesAction = ReturnType<typeof declineChangesAction>;
export const declineChangesAction = () => ({
  type: DECLINE_CHANGES as typeof DECLINE_CHANGES,
});


export type Any =
  | GetRequestAction
  | GetSuccessAction
  | GetFailureAction
  | CsvRequestAction
  | CsvSuccessAction
  | CsvFailureAction
  | DeleteRequestAction
  | DeleteSuccessAction
  | DeleteFailureAction
  | SetSearchFilterAction
  | SetEmailFilterAction
  | SetCountryCodeFilterAction
  | SetUserRoleFilterAction
  | SetStatusFilterAction
  | SetPageAction
  | SetPerPageAction
  | SetToDeleteAction
  | SetRequestAction
  | SetSelectedSRAction
  | ResetSelectedSRAction
  | GetIdleSelectedSRTAsAction
  | GetRequestSelectedSRTAsAction
  | GetSuccessSelectedSRTAsAction
  | GetFailureSelectedSRTAsAction
  | AddSelectedSRTAsAction
  | SetSelectedSRTAsAction
  | RemoveSelectedSRTAsAction
  | CheckAllSRTAsAction
  | UnCheckAllSRTAsAction
  | SetTAFilterCountryAction
  | SetTAFilterNameAction
  | SetIsAssignedTabAction
  | ResetIsAssignedTabAction
  | PostRequestSelectedSRTAsAction
  | PostSuccessSelectedSRTAsAction
  | PostFailureSelectedSRTAsAction
  | OpenConfirmationModalAction
  | CloseConfirmationModalAction
  | ConfirmChangesAction
  | DeclineChangesAction;