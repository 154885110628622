import { call, takeLatest, select, put } from 'redux-saga/effects';
import { makeBookingManagerApi } from 'services/BookingManagerApi';
import {
  SET_BOOKING_TYPE_REQUEST,
  setBookingTypeSuccessAction,
  setBookingTypeFailureAction,
  SetBookingTypeRequestAction,
} from '../actions';

import { bookingUuidSelector } from '../../../selectors';
import { AxiosResponse } from 'axios';
import { ISetBookingTypeResponse } from 'services/BookingManagerApi/types/BookingTypeResponse';

export function* setBookingTypeSaga(action: SetBookingTypeRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<ISetBookingTypeResponse> = yield call(
      bookingManagerApi.setBookingType,
      bookingUuid,
      action.bookingType,
    );

    yield put(setBookingTypeSuccessAction(result.data.bookingType));
  } catch (e) {
    yield put(setBookingTypeFailureAction(e));
  }
}

export function* watchSetBookingTypeSaga() {
  yield takeLatest([SET_BOOKING_TYPE_REQUEST], setBookingTypeSaga);
}
