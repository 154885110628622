import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import { TextInput } from 'ui/TextInput';
import { GeneralModal } from 'ui/GeneralModal';
import { Warning } from 'ui/Warning';
import { getAuthStatus, setPassword } from 'store/modules/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentWidth } from 'effects';
import { IResetPasswordFormValues } from './types';
import { Field, Formik, FormikErrors, FormikProps } from 'formik';
import { Status } from 'store/common';

export interface iResetPasswordModalProps {
  isOpen: boolean;
  token: string;
  onClose?: () => void;
}

export const ResetPasswordModal = (props: iResetPasswordModalProps) => {
  const { isOpen, onClose, token } = props;

  const dispatch = useDispatch();
  const authStatus = useSelector(getAuthStatus);
  const loading = authStatus === Status.SENDING;
  const { isMobile } = useCurrentWidth();
  const modalWidthClassname = classnames('flex justify-center', {
    'w-330px': isMobile,
    'w-400px': !isMobile,
  });

  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const initialValues: IResetPasswordFormValues = {
    password: '',
    passwordConfirm: '',
  };

  const validateForm = useCallback((values: IResetPasswordFormValues) => {
    const errors: FormikErrors<IResetPasswordFormValues> = {};

    if (!values.password?.trim().length) {
      errors.password = 'This field is required';
    }
    if (!values.passwordConfirm?.trim().length) {
      errors.passwordConfirm = 'This field is required';
    }
    if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = 'Passwords do not match';
    }

    return errors;
  }, []);

  const handleFormSubmit = useCallback(
    (resetPasswordFormValues: IResetPasswordFormValues) => {
      const { password, passwordConfirm } = resetPasswordFormValues;
      setErrorMessage('');
      setSuccess(false);
      dispatch(setPassword({ token, password, passwordConfirm }));
    },
    [dispatch, token]
  );

  useEffect(() => {
    if (authStatus === Status.ERROR) {
      setErrorMessage('Could not reset password');
    }

    if (authStatus === Status.SUCCESS) {
      setSuccess(true);
    }
  }, [authStatus]);

  const renderTitle = (isSuccess: boolean) => {
    return isSuccess ? (
      <p className="modal-title text-black text-center text-4xl leading-54px font-noe-display mt-5px my-0">
        Reset Done
      </p>
    ) : (
      <p className="modal-title text-black text-center text-4xl leading-54px font-noe-display mt-5px my-0">
        Set your password
      </p>
    );
  };

  const renderContent = () => {
    return (
      <Formik
        initialValues={initialValues}
        validate={validateForm}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {(form: FormikProps<IResetPasswordFormValues>) => {
          return (
            <form onSubmit={form.handleSubmit} className="flex justify-center">
              <div className="min-w-250px max-w-250px ml-auto mr-auto">
                <Field
                  as={TextInput}
                  name="password"
                  type="password"
                  id="reset-password-modal-password"
                  className="reset-password-modal-password mt-25px"
                  label="Password"
                  errorMessage={form.touched.password && form.errors.password ? form.errors.password : null}
                  errorClassName="set-password-error"
                />

                <Field
                  as={TextInput}
                  name="passwordConfirm"
                  type="password"
                  id="reset-password-modal-passwordConfirm"
                  className="reset-password-modal-passwordConfirm mt-25px"
                  label="Repeat password"
                  errorMessage={
                    form.touched.passwordConfirm && form.errors.passwordConfirm ? form.errors.passwordConfirm : null
                  }
                  errorClassName="set-password-confirm-error"
                />

                <button
                  id="setPasswordSubmitCta"
                  className="font-pt-sans bg-brown-prime w-full h-10 border-none mt-25px px-33px text-white-true tracking-wider hover:bg-brown-hard active:bg-brown-hard cursor-pointer"
                  disabled={loading}
                >
                  Submit
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  };

  if (!isOpen) {
    return null;
  }

  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      modalWindowClassName={modalWidthClassname}
      shouldCloseByClickingOutside
    >
      <div
        className={classnames(
          'reset-password-modal flex justify-center pt-55px pb-65px w-screen max-w-330px',
          modalWidthClassname
        )}
      >
        <div
          className={classnames('reset-password-modal-content', {
            'max-w-300px': isMobile,
            'max-w-312px': !isMobile,
          })}
        >
          <div className="reset-password-modal-title flex flex-col items-center">
            {!loading && renderTitle(success)}
          </div>

          {errorMessage && <Warning message={errorMessage} />}

          {loading && <p className="font-pt-sans mt-5 text-center text-black text-base leading-xl">Please wait ...</p>}

          {!loading && success && (
            <p className="font-pt-sans mt-25px text-black text-base leading-xl text-center mb-0">
              You can login with your new password.
            </p>
          )}

          {!loading && !success && renderContent()}
        </div>
      </div>
    </GeneralModal>
  );
};
