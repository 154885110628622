import { initialState, ITaLogoSubdomain } from './model';
import * as Actions from './actions';
import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';

const taLogoReducer = (state: ITaLogoSubdomain = initialState, action: Actions.TaLogoAction) => {
  switch (action.type) {
    case Actions.GET_TA_LOGO_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.taLogo = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_TA_LOGO_SUCCESS:
      return produce(state, draftState => {
        draftState.networkRequests.taLogo = ENetworkRequestStatus.SUCCESS;
        draftState.taLogo = action.taLogo;
        return draftState;
      });

    case Actions.GET_TA_LOGO_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.taLogo = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    default:
      return state;
  }
};

export default taLogoReducer;
