import React from 'react';
import { IHotel, IMealPlanProduct, IMealPlanProductOptions, IProduct } from 'services/BackendApi';
import * as Inputs from '../Inputs';
import produce from 'immer';
import { isNil } from 'lodash-es';
import FluidButton from 'ui/FluidButton';
import { validateMealPlan } from 'containers/HotelAdmin/validation';
import { useDispatch } from 'react-redux';
import { enqueueNotification } from 'store/modules/ui';

/**
 * perAccommodationProduct: 'Per accomodation',
  perBooking: 'Per booking',
  perNight: 'Per night',
  perPerson: 'Per person',
  perPersonPerNight: 'Per person per night',

  RO: 'Room Only',
  BB: 'Breakfast',
  HB: 'Half board',
  FB: 'Full board',
  AI: 'All inclusive',
 */

export interface iMealPlanProps {
  hotel: IHotel;
  mealPlan: Partial<IMealPlanProduct>;
  onUpdate: (field: string, newValue: any) => void;
  onCta: () => void;
  ctaLabel: string;
  mode: 'create' | 'edit';
}

const MealPlanTypes = {
  RO: 'Room Only',
  BB: 'Breakfast',
  HB: 'Half board',
  FB: 'Full board',
  AI: 'All inclusive',
};

export const MealPlan = (props: iMealPlanProps) => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Input
        label="Name"
        onChange={val => {
          props.onUpdate('name', val);
        }}
        value={props.mealPlan.name || ''}
      />

      <Inputs.TextArea
        label="Description"
        onChange={val => {
          props.onUpdate('meta', {
            ...props.mealPlan.meta,
            description: val,
          });
        }}
        value={props.mealPlan.meta?.description || ''}
      />

      <Inputs.RadioCollection
        label="Meal Type"
        collection={Object.keys(MealPlanTypes).map(k => {
          return {
            isChecked: props.mealPlan.meta?.categoryType === k,
            value: k,
            label: MealPlanTypes[k],
          };
        })}
        onChange={val => {
          props.onUpdate('meta', {
            ...props.mealPlan.meta,
            categoryType: val,
          });
        }}
      />

      <Inputs.AgeGroups
        label="Age Groups"
        collection={props.mealPlan.options?.ages || []}
        onChange={newAgeGroups => {
          props.onUpdate('options', {
            ...props.mealPlan.options,
            ages: newAgeGroups,
          });
        }}
      />

      <Inputs.RadioCollection
        label="Category"
        collection={[...Inputs.mealPlanCategoryTypes].map(mt => {
          return {
            isChecked: props.mealPlan.category === mt.value,
            value: mt.value,
            label: mt.label,
          };
        })}
        onChange={val => {
          props.onUpdate('category', val);
        }}
      />

      <FluidButton
        type="primary"
        className="w-[200px] self-end"
        onClick={() => {
          console.log('props.mealPlan', JSON.stringify(props.mealPlan, null, 2));
          const validation = validateMealPlan(props.mealPlan as IMealPlanProduct);
          if (validation.isValid === false) {
            dispatch(
              enqueueNotification({
                message: `Meal plan "${props.mealPlan.name}" is not valid: ${validation.message}`,
                options: { variant: 'warning' },
              })
            );
            return;
          } else {
            props.onCta();
          }
        }}
      >
        {props.ctaLabel}
      </FluidButton>
    </div>
  );
};
