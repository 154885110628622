import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Actions from './actions';
import { ISRDomain, initialState } from './model';
import { update } from 'utils/immutable';

export const srReducer = (
  state: ISRDomain = initialState,
  action: Actions.SRAction
): ISRDomain => {
  switch (action.type) {
    case Actions.GET_LIST_REQUEST:
      return update(state, s => {
        s.request.getList = ENetworkRequestStatus.PENDING;
      });

    case Actions.GET_LIST_SUCCESS:
      return update(state, s => {
        s.request.getList = ENetworkRequestStatus.SUCCESS;
        s.list = action.srList;
      });

    case Actions.GET_LIST_FAILURE:
      return update(state, s => s.request.getList = ENetworkRequestStatus.ERROR);

    default:
      return state;
  }
};

export default srReducer;
