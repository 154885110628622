import styled from 'styled-components';

export const AddresseeModalStyles = styled.div`
  .fieldError .surrogate {
    border: 1px solid #D4244E;
  }
  
  .fieldDisabled .surrogate {
    border: none;
    color: #968F8B; 
  }
  
  .fieldDisabled input {
    color: #968F8B;
    background: #f4f4f3;
  }
`;
