import { createSelector } from 'reselect';
import { ITravelAgentDomain } from './model';

export const domainSelector = (state: any): ITravelAgentDomain => state.travelAgent;

export const travelAgentSelector = createSelector(
  domainSelector,
  (domain: ITravelAgentDomain) => domain.travelAgent
);

export const travelAgentGetRequestSelector = createSelector(
  domainSelector,
  (domain: ITravelAgentDomain) => domain.request.get
);

export const travelAgentCreateRequestSelector = createSelector(
  domainSelector,
  (domain: ITravelAgentDomain) => domain.request.create
);

export const travelAgentUpdateRequestSelector = createSelector(
  domainSelector,
  (domain: ITravelAgentDomain) => domain.request.update
);

export const companyFilterCountrySelector = createSelector(
  domainSelector,
  (domain: ITravelAgentDomain) => domain.taCompanies.companyCountry
);

export const companyFilterNameSelector = createSelector(
  domainSelector,
  (domain: ITravelAgentDomain) => domain.taCompanies.companyName
);

export const filteredTaCompaniesSelector = createSelector(
  domainSelector,
  (domain: ITravelAgentDomain) => domain.taCompanies.filteredCompanies
);

export const taCompaniesModalVisibleSelector = createSelector(
  domainSelector,
  (domain: ITravelAgentDomain) => domain.taCompanies.taCompaniesModalVisible
);

export const selectedTaCompanySelector = createSelector(
  domainSelector,
  (domain: ITravelAgentDomain) => domain.taCompanies.selectedCompany
);
