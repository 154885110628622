import React, { HTMLProps } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BookingStatusTypes } from 'config/enums';

export interface BookingStatusProps extends HTMLProps<HTMLDivElement> {
  status: string;
  children?: React.ReactNode;
}
export const statusToColorMap = {
  [BookingStatusTypes.POTENTIAL]: '#4D98FE',
  [BookingStatusTypes.ENQUIRY]: '#4D98FE',
  [BookingStatusTypes.REQUESTED]: '#F49C20',
  [BookingStatusTypes.CONFIRMED]: '#27BE58',
  [BookingStatusTypes.CANCELLED]: '#FD4B4C',
  [BookingStatusTypes.COMPLETED]: '#A08264',
  [BookingStatusTypes.DISCARDED]: '#CECCC9',
};

const Indicator = styled.div`
  border-radius: 50%;
  background-color: ${props => props.color};
  height: 0.75em;
  width: 0.75em;
  margin-right: 0.75em;
`;

const IndicatorWithoutMargins = styled.div`
  border-radius: 50%;
  background-color: ${props => props.color};
  height: 0.75em;
  width: 0.75em;
`;

export interface IndicatorByStatusProps {
  status: any;
  className?: string;
}

export const IndicatorByStatus = (props: IndicatorByStatusProps) => {
  return <IndicatorWithoutMargins className={props.className || ''} color={statusToColorMap[props.status]} />;
};

const BookingStatus = (props: BookingStatusProps) => {
  const { t } = useTranslation();

  return (
    // @ts-ignore
    <div className={props.className}>
      <Indicator color={statusToColorMap[props.status]} />
      {t(`labels.${props.status}`)}
    </div>
  );
};

export default styled(BookingStatus)`
  display: flex;
  align-items: center;
`;
