import { LiveRatesData } from 'services/BackendApi';

export const GET_LIVE_RATES_REQUEST = 'bookingBuilder/GET_LIVE_RATES_REQUEST';
export const GET_LIVE_RATES_SUCCESS = 'bookingBuilder/GET_LIVE_RATES_SUCCESS';
export const GET_LIVE_RATES_FAILURE = 'bookingBuilder/GET_LIVE_RATES_FAILURE';

export type GetLiveRatesRequestAction = ReturnType<typeof getLiveRatesRequestAction>;
export const getLiveRatesRequestAction = (hotelUuid: string, accommodationTab: number) => ({
  type: GET_LIVE_RATES_REQUEST as typeof GET_LIVE_RATES_REQUEST,
  hotelUuid,
  accommodationTab,
});

export type GetLiveRatesSuccessAction = ReturnType<typeof getLiveRatesSuccessAction>;
export const getLiveRatesSuccessAction = (liveRatesData: LiveRatesData) => ({
  type: GET_LIVE_RATES_SUCCESS as typeof GET_LIVE_RATES_SUCCESS,
  liveRatesData,
});

export type GetLiveRatesFailureAction = ReturnType<typeof getLiveRatesFailureAction>;
export const getLiveRatesFailureAction = (error: string) => ({
  type: GET_LIVE_RATES_FAILURE as typeof GET_LIVE_RATES_FAILURE,
  error,
});

export type LiveRatesAction =
  | GetLiveRatesRequestAction
  | GetLiveRatesSuccessAction
  | GetLiveRatesFailureAction;
