import React from 'react';
import classnames from 'classnames';

export const TravelCompaniesListHeader: React.FC = React.memo(() => {
  const textClassName = 'text-gray-100 tracking-2xs text-xs leading-16px';

  return (
    <div className="travel-companies-list-header bg-ivory uppercase h-10 flex items-center">
      <div className="travel-companies-list-header-title w-200px">
        <label className="travel-companies-list-row-checkbox-label flex flex-row items-center pl-3px">
          <span className={classnames('ml-2', textClassName)}>TRAVEL COMPANY</span>
        </label>
      </div>
      <div className="travel-companies-list-header-country w-135px ml-5">
        <span className={classnames(textClassName)}>COUNTRY</span>
      </div>
    </div>
  );
});
