import { createSelector } from 'reselect';
import { ITaLogoSubdomain } from './model';
import { taLogoSubdomainSelector } from '../../selectors';

export const taLogoSelector = createSelector(
  taLogoSubdomainSelector,
  (subdomain: ITaLogoSubdomain) => subdomain.taLogo
);

export const taLogoNetworkRequestsSelector = createSelector(
  taLogoSubdomainSelector,
  (subdomain: ITaLogoSubdomain) => subdomain.networkRequests
);
