import { ICountryDefinition, TCountryCode } from 'interfaces';
import countries from '../config/data/countries.json';

export const getCountry = (countryCode: TCountryCode): ICountryDefinition => {
  return countries[countryCode] as ICountryDefinition;
};

export const getCountryName = (countryCode: TCountryCode): string => {
  return getCountry(countryCode).name;
};
