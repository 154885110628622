import { createSelector } from 'reselect';

import { bookingCommentsSubdomainSelector } from 'store/modules/bookingManager/selectors';
import { IBookingManagerCommentsSubdomain } from '../../model';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { IBookingComment } from 'services/BookingManagerApi';

export const bookingCommentsSelector = createSelector(
  bookingCommentsSubdomainSelector,
  (commentsSubdomain: IBookingManagerCommentsSubdomain) => commentsSubdomain.comments
);

export const publicCommentsSelector = createSelector(bookingCommentsSelector, (comments: IBookingComment[]) =>
  comments.filter(comment => comment.type === 'public')
);

export const privateCommentsSelector = createSelector(bookingCommentsSelector, (comments: IBookingComment[]) =>
  comments.filter(comment => comment.type === 'private')
);

export const publicCommentDraftSelector = createSelector(
  bookingCommentsSubdomainSelector,
  (commentsSubdomain: IBookingManagerCommentsSubdomain) => commentsSubdomain.publicCommentDraft
);

export const privateCommentDraftSelector = createSelector(
  bookingCommentsSubdomainSelector,
  (commentsSubdomain: IBookingManagerCommentsSubdomain) => commentsSubdomain.privateCommentDraft
);

export const addBookingCommentLoadSelector = createSelector(
  bookingCommentsSubdomainSelector,
  (commentsSubdomain: IBookingManagerCommentsSubdomain): ENetworkRequestStatus =>
    commentsSubdomain.addBookingCommentLoad
);

export const getBookingCommentsLoadSelector = createSelector(
  bookingCommentsSubdomainSelector,
  (commentsSubdomain: IBookingManagerCommentsSubdomain): ENetworkRequestStatus =>
    commentsSubdomain.getBookingCommentsLoad
);

export const bookingCommentUuidsLoadingSelector = createSelector(
  bookingCommentsSubdomainSelector,
  (commentsSubdomain: IBookingManagerCommentsSubdomain): string[] => commentsSubdomain.bookingCommentUuidsLoading
);

export const bookingCommentUuidsEditingSelector = createSelector(
  bookingCommentsSubdomainSelector,
  (commentsSubdomain: IBookingManagerCommentsSubdomain): string[] => commentsSubdomain.bookingCommentUuidsEditing
);

export const bookingCommentUuidsEditingDraftsSelector = createSelector(
  bookingCommentsSubdomainSelector,
  (commentsSubdomain: IBookingManagerCommentsSubdomain): { [key: string]: string } =>
    commentsSubdomain.bookingCommentUuidsEditingDrafts
);

export const latest10PublicCommentsSelector = createSelector(publicCommentsSelector, (comments: IBookingComment[]) =>
  [...comments].sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1)).slice(0, 10)
);

export const latest10CommentsSelector = createSelector(bookingCommentsSelector, (comments: IBookingComment[]) =>
  [...comments].sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1)).slice(0, 10)
);
