import { omit, pick } from 'lodash-es';
import { createSelector } from 'reselect';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { bespokeNegotiationSubdomainSelector } from '../../selectors';
import { managedBySelector } from '../ownership/selectors';

export const requestSelector = createSelector(
  bespokeNegotiationSubdomainSelector,
  (subdomain) => subdomain.request
);

export const getLoadingSelector = createSelector(
  requestSelector,
  (req) => req.get === ENetworkRequestStatus.PENDING
);

export const updateLoadingSelector = createSelector(
  requestSelector,
  (req) => req.patch === ENetworkRequestStatus.PENDING
);

export const itemsSelector = createSelector(
  bespokeNegotiationSubdomainSelector,
  (subdomain) => subdomain.items
);

export const editingSelector = createSelector(
  bespokeNegotiationSubdomainSelector,
  (subdomain) => subdomain.editing
);

export const versionsSelector = createSelector(
  itemsSelector, editingSelector,
  (items, editing) => {
    const xs = items?.length
      ? items
      : [editing];
      
    return xs.map(x => x?.version);
  }
);

export const leadsSelector = createSelector(
  managedBySelector, itemsSelector, editingSelector,
  (managedBy, items, editing) => {
    const currentSRs = (managedBy || []).map(x  => pick(x, ['uuid', 'email']));
    const edited = items?.find(x => x.version === editing?.version);

    return edited && !currentSRs.find(x => x.uuid === edited.lead.uuid)
      ? [...currentSRs, edited.lead]
      : currentSRs;
  }
);

export const saveAsNewSelector = createSelector(
  bespokeNegotiationSubdomainSelector,
  (subdomain) => subdomain.saveAsNew
);

export const updatePayloadSelector = createSelector(
  editingSelector, saveAsNewSelector,
  (editing, saveAsNew) => saveAsNew
    ? omit(editing, 'version')
    : editing
);

export const canSubmitSelector = createSelector(
  editingSelector,
  (x) => x && 
    x.lead &&
    x.discount.amount &&
    x.discount.type &&
    x.appliedTo.length &&
    x.affectedItems.length
);
