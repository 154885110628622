import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface LinkProps {
  to: string;
  type?: 'internal' | 'external';
  className?: string;
  internalClassName?: string;
  target?: string;
  children?: React.ReactNode;
  isNewTab?: boolean;
  styledAsButton?: boolean;
}

export const Link: React.FC<LinkProps> = ({
  children,
  to,
  type = 'internal',
  className = '',
  internalClassName = '',
  target,
  isNewTab = false,
  styledAsButton = false,
}) => {
  if (styledAsButton) {
    internalClassName += ` align-middle inline-flex items-center h-35px px-3 border-brown-100 hover:bg-brown-120 bg-brown-100 cursor-pointer text-ivory hover:text-ivory rounded`;
  }
  return (
    <span className={className}>
      {type === 'external' && (
        <a
          className={internalClassName}
          {...{
            target: isNewTab ? '_blank' : undefined,
            rel: isNewTab ? 'noopener noreferrer' : undefined,
          }}
          href={to}
          target={target}
        >
          {children}
        </a>
      )}
      {type === 'internal' && (
        <RouterLink
          {...{
            target: isNewTab ? '_blank' : undefined,
            rel: isNewTab ? 'noopener noreferrer' : undefined,
          }}
          to={to}
          target={target}
        >
          <span className={internalClassName}>{children}</span>
        </RouterLink>
      )}
    </span>
  );
};
