import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { makeBookingManagerApi, IInvoiceAddresseeResponse } from 'services/BookingManagerApi';
import {
  GET_INVOICE_ADDRESSEE_REQUEST,
  getInvoiceAddresseeSuccessAction,
  getInvoiceAddresseeFailureAction,
  SAVE_INVOICE_ADDRESSEE_REQUEST,
  SaveInvoiceAddresseeRequestAction,
  saveInvoiceAddresseeSuccessAction,
  saveInvoiceAddresseeFailureAction,
  closeAddresseeModalAction,
  GET_MANUAL_INVOICE_ADDRESSE_REQUEST,
  SAVE_MANUAL_INVOICE_ADDRESSEE_REQUEST,
  getManualInvoiceAddresseeSuccessAction,
  getManualInvoiceAddresseeFailureAction,
  SaveManualInvoiceAddresseeRequestAction,
  saveManualInvoiceAddresseeSuccessAction,
  closeAddresseeManualModalAction,
  saveManualInvoiceAddresseeFailureAction,
} from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { bookingUuidSelector } from 'store/modules/bookingManager/selectors';
import { breakdownCreateInvoiceSelector, breakdownCreateManualInvoiceSelector } from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import { EInvoiceAddresseeType } from 'store/modules/bookingManager/subdomains/breakdown/model';
import { enqueueNotification } from 'store/modules/ui';

export function* getInvoiceAddresseeSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);
    const createInvoice = yield select(breakdownCreateInvoiceSelector);

    const getInvoiceAddresseeFunction =
      createInvoice.invoiceAddresseeType === EInvoiceAddresseeType.TRAVEL_PARTNER
        ? bookingManagerApi.getTravelPartnerInvoiceAddressee
        : bookingManagerApi.getFinalClientInvoiceAddressee;

    const result: AxiosResponse<IInvoiceAddresseeResponse> = yield call(getInvoiceAddresseeFunction, bookingUuid);

    yield put(getInvoiceAddresseeSuccessAction(result.data.invoiceAddressee));
  } catch (e) {
    yield put(getInvoiceAddresseeFailureAction(e));
  }
}

export function* getManualInvoiceAddresseeSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);
    const createManualInvoice = yield select(breakdownCreateManualInvoiceSelector);

    const getManualInvoiceAddresseeFunction =
    createManualInvoice.invoiceAddresseeType === EInvoiceAddresseeType.TRAVEL_PARTNER
        ? bookingManagerApi.getTravelPartnerInvoiceAddressee
        : bookingManagerApi.getFinalClientInvoiceAddressee;

    const result: AxiosResponse<IInvoiceAddresseeResponse> = yield call(getManualInvoiceAddresseeFunction, bookingUuid);

    yield put(getManualInvoiceAddresseeSuccessAction(result.data.invoiceAddressee));
  } catch (e) {
    yield put(getManualInvoiceAddresseeFailureAction(e));
  }
}

export function* saveManualInvoiceAddresseeSaga(action: SaveManualInvoiceAddresseeRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);
    const createManualInvoice = yield select(breakdownCreateManualInvoiceSelector);

    const saveManualInvoiceAddresseeFunction =
    createManualInvoice.invoiceAddresseeType === EInvoiceAddresseeType.TRAVEL_PARTNER
        ? bookingManagerApi.saveTravelPartnerInvoiceAddressee
        : bookingManagerApi.saveFinalClientInvoiceAddressee;

    const result: AxiosResponse<IInvoiceAddresseeResponse> = yield call(
      saveManualInvoiceAddresseeFunction,
      bookingUuid,
      action.invoiceAddressee
    );

    yield put(saveManualInvoiceAddresseeSuccessAction(result.data.invoiceAddressee));
    yield put(
      enqueueNotification({
        message: 'The invoice holder was updated and saved',
        options: { variant: 'success' },
      })
    );
    yield put(closeAddresseeManualModalAction());
  } catch (e) {
    yield put(saveManualInvoiceAddresseeFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'There was an error saving invoice holder',
        options: { variant: 'error' },
      })
    );
  }
}

export function* saveInvoiceAddresseeSaga(action: SaveInvoiceAddresseeRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);
    const createInvoice = yield select(breakdownCreateInvoiceSelector);

    const saveInvoiceAddresseeFunction =
      createInvoice.invoiceAddresseeType === EInvoiceAddresseeType.TRAVEL_PARTNER
        ? bookingManagerApi.saveTravelPartnerInvoiceAddressee
        : bookingManagerApi.saveFinalClientInvoiceAddressee;

    const result: AxiosResponse<IInvoiceAddresseeResponse> = yield call(
      saveInvoiceAddresseeFunction,
      bookingUuid,
      action.invoiceAddressee
    );

    yield put(saveInvoiceAddresseeSuccessAction(result.data.invoiceAddressee));
    yield put(
      enqueueNotification({
        message: 'The invoice holder was updated and saved',
        options: { variant: 'success' },
      })
    );
    yield put(closeAddresseeModalAction());
  } catch (e) {
    yield put(saveInvoiceAddresseeFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'There was an error saving invoice holder',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchInvoiceAddresseeSaga() {
  yield takeLatest([GET_INVOICE_ADDRESSEE_REQUEST], getInvoiceAddresseeSaga);
  yield takeLatest([GET_MANUAL_INVOICE_ADDRESSE_REQUEST], getManualInvoiceAddresseeSaga);
  yield takeLatest([SAVE_INVOICE_ADDRESSEE_REQUEST], saveInvoiceAddresseeSaga);
  yield takeLatest([SAVE_MANUAL_INVOICE_ADDRESSEE_REQUEST], saveManualInvoiceAddresseeSaga);
}
