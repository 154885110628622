import React from 'react';
import { EImportEntity } from '../../store/modules/importer/model';
import { ancillarySuppliersImportDomainSelector } from '../../store/modules/ancillarySuppliersImport/selectors';
import { useSelector } from 'react-redux';
import { AncillaryImporterTemplateContainer } from '../AncillaryImporterTemplating/importer';

export const AncillarySuppliersImporterContainer = () => {
  const importerData = useSelector(ancillarySuppliersImportDomainSelector);

  return (
    <AncillaryImporterTemplateContainer
      importerEntity={EImportEntity.ANCILLARY_SUPPLIERS}
      importerData={importerData}
      importerDomainName="Suppliers"
      importerPageTitle={<h1 className="font-normal font-noe-display text-[36px] leading-46px">Suppliers</h1>}
    />
  );
};
