import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBookingManagerApi } from 'services/BookingManagerApi';
import {
  getBookingTypeSuccessAction,
  getBookingTypeFailureAction,
  GET_BOOKING_TYPE_REQUEST,
} from '../actions';

import { bookingUuidSelector } from '../../../selectors';
import { IGetBookingTypeResponse } from 'services/BookingManagerApi/types/BookingTypeResponse';

export function* getBookingTypeSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IGetBookingTypeResponse> = yield call(
      bookingManagerApi.getBookingType,
      bookingUuid
    );

    yield put(getBookingTypeSuccessAction(result.data.bookingType));
  } catch (e) {
    yield put(getBookingTypeFailureAction(e));
  }
}

export function* watchGetBookingTypeSaga() {
  yield takeLatest([GET_BOOKING_TYPE_REQUEST], getBookingTypeSaga);
}
