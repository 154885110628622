import React, { useCallback, useState } from 'react';
import { MenuButton as MenuButtonComponent, IOption } from './index';

export interface IMenuButtonProps {
  className?: string;
  innerClassName?: string;
  options: IOption[];
  onSelect: (IOption) => void;
  fixedWidth?: string;
  isLoading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  rounded?: boolean;
}

export const MenuButton: React.FC<IMenuButtonProps> = React.memo(props => {
  const [isOpen, open] = useState(false);

  const handleClick = useCallback(() => open(!isOpen), [isOpen]);

  const handleClickOutside = useCallback(() => open(false), []);

  const handleSelect = useCallback(
    ops => {
      props.onSelect(ops);
      open(false);
    },
    [props.onSelect]
  );

  return (
    <MenuButtonComponent
      {...props}
      isOpen={isOpen}
      onClick={handleClick}
      onClickOutside={handleClickOutside}
      onSelect={handleSelect}
    />
  );
});
