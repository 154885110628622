import React, { RefObject, forwardRef, useCallback } from 'react';
import classnames from 'classnames';

import { theme } from '../../../tailwind.config';
import { HidingTooltip } from 'ui/Tooltip';
import { Link } from 'ui/Link';
import classNames from 'classnames';

export interface IInvHeaderIconProps {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  tooltipText?: string;
  url?: string;
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
  className?: string;
  iconClassName?: string;
  isExternal?: boolean;
  isNewTab?: boolean;
}

export const InvHeaderIcon = forwardRef<HTMLSpanElement, IInvHeaderIconProps>(
  ({ Icon, url, onClick, tooltipText, className, isExternal = false, isNewTab = false, iconClassName }, ref) => {
    const renderTooltip = useCallback(() => {
      return (
        tooltipText !== undefined && (
          <div className="inv-tooltip text-xs leading-14px p-5px font-pt-sans border border-gray-10 text-left">
            {tooltipText}
          </div>
        )
      );
    }, [tooltipText]);

    return (
      <div
        className={classnames(
          'inv-header-icon text-black min-w-8 min-h-8 max-w-8 max-h-8 flex items-center justify-center cursor-pointer hover:bg-gray-10 rounded-full',
          className
        )}
      >
        <HidingTooltip renderTooltipContent={renderTooltip} position="bottom">
          {url && !isExternal && (
            <Link to={url} isNewTab={isNewTab}>
              <span className="flex">
                <Icon className={classNames("w-[32px]", iconClassName)} fill={theme.colors['black']} />
              </span>
            </Link>
          )}
          {url && isExternal && (
            <a
              href={url}
              {...{
                target: isNewTab ? '_blank' : undefined,
                rel: isNewTab ? 'noopener noreferrer' : undefined,
              }}
            >
              <span className="flex">
                <Icon className={classNames("w-[32px]", iconClassName)} fill={theme.colors['black']} />
              </span>
            </a>
          )}
          {onClick && (
            <span className="flex" onClick={onClick} ref={ref}>
              <Icon className={classNames("w-[32px]", iconClassName)} fill={theme.colors['black']} />
            </span>
          )}
        </HidingTooltip>
      </div>
    );
  }
);
