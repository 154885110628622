import { totalCentsAsString } from 'store/modules/bookingBuilder/utils';
import { isNilOrEmpty } from 'ramda-adjunct';

import { TCurrencyCode } from 'interfaces';
import { parseISO, isBefore } from 'date-fns';
import { formatPriceCents, getCurrencySymbol } from 'utils';

interface IGetFromPricesReturn {
  fromPrice: string | null;
  fromPriceBeforeDiscount: string | null;
  appliedOfferNames: string[];
  isLiveRate: boolean;
}

interface iCancellationPolicyWithMeta {
  cancellationPolicy: string;
  meta: {
    isInPast: boolean;
  };
}
export const deadlinesToCancellationPolicies = (
  currencyCode: TCurrencyCode,
  expensesDeadlines:
    | {
        deadline: string;
        amount: number;
      }[]
    | null
    | undefined
) => {
  if (expensesDeadlines === null || expensesDeadlines === undefined) {
    return [];
  }

  let deadlines = expensesDeadlines
    .sort((a, b) => (a.deadline > b.deadline ? 1 : -1))
    .map(ed => {
      const dateObj = parseISO(ed.deadline);
      const [date, time] = ed.deadline.split(' ');
      const [year, month, day] = date.split('-');
      const [hour, minute, second] = time.split(':');
      const deadline = `${day}/${month}/${year} at ${hour}:${minute}`;
      return {
        ...ed,
        amount: `${getCurrencySymbol(currencyCode)}${formatPriceCents(ed.amount, 2)}`,
        deadline,
        dateObj,
      };
    });

  const cancellationPolicys: iCancellationPolicyWithMeta[] = [];
  const now = new Date();

  // build the first one
  if (deadlines.length >= 1) {
    cancellationPolicys.push({
      cancellationPolicy: `Cancel before ${deadlines[0].deadline}* Free`,
      meta: {
        isInPast: isBefore(deadlines[0].dateObj, now),
      },
    });
  }

  // then loop over the others, if we have enough to be worth looping over
  if (deadlines.length >= 2) {
    deadlines.forEach((d, dIdx) => {
      if (deadlines[dIdx + 1] != null) {
        cancellationPolicys.push({
          cancellationPolicy: `Cancel between ${d.deadline}* - ${deadlines[dIdx + 1].deadline}* ${d.amount}`,
          meta: {
            isInPast: isBefore(deadlines[dIdx + 1].dateObj, now),
          },
        });
      }
    });
  }

  // then add 1 final one for the last index item
  if (deadlines.length >= 1) {
    const lastIndex = deadlines.length - 1;
    cancellationPolicys.push({
      cancellationPolicy: `Cancel after ${deadlines[lastIndex].deadline}* ${deadlines[lastIndex].amount}`,
      meta: {
        isInPast: false, // this cancellation policy is always "Cancel *AFTER*" so it's never in the past
      },
    });
  }

  return cancellationPolicys;
};

const getTotalCents = (rate: { totalCents: number }): number => rate.totalCents;

export const getFromPrices = (staticRates, liveRates): IGetFromPricesReturn => {
  let fromPrice: string | null = null;
  let fromPriceBeforeDiscount: string | null = null;
  let appliedOfferNames: string[] = [];
  let isLiveRate: boolean = false;

  const standardOccupancyRate = staticRates.find(sr => sr.isStandardOccupancy);
  const standardOccupancyLiveRate = liveRates?.find(lr => lr.guestAges?.isStandardOccupancy && !isNilOrEmpty(lr.list));

  if (standardOccupancyRate) {
    fromPrice = standardOccupancyRate.totals.total;
    fromPriceBeforeDiscount = standardOccupancyRate.totals.totalBeforeDiscount;
    appliedOfferNames = standardOccupancyRate.appliedOfferNames;
    isLiveRate = false;
  }

  if (standardOccupancyLiveRate) {
    const minLiveRate = standardOccupancyLiveRate.list.reduce((minRate, currentRate) => 
      getTotalCents(currentRate) < getTotalCents(minRate) ? currentRate : minRate, standardOccupancyLiveRate.list[0]
    );
  
    const minLiveRateTotal = totalCentsAsString(minLiveRate.totalCents);
    
    if (!fromPrice  || parseFloat(minLiveRateTotal) < parseFloat(fromPrice)) {
      fromPrice = minLiveRateTotal;
      fromPriceBeforeDiscount = minLiveRateTotal;
      appliedOfferNames = [];
      isLiveRate = true;
    }
  }
  if (!fromPrice && !isNilOrEmpty(liveRates)) {
    // if we DONT have a standard occupancy, instead get the cheapest live rate price
    // (if we have live rates with prices)
    const sortedLiveRates = [...liveRates]
      .filter(lr => !isNilOrEmpty(lr.list)) // ignore live rates that have an empty `list`
      .sort((a, b) => parseFloat(a.list[0].totalCents) - parseFloat(b.list[0].totalCents));

    if (!isNilOrEmpty(sortedLiveRates)) {
      fromPrice = totalCentsAsString(sortedLiveRates[0].list[0].totalCents);
      fromPriceBeforeDiscount = fromPrice;
      appliedOfferNames = [];
      isLiveRate = true;
    }
  }

  return {
    fromPrice,
    fromPriceBeforeDiscount,
    appliedOfferNames,
    isLiveRate
  };
};
