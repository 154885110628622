import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ILedgerTotals } from 'store/modules/ledger/model';
import { ITCBookingsTotals } from 'store/modules/tcBookingStatement/model';
import { bookingLedgerResetAction } from 'store/modules/ledger/actions';
import { tcBookingStatementResetAction } from 'store/modules/tcBookingStatement/actions';
import PiggyBankIcon from 'ui/Icons/piggyBank.component.svg';
import UsdIcon from 'ui/Icons/currency.usd.component.svg';
import EurIcon from 'ui/Icons/currency.eur.component.svg';
import RightIcon from 'ui/Icons/right.component.svg';
import { SvgIcon } from 'ui/SvgIcon';
import { HidingTooltip } from 'ui/Tooltip';

import { format } from './utils';
import { ELedgerType } from './types';
import SingleSelect from 'ui/SingleSelect';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';

interface ILedgerSummaryHeaderProps {
  selectedCompany: string | null;
  selectedCompanyName?: string;
  handleSelectCompany: (value?: string | undefined) => void;
  companyOptions: { value: string; label: string }[];
}

interface ILedgerSummaryTableProps {
  selectedCompany: string | null;
  depositTotals: ILedgerTotals[];
  tcBookingStatementTotals: ITCBookingsTotals[];
}

interface IBookingStatementRowProps {
  company: string;
  row: ILedgerTotals;
  className?: string;
}

interface ITCBookingStatementRowProps {
  company: string;
  row: ITCBookingsTotals;
  className?: string;
}

interface IStatementTableProps {
  company: string;
  totals: ILedgerTotals[];
}

interface ITCStatementTableProps {
  company: string;
  tcTotals: ITCBookingsTotals[];
}

interface IGoToDetailsProps {
  type: ELedgerType;
  company?: string | null;
  currency?: string;
}

const useFormatHelpers = (row: ILedgerTotals | ITCBookingsTotals) => {
  const formatAmount = useMemo(() => format.amount(row.currency), [row.currency]);
  const renderCurrencyIcon = useCallback(() => {
    switch (row.currency) {
      case 'USD':
        return <SvgIcon IconComponent={UsdIcon} className="w-30px h-30px fill-none" />;
      case 'EUR':
        return <SvgIcon IconComponent={EurIcon} className="w-30px h-30px fill-none" />;

      default:
        return null;
    }
  }, [row.currency]);

  return { formatAmount, renderCurrencyIcon };
};

const GoToDetails: React.FC<IGoToDetailsProps> = React.memo(({ type, company, currency }) => {
  const dispatch = useDispatch();

  const renderCircleButton = useCallback(
    (handler?: () => void) => (
      <span className="cursor-pointer" onClick={handler}>
        <SvgIcon IconComponent={RightIcon} defaultFill="#ffffff" hoverFill="#e3dad1" activeFill="#fbfbfa" />
      </span>
    ),
    []
  );

  const renderContent = useCallback(() => {
    const ledgerTypeMap = {
      [ELedgerType.BOOKING_LEDGER]: 'bookings',
      [ELedgerType.DEPOSIT_ACCOUNT]: 'deposits',
    };
    const ledgerType = ledgerTypeMap[type];

    if (company && currency) {
      return renderCircleButton(() => {
        dispatch(bookingLedgerResetAction());
        dispatch(tcBookingStatementResetAction());
        dispatch(push(`/statement/${company}/${ledgerType}/${currency.toLowerCase()}`));
      });
    }

    return <>{renderCircleButton()}</>;
  }, [company, currency, dispatch, renderCircleButton, type]);

  return (
    <HidingTextTooltip
      tooltipContent="Go to Details"
      position="bottom"
      width="86px"
      tooltipContentClassname="px-[7px] py-[5px]"
    >
      <div className="mx-auto mt-2">{renderContent()}</div>
    </HidingTextTooltip>
  );
});

const DepositsTable: React.FC<IStatementTableProps> = React.memo(({ company, totals }) => (
  <div
    className="ledger-summary-deposits-table border border-solid border-gray-20 rounded shadow-pe4"
    style={{ flexBasis: '37%' }}
  >
    <table className="table border-collapse w-full">
      <thead className="border-solid border-b border-gray-20 bg-ivory">
        <tr className="h-10">
          <td className="w-1/2 border-r border-solid border-gray-20 pl-4">
            <div className="flex items-center">
              <PiggyBankIcon fill="#413E3B" />
              <span className="ml-3 font-bold text-17px">Deposits Account</span>
            </div>
          </td>
          <td className="border-r border-solid border-gray-20 w-auto text-center">
            <span className="text-xs tracking-2xs text-gray-100 uppercase">Deposit Balance</span>
          </td>
          <td style={{ width: '12%' }}></td>
        </tr>
      </thead>
      <tbody>
        {totals.map((row, rowI) => (
          <DepositStatementRow
            key={`deposit-statement-row-${rowI}`}
            row={row}
            company={company}
            className={`deposit-statement-row-${rowI}`}
          />
        ))}
      </tbody>
      <tbody></tbody>
    </table>
  </div>
));

const DepositStatementRow: React.FC<IBookingStatementRowProps> = React.memo(({ company, row, className }) => {
  const { formatAmount, renderCurrencyIcon } = useFormatHelpers(row);

  return (
    <tr className={`${className} h-58px border-solid border-b border-gray-20`}>
      <td className="border-r border-solid border-gray-20 pl-4">
        <div className="flex items-center">
          {renderCurrencyIcon()}
          <span className="ml-3">{row.currency} Deposit</span>
        </div>
      </td>
      <td className="border-r border-solid border-gray-20 bg-green-25 text-right pr-15px">
        <span className="text-17px font-bold">{formatAmount(row.netTotal)}</span>
      </td>
      <td className="relative">
        <GoToDetails type={ELedgerType.DEPOSIT_ACCOUNT} company={company} currency={row.currency} />
      </td>
    </tr>
  );
});

const TCBookingStatementRow: React.FC<ITCBookingStatementRowProps> = React.memo(({ company, row, className }) => {
  const { formatAmount, renderCurrencyIcon } = useFormatHelpers(row);

  return (
    <tr className={`${className} h-58px border-solid border-b border-gray-20`}>
      <td className="border-r border-solid border-gray-20 pl-4">
        <div className="flex items-center">
          {renderCurrencyIcon()}
          <span className="ml-3">{row.currency} Bookings</span>
        </div>
      </td>
      <td className="border-r border-solid border-gray-20 bg-green-25 text-right pr-15px">
        <span className="text-17px font-bold">{formatAmount(row.invoiceAmountTotal)}</span>
      </td>
      <td className="border-r border-solid border-gray-20 bg-green-25 text-right pr-15px">
        <span className="text-17px font-bold">{formatAmount(row.paidAmountTotal)}</span>
      </td>
      <td className="border-r border-solid border-gray-20 bg-teal-20 text-right pr-15px">
        <span className="text-17px font-bold">{formatAmount(row.balanceToPayTotal)}</span>
      </td>
      <td className="border-r border-solid border-gray-20 bg-teal-20 text-right pr-15px">
        <span className="text-17px font-bold">{formatAmount(row.overdueTotal)}</span>
      </td>
      <td className="relative">
        <GoToDetails type={ELedgerType.BOOKING_LEDGER} company={company} currency={row.currency} />
      </td>
    </tr>
  );
});

const TCBookingsStatementTable: React.FC<ITCStatementTableProps> = React.memo(({ company, tcTotals }) => (
  <div
    className="ledger-summary-bookings-table border border-solid border-gray-20 rounded  shadow-pe4"
    style={{ flexBasis: '60%' }}
  >
    <table className="table border-collapse w-full h-full">
      <thead className="border-solid border-b border-gray-20 bg-ivory">
        <tr className="h-10">
          <td className="border-r border-solid border-gray-20 pl-4" style={{ width: '28%' }}>
            <span className="font-bold text-17px">Bookings Statement</span>
          </td>
          <td className="border-r border-solid border-gray-20 w-auto text-center">
            <span className="text-xs tracking-2xs text-gray-100 uppercase">Total Invoiced</span>
          </td>
          <td className="border-r border-solid border-gray-20 w-auto text-center">
            <span className="text-xs tracking-2xs text-gray-100 uppercase">Total Paid</span>
          </td>
          <td className="border-r border-solid border-gray-20 w-auto text-center">
            <span className="text-xs tracking-2xs text-gray-100 uppercase">Total to Pay</span>
          </td>
          <td className="border-r border-solid border-gray-20 w-auto text-center">
            <span className="text-xs tracking-2xs text-gray-100 uppercase">Total Overdue</span>
          </td>
          <td style={{ width: '7.5%' }}></td>
        </tr>
      </thead>
      <tbody>
        {tcTotals.map((row, rowI) => (
          <TCBookingStatementRow
            key={`tc-booking-statement-row-${rowI}`}
            row={row}
            company={company}
            className={`tc-booking-statement-row-${rowI}`}
          />
        ))}
      </tbody>
    </table>
  </div>
));

export const LedgerSummaryHeader: React.FC<ILedgerSummaryHeaderProps> = React.memo(
  ({ selectedCompany, selectedCompanyName, handleSelectCompany, companyOptions }) => {
    return (
      <div className="ledger-summary-header flex justify-between items-center">
        <div className="ledger-summary-title flex items-baseline">
          <h1 className="font-noe-display font-normal text-4xl m-0">Statement summary</h1>
          {selectedCompany && (
            <h2 className="ledger-summary-selected-company font-noe-display font-normal text-black text-26px m-0 ml-2">
              - {selectedCompanyName}
            </h2>
          )}
        </div>
        <label className="ledger-summary-companies flex items-center text-black text-xs h-35px">
          <span className="font-bold text-17px mr-5px">Travel Company:</span>
          <SingleSelect
            fieldId="ledger-summary-companies-select"
            className="ledger-summary-companies-select w-290px"
            value={selectedCompany ?? undefined}
            options={companyOptions}
            onChange={handleSelectCompany}
            maxVisibleItems={6}
          />
        </label>
      </div>
    );
  }
);

export const LedgerSummaryTable: React.FC<ILedgerSummaryTableProps> = React.memo(
  ({ selectedCompany, depositTotals, tcBookingStatementTotals }) => {
    return (
      <div className="ledger-summary-table flex justify-between">
        <DepositsTable company={selectedCompany ?? ''} totals={depositTotals} />
        <TCBookingsStatementTable company={selectedCompany ?? ''} tcTotals={tcBookingStatementTotals} />
      </div>
    );
  }
);
