import {
  initialState,
  IBookingManagerBreakdownSubdomain,
  ILiveCancellationModalDataAccom,
  EInvoiceAddresseeType,
  EInvoiceLang,
  initialBamState,
} from './model';
import * as Actions from './actions';
import * as PurchaseCostReviewActions from '../purchaseCostReview/actions';
import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { get, set, cloneDeep } from 'lodash-es';
import {
  IHeadlineLineItemBreakdownLineItem,
  IHeadlineLineItemBreakdown,
  IHeadlineLineItemBreakdownAccommodationLineItem,
  ERateSource,
  IAncillaryData,
  IHeadlineLineItemBreakdownLineItemCustomData,
} from 'services/BookingManagerApi';

const initialiseUnreactiveVariables = (breakdown: IHeadlineLineItemBreakdown) => {
  if (breakdown.Accommodation.items) {
    breakdown.Accommodation.items = breakdown.Accommodation.items.map(item => {
      item.saleCostCentsUnreactive = item.saleCostCents;
      return item;
    });
  }

  return breakdown;
};

const breakdownReducer = (
  state: IBookingManagerBreakdownSubdomain = initialState,
  action: Actions.BreakdownAction | PurchaseCostReviewActions.PurchaseCostReviewAction
) => {
  switch (action.type) {
    case Actions.GET_HEADLINE_LINE_ITEM_BREAKDOWN_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.headlineLineItemBreakdownLoad = ENetworkRequestStatus.PENDING;

        // when we request the headline breakdown, we reset the save back to idle
        draftState.networkRequests.saveAndUpdateHeadlineBreakdownLoad = ENetworkRequestStatus.IDLE;

        // when we request the headline breakdown, we reset download back to idle
        draftState.networkRequests.downloadBreakdown = ENetworkRequestStatus.IDLE;
        return draftState;
      });

    case Actions.GET_HEADLINE_LINE_ITEM_BREAKDOWN_SUCCESS:
      return produce(state, draftState => {
        draftState.headlineLineItemBreakdown = action.headlineLineItemBreakdown;
        draftState.headlineLineItemBreakdownCommited = cloneDeep(action.headlineLineItemBreakdown);
        draftState.headlineLineItemBreakdown = initialiseUnreactiveVariables(draftState.headlineLineItemBreakdown);
        draftState.networkRequests.headlineLineItemBreakdownLoad = ENetworkRequestStatus.SUCCESS;
        draftState.networkRequests.headlineLineItemBreakdownViaVersionReload = ENetworkRequestStatus.SUCCESS;
        if (action.headlineLineItemBreakdownUpdatedAt) {
          draftState.headlineLineItemBreakdownUpdatedAt = action.headlineLineItemBreakdownUpdatedAt;
        }

        // if we've refreshed the headline breakdown, set to false too
        draftState.isHeadlineBreakdownEditedWithoutSaving = false;
        draftState.shouldUserNeedToConfirmCPsAndPTs = false;

        return draftState;
      });

    case Actions.GET_HEADLINE_LINE_ITEM_BREAKDOWN_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.headlineLineItemBreakdownLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.GET_BAR_COMMISSION_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.barCommission = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_BAR_COMMISSION_SUCCESS:
      return produce(state, draftState => {
        draftState.barCommissionPercentage = action.commissionPercentage || null;
        draftState.barCommissionAmount = action.commissionAmount || null;
        return draftState;
      });
    case Actions.UPDATE_BAR_COMMISSION_PERCENTAGE:
      return produce(state, draftState => {
        draftState.barCommissionPercentage = action.commissionPercentage;
        draftState.isHeadlineBreakdownEditedWithoutSaving = true;
        return draftState;
      });
    case Actions.UPDATE_BAR_COMMISSION_AMOUNT:
      return produce(state, draftState => {
        draftState.barCommissionAmount = action.commissionAmount;
        draftState.isHeadlineBreakdownEditedWithoutSaving = true;
        return draftState;
      });
    case Actions.SET_BAR_COMMISSION_ERRORS:
      return produce(state, draftState => {
        draftState.barCommissionErrors = action.errors;
        return draftState;
      });

    case Actions.GET_BAR_COMMISSION_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.barCommission = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_HEADLINE_LINE_ITEM_FIELD:
      return produce(state, draftState => {
        if (draftState.headlineLineItemBreakdown) {
          set(draftState.headlineLineItemBreakdown, action.setPath, action.newValue);
        }

        draftState.isHeadlineBreakdownEditedWithoutSaving = true;

        return draftState;
      });

    case Actions.ADD_HEADLINE_LINE_ITEM:
      return produce(state, draftState => {
        const initialData = action.initialData ? action.initialData : {};
        const blankLineItem: IHeadlineLineItemBreakdownLineItem = {
          title: '',
          tertiaryText: '',
          costBeforeDiscountCents: 0,
          saleCostCents: 0,
          purchaseCostCents: 0,
          ...initialData,
          ancillaryData: action.ancillaryRequestData ? (action.ancillaryRequestData as IAncillaryData) : undefined,
          customData: action.customRequestData
            ? (action.customRequestData as IHeadlineLineItemBreakdownLineItemCustomData)
            : undefined,
        };

        const lineItems = get(draftState.headlineLineItemBreakdown, action.addPath);

        if (action.atIndex !== undefined) {
          lineItems.splice(action.atIndex, 0, blankLineItem);
        } else {
          lineItems.push(blankLineItem);
        }

        if (draftState.headlineLineItemBreakdown) {
          set(draftState.headlineLineItemBreakdown, action.addPath, lineItems);
        }

        draftState.isHeadlineBreakdownEditedWithoutSaving = true;

        return draftState;
      });

    case Actions.REPLACE_HEADLINE_LINE_ITEM:
      return produce(state, draftState => {
        const lineItems = get(draftState.headlineLineItemBreakdown, action.path);

        lineItems[action.index] = action.newLineItem;

        draftState.isHeadlineBreakdownEditedWithoutSaving = true;

        return draftState;
      });

    case Actions.REMOVE_HEADLINE_LINE_ITEM:
      return produce(state, draftState => {
        const lineItems = get(draftState.headlineLineItemBreakdown, action.removePath);

        const lineItem = lineItems[action.index];
        if (lineItem.ancillaryData) {
          draftState.shouldUserNeedToConfirmCPsAndPTs = true;
        }

        lineItems.splice(action.index, 1);

        if (draftState.headlineLineItemBreakdown) {
          set(draftState.headlineLineItemBreakdown, action.removePath, lineItems);
        }

        draftState.isHeadlineBreakdownEditedWithoutSaving = true;

        return draftState;
      });

    case Actions.SET_HEADLINE_LINE_ITEM_BREAKDOWN_NOTES:
      return produce(state, draftState => {
        if (draftState.headlineLineItemBreakdown) {
          draftState.headlineLineItemBreakdown.Notes = action.newNotes;
          draftState.isHeadlineBreakdownEditedWithoutSaving = true;
        }

        return draftState;
      });

    case Actions.SET_HEADLINE_LINE_ITEM_BREAKDOWN_MARGIN_PERCENTAGE:
      return produce(state, draftState => {
        if (draftState.headlineLineItemBreakdown) {
          draftState.headlineLineItemBreakdown.MarginPercentage = action.newMargin;
          draftState.isHeadlineBreakdownEditedWithoutSaving = true;
        }

        return draftState;
      });

    case Actions.SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.saveAndUpdateHeadlineBreakdownLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_SUCCESS:
      return produce(state, draftState => {
        draftState.networkRequests.saveAndUpdateHeadlineBreakdownLoad = ENetworkRequestStatus.SUCCESS;
        draftState.headlineLineItemBreakdown = action.headlineLineItemBreakdown;
        draftState.isHeadlineBreakdownEditedWithoutSaving = false;
        draftState.shouldUserNeedToConfirmCPsAndPTs = false;

        draftState.headlineLineItemBreakdown = initialiseUnreactiveVariables(draftState.headlineLineItemBreakdown);

        return draftState;
      });

    case Actions.SAVE_AND_UPDATE_HEADLINE_BREAKDOWN_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.saveAndUpdateHeadlineBreakdownLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.DOWNLOAD_BREAKDOWN_FOR_TA_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.downloadBreakdown = ENetworkRequestStatus.PENDING;
        draftState.isCreateQuoteModalOpen = false;
        return draftState;
      });

    case Actions.DOWNLOAD_BREAKDOWN_FOR_TA_SUCCESS:
      return produce(state, draftState => {
        draftState.networkRequests.downloadBreakdown = ENetworkRequestStatus.SUCCESS;
        draftState.isCreateQuoteModalOpen = false;
        return draftState;
      });

    case Actions.DOWNLOAD_BREAKDOWN_FOR_TA_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.downloadBreakdown = ENetworkRequestStatus.ERROR;
        draftState.isCreateQuoteModalOpen = false;
        return draftState;
      });

    case Actions.SET_BREAKDOWN_HOLD:
      return produce(state, draftState => {
        if (!draftState.headlineLineItemBreakdown) {
          return draftState;
        }

        if (action.hold === undefined) {
          draftState.headlineLineItemBreakdown.Accommodation.items[action.accommodationIndex].hold = {
            isActive: false,
            holdHours: 0,
            expires: null,
          };
        }

        draftState.headlineLineItemBreakdown.Accommodation.items[action.accommodationIndex].hold = action.hold;

        return draftState;
      });

    case Actions.SET_COMPANY_LOGO:
      return produce(state, draftState => {
        draftState.breakdownLogo = action.logo;
        return draftState;
      });

    case Actions.GET_HEADLINE_BREAKDOWN_VERSION_LIST_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.headlineBreakdownVersionListLoad = ENetworkRequestStatus.PENDING;

        draftState.networkRequests.saveAndUpdateHeadlineBreakdownLoad = ENetworkRequestStatus.IDLE;
        draftState.networkRequests.downloadBreakdown = ENetworkRequestStatus.IDLE;

        return draftState;
      });

    case Actions.GET_HEADLINE_BREAKDOWN_VERSION_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.networkRequests.headlineBreakdownVersionListLoad = ENetworkRequestStatus.SUCCESS;
        draftState.headlineBreakdownVersionList = action.headlineBreakdownVersionList;
      });

    case Actions.SET_HEADLINE_BREAKDOWN_SELECTED_VERSION:
      return produce(state, draftState => {
        draftState.headlineBreakdownSelectedVersion = action.selectedVersion;
        draftState.networkRequests.headlineLineItemBreakdownViaVersionReload = ENetworkRequestStatus.PENDING;
      });

    case Actions.SET_HEADLINE_BREAKDOWN_REAL_LATEST_VERSION:
      return produce(state, draftState => {
        draftState.headlineBreakdownRealLatestVersion = action.realLatestVersion;
      });

    case Actions.DOWNLOAD_BREAKDOWN_FOR_CLIENT_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.downloadBreakdownForClient = ENetworkRequestStatus.PENDING;
        draftState.isCreateQuoteModalOpen = false;
        return draftState;
      });

    case Actions.DOWNLOAD_BREAKDOWN_FOR_CLIENT_SUCCESS:
      return produce(state, draftState => {
        draftState.networkRequests.downloadBreakdownForClient = ENetworkRequestStatus.SUCCESS;
        draftState.isCreateQuoteModalOpen = false;
        return draftState;
      });

    case Actions.DOWNLOAD_BREAKDOWN_FOR_CLIENT_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.downloadBreakdownForClient = ENetworkRequestStatus.ERROR;
        draftState.isCreateQuoteModalOpen = false;
        return draftState;
      });

    case Actions.OPEN_CREATE_INVOICE_MODAL:
      // default bank
      return produce(state, draftState => {
        draftState.createInvoice.isCreateInvoiceModalOpen = true;
        draftState.createInvoice.mode = action.mode;
        draftState.createInvoice.invoiceAddresseeType = EInvoiceAddresseeType.TRAVEL_PARTNER;

        const selected = draftState.paymentMethods.list?.find(
          x => x.code === draftState.paymentMethods.defaultPaymentMethodCode
        );
        draftState.createInvoice.bankAccount = selected?.name;
        return draftState;
      });

    case Actions.CLOSE_CREATE_INVOICE_MODAL:
      return produce(state, draftState => {
        draftState.createInvoice.isCreateInvoiceModalOpen = false;
        draftState.createInvoice.bankAccount = undefined;
        draftState.createInvoice.mode = null;
        draftState.createInvoice.lang = EInvoiceLang.EN;
        return draftState;
      });

    case Actions.OPEN_CREATE_MANUAL_INVOICE_MODAL:
      // default bank
      return produce(state, draftState => {
        draftState.createManualInvoice.isCreateManualInvoiceModalOpen = true;
        draftState.createManualInvoice.mode = action.mode;
        draftState.createManualInvoice.invoiceAddresseeType = EInvoiceAddresseeType.TRAVEL_PARTNER;

        const selected = draftState.paymentMethods.list?.find(
          x => x.code === draftState.paymentMethods.defaultPaymentMethodCode
        );
        draftState.createManualInvoice.bankAccount = selected?.name;
        return draftState;
      });

    case Actions.CLOSE_CREATE_MANUAL_INVOICE_MODAL:
      return produce(state, draftState => {
        draftState.createManualInvoice.isCreateManualInvoiceModalOpen = false;
        draftState.createManualInvoice.bankAccount = undefined;
        draftState.createManualInvoice.mode = null;
        draftState.createManualInvoice.lang = EInvoiceLang.EN;
        return draftState;
      });

    case Actions.OPEN_ADD_ACTIVITY_MODAL:
      return produce(state, draftState => {
        draftState.activity.isAddActivityModalOpen = true;
        return draftState;
      });

    case Actions.CLOSE_ADD_ACTIVITY_MODAL:
      return produce(state, draftState => {
        draftState.activity.isAddActivityModalOpen = false;
        return draftState;
      });

    case Actions.ADD_ANCILLARY_TRANSFERS_MODAL_TOGGLE:
      return produce(state, draftState => {
        draftState.isAddAncillaryTransfersModalOpen = action.isOpen;
        return draftState;
      });

    case Actions.ADD_ANCILLARY_GROUND_SERVICE_MODAL_TOGGLE:
      return produce(state, draftState => {
        draftState.isAddAncillaryGroundServiceModalOpen = action.isOpen;
        return draftState;
      });

    case Actions.ADD_ANCILLARY_ACCOMMODATION_MODAL_TOGGLE:
      return produce(state, draftState => {
        draftState.isAddAncillaryAccommodationModalOpen = action.isOpen;
        return draftState;
      });

    case Actions.SET_MANUAL_INVOICE_BANK_ACCOUNT:
      return produce(state, draftState => {
        draftState.createManualInvoice.bankAccount = action.bankAccount;
        return draftState;
      });

    case Actions.SET_MANUAL_INVOICE_ADDRESSEE_TYPE:
      return produce(state, draftState => {
        draftState.createManualInvoice.invoiceAddresseeType = action.invoiceAddresseeType;
        return draftState;
      });

    case Actions.SET_INVOICE_BANK_ACCOUNT:
      return produce(state, draftState => {
        draftState.createInvoice.bankAccount = action.bankAccount;
        return draftState;
      });

    case Actions.SET_INVOICE_ADDRESSEE_TYPE:
      return produce(state, draftState => {
        draftState.createInvoice.invoiceAddresseeType = action.invoiceAddresseeType;
        return draftState;
      });

    case Actions.SET_INVOICE_LANG:
      return produce(state, draftState => {
        draftState.createInvoice.lang = action.lang;
        return draftState;
      });

    case Actions.SET_MANUAL_INVOICE_LANG:
      return produce(state, draftState => {
        draftState.createManualInvoice.lang = action.lang;
        return draftState;
      });

    case Actions.GENERATE_INVOICE_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.createInvoice = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GENERATE_INVOICE_SUCCESS:
      return produce(state, draftState => {
        draftState.networkRequests.createInvoice = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GENERATE_INVOICE_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.createInvoice = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.GENERATE_MANUAL_INVOICE_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.createManualInvoice = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GENERATE_MANUAL_INVOICE_SUCCESS:
      return produce(state, draftState => {
        draftState.networkRequests.createManualInvoice = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GENERATE_MANUAL_INVOICE_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.createManualInvoice = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_IS_CREATE_QUOTE_MODAL_OPEN:
      return produce(state, draftState => {
        draftState.isCreateQuoteModalOpen = action.value;
        return draftState;
      });

    case Actions.GET_PAYMENT_TERMS_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.paymentTermsLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_PAYMENT_TERMS_SUCCESS:
      return produce(state, draftState => {
        draftState.paymentTerms = action.paymentTerms;
        draftState.networkRequests.paymentTermsLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_PAYMENT_TERMS_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.paymentTermsLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.GET_CANCELLATION_POLICIES_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.cancellationPolicyLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_CANCELLATION_POLICIES_SUCCESS:
      return produce(state, draftState => {
        draftState.cancellationPolicies = action.cancellationPolicy;
        draftState.networkRequests.cancellationPolicyLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_CANCELLATION_POLICIES_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.cancellationPolicyLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.GET_OFFER_TERMS_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.offerTermsLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_OFFER_TERMS_SUCCESS:
      return produce(state, draftState => {
        draftState.offerTerms = action.offerTerms;
        draftState.networkRequests.offerTermsLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_OFFER_TERMS_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.offerTermsLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_PAYMENT_TERMS:
      return produce(state, draftState => {
        draftState.paymentTerms = action.text;
        draftState.isHeadlineBreakdownEditedWithoutSaving = true;
        return draftState;
      });

    case Actions.GET_MEMBERSHIP_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.membershipLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_MEMBERSHIP_SUCCESS:
      return produce(state, draftState => {
        draftState.membership = action.membership;
        draftState.networkRequests.membershipLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_MEMBERSHIP_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.membershipLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_MEMBERSHIP_BENEFITS_DESCRIPTION:
      return produce(state, draftState => {
        if (!draftState.membership) {
          return draftState;
        }
        draftState.membership.benefitsDescription = action.text;
        draftState.isHeadlineBreakdownEditedWithoutSaving = true;
        return draftState;
      });

    case Actions.UPDATE_MEMBERSHIP_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.membershipUpdate = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.UPDATE_MEMBERSHIP_SUCCESS:
      return produce(state, draftState => {
        draftState.membership = action.membership;
        draftState.networkRequests.membershipUpdate = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.UPDATE_MEMBERSHIP_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.membershipUpdate = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_CANCELLATION_POLICIES:
      return produce(state, draftState => {
        draftState.cancellationPolicies = action.text;
        draftState.isHeadlineBreakdownEditedWithoutSaving = true;
        return draftState;
      });

    case Actions.SET_OFFER_TERMS:
      return produce(state, draftState => {
        draftState.offerTerms = action.text;
        draftState.isHeadlineBreakdownEditedWithoutSaving = true;
        return draftState;
      });

    case Actions.UPDATE_POLICIES_AND_TERMS_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.policiesAndTermsUpdate = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.UPDATE_POLICIES_AND_TERMS_SUCCESS:
      return produce(state, draftState => {
        draftState.cancellationPolicies = action.cancellationPolicies;
        draftState.paymentTerms = action.paymentTerms;
        draftState.offerTerms = action.offerTerms;
        draftState.networkRequests.policiesAndTermsUpdate = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.UPDATE_POLICIES_AND_TERMS_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.policiesAndTermsUpdate = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.GET_POLICIES_AND_RESTRICTIONS_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.policiesAndRestrictionsLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_POLICIES_AND_RESTRICTIONS_SUCCESS:
      return produce(state, draftState => {
        draftState.policiesAndRestrictions = action.policiesAndRestrictions;
        draftState.networkRequests.policiesAndRestrictionsLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_POLICIES_AND_RESTRICTIONS_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.policiesAndRestrictionsLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_POLICIES_AND_RESTRICTIONS:
      return produce(state, draftState => {
        draftState.policiesAndRestrictions = action.text;
        draftState.isHeadlineBreakdownEditedWithoutSaving = true;
        return draftState;
      });

    case Actions.SET_IS_BREAKDOWN_EDITED_WITHOUT_SAVING:
      return produce(state, draftState => {
        draftState.isHeadlineBreakdownEditedWithoutSaving = action.value;
        return draftState;
      });

    case Actions.GET_INVOICE_ADDRESSEE_REQUEST:
      return produce(state, draftState => {
        draftState.createInvoice.invoiceAddressee = undefined;
        draftState.networkRequests.invoiceAddresseeLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_INVOICE_ADDRESSEE_SUCCESS:
      return produce(state, draftState => {
        draftState.createInvoice.invoiceAddressee = action.invoiceAddressee;
        draftState.networkRequests.invoiceAddresseeLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_INVOICE_ADDRESSEE_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.invoiceAddresseeLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.GET_MANUAL_INVOICE_ADDRESSE_REQUEST:
      return produce(state, draftState => {
        draftState.createManualInvoice.invoiceAddressee = undefined;
        draftState.networkRequests.invoiceAddresseeLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_MANUAL_INVOICE_ADDRESSE_SUCCESS:
      return produce(state, draftState => {
        draftState.createManualInvoice.invoiceAddressee = action.invoiceAddressee;
        draftState.networkRequests.invoiceAddresseeLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_MANUAL_INVOICE_ADDRESSE_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.invoiceAddresseeLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SAVE_MANUAL_INVOICE_ADDRESSEE_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.invoiceAddresseeSave = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.SAVE_MANUAL_INVOICE_ADDRESSEE_SUCCESS:
      return produce(state, draftState => {
        draftState.createManualInvoice.invoiceAddressee = action.invoiceAddressee;
        draftState.networkRequests.invoiceAddresseeSave = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.SAVE_MANUAL_INVOICE_ADDRESSEE_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.invoiceAddresseeSave = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SAVE_INVOICE_ADDRESSEE_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.invoiceAddresseeSave = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.SAVE_INVOICE_ADDRESSEE_SUCCESS:
      return produce(state, draftState => {
        draftState.createInvoice.invoiceAddressee = action.invoiceAddressee;
        draftState.networkRequests.invoiceAddresseeSave = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.SAVE_INVOICE_ADDRESSEE_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.invoiceAddresseeSave = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.OPEN_ADDRESSEE_MODAL:
      return produce(state, draftState => {
        draftState.createInvoice.isAddresseeModalOpen = true;
        return draftState;
      });

    case Actions.CLOSE_ADDRESSEE_MODAL:
      return produce(state, draftState => {
        draftState.createInvoice.isAddresseeModalOpen = false;
        return draftState;
      });

    case Actions.OPEN_ADDRESSEE_MANUAL_MODAL:
      return produce(state, draftState => {
        draftState.createManualInvoice.isAddresseeModalOpen = true;
        return draftState;
      });

    case Actions.CLOSE_ADDRESSEE_MANUAL_MODAL:
      return produce(state, draftState => {
        draftState.createManualInvoice.isAddresseeModalOpen = false;
        return draftState;
      });

    case Actions.OPEN_PCR_DETAILS_MODAL:
      return produce(state, draftState => {
        draftState.isPcrDetailsModalOpen = true;
        return draftState;
      });

    case Actions.CLOSE_PCR_DETAILS_MODAL:
      return produce(state, draftState => {
        draftState.isPcrDetailsModalOpen = false;
        return draftState;
      });

    case Actions.OPEN_SAVE_TO_PERFORM_ACTION_MODAL:
      return produce(state, draftState => {
        draftState.isSaveToPerformActionModalOpen = true;
        return draftState;
      });

    case Actions.CLOSE_SAVE_TO_PERFORM_ACTION_MODAL:
      return produce(state, draftState => {
        draftState.isSaveToPerformActionModalOpen = false;
        return draftState;
      });

    case Actions.ADD_HEADLINE_LINE_ITEM_ACCOMMODATION:
      return produce(state, draftState => {
        const blankAccommodationItem: IHeadlineLineItemBreakdownAccommodationLineItem = {
          'Meal Plan': {
            subtotalCents: 0,
            preDiscountSubtotalCents: 0,
            purchaseCostSubtotalCents: 0,
            isOnRequest: false,
            items: [
              {
                title: '',
                tertiaryText: '',
                costBeforeDiscountCents: 0,
                saleCostCents: 0,
                purchaseCostCents: 0,
              },
            ],
          },
          Supplement: {
            subtotalCents: 0,
            preDiscountSubtotalCents: 0,
            purchaseCostSubtotalCents: 0,
            isOnRequest: false,
            items: [],
          },
          externalBooking: null,
          title: '',
          tertiaryText: '',
          roomRateSource: ERateSource.STATIC,
          costBeforeDiscountCents: 0,
          saleCostCents: 0,
          purchaseCostCents: 0,
          saleCostCentsUnreactive: 0,
          wasCreatedInstantBook: false,
        };

        draftState.isHeadlineBreakdownEditedWithoutSaving = true;

        draftState.headlineLineItemBreakdown?.Accommodation.items.push(blankAccommodationItem);

        return draftState;
      });

    case Actions.SET_LIVE_ACCOMMODATION_CANCELLATION_MODAL_IS_OPEN:
      return produce(state, draftState => {
        draftState.liveCancellationModalData.isLiveCancellationModalOpen = action.isOpen;
        return draftState;
      });

    case Actions.SET_LIVE_ACCOMMODATION_CANCELLATION_WARNING_MODAL_IS_OPEN:
      return produce(state, draftState => {
        draftState.isLiveCancellationWarningModalOpen = action.isOpen;
        return draftState;
      });

    case Actions.ADD_LIVE_ACCOMMODATION_CANCELLATION_MODAL_ACCOM:
      return produce(state, draftState => {
        const liveData = draftState.liveCancellationAccommodationData.find(
          lcad => lcad.bookingRef === action.liveAccommodationBookingRef
        );

        if (!liveData) {
          return draftState;
        }

        // the rate cents doesn't *technically* live on the liveData, but it gets set there
        // via the saga.
        const newAccom: ILiveCancellationModalDataAccom = {
          bookingRef: action.liveAccommodationBookingRef,
          cancellationFeeCents: liveData.cancellationFeeCents,
          date: '',
          rateCents: liveData.rateCents,

          cancellationPolicies:
            draftState.liveAccommodationCancellationPolicies.find(
              cp => cp.bookingRef === action.liveAccommodationBookingRef
            )?.cancellationPolicies || [],
        };

        draftState.liveCancellationModalData.accoms.push(newAccom);

        return draftState;
      });

    case Actions.ADD_ALL_LIVE_ACCOMMODATION_CANCELLATION_MODAL_ACCOMS:
      return produce(state, draftState => {
        draftState.liveCancellationModalData.accoms = draftState.liveCancellationAccommodationData.map(a => {
          return {
            bookingRef: a.bookingRef,
            cancellationFeeCents: a.cancellationFeeCents,
            date: '',
            rateCents: a.rateCents,

            cancellationPolicies:
              draftState.liveAccommodationCancellationPolicies.find(cp => cp.bookingRef === a.bookingRef)
                ?.cancellationPolicies || [],
          };
        });

        // also add up the total
        draftState.liveCancellationModalData.cancellationFeeCentsTotal = draftState.liveCancellationModalData.accoms.reduce(
          (runningTotal, accom) => {
            return runningTotal + accom.cancellationFeeCents;
          },
          0
        );
        return draftState;
      });

    case Actions.SET_LIVE_ACCOMMODATION_CANCELLATION_MODAL_ACCOM_DATA:
      return produce(state, draftState => {
        const idx = draftState.liveCancellationModalData.accoms.findIndex(
          lcmd => lcmd.bookingRef === action.bookingRef
        );
        draftState.liveCancellationModalData.accoms[idx] = {
          ...draftState.liveCancellationModalData.accoms[idx],
          ...action.accomData,
        };

        // recalculate the total cancellation fee
        draftState.liveCancellationModalData.cancellationFeeCentsTotal = draftState.liveCancellationModalData.accoms.reduce(
          (runningTotal, accom) => {
            return runningTotal + accom.cancellationFeeCents;
          },
          0
        );
        return draftState;
      });

    case Actions.RESET_LIVE_ACCOMMODATION_CANCELLATION_MODAL:
      return produce(state, draftState => {
        draftState.liveCancellationModalData = {
          ...initialState.liveCancellationModalData,
        };
        draftState.networkRequests.postLiveAccommodationCancellationLoad = ENetworkRequestStatus.IDLE;
        return draftState;
      });

    case Actions.POST_LIVE_ACCOMMODATION_CANCELLATION_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.postLiveAccommodationCancellationLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    case Actions.POST_LIVE_ACCOMMODATION_CANCELLATION_SUCCESS:
      return produce(state, draftState => {
        draftState.liveCancellationModalData.isLiveCancellationModalOpen = false;
        draftState.networkRequests.postLiveAccommodationCancellationLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });
    case Actions.POST_LIVE_ACCOMMODATION_CANCELLATION_FAILURE:
      return produce(state, draftState => {
        draftState.liveCancellationModalData.isLiveCancellationModalOpen = false;
        draftState.networkRequests.postLiveAccommodationCancellationLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.GET_LIVE_ACCOMMODATION_CANCELLATION_DATA_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.getLiveAccommodationCancellationDataLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    case Actions.GET_LIVE_ACCOMMODATION_CANCELLATION_DATA_SUCCESS:
      return produce(state, draftState => {
        draftState.networkRequests.getLiveAccommodationCancellationDataLoad = ENetworkRequestStatus.SUCCESS;
        draftState.liveCancellationAccommodationData = [...action.liveAccommodationData];
        return draftState;
      });
    case Actions.GET_LIVE_ACCOMMODATION_CANCELLATION_DATA_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.getLiveAccommodationCancellationDataLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_LIVE_ACCOMMODATION_CANCELLATION_POLICIES:
      return produce(state, draftState => {
        draftState.liveAccommodationCancellationPolicies = action.liveAccommodationCancellationPolicies;
        return draftState;
      });

    case Actions.GET_PAYMENT_METHODS_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.getPaymentMethods = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_PAYMENT_METHODS_SUCCESS:
      return produce(state, draftState => {
        draftState.paymentMethods.list = action.paymentMethods;
        draftState.paymentMethods.defaultPaymentMethodCode = action.defaultPaymentMethodCode || null;
        draftState.networkRequests.getPaymentMethods = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_PAYMENT_METHODS_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.getPaymentMethods = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_IS_ACCOMMODATION_MODIFIED:
      return produce(state, draftState => {
        draftState.shouldUserNeedToConfirmCPsAndPTs = action.value;
        return draftState;
      });

    case Actions.GET_SUPPLIERS_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.getSuppliers = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_SUPPLIERS_SUCCESS:
      return produce(state, draftState => {
        draftState.transfersSuppliers = action.transfersSuppliers;
        draftState.groundServicesSuppliers = action.groundServicesSuppliers;
        draftState.activitiesSuppliers = action.activitiesSuppliers;
        draftState.networkRequests.getSuppliers = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_SUPPLIERS_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.getSuppliers = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_CURRENTLY_EDITING_PRODUCT_DATA:
      return produce(state, draftState => {
        draftState.currentlyEditingLineItem = action.lineItem;
        draftState.currentlyEditingLineItemIndex = action.index;
        return draftState;
      });

      case Actions.GET_SUPPLIERS_REQUEST:
        return produce(state, draftState => {
          draftState.networkRequests.getSuppliers = ENetworkRequestStatus.PENDING;
          return draftState;
        });
  
      case Actions.GET_SUPPLIERS_SUCCESS:
        return produce(state, draftState => {
          draftState.transfersSuppliers = action.transfersSuppliers;
          draftState.groundServicesSuppliers = action.groundServicesSuppliers;
          draftState.activitiesSuppliers = action.activitiesSuppliers;
          draftState.networkRequests.getSuppliers = ENetworkRequestStatus.SUCCESS;
          return draftState;
        });
  
      case Actions.GET_SUPPLIERS_FAILURE:
        return produce(state, draftState => {
          draftState.networkRequests.getSuppliers = ENetworkRequestStatus.ERROR;
          return draftState;
        });

    case Actions.GET_HOTELS_BY_CURRENCY_REQUEST:
      return produce(state, draftState => {
        draftState.accommodation.requests.filterHotelsRequest = ENetworkRequestStatus.PENDING;
        return draftState;
      });
  
    case Actions.GET_HOTELS_BY_CURRENCY_SUCCESS:
      return produce(state, draftState => {
        draftState.accommodation.requests.filterHotelsRequest = ENetworkRequestStatus.SUCCESS;
        draftState.accommodation.data.filterHotels = action.hotels;
        return draftState;
      });
  
    case Actions.GET_HOTELS_BY_CURRENCY_FAILURE:
      return produce(state, draftState => {
        draftState.accommodation.requests.filterHotelsRequest = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_BAM_HOTEL_FILTER:
      return produce(state, draftState => {
        draftState.accommodation.filters.hotelUuid = action.hotelUuid;
        return draftState;
      });

    case Actions.SET_BAM_GUEST_AGES_FILTER:
      return produce(state, draftState => {
        draftState.accommodation.filters.guestAges = action.guestAges;
        return draftState;
      });

    case Actions.SET_BAM_DATES_FILTER:
      return produce(state, draftState => {
        draftState.accommodation.filters.startDate = action.startDate;
        draftState.accommodation.filters.endDate = action.endDate;
        return draftState;
      });
    
    case Actions.SET_BAM_ERROR:
      return produce(state, draftState => {
        draftState.accommodation.error = action.error;
        return draftState;
      });

    case Actions.RESET_BAM_STATE:
      return produce(state, draftState => {
        draftState.accommodation = initialBamState;
        return draftState;
      });
  
    case Actions.SEARCH_BAM_ACCOMMODATIONS_REQUEST:
      return produce(state, draftState => {
        draftState.accommodation.data.selectedMealPlanUuid = [];
        draftState.accommodation.data.isRepeatGuest = false;
        draftState.accommodation.data.selectedOccasions = [];
        draftState.accommodation.data.lastRequestedBuild = null;
        draftState.accommodation.data.selectedBuild = null;
        draftState.accommodation.requests.searchAccommodationsRequest = ENetworkRequestStatus.PENDING;
        return draftState;
      });
  
    case Actions.SEARCH_BAM_ACCOMMODATIONS_SUCCESS:
      return produce(state, draftState => {
        draftState.accommodation.requests.searchAccommodationsRequest = ENetworkRequestStatus.SUCCESS;
        draftState.accommodation.data.accommodationResults = action.accommodations;
        return draftState;
      });
  
    case Actions.SEARCH_BAM_ACCOMMODATIONS_FAILURE:
      return produce(state, draftState => {
        draftState.accommodation.requests.searchAccommodationsRequest = ENetworkRequestStatus.ERROR;
        return draftState;
      });
  
    case Actions.SET_BAM_SELECTED_ACCOMMODATION:
      return produce(state, draftState => {
        draftState.accommodation.data.selectedBuild = action.build;
        return draftState;
      });
    
    case Actions.SET_BAM_LAST_REQUEST:
      return produce(state, draftState => {
        draftState.accommodation.data.lastRequest = action.lastRequest;
        return draftState;
      });
    
    case Actions.SET_BAM_LAST_REQUESTED_BUILD:
      return produce(state, draftState => {
        draftState.accommodation.data.lastRequestedBuild = action.lastRequestedBuild;
        return draftState;
      });
    
    case Actions.SET_BAM_SELECTED_MEAL_PLAN:
      return produce(state, draftState => {
        draftState.accommodation.data.selectedMealPlanUuid = action.mealPlanId;
        return draftState;
      });
  
    case Actions.GET_EXCHANGE_CURRENCY_REQUEST:
      return produce(state, draftState => {
        if (draftState.headlineLineItemBreakdown) {
          draftState.headlineLineItemBreakdown.ExchangeRate = {currency: action.currencyCode, rate: null, timestamp: null};
        }
        draftState.isHeadlineBreakdownEditedWithoutSaving = true;
        draftState.networkRequests.exchangeCurrencyRequest = ENetworkRequestStatus.PENDING;
      })
  
    case Actions.GET_EXCHANGE_CURRENCY_SUCCESS:
      return produce(state, draftState => {
        if (draftState.headlineLineItemBreakdown && draftState.headlineLineItemBreakdown.ExchangeRate) {
          draftState.headlineLineItemBreakdown.ExchangeRate.rate = action.exchangeRate;
          draftState.headlineLineItemBreakdown.ExchangeRate.timestamp = action.exchangeCurrencyTimeStamp;  
        }
        draftState.networkRequests.exchangeCurrencyRequest = ENetworkRequestStatus.SUCCESS;
      })
  
    case Actions.GET_EXCHANGE_CURRENCY_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.exchangeCurrencyRequest = ENetworkRequestStatus.ERROR;
        if (draftState.headlineLineItemBreakdown) {
          draftState.headlineLineItemBreakdown.ExchangeRate = undefined;
        }
        draftState.isHeadlineBreakdownEditedWithoutSaving = false;
      })
   
    case Actions.REFRESH_EXCHANGE_CURRENCY:
      return produce(state, draftState => {
        draftState.networkRequests.exchangeCurrencyRequest = ENetworkRequestStatus.PENDING;
        if (draftState.headlineLineItemBreakdown && draftState.headlineLineItemBreakdown.ExchangeRate) {
          draftState.headlineLineItemBreakdown.ExchangeRate.rate = null;
          draftState.headlineLineItemBreakdown.ExchangeRate.timestamp = null;  
        }
        draftState.isHeadlineBreakdownEditedWithoutSaving = true;
      })
   
    case Actions.RESET_EXCHANGE_CURRENCY:
      return produce(state, draftState => {
        if (draftState.headlineLineItemBreakdown) {
          draftState.headlineLineItemBreakdown.ExchangeRate = undefined;
        }
        draftState.isHeadlineBreakdownEditedWithoutSaving = true;
      })
  
    case Actions.SET_BAM_SELECTED_REPEAT_GUEST:
      return produce(state, draftState => {
        draftState.accommodation.data.isRepeatGuest = action.repeatGuest;
        return draftState;
      });
    
    case Actions.BAM_BOOKING_BUILD_REQUEST:
      return produce(state, draftState => {
        draftState.accommodation.requests.bookingBuildRequest = ENetworkRequestStatus.PENDING;
        return draftState;
      });
  
    case Actions.BAM_BOOKING_BUILD_SUCCESS:
      return produce(state, draftState => {
        draftState.accommodation.requests.bookingBuildRequest = ENetworkRequestStatus.SUCCESS;
        draftState.accommodation.data.selectedBuild = action.build;
        return draftState;
      });
  
    case Actions.BAM_BOOKING_BUILD_FAILURE:
      return produce(state, draftState => {
        draftState.accommodation.requests.bookingBuildRequest = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_BAM_SELECTED_OCCASIONS:
      return produce(state, draftState => {
        draftState.accommodation.data.selectedOccasions = action.occasions;
        return draftState;
      });

    case Actions.BAM_BOOKING_BUILD_REQUEST:
      return produce(state, draftState => {
        draftState.accommodation.requests.bookingBuildRequest = ENetworkRequestStatus.PENDING;
        return draftState;
      });


    default:
      return state;
  }
};

export default breakdownReducer;
