import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash-es';

import { ENetworkRequestStatus, ITravelAgent } from 'services/BackendApi';
import * as Actions from 'store/modules/internalUsersList/actions';
import * as Selectors from 'store/modules/internalUsersList/selectors';

import { LoadingBar, ErrorBar } from 'ui/NetworkStatusBar';
import { Pagination } from 'pureUi/Pagination';

import { Filter } from './filter';
import { Controls } from './controls';
import { Table } from './table';
import { PageTitle } from 'ui/PageTitle';
import { AssignTAModal } from './AssignTAModal';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';

export const List: React.FC = () => {
  const request = useSelector(Selectors.requestSelector);
  const list = useSelector(Selectors.listSelector);
  const pagination = useSelector(Selectors.paginationSelector);
  const toDelete = useSelector(Selectors.toDeleteSelector);
  const selectedRS = useSelector(Selectors.selectedRSSelector);

  const isInitialized = !isNil(pagination.total);
  const isLoading = request.get === ENetworkRequestStatus.PENDING;

  const isError = request.get === ENetworkRequestStatus.ERROR;

  const pageCount = useMemo(
    () => (!isNil(pagination.total) ? Math.ceil(pagination.total / pagination.perPage) : null),
    [pagination.total, pagination.perPage]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.getRequestAction());
  }, [dispatch]);

  const handleDelete = useCallback((x: ITravelAgent) => dispatch(Actions.setToDeleteAction(x)), [dispatch]);

  const handleDeleteConfirm = useCallback(() => dispatch(Actions.deleteRequestAction()), [dispatch]);

  const handleDeleteCancel = useCallback(() => dispatch(Actions.setToDeleteAction(null)), [dispatch]);

  const handlePageSelect = useCallback(page => dispatch(Actions.setPageAction(page - 1)), [dispatch]);

  const handlePerPageChange = useCallback(perPage => dispatch(Actions.setPerPageAction(perPage)), [dispatch]);
  
  const handleOpenAssignTAModal = useCallback(sa => dispatch(Actions.setSelectedSRAction(sa)), [dispatch]);

  return (
    <div className="internal-users-list w-1280px mx-auto">
      <PageTitle
        primaryText="Internal Users"
        secondaryCondition={isInitialized && !isLoading}
        secondaryText={`(${getResultsText(pagination?.total ?? 0)})`}
        loadingCondition={isLoading && isInitialized}
        loadingText="Loading ..."
      />
      {isError && <ErrorBar />}
      {!isError && !isInitialized && <LoadingBar />}
      {!isError && isInitialized && (
        <>
          <div className="controls flex justify-between items-end mt-5">
            <Filter />
            <Controls />
          </div>
          <div className="mt-5">
            <Table
              className="mt-20px"
              travelAgents={list || []}
              onDelete={handleDelete}
              loading={request.get === ENetworkRequestStatus.PENDING}
              selectSR={handleOpenAssignTAModal}
            />
          </div>
          {(list || []).length >= 1 && (
            <Pagination
              className="pagination mt-40px"
              onPageSelect={handlePageSelect}
              pageCount={pageCount || 0}
              currentPage={pagination.page + 1}
              itemsPerPage={pagination.perPage}
              onItemsPerPageChange={handlePerPageChange}
            />
          )}
        </>
      )}
      {!!toDelete && (
        <ConfirmationModal
          className="delete-modal"
          type={EConfirmationModalType.WARNING}
          title="You are going to delete an Internal User."
          message="Are you sure want to delete this item?"
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          isConfirmLoading={request.delete === ENetworkRequestStatus.PENDING}
        />
      )}
      {selectedRS &&(
        <AssignTAModal />
      )}
    </div>
  );
};

const getResultsText = (count: number): string => {
  if (!count) {
    return 'no results found';
  }
  if (count === 1) {
    return '1 result found';
  }
  return `${count} results found`;
};
