import { TCountryCode } from 'interfaces';
import { ENetworkRequestStatus } from 'services/BackendApi';

////// for the availability endpoints
export interface iCompanyMarkupAvailableSupplier {
  supplierId: number;
  name: string;
  countryCode: TCountryCode;
  countryName: string;
  type: string;
}
export interface iCompanyMarkupAvailableHotel {
  uuid: string;
  name: string;
  countryCode: TCountryCode;
  countryName: string;
  type: string;
}
export type iCompanyMarkupOption = iCompanyMarkupAvailableHotel | iCompanyMarkupAvailableSupplier;
//////

export interface iCompanyMarkupSavedHotel {
  type: string;
  companyUuid: string;
  name: string;
  countryCode: TCountryCode;
  markupPercentage: string;
  createdAt: string;
  countryName: string;
  hotelUuid: string;
}
export interface iCompanyMarkupSavedSupplier {
  supplierId: number;
  type: string;
  companyUuid: string;
  name: string;
  countryCode: TCountryCode;
  markupPercentage: number;
  createdAt: string;
  countryName: string;
}
export type iCompanyMarkupOptionSaved = iCompanyMarkupSavedHotel | iCompanyMarkupSavedSupplier;

export type ICompanyMarkupOptionModel = {
  selected: boolean;
  isEditMode: boolean;
  editedValue: string;
} & iCompanyMarkupOptionSaved;

export type CHMarkupSortField = 'type' | 'countryName' | 'name' | 'markupPercentage' | 'createdAt';
export type CHMarkupSortOrder = 'asc' | 'desc';

export interface ChMarkupToDelete {
  companyUuid: string;
  option: ICompanyMarkupOptionModel;
}

export interface IBulkEdit {
  editedValue: string;
}

export interface IBulkDelete {}

export interface ICompanyHotelMarkupDomain {
  companyUuid: string | null;
  list: ICompanyMarkupOptionModel[];
  listLoad: ENetworkRequestStatus;
  listLoadError?: Error;
  sortField?: CHMarkupSortField;
  sortOrder?: CHMarkupSortOrder;

  addMarkupModalOpen: boolean;
  availableHotels: ICompanyMarkupOptionModel[];
  availableHotelsLoad: ENetworkRequestStatus;
  availableHotelsError?: Error;
  createMarkupLoad: ENetworkRequestStatus;
  createMarkupError?: Error;

  editMarkupLoad: ENetworkRequestStatus;
  editMarkupError?: Error;

  chMarkupToDelete?: ChMarkupToDelete;
  deleteLoad: ENetworkRequestStatus;
  deleteError?: Error;

  bulkEdit: IBulkEdit | null;
  bulkDelete: IBulkDelete | null;

  productsWithCompanyHotelMarkup: string[];
  productsWithCompanyHotelMarkupLoad: ENetworkRequestStatus;
  productsWithCompanyHotelMarkupError?: Error;
}

export const initialState: ICompanyHotelMarkupDomain = {
  companyUuid: null,
  list: [],
  listLoad: ENetworkRequestStatus.IDLE,
  addMarkupModalOpen: false,
  availableHotels: [],
  availableHotelsLoad: ENetworkRequestStatus.IDLE,
  createMarkupLoad: ENetworkRequestStatus.IDLE,
  editMarkupLoad: ENetworkRequestStatus.IDLE,
  deleteLoad: ENetworkRequestStatus.IDLE,
  bulkEdit: null,
  bulkDelete: null,
  productsWithCompanyHotelMarkup: [],
  productsWithCompanyHotelMarkupLoad: ENetworkRequestStatus.IDLE,
};
