import React from 'react';
import { EImportEntity } from '../../store/modules/importer/model';
import { ancillaryActivitiesImportDomainSelector } from '../../store/modules/ancillaryActivitiesImport/selectors';
import { ancillaryActivitiesRatesImportDomainSelector } from '../../store/modules/ancillaryActivitiesRatesImport/selectors';
import { useSelector } from 'react-redux';
import { AncillaryImporterTemplateContainer } from '../AncillaryImporterTemplating/importer';
import { SimpleTabs } from 'ui/SimpleTabs';

export const AncillaryActivitiesImporterContainer = () => {
  const activitiesImportData = useSelector(ancillaryActivitiesImportDomainSelector);
  const activitiesRatesImportData = useSelector(ancillaryActivitiesRatesImportDomainSelector);

  return (
    <>
      <div className="container w-full max-w-1280px mx-auto space-y-20px">
        <h1 className="font-normal font-noe-display text-[36px] leading-46px">
          Products - <span className="text-[26px]">Activities</span>
        </h1>
      </div>
      <SimpleTabs
        tabConfig={[
          {
            title: 'Activities',
            name: 'activities',
            styles: 'min-w-150px',
            renderContent: () => (
              <AncillaryImporterTemplateContainer
                importerEntity={EImportEntity.ANCILLARY_ACTIVITIES}
                importerData={activitiesImportData}
                importerDomainName="Activities"
                importerPageTitle={<h2 className="font-bold font-pt-sans text-[17px] leading-22px">Activities</h2>}
              />
            ),
          },
          {
            title: 'Rates',
            name: 'activities-rates',
            styles: 'min-w-150px',
            renderContent: () => (
              <AncillaryImporterTemplateContainer
                importerEntity={EImportEntity.ANCILLARY_ACTIVITIES_RATES}
                importerData={activitiesRatesImportData}
                importerDomainName="Activities Rates"
                importerPageTitle={<h2 className="font-bold font-pt-sans text-[17px] leading-22px">Rates</h2>}
              />
            ),
          },
        ]}
      />
    </>
  );
};
