import React from 'react';
import { IImportErrorItem } from 'services/BackendApi';

export interface ReportErrorListProps {
  className?: string;
  title: string;
  items: Array<IImportErrorItem>;
}

export const ErrorItem = ({ data, errorIndex }: { data: IImportErrorItem; errorIndex: number }) => {
  return (
    <div className="my-[20px] mx-[0]">
      <p className="font-pt-sans text-15px leading-22px italic text-red-95">Error {errorIndex + 1}</p>
      <ul className="list-none list-inside m-0">
        <li className="font-pt-sans text-15px leading-22px text-black">
          {data.ref && <div data-role={'error-ref'}>{data.ref}</div>}
          <div data-role={'error-message-list'}>
            {typeof data === 'string' && (
              <div className="text-gray-darker" data-role={'error-message'} key={data}>
                {data}
              </div>
            )}
            {typeof data !== 'string' &&
              (data.messages || []).map(msg => (
                <div className="text-gray-darker" key={msg}>
                  {msg}
                </div>
              ))}
          </div>
        </li>
      </ul>
    </div>
  );
};

const ReportErrorList = (props: ReportErrorListProps) => {
  const { title, items } = props;

  return (
    <div className="">
      <div data-role={'error-list'}>
        {(items || []).map((item, errorIndex) => (
          <ErrorItem
            data-role={'error-item'}
            key={item.ref || ((item as unknown) as string)}
            data={item}
            errorIndex={errorIndex}
          />
        ))}
      </div>
    </div>
  );
};

export default ReportErrorList;
