import styled from 'styled-components';
import { pureUiTheme } from 'pureUi/pureUiTheme';

export const SingleDateInputStyles = styled.div`
  display: grid;
  
  .pseudoSelect {
    display: flex;
    align-items: center;
    border: ${pureUiTheme.colorRoles.lightGreyBorder} 1px solid;
    position: relative;
    text-transform: uppercase;
    padding: 0 10px;
    font-family: 'HurmeGeometricSans2', 'sans-serif';
    font-size: 14px;
    color: ${pureUiTheme.colors.black};
    text-align: left;
    width: 100%;
    height: 39px;
    color: ${pureUiTheme.colors.black};
    background-color: ${pureUiTheme.colors.white};

    transition: all 0.15s ease-out;
    box-shadow: 0 0 0 2px transparent;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px ${pureUiTheme.colors.marine};
    }
  }
  
  .displayString {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

