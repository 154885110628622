import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const CloseBoldIcon: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors['brown-100'], height = 12, width = 12, onClick, className }) => (
    <svg width={width} height={height} viewBox="0 0 14 13" fill="none" className={className} xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path d="M6.99232 7.13101C6.8174 7.13101 6.64248 7.04967 6.51129 6.92765L1.17628 1.5181C0.913901 1.27406 0.957631 0.907998 1.22001 0.663958C1.48239 0.419919 1.87595 0.460592 2.13833 0.704632L7.47335 6.07351C7.73572 6.31755 7.69199 6.72428 7.42962 6.92765C7.29843 7.04967 7.16724 7.13101 6.99232 7.13101Z" fill={fill}/>
      <path d="M1.6573 12.5C1.48238 12.5 1.3512 12.4593 1.22001 12.3373C0.957629 12.0932 0.957629 11.7272 1.17628 11.4831L6.51129 6.11427C6.77367 5.87023 7.16724 5.87023 7.42962 6.07359C7.69199 6.31763 7.69199 6.68369 7.47335 6.92773L2.13833 12.2966C2.00714 12.4186 1.83222 12.5 1.6573 12.5Z" fill={fill}/>
      <path d="M6.99232 7.13101C6.8174 7.13101 6.68622 7.09045 6.55503 6.96843C6.29265 6.72439 6.29265 6.35831 6.51129 6.11427L11.8463 0.704741C12.1087 0.460701 12.5023 0.460701 12.7646 0.664068C13.0707 0.908108 13.0707 1.27417 12.8084 1.51821L7.47335 6.92773C7.34216 7.04975 7.16724 7.13101 6.99232 7.13101Z" fill={fill}/>
      <path d="M12.3273 12.5C12.1524 12.5 11.9775 12.4187 11.8463 12.2966L6.51129 6.92765C6.24892 6.68361 6.29265 6.27698 6.55503 6.07362C6.8174 5.82958 7.2547 5.87025 7.47335 6.11429L12.8084 11.4832C13.0707 11.7272 13.027 12.1339 12.7646 12.3373C12.6334 12.4593 12.5023 12.5 12.3273 12.5Z" fill={fill}/>
      <path d="M6.99232 7.13101C6.8174 7.13101 6.64248 7.04967 6.51129 6.92765M6.99232 7.13101C7.16724 7.13101 7.29843 7.04967 7.42962 6.92765C7.69199 6.72428 7.73572 6.31755 7.47335 6.07351L2.13833 0.704632C1.87595 0.460592 1.48239 0.419919 1.22001 0.663958C0.957631 0.907998 0.913901 1.27406 1.17628 1.5181L6.51129 6.92765M6.99232 7.13101C6.8174 7.13101 6.68622 7.09045 6.55503 6.96843C6.29265 6.72439 6.29265 6.35831 6.51129 6.11427M6.99232 7.13101C7.16724 7.13101 7.34216 7.04975 7.47335 6.92773M6.51129 6.92765L11.8463 12.2966C11.9775 12.4187 12.1524 12.5 12.3273 12.5C12.5023 12.5 12.6334 12.4593 12.7646 12.3373C13.027 12.1339 13.0707 11.7272 12.8084 11.4832L7.47335 6.11429C7.2547 5.87025 6.8174 5.82958 6.55503 6.07362C6.29265 6.27698 6.24892 6.68361 6.51129 6.92765ZM6.51129 6.11427L1.17628 11.4831C0.957629 11.7272 0.957629 12.0932 1.22001 12.3373C1.3512 12.4593 1.48238 12.5 1.6573 12.5C1.83222 12.5 2.00714 12.4186 2.13833 12.2966L7.47335 6.92773M6.51129 6.11427C6.77367 5.87023 7.16724 5.87023 7.42962 6.07359C7.69199 6.31763 7.69199 6.68369 7.47335 6.92773M6.51129 6.11427L11.8463 0.704741C12.1087 0.460701 12.5023 0.460701 12.7646 0.664068C13.0707 0.908108 13.0707 1.27417 12.8084 1.51821L7.47335 6.92773" stroke={fill} />
    </svg>
  )
);
