import { BookingBuilderRequest } from 'services/BackendApi';

export const COPY_BOOKING_BUILDER_REQUEST = 'bookingBuilder/COPY_BOOKING_BUILDER_REQUEST';

export const ADD_ACCOMMODATION = 'bookingBuilder/ADD_ACCOMMODATION';
export const REMOVE_ACCOMMODATION = 'bookingBuilder/REMOVE_ACCOMMODATION';

export const EDIT_ACCOMMODATION_DATE = 'bookingBuilder/EDIT_ACCOMMODATION_DATE';
export const EDIT_ACCOMMODATION_REPEAT_GUEST = 'bookingBuilder/EDIT_ACCOMMODATION_REPEAT_GUEST';
export const EDIT_ACCOMMODATION_GUESTS = 'bookingBuilder/EDIT_ACCOMMODATION_GUESTS';

export const SAVE_EDIT_ACCOMMODATIONS = 'bookingBuilder/SAVE_EDIT_ACCOMMODATIONS';
export const RESET_EDIT_ACCOMMODATIONS = 'bookingBuilder/RESET_EDIT_ACCOMMODATIONS';

export type SetBookingBuilderRequestAction = ReturnType<typeof setBookingBuilderRequestAction>;
export const setBookingBuilderRequestAction = (hotelUuid: string, bookingBuilderRequest: BookingBuilderRequest) => ({
  type: COPY_BOOKING_BUILDER_REQUEST as typeof COPY_BOOKING_BUILDER_REQUEST,
  hotelUuid,
  bookingBuilderRequest,
});

export type AddAccommodationAction = ReturnType<typeof addAccommodationAction>;
export const addAccommodationAction = () => ({
  type: ADD_ACCOMMODATION as typeof ADD_ACCOMMODATION,
});

export type RemoveAccommodationAction = ReturnType<typeof removeAccommodationAction>;
export const removeAccommodationAction = (accommodationIndex: number) => ({
  type: REMOVE_ACCOMMODATION as typeof REMOVE_ACCOMMODATION,
  accommodationIndex,
});

export type EditAccommodationDateAction = ReturnType<typeof editAccommodationDateAction>;
export const editAccommodationDateAction = (accommodationIndex: number, date: string[]) => ({
  type: EDIT_ACCOMMODATION_DATE as typeof EDIT_ACCOMMODATION_DATE,
  accommodationIndex,
  date,
});

export type EditAccommodationRepeatGuestAction = ReturnType<typeof editAccommodationRepeatGuestAction>;
export const editAccommodationRepeatGuestAction = (accommodationIndex: number, repeatGuest: boolean) => ({
  type: EDIT_ACCOMMODATION_REPEAT_GUEST as typeof EDIT_ACCOMMODATION_REPEAT_GUEST,
  accommodationIndex,
  repeatGuest,
});

export type EditAccommodationGuestsAction = ReturnType<typeof editAccommodationGuestsAction>;
export const editAccommodationGuestsAction = (
  accommodationIndex: number,
  value: number,
  valueType: 'ADULT' | 'CHILDREN' | 'CHILDREN_AGE',
  childrenIndex?: number
) => ({
  type: EDIT_ACCOMMODATION_GUESTS as typeof EDIT_ACCOMMODATION_GUESTS,
  accommodationIndex,
  valueType,
  value,
  childrenIndex,
});

export type SaveEditAccommodationsAction = ReturnType<typeof saveEditAccommodationsAction>;
export const saveEditAccommodationsAction = (hotelUuid: string) => ({
  type: SAVE_EDIT_ACCOMMODATIONS as typeof SAVE_EDIT_ACCOMMODATIONS,
  hotelUuid,
});

export type ResetEditAcccommodationsAction = ReturnType<typeof resetEditAcccommodationsAction>;
export const resetEditAcccommodationsAction = () => ({
  type: RESET_EDIT_ACCOMMODATIONS as typeof RESET_EDIT_ACCOMMODATIONS,
});

export type BookingBuilderRequestAction =
  | SetBookingBuilderRequestAction
  | EditAccommodationDateAction
  | EditAccommodationRepeatGuestAction
  | EditAccommodationGuestsAction
  | SaveEditAccommodationsAction
  | AddAccommodationAction
  | RemoveAccommodationAction
  | ResetEditAcccommodationsAction;
