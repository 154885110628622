import React, { FC, useCallback } from 'react';
import { HidingTooltip, TooltipPosition } from './HidingTooltip';

interface IHidingTextTooltipProps {
  children: React.ReactNode;
  tooltipContent: React.ReactNode;
  width?: string;
  position?: TooltipPosition;
  showShadow?: boolean;
  tooltipWrapperClassname?: string;
  tooltipContentClassname?: string;
  tooltipClassname?: string;
  elementClassname?: string;
}

export const HidingTextTooltip: FC<IHidingTextTooltipProps> = React.memo(
  ({
    children,
    tooltipContent,
    tooltipWrapperClassname = '',
    tooltipContentClassname = '',
    tooltipClassname = '',
    elementClassname = '',
    width = 'max-content',
    showShadow = true,
    position = 'bottom',
  }) => {
    const renderTooltipContent = useCallback(() => {
      return (
        <div className={`${tooltipContentClassname ?? ''} text-13px leading-17px font-pt-sans font-normal text-black rounded text-left`} style={{ width }}>
          {tooltipContent}
        </div>
      );
    }, [tooltipContentClassname, width, tooltipContent]);

    return (
      <HidingTooltip 
        renderTooltipContent={renderTooltipContent} 
        position={position}
        showShadow={showShadow} 
        tooltipWrapperClassname={tooltipWrapperClassname}
        tooltipClassname={tooltipClassname}
        elementClassname={elementClassname}
      >
        {children}
      </HidingTooltip>
    );
  }
);
