import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import styled from 'styled-components';
import ConfirmationModal, { EConfirmationModalType } from '../ConfirmationModal';

interface ILeaveWithoutSavingModalProps {
  when: boolean;
  title?: string;
  message?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  className?: string;
}

/**
 * Renders a modal that prompts a user with a "you have unsaved changes" message
 * Will only render if the `when` prop is passed as true.
 *
 * Relies on react-router usage under the hood
 *
 * If the page we're going to is NOT in the same react-router instance, we'll get
 * a browser security message
 */

export const LeaveWithoutSavingModalComponent = (props: ILeaveWithoutSavingModalProps) => {
  const { title, message, confirmButtonLabel, cancelButtonLabel } = props;
  return (
    // @ts-ignore
    <NavigationPrompt when={props.when}>
      {({ onConfirm, onCancel }) => (
        <ConfirmationModal
          className="leave-without-saving z-30"
          type={EConfirmationModalType.WARNING}
          isOpen
          title={
            title ||
            `You have made changes to this page, and not saved.
             If you leave this page now, these changes will be lost.`
          }
          message={message || 'Are you sure you want to leave this page?'}
          confirmButtonLabel={confirmButtonLabel || 'Leave'}
          cancelButtonLabel={cancelButtonLabel || 'Cancel'}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )}
    </NavigationPrompt>
  );
};

export const LeaveWithoutSavingModal = styled(LeaveWithoutSavingModalComponent)`
  width: 500px;
`;
