import { IInvoiceAddressee } from 'services/BackendApi';

export const GET_REQUEST = 'companyInvoiceAddressee/GET_REQUEST';
export const GET_SUCCESS = 'companyInvoiceAddressee/GET_SUCCESS';
export const GET_FAILURE = 'companyInvoiceAddressee/GET_FAILURE';

export const UPDATE_REQUEST = 'companyInvoiceAddressee/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'companyInvoiceAddressee/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'companyInvoiceAddressee/UPDATE_FAILURE';

export const SET_NAME = 'companyInvoiceAddressee/SET_NAME';
export const SET_COMPANY_NAME = 'companyInvoiceAddressee/SET_COMPANY_NAME';
export const SET_ADDRESS = 'companyInvoiceAddressee/SET_ADDRESS';
export const SET_COUNTRY_CODE = 'companyInvoiceAddressee/SET_COUNTRY_CODE';
export const SET_EMAIL = 'companyInvoiceAddressee/SET_EMAIL';
export const SET_VAT = 'companyInvoiceAddressee/SET_VAT';
export const SET_IATA = 'companyInvoiceAddressee/SET_IATA';

export type GetRequestAction = ReturnType<typeof getRequestAction>;
export const getRequestAction = (companyUuid: string) => ({
  type: GET_REQUEST as typeof GET_REQUEST,
  companyUuid
});

export type GetSuccessAction = ReturnType<typeof getSuccessAction>;
export const getSuccessAction = (addressee: IInvoiceAddressee | null) => ({
  type: GET_SUCCESS as typeof GET_SUCCESS,
  addressee,
});

export type GetFailureAction = ReturnType<typeof getFailureAction>;
export const getFailureAction = (error: string) => ({
  type: GET_FAILURE as typeof GET_FAILURE,
  error,
});

export type UpdateRequestAction = ReturnType<typeof updateRequestAction>;
export const updateRequestAction = (companyUuid: string) => ({
  type: UPDATE_REQUEST as typeof UPDATE_REQUEST,
  companyUuid
});

export type UpdateSuccessAction = ReturnType<typeof updateSuccessAction>;
export const updateSuccessAction = (addressee: IInvoiceAddressee) => ({
  type: UPDATE_SUCCESS as typeof UPDATE_SUCCESS,
  addressee,
});

export type UpdateFailureAction = ReturnType<typeof updateFailureAction>;
export const updateFailureAction = (error: string) => ({
  type: UPDATE_FAILURE as typeof UPDATE_FAILURE,
  error,
});

export type SetNameAction = ReturnType<typeof setNameAction>;
export const setNameAction = (value: string) => ({
  type: SET_NAME as typeof SET_NAME,
  value
});

export type SetCompanyNameAction = ReturnType<typeof setCompanyNameAction>;
export const setCompanyNameAction = (value: string) => ({
  type: SET_COMPANY_NAME as typeof SET_COMPANY_NAME,
  value
});

export type SetAddressAction = ReturnType<typeof setAddressAction>;
export const setAddressAction = (value: string) => ({
  type: SET_ADDRESS as typeof SET_ADDRESS,
  value
});

export type SetCountryCodeAction = ReturnType<typeof setCountryCodeAction>;
export const setCountryCodeAction = (value: string) => ({
  type: SET_COUNTRY_CODE as typeof SET_COUNTRY_CODE,
  value
});

export type SetEmailAction = ReturnType<typeof setEmailAction>;
export const setEmailAction = (value: string) => ({
  type: SET_EMAIL as typeof SET_EMAIL,
  value
});

export type SetVatAction = ReturnType<typeof setVatAction>;
export const setVatAction = (value: string) => ({
  type: SET_VAT as typeof SET_VAT,
  value
});

export type SetIataAction = ReturnType<typeof setIataAction>;
export const setIataAction = (value: string) => ({
  type: SET_IATA as typeof SET_IATA,
  value
});

export type Any =
  | GetRequestAction
  | GetSuccessAction
  | GetFailureAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | SetNameAction
  | SetCompanyNameAction
  | SetAddressAction
  | SetCountryCodeAction
  | SetEmailAction
  | SetVatAction
  | SetIataAction
  ;
