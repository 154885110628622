import { maxBy } from 'lodash-es';
import { initialState, BookingBuilderDomain } from './model';
import * as Actions from './actions';
import * as CustomItemActions from './subdomains/customItem/actions';
import { customItemReducer } from './subdomains/customItem/reducer';
import * as SearchActions from './subdomains/search/actions';
import { searchReducer } from './subdomains/search/reducer';
import { makeBookingBuilderStub } from './utils';
import { SelectedAccommodation, ENetworkRequestStatus } from 'services/BackendApi';
import { produce } from 'immer';
import { formatDate } from 'utils';
import { SERVICE_CHARGE_UUID, FUEL_CHARGE_UUID } from 'config';

import { bookingBuilderRequestReducer } from './subdomains/request/reducer';
import { BookingBuilderRequestAction } from './subdomains/request/actions';

import { bookingBuilderResponseReducer } from './subdomains/response/reducer';
import { BookingBuilderResponseAction } from './subdomains/response/actions';

import { bookingBuilderHotelReducer } from './subdomains/hotel/reducer';
import { BookingBuilderHotelAction } from './subdomains/hotel/actions';

import { bookingBuilderHotelModalReducer } from './subdomains/hotelModal/reducer';
import { BookingBuilderHotelModalAction } from './subdomains/hotelModal/actions';

import { bookingBuilderHotelAccommodationReducer } from './subdomains/hotelAccommodation/reducer';
import { BookingBuilderHotelAccommodationAction } from './subdomains/hotelAccommodation/actions';

import { liveRatesReducer } from './subdomains/liveRates/reducer';
import { LiveRatesAction } from './subdomains/liveRates/actions';

import { bookingBuilderBasketReducer } from './subdomains/basket/reducer';
import { BookingBuilderBasketAction } from './subdomains/basket/actions';
import { BookingBuilderTransfersAction } from './subdomains/transfers/actions';
import { bookingBuilderTransfersReducer } from './subdomains/transfers/reducer';

export const bookingBuilderReducer = (
  state: BookingBuilderDomain = initialState,
  action: Actions.BookingBuilderAction
) => {
  return {
    ...oldBookingBuilderReducer(state, action),
    requestSubdomain: bookingBuilderRequestReducer(state.requestSubdomain, action as BookingBuilderRequestAction),
    responseSubdomain: bookingBuilderResponseReducer(state.responseSubdomain, action as BookingBuilderResponseAction),
    hotelSubdomain: bookingBuilderHotelReducer(state.hotelSubdomain, action as BookingBuilderHotelAction),
    hotelModalSubdomain: bookingBuilderHotelModalReducer(
      state.hotelModalSubdomain,
      action as BookingBuilderHotelModalAction
    ),
    hotelAccommodationSubdomain: bookingBuilderHotelAccommodationReducer(
      state.hotelAccommodationSubdomain,
      action as BookingBuilderHotelAccommodationAction
    ),
    liveRatesSubdomain: liveRatesReducer(state.liveRatesSubdomain, action as LiveRatesAction),
    basketSubdomain: bookingBuilderBasketReducer(state.basketSubdomain, action as BookingBuilderBasketAction),
    transfersSubdomain: bookingBuilderTransfersReducer(
      state.transfersSubdomain,
      action as BookingBuilderTransfersAction
    ),
  };
};

export const oldBookingBuilderReducer = (
  state: BookingBuilderDomain = initialState,
  action: Actions.BookingBuilderAction
) => {
  switch (action.type) {
    case Actions.CLEAR_BOOKING_BUILDER:
      return clearBookingBuilderReducer(state, action);
    case Actions.CLEAR_BOOKING_BUILDER_GUEST_DETAILS:
      return clearBookingBuilderGuestDetailsReducer(state, action);

    case Actions.COPY_BOOKING_BUILDER:
      return copyBookingBuilderReducer(state, action);

    case Actions.CREATE_STUB_BOOKING_BUILDER:
      return createStubBookingBuilderReducer(state, action);

    case Actions.UPDATE_LODGING_GUEST_AGES_ACTION:
      return updateLodgingGuestAgesReducer(state, action);
    case Actions.UPDATE_LODGING_MEAL_PLAN_ACTION:
      return updateLodgingMealPlanReducer(state, action);
    case Actions.ADD_LODGING_ACTION:
      return addLodgingReducer(state, action);
    case Actions.UPDATE_LODGING_DATES_ACTION:
      return updateLodgingDatesReducer(state, action);
    case Actions.UPDATE_LODGING_OCCASIONS_ACTION:
      return updateLodgingOccasionsReducer(state, action);
    case Actions.REMOVE_LODGING_ACTION:
      return removeLodgingReducer(state, action);
    case Actions.UPDATE_GROUND_SERVICE_ACTION:
      return updateGroundServiceReducer(state, action);
    case Actions.UPDATE_SUPPLEMENT_ACTION:
      return updateSupplementReducer(state, action);
    case Actions.UPDATE_FINE_ACTION:
      return updateFineReducer(state, action);
    case Actions.UPDATE_TRANSFER:
      return updateTransferReducer(state, action);

    case Actions.UPDATE_BOOKING_REQUEST:
      return updateBookingRequestReducer(state, action);
    case Actions.UPDATE_BOOKING_SUCCESS:
      return updateBookingSuccessReducer(state, action);
    case Actions.UPDATE_BOOKING_FAILURE:
      return updateBookingFailureReducer(state, action);

    case Actions.INITIALIZE_BOOKING_BUILDER:
      return initializeBookingBuilderReducer(state, action);

    case Actions.FORWARDS_COMPAT_BOOKING_BUILDER_ACTION:
      return forwardsCompatBookingBuilderReducer(state, action);

    case Actions.UPDATE_TA_MARGIN_TYPE_ACTION:
      return updateTAMarginTypeReducer(state, action);
    case Actions.UPDATE_TA_MARGIN_AMOUNT_ACTION:
      return updateTAMarginAmountReducer(state, action);
    case Actions.UPDATE_IS_TA_MARGIN_APPLIED_ACTION:
      return updateIsTAMarginAppliedReducer(state, action);

    case Actions.UPDATE_BOOKING_GUEST_INFORMATION_ACTION:
      return updateBookingGuestInformationReducer(state, action);
    case Actions.CLEAR_BOOKING_BUILDER_UI_STATE:
      return resetBookingBuilderUiStateReducer(state);

    case Actions.UPDATE_LODGING_REPEAT_GUEST_ACTION:
      return updateLodgingRepeatGuestReducer(state, action);

    case Actions.SET_IS_PRISTINE:
      return setIsPristineReducer(state, action);

    case Actions.SET_LATEST_BOOKING_OPERATION:
      return latestBookingOperationReducer(state, action);

    case Actions.SAVE_CUSTOM_ITEM:
      return saveCustomItemReducer(state, action);
    case Actions.REMOVE_CUSTOM_ITEM:
      return removeCustomItemReducer(state, action);

    case CustomItemActions.SHOW_CUSTOM_ITEM_FORM:
    case CustomItemActions.HIDE_CUSTOM_ITEM_FORM:
    case CustomItemActions.UPDATE_CUSTOM_ITEM_NAME:
    case CustomItemActions.UPDATE_CUSTOM_ITEM_TOTAL:
    case CustomItemActions.UPDATE_CUSTOM_ITEM_DESCRIPTION:
    case CustomItemActions.UPDATE_CUSTOM_ITEM_COUNTS_AS_MEAL_PLAN:
    case CustomItemActions.UPDATE_CUSTOM_ITEM_COUNTS_AS_TRANSFER:
      return {
        ...state,
        customItem: customItemReducer(state.customItem, action),
      };

    case Actions.BBV1_ADD_BOOKING_ERROR:
      return bbv1AddBookingErrorReducer(state, action);

    case Actions.BBV1_CLEAR_BOOKING_ERRORS:
      return bbv1ClearBookingErrorsReducer(state, action);

    case SearchActions.INITIALIZE_QUERY:
    case SearchActions.POPULATE_QUERY:
    case SearchActions.CLEAR_EXTENDED_QUERY:
    case SearchActions.CLEAR_SEARCH_RESULTS:
    case SearchActions.EXECUTE:
    case SearchActions.NAME_SEARCH_SUCCESS:
    case SearchActions.NAME_SEARCH_FAILURE:
    case SearchActions.SET_NAME_SEARCH_RESUTS_VISIBILITY:
    case SearchActions.DESTINATION_CHANGE:
    case SearchActions.DATE_RANGE_CHANGE:
    case SearchActions.TOGGLE_LODGING_CONTROLS:
    case SearchActions.SET_LODGING_CONTOLS_VISBILITY:
    case SearchActions.INCREMENT_ROOM:
    case SearchActions.SET_ACTIVE_LODGING_INDEX:
    case SearchActions.INCREMENT_ACTIVE_LODGING_INDEX:
    case SearchActions.INCREMENT_ADULT:
    case SearchActions.INCREMENT_CHILD:
    case SearchActions.SET_AGE:
    case SearchActions.TOGGLE_REPEAT_GUEST:
    case SearchActions.GET_COMPANIES_REQUEST:
    case SearchActions.GET_COMPANIES_SUCCESS:
    case SearchActions.GET_COMPANIES_FAILURE:
    case SearchActions.GET_TRAVEL_AGENTS_BY_COMPANY_ID_SUCCESS:
    case SearchActions.GET_TRAVEL_AGENTS_BY_COMPANY_ID_FAILURE:
    case SearchActions.SHOW_COMPANY_DROPDOWN:
    case SearchActions.SHOW_TA_DROPDOWN:
    case SearchActions.SEARCH_TA_BY_NAME:
    case SearchActions.SEARCH_COMPANY_BY_NAME:
    case SearchActions.CLEAR_SELECTED_TA:
    case SearchActions.SELECTED_COMPANY_CHANGE:
    case SearchActions.SELECTED_TA_CHANGE:
    case SearchActions.GUEST_COUNTRY_CHANGE:
    case SearchActions.SET_TRAVEL_COMPANY_MEMBERSHIP:
      return {
        ...state,
        search: searchReducer(state.search, action),
      };

    case Actions.SET_CURRENT_BOOKING_BUILDER:
      return {
        ...state,
        currentBookingBuilder: action.bookingBuild,
      };
    default:
      return state;
  }
};

export const updateBookingRequestReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateBookingRequestAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    draftState.updateBookingRequestStatus = ENetworkRequestStatus.PENDING;
    return draftState;
  });
};

export const updateBookingSuccessReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateBookingSuccessAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder) {
      return state;
    }

    action.response.potentialBooking.Accommodation.forEach((accommodation, index) => {
      if (draftState?.currentBookingBuilder?.request?.Accommodation[index]?.liveRate?.amount) {
        // @ts-ignore
        draftState.currentBookingBuilder.request.Accommodation[index].liveRate.amount = accommodation.totalCents;
      }
    });
    draftState.currentBookingBuilder.response = action.response;
    draftState.updateBookingRequestStatus = ENetworkRequestStatus.SUCCESS;

    return draftState;
  });
};

export const updateBookingFailureReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateBookingFailureAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    draftState.updateBookingRequestStatus = ENetworkRequestStatus.ERROR;
    return draftState;
  });
};

export const updateTransferReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateTransferAction
): BookingBuilderDomain => {
  const { hotelUuid, transfer } = action;

  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder) {
      return state;
    }

    if (!action.transfer.direction) {
      draftState.currentBookingBuilder.request.Transfer = [transfer];
    }

    if (action.transfer.direction) {
      draftState.currentBookingBuilder.request.Transfer = draftState.currentBookingBuilder.request.Transfer.filter(
        t => t.direction && t.direction !== action.transfer.direction
      ).concat(action.transfer);
    }

    return draftState;
  });
};

export const updateLodgingGuestAgesReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateLodgingGuestAgesAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder) {
      return state;
    }

    // update the request accommodation to be correct
    draftState.currentBookingBuilder.request.Accommodation[action.lodgingIndex].guestAges = action.guestAges;

    // rebuild the request level `guestAges` to match all the new figures
    draftState.currentBookingBuilder.request.guestAges = calculateBookingTotalGuestAges(
      draftState.currentBookingBuilder.request.Accommodation
    );

    return draftState;
  });
};

export const updateLodgingMealPlanReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateLodgingMealPlanAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder) {
      return state;
    }

    const subProducts = draftState.currentBookingBuilder.request.Accommodation[action.lodgingIndex].subProducts;

    if (subProducts) {
      // update the meal plans on the correct lodging
      subProducts['Meal Plan'] = action.mealPlanUuids.map(mealPlanUuid => ({ uuid: mealPlanUuid }));
    }

    return draftState;
  });
};

export const addLodgingReducer = (
  state: BookingBuilderDomain,
  action: Actions.AddLodgingAction
): BookingBuilderDomain => {
  const { accommodationProduct, searchQuery } = action;
  let { guestAges } = action;
  const { startDate, endDate } = searchQuery;

  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder) {
      return state;
    }

    // if they're adding the room without guest ages, they want standard occupancy
    if (guestAges == null) {
      guestAges = {
        numberOfAdults: action.accommodationProduct.occupancy.standardOccupancy,
        agesOfAllChildren: [],
      };
    }

    let newLodging;

    const isAddingFirstRoom = draftState?.currentBookingBuilder?.request?.Accommodation?.length <= 0 || undefined;

    if (isAddingFirstRoom) {
      // first room on the booking
      newLodging = {
        uuid: accommodationProduct.uuid,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        honeymoon: ensureBoolean(searchQuery.lodgings[0].honeymoon),
        anniversary: ensureBoolean(searchQuery.lodgings[0].anniversary),
        wedding: ensureBoolean(searchQuery.lodgings[0].wedding),
        birthday: ensureBoolean(searchQuery.lodgings[0].birthday),
        repeatCustomer: ensureBoolean(searchQuery.lodgings[0].repeatCustomer),
        guestAges,
        availableToInstantBook: accommodationProduct.availableToInstantBook || false,
      };
    } else {
      // not the first room on the booking
      newLodging = {
        uuid: accommodationProduct.uuid,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        honeymoon: false,
        anniversary: false,
        wedding: false,
        birthday: false,
        repeatCustomer: false,
        guestAges,
        availableToInstantBook: accommodationProduct.availableToInstantBook || false,
      };
    }

    // if we're adding the first room, also add the service charge and fuel charge
    // @see OWA-3929 & OWA-4353
    if (isAddingFirstRoom) {
      if (action.includeServiceCharge) {
        draftState.currentBookingBuilder.request.Supplement.push({
          uuid: SERVICE_CHARGE_UUID,
        });
      }

      draftState.currentBookingBuilder.request.Supplement.push({
        uuid: FUEL_CHARGE_UUID,
      });
    }

    if (accommodationProduct.defaultMealPlan) {
      newLodging.subProducts = {
        'Meal Plan': accommodationProduct.defaultMealPlan,
      };
    } else if (accommodationProduct.liveRate) {
      newLodging.liveRate = accommodationProduct.liveRate;
    }

    // now that newLodging is built, add it into the request Accommodation array
    draftState.currentBookingBuilder.request.Accommodation.push(newLodging);

    // rebuild the request level `guestAges` to match all the new figures
    draftState.currentBookingBuilder.request.guestAges = calculateBookingTotalGuestAges(
      draftState.currentBookingBuilder.request.Accommodation
    );

    const { earliestStartDate, latestEndDate } = calculateBookingDates(
      draftState.currentBookingBuilder.request.Accommodation
    );

    draftState.currentBookingBuilder.request.startDate = earliestStartDate;
    draftState.currentBookingBuilder.request.endDate = latestEndDate;

    return draftState;
  });
};

export const updateLodgingOccasionsReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateLodgingOccasionsAction
): BookingBuilderDomain => {
  const { hotelUuid, lodgingIndex, occasions } = action;

  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder) {
      return state;
    }

    // update the occasions on the correct lodging
    draftState.currentBookingBuilder.request.Accommodation[lodgingIndex] = {
      ...draftState.currentBookingBuilder.request.Accommodation[lodgingIndex],
      ...occasions,
    };

    return draftState;
  });
};

export const removeLodgingReducer = (
  state: BookingBuilderDomain,
  action: Actions.RemoveLodgingAction
): BookingBuilderDomain => {
  const { lodgingIndex } = action;

  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder) {
      return state;
    }

    // if we're somehow trying to remove a lodging that doesn't exist, just return out
    if (!draftState.currentBookingBuilder.request.Accommodation[lodgingIndex]) {
      return state;
    }

    // remove the lodging at the index
    draftState.currentBookingBuilder.request.Accommodation.splice(lodgingIndex, 1);

    // now that the lodging has gone, rebuild the overall start and end dates
    const { earliestStartDate, latestEndDate } = calculateBookingDates(
      draftState.currentBookingBuilder.request.Accommodation
    );
    draftState.currentBookingBuilder.request.startDate = earliestStartDate;
    draftState.currentBookingBuilder.request.endDate = latestEndDate;

    // rebuild the request level `guestAges` to match all the new guest ages and dates
    draftState.currentBookingBuilder.request.guestAges = calculateBookingTotalGuestAges(
      draftState.currentBookingBuilder.request.Accommodation
    );

    // if the user has got to a position where they have no lodgings at all,
    // they've basically manually reset the booking - reset a whooooole bunch of stuff
    if (draftState.currentBookingBuilder.request.Accommodation.length <= 0) {
      draftState.currentBookingBuilder.request.Accommodation = [];
      if (draftState.currentBookingBuilder.response) {
        draftState.currentBookingBuilder.response.canBeBooked = false;
        draftState.currentBookingBuilder.response.mustStop = false;
        draftState.currentBookingBuilder.response.errors = [];
        draftState.currentBookingBuilder.response.potentialBooking = {
          Accommodation: [],
          Supplement: [],
          Transfer: [],
          'Ground Service': [],
          Fine: [],
        };
        draftState.currentBookingBuilder.response.availableProductSets = {
          Accommodation: [],
          Supplement: [],
          Transfer: [],
          'Ground Service': [],
          Fine: [],
        };
        draftState.currentBookingBuilder.response.totals = {
          oneOrMoreItemsOnRequest: false,
          totalForPricedItemsCents: 0,
          totalBeforeDiscountForPricedItemsCents: 0,
          totalForPricedItems: '0.00',
          totalBeforeDiscountForPricedItems: '0.00',
          total: '0.00',
          totalBeforeDiscount: '0.00',
        };
        if (draftState.currentBookingBuilder.response?.displayTotals?.totals) {
          draftState.currentBookingBuilder.response.displayTotals.totals.total = null;
        }
      }
    }

    return draftState;
  });
};

export const updateLodgingDatesReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateLodgingDatesAction
): BookingBuilderDomain => {
  const { lodgingIndex, startDate, endDate } = action;

  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder) {
      return state;
    }

    draftState.currentBookingBuilder.request.Accommodation[lodgingIndex].startDate = formatDate(startDate);
    draftState.currentBookingBuilder.request.Accommodation[lodgingIndex].endDate = formatDate(endDate);

    const { earliestStartDate, latestEndDate } = calculateBookingDates(
      draftState.currentBookingBuilder.request.Accommodation
    );

    draftState.currentBookingBuilder.request.startDate = earliestStartDate;
    draftState.currentBookingBuilder.request.endDate = latestEndDate;

    // rebuild the request level `guestAges` to take into account the new dates
    draftState.currentBookingBuilder.request.guestAges = calculateBookingTotalGuestAges(
      draftState.currentBookingBuilder.request.Accommodation
    );

    return draftState;
  });
};

export const updateGroundServiceReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateGroundServiceAction
): BookingBuilderDomain => {
  const { groundService, hotelUuid } = action;

  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder) {
      return state;
    }

    if (!draftState.currentBookingBuilder.request['Ground Service']) {
      draftState.currentBookingBuilder.request['Ground Service'] = [];
    }

    const existingIndex = draftState.currentBookingBuilder.request['Ground Service'].findIndex(
      gs => gs.uuid === groundService.uuid
    );

    if (existingIndex !== -1) {
      // we have it, so remove it
      draftState.currentBookingBuilder.request['Ground Service'].splice(existingIndex, 1);
    } else {
      draftState.currentBookingBuilder.request['Ground Service'].push({
        uuid: groundService.uuid,
      });
    }

    return draftState;
  });
};

export const updateSupplementReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateSupplementAction
): BookingBuilderDomain => {
  const { supplement } = action;

  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder) {
      return state;
    }

    if (!draftState.currentBookingBuilder.request.Supplement) {
      draftState.currentBookingBuilder.request.Supplement = [];
    }

    const existingIndex = draftState.currentBookingBuilder.request.Supplement.findIndex(
      gs => gs.uuid === supplement.uuid
    );

    if (existingIndex !== -1) {
      // we have it, so remove it
      draftState.currentBookingBuilder.request.Supplement.splice(existingIndex, 1);
    } else {
      draftState.currentBookingBuilder.request.Supplement.push({
        uuid: supplement.uuid,
      });
    }

    return draftState;
  });
};

export const updateFineReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateFineAction
): BookingBuilderDomain => {
  const { fine, hotelUuid } = action;

  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder) {
      return state;
    }

    if (!draftState.currentBookingBuilder.request.Fine) {
      draftState.currentBookingBuilder.request.Fine = [];
    }

    const existingIndex = draftState.currentBookingBuilder.request.Fine.findIndex(gs => gs.uuid === fine.uuid);

    if (existingIndex !== -1) {
      // we have it, so remove it
      draftState.currentBookingBuilder.request.Fine.splice(existingIndex, 1);
    } else {
      draftState.currentBookingBuilder.request.Fine.push({
        uuid: fine.uuid,
      });
    }

    return draftState;
  });
};

export const initializeBookingBuilderReducer = (
  state: BookingBuilderDomain,
  action: Actions.InitializeBookingBuilderAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    draftState.hotelUuid = action.hotelUuid;
    if (action.selectedTaUuid) {
      draftState.travelAgentUserUuid = action.selectedTaUuid;
    }

    return draftState;
  });
};

export const clearBookingBuilderReducer = (
  state: BookingBuilderDomain,
  action: Actions.ClearBookingBuilderAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    draftState = initialState;
    draftState.latestBookingOperation = state.latestBookingOperation;
    return draftState;
  });
};

export const clearBookingBuilderGuestDetailsReducer = (
  state: BookingBuilderDomain,
  action: Actions.ClearBookingBuilderGuestDetailsAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    draftState.guestTitle = null;
    draftState.guestFirstName = '';
    draftState.guestLastName = '';
    draftState.guestEmail = '';
    draftState.specialRequests = [];
    draftState.comments = '';
    draftState.latestBookingOperation = state.latestBookingOperation;
    return draftState;
  });
};

export const copyBookingBuilderReducer = (
  state: BookingBuilderDomain,
  action: Actions.CopyBookingBuilderAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    if (action.bookingBuilder !== false) {
      draftState.currentBookingBuilder = action.bookingBuilder;
      const selectedSupplements = action.bookingBuilder.response.availableProductSets.Supplement.filter(
        supplement => supplement.selected === true
      ).map(supplement => ({ uuid: supplement.products[0].uuid }));
      draftState.currentBookingBuilder.request.Supplement = selectedSupplements;
    }

    return draftState;
  });
};

export const createStubBookingBuilderReducer = (state: BookingBuilderDomain, action): BookingBuilderDomain => {
  return produce(state, draftState => {
    draftState.currentBookingBuilder = makeBookingBuilderStub(action.hotel);

    return draftState;
  });
};

export const forwardsCompatBookingBuilderReducer = (
  state: BookingBuilderDomain,
  action: Actions.ForwardsCompatBookingBuilderAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    if (!action.booking.breakdown || !action.booking.breakdown.requestedBuild) {
      return draftState;
    }

    draftState = {
      ...action.booking,
      currentBookingBuilder: {
        request: {
          ...action.booking.breakdown.requestedBuild,
        },
        response: {
          ...action.booking.breakdown,
          requestedBuild: undefined,
        },
      },
      breakdown: undefined,
      customItem: initialState.customItem,
    };

    return draftState;
  });
};

export const updateTAMarginTypeReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateTAMarginType
): BookingBuilderDomain => {
  return produce(state, draftState => {
    draftState.taMarginType = action.taMarginType;
    return draftState;
  });
};

export const updateTAMarginAmountReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateTAMarginAmount
): BookingBuilderDomain => {
  return produce(state, draftState => {
    draftState.taMarginAmount = action.taMarginAmount;
    return draftState;
  });
};

export const updateIsTAMarginAppliedReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateIsTAMarginAppliedAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    draftState.isTAMarginApplied = action.value;
    return draftState;
  });
};

export const updateBookingGuestInformationReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateBookingGuestInformationAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    draftState = {
      ...draftState,
      ...action.bookingGuestInformation,
    };

    return draftState;
  });
};

export const setIsPristineReducer = (
  state: BookingBuilderDomain,
  action: Actions.SetIsPristineAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    draftState.isPristine = action.value;
    return draftState;
  });
};

export const updateLodgingRepeatGuestReducer = (
  state: BookingBuilderDomain,
  action: Actions.UpdateLodgingRepeatGuestAction
) => {
  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder) {
      return draftState;
    }
    draftState.currentBookingBuilder.request.Accommodation[action.lodgingIndex].repeatCustomer = action.checked;
    return draftState;
  });
};

export const resetBookingBuilderUiStateReducer = (state: BookingBuilderDomain): BookingBuilderDomain => ({
  ...state,
  isTAMarginApplied: true,
  taMarginType: 'percentage',
  taMarginAmount: '0',
});

const isOverlapping = (a: SelectedAccommodation, b: SelectedAccommodation): boolean =>
  !(a.endDate < b.startDate || a.startDate > b.endDate);

const getOverlapping = (a: SelectedAccommodation, all: SelectedAccommodation[]): SelectedAccommodation[] =>
  all.filter(x => isOverlapping(a, x));

const sumGuests = (xs: SelectedAccommodation[]) =>
  xs.reduce(
    (acc, cur) => ({
      numberOfAdults: acc.numberOfAdults + (cur.guestAges.numberOfAdults || 0),
      agesOfAllChildren: [...acc.agesOfAllChildren, ...(cur.guestAges.agesOfAllChildren || [])],
    }),
    {
      numberOfAdults: 0,
      agesOfAllChildren: [],
    }
  );

// given a collection of lodgings, calculate the total guest ages across all the lodgings
export const calculateBookingTotalGuestAges = (lodgings: SelectedAccommodation[]) => {
  const overlappingGroups = lodgings.map(x => getOverlapping(x, lodgings));
  const sumForGroups = overlappingGroups.map(x => sumGuests(x));

  const zero = { numberOfAdults: 0, agesOfAllChildren: [] };
  return maxBy(sumForGroups, x => x.numberOfAdults + x.agesOfAllChildren.length) || zero;
};

// given a collection of accommodations, calculate and return the earliest start date
// and the latest end date
export const calculateBookingDates = (accommodations: SelectedAccommodation[]) => {
  const startDates = accommodations.map(x => x.startDate).sort();

  const endDates = accommodations.map(x => x.endDate).sort();

  return {
    earliestStartDate: startDates[0] || formatDate(new Date()),
    latestEndDate: endDates[endDates.length - 1] || formatDate(new Date()),
  };
};

export default bookingBuilderReducer;

export const saveCustomItemReducer = (
  state: BookingBuilderDomain,
  action: Actions.SaveCustomItemAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder || !draftState.customItem.payload) {
      return state;
    }

    if (!draftState.currentBookingBuilder.request.customItems) {
      draftState.currentBookingBuilder.request.customItems = [];
    }

    const { payload } = draftState.customItem;

    draftState.currentBookingBuilder.request.customItems.push(payload);

    if (payload.countsAsTransfer) {
      draftState.currentBookingBuilder.request.Transfer = [];
    }

    if (payload.countsAsMealPlan) {
      draftState.currentBookingBuilder.request.Accommodation.forEach(product => {
        if (!product.subProducts) {
          product.subProducts = {};
        }

        product.subProducts['Meal Plan'] = [];
      });
    }

    draftState.customItem.payload = null;

    return draftState;
  });
};

export const removeCustomItemReducer = (
  state: BookingBuilderDomain,
  action: Actions.RemoveCustomItemAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder) {
      return state;
    }

    draftState.currentBookingBuilder.request.customItems.splice(action.index, 1);

    return draftState;
  });
};

export const latestBookingOperationReducer = (
  state: BookingBuilderDomain,
  action: Actions.SetLatestBookingOperationAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    draftState.latestBookingOperation = action.operation;
    return draftState;
  });
};

export const bbv1AddBookingErrorReducer = (
  state: BookingBuilderDomain,
  action: Actions.BBv1AddBookingErrorAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder) {
      return state;
    }

    if (!Array.isArray(draftState.currentBookingBuilder.bookingErrors)) {
      draftState.currentBookingBuilder.bookingErrors = [];
    }
    const alreadyExists = draftState.currentBookingBuilder.bookingErrors.find(
      bookingError => bookingError.detail === action.error.detail
    );
    if (!alreadyExists) {
      // @ts-ignore
      draftState.currentBookingBuilder.bookingErrors.push(action.error);
    }

    return draftState;
  });
};

export const bbv1ClearBookingErrorsReducer = (
  state: BookingBuilderDomain,
  action: Actions.BBv1ClearBookingErrorsAction
): BookingBuilderDomain => {
  return produce(state, draftState => {
    if (!draftState.currentBookingBuilder) {
      return state;
    }

    draftState.currentBookingBuilder.bookingErrors = [];

    return draftState;
  });
};

export const ensureBoolean = (x: any): boolean => {
  if (typeof x === 'boolean') {
    return x;
  }
  return x === 'true';
};
