import React from 'react';

export const InstantBookTag = () => {
  return (
    <div className="flex justify-end">
      <span className="inline-flex items-center h-18px py-0 px-5px rounded-1px text-xs leading-14px tracking-[0.04em] mb-5px bg-teal-30">
        Instant Book
      </span>
    </div>
  );
};
