import { RefObject, useState } from 'react';

export const useSticky = (containerRef: RefObject<HTMLDivElement>) => {
  const [isSticky, setSticky] = useState(false);

  if (containerRef?.current && 'IntersectionObserver' in window) {
    const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
      const { isIntersecting } = entries[0];
      setSticky(!isIntersecting);
    };

    const options = {
      root: null,
      threshold: 1
    }

    const observer = new IntersectionObserver(intersectionCallback, options);
    observer.observe(containerRef?.current);
  }

  return [isSticky]
};
