import { auth, user, page } from 'routing/common';

const redirect = [
  {
    from: '/admin',
    to: '/',
    exact: true,
  },
  {
    from: '/admin/travel-agents',
    to: '/',
  },
];

export default [...redirect, ...auth, ...user, ...page];
