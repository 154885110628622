import React, { useCallback } from 'react';
import classnames from 'classnames';
import Checkbox from 'ui/Checkbox';

interface IMassAssignRowHeaderProps {
  onChange: (enabled: boolean) => void;
  enabled: boolean;
}

export const MassAssignRowHeader: React.FC<IMassAssignRowHeaderProps> = React.memo(({ onChange, enabled }) => {
  const textClassName = 'text-gray-100 tracking-2xs text-xs leading-16px';

  const handleSrChange = useCallback(() => {
    onChange(!enabled);
  }, [onChange, enabled]);

  return (
    <div className="sr-list-header bg-ivory uppercase h-10 flex items-center">
      <div className="sr-list-header-title w-200px">
        <label className="sr-list-row-checkbox-label flex flex-row items-center pl-3px">
          <Checkbox checked={enabled} onClick={handleSrChange} />
          <span className={classnames('ml-2', textClassName)}>SALES REP.</span>
        </label>
      </div>
      <div className="sr-list-header-country w-135px ml-50px">
        <span className={classnames(textClassName)}>COUNTRY</span>
      </div>
    </div>
  );
});
