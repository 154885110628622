import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import AuthRoute from 'containers/AuthRoute';
import { EUserType } from 'services/BackendApi';

import { LedgerSummaryContainer as Summary } from './Summary';
import { GlobalStatement } from './GlobalStatement';
import { BookingLedgerFrame } from './BookingLedgerFrame';
import { DepositAccount } from './DepositAccount';

export interface StatementRoutingProps extends RouteComponentProps {}

export const StatementRoutingComponent = (props: StatementRoutingProps) => {
  return (
    <Switch>
      <AuthRoute
        allow={[EUserType.ADMIN, EUserType.FINANCE]}
        path={`${props.match.path}/all`}
        component={GlobalStatement}
      />
      <Route exact path={props.match.path} component={Summary} />
      <Route exact path={`${props.match.path}/:companyUuid`} component={Summary} />

      <Route
        path={[
          `${props.match.path}/:companyUuid/bookings/:currency`,
          `${props.match.path}/:companyUuid/bookings/:currency`,
        ]}
        component={BookingLedgerFrame}
      />
      <Route exact path={`${props.match.path}/:companyUuid/deposits/:currency`} component={DepositAccount} />
    </Switch>
  );
};

export const StatementRouting = compose(withRouter)(StatementRoutingComponent);
