import { ITravelAgent, ICompany, ENetworkRequestStatus } from 'services/BackendApi';
import { ICompanyMembership } from 'services/BookingManagerApi/types/CompanyMemberships';

export interface ICompanyAgentSelection {
  selectedTa: Partial<ITravelAgent> | null;
  taNameSearch: string;
  selectedCompany: ICompany | null;
  companyNameSearch: string;
  selectedCompanyMembership: ICompanyMembership | null;
}

export interface IAgentsModuleDomain {
  requestPending: boolean;
  agents: ITravelAgent[] | undefined;
  error: string | undefined;
  isFetchingTA: boolean;
  selectedTa: Partial<ITravelAgent> | null;
  showTaDropdown: boolean;
  taNameSearch: string;

  companies: ICompany[] | null;
  isFetchingCompanies: boolean;
  selectedCompany: ICompany | null;
  showCompanyDropdown: boolean;
  companyNameSearch: string;
  selectedCompanyAgents: ITravelAgent[] | null;

  originalSelection: ICompanyAgentSelection | null;

  getMembershipsRequest: ENetworkRequestStatus;
  companyMemberships: ICompanyMembership[];
  selectedCompanyMembership: ICompanyMembership | null;
}

export const initialState: IAgentsModuleDomain = {
  requestPending: false,
  agents: undefined,
  error: undefined,
  isFetchingTA: false,
  selectedTa: null,
  showTaDropdown: false,
  taNameSearch: '',

  companies: null,
  isFetchingCompanies: true,
  selectedCompany: null,
  showCompanyDropdown: false,
  companyNameSearch: '',
  selectedCompanyAgents: null,

  originalSelection: null,

  getMembershipsRequest: ENetworkRequestStatus.IDLE,
  companyMemberships: [],
  selectedCompanyMembership: null,
};
