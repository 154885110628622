import { TCurrencyCode } from 'interfaces';
import { SelectedAccommodation, BookingBuilderResponse, ENetworkRequestStatus } from 'services/BackendApi';
import { EProductCategory } from 'store/modules/bookingManager/sagas/bookingTransforms/BookingModel';
import { ERoomRateType } from '../../model';

export interface IBasketRoomTooltipInfo {
  category?: string;
  date?: string;
  mealPlan?: {
    title: string;
    description: string;
    total: string | null;
    totalBeforeDiscount: string | null;
  };
  totalBeforeDiscount: string | null;
}

export interface IBasketRoom extends IBasketRoomTooltipInfo {
  uuid: string;
  name: string;
  appliedOffers: string[];
  total: string | null;
  currency: TCurrencyCode;
  oneOrMoreItemsOnRequest: boolean;
  numberOfAdults?: number;
  agesOfAllChildren: number[];
  selectedMealPlanUuids?: string[];
  request: SelectedAccommodation;
  externalRateId?: string;
  externalMealPlanCode?: string;
}

export interface IBasketSupplement {
  uuid: string;
  name: string;
  category: EProductCategory;
  singlePrice: string | null;
  total: string | null;
  isOnRequestOrPartiallyOnRequest: boolean;
}

export interface IBasketSubdomain {
  rooms: Array<IBasketRoom | null>;
  supplements: IBasketSupplement[];
  total: string | null;
  totalBeforeDiscount: string | null;
  response: BookingBuilderResponse | null;
  hotelUuid: string | null;
  rateType: ERoomRateType | null;
  networkRequests: {
    basketUpdating: ENetworkRequestStatus;
    requestBooking: ENetworkRequestStatus;
  };
  isRequestBookingModalOpen: boolean;
  bookingHash?: string;
  requestBookingModalData: {
    title: string;
    firstName: string;
    lastName: string;
    comment: string;
    travelAgentName: string;
    isApplyMargin: boolean;
    marginType?: 'percentage' | 'flat';
    marginAmount?: number;
    isAgreeToTerms: boolean;
  };
}

export const basketInitialState: IBasketSubdomain = {
  rooms: [],
  supplements: [],
  total: null,
  totalBeforeDiscount: null,
  hotelUuid: null,
  response: null,
  rateType: null,
  networkRequests: {
    basketUpdating: ENetworkRequestStatus.IDLE,
    requestBooking: ENetworkRequestStatus.IDLE,
  },
  isRequestBookingModalOpen: false,
  requestBookingModalData: {
    title: '',
    firstName: '',
    lastName: '',
    comment: '',
    travelAgentName: '',
    isApplyMargin: false,
    isAgreeToTerms: false,
  },
};
