import React from 'react';
import classnames from 'classnames';
import { SortIcon } from './SortIcon';
import { ESortOrder } from 'store/common/types';

export interface ITableColumnHeaderProps {
  label: string | React.ReactNode;
  currentSortBy: string;
  currentSortOrder: ESortOrder;
  columnSortBy?: string;
}

export const TableColumnHeader: React.FC<ITableColumnHeaderProps> = React.memo(
  ({ label, currentSortBy, columnSortBy, currentSortOrder }) => {
    const columnClassname = classnames('group font-normal items-center px-2 justify-between h-8', {
      flex: columnSortBy,
      'hover:bg-gray-10 cursor-pointer rounded': !!columnSortBy,
      'bg-gray-10': !!columnSortBy && currentSortBy === columnSortBy,
    });

    return (
      <span className={columnClassname}>
        <span>{label}</span>
        {!!columnSortBy && <SortIcon sortBy={currentSortBy} sortOrder={currentSortOrder} newSortBy={columnSortBy} />}
      </span>
    );
  }
);
