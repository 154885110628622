import React from 'react';
import classnames from 'classnames';

export enum ERateType {
  STATIC_RATE,
  LIVE_RATE,
  INSTANT_BOOK,
}

export interface IRateTagProps {
  className?: string;
  rateType: ERateType;
}
export const RateTag = (props: IRateTagProps) => {
  const { className, rateType } = props;
  return (
    <span
      style={{
        padding: '2px 5px',
      }}
      className={classnames(
        `${className ?? ''} text-black font-hurmegeometric-sans text-[12px] leading-14px tracking-2xs rounded-1px not-italic font-normal uppercase`,
        {
          'bg-teal-30': rateType === ERateType.LIVE_RATE || ERateType.INSTANT_BOOK,
          'bg-white': rateType === ERateType.STATIC_RATE,
        }
      )}
    >
      {rateType === ERateType.LIVE_RATE && 'Live Rate'}
      {rateType === ERateType.STATIC_RATE && 'Static Rate'}
      {rateType === ERateType.INSTANT_BOOK && 'Instant Book'}
    </span>
  );
};
