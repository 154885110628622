import classNames from 'classnames';
import React from 'react';
import { AncillaryProductWithRatesWithUserData } from 'services/BookingManagerApi/types/AncillaryService';
import Checkbox from 'ui/Checkbox';
import { Multiselect } from 'ui/Multiselect';

export interface IAddAncillaryRightHandPanelOptionsProps {
  errorIds: string[];
  selectedProduct: AncillaryProductWithRatesWithUserData;
  updateSelectedProductWithUserData: (data: Partial<AncillaryProductWithRatesWithUserData['userData']>) => void;
  nonBooleanWrapperClassname?: string;
  disabled?: boolean;
  flexibleAttributesWithAnInvalidSelection?: any[];
  className?: string;
}

export const AddAncillaryRightHandPanelOptions = (props: IAddAncillaryRightHandPanelOptionsProps) => {
  const nonBooleanOptions = props.selectedProduct.product.flexibleAttributes.filter(fa => fa.type === 'option');
  const booleanOptions = props.selectedProduct.product.flexibleAttributes.filter(fa => fa.type === 'option-boolean');
  return (
    <div className={classNames('options-wrapper h-[200px] overflow-y-scroll space-y-10px flex-grow', props.className)}>
      <div className={classNames(props.nonBooleanWrapperClassname)}>
        {nonBooleanOptions.map(fa => {
          // get if this option has an invalid selection based on flexibleAttributesWithAnInvalidSelection
          const isInvalid = props.flexibleAttributesWithAnInvalidSelection?.find(
            faWithInvalidSelection => Number(faWithInvalidSelection.id) === Number(fa.id)
          );

          const isDisabled = props.disabled || (isInvalid && isInvalid.invalidType === 'attribute');

          return (
            <div key={fa.id} id={`option-id-${fa.id}`} className="option">
              <span className="block text-16px font-bold text-black mb-5px">{fa.name}</span>
              <Multiselect
                disabled={isDisabled}
                options={fa.values!.map(faVal => {
                  return {
                    value: faVal.value!,
                    label: faVal.value!,
                  };
                })}
                className={classNames({
                  'bg-ivory': !props.errorIds.includes(fa.id.toString()),
                  'bg-red-25 ': props.errorIds.includes(fa.id.toString()),
                  'o:bg-gray-10 o:cursor-auto': isDisabled,
                })}
                borderColourClass={classNames({
                  'border-gray-40': !props.errorIds.includes(fa.id.toString()),
                  'border-red-95 ': props.errorIds.includes(fa.id.toString()),
                  'o:border-gray-10': isDisabled,
                })}
                itemsClassname="bg-ivory"
                itemCtaClassName={classNames('hover:bg-gray-10', {
                  'o:cursor-auto': isDisabled,
                })}
                onUpdate={values => {
                  if (values.length >= 1) {
                    props.updateSelectedProductWithUserData({
                      optionSelections: {
                        ...props.selectedProduct?.userData.optionSelections,
                        [fa.id]: values.length >= 1 ? values[0] : undefined,
                      },
                    });
                  }
                }}
                selectedValues={
                  props.selectedProduct?.userData.optionSelections[fa.id]
                    ? [props.selectedProduct?.userData.optionSelections[fa.id].toString()]
                    : []
                }
                isCloseOnSelect
                isSingleSelectMode
                hideCheckboxes
              />
              {isInvalid && <span className="text-15px text-yellow-60 leading-19px">{isInvalid.errorMessage}</span>}
            </div>
          );
        })}
      </div>

      {booleanOptions.map(fa => {
        return (
          <div key={fa.id} id={`option-id-${fa.id}`} className="option">
            {/* need to render a checkbox for the boolean */}
            <label className="flex items-center space-x-5">
              <Checkbox
                disabled={false}
                checked={props.selectedProduct?.userData.optionSelections[fa.id] === true}
                onChange={e => {
                  props.updateSelectedProductWithUserData({
                    optionSelections: {
                      ...props.selectedProduct?.userData.optionSelections,
                      [fa.id]: e.currentTarget.checked ? true : false,
                    },
                  });
                }}
              />
              <span className="text-15px text-black">{fa.name}</span>
            </label>
          </div>
        );
      })}
    </div>
  );
};
