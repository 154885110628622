import React from "react";
import { ETaskStatus } from "services/TaskManagementApi/types/TaskManagementResponse";

export interface ITaskListStatusProps {
  status: ETaskStatus;
}

export const TaskListStatus = ({ status }: ITaskListStatusProps) => {
  const options = {
    [ETaskStatus.TO_DO]: {text: 'To Do', textColor: 'text-purple', bgColor: 'bg-purple-light'},
    [ETaskStatus.BLOCKED]: {text: 'Blocked', textColor: 'text-status-cancelled', bgColor: 'bg-status-light-cancelled'},
    [ETaskStatus.BACKLOG]: {text: 'Backlog', textColor: 'text-gray-100', bgColor: 'bg-gray-20'},
    [ETaskStatus.IN_PROGRESS]: {text: 'In Progress', textColor: 'text-bright-sky-blue', bgColor: 'bg-powder-blue'},
    [ETaskStatus.DONE]: {text: 'Done', textColor: 'text-green-hard', bgColor: 'bg-teal-20'},
  };
  return (
    <div className={`h-[26px] p-[5px] ${options[status].bgColor} rounded-[3px] justify-start items-start gap-2.5 inline-flex`}>
      {/* @ts-ignore-line */}
      <div className={`text-right ${options[status].textColor} text-xs font-normal font-pt-sans uppercase tracking-wide`} style={{ textWrap: 'nowrap' }}>{options[status].text}</div>
    </div>
  )
}