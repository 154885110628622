import { useSelector } from 'react-redux';
import { handleSaveBooking } from '../helpers';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import { ModalModes } from 'containers/SummaryForm/const';
import { useCallback } from 'react';

export const useSaveBooking = ({
  bookingBuild,
  setRequestInProgress,
  backendApi,
  selectedCompanyMembership,
  clientCountryCode,
  closeModalRatesWarning,
  containsLiveRates,
  setShouldShowLeaveAlert,
  setRedirectLocation,
  openModalRatesWarning,
  taMarginAmountOverride,
  actingOnBehalfOfUser,
  selectedTa,
  basketBuildUuid,
}) => {
  const bookingDomain = useSelector(BookingBuilderSelectors.bookingSelector);

  const handleSaveBookingButton = useCallback(async () => {
    setRequestInProgress(true);
    setShouldShowLeaveAlert(false);
    try {
      await handleSaveBooking({
        bookingBuild,
        backendApi,
        bookingDomain,
        clientCountryCode,
        selectedCompanyMembership,
        setShouldShowLeaveAlert,
        setRedirectLocation,
        taMarginAmountOverride,
        actingOnBehalfOfUser,
        selectedTa,
        basketBuildUuid,
      });
    } catch (e) {
      setRequestInProgress(false);
      setShouldShowLeaveAlert(true);
    }
    closeModalRatesWarning();
  }, [
    bookingBuild,
    backendApi,
    bookingDomain,
    clientCountryCode,
    closeModalRatesWarning,
    selectedCompanyMembership,
    setRedirectLocation,
    setRequestInProgress,
    setShouldShowLeaveAlert,
    taMarginAmountOverride,
    actingOnBehalfOfUser,
    selectedTa,
    basketBuildUuid,
  ]);

  const handleSaveBookingButtonTrigger = useCallback(() => {
    if (containsLiveRates) {
      openModalRatesWarning(ModalModes.SAVE);
    } else {
      handleSaveBookingButton();
    }
  }, [containsLiveRates, handleSaveBookingButton, openModalRatesWarning]);

  return { handleSaveBookingButton, handleSaveBookingButtonTrigger };
};
