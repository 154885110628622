import { last, cloneDeep } from 'lodash-es';
import { initialState, editingInitState, IBespokeNegotiationSubdomain } from './model';
import * as Actions from './actions';
import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';

export const bespokeNegotiationReducer = (state: IBespokeNegotiationSubdomain = initialState, action: Actions.Any) => {
  switch (action.type) {
    case Actions.GET_REQUEST:
      return produce(state, draftState => {
        draftState.request.get = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_SUCCESS:
      return produce(state, draftState => {
        draftState.request.get = ENetworkRequestStatus.SUCCESS;
        draftState.items = action.items;
        return draftState;
      });

    case Actions.GET_FAILURE:
      return produce(state, draftState => {
        draftState.request.get = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.UPDATE_REQUEST:
      return produce(state, draftState => {
        draftState.request.patch = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.UPDATE_SUCCESS:
      return produce(state, draftState => {
        draftState.request.patch = ENetworkRequestStatus.SUCCESS;
        draftState.items = action.items;
        draftState.editing = null;
        return draftState;
      });

    case Actions.UPDATE_FAILURE:
      return produce(state, draftState => {
        draftState.request.patch = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    
    case Actions.OPEN_MODAL:
      return produce(state, draftState => {
        const x = last(state.items);

        draftState.editing = x 
          ? cloneDeep(x)
          : editingInitState();

        draftState.saveAsNew = true;
        return draftState;
      });
    
    case Actions.CLOSE_MODAL:
      return produce(state, draftState => {
        draftState.editing = null
        return draftState;
      });
    
    case Actions.SELECT_VERSION:
      return produce(state, draftState => {
        const x = state.items?.find(x => x.version === action.version);
        if(x) {
          draftState.editing = cloneDeep(x);
        }
        return draftState;
      });

    case Actions.SELECT_LEAD:
      return produce(state, draftState => {
        draftState.editing!.lead = action.lead;
        return draftState;
      });
    
    case Actions.SET_DISCOUNT_AMOUNT:
      return produce(state, draftState => {
        draftState.editing!.discount.amount = action.amount;
        return draftState;
      });
    
    case Actions.SET_DISCOUNT_TYPE:
      return produce(state, draftState => {
        draftState.editing!.discount.type = action.discountType;
        return draftState;
      });

    case Actions.TOGGLE_APPLICATION:
      return produce(state, draftState => {
        draftState.editing!.appliedTo = toggleInArray(
          draftState.editing!.appliedTo,
          action.application
        );
        return draftState;
      });
    
    case Actions.TOGGLE_AFFECTED_ITEMS:
      return produce(state, draftState => {
        draftState.editing!.affectedItems = toggleInArray(
          draftState.editing!.affectedItems,
          action.items
        );
        return draftState;
      });

    case Actions.TOGGLE_SAVE_AS_NEW:
      return produce(state, draftState => {
        draftState.saveAsNew = !draftState.saveAsNew;
        return draftState;
      });

    default:
      return state;
  }
};

const toggleInArray = <T>(xs: T[], x: T): T[] => {
  const set = new Set(xs);     
  if(set.has(x)) {
    set.delete(x);
  } else {
    set.add(x);
  }
  return Array.from(set);
};
