import { createSelector } from 'reselect';
import { IBookingStatusOptionsSubdomain } from './model';
import { bookingStatusOptionsSubdomainSelector } from '../../selectors';

export const getBookingStatusOptionsSelector = createSelector(
  bookingStatusOptionsSubdomainSelector,
  (subdomain: IBookingStatusOptionsSubdomain) => subdomain.bookingStatusOptions
);

export const bookingStatusOptionsNetworkRequestsSelector = createSelector(
  bookingStatusOptionsSubdomainSelector,
  (subdomain: IBookingStatusOptionsSubdomain) => subdomain.networkRequests
);

export const bookingStatusModalSelector = createSelector(
  bookingStatusOptionsSubdomainSelector,
  (subdomain: IBookingStatusOptionsSubdomain) => subdomain.modal
);

export const isCancellationModalOpenSelector = createSelector(
  bookingStatusOptionsSubdomainSelector,
  (subdomain: IBookingStatusOptionsSubdomain) => subdomain.isCancellationModalOpen
);

export const cancellationModalDataSelector = createSelector(
  bookingStatusOptionsSubdomainSelector,
  (subdomain: IBookingStatusOptionsSubdomain) => subdomain.cancellationModalData
);
