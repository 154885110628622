import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBookingManagerApi, ISpecialRequestsResponse } from 'services/BookingManagerApi';
import {
  UPDATE_SPECIAL_REQUESTS_REQUEST,
  UpdateSpecialRequestsRequestAction,
  updateSpecialRequestsSuccessAction,
  updateSpecialRequestsFailureAction,
} from '../actions';

import { bookingUuidSelector } from '../../../selectors';
import { enqueueNotification } from 'store/modules/ui';

export function* updateDashboardSpecialRequestsSaga(action: UpdateSpecialRequestsRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<ISpecialRequestsResponse> = yield call(
      bookingManagerApi.updateSpecialRequests,
      bookingUuid,
      action.specialRequests
    );

    yield put(updateSpecialRequestsSuccessAction(result.data.specialRequests));
    yield put(enqueueNotification({
      message: 'Data saved successfully',
      options: { variant: 'success' },
    }));
  } catch (e) {
    yield put(updateSpecialRequestsFailureAction(e));
    yield put(enqueueNotification({
      message: 'There was an error saving data',
      options: { variant: 'error' },
    }));
  }
}

export function* watchBookingManagerDashboardSpecialRequestsUpdate() {
  yield takeLatest([UPDATE_SPECIAL_REQUESTS_REQUEST], updateDashboardSpecialRequestsSaga);
}
