import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';

import {
  depositFilterSetFromDateAction,
  depositFilterSetToDateAction,
  depositFilterSetStatusAction,
  depositFilterResetAction,
  depositFilterUpdateResultsAction,
  depositFilterSetBookingRefAction,
} from 'store/modules/ledger/actions';

import {
  depositFilterFromDateSelector,
  depositFilterToDateSelector,
  depositFilterStatusSelector,
  depositFilterBookingRefSelector,
} from 'store/modules/ledger/selectors';

import SingleDateInput from 'pureUi/SingleDateInput';
import { formatDate } from 'utils';
import classNames from 'classnames';
import { Multiselect } from 'ui/Multiselect';
import FluidButton from 'ui/FluidButton';

import { filterTypeOptions } from './FilterTypeOptions';
import { BookingRefInput } from './BookingRefInput';
import { theme } from '../../../../tailwind.config';
import PlusIcon from 'ui/Icons/plus.component.svg';
import { HidingTooltip } from 'ui/Tooltip';
import { PriceAsCentsInput } from 'ui/stateful/PriceAsCentsInput';
import { SvgIcon } from 'ui/SvgIcon';
import InfoIcon from 'ui/Icons/components/Info.component';

interface IDepositAccountFilterProps {
  isOpen: boolean;
  className?: string;
}

export const FILTERS_ANIMATION_DURATION = 300;

export const DepositAccountFilter: React.FC<IDepositAccountFilterProps> = ({ isOpen, className }) => {
  const dispatch = useDispatch();

  const depositFilterFromDate = useSelector(depositFilterFromDateSelector);
  const depositFilterToDate = useSelector(depositFilterToDateSelector);
  const depositFilterStatus = useSelector(depositFilterStatusSelector);
  const depositFilterBookingRef = useSelector(depositFilterBookingRefSelector);

  const contentHeight = useMemo(() => (isOpen ? 'auto' : 0), [isOpen]);
  const addBookingRefButtonClass = `
    add-booking-ref w-5 h-5 rounded-full flex justify-center items-center border border-solid
    border-brown-prime cursor-pointer hover:bg-brown-20
  `;

  const handleResetFilters = useCallback(() => {
    dispatch(depositFilterResetAction());
    dispatch(depositFilterUpdateResultsAction());
  }, [dispatch]);

  const handleSearchClick = useCallback(() => {
    dispatch(depositFilterUpdateResultsAction());
  }, [dispatch]);

  const renderTooltipContent = useCallback(
    () => (
      <div className="add-booking-ref-tooltip font-pt-sans text-13px leading-17px text-black p-5px">
        Add Booking Ref.
      </div>
    ),
    []
  );

  return (
    // @ts-ignore
    <AnimateHeight className="animate-height" duration={FILTERS_ANIMATION_DURATION} height={contentHeight}>
      <div className={classNames(className, 'booking-filter px-10px py-15px bg-ivory mb-5 rounded mx-15px')}>
        <h2 className="text-17px font-bold mt-0">Filters and Search Options</h2>

        <div className="flex items-end flex-wrap">
          {/* statuses */}
          <div className="mr-5 mb-15px">
            <span className="block text-13px pb-5px">Statuses</span>
            <Multiselect
              className="type-select min-w-400px bg-white text-15px"
              itemCtaClassName="hover:bg-teal-20 text-left"
              itemsClassname="bg-white"
              onUpdate={selectedValues => {
                dispatch(depositFilterSetStatusAction(selectedValues));
              }}
              options={[
                {
                  value: 'enquiry',
                  label: 'Enquiry',
                  iconHtml: <i className="fas fa-circle text-status-enquiry"></i>,
                  iconAlign: 'end',
                },
                {
                  value: 'requested',
                  label: 'Requested',
                  iconHtml: <i className="fas fa-circle text-status-requested"></i>,
                  iconAlign: 'end',
                },
                {
                  value: 'discarded',
                  label: 'Discarded',
                  iconHtml: <i className="fas fa-circle text-status-discarded"></i>,
                  iconAlign: 'end',
                },
                {
                  value: 'confirmed',
                  label: 'Confirmed',
                  iconHtml: <i className="fas fa-circle text-status-confirmed"></i>,
                  iconAlign: 'end',
                },
                {
                  value: 'cancelled',
                  label: 'Cancelled',
                  iconHtml: <i className="fas fa-circle text-status-cancelled"></i>,
                  iconAlign: 'end',
                },
                {
                  value: 'completed',
                  label: 'Completed',
                  iconHtml: <i className="fas fa-circle text-status-completed"></i>,
                  iconAlign: 'end',
                },
              ]}
              selectedValues={depositFilterStatus == null ? [] : depositFilterStatus}
            />
          </div>

          <div className="booking-ref-inputs mr-5px mb-15px">
            <span className="block text-13px pb-5px">Booking Ref.</span>
            <BookingRefInput
              bookingRef={depositFilterBookingRef}
              onChange={newValue => {
                dispatch(depositFilterSetBookingRefAction(newValue));
              }}
              onCrossButtonClick={() => {
                dispatch(depositFilterSetBookingRefAction(''));
              }}
            />
          </div>
        </div>

        <div className="flex flex-row">
          <FluidButton className="reset-filters mr-2" type="secondary" onClick={handleResetFilters}>
            Reset Filters
          </FluidButton>
          {/* <FluidButton className="search" type="primary" onClick={handleSearchClick}>
            Search
          </FluidButton> */}
        </div>
      </div>
    </AnimateHeight>
  );
};
