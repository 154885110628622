import { BookingBuilderResponse, Occasion } from 'services/BackendApi';
import { IHotelAccommodation, THotelAccommodationViewMode } from './types';

export const GET_HOTEL_ACCOMMODATION_REQUEST = 'bookingBuilder/GET_HOTEL_ACCOMMODATION_REQUEST';
export const GET_HOTEL_ACCOMMODATION_SUCCESS = 'bookingBuilder/GET_HOTEL_ACCOMMODATION_SUCCESS';
export const GET_HOTEL_ACCOMMODATION_FAILURE = 'bookingBuilder/GET_HOTEL_ACCOMMODATION_FAILURE';

export const UPDATE_ACCOMMODATION_RESPONSE_REQUEST = 'bookingBuilder/UPDATE_ACCOMMODATION_RESPONSE_REQUEST';
export const UPDATE_ACCOMMODATION_RESPONSE_SUCCESS = 'bookingBuilder/UPDATE_ACCOMMODATION_RESPONSE_SUCCESS';
export const UPDATE_ACCOMMODATION_RESPONSE_FAILURE = 'bookingBuilder/UPDATE_ACCOMMODATION_RESPONSE_FAILURE';

export const CLEAR_ACCOMMODATIONS = 'bookingBuilder/CLEAR_ACCOMMODATIONS';

export const SET_ACCOMMODATION_START_DATE = 'bookingBuilder/SET_ACCOMMODATION_START_DATE';
export const SET_ACCOMMODATION_END_DATE = 'bookingBuilder/SET_ACCOMMODATION_END_DATE';
export const SET_NUMBER_OF_ADULTS = 'bookingBuilder/SET_NUMBER_OF_ADULTS';
export const SET_AGES_OF_ALL_CHILDREN = 'bookingBuilder/SET_AGES_OF_ALL_CHILDREN';
export const SET_SELECTED_MEAL_PLAN_UUID = 'bookingBuilder/SET_SELECTED_MEAL_PLAN_UUID';
export const TOGGLE_OCCASION = 'bookingBuilder/TOGGLE_OCCASION';

export const SET_ACTIVE_ACCOMMODATION_TAB = 'bookingBuilder/SET_ACTIVE_ACCOMMODATION_TAB';
export const SET_ACTIVE_ACCOMMODATION_VIEW_MODE = 'bookingBuilder/SET_ACTIVE_ACCOMMODATION_VIEW_MODE';

export const OPEN_EDIT_ACCOMMODATIONS_MODAL = 'bookingBuilder/OPEN_EDIT_ACCOMMODATIONS_MODAL';
export const CLOSE_EDIT_ACCOMMODATIONS_MODAL = 'bookingBuilder/CLOSE_EDIT_ACCOMMODATIONS_MODAL';

export const ADD_ACCOMMODATION_TO_IS_OPEN = 'bookingBuilder/ADD_ACCOMMODATION_TO_IS_OPEN';
export const REMOVE_ACCOMMODATION_FROM_IS_OPEN = 'bookingBuilder/REMOVE_ACCOMMODATION_FROM_IS_OPEN';
export const CLEAR_ALL_ACCOMMODATION_FROM_IS_OPEN = 'bookingBuilder/CLEAR_ALL_ACCOMMODATION_FROM_IS_OPEN';
export const REPLACE_OPEN_ACCOMMODATIONS = 'bookingBuilder/REPLACE_OPEN_ACCOMMODATIONS';

export const SET_MODIFIED_WITHOUT_UPDATING = 'bookingBuilder/SET_MODIFIED_WITHOUT_UPDATING';
export const CLOSE_MIX_RATE_TYPES_ALERT = 'bookingBuilder/CLOSE_MIX_RATE_TYPES_ALERT';

// get the initial hotel accommodation products
export type GetHotelAccommodationRequestAction = ReturnType<typeof getHotelAccommodationRequestAction>;
export const getHotelAccommodationRequestAction = (hotelUuid: string, accommodationTab: number) => ({
  type: GET_HOTEL_ACCOMMODATION_REQUEST as typeof GET_HOTEL_ACCOMMODATION_REQUEST,
  hotelUuid,
  accommodationTab,
});
export type GetHotelAccommodationSuccessAction = ReturnType<typeof getHotelAccommodationSuccessAction>;
export const getHotelAccommodationSuccessAction = (hotelAccommodations: IHotelAccommodation[]) => ({
  type: GET_HOTEL_ACCOMMODATION_SUCCESS as typeof GET_HOTEL_ACCOMMODATION_SUCCESS,
  hotelAccommodations,
});
export type GetHotelAccommodationFailureAction = ReturnType<typeof getHotelAccommodationFailureAction>;
export const getHotelAccommodationFailureAction = (error: string) => ({
  type: GET_HOTEL_ACCOMMODATION_FAILURE as typeof GET_HOTEL_ACCOMMODATION_FAILURE,
  error,
});

// performing an update on an accommodation
export type UpdateAccommodationResponseRequestAction = ReturnType<typeof updateAccommodationResponseRequestAction>;
export const updateAccommodationResponseRequestAction = (accommodationUuid: string) => ({
  type: UPDATE_ACCOMMODATION_RESPONSE_REQUEST as typeof UPDATE_ACCOMMODATION_RESPONSE_REQUEST,
  accommodationUuid,
});
export type UpdateAccommodationResponseSuccessAction = ReturnType<typeof updateAccommodationResponseSuccessAction>;
export const updateAccommodationResponseSuccessAction = (
  accommodationUuid: string,
  bookingBuilderResponse: BookingBuilderResponse
) => ({
  type: UPDATE_ACCOMMODATION_RESPONSE_SUCCESS as typeof UPDATE_ACCOMMODATION_RESPONSE_SUCCESS,
  accommodationUuid,
  bookingBuilderResponse,
});
export type UpdateAccommodationResponseFailureAction = ReturnType<typeof updateAccommodationResponseFailureAction>;
export const updateAccommodationResponseFailureAction = (accommodationUuid: string, error: string) => ({
  type: UPDATE_ACCOMMODATION_RESPONSE_FAILURE as typeof UPDATE_ACCOMMODATION_RESPONSE_FAILURE,
  accommodationUuid,
  error,
});

// general utility
export type ClearAccommodationsAction = ReturnType<typeof clearAccommodationsAction>;
export const clearAccommodationsAction = () => ({
  type: CLEAR_ACCOMMODATIONS as typeof CLEAR_ACCOMMODATIONS,
});

// updating individual accommodation data
export type SetAccommodationStartDateAction = ReturnType<typeof setAccommodationStartDateAction>;
export const setAccommodationStartDateAction = (accommodationUuid: string, startDate: string) => ({
  type: SET_ACCOMMODATION_START_DATE as typeof SET_ACCOMMODATION_START_DATE,
  accommodationUuid,
  startDate,
});
export type SetAccommodationEndDateAction = ReturnType<typeof setAccommodationEndDateAction>;
export const setAccommodationEndDateAction = (accommodationUuid: string, endDate: string) => ({
  type: SET_ACCOMMODATION_END_DATE as typeof SET_ACCOMMODATION_END_DATE,
  accommodationUuid,
  endDate,
});

export type SetNumberOfAdultsAction = ReturnType<typeof setNumberOfAdultsAction>;
export const setNumberOfAdultsAction = (accommodationUuid: string, numberOfAdults: number) => ({
  type: SET_NUMBER_OF_ADULTS as typeof SET_NUMBER_OF_ADULTS,
  accommodationUuid,
  numberOfAdults,
});

export type SetAgesOfAllChildrenAction = ReturnType<typeof setAgesOfAllChildrenAction>;
export const setAgesOfAllChildrenAction = (accommodationUuid: string, agesOfAllChildren: number[]) => ({
  type: SET_AGES_OF_ALL_CHILDREN as typeof SET_AGES_OF_ALL_CHILDREN,
  accommodationUuid,
  agesOfAllChildren,
});

export type SetSelectedMealPlanUuidAction = ReturnType<typeof setSelectedMealPlanUuidAction>;
export const setSelectedMealPlanUuidAction = (accommodationUuid: string, selectedMealPlanUuids: string[]) => ({
  type: SET_SELECTED_MEAL_PLAN_UUID as typeof SET_SELECTED_MEAL_PLAN_UUID,
  accommodationUuid,
  selectedMealPlanUuids,
});

export type ToggleOccasionAction = ReturnType<typeof toggleOccasionAction>;
export const toggleOccasionAction = (accommodationUuid: string, occasionType: Occasion, isChecked: boolean) => ({
  type: TOGGLE_OCCASION as typeof TOGGLE_OCCASION,
  accommodationUuid,
  occasionType,
  isChecked,
});

export type SetActiveAccommodationTabAction = ReturnType<typeof setActiveAccommodationTabAction>;
export const setActiveAccommodationTabAction = (accommodationTabIndex: number) => ({
  type: SET_ACTIVE_ACCOMMODATION_TAB as typeof SET_ACTIVE_ACCOMMODATION_TAB,
  accommodationTabIndex,
});

export type SetActiveAccommodationViewModeAction = ReturnType<typeof setActiveAccommodationViewModeAction>;
export const setActiveAccommodationViewModeAction = (accommodationViewMode: THotelAccommodationViewMode) => ({
  type: SET_ACTIVE_ACCOMMODATION_VIEW_MODE as typeof SET_ACTIVE_ACCOMMODATION_VIEW_MODE,
  accommodationViewMode,
});

export type OpenEditAccommodationsModalAction = ReturnType<typeof openEditAccommodationsModalAction>;
export const openEditAccommodationsModalAction = () => ({
  type: OPEN_EDIT_ACCOMMODATIONS_MODAL as typeof OPEN_EDIT_ACCOMMODATIONS_MODAL,
});

export type CloseEditAccommodationsModalAction = ReturnType<typeof closeEditAccommodationsModalAction>;
export const closeEditAccommodationsModalAction = () => ({
  type: CLOSE_EDIT_ACCOMMODATIONS_MODAL as typeof CLOSE_EDIT_ACCOMMODATIONS_MODAL,
});

export type AddAccommodationToIsOpenAction = ReturnType<typeof addAccommodationToIsOpenAction>;
export const addAccommodationToIsOpenAction = (accommodationUuid: string) => ({
  type: ADD_ACCOMMODATION_TO_IS_OPEN as typeof ADD_ACCOMMODATION_TO_IS_OPEN,
  accommodationUuid,
});

export type RemoveAccommodationFromIsOpenAction = ReturnType<typeof removeAccommodationFromIsOpenAction>;
export const removeAccommodationFromIsOpenAction = (accommodationUuid: string) => ({
  type: REMOVE_ACCOMMODATION_FROM_IS_OPEN as typeof REMOVE_ACCOMMODATION_FROM_IS_OPEN,
  accommodationUuid,
});

export type ClearAllAccommodationsFromIsOpenAction = ReturnType<typeof clearAllAccommodationsFromIsOpenAction>;
export const clearAllAccommodationsFromIsOpenAction = () => ({
  type: CLEAR_ALL_ACCOMMODATION_FROM_IS_OPEN as typeof CLEAR_ALL_ACCOMMODATION_FROM_IS_OPEN,
});

export type ReplaceOpenAccommodationsAction = ReturnType<typeof replaceOpenAccommodationsAction>;
export const replaceOpenAccommodationsAction = (openAccommodationUuids: string[]) => ({
  type: REPLACE_OPEN_ACCOMMODATIONS as typeof REPLACE_OPEN_ACCOMMODATIONS,
  openAccommodationUuids,
});

export type SetModifiedWithoutUpdatingAction = ReturnType<typeof setModifiedWithoutUpdatingAction>;
export const setModifiedWithoutUpdatingAction = (isModifiedWithoutUpdating: boolean) => ({
  type: SET_MODIFIED_WITHOUT_UPDATING as typeof SET_MODIFIED_WITHOUT_UPDATING,
  isModifiedWithoutUpdating,
});

export type CloseMixedRateTypesAlertAction = ReturnType<typeof closeMixedRateTypesAlertAction>;
export const closeMixedRateTypesAlertAction = () => ({
  type: CLOSE_MIX_RATE_TYPES_ALERT as typeof CLOSE_MIX_RATE_TYPES_ALERT,
});

export type BookingBuilderHotelAccommodationAction =
  | GetHotelAccommodationRequestAction
  | GetHotelAccommodationSuccessAction
  | GetHotelAccommodationFailureAction
  | UpdateAccommodationResponseRequestAction
  | UpdateAccommodationResponseSuccessAction
  | UpdateAccommodationResponseFailureAction
  | ClearAccommodationsAction
  | SetAccommodationStartDateAction
  | SetAccommodationEndDateAction
  | SetNumberOfAdultsAction
  | SetSelectedMealPlanUuidAction
  | ToggleOccasionAction
  | SetAgesOfAllChildrenAction
  | SetActiveAccommodationTabAction
  | OpenEditAccommodationsModalAction
  | CloseEditAccommodationsModalAction
  | AddAccommodationToIsOpenAction
  | RemoveAccommodationFromIsOpenAction
  | ClearAllAccommodationsFromIsOpenAction
  | ReplaceOpenAccommodationsAction
  | SetModifiedWithoutUpdatingAction
  | SetActiveAccommodationViewModeAction
  | CloseMixedRateTypesAlertAction;
