import React, { useCallback } from 'react';
import classnames from 'classnames';
import Checkbox from 'ui/Checkbox';
import { ISalesRepresentative } from 'services/BackendApi';
import { renderFullName } from 'utils/guestInformation';
import countries from 'config/data/countries.json';

interface IMassAssignRowProps {
  sr: ISalesRepresentative;
  enabled: boolean;
  onChange: (srUuid: string, enabled: boolean) => void;
}

export const MassAssignRow: React.FC<IMassAssignRowProps> = React.memo(({ sr, enabled, onChange }) => {
  const srName = renderFullName(undefined, sr.firstName, sr.lastName);
  const srCountry = countries[sr.countryCode]?.name ?? '';

  const handleSrChange = useCallback(() => {
    onChange(sr.uuid, !enabled);
  }, [sr, enabled, onChange]);

  return (
    <div
      key={sr.uuid}
      className={classnames('sr-list-row text-black text-15px h-30px flex items-center mt-5px mb-5px', {
        'bg-green-25': enabled,
      })}
    >
      <div className="sr-name min-w-200px max-w-200px">
        <label className="sr-list-row-checkbox-label flex flex-row items-center pl-3px">
          <Checkbox checked={enabled} onClick={handleSrChange} />
          <span className="ml-2 overflow-hidden whitespace-nowrap text-ellipsis" title={srName}>
            {srName}
          </span>
        </label>
      </div>
      <td className="sr-country w-135px ml-50px">{srCountry}</td>
    </div>
  );
});
