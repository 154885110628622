import React from 'react';
import styled from 'styled-components';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { pureUiTheme } from 'pureUi/pureUiTheme';

export const SidebarNav = styled.dl`
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    background-color: ${pureUiTheme.colors.grayDepth4};
`;

export const NavGroupTitle = styled.dt`
    margin-bottom: 5px;
    margin-top: 20px;
    padding: 5px;
    font-family: ${pureUiTheme.typography.sansSerifFont};
    font-size: 12px;
    text-transform: uppercase;
    color: ${pureUiTheme.colorRoles.grayLabel};
    &:first-of-type {
        margin-top: 0;
    }
`

const NavItemComponent = (props: NavLinkProps) => {
    const { children, className, ...linkProps } = props;
    return <li className={className}><NavLink activeClassName="active" {...linkProps}>{children}</NavLink></li>;
}

export const NavItem = styled(NavItemComponent)`
    a {
        display: block;
        font-family: 'PT Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        color: ${pureUiTheme.colors.blackText};
        padding: 0 17px; 
        line-height: 34px;
        transition: all 0.1s linear;
    }

    a:hover {
        cursor: pointer;
        color: ${pureUiTheme.colors.blackText};
    }

    a.active {
        background-color: ${pureUiTheme.colors.greenLight};
        color: ${pureUiTheme.colors.greenDark};
        font-weight: 700;
    }
`;
