import React from 'react';

interface IErrorMessageProps {
  message: string;
}

export const ErrorMessage = React.memo(({ message }: IErrorMessageProps) => {
  return (
    <div className="bg-red-20 text-red-100 text-center font-pt-sans p-4 mb-4 absolute top-0 left-0 w-full">
      <i className="fas fa-exclamation-triangle"></i>
      <span className="ml-2">{message}</span>
    </div>
  );
});
