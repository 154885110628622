import React from 'react';
import classnames from 'classnames';
import { EProductLineDisplayMode, ProductLineWithPrice } from './ProductLineWithPrice';
import { TCurrencyCode } from 'interfaces';
import { IProposalBookingProduct } from 'store/modules/proposalDetails/types';

interface IProposalNonAccommodationProductProps {
  products: IProposalBookingProduct[];
  title: string;
  currencyCode: TCurrencyCode;
  displayMode: EProductLineDisplayMode;
  className?: string;
}

export const ProposalNonAccommodationProduct: React.FC<IProposalNonAccommodationProductProps> = React.memo(
  ({ products, title, currencyCode, displayMode, className }) => {
    return (
      <div className="proposal-non-accommodation-product px-5 pt-15px border-solid border-b border-gray-20">
        <div className="nap-data flex items-center justify-between">
          <p className="nap-title font-hurmegeometric-sans text-flint text-13px leading-16px uppercase font-bold m-0">
            {title}
          </p>
        </div>

        <div className="product-lines mt-3px">
          {products.map((product, index) => {
            return (
              <ProductLineWithPrice
                key={index}
                name={product.title}
                tertiaryText={product.tertiaryText}
                total={product.total}
                totalBeforeDiscount={product.totalBeforeDiscount}
                currencyCode={currencyCode}
                isOnRequest={product.isOnRequest}
                displayMode={displayMode}
                className={classnames('non-accommodation-product mb-15px', className)}
              />
            );
          })}
        </div>
      </div>
    );
  }
);
