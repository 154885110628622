import React, { useCallback } from 'react';
import classNames from 'classnames';
import { ProposalBookingInfo } from './ProposalBookingInfo';
import { IProposalBooking } from 'store/modules/proposalDetails/types';

interface IProposalDetailsItemProps {
  booking: IProposalBooking;
  selected: boolean;
  onSelectionToggle: (bookingUuid: string, selected: boolean) => void;
  className?: string;
}

export const ProposalItem: React.FC<IProposalDetailsItemProps> = React.memo(({ booking, className, selected, onSelectionToggle }) => {
  const handleToggleCheckbox = useCallback((checked: boolean) => {
    onSelectionToggle(booking.uuid, checked);
  }, [booking, onSelectionToggle]);

  return (
    <div className={classNames('proposal-item flex', className, { 'border-3 border-solid border-teal-80': selected})}>
      <div className="proposal-image w-360px h-175px">
        <img
          className="block w-full h-full object-cover"
          src={booking.hotel.featuredPhoto?.url}
          alt="Hotel photo"
        />
      </div>

      <ProposalBookingInfo booking={booking} checkboxChecked={selected} onToggleCheckbox={handleToggleCheckbox} />
    </div>
	);
});