import React, { useLayoutEffect } from 'react';
import { Link } from 'ui/Link';
import { useDispatch, useSelector } from 'react-redux';
import * as CompanyInfoActions from 'store/modules/companyInfo/actions';
import { getCompanySelector, getCurrentUser } from 'store/modules/auth';
import { ENetworkRequestStatus, EUserType, ICompany, IUser } from 'services/BackendApi';
import { companyLogoNetworkSelector, companyLogoUrlSelector } from 'store/modules/companyInfo/selectors';
import { getAvailableWorkspacesLinks } from 'ui/InvHeader/inventoryMap';
import { getMainCompanyLogoSelector } from 'store/modules/bootstrap/selectors';

export const InvHeaderLogo: React.FC = () => {
  const company = useSelector(getCompanySelector) as ICompany;
  const mainCompanyLogo = useSelector(getMainCompanyLogoSelector);
  const companyLogoUrl = useSelector(companyLogoUrlSelector);
  const companyLogoNetwork = useSelector(companyLogoNetworkSelector);
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser) as IUser;
  const isTA = user.type === EUserType.TA;

  useLayoutEffect(() => {
    if (company?.uuid) {
      dispatch(CompanyInfoActions.fetchCompanyLogoAction(company.uuid));
    }
  }, [company?.uuid]);

  if (companyLogoNetwork.fetchLogo === ENetworkRequestStatus.PENDING) {
    return <div className="inv-header-logo"></div>;
  }

  const availableWorkspacesLinks = getAvailableWorkspacesLinks(user);
  let headerLogoUrl = '/';
  if (availableWorkspacesLinks.length === 1) {
    headerLogoUrl = availableWorkspacesLinks[0]?.urlGroup ? availableWorkspacesLinks[0]?.urlGroup()[0] : '/';
  }

  const useCompanyLogoUrl = !!companyLogoUrl;

  if (useCompanyLogoUrl && isTA) {
    return (
      <div className="inv-header-logo">
        <a href={headerLogoUrl}>
          <img style={{ maxHeight: '70px', maxWidth: '218px' }} src={companyLogoUrl + '?isIcon=true'} />
        </a>
      </div>
    );
  }

  if (mainCompanyLogo?.url) {
    return (
      <div className="inv-header-logo">
        <Link type={headerLogoUrl.includes('://') ? 'external' : 'internal'} to={headerLogoUrl}>
          <img style={{ maxHeight: '70px', maxWidth: '218px' }} src={mainCompanyLogo?.url + '?isIcon=true'} />
        </Link>
      </div>
    );
  }

  return <div className="inv-header-logo"></div>;
};
