import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ICompany } from 'services/BackendApi';
import { CircleIconButton } from 'ui/CircleIconButton';
import { settingsBrown } from 'ui/Icons';
import { Table } from 'ui/Table';
import { ESortOrder } from 'store/common/types';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';

export interface ITravelCompaniesTableProps {
  className?: string;
  companies: ICompany[];
  loading: boolean;
  onDelete: (ICompany) => void;
}

export const TravelCompaniesTable: React.FC<ITravelCompaniesTableProps> = props => {
  const columns = useMemo(
    () => [
      {
        id: 'id',
        label: 'ID',
        width: '80px',
        className: 'pl-4',
      },
      {
        id: 'company-name',
        label: 'Company Name',
        width: '250px',
      },
      {
        id: 'country',
        label: 'Country',
        width: '70px',
      },
      {
        id: 'address',
        label: 'Address',
        width: '230px',
      },
      {
        id: 'phone',
        label: 'Phone',
        width: '120px',
      },
      {
        id: 'website',
        label: 'Website',
        width: '200px',
      },
      {
        id: 'actions',
        label: '',
        width: '58px',
      },
    ],
    []
  );

  const rows = useMemo(() => {
    return props.companies.map(company => {
      const rowItemClassName = 'text-ellipsis overflow-hidden whitespace-nowrap';

      const actions = (
        <div className="flex items-center relative">
          <div className="w-4px h-34px rounded bg-brown-15 mr-10px" />
          <HidingTextTooltip tooltipContent="Settings" position="bottom" tooltipContentClassname="px-[7px] py-[5px]">
            <Link to={`/travel-companies/${company.uuid}/settings`}>
              <CircleIconButton
                type="secondary"
                className="settings mx-1"
                iconClass="inline relative left-0 top-2px w-14px h-14px"
                iconSrc={settingsBrown}
              />
            </Link>
          </HidingTextTooltip>

          {/* Remove button is hidden because it is not clear if it is possible to remove a travel company */}
          {/* <Tooltip content="Remove">
            <CircleIconButton
              type="secondary"
              className="delete mx-1"
              iconClass="far fa-trash-alt"
              onClick={() => props.onDelete(company)}
            />
          </Tooltip> */}
        </div>
      );

      return {
        id: company.companyId,
        cells: [
          {
            id: 'id',
            value: company.companyId,
            className: `${rowItemClassName} pl-4`,
          },
          {
            id: 'company-name',
            title: company.name,
            value: company.name,
            className: rowItemClassName,
          },
          {
            id: 'country',
            value: company.countryCode,
            className: rowItemClassName,
          },
          {
            id: 'address',
            title: company.address,
            value: company.address,
            className: rowItemClassName,
          },
          {
            id: 'phone',
            value: company.phoneNumber,
            className: rowItemClassName,
          },
          {
            id: 'website',
            title: company.website ?? undefined,
            value: company.website,
            className: rowItemClassName,
          },
          {
            id: 'actions',
            value: actions,
          },
        ],
      };
    });
  }, [props.companies]);

  return (
    <Table
      columns={columns}
      rows={rows}
      sortBy="company.name"
      sortOrder={ESortOrder.ASC}
      onSort={() => {}}
      messageWhenEmpty="No results"
      loading={props.loading}
    />
  );
};
