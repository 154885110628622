import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  makeBookingManagerApi,
  ITopNavigationDataResponse,
  IBookingInformationReservationTeamDataResponse,
} from 'services/BookingManagerApi';
import {
  GET_TOP_NAVIGATION_DATA_REQUEST,
  GET_TOP_NAVIGATION_DATA_INLINE_REQUEST,
  getTopNavigationDataSuccessAction,
  getTopNavigationDataFailureAction,
} from '../actions';

import { bookingUuidSelector } from '../../../selectors';

export function* getDashboardTopNavigationDataSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<ITopNavigationDataResponse> = yield call(
      bookingManagerApi.getTopNavigationData,
      bookingUuid
    );

    const bookingInformationReservationTeamResult: AxiosResponse<IBookingInformationReservationTeamDataResponse> = yield call(
      bookingManagerApi.getBookingInformationReservationTeamData,
      bookingUuid
    );

    yield put(
      getTopNavigationDataSuccessAction(
        result.data.topNavigationData,
        bookingInformationReservationTeamResult.data.bookingInformationReservationTeamData
      )
    );
  } catch (e) {
    yield put(getTopNavigationDataFailureAction(e));
  }
}

export function* watchBookingManagerDashboardTopNavigationData() {
  yield takeLatest(
    [GET_TOP_NAVIGATION_DATA_REQUEST, GET_TOP_NAVIGATION_DATA_INLINE_REQUEST],
    getDashboardTopNavigationDataSaga
  );
}
