import * as Yup from 'yup';
import { ETravelAgentFormMode } from './helpers';
import { ETravelAgentStatus } from 'services/BackendApi';

export const getTravelAgentFormValidationSchema = (mode: ETravelAgentFormMode) => {
  const emailValidationSchema = Yup.string()
    .email('Must be valid email')
    .required('This field is required');

  return Yup.object().shape({
    title: Yup.string(),
    taFirstName: Yup.string().required('This field is required'),
    taLastName: Yup.string().required('This field is required'),
    countryCode: Yup.string()
      .required('This field is required')
      .nullable(),
    companyUuid: Yup.string()
      .when('status',{
        is: (status) => status === ETravelAgentStatus.ACCEPTED || status === ETravelAgentStatus.VERIFIED,
        then: Yup.string().nullable().required('This field is required'),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    companyRole: Yup.string(),
    landline: Yup.string(),
    mobile: Yup.string(),
    taEmail: emailValidationSchema,
    taPassword: Yup.string().nullable().notRequired(),
    status: Yup.string()
      .required('This field is required')
      .nullable(),
    comment: Yup.string(),
  });
};
