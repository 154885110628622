import React from 'react';
import classnames from 'classnames';
import { ESortOrder } from 'store/common/types';

export interface ISortIconProps {
  sortBy: string;
  newSortBy: string;
  sortOrder: ESortOrder;
}

export const SortIcon: React.FC<ISortIconProps> = React.memo(({ 
  sortBy, newSortBy, sortOrder
}) => {
  const spanClassName = classnames('fa-stack fa-lg', {
    'group-hover:text-gray-40 text-ivory': newSortBy !== sortBy
  });
  const arrowUpClassName = classnames(
    'fas fa-stack-1x fa-sort-up', 
    { 
      'text-gray-100': newSortBy === sortBy && sortOrder === ESortOrder.ASC, 
      'text-gray-40': newSortBy === sortBy && sortOrder === ESortOrder.DESC
    }
  );
  const arrowDownClassName = classnames(
    'fas fa-stack-1x fa-sort-down',
    { 
      'text-gray-100': newSortBy === sortBy && sortOrder === ESortOrder.DESC,
      'text-gray-40': newSortBy === sortBy && sortOrder === ESortOrder.ASC 
    }
  );

  return (
    <span style={{ marginRight: '-18px', width: '38px' }} className={spanClassName}>
      <i className={arrowUpClassName}></i>
      <i className={arrowDownClassName}></i>
    </span>
  );
});