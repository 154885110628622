import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  travelCompanyUuidSelector,
  bulkEditSelector,
  chmEditLoadSelector,
  editMarkupRequestAction,
  closeBulkEditModalAction,
  setBulkEditValueAction,
} from 'store/modules/companyHotelMarkup';
import { IBulkEdit } from 'store/modules/companyHotelMarkup/model';

import { StandardModal } from 'pureUi/Modal';
import FluidButton from 'ui/FluidButton';
import { NumericMaskedInput } from 'ui/stateful/MaskedInput';
import { ENetworkRequestStatus } from 'services/BackendApi';

interface EditMarkupModalProps {
  isOpen: boolean;
}

export const EditMarkupModal: React.FC<EditMarkupModalProps> = React.memo(({ isOpen }) => {
  const companyUuid = useSelector(travelCompanyUuidSelector);
  const editMarkupLoad = useSelector(chmEditLoadSelector);
  const bulkEdit = useSelector(bulkEditSelector) as IBulkEdit;
  const dispatch = useDispatch();

  const handleCloseClick = useCallback(() => {
    dispatch(closeBulkEditModalAction());
  }, [dispatch]);

  const handleSaveClick = useCallback(() => {
    dispatch(editMarkupRequestAction(companyUuid!));
  }, [dispatch, companyUuid]);

  const handleEdit = useCallback(
    (value: string) => {
      dispatch(setBulkEditValueAction(companyUuid!, value));
    },
    [dispatch, companyUuid]
  );

  if (!isOpen) {
    return null;
  }

  return (
    <StandardModal
      className="items-start bg-black-true-transparent-60 z-11"
      frameClassName={`pt-25px px-9 pb-8 rounded text-black border-black-true font-pt-sans overflow-y-hidden w-400px`}
      showCloseButton={false}
      closeOnOutsideClick={true}
      onClose={handleCloseClick}
      removePadding
    >
      <div
        className="text-base text-brown-100 absolute top-6 right-8 cursor-pointer outline-none"
        onClick={handleCloseClick}
      >
        <i className="fas fa-times"></i>
      </div>
      <h3 className="font-noe-display font-normal text-21px leading-29px mt-0 mb-0">Edit Mark-Ups Selected</h3>

      <div className="mt-25px">
        <span className="font-bold">New Mark-Up</span>
        <span className="ml-1">(%)</span>
        <div className="flex text-black items-baseline my-2">
          <div className="w-16">
            <NumericMaskedInput
              inputClassName="markup-input"
              initialValue="0.00"
              value={bulkEdit.editedValue}
              mask="##.##"
              onChange={handleEdit}
              allowNegative
            />
          </div>
          <span className="ml-2 text-15px">Including two decimals (##.##)</span>
        </div>
      </div>

      <div className="flex mt-25px">
        <FluidButton
          type="secondary"
          disabled={editMarkupLoad === ENetworkRequestStatus.PENDING}
          onClick={handleCloseClick}
        >
          Cancel
        </FluidButton>
        <FluidButton
          type="primary"
          className="ml-3"
          isLoading={editMarkupLoad === ENetworkRequestStatus.PENDING}
          disabled={parseFloat(bulkEdit.editedValue) === 0}
          onClick={handleSaveClick}
        >
          Save
        </FluidButton>
      </div>
    </StandardModal>
  );
});
