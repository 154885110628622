import { call, takeLatest, put, all, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import * as Actions from './actions';
import { makeBookingManagerApi, IBookingComment, IBookingComments } from 'services/BookingManagerApi';
import { bookingUuidSelector } from '../../selectors';

export function* getBookingCommentsRequestSaga() {
  try {
    const bmsClient = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    // get all the comments against this booking and add them to the store
    const getCommentsResponse: AxiosResponse<IBookingComments> = yield call(
      bmsClient.getCommentsForBooking,
      bookingUuid
    );
    yield put(Actions.getBookingCommentsSuccessAction(getCommentsResponse.data.comments));
  } catch (e) {
    console.error(e);
    yield put(Actions.addBookingCommentFailureAction(e));
  }
}

export function* addBookingCommentRequestSaga(action: Actions.AddBookingCommentRequestAction) {
  try {
    const bmsClient = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    // post the comment...
    const postCommentResponse: AxiosResponse<IBookingComments> = yield call(
      bmsClient.addCommentToBooking,
      bookingUuid,
      action.commentText,
      action.commentType
    );

    // ...then once its done, add it into the comments store
    yield put(Actions.addBookingCommentSuccessAction(postCommentResponse.data.comments));
  } catch (e) {
    console.error(e);
    yield put(Actions.addBookingCommentFailureAction(e));
  }
}

export function* updateBookingCommentRequestSaga(
  action: Actions.UpdateBookingCommentRequestAction | Actions.OnCompleteEditingCommentAction
) {
  try {
    const bmsClient = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    // patch the comment...
    const patchCommentResponse: AxiosResponse<IBookingComments> = yield call(
      bmsClient.updateCommentOnBooking,
      bookingUuid,
      action.comment.uuid,
      action.newText
    );

    // ...then once its done, add it into the comments store
    yield put(Actions.updateBookingCommentSuccessAction(action.comment, patchCommentResponse.data.comments));
  } catch (e) {
    console.error(e);
    yield put(Actions.updateBookingCommentFailureAction(e));
  }
}

export function* deleteBookingCommentRequestSaga(action: Actions.DeleteBookingCommentRequestAction) {
  try {
    const bmsClient = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    // delete the comment...
    const deleteCommentResponse: AxiosResponse<IBookingComments> = yield call(
      bmsClient.deleteCommentOnBooking,
      bookingUuid,
      action.comment.uuid
    );

    // ...then once its done, delete it from the store
    yield put(Actions.deleteBookingCommentSuccessAction(action.comment, deleteCommentResponse.data.comments));
  } catch (e) {
    console.error(e);
    yield put(Actions.updateBookingCommentFailureAction(e));
  }
}

export function* watchBookingManagerComments() {
  yield all([
    takeLatest([Actions.ADD_BOOKING_COMMENT_REQUEST], addBookingCommentRequestSaga),
    takeLatest([Actions.GET_BOOKING_COMMENTS_REQUEST], getBookingCommentsRequestSaga),
    takeLatest(
      [Actions.UPDATE_BOOKING_COMMENT_REQUEST, Actions.ON_COMPLETE_EDITING_COMMENT],
      updateBookingCommentRequestSaga
    ),
    takeLatest([Actions.DELETE_BOOKING_COMMENT_REQUEST], deleteBookingCommentRequestSaga),
  ]);
}
