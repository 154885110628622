import { TCountryCode } from 'interfaces';
import { RouteComponentProps } from 'react-router';
import { NameSearchResponseData, ErrorResponse, ITravelAgent, ICompany, ISearchQuery } from 'services/BackendApi';
import { ICompanyMembership } from 'services/BookingManagerApi/types/CompanyMemberships';

/**
 * Any new actions you make here ALSO needed to be listed at src/store/modules/bookingBuilder/reducer.ts
 * under the section with the `SearchActions`.
 */
export const INITIALIZE_QUERY = 'bookingBuilder/search/INITIALIZE_QUERY';
export const POPULATE_QUERY = 'bookingBuilder/search/POPULATE_QUERY';
export const CLEAR_EXTENDED_QUERY = 'bookingBuilder/search/CLEAR_EXTENDED_QUERY';
export const CLEAR_SEARCH_RESULTS = 'bookingBuilder/search/CLEAR_SEARCH_RESULTS';
export const EXECUTE = 'bookingBuilder/search/EXECUTE';

export const NAME_SEARCH_SUCCESS = 'bookingBuilder/search/NAME_SEARCH_SUCCESS';
export const NAME_SEARCH_FAILURE = 'bookingBuilder/search/NAME_SEARCH_FAILURE';
export const SET_NAME_SEARCH_RESUTS_VISIBILITY = 'bookingBuilder/search/SET_NAME_SEARCH_RESUTS_VISIBILITY';
export const DESTINATION_CHANGE = 'bookingBuilder/search/DESTINATION_CHANGE';
export const DATE_RANGE_CHANGE = 'bookingBuilder/search/DATE_RANGE_CHANGE';
export const TOGGLE_LODGING_CONTROLS = 'bookingBuilder/search/TOGGLE_LODGING_CONTROLS';
export const SET_LODGING_CONTOLS_VISBILITY = 'bookingBuilder/search/SET_LODGING_CONTOLS_VISBILITY';

export const INCREMENT_ROOM = 'bookingBuilder/search/INCREMENT_ROOM';
export const SET_ACTIVE_LODGING_INDEX = 'bookingBuilder/search/SET_ACTIVE_LODGING_INDEX';
export const INCREMENT_ACTIVE_LODGING_INDEX = 'bookingBuilder/search/INCREMENT_ACTIVE_LODGING_INDEX';
export const INCREMENT_ADULT = 'bookingBuilder/search/INCREMENT_ADULT';
export const INCREMENT_CHILD = 'bookingBuilder/search/INCREMENT_CHILD';
export const SET_AGE = 'bookingBuilder/search/SET_AGE';
export const TOGGLE_REPEAT_GUEST = 'bookingBuilder/search/TOGGLE_REPEAT_GUEST';

export const GET_COMPANIES_REQUEST = 'bookingBuilder/search/GET_COMPANIES_REQUEST';
export const GET_COMPANIES_SUCCESS = 'bookingBuilder/search/GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILURE = 'bookingBuilder/search/GET_COMPANIES_FAILURE';

export const GET_TRAVEL_AGENTS_BY_COMPANY_ID_SUCCESS = 'bookingBuilder/search/GET_TRAVEL_AGENTS_BY_COMPANY_ID_SUCCESS';
export const GET_TRAVEL_AGENTS_BY_COMPANY_ID_FAILURE = 'bookingBuilder/search/GET_TRAVEL_AGENTS_BY_COMPANY_ID_FAILURE';

export const SHOW_COMPANY_DROPDOWN = 'bookingBuilder/search/SHOW_COMPANY_DROPDOWN';
export const SHOW_TA_DROPDOWN = 'bookingBuilder/search/SHOW_TA_DROPDOWN';
export const SEARCH_TA_BY_NAME = 'bookingBuilder/search/SEARCH_TA_BY_NAME';
export const SEARCH_COMPANY_BY_NAME = 'bookingBuilder/search/SEARCH_COMPANY_BY_NAME';

export const CLEAR_SELECTED_TA = 'bookingBuilder/search/CLEAR_SELECTED_TA';
export const SELECTED_COMPANY_CHANGE = 'bookingBuilder/search/SELECTED_COMPANY_CHANGE';
export const SELECTED_TA_CHANGE = 'bookingBuilder/search/SELECTED_TA_CHANGE';

export const GUEST_COUNTRY_CHANGE = 'bookingBuilder/search/GUEST_COUNTRY_CHANGE';

export const SET_TRAVEL_COMPANY_MEMBERSHIP = 'bookingBuilder/search/SET_TRAVEL_COMPANY_MEMBERSHIP';

export type InitializeQueryAction = ReturnType<typeof initializeQueryAction>;
export const initializeQueryAction = (queryString: string) => ({
  type: INITIALIZE_QUERY as typeof INITIALIZE_QUERY,
  queryString,
});

export type PopulateQueryAction = ReturnType<typeof populateQueryAction>;
export const populateQueryAction = (query: ISearchQuery) => ({
  type: POPULATE_QUERY as typeof POPULATE_QUERY,
  query,
});

export type ClearExtendedQueryAction = ReturnType<typeof clearExtendedQueryAction>;
export const clearExtendedQueryAction = () => ({
  type: CLEAR_EXTENDED_QUERY as typeof CLEAR_EXTENDED_QUERY,
});

export type ClearSearchResultsAction = ReturnType<typeof clearSearchResultsAction>;
export const clearSearchResultsAction = () => ({
  type: CLEAR_SEARCH_RESULTS as typeof CLEAR_SEARCH_RESULTS,
});

export type ExecuteAction = ReturnType<typeof executeAction>;
export const executeAction = (history: RouteComponentProps['history']) => ({
  type: EXECUTE as typeof EXECUTE,
  history,
});

export type DestinationChangeAction = ReturnType<typeof destinationChangeAction>;
export const destinationChangeAction = (value: string) => ({
  type: DESTINATION_CHANGE as typeof DESTINATION_CHANGE,
  value,
});

export type ToggleRepeatGuestAction = ReturnType<typeof toggleRepeatGuestAction>;
export const toggleRepeatGuestAction = () => ({
  type: TOGGLE_REPEAT_GUEST as typeof TOGGLE_REPEAT_GUEST,
});

export type ToggleLodgingControlsAction = ReturnType<typeof toggleLodgingControlsAction>;
export const toggleLodgingControlsAction = () => ({
  type: TOGGLE_LODGING_CONTROLS as typeof TOGGLE_LODGING_CONTROLS,
});

export type SetLodgingControlsVisibilityAction = ReturnType<typeof setLodgingControlsVisibilityAction>;
export const setLodgingControlsVisibilityAction = (visible: boolean) => ({
  type: SET_LODGING_CONTOLS_VISBILITY as typeof SET_LODGING_CONTOLS_VISBILITY,
  visible,
});

export type IncrementRoomAction = ReturnType<typeof incrementRoomAction>;
export const incrementRoomAction = (step: number) => ({
  type: INCREMENT_ROOM as typeof INCREMENT_ROOM,
  step,
});

export type SetActiveLodgingIndexAction = ReturnType<typeof setActiveLodgingIndexAction>;
export const setActiveLodgingIndexAction = (index: number) => ({
  type: SET_ACTIVE_LODGING_INDEX as typeof SET_ACTIVE_LODGING_INDEX,
  index,
});

export type IncrementActiveLodgingIndexAction = ReturnType<typeof incrementActiveLodgingIndexAction>;
export const incrementActiveLodgingIndexAction = (step: number) => ({
  type: INCREMENT_ACTIVE_LODGING_INDEX as typeof INCREMENT_ACTIVE_LODGING_INDEX,
  step,
});

export type IncrementAdultAction = ReturnType<typeof incrementAdultAction>;
export const incrementAdultAction = (lodgingIndex: number, step: number) => ({
  type: INCREMENT_ADULT as typeof INCREMENT_ADULT,
  lodgingIndex,
  step,
});

export type IncrementChildAction = ReturnType<typeof incrementChildAction>;
export const incrementChildAction = (lodgingIndex: number, step: number) => ({
  type: INCREMENT_CHILD as typeof INCREMENT_CHILD,
  lodgingIndex,
  step,
});

export type SetAgeAction = ReturnType<typeof setAgeAction>;
export const setAgeAction = (lodgingIndex: number, childIndex: number, value: string) => ({
  type: SET_AGE as typeof SET_AGE,
  lodgingIndex,
  childIndex,
  value,
});

export type NamesSearchSuccessAction = ReturnType<typeof namesSearchSuccessAction>;
export const namesSearchSuccessAction = (successResponse: NameSearchResponseData) => ({
  type: NAME_SEARCH_SUCCESS as typeof NAME_SEARCH_SUCCESS,
  successResponse,
});

export type NamesSearchFailureAction = ReturnType<typeof namesSearchFailureAction>;
export const namesSearchFailureAction = (errorResponse: ErrorResponse) => ({
  type: NAME_SEARCH_FAILURE as typeof NAME_SEARCH_FAILURE,
  errorResponse,
});

export type SetNamesSearchResultsVisibilityAction = ReturnType<typeof setNamesSearchResultsVisibilityAction>;
export const setNamesSearchResultsVisibilityAction = (visible: boolean) => ({
  type: SET_NAME_SEARCH_RESUTS_VISIBILITY as typeof SET_NAME_SEARCH_RESUTS_VISIBILITY,
  visible,
});

export type DateRangeChangeAction = ReturnType<typeof dateRangeChangeAction>;
export const dateRangeChangeAction = (dates: string[]) => ({
  type: DATE_RANGE_CHANGE as typeof DATE_RANGE_CHANGE,
  dates,
});

export type ClientCountryCodeChangeAction = ReturnType<typeof clientCountryCodeChangeAction>;
export const clientCountryCodeChangeAction = (value: TCountryCode | null) => ({
  type: GUEST_COUNTRY_CHANGE as typeof GUEST_COUNTRY_CHANGE,
  value,
});

export type SelectedTaChangeAction = ReturnType<typeof selectedTaChangeAction>;
export const selectedTaChangeAction = (value: ITravelAgent | null) => ({
  type: SELECTED_TA_CHANGE as typeof SELECTED_TA_CHANGE,
  value,
});

export type ShowTaDropdownAction = ReturnType<typeof showTaDropdownAction>;
export const showTaDropdownAction = (value: boolean) => ({
  type: SHOW_TA_DROPDOWN as typeof SHOW_TA_DROPDOWN,
  value,
});

export type SearchTaByNameAction = ReturnType<typeof searchTaByNameAction>;
export const searchTaByNameAction = (value: string) => ({
  type: SEARCH_TA_BY_NAME as typeof SEARCH_TA_BY_NAME,
  value,
});

export type GetCompaniesRequestAction = ReturnType<typeof getCompaniesRequestAction>;
export const getCompaniesRequestAction = () => ({
  type: GET_COMPANIES_REQUEST as typeof GET_COMPANIES_REQUEST,
});

export type GetCompaniesSuccessAction = ReturnType<typeof getCompaniesSuccessAction>;
export const getCompaniesSuccessAction = (companies: ICompany[]) => ({
  type: GET_COMPANIES_SUCCESS as typeof GET_COMPANIES_SUCCESS,
  companies,
});

export type GetCompaniesFailureAction = ReturnType<typeof getCompaniesFailureAction>;
export const getCompaniesFailureAction = (error: string) => ({
  type: GET_COMPANIES_FAILURE as typeof GET_COMPANIES_FAILURE,
  error,
});

export type SelectedCompanyChangeAction = ReturnType<typeof selectedCompanyChangeAction>;
export const selectedCompanyChangeAction = (value: ICompany | null) => ({
  type: SELECTED_COMPANY_CHANGE as typeof SELECTED_COMPANY_CHANGE,
  value,
});

export type ClearSelectedTaAction = ReturnType<typeof clearSelectedTaAction>;
export const clearSelectedTaAction = () => ({
  type: CLEAR_SELECTED_TA as typeof CLEAR_SELECTED_TA,
});

export type ShowCompanyDropdownAction = ReturnType<typeof showCompanyDropdownAction>;
export const showCompanyDropdownAction = (value: boolean) => ({
  type: SHOW_COMPANY_DROPDOWN as typeof SHOW_COMPANY_DROPDOWN,
  value,
});

export type SearchCompanyByNameAction = ReturnType<typeof searchCompanyByNameAction>;
export const searchCompanyByNameAction = (value: string) => ({
  type: SEARCH_COMPANY_BY_NAME as typeof SEARCH_COMPANY_BY_NAME,
  value,
});

export type GetTravelAgentsByCompanyIdSuccessAction = ReturnType<typeof getTravelAgentsByCompanyIdSuccessAction>;
export const getTravelAgentsByCompanyIdSuccessAction = (agents: ITravelAgent[]) => ({
  type: GET_TRAVEL_AGENTS_BY_COMPANY_ID_SUCCESS as typeof GET_TRAVEL_AGENTS_BY_COMPANY_ID_SUCCESS,
  agents,
});

export type GetTravelAgentsByCompanyIdRequestAction = ReturnType<typeof getTravelAgentsByCompanyIdFailureAction>;
export const getTravelAgentsByCompanyIdFailureAction = (error: string) => ({
  type: GET_TRAVEL_AGENTS_BY_COMPANY_ID_FAILURE as typeof GET_TRAVEL_AGENTS_BY_COMPANY_ID_FAILURE,
  error,
});

export type SetTravelCompanyMembershipAction = ReturnType<typeof setTravelCompanyMembershipAction>;
export const setTravelCompanyMembershipAction = (tcm: ICompanyMembership | null) => ({ // OWA-5724
  type: SET_TRAVEL_COMPANY_MEMBERSHIP as typeof SET_TRAVEL_COMPANY_MEMBERSHIP,
  tcm,
});

export type Any =
  | InitializeQueryAction
  | PopulateQueryAction
  | ClearExtendedQueryAction
  | ClearSearchResultsAction
  | ExecuteAction
  | DestinationChangeAction
  | ToggleRepeatGuestAction
  | ToggleLodgingControlsAction
  | SetLodgingControlsVisibilityAction
  | IncrementRoomAction
  | IncrementAdultAction
  | IncrementChildAction
  | SetAgeAction
  | SetActiveLodgingIndexAction
  | IncrementActiveLodgingIndexAction
  | NamesSearchSuccessAction
  | NamesSearchFailureAction
  | SetNamesSearchResultsVisibilityAction
  | DateRangeChangeAction
  | ClientCountryCodeChangeAction
  | SelectedTaChangeAction
  | ShowTaDropdownAction
  | SearchTaByNameAction
  | GetCompaniesRequestAction
  | GetCompaniesSuccessAction
  | GetCompaniesFailureAction
  | SelectedCompanyChangeAction
  | ShowCompanyDropdownAction
  | SearchCompanyByNameAction
  | ClearSelectedTaAction
  | GetTravelAgentsByCompanyIdSuccessAction
  | GetTravelAgentsByCompanyIdRequestAction
  | SetTravelCompanyMembershipAction;
