import { createSelector } from 'reselect';
import { ILiveRatesDomain } from './model';
import { ELiveRatesSettingsListFilterType } from 'services/BackendApi/types/LiveRatesInternal';

export const domainSelector = (state: any): ILiveRatesDomain => state.liveRates;

// Page selectors
export const listingPageSelector = createSelector(domainSelector, (domain: ILiveRatesDomain) => domain.listingPage);

export const settingsPageSelector = createSelector(domainSelector, (domain: ILiveRatesDomain) => domain.settingsPage);

export const createModalSelector = createSelector(domainSelector, (domain: ILiveRatesDomain) => domain.createModal);

/**
 * Listing page selectors
 */
export const itemsSelector = createSelector(
  listingPageSelector,
  (domain: ILiveRatesDomain['listingPage']) => domain.items
);

export const filterSelector = createSelector(
  listingPageSelector,
  (domain: ILiveRatesDomain['listingPage']) => domain.filter
);

export const requestSelector = createSelector(domainSelector, (domain: ILiveRatesDomain) => domain.request);

export const sortBySelector = createSelector(
  listingPageSelector,
  (listingPage: ILiveRatesDomain['listingPage']) => listingPage.filter.order_by
);
export const sortOrderSelector = createSelector(
  listingPageSelector,
  (listingPage: ILiveRatesDomain['listingPage']) => listingPage.filter.order_type
);
export const toDeleteSelector = createSelector(
  listingPageSelector,
  (domain: ILiveRatesDomain['listingPage']) => domain.toDelete
);

/**
 * Settings page selectors
 */
export const internalRoomsFilterSelector = createSelector(
  settingsPageSelector,
  (settingsPage: ILiveRatesDomain['settingsPage']) => settingsPage.internalRoomsFilter
);

export const externalRoomsFilterSelector = createSelector(
  settingsPageSelector,
  (settingsPage: ILiveRatesDomain['settingsPage']) => settingsPage.externalRoomsFilter
);

export const internalRoomsListSelector = createSelector(
  settingsPageSelector,
  (settingsPage: ILiveRatesDomain['settingsPage']) => {
    const internalRooms = [...settingsPage.internalRooms].sort((a, b) => a.name.localeCompare(b.name));
    if (settingsPage.internalRoomsFilter === ELiveRatesSettingsListFilterType.MAPPED) {
      return internalRooms.filter(ir => ir.isMapped);
    } else if (settingsPage.internalRoomsFilter === ELiveRatesSettingsListFilterType.NOT_MAPPED) {
      return internalRooms.filter(ir => !ir.isMapped);
    } else {
      return internalRooms;
    }
  }
);
export const allInternalRoomsListSelector = createSelector(
  settingsPageSelector,
  (settingsPage: ILiveRatesDomain['settingsPage']) => settingsPage.internalRooms
);

export const originalInternalRoomsListSelector = createSelector(
  settingsPageSelector,
  (settingsPage: ILiveRatesDomain['settingsPage']) => settingsPage.originalInternalRooms
);

export const allExternalRoomsListSelector = createSelector(
  settingsPageSelector,
  (settingsPage: ILiveRatesDomain['settingsPage']) => settingsPage.externalRooms
);

export const externalRoomsListSelector = createSelector(
  settingsPageSelector,
  originalInternalRoomsListSelector,
  (settingsPage: ILiveRatesDomain['settingsPage'], originalInternalRooms) => {
    // compute, for each external room, whether it is mapped or not
    const externalRooms = [...settingsPage.externalRooms]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(er => {
        return {
          ...er,
          isMapped: originalInternalRooms.find(ir => ir.externalId === er.externalId) !== undefined,
        };
      });

    if (settingsPage.externalRoomsFilter === ELiveRatesSettingsListFilterType.MAPPED) {
      return externalRooms.filter(er => er.isMapped);
    } else if (settingsPage.externalRoomsFilter === ELiveRatesSettingsListFilterType.NOT_MAPPED) {
      return externalRooms.filter(er => !er.isMapped);
    } else {
      return externalRooms;
    }
  }
);

export const internalRoomsErrorsSelector = createSelector(
  settingsPageSelector,
  (settingsPage: ILiveRatesDomain['settingsPage']) => settingsPage.internalRoomErrors
);

export const internalRoomsUniqueErrorMessagesSelector = createSelector(
  settingsPageSelector,
  (settingsPage: ILiveRatesDomain['settingsPage']) => {
    return Array.from(new Set(settingsPage.internalRoomErrors.map(e => e.errorMessage)));
  }
);
 
export const stayInformationSelector = createSelector(
  settingsPageSelector,
  (settingsPage: ILiveRatesDomain['settingsPage']) => settingsPage.stayInformation
);

export const hasUnsavedChangesSelector = createSelector(
  settingsPageSelector,
  (settingsPage: ILiveRatesDomain['settingsPage']) => settingsPage.hasUnsavedChanges
);

export const showUnsavedChangesModalSelector = createSelector(
  settingsPageSelector,
  (settingsPage: ILiveRatesDomain['settingsPage']) => settingsPage.showUnsavedChangesModal
);

export const isAttemptingToChangeInternalRoomsFilterToSelector = createSelector(
  settingsPageSelector,
  (settingsPage: ILiveRatesDomain['settingsPage']) => settingsPage.isAttemptingToChangeInternalRoomsFilterTo
);

export const mappableStaysSelector = createSelector(
  createModalSelector,
  (domain: ILiveRatesDomain['createModal']) => domain.mappableStays
);

export const unmappedHotelsSelector = createSelector(
  createModalSelector,
  (domain: ILiveRatesDomain['createModal']) => domain.unmappedHotels
);

export const requestMappableStaysGetSelector = createSelector(
  domainSelector,
  (domain: ILiveRatesDomain) => domain.request.mappableStaysGet
);

export const requestMappableStaysPatchSelector = createSelector(
  domainSelector,
  (domain: ILiveRatesDomain) => domain.request.mappableStaysPatch
);

export const isNewMappingModalOpenSelector = createSelector(
  domainSelector,
  (domain: ILiveRatesDomain) => domain.createModal.isOpen
);
