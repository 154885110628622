import React from 'react';
import classNames from 'classnames';
import { interpose } from 'utils/array';

export interface IContactsProps {
  className?: string;
  emails: string[];
}

export const Contacts: React.FC<IContactsProps> = ({ className, emails }) => {
  return (
    <div className={classNames(className, 'contacts')}>
      {!emails.length && 'N/A'}
      {interpose(
        emails.map(x => <span key={x} className="inline-block">{x.split('@')[0]}</span>),
        <span className="text-brown-100"> | </span>
      )}
    </div>
  )
};
