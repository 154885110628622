import { useCallback, useRef, useEffect, useState, RefObject } from 'react';

export function useHover(ref: RefObject<HTMLElement>) {
  const [value, setValue] = useState(false);

  const handleMouseOver = useCallback(() => setValue(true), []);
  const handleMouseOut = useCallback(() => setValue(false), []);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    },

    [handleMouseOver, handleMouseOut, ref] // Recall only if ref changes
  );

  return value;
}
