import React, { useEffect, useState } from 'react';
import { formatPrice, rateStringToCents } from 'utils';

interface IPriceAsCentsInputProps {
  cents: number | undefined;
  onBlurUpdate: (cents) => void;
  isAllowUndefined?: boolean;
  allowNegative?: boolean;
  disabled: boolean;
  className?: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PriceAsCentsInput = (props: IPriceAsCentsInputProps) => {
  const { cents, onBlurUpdate, className = '', placeholder = '', isAllowUndefined, allowNegative } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [localVal, setLocalVal] = useState(cents === undefined ? '' : formatPrice(cents / 100));

  // if the cents prop changes, change the local val
  useEffect(() => {
    setLocalVal(cents === undefined ? '' : formatPrice(cents / 100));
  }, [cents]);

  const disabled = props.disabled === false ? false : true;
  const renderValue = isFocused ? localVal : cents === undefined ? '' : formatPrice(cents / 100);

  return (
    <input
      disabled={disabled}
      className={className}
      placeholder={placeholder}
      type="text"
      onClick={() => {
        setLocalVal(cents === undefined ? '' : formatPrice(cents / 100));
      }}
      value={renderValue}
      onChange={event => {
        if (props.onChange) {
          props.onChange(event);
        }
        // if the new value doesn't contain only commas, dots, or numbers, disregard it
        // 26/01/2022 or hyphens, for negative numbers
        const validCurrencyChecker = /^\-?[0-9,.]*$/;
        if (!validCurrencyChecker.test(event.target.value)) {
          return;
        }
        setLocalVal(event.target.value);
      }}
      onKeyPress={event => {
        if (event.key === 'Enter') {
          const activeElement = document.activeElement as HTMLElement;
          activeElement.blur();
        }
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => {
        setIsFocused(false);
        // thanks to the regex, the local value can sometimes be a single hyphen
        if (localVal === '' || localVal === '-') {
          if (isAllowUndefined) {
            onBlurUpdate(undefined);
          } else {
            onBlurUpdate(0);
          }
        } else {
          const cents = rateStringToCents(localVal);
          onBlurUpdate(allowNegative ? cents : Math.abs(cents));
        }

        if (isAllowUndefined && (localVal === '' || localVal === '-')) {
          setLocalVal('');
        } else {
          setLocalVal(formatPrice(localVal));
        }
      }}
    />
  );
};
