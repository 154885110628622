// import { ENetworkRequestStatus } from 'services/BackendApi';
import { ISelectedTransfer } from './types';

export interface ITransfersSubdomain {
  selectedTransfers: ISelectedTransfer[];
  networkRequests: {};
}

export const transfersInitialState: ITransfersSubdomain = {
  selectedTransfers: [],
  networkRequests: {},
};
