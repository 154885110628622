import React, { useCallback, HTMLProps } from 'react';
import styled from 'styled-components';

export interface StepperProps extends HTMLProps<HTMLDivElement> {
  value: number;
  incrementButtonClassname?: string;
  decrementButtonClassname?: string;
  min?: number;
  max?: number;
  onIncrement: (step: number) => void;
}

const Stepper = (props: StepperProps) => {
  const { incrementButtonClassname = 'stepper-increment', decrementButtonClassname = 'stepper-decrement' } = props;
  const handleClick = useCallback(
    (step: number) => () => {
      props.onIncrement(step);
    },
    [props.onIncrement]
  );
  return (
    <div className={props.className}>
      <button
        onClick={handleClick(-1)}
        disabled={props.value === props.min}
        className={`${decrementButtonClassname} cursor-pointer outline-none bg-transparent text-brown-100 hover:text-teal-100 active:animate-ping`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path
            fillRule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm3 10.5a.75.75 0 000-1.5H9a.75.75 0 000 1.5h6z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {props.value}
      <button
        onClick={handleClick(1)}
        disabled={props.value === props.max}
        className={`${incrementButtonClassname} cursor-pointer outline-none bg-transparent text-brown-100 hover:text-teal-100 active:animate-ping`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path
            fillRule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};

Stepper.defaultProps = {
  value: 1,
  min: 1,
  max: 99,
};

export default styled(Stepper)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 75px;

  button {
    flex-grow: 0;
  }
`;
