import { call, takeLatest, put, select, delay } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  makeBackendApi,
  IOneTravelAgentResponse,
  ITravelAgentResponse,
  IEmailExistsResponse,
  ETravelAgentStatus,
} from 'services/BackendApi';

import {
  GET_REQUEST,
  getTravelAgentSuccessAction,
  getTravelAgentFailureAction,
  CreateTravelAgentRequestAction,
  createTravelAgentSuccessAction,
  createTravelAgentFailureAction,
  CREATE_REQUEST,
  UPDATE_REQUEST,
  updateTravelAgentSuccessAction,
  updateTravelAgentFailureAction,
  UpdateTravelAgentRequestAction,
  GetTravelAgentRequestAction,
  SetSrAssignmentsRequestAction,
  SET_SR_ASSIGNMENTS_REQUEST,
  setSrAssignmentsSuccessAction,
  setSrAssignmentsFailureAction,
  setSrAssignmentsRequestAction,
  SET_COMPANY_FILTER_NAME,
  SET_COMPANY_FILTER_COUNTRY,
  setFilteredCompaniesAction,
} from './actions';
import { GET_COMPANIES_SUCCESS } from '../agents/actions';
import { isAdmin as isAdminSelector } from 'store/modules/auth';

import { enqueueNotification } from '../ui';
import { IEnabledNotification } from 'services/BackendApi/types/Notification';
import { companiesSelector } from '../agents';
import { companyFilterCountrySelector, companyFilterNameSelector } from './selectors';

function* successNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'success' } }));
}

function* errorNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'error' } }));
}

export function* getRequestSaga(action: GetTravelAgentRequestAction) {
  const backendApi = makeBackendApi();

  try {
    const travelAgentResponse: AxiosResponse<IOneTravelAgentResponse> = yield call(
      backendApi.getTravelAgentViaUuid,
      action.travelAgentUuid,
      ['assignedSalesRepresentatives', 'createdBy'],
      action.userType
    );

    yield put(getTravelAgentSuccessAction(travelAgentResponse.data.data));
  } catch (e) {
    yield put(getTravelAgentFailureAction(e.message));
  }
}

export function* createRequestSaga(action: CreateTravelAgentRequestAction) {
  const backendApi = makeBackendApi();
  const notifications: IEnabledNotification[] = action.notifications.map(notification => {
    return {
      code: notification.code,
      enabled: notification.enabled ?? false,
      ccToDepartmentsUuids: notification.ccToDepartmentsUuids || [],
    };
  });

  try {
    const emailValidationResponse: AxiosResponse<IEmailExistsResponse> = yield call(
      backendApi.doesEmailExist,
      action.travelAgent.email ?? ''
    );
    if (emailValidationResponse.data.valid === false) {
      throw new Error('This email has already been registered');
    }
    const isAdmin = yield select(isAdminSelector);
    const createUserEndpoint = isAdmin ? backendApi.createUser : backendApi.createUserRequest;
    const travelAgentResponse: AxiosResponse<IOneTravelAgentResponse> = yield call(
      createUserEndpoint,
      action.travelAgent,
      notifications
    );
    const createdTravelAgent = travelAgentResponse.data.data;

    yield put(createTravelAgentSuccessAction(createdTravelAgent));
    if (isAdmin) {
      yield successNotification('Travel Agent created.');
      yield put(setSrAssignmentsRequestAction(createdTravelAgent.uuid, action.srUuids));
    } else {
      yield successNotification('Travel agent requested successfully. The TA will receive an email when approved.');
    }

    yield call(action.history.push, '/travel-agents');
  } catch (e) {
    yield put(createTravelAgentFailureAction(e.message));
    yield errorNotification(e.message ?? 'Failed to create Travel Agent.');
  }
}

export function* updateRequestSaga(action: UpdateTravelAgentRequestAction) {
  const backendApi = makeBackendApi();
  const notifications: IEnabledNotification[] = action.notifications.map(notification => {
    return {
      code: notification.code,
      enabled: notification.enabled ?? false,
      ccToDepartmentsUuids: notification.ccToDepartmentsUuids || [],
    };
  });

  try {
    const travelAgentResponse: AxiosResponse<IOneTravelAgentResponse> = yield call(
      backendApi.updateUser,
      action.travelAgentUuid,
      action.travelAgent,
      notifications,
      action.userType
    );
    const updatedTravelAgent = travelAgentResponse.data.data;

    yield put(updateTravelAgentSuccessAction(updatedTravelAgent));
    if (
      action.travelAgent.status === ETravelAgentStatus.REJECTED &&
      action.oldValues.status !== ETravelAgentStatus.REJECTED &&
      action.oldValues.createdBy === undefined
    ) {
      yield successNotification('Travel Agent updated & rejection email sent.');
    } else {
      yield successNotification('Travel Agent updated.');
    }
  } catch (e) {
    yield put(updateTravelAgentFailureAction(e.message));
    yield errorNotification('Failed to update Travel Agent.');
  }
}

export function* setSrAssignmentsSaga(action: SetSrAssignmentsRequestAction) {
  const backendApi = makeBackendApi();

  try {
    yield call(backendApi.updateUserAssignments, action.travelAgentUuid, action.srUuids);

    yield put(setSrAssignmentsSuccessAction());
  } catch (e) {
    yield put(setSrAssignmentsFailureAction(e.message));
    yield errorNotification('Failed to update Travel Agent Assignments.');
  }
}

export function* filterTACompaniesSaga() {
  yield delay(500);

  const companies = yield select(companiesSelector) ?? [];
  const countryCodeFilter = yield select(companyFilterCountrySelector);
  const companyNameFilter = yield select(companyFilterNameSelector);

  const filteredCompanies = companies.filter(company => {
    const companyNameMatches = company.name.toLowerCase().includes(companyNameFilter.toLowerCase());
    const countryMatches = countryCodeFilter ? company.countryCode === countryCodeFilter : true;
    return companyNameMatches && countryMatches;
  });

  yield put(setFilteredCompaniesAction(filteredCompanies));
}

export function* watchTravelAgentSaga() {
  yield takeLatest(GET_REQUEST, getRequestSaga);

  yield takeLatest(CREATE_REQUEST, createRequestSaga);

  yield takeLatest(UPDATE_REQUEST, updateRequestSaga);

  yield takeLatest(SET_SR_ASSIGNMENTS_REQUEST, setSrAssignmentsSaga);

  yield takeLatest([GET_COMPANIES_SUCCESS, SET_COMPANY_FILTER_COUNTRY, SET_COMPANY_FILTER_NAME], filterTACompaniesSaga);
}
