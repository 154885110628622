import React, { FC, useMemo, useCallback, useState, CSSProperties } from 'react';

import TextInput from 'pureUi/TextInput';

interface IMaskedInputProps {
  initialValue: string;
  value: string;
  onChange: (string) => void;
  mask: string;
  disabled?: boolean;
  inputClassName?: string;
  style?: CSSProperties;
  allowZero?: boolean;
  allowNegative?: boolean;
}

export const NumericMaskedInput: FC<IMaskedInputProps> = React.memo(
  ({ initialValue, value, mask, onChange, disabled, inputClassName, style, allowZero = false, allowNegative = false }) => {
    const [maskedValue, setMaskedValue] = useState<string>(initialValue);
    let classes = 'text-black bg-ivory border border-solid border-gray-40';
    if (inputClassName) {
      classes += ` ${inputClassName}`;
    }
    const separator = useMemo(() => [...mask].find(c => c !== '#') ?? '.', []);

    const handleOnBlur = useCallback(
      e => {
        const isNegative = maskedValue.startsWith('-');
        const absValue = isNegative
          ? maskedValue.slice(1)
          : maskedValue;

        if (maskedValue.length === 0 || maskedValue === '-' ||  e.target.value.length === 0) {
          setMaskedValue(initialValue);
          onChange(initialValue);
          return;
        }

        if (absValue.startsWith('100')) {
          onChange(maskedValue);
          return;
        }

        let result = '';
        const expectedDecimals = mask.length - mask.indexOf(separator) - 1;

        if (absValue.includes(separator)) {
          const separatorIndex = absValue.indexOf(separator);
          const hasExpectedDecimals = absValue.length - separatorIndex - 1 === expectedDecimals;

          if (hasExpectedDecimals) {
            if (separatorIndex === 0) {
              result = '0';
            }
            result += absValue;
          } else {
            const intPart: number = isNaN(Number(absValue)) ? 0 : Number(absValue);
            result = `${Math.floor(intPart)}${separator}${absValue
              .slice(separatorIndex + 1)
              .padEnd(expectedDecimals, '0')}`;
          }
        } else {
          result = `${absValue}${separator}${''.padEnd(expectedDecimals, '0')}`;
        }

        if(isNegative) {
          result =`-${result}`;
        }

        if (isNaN(Number(result)) || result.length > mask.length + 1) {
          setMaskedValue(initialValue);
        } else {
          setMaskedValue(result);
        }

        onChange(result);
      },
      [maskedValue, mask, separator, onChange, initialValue]
    );

    const handleOnChange = useCallback(
      e => {
        const isNegative = (e.target.value || '').startsWith('-');
        const targetValue = isNegative
          ? e.target.value.slice(1)
          : e.target.value;

        const characterTyped = e.target.value.slice(-1);
        const targetLength = targetValue.length;

        if (allowNegative && e.target.value === '-') {
          setMaskedValue(e.target.value);
          return;
        }
        
        if (targetLength > mask.length) {
          return;
        }
        
        if (targetValue.includes(separator)) {
          const separatorIndex = targetValue.indexOf(separator);
          if (targetValue.slice(separatorIndex + 1, targetValue.length).length > 2) {
            return;
          }
        }
        
        if (characterTyped && mask.includes(characterTyped)) {
          if (characterTyped !== '#') {
            setMaskedValue(e.target.value);
          }

          return;
        }

        if (value.length > 0 && isNaN(characterTyped)) {
          return;
        }

        if(!allowZero && targetValue === 0) {
          return;
        }

        if(!allowNegative && isNegative) {
          return;
        }

        if (targetValue <= 100) {
          setMaskedValue(e.target.value);
        }
      },
      [mask, separator, value.length, allowZero]
    );

    return (
      <TextInput
        inputClassName={classes}
        style={{ height: '35px', fontFamily: 'PT Sans', fontSize: '15px', ...(style ?? {}) }}
        value={maskedValue}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        disabled={disabled ?? false}
      />
    );
  }
);
