import React from 'react';
import { EImportEntity } from '../../store/modules/importer/model';
import { ancillaryTransfersImportDomainSelector } from '../../store/modules/ancillaryTransfersImport/selectors';
import { ancillaryTransfersRatesImportDomainSelector } from '../../store/modules/ancillaryTransfersRatesImport/selectors';
import { useSelector } from 'react-redux';
import { AncillaryImporterTemplateContainer } from '../AncillaryImporterTemplating/importer';
import { SimpleTabs } from 'ui/SimpleTabs';

export const AncillaryTransfersImporterContainer = () => {
  const transfersImportData = useSelector(ancillaryTransfersImportDomainSelector);
  const transfersRatesImportData = useSelector(ancillaryTransfersRatesImportDomainSelector);

  return (
    <>
      <div className="container w-full max-w-1280px mx-auto space-y-20px">
        <h1 className="font-normal font-noe-display text-[36px] leading-46px">
          Products - <span className="text-[26px]">Transfers</span>
        </h1>
      </div>
      <SimpleTabs
        tabConfig={[
          {
            title: 'Transfers',
            name: 'transfers',
            styles: 'min-w-150px',
            renderContent: () => (
              <AncillaryImporterTemplateContainer
                importerEntity={EImportEntity.ANCILLARY_TRANSFERS}
                importerData={transfersImportData}
                importerDomainName="Transfers"
                importerPageTitle={<h2 className="font-bold font-pt-sans text-[17px] leading-22px">Transfers</h2>}
              />
            ),
          },
          {
            title: 'Rates',
            name: 'transfers-rates',
            styles: 'min-w-150px',
            renderContent: () => (
              <AncillaryImporterTemplateContainer
                importerEntity={EImportEntity.ANCILLARY_TRANSFERS_RATES}
                importerData={transfersRatesImportData}
                importerDomainName="Transfers Rates"
                importerPageTitle={<h2 className="font-bold font-pt-sans text-[17px] leading-22px">Rates</h2>}
              />
            ),
          },
        ]}
      />
    </>
  );
};
