import { IOwnershipTravelAgentMovements, IOwnershipData, IOwnershipPatchBody } from 'services/BookingManagerApi';
import { ISalesRepresentative } from 'services/BackendApi';

export const GET_OWNERSHIP_REQUEST = 'bookingManager/GET_OWNERSHIP_REQUEST';
export const GET_OWNERSHIP_SUCCESS = 'bookingManager/GET_OWNERSHIP_SUCCESS';
export const GET_OWNERSHIP_FAILURE = 'bookingManager/GET_OWNERSHIP_FAILURE';

export const UPDATE_OWNERSHIP_REQUEST = 'bookingManager/UPDATE_OWNERSHIP_REQUEST';
export const UPDATE_OWNERSHIP_SUCCESS = 'bookingManager/UPDATE_OWNERSHIP_SUCCESS';
export const UPDATE_OWNERSHIP_FAILURE = 'bookingManager/UPDATE_OWNERSHIP_FAILURE';

export const GET_ASSIGNED_SALES_REPRESENTATIVES_REQUEST = 'bookingManager/GET_ASSIGNED_SALES_REPRESENTATIVES_REQUEST';
export const GET_ASSIGNED_SALES_REPRESENTATIVES_SUCCESS = 'bookingManager/GET_ASSIGNED_SALES_REPRESENTATIVES_SUCCESS';
export const GET_ASSIGNED_SALES_REPRESENTATIVES_FAILURE = 'bookingManager/GET_ASSIGNED_SALES_REPRESENTATIVES_FAILURE';

export const GET_TP_MOVEMENTS_REQUEST = 'bookingManager/GET_TP_MOVEMENTS_REQUEST';
export const GET_TP_MOVEMENTS_SUCCESS = 'bookingManager/GET_TP_MOVEMENTS_SUCCESS';
export const GET_TP_MOVEMENTS_FAILURE = 'bookingManager/GET_TP_MOVEMENTS_FAILURE';

export type GetOwnershipRequestAction = ReturnType<typeof getOwnershipRequestAction>;
export const getOwnershipRequestAction = () => ({
  type: GET_OWNERSHIP_REQUEST as typeof GET_OWNERSHIP_REQUEST,
});

export type GetOwnershipSuccessAction = ReturnType<typeof getOwnershipSuccessAction>;
export const getOwnershipSuccessAction = (ownershipData: IOwnershipData) => ({
  type: GET_OWNERSHIP_SUCCESS as typeof GET_OWNERSHIP_SUCCESS,
  ownershipData,
});

export type GetOwnershipFailureAction = ReturnType<typeof getOwnershipFailureAction>;
export const getOwnershipFailureAction = (error: string) => ({
  type: GET_OWNERSHIP_FAILURE as typeof GET_OWNERSHIP_FAILURE,
  error,
});

export type UpdateOwnershipRequestAction = ReturnType<typeof updateOwnershipRequestAction>;
export const updateOwnershipRequestAction = () => ({
  type: UPDATE_OWNERSHIP_REQUEST as typeof UPDATE_OWNERSHIP_REQUEST,
});

export type UpdateOwnershipSuccessAction = ReturnType<typeof updateOwnershipSuccessAction>;
export const updateOwnershipSuccessAction = (ownershipData: IOwnershipPatchBody) => ({
  type: UPDATE_OWNERSHIP_SUCCESS as typeof UPDATE_OWNERSHIP_SUCCESS,
  ownershipData,
});

export type UpdateOwnershipFailureAction = ReturnType<typeof updateOwnershipFailureAction>;
export const updateOwnershipFailureAction = (error: string) => ({
  type: UPDATE_OWNERSHIP_FAILURE as typeof UPDATE_OWNERSHIP_FAILURE,
  error,
});

export type GetAssignedSalesRepresentativesRequestAction = ReturnType<
  typeof getAssignedSalesRepresentativesRequestAction
>;
export const getAssignedSalesRepresentativesRequestAction = () => ({
  type: GET_ASSIGNED_SALES_REPRESENTATIVES_REQUEST as typeof GET_ASSIGNED_SALES_REPRESENTATIVES_REQUEST,
});

export type GetAssignedSalesRepresentativesSuccessAction = ReturnType<
  typeof getAssignedSalesRepresentativesSuccessAction
>;
export const getAssignedSalesRepresentativesSuccessAction = (salesRepresentatives: ISalesRepresentative[]) => ({
  type: GET_ASSIGNED_SALES_REPRESENTATIVES_SUCCESS as typeof GET_ASSIGNED_SALES_REPRESENTATIVES_SUCCESS,
  salesRepresentatives,
});

export type GetAssignedSalesRepresentativesFailureAction = ReturnType<
  typeof getAssignedSalesRepresentativesFailureAction
>;
export const getAssignedSalesRepresentativesFailureAction = (error: string) => ({
  type: GET_ASSIGNED_SALES_REPRESENTATIVES_FAILURE as typeof GET_ASSIGNED_SALES_REPRESENTATIVES_FAILURE,
  error,
});

export type GetTravelPartnerMovementsRequestAction = ReturnType<
  typeof getTravelPartnerMovementsRequestAction
>;

export const getTravelPartnerMovementsRequestAction = () => ({
  type: GET_TP_MOVEMENTS_REQUEST as typeof GET_TP_MOVEMENTS_REQUEST,
});

export type GetTravelPartnerMovementsSuccessAction = ReturnType<
  typeof getTravelPartnerMovementsSuccessAction
>;

export const getTravelPartnerMovementsSuccessAction = (movements: IOwnershipTravelAgentMovements[]) => ({
  type: GET_TP_MOVEMENTS_SUCCESS as typeof GET_TP_MOVEMENTS_SUCCESS,
  movements,
});

export type GetTravelPartnerMovementsFailureAction = ReturnType<
  typeof getTravelPartnerMovementsFailureAction
>;

export const getTravelPartnerMovementsFailureAction = (error: string) => ({
  type: GET_TP_MOVEMENTS_FAILURE as typeof GET_TP_MOVEMENTS_FAILURE,
  error,
});

export type OwnershipAction =
  | GetOwnershipRequestAction
  | GetOwnershipSuccessAction
  | GetOwnershipFailureAction
  | UpdateOwnershipRequestAction
  | UpdateOwnershipSuccessAction
  | UpdateOwnershipFailureAction
  | GetAssignedSalesRepresentativesRequestAction
  | GetAssignedSalesRepresentativesSuccessAction
  | GetAssignedSalesRepresentativesFailureAction
  | GetTravelPartnerMovementsRequestAction
  | GetTravelPartnerMovementsSuccessAction
  | GetTravelPartnerMovementsFailureAction;
