import React, { useRef } from 'react';
import classNames from 'classnames';
import { useInitialDimensions } from 'hooks/useInitialDimensions';

export interface IFluidButton {
  type: 'primary' | 'secondary';
  slim?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
  textClassName?: string;
  fixedWidth?: string;
  isLoading?: boolean;
  submit?: boolean;
  disabled?: boolean;
  // props added to support legacy buttons OWA-3944
  rounded?: boolean;
  border?: boolean;
  heightLimited?: boolean;
  children?: React.ReactNode;
}

export const FluidButton: React.FC<IFluidButton> = ({
  type,
  className,
  textClassName,
  children,
  onClick,
  onMouseEnter = undefined,
  onMouseLeave = undefined,
  submit = false,
  slim = false,
  fixedWidth = undefined,
  isLoading = false,
  disabled = false,
  rounded = true,
  border = true,
  heightLimited = true,
}) => {
  const defaultButtonClassName = classNames(
    'flex justify-center items-center select-none default:font-pt-sans text-sm default:font-bold leading-xs tracking-wider outline-none',
    {
      rounded,
      border,
      'border-none': !border,
      'border-solid': border,
    },
    className
  );

  const heightClassName = heightLimited
    ? classNames({
        slim,
        'min-h-32px': slim,
        'max-h-32px': slim,
        'full-height': !slim,
        'min-h-35px': !slim,
        'max-h-35px': !slim,
      })
    : '';

  const buttonRef = useRef<HTMLButtonElement>(null);
  const initialButtonDimensions = useInitialDimensions(buttonRef);
  const width = fixedWidth ?? `${initialButtonDimensions?.width}px`;

  const primaryButtonClassName = classNames(defaultButtonClassName, heightClassName, {
    'border-brown-100': border,
    'hover:bg-brown-120': border,
  });

  const secondaryButtonClassName = classNames(defaultButtonClassName, heightClassName, {
    'bg-white-true': true,
    'default:text-brown-100': true,
    'default:border-brown-100': border,
  });

  const buttonClassName = type === 'primary' ? primaryButtonClassName : secondaryButtonClassName;

  const buttonType = submit === true ? 'submit' : 'button';

  return (
    <button
      type={buttonType}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={buttonRef}
      disabled={disabled || isLoading}
      className={classNames(buttonClassName, {
        'bg-brown-100': !isLoading && type === 'primary',
        'bg-brown-120': isLoading && type === 'primary',
        'pl-3 pr-3': fixedWidth === undefined,
        'cursor-pointer': !disabled && !isLoading,
        'hover:bg-brown-40 bg-brown-40 text-white-true border-gray-20': disabled && type === 'primary',
        'text-ivory': !disabled && type === 'primary',
        'hover:bg-brown-20': !disabled && !isLoading && type === 'secondary',
        'border-gray-60 text-brown-40': disabled && type === 'secondary',
      })}
      style={{ width: width }}
    >
      {isLoading ? (
        <span>
          <i className="text-xl fas fa-circle-notch fa-spin"></i>
        </span>
      ) : (
        <span className={classNames(textClassName)}>{children}</span>
      )}
    </button>
  );
};

FluidButton.displayName = 'FluidButton';

export default FluidButton;
