import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

import {
  IStatementPdfDownloadHistoryResponse,
  ITCBookingStatementTotalsResponse,
  makeBookingManagerApi,
} from 'services/BookingManagerApi';
import {
  ITCBookingStatementPageRequest,
} from 'services/BookingManagerApi/types';
import { enqueueNotification } from 'store/modules/ui';

import {
  ITCBookingStatementPageResponse,
  IPostExportResponse,
} from 'services/BookingManagerApi/types';
import * as Actions from './actions';
import * as Selectors from './selectors';
import * as ExportCsvPollingSaga from 'store/modules/exportCsv/saga';
import { formatDateTimeIncludeTimezoneDisplay } from 'utils';

function* getTcBookingStatementParam() {
  const currency = yield select(Selectors.tcBookingStatementCurrencySelector);
  const companyUuid = yield select(Selectors.tcBookingStatementCompanySelector);
  const bookingLedgerSort = yield select(Selectors.tcBookingStatementSortSelector);
  const currentPage = yield select(Selectors.tcBookingStatementPageSelector);
  const itemsPerPage = yield select(Selectors.tcBookingStatementItemsPerPageSelector);
  const filter = yield select(Selectors.tcBookingStatementFilterSelector);

  const requestData: ITCBookingStatementPageRequest = {
    filter,
    pagination: { limit: itemsPerPage, offset: currentPage * itemsPerPage },
    sort: bookingLedgerSort,
  };

  return {
    companyUuid,
    currency,
    requestData
  };
}

// eslint-disable-next-line no-unused-vars
export function* getTCBookingStatementByCurrencySaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const { companyUuid, currency, requestData } = yield call(getTcBookingStatementParam);

    const result: AxiosResponse<ITCBookingStatementPageResponse> = yield call(
      bookingManagerApi.tcBookingStatementByCurrency,
      companyUuid,
      currency,
      requestData
    );
    // Commit changes
    yield put(Actions.tcBookingStatementSuccessAction(result.data));

  } catch (e) {
    yield put(Actions.tcBookingStatementFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'Failed to load TC Booking Statement.',
        options: { variant: 'error' },
      })
    );
  }
}

// eslint-disable-next-line no-unused-vars
export function* getBookingStatementTotalsSaga(action: Actions.TCBookingStatementRequestTotalsAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();

    const result: AxiosResponse<ITCBookingStatementTotalsResponse> = yield call(
      bookingManagerApi.tcBookingStatementTotals,
      action.company,
    );
    // Commit changes
    yield put(Actions.tcBookingStatementSuccessTotalsAction(result.data));

  } catch (e) {
    yield put(Actions.tcBookingStatementFailureTotalsAction(e));
    yield put(
      enqueueNotification({
        message: 'Failed to load TC Booking Statement Headlines.',
        options: { variant: 'error' },
      })
    );
  }
}

function* downloadTCBookingStatementPdf() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const { companyUuid, currency, requestData } = yield call(getTcBookingStatementParam);

    const result: AxiosResponse<any> = yield call(
      bookingManagerApi.tcBookingStatementDownloadPdf,
      companyUuid,
      currency,
      requestData
    );
    saveAs(result.data.url, result.data.filename);

    yield put(Actions.tcBookingStatementDownloadPdfSuccessAction(result.data));
  } catch (e) {
    yield put(Actions.tcBookingStatementDownloadPdfFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'Failed to load booking ledger.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* postTriggerExport() {
  const bookingManagerApi = makeBookingManagerApi();
  const currency = yield select(Selectors.tcBookingStatementCurrencySelector);
  const companyUuid = yield select(Selectors.tcBookingStatementCompanySelector);
  const query: ITCBookingStatementPageRequest = yield select(Selectors.tableDataQuerySelector);

  try{

    const res: AxiosResponse<IPostExportResponse> = yield call(
      bookingManagerApi.postTCBookingStatementExportCsv,
      companyUuid,
      currency,
      query.filter,
    );

    yield put(Actions.triggerExportCsvSuccessAction(res.data.exportUuid));

  } catch (e) {
    yield put(Actions.triggerExportCsvFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Failed to export CSV for TC Booking Statement.',
        options: { variant: 'error' },
      })
    );
  }

}

export function* getPollExportCsv() {
  const bookingManagerApi = makeBookingManagerApi();
  const exportCsvNamespace = yield select(Selectors.exportCsvNamespaceSelector);

  yield ExportCsvPollingSaga.getPollExportCsv(
    bookingManagerApi.getTCBookingStatementExportCsv,
    `Global Statement Export - ${formatDateTimeIncludeTimezoneDisplay(new Date())}`,
    exportCsvNamespace.exportUuid,
    Actions.pollExportCsvSuccessAction,
    Actions.pollExportCsvFailureAction,
    Actions.pollExportCsvContinueAction
  );
}

export function* downloadPdfHistoryForTcBookingStatementSaga(action: Actions.TCBookingStatementDownloadPdfHistoryRequestAction) {
  const bookingManagerApi = makeBookingManagerApi();

  try {
    const res: AxiosResponse<IStatementPdfDownloadHistoryResponse> = yield call(
      bookingManagerApi.downloadHistoryForTcBookingStatement,
      action.companyUuid,
      action.currency
    );

    yield put(Actions.tcBookingStatementDownloadPdfHistorySuccessAction(res.data.rows));
  } catch (e) {
    yield put(Actions.tcBookingStatementDownloadPdfHistoryFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Failed to download PDF history',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchTCBookingStatementSaga() {

  yield takeLatest([Actions.TC_BOOKING_STATEMENT_TOTALS_REQUEST], getBookingStatementTotalsSaga);

  yield takeLatest(
    [
      Actions.TC_BOOKING_STATEMENT_REQUEST,
      Actions.TC_STATEMENT_FILTER_UPDATE_RESULTS,
      Actions.TC_BOOKING_STATEMENT_SET_PAGE_NUMBER,
      Actions.TC_BOOKING_STATEMENT_SET_ITEMS_PER_PAGE,
      Actions.TC_BOOKING_STATEMENT_SET_SORT,
    ], 
    getTCBookingStatementByCurrencySaga
  );

  yield takeLatest([Actions.TRIGGER_EXPORT_CSV_REQUEST], postTriggerExport);
  yield takeLatest([Actions.POLL_EXPORT_CSV_REQUEST], getPollExportCsv);
  yield takeLatest([Actions.TC_BOOKING_STATEMENT_DOWNLOAD_PDF], downloadTCBookingStatementPdf)
  yield takeLatest(Actions.TC_BOOKING_STATEMENT_DOWNLOAD_PDF_HISTORY_REQUEST, downloadPdfHistoryForTcBookingStatementSaga);
}
