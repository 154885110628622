export default {
  placeholders: {
    search: 'Where to',
    dates: 'Select dates',
    overrideTotal: 'Override Total',
    nameSearchClear: 'All countries and resorts',
  },
  titles: {
    addToProposal: 'Add to proposal',
    signIn: 'Sign In',
    resetYourPassword: 'Reset Your Password',
    resetYourPasswordComplete: 'Help is on the way - check your inbox!',
    setPassword: 'Set your password',
    setPasswordComplete: 'Password has been reset',
    updateYourPassword: 'Update your password',
  },
  errors: {
    required: 'This field is required',
    min: 'Value is too short',
    max: 'Value is too long',
    email: 'Email address is invalid',
    acceptTerms: 'Must Accept Terms and Conditions',
    phone: 'Must be a valid phone number',
    passwordsMatch: 'Passwords do not match',
    passwordStrength: 'Must be at least 8 characters, contain a number, upper and lowercase letter',
    minOneRoom: 'Must have at least one room.',
    minGuests: 'Must have minimum ${min}',
    maxGuests: 'Cannot exceed ${max}',
    bookingValid: 'Cannot proceed, please check your selections',
    decimalPlaces: 'Cannot be more than 2 decimal places',
  },
};
