import { takeLatest } from 'redux-saga/effects';
import { VISIBILITY_CHANGE, VisibilityChange } from '../actions';
import { clearUser } from '../../auth/saga';
import { getBearerTokenFromLocalStorage } from 'services/tokenLocalStorage';

export function* visibilityChangeSaga({ visible }: VisibilityChange) {
  if(!visible){
    return;
  }

  const token = getBearerTokenFromLocalStorage();
  
  if (!token.value || token.expired) {
    yield clearUser();
  }

}

export function* watchVisibilityChange() {
  yield takeLatest(VISIBILITY_CHANGE, visibilityChangeSaga);
}
