import { IInventoryCurrency } from "services/InventoryAPI";

export const BOOTSTRAP_APP_REQUEST = 'inventoryBootstrap/BOOTSTRAP_APP_REQUEST';
export const BOOTSTRAP_APP_SUCCESS = 'inventoryBootstrap/BOOTSTRAP_APP_SUCCESS';
export const BOOTSTRAP_APP_FAILURE = 'inventoryBootstrap/BOOTSTRAP_APP_FAILURE';

export type InventoryBootstrapRequestAction = ReturnType<typeof inventoryBootstrapRequestAction>;
export const inventoryBootstrapRequestAction = () => ({
  type: BOOTSTRAP_APP_REQUEST as typeof BOOTSTRAP_APP_REQUEST,
});

export type InventoryBootstrapSuccessAction = ReturnType<typeof inventoryBootstrapSuccessAction>;
export const inventoryBootstrapSuccessAction = (
  countries: any[], allCountries: any[], currencies: IInventoryCurrency[]
) => ({
  type: BOOTSTRAP_APP_SUCCESS as typeof BOOTSTRAP_APP_SUCCESS,
  countries,
  allCountries,
  currencies,
});

export type InventoryBootstrapFailureAction = ReturnType<typeof inventoryBootstrapFailureAction>;
export const inventoryBootstrapFailureAction = (error: any) => ({
  type: BOOTSTRAP_APP_FAILURE as typeof BOOTSTRAP_APP_FAILURE,
  error,
});

export type InventoryBoostrapDomainAction =
  | InventoryBootstrapRequestAction
  | InventoryBootstrapSuccessAction
  | InventoryBootstrapFailureAction;
