import { createSelector } from 'reselect';
import { IUploadsSubdomain } from './model';
import { uploadsSubdomainSelector } from '../../selectors';
import { values } from 'ramda';
import { UPLOAD_FILE_SIZE_LIMIT } from 'config';

const OTHER_INTERNAL_TAG = 'otherInternal';

export const uploadsSelector = createSelector(
  uploadsSubdomainSelector,
  (subdomain: IUploadsSubdomain) => subdomain.uploads
);

export const uploadListSelector = createSelector(uploadsSelector, uploads => uploads.list);

export const canDeleteListSelector = createSelector(
  uploadsSubdomainSelector,
  (subdomain: IUploadsSubdomain) => subdomain.canDeleteList
);

export const uploadListRequestStatusSelector = createSelector(uploadsSelector, uploads => uploads.request);

// new upload
export const newUploadSelector = createSelector(
  uploadsSubdomainSelector,
  (subdomain: IUploadsSubdomain) => subdomain.newUpload
);

export const newUploadPayloadSelector = createSelector(newUploadSelector, newUpload => newUpload.payload);

export const newUploadPrivacyAckSelector = createSelector(newUploadSelector, newUpload => newUpload.privacyAck);

export const newUploadRequestStatusSelector = createSelector(newUploadSelector, newUpload => newUpload.request);

export const newUploadErrorSelector = createSelector(newUploadSelector, newUpload => newUpload.error);

export const shouldShowNewUploadPrivacyAckSelector = createSelector(newUploadPayloadSelector, payload =>
  !!payload?.tag && payload.tag !== OTHER_INTERNAL_TAG
);

// upload form

export const newUploadFileValidationSelector = createSelector(newUploadPayloadSelector, payload => {
  if (!payload) {
    return [];
  }

  if (!payload.file) {
    return ['Required'];
  }

  if (payload.file.size > UPLOAD_FILE_SIZE_LIMIT) {
    return ['File size exceeds 4MB limit'];
  }

  return [];
});

export const newUploadDisplayNameValidationSelector = createSelector(newUploadPayloadSelector, payload =>
  !payload || payload.displayName ? [] : ['Required']
);

export const newUploadTagValidationSelector = createSelector(newUploadPayloadSelector, payload =>
  !payload || payload.tag ? [] : ['Required']
);

export const newUploadPrivacyAckValidationSelector = createSelector(newUploadSelector, newUpload =>
  !newUpload.payload ||
  newUpload.payload.tag === OTHER_INTERNAL_TAG ||
  newUpload.privacyAck ? [] : ['Required']
);

export const newUploadValidationSelector = createSelector(
  newUploadFileValidationSelector,
  newUploadDisplayNameValidationSelector,
  newUploadTagValidationSelector,
  newUploadPrivacyAckValidationSelector,
  (file, displayName, tag, privacyAck) => ({ file, displayName, tag, privacyAck })
);

export const canSubmitUploadSelector = createSelector(newUploadValidationSelector, obj =>
  values(obj).every(errors => !errors.length)
);

// delete upload
export const deleteUploadSelector = createSelector(
  uploadsSubdomainSelector,
  (subdomain: IUploadsSubdomain) => subdomain.deleteUpload
);

export const deleteUploadUuidSelector = createSelector(deleteUploadSelector, deleteUpload => deleteUpload.uuid);

export const deleteUploadRequestStatusSelector = createSelector(
  deleteUploadSelector,
  deleteUpload => deleteUpload.request
);
