import React, { useCallback } from 'react';
import classnames from 'classnames';
import { INotification } from 'services/BackendApi/types/Notification';
import { TravelAgentNotificationRow } from './TravelAgentNotificationRow';
import { isAdmin as isAdminSelector } from 'store/modules/auth';
import { useSelector } from 'react-redux';

interface ITravelAgentNotificationsProps {
  notifications: INotification[];
  onChange: (notifications: INotification[]) => void;
}

export const TravelAgentNotifications: React.FC<ITravelAgentNotificationsProps> = React.memo(
  ({ notifications, onChange }) => {
    const isAdmin = useSelector(isAdminSelector);
    const handleNotificationChangeEnabled = useCallback(
      (notificationCode: string, enabled: boolean) => {
        const updatedNotifications = notifications.map(notification => {
          if (notification.code === notificationCode) {
            if (enabled) {
              return {
                ...notification,
                enabled: true,
              };
            } else {
              return {
                ...notification,
                enabled: false,
                ccToDepartmentsUuids: [],
              };
            }
          }
          return {
            ...notification,
          };
        });
        onChange(updatedNotifications);
      },
      [onChange, notifications]
    );

    const handleNotificationChangeDepartmentsUuids = useCallback(
      (notificationCode: string, ccToDepartmentsUuids: string[]) => {
        const updatedNotifications = notifications.map(notification => {
          return notification.code === notificationCode ? { ...notification, ccToDepartmentsUuids } : notification;
        });
        onChange(updatedNotifications);
      },
      [onChange, notifications]
    );

    const enabledNotifications = notifications.filter(notification => !!notification.enabled);

    const thClassName =
      'font-normal bg-ivory border border-solid border-r-0 border-gray-20 shadow-pe1 uppercase px-5 py-3 text-xs text-gray-120 sticky top-0 text-left z-1';

    return (
      <div className="notifications-tab pt-10 px-5 pb-30px font-pt-sans">
        <p className="notifications-title m-0 font-bold text-black-true text-17px leading-22px">
          Notifications Settings
        </p>
        <div className="flex justify-between items-center">
          <p className="notifications-tab-description m-0 mt-5px text-black text-15px leading-19px">
            Select from the list below the type of notifications the TA should receive.
          </p>
          <p className="font-pt-sans text-13px leading-22px text-brown-100 m-0">
            <span className="font-bold">{enabledNotifications.length}</span> of {notifications.length} Notifications
            Active
          </p>
        </div>

        <div className="mt-5">
          <table className="ta-notifications-table relative table-fixed w-full border-separate border-spacing-0 font-pt-sans">
            <thead>
              <tr>
                <th className={thClassName} style={{ width: '250px' }}>
                  NOTIFICATION
                </th>
                <th className={classnames('border-l-0', thClassName)} style={{ width: '450px' }}>
                  DESCRIPTION
                </th>
                <th className={classnames('border-l-0', thClassName)} style={{ width: '300px' }}>
                  CC TO DEPARTMENT
                </th>
                <th className={classnames('border-l-0', thClassName)} style={{ width: '140px' }}>
                  STATUS
                </th>
              </tr>
            </thead>
            <tbody>
              {notifications.map(notification => {
                return (
                  <TravelAgentNotificationRow
                    key={notification.code}
                    notification={notification}
                    onChangeEnabled={handleNotificationChangeEnabled}
                    onChangeDepartmentsUuids={handleNotificationChangeDepartmentsUuids}
                    disabled={!isAdmin}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
);
