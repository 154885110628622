import React from 'react';
import classnames from 'classnames';

export interface IFormLabelProps {
  className?: string;
  text: string | React.ReactNode;
  textWrapperClasses?: string;
  textWrapperStyle?: React.CSSProperties;
  children?: React.ReactNode;
}

export const FormLabel: React.FC<IFormLabelProps> = props => {
  return (
    <label className={props.className}>
      <span
        style={props.textWrapperStyle}
        className={classnames(
          'inline-block mb-5px text-black text-13px leading-17px tracking-2xs font-pt-sans',
          props.textWrapperClasses
        )}
      >
        {props.text}
      </span>
      {props.children}
    </label>
  );
};

export default FormLabel;
