import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { makeBackendApi, ICompaniesResponse } from 'services/BackendApi';
import {
  getCompaniesSuccessAction,
  getCompaniesFailureAction,
  GET_COMPANIES_REQUEST
} from '../actions';
import { selectedTaSelector } from '../selectors';

export function* getCompaniesRequestSaga() {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);

    const result: AxiosResponse<ICompaniesResponse> = yield call(backendApi.getCompanies);
    yield put(getCompaniesSuccessAction(result.data.data));

  } catch (e) {
    yield put(getCompaniesFailureAction(e.message));
  }
}

export function* watchGetCompaniesRequest() {
  yield takeLatest([GET_COMPANIES_REQUEST], getCompaniesRequestSaga);
}
