import { AxiosResponse } from 'axios';
import { call, takeLatest, select, put } from 'redux-saga/effects';
import * as Actions from './actions';
import { ENetworkRequestStatus, IUploadResponse, makeBackendApi } from 'services/BackendApi';
import { enqueueNotification } from '../ui';
import { ICompanyDepartmentsResponse } from 'services/BookingManagerApi/types/CompanyDepartmentsResponse';
import { ICompanyMembership, ICompanyMembershipsResponse } from 'services/BookingManagerApi/types/CompanyMemberships';

export function* createCompanyMembershipSaga(action: Actions.CreateCompanyMembershipRequestAction) {
  try {
    const backendApi = makeBackendApi();
    const { membershipPayload } = action;
    const { logoFormData } = membershipPayload;

    try {
      // 1. create the membership
      const createResponse = yield call(
        backendApi.postCompanyMembership,
        membershipPayload.companyUuid!,
        membershipPayload.name!,
        membershipPayload.status!,
        undefined, // with NO company logo
        membershipPayload.contactEmail,
        membershipPayload.contactPhone,
        membershipPayload.contactInfo,
        membershipPayload.benefitsDescription,
        membershipPayload.companyDepartmentUuid
      );

      // 2. then, if we have a logo to upload, upload it and update the membership
      if (logoFormData) {
        let logoUrl: string | undefined = undefined;
        try {
          logoFormData.append('ownerUuid', createResponse.data.data.uuid);
          const logoUploadResponse: AxiosResponse<IUploadResponse> = yield call(backendApi.uploadFile, logoFormData);
          logoUrl = logoUploadResponse.data.data.url;
        } catch (e) {
          yield put(
            enqueueNotification({
              message: 'Error uploading the membership logo while creating a new membership. Please try again.',
              options: { variant: 'error' },
            })
          );
          return;
        }

        // update the membership to have the new logo url
        yield call(
          backendApi.patchCompanyMembership,
          membershipPayload.companyUuid!,
          createResponse.data.data.uuid,
          membershipPayload.name!,
          membershipPayload.status!,
          logoUrl ? logoUrl : undefined,
          membershipPayload.contactEmail,
          membershipPayload.contactPhone,
          membershipPayload.contactInfo,
          membershipPayload.benefitsDescription,
          membershipPayload.companyDepartmentUuid
        );
      }

      yield put(
        enqueueNotification({
          message: 'Company membership has been created',
          options: { variant: 'success' },
        })
      );
    } catch (e) {
      yield put(
        enqueueNotification({
          message: 'Company membership could not be created',
          options: { variant: 'error' },
        })
      );
    }

    // reload the list and close the modal
    yield put(Actions.fetchCompanyMembershipsRequestAction(membershipPayload.companyUuid!));
    yield put(Actions.createCompanyMembershipSuccessAction());
  } catch (e) {
    yield put(
      enqueueNotification({
        message: 'Error updating company logo',
        options: { variant: 'success' },
      })
    );
  }
}

export function* updateCompanyMembershipSaga(action: Actions.CreateCompanyMembershipRequestAction) {
  try {
    const backendApi = makeBackendApi();
    const { membershipPayload } = action;

    // if they've removed a logo, we need to delete the logo
    if (membershipPayload.hasRemovedLogo) {
      try {
        // get the upload response
        const uploadResponse: AxiosResponse = yield call(
          backendApi.getUploadsForCompanyMembership,
          membershipPayload.uuid!,
          membershipPayload.logoUrl!
        );

        const uploadUuid = uploadResponse.data.data[0].uuid;
        yield call(backendApi.deleteUpload, uploadUuid);
      } catch (e) {
        yield put(
          enqueueNotification({
            message: 'Error deleting the membership logo while updating a membership. Please try again.',
            options: { variant: 'error' },
          })
        );
      }
    }

    // if they've supplied logo form data, they're uploading a new logo
    let logoUrl: string | undefined = undefined;
    const { logoFormData } = membershipPayload;
    if (logoFormData) {
      try {
        logoFormData.append('ownerUuid', membershipPayload.uuid!);
        const logoUploadResponse: AxiosResponse<IUploadResponse> = yield call(backendApi.uploadFile, logoFormData);
        logoUrl = logoUploadResponse.data.data.url;
      } catch (e) {
        yield put(
          enqueueNotification({
            message: 'Error uploading the membership logo while updating a membership. Please try again.',
            options: { variant: 'error' },
          })
        );
        yield put(Actions.editCompanyMembershipFailureAction(e));
        return;
      }
    }

    // now update the actual membership
    yield call(
      backendApi.patchCompanyMembership,
      membershipPayload.companyUuid!,
      membershipPayload.uuid!,
      membershipPayload.name!,
      membershipPayload.status!,
      logoUrl ? logoUrl : membershipPayload.hasRemovedLogo ? null : undefined, // if they've uploaded a logo, use it. if not, have they deleted a logo? if so, set it to null. otherwise, leave it undefined
      membershipPayload.contactEmail,
      membershipPayload.contactPhone,
      membershipPayload.contactInfo,
      membershipPayload.benefitsDescription,
      membershipPayload.companyDepartmentUuid
    );

    yield put(
      enqueueNotification({
        message: 'Company membership has been updated',
        options: { variant: 'success' },
      })
    );

    // reload the list and close the modal
    yield put(Actions.fetchCompanyMembershipsRequestAction(membershipPayload.companyUuid!));
    yield put(Actions.editCompanyMembershipSuccessAction());
  } catch (e) {
    yield put(
      enqueueNotification({
        message: 'Error updating company logo',
        options: { variant: 'success' },
      })
    );
  }
}

export function* deleteCompanyMembershipSaga(action: Actions.DeleteCompanyMembershipRequestAction) {
  try {
    const backendApi = makeBackendApi();

    // now save the actual membership, with the logo (if we have it)
    yield call(backendApi.deleteCompanyMembership, action.membershipUuid);

    yield put(
      enqueueNotification({
        message: 'Membership deleted',
        options: { variant: 'success' },
      })
    );

    yield put(Actions.fetchCompanyMembershipsRequestAction(action.companyUuid));
    yield put(Actions.deleteCompanyMembershipSuccessAction());
  } catch (e) {
    yield put(
      enqueueNotification({
        message: 'Error deleting company membership',
        options: { variant: 'error' },
      })
    );
  }
}

function* fetchMembershipsSaga(action: Actions.FetchCompanyMembershipsRequestAction) {
  try {
    const backendApi = makeBackendApi();
    const result: AxiosResponse<ICompanyMembershipsResponse> = yield call(
      backendApi.fetchCompanyMemberships,
      action.companyUuid
    );

    yield put(Actions.fetchCompanyMembershipsSuccessAction(result.data.data));
  } catch (e) {
    yield put(Actions.fetchCompanyMembershipsFailureAction(e));
  }
}

export function* watchCompanyMembershipsSaga() {
  yield takeLatest(Actions.CREATE_COMPANY_MEMBERSHIP_REQUEST, createCompanyMembershipSaga);
  yield takeLatest(Actions.EDIT_COMPANY_MEMBERSHIP_REQUEST, updateCompanyMembershipSaga);
  yield takeLatest(Actions.DELETE_COMPANY_MEMBERSHIP_REQUEST, deleteCompanyMembershipSaga);
  yield takeLatest([Actions.FETCH_COMPANY_MEMBERSHIPS_REQUEST], fetchMembershipsSaga);
}
