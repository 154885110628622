import React from 'react';
import { IInventoryOptionBespoke } from '../../interfaces';

export interface IMenuSingleElementProps {
  onUpdate: (value: IInventoryOptionBespoke) => void;
  className?: string;
  selectedClassName?: string;
  option: IInventoryOptionBespoke;
  isSelected?: boolean;
  hidden?: boolean;
}

export const MenuSingleElement = (props: IMenuSingleElementProps) => {
  const handleOptionClick = (option: IInventoryOptionBespoke) => {
    props.onUpdate(option);
  };

  return (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        handleOptionClick(props.option);
      }}
      className={`menusingleelement-label text-black min-h-35px flex items-center ${props.className} ${
        props.isSelected ? props.selectedClassName : ''
      } 
      ${props.hidden && props.hidden === true ? 'hidden' : ''}`}
    >
      {props.option.label}
    </div>
  );
};
