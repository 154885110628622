export enum EIncentiveAmountType {
  FLAT = 'flat',
  PERCENTAGE = 'percentage'
}

export interface ICompanyIncentiveUpsertBody {
  startDate: string;
  amount: number;
  type: EIncentiveAmountType;
  notes?: string;
}

export interface ICompanyIncentiveResponse {
  travelCompanyUuid: string;
  startDate: string;
  amount: number;
  type: EIncentiveAmountType;
  version: number;
  notes?: string;
  createdAt: string;
  updatedAt?: string
}
