import { EBespokeNegotiationAffectedItems, EBespokeNegotiationApplication, EBespokeNegotiationDiscountType, IBespokeNegotiationItem, IBespokeNegotiationLead } from 'services/BookingManagerApi';

export const GET_REQUEST = 'bookingManager/bespokeNegotiation/GET_REQUEST';
export const GET_SUCCESS = 'bookingManager/bespokeNegotiation/GET_SUCCESS';
export const GET_FAILURE = 'bookingManager/bespokeNegotiation/GET_FAILURE';

export const UPDATE_REQUEST = 'bookingManager/bespokeNegotiation/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'bookingManager/bespokeNegotiation/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'bookingManager/bespokeNegotiation/UPDATE_FAILURE';

export const OPEN_MODAL = 'bookingManager/bespokeNegotiation/OPEN_MODAL';
export const CLOSE_MODAL = 'bookingManager/bespokeNegotiation/CLOSE_MODAL';

export const SELECT_VERSION = 'bookingManager/bespokeNegotiation/SELECT_VERSION';
export const SELECT_LEAD = 'bookingManager/bespokeNegotiation/SELECT_LEAD';
export const SET_DISCOUNT_AMOUNT = 'bookingManager/bespokeNegotiation/SET_DISCOUNT_AMOUNT';
export const SET_DISCOUNT_TYPE = 'bookingManager/bespokeNegotiation/SET_DISCOUNT_TYPE';
export const TOGGLE_APPLICATION = 'bookingManager/bespokeNegotiation/TOGGLE_APPLICATION';
export const TOGGLE_AFFECTED_ITEMS = 'bookingManager/bespokeNegotiation/TOGGLE_AFFECTED_ITEMS';
export const TOGGLE_SAVE_AS_NEW = 'bookingManager/bespokeNegotiation/TOGGLE_SAVE_AS_NEW';

export type GetRequestAction = ReturnType<typeof getRequestAction>;
export const getRequestAction = () => ({
  type: GET_REQUEST as typeof GET_REQUEST,
});

export type GetSuccessAction = ReturnType<typeof getSuccessAction>;
export const getSuccessAction = (items: IBespokeNegotiationItem[]) => ({
  type: GET_SUCCESS as typeof GET_SUCCESS,
  items,
});

export type GetFailureAction = ReturnType<typeof getFailureAction>;
export const getFailureAction = (error: string) => ({
  type: GET_FAILURE as typeof GET_FAILURE,
  error,
});

export type UpdateRequestAction = ReturnType<typeof updateRequestAction>;
export const updateRequestAction = () => ({
  type: UPDATE_REQUEST as typeof UPDATE_REQUEST,
});

export type UpdateSuccessAction = ReturnType<typeof updateSuccessAction>;
export const updateSuccessAction = (items: IBespokeNegotiationItem[]) => ({
  type: UPDATE_SUCCESS as typeof UPDATE_SUCCESS,
  items,
});

export type UpdateFailureAction = ReturnType<typeof updateFailureAction>;
export const updateFailureAction = (error: string) => ({
  type: UPDATE_FAILURE as typeof UPDATE_FAILURE,
  error,
});

export type OpenModalAction = ReturnType<typeof openModalAction>;
export const openModalAction = () => ({
  type: OPEN_MODAL as typeof OPEN_MODAL,
});

export type CloseModalAction = ReturnType<typeof closeModalAction>;
export const closeModalAction = () => ({
  type: CLOSE_MODAL as typeof CLOSE_MODAL,
});

export type SelectVersionAction = ReturnType<typeof selectVersionAction>;
export const selectVersionAction = (version: string) => ({
  type: SELECT_VERSION as typeof SELECT_VERSION,
  version
});

export type SelectLeadAction = ReturnType<typeof selectLeadAction>;
export const selectLeadAction = (lead: IBespokeNegotiationLead) => ({
  type: SELECT_LEAD as typeof SELECT_LEAD,
  lead
});

export type SetDiscountAmountAction = ReturnType<typeof setDiscountAmountAction>;
export const setDiscountAmountAction = (amount: number) => ({
  type: SET_DISCOUNT_AMOUNT as typeof SET_DISCOUNT_AMOUNT,
  amount
});

export type SetDiscountTypeAction = ReturnType<typeof setDiscountTypeAction>;
export const setDiscountTypeAction = (discountType: EBespokeNegotiationDiscountType) => ({
  type: SET_DISCOUNT_TYPE as typeof SET_DISCOUNT_TYPE,
  discountType
});

export type ToggleApplicationAction = ReturnType<typeof toggleApplicationAction>;
export const toggleApplicationAction = (application: EBespokeNegotiationApplication) => ({
  type: TOGGLE_APPLICATION as typeof TOGGLE_APPLICATION,
  application,
});

export type ToggleAffectedItemsAction = ReturnType<typeof toggleAffectedItemsAction>;
export const toggleAffectedItemsAction = (items: EBespokeNegotiationAffectedItems) => ({
  type: TOGGLE_AFFECTED_ITEMS as typeof TOGGLE_AFFECTED_ITEMS,
  items,
});

export type ToggleSaveAsNewAction = ReturnType<typeof toggleSaveAsNewAction>;
export const toggleSaveAsNewAction = () => ({
  type: TOGGLE_SAVE_AS_NEW as typeof TOGGLE_SAVE_AS_NEW,
});


export type Any =
  | GetRequestAction
  | GetSuccessAction
  | GetFailureAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | OpenModalAction
  | CloseModalAction
  | SelectVersionAction
  | SelectLeadAction
  | SetDiscountAmountAction
  | SetDiscountTypeAction
  | ToggleApplicationAction
  | ToggleAffectedItemsAction
  | ToggleSaveAsNewAction;
