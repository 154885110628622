import { TCurrencyCode } from 'interfaces';
import { RequestedAgeGroups } from 'services/BackendApi';

export interface Total {
  cents: number;
  beforeDiscountCents: number | null;
  currency: TCurrencyCode;
}

export interface StayDetails {
  roomCount: number;
  guestCount: number;
  nightCount: number;
};

export interface Room {
  name: string;
  category?: string;
  mealPlan?: string;
  occupancy: RequestedAgeGroups;
  appliedOffers: string[];
};

export interface Quote {
  isOnRequest: boolean;
  total: Total | null;
  appliedOfferNames: string[];
};

export interface BestOptionProduct {
  name: string;
  appliedOffers: string[];
};

export interface BestOptionSubdomain {
  stayDetails: StayDetails | null;
  quote: Quote | null;
  rooms: Room[] | null;
  transfers: BestOptionProduct[] | null;
  services: BestOptionProduct[] | null;
};

export const bestOptionInitialState: BestOptionSubdomain = {
  stayDetails: null,
  quote: null,
  rooms: null,
  transfers: null,
  services: null,
};
