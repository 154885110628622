import { normalize } from 'normalizr';
import { makeBackendApi } from 'services/BackendApi';
import { content as contentSchema } from 'api/schema';
import { successAction, errorFromResponse, loadingAction } from 'store/common';
import { getBearerTokenFromLocalStorage } from 'services/tokenLocalStorage';
import { selectedTaSelector } from '../agents';

export const OFFERS_LATEST = 'OFFERS_LATEST';

/**
 * Fetch offers action
 *
 * @returns {object}
 */
export const fetchOffersAction = () => ({
  type: OFFERS_LATEST,
});

/**
 * Fetch latest offers
 *
 * @param {*} args
 * @returns {Function}
 */
export const fetchLatestOffers = () => async (dispatch, getState) => {
  const token = getBearerTokenFromLocalStorage();

  if (!token.value || token.expired) {
    return;
  }

  dispatch(fetchOffersAction());
  dispatch(loadingAction(OFFERS_LATEST, {}));

  const selectedTa = selectedTaSelector(getState());
  const backendApi = makeBackendApi(selectedTa?.uuid);

  try {
    const {
      data: { data },
    } = await backendApi.getContentLatestOffers();

    const normalized = normalize(data, [contentSchema]);
    dispatch(successAction(OFFERS_LATEST, normalized));
  } catch (e) {
    dispatch(errorFromResponse(OFFERS_LATEST, e, 'Could not fetch latest offers.'));
  }
};
