import { createSelector } from 'reselect';
import { ICompanyListDomain } from './model';

export const domainSelector = (state: any): ICompanyListDomain => state.companyList;

export const listSelector = createSelector(
  domainSelector,
  (domain: ICompanyListDomain) => domain.list
);

export const filterSelector = createSelector(
  domainSelector,
  (domain: ICompanyListDomain) => domain.filter
);

export const paginationSelector = createSelector(
  domainSelector,
  (domain: ICompanyListDomain) => domain.pagination
);

export const requestSelector = createSelector(
  domainSelector,
  (domain: ICompanyListDomain) => domain.request
);
