import * as Actions from './actions';
import { initialState, IUserDomain } from './model';

export const userDomainReducer = (
  state: IUserDomain = initialState,
  action: Actions.UserAction
): IUserDomain => {
  switch (action.type) {
    case Actions.SET_AUTHORIZED_USER:
      return {
        ...state,
        authorizedUser: action.user,
      };
    case Actions.CLEAR_AUTHORIZED_USER:
      return {
        ...state,
        authorizedUser: null,
      };

    default:
      return state;
  }
};

export default userDomainReducer;
