import React, { useCallback } from 'react';
import classnames from 'classnames';

export enum EUITextAreaResizeType {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
  BOTH = 'both',
  NONE = 'none',
}

export interface IUITextAreaProps {
  label?: string;
  value: string;
  id?: string;
  rows?: number;
  limit?: number;
  aboutText?: string;
  className?: string;
  labelClassName?: string;
  textAreaClassName?: string;
  labelStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  errorMessage?: string | null;
  errorClassName?: string;
  warning?: boolean;
  disabled?: boolean;
  resizable?: EUITextAreaResizeType;
  placeholder?: string;
  name?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
}

export const UITextArea = (props: IUITextAreaProps) => {
  const { label, id='cancellation-policies-text', rows = 5, limit = 0, aboutText, onChange, resizable = EUITextAreaResizeType.NONE, className, errorMessage, labelClassName, textAreaClassName, errorClassName, ...rest } = props;
  const isError = !!errorMessage || errorMessage === '';
  const inputClassname = classnames('font-pt-sans text-[15px] p-[10px] w-full outline-none text-black border border-solid', textAreaClassName,
    {
      "default:border-gray-40 default:bg-ivory": !isError,
      "border-red-95 bg-red-25": isError,
      "resize-none": resizable === EUITextAreaResizeType.NONE,
      "resize-y": resizable === EUITextAreaResizeType.VERTICAL,
      "resize-x": resizable === EUITextAreaResizeType.HORIZONTAL,
      "resize": resizable === EUITextAreaResizeType.BOTH,
    }
  );

  const handleChange = useCallback((e) => {
    onChange && onChange(e.target.value);
  }, [onChange]);

  const currentTextLength = props.value?.length ?? 0;
  const remainingLength = limit - currentTextLength > 0 ? limit - currentTextLength :0;
  const limitText = limit ? `Limit: ${limit} characters / Remaining: ${remainingLength}` : '';

  return (
    <div className={`flex flex-col ${className ?? ''}`}>
      {label && (
        <label
          style={props.labelStyle}
          className={classnames('text-black font-pt-sans text-13px leading-17px tracking-2xs mb-5px', labelClassName)}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      {/* @ts-ignore */}
      <textarea rows={rows} className={inputClassname} id={id} onChange={handleChange} {...rest} />
      <div className="flex justify-between items-start">
        <p className={`text-gray-80 text-[14px] leading-[18px] font-pt-sans mt-5px mb-0`}>
          {aboutText}
        </p>
        {limitText && <p className={`text-gray-80 text-[14px] leading-[18px] font-pt-sans mt-5px mb-0`}>
          {limitText}
        </p>}
      </div>
      {errorMessage && (
        <p className={`font-normal text-red-95 text-13px leading-2xs font-pt-sans mt-5px mb-0 ${errorClassName ?? ''}`}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};
