import { CHMarkupSortField, CHMarkupSortOrder, ICompanyMarkupOptionModel } from './model';
import { ICompanyHotelMarkup } from '../../../services/BackendApi/types/CHMarkupResponse';

export const SET_TRAVEL_COMPANY_ACTION = 'companyHotelMarkup/SET_TRAVEL_COMPANY_ACTION';

export const FETCH_COMPANY_HOTEL_MARKUP_LIST_REQUEST = 'companyHotelMarkup/FETCH_COMPANY_HOTEL_MARKUP_LIST_REQUEST';
export const FETCH_COMPANY_HOTEL_MARKUP_LIST_SUCCESS = 'companyHotelMarkup/FETCH_COMPANY_HOTEL_MARKUP_LIST_SUCCESS';
export const FETCH_COMPANY_HOTEL_MARKUP_LIST_FAILURE = 'companyHotelMarkup/FETCH_COMPANY_HOTEL_MARKUP_LIST_FAILURE';

export const FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_REQUEST =
  'companyHotelMarkup/FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_REQUEST';
export const FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_SUCCESS =
  'companyHotelMarkup/FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_SUCCESS';
export const FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_FAILURE =
  'companyHotelMarkup/FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_FAILURE';

export const CREATE_COMPANY_HOTEL_MARKUP_REQUEST = 'companyHotelMarkup/CREATE_COMPANY_HOTEL_MARKUP_REQUEST';
export const CREATE_COMPANY_HOTEL_MARKUP_SUCCESS = 'companyHotelMarkup/CREATE_COMPANY_HOTEL_MARKUP_SUCCESS';
export const CREATE_COMPANY_HOTEL_MARKUP_FAILURE = 'companyHotelMarkup/CREATE_COMPANY_HOTEL_MARKUP_FAILURE';

export const OPEN_CREATE_MARKUP_MODAL = 'companyHotelMarkup/OPEN_CREATE_MARKUP_MODAL';
export const CLOSE_CREATE_MARKUP_MODAL = 'companyHotelMarkup/CLOSE_CREATE_MARKUP_MODAL';

export const FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_REQUEST =
  'companyHotelMarkup/FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_REQUEST';
export const FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_SUCCESS =
  'companyHotelMarkup/FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_SUCCESS';
export const FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_FAILURE =
  'companyHotelMarkup/FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_FAILURE';

export const SET_CH_MARKUP_SORT_FIELD = 'companyHotelMarkup/CH_MARKUP_SET_SORT_FIELD';
export const SET_CH_MARKUP_EDITING_MODE = 'companyHotelMarkup/SET_CH_MARKUP_EDITING_MODE';
export const SET_CH_MARKUP_PERCENTAGE_EDITED = 'companyHotelMarkup/SET_CH_MARKUP_PERCENTAGE_EDITED';

export const EDIT_MARKUP_REQUEST = 'companyHotelMarkup/EDIT_MARKUP_REQUEST';
export const EDIT_MARKUP_SUCCESS = 'companyHotelMarkup/EDIT_MARKUP_SUCCESS';
export const EDIT_MARKUP_FAILURE = 'companyHotelMarkup/EDIT_MARKUP_FAILURE';

export const OPEN_DELETE_MODAL = 'companyHotelMarkup/OPEN_DELETE_MODAL';
export const CLOSE_DELETE_MODAL = 'companyHotelMarkup/CLOSE_DELETE_MODAL';

export const DELETE_MARKUP_REQUEST = 'companyHotelMarkup/DELETE_MARKUP_REQUEST';
export const DELETE_MARKUP_SUCCESS = 'companyHotelMarkup/DELETE_MARKUP_SUCCESS';
export const DELETE_MARKUP_FAILURE = 'companyHotelMarkup/DELETE_MARKUP_FAILURE';

export const TOGGLE_MARKUP = 'companyHotelMarkup/TOGGLE_MARKUP';
export const TOGGLE_ALL_MARKUPS = 'companyHotelMarkup/TOGGLE_ALL_MARKUPS';

export const OPEN_BULK_EDIT_MODAL = 'companyHotelMarkup/OPEN_BULK_EDIT_MODAL';
export const SET_BULK_EDIT_VALUE = 'companyHotelMarkup/SET_BULK_EDIT_VALUE';
export const CLOSE_BULK_EDIT_MODAL = 'companyHotelMarkup/CLOSE_BULK_EDIT_MODAL';

export const OPEN_BULK_DELETE_MODAL = 'companyHotelMarkup/OPEN_BULK_DELETE_MODAL';
export const CLOSE_BULK_DELETE_MODAL = 'companyHotelMarkup/CLOSE_BULK_DELETE_MODAL';

export type SetTravelCompanyAction = ReturnType<typeof setTravelCompanyAction>;
export const setTravelCompanyAction = (companyUuid: string) => ({
  type: SET_TRAVEL_COMPANY_ACTION as typeof SET_TRAVEL_COMPANY_ACTION,
  companyUuid,
});

export type FetchCHMarkupListRequestAction = ReturnType<typeof fetchCHMarkupListRequestAction>;
export const fetchCHMarkupListRequestAction = () => ({
  type: FETCH_COMPANY_HOTEL_MARKUP_LIST_REQUEST as typeof FETCH_COMPANY_HOTEL_MARKUP_LIST_REQUEST,
});

export type FetchCHMarkupListSuccessAction = ReturnType<typeof fetchCHMarkupListSuccessAction>;
export const fetchCHMarkupListSuccessAction = (companyHotelMarkups: ICompanyMarkupOptionModel[]) => ({
  type: FETCH_COMPANY_HOTEL_MARKUP_LIST_SUCCESS as typeof FETCH_COMPANY_HOTEL_MARKUP_LIST_SUCCESS,
  companyHotelMarkups,
});

export type FetchCHMarkupListFailureAction = ReturnType<typeof fetchCHMarkupListFailureAction>;
export const fetchCHMarkupListFailureAction = (error: Error) => ({
  type: FETCH_COMPANY_HOTEL_MARKUP_LIST_FAILURE as typeof FETCH_COMPANY_HOTEL_MARKUP_LIST_FAILURE,
  error,
});

export type FetchProductsWithCompanyHotelMarkupRequestAction = ReturnType<
  typeof fetchProductsWithCompanyHotelMarkupRequestAction
>;
export const fetchProductsWithCompanyHotelMarkupRequestAction = () => ({
  type: FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_REQUEST as typeof FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_REQUEST,
});

export type FetchProductsWithCompanyHotelMarkupSuccessAction = ReturnType<
  typeof fetchProductsWithCompanyHotelMarkupSuccessAction
>;
export const fetchProductsWithCompanyHotelMarkupSuccessAction = (productsWithCompanyHotelMarkup: string[]) => ({
  type: FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_SUCCESS as typeof FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_SUCCESS,
  productsWithCompanyHotelMarkup,
});

export type FetchProductsWithCompanyHotelMarkupFailureAction = ReturnType<
  typeof fetchProductsWithCompanyHotelMarkupFailureAction
>;
export const fetchProductsWithCompanyHotelMarkupFailureAction = (error: Error) => ({
  type: FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_FAILURE as typeof FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_FAILURE,
  error,
});

export type FetchCompanyHotelsWithoutMarkupRequestAction = ReturnType<
  typeof fetchCompanyHotelsWithoutMarkupRequestAction
>;
export const fetchCompanyHotelsWithoutMarkupRequestAction = () => ({
  type: FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_REQUEST as typeof FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_REQUEST,
});

export type FetchCompanyHotelsWithoutMarkupSuccessAction = ReturnType<
  typeof fetchCompanyHotelsWithoutMarkupSuccessAction
>;
export const fetchCompanyHotelsWithoutMarkupSuccessAction = (companyHotels: any[]) => ({
  type: FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_SUCCESS as typeof FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_SUCCESS,
  companyHotels,
});

export type FetchCompanyHotelsWithoutMarkupFailureAction = ReturnType<
  typeof fetchCompanyHotelsWithoutMarkupFailureAction
>;
export const fetchCompanyHotelsWithoutMarkupFailureAction = (error: Error) => ({
  type: FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_FAILURE as typeof FETCH_COMPANY_HOTELS_WITHOUT_MARKUP_FAILURE,
  error,
});

export type CreateCHMarkupRequestAction = ReturnType<typeof createCHMarkupRequestAction>;
export const createCHMarkupRequestAction = (markupPercentage: string, hotelUuids: string[]) => ({
  type: CREATE_COMPANY_HOTEL_MARKUP_REQUEST as typeof CREATE_COMPANY_HOTEL_MARKUP_REQUEST,
  markupPercentage,
  hotelUuids,
});

export type CreateCHMarkupSuccessAction = ReturnType<typeof createCHMarkupSuccessAction>;
export const createCHMarkupSuccessAction = () => ({
  type: CREATE_COMPANY_HOTEL_MARKUP_SUCCESS as typeof CREATE_COMPANY_HOTEL_MARKUP_SUCCESS,
});

export type CreateCHMarkupFailureAction = ReturnType<typeof createCHMarkupFailureAction>;
export const createCHMarkupFailureAction = (error: Error) => ({
  type: CREATE_COMPANY_HOTEL_MARKUP_FAILURE as typeof CREATE_COMPANY_HOTEL_MARKUP_FAILURE,
  error,
});

export type SetCHMarkupSortFieldAction = ReturnType<typeof setCHMarkupSortFieldAction>;
export const setCHMarkupSortFieldAction = (sortField: CHMarkupSortField, sortOrder: CHMarkupSortOrder) => ({
  type: SET_CH_MARKUP_SORT_FIELD as typeof SET_CH_MARKUP_SORT_FIELD,
  sortField,
  sortOrder,
});

export type OpenCreateMarkupModalAction = ReturnType<typeof openCreateMarkupModalAction>;
export const openCreateMarkupModalAction = () => ({
  type: OPEN_CREATE_MARKUP_MODAL as typeof OPEN_CREATE_MARKUP_MODAL,
});

export type CloseCreateMarkupModalAction = ReturnType<typeof closeCreateMarkupModalAction>;
export const closeCreateMarkupModalAction = () => ({
  type: CLOSE_CREATE_MARKUP_MODAL as typeof CLOSE_CREATE_MARKUP_MODAL,
});

export type SetCHMarkupEditingModeAction = ReturnType<typeof setCHMarkupEditingModeAction>;
export const setCHMarkupEditingModeAction = (
  companyUuid: string,
  option: ICompanyMarkupOptionModel,
  isEditMode: boolean
) => ({
  type: SET_CH_MARKUP_EDITING_MODE as typeof SET_CH_MARKUP_EDITING_MODE,
  companyUuid,
  option,
  isEditMode,
});

export type SetCHMarkupPercentageEditedAction = ReturnType<typeof setCHMarkupPercentageEditedAction>;
export const setCHMarkupPercentageEditedAction = (
  companyUuid: string,
  option: ICompanyMarkupOptionModel,
  editedValue: string
) => ({
  type: SET_CH_MARKUP_PERCENTAGE_EDITED as typeof SET_CH_MARKUP_PERCENTAGE_EDITED,
  companyUuid,
  option,
  editedValue,
});

export type EditMarkupRequestAction = ReturnType<typeof editMarkupRequestAction>;
export const editMarkupRequestAction = (companyUuid: string, option?: ICompanyMarkupOptionModel) => ({
  type: EDIT_MARKUP_REQUEST as typeof EDIT_MARKUP_REQUEST,
  companyUuid,
  option,
});

export type EditMarkupSuccessAction = ReturnType<typeof editMarkupSuccessAction>;
export const editMarkupSuccessAction = (
  companyUuid: string,
  editedValue: string,
  option?: ICompanyMarkupOptionModel
) => ({
  type: EDIT_MARKUP_SUCCESS as typeof EDIT_MARKUP_SUCCESS,
  companyUuid,
  option,
  editedValue,
});

export type EditMarkupFailureAction = ReturnType<typeof editMarkupFailureAction>;
export const editMarkupFailureAction = (companyUuid: string, error: Error, option?: ICompanyMarkupOptionModel) => ({
  type: EDIT_MARKUP_FAILURE as typeof EDIT_MARKUP_FAILURE,
  companyUuid,
  option,
  error,
});

export type ChmOpenDeleteModalAction = ReturnType<typeof chmOpenDeleteModalAction>;
export const chmOpenDeleteModalAction = (companyUuid: string, option: ICompanyMarkupOptionModel) => ({
  type: OPEN_DELETE_MODAL as typeof OPEN_DELETE_MODAL,
  companyUuid,
  option,
});

export type ChmCloseDeleteModalAction = ReturnType<typeof chmCloseDeleteModalAction>;
export const chmCloseDeleteModalAction = () => ({
  type: CLOSE_DELETE_MODAL as typeof CLOSE_DELETE_MODAL,
});

export type DeleteCHMarkupRequestAction = ReturnType<typeof deleteCHMarkupRequestAction>;
export const deleteCHMarkupRequestAction = () => ({
  type: DELETE_MARKUP_REQUEST as typeof DELETE_MARKUP_REQUEST,
});

export type DeleteCHMarkupSuccessAction = ReturnType<typeof deleteCHMarkupSuccessAction>;
export const deleteCHMarkupSuccessAction = () => ({
  type: DELETE_MARKUP_SUCCESS as typeof DELETE_MARKUP_SUCCESS,
});

export type DeleteCHMarkupFailureAction = ReturnType<typeof deleteCHMarkupFailureAction>;
export const deleteCHMarkupFailureAction = (error: Error) => ({
  type: DELETE_MARKUP_FAILURE as typeof DELETE_MARKUP_FAILURE,
  error,
});

export type ToggleMarkupAction = ReturnType<typeof toggleMarkupAction>;
export const toggleMarkupAction = (option: ICompanyMarkupOptionModel) => ({
  type: TOGGLE_MARKUP as typeof TOGGLE_MARKUP,
  option,
});

export type ToggleAllMarkupsAction = ReturnType<typeof toggleAllMarkupsAction>;
export const toggleAllMarkupsAction = () => ({
  type: TOGGLE_ALL_MARKUPS as typeof TOGGLE_ALL_MARKUPS,
});

export type OpenBulkEditModalAction = ReturnType<typeof openBulkEditModalAction>;
export const openBulkEditModalAction = () => ({
  type: OPEN_BULK_EDIT_MODAL as typeof OPEN_BULK_EDIT_MODAL,
});

export type CloseBulkEditModalAction = ReturnType<typeof closeBulkEditModalAction>;
export const closeBulkEditModalAction = () => ({
  type: CLOSE_BULK_EDIT_MODAL as typeof CLOSE_BULK_EDIT_MODAL,
});

export type SetBulkEditValueAction = ReturnType<typeof setBulkEditValueAction>;
export const setBulkEditValueAction = (companyUuid: string, editedValue: string) => ({
  type: SET_BULK_EDIT_VALUE as typeof SET_BULK_EDIT_VALUE,
  companyUuid,
  editedValue,
});

export type OpenBulkDeleteModalAction = ReturnType<typeof openBulkDeleteModalAction>;
export const openBulkDeleteModalAction = () => ({
  type: OPEN_BULK_DELETE_MODAL as typeof OPEN_BULK_DELETE_MODAL,
});

export type CloseBulkDeleteModalAction = ReturnType<typeof closeBulkDeleteModalAction>;
export const closeBulkDeleteModalAction = () => ({
  type: CLOSE_BULK_DELETE_MODAL as typeof CLOSE_BULK_DELETE_MODAL,
});

export type CompanyHotelMarkupAction =
  | SetTravelCompanyAction
  | FetchCHMarkupListRequestAction
  | FetchCHMarkupListSuccessAction
  | FetchCHMarkupListFailureAction
  | FetchCompanyHotelsWithoutMarkupRequestAction
  | FetchCompanyHotelsWithoutMarkupSuccessAction
  | FetchCompanyHotelsWithoutMarkupFailureAction
  | CreateCHMarkupRequestAction
  | CreateCHMarkupSuccessAction
  | CreateCHMarkupFailureAction
  | EditMarkupRequestAction
  | EditMarkupSuccessAction
  | EditMarkupFailureAction
  | OpenCreateMarkupModalAction
  | CloseCreateMarkupModalAction
  | SetCHMarkupEditingModeAction
  | SetCHMarkupSortFieldAction
  | ChmOpenDeleteModalAction
  | ChmCloseDeleteModalAction
  | DeleteCHMarkupRequestAction
  | DeleteCHMarkupSuccessAction
  | DeleteCHMarkupFailureAction
  | FetchProductsWithCompanyHotelMarkupRequestAction
  | FetchProductsWithCompanyHotelMarkupSuccessAction
  | FetchProductsWithCompanyHotelMarkupFailureAction
  | SetCHMarkupPercentageEditedAction
  | ToggleMarkupAction
  | ToggleAllMarkupsAction
  | OpenBulkEditModalAction
  | CloseBulkEditModalAction
  | SetBulkEditValueAction
  | OpenBulkDeleteModalAction
  | CloseBulkDeleteModalAction;
