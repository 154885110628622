import { rootSagaFactory } from '../importer/sagas';
import { EImportEntity } from '../importer/model';

import { ancillarySuppliersImportDomainSelector } from './selectors';

export default rootSagaFactory({
  entity: EImportEntity.ANCILLARY_SUPPLIERS,
  domainSelector: ancillarySuppliersImportDomainSelector,

  importApiCall: (backendApi, bookingManagerApi) => bookingManagerApi.postImportAncillarySuppliers(),

  importStatusApiCall: async (backendApi, bookingManagerApi) => {
    const result = await bookingManagerApi.getImportAncillarySuppliersStatus();

    // massage the result into the expected shape
    return {
      ...result,
      data: {
        ...result.data,
        meta: {
          workbookId: result.data.workbookId,
        },
        data: {
          ...result.data,
        },
      },
    };
  },
});
