import { createSelector } from 'reselect';
import { IProposalDetailsDomain } from './model';

export const proposalDetailsDomain = (state: any): IProposalDetailsDomain => state.proposalDetails;

export const proposalDetailsRequestsSelector = createSelector(
  proposalDetailsDomain,
  (domain: IProposalDetailsDomain): IProposalDetailsDomain['requests'] => domain.requests
);

export const proposalDetailsErrorSelector = createSelector(
  proposalDetailsDomain,
  (domain: IProposalDetailsDomain): IProposalDetailsDomain['error'] => domain.error
);

export const proposalDetailsSelector = createSelector(
  proposalDetailsDomain,
  (domain: IProposalDetailsDomain): IProposalDetailsDomain['proposal'] => {
    return domain.proposal;
  }
);

export const proposalBookingsSelector = createSelector(
  proposalDetailsDomain,
  (domain: IProposalDetailsDomain): IProposalDetailsDomain['bookings'] => {
    return domain.bookings;
  }
);

export const proposalDetailsUuidSelector = createSelector(
  proposalDetailsDomain,
  (domain: IProposalDetailsDomain): IProposalDetailsDomain['proposalUuid'] => domain.proposalUuid
);

export const selectedBookingUuidsSelector = createSelector(
  proposalDetailsDomain,
  (domain: IProposalDetailsDomain): IProposalDetailsDomain['selectedBookingUuids'] => {
    return domain.selectedBookingUuids;
  }
);

export const generatePdfSelectedBookingUuidsSelector = createSelector(
  selectedBookingUuidsSelector,
  proposalBookingsSelector,
  (selected, bookings) => selected.length
    ? selected
    : bookings.map(x => x.uuid)
);

export const proposalDetailsSortBySelector = createSelector(
  proposalDetailsDomain,
  (domain: IProposalDetailsDomain): IProposalDetailsDomain['sortBy'] => domain.sortBy
);

export const proposalDetailsSortOrderSelector = createSelector(
  proposalDetailsDomain,
  (domain: IProposalDetailsDomain): IProposalDetailsDomain['sortOrder'] => domain.sortOrder
);

export const proposalDownloadHistorySelector = createSelector(
  proposalDetailsDomain,
  (domain: IProposalDetailsDomain): IProposalDetailsDomain['proposalDownloadHistory'] => domain.proposalDownloadHistory
);
