import { ENetworkRequestStatus } from 'services/BackendApi';
import { ICompanyDepartment } from 'services/BookingManagerApi/types/CompanyDepartmentsResponse';

export interface ICompanyDepartmentRequests {
  departmentListLoad: ENetworkRequestStatus;
  departmentCreate: ENetworkRequestStatus;
  departmentSave: ENetworkRequestStatus;
  departmentDelete: ENetworkRequestStatus;
}

export interface ICompanyDepartmentDomain {
  departments: ICompanyDepartment[] | null;
  uuidToDelete: string | null,
  departmentToEdit: ICompanyDepartment | null,
  requests: ICompanyDepartmentRequests;
  modals: {
    isCreateDepartmentModalOpen: boolean;
    isEditDepartmentModalOpen: boolean;
    isDeleteDepartmentModalOpen: boolean;
  };
}

export const initialState: ICompanyDepartmentDomain = {
  departments: null,
  uuidToDelete: null,
  departmentToEdit: null,
  requests: {
    departmentListLoad: ENetworkRequestStatus.IDLE,
    departmentCreate: ENetworkRequestStatus.IDLE,
    departmentSave: ENetworkRequestStatus.IDLE,
    departmentDelete: ENetworkRequestStatus.IDLE,
  },
  modals: {
    isCreateDepartmentModalOpen: false,
    isEditDepartmentModalOpen: false,
    isDeleteDepartmentModalOpen: false,
  },
}

export const validateEmailList = (s: string | null): string | null => {
  if(!(s || '').trim()) {
    return 'This field is required';
  }
  const xs = (s as string).split(',').map(x => x.trim()).filter(Boolean);
  const valid = xs.every(x => /\S+@\S+\.\S+/.test(x));
 
  return valid ? null : 'Invalid format';
};

export const sanitizeEmailList = (s: string | null): string => 
  (s || '')
    .split(',')
    .map(x => x.trim())
    .filter(Boolean)
    .join(',');
