import React, { HTMLProps } from 'react';
import styled from 'styled-components';
import MousePositionProvider from 'pureUi/providers/MousePositionProvider';
import Tooltip from 'pureUi/Tooltip';
import { SvgIcon } from 'ui/SvgIcon';
import InfoIcon from 'ui/Icons/info.full.component.svg';
import { theme } from '../../../tailwind.config';

export interface IInfoProps extends HTMLProps<HTMLSpanElement> {}

const Info = (props: IInfoProps) => {
  return (
    <MousePositionProvider
      className={`${props.className} ${props.type}`}
      render={mousePosition => {
        return (
          <>
            <SvgIcon
              className="info inline-block"
              key={'icon'}
              IconComponent={InfoIcon}
              width="16px"
              height="16px"
              defaultFill={theme?.colors!['brown-100']}
              hoverFill={theme?.colors!['brown-100']}
              activeFill={theme?.colors!['brown-100']}
            />
            {/* 
            // @ts-ignore */}
            {props.children && mousePosition.isOver && <Tooltip position={mousePosition}>{props.children}</Tooltip>}
            {/* TODO: Figure out cause and fix for the TS error above */}
          </>
        );
      }}
    />
  );
};

export default styled(Info)`
  .material-icons {
    font-size: inherit;
    color: inherit;
  }
`;
