import { cloneDeep, sortBy } from 'lodash-es';
import { CredentialsInfoSortDirection, CredentialsInfoSortField, ENetworkRequestStatus, ICredentialsInfo } from 'services/BackendApi';
import * as Actions from './actions';
import { ICredentialsInfoDomain, initialState } from './model';
import { update } from 'utils/immutable';

export const credentialsInfoReducer = (
  state: ICredentialsInfoDomain = initialState,
  action: Actions.Any
): ICredentialsInfoDomain => {
  switch (action.type) {

    case Actions.GET_REQUEST:
      return update(state, s => s.request.get = ENetworkRequestStatus.PENDING);

    case Actions.GET_SUCCESS:
      return update(state, s => {
        s.request.get = ENetworkRequestStatus.SUCCESS;
        
        s.storedList = cloneDeep(action.data);
        s.draftList = cloneDeep(action.data);
      });

    case Actions.GET_FAILURE:
      return update(state, s => s.request.get = ENetworkRequestStatus.ERROR);
    
    case Actions.SET_FILTER_TA_UUID:
      return update(state, s => s.filters.taUuid = action.value);
    
    case Actions.SET_FILTER_SENT:
      return update(state, s => s.filters.sent = action.value);
    
    case Actions.SET_FILTER_COMPANY_UUID:
      return update(state, s => s.filters.companyUuid = action.value);
    
    case Actions.SET_SORT_FIELD:
      return update(state, s => s.sortField = action.value);
    
    case Actions.SET_SORT_DIRECTION:
      return update(state, s => s.sortDirection = action.value);
    
    case Actions.POST_REQUEST:
      return update(state, s => s.request.post = ENetworkRequestStatus.PENDING);
    
    case Actions.POST_SUCCESS:
      return update(state, s => s.request.post = ENetworkRequestStatus.SUCCESS);

    case Actions.POST_FAILURE:
      return update(state, s => s.request.post = ENetworkRequestStatus.ERROR);

    case Actions.SET_SENT:
      return update(state, s => {
        const found = s.draftList?.find(x => x.uuid === action.uuid);
        if(found) {
          found.sent = !!action.value;
          found.date = action.value;
        }
      });

    default:
      return state;
  }
};

export default credentialsInfoReducer;