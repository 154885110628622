import { produce } from 'immer';

import { SelectedAccommodation } from 'services/BackendApi';

import * as Actions from './actions';
import { IRequestSubdomain, requestInitialState } from './model';

const createInitialEditRequestAccommodations = (accommodations: SelectedAccommodation[]) =>
  accommodations.map(accommodation => ({
    startDate: accommodation.startDate,
    endDate: accommodation.endDate,
    guestAges: accommodation.guestAges,
    repeatCustomer: accommodation.repeatCustomer,
    modified: false,
    remove: false,
  }));

export const bookingBuilderRequestReducer = (
  state: IRequestSubdomain = requestInitialState,
  action: Actions.BookingBuilderRequestAction
): IRequestSubdomain => {
  switch (action.type) {
    case Actions.COPY_BOOKING_BUILDER_REQUEST:
      return produce(state, draftState => {
        draftState.request = action.bookingBuilderRequest;
        draftState.request.hotelUuid = action.hotelUuid;
        draftState.editRequestAccommodations = createInitialEditRequestAccommodations(
          action.bookingBuilderRequest.Accommodation
        );

        return draftState;
      });

    case Actions.ADD_ACCOMMODATION: {
      return produce(state, draftState => {
        draftState.editRequestAccommodations.push({
          startDate: draftState.request?.startDate ?? '',
          endDate: draftState.request?.endDate ?? '',
          guestAges: { numberOfAdults: 2, agesOfAllChildren: [] },
          repeatCustomer: false,
          modified: true,
          notSaved: true,
        });

        return draftState;
      });
    }

    case Actions.REMOVE_ACCOMMODATION: {
      return produce(state, draftState => {
        // just remove from array if it is a temporaty not saved accommodation
        if (draftState.editRequestAccommodations[action.accommodationIndex].notSaved) {
          draftState.editRequestAccommodations.splice(action.accommodationIndex, 1);
          return draftState;
        }

        // flag accommodation to remove when saved
        draftState.editRequestAccommodations[action.accommodationIndex].remove = true;
        draftState.editRequestAccommodations[action.accommodationIndex].modified = true;

        return draftState;
      });
    }

    case Actions.EDIT_ACCOMMODATION_DATE: {
      return produce(state, draftState => {
        draftState.editRequestAccommodations[action.accommodationIndex].startDate = action.date[0];
        draftState.editRequestAccommodations[action.accommodationIndex].endDate = action.date[1];
        draftState.editRequestAccommodations[action.accommodationIndex].modified = true;

        return draftState;
      });
    }

    case Actions.EDIT_ACCOMMODATION_REPEAT_GUEST: {
      return produce(state, draftState => {
        draftState.editRequestAccommodations[action.accommodationIndex].repeatCustomer = action.repeatGuest;
        draftState.editRequestAccommodations[action.accommodationIndex].modified = true;

        return draftState;
      });
    }

    case Actions.EDIT_ACCOMMODATION_GUESTS: {
      return produce(state, draftState => {
        switch (action.valueType) {
          case 'ADULT':
            draftState.editRequestAccommodations[action.accommodationIndex].guestAges.numberOfAdults = action.value;
            break;
          case 'CHILDREN':
            if (!draftState.editRequestAccommodations[action.accommodationIndex].guestAges.agesOfAllChildren) {
              draftState.editRequestAccommodations[action.accommodationIndex].guestAges.agesOfAllChildren = [];
            }

            draftState.editRequestAccommodations[action.accommodationIndex].guestAges.agesOfAllChildren.length =
              action.value;
            const fillIndex = draftState.editRequestAccommodations[
              action.accommodationIndex
            ].guestAges.agesOfAllChildren.findIndex(age => age === undefined);
            if (fillIndex > -1) {
              draftState.editRequestAccommodations[action.accommodationIndex].guestAges.agesOfAllChildren.fill(
                0,
                fillIndex
              );
            }

            break;
          case 'CHILDREN_AGE':
            if (action.childrenIndex !== undefined && Array.isArray(draftState.editRequestAccommodations[action.accommodationIndex].guestAges.agesOfAllChildren)) {
              draftState.editRequestAccommodations[action.accommodationIndex].guestAges.agesOfAllChildren[
                action.childrenIndex
              ] = action.value;
            }
            break;
        }

        draftState.editRequestAccommodations[action.accommodationIndex].modified = true;

        return draftState;
      });
    }

    case Actions.SAVE_EDIT_ACCOMMODATIONS: {
      return produce(state, draftState => {
        const accommodationsToRemove: number[] = [];

        draftState.editRequestAccommodations.forEach((accomm, accommIndex) => {
          // just in case we dont have request
          if (!draftState.request) {
            return;
          }

          // skip on not modified accommodations
          if (!accomm.modified) {
            return;
          }

          // save index and skip if we have to remove accommodation
          if (accomm.remove) {
            accommodationsToRemove.push(accommIndex);
            return;
          }

          // if cannot access to Accommodation[accommIndex] it means is a new accommodation
          if (!draftState.request.Accommodation[accommIndex]) {
            draftState.request.Accommodation.push({ guestAges: {} } as SelectedAccommodation);
          }

          // set values in request
          draftState.request.Accommodation[accommIndex].startDate = accomm.startDate;
          draftState.request.Accommodation[accommIndex].endDate = accomm.endDate;
          draftState.request.Accommodation[accommIndex].guestAges.numberOfAdults = accomm.guestAges.numberOfAdults;
          draftState.request.Accommodation[accommIndex].guestAges.agesOfAllChildren =
            accomm.guestAges.agesOfAllChildren;
          draftState.request.Accommodation[accommIndex].repeatCustomer = accomm.repeatCustomer ?? false;

          // reset modified flag
          draftState.editRequestAccommodations[accommIndex].modified = false;

          // reset notSaved flag
          draftState.editRequestAccommodations[accommIndex].notSaved = false;
        });

        // loop over accommodationsToRemove and remove entries from request and editRequestAccommodations to sync
        accommodationsToRemove.forEach((accommodation, index) => {
          draftState.editRequestAccommodations.splice(accommodation - index, 1);
          draftState.request?.Accommodation.splice(accommodation - index, 1);
        });

        return draftState;
      });
    }

    case Actions.RESET_EDIT_ACCOMMODATIONS: {
      return produce(state, draftState => {
        draftState.editRequestAccommodations = createInitialEditRequestAccommodations(
          draftState.request?.Accommodation ?? []
        );

        return draftState;
      });
    }

    default:
      return state;
  }
};
