import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBookingManagerApi, IHeadlineLineItemBreakdown } from 'services/BookingManagerApi';
import * as BreakdownActions from '../actions';
import * as BreakdownSelectors from '../selectors';
import * as BookingStatusOptionsActions from '../../bookingStatusOptions/actions';
import { enqueueNotification } from 'store/modules/ui';

import { bookingUuidSelector } from '../../../selectors';
import { IGetExternalCancellationDataResponse } from 'services/BookingManagerApi/types/ExternalCancellationData';
import { ILiveAccommodationCancellationPolicy, ILiveAccommodationData } from '../model';
import { ICancelBookingResponse } from 'services/BookingManagerApi/types/PostBookingCancel';

export function* getLiveAccommodationDataSaga(
  action: BreakdownActions.GetLiveAccommodationCancellationDataRequestAction
) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    // we need the breakdown data for the rate cents
    const breakdownData: IHeadlineLineItemBreakdown | null = yield select(
      BreakdownSelectors.headlineLineItemBreakdownSelector
    );
    if (!breakdownData) {
      return;
    }

    // once we have a breakdown, make the call
    const result: AxiosResponse<IGetExternalCancellationDataResponse> = yield call(
      bookingManagerApi.getExternalCancellationData,
      bookingUuid
    );

    // format the cancellation policies and the cent amounts into 2 separate objects

    // we need to filter out any live accommodations which AREN'T in the headline breakdown either
    const breakdownAccommodationBookingRefs = breakdownData.Accommodation.items.map(
      a => a.externalBooking?.bookingReference
    );

    const liveAccommodationCancellationPolicies: ILiveAccommodationCancellationPolicy[] = result.data
      .filter(d => breakdownAccommodationBookingRefs.includes(d.bookingReference))
      .map(d => {
        return {
          bookingRef: d.bookingReference,
          cancellationPolicies: [...d.cancellationPolicies],
        };
      });

    const liveAccommodationData: ILiveAccommodationData[] = result.data
      .filter(d => breakdownAccommodationBookingRefs.includes(d.bookingReference))
      .map(d => {
        return {
          bookingRef: d.bookingReference,
          cancellationFeeCents: d.calculatedCancellationFeeCents,
          rateCents:
            breakdownData.Accommodation.items.find(a => a.externalBooking?.bookingReference === d.bookingReference)
              ?.saleCostCents || 0,
        };
      });

    yield put(BreakdownActions.getLiveAccommodationCancellationDataSuccessAction(liveAccommodationData));
    yield put(BreakdownActions.setLiveAccommodationCancellationPoliciesAction(liveAccommodationCancellationPolicies));
  } catch (e) {
    yield put(BreakdownActions.getLiveAccommodationCancellationDataFailureAction());
  }
}

export function* postCancellationRequest(action: BreakdownActions.PostLiveAccommodationCancellationRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const cancelAccommodations = yield select(
      BreakdownSelectors.liveCancellationModalDataForPostBookingEndpointSelector
    );

    const result: AxiosResponse<ICancelBookingResponse> = yield call(bookingManagerApi.postBookingCancel, bookingUuid, {
      accommodations: cancelAccommodations,
    });

    if (result.data.cancellations.some(cr => cr.error)) {
      throw 'Supplier service error when cancelling';
    }

    yield put(BreakdownActions.postLiveAccommodationCancellationSuccessAction());
    yield put(
      enqueueNotification({
        message: 'Accommodation of Live Rate products has been cancelled.',
        options: { variant: 'success' },
      })
    );

    yield put(
      enqueueNotification({
        message: 'Cancelled and Invoice updated.',
        options: { variant: 'success' },
      })
    );

    // refetch all the breakdown versions, now that we've done a cancel
    // this will also fetch the latest breakdown version
    yield put(BreakdownActions.getHeadlineBreakdownVersionListRequestAction());
    // also refetch the booking status options
    yield put(BookingStatusOptionsActions.getBookingStatusOptionsRequestAction());
  } catch (e) {
    yield put(BreakdownActions.postLiveAccommodationCancellationFailureAction());
    yield put(
      enqueueNotification({
        message: 'The accommodation has NOT been cancelled.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchLiveAccommodationCancellation() {
  yield takeLatest([BreakdownActions.GET_HEADLINE_LINE_ITEM_BREAKDOWN_SUCCESS], getLiveAccommodationDataSaga);
  yield takeLatest([BreakdownActions.POST_LIVE_ACCOMMODATION_CANCELLATION_REQUEST], postCancellationRequest);
}
