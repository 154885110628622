import { call, take, takeLatest, select, put, race } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  makeBookingManagerApi,
  IPurchaseCostReviewResponse,
  EPurchaseCostReviewStatus,
} from 'services/BookingManagerApi';
import {
  makeBackendApi,
  EUploadTag,
  IUploadResponse,
} from 'services/BackendApi';
import { enqueueNotification } from 'store/modules/ui';

import {
  GET_PURCHASE_COST_REVIEW_REQUEST,
  GetPurchaseCostReviewRequestAction,
  getPurchaseCostReviewSuccessAction,
  getPurchaseCostReviewFailureAction,
  
  PatchPurchaseCostReviewRequestAction,
  patchPurchaseCostReviewRequestAction,
  patchPurchaseCostReviewSuccessAction,
  patchPurchaseCostReviewFailureAction,
  
  PATCH_PURCHASE_COST_REVIEW_REQUEST,
  PATCH_PURCHASE_COST_REVIEW_FAILURE,
  PATCH_PURCHASE_COST_REVIEW_SUCCESS,
  REQUEST_STATUS_CHANGE,
  RequestStatusChangeAction,

  CONFIRM_STATUS_CHANGE,
  confirmStatusChangeAction,
  ConfirmStatusChangeAction,

  CANCEL_STATUS_CHANGE,
  CancelStatusChangeAction,

  uploadFileRequestAction,
  uploadFileSuccessAction,
  uploadFileFailureAction,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE
} from './actions';

import { requestedStatusSelector } from './selectors';
import { bookingUuidSelector } from  '../../selectors';

export function* getPurchaseCostReviewSaga(action: GetPurchaseCostReviewRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IPurchaseCostReviewResponse> = yield call(
      bookingManagerApi.getPurchaseCostReview,
      bookingUuid
    );

    yield put(getPurchaseCostReviewSuccessAction(result.data));
  } catch (e) {
    yield put(getPurchaseCostReviewFailureAction(e.message));

    yield put(
      enqueueNotification({
        message: 'Failed to fetch purchase cost review',
        options: { variant: 'error' }
      })
    );
  }
}

export function* patchPurchaseCostReviewSaga(action: PatchPurchaseCostReviewRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IPurchaseCostReviewResponse> = yield call(
      bookingManagerApi.patchPurchaseCostReview,
      bookingUuid,
      action.request
    );

    yield put(patchPurchaseCostReviewSuccessAction(result.data));
  } catch (e) {
    yield put(patchPurchaseCostReviewFailureAction(e.message));

    yield put(
      enqueueNotification({
        message: 'Failed to change status of purchase cost review',
        options: { variant: 'error' }
      })
    );
  }
}

export function* requestStatusChangeSaga(action: RequestStatusChangeAction) {
  const whitelist = new Set([
    EPurchaseCostReviewStatus.PENDING,
    EPurchaseCostReviewStatus.CANCELLED
  ]);

  if(!whitelist.has(action.requestedStatus)) {
    return;
  }

  yield put(confirmStatusChangeAction());

  const result = yield take([
    PATCH_PURCHASE_COST_REVIEW_FAILURE,
    PATCH_PURCHASE_COST_REVIEW_SUCCESS
  ]);

  if(result.type === PATCH_PURCHASE_COST_REVIEW_SUCCESS) {
    const statusName = action.requestedStatus === EPurchaseCostReviewStatus.PENDING
      ? 'Pending Review'
      : 'Cancelled';

    yield put(
      enqueueNotification({
        message: `The status of the Hotel Purchase Cost has been moved to ${statusName}`,
        options: { variant: 'success' }
      })
    ); 
  }
}

function* uploadFileSaga(file: File) {
  const bookingUuid = yield select(bookingUuidSelector);

  const formData = new FormData();  
  formData.append('file', file);
  formData.append('tag', EUploadTag.FINANCE_PURCHASE);
  formData.append('displayName', file.name);
  formData.append('ownerType', 'Booking');
  formData.append('ownerUuid', bookingUuid);

  try {
    yield put(uploadFileRequestAction(file));

    const backendApi = makeBackendApi();
    const { data: { data: upload } }: AxiosResponse<IUploadResponse> = yield call(
      backendApi.uploadFile,
      formData
    );
    
    yield put(uploadFileSuccessAction(upload));
    return upload;
  } catch(err) {
    yield put(uploadFileFailureAction(err.message));
  }
}

export function* confirmStatusChangeSaga(action: ConfirmStatusChangeAction) {
  const requestedStatus = yield select(requestedStatusSelector);
  
  const comment =  action.comment
    ? { text: action.comment }
    : undefined;

  const financePurchaseRow = action.financePurchaseRow
    ? { ...action.financePurchaseRow }
    : undefined;

  if(financePurchaseRow && action.file){
    const upload = yield uploadFileSaga(action.file);
    
    if(!upload) {
      yield put(
        enqueueNotification({
          message: 'Failed to upload file',
          options: { variant: 'error' }
        })
      );
      return;
    }

    financePurchaseRow.uploadUuid = upload.uuid;
    financePurchaseRow.uploadUrl =  upload.url;
    financePurchaseRow.uploadName = upload.filename;
  }

  yield put(
    patchPurchaseCostReviewRequestAction({
      status: requestedStatus,
      financePurchaseRow,
      comment
    })
  );
}

export function* cancelStatusChangeSaga(action: CancelStatusChangeAction) {
  const message = action.requestedStatus === EPurchaseCostReviewStatus.APPROVED
    ? 'The Hotel Purchase Cost has not been approved.'
    : 'The Hotel Purchase Cost has not been rejected.';
  
  yield put(
    enqueueNotification({
      message,
      options: { variant: 'warning' }
    })
  );
}

export function* watchBookingManagerPurchaseCostReview() {
  yield takeLatest(GET_PURCHASE_COST_REVIEW_REQUEST, getPurchaseCostReviewSaga);
  yield takeLatest(PATCH_PURCHASE_COST_REVIEW_REQUEST, patchPurchaseCostReviewSaga);
  yield takeLatest(REQUEST_STATUS_CHANGE, requestStatusChangeSaga);
  yield takeLatest(CONFIRM_STATUS_CHANGE, confirmStatusChangeSaga);
  yield takeLatest(CANCEL_STATUS_CHANGE, cancelStatusChangeSaga);
}
