import { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { makeCognitoApi } from 'services/CognitoApi';
import { removeTokensFromLocalStorage } from './tokenLocalStorage';

const errorHandler = (error: AxiosError) => {
  if(error.response?.status === 401) {
    removeTokensFromLocalStorage();
    // Logout in AWS so this browser/device won't be able to use AWS cookie anymore
    const cognitoApi = makeCognitoApi();
    cognitoApi.logout(); // not async
    // Don't redirect as AWS will redirect browser anyway
  }
  throw error;
};

export class AxiosWrapper<T extends AxiosInstance> {
  client: T;
  
  constructor(client: T) {
    this.client = client;
  }
  
  get<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return this.client.get(url, config).catch(errorHandler);
  }
  
  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    return this.client.post(url, data, config).catch(errorHandler);
  }
  
  request<T = any>(config: AxiosRequestConfig): AxiosPromise<T> {
    return this.client.request(config).catch(errorHandler);
  }
  
  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    return this.client.put(url, data, config).catch(errorHandler);
  }
  
  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    return this.client.patch(url, data, config).catch(errorHandler);
  }
  
  head<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return this.client.get(url, config).catch(errorHandler);
  }
  
  delete<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return this.client.delete(url, config).catch(errorHandler);
  }
}