import { useState, useEffect, useCallback } from 'react';

/**
 * A custom hook that provides debounced state updates
 * @param initialValue The initial value
 * @param delay The debounce delay in milliseconds
 * @param onChange Optional callback that fires when the debounced value changes
 * @returns An array containing [currentValue, setCurrentValue, debouncedValue]
 */
export function useDebounce<T>(
  initialValue: T,
  delay: number,
  onChange?: (value: T) => void
): [T, (value: T) => void, T] {
  const [value, setValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      onChange?.(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, onChange]);

  const setValueAndTrigger = useCallback((newValue: T) => {
    setValue(newValue);
  }, []);

  return [value, setValueAndTrigger, debouncedValue];
}
