import React from 'react';

import { IIconProps } from 'ui/CircleIconButton';
import { theme } from '../../../../tailwind.config';

export const TriangleDownIcon: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors.black }) => (
    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0L5 5L10 0H0Z" fill={fill}/>
    </svg>
  )
  ,
);

export default TriangleDownIcon;
    