import { pipe, invoker, multiply, divide, replace, when } from 'ramda';
import { isString } from './helpers';
import { getCurrencySymbol } from './currency';
import { TCurrencyCode } from 'interfaces';

/**
 * Remove commas if string
 *
 * @param {string}
 * @returns {string}
 */
const removeCommasIfString = when(isString, replace(/,/g, ''));

/**
 * Format price
 *
 * @param {string | number} data
 * @param {number} decimals
 * @returns {string}
 */
export const formatPrice = (data, decimals = 2) =>
  pipe(
    removeCommasIfString,
    Number,
    invoker(1, 'toFixed')(decimals),
    replace(/\B(?=(\d{3})+(?!\d))/g, ',') // regex taken from https://stackoverflow.com/a/2901298
  )(data);

/**
 * Calculate percentage
 *
 * @param {string | number} amount
 * @param {string | number} percent
 */
export const calculatePercentage = (amount, percent) =>
  divide(multiply(Number(removeCommasIfString(amount)), Number(percent)), 100);

/**
 * Same as format price, but does some computing first
 *
 * @param {string | number} data
 * @param {number} decimals
 * @returns {string}
 */
export const formatPriceCents = (value, decimals = 2) => {
  value = value / 100;
  return pipe(
    removeCommasIfString,
    Number,
    invoker(1, 'toFixed')(decimals),
    replace(/\B(?=(\d{3})+(?!\d))/g, ',') // regex taken from https://stackoverflow.com/a/2901298
  )(value);
};

export const roundToDecimalPlaces = (value: number, decimalPlaces: number) => {
  // taken, as is tradition, from https://stackoverflow.com/a/32178833
  return Number(Math.round(parseFloat(value + 'e' + decimalPlaces)) + 'e-' + decimalPlaces);
};

export const renderPrice = (currency: TCurrencyCode, total: string, isOnRequest: boolean) => {
  if (isNaN(parseInt(total))) {
    return;
  }

  return isOnRequest ? 'On Request' : `${getCurrencySymbol(currency)}${formatPrice(total)}`;
};

export const formatNumberToShortThousands = (number: number) => {
  if (number > 99999) {
    const truncatedNumber = Math.floor(number / 1000);
    const decimalPart = Math.floor((number % 1000) / 100);
    const result =
      decimalPart === 0
        ? truncatedNumber.toString() + 'K'
        : truncatedNumber.toString() + '.' + decimalPart.toString() + 'K';
    return result;
  }
  return number.toString();
};
