import React from 'react';
import { Link } from 'ui/Link'

import { IProposalBooking } from 'store/modules/proposalDetails/types';
import { formatPriceCents, getCurrencySymbol } from 'utils';
import { EProductLineDisplayMode } from './ProductLineWithPrice';
import { ProposalAccommodation } from './ProposalAccommodation';
import { ProposalNonAccommodationProduct } from './ProposalNonAccommodationProduct';

interface IProposalMoreInfoProps {
  booking: IProposalBooking;
}

export const ProposalMoreInfo: React.FC<IProposalMoreInfoProps> = React.memo(({ booking  }) => {
  const profitValue = `${getCurrencySymbol(booking.currency)}${formatPriceCents(booking.totals.marginCents)}`;
  const profitPercentage = booking.totals.marginPercentage;

  return (
    <div className="proposal-more-info mt-5 bg-white">
      {booking.Accommodation.map((accommodation, index) => (
        <ProposalAccommodation
          // bms does not return accommodation uuid
          key={index}
          accommodation={accommodation}
          currencyCode={booking.currency}
        />
      ))}

      {booking.Transfer.length > 0 && (
        <ProposalNonAccommodationProduct
          title="Transfers"
          products={booking.Transfer}
          currencyCode={booking.currency}
          displayMode={EProductLineDisplayMode.TWO_LINES}
          className="transfer"
        />
      )}

      {booking['Ground Service'].length > 0 && (
        <ProposalNonAccommodationProduct
          title="Ground Services"
          products={booking['Ground Service']}
          currencyCode={booking.currency}
          displayMode={EProductLineDisplayMode.ONE_LINE}
          className="ground-service"
        />
      )}

      {booking.Fine.length > 0 && (
        <ProposalNonAccommodationProduct
          title="Fines"
          products={booking.Fine}
          currencyCode={booking.currency}
          displayMode={EProductLineDisplayMode.ONE_LINE}
          className="fines"
        />
      )}

      {booking.Supplement.length > 0 && (
        <ProposalNonAccommodationProduct
          title="Other Items"
          products={booking.Supplement}
          currencyCode={booking.currency}
          displayMode={EProductLineDisplayMode.ONE_LINE}
          className="other-items"
        />
      )}

      <div className="margin-row flex justify-between items-center h-16 px-5">
        <p className="font-hurmegeometric-sans text-13px leading-16px text-flint m-0">
          Current margin will be <span className="font-bold">{profitValue}</span> ({profitPercentage}% of the total cost)
        </p>
        <Link 
          to={`/booking-manager/${booking.uuid}/overview`} 
          target="_blank"
          className="view-booking inline-block px-15px py-9px bg-brown-100"
        >
          <span className="font-hurmegeometric-sans text-sm leading-17px font-normal text-white uppercase">
            View Booking
          </span>
        </Link>
      </div>
    </div>
  );
});
