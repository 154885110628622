import { initialState, IUploadsSubdomain } from './model';
import * as Actions from './actions';
import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';

const uploadsReducer = (state: IUploadsSubdomain = initialState, action: Actions.UploadsAction) => {
  switch (action.type) {
    case Actions.GET_UPLOADS_REQUEST:
      return produce(state, draftState => {
        draftState.uploads.request = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_UPLOADS_SUCCESS:
      return produce(state, draftState => {
        draftState.uploads.request = ENetworkRequestStatus.SUCCESS;
        draftState.uploads.list = action.uploads;
        draftState.canDeleteList = action.canDeleteList;
        return draftState;
      });

    case Actions.GET_UPLOADS_FAILURE:
      return produce(state, draftState => {
        draftState.uploads.request = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.NEW_UPLOAD_REQUEST:
      return produce(state, draftState => {
        draftState.newUpload.request = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.NEW_UPLOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.newUpload.request = ENetworkRequestStatus.SUCCESS;
        draftState.newUpload.payload = null;
        draftState.newUpload.error = null;
        return draftState;
      });

    case Actions.NEW_UPLOAD_FAILURE:
      return produce(state, draftState => {
        draftState.newUpload.request = ENetworkRequestStatus.ERROR;
        draftState.newUpload.error = action.error;
        return draftState;
      });

    case Actions.OPEN_UPLOAD_FORM:
      return produce(state, draftState => {
        draftState.newUpload.payload = {
          file: null,
          displayName: null,
          tag: null,
        };
        draftState.newUpload.privacyAck = false;
        return draftState;
      });

    case Actions.CLOSE_UPLOAD_FORM:
      return produce(state, draftState => {
        draftState.newUpload.payload = null;
        return draftState;
      });

    case Actions.SET_UPLOAD_FILE:
      return produce(state, draftState => {
        if (!draftState.newUpload.payload) {
          return;
        }

        draftState.newUpload.payload.file = action.file;
        draftState.newUpload.payload.displayName = action.file.name;
        return draftState;
      });

    case Actions.SET_UPLOAD_DISPLAY_NAME:
      return produce(state, draftState => {
        if (!draftState.newUpload.payload) {
          return;
        }
        draftState.newUpload.payload.displayName = action.displayName;
        return draftState;
      });

    case Actions.SET_UPLOAD_TAG:
      return produce(state, draftState => {
        if (!draftState.newUpload.payload) {
          return;
        }
        draftState.newUpload.payload.tag = action.tag;
        return draftState;
      });

    case Actions.SET_PRIVACY_ACK:
      return produce(state, draftState => {
        draftState.newUpload.privacyAck = action.privacyAck;
        return draftState;
      });

    case Actions.DELETE_UPLOAD_REQUEST:
      return produce(state, draftState => {
        draftState.deleteUpload.request = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.DELETE_UPLOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.deleteUpload.request = ENetworkRequestStatus.SUCCESS;
        draftState.deleteUpload.uuid = null;
        return draftState;
      });

    case Actions.DELETE_UPLOAD_FAILURE:
      return produce(state, draftState => {
        draftState.deleteUpload.request = ENetworkRequestStatus.ERROR;
        draftState.deleteUpload.uuid = null;
        return draftState;
      });

    case Actions.SET_DELETE_UPLOAD:
      return produce(state, draftState => {
        draftState.deleteUpload.uuid = action.uuid;
        draftState.deleteUpload.request = ENetworkRequestStatus.IDLE;
        return draftState;
      });

    case Actions.CANCEL_DELETE_UPLOAD:
      return produce(state, draftState => {
        draftState.deleteUpload.uuid = null;
        return draftState;
      });

    default:
      return state;
  }
};

export default uploadsReducer;
