import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FluidButton from 'ui/FluidButton';
import {
  AnonymousAvatarComponent as AnonymousAvatarIcon,
} from 'ui/Icons';
import { theme } from '../../../../tailwind.config';
import { createTaskCommentRequestAction } from 'store/modules/taskManagement/actions';
import { commentSavingSelector, mentionListSelector } from 'store/modules/taskManagement/selectors';
import { ENetworkRequestStatus } from 'services/BackendApi';
import Editor, { IMentionOptions } from 'pureUi/Editor';

interface ITaskCommentEditProps {
  commentId: string | null;
  onCancel: () => void;
  taskId: string;
}

export const TaskCommentEdit: React.FC<ITaskCommentEditProps> = ({ taskId, commentId, onCancel }) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const [internalMentionList, setInternalMentionList] = useState<IMentionOptions[]>([]);
  const commentSavingStatus = useSelector(commentSavingSelector);
  const mentionList = useSelector(mentionListSelector);
  const isSaving = commentSavingStatus === ENetworkRequestStatus.PENDING;
  const isCommentEmpty = comment.length === 0;

  const handleCancelEdit = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleConfirmEdit = useCallback(() => {
    dispatch(createTaskCommentRequestAction(taskId, comment, commentId));
  }, [dispatch, comment, taskId, commentId]);

  const handleEditorChange = useCallback((content, editor) => {
    setComment(content);
  }, []);

  useEffect(() => {
    const users = mentionList.map(user => ({name: `${user.firstName} ${user.lastName}`, email: user.email, role: user.type, id: user.uuid}))
    setInternalMentionList(users);
  }, [mentionList])
  return (
    <div className="edit-comment flex w-full items-start gap-[10px]">
      <div className="user-picture">
        <AnonymousAvatarIcon icon={theme.colors['gray-100']} fill={theme.colors['gray-40']} />
      </div>
      <div className="comment-container flex-1">
        <Editor text={comment} handleEditorChange={handleEditorChange} options={internalMentionList} placeHolder='Type @ to mention and notify someone.'/>
        <div className="buttons flex gap-[10px] mt-[10px]">
          <FluidButton type="secondary" onClick={handleCancelEdit} disabled={isSaving}>
            Cancel
          </FluidButton>
          <FluidButton type="primary" onClick={handleConfirmEdit} isLoading={isSaving} disabled={isSaving || isCommentEmpty}>
            Save
          </FluidButton>
        </div>
      </div>
    </div>
  )
};
