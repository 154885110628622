import { Upload } from 'services/BackendApi';

export const GET_DOWNLOADS_REQUEST = 'bookingManager/GET_DOWNLOADS_REQUEST';
export const GET_DOWNLOADS_SUCCESS = 'bookingManager/GET_DOWNLOADS_SUCCESS';
export const GET_DOWNLOADS_FAILURE = 'bookingManager/GET_DOWNLOADS_FAILURE';


export type GetDownloadsRequestAction = ReturnType<typeof getDownloadsRequestAction>;
export const getDownloadsRequestAction = () => ({
  type: GET_DOWNLOADS_REQUEST as typeof GET_DOWNLOADS_REQUEST
});

export type GetDownloadsSuccessAction = ReturnType<typeof getDownloadsSuccessAction>;
export const getDownloadsSuccessAction = (downloads: Upload[]) => ({
  type: GET_DOWNLOADS_SUCCESS as typeof GET_DOWNLOADS_SUCCESS,
  downloads,
});

export type GetDownloadsFailureAction = ReturnType<typeof getDownloadsFailureAction>;
export const getDownloadsFailureAction = (error: string) => ({
  type: GET_DOWNLOADS_FAILURE as typeof GET_DOWNLOADS_FAILURE,
  error,
});

export type DownloadsAction =
  | GetDownloadsRequestAction
  | GetDownloadsSuccessAction
  | GetDownloadsFailureAction;
