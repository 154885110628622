import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'ui/Link';
import classNames from 'classnames';

import { ENetworkRequestStatus } from 'services/BackendApi';
import {
  checkDepositInitialBCFRequestAction,
  closeDepositAccountModalAction,
  depositAccountRequestAction,
  depositAccountResetAction,
  depositAccountSetItemsPerPageAction,
  depositAccountSetPageNumberAction,
  saveDepositRowRequestAction,
  selectCompanyAction,
  openDepositAccountModalAction,
  depositAccountPostExportRequestAction,
  depositAccountGeneratePdfRequestAction,
  depositAccountDownloadPdfHistoryRequestAction,
  deleteDepositRowRequestAction,
  setDepositAccountRowToDeleteAction,
  setDARDeleteModalOpenAction,
} from 'store/modules/ledger/actions';
import {
  depositAccountDataSelector,
  depositAccountModalSelector,
  depositAccountPaginationSelector,
  depositAccountRequestsSelector,
  depositAccountCsvExportLoading,
  depositAccountPdfGenerationInProgress,
  depositAccountDownloadHistoryInProgress,
  depositAccountDownloadedPdfs,
  isDeleteDARModalOpenSelector,
  depositAccountFilterSelector,
} from 'store/modules/ledger/selectors';
import { companiesSelector } from 'store/modules/agents/selectors';
import { isAdmin as isAdminSelector, isFinanceUser as isFinanceUserSelector } from 'store/modules/auth/selectors';

import { Controls } from 'ui/Ledger';

import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { getCompaniesRequestAction } from 'store/modules/agents/actions';
import PiggyBankIcon from 'ui/Icons/piggyBank.component.svg';
import { DepositAccountTable } from 'ui/Ledger/DepositAccountTable';

import { HidingTooltip } from 'ui/Tooltip';
import UsdIcon from 'ui/Icons/currency.usd.component.svg';
import EurIcon from 'ui/Icons/currency.eur.component.svg';
import { delay, formatPriceCents, getCurrencySymbol } from 'utils';
import { DOWNLOAD_PDF_HISTORY_ANIMATION_DURATION, PdfDownloadHistory } from './PdfDownloadHistory';
import { theme } from '../../../tailwind.config';
import { TCurrencyCode } from 'interfaces';
import { StandardModal } from '../../pureUi/Modal';
import { IDepositAccountRowForSave } from 'store/modules/ledger/model';
import { DepositAccountModal } from 'ui/DepositAccountModal';
import { IDepositAccountRow } from 'services/BookingManagerApi';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';
import SingleDateInput from 'pureUi/SingleDateInput';
import {
  depositFilterSetFromDateAction,
  depositFilterSetToDateAction,
  depositFilterUpdateResultsAction,
} from 'store/modules/ledger/actions';

import { depositFilterFromDateSelector, depositFilterToDateSelector } from 'store/modules/ledger/selectors';

import { formatDate } from 'utils';
import FluidButton from 'ui/FluidButton';
import { CsvExportButton } from 'ui/Ledger/Controls';
import { DepositAccountFilter } from './AllTransactions/DepositAccountFilter';
import { SvgIcon } from 'ui/SvgIcon';

interface IDepositLedgerRouteParams {
  companyUuid: string;
  currency: string; // not TCurrencyCode, as this a URL param
}

export const DepositAccount: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const match = useRouteMatch<IDepositLedgerRouteParams>();

  const companyUuid = match.params.companyUuid;
  const currencyCode = match.params.currency.toUpperCase() as TCurrencyCode;

  const isAdmin = useSelector(isAdminSelector);
  const isFinanceUser = useSelector(isFinanceUserSelector);

  const data = useSelector(depositAccountDataSelector);
  const requests = useSelector(depositAccountRequestsSelector);
  const pagination = useSelector(depositAccountPaginationSelector);
  const companies = useSelector(companiesSelector);
  const csvExportLoading = useSelector(depositAccountCsvExportLoading);
  const pdfGenerationInProgress = useSelector(depositAccountPdfGenerationInProgress);
  const downloadHistoryInProgress = useSelector(depositAccountDownloadHistoryInProgress);
  const downloadHistoryPdfs = useSelector(depositAccountDownloadedPdfs);
  const isDeleteDARModalOpen = useSelector(isDeleteDARModalOpenSelector);
  const depositAccountFilterData = useSelector(depositAccountFilterSelector);

  const [isDownloadPdfHistoryDetailOpen, setIsDownloadPdfHistoryDetailOpen] = useState(false);
  const [isFiltersDetailOpen, setIsFiltersDetailOpen] = useState(false);

  const toggleDownloadPdfHistory = useCallback(async (isOpen: boolean) => {
    setIsDownloadPdfHistoryDetailOpen(isOpen);
  }, []);

  const { isDepositAccountModalOpen, requests: depositAccountModalRequests } = useSelector(depositAccountModalSelector);
  const creatingDepositAccountRowInProgress =
    depositAccountModalRequests.bcfRowCreate === ENetworkRequestStatus.PENDING;

  const handleCloseDepositAccountModal = useCallback(() => {
    dispatch(closeDepositAccountModalAction());
  }, []);

  const handleSaveDepositAccountRow = useCallback((bcfRowWithoutUpload: IDepositAccountRowForSave, files: File[]) => {
    dispatch(saveDepositRowRequestAction(bcfRowWithoutUpload, files));
  }, []);

  useEffect(() => {
    dispatch(getCompaniesRequestAction());
    dispatch(selectCompanyAction(companyUuid));
    dispatch(depositAccountRequestAction(currencyCode));
    dispatch(depositAccountDownloadPdfHistoryRequestAction(companyUuid, currencyCode));

    return () => {
      dispatch(depositAccountResetAction());
    };
  }, []);

  const selectedCompanyName = useMemo(() => companies?.find(company => company.uuid === companyUuid)?.name, [
    companies,
    companyUuid,
  ]);

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      dispatch(depositAccountSetPageNumberAction(pageNumber - 1));
    },
    [dispatch]
  );

  const handleItemsPerPageChange = useCallback(
    (itemsPerPage: number) => {
      dispatch(depositAccountSetItemsPerPageAction(itemsPerPage));
    },
    [dispatch]
  );

  const toggleFilters = useCallback(
    async (isOpen: boolean) => {
      if (isDownloadPdfHistoryDetailOpen) {
        setIsDownloadPdfHistoryDetailOpen(false);
        await delay(DOWNLOAD_PDF_HISTORY_ANIMATION_DURATION);
      }
      setIsFiltersDetailOpen(isOpen);
    },
    [isDownloadPdfHistoryDetailOpen]
  );

  const filters = useMemo(() => ({ handler: toggleFilters, isOpen: isFiltersDetailOpen }), [
    toggleFilters,
    isFiltersDetailOpen,
  ]);

  const openDepositAccountModal = useCallback((rowEdited?: IDepositAccountRow) => {
    if (rowEdited) {
      dispatch(openDepositAccountModalAction(rowEdited));
    }
    dispatch(checkDepositInitialBCFRequestAction(rowEdited));
  }, []);

  const handleAddDepositRow = useCallback(() => {
    openDepositAccountModal();
  }, []);

  const handleEditDepositRow = useCallback((rowEdited: IDepositAccountRow) => {
    openDepositAccountModal(rowEdited);
  }, []);

  const handleDeleteDepositRow = useCallback(() => {
    dispatch(deleteDepositRowRequestAction());
  }, []);

  const openDeleteDARModal = useCallback((row: IDepositAccountRow) => {
    dispatch(setDepositAccountRowToDeleteAction(row?.uuid ?? null));
    dispatch(setDARDeleteModalOpenAction(true));
  }, []);

  const closeDeleteDARModal = useCallback(() => {
    dispatch(setDARDeleteModalOpenAction(false));
    dispatch(setDepositAccountRowToDeleteAction(null));
  }, []);

  const handleCsvExport = useCallback(() => {
    dispatch(depositAccountPostExportRequestAction());
  }, []);

  const handleGeneratePdf = useCallback(() => {
    dispatch(depositAccountGeneratePdfRequestAction());
  }, []);

  const renderCurrencyIcon = useCallback(() => {
    switch (currencyCode) {
      case 'USD':
        return <SvgIcon IconComponent={UsdIcon} className="w-30px h-30px fill-none" />;
      case 'EUR':
        return <SvgIcon IconComponent={EurIcon} className="w-30px h-30px fill-none" />;

      default:
        return null;
    }
  }, [currencyCode]);

  const formatAmount = amount => {
    const symbol = getCurrencySymbol(currencyCode);
    return `${symbol}${formatPriceCents(amount)}`;
  };

  const csvExport = useMemo(
    () => (isAdmin || isFinanceUser ? [{ handler: handleCsvExport, loading: csvExportLoading }] : undefined),
    [isAdmin, isFinanceUser, csvExportLoading]
  );

  const generatePdf = useMemo(() => ({ handler: handleGeneratePdf, loading: pdfGenerationInProgress }), [
    pdfGenerationInProgress,
  ]);

  const downloadHistory = useMemo(
    () => ({ handler: toggleDownloadPdfHistory, isOpen: isDownloadPdfHistoryDetailOpen }),
    [toggleDownloadPdfHistory, isDownloadPdfHistoryDetailOpen]
  );

  const userCanAddRows = useMemo(() => isAdmin || isFinanceUser, [isAdmin, isFinanceUser]);

  const getDepositAccountTooltipContent = useCallback((amount: number) => {
    return amount > 0 ? (
      <p className="m-0">
        The TA <span className="text-brown-100">owes money</span>.
      </p>
    ) : (
      <p className="m-0">
        The TA has <span className="text-brown-100">a credit.</span>
      </p>
    );
  }, []);

  const depositFilterFromDate = useSelector(depositFilterFromDateSelector);
  const depositFilterToDate = useSelector(depositFilterToDateSelector);

  const Classes = useMemo(
    () => ({
      column: 'flex flex-col px-30px justify-center',
      columnBorder: 'border-r border-solid border-r-gray-20',
      columnLabel: 'text-xs text-gray-100 tracking-2xs uppercase',
      columnValue: 'text-21px font-bold mt-5px',
      currencyValue: 'flex text-center font-bold mt-2px m-auto',
    }),
    []
  );

  return (
    <div className="deposit-ledger-container container mx-auto max-w-1280px font-pt-sans text-black">
      <Link to="/statement">
        <span className="inline-block cursor-pointer font-bold mb-10px underline text-brown-100">
          Back to statement summary
        </span>
      </Link>

      <div className="flex justify-between mb-15px">
        <h1 className="booking-ledger-title-currency font-noe-display font-normal text-4xl m-0">
          {currencyCode} Deposit Account
        </h1>
        <div className="flex"></div>
      </div>

      <div
        className={`deposit-account-summary bg-ivory justify-between flex w-full h-20 py-10px shadow-pe4 text-black items-center mb-35px`}
      >
        <h2 className="w-2/5 font-noe-display font-normal text-black text-26px m-0 ml-25px">{selectedCompanyName}</h2>
        <div className="flex justify-around w-3/5 items-center">
          {/* currency icon */}
          <div className={`deposit-account-summary-currency-icon ${Classes.column} ${Classes.columnBorder}`}>
            <span className={Classes.columnLabel}>Currency</span>
            <span className={Classes.currencyValue}>{renderCurrencyIcon()}</span>
          </div>

          {/* deposit in */}
          <div className={`deposit-account-summary-deposit-in text-right ${Classes.column} ${Classes.columnBorder}`}>
            <span className={Classes.columnLabel}>Deposit In</span>
            <HidingTooltip
              renderTooltipContent={() => (
                <div style={{ maxWidth: '170px' }}>
                  Total of Transfers to Increase the <span className="text-brown-100">DEPOSIT BALANCE</span>.
                </div>
              )}
              position="bottom"
              tooltipClassname="p-2 mt-2 text-left"
            >
              <div className={`flex w-full justify-end items-center ${Classes.columnValue}`}>
                {requests.rowsLoad === ENetworkRequestStatus.PENDING && (
                  <i className="fas fa-circle-notch fa-spin text-brown-140"></i>
                )}
                {requests.rowsLoad === ENetworkRequestStatus.SUCCESS && (
                  <React.Fragment>
                    <PiggyBankIcon fill={theme.colors['black']} />
                    {formatAmount(data?.negativeTotal || 0)}
                  </React.Fragment>
                )}
              </div>
            </HidingTooltip>
          </div>

          {/* deposit out */}
          <div className={`deposit-account-summary-deposit-out text-right ${Classes.column} ${Classes.columnBorder}`}>
            <span className={Classes.columnLabel}>Deposit Out</span>
            <HidingTooltip
              renderTooltipContent={() => (
                <div style={{ maxWidth: '170px' }}>
                  Total of Transfers to Reduce the <span className="text-brown-100">DEPOSIT BALANCE</span>.
                </div>
              )}
              position="bottom"
              tooltipClassname="p-2 mt-2 text-left"
            >
              <span className={`flex w-full justify-end items-center ${Classes.columnValue}`}>
                {requests.rowsLoad === ENetworkRequestStatus.PENDING && (
                  <i className="fas fa-circle-notch fa-spin text-brown-140"></i>
                )}
                {requests.rowsLoad === ENetworkRequestStatus.SUCCESS && (
                  <React.Fragment>
                    <PiggyBankIcon fill={theme.colors['black']} />
                    {formatAmount(data?.positiveTotal || 0)}
                  </React.Fragment>
                )}
              </span>
            </HidingTooltip>
          </div>

          {/* deposit balance */}
          <div className={`deposit-account-summary-deposit-balance text-right ${Classes.column} `}>
            <span className={Classes.columnLabel}>Deposit Balance</span>
            <span className={`flex items-center text-teal-100 ${Classes.columnValue}`}>
              {requests.rowsLoad === ENetworkRequestStatus.PENDING && (
                <i className="fas fa-circle-notch fa-spin text-teal-100"></i>
              )}
              {requests.rowsLoad === ENetworkRequestStatus.SUCCESS && (
                <React.Fragment>
                  <PiggyBankIcon fill={theme.colors['black']} />
                  <HidingTextTooltip
                    width="102px"
                    showShadow={false}
                    tooltipContent={getDepositAccountTooltipContent(data?.netTotal || 0)}
                    position="bottom"
                  >
                    <span>{formatAmount(data?.netTotal || 0)}</span>
                  </HidingTextTooltip>
                </React.Fragment>
              )}
            </span>
          </div>
        </div>
      </div>

      {requests.rowsLoad === ENetworkRequestStatus.PENDING && <LoadingBar />}
      {requests.rowsLoad === ENetworkRequestStatus.ERROR && <ErrorBar />}
      {requests.rowsLoad === ENetworkRequestStatus.SUCCESS && data && (
        <>
          <div className="start-and-end-date flex flex-row mb-15px items-end space-x-[20px]">
            <div className="start-date-input">
              <div className="flex flex-row justify-between text-13px pb-5px font-pt-sans">
                <span className="block">Start Date</span>
              </div>
              <SingleDateInput
                value={depositFilterFromDate !== null ? new Date(depositFilterFromDate) : null}
                onChange={value => {
                  dispatch(depositFilterSetFromDateAction(formatDate(value)));
                }}
                showYearDropdown
                enablePastDates={true}
                internalClassName={classNames('from-date', {
                  dateInputField: true,
                  dateNotSelected: depositFilterFromDate === null,
                })}
              />
            </div>

            <div className="end-date-input">
              <div className="flex flex-row justify-between text-13px pb-5px font-pt-sans">
                <span className="block">End Date</span>
              </div>
              <SingleDateInput
                value={depositFilterToDate !== null ? new Date(depositFilterToDate) : null}
                onChange={value => {
                  dispatch(depositFilterSetToDateAction(formatDate(value)));
                }}
                showYearDropdown
                enablePastDates={true}
                internalClassName={classNames('to-date', {
                  dateInputField: true,
                  dateNotSelected: depositFilterToDate === null,
                })}
              />
            </div>

            {generatePdf && (
              <FluidButton
                className="pdf-btn"
                type={'primary'}
                isLoading={generatePdf.loading}
                disabled={
                  generatePdf.loading ||
                  (depositAccountFilterData?.status !== undefined && depositAccountFilterData?.status.length > 0) ||
                  depositAccountFilterData?.humanReadableIds !== undefined
                }
                onClick={generatePdf.handler}
              >
                Download PDF Statement
              </FluidButton>
            )}
          </div>

          <Controls
            className="!px-0"
            filters={filters}
            // set as undefined because we don't want the Controls to render these buttons anymore,
            // but we _do_ want the rest of the Controls to render
            csvExport={undefined}
            generatePdf={undefined}
            downloadHistory={downloadHistory}
          />

          <DepositAccountFilter className="!mx-0" isOpen={isFiltersDetailOpen} />

          <PdfDownloadHistory
            pdfs={downloadHistoryPdfs}
            loading={downloadHistoryInProgress}
            isOpen={isDownloadPdfHistoryDetailOpen}
          />

          <div className="flex flex-row mb-20px space-x-10px">
            <FluidButton
              className="search"
              type="primary"
              onClick={() => {
                dispatch(depositFilterUpdateResultsAction());
              }}
            >
              Search
            </FluidButton>

            {csvExport && <CsvExportButton type={'secondary'} actions={csvExport} />}
          </div>

          <DepositAccountTable
            depositAccountRows={data.rows}
            balanceCarriedForward={data.balanceCarriedForward}
            currency={currencyCode}
            isModalLoading={depositAccountModalRequests.initialBCFRowLoad === ENetworkRequestStatus.PENDING}
            handleAddDepositRow={handleAddDepositRow}
            handleEditDepositRow={handleEditDepositRow}
            handleDeleteDepositRow={openDeleteDARModal}
            pagination={pagination}
            handlePageChange={handlePageChange}
            handleItemsPerPageChange={handleItemsPerPageChange}
            userCanAddRows={userCanAddRows}
          />
        </>
      )}

      {isDepositAccountModalOpen && (
        <StandardModal
          className="deposit-account-modal font-pt-sans"
          frameClassName="w-940px px-35px pt-25px pb-30px border border-solid"
          showCloseButton={true}
          removePadding={true}
          closeOnOutsideClick={false}
          onClose={handleCloseDepositAccountModal}
        >
          <DepositAccountModal
            isSubmitButtonLoading={creatingDepositAccountRowInProgress}
            depositAccountBalance={formatAmount(data?.netTotal || 0)}
            depositAccountBalanceLoadingStatus={requests.rowsLoad}
            isModalLoading={depositAccountModalRequests.initialBCFRowLoad === ENetworkRequestStatus.PENDING}
            onSubmit={handleSaveDepositAccountRow}
            onClose={handleCloseDepositAccountModal}
            selectedCompanyName={selectedCompanyName || ''}
          />
        </StandardModal>
      )}

      {isDeleteDARModalOpen && (
        <ConfirmationModal
          className="deposit-account-row-delete-warning"
          type={EConfirmationModalType.WARNING}
          isOpen
          title="Are you sure you want to delete this row?"
          message="This change CANNOT be undone"
          confirmButtonLabel="Delete"
          cancelButtonLabel="Cancel"
          isConfirmLoading={requests.rowDelete === ENetworkRequestStatus.PENDING}
          onConfirm={handleDeleteDepositRow}
          onCancel={closeDeleteDARModal}
        />
      )}
    </div>
  );
});
