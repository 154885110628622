import React, { FC, HTMLProps, useCallback, useMemo } from 'react';
import { isNil } from 'lodash-es';
import { formatDateDisplay, formatPriceCents } from 'utils';
import {
  EFinanceTableTypes,
  IFinanceDocument,
  IFinanceDocumentBalance,
} from 'store/modules/bookingManager/subdomains/finance/types';
import {
  isNegativeFinanceRow,
  isPositiveFinanceRow,
  isAutomaticFinanceRow,
} from 'store/modules/bookingManager/subdomains/finance/utils';
import { IFinanceRow, EPurchaseCostReviewStatus, EFinanceRowTypes } from 'services/BookingManagerApi/types';
import { EBankAccount } from 'interfaces';
import classNames from 'classnames';

import { FixedTooltip, HidingTooltip, InvoiceDueDatesSummary } from 'ui/Tooltip';
import { CircleIconButton } from 'ui/CircleIconButton';
import { bank } from 'ui/Icons';
import { PurchaseCostReviewIndicator } from './PurchaseCostReviewIndicator';
import { CreditNoteIndicator } from './CreditNoteIndicator';
import { getFinanceRowTypeDisplayName } from 'ui/FinanceTableModal/type_options';

export interface IPurchaseCostReview {
  status: EPurchaseCostReviewStatus;
  financePurchaseRow?: IFinanceRow | null;
}

export interface IFinanceTableProps {
  financeTableType: EFinanceTableTypes;
  financeDocument: IFinanceDocument;
  financeDocumentBalance: IFinanceDocumentBalance;
  currencySymbol: string;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
  canEdit: boolean;
  canDelete: boolean;
  purchaseCostReview?: IPurchaseCostReview | null;
}

// teal-20 for sales
// blue-10 for purchase

const pcrCellClasses = {
  [EPurchaseCostReviewStatus.PENDING]: 'bg-yellow-20',
  [EPurchaseCostReviewStatus.APPROVED]: 'bg-green-25',
  [EPurchaseCostReviewStatus.REJECTED]: 'bg-red-25',
};

const invoiceDueDatesSpec = {
  [EFinanceRowTypes.Automatic_Proforma_to_Final_Client]: {
    title: 'Proforma Invoice',
    showTotal: true,
    showOutstandingBalance: true,
  },
  [EFinanceRowTypes.Automatic_Proforma_to_Travel_Agent]: {
    title: 'Proforma Invoice',
    showTotal: true,
    showOutstandingBalance: true,
  },
  [EFinanceRowTypes.Automatic_Cancellation_Fee]: {
    title: 'Automatic Cancellation Fee',
  },
  [EFinanceRowTypes.Automatic_Cancellation_Fee_Live]: {
    title: 'Automatic Cancellation Fee (Live)',
  },
};

const renderFinanceRowType = (type: EFinanceRowTypes) => {
  let returnString: string = getFinanceRowTypeDisplayName(type);

  // swap out "Agent" for "Partner" in re. to travel partners
  returnString = returnString.replace(/Travel Agent/g, 'Travel Partner');

  return returnString;
};

export const FinanceTable = (props: IFinanceTableProps) => {
  const { financeTableType, purchaseCostReview } = props;
  const { rows } = props.financeDocument;

  const pcrRowId = purchaseCostReview?.financePurchaseRow?.uuid;
  const pcrStatus = purchaseCostReview?.status as EPurchaseCostReviewStatus;

  const renderPaymentMethodLabel = useCallback(
    (bankAccount: EBankAccount) => () => (
      <span className="font-pt-sans font-normal flex text-black text-13px leading-17px text-center p-1 whitespace-nowrap">
        {bankAccount}
      </span>
    ),
    []
  );

  return (
    <div>
      <table className="finance-table border-collapse w-full table-fixed font-pt-sans">
        <thead>
          <tr className="border border-solid border-gray-40">
            <th className="uppercase text-left font-normal text-gray-100 text-xs p-2 w-85px">Entry Date</th>
            <th className="uppercase text-left font-normal text-gray-100 text-xs p-2 w-85px">Value Date</th>
            <th className="uppercase text-left font-normal text-gray-100 text-xs p-2 w-180px">Type</th>
            <th className="uppercase text-left font-normal text-gray-100 text-xs p-2">Description</th>
            <th
              colSpan={2}
              className="uppercase border border-solid border-gray-40 border-l-0 border-r-0 text-center font-normal text-gray-100 text-xs p-2 w-1/4"
            >
              Amount
            </th>
            <th colSpan={3} className="text-left font-normal text-gray-100 text-xs p-1 align-top w-1/5">
              {/* blank - this is the CTA column */}
            </th>
          </tr>
        </thead>

        {rows.length <= 0 && (
          <tbody>
            <tr>
              <td colSpan={9} className="border border-solid border-gray-40 p-2">
                <span className="italic text-gray-80">No rows</span>
              </td>
            </tr>
          </tbody>
        )}

        {rows.length >= 1 && (
          <React.Fragment>
            <tbody>
              {rows.map((fr, frIndex) => {
                const isPcrRow = !isNil(pcrRowId) && pcrRowId === fr.uuid;
                const isCreditNote = [
                  EFinanceRowTypes.Credit_Note_For_Guest_In_Negative,
                  EFinanceRowTypes.Credit_Note_For_Guest_Out_Positive,
                  EFinanceRowTypes.Credit_Note_For_Travel_Partner_In_Negative,
                  EFinanceRowTypes.Credit_Note_For_Travel_Partner_Out_Positive,
                  EFinanceRowTypes.Credit_Note_From_Hotel_Negative,
                  EFinanceRowTypes.Credit_Note_From_Hotel_Used_Positive,
                  EFinanceRowTypes.Credit_Note_From_Non_Hotel_Supplier_Negative,
                  EFinanceRowTypes.Credit_Note_From_Non_Hotel_Supplier_Used_Positive,
                ].includes(fr.rowType);

                return (
                  <tr key={frIndex} className={`finance-row index-${frIndex}`}>
                    {/* entry date */}
                    <td className={`border border-solid border-gray-40 p-2 border-r-0 `}>
                      <span className="finance-row-date text-15px">
                        {fr.entryDate ? formatDateDisplay(fr.entryDate) : null}
                      </span>
                    </td>

                    {/* value date */}
                    <td className={`border border-solid border-gray-40 p-2 border-r-0 border-l-0`}>
                      <span className="finance-row-date text-15px">{formatDateDisplay(fr.date)}</span>
                    </td>

                    {/* row type */}
                    <td className={`border border-solid border-gray-40 p-2 border-r-0 border-l-0`}>
                      <span className="finance-row-date text-15px">{renderFinanceRowType(fr.rowType)}</span>
                    </td>

                    {/* description */}
                    <td className="relative border border-solid border-gray-40 pl-2 pr-9 border-l-0">
                      <span className="finance-row-description text-15px break-words">{fr.description}</span>
                      {isCreditNote && (
                        <span className="credit-note-indicator absolute right-1 top-4">
                          <CreditNoteIndicator
                            creditNoteExpiryDate={fr.creditNoteExpiryDate}
                            isCreditNoteSpecificToGuest={fr.isCreditNoteSpecificToGuest}
                            isCreditNoteSpecificToHotel={fr.isCreditNoteSpecificToHotel}
                          />
                        </span>
                      )}
                    </td>

                    <td
                      className={classNames('border border-solid border-gray-40 p-2', {
                        'bg-teal-20': financeTableType === EFinanceTableTypes.SALES,
                        'bg-blue-10': financeTableType === EFinanceTableTypes.PURCHASE,
                      })}
                    >
                      {isPositiveFinanceRow(fr) && (
                        <div className="flex justify-between items-center">
                          <div className="relative">
                            {financeTableType === EFinanceTableTypes.SALES &&
                              invoiceDueDatesSpec[fr.rowType] &&
                              fr?.invoiceDueDates &&
                              fr.invoiceDueDates.length > 0 && (
                                <InvoiceDueDatesSummaryTooltip>
                                  <InvoiceDueDatesSummary
                                    className="px-15px py-20px"
                                    currencySymbol={props.currencySymbol}
                                    financeRow={fr}
                                    {...invoiceDueDatesSpec[fr.rowType]}
                                  />
                                </InvoiceDueDatesSummaryTooltip>
                              )}
                          </div>
                          <span className="finance-row-invoice-amount text-15px">
                            {props.currencySymbol}
                            {formatPriceCents(fr.amountCents)}
                          </span>
                        </div>
                      )}
                    </td>
                    <td
                      className={classNames('border border-solid border-gray-40 p-2 text-right', {
                        'bg-teal-20': financeTableType === EFinanceTableTypes.SALES,
                        'bg-blue-10': financeTableType === EFinanceTableTypes.PURCHASE,
                      })}
                    >
                      {isNegativeFinanceRow(fr) && (
                        <div className="flex justify-between items-center">
                          <div className="relative">
                            {isPcrRow && pcrStatus === EPurchaseCostReviewStatus.APPROVED && (
                              <InvoiceDueDatesSummaryTooltip>
                                <InvoiceDueDatesSummary
                                  title="Hotel Invoice"
                                  className="px-15px py-20px text-left"
                                  currencySymbol={props.currencySymbol}
                                  financeRow={fr}
                                  showTotal
                                />
                              </InvoiceDueDatesSummaryTooltip>
                            )}
                          </div>
                          <span className="finance-row-payment-amount text-15px">
                            -{props.currencySymbol}
                            {formatPriceCents(fr.amountCents)}
                          </span>
                        </div>
                      )}
                    </td>
                    <td
                      colSpan={3}
                      className="border border-solid border-gray-40 p-2 w-12"
                      style={{ minWidth: '170px' }}
                    >
                      <div className="flex items-center justify-between w-full">
                        <div>
                          {isPcrRow && pcrCellClasses[pcrStatus] && (
                            <span className={`relative block p-2 ${pcrCellClasses[pcrStatus]}`}>
                              <PurchaseCostReviewIndicator status={pcrStatus} />
                            </span>
                          )}
                        </div>

                        <div className="flex justify-end items-center">
                          {!!fr.bankAccount && (
                            <span className="relative">
                              <HidingTooltip
                                renderTooltipContent={renderPaymentMethodLabel(fr.bankAccount)}
                                position="bottom"
                              >
                                <img
                                  className="bank-icon mx-1 mt-1"
                                  src={bank}
                                  width="18"
                                  height="16"
                                  alt="Payment method"
                                />
                              </HidingTooltip>
                            </span>
                          )}
                          {fr.uploadUrl !== '' && fr.uploadUrl != undefined && (
                            <a className="text-lg" target="_blank" href={fr.uploadUrl}>
                              <CircleIconButton
                                type="secondary"
                                className="finance-row-edit-button mx-1"
                                iconClass="far fa-file"
                                onClick={() => {}}
                              />
                            </a>
                          )}
                          {(props.canEdit || props.canDelete) && (
                            <div>
                              {!isAutomaticFinanceRow(fr) && props.canEdit && !isPcrRow && (
                                <CircleIconButton
                                  type="secondary"
                                  className="finance-row-edit-button mx-1"
                                  iconClass="fas fa-pen"
                                  onClick={() => {
                                    props.onEdit(frIndex);
                                  }}
                                />
                              )}
                              {props.canDelete && !isPcrRow && (
                                <CircleIconButton
                                  type="secondary"
                                  className="finance-row-delete-button mx-1"
                                  iconClass="far fa-trash-alt"
                                  onClick={() => {
                                    props.onDelete(frIndex);
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>

            <tfoot>
              <tr className="">
                <td className="border border-solid border-gray-40 p-2 border-r-0" colSpan={3}>
                  <span className="uppercase text-gray-100 text-xs">Total</span>
                </td>
                <td className="border border-solid border-gray-40 p-2 border-l-0"></td>
                <td className="border border-solid border-gray-40 p-2 text-right">
                  <span className="finance-total finance-invoice-total font-bold text-base">
                    {props.currencySymbol}
                    {formatPriceCents(props.financeDocumentBalance.totalDebitCents)}
                  </span>
                </td>
                <td className="border border-solid border-gray-40 p-2 text-right">
                  <span className="finance-total finance-payment-total font-bold text-base">
                    {props.financeDocumentBalance.totalCreditCents !== 0 ? '-' : ''}
                    {props.currencySymbol}
                    {formatPriceCents(props.financeDocumentBalance.totalCreditCents)}
                  </span>
                </td>
                <td colSpan={3} className="border border-solid border-gray-40 p-2">
                  {/* deliberately blank - total cta column */}
                </td>
              </tr>
              <tr className="bg-brown-15">
                <td className="border border-solid border-gray-40 p-2" colSpan={4}>
                  <span className="uppercase text-gray-100 text-xs">Outstanding Balance</span>
                </td>
                <td className="border border-solid border-gray-40 p-2 text-center" colSpan={2}>
                  <span
                    className={classNames('finance-total finance-outstanding-total font-bold text-base', {
                      'text-red-90': props.financeDocumentBalance.totalOutstandingCents < 0,
                    })}
                  >
                    {props.currencySymbol}
                    {formatPriceCents(props.financeDocumentBalance.totalOutstandingCents)}
                  </span>
                </td>
                <td colSpan={3} className="border border-solid border-gray-40 p-2">
                  {/* deliberately blank - outstanding balance cta column */}
                </td>
              </tr>
            </tfoot>
          </React.Fragment>
        )}
      </table>
    </div>
  );
};

const InvoiceDueDatesSummaryTooltip: FC<HTMLProps<HTMLDivElement>> = ({ className, children }) => (
  <FixedTooltip className={className} width={310}>
    <FixedTooltip.Label
      render={tooltipVisible => (
        <i
          className={classNames(
            'invoice-due-dates-indicator',
            'fas fa-info-circle text-gray-90 -ml-1',
            tooltipVisible ? 'opacity-100' : 'opacity-50'
          )}
        />
      )}
    />
    <FixedTooltip.Content>{children}</FixedTooltip.Content>
  </FixedTooltip>
);
