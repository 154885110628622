import { ENetworkRequestStatus, IGuest, IAirlineListItem } from 'services/BackendApi/types';

export interface IGuestInformationSubdomain {
  guests: IGuest[] | null;
  loading: boolean;
  saving: boolean;
  error: string | null;
  airlinesLoad: ENetworkRequestStatus;
  airlines: IAirlineListItem[];
}

export const initialState: IGuestInformationSubdomain = {
  airlines: [],
  airlinesLoad: ENetworkRequestStatus.IDLE,
  guests: null,
  loading: true,
  saving: false,
  error: null,
};
