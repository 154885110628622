import React from 'react';
import classNames from 'classnames';
import { produce } from 'immer';
import { sumBy } from 'lodash-es';
import { formatPrice, roundToDecimalPlaces } from 'utils';
import { IInvoiceDueDate } from 'services/BookingManagerApi/types/FinanceResponse';
import FluidButton from 'ui/FluidButton';
import { InvoiceModalDueDateRow } from 'ui/InvoiceModalDueDateRow';

export interface IInvoiceDueDates {
  className?: string;
  amountCents: number;
  currencySymbol: string;
  invoiceDueDates: IInvoiceDueDate[];
  onChange: (invoiceDueDates: IInvoiceDueDate[]) => void;
}

export const InvoiceDueDates = (props: IInvoiceDueDates) => {
  const rest =
    props.amountCents -
    sumBy(
      props.invoiceDueDates.filter(x => x.isConfirmed),
      'amountCents'
    );

  const unconfirmedRest = props.amountCents - sumBy(props.invoiceDueDates, 'amountCents');

  const percentage = (x: number) => roundToDecimalPlaces((x / props.amountCents) * 100, 2);

  const amount = (x: number) => Math.round((x / 100) * props.amountCents);

  const addHandler = () =>
    produce(props.invoiceDueDates, draft => {
      draft.push({
        date: null,
        amountCents: unconfirmedRest,
        percentage: percentage(unconfirmedRest),
        isConfirmed: false,
      });
      return draft;
    });

  const removeHandler = (idx: number) =>
    produce(props.invoiceDueDates, draft => {
      draft.splice(idx, 1);
      return draft;
    });

  const amountCentsHandler = (idx: number, amountCents: number) =>
    produce(props.invoiceDueDates, draft => {
      draft[idx].amountCents = amountCents;
      draft[idx].percentage = percentage(amountCents);
      return draft;
    });

  const percentageHandler = (idx: number, p: number) =>
    produce(props.invoiceDueDates, draft => {
      draft[idx].amountCents = amount(p);
      draft[idx].percentage = p;
      return draft;
    });

  const dateHandler = (idx: number, date: string | null) =>
    produce(props.invoiceDueDates, draft => {
      draft[idx].date = date;
      return draft;
    });

  const confirmHandler = (idx: number) =>
    produce(props.invoiceDueDates, draft => {
      draft[idx].isConfirmed = true;
      return draft;
    });

  const wrap = handler => (...args) => props.onChange(handler(...args));

  const hasDueDates = !!props.invoiceDueDates.length;

  const restStr = `${props.currencySymbol}${formatPrice(rest / 100)}`;
  const ico = <i className="fas fa-exclamation-circle" />;

  return (
    <div className={classNames('invoice-due-dates font-pt-sans', props.className)}>
      <p className="font-bold text-base leading-sm text-black mb-0">Select Due Dates</p>
      {!props.invoiceDueDates.length && (
        <p className="text-15px leading-sm text-black m-0">Add due dates to the amount</p>
      )}
      <div
        style={{
          maxHeight: '155px',
          overflowY: 'auto',
        }}
      >
        {props.invoiceDueDates.map((invoiceDueDate, index) => {
          return (
            <InvoiceModalDueDateRow
              className="py-2"
              key={index}
              index={index}
              date={invoiceDueDate.date || null}
              allOtherDates={props.invoiceDueDates
                .filter((x, i) => i !== index && x.date !== null)
                .map(x => x.date || '')}
              percentage={invoiceDueDate.percentage || 0}
              amountCents={invoiceDueDate.amountCents || 0}
              currencySymbol={props.currencySymbol}
              isConfirmed={invoiceDueDate.isConfirmed}
              onUpdateAmountCents={wrap(amountCentsHandler)}
              onUpdatePercentage={wrap(percentageHandler)}
              onUpdateDate={wrap(dateHandler)}
              onClickRemove={wrap(removeHandler)}
              onClickDelete={wrap(removeHandler)}
              onClickConfirm={wrap(confirmHandler)}
            />
          );
        })}
      </div>
      <div className="flex justify-left items-center mt-2 mb-30px">
        <FluidButton className="add" type="secondary" onClick={wrap(addHandler)} disabled={!rest}>
          Add Due Date
        </FluidButton>
        <div className="message text-13px text-brown-100 ml-4">
          {!hasDueDates && <span>{ico} You must add minimum a due date to Add Finance Row</span>}
          {hasDueDates && rest === 0 && (
            <span>
              {ico} <b>100% Match!</b> You can’t add more due dates
            </span>
          )}
          {hasDueDates && rest > 0 && (
            <span>
              <b>{restStr}</b> to Match
            </span>
          )}
          {hasDueDates && rest < 0 && (
            <span className="text-red-100">
              {ico} <b>{restStr}</b> Please delete one or more Due Dates
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
