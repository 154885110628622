import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'ui/Checkbox';
import classnames from 'classnames';
import { SvgIcon } from 'ui/SvgIcon';
import OpenEyeIcon from 'ui/Icons/openEye.component.svg';
import ClosedEyeIcon from 'ui/Icons/closedEye.component.svg';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { theme } from '../../../../tailwind.config';
import * as BootstrapSelectors from 'store/modules/bootstrap/selectors';
import * as CompanyInfoActions from 'store/modules/companyInfo/actions';
import * as CompanyInfoSelectors from 'store/modules/companyInfo/selectors';
import { TCountryCode } from 'interfaces';
import FluidButton from 'ui/FluidButton';
import { ENetworkRequestStatus } from 'services/BackendApi';

export interface IHotelListProps {
  companyUuid: string;
  isEditMode: boolean;
}

export const HotelList = (props: IHotelListProps) => {
  const { companyUuid, isEditMode } = props;
  const dispatch = useDispatch();
  const allHotels = useSelector(BootstrapSelectors.getBootstrapHotelsSelector);
  const countries = useSelector(BootstrapSelectors.getBootstrapCountriesSelector);
  const destinations = countries.filter(c => c.isDestination);

  const [locationNameMap, setLocationNameMap] = useState({});

  // locationNameMap is based on the raw countries data, with some overwrites
  useEffect(() => {
    const temp = {};
    let destinations = countries.filter(c => c.isDestination);
    destinations.forEach(d => {
      temp[d.code] = d.name;
    });
    // any overwrites
    temp['AE'] = 'UAE';
    setLocationNameMap(temp);
  }, [countries]);

  useEffect(() => {
    dispatch(CompanyInfoActions.getHotelListDataRequestAction());
  }, [companyUuid]);

  const allHotelsCount = useSelector(BootstrapSelectors.getBootstrapHotelsCountSelector);
  const hotelListData = useSelector(CompanyInfoSelectors.hotelListDataSelector);
  const hotelsActivatedCount = useSelector(CompanyInfoSelectors.hotelListDataHotelsActivatedCountSelector);
  const hotelListDestinations = useSelector(CompanyInfoSelectors.hotelListDestinationsSelector);
  const hotelListDataNetwork = useSelector(CompanyInfoSelectors.hotelListDataNetworkSelector);

  return (
    <div className="hotel-list w-full font-pt-sans">
      <LeaveWithoutSavingModal
        title={`You have made changes to this page, and not saved. If you leave this page now, these changes will be lost.`}
        when={hotelListData.hasEditedWithoutSaving}
      />

      <p className="title text-black-true font-bold text-17px leading-md mb-0">Hotel List</p>
      <p className="description text-black text-15px leading-sm mt-5px mb-0">
        The selected hotels on the following list will be the ones available through the App for this Travel Partner.
      </p>

      {isEditMode && (<p className="text-13px leading-16px text-gray-100">Select hotels by location</p>)}

      <div
        className={classnames('flex flex-row justify-between items-center', {
          'opacity-60 cursor-wait pointer-events-none':
            hotelListDataNetwork.postHotelListData === ENetworkRequestStatus.PENDING ||
            hotelListDataNetwork.getHotelListData === ENetworkRequestStatus.PENDING,
        })}
      >
        {isEditMode && (<div className="flex flex-row space-x-20px mb-5">
          {destinations.map(d => {
            return (
              <label key={d.name} className="destination-checkbox-label flex flex-row items-center">
                <Checkbox
                  indeterminate={hotelListDestinations[d.code].indeterminate}
                  checked={hotelListDestinations[d.code].checked}
                  onChange={e => {
                    dispatch(
                      CompanyInfoActions.setActiveDestinationAction(
                        e.currentTarget.checked,
                        d.code as TCountryCode,
                        allHotels
                      )
                    );
                  }}
                  disabled={!isEditMode}
                />
                <span className="ml-2">{d.name}</span>
              </label>
            );
          })}
        </div>)}

        {!isEditMode && (<div className="flex flex-row space-x-20px mb-5"></div>)}

        <span className="text-13px leading-22px text-brown-prime">
          <span className="font-bold">{hotelsActivatedCount}</span> of {allHotelsCount} Hotels Activated
        </span>
      </div>

      <div
        style={{
          maxHeight: '418px', // design specific
          overflowY: 'scroll',
        }}
        className={classnames({
          'opacity-60 cursor-wait pointer-events-none':
            hotelListDataNetwork.postHotelListData === ENetworkRequestStatus.PENDING ||
            hotelListDataNetwork.getHotelListData === ENetworkRequestStatus.PENDING,
        })}
      >
        <table className="hotel-list-table relative table-fixed w-full border-separate border-spacing-0 font-pt-sans">
          <thead>
            <tr>
              <th
                className="bg-ivory border border-solid border-r-0 border-gray-20 shadow-pe1 h-10 uppercase text-xs text-gray-120 pl-5 sticky top-0 text-left z-1"
                style={{ width: '430px ' }}
              >
                Hotel
              </th>
              <th className="bg-ivory border border-solid border-r-0 border-l-0 border-gray-20 shadow-pe1 h-10 uppercase text-xs text-gray-120 sticky top-0 text-left z-1">
                Location
              </th>
              <th className="bg-ivory border border-solid border-r-0 border-l-0 border-gray-20 shadow-pe1 h-10 uppercase text-xs text-gray-120 sticky top-0 text-left z-1">
                Hotel Status
              </th>
              <th className="bg-ivory border border-solid border-l-0 border-gray-20 shadow-pe1 h-10 uppercase text-xs text-gray-120 sticky top-0 text-left z-1">
                {/* deliberately blank, for the eye icon */}
              </th>
            </tr>
          </thead>
          <tbody>
            {allHotels.map(hotel => {
              const isActive = hotelListData.activeHotels.some(ah => ah.uuid === hotel.uuid);
              return (
                <tr
                  key={hotel.uuid}
                  className={classnames('hotel-list-row text-15px even:bg-white-hard', {
                    'text-gray-100': !isActive,
                  })}
                >
                  <td className="pl-5 py-4">
                    <label className="hotel-list-row-checkbox-label flex flex-row items-center">
                      <Checkbox
                        checked={isActive}
                        onChange={e => {
                          dispatch(CompanyInfoActions.setActiveHotelAction(e.currentTarget.checked, hotel));
                        }}
                        disabled={!isEditMode}
                      />
                      <span className="ml-2">{hotel.name}</span>
                    </label>
                  </td>
                  <td className="py-4">{locationNameMap[hotel.countryCode]}</td>
                  <td className="py-4">
                    <span className="italic">{isActive ? 'Active' : 'Inactive'}</span>
                  </td>
                  <td className="py-4 pr-5">
                    <span className="float-right">
                      {(isActive && (
                        <SvgIcon
                          key={hotel.uuid + 'open'}
                          IconComponent={OpenEyeIcon}
                          width="22px"
                          height="22px"
                          className="pt-2px"
                          defaultFill={theme.colors['green-prime']}
                          hoverFill={theme.colors['green-prime']}
                          activeFill={theme.colors['green-prime']}
                        />
                      )) || (
                        <SvgIcon
                          key={hotel.uuid + 'closed'}
                          IconComponent={ClosedEyeIcon}
                          width="22px"
                          height="22px"
                          defaultFill={theme.colors['gray-60']}
                          hoverFill={theme.colors['gray-60']}
                          activeFill={theme.colors['gray-60']}
                        />
                      )}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {isEditMode && (<FluidButton
        type="primary"
        className="mt-25px"
        isLoading={hotelListDataNetwork.postHotelListData === ENetworkRequestStatus.PENDING}
        onClick={() => {
          dispatch(CompanyInfoActions.postHotelListDataRequestAction());
        }}
      >
        Save Hotel List
      </FluidButton>)}
    </div>
  );
};
