import React from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import * as Inputs from '../Inputs';
import FluidButton from 'ui/FluidButton';
import {
  IAccommodationProduct,
  IFineProduct,
  IGroundServiceProduct,
  IHotel,
  IMealPlanProduct,
  ISeason,
  ISupplementProduct,
  ITransferProduct,
} from 'services/BackendApi';
import { Room } from '../child-product-forms/Room';
import { produce } from 'immer';
import * as _ from 'lodash-es';
import { MealPlan } from '../child-product-forms/MealPlan';
import { Transfer } from '../child-product-forms/Transfer';
import { Supplement } from '../child-product-forms/Supplement';
import { GroundService } from '../child-product-forms/GroundService';
import { Fine } from '../child-product-forms/Fine';
import { validateFine } from 'containers/HotelAdmin/validation';
import { enqueueNotification } from 'store/modules/ui';
import { useDispatch } from 'react-redux';

export const CreateFineModal = ({
  onConfirm,
  onClose,
  hotel,
}: {
  onConfirm: (data: any) => void;
  onClose: () => void;
  hotel: IHotel;
}) => {
  const dispatch = useDispatch();
  const [draftFine, setDraftFine] = React.useState<Partial<IFineProduct>>({
    meta: {
      description: '',
    },
    options: {},
  });

  return (
    <GeneralModal
      onClose={() => onClose()}
      modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-scroll font-pt-sans"
      shouldCloseByClickingOutside={false}
    >
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">Create new Fine</h1>
      <Fine
        fine={draftFine}
        onUpdate={(field, value) => {
          setDraftFine({
            ...produce(draftFine, _draftRoom => {
              _.set(_draftRoom, field, value);
            }),
          });
        }}
        onCta={() => {
          const validation = validateFine(draftFine);
          if (validation.isValid === false) {
            dispatch(
              enqueueNotification({
                message: `Fine is not valid: ${validation.message}`,
                options: { variant: 'warning' },
              })
            );
            return;
          }
          onConfirm(draftFine);
        }}
        ctaLabel="Create Fine"
      />
    </GeneralModal>
  );
};
