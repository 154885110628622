import { EUserType, ICompany, ITravelAgent } from 'services/BackendApi/types';
import { History } from 'history';
import { INotification } from 'services/BackendApi/types/Notification';

export const GET_REQUEST = 'travelAgent/GET_REQUEST';
export const GET_SUCCESS = 'travelAgent/GET_SUCCESS';
export const GET_FAILURE = 'travelAgent/GET_FAILURE';
export const INIT_TA_SAVE_STATUS = 'travelAgent/INIT_TA_SAVE_STATUS';
export const CREATE_REQUEST = 'travelAgent/CREATE_REQUEST';
export const CREATE_SUCCESS = 'travelAgent/CREATE_SUCCESS';
export const CREATE_FAILURE = 'travelAgent/CREATE_FAILURE';
export const UPDATE_REQUEST = 'travelAgent/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'travelAgent/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'travelAgent/UPDATE_FAILURE';
export const SET_SR_ASSIGNMENTS_REQUEST = 'travelAgent/SET_SR_ASSIGNMENTS_REQUEST';
export const SET_SR_ASSIGNMENTS_SUCCESS = 'travelAgent/SET_SR_ASSIGNMENTS_SUCCESS';
export const SET_SR_ASSIGNMENTS_FAILURE = 'travelAgent/SET_SR_ASSIGNMENTS_FAILURE';
export const RESET_TRAVEL_AGENT = 'travelAgent/RESET_TRAVEL_AGENT';

export const SET_COMPANY_FILTER_COUNTRY = 'travelAgent/SET_COMPANY_FILTER_COUNTRY';
export const SET_COMPANY_FILTER_NAME = 'travelAgent/SET_COMPANY_FILTER_NAME';
export const SET_FILTERED_COMPANIES = 'travelAgent/SET_FILTERED_COMPANIES';
export const SET_TA_COMPANIES_MODAL_VISIBLE = 'travelAgent/SET_TA_COMPANIES_MODAL_VISIBLE';
export const SET_SELECTED_TA_COMPANY = 'travelAgent/SET_SELECTED_TA_COMPANY';

export type GetTravelAgentRequestAction = ReturnType<typeof getTravelAgentRequestAction>;
export const getTravelAgentRequestAction = (travelAgentUuid: string, userType: EUserType) => ({
  type: GET_REQUEST as typeof GET_REQUEST,
  travelAgentUuid,
  userType,
});

export type GetTravelAgentSuccessAction = ReturnType<typeof getTravelAgentSuccessAction>;
export const getTravelAgentSuccessAction = (travelAgent: ITravelAgent) => ({
  type: GET_SUCCESS as typeof GET_SUCCESS,
  travelAgent,
});

export type GetTravelAgentFailureAction = ReturnType<typeof getTravelAgentFailureAction>;
export const getTravelAgentFailureAction = (error: string) => ({
  type: GET_FAILURE as typeof GET_FAILURE,
  error,
});

export type CreateTravelAgentRequestAction = ReturnType<typeof createTravelAgentRequestAction>;
export const createTravelAgentRequestAction = (
  travelAgent: Partial<ITravelAgent>,
  srUuids: string[],
  notifications: INotification[],
  history: History
) => ({
  type: CREATE_REQUEST as typeof CREATE_REQUEST,
  travelAgent,
  srUuids,
  notifications,
  history,
});

export type InitTaNetworkStatusAction = ReturnType<typeof initTaSaveStatusAction>;
export const initTaSaveStatusAction = () => ({
  type: INIT_TA_SAVE_STATUS as typeof INIT_TA_SAVE_STATUS,
});

export type ResetTravelAgentAction = ReturnType<typeof resetTravelAgentAction>;
export const resetTravelAgentAction = () => ({
  type: RESET_TRAVEL_AGENT as typeof RESET_TRAVEL_AGENT,
});

export type CreateTravelAgentSuccessAction = ReturnType<typeof createTravelAgentSuccessAction>;
export const createTravelAgentSuccessAction = (travelAgent: ITravelAgent) => ({
  type: CREATE_SUCCESS as typeof CREATE_SUCCESS,
  travelAgent,
});

export type CreateTravelAgentFailureAction = ReturnType<typeof createTravelAgentFailureAction>;
export const createTravelAgentFailureAction = (error: string) => ({
  type: CREATE_FAILURE as typeof CREATE_FAILURE,
  error,
});

export type UpdateTravelAgentRequestAction = ReturnType<typeof updateTravelAgentRequestAction>;
export const updateTravelAgentRequestAction = (
  travelAgentUuid: string,
  travelAgent: Partial<ITravelAgent>,
  notifications: INotification[],
  userType: EUserType,
  oldValues: Partial<ITravelAgent>
) => ({
  type: UPDATE_REQUEST as typeof UPDATE_REQUEST,
  travelAgentUuid,
  travelAgent,
  notifications,
  userType,
  oldValues,
});

export type UpdateTravelAgentSuccessAction = ReturnType<typeof updateTravelAgentSuccessAction>;
export const updateTravelAgentSuccessAction = (travelAgent: ITravelAgent) => ({
  type: UPDATE_SUCCESS as typeof UPDATE_SUCCESS,
  travelAgent,
});

export type UpdateTravelAgentFailureAction = ReturnType<typeof updateTravelAgentFailureAction>;
export const updateTravelAgentFailureAction = (error: string) => ({
  type: UPDATE_FAILURE as typeof UPDATE_FAILURE,
  error,
});

export type SetSrAssignmentsRequestAction = ReturnType<typeof setSrAssignmentsRequestAction>;
export const setSrAssignmentsRequestAction = (travelAgentUuid: string, srUuids: string[]) => ({
  type: SET_SR_ASSIGNMENTS_REQUEST as typeof SET_SR_ASSIGNMENTS_REQUEST,
  travelAgentUuid,
  srUuids,
});

export type SetSrAssignmentsSuccessAction = ReturnType<typeof setSrAssignmentsSuccessAction>;
export const setSrAssignmentsSuccessAction = () => ({
  type: SET_SR_ASSIGNMENTS_SUCCESS as typeof SET_SR_ASSIGNMENTS_SUCCESS,
});

export type SetSrAssignmentsFailureAction = ReturnType<typeof setSrAssignmentsFailureAction>;
export const setSrAssignmentsFailureAction = (error: string) => ({
  type: SET_SR_ASSIGNMENTS_FAILURE as typeof SET_SR_ASSIGNMENTS_FAILURE,
  error,
});

export type SetCompanyFilterCountryAction = ReturnType<typeof setCompanyFilterCountryAction>;
export const setCompanyFilterCountryAction = (countryCode: string) => ({
  type: SET_COMPANY_FILTER_COUNTRY as typeof SET_COMPANY_FILTER_COUNTRY,
  countryCode,
});

export type SetCompanyFilterNameAction = ReturnType<typeof setCompanyFilterNameAction>;
export const setCompanyFilterNameAction = (companyName: string) => ({
  type: SET_COMPANY_FILTER_NAME as typeof SET_COMPANY_FILTER_NAME,
  companyName,
});

export type SetFilteredCompaniesAction = ReturnType<typeof setFilteredCompaniesAction>;
export const setFilteredCompaniesAction = (filteredCompanies: ICompany[]) => ({
  type: SET_FILTERED_COMPANIES as typeof SET_FILTERED_COMPANIES,
  filteredCompanies,
});

export type SetTaCompaniesModalVisibleAction = ReturnType<typeof setTaCompaniesModalVisibleAction>;
export const setTaCompaniesModalVisibleAction = (visible: boolean) => ({
  type: SET_TA_COMPANIES_MODAL_VISIBLE as typeof SET_TA_COMPANIES_MODAL_VISIBLE,
  visible,
});

export type SetSelectedTaCompanyAction = ReturnType<typeof setSelectedTaCompanyAction>;
export const setSelectedTaCompanyAction = (companyUuid: string) => ({
  type: SET_SELECTED_TA_COMPANY as typeof SET_SELECTED_TA_COMPANY,
  companyUuid,
});

export type TravelAgentAction =
  | GetTravelAgentRequestAction
  | GetTravelAgentSuccessAction
  | GetTravelAgentFailureAction
  | CreateTravelAgentRequestAction
  | CreateTravelAgentSuccessAction
  | CreateTravelAgentFailureAction
  | InitTaNetworkStatusAction
  | UpdateTravelAgentRequestAction
  | UpdateTravelAgentSuccessAction
  | UpdateTravelAgentFailureAction
  | SetSrAssignmentsRequestAction
  | SetSrAssignmentsSuccessAction
  | SetSrAssignmentsFailureAction
  | SetCompanyFilterCountryAction
  | SetCompanyFilterNameAction
  | SetFilteredCompaniesAction
  | SetTaCompaniesModalVisibleAction
  | SetSelectedTaCompanyAction
  | ResetTravelAgentAction;
