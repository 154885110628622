import { Transfer } from 'services/BackendApi';

export interface ISelectedTransfer {
  uuid: string;
  direction: ETransferDirection;
  roomIdxs: number[];
  isReturn: boolean;
}

export interface ITransferWithShareIndexes extends Transfer {
  // the `in` and the `out` should only be populated under the respective groupings
  // e.g even if the whole transfer can be shared in and out, when the transfer is rendered
  // underneath the `in` grouping, we'll only populate the `in`
  canBeSharedWithRoomIndexes: {
    in: number[];
    out: number[];
  };
}

export interface IRenderableTransfer extends Omit<ITransferWithShareIndexes, 'roomIdxs'> {
  // the `in` and the `out` should only be populated under the respective groupings
  // e.g even if the whole transfer is selected in and out, when the transfer is rendered
  // underneath the `in` grouping, we'll only populate the `in`
  isSelectedRoomIndexes: {
    in: number[];
    out: number[];
  };
  isReturn: boolean;
  selectionTotal: string;
  selectionTotalBeforeDiscount: string;
}

export interface IRenderableTransfersByRoomByDirection {
  [key: number]: {
    return: IRenderableTransfer[];
    in: IRenderableTransfer[];
    out: IRenderableTransfer[];
  };
}

export interface ITransferFulfillmentByRoom {
  [key: number]: boolean;
}

export interface ITransferDirectionFulfillmentByRoom {
  [key: number]: Record<'in' | 'out', boolean>;
}

export interface ISelectedTransfersByDirection {
  return: ISelectedTransfer[];
  in: ISelectedTransfer[];
  out: ISelectedTransfer[];
}

// export type TTransferDirection = 'in' | 'out' | 'both';
export enum ETransferDirection {
  IN = 'in',
  OUT = 'out',
  RETURN = 'return',
}
// export type TTransferType = 'in' | 'out' | 'return';
