import { update } from 'utils/immutable';
import { IProposalEditorDomain, initialState } from './model';
import * as Actions from './actions';
import { ENetworkRequestStatus } from 'services/BackendApi';

export const proposalEditorReducer = (
  state: IProposalEditorDomain = initialState,
  action: Actions.Any
): IProposalEditorDomain => {

  switch (action.type) {

    case Actions.GET_REQUEST:  
      return update(state, s => {
        s.proposal = null;
        s.googleDocDraftId = null;
        s.request.get = ENetworkRequestStatus.PENDING;
      });
    
    case Actions.GET_SUCCESS:  
      return update(state, s => {
        s.proposal = action.value;
        s.request.get = ENetworkRequestStatus.SUCCESS;
      });
    
    case Actions.GET_FAILURE:  
      return update(state, s => s.request.get = ENetworkRequestStatus.ERROR);
    
    case Actions.DRAFT_REQUEST:
      return update(state, s => s.request.draft = ENetworkRequestStatus.PENDING);
    
    case Actions.DRAFT_SUCCESS:
      return update(state, s => {
        s.googleDocDraftId = action.value;
        s.request.draft = ENetworkRequestStatus.SUCCESS;
      });
    
    case Actions.DRAFT_FAILURE:  
      return update(state, s => s.request.draft = ENetworkRequestStatus.ERROR);

    case Actions.COMMIT_REQUEST:
      return update(state, s => s.request.commit = ENetworkRequestStatus.PENDING);
    
    case Actions.COMMIT_SUCCESS:
      return update(state, s => {
        s.request.commit = ENetworkRequestStatus.SUCCESS;
      });
    
    case Actions.COMMIT_FAILURE:  
      return update(state, s => s.request.commit = ENetworkRequestStatus.ERROR);
    
    default:
      return state;
  }
};

export default proposalEditorReducer;
