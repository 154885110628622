import { prop, propEq, omit, propOr, mergeDeepLeft, equals, has } from 'ramda';
import { FORBIDDEN } from 'http-status';

import { UserStatusTypes, AuthTypes } from 'config/enums';
import { makeBackendApi } from 'services/BackendApi';

import { genericAction, successAction, errorAction, errorFromResponse } from 'store/common';
import { getCurrentUserType } from './selectors';
import { selectedTaSelector } from '../agents/selectors';
import { AUTHENTICATION_STRATEGY } from 'config';
import {
  saveTokenWithRememberMe,
  saveTokenWithoutRememberMe,
  parseJwtToken,
  getBearerTokenFromLocalStorage,
} from 'services/tokenLocalStorage';

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_OK = 'AUTH_OK';
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_SIGN_UP = 'AUTH_SIGN_UP';
export const AUTH_LOG_IN = 'AUTH_LOG_IN';
export const AUTH_LOG_IN_SUCCESS = 'AUTH_LOG_IN_SUCCESS';
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';
export const AUTH_LOG_OUT_SUCCESS = 'AUTH_LOG_OUT_SUCCESS';
export const AUTH_PASSWORD_RESET = 'AUTH_PASSWORD_RESET';
export const AUTH_SET_PASSWORD = 'AUTH_SET_PASSWORD';
export const AUTH_COUNTRY_SET = 'AUTH_COUNTRY_SET';
export const AUTH_PASSWORD_UPDATE = 'AUTH_PASSWORD_UPDATE';
export const AUTH_CLEAR_ERROR = 'AUTH_CLEAR_ERROR';

// Localstorage constants for auth
export const AUTH_TOKEN = 'authToken';
export const AUTH_USER = 'authUser';
export const AUTH_COUNTRY_CODE = 'authCountryCode';
export const AUTH_ROLE = 'role';
export const AUTH_USERNAME = 'username';

/**
 * Auth reset action
 *
 * @returns {object}
 */
export const authReset = () => ({
  type: AUTH_RESET,
});

/**
 * Set token action
 *
 * @param {string} token
 * @returns {object}
 */
export const setToken = (token, parsedToken, rawAuthorizationHeader) => ({
  type: AUTH_SET_TOKEN,
  payload: { token, parsedToken },
  rawAuthorizationHeader,
});

/**
 * Auth request action
 *
 * @param {*} value
 * @returns {object}
 */
export const authRequest = value => ({
  type: AUTH_REQUEST,
  payload: value,
});

/**
 * Auth password reset action
 *
 * @param {*} value
 * @returns {object}
 */
export const authPasswordReset = value => ({
  type: AUTH_PASSWORD_RESET,
  payload: value,
});

/**
 * Auth set password reset action
 *
 * @param {*} value
 * @returns {object}
 */
export const authSetPasswordReset = value => ({
  type: AUTH_SET_PASSWORD,
  payload: value,
});

/**
 * Auth ok action
 *
 * @param {*} values
 * @returns {object}
 */
export const authOk = values => ({
  type: AUTH_OK,
  payload: values,
});

/**
 * Auth sign up action
 *
 * @param {*} values
 * @returns {object}
 */
export const authSignUp = values => ({
  type: AUTH_SIGN_UP,
  payload: values,
});

/**
 * Auth log out action
 *
 * @param {string} token
 * @returns {object}
 */
export const authLogOut = token => ({
  type: AUTH_LOG_OUT,
  payload: token,
});

/**
 * Auth set country action
 *
 * @param {string} countryCode
 * @returns {object}
 */
export const authSetCountryAction = countryCode => ({
  type: AUTH_COUNTRY_SET,
  payload: countryCode,
});

/**
 * Set remembered token
 *
 * @param {string} token
 */
export const setRememberedToken = token => localStorage.setItem(AUTH_TOKEN, token);

/**
 * Set remembered user
 *
 * @param {object} user
 */
export const setRememberedUser = user => localStorage.setItem(AUTH_USER, JSON.stringify(user));

/**
 * Set remembered country
 *
 * @param {string} countryCode
 */
export const setRememberedCountry = countryCode => localStorage.setItem(AUTH_COUNTRY_CODE, countryCode);
export const setRememberedRole = role => localStorage.setItem(AUTH_ROLE, role);
export const setRememberedUsername = username => localStorage.setItem(AUTH_USERNAME, username);

/**
 * Delete remembered token
 */
export const deleteRememberedToken = () => localStorage.removeItem(AUTH_TOKEN);

/**
 * Delete remembered user
 */
export const deleteRememberedUser = () => localStorage.removeItem(AUTH_USER);

/**
 * Delete remembered country
 */
export const deleteRememberedCountry = () => localStorage.removeItem(AUTH_COUNTRY_CODE);
export const deleteRememberedRole = () => localStorage.removeItem(AUTH_ROLE);
export const deleteRememberedUsername = () => localStorage.removeItem(AUTH_USERNAME);

/**
 * Auth set country action
 *
 * Sets the remembered country into local storage
 *
 * @param {string} countryCode
 * @returns {Function}
 */
export const authSetCountry = countryCode => dispatch => {
  setRememberedCountry(countryCode);
  dispatch(authSetCountryAction(countryCode));
};

const backendApi = getState => makeBackendApi(getState ? selectedTaSelector(getState()) : null);

/**
 * Sign up action
 *
 * @param {object} values
 * @returns {Function}
 */
export const signUp = values => async dispatch => {
  dispatch(authSignUp(values));

  try {
    const { data } = await backendApi().signup(values);
    dispatch(successAction(AUTH_SIGN_UP, data));
  } catch (e) {
    dispatch(errorFromResponse(AUTH_SIGN_UP, e));
  }
};

/**
 * Clear user action
 *
 * Removes user form local storage
 *
 * @param {Function} dispatch
 */
const clearUser = dispatch => {
  deleteRememberedToken();
  deleteRememberedUser();
  deleteRememberedCountry();
  deleteRememberedRole();
  deleteRememberedUsername();

  dispatch(authReset());
  dispatch(successAction(AUTH_LOG_OUT, {}));
};

/**
 * Log out action
 *
 * Trrigger logout request
 *
 * @param {string} token
 * @returns {Function}
 */
export const logOut = token => async dispatch => {
  dispatch(authLogOut(token));
  try {
    await backendApi().logout(); // Cognito and legacy share the tlogout endpoint
    clearUser(dispatch);
  } catch (e) {
    return true;
  }
};

/**
 * Persist user action
 *
 * Sets user data to local storage
 *
 * @param {Function} dispatch
 * @param {object} data
 */
export const persistUser = (dispatch, data) => {
  const userUuid = prop('uuid', data);

  // If a user is 'pending' then throw a forbidden error and return
  if (propEq('status', UserStatusTypes.PENDING, data)) {
    return dispatch(errorAction(AUTH_REQUEST, { status: FORBIDDEN, unverified: true }));
  }

  setRememberedToken(userUuid);
  setRememberedUser(data);
  has('countryCode', data) && setRememberedCountry(propOr(null, 'countryCode', data));
  setRememberedRole(propOr('ta', 'type', data));
  setRememberedUsername(`${data.firstName} ${data.lastName}`);
  try {
    dispatch(setToken(userUuid));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(e));
  }
};

// Let's call from Cognito Saga to the very same function we were calling after legacy login
export const bindPersistUser = userData => async dispatch => {
  persistUser(dispatch, userData);
};

/**
 * Log in action
 *
 * @param {object} values
 * @returns {Function}
 */
export const logIn = values => async dispatch => {
  dispatch(authRequest(omit(['password'], values)));

  if (AUTHENTICATION_STRATEGY === 'jwt') {
    values.authenticationStrategy = 'jwt';
  }

  try {
    const loginResponse = await backendApi().login(values);

    if (loginResponse.status >= 200 && loginResponse.status <= 299) {
      const user = loginResponse.data.data;

      if (AUTHENTICATION_STRATEGY === 'jwt') {
        const authRawToken = loginResponse.data.meta.authToken;
        const authBearer = `Bearer ${authRawToken}`;
        const parsedToken = parseJwtToken(authRawToken);

        if (parsedToken.rememberMe) {
          await saveTokenWithRememberMe(authBearer);
        } else {
          await saveTokenWithoutRememberMe(authBearer);
        }
      }

      persistUser(dispatch, user);

      dispatch(successAction(AUTH_REQUEST, { user: { ...user } }));
      dispatch(successAction(AUTH_LOG_IN, { user: { ...user } }));
    }
  } catch (e) {
    dispatch(errorFromResponse(AUTH_REQUEST, e));
  }
};

/**
 * Reset password action
 *
 * @param {object} values
 * @returns {Function}
 */
export const resetPassword = values => async dispatch => {
  dispatch(authPasswordReset(values));

  try {
    await backendApi().resetPassword(values);
    dispatch(successAction(AUTH_PASSWORD_RESET, values));
  } catch (e) {
    dispatch(errorFromResponse(AUTH_SET_PASSWORD, e));
    throw e;
  }
};

/**
 * Set password action
 *
 * @param {object} values
 * @returns {Function}
 */
export const setPassword = values => async dispatch => {
  dispatch(authSetPasswordReset(omit(['values'], values)));

  try {
    await backendApi().setPassword(values);
    dispatch(successAction(AUTH_SET_PASSWORD, values));
  } catch (e) {
    dispatch(errorFromResponse(AUTH_SET_PASSWORD, e));
  }
};

/**
 * Auth check action
 *
 * @param {object} params
 * @returns {Function}
 */
export const authCheck = params => async (dispatch, getState) => {
  const token = getBearerTokenFromLocalStorage();

  if (!token.value || token.expired) {
    return;
  }

  dispatch(genericAction(AUTH_CHECK));

  const role = getCurrentUserType(getState());

  // Associations for auth call are different per role
  // TAs : assignedSalesRepresentatives
  // Everyone else : assignedTravelAgents
  const authParams = mergeDeepLeft(params, {
    associations: equals(AuthTypes.TA, role) ? 'assignedSalesRepresentatives' : 'assignedTravelAgents',
  });

  try {
    const {
      data: { data },
    } = await backendApi(getState).getUserMe(authParams);

    const user = omit(['countryCode'], data);

    dispatch(successAction(AUTH_CHECK, { user }));
    persistUser(dispatch, user);
  } catch (e) {
    clearUser(dispatch);

    throw e;
  }
};

/**
 * Update password action
 *
 * @param {object} body
 * @returns {Function}
 */
export const updatePassword = body => async dispatch => {
  try {
    await backendApi().updatePassword(body);
    dispatch(successAction(AUTH_PASSWORD_UPDATE, body));
  } catch (e) {
    dispatch(errorFromResponse(AUTH_PASSWORD_UPDATE, e));
  }
};

export const authClearErrorAction = () => ({
  type: AUTH_CLEAR_ERROR,
});
