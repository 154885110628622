import React, { useCallback } from 'react';
import Warning from 'ui/Icons/warning.component.svg';

import { PrimaryButton, SecondaryButton } from 'pureUi/Buttons';

import { useTranslation } from 'react-i18next';
import { RatesWarningModalStyles } from './HotelContainer.styles';
import { theme } from '../../../tailwind.config';
interface RatesWarningModalContentProps {
  onConfirm?: Function;
  onClose?: Function;
  isConfirmDisabled?: boolean;
}

export const RatesWarningModalContent = (props: RatesWarningModalContentProps) => {
  const { t } = useTranslation();
  const { onConfirm, onClose, isConfirmDisabled } = props;

  const handleConfirm = useCallback(() => onConfirm && onConfirm(), [onConfirm]);
  const handleCancel = useCallback(() => onClose && onClose(), [onClose]);

  return (
    <RatesWarningModalStyles className="rates-warning-modal">
      <div className="icon">
        <Warning fill={theme.colors['gray-40']} />
      </div>
      <p className="message">
        You can Save/Download/Create Proposal but please note there is no guarantee the selection will be available and
        the price will remain the same.
      </p>
      <p className="question">Do you wish to proceed?</p>

      <div className="actions">
        <SecondaryButton id="live-rates-warning-button-no" type="button" onClick={handleCancel}>
          <>{t('labels.no')}</>
        </SecondaryButton>
        <PrimaryButton
          id="live-rates-warning-button-yes"
          type="button"
          onClick={handleConfirm}
          disabled={isConfirmDisabled}
        >
          <>{t('labels.yes')}</>
        </PrimaryButton>
      </div>
    </RatesWarningModalStyles>
  );
};
