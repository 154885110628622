import * as React from 'react';

import GuestInformationForm from 'pureUi/GuestInformationForm';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as GuestInformationActions from 'store/modules/bookingManager/subdomains/guestInformation/actions';

import { getTopNavigationDataRequestAction } from 'store/modules/bookingManager/subdomains/dashboard/actions';

import * as GuestInformationSelectors from 'store/modules/bookingManager/subdomains/guestInformation/selectors';

import { EUserType, IGuest } from 'services/BackendApi/types';

import TopNavigationBar from 'pureUi/TopNavigationBar';
import * as AuthSelectors from 'store/modules/auth';
import {
  topNavigationDataDashboardSelector,
  bookingInformationReservationTeamDashboardSelector,
  dashboardNetworkRequestsSelector,
} from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { LoadingBar, ErrorBar } from 'ui/NetworkStatusBar';

import { isAdmin as isAdminSelector, isInternalUser as isSRSelector, isTA as isTASelector } from 'store/modules/auth';

export const BookingManagerGuestInformation: React.FC = () => {
  const dispatch = useDispatch();
  const userRole = useSelector(AuthSelectors.getCurrentUserType);

  const guests = useSelector(GuestInformationSelectors.guestsSelector);
  const loading = useSelector(GuestInformationSelectors.guestsLoadingSelector);
  const saving = useSelector(GuestInformationSelectors.guestsSavingSelector);

  const airlines = useSelector(GuestInformationSelectors.airlinesSelector);
  const airlinesLoad = useSelector(GuestInformationSelectors.airlinesLoadSelector);

  const topNavigationData = useSelector(topNavigationDataDashboardSelector);
  const bookingInformationReservationTeamData = useSelector(bookingInformationReservationTeamDashboardSelector);
  const dashboardNetworkRequests = useSelector(dashboardNetworkRequestsSelector);

  const isSrUser = useSelector(isSRSelector);
  const isAdminUser = useSelector(isAdminSelector);
  const isTaUser = useSelector(isTASelector);

  useEffect(() => {
    dispatch(GuestInformationActions.getGuestInformationRequestAction());
    dispatch(getTopNavigationDataRequestAction());
    dispatch(GuestInformationActions.getAirlinesRequestAction());
  }, [dispatch]);

  const handleFormSave = useCallback(
    (guests: IGuest[]) => {
      dispatch(GuestInformationActions.saveGuestInformationRequestAction(guests));
    },
    [dispatch]
  );

  if (loading || airlinesLoad === ENetworkRequestStatus.PENDING || airlinesLoad === ENetworkRequestStatus.IDLE) {
    return <LoadingBar />;
  }

  if (guests === null || airlinesLoad === ENetworkRequestStatus.ERROR) {
    return <ErrorBar />;
  }

  return (
    <React.Fragment>
      <TopNavigationBar
        userRole={userRole as EUserType}
        data={topNavigationData}
        bookingInformationReservationTeamData={bookingInformationReservationTeamData}
        isLoading={dashboardNetworkRequests.topNavigationDataLoad === ENetworkRequestStatus.PENDING}
        isError={dashboardNetworkRequests.topNavigationDataLoad === ENetworkRequestStatus.ERROR}
      />
      <div className="guest-information">
        <h2 className="font-noe-display text-3xl text-black leading-42px font-normal mt-0 mb-0">Guest information</h2>
        <GuestInformationForm
          guests={guests}
          onSave={handleFormSave}
          isSaving={saving}
          airlines={airlines}
          canEdit={isTaUser || isSrUser || isAdminUser}
        />
      </div>
    </React.Fragment>
  );
};

export default BookingManagerGuestInformation;
