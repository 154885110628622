import { ENetworkRequestStatus } from 'services/BackendApi';
import { IProposalHistory } from 'services/BookingManagerApi';
import { IProposalSelection } from 'services/BookingManagerApi/types/ProposalSelection';
import { ESortOrder } from 'store/common/types';
import { IProposalBooking } from './types';

export enum EProposalSortBy {
  CREATED = 'created',
  TOTAL = 'total',
}

export interface IProposalDetailsDomain {
  proposalUuid: string | null;
  proposal: IProposalSelection | null;
  bookings: IProposalBooking[];
  selectedBookingUuids: string[];
  proposalDownloadHistory: IProposalHistory[];
  sortBy: EProposalSortBy;
  sortOrder: ESortOrder;
  error: any | null;
  requests: {
    proposalDetailsLoad: ENetworkRequestStatus;
  }
}

export const initialState: IProposalDetailsDomain = {
  proposalUuid: null,
  proposal: null,
  bookings: [],
  selectedBookingUuids: [],
  proposalDownloadHistory: [],
  sortBy: EProposalSortBy.CREATED,
  sortOrder: ESortOrder.ASC,
  error: null,
  requests: {
    proposalDetailsLoad: ENetworkRequestStatus.IDLE,
  }
};
