import React from 'react';
import { VerticalLine } from 'ui/VerticalLine';
import { theme } from '../../../tailwind.config';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { Link } from 'ui/Link';

// footer links are stored in "label|*|url" format
const parseFooterLink = (footerLink?: string) => {
  const separator = '|*|';
  return typeof footerLink === 'string' ? footerLink.split(separator) : [];
};

const getFooterLinkUrl = (footerLink?: string) => {
  return parseFooterLink(footerLink)[1];
};

const getFooterLinkText = (footerLink?: string) => {
  return parseFooterLink(footerLink)[0];
};

const getFooterLinkType = (footerLink?: string): 'internal' | 'external' => {
  return footerLink ? (parseFooterLink(footerLink)[2] as 'internal' | 'external') || 'external' : 'external';
};

export const Footer = ({ className }) => {
  const { dynamicParameters } = useDynamicParameters();
  const linkClassName =
    'footer-link font-pt-sans text-[15px] leading-[20px] text-brown-100 underline ml-[20px] mr-[20px] hover:no-underline active:font-bold';

  return (
    <div className={`app-footer ${className} border border-t-solid border-gray-20 pt-[30px]`}>
      <div className="flex items-center flex-wrap gap-y-[15px]">
        <Link
          className={linkClassName}
          to={getFooterLinkUrl(dynamicParameters.FOOTER_LINK_1)}
          type={getFooterLinkType(dynamicParameters.FOOTER_LINK_1)}
        >
          {getFooterLinkText(dynamicParameters.FOOTER_LINK_1)}
        </Link>
        {dynamicParameters.FOOTER_LINK_2 && (
          <>
            <VerticalLine height="15px" color={theme.colors['gray-20']} />
            <Link className={linkClassName} to={getFooterLinkUrl(dynamicParameters.FOOTER_LINK_2)}>
              {getFooterLinkText(dynamicParameters.FOOTER_LINK_2)}
            </Link>
          </>
        )}
        {dynamicParameters.FOOTER_LINK_3 && (
          <>
            <VerticalLine height="15px" color={theme.colors['gray-20']} />
            <Link
              className={linkClassName}
              to={getFooterLinkUrl(dynamicParameters.FOOTER_LINK_3)}
              type={getFooterLinkType(dynamicParameters.FOOTER_LINK_3)}
            >
              {getFooterLinkText(dynamicParameters.FOOTER_LINK_3)}
            </Link>
          </>
        )}
        {dynamicParameters.FOOTER_LINK_4 && (
          <>
            <VerticalLine height="15px" color={theme.colors['gray-20']} />
            <Link
              className={linkClassName}
              to={getFooterLinkUrl(dynamicParameters.FOOTER_LINK_4)}
              type={getFooterLinkType(dynamicParameters.FOOTER_LINK_4)}
            >
              {getFooterLinkText(dynamicParameters.FOOTER_LINK_4)}
            </Link>
          </>
        )}
        {dynamicParameters.FOOTER_LINK_5 && (
          <>
            <VerticalLine height="15px" color={theme.colors['gray-20']} />
            <Link
              className={linkClassName}
              to={getFooterLinkUrl(dynamicParameters.FOOTER_LINK_5)}
              type={getFooterLinkType(dynamicParameters.FOOTER_LINK_5)}
            >
              {getFooterLinkText(dynamicParameters.FOOTER_LINK_5)}
            </Link>
          </>
        )}
      </div>
      <div className="flex justify-between items-center mb-[30px] pl-[20px] pr-[20px] pt-[30px]">
        <div className="">
          <address className="footer-line font-pt-sans text-gray-80 text-[14px] leading-[28px] not-italic tracking-[0.84px]">
            {dynamicParameters.FOOTER_LINE_1}
          </address>

          {dynamicParameters.FOOTER_LINE_2 && (
            <span className="footer-line font-pt-sans text-gray-80 text-[14px] leading-[28px] tracking-[0.84px]">
              {dynamicParameters.FOOTER_LINE_2}
            </span>
          )}
        </div>

        {dynamicParameters.FOOTER_LOGO && (
          <span className="footer-logo w-[80px] h-auto">
            <img src={dynamicParameters.FOOTER_LOGO} alt="" className="w-[100%] h-[100%]" />
          </span>
        )}
      </div>
    </div>
  );
};

export default Footer;
