import React from 'react';
import classnames from 'classnames';

import { GeneralModal } from 'ui/GeneralModal';
import { useCurrentWidth } from 'effects';
import { LoadingBar } from 'ui/NetworkStatusBar';

export interface iLoadingOverlayProps {
  isOpen: boolean;
}

export const LoadingOverlay = (props: iLoadingOverlayProps) => {
  const { isOpen } = props;

  const { isMobile } = useCurrentWidth();
  const generalModalClassname = classnames('flex justify-center', {
    'w-330px': isMobile,
    'w-400px': !isMobile,
  });
  const loadingOverlayClassname = classnames(
    'loading-overlay flex justify-center pt-55px pb-65px w-screen max-w-330px',
    generalModalClassname
  );

  if (!isOpen) {
    return null;
  }

  return (
    <GeneralModal isOpen={isOpen} modalWindowClassName={generalModalClassname} isCloseButtonVisible={false}>
      <div className={loadingOverlayClassname}>
        <div className="w-250px">
          <div className="flex flex-col items-center">
            <LoadingBar />
          </div>

          <p className="mt-5 text-center text-black text-base leading-xl">Loading ...</p>
        </div>
      </div>
    </GeneralModal>
  );
};
