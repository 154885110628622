import { pipe, propOr } from 'ramda';
import qs from 'qs';
import { APP_ROUTING_PATHS } from 'config';

const qsConfig = {
  ignoreQueryPrefix: true,
};

/**
 * Parse query string
 *
 * @param {string} search
 * @returns {object}
 */
export const parseQueryString = search => qs.parse(search, qsConfig);

/**
 * Get query
 *
 * Returns query string from `location` object
 *
 * @param {object}
 * @returns {*}
 */
export const getQuery = pipe(propOr('', 'search'), parseQueryString);

export const isOnBasketRoute = () => {
  const pName = '/' + window.location.pathname.slice(1).split('/')[0];
  return APP_ROUTING_PATHS.basket.home === pName || APP_ROUTING_PATHS.basket.edit === pName;
};
