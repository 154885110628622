import React from 'react';
import styled from 'styled-components';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';

interface SaveToPerformActionModalProps {
  onClose: () => void;
}

export const SaveToPerformActionModalComponent = (props: SaveToPerformActionModalProps) => {
  return (
    <ConfirmationModal
      className="save-to-perform-action z-30"
      type={EConfirmationModalType.WARNING}
      isOpen
      title={"You have made changes to this page that should be saved to perform this action."}
      message={'Please hit "Save" first.'}
      confirmButtonLabel={'Accept'}
      onConfirm={props.onClose}
      onCancel={props.onClose}
    />
  );
};

export const SaveToPerformActionModal = styled(SaveToPerformActionModalComponent)`
  width: 500px;
`;
