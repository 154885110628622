import React from 'react';
import classNames from 'classnames';
import { generateArrayOfDatesBetween } from 'utils';
import { format } from 'date-fns';
import ArrowUp from 'ui/Icons/arrowUp.component.svg';

export interface iCarouselProps {
  startDate: string;
  endDate: string;
  selectedDate: string;
  setSelectedDate: (date: string) => void;
  numberOfDatesToShow?: number;
  dateToTextMap: { [key: string]: string };
  disabledDates?: string[];
}

export const DatePickerCarousel = (props: iCarouselProps) => {
  const datesArray = generateArrayOfDatesBetween(props.startDate, props.endDate);

  const { numberOfDatesToShow = 4 } = props;
  const amountToJumpBy = numberOfDatesToShow;
  const maxOffset = datesArray.length - numberOfDatesToShow;

  // initial offset should be setup so that the first selected date is first in the carousel
  let initialOffset = datesArray.findIndex(date => date === props.selectedDate);
  // if the initial offset is greater than the max offset, set it to the max offset
  if (initialOffset > maxOffset) {
    initialOffset = maxOffset;
  }

  const [offset, setOffset] = React.useState<number>(initialOffset);

  return (
    <div className="flex font-pt-sans">
      <button
        className={classNames('cursor-pointer bg-transparent', {
          'text-light-gray': offset === 0,
          'text-brown-100': offset !== 0,
        })}
        onClick={() => {
          setOffset(offset - amountToJumpBy < 0 ? 0 : offset - amountToJumpBy);
        }}
      >
        <ArrowUp
          className={classNames('w-10px h-10px -rotate-90', {
            'stroke-brown-100 fill-brown-100': offset !== 0,
            'stroke-light-gray fill-light-gray': offset === 0,
          })}
        />
      </button>
      <div
        style={{
          width: `${55 * numberOfDatesToShow + 5 * (numberOfDatesToShow - 1)}px`,
        }}
        className="wrapper overflow-x-hidden"
      >
        <div
          className="flex space-x-5px transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${offset * 55 + offset * 5}px)`,
          }}
        >
          {datesArray.map(stringDate => {
            const date = new Date(stringDate);
            const text = props.dateToTextMap ? props.dateToTextMap[stringDate] || '' : '';
            const isDisabled = props.disabledDates ? props.disabledDates.includes(stringDate) : false;

            return (
              <div
                onClick={() => {
                  props.setSelectedDate(stringDate);
                }}
                key={stringDate}
                className={classNames('min-w-[55px] min-h-[80px] border-solid text-center px-2', {
                  'border-2 py-2px bg-green-25 border-teal-80 cursor-pointer': stringDate === props.selectedDate,
                  'border-1 py-3px bg-white border-gray-20 cursor-pointer': stringDate !== props.selectedDate,
                  'pointer-events-none cursor-auto opacity-50': isDisabled,
                })}
              >
                <div className="flex flex-col">
                  <span className="text-gray-100 text-xs">{format(date, 'EEEEE')}</span>
                  <span className="text-black text-[19px]">{format(date, 'd')}</span>
                  <span className="text-gray-100 text-xs">{format(date, 'LLL')}</span>
                  <span className="text-black text-xs">{text}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <button
        className={classNames('cursor-pointer bg-transparent', {
          'stroke-light-gray': offset === maxOffset,
          'stroke-brown-100': offset !== maxOffset,
        })}
        onClick={() => {
          setOffset(offset + amountToJumpBy > maxOffset ? maxOffset : offset + amountToJumpBy);
        }}
      >
        <ArrowUp
          className={classNames('w-10px h-10px rotate-90', {
            'stroke-brown-100 fill-brown-100': offset !== maxOffset,
            'stroke-light-gray fill-light-gray': offset === maxOffset,
          })}
        />
      </button>
    </div>
  );
};
