import { JWT_KEEPALIVE_INTERVAL, ANALYTICS_SESSION_UUID_KEY } from 'config';
import { readFromSessionStorage } from 'services/windowSessionStorage';
import { ITokenSet, makeCognitoApi } from 'services/CognitoApi';
import { COGNITO_ENABLE_FLOW } from 'config';

const DO_NOT_REMEMBER_ME_EXPIRATION_TIMEOUT = JWT_KEEPALIVE_INTERVAL * 10;
const COGNITO_ADVANCE_FOR_REFRESH_TOKEN_MS = 60000; // 1min

export const parseJwtToken = (rawToken: string) => {
  const normalized = (rawToken || '').trim();
  
  const content = /^bearer/i.test(normalized)
    ? normalized.split(/\s+/)[1] || ''
    : normalized;

  return JSON.parse(atob(content.split('.')[1]));
};

export const isTokenExpired = (rawToken: string | null): boolean => {
  if (!rawToken) {
    return true;
  }
  const parsed = parseJwtToken(rawToken);
  return parsed.exp * 1000 < Date.now();
};

export const removeTokensFromLocalStorage = () => {
  window.localStorage.removeItem('AUTH_JWT');
  window.localStorage.removeItem('REFRESH_TOKEN');
  window.localStorage.removeItem('ID_TOKEN');
}

export const saveTokenWithRememberMe = async (
  bearerToken: string,
): Promise<void> => {
  window.localStorage.setItem('AUTH_JWT', bearerToken);

  return Promise.resolve();
};

export const saveTokenWithoutRememberMe = async (
  bearerToken: string,
): Promise<void> => {
  const expirationInterval = DO_NOT_REMEMBER_ME_EXPIRATION_TIMEOUT;

  const now = new Date();
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: bearerToken,
    expiry: now.getTime() + expirationInterval,
    expirationInterval: expirationInterval,
  };

  window.localStorage.setItem('AUTH_JWT', JSON.stringify(item));

  return Promise.resolve();
};

export const saveCognitoTokensWithoutRememberMe = async (
  tokens: ITokenSet,
): Promise<void> => {
  const expirationInterval = DO_NOT_REMEMBER_ME_EXPIRATION_TIMEOUT;

  const now = new Date();
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: `Bearer ${tokens.accessToken}`,
    expiry: now.getTime() + expirationInterval,
    expirationInterval: expirationInterval,
  };

  window.localStorage.setItem('AUTH_JWT', JSON.stringify(item)); // Should be ACCESS_TOKEN just keeping old name
  window.localStorage.setItem('ID_TOKEN', tokens.idToken || '');
  window.localStorage.setItem('REFRESH_TOKEN', tokens.refreshToken || '');

  return Promise.resolve();
};

export const renewTokensFromAWS = async (): Promise<void> => {
  const actualRefreshToken : string | null = window.localStorage.getItem('REFRESH_TOKEN');
  if (!actualRefreshToken) {
    // We can't renew, consider this a logout as in legacy code
    window.localStorage.removeItem('AUTH_JWT');
    window.localStorage.removeItem('REFRESH_TOKEN');
    window.localStorage.removeItem('ID_TOKEN');
    return;
  }
  const cognitoApi = makeCognitoApi();
  const res = await cognitoApi.refreshTokens(actualRefreshToken);
  const tokens : ITokenSet = {
    accessToken: res.data.access_token,
    idToken: res.data.id_token,
    refreshToken: actualRefreshToken,
  }; 

  await saveCognitoTokensWithoutRememberMe(tokens);

  return Promise.resolve();
};

export const isCognitoTokenAboutToExpire = (): boolean => {
  const itemStr = window.localStorage.getItem('AUTH_JWT');
  if (!itemStr) {
    return true;
  }
  let rawToken : string | null = null;
  if (itemStr.substring(0, 7).toLowerCase() === 'bearer ') {
    rawToken = itemStr;
  }
  try {
    rawToken = JSON.parse(itemStr).value;
  } catch (e) {
    return true;
  }
  if (!rawToken) {
    return true;
  }
  return parseJwtToken(rawToken).exp * 1000 < (Date.now() + COGNITO_ADVANCE_FOR_REFRESH_TOKEN_MS);
};

// If we renew when token is expired, we have to wait for 'renewTokensFromAWS',
// then we have to wait for 'getBearerTokenFromLocalStorage' and 'injectJwtTokenIntoHeaders',
// then we have to await everywhere the code call the functions above,
// which can introduce a new behavior and many side effects.
// The solution: check if token "is about to expire" rather than it's "expired",
// then trigger 'renewTokensFromAWS' asynchronously and don't wait.

export const getBearerTokenFromLocalStorage = () => {
  const itemStr = window.localStorage.getItem('AUTH_JWT');

  // if we don't have an item, return null
  if (!itemStr) {
    return { value: undefined };
  }

  // if the item string was saved WITHOUT expiry, e.g just a raw string, that means we
  // can just return it
  if (itemStr.substring(0, 7).toLowerCase() === 'bearer ') {
    const expired = isTokenExpired(itemStr);
    if(expired) {
      window.localStorage.removeItem('AUTH_JWT');
    }
    if (COGNITO_ENABLE_FLOW) {
      if (isCognitoTokenAboutToExpire()) {
        renewTokensFromAWS(); // don't wait
      }
    }    
    
    return { value: itemStr, expired };
  }

  // otherwise, we saved an object with an expiry.
  // retrieve the object, and if the expiry is still within limits, refresh it
  // and then return the bearer tokens
  const item = JSON.parse(itemStr);

  if (typeof item === 'object' && item.expiry && item.value) {
    const expired = Date.now() > item.expiry || isTokenExpired(item.value);
    
    if (expired) {
      window.localStorage.removeItem('AUTH_JWT');
    } else {
      saveTokenWithoutRememberMe(item.value);
    }

    if (COGNITO_ENABLE_FLOW) {
      if (isCognitoTokenAboutToExpire()) {
        renewTokensFromAWS(); // don't wait
      }
    }

    return { value: item.value, expired };
  }

  return { value: undefined };
};

export const injectJwtTokenIntoHeaders = (headers: any) => {
  const jwtBearerToken = getBearerTokenFromLocalStorage().value;
  const sessionUuid = readFromSessionStorage(ANALYTICS_SESSION_UUID_KEY);

  if (jwtBearerToken) {
    headers.authorization = jwtBearerToken;
    headers['x-session-id'] = sessionUuid;
  }

  return headers;
}
