import React from 'react';
import classnames from 'classnames';
import { ITableCell } from './types';

export interface ITableExpandSubrowButtonProps {
  children: React.ReactNode;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

export const TableExpandSubrowButton: React.FC<ITableExpandSubrowButtonProps> = React.memo(({ children, isExpanded, setIsExpanded }) => {
  return (
    <span
      onClick={() => setIsExpanded(!isExpanded)}
      className={classnames('flex items-center space-x-2 cursor-pointer', {
        'font-bold': isExpanded,
      })}
    >
      <span
        className={classnames('w-5 transition-transform bg-none flex justify-around cursor-pointer', {
          'transform rotate-90': isExpanded,
        })}
      >
        <i className="fas fa-chevron-right" />
      </span>
      {children}
    </span>
  );
});
