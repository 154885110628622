import { dropLast, update, omit, mergeDeepRight } from 'ramda';
import { SearchDomain, initialState } from './model';
import * as Actions from './actions';
import { Lodging, ISearchQuery, EUserType } from 'services/BackendApi';
import { getTaFullName } from 'store/utils';
import { TCountryCode } from 'interfaces';

const defaultAge = 0;
const makeLodgingStub = (existingLodging?: Lodging): Lodging => {
  const lodgingStub: Lodging = {
    numberOfAdults: 2,
    agesOfAllChildren: [],
    repeatCustomer: false,
  };

  return existingLodging ? { ...existingLodging, ...lodgingStub } : { ...lodgingStub };
};

export const searchReducer = (state: SearchDomain = initialState, action: Actions.Any): SearchDomain => {
  switch (action.type) {
    case Actions.INITIALIZE_QUERY:
      return {
        ...state,
        initialized: false,
      };

    case Actions.POPULATE_QUERY:
      return {
        ...state,
        query: mergeDeepRight<ISearchQuery, ISearchQuery>(state.query, action.query),
        initialized: true,
      };

    case Actions.CLEAR_EXTENDED_QUERY: {
      return {
        ...state,
        query: {
          ...state.query,
          lodgings: state.query.lodgings.map(item => ({
            ...item,
            honeymoon: false,
            birthday: false,
            anniversary: false,
            wedding: false,
          })),
          ...omit(['name', 'lodgings', 'startDate', 'endDate', 'clientCountryCode'], initialState.query),
        },
      };
    }

    case Actions.DESTINATION_CHANGE:
      return {
        ...state,
        isFetchingNameSearch: true,
        query: {
          ...state.query,
          name: action.value,
        },
      };

    case Actions.NAME_SEARCH_SUCCESS:
      return {
        ...state,
        isFetchingNameSearch: false,
        nameSearchResults: action.successResponse,
      };

    case Actions.NAME_SEARCH_FAILURE:
      return {
        ...state,
        isFetchingNameSearch: false,
        nameSearchRequestError: action.errorResponse,
      };

    case Actions.SET_NAME_SEARCH_RESUTS_VISIBILITY:
      return {
        ...state,
        showNameSearchResults: action.visible,
      };

    case Actions.TOGGLE_REPEAT_GUEST:
      return {
        ...state,
        query: {
          ...state.query,
          lodgings: state.query.lodgings.map(l => ({ ...l, repeatCustomer: !l.repeatCustomer })),
        },
      };

    case Actions.INCREMENT_ROOM:
      const newActiveLodgingIndex =
        state.activeLodgingIndex === state.query.lodgings.length - 1 && action.step < 1
          ? (state.activeLodgingIndex = state.activeLodgingIndex - 1)
          : state.activeLodgingIndex;

      return {
        ...state,
        activeLodgingIndex: newActiveLodgingIndex,
        query: {
          ...state.query,
          lodgings:
            action.step > 0
              ? [...state.query.lodgings, makeLodgingStub(state.query.lodgings[0])]
              : dropLast(1, state.query.lodgings),
        },
      };

    case Actions.TOGGLE_LODGING_CONTROLS:
      return {
        ...state,
        showLodgingControls: !state.showLodgingControls,
      };

    case Actions.SET_LODGING_CONTOLS_VISBILITY:
      return {
        ...state,
        showLodgingControls: action.visible,
      };

    case Actions.SET_ACTIVE_LODGING_INDEX:
      return {
        ...state,
        activeLodgingIndex: action.index,
      };

    case Actions.INCREMENT_ACTIVE_LODGING_INDEX:
      return {
        ...state,
        activeLodgingIndex: state.activeLodgingIndex += action.step,
      };

    case Actions.INCREMENT_ADULT:
      const current = state.query.lodgings[action.lodgingIndex];
      const newLodging: Lodging = {
        ...current,
        numberOfAdults: current.numberOfAdults + action.step,
      };
      return {
        ...state,
        query: {
          ...state.query,
          lodgings: update(action.lodgingIndex, newLodging, state.query.lodgings),
        },
      };

    case Actions.INCREMENT_CHILD:
      const currentWithAges: Lodging = state.query.lodgings[action.lodgingIndex];
      const currentAges: number[] = currentWithAges.agesOfAllChildren || [];
      const newAges = action.step > 0 ? [...currentAges, defaultAge] : dropLast(1, currentAges);
      const newLodgingWithAhes: Lodging = {
        ...currentWithAges,
        agesOfAllChildren: newAges,
      };
      return {
        ...state,
        query: {
          ...state.query,
          lodgings: update(action.lodgingIndex, newLodgingWithAhes, state.query.lodgings),
        },
      };

    case Actions.SET_AGE:
      const updatedAges: number[] = update(
        action.childIndex,
        parseInt(action.value, 10),
        state.query.lodgings[action.lodgingIndex].agesOfAllChildren || []
      );

      const updatedLodging: Lodging = {
        ...state.query.lodgings[action.lodgingIndex],
        agesOfAllChildren: updatedAges,
      };
      return {
        ...state,
        query: {
          ...state.query,
          lodgings: update(action.lodgingIndex, updatedLodging, state.query.lodgings),
        },
      };

    case Actions.DATE_RANGE_CHANGE:
      return {
        ...state,
        query: {
          ...state.query,
          startDate: action.dates[0],
          endDate: action.dates[action.dates.length - 2],
        },
      };

    case Actions.GUEST_COUNTRY_CHANGE:
      return {
        ...state,
        query: {
          ...state.query,
          clientCountryCode: action.value as TCountryCode,
        },
      };

    case Actions.SELECTED_TA_CHANGE:
      return {
        ...state,
        selectedTa: action.value,
        taNameSearch: getTaFullName(action.value),
      };

    case Actions.CLEAR_SELECTED_TA:
      return {
        ...state,
        selectedTa: null,
        taNameSearch: '',
      };

    case Actions.SHOW_TA_DROPDOWN:
      return {
        ...state,
        showTaDropdown: action.value,
      };

    case Actions.SEARCH_TA_BY_NAME:
      return {
        ...state,
        taNameSearch: action.value,
      };

    case Actions.GET_COMPANIES_REQUEST:
      return {
        ...state,
        isFetchingCompanies: true,
      };

    case Actions.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        isFetchingCompanies: false,
        companies: action.companies,
      };

    case Actions.GET_COMPANIES_FAILURE:
      return {
        ...state,
        isFetchingCompanies: true,
        companies: null,
        companyRequestError: action.error,
      };

    case Actions.SELECTED_COMPANY_CHANGE:
      return {
        ...state,
        selectedCompany: action.value,
        companyNameSearch: action.value!.name,
        selectedTa: null,
        selectedCompanyAgents: null,
        isFetchingTA: true,
      };

    case Actions.SHOW_COMPANY_DROPDOWN:
      return {
        ...state,
        showCompanyDropdown: action.value,
      };

    case Actions.SEARCH_COMPANY_BY_NAME:
      return {
        ...state,
        companyNameSearch: action.value,
      };

    case Actions.SET_TRAVEL_COMPANY_MEMBERSHIP:
      return {
        ...state,
        selectedTravelCompanyMembership: action.tcm,
      };

    case Actions.GET_TRAVEL_AGENTS_BY_COMPANY_ID_SUCCESS:
      return {
        ...state,
        // if the agent has got notifications, we only want them to keep the ones that are for TAs
        selectedCompanyAgents: action.agents.map(agent => {
          if (agent.notifications) {
            agent.notifications = agent.notifications.filter(n => n.role === EUserType.TA) || [];
            return { ...agent };
          } else {
            return { ...agent };
          }
        }),
        isFetchingTA: false,
      };

    default:
      return state;
  }
};
