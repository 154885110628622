import React, { FC, ReactNode, useRef, useReducer, useEffect, useContext } from 'react';
import { Transition } from 'react-transition-group';
import classnames from 'classnames';

import { BaseTooltip } from './BaseTooltip';
import { TooltipContext } from './context';

const TOOLTIP_POSITION_MARGIN = 5;

interface ITooltipProps {
  children?: React.ReactNode;
  className?: string;
  width?: number;
}

interface ITooltipLabelProps {
  children?: React.ReactNode;
  render?: (visible: boolean) => ReactNode;
  labelClassName?: string;
}

interface TooltipSubComponents {
  Label: FC<ITooltipLabelProps>;
  Content: FC<{ children: ReactNode }>;
  children?: React.ReactNode;
}

interface ITooltipAction {
  type: 'TOGGLE' | 'SET_POSITION';
  position?: { x: number; y: number };
  children?: React.ReactNode;
}

interface ITooltipState {
  visible: boolean;
  position: { x: number; y: number } | undefined;
}

const reducer = (state: ITooltipState, action: ITooltipAction) => {
  switch (action.type) {
    case 'TOGGLE':
      return { ...state, visible: !state.visible };
    case 'SET_POSITION':
      return { visible: true, position: action.position };
  }
};

const isComponent = (child: ReactNode): child is React.ReactElement => {
  return (child as React.ReactElement).type !== undefined;
};

const FixedTooltip: FC<ITooltipProps> & TooltipSubComponents = ({ className, children, width = 380 }) => {
  const [state, dispatch] = useReducer(reducer, { visible: false, position: undefined });
  const tooltipRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const labelClassName = useRef<string>('');
  const TOOLTIP_WIDTH = width;

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [state.visible, labelRef.current]);

  const handleWindowResize = (_: Event) => {
    if (labelRef.current && state.visible) {
      const x = labelRef.current.offsetLeft - TOOLTIP_WIDTH - TOOLTIP_POSITION_MARGIN;
      const y = labelRef.current.offsetTop;
      dispatch({ type: 'SET_POSITION', position: { x, y } });
    }
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!tooltipRef.current?.contains(event.target as HTMLDivElement)) {
      dispatch({ type: 'TOGGLE' });
    }
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!state.visible) {
      const x = event.currentTarget.offsetLeft - TOOLTIP_WIDTH - TOOLTIP_POSITION_MARGIN;
      const y = event.currentTarget.offsetTop;
      dispatch({ type: 'SET_POSITION', position: { x, y } });
    }
  };

  const components: TooltipSubComponents = {
    Label: () => null,
    Content: () => null,
  };

  React.Children.forEach(children, child => {
    if (child && isComponent(child)) {
      const displayName = child.type['displayName'];
      if (displayName === 'Label') {
        labelClassName.current = child.props.labelClassName;
        components[child.type['displayName']] = React.cloneElement(child, { ...child.props });
        return;
      }
      components[child.type['displayName']] = child;
    }
  });

  const tooltipClasses = classnames(
    'absolute',
    'border',
    'border-solid',
    'border-gray-40',
    'rounded',
    'shadow-pe1',
    'bg-white',
    'z-11',
    className
  );

  const tooltipDefaultStyle = { transition: 'opacity 300ms ease-in-out', opacity: 0 };
  const tooltipTransitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0, zIndex: -1, position: 'relative', width: 0, border: 0 },
  };

  return (
    <TooltipContext.Provider value={{ visible: state.visible }}>
      <div className="tooltip-wrapper inline-block">
        <div onMouseEnter={handleMouseMove} onMouseLeave={handleMouseLeave} ref={labelRef} className="cursor-pointer">
          <>{components.Label}</>
        </div>

        <Transition in={state.visible} appear timeout={300}>
          {tooltipAnimationState => (
            <BaseTooltip
              wrapperClassName={tooltipClasses}
              position={state.position}
              width={`${TOOLTIP_WIDTH}px`}
              tooltipRef={tooltipRef}
              style={{
                ...tooltipDefaultStyle,
                ...tooltipTransitionStyles[tooltipAnimationState],
              }}
            >
              <>{tooltipAnimationState !== 'exited' && components.Content}</>
            </BaseTooltip>
          )}
        </Transition>
      </div>
    </TooltipContext.Provider>
  );
};

FixedTooltip.displayName = 'Tooltip';
FixedTooltip.Label = ({ children, render }) => {
  const state = useContext(TooltipContext);
  return <>{render ? render(state.visible) : children}</>;
};

FixedTooltip.Label.displayName = 'Label';
FixedTooltip.Content = ({ children }) => <>{children}</>;
FixedTooltip.Content.displayName = 'Content';

export { FixedTooltip };
