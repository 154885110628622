import { AxiosResponse } from 'axios';
import { call, select, takeLatest, put } from 'redux-saga/effects';
import {
  FETCH_COMPANY_HOTEL_MARKUP_LIST_REQUEST,
  FetchCHMarkupListRequestAction,
  fetchCHMarkupListSuccessAction,
  fetchCHMarkupListFailureAction,
  SET_CH_MARKUP_SORT_FIELD,
  CREATE_COMPANY_HOTEL_MARKUP_SUCCESS,
} from '../actions';
import { ENetworkRequestStatus, makeBackendApi } from 'services/BackendApi';
import { ICompanyHotelMarkup } from 'services/BackendApi/types/CHMarkupResponse';
import {
  companyHotelMarkupSortFieldSelector,
  companyHotelMarkupSortOrderSelector,
  travelCompanyUuidSelector,
} from '../selectors';
import { iCompanyMarkupOptionSaved } from '../model';

export function* fetchCHMarkupListSaga(action: FetchCHMarkupListRequestAction) {
  try {
    const companyUuid = yield select(travelCompanyUuidSelector);
    const sortField = yield select(companyHotelMarkupSortFieldSelector);
    const sortOrder = yield select(companyHotelMarkupSortOrderSelector);
    const backendApi = makeBackendApi();
    const result: AxiosResponse<iCompanyMarkupOptionSaved[]> = yield call(
      backendApi.fetchCompanyHotelMarkups,
      companyUuid,
      sortField,
      sortOrder
    );
    console.log('result', result);
    const companyHotelMarkupList = result.data.map(item => ({
      ...item,
      selected: false,
      isEditMode: false,
      editedValue: '',
      editMarkupLoad: ENetworkRequestStatus.IDLE,
    }));
    yield put(fetchCHMarkupListSuccessAction(companyHotelMarkupList));
  } catch (e) {
    yield put(fetchCHMarkupListFailureAction(e));
  }
}

export function* watchFetchCHMarkupListSaga() {
  yield takeLatest(
    [FETCH_COMPANY_HOTEL_MARKUP_LIST_REQUEST, SET_CH_MARKUP_SORT_FIELD, CREATE_COMPANY_HOTEL_MARKUP_SUCCESS],
    fetchCHMarkupListSaga
  );
}
