import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Actions from './actions';
import { IInternalUserDomain, initialState } from './model';
import { update } from 'utils/immutable';

export const internalUserReducer = (
  state: IInternalUserDomain = initialState,
  action: Actions.Any
): IInternalUserDomain => {
  switch (action.type) {
    case Actions.CREATE_REQUEST:
      return update(state, s => {
        s.request.create = ENetworkRequestStatus.PENDING;
      });

    case Actions.CREATE_SUCCESS:
      return update(state, s => {
        s.request.create = ENetworkRequestStatus.SUCCESS;
      });

    case Actions.CREATE_FAILURE:
      return update(state, s => {
        s.request.create = ENetworkRequestStatus.ERROR;
      });

    case Actions.GET_REQUEST:
      return update(state, s => {
        s.request.get = ENetworkRequestStatus.PENDING;
      });

    case Actions.GET_SUCCESS:
      return update(state, s => {
        s.internalUser = action.internalUser;
        s.request.get = ENetworkRequestStatus.SUCCESS;
      });

    case Actions.GET_FAILURE:
      return update(state, s => {
        s.request.get = ENetworkRequestStatus.ERROR;
      });

    case Actions.RESET_INTERNAL_USER:
      return update(state, s => {
        s.internalUser = null;
        s.request.create = ENetworkRequestStatus.IDLE;
        s.request.get = ENetworkRequestStatus.IDLE;
      });

    default:
      return state;
  }
};

export default internalUserReducer;
