import { IMultiselectValueLabelPair } from 'ui/Multiselect';
import { EFinanceRowTypes } from 'services/BookingManagerApi';
import { salesPositive, salesNegative, PositiveIcon, NegativeIcon } from 'ui/FinanceTableModal/type_options';
import { getManualBookingLedgerRowLabel } from 'ui/BalanceCarriedForwardModal/type_options';

export const filterTypeOptions: IMultiselectValueLabelPair[] = [
  ...salesPositive,
  {
    label: 'Automatic Invoice to Travel Partner',
    value: EFinanceRowTypes.Automatic_Invoice_to_Travel_Agent,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: 'Automatic Invoice to Guest',
    value: EFinanceRowTypes.Automatic_Invoice_to_Final_Client,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: 'Automatic Proforma to Travel Partner',
    value: EFinanceRowTypes.Automatic_Proforma_to_Travel_Agent,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: 'Automatic Proforma to Guest',
    value: EFinanceRowTypes.Automatic_Proforma_to_Final_Client,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: 'Automatic Cancellation Fee',
    value: EFinanceRowTypes.Automatic_Cancellation_Fee,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: 'Automatic Cancellation Fee (Live)',
    value: EFinanceRowTypes.Automatic_Cancellation_Fee_Live,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getManualBookingLedgerRowLabel(EFinanceRowTypes.Initial_Balance_Carried_Forward_Positive),
    value: EFinanceRowTypes.Initial_Balance_Carried_Forward_Positive,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getManualBookingLedgerRowLabel(EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Positive),
    value: EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Positive,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  ...salesNegative,
  {
    label: 'Payment - Other',
    value: EFinanceRowTypes.Payment_Other,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: 'Automatic Discard of Invoice',
    value: EFinanceRowTypes.Automatic_Discard_of_Invoice,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: 'Automatic Cancellation of Invoice',
    value: EFinanceRowTypes.Automatic_Cancellation_of_Invoice,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: getManualBookingLedgerRowLabel(EFinanceRowTypes.Initial_Balance_Carried_Forward_Negative),
    value: EFinanceRowTypes.Initial_Balance_Carried_Forward_Negative,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
  {
    label: getManualBookingLedgerRowLabel(EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Negative),
    value: EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Negative,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
];
