import { IHotelDomain } from './types';
import { createSelector } from 'reselect';

/**
 * new non legacy stuff
 */
export const hotelDomainSelector = (state: any): IHotelDomain => state.hotel;

export const hotelSelector = createSelector(hotelDomainSelector, hotelDomain => hotelDomain.hotel);
export const hotelFetchErrorSelector = createSelector(hotelDomainSelector, hotelDomain => hotelDomain.error);

export const hotelDefaultCurrencySelector = createSelector(hotelSelector, hotelData => hotelData?.defaultCurrency);

export const hotelPhotosSelector = createSelector(hotelDomainSelector, hotelDomain => hotelDomain.photos);

export const hotelBrochuresSelector = createSelector(hotelDomainSelector, hotelDomain => hotelDomain.brochures);
export const hotelNameSelector = createSelector(hotelDomainSelector, hotelDomain => hotelDomain.hotel?.name);
