import { EUserType } from 'services/BackendApi';
import { IEnabledNotification, INotification } from 'services/BackendApi/types/Notification';
import { EAssignNotificationsAction } from './model';

export const GET_POSSIBLE_NOTIFICATIONS_REQUEST = 'notifications/GET_POSSIBLE_NOTIFICATIONS_REQUEST';
export const GET_POSSIBLE_NOTIFICATIONS_SUCCESS = 'notifications/GET_POSSIBLE_NOTIFICATIONS_SUCCESS';
export const GET_POSSIBLE_NOTIFICATIONS_FAILURE = 'notifications/GET_POSSIBLE_NOTIFICATIONS_FAILURE';

export const GET_ENABLED_NOTIFICATIONS_REQUEST = 'notifications/GET_ENABLED_NOTIFICATIONS_REQUEST';
export const GET_ENABLED_NOTIFICATIONS_SUCCESS = 'notifications/GET_ENABLED_NOTIFICATIONS_SUCCESS';
export const GET_ENABLED_NOTIFICATIONS_FAILURE = 'notifications/GET_ENABLED_NOTIFICATIONS_FAILURE';

export const SET_NOTIFICATIONS_FOR_COMPANY_USERS_REQUEST = 'notifications/SET_NOTIFICATIONS_FOR_COMPANY_USERS_REQUEST';
export const SET_NOTIFICATIONS_FOR_COMPANY_USERS_SUCCESS = 'notifications/SET_NOTIFICATIONS_FOR_COMPANY_USERS_SUCCESS';
export const SET_NOTIFICATIONS_FOR_COMPANY_USERS_FAILURE = 'notifications/SET_NOTIFICATIONS_FOR_COMPANY_USERS_FAILURE';

export const RESET_NOTIFICATIONS = 'notifications/RESET_NOTIFICATIONS';
export const SET_ENABLED_NOTIFICATIONS = 'notifications/SET_ENABLED_NOTIFICATIONS';
export const SET_NOTIFICATION_SETTINGS_MODAL_VISIBILITY = 'notifications/SET_NOTIFICATION_SETTINGS_MODAL_VISIBILITY';
export const SET_NOTIFICATION_CONFIRMATION_MODAL_VISIBILITY = 'notifications/SET_NOTIFICATION_CONFIRMATION_MODAL_VISIBILITY';

export type GetPossibleNotificationsRequestAction = ReturnType<typeof getPossibleNotificationsRequestAction>;
export const getPossibleNotificationsRequestAction = (userType: EUserType) => ({
  type: GET_POSSIBLE_NOTIFICATIONS_REQUEST as typeof GET_POSSIBLE_NOTIFICATIONS_REQUEST,
  userType,
});

export type GetPossibleNotificationsSuccessAction = ReturnType<typeof getPossibleNotificationsSuccessAction>;
export const getPossibleNotificationsSuccessAction = (notifications: INotification[]) => ({
  type: GET_POSSIBLE_NOTIFICATIONS_SUCCESS as typeof GET_POSSIBLE_NOTIFICATIONS_SUCCESS,
  notifications,
});

export type GetPossibleNotificationsFailureAction = ReturnType<typeof getPossibleNotificationsFailureAction>;
export const getPossibleNotificationsFailureAction = (error: string) => ({
  type: GET_POSSIBLE_NOTIFICATIONS_FAILURE as typeof GET_POSSIBLE_NOTIFICATIONS_FAILURE,
  error,
});

export type GetEnabledNotificationsRequestAction = ReturnType<typeof getEnabledNotificationsRequestAction>;
export const getEnabledNotificationsRequestAction = (userUuid: string) => ({
  type: GET_ENABLED_NOTIFICATIONS_REQUEST as typeof GET_ENABLED_NOTIFICATIONS_REQUEST,
  userUuid,
});

export type GetEnabledNotificationsSuccessAction = ReturnType<typeof getEnabledNotificationsSuccessAction>;
export const getEnabledNotificationsSuccessAction = (notifications: IEnabledNotification[]) => ({
  type: GET_ENABLED_NOTIFICATIONS_SUCCESS as typeof GET_ENABLED_NOTIFICATIONS_SUCCESS,
  notifications,
});

export type GetEnabledNotificationsFailureAction = ReturnType<typeof getEnabledNotificationsFailureAction>;
export const getEnabledNotificationsFailureAction = (error: string) => ({
  type: GET_ENABLED_NOTIFICATIONS_FAILURE as typeof GET_ENABLED_NOTIFICATIONS_FAILURE,
  error,
});

export type ResetNotificationsAction = ReturnType<typeof resetNotificationsAction>;
export const resetNotificationsAction = () => ({
  type: RESET_NOTIFICATIONS as typeof RESET_NOTIFICATIONS,
});

export type SetNotificationsForCompanyUsersRequestAction = ReturnType<typeof setNotificationsForCompanyUsersRequestAction>;
export const setNotificationsForCompanyUsersRequestAction = (
  companyUuid: string,
  role: EUserType,
  notificationCodes: string[],
  enabled: boolean,
) => ({
  type: SET_NOTIFICATIONS_FOR_COMPANY_USERS_REQUEST as typeof SET_NOTIFICATIONS_FOR_COMPANY_USERS_REQUEST,
  companyUuid,
  role,
  notificationCodes,
  enabled,
});

export type SetNotificationsForCompanyUsersSuccessAction = ReturnType<typeof setNotificationsForCompanyUsersSuccessAction>;
export const setNotificationsForCompanyUsersSuccessAction = () => ({
  type: SET_NOTIFICATIONS_FOR_COMPANY_USERS_SUCCESS as typeof SET_NOTIFICATIONS_FOR_COMPANY_USERS_SUCCESS,
});

export type SetNotificationsForCompanyUsersFailureAction = ReturnType<typeof setNotificationsForCompanyUsersFailureAction>;
export const setNotificationsForCompanyUsersFailureAction = (error: string) => ({
  type: SET_NOTIFICATIONS_FOR_COMPANY_USERS_FAILURE as typeof SET_NOTIFICATIONS_FOR_COMPANY_USERS_FAILURE,
  error,
});

export type SetNotificationSettingsModalVisibilityAction = ReturnType<typeof setNotificationSettingsModalVisibilityAction>;
export const setNotificationSettingsModalVisibilityAction = (isVisible: boolean) => ({
  type: SET_NOTIFICATION_SETTINGS_MODAL_VISIBILITY as typeof SET_NOTIFICATION_SETTINGS_MODAL_VISIBILITY,
  isVisible,
});

export type SetNotificationConfirmationModalVisibilityAction = ReturnType<typeof setNotificationConfirmationModalVisibilityAction>;
export const setNotificationConfirmationModalVisibilityAction = (isVisible: boolean, assignNotificationsAction: EAssignNotificationsAction | null) => ({
  type: SET_NOTIFICATION_CONFIRMATION_MODAL_VISIBILITY as typeof SET_NOTIFICATION_CONFIRMATION_MODAL_VISIBILITY,
  isVisible,
  assignNotificationsAction,
});

export type NotificationsAction =
  | GetPossibleNotificationsRequestAction
  | GetPossibleNotificationsSuccessAction
  | GetPossibleNotificationsFailureAction
  | GetEnabledNotificationsRequestAction
  | GetEnabledNotificationsSuccessAction
  | GetEnabledNotificationsFailureAction
  | SetNotificationsForCompanyUsersRequestAction
  | SetNotificationsForCompanyUsersSuccessAction
  | SetNotificationsForCompanyUsersFailureAction
  | ResetNotificationsAction
  | SetNotificationSettingsModalVisibilityAction
  | SetNotificationConfirmationModalVisibilityAction
  ;
