import React from 'react';

export interface IFormLabelProps {
  isOptional?: boolean;
  className?: string;
  text: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const FormLabel = ({ className, style, isOptional, text, children }: IFormLabelProps) => {
  return (
    <label className={className} style={style}>
      <span className="inline-block mb-5px mt-4 text-black text-base leading-21px tracking-2xs font-pt-sans font-bold">
        {text} {isOptional && <span className="font-normal">(Optional)</span>}
      </span>
      {children}
    </label>
  );
};

export default FormLabel;
