import * as Yup from 'yup';
import { EInternalUserFormMode } from './helpers';

export const getInternalUserFormValidationSchema = (mode: EInternalUserFormMode) => {
  const emailValidationSchema = Yup.string()
    .email('Must be valid email')
    .required('This field is required');

  return Yup.object().shape({
    type: Yup.string()
      .nullable()
      .required('This field is required'),
    title: Yup.string(),
    iuFirstName: Yup.string()
      .required('This field is required'),
    iuLastName: Yup.string()
      .required('This field is required'),
    countryCode: Yup.string()
      .required('This field is required')
      .nullable(),
    mobile: Yup.string(),
    iuEmail: emailValidationSchema,
    iuPassword: Yup.string().nullable().notRequired(),
  });
};
