import React from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import { formatNumberToShortThousands, generateArrayOfDatesBetween } from 'utils';
import { Multiselect, IMultiselectProps } from 'ui/Multiselect';
import FluidButton from 'ui/FluidButton';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import { GuestAgesPicker } from 'ui/GuestAgesPicker/GuestAgesPicker';
import { format } from 'date-fns';
import DateRangeInput from 'pureUi/DateRangeInput';
import styled from 'styled-components';
import classNames from 'classnames';
import { ErrorBar } from 'ui/NetworkStatusBar';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { isNil } from 'lodash-es';
import {
  AncillaryProductWithRatesWithUserData,
  AncillaryRatedResult,
  AncillaryRatesProductsSearchItem,
  isAncillaryRatedResult,
  SelectedAncillarySearchResult,
} from 'services/BookingManagerApi/types/AncillaryService';
import { TCountryCode } from 'interfaces';
import { theme } from '../../../tailwind.config';
import { DatePickerCarousel } from 'ui/DatePickerCarousel';
import { AddAncillarySearchResultsTable } from 'ui/AddAncillarySharedComponents/AddAncillarySearchResultsTable';
import { AddAncillaryRightHandPanel } from 'ui/AddAncillarySharedComponents/AddAncillaryRightHandPanel';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { IGuestAges } from 'services/BackendApi';

export interface IAddActivityModalProps {
  isOpen: boolean;
  currencySymbol: string;
  onClose: () => void;
  onSearchButtonClick: () => void;

  startAndEndDate: {
    startDate: string;
    endDate: string;
  };
  setStartAndEndDate: ({ startDate, endDate }: { startDate: string; endDate: string }) => void;

  countryCode: TCountryCode;
  countries: IMultiselectProps['options'];
  setCountryCode: (countryCode: TCountryCode) => void;

  selectedRegionId: string;
  setSelectedRegionId: (regionId: string) => void;

  typesOptions: IMultiselectProps['options'];

  selectedFilterIds: string[];
  setSelectedFilterIds: (filterIds: string[]) => void;
  selectedActivityOrSupplier: {
    id: string;
    type: 'activity' | 'supplier';
  } | null;

  setSelectedActivityOrSupplier: (
    arg: {
      id: string;
      type: 'activity' | 'supplier';
    } | null
  ) => void;

  activityOrSupplierSelectOnLookup: IMultiselectProps['onLookupSearch'];

  guestAges: IGuestAges;
  setGuestAges: (numberOfAdults: number, agesOfAllChildren?: number[]) => void;

  regions: IMultiselectProps['options'];

  searchResults?: AncillaryRatesProductsSearchItem[];
  selectedSearchResult: SelectedAncillarySearchResult | null;
  setSelectedSearchResult: (selectedSearchResult: SelectedAncillarySearchResult | null) => void;
  selectedActivityProduct: AncillaryProductWithRatesWithUserData | null; // the full details from the api for the search result

  updateSelectedActivityProductWithUserData: (data: Partial<AncillaryProductWithRatesWithUserData['userData']>) => void;

  handleAddActivity: () => void;

  errorIds: string[];

  searchInstanceVars: {
    startAndEndDate?: { startDate: string; endDate: string };
    guestAges?: IGuestAges;
  };

  getSearchResultsNetwork: ENetworkRequestStatus;
  getTypesResultsNetworkStatus: ENetworkRequestStatus;
  getRegionsResultsNetworkStatus: ENetworkRequestStatus;
  getActivityProductNetwork: ENetworkRequestStatus;
}

const GA_FLOW_TAG = 'act-modal';

// @ts-ignore Styled component errors
const StyledDateRangeInput = styled(DateRangeInput)`
  .pseudoSelect {
    background: ${theme.colors['ivory']};
    height: 37px;
    border-color: ${theme.colors['gray-40']};
  }
  .displayString {
    text-transform: none;
    font-family: 'PT Sans', sans-serif;
  }
`;

/**
 * Renders the add activity modal
 * This component doesn't use any state internally, it's all passed in as props
 * Which means you probably don't want to use this component directly, and you're actually wanting
 * to use the AddActivityModalContainer component
 */

export const AddActivityModal = (props: IAddActivityModalProps) => {
  const { dynamicParameters } = useDynamicParameters();
  const { guestAges, setGuestAges, getSearchResultsNetwork = ENetworkRequestStatus.IDLE } = props;

  if (!props.isOpen) {
    return null;
  }

  let selectedDates: string[] = [];
  if (props.startAndEndDate.startDate && props.startAndEndDate.endDate) {
    selectedDates = generateArrayOfDatesBetween(props.startAndEndDate.startDate, props.startAndEndDate.endDate).map(
      // this odd formatting is because `DatePickerStateProvider` expects dates in this format, for some insane reason
      d => `${d}T00:00:00.000Z`
    );
  }

  const showRightPanel = !isNil(props.selectedSearchResult);

  return (
    <GeneralModal
      onClose={props.onClose}
      modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-clip"
      shouldCloseByClickingOutside={false}
    >
      {/* the modal title and search fields */}
      <h2 className="m-0 p-0 font-noe-display text-21px leading-27px font-normal mb-25px">Add Activity</h2>

      {/* this is an extremely specific height for a reason */}
      <div className="all-panels flex space-x-[30px] overflow-x-hidden flex-grow h-[calc(100%-65px)]">
        <div
          className={classNames('left-panel flex flex-col flex-shrink space-y-25px', {
            'w-[calc(100%-321px-35px)]': showRightPanel,
          })}
        >
          <div className="search-and-filters">
            <div
              className={classNames('filters-and-search space-y-15px font-pt-sans', {
                'o:opacity-50 pointer-events-none': getSearchResultsNetwork === ENetworkRequestStatus.PENDING,
              })}
            >
              <div className="flex space-x-20px">
                {/* Country */}
                <label className="w-[260px]">
                  <span className="font-bold text-black mb-5px block">Country *</span>
                  <Multiselect
                    options={props.countries}
                    className="bg-ivory"
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-10"
                    onUpdate={values => {
                      props.setCountryCode(values[0] as TCountryCode);
                      if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
                        // @ts-ignore
                        window.gtag('event', `${GA_FLOW_TAG}_select-country`, {
                          'selected-country': values[0] as string,
                        });
                      }
                    }}
                    selectedValues={props.countryCode ? [props.countryCode] : []}
                    isCloseOnSelect
                    isSingleSelectMode
                    hideCheckboxes
                    hideDropdownArrow
                    isEnableFuzzySearch
                    hideNonMatchingOptions={true}
                  />
                </label>

                {/* Region */}
                <label className="w-[260px]">
                  <span className="font-bold text-black mb-5px block">Region</span>
                  <Multiselect
                    options={props.regions}
                    className={classNames('bg-ivory', {
                      'o:opacity-50 pointer-events-none':
                        props.getRegionsResultsNetworkStatus === ENetworkRequestStatus.PENDING,
                    })}
                    disabled={props.getRegionsResultsNetworkStatus === ENetworkRequestStatus.PENDING}
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-10"
                    onUpdate={values => {
                      props.setSelectedRegionId(values[0]);
                      if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
                        // @ts-ignore
                        window.gtag('event', `${GA_FLOW_TAG}_select-region`, {
                          'selected-region': values[0] as string,
                        });
                      }
                    }}
                    staticOptions={[
                      {
                        value: '-1',
                        label: 'All',
                      },
                    ]}
                    selectedValues={props.selectedRegionId ? [props.selectedRegionId] : []}
                    isEnableFuzzySearch
                    isCloseOnSelect
                    isSingleSelectMode
                    hideCheckboxes
                    hideDropdownArrow
                  />
                </label>

                {/* Dates */}
                <label className="w-[190px]">
                  <span className="font-bold text-black mb-5px block">Dates *</span>
                  <DatePickerStateProvider
                    defaultSelectedDates={selectedDates}
                    onDateChange={dates => {
                      const startDate = format(new Date(dates[0]), 'yyyy-MM-dd');
                      const endDate = format(new Date(dates[dates.length - 1]), 'yyyy-MM-dd');
                      props.setStartAndEndDate({ startDate, endDate });
                      if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
                        // @ts-ignore
                        window.gtag('event', `${GA_FLOW_TAG}_select-period`, {
                          'selected-period': `from ${startDate} to ${endDate}`,
                        });
                      }
                    }}
                    render={(params: IDatePickerSateParams) => {
                      return (
                        <StyledDateRangeInput
                          displayString={params.displayString}
                          currentDate={params.datePickerCurrentDate}
                          selectedDates={[...params.selectedDates]}
                          onDayClick={params.handleDayClick}
                          onDayMouseOver={params.handleDateMouseOver}
                          showDatePicker={params.showDatePicker}
                          onNextClick={params.incrementDate}
                          onPrevClick={params.decrementDate}
                          onMouseDown={params.toggleDatePicker}
                          onClickOutside={params.hideDatePicker}
                          placeholder={'Select Dates...'}
                          showTotalNights={false}
                          enablePastDates
                          noPortal
                        />
                      );
                    }}
                  />
                </label>

                {/* People */}
                <label className="w-[260px]">
                  <span className="font-bold text-black mb-5px block">People *</span>
                  <GuestAgesPicker
                    numberOfAdults={guestAges.numberOfAdults}
                    agesOfAllChildren={guestAges.agesOfAllChildren}
                    onUpdate={(numberOfAdults, agesOfAllChildren) => {
                      setGuestAges(numberOfAdults, agesOfAllChildren || []);
                      if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
                        // @ts-ignore
                        window.gtag('event', `${GA_FLOW_TAG}_set-guest`, {
                          'num-adults': numberOfAdults,
                          'child-ages': agesOfAllChildren,
                        });
                      }
                    }}
                  />
                </label>
              </div>

              <div className="flex space-x-20px">
                {/* Type */}
                <label className="w-[260px]">
                  <span className="font-bold text-black mb-5px block">Type</span>
                  <Multiselect
                    options={props.typesOptions}
                    className={classNames('bg-ivory', {
                      'o:opacity-50 pointer-events-none':
                        props.getTypesResultsNetworkStatus === ENetworkRequestStatus.PENDING,
                    })}
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-10"
                    disabled={props.getTypesResultsNetworkStatus === ENetworkRequestStatus.PENDING}
                    onUpdate={values => {
                      props.setSelectedFilterIds(values);
                      if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
                        // @ts-ignore
                        window.gtag('event', `${GA_FLOW_TAG}_select-type`, {
                          'selected-type': values.join(','),
                        });
                      }
                    }}
                    staticOptions={[
                      {
                        value: '-1',
                        label: 'All',
                      },
                    ]}
                    selectedValues={isNil(props.selectedFilterIds) ? [] : props.selectedFilterIds}
                  />
                </label>

                {/* Activity or Supplier */}
                <label className="w-[750px]">
                  <span className="font-bold text-black mb-5px block">Activity or Supplier</span>
                  <Multiselect
                    options={[]}
                    className="bg-ivory"
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-10"
                    selectedValues={
                      isNil(props.selectedActivityOrSupplier)
                        ? []
                        : [`${props.selectedActivityOrSupplier.type}-${props.selectedActivityOrSupplier.id}`]
                    }
                    onUpdate={values => {
                      if (values.length === 0) {
                        props.setSelectedActivityOrSupplier(null);
                        return;
                      }
                      const [type, id] = values[0].split('-');
                      props.setSelectedActivityOrSupplier({ id, type: type as 'activity' | 'supplier' });
                      if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
                        // @ts-ignore
                        window.gtag('event', `${GA_FLOW_TAG}_select-act`, {
                          'selected-type': type,
                          'selected-id': id,
                        });
                      }
                    }}
                    hideDropdownArrow
                    isEnableLookupSearch
                    onLookupSearch={props.activityOrSupplierSelectOnLookup}
                    isCloseOnSelect={true}
                    isSingleSelectMode={true}
                    hideCheckboxes={true}
                    noOptionsAvailableText="Please type and search to lookup activities or suppliers"
                  />
                </label>
              </div>

              <FluidButton
                disabled={isNil(props.selectedRegionId) || isNil(props.countryCode) || selectedDates.length === 0}
                onClick={props.onSearchButtonClick}
                type="primary"
                isLoading={getSearchResultsNetwork === ENetworkRequestStatus.PENDING}
              >
                Search
              </FluidButton>
            </div>
          </div>

          {getSearchResultsNetwork === ENetworkRequestStatus.ERROR && (
            <ErrorBar message="Failed to load search results" ctaCaption="Please reload the page and try again" />
          )}

          <AddAncillarySearchResultsTable
            className="flex-grow h-[100px]"
            primaryColumnId="activity"
            primaryColumnName="Activity"
            currencySymbol={props.currencySymbol}
            handleSelectSearchResult={(baseProductId, variantId) => {
              props.setSelectedSearchResult({ baseProductId, variantId });
            }}
            searchResults={props.searchResults}
            selectedSearchResult={props.selectedSearchResult}
            isLoading={getSearchResultsNetwork === ENetworkRequestStatus.PENDING}
            baseProductNameRenderer={searchResult => searchResult.baseProductName}
          />
        </div>

        {showRightPanel && (
          <AddAncillaryRightHandPanel
            domainName="Activity"
            currencySymbol={props.currencySymbol}
            errorIds={props.errorIds}
            handleAddProduct={props.handleAddActivity}
            selectedProduct={props.selectedActivityProduct}
            slotContent={
              <React.Fragment>
                {/* the date carousel and label */}
                {!isNil(props.selectedActivityProduct) && (
                  <div className="confirm-date flex flex-col space-y-5px">
                    <span className="text-xs text-gray-100 uppercase">Confirm Date</span>
                    <DatePickerCarousel
                      numberOfDatesToShow={4}
                      selectedDate={props.selectedActivityProduct.userData.rate?.date || ''}
                      startDate={props.searchInstanceVars.startAndEndDate?.startDate || ''}
                      endDate={props.searchInstanceVars.startAndEndDate?.endDate || ''}
                      dateToTextMap={Object.fromEntries(
                        Object.entries(props.selectedActivityProduct?.rates).map(([rateDate, rate]) => {
                          if (rate.hasRateForDate && isAncillaryRatedResult(rate)) {
                            if (rate.rateIsOnRequest) {
                              return [rateDate, '-'];
                            }
                            return [
                              rateDate,
                              `${props.currencySymbol}` +
                                formatNumberToShortThousands(parseInt((rate as AncillaryRatedResult).price!)),
                            ];
                          }
                          return [rateDate, ''];
                        })
                      )}
                      disabledDates={Object.values(props.selectedActivityProduct.rates)
                        .filter(rate => !rate.hasRateForDate)
                        .map(r => r.date)}
                      setSelectedDate={d => {
                        props.updateSelectedActivityProductWithUserData({
                          rate: props.selectedActivityProduct?.rates[d],
                        });
                      }}
                    />
                  </div>
                )}
              </React.Fragment>
            }
            updateSelectedProductWithUserData={props.updateSelectedActivityProductWithUserData}
            productLoad={props.getActivityProductNetwork}
          />
        )}
      </div>
    </GeneralModal>
  );
};
