import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Actions from 'store/modules/bookingManager/subdomains/externalIds/actions';
import * as Selectors from 'store/modules/bookingManager/subdomains/externalIds/selectors';

export const TravelPartnerRef: React.FC = () => {
  const dispatch = useDispatch();
  const editing = useSelector(Selectors.editingSelector);

  const handleChange = useCallback(
    (e) => dispatch(Actions.setTravelPartnerRefAction(e.target.value)),
    [dispatch]
  );
  
  return (
    <div className="travel-partner-ref flex flex-col w-170px">
      <span className="block text-black font-pt-sans font-bold pb-5px">
        Travel Partner Ref.
      </span>
      <input
        value={editing.travelPartnerRef}
        type="text"
        className="travel-partner-ref-input focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 font-pt-sans"
        onChange={handleChange}
      />
    </div>
  );
};
