import { EPaymentMethod } from "../bookingBuilder/types";

export interface QuoteSubdomain {
  isQuoteDownloading: boolean;
}

export interface PaymentSubdomain {
  paymentMethod: EPaymentMethod | null;
}

export interface IBookingConfirmationDomain {
  quote: QuoteSubdomain;
  payment: PaymentSubdomain;
}

export const initialState: IBookingConfirmationDomain = {
  quote: {
    isQuoteDownloading: false,
  },
  payment: {
    paymentMethod: null,
  },
};
