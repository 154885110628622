import booking from './md/booking.md';
import bookingOnRequest from './md/booking--on-request.md';
import bookingCC from './md/booking--bt.md';
import bookingBT from './md/booking--cc.md';

import bookingConfirmed from './md/booking-confirmed.md';
import bookingConfirmedOnRequest from './md/booking-confirmed--on-request.md';
import bookingConfirmedCC from './md/booking-confirmed--cc.md';
import bookingConfirmedBT from './md/booking-confirmed--bt.md';

import editGuard from './md/edit-guard.md';
import amendBooking from './md/booking-amend.md';
import cancelBooking from './md/booking-cancel.md';
import requestBooking from './md/booking-request.md';

import searchRequired from './md/search-required.md';

import passwordResetDescription from './md/password-reset--description.md';
import passwordResetComplete from './md/password-reset--complete.md';
import passwordResetErrorUnknown from './md/password-reset--error--unknown.md';

import setPasswordErrorUnknown from './md/set-password--error--unknown.md';
import setPasswordErrorExpired from './md/set-password--error--expired.md';
export default {
  setPassword: {
    error: {
      unknown: setPasswordErrorUnknown,
      expired: setPasswordErrorExpired,
    },
  },
  passwordReset: {
    description: passwordResetDescription,
    complete: passwordResetComplete,
    error: {
      unknown: passwordResetErrorUnknown,
    },
  },
  amendBooking,
  editGuard,
  searchRequired,
  booking: {
    cancel: cancelBooking,
    request: requestBooking,
    default: booking,
    onRequest: bookingOnRequest,
    cc: bookingCC,
    bt: bookingBT,
    confirmed: {
      default: bookingConfirmed,
      onRequest: bookingConfirmedOnRequest,
      cc: bookingConfirmedCC,
      bt: bookingConfirmedBT,
    },
  },
};
