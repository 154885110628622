import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { salesRepsOrderedSelector } from 'store/modules/travelAgentList/selectors';

export interface ISalesRepSelectProps {
  className?: string;
  value: string | null;
  onChange: (v: string | null) => void;
}

export const SalesRepSelect: React.FC<ISalesRepSelectProps> = props => {
  const salesReps = useSelector(salesRepsOrderedSelector);
  let salesRepsOptions: ISingleSelectOption[] = useMemo(
    () => (salesReps || []).map(c => ({ value: c.uuid, label: `${c.firstName} ${c.lastName}`.trim() })),
    [salesReps]
  );

  salesRepsOptions = [
    {
      label: 'None',
      value: '00000000-0000-0000-0000-000000000000',
    },
    ...salesRepsOptions,
  ];

  const handleChange = useCallback((value?: string) => props.onChange(value ?? null), [props.onChange]);

  return (
    <SingleSelect
      fieldId="filterSalesRep"
      className={props.className}
      value={props.value ?? undefined}
      options={salesRepsOptions}
      onChange={handleChange}
      maxVisibleItems={6}
      scrollToSelectedItem
      showSelectedOption
      labelWhenNothingSelected="All"
    />
  );
};
