import React from 'react';
import { Helmet } from 'react-helmet';

export interface IGoogleAnalyticsSnippetProps {
  appId: string;
  userUiid: string;
}

export const GoogleAnalyticsSnippet = ({ appId, userUiid }: IGoogleAnalyticsSnippetProps) => {
  const src = `https://www.googletagmanager.com/gtag/js?id=${appId}`
  // @ts-ignore
  return  <Helmet>
    <script type="text/javascript" async src={src}></script>
    <script>
    {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${appId}', {
          'user_id': '${userUiid}'
        });
    `}
    </script>
  </Helmet>;
}
