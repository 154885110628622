import { ITravelAgent, ENetworkRequestStatus } from 'services/BackendApi';

export enum SortableFields {
  NAME = 'name',
  COUNTRY = 'country',
  EMAIL = 'email',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ECompanyManagerType {
  BOOKING = 'booking',
  TA_MANAGER = 'taManager',
}

export const CompanyManagerUserField = Object.freeze({
  [ECompanyManagerType.BOOKING]: 'companyBookingManager',
  [ECompanyManagerType.TA_MANAGER]: 'companyTaManager',
});

export interface ICompanyManagersDomain {
  travelAgents: ITravelAgent[] | null;
  savedBookingManagers: Set<string> | null;
  savedTaManagers: Set<string> | null;
  sortBy: SortableFields;
  sortOrder: SortOrder;
  request: {
    get: ENetworkRequestStatus;
    post: ENetworkRequestStatus;
    patch: ENetworkRequestStatus;
  };
}

export const initialState: ICompanyManagersDomain = {
  travelAgents: null,
  savedBookingManagers: null,
  savedTaManagers: null,
  sortBy: SortableFields.EMAIL,
  sortOrder: SortOrder.ASC,
  request: {
    get: ENetworkRequestStatus.IDLE,
    post: ENetworkRequestStatus.IDLE,
    patch: ENetworkRequestStatus.IDLE,
  },
};
