import React from 'react';
import { formatPrice, formatPriceCents } from 'utils';
interface ITADepositAccountRow {
  thereIsCredit: boolean;
  creditLimit: string | null;
  balance: number;
  symbol: string;
  loading: boolean;
}
export const TADepositAccountRow: React.FC<ITADepositAccountRow> = ({
  thereIsCredit,
  creditLimit,
  balance,
  symbol,
  loading,
}) => {
  const availability = Number(creditLimit) * 100 - (balance || 0);
  const availabilityClassName = availability <= 0 ? 'text-[#FF0000]' : '';

  return (
    <div className="justify-start h-[25px] items-center gap-2.5 inline-flex font-pt-sans">
      <div className="w-[25px] h-[25px] relative">
        <div className="w-[25px] h-[25px] top-[-0.50px] absolute bg-white rounded-full border border-black" />
        <div className="left-[9px] top-[4.50px] absolute text-center text-black text-xs font-normal">{symbol}</div>
      </div>
      <div className="w-[90px] items-center flex text-center text-black text-sm font-normal">
        {loading
          ? <i className="fas fa-circle-notch fa-spin text-brown-140"></i>
          : <span className={availabilityClassName}>{formatPriceCents(availability)}</span>
        }
      </div>
      {thereIsCredit && <div className="w-[90px] items-center flex text-center text-green-message text-sm font-normal">{loading ? <i className="fas fa-circle-notch fa-spin text-brown-140"></i> : (formatPrice(creditLimit) ?? '0.00')}</div>}
    </div>
  );
};
