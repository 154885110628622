import { EUserType } from 'services/BackendApi';
import { IBasketBuild } from 'services/BackendApi/types/Basket';

export interface IBasketDomain {
  basketBuild: IBasketBuild | null;
  shouldShowLeaveWarningInBookingBuilder: boolean;
}

export const initialState: IBasketDomain = {
  basketBuild: null,

  // excuse the verbose name; this is specifically powering the "are you sure you want to leave"
  // modal in the booking builder
  shouldShowLeaveWarningInBookingBuilder: false,
};
