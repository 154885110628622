import React, { useRef, useEffect } from 'react';

export interface IClickOutsideProps {
  className?: string;
  onClickOutside: (event) => void;
  children?: React.ReactNode;
}

export const ClickOutside: React.FC<IClickOutsideProps> = React.memo(props => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseDown = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        props.onClickOutside(event);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    return () => document.removeEventListener('mousedown', handleMouseDown);
  }, [ref]);

  return (
    <div className={props.className} ref={ref}>
      {props.children}
    </div>
  );
});
