import { ENetworkRequestStatus, IImportStatus } from 'services/BackendApi';

export enum EImportEntity {
  RATES = 'rates',
  PRODUCTS = 'products',
  STATIC_RATES_AVAILABILITY = 'static_rates_availability',
  ANCILLARY_SUPPLIERS = 'ANCILLARY_SUPPLIERS',
  ANCILLARY_ACTIVITIES = 'ANCILLARY_ACTIVITIES',
  ANCILLARY_TRANSFERS = 'ANCILLARY_TRANSFERS',
  ANCILLARY_ACTIVITIES_RATES = 'ANCILLARY_ACTIVITIES_RATES',
  ANCILLARY_TRANSFERS_RATES = 'ANCILLARY_TRANSFERS_RATES',
  ANCILLARY_GROUND_SERVICES = 'ANCILLARY_GROUND_SERVICES',
  ANCILLARY_GROUND_SERVICES_RATES = 'ANCILLARY_GROUND_SERVICES_RATES',
}

export interface IImportUiState {
  confirmationModalOpen: boolean;
}

export interface IImportModel {
  uiState: IImportUiState;
  importRequestIsPending: boolean;
  error: any | null;
  message?: string;
  latestStatus: IImportStatus | null;
  workbookId: string | null;
  importConfirmedWaitingForLatestStatus: boolean; // true for the space of between between import confirmation and latest status response
}

export const initialState: IImportModel = {
  uiState: {
    confirmationModalOpen: false,
  },
  importRequestIsPending: false,
  error: null,
  latestStatus: null,
  workbookId: null,
  importConfirmedWaitingForLatestStatus: false,
};
