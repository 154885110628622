import validators from '../validators';
import { prop } from 'ramda';

import errors from '../errors';

export default validators.shape({
  currentPassword: validators.text(),
  newPassword: validators.match(
    /^(?=.*[a-z])(?=.*[A-Z]|[áéíóúàèìòùäëïöüâêîôûÁÉÍÓÚÀÈÌÒÙÄËÏÖÜÂÊÎÔÛª!"·$%&/()=?¿º'^*¨;:_>¡`+´,.\-\\|@#¬[\]}{])(?=.*\d)[a-zA-ZáéíóúàèìòùäëïöüâêîôûÁÉÍÓÚÀÈÌÒÙÄËÏÖÜÂÊÎÔÛª!"·$%&/()=?¿º'^*¨;:_>¡`+´,.\-\\|@#¬[\]}{\d]{8,}$/,
    'passwordStrength'
  ),
  newPasswordConfirm: validators.matches('newPassword', prop('passwordsMatch', errors)),
});
