import { ENetworkRequestStatus } from 'services/BackendApi';
import { IInventoryStaysFilter, IInventoryStayListingItem, EInventoryStayListingItemType, IInventoryTag, IInventorySupplierSimple } from 'services/InventoryAPI';

export interface IInventoryStaysDomain {
  listingPage: {
    items: IInventoryStayListingItem[] | null;
    filter: IInventoryStaysFilter;
  };
  createEditPage : {
    stay: IInventoryStayListingItem | null;
    regions: IInventoryTag[] | null;
    ratings: string[] | null;
    suppliers: IInventorySupplierSimple[] | null;
    bestforFilters: any[] | null;
    amenityFilters: any[] | null;
    otherFilters: any[] | null;
  };
  request: {
    listingPageGet: ENetworkRequestStatus;
    ratingsGet: ENetworkRequestStatus;
    regionsGet: ENetworkRequestStatus;
    bestforFiltersGet: ENetworkRequestStatus;
    amenityFiltersGet: ENetworkRequestStatus;
    otherFiltersGet: ENetworkRequestStatus;
    stayGet: ENetworkRequestStatus;
    stayPost: ENetworkRequestStatus;
    stayPut: ENetworkRequestStatus;
    suppliersSearch: ENetworkRequestStatus;
  };
}

export const initialState: IInventoryStaysDomain = {
  listingPage: {
    items: [],
    filter: {
      type: EInventoryStayListingItemType.HOTEL,
      page: 1,
      page_size: 10,
      order_by: 'public_id',
      order_type: 'asc',
    },
  },
  createEditPage : {
    stay: null,
    regions: null,
    ratings: null,
    suppliers: null,
    bestforFilters: null,
    amenityFilters: null,
    otherFilters: null,
  },
  request: {
    listingPageGet: ENetworkRequestStatus.IDLE,
    ratingsGet: ENetworkRequestStatus.IDLE,
    regionsGet: ENetworkRequestStatus.IDLE,
    stayGet: ENetworkRequestStatus.IDLE,
    stayPost: ENetworkRequestStatus.IDLE,
    stayPut: ENetworkRequestStatus.IDLE,
    suppliersSearch: ENetworkRequestStatus.IDLE,
    bestforFiltersGet: ENetworkRequestStatus.IDLE,
    amenityFiltersGet: ENetworkRequestStatus.IDLE,
    otherFiltersGet: ENetworkRequestStatus.IDLE,
  },
}
