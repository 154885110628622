import React from 'react';
import FluidButton from 'ui/FluidButton';

export const AddNewRate = ({ onCta }: { onCta: () => void }) => {
  return (
    <FluidButton onClick={onCta} type="secondary" className="w-[200px] self-start">
      Add New Rate
    </FluidButton>
  );
};
