import React from 'react';
import classnames from 'classnames';
import { ESortOrder } from 'store/common/types';
import { ITableColumn, ITableRow } from './types';
import { TableColumn } from './TableColumn';
import { TableRow } from './TableRow';
import { EmptyTableRow } from './EmptyTableRow';
import { BlockingOverlay } from 'ui/BlockingOverlay';

interface ITableProps {
  columns: ITableColumn[];
  rows: ITableRow[];
  sortBy: string;
  sortOrder: ESortOrder;
  onSort: (newSortBy: string, newSortOrder: string) => void;
  messageWhenEmpty: string;
  stickyHeader?: boolean;
  loading?: boolean;
  className?: string;
  headerRowClassName?: string;
  emptyTableRowClassName?: string;
  noHeaderBorder?: boolean;
  evenColor?: string;
}

export const Table: React.FC<ITableProps> = React.memo(
  ({
    columns,
    rows,
    sortBy,
    sortOrder,
    onSort,
    className,
    messageWhenEmpty,
    loading = false,
    stickyHeader = false,
    headerRowClassName = '',
    emptyTableRowClassName = '',
    noHeaderBorder = false,
    evenColor = 'gray-10',
  }) => {
    const visibleColumns = columns.filter(column => !column.isHidden);
    const tableClassName = classnames(
      className,
      'table table-xs table-fixed w-full border-collapse text-left font-pt-sans text-black',
      {
        'opacity-30': loading,
      }
    );

    return (
      <BlockingOverlay showWhen={loading}>
        <table className={tableClassName}>
          <thead
            className={classnames({
              'z-1 sticky top-0': stickyHeader,
            })}
          >
            <tr
              className={classnames(headerRowClassName, 'default:bg-ivory h-10 uppercase text-xs text-gray-100', {
                'border border-solid border-gray-40': !stickyHeader && !noHeaderBorder,
              })}
            >
              {visibleColumns.map(column => (
                <TableColumn key={column.id} column={column} sortBy={sortBy} sortOrder={sortOrder} onSort={onSort} />
              ))}
            </tr>
          </thead>

          <tbody>
            {rows.length === 0 ? (
              <EmptyTableRow
                className={emptyTableRowClassName}
                cellsQty={visibleColumns.length}
                text={messageWhenEmpty}
              />
            ) : (
              rows.map((row: ITableRow, rowIndex) => <TableRow key={row.id} row={row} rowIndex={rowIndex} evenColor={evenColor} />)
            )}
          </tbody>
        </table>
      </BlockingOverlay>
    );
  }
);
