import { call, takeLatest, put } from 'redux-saga/effects';
import { CREATE_TASK_COMMENT_REQUEST, createTaskCommentFailureAction, CreateTaskCommentRequestAction, createTaskCommentSuccessAction, listTaskCommentsRequestAction, removeFocusedCommentAction } from '../actions';
import { enqueueNotification } from 'store/modules/ui';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';
import { AxiosResponse } from 'axios';
import { ICreateTaskCommentResponse } from 'services/TaskManagementApi/types/TaskManagementResponse';


export function* createTaskCommentSaga(action: CreateTaskCommentRequestAction) {
  try {
    const taskManagementApi = makeTaskManagementApi();
    const res: AxiosResponse<ICreateTaskCommentResponse> = yield call(taskManagementApi.createTaskComment, action.taskId, action.comment?.trim());

    yield put(createTaskCommentSuccessAction());
    yield put(removeFocusedCommentAction(action.commentId));
    yield put(listTaskCommentsRequestAction(action.taskId));

    const warnings = res.data.warnings;
    for (const warning of warnings) {
      yield put(enqueueNotification({ message: warning, options: { variant: 'error' } }));
    }
  } catch (e) {
    yield put(createTaskCommentFailureAction(e));
    yield put(enqueueNotification({ message: 'Unable to save comment', options: { variant: 'error' } }));
  }
}

export function* watchCreateTaskCommentSaga() {
  yield takeLatest(
    [CREATE_TASK_COMMENT_REQUEST],
    createTaskCommentSaga
  );
}
