import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import Warning from 'ui/Icons/warning.component.svg';

import { PrimaryButton, SecondaryButton } from 'pureUi/Buttons';

import { useTranslation } from 'react-i18next';
import { LiveRateItemStyles, LiveRatePricesModalStyles } from './HotelContainer.styles';
import { ILiveRatePricesModalItem } from 'services/BackendApi';
import { renderAdultsChildrenString, formatPrice, getCurrencySymbol } from 'utils';
import { TCurrencyCode } from '../../interfaces';
import { theme } from '../../../tailwind.config';

interface IRateRow {
  liveRate: ILiveRatePricesModalItem;
  liveRatesCurrency: TCurrencyCode;
  isAvailable: boolean;
  isPriceNew: boolean;
  isMealPlanOutdated?: boolean;
  isMealPlanNew?: boolean;
}

const RateRow: React.FC<IRateRow> = ({
  liveRate,
  liveRatesCurrency,
  isAvailable,
  isPriceNew,
  isMealPlanOutdated = false,
  isMealPlanNew = false,
}) => {
  return (
    <div className="rate">
      <div className={classNames('rateLeft', { 'line-through': !isAvailable || isMealPlanOutdated })}>
        <span className={classNames('rateLeftNumber', { rateLeftNew: isMealPlanNew })}>
          Rate #{isMealPlanNew ? 2 : 1}.
        </span>{' '}
        <span className={classNames('rateLeftMealPlan', { rateLeftNew: isMealPlanNew })}>
          {isMealPlanNew ? liveRate.newMealPlan : liveRate.prevMealPlan}. TAX INCLUDED
        </span>
      </div>
      {!isMealPlanOutdated && (
        <div className="rateRight">
          {isPriceNew && <span className="rateRightNow">Now</span>}
          {isAvailable ? (
            <span className={classNames('rateRightPrice', { rateRightPriceNew: isPriceNew })}>
              {`${getCurrencySymbol(liveRatesCurrency)}${formatPrice(liveRate.newPrice / 100)}`}
            </span>
          ) : null}
        </div>
      )}
    </div>
  );
};

interface ILiveRateItemProps {
  liveRate: ILiveRatePricesModalItem;
  liveRatesCurrency: TCurrencyCode;
}

const LiveRateItem = ({ liveRate, liveRatesCurrency }: ILiveRateItemProps) => {
  const numberOfAdults = liveRate.guestAges.numberOfAdults;
  const agesOfAllChildren = liveRate.guestAges.agesOfAllChildren;
  const numberOfChildren = liveRate.guestAges.agesOfAllChildren?.length ?? 0;
  const numberOfGuests = numberOfAdults + numberOfChildren;
  const isAvailable = !!liveRate.newPrice;
  const hasRateChanged = isAvailable && Math.abs(liveRate.newPrice - liveRate.prevPrice) > 0;
  const hasMealPlanChanged = isAvailable && liveRate.prevMealPlan !== liveRate.newMealPlan;

  const guestsTitle = useMemo(() => `${numberOfGuests} Guests`, [numberOfGuests]);
  const guestsValueAsString = useMemo(() => renderAdultsChildrenString(numberOfAdults, agesOfAllChildren), [
    numberOfAdults,
    agesOfAllChildren,
  ]);

  return (
    <LiveRateItemStyles>
      <div className="liveRateItem">
        <p className="headingTitle">{liveRate.title}</p>
        <div className="guests">
          <p className={classNames('guestsLeft', { 'line-through': !isAvailable })}>
            {guestsTitle}{' '}
            <span className={classNames('guestsDetails', { 'line-through': !isAvailable })}>{guestsValueAsString}</span>
          </p>
          <div className={classNames('rateRight')}>
            {isAvailable && hasRateChanged && (
              <span className={classNames('rateRightPrice', { 'line-through': hasRateChanged })}>
                {`${getCurrencySymbol(liveRatesCurrency)}${formatPrice(liveRate.prevPrice / 100)}`}
              </span>
            )}
            {!isAvailable && <span className={classNames('rateRightNotAvailable')}>Not available</span>}
          </div>
        </div>
        <RateRow
          liveRate={liveRate}
          liveRatesCurrency={liveRatesCurrency}
          isPriceNew={hasRateChanged}
          isAvailable={isAvailable}
          isMealPlanOutdated={hasMealPlanChanged}
        />
        {hasMealPlanChanged && (
          <RateRow
            liveRate={liveRate}
            liveRatesCurrency={liveRatesCurrency}
            isPriceNew={hasRateChanged}
            isAvailable={isAvailable}
            isMealPlanNew={hasMealPlanChanged}
          />
        )}
      </div>
    </LiveRateItemStyles>
  );
};

interface LiveRatePricesModalProps {
  onConfirm?: Function;
  onClose?: Function;
  isConfirmDisabled?: boolean;
  currency: TCurrencyCode;
  liveRates: ILiveRatePricesModalItem[];
  total: number;
}

export const LiveRatePricesModal = (props: LiveRatePricesModalProps) => {
  const { t } = useTranslation();
  const { onConfirm, onClose, isConfirmDisabled, currency } = props;
  const atLeastOneRoomNotAvailable = !!props.liveRates.find(liveRate => !liveRate.newPrice);
  const prevTotalPriceAccom = props.liveRates.reduce((acc, liveRate) => {
    return acc + liveRate.prevPrice;
  }, 0);
  const newTotalPriceAccom = props.liveRates.reduce((acc, liveRate) => {
    return acc + liveRate.newPrice;
  }, 0);
  const priceDifferenceAccom = newTotalPriceAccom - prevTotalPriceAccom;
  const newTotal = props.total + priceDifferenceAccom;
  const atLeastOneRateHasChanged = !!props.liveRates.find(
    liveRate => Math.abs(liveRate.newPrice - liveRate.prevPrice) > 0
  );

  const handleConfirm = useCallback(() => onConfirm && onConfirm(), [onConfirm]);
  const handleCancel = useCallback(() => onClose && onClose(), [onClose]);

  return (
    <LiveRatePricesModalStyles>
      <div className="icon">
        <Warning fill={theme.colors['gray-40']} />
      </div>
      <p className="title">The price has been updated</p>
      <p className="message">
        Upon cross checking the rates and meal plans with our supplier there are some differences to the accommodation
        and/or meal plan. You can review the changes in red below:
      </p>

      {props.liveRates.map(liveRate => {
        const { numberOfAdults, agesOfAllChildren } = liveRate.guestAges;
        const key = `${numberOfAdults}${agesOfAllChildren?.join(',')}${liveRate.liveRate.externalRateId}${
          liveRate.prevPrice
        }${liveRate.newPrice}`;
        return <LiveRateItem liveRate={liveRate} liveRatesCurrency={currency} key={key} />;
      })}

      <div className="liveRatePricesModalTotals">
        <div className="totalCostBefore">
          <span className="totalCostTitle">TOTAL COST BEFORE:</span>
          <span className="totalCostRate">{`${getCurrencySymbol(currency)}${formatPrice(props.total / 100)}`}</span>
        </div>
        <div className="totalCostAfter">
          <span className="totalCostTitle">TOTAL COST NOW:</span>
          <span
            className={classNames('totalCostRateNow', {
              rateRightPriceNew: atLeastOneRateHasChanged || atLeastOneRoomNotAvailable,
            })}
          >{`${getCurrencySymbol(currency)}${formatPrice(newTotal / 100)}`}</span>
        </div>
      </div>

      <p className="liveRateTotalPhrase">* TOTAL COST with Transfers, Ground Services and Extras includes.</p>

      <div className="actions">
        <SecondaryButton type="button" onClick={handleCancel} className="button-cancel">
          {/* @ts-ignore React 18 types & 3rd party code */}
          {t('buttons.cancel')}
        </SecondaryButton>
        <PrimaryButton
          type="button"
          onClick={handleConfirm}
          disabled={isConfirmDisabled || atLeastOneRoomNotAvailable}
          className="button-confirm"
        >
          {/* @ts-ignore React 18 types & 3rd party code */}
          {t('buttons.bookLive')}
        </PrimaryButton>
      </div>
    </LiveRatePricesModalStyles>
  );
};
