import { ENetworkRequestStatus } from 'services/BackendApi';
import {
  EDepositAccountRowType,
  EManualBookingLedgerRowType,
  EFinanceRowTypes,
  IBookingLedgerSortableItems,
  IDepositAccountSortableItems,
  IDepositAccountRow,
  IStatementPdf,
  IBookingLedgerRequestData,
  IDepositAccountRequestData,
  IPaymentMethod,
} from 'services/BookingManagerApi';
import { BookingStatusTypes } from 'config/enums';
import { EBankAccount, TCurrencyCode } from 'interfaces';
import * as DateFNS from 'date-fns';

export interface ILedgerPagination {
  offset: number;
  limit: number;
  totalRows: number;
}

export interface IDueDate {
  date: string;
  amountCents: number;
}

export interface IBookingLedgerPageRow {
  row: IBookingLedgerRow;
  balance: number;
}
export interface IBookingLedgerRow {
  companyUuid: string;
  bookingUuid?: string;
  canViewBooking?: boolean;
  humanReadableId?: string;
  travelAgentUuid?: string;
  travelAgentEmail?: string;
  status?: typeof BookingStatusTypes[keyof typeof BookingStatusTypes];
  guestTitle?: string | null;
  guestFirstName?: string;
  guestLastName?: string;
  currency: TCurrencyCode;
  date: string;
  type: EFinanceRowTypes;
  amountCents: number;
  parentNetTotal: number;
  dueDates?: IDueDate[];
  uuid?: string;
  uploadUuid?: string;
  uploadUrl?: string;
  uploadName?: string;
}

export interface IManualBookingLedgerRowForSave {
  uuid?: string;
  date: string;
  type?: EManualBookingLedgerRowType;
  amountCents: number;
  uploadUuid?: string;
  uploadUrl?: string;
  uploadName?: string;
}

export interface IBookingLedger {
  lastPageTotal: number;
  positiveTotal: number;
  negativeTotal: number;
  netTotal: number;
  pagination: ILedgerPagination;
  rows: IBookingLedgerPageRow[];
}

export interface ILedgerTotals {
  currency: TCurrencyCode;
  netTotal: number;
  positiveTotal: number;
  negativeTotal: number;
}

export interface IDepositAccountRowForSave {
  uuid?: string;
  date: string;
  type?: EDepositAccountRowType;
  amountCents: number;
  uploadUuid?: string;
  uploadUrl?: string;
  uploadName?: string;
  notes?: string;
  bankAccount?: string;
  paymentReceiverCompanyUuid?: string;
}

export interface IDepositAccountData {
  rows: { balance: number; row: IDepositAccountRow }[];
  balanceCarriedForward: number;
  positiveTotal: number;
  negativeTotal: number;
  netTotal: number;
}

export interface IPagination {
  currentPage: number;
  itemsPerPage: number;
  totalPages: number;
}

export interface ISorting {
  sortBy: IDepositAccountSortableItems;
  sortOrder: 'asc' | 'desc';
}

export interface IDepositAccountDomain {
  currency: TCurrencyCode | null;
  data: IDepositAccountData | null;
  pagination: IPagination;
  sorting: ISorting;
  filter?: IDepositAccountRequestData['filter'];
  requests: {
    rowsLoad: ENetworkRequestStatus;
    rowDelete: ENetworkRequestStatus;
  };
  errors: {
    rowsLoadError?: Error;
  };
  isDeleteDARModalOpen: boolean;
  depositAccountRowUuidToDelete: string | null;
  csvExport: IExport;
  pdf: IPdf;
  depositAccountModal: {
    isDepositAccountModalOpen: boolean;
    initialBCFRowExists: boolean | null;
    firstRowDate: string | null;
    data: IDepositAccountBCFData | null;
    paymentMethods: {
      list: IPaymentMethod[] | null;
      defaultPaymentMethodCode: string | null;
    };
    requests: {
      initialBCFRowLoad: ENetworkRequestStatus;
      bcfRowCreate: ENetworkRequestStatus;
      getPaymentMethods: ENetworkRequestStatus;
    };
  };
}

export interface IBookingLedgerBCFData {
  uuid?: string;
  type?: EManualBookingLedgerRowType;
  date?: string;
  amountCents?: number;
  uploadUuid?: string;
  uploadUrl?: string;
  uploadName?: string;
}

export interface IDepositAccountBCFData {
  uuid?: string;
  type?: EDepositAccountRowType;
  notes?: string;
  date?: string;
  amountCents?: number;
  uploadUuid?: string;
  uploadUrl?: string;
  uploadName?: string;
  bankAccount?: EBankAccount;
}

export interface IExport {
  request: ENetworkRequestStatus;
  exportUuid: string | null;
}

export interface IPdf {
  downloadedPdfs: IStatementPdf[];
  requests: {
    generate: ENetworkRequestStatus;
    downloadHistory: ENetworkRequestStatus;
  };
}

export interface ILedgerDomain {
  // summary
  selectedCompany: string | null;
  depositTotals: ILedgerTotals[];
  depositTotalsLoad: ENetworkRequestStatus;
  depositTotalsError?: Error;

  bookingTotals: ILedgerTotals[];
  bookingTotalsLoad: ENetworkRequestStatus;
  bookingTotalsError?: Error;

  summaryPdf: IPdf;
  summaryCsvExport: {
    bookingLedger: IExport;
    depositAccount: IExport;
  };
  // booking ledger
  bookingsByCurrency: {
    data: IBookingLedger | null;
    bookingLedgerLoad: ENetworkRequestStatus;
    bookingLedgerError?: Error;
    sortBy?: IBookingLedgerSortableItems;
    sortOrder?: 'asc' | 'desc';
    currentPage: number;
    itemsPerPage: number;
    filter: IBookingLedgerRequestData['filter'];
    currency: TCurrencyCode | null;
    csvExport: IExport;
    request: {
      updateFilters: ENetworkRequestStatus;
    };
    pdf: IPdf;
  };
  balanceCarriedForward: {
    isBCFModalOpen: boolean;
    initialBCFRowExists: boolean | null;
    firstRowDate: string | null;
    data: IBookingLedgerBCFData | null;
    requests: {
      initialBCFRowLoad: ENetworkRequestStatus;
      bcfRowCreate: ENetworkRequestStatus;
    };
  };

  // deposit account
  depositAccount: IDepositAccountDomain;

  // ui stuff
  statementFilterFromDate: string | null;
  statementFilterToDate: string | null;
  statementFilterTypes: string[];
  statementFilterStatus: string[] | null;
  statementFilterBookingRefs: string[];

  statementFilterAmountMin: number | null;
  statementFilterAmountMax: number | null;

  depositFilterFromDate: string | null;
  depositFilterToDate: string | null;
  depositFilterStatus: string[];
  depositFilterBookingRef: string;
}

export const initialState: ILedgerDomain = {
  selectedCompany: null,
  depositTotals: [],
  depositTotalsLoad: ENetworkRequestStatus.IDLE,
  bookingTotals: [],
  bookingTotalsLoad: ENetworkRequestStatus.IDLE,
  summaryPdf: {
    downloadedPdfs: [],
    requests: {
      generate: ENetworkRequestStatus.IDLE,
      downloadHistory: ENetworkRequestStatus.IDLE,
    },
  },
  summaryCsvExport: {
    bookingLedger: {
      request: ENetworkRequestStatus.IDLE,
      exportUuid: null,
    },
    depositAccount: {
      request: ENetworkRequestStatus.IDLE,
      exportUuid: null,
    },
  },
  bookingsByCurrency: {
    data: null,
    bookingLedgerLoad: ENetworkRequestStatus.IDLE,
    currentPage: 0,
    itemsPerPage: 10,
    currency: null,
    filter: {},
    sortBy: 'date',
    sortOrder: 'desc',
    csvExport: {
      request: ENetworkRequestStatus.IDLE,
      exportUuid: null,
    },
    request: {
      updateFilters: ENetworkRequestStatus.IDLE,
    },
    pdf: {
      downloadedPdfs: [],
      requests: {
        generate: ENetworkRequestStatus.IDLE,
        downloadHistory: ENetworkRequestStatus.IDLE,
      },
    },
  },
  depositAccount: {
    currency: null,
    data: null,
    pagination: {
      currentPage: 0,
      itemsPerPage: 10,
      totalPages: 1,
    },
    sorting: {
      sortBy: 'date',
      sortOrder: 'desc',
    },
    filter: {
      fromDate: DateFNS.format(DateFNS.startOfMonth(new Date()), 'yyyy-MM-dd'),
      toDate: DateFNS.format(DateFNS.endOfMonth(new Date()), 'yyyy-MM-dd'),
      status: [],
    },
    requests: {
      rowsLoad: ENetworkRequestStatus.IDLE,
      rowDelete: ENetworkRequestStatus.IDLE,
    },
    depositAccountRowUuidToDelete: null,
    isDeleteDARModalOpen: false,
    errors: {},
    csvExport: {
      request: ENetworkRequestStatus.IDLE,
      exportUuid: null,
    },
    pdf: {
      downloadedPdfs: [],
      requests: {
        generate: ENetworkRequestStatus.IDLE,
        downloadHistory: ENetworkRequestStatus.IDLE,
      },
    },
    depositAccountModal: {
      isDepositAccountModalOpen: false,
      firstRowDate: null,
      initialBCFRowExists: null,
      data: null,
      paymentMethods: {
        list: null,
        defaultPaymentMethodCode: null,
      },
      requests: {
        initialBCFRowLoad: ENetworkRequestStatus.IDLE,
        bcfRowCreate: ENetworkRequestStatus.IDLE,
        getPaymentMethods: ENetworkRequestStatus.IDLE,
      },
    },
  },
  balanceCarriedForward: {
    isBCFModalOpen: false,
    firstRowDate: null,
    initialBCFRowExists: null,
    data: null,
    requests: {
      initialBCFRowLoad: ENetworkRequestStatus.IDLE,
      bcfRowCreate: ENetworkRequestStatus.IDLE,
    },
  },
  statementFilterFromDate: null,
  statementFilterToDate: null,
  statementFilterTypes: [],
  statementFilterStatus: null,
  statementFilterBookingRefs: [''],
  statementFilterAmountMin: null,
  statementFilterAmountMax: null,
  depositFilterFromDate: DateFNS.format(DateFNS.startOfMonth(new Date()), 'yyyy-MM-dd'),
  depositFilterToDate: DateFNS.format(DateFNS.endOfMonth(new Date()), 'yyyy-MM-dd'),
  depositFilterStatus: [],
  depositFilterBookingRef: '',
};
