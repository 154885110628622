import { call, takeLatest, select, put } from 'redux-saga/effects';
import { sortBy } from 'lodash-es';
import { AxiosResponse } from 'axios';

import { makeBackendApi, ICompaniesResponse, ICompany } from 'services/BackendApi';
import {
  getCompaniesSuccessAction,
  getCompaniesFailureAction,
  GET_COMPANIES_REQUEST
} from '../actions';
import { selectedTaSelector } from '../selectors';

export function* getCompaniesRequestSaga() {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);

    const result: AxiosResponse<ICompaniesResponse> = yield call(backendApi.getCompanies);
    const sortedCompanies = sortBy(
      result.data.data, (company: ICompany) => company?.name?.toLowerCase() ?? ''
    );
    yield put(getCompaniesSuccessAction(sortedCompanies));

  } catch (e) {
    yield put(getCompaniesFailureAction(e.message));
  }
}

export function* watchGetCompaniesRequest() {
  yield takeLatest([GET_COMPANIES_REQUEST], getCompaniesRequestSaga);
}
