import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi';

// Copy the following into your module `reducer.ts`
/*
import * as ExportCsvPollingReducerFunctions from 'store/modules/exportCsvPolling/reducer';

case Actions.TRIGGER_EXPORT_CSV_REQUEST:
  return ExportCsvPollingReducerFunctions.triggerRequest(state);

case Actions.TRIGGER_EXPORT_CSV_SUCCESS:
  return ExportCsvPollingReducerFunctions.triggerSuccess(state, action.exportUuid);

case Actions.TRIGGER_EXPORT_CSV_FAILURE:
  return ExportCsvPollingReducerFunctions.triggerFailure(state);

case Actions.POLL_EXPORT_CSV_REQUEST:
  return ExportCsvPollingReducerFunctions.pollRequest(state);

case Actions.POLL_EXPORT_CSV_SUCCESS:
  return ExportCsvPollingReducerFunctions.pollSuccess(state, action.signedUrl);

case Actions.POLL_EXPORT_CSV_CONTINUE:
  return ExportCsvPollingReducerFunctions.pollContinue(state);

case Actions.POLL_EXPORT_CSV_FAILURE:
  return ExportCsvPollingReducerFunctions.pollFailure(state);

case Actions.POLL_EXPORT_CSV_CANCEL:
  return ExportCsvPollingReducerFunctions.pollCancel(state);


...to make use of the below functions
*/

export const triggerRequest = state => {
  return produce(state, draftState => {
    draftState.exportCsvNamespace.triggerExportCsvLoad = ENetworkRequestStatus.PENDING;
    draftState.exportCsvNamespace.isWaitingForExport = true;
    draftState.exportCsvNamespace.exportSignedUrl = null;
    draftState.exportCsvNamespace.exportUuid = null;
    return draftState;
  });
};

export const triggerSuccess = (state, exportUuid) => {
  return produce(state, draftState => {
    draftState.exportCsvNamespace.triggerExportCsvLoad = ENetworkRequestStatus.SUCCESS;
    draftState.exportCsvNamespace.exportUuid = exportUuid;
    return draftState;
  });
};

export const triggerFailure = state => {
  return produce(state, draftState => {
    draftState.exportCsvNamespace.triggerExportCsvLoad = ENetworkRequestStatus.ERROR;
    draftState.exportCsvNamespace.exportUuid = null;
    draftState.exportCsvNamespace.isWaitingForExport = false;
    return draftState;
  });
};

export const pollRequest = state => {
  return produce(state, draftState => {
    draftState.exportCsvNamespace.pollExportCsvLoad = ENetworkRequestStatus.PENDING;
    return draftState;
  });
};

export const pollSuccess = (state, signedUrl) => {
  return produce(state, draftState => {
    draftState.exportCsvNamespace.isWaitingForExport = false;
    draftState.exportCsvNamespace.exportSignedUrl = signedUrl;
    draftState.exportCsvNamespace.pollExportCsvLoad = ENetworkRequestStatus.SUCCESS;
    draftState.exportCsvNamespace.triggerExportCsvLoad = ENetworkRequestStatus.IDLE;
    return draftState;
  });
};

export const pollContinue = state => {
  return produce(state, draftState => {
    draftState.exportCsvNamespace.pollExportCsvLoad = ENetworkRequestStatus.IDLE;
    return draftState;
  });
};

export const pollFailure = state => {
  return produce(state, draftState => {
    draftState.exportCsvNamespace.isWaitingForExport = false;
    draftState.exportCsvNamespace.exportSignedUrl = null;
    draftState.exportCsvNamespace.pollExportCsvLoad = ENetworkRequestStatus.ERROR;
    draftState.exportCsvNamespace.triggerExportCsvLoad = ENetworkRequestStatus.IDLE;
    return draftState;
  });
};

export const pollCancel = state => {
  return produce(state, draftState => {
    draftState.exportCsvNamespace.isWaitingForExport = false;
    draftState.exportCsvNamespace.exportSignedUrl = null;
    draftState.exportCsvNamespace.pollExportCsvLoad = ENetworkRequestStatus.IDLE;
    draftState.exportCsvNamespace.triggerExportCsvLoad = ENetworkRequestStatus.IDLE;
    return draftState;
  });
};
