import { initialState, IBookingConfirmationDomain } from './model';
import * as Actions from './actions'; 
import { quoteReducer } from './subdomains/quote/reducer';
import { paymentReducer } from './subdomains/payment/reducer';

export const bookingConfirmationReducer = (
  state: IBookingConfirmationDomain = initialState,
  action: Actions.BookingConfirmationAction
) => {
  
  return {
    quote: quoteReducer(state.quote, action),
    payment: paymentReducer(state.payment, action),
  };
};

export default bookingConfirmationReducer;
