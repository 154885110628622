import React from 'react';

interface ITransactionAmountColumnProps {
  amount: number;
  formatter: (amount: number) => string;
  className?: string;
}

export const TransactionAmountColumn: React.FC<ITransactionAmountColumnProps> = React.memo(
  ({ amount, formatter, className }) => {
    return (
      <td className={className}>
        <span>{formatter(amount)}</span>
      </td>
    );
  }
);
