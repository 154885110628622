import { ENetworkRequestStatus, EUploadTag, Upload } from 'services/BackendApi';

import { INewUploadPayload } from '../bookingManager/subdomains/uploads/model';

export type CompanyDocumentsSortField = 'createdAt' | 'displayName' | 'tag';
export type CompanyDocumentsSortOrder = 'asc' | 'desc';

export const TagLabel = {
  [EUploadTag.OTHER_GENERAL]: 'General',
  [EUploadTag.OTHER_INTERNAL]: 'Internal',
};

export interface INewUpload {
  payload: INewUploadPayload | null;
  request: ENetworkRequestStatus;
  error: Error | null;
}

export interface IDeleteDocument {
  uuid: string | null;
  request: ENetworkRequestStatus;
}

export interface ICompanyDocumentsDomain {
  documents: Upload[];
  documentsSortField?: CompanyDocumentsSortField;
  documentsSortOrder?: CompanyDocumentsSortOrder;
  documentsLoad: ENetworkRequestStatus;
  documentsLoadError?: Error;
  uploadDocumentModalOpen: boolean;
  uploadDocumentError?: Error;
  newDocument: INewUpload;
  deleteDocument: IDeleteDocument;
}

export const initialState: ICompanyDocumentsDomain = {
  documents: [],
  documentsLoad: ENetworkRequestStatus.IDLE,
  uploadDocumentModalOpen: false,
  newDocument: {
    payload: null,
    request: ENetworkRequestStatus.IDLE,
    error: null,
  },
  deleteDocument: {
    uuid: null,
    request: ENetworkRequestStatus.IDLE,
  },
};
