import React from 'react';

export const enquiryCreatedNotificationMessage = booking => (
  <a
    className="download-quote-enquiry text-white no-underline hover:text-white"
    href={`/booking-manager/${booking.uuid}`}
  >
    <div>This enquiry has been saved with reference: {booking.humanReadableId}</div>
    <div>Click here to manage your booking</div>
  </a>
);
