import styled from 'styled-components';
import { EBreakpoints, breakpointValue } from 'pureUi/breakpoints';
import { pureUiTheme } from 'pureUi/pureUiTheme';

const animationTime = '300ms';
const animationEasing = 'ease-in';

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: stretch;

  > aside {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 178px;
    min-height: 570px;
    flex-shrink: 0;
  }

  
  > main { 
    flex-direction: column;
    display: flex;
    flex-grow: 1;
  }

  &.mobile {
    > aside {
      display: span 0;
    }
  
    > main {
      grid-column: span 12;

      > .animation-container {
        position: relative;
        height: 100%;

        > * {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  
  .animate {
    
    &.in-enter {
      transform: translateX(-100%);
    }

    &.out-enter {
      transform: translateX(100%);
    }

    &.in-enter-active,
    &.out-enter-active {
      transform: translateX(0%);
      transition: transform ${animationTime} ${animationEasing};
    }

    &.in-exit,
    &.out-exit {
      transform: translateX(0%);
    }
   
    &.in-exit-active {
      transition: transform ${animationTime} ${animationEasing};
      transform: translateX(-100%);
    }

    &.out-exit-active {
      transition: transform ${animationTime} ${animationEasing};
      transform: translateX(100%);
    }
  }

`;
