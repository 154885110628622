import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import * as Actions from 'store/modules/internalUsersList/actions';

import * as Selectors from 'store/modules/travelAgentList/selectors';

import FluidButton from 'ui/FluidButton';
import { ENetworkRequestStatus } from 'services/BackendApi';

export interface ControlsProps {
  className?: string;
}

export const Controls: React.FC<ControlsProps> = props => {
  const request = useSelector(Selectors.requestSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleCsvExport = useCallback(() => {
    dispatch(Actions.csvRequestAction());
  }, [dispatch]);

  const handleAdd = useCallback(() => {
    history.push('/users/create');
  }, [history]);

  return (
    <div className={classNames(props.className, 'controls flex gap-10px')}>
      <FluidButton
        className="export-csv"
        type="secondary"
        onClick={handleCsvExport}
        isLoading={request.csv === ENetworkRequestStatus.PENDING}
      >
        Export CSV
      </FluidButton>

      <FluidButton className="create-new" type="primary" onClick={handleAdd}>
        + New User
      </FluidButton>
    </div>
  );
};
