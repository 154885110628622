import { isNil } from 'lodash-es';
import DateRangeInput from 'pureUi/DateRangeInput';
import {
  AncillaryProductResult,
  AncillaryProductWithRatesWithUserData,
  AncillaryRateForDate,
  EAncillaryProductType,
  isAncillaryRatedResult,
  isAncillaryRatedResultPerUnit,
  LocationDirectionWithCountry,
  LocationFromSearchLocationsWithType,
} from 'services/BookingManagerApi/types/AncillaryService';
import { formatDateDisplay } from 'utils';
import { theme } from '../../../tailwind.config';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { IGuestAges } from 'services/BackendApi';
import * as uuid from 'uuid';
import { IAncillaryData } from 'services/BookingManagerApi';
import * as _ from 'lodash-es';

export const adjustInboundOutboundForReturns = (inbound: number, outbound: number): [number, number] => {
  const inboundHasHalfCent = inbound % 1 === 0.5;
  const outboundHasHalfCent = outbound % 1 === 0.5;

  let adjustedInbound = inbound;
  let adjustedOutbound = outbound;

  if (inboundHasHalfCent && outboundHasHalfCent) {
    // Both have half a cent: round inbound up and outbound down
    adjustedInbound = Math.ceil(inbound);
    adjustedOutbound = Math.floor(outbound);
  } else if (inboundHasHalfCent) {
    // Only inbound has half a cent: round inbound up
    adjustedInbound = Math.ceil(inbound);
  } else if (outboundHasHalfCent) {
    // Only outbound has half a cent: round outbound up
    adjustedOutbound = Math.ceil(outbound);
  }

  return [adjustedInbound, adjustedOutbound];
};

export const generateBreakdownItemDataForAncillaryProduct = (
  selectedProduct: AncillaryProductWithRatesWithUserData,
  guestAges: IGuestAges,
  opts?: {
    selectedDate?: string;
    pickupFromLocationName?: string;
    dropofflocationName?: string;
    pickupTime?: string;
    airport?: LocationFromSearchLocationsWithType | null;
    dropoffTime?: string;
    isOutbound?: boolean; // used only for transfers
  }
) => {
  let title = '';
  let tertiaryText = '';
  let purchaseCostCents = 0;
  let saleCostCents = 0;

  if (!isAncillaryRatedResult(selectedProduct.userData.rate!)) {
    return {
      title,
      tertiaryText,
      purchaseCostCents,
      saleCostCents,
      flexibleAttributesNonValidatedSelections: [],
    };
  }

  const flexibleAttributesNonValidatedSelections: { id: any; selectedValue: any }[] = Object.keys(
    selectedProduct?.userData.optionSelections
  )
    .map(id => {
      return {
        id: parseInt(id),
        selectedValue: {
          value: selectedProduct?.userData.optionSelections[id],
        },
      };
    })
    .filter(fa => fa.selectedValue.value !== undefined);

  // the string of selected options to values that goes into the tertiary text
  const optionsString = flexibleAttributesNonValidatedSelections
    .map(fa => {
      // get the actual option name using the id
      const option = selectedProduct?.product.flexibleAttributes.find(f => f.id === fa.id);
      if (option && option.type === 'option-boolean' && fa.selectedValue) {
        return option?.name;
      } else {
        return `${option?.name}: ${fa.selectedValue.value}`;
      }
    })
    .join(', ');
  // make a string of number of adults and number of children
  const guestAgesString = `${guestAges.numberOfAdults} Adults${
    (guestAges.agesOfAllChildren || []).length > 0 ? `, ${(guestAges.agesOfAllChildren || []).length} Children` : ''
  }`;

  if (!isNil(selectedProduct.userData.rate!.price)) {
    saleCostCents = parseInt((parseFloat(selectedProduct.userData.rate!.price) * 100).toFixed(0));
    purchaseCostCents = parseInt((parseFloat(selectedProduct.userData.rate!.purchaseCost!) * 100).toFixed(0));
  }

  // title is always the same (as of 23/07/24)...
  title = selectedProduct.product.variantName
    ? `${selectedProduct.product.baseProductName} - ${selectedProduct.product.variantName}`
    : selectedProduct.product.baseProductName;

  // ...but tertiary text changes depending on the product type
  switch (selectedProduct.product.productType) {
    case EAncillaryProductType.ACTIVITY:
      tertiaryText = `${formatDateDisplay(
        opts?.selectedDate ? opts.selectedDate : selectedProduct.userData.rate!.date
      )}, ${guestAgesString}${
        selectedProduct.userData.unitCount && isAncillaryRatedResultPerUnit(selectedProduct.userData.rate!)
          ? `, Units: ${selectedProduct.userData.unitCount}`
          : ''
      }${optionsString ? `, ${optionsString}` : ''}`;
      break;
    case EAncillaryProductType.TRANSFER:
      if (opts?.isOutbound === undefined) {
        tertiaryText = `${formatDateDisplay(
          opts?.selectedDate ? opts.selectedDate : selectedProduct.userData.rate!.date
        )} ${opts!.pickupTime}, ${guestAgesString}${
          selectedProduct.userData.unitCount && isAncillaryRatedResultPerUnit(selectedProduct.userData.rate!)
            ? `, Units: ${selectedProduct.userData.unitCount}`
            : ''
        }, From: ${opts!.pickupFromLocationName}, To: ${opts!.dropofflocationName}${
          optionsString ? `, ${optionsString}` : ''
        }`;
      } else if (opts.isOutbound === true) {
        tertiaryText = `Inbound, ${formatDateDisplay(
          opts?.selectedDate ? opts.selectedDate : selectedProduct.userData.rate!.date
        )} ${opts!.pickupTime}, ${guestAgesString}${
          selectedProduct.userData.unitCount && isAncillaryRatedResultPerUnit(selectedProduct.userData.rate!)
            ? `, Units: ${selectedProduct.userData.unitCount}`
            : ''
        }, From: ${opts!.pickupFromLocationName}, To: ${opts!.dropofflocationName}${
          optionsString ? `, ${optionsString}` : ''
        }`;
      } else if (opts.isOutbound === false) {
        tertiaryText = `Outbound, ${formatDateDisplay(
          opts?.selectedDate ? opts.selectedDate : selectedProduct.userData.rate!.date
        )} ${opts!.pickupTime}, ${guestAgesString}${
          selectedProduct.userData.unitCount && isAncillaryRatedResultPerUnit(selectedProduct.userData.rate!)
            ? `, Units: ${selectedProduct.userData.unitCount}`
            : ''
        }, From: ${opts!.dropofflocationName}, To: ${opts!.pickupFromLocationName}${
          optionsString ? `, ${optionsString}` : ''
        }`;
      }
      break;

    case EAncillaryProductType.GROUND_SERVICE:
      tertiaryText = `${formatDateDisplay(
        opts?.selectedDate ? opts.selectedDate : selectedProduct.userData.rate!.date
      )} ${opts!.pickupTime}, ${guestAgesString}${
        selectedProduct.userData.unitCount && isAncillaryRatedResultPerUnit(selectedProduct.userData.rate!)
          ? `, Units: ${selectedProduct.userData.unitCount}`
          : ''
      }, Airport: ${`${opts!.airport?.name}${opts!.airport?.iata_code ? ` (${opts!.airport.iata_code})` : ''}`}${
        optionsString ? `, ${optionsString}` : ''
      }`;
      break;
  }

  return {
    title,
    tertiaryText,
    purchaseCostCents,
    saleCostCents,
    flexibleAttributesNonValidatedSelections,
  };
};

/**
 * this generates the data that goes into the breakdown for a transfer
 * handles things like; splitting the cost if its a return, swapping from/to for the return
 * etc.
 */
export const generateBreakdownItemDataForAncillaryTransfers = (data: {
  selectedTransferProduct: AncillaryProductWithRatesWithUserData;
  guestAges: IGuestAges;
  pickupTime: string;
  pickupFromLocationName: string;
  dropofflocationName: string;
  pickupFromLocation: LocationDirectionWithCountry;
  dropOffLocation: LocationDirectionWithCountry;
  dates: string[];
  returnTime?: string;
  isReturn: boolean;
}) => {
  const {
    selectedTransferProduct,
    guestAges,
    pickupTime,
    pickupFromLocationName,
    dropofflocationName,
    pickupFromLocation,
    dropOffLocation,
    dates,
    returnTime,
    isReturn,
  } = data;

  if (!isAncillaryRatedResult(selectedTransferProduct.userData.rate!)) {
    throw new Error('selectedTransferProduct is not rated');
  }

  const inboundInitialData = generateBreakdownItemDataForAncillaryProduct(selectedTransferProduct, guestAges, {
    pickupTime,
    pickupFromLocationName: pickupFromLocationName!,
    dropofflocationName: dropofflocationName!,
    isOutbound: true,
    selectedDate: dates![0],
  });

  const outboundInitialData = generateBreakdownItemDataForAncillaryProduct(selectedTransferProduct, guestAges, {
    pickupTime: returnTime,
    pickupFromLocationName: pickupFromLocationName!,
    dropofflocationName: dropofflocationName!,
    isOutbound: false,
    selectedDate: dates![dates!.length - 1],
  });
  if (isReturn) {
    // ...then adjust the costings
    const [inboundSaleCostCents, outboundSaleCostCents] = adjustInboundOutboundForReturns(
      inboundInitialData.saleCostCents / 2,
      outboundInitialData.saleCostCents / 2
    );
    const [inboundPurchaseCostCents, outboundPurchaseCostCents] = adjustInboundOutboundForReturns(
      inboundInitialData.purchaseCostCents / 2,
      outboundInitialData.purchaseCostCents / 2
    );
    inboundInitialData.saleCostCents = inboundSaleCostCents;
    outboundInitialData.saleCostCents = outboundSaleCostCents;
    inboundInitialData.purchaseCostCents = inboundPurchaseCostCents;
    outboundInitialData.purchaseCostCents = outboundPurchaseCostCents;
  }

  // make the core ancillary data that is going to be used by both line items
  const returnHash = uuid.v4();
  const inboundAncillaryRequestData: Partial<IAncillaryData> = {
    isNew: true,
    product: selectedTransferProduct.product,
    rateForDate: selectedTransferProduct.userData.rate!,
    userSelections: {
      selectedDate: selectedTransferProduct.userData.rate!.date,
      numberOfAdults: guestAges.numberOfAdults,
      agesOfAllChildren: guestAges.agesOfAllChildren || [],
      baseProductId: selectedTransferProduct.product.baseProductId,
      variantId: !isNil(selectedTransferProduct.product.variantId)
        ? selectedTransferProduct.product.variantId
        : undefined,
      perUnitQuantityOverride: selectedTransferProduct.userData.unitCount,
      flexibleAttributesNonValidatedSelections: inboundInitialData.flexibleAttributesNonValidatedSelections,
      selectionHash: selectedTransferProduct.userData.rate!.hash,
      time: pickupTime,
      transferLocations: {
        from: {
          id: pickupFromLocation!.id,
          type: pickupFromLocation!.type,
          placeName: pickupFromLocationName,
        },
        to: {
          id: dropOffLocation!.id,
          type: dropOffLocation!.type,
          placeName: dropofflocationName,
        },
      },
      returnHash: isReturn ? returnHash : undefined,
    },
  };

  // ...then make the outbound one, which is the same as the inbound but with some tiny changes
  const outboundAncillaryRequestData: Partial<IAncillaryData> = {
    ...inboundAncillaryRequestData,
    userSelections: {
      ...inboundAncillaryRequestData.userSelections,
      flexibleAttributesNonValidatedSelections: outboundInitialData.flexibleAttributesNonValidatedSelections,
      selectedDate: dates![dates!.length - 1],
      time: returnTime,
    },
  };

  // @ts-ignore This is fine
  delete inboundInitialData.flexibleAttributesNonValidatedSelections;
  // @ts-ignore This is fine
  delete outboundInitialData.flexibleAttributesNonValidatedSelections;

  return { inboundInitialData, outboundInitialData, inboundAncillaryRequestData, outboundAncillaryRequestData };
};

export const getDisplayNameForAncillaryProductResult = (product: AncillaryProductResult) => {
  let name = product.baseProductName;
  if (product.variantName) {
    name += ` - ${product.variantName}`;
  }
  return name;
};

export const getErrorIdsMissingFlexibleAttributes = (p: AncillaryProductWithRatesWithUserData) => {
  const errorIds: string[] = [];

  p.product.flexibleAttributes.forEach((fa, i) => {
    if (fa.type === 'option' && isNil(p.userData.optionSelections[fa.id])) {
      errorIds.push(fa.id.toString());
    }
  });

  return errorIds;
};

// @ts-ignore Styled component errors
export const AncillaryStyledDateRangeInput = styled(DateRangeInput)`
  .pseudoSelect {
    background: ${theme.colors['ivory']};
    height: 37px;
    border-color: ${theme.colors['gray-40']};
  }
  .displayString {
    text-transform: none;
    font-family: 'PT Sans', sans-serif;
  }
  &:disabled {
    background: ${theme.colors['gray-10']};
    border-color: ${theme.colors['gray-10']};
    color: ${theme.colors['gray-100']};
  }
`;
