import { ENetworkRequestStatus } from 'services/BackendApi';
import * as CognitoActions from './actions';
import { ICognitoDomain, initialState } from './model';
import { update } from 'utils/immutable';

export const cognitoReducer = (
  state: ICognitoDomain = initialState,
  action: CognitoActions.CognitoAction
): ICognitoDomain => {
  switch (action.type) {
    case CognitoActions.COGNITO_FLOW_REQUEST:
      return update(state, s => {
        s.request.callWelcome = ENetworkRequestStatus.PENDING;
      });

    case CognitoActions.COGNITO_FLOW_SUCCESS:
      return update(state, s => {
        s.request.callWelcome = ENetworkRequestStatus.SUCCESS;
      });

    case CognitoActions.COGNITO_FLOW_FAILURE:
      return update(state, s => {
        s.request.callWelcome = ENetworkRequestStatus.ERROR;
        s.request.callWelcomeServerErrorMessage = action.error;
        s.request.callWelcomeServerStatus = action.status;
      });
    
    default:
      return state;
  }
};
