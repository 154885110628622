import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import QuoteMarginSelector from 'components/QuoteMarginSelector';
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import * as TaLogoSelectors from 'store/modules/bookingManager/subdomains/taLogo/selectors';
import { EPdfLogo } from 'store/modules/bookingManager/subdomains/breakdown/model';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';

export const RenderQuoteModal = () => {
    const dispatch = useDispatch();
    const breakdownLogo = useSelector(BreakdownSelectors.getBreakdownLogoSelector);
    const companyLogo = useSelector(TaLogoSelectors.taLogoSelector);

    const handleClose = useCallback(() => {
        dispatch(BreakdownActions.setIsCreateQuoteModalOpenAction(false))
    }, [dispatch, BreakdownActions.setIsCreateQuoteModalOpenAction]);

    const handleSelectCreateQuoteWithMarginOption = useCallback(() => {
        dispatch(BreakdownActions.setBreakdownLogoAction(EPdfLogo.MAIN_COMPANY_LOGO));
    }, [dispatch, BreakdownActions.setBreakdownLogoAction]);

    const handleSelectCreateQuoteWithoutMarginWithLogoOption = useCallback(() => {
        dispatch(BreakdownActions.setBreakdownLogoAction(EPdfLogo.TA_LOGO));
    }, [dispatch, BreakdownActions.setBreakdownLogoAction]);

    const handleSelectCreateQuoteWithoutMarginWithoutLogoOption = useCallback(() => {
        dispatch(BreakdownActions.setBreakdownLogoAction(EPdfLogo.NO_LOGO));
    }, [dispatch, BreakdownActions.setBreakdownLogoAction]);

    const handleGenerateQuote = useCallback(() => {
        if (breakdownLogo === EPdfLogo.MAIN_COMPANY_LOGO) {
            dispatch(BreakdownActions.downloadBreakdownForTARequestAction());
        } else {
            dispatch(BreakdownActions.downloadBreakdownForClientRequestAction());
        }
    }, [
        dispatch, 
        breakdownLogo, 
        BreakdownActions.downloadBreakdownForTARequestAction, 
        BreakdownActions.downloadBreakdownForClientRequestAction
    ]);

    return (
      <QuoteMarginSelector
        downloadQuoteButtonTitle='Generate'
        hasCompanyLogo={Boolean(companyLogo)}
        selectedLogoOption={breakdownLogo}
        onClose={handleClose}
        onDownloadQuote={handleGenerateQuote}
        onQuoteWithMarginOptionSelect={handleSelectCreateQuoteWithMarginOption}
        onQuoteWithoutMarginOptionWithLogoSelect={handleSelectCreateQuoteWithoutMarginWithLogoOption}
        onQuoteWithoutMarginOptionWithoutLogoSelect={handleSelectCreateQuoteWithoutMarginWithoutLogoOption}
      />
    );
  };

export default RenderQuoteModal;