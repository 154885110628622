import { EProposalTemplate, EProposalLang, EProposalLogo, Proposal } from 'services/BookingManagerApi';
import { IProposalSelection } from 'services/BookingManagerApi/types/ProposalSelection';
import { HotelComment, IlogoOption } from './model';

export const SET_TEMPLATE = 'proposalGenerate/SET_TEMPLATE';
export const SET_LANG = 'proposalGenerate/SET_LANG';
export const SET_NAME = 'proposalGenerate/SET_NAME';

export const SET_LOGO = 'proposalGenerate/SET_LOGO';
export const TOGGLE_TA_MARGIN_INCLUDED = 'proposalGenerate/TOGGLE_TA_MARGIN_INCLUDED';

export const SET_GUEST_TITLE = 'proposalGenerate/SET_GUEST_TITLE';
export const SET_GUEST_FIRST_NAME = 'proposalGenerate/SET_GUEST_FIRST_NAME';
export const SET_GUEST_LAST_NAME = 'proposalGenerate/SET_GUEST_LAST_NAME';

export const SET_GENERAL_COMMENTS = 'proposalGenerate/SET_GENERAL_COMMENTS';
export const SET_OTHER_COMMENTS = 'proposalGenerate/SET_OTHER_COMMENTS';

export const SET_ALL_HOTEL_COMMENTS = 'proposalGenerate/SET_ALL_HOTEL_COMMENTS';
export const SET_HOTEL_COMMENTS = 'proposalGenerate/SET_HOTEL_COMMENTS';

export const INIT_FORM = 'proposalGenerate/INIT_FORM';

export const GET_REQUEST = 'proposalGenerate/GET_REQUEST';
export const GET_SUCCESS = 'proposalGenerate/GET_SUCCESS';
export const GET_FAILURE = 'proposalGenerate/GET_FAILURE';

export const GET_SELECTION_REQUEST = 'proposalGenerate/GET_SELECTION_REQUEST';
export const GET_SELECTION_SUCCESS = 'proposalGenerate/GET_SELECTION_SUCCESS';
export const GET_SELECTION_FAILURE = 'proposalGenerate/GET_SELECTION_FAILURE';

export const SAVE_REQUEST = 'proposalGenerate/SAVE_REQUEST';
export const SAVE_SUCCESS = 'proposalGenerate/SAVE_SUCCESS';
export const SAVE_FAILURE = 'proposalGenerate/SAVE_FAILURE';

export const DOWNLOAD_REQUEST = 'proposalGenerate/DOWNLOAD_REQUEST';
export const DOWNLOAD_SUCCESS = 'proposalGenerate/DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILURE = 'proposalGenerate/DOWNLOAD_FAILURE';

export const SET_PROPOSAL_LOGO_OPTIONS = 'proposalDetails/SET_PROPOSAL_LOGO_OPTIONS';

export type SetTemplateAction = ReturnType<typeof setTemplateAction>;
export const setTemplateAction = (value: EProposalTemplate) => ({
  type: SET_TEMPLATE as typeof SET_TEMPLATE,
  value
});

export type SetLangAction = ReturnType<typeof setLangAction>;
export const setLangAction = (value: EProposalLang) => ({
  type: SET_LANG as typeof SET_LANG,
  value
});

export type SetNameAction = ReturnType<typeof setNameAction>;
export const setNameAction = (value: string) => ({
  type: SET_NAME as typeof SET_NAME,
  value
});

export type SetLogoAction = ReturnType<typeof setLogoAction>;
export const setLogoAction = (value: EProposalLogo) => ({
  type: SET_LOGO as typeof SET_LOGO,
  value
});

export type ToggleTaMarginIncludedAction = ReturnType<typeof toggleTaMarginIncludedAction>;
export const toggleTaMarginIncludedAction = () => ({
  type: TOGGLE_TA_MARGIN_INCLUDED as typeof TOGGLE_TA_MARGIN_INCLUDED,
});

export type SetGuestTitleAction = ReturnType<typeof setGuestTitleAction>;
export const setGuestTitleAction = (value: string | null) => ({
  type: SET_GUEST_TITLE as typeof SET_GUEST_TITLE,
  value
});

export type SetGuestFirstNameAction = ReturnType<typeof setGuestFirstNameAction>;
export const setGuestFirstNameAction = (value: string) => ({
  type: SET_GUEST_FIRST_NAME as typeof SET_GUEST_FIRST_NAME,
  value
});

export type SetGuestLastNameAction = ReturnType<typeof setGuestLastNameAction>;
export const setGuestLastNameAction = (value: string) => ({
  type: SET_GUEST_LAST_NAME as typeof SET_GUEST_LAST_NAME,
  value
});

export type SetGeneralCommentsAction = ReturnType<typeof setGeneralCommentsAction>;
export const setGeneralCommentsAction = (value: string) => ({
  type: SET_GENERAL_COMMENTS as typeof SET_GENERAL_COMMENTS,
  value
});

export type SetOtherCommentsAction = ReturnType<typeof setOtherCommentsAction>;
export const setOtherCommentsAction = (value: string) => ({
  type: SET_OTHER_COMMENTS as typeof SET_OTHER_COMMENTS,
  value
});

export type SetHotelCommentsAction = ReturnType<typeof setHotelCommentsAction>;
export const setHotelCommentsAction = (idx: number, value: string) => ({
  type: SET_HOTEL_COMMENTS as typeof SET_HOTEL_COMMENTS,
  idx,
  value
});

export type SetAllHotelCommentsAction = ReturnType<typeof setAllHotelCommentsAction>;
export const setAllHotelCommentsAction = (value: HotelComment[]) => ({
  type: SET_ALL_HOTEL_COMMENTS as typeof SET_ALL_HOTEL_COMMENTS,
  value
});

export type InitFormAction = ReturnType<typeof initFormAction>;
export const initFormAction = (proposalSelectionUuid: string, bookingUuids: string[], proposalUuid?: string) => ({
  type: INIT_FORM as typeof INIT_FORM,
  proposalSelectionUuid,
  bookingUuids,
  proposalUuid
});

export type GetRequestAction = ReturnType<typeof getRequestAction>;
export const getRequestAction = () => ({
  type: GET_REQUEST as typeof GET_REQUEST,
});

export type GetSuccessAction = ReturnType<typeof getSuccessAction>;
export const getSuccessAction = (value: Proposal) => ({
  type: GET_SUCCESS as typeof GET_SUCCESS,
  value
});

export type GetFailureAction = ReturnType<typeof getFailureAction>;
export const getFailureAction = () => ({
  type: GET_FAILURE as typeof GET_FAILURE,
});

export type GetSelectionRequestAction = ReturnType<typeof getSelectionRequestAction>;
export const getSelectionRequestAction = () => ({
  type: GET_SELECTION_REQUEST as typeof GET_SELECTION_REQUEST,
});

export type GetSelectionSuccessAction = ReturnType<typeof getSelectionSuccessAction>;
export const getSelectionSuccessAction = (value: IProposalSelection) => ({
  type: GET_SELECTION_SUCCESS as typeof GET_SELECTION_SUCCESS,
  value
});

export type GetSelectionFailureAction = ReturnType<typeof getSelectionFailureAction>;
export const getSelectionFailureAction = () => ({
  type: GET_SELECTION_FAILURE as typeof GET_SELECTION_FAILURE,
});

export type SaveRequestAction = ReturnType<typeof saveRequestAction>;
export const saveRequestAction = () => ({
  type: SAVE_REQUEST as typeof SAVE_REQUEST,
});

export type SaveSuccessAction = ReturnType<typeof saveSuccessAction>;
export const saveSuccessAction = (value: Proposal) => ({
  type: SAVE_SUCCESS as typeof SAVE_SUCCESS,
  value
});

export type SaveFailureAction = ReturnType<typeof saveFailureAction>;
export const saveFailureAction = () => ({
  type: SAVE_FAILURE as typeof SAVE_FAILURE,
});

export type DownloadRequestAction = ReturnType<typeof downloadRequestAction>;
export const downloadRequestAction = () => ({
  type: DOWNLOAD_REQUEST as typeof DOWNLOAD_REQUEST,
});

export type DownloadSuccessAction = ReturnType<typeof downloadSuccessAction>;
export const downloadSuccessAction = () => ({
  type: DOWNLOAD_SUCCESS as typeof DOWNLOAD_SUCCESS,
});

export type DownloadFailureAction = ReturnType<typeof downloadFailureAction>;
export const downloadFailureAction = () => ({
  type: DOWNLOAD_FAILURE as typeof DOWNLOAD_FAILURE,
});

export type SetLogoOptionsAction = ReturnType<typeof setLogoOptionsAction>;
export const setLogoOptionsAction = (logoOptions: IlogoOption[]) => ({
  type: SET_PROPOSAL_LOGO_OPTIONS as typeof SET_PROPOSAL_LOGO_OPTIONS,
  logoOptions,
});

export type Any =
  | SetTemplateAction
  | SetLangAction
  | SetNameAction
  | SetLogoAction
  | ToggleTaMarginIncludedAction
  | SetGuestTitleAction
  | SetGuestFirstNameAction
  | SetGuestLastNameAction
  | SetGeneralCommentsAction
  | SetOtherCommentsAction
  | SetHotelCommentsAction
  | SetAllHotelCommentsAction
  | InitFormAction
  | GetRequestAction
  | GetSuccessAction
  | GetFailureAction
  | GetSelectionRequestAction
  | GetSelectionSuccessAction
  | GetSelectionFailureAction
  | SaveRequestAction
  | SaveSuccessAction
  | SaveFailureAction
  | DownloadRequestAction
  | DownloadSuccessAction
  | DownloadFailureAction
  | SetLogoOptionsAction;
