import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { BookingHotel } from './BookingHotel';
import { PageTitle } from 'ui/PageTitle';
import * as HotelSelectors from 'store/modules/hotel';
import * as HotelActions from 'store/modules/hotel/actions';
import * as BookingBuilderActions from 'store/modules/bookingBuilder/actions';
import * as HotelAccommodationProductsActions from 'store/modules/hotelAccommodationProducts/actions';
import * as StaticRatesAvailabilityActions from 'store/modules/staticRatesAvailability/actions';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBar } from 'ui/NetworkStatusBar';
import * as BookingBuilderUtils from 'utils/bookingBuilder';
import { BookingRightColumn } from './BookingRightColumn';
import { useTranslation } from 'react-i18next';
import { ERoutingPreviousPage } from 'utils/routingUtils';
import { History } from 'history';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';

interface IBookingConfirmationPageParams {
  hotelUuid: string;
}

export const BookingConfirmationPage: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory<History.LocationState>();
  const match = useRouteMatch<IBookingConfirmationPageParams>();
  const hotelUuid = match.params.hotelUuid;
  const booking = useSelector(BookingBuilderSelectors.bookingBuilderSelector);
  const hotel = useSelector(HotelSelectors.hotelSelector);
  const { t } = useTranslation();
  const [shouldShowLeaveAlert, setShouldShowLeaveAlert] = useState(true);
  const [redirectLocation, setRedirectLocation] = useState<string | null>(null);

  const lodgingSummaries = BookingBuilderUtils.generateLodgingSummariesFromBooking(t, booking);

  useEffect(() => {
    return () => {
      if (history.location.state?.comingFrom === ERoutingPreviousPage.BOOKING_CONFIRMATION_PAGE) {
        return;
      }
      // on unmount, clear everything out
      dispatch(BookingBuilderActions.resetBookingBuilderUiStateAction());
      // and reset the hotel accommodation products
      dispatch(HotelAccommodationProductsActions.resetHotelAccommodationProductsStateAction());
      // and clear the hotel data
      dispatch(HotelActions.clearHotelAction());
      // and clear out the static rates availability data
      dispatch(StaticRatesAvailabilityActions.resetStaticRatesAvailabilityDomainAction());
      // and clear out the booking builder domain
      dispatch(BookingBuilderActions.clearBookingBuilderAction());
    };
  }, [history, dispatch]);

  useEffect(() => {
    if (redirectLocation) {
      history.push(redirectLocation, { comingFrom: ERoutingPreviousPage.BOOKING_CONFIRMATION_PAGE });
    }
  }, [redirectLocation, history]);

  const goBackToSearch = useCallback(() => {
    const searchQuery = window.location.search ?? '';
    history.push(`/hotels/${hotelUuid}${searchQuery}`);
  }, [hotelUuid, history]);

  if (!booking || !hotel) {
    return <ErrorBar ctaCaption="Back to Room Selection" onCtaClick={goBackToSearch} />
  }

  return (
    <div className="booking-confirmation-page container mx-auto max-w-[1800px] font-pt-sans text-black px-[20px]">
      <div className="page-title-container flex items-baseline justify-between">
        <PageTitle primaryText="Confirm Your Booking" />
      </div>

      <div className="booking-confirmation-columns flex gap-[40px] mt-[20px]">
        <div className="booking-left-column flex-1">
          <BookingHotel
            hotel={hotel}
            booking={booking}
            lodgingSummaries={lodgingSummaries}
            shouldShowLeaveAlert={shouldShowLeaveAlert}
            setShouldShowLeaveAlert={setShouldShowLeaveAlert}
            setRedirectLocation={setRedirectLocation}
            className="flex-1"
          />
        </div>
        <BookingRightColumn 
          hotel={hotel}
          booking={booking}
          lodgingSummaries={lodgingSummaries}
          shouldShowLeaveAlert={shouldShowLeaveAlert}
          setShouldShowLeaveAlert={setShouldShowLeaveAlert}
          setRedirectLocation={setRedirectLocation}
        />
      </div>
      
      <LeaveWithoutSavingModal
          title="You have made changes to this page, and not saved. If you leave this page now, these changes will be lost."
          when={shouldShowLeaveAlert}
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
        />
    </div>
  );
});
