import React, { ReactNode } from 'react';

import { StandardModal } from 'pureUi/Modal';
import { theme } from '../../../tailwind.config';
import classnames from 'classnames';
import { CloseBoldIcon } from 'ui/Icons/components/CloseBold.component';

interface IModalV1Props {
  title: string;
  message?: string;
  children?: ReactNode;
  onClose?: () => void;
  showCloseButton?: boolean;
  className?: string;
  modalHeadingClassName?: string;
  modalMessageClassName?: string;
}

// This component should be used for Modals on those pages
// that are using legacy design
// such as BBV1, Home, Dashboard, Hotels Search Result
export const ModalV1: React.FC<IModalV1Props> = ({
  title,
  message,
  children,
  className,
  modalHeadingClassName,
  modalMessageClassName,
  showCloseButton = true,
  onClose,
}) => {
  return (
    <StandardModal
      frameClassName={classnames(className, 'rounded')}
      onClose={onClose}
      focusTrapActive={false}
      showCloseButton={false}
      removePadding
      closeOnOutsideClick
    >
      <div className={classnames('p-10 space-y-[25px]', className)}>
        <div className={classnames('modal-heading-container flex items-center justify-between gap-[20px]', modalHeadingClassName)}>
          <h2 className="modal-heading m-0 font-noe-display text-21px leading-[27px] text-black">
            {title}
          </h2>
          {showCloseButton && (
            <CloseBoldIcon onClick={onClose} className="cursor-pointer min-w-[12px] min-h-[12px]" fill={theme.colors['brown-100']} />
          )}
        </div>
        {message && (
          <p className={classnames('modal-message font-hurmegeometric-sans text-15px leading-20px text-black-true', modalMessageClassName)}>
            {message}
          </p>
        )}
        {children && children}
      </div>
    </StandardModal>
  );
};
