import { Upload } from 'services/BackendApi';

export const GET_UPLOADS_REQUEST = 'bookingManager/GET_UPLOADS_REQUEST';
export const GET_UPLOADS_SUCCESS = 'bookingManager/GET_UPLOADS_SUCCESS';
export const GET_UPLOADS_FAILURE = 'bookingManager/GET_UPLOADS_FAILURE';

export const NEW_UPLOAD_REQUEST = 'bookingManager/NEW_UPLOAD_REQUEST';
export const NEW_UPLOAD_SUCCESS = 'bookingManager/NEW_UPLOAD_SUCCESS';
export const NEW_UPLOAD_FAILURE = 'bookingManager/NEW_UPLOAD_FAILURE';

export const OPEN_UPLOAD_FORM = 'bookingManager/OPEN_UPLOAD_FORM';
export const CLOSE_UPLOAD_FORM = 'bookingManager/CLOSE_UPLOAD_FORM';

export const SET_UPLOAD_FILE = 'bookingManager/SET_UPLOAD_FILE';
export const SET_UPLOAD_DISPLAY_NAME = 'bookingManager/SET_UPLOAD_DISPLAY_NAME';
export const SET_UPLOAD_TAG = 'bookingManager/SET_UPLOAD_TAG';

export const SET_PRIVACY_ACK = 'bookingManager/SET_PRIVACY_ACK';

export const DELETE_UPLOAD_REQUEST = 'bookingManager/DELETE_UPLOAD_REQUEST';
export const DELETE_UPLOAD_SUCCESS = 'bookingManager/DELETE_UPLOAD_SUCCESS';
export const DELETE_UPLOAD_FAILURE = 'bookingManager/DELETE_UPLOAD_FAILURE';

export const SET_DELETE_UPLOAD = 'bookingManager/SET_DELETE_UPLOAD';
export const CANCEL_DELETE_UPLOAD = 'bookingManager/CANCEL_DELETE_UPLOAD';

export type GetUploadsRequestAction = ReturnType<typeof getUploadsRequestAction>;
export const getUploadsRequestAction = (tag?: string) => ({
  type: GET_UPLOADS_REQUEST as typeof GET_UPLOADS_REQUEST,
  tag,
});

export type GetUploadsSuccessAction = ReturnType<typeof getUploadsSuccessAction>;
export const getUploadsSuccessAction = (uploads: Upload[], canDeleteList: string[]) => ({
  type: GET_UPLOADS_SUCCESS as typeof GET_UPLOADS_SUCCESS,
  uploads,
  canDeleteList,
});

export type GetUploadsFailureAction = ReturnType<typeof getUploadsFailureAction>;
export const getUploadsFailureAction = (error: string) => ({
  type: GET_UPLOADS_FAILURE as typeof GET_UPLOADS_FAILURE,
  error,
});

// new upload

export type NewUploadRequestAction = ReturnType<typeof newUploadRequestAction>;
export const newUploadRequestAction = () => ({
  type: NEW_UPLOAD_REQUEST as typeof NEW_UPLOAD_REQUEST,
});

export type NewUploadSuccessAction = ReturnType<typeof newUploadSuccessAction>;
export const newUploadSuccessAction = (upload: Upload) => ({
  type: NEW_UPLOAD_SUCCESS as typeof NEW_UPLOAD_SUCCESS,
  upload,
});

export type NewUploadFailureAction = ReturnType<typeof newUploadFailureAction>;
export const newUploadFailureAction = (error: string) => ({
  type: NEW_UPLOAD_FAILURE as typeof NEW_UPLOAD_FAILURE,
  error,
});

// upload form

export type SetUploadFileAction = ReturnType<typeof setUploadFileAction>;
export const setUploadFileAction = (file: File) => ({
  type: SET_UPLOAD_FILE as typeof SET_UPLOAD_FILE,
  file,
});

export type SetUploadDisplayNameAction = ReturnType<typeof setUploadDisplayNameAction>;
export const setUploadDisplayNameAction = (displayName: string) => ({
  type: SET_UPLOAD_DISPLAY_NAME as typeof SET_UPLOAD_DISPLAY_NAME,
  displayName,
});

export type SetUploadTagAction = ReturnType<typeof setUploadTagAction>;
export const setUploadTagAction = (tag: string) => ({
  type: SET_UPLOAD_TAG as typeof SET_UPLOAD_TAG,
  tag,
});

export type SetPrivacyAckAction = ReturnType<typeof setPrivacyAckAction>;
export const setPrivacyAckAction = (privacyAck: boolean) => ({
  type: SET_PRIVACY_ACK as typeof SET_PRIVACY_ACK,
  privacyAck
});

export type OpenUploadFormAction = ReturnType<typeof openUploadFormAction>;
export const openUploadFormAction = () => ({
  type: OPEN_UPLOAD_FORM as typeof OPEN_UPLOAD_FORM,
});

export type CloseUploadFormAction = ReturnType<typeof closeUploadFormAction>;
export const closeUploadFormAction = () => ({
  type: CLOSE_UPLOAD_FORM as typeof CLOSE_UPLOAD_FORM,
});

// delete file

export type DeleteUploadRequestAction = ReturnType<typeof deleteUploadRequestAction>;
export const deleteUploadRequestAction = () => ({
  type: DELETE_UPLOAD_REQUEST as typeof DELETE_UPLOAD_REQUEST,
});

export type DeleteUploadSuccessAction = ReturnType<typeof deleteUploadSuccessAction>;
export const deleteUploadSuccessAction = () => ({
  type: DELETE_UPLOAD_SUCCESS as typeof DELETE_UPLOAD_SUCCESS,
});

export type DeleteUploadFailureAction = ReturnType<typeof deleteUploadFailureAction>;
export const deleteUploadFailureAction = (error: string) => ({
  type: DELETE_UPLOAD_FAILURE as typeof DELETE_UPLOAD_FAILURE,
  error,
});

export type SetDeleteUploadAction = ReturnType<typeof setDeleteUploadAction>;
export const setDeleteUploadAction = (uuid: string) => ({
  type: SET_DELETE_UPLOAD as typeof SET_DELETE_UPLOAD,
  uuid,
});

export type CancelDeleteUploadAction = ReturnType<typeof cancelDeleteUploadAction>;
export const cancelDeleteUploadAction = () => ({
  type: CANCEL_DELETE_UPLOAD as typeof CANCEL_DELETE_UPLOAD,
});

export type UploadsAction =
  | GetUploadsRequestAction
  | GetUploadsSuccessAction
  | GetUploadsFailureAction
  | NewUploadRequestAction
  | NewUploadSuccessAction
  | NewUploadFailureAction
  | SetUploadFileAction
  | SetUploadDisplayNameAction
  | SetUploadTagAction
  | SetPrivacyAckAction
  | OpenUploadFormAction
  | CloseUploadFormAction
  | DeleteUploadRequestAction
  | DeleteUploadSuccessAction
  | DeleteUploadFailureAction
  | SetDeleteUploadAction
  | CancelDeleteUploadAction;
