import React from 'react';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { bookingSelector } from 'store/modules/bookingManager/selectors';
import { BookingComment } from 'pureUi/BookingComment';

import { FluidButton } from 'ui/FluidButton';
import * as Selectors from 'store/modules/bookingManager/subdomains/comments/selectors';
import * as Actions from 'store/modules/bookingManager/subdomains/comments/actions';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { isSr, isFinanceUser, isAdmin, getCurrentUserType } from 'store/modules/auth';
import { LoadingBar, ErrorBar } from 'ui/NetworkStatusBar';

import { getTopNavigationDataRequestAction } from 'store/modules/bookingManager/subdomains/dashboard/actions';
import TopNavigationBar from 'pureUi/TopNavigationBar';
import {
  topNavigationDataDashboardSelector,
  dashboardNetworkRequestsSelector,
  bookingInformationReservationTeamDashboardSelector,
} from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import { EUserType } from 'services/BackendApi';
import { EUITextAreaResizeType, UITextArea } from 'ui/UITextArea';

class BookingManagerComments extends React.Component<IBookingManagerCommentsProps, IBookingManagerCommentsState> {
  handleAddPublicComment = () => {
    if (!this.props.booking) {
      return;
    }
    this.props.addBookingComment(this.props.booking.bookingUuid, this.props.publicCommentDraft, 'public');
    this.props.setBookingCommentDraft('', 'public');
  };

  handleAddPrivateComment = () => {
    if (!this.props.booking) {
      return;
    }
    this.props.addBookingComment(this.props.booking.bookingUuid, this.props.privateCommentDraft, 'private');
    this.props.setBookingCommentDraft('', 'private');
  };

  canViewPrivateComments = () => {
    return this.props.isAdmin || this.props.isSr || this.props.isFinanceUser;
  };

  canAddPublicComments = () => {
    return this.props.isSr || this.props.isAdmin;
  };

  canAddPrivateComments = () => {
    return this.props.isAdmin || this.props.isSr || this.props.isFinanceUser;
  };

  componentDidMount() {
    this.props.getBookingComments();
    this.props.getTopNavigationDataRequest();
  }

  render() {
    if (!this.props.booking) {
      return null;
    }

    if (
      this.props.dashboardNetworkRequests.topNavigationDataLoad === ENetworkRequestStatus.PENDING ||
      this.props.getBookingCommentsLoadStatus === ENetworkRequestStatus.PENDING
    ) {
      return (
        <div className="mt-5">
          <LoadingBar />
        </div>
      );
    }

    return (
      <React.Fragment>
        <TopNavigationBar
          userRole={this.props.userRole as EUserType}
          data={this.props.topNavigationData}
          bookingInformationReservationTeamData={this.props.bookingInformationReservationTeamData}
          isError={this.props.dashboardNetworkRequests.topNavigationDataLoad === ENetworkRequestStatus.ERROR}
        />

        <React.Fragment>
          <h1 className="text-4xl text-left font-noe-display font-normal mt-0 mb-0">Comments</h1>
          {this.props.getBookingCommentsLoadStatus === ENetworkRequestStatus.ERROR && <ErrorBar />}
        </React.Fragment>

        {this.props.getBookingCommentsLoadStatus === ENetworkRequestStatus.SUCCESS && (
          <div className="comments flex flex-col grow font-pt-sans mt-4">
            <main className="grid grid-cols-[minmax(0,_50%)_minmax(0,_50%)]">
              <div className="public mr-2">
                <h2 className="mt-0 font-bold text-lg">Public Comments</h2>
                {this.props.getBookingCommentsLoadStatus === ENetworkRequestStatus.SUCCESS &&
                  this.props.publicComments.length <= 0 && (
                    <p className="info empty">This booking has no public comments.</p>
                  )}
                {this.props.getBookingCommentsLoadStatus === ENetworkRequestStatus.SUCCESS &&
                  this.props.publicComments.map(comment => (
                    <BookingComment
                      key={comment.uuid}
                      comment={comment}
                      canEdit={comment.canEdit}
                      isLoading={this.props.bookingCommentUuidsLoading.includes(comment.uuid)}
                      isEditing={this.props.bookingCommentUuidsEditing.includes(comment.uuid)}
                      editingDraft={this.props.bookingCommentUuidsEditingDrafts[comment.uuid]}
                      onEditDraftChange={this.props.setEditingCommentDraft}
                      onStartEdit={this.props.onStartEditingComment}
                      onCancelEdit={this.props.onCancelEditingComment}
                      onCompleteEdit={this.props.onCompleteEditingComment}
                      onDelete={this.props.deleteBookingComment}
                    />
                  ))}
              </div>

              {(this.props.isAdmin || this.props.isSr || this.props.isFinanceUser) && (
                <div className="private ml-2">
                  <h2 className="mt-0 font-bold text-lg">Internal Comments</h2>
                  {this.props.getBookingCommentsLoadStatus === ENetworkRequestStatus.SUCCESS &&
                    this.props.privateComments.length <= 0 && (
                      <p className="info empty">This booking has no private comments.</p>
                    )}
                  {this.props.getBookingCommentsLoadStatus === ENetworkRequestStatus.SUCCESS &&
                    this.props.privateComments.map((comment, index) => (
                      <BookingComment
                        key={comment.uuid}
                        comment={comment}
                        canEdit={comment.canEdit}
                        isLoading={this.props.bookingCommentUuidsLoading.includes(comment.uuid)}
                        isEditing={this.props.bookingCommentUuidsEditing.includes(comment.uuid)}
                        editingDraft={this.props.bookingCommentUuidsEditingDrafts[comment.uuid]}
                        onEditDraftChange={this.props.setEditingCommentDraft}
                        onStartEdit={this.props.onStartEditingComment}
                        onCancelEdit={this.props.onCancelEditingComment}
                        onCompleteEdit={this.props.onCompleteEditingComment}
                        onDelete={this.props.deleteBookingComment}
                      />
                    ))}
                </div>
              )}
            </main>

            <footer className="flex justify-between mt-8">
              <div className="add public w-1/2 mr-2">
                {this.canAddPublicComments() && (
                  <>
                    <UITextArea
                      rows={4}
                      value={this.props.publicCommentDraft}
                      resizable={EUITextAreaResizeType.VERTICAL}
                      onChange={value => {
                        this.props.setBookingCommentDraft(value, 'public');
                      }}
                    />
                    <FluidButton
                      type="primary"
                      className="confirm mt-2"
                      disabled={this.props.addBookingLoadStatus === ENetworkRequestStatus.PENDING}
                      onClick={this.handleAddPublicComment}
                    >
                      Add Public Comment
                    </FluidButton>
                  </>
                )}
              </div>

              <div className="add private w-1/2 ml-2">
                {this.canAddPrivateComments() && (
                  <>
                    <UITextArea
                      rows={4}
                      value={this.props.privateCommentDraft}
                      resizable={EUITextAreaResizeType.VERTICAL}
                      onChange={value => {
                        this.props.setBookingCommentDraft(value, 'private');
                      }}
                    />
                    <FluidButton
                      type="primary"
                      className="confirm mt-2"
                      disabled={this.props.addBookingLoadStatus === ENetworkRequestStatus.PENDING}
                      onClick={this.handleAddPrivateComment}
                    >
                      Add Internal Comment
                    </FluidButton>
                  </>
                )}
              </div>
            </footer>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export type StateToProps = ReturnType<typeof mapStateToProps>;
export type DispatchToProps = typeof actionCreators;

export interface IBookingManagerCommentsProps extends StateToProps, DispatchToProps {}
export interface IBookingManagerCommentsState {}

const mapStateToProps = createStructuredSelector({
  booking: bookingSelector,
  publicComments: Selectors.publicCommentsSelector,
  privateComments: Selectors.privateCommentsSelector,
  publicCommentDraft: Selectors.publicCommentDraftSelector,
  privateCommentDraft: Selectors.privateCommentDraftSelector,
  addBookingLoadStatus: Selectors.addBookingCommentLoadSelector,
  getBookingCommentsLoadStatus: Selectors.getBookingCommentsLoadSelector,
  isSr: isSr,
  isFinanceUser: isFinanceUser,
  isAdmin: isAdmin,
  bookingCommentUuidsLoading: Selectors.bookingCommentUuidsLoadingSelector,
  bookingCommentUuidsEditing: Selectors.bookingCommentUuidsEditingSelector,
  bookingCommentUuidsEditingDrafts: Selectors.bookingCommentUuidsEditingDraftsSelector,
  topNavigationData: topNavigationDataDashboardSelector,
  bookingInformationReservationTeamData: bookingInformationReservationTeamDashboardSelector,
  dashboardNetworkRequests: dashboardNetworkRequestsSelector,
  userRole: getCurrentUserType,
});

const actionCreators = {
  getBookingComments: Actions.getBookingCommentsRequestAction,
  addBookingComment: Actions.addBookingCommentRequestAction,
  setBookingCommentDraft: Actions.setBookingCommentDraftAction,

  updateBookingComment: Actions.updateBookingCommentRequestAction,
  deleteBookingComment: Actions.deleteBookingCommentRequestAction,

  setEditingCommentDraft: Actions.setEditingCommentDraftAction,
  onStartEditingComment: Actions.onStartEditingCommentAction,
  onCancelEditingComment: Actions.onCancelEditingCommentAction,
  onCompleteEditingComment: Actions.onCompleteEditingCommentAction,

  getTopNavigationDataRequest: getTopNavigationDataRequestAction,
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

// -----------------------------------------------------------------------------
// Connected
// -----------------------------------------------------------------------------
const withConnect = connect<StateToProps, DispatchToProps, IBookingManagerCommentsProps>(
  mapStateToProps,
  mapDispatchToProps
);

// @ts-ignore
export const BookingManagerCommentsConnected = compose(withConnect)(BookingManagerComments);
export default BookingManagerCommentsConnected;
