export enum EBookingManageRoutes {
  Overview = 'overview',
  Breakdown = 'breakdown',
  GuestInformation = 'guest-information',
  Comments = 'comments',
  HotelDocuments = 'hotel-documents',
  Uploads = 'uploads',
  Vouchers = 'vouchers',
  Finance = 'finance',
  Settings = 'settings',
}
