import {
  ICredentialsInfo,
  CredentialsInfoSortField,
  CredentialsInfoSortDirection,
  ICredentialsInfoFilters,
  ENetworkRequestStatus
} from "services/BackendApi";

export interface ICredentialsInfoDomain {
  filters: ICredentialsInfoFilters;
  sortField: CredentialsInfoSortField;
  sortDirection: CredentialsInfoSortDirection;

  storedList: ICredentialsInfo[] | null;
  draftList: ICredentialsInfo[] | null;
  request: {
    get: ENetworkRequestStatus;
    post: ENetworkRequestStatus;
  };
}

export const initialState: ICredentialsInfoDomain = {
  filters: {},
  sortField: CredentialsInfoSortField.CREATION_DATE,
  sortDirection: CredentialsInfoSortDirection.DESC,
  
  storedList: null,
  draftList: null,
  request: {
    get: ENetworkRequestStatus.IDLE,
    post: ENetworkRequestStatus.IDLE
  }
};
