import React, { HTMLAttributes, useCallback } from 'react';
import styled from 'styled-components';
import { find, memoizeWith, identity } from 'ramda';
import { saveAs } from 'file-saver';
import { getExtension } from 'utils/file';
import { colors } from '../pureUiTheme';
import { EBreakpoints, breakpointValue } from '../breakpoints';
import { Upload } from 'services/BackendApi/types';

export interface FilePreviewProps extends HTMLAttributes<HTMLDivElement> {
  file: Pick<Upload, 'url' | 'filename'>;
}

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
const PdfPreview = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

const DefaultPreview = styled.div`
  width: 100% !important; //to override svg width
  height: 100% !important; //to override svg height
  max-width: 100%;
  max-height: 100%;
  color: ${colors.grayDarker};
`;

const strategies = [
  {
    types: [
      'png',
      'jpg',
      'jpeg',
      'jfif',
      'pjpeg',
      'pjp',
      'apng',
      'bmp',
      'gif',
      'ico',
      'cur',
      'svg',
      'tif',
      'tiff',
      'webp',
    ],
    render: ({ url }) => <ImagePreview src={url} />,
  },
  {
    types: ['pdf'],
    render: ({ url }) => <PdfPreview src={`${url}?cacheBuster=${Date.now()}#toolbar=0`} />,
  },
];

const defaultStrategy = {
  render: ({ url }) => <DefaultPreview />,
};

const getStrategy = memoizeWith(
  identity,
  (ext: string) => find(item => item.types.some(cur => cur === ext), strategies) || defaultStrategy
);

const FilePreview = (props: FilePreviewProps) => {
  const {
    className,
    file: { url, filename },
  } = props;
  const strategy = getStrategy(getExtension(url));
  const handleDownload = useCallback(() => saveAs(url, filename), [url, filename]);

  return (
    <div className={className}>
      <div className="main">{strategy.render({ url })}</div>
      <button
        onClick={handleDownload}
        className="download cursor-pointer outline-none hover:bg-teal-100 bg-brown-100 rounded-full text-white"
      >
        <svg fill="currentColor" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
          <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
        </svg>
      </button>
    </div>
  );
};

export default styled(FilePreview)`
  background-color: ${colors.grayDark};
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: 1fr 60px;
  grid-row-gap: 10px;

  > .main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .download {
    width: 50px;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
  }

  @media screen and (min-width: ${breakpointValue(EBreakpoints.TABLET_UP)}px) {
    padding: 50px;

    grid-template-columns: 1fr 50px;
    grid-template-rows: 100%;
    grid-column-gap: 50px;

    > .download {
      align-self: end;
    }
  }
`;
