import React, { useCallback } from 'react';

import { StyledRadio } from 'pureUi/StyledRadio';
import { EPdfLogo } from 'store/modules/bookingManager/subdomains/breakdown/model';
import { useSelector } from 'react-redux';
import { getMainCompanySelector } from 'store/modules/bootstrap/selectors';

export interface RadioButtonProps {
  checked: boolean;
  disabled?: boolean;
  label: string;
  logoType: EPdfLogo;
  onChange: (logoType: EPdfLogo) => void;
  labelClassName?: string;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  checked,
  disabled = false,
  label,
  logoType,
  onChange,
  labelClassName,
}) => {
  const handleChange = useCallback(() => {
    onChange(logoType);
  }, [onChange, logoType]);

  return (
    <label
      className={`${labelClassName || ''} inline-flex font-pt-sans text-base leading-19px text-black cursor-pointer`}
    >
      <StyledRadio type="radio" checked={checked} disabled={disabled} onChange={handleChange} />
      <span
        className={`surrogate relative inline-block w-4 h-4 mr-2 
        bg-ivory border border-solid border-gray-40 rounded-full`}
      />
      {label}
    </label>
  );
};

export interface ILogoSelectorProps {
  selectedLogo: EPdfLogo;
  hasTaLogo: boolean;
  onChange: (logoType: EPdfLogo) => void;
  isLoading: boolean;
  isError: boolean;
}

export const LogoSelector: React.FC<ILogoSelectorProps> = ({
  selectedLogo,
  hasTaLogo,
  onChange,
  isLoading,
  isError,
}) => {
  const mainCompany = useSelector(getMainCompanySelector);

  if (isLoading) {
    return <div className="logo-selector loading flex flex-col mt-5 mb-7">Loading logo options...</div>;
  }

  if (isError) {
    return <div className="logo-selector error flex flex-col mt-5 mb-7 text-red-100">Failed to load logo options.</div>;
  }

  return (
    <div className="logo-selector flex">
      <RadioButton
        checked={selectedLogo === EPdfLogo.MAIN_COMPANY_LOGO}
        onChange={onChange}
        label={`${mainCompany?.name} Logo`}
        logoType={EPdfLogo.MAIN_COMPANY_LOGO}
        labelClassName="pe-logo mr-5"
      />
      {hasTaLogo ? (
        <RadioButton
          checked={selectedLogo === EPdfLogo.TA_LOGO}
          label="Travel Partner Logo"
          logoType={EPdfLogo.TA_LOGO}
          onChange={onChange}
          labelClassName="ta-logo"
        />
      ) : (
        <RadioButton
          checked={selectedLogo === EPdfLogo.NO_LOGO}
          label="No Logo"
          logoType={EPdfLogo.NO_LOGO}
          onChange={onChange}
          labelClassName="no-logo"
        />
      )}
    </div>
  );
};

export default LogoSelector;
