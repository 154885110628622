import { IUploadFileInfo } from 'services/BackendApi/types';

export const GET_TA_LOGO_REQUEST = 'bookingManager/GET_TA_LOGO_REQUEST';
export const GET_TA_LOGO_SUCCESS = 'bookingManager/GET_TA_LOGO_SUCCESS';
export const GET_TA_LOGO_FAILURE = 'bookingManager/GET_TA_LOGO_FAILURE';

export type GetTaLogoRequestAction = ReturnType<typeof getTaLogoRequestAction>;
export const getTaLogoRequestAction = () => ({
  type: GET_TA_LOGO_REQUEST as typeof GET_TA_LOGO_REQUEST,
});

export type GetTaLogoSuccessAction = ReturnType<typeof getTaLogoSuccessAction>;
export const getTaLogoSuccessAction = (taLogo: IUploadFileInfo | undefined) => ({
  type: GET_TA_LOGO_SUCCESS as typeof GET_TA_LOGO_SUCCESS,
  taLogo,
});

export type GetTaLogoFailureAction = ReturnType<typeof getTaLogoFailureAction>;
export const getTaLogoFailureAction = (error: string) => ({
  type: GET_TA_LOGO_FAILURE as typeof GET_TA_LOGO_FAILURE,
  error,
});

export type TaLogoAction =
  | GetTaLogoRequestAction
  | GetTaLogoSuccessAction
  | GetTaLogoFailureAction;
