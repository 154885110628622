import React, { FC, HTMLProps } from 'react';
import classNames from 'classnames';
import { isNil, sumBy } from 'lodash-es';
import { formatPriceCents, formatDateDisplay } from 'utils';
import { IFinanceRow } from 'services/BookingManagerApi/types/FinanceResponse';

export interface IInvoiceDueDatesSummaryProps extends HTMLProps<HTMLDivElement> {
  title: string;
  financeRow: IFinanceRow;
  currencySymbol: string;
  showTotal?: boolean;
  showOutstandingBalance?: boolean;
}

export const InvoiceDueDatesSummary: FC<IInvoiceDueDatesSummaryProps> = props => {
  const priceTag = formatPrice(props.currencySymbol);

  const dueDates = props.financeRow.invoiceDueDates || [];
  const outstanding = sumBy(dueDates, item => item.amountCents || 0);

  return (
    <div className={classNames(props.className, 'invoice-due-dates-summary', 'font-pt-sans text-black')}>
      <Row className="header text-15px mb-20px">
        <div className="title font-bold">{props.title}</div>
      </Row>
      {!!props.showTotal && (
        <Row className="total px-15px py-10px bg-teal-20">
          <div className="label text-xs uppercase">Invoice Total</div>
          <div className="value font-bold text-15px">{priceTag(props.financeRow.amountCents)}</div>
        </Row>
      )}
      {!!props.showOutstandingBalance && (
        <Row className="outstanding-balance px-15px py-10px bg-brown-18">
          <div className="label text-xs uppercase">Outstanding Balance</div>
          <div className="value font-bold text-15px">{priceTag(outstanding)}</div>
        </Row>
      )}
      <div className="due-dates bg-brown-15 text-13px py-5px px-15px">
        <div className="title text-gray-100 pb-1px">Due dates:</div>
        <div className="items">
          {dueDates.map(item => (
            <Row key={item.date as string} className="py-1">
              <div className="label">{formatDateDisplay(item.date)}</div>
              <div className="value">{priceTag(item.amountCents)}</div>
            </Row>
          ))}
        </div>
      </div>
    </div>
  );
};

const Row: FC<HTMLProps<HTMLDivElement>> = props => (
  <div className={classNames(props.className, 'flex justify-between items-center')}>{props.children}</div>
);

const formatPrice = (currencySymbol: string) => (amount?: number | null): string =>
  isNil(amount) ? '-' : `${currencySymbol}${formatPriceCents(amount)}`;
