import { BookingBuilderResponse } from 'services/BackendApi';

export const COPY_BOOKING_BUILDER_RESPONSE = 'bookingBuilder/COPY_BOOKING_BUILDER_RESPONSE';

export type CopyBookingBuilderResponseAction = ReturnType<typeof copyBookingBuilderResponseAction>;
export const copyBookingBuilderResponseAction = (bookingBuilderResponse: Omit<BookingBuilderResponse, 'hotel'>) => ({
  type: COPY_BOOKING_BUILDER_RESPONSE as typeof COPY_BOOKING_BUILDER_RESPONSE,
  bookingBuilderResponse,
});

export type BookingBuilderResponseAction =
| CopyBookingBuilderResponseAction;
