import { createSelector } from 'reselect';
import { IOwnershipSubdomain } from './model';
import { ownershipSubdomainSelector } from '../../selectors';

export const ownershipDataSelector = createSelector(
  ownershipSubdomainSelector,
  (subdomain: IOwnershipSubdomain) => subdomain.ownershipData
);

export const managedBySelector = createSelector(
  ownershipSubdomainSelector,
  (subdomain: IOwnershipSubdomain) => subdomain.ownershipData?.managedBy
);

export const getOwnershipRequestStatusSelector = createSelector(
  ownershipSubdomainSelector,
  (subdomain: IOwnershipSubdomain) => subdomain.request.getOwnership
);

export const patchOwnershipRequestStatusSelector = createSelector(
  ownershipSubdomainSelector,
  (subdomain: IOwnershipSubdomain) => subdomain.request.patchOwnership
);

export const assignedSalesRepresentativesSelector = createSelector(
  ownershipSubdomainSelector,
  (subdomain: IOwnershipSubdomain) => subdomain.assignedSalesRepresentatives
);

export const getAssignedSalesRepresentativesRequestStatusSelector = createSelector(
  ownershipSubdomainSelector,
  (subdomain: IOwnershipSubdomain) => subdomain.request.getAssignedSalesRepresentatives
);

export const getTravelPartnerMovementsSelector = createSelector(
  ownershipSubdomainSelector,
  (subdomain: IOwnershipSubdomain) => subdomain.travelPartnerMovements
);
