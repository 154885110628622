import { TCurrencyCode } from 'interfaces';
import { formatPriceCents, formatDateDisplay, getCurrencySymbol } from 'utils';

export const format = {
  amount: (currency: TCurrencyCode) => (amount: number) => {
    const symbol = getCurrencySymbol(currency);
    return `${symbol}${formatPriceCents(amount)}`;
  },

  date: (dateString: string) => formatDateDisplay(dateString),
};
