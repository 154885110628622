import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Actions from './actions';
import { initialState, IAgentsModuleDomain } from './model';
import { getTaFullName } from 'store/utils';

export const agentsDomainReducer = (
  state: IAgentsModuleDomain = initialState,
  action: Actions.AgentsModuleAction
): IAgentsModuleDomain => {
  switch (action.type) {
    case Actions.GET_TRAVEL_AGENTS_REQUEST:
      return {
        ...state,
        requestPending: true,
      };

    case Actions.GET_TRAVEL_AGENTS_SUCCESS:
      return {
        ...state,
        requestPending: false,
        agents: action.agents,
      };

    case Actions.GET_TRAVEL_AGENTS_FAILURE:
      return {
        ...state,
        requestPending: true,
        agents: undefined,
        error: action.error,
      };

    case Actions.SELECTED_TA_CHANGE:
      return {
        ...state,
        selectedTa: action.value,
        taNameSearch: getTaFullName(action.value),
      };

    case Actions.CLEAR_SELECTED_TA:
      return {
        ...state,
        selectedTa: null,
        taNameSearch: '',
      };

    case Actions.SHOW_TA_DROPDOWN:
      return {
        ...state,
        showTaDropdown: action.value,
      };

    case Actions.SEARCH_TA_BY_NAME:
      return {
        ...state,
        taNameSearch: action.value,
      };

    case Actions.GET_COMPANIES_REQUEST:
      return {
        ...state,
        isFetchingCompanies: true,
      };

    case Actions.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        isFetchingCompanies: false,
        companies: action.companies,
      };

    case Actions.GET_COMPANIES_FAILURE:
      return {
        ...state,
        isFetchingCompanies: true,
        companies: null,
        error: action.error,
      };

    case Actions.SELECTED_COMPANY_CHANGE:
      return {
        ...state,
        selectedCompany: action.value,
        companyNameSearch: action.value!.name,
        selectedTa: null,
        selectedCompanyAgents: null,
        isFetchingTA: true,
      };

    case Actions.SHOW_COMPANY_DROPDOWN:
      return {
        ...state,
        showCompanyDropdown: action.value,
      };

    case Actions.SEARCH_COMPANY_BY_NAME:
      return {
        ...state,
        companyNameSearch: action.value,
      };

    case Actions.GET_TRAVEL_AGENTS_BY_COMPANY_ID_SUCCESS:
      return {
        ...state,
        // if the agent has got notifications, we only want them to keep the ones that are for TAs
        selectedCompanyAgents: action.agents.map(agent => {
          if (agent.notifications) {
            agent.notifications = agent.notifications.filter(n => n.role === 'ta') || [];
            return { ...agent };
          } else {
            return { ...agent };
          }
        }),
        isFetchingTA: false,
      };

    case Actions.LOAD_STATE:
      return {
        ...state,
        selectedTa: {
          uuid: action.travelAgentUuid,
        },
      };
    case Actions.LOAD_SELECTED_TA_VIA_UUID_SUCCESS:
      return {
        ...state,
        selectedTa: {
          ...action.selectedTa,
        },
      };

    case Actions.SAVE_ORIGINAL_SELECTION:
      return {
        ...state,
        originalSelection: {
          selectedTa: state.selectedTa,
          taNameSearch: state.taNameSearch,
          selectedCompany: state.selectedCompany,
          companyNameSearch: state.companyNameSearch,
          selectedCompanyMembership: state.selectedCompanyMembership,
        },
      };

    case Actions.RESTORE_ORIGINAL_SELECTION:
      if (state.originalSelection) {
        return {
          ...state,
          ...state.originalSelection,
          originalSelection: null,
        };
      }
      return state;

    case Actions.GET_MEMBERSHIPS_REQUEST:
      return {
        ...state,
        getMembershipsRequest: ENetworkRequestStatus.PENDING,
        companyMemberships: [],
        selectedCompanyMembership: action.resetSelected ? null : state.selectedCompanyMembership,
      };
    case Actions.GET_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        getMembershipsRequest: ENetworkRequestStatus.SUCCESS,
        companyMemberships: action.memberships,
        selectedCompanyMembership: action.resetSelected ? action.memberships[0] : state.selectedCompanyMembership,
      };
    case Actions.GET_MEMBERSHIPS_FAILURE:
      return {
        ...state,
        getMembershipsRequest: ENetworkRequestStatus.ERROR,
        companyMemberships: [],
        selectedCompanyMembership: null,
      };

    case Actions.SET_SELECTED_COMPANY_MEMBERSHIP:
      return {
        ...state,
        selectedCompanyMembership: action.companyMembership,
      };

    default:
      return state;
  }
};

export default agentsDomainReducer;
