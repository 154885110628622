import { 
  EUserType,
} from 'services/BackendApi';

export const userTypesOptions = [
  {
    label: "Finance",
    value: EUserType.FINANCE,
  },
  {
    label: "Sales",
    value: EUserType.SR,
  },
  {
    label: "Admin",
    value: EUserType.ADMIN,
  },
  {
    label: "Rate Loader",
    value: EUserType.RL,
  },
];