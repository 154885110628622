import React from 'react';
import { EImportEntity } from '../../store/modules/importer/model';
import { productsImportDomainSelector } from '../../store/modules/productsImport/selectors';
import Importer, { IImportProps } from 'pureUi/Importer';
import { WithImporter } from 'hoc/WithImporter';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

const ProductsImportContainer = (props: Omit<IImportProps, 'entityName'>) => {
  const { dynamicParameters } = useDynamicParameters();

  return (
    <Importer
      entityName="Products"
      confirmationCopy="This operation takes usually around 1 minute. It cannot be canceld before completion and it is irreversible. If successful, it adds new products."
      {...props}
      dynamicParameters={dynamicParameters}
      pageTitle={
        <h1 className="font-normal font-noe-display text-[36px] leading-46px">
          Products - <span className="text-[26px]">Hotels</span>
        </h1>
      }
    />
  );
};

export const ProductsImportContainerConnected = WithImporter(
  EImportEntity.PRODUCTS,
  productsImportDomainSelector
)(ProductsImportContainer);
