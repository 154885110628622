import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styled from 'styled-components';

import { ENetworkRequestStatus } from 'services/BackendApi';
import { EIncentiveAmountType } from 'services/BookingManagerApi/types/Incentives';
import * as Selectors from 'store/modules/companyIncentives/selectors';
import * as Actions from 'store/modules/companyIncentives/actions';
import { formatDate } from 'utils';
import { theme } from '../../../../tailwind.config';
import SingleDateInput from 'pureUi/SingleDateInput';
import TextInput from 'pureUi/TextInput';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { NumericMaskedInput } from 'ui/stateful/MaskedInput';
import { DecimalInput } from 'ui/stateful/DecimalInput';
import FluidButton from 'ui/FluidButton';
import Checkbox from 'ui/Checkbox';
import { Radio } from 'ui/Radio';
import { LoadingBar } from 'ui/NetworkStatusBar';

interface CompanyIncentivesProps {
  companyUuid: string;
  isEditMode: boolean;
}

const StyledSingleDateInput = styled(SingleDateInput)`
  .pseudoSelect {
    outline: none;
    width: 115px;
    height: 35px;
    font-family: 'PT Sans';
    font-size: 15px;
    text-transform: none;
    color: ${theme.colors['black']};
  }
`;

const StyledTextInput = styled(TextInput)`
  input {
    font-family: 'PT Sans';
    font-size: 15px;
    color: ${theme.colors['black']};
  }
`;

export const CompanyIncentives: React.FC<CompanyIncentivesProps> = ({ companyUuid, isEditMode }) => {
  const request = useSelector(Selectors.requestSelector);
  const incentivesEnabled = useSelector(Selectors.incentivesEnabledSelector);
  const incentiveAmount = useSelector(Selectors.incentiveAmountSelector);
  const incentiveAmountType = useSelector(Selectors.incentiveAmountTypeSelector);
  const incentiveStartDate = useSelector(Selectors.incentiveStartDateSelector);
  const incentiveNotes = useSelector(Selectors.incentiveNotesSelector);
  const isValid = useSelector(Selectors.fieldValidationSelector);
  const requestError = useSelector(Selectors.errorSelector);
  const [hasErrors, setHasErrors] = useState(false);
  const dirty = useSelector(Selectors.dirtySelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.fetchCompanyIncentivesRequestAction());
  }, [dispatch]);

  const toggleIncentives = useCallback(() => {
    dispatch(Actions.toggleCompanyIncentivesAction());
  }, [dispatch]);

  const setAmount = useCallback(
    (amount: number) => {
      if (incentiveAmountType === EIncentiveAmountType.PERCENTAGE && amount > 1) {
        setHasErrors(true);
        return;
      }

      if (hasErrors) {
        setHasErrors(false);
      }

      dispatch(Actions.setCompanyIncentiveAmountAction(Number(amount)));
    },
    [dispatch, incentiveAmountType, hasErrors]
  );

  const setFlatAmountType = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(Actions.setCompanyIncentiveTypeAction(EIncentiveAmountType.FLAT));
    },
    [dispatch]
  );

  const setPercentageType = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(Actions.setCompanyIncentiveTypeAction(EIncentiveAmountType.PERCENTAGE));
    },
    [dispatch]
  );

  const saveChanges = useCallback(() => {
    incentivesEnabled
      ? dispatch(Actions.upsertCompanyIncentivesRequestAction())
      : dispatch(Actions.deleteCompanyIncentivesRequestAction());
  }, [dispatch, incentivesEnabled]);

  const percetangeValidValue = useMemo(() => {
    if (!incentiveAmount || incentiveAmount > 1) {
      return '0.00';
    }

    return incentiveAmount.toString();
  }, [incentiveAmount]);

  const isLoaded =
    request.get === ENetworkRequestStatus.SUCCESS ||
    (request.get === ENetworkRequestStatus.ERROR && (requestError as any)?.response?.status === 404);

  return (
    <div className="company-incentives w-full">
      <p className="title font-pt-sans text-black-true font-bold text-17px leading-md mt-25px mb-0">
        Travel Partner Incentive Program
      </p>
      <div className="flex items-center justify-between mt-5px mb-30px">
        <p className="description font-pt-sans text-black text-15px leading-sm m-0">
          This incentive scheme will entitle the TP to receive a credit upon the successful completion of each booking.
        </p>
      </div>

      {request.get === ENetworkRequestStatus.PENDING && <LoadingBar />}

      {isLoaded && (
        <div className="font-pt-sans text-base text-black">
          <label className="flex items-center cursor-pointer w-fit">
            <Checkbox 
              checked={incentivesEnabled} 
              onChange={toggleIncentives} 
              disabled={!isEditMode}
            />
            <span className="ml-2">Enable Incentive Program</span>
          </label>

          {incentivesEnabled && (
            <>
              <label className="flex flex-col mt-30px w-fit">
                <span className="text-13px mb-5px tracking-2xs">Incentive to Apply</span>
                <div className="flex items-start relative">
                  {incentiveAmountType === EIncentiveAmountType.PERCENTAGE && (
                    <div className="w-115px">
                      <NumericMaskedInput
                        style={hasErrors ? { border: '1px solid #D4244E', zIndex: 1 } : {}}
                        initialValue={percetangeValidValue}
                        value={`${incentiveAmount}`}
                        mask={'#.##'}
                        onChange={setAmount}
                        allowZero
                        disabled={!isEditMode}
                      />

                      <span style={{ top: '7px', left: '85px' }} className="absolute text-base text-gray-80 ml-2">
                        %
                      </span>
                    </div>
                  )}

                  {incentiveAmountType === EIncentiveAmountType.FLAT && (
                    <div className="w-115px">
                      <DecimalInput
                        className={classNames(
                          'w-115px font-pt-sans text-black text-15px',
                          'bg-ivory border border-solid border-gray-40 py-7px pl-10px outline-none'
                        )}
                        decimalPlaces={2}
                        onBlur={val => {
                          setAmount(Number(val as number));
                        }}
                        value={incentiveAmount ?? 0}
                        disabled={!isEditMode}
                      />
                    </div>
                  )}

                  <label className="flex cursor-pointer items-end leading-35px ml-20px" onClick={(e) => {if(isEditMode){setPercentageType(e)}}}>
                    <Radio 
                      checked={incentiveAmountType === EIncentiveAmountType.PERCENTAGE} 
                      isDisabled={!isEditMode}
                    />
                    <span className="ml-2">Percentage (%)</span>
                  </label>
                  <label className="flex cursor-pointer items-end leading-35px ml-20px" onClick={(e) => {if(isEditMode){setFlatAmountType(e)}}}>
                    <Radio 
                      checked={incentiveAmountType === EIncentiveAmountType.FLAT} 
                      isDisabled={!isEditMode}
                    />
                    <span className="ml-2">Flat Rate</span>
                  </label>
                </div>

                {hasErrors && <span className="text-red-95 text-13px">Invalid percentage or format</span>}
                {incentiveAmountType === EIncentiveAmountType.PERCENTAGE && (
                  <span className="text-13px text-gray-100 mt-5px">
                    Max. Percentage 1.00 % | Format two decimals (#.##)
                  </span>
                )}
                {incentiveAmountType === EIncentiveAmountType.FLAT && (
                  <span className="text-13px text-gray-100 mt-5px">
                    Rate applied to the TC - Currency will vary depending on the hotel booked
                  </span>
                )}
              </label>

              <label
                className="flex flex-col mt-20px w-fit"
                onClick={e => {
                  e.preventDefault();
                }}
              >
                <span className="text-13px mb-5px tracking-2xs">Apply From (Departure date)</span>
                <div className="flex relative w-115px">
                  <StyledSingleDateInput
                    calendarCount={1}
                    value={incentiveStartDate !== null ? new Date(incentiveStartDate) : null}
                    onChange={value => {
                      dispatch(Actions.setCompanyIncentiveStartDateAction(formatDate(value)));
                    }}
                    enablePastDates={true}
                    placeholder="Date"
                    internalClassName={classNames('incentive-start-date', { dateInputField: true })}
                    isDisabled={!isEditMode}
                  />

                  <span
                    style={{ top: '7px', left: '85px' }}
                    className="pointer-events-none absolute text-gray-100 ml-2"
                  >
                    <i className="fas fa-calendar" />
                  </span>
                </div>
                <span className="text-13px text-gray-100 mt-5px">
                  The deposit will be credited on the TP account 2 days after the date above
                </span>
              </label>

              <label className="flex flex-col mt-20px w-fit">
                <span className="text-13px mb-5px tracking-2xs">Comment (Optional)</span>
                <div className="flex w-600px">
                  <StyledTextInput
                    value={incentiveNotes}
                    textTransform={'inherit'}
                    className="w-full"
                    onChange={val => dispatch(Actions.setCompanyIncentiveNotesAction(val.currentTarget.value))}
                    disabled={!isEditMode}
                  />
                </div>
              </label>
            </>
          )}

          {isEditMode && (<FluidButton
            type="primary"
            className="save mt-25px"
            onClick={saveChanges}
            disabled={!dirty || !isValid || request.post === ENetworkRequestStatus.PENDING}
            isLoading={request.post === ENetworkRequestStatus.PENDING}
          >
            Save Changes
          </FluidButton>)}
        </div>
      )}

      <LeaveWithoutSavingModal when={dirty} />
    </div>
  );
};

export default CompanyIncentives;
