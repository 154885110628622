import React, { useEffect, Fragment, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as AgentsActions from 'store/modules/agents/actions';
import * as AgentsSelectors from 'store/modules/agents/selectors';

import { SearchArea } from './Dashboard.styles';
import qs from 'qs';
import SearchBar from 'containers/SearchBar';

export const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSearchButtonClick = useCallback(
    searchQuery => {
      history.push(`/search/beta/?${qs.stringify(searchQuery)}`);
    },
    [history]
  );

  const selectedTa = useSelector(AgentsSelectors.selectedTaSelector);

  useEffect(() => {
    // when we mount the dashboard, if we already have a selected TA its because we've chosen one
    // before, and then returned to this page. if that's the case, we reload the memberships so they're correct
    // as the user _may_ have altered the memberships by changing TCs via the booking builder search bar
    if (selectedTa) {
      dispatch(AgentsActions.getMembershipsRequestAction(selectedTa.companyUuid, false));
    }
  }, []);

  return (
    <Fragment>
      <SearchArea>
        <div className="container mx-auto max-w-1280px">
          <div className="search-bar-container p-5 max-w-1190px" style={{ backgroundColor: 'rgba(248,248,248,0.85)' }}>
            <SearchBar onSearch={handleSearchButtonClick} />
          </div>
        </div>
      </SearchArea>
    </Fragment>
  );
};

export default Dashboard;
