import { IChecklistItem } from 'services/BookingManagerApi';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';

export interface IChecklistSubdomain {
  checklist: IChecklistItem[];
  networkRequests: IChecklistSubdomainNetworkRequests;
}

export interface IChecklistSubdomainNetworkRequests {
  checklistLoad: ENetworkRequestStatus;
  checklistUpdate: ENetworkRequestStatus;
}

export const initialState: IChecklistSubdomain = {
  checklist: [],
  networkRequests: {
    checklistLoad: ENetworkRequestStatus.IDLE,
    checklistUpdate: ENetworkRequestStatus.IDLE,
  },
};
