import * as React from 'react';
import classnames from 'classnames';
import { EPurchaseCostReviewStatus } from 'services/BookingManagerApi/types';

export interface IPurchaseCostReviewStatusProps {
  className?: string;
  status: EPurchaseCostReviewStatus;
}

const ICONS = {
  [EPurchaseCostReviewStatus.PENDING]: 'fa-exclamation-circle text-yellow-30',
  [EPurchaseCostReviewStatus.APPROVED]: 'fa-check-circle text-teal-100',
  [EPurchaseCostReviewStatus.REJECTED]: 'fa-exclamation-triangle text-red-95',
};

export const PurchaseCostReviewStatus: React.FC<IPurchaseCostReviewStatusProps> = ({ status, className }) => {
  if (!ICONS[status]) {
    return null;
  }

  return <i className={classnames(className, 'purchase-cost-review-status', status, 'fas', ICONS[status])} />;
};
