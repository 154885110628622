import classNames from 'classnames';
import useOnClickOutside from 'hooks/useOnClickOutside';
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';

export interface IPopupProps {
  isVisible: boolean;
  onShow?: () => void;
  onHide?: (e: React.MouseEvent<any>) => void;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  overlayClose?: boolean;
  overlayClassName?: string;
  overlayStyle?: React.CSSProperties;
  triggerRef?: RefObject<any>;
}

export const Popup = ({ isVisible, onShow, onHide, children, className, style, overlayClose, overlayClassName, overlayStyle, triggerRef }: IPopupProps) => {
  const ref = useRef(null);
  const [exceptionRefs] = useState(triggerRef ? [triggerRef] : []);
  const OnClickOutsideHandler = useMemo(() => {
    if (overlayClose && onHide) {
      return (e: React.MouseEvent<any>) => onHide(e)
    }
  }, [onHide]);
  useOnClickOutside(ref, OnClickOutsideHandler, exceptionRefs);

  useEffect(() => {
    if (isVisible && onShow) {
      onShow();
    }
  }, [isVisible, onShow]);
  return (
    <div ref={ref} className={classNames('popup-overlay', { overlayClassName: overlayClassName })} style={overlayStyle} >
      <div className={`popup ${className}`} style={{ display: isVisible ? 'block' : 'none', ...style }}>
        {children}
      </div>
    </div>
  );
};