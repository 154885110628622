import { call, takeLatest, select, put } from 'redux-saga/effects';
import {
  CreateCompanyRequestAction,
  createCompanySuccessAction,
  createCompanyFailureAction,
  CREATE_COMPANY_REQUEST,
} from '../actions';
import { ICompanyResponse, makeBackendApi } from 'services/BackendApi';
import { selectedTaSelector } from '../../agents';
import { enqueueNotification } from '../../ui';
import { AxiosResponse } from 'axios';

export function* createCompanySaga(action: CreateCompanyRequestAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);

    const companyResponse: AxiosResponse<ICompanyResponse> = yield call(backendApi.createCompany, action.company);
    yield put(createCompanySuccessAction(companyResponse.data.data));
    yield put(
      enqueueNotification({
        message: 'New company created',
        options: { variant: 'success' },
      })
    );
    yield call(action.history.push, '/travel-companies');
  } catch (e) {
    yield put(createCompanyFailureAction(e));
    const errors = e.response.data?.errors ?? [];
    for (let error of errors) {
      yield put(
        enqueueNotification({
          message: error.detail,
          options: { variant: 'error' },
        })
      );
    }
  }
}

export function* watchCreateCompanySaga() {
  yield takeLatest(CREATE_COMPANY_REQUEST, createCompanySaga);
}
