import { useCallback, useState } from 'react';
import { EPdfLogo } from 'store/modules/bookingManager/subdomains/breakdown/model';
import * as FastSearchSelectors from 'store/modules/fastSearch/selectors';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import { handleDownloadQuote } from '../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { ModalModes } from 'containers/SummaryForm/const';
import { isQuoteDownloadingSelector } from 'store/modules/bookingConfirmation/subdomains/quote/selectors';
import { setQuoteDownloadingAction } from 'store/modules/bookingConfirmation/subdomains/quote/actions';

export const useDownloadQuote = ({
  setRequestInProgress,
  backendApi,
  bookingManagerApi,
  enqueueNotification,
  containsLiveRates,
  selectedCompanyMembership,
  closeModalRatesWarning,
  setShouldShowLeaveAlert,
  openModalRatesWarning,
  bookingBuild,
  taMarginAmountOverride,
  actingOnBehalfOfUser,
  selectedTa,
  basketBuildUuid,
  onPostRemove,
  clientCountryCode,
}) => {
  const dispatch = useDispatch();
  const [isQuoteMarginSelectorVisible, setQuoteMarginSelectorVisibility] = useState(false);
  const isQuoteDownloading = useSelector(isQuoteDownloadingSelector);
  const bookingDomain = useSelector(BookingBuilderSelectors.bookingSelector);

  const handleDownloadQuoteButton = useCallback(() => {
    setQuoteMarginSelectorVisibility(true);
    closeModalRatesWarning();
  }, [closeModalRatesWarning]);

  const handleDownloadQuoteModalButton = async (quoteMrginLogoOption: EPdfLogo | null) => {
    setRequestInProgress(true);
    setShouldShowLeaveAlert(false);
    let hasError = false;
    dispatch(setQuoteDownloadingAction(true));
    try {
      await handleDownloadQuote({
        bookingBuild,
        backendApi,
        bookingManagerApi,
        bookingDomain,
        enqueueNotification,
        clientCountryCode,
        quoteMrginLogoOption,
        selectedCompanyMembership,
        taMarginAmountOverride,
        actingOnBehalfOfUser,
        selectedTa,
        basketBuildUuid,
        onPostRemove,
      });
    } catch (e) {
      hasError = true;
      throw e;
    } finally {
      if (!hasError) {
        setQuoteMarginSelectorVisibility(false);
      }
      dispatch(setQuoteDownloadingAction(false));
      setRequestInProgress(false);
    }

    closeModalRatesWarning();
  };

  const handleDownloadQuoteModalClose = useCallback(() => setQuoteMarginSelectorVisibility(false), []);

  const handleDownloadQuoteButtonTrigger = useCallback(() => {
    if (containsLiveRates) {
      openModalRatesWarning(ModalModes.DOWNLOAD);
    } else {
      handleDownloadQuoteButton();
    }
  }, [containsLiveRates, handleDownloadQuoteButton, openModalRatesWarning]);

  return {
    handleDownloadQuoteButtonTrigger,
    handleDownloadQuoteModalButton,
    handleDownloadQuoteButton,
    handleDownloadQuoteModalClose,
    isQuoteMarginSelectorVisible,
    isQuoteDownloading,
  };
};
