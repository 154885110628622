import styled from 'styled-components';
import { pureUiTheme } from 'pureUi/pureUiTheme';

export const BookingListStylesWrapper = styled.section`
  width: 90%;
  max-width: 1280px;
  align-self: center;

  .filter-ctas {
    grid-area: filter-ctas;
  }

  .settings {
    grid-area: settings;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .settings > label {
    margin-left: 10px;
    color: ${pureUiTheme.colors.black};
    font-size: 12px;
    :first-child {
      margin-left: 0;
    }
    :last-child {
      flex-grow: 1;
    }
  }

  .settings .filterInput {
    flex-grow: 1;
    height: 38px;
    input {
      padding: 10px;
    }
  }

  .date-filters > .date-range-filter {
    .pseudoSelect {
      text-transform: none;
    }
  }

  .table {
    grid-area: table;
  }

  .pagination {
    margin: 20px 0;
  }

  .table .id {
  }

  .table .client {
    width: 10%;
  }
  .table .created {
    width: 13%;
  }

  .table .bookingCount {
    width: 85px;
  }

  .table .hotelCount {
    width: 85px;
  }

  .table .comments {
    width: auto;
  }
  .table .actions {
    width: 8%;
  }

  .table .centered {
    text-align: center;
  }

  .td.actions {
    display: flex;
  }

  /* Todo: Make the TextInput responsible for styling icons */
  .searchIcon {
    height: 100%;
    color: ${pureUiTheme.colors.goldLight};
    margin-right: 10px;
  }
`;
