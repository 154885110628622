import React from 'react';

export const EmptyHeaderProducts = () => {
    return (
        <React.Fragment>
            <th className=""></th>
            <th className="w-14% text-gray-darker text-xs"></th>
            <th className="w-14% text-gray-darker text-xs"></th>
        </React.Fragment>
    );
}