import { call, takeLatest, select, put } from 'redux-saga/effects';
import {
  UpdateCompanyLogoPositionAction,
  UPDATE_COMPANY_LOGO_POSITION,
  updateCompanyLogoPositionSuccessAction,
  fetchCompanyLogoFailureAction,
  fetchCompanyDataRequestAction,
} from '../actions';
import { makeBackendApi } from 'services/BackendApi';
import { selectedTaSelector } from '../../agents';
import { enqueueNotification } from '../../ui';

export function* updateCompanyLogoPositionSaga(action: UpdateCompanyLogoPositionAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    yield call(backendApi.setCompanyLogoPosition, action.companyUuid, action.position);
    yield put(updateCompanyLogoPositionSuccessAction(action.position));
    yield put(fetchCompanyDataRequestAction(action.companyUuid));
    yield put(
      enqueueNotification({
        message: 'Company logo position has been updated',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(fetchCompanyLogoFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'Error updating company logo position',
        options: { variant: 'success' },
      })
    );
  }
}

export function* watchUpdateCompanyLogoPosition() {
  yield takeLatest(UPDATE_COMPANY_LOGO_POSITION, updateCompanyLogoPositionSaga);
}
