import { createSelector } from 'reselect';
import { ISRDomain } from './model';

export const domainSelector = (state: any): ISRDomain => state.sr;

export const allSRsSelector = createSelector(
  domainSelector,
  (domain: ISRDomain) => domain.list
);

export const requestSelector = createSelector(
  domainSelector,
  (domain: ISRDomain) => domain.request
);

export const getAllSRsRequestSelector = createSelector(
  requestSelector,
  (domain: ISRDomain['request']) => domain.getList
);
