import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENetworkRequestStatus } from 'services/BackendApi';

import { LoadingBar } from 'ui/NetworkStatusBar';
import { PageTitle } from 'ui/PageTitle';

import { requestSelector, draftListSelector } from '../../store/modules/credentialsInfo/selectors';
import { getRequestAction } from '../../store/modules/credentialsInfo/actions';

import { Filters } from './Filters';
import { Table } from './Table';
import { Controls } from './Controls';

export const List: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRequestAction());
  }, []);

  const request = useSelector(requestSelector);
  const loading = request.get === ENetworkRequestStatus.PENDING;

  const list = useSelector(draftListSelector);

  if (loading) {
    return (
      <div className="">
        <LoadingBar />
      </div>
    );
  }

  const getResultsText = () => {
    if (loading) {
      return '';
    }
    if (!list?.length) {
      return 'no results found';
    }
    if (list?.length) {
      return `${list.length} ${list.length === 1 ? 'result' : 'results'} found`;
    }
  };

  return (
    <div className="credentials-info-list-page flex justify-center">
      <div className="content min-w-1280px max-w-1280px">
        <PageTitle primaryText="TA History" secondaryText={`(${getResultsText()})`} />
        <p className="description font-pt-sans text-black text-15px leading-sm mt-5px mb-6">
          This table aims to keep a record about App credentials sent to the Travel Agents.
        </p>
        <Filters />
        <Table />
        <Controls />
      </div>
    </div>
  );
};
