import React from 'react';
import { IImportStatus, EGenericStatusValue } from 'services/BackendApi';
import { formatDateTimeDisplayWithoutOffset } from 'utils/date';
import { theme } from '../../../../tailwind.config';
import ClockIcon from 'ui/Icons/clock.component.svg';
import { SvgIcon } from 'ui/SvgIcon';

export interface LatestStatusInfoProps {
  className?: string;
  status: IImportStatus;
}

const StatusLabels: { [key in EGenericStatusValue]: string } = {
  [EGenericStatusValue.PENDING]: 'Pending',
  [EGenericStatusValue.IN_PROGRESS]: 'In Progress',
  [EGenericStatusValue.DONE]: 'Done',
};

const jobDoneOrInProgress = (latestStatus: EGenericStatusValue): 'Done' | 'In Progress' => {
  if (latestStatus === EGenericStatusValue.IN_PROGRESS || latestStatus === EGenericStatusValue.PENDING) {
    return 'In Progress';
  } else {
    return 'Done';
  }
};

const LatestStatusInfo = (props: LatestStatusInfoProps) => {
  const { status } = props;

  return (
    <div className="flex items-center space-x-1">
      <SvgIcon
        IconComponent={ClockIcon}
        width="22px"
        height="22px"
        className="p-1"
        defaultFill={theme.colors['gray-80']}
        hoverFill={theme.colors['gray-80']}
        activeFill={theme.colors['gray-80']}
      />
      <div className="font-pt-sans text-15px text-gray-100 leading-19px">
        Latest import: <span className="uppercase">{jobDoneOrInProgress(status.status)}</span> (
        {formatDateTimeDisplayWithoutOffset(status.createdAt)})
      </div>
    </div>
  );
};

export default LatestStatusInfo;
