import { history } from 'store';

export const initWoopra = woopraDomain => {
  if (woopraDomain) {
    !(function() {
      var t,
        o,
        c,
        e = window,
        n = document,
        r = arguments,
        a = 'script',
        i = [
          'call',
          'cancelAction',
          'config',
          'identify',
          'push',
          'track',
          'trackClick',
          'trackForm',
          'update',
          'visit',
        ],
        s = function() {
          var t,
            o = this,
            c = function(t) {
              o[t] = function() {
                return o._e.push([t].concat(Array.prototype.slice.call(arguments, 0))), o;
              };
            };
          for (o._e = [], t = 0; t < i.length; t++) c(i[t]);
        };
      for (e.__woo = e.__woo || {}, t = 0; t < r.length; t++) e.__woo[r[t]] = e[r[t]] = e[r[t]] || new s();
      ((o = n.createElement(a)).async = 1),
        (o.src = 'https://static.woopra.com/js/w.js'),
        (c = n.getElementsByTagName(a)[0]).parentNode.insertBefore(o, c);
    })('woopra');

    // eslint-disable-next-line no-undef
    woopra &&
      // eslint-disable-next-line no-undef
      woopra.config({
        domain: woopraDomain,
        outgoing_tracking: true,
        download_tracking: true,
        click_tracking: true,
      });

    const pathnamesToTrack = ['/search', '/hotels'];

    const shouldTrack = pathname => pathnamesToTrack.some(x => pathname.startsWith(x));

    const track = () =>
      // eslint-disable-next-line no-undef
      woopra && shouldTrack(history.location.pathname) && woopra.track();

    track();
    history.listen(track);
  }
};
