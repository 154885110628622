import React, { useCallback, useState } from 'react';

interface IShowMoreTextProps {
  linkClassname: string;
  textClassname: string;
  text: string;
  linkLabelWhenOpen: string;
  linkLabelWhenCollapsed: string;
  maxVisibleCharacters: number;
}

export const ShowMoreText: React.FC<IShowMoreTextProps> = React.memo(
  ({ maxVisibleCharacters, linkClassname, textClassname, text, linkLabelWhenOpen, linkLabelWhenCollapsed }) => {
    const isTextTooLong = text.length > maxVisibleCharacters;
    const longVersion = text;
    const shortVersion = isTextTooLong ? `${text.slice(0, maxVisibleCharacters)}...` : text;

    const [isLongVersion, setIsLongVersion] = useState(false);
    const linkLabel = isLongVersion ? linkLabelWhenOpen : linkLabelWhenCollapsed;

    const toggleShowMore = useCallback(() => {
      setIsLongVersion(!isLongVersion);
    }, [isLongVersion]);

    return (
        <p className={textClassname}>
          {isLongVersion ? longVersion : shortVersion}
          {isTextTooLong ? <a className={linkClassname} onClick={toggleShowMore}>{linkLabel}</a> : null}
        </p>
    );
  }
);
