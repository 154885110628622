import { StandardModal } from 'pureUi/Modal';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { ErrorMessage, Field, Formik, FormikErrors, FormikProps } from 'formik';

import { ENetworkRequestStatus } from 'services/BackendApi';
import { IInvoiceAddressee } from 'services/BookingManagerApi/types';
import {
  closeAddresseeManualModalAction,
  closeAddresseeModalAction,
  saveInvoiceAddresseeRequestAction,
  saveManualInvoiceAddresseeRequestAction,
} from 'store/modules/bookingManager/subdomains/breakdown/actions';
import TextInput from 'pureUi/TextInput';
import FluidButton from 'ui/FluidButton';
import { Multiselect } from 'ui/Multiselect';
import { isBlank } from 'utils';
import { AddresseeModalStyles } from './AddresseeModalStyles';
import FormLabel from './FormLabel';

export interface ICountry {
  code: string;
  name: string;
  region: string;
  isDestination: boolean;
}

export interface IAddresseeModal {
  isOpen: boolean;
  invoiceAddressee?: IInvoiceAddressee;
  invoiceAddresseeSave: ENetworkRequestStatus;
  countries: ICountry[];
  isManual?: boolean;
}

export interface IAddresseeFormValues {
  name?: string;
  company?: string;
  address?: string;
  countryCode?: string;
  'email-field'?: string; // Change from 'email' to 'email-field' to prevent LastPass from autocomplete the field.
  vat?: string | null;
  iata?: string | null;
}

const sanitizeFormValues = (invoiceAddressee: IAddresseeFormValues): IInvoiceAddressee => {
  return {
    name: invoiceAddressee.name?.trim() ?? '',
    company: invoiceAddressee.company?.trim() ?? '',
    address: invoiceAddressee.address?.trim() ?? '',
    countryCode: invoiceAddressee.countryCode,
    email: invoiceAddressee['email-field']?.trim() ?? '',
    vat: invoiceAddressee.vat?.trim() ?? '',
    iata: invoiceAddressee.iata?.trim() ?? '',
  };
};

export const AddresseeModal: React.FC<IAddresseeModal> = React.memo(
  ({ isOpen, invoiceAddressee, invoiceAddresseeSave, countries, isManual = false }) => {
    const dispatch = useDispatch();
    const saveInvoice = useMemo(() => {
      if (isManual) {
        return saveManualInvoiceAddresseeRequestAction
      }
      else return saveInvoiceAddresseeRequestAction;
    },[isManual]);

    const handleCloseModal = useCallback(() => {      
      if (isManual) {
        dispatch(closeAddresseeManualModalAction())
      } else {
        dispatch(closeAddresseeModalAction())
      };
    }, [dispatch, isManual]);

    const handleFormSubmit = useCallback(
      (invoiceAddressee: IAddresseeFormValues) => {
        const sanitizedInvoiceAddressee = sanitizeFormValues(invoiceAddressee);
        dispatch(saveInvoice(sanitizedInvoiceAddressee));
      },
      [dispatch]
    );

    const initialValues: IAddresseeFormValues = {
      name: invoiceAddressee?.name ?? '',
      company: invoiceAddressee?.company ?? '',
      address: invoiceAddressee?.address ?? '',
      countryCode: invoiceAddressee?.countryCode ?? '',
      'email-field': invoiceAddressee?.email ?? '',
      vat: invoiceAddressee?.vat ?? '',
      iata: invoiceAddressee?.iata ?? '',
    };

    const validateAddresseeForm = useCallback((values: IAddresseeFormValues) => {
      const errors: FormikErrors<IAddresseeFormValues> = {};
      if (!values.name?.trim().length && !values.company?.trim().length) {
        errors.name = 'A Full Name or a Company Name is mandatory to continue. Please fill it to continue';
      }
      return errors;
    }, []);

    if (!isOpen) {
      return null;
    }

    return (
      <StandardModal
        className="items-start bg-black-true-transparent-60 z-11"
        frameClassName="pt-6 px-8 pb-8 border border-solid border-black-true w-740px"
        overflowY="visible"
        showCloseButton={false}
        removePadding={true}
      >
        <AddresseeModalStyles>
          <Formik
            initialValues={initialValues}
            validate={validateAddresseeForm}
            enableReinitialize={true}
            onSubmit={handleFormSubmit}
          >
            {(form: FormikProps<IAddresseeFormValues>) => {
              return (
                <form onSubmit={form.handleSubmit}>
                  <div
                    className="text-base text-brown-100 absolute top-4 right-4 cursor-pointer outline-none"
                    onClick={handleCloseModal}
                  >
                    <i className="fas fa-times"></i>
                  </div>
                  <h3 className="font-noe-display font-normal text-21px leading-27px text-black mt-0 mb-10px">
                    Edit Invoice Addressee
                  </h3>
                  <div className="name-and-company">
                    <div className="flex justify-between">
                      <FormLabel className="name" text="Full Name" style={{ width: '360px' }}>
                        <Field
                          as={TextInput}
                          name="name"
                          disabled={invoiceAddresseeSave === ENetworkRequestStatus.PENDING}
                          className={classNames({
                            fieldError: form.touched.name && form.errors.name,
                            fieldDisabled: invoiceAddresseeSave === ENetworkRequestStatus.PENDING,
                          })}
                          inputClassName={classNames('bg-ivory border-gray-40', {
                            'bg-red-gradient': form.touched.name && form.errors.name,
                          })}
                        />
                      </FormLabel>

                      <FormLabel className="company" text="Company" style={{ width: '290px' }}>
                        <Field
                          as={TextInput}
                          name="company"
                          disabled={invoiceAddresseeSave === ENetworkRequestStatus.PENDING}
                          className={classNames({
                            fieldError: form.touched.name && form.errors.name,
                            fieldDisabled: invoiceAddresseeSave === ENetworkRequestStatus.PENDING,
                          })}
                          inputClassName={classNames('bg-ivory border-gray-40', {
                            'bg-red-gradient': form.touched.name && form.errors.name,
                          })}
                        />
                      </FormLabel>
                    </div>
                    {/* @ts-ignore */}
                    <ErrorMessage
                      name="name"
                      className="errorMessage text-13px leading-17px mt-5px text-red-95 font-pt-sans"
                      component="div"
                    />
                  </div>
                  <div className="address flex">
                    <FormLabel className="address" text="Address" style={{ width: '100%' }}>
                      <Field
                        as={TextInput}
                        name="address"
                        disabled={invoiceAddresseeSave === ENetworkRequestStatus.PENDING}
                        className={classNames({
                          fieldError: form.touched.address && form.errors.address,
                          fieldDisabled: invoiceAddresseeSave === ENetworkRequestStatus.PENDING,
                        })}
                        inputClassName="bg-ivory border-gray-40"
                      />
                      {/* @ts-ignore */}
                      <ErrorMessage name="address" className="errorMessage" component="div" />
                    </FormLabel>
                  </div>
                  <div className="country-and-email flex justify-between">
                    <FormLabel className="country" text="Country">
                      <Field name="countryCode">
                        {({ field: { name, value }, form: { setFieldValue } }) => (
                          <Multiselect
                            className={'country min-w-290px'}
                            itemsClassname={classNames({
                              'bg-ivory': invoiceAddresseeSave !== ENetworkRequestStatus.PENDING,
                              'bg-gray-10': invoiceAddresseeSave === ENetworkRequestStatus.PENDING,
                            })}
                            openClassName="outline outline-teal-100"
                            onUpdate={sv => {
                              setFieldValue(name, sv[0]);
                            }}
                            itemsToDisplayBeforeScroll={7}
                            selectedValues={[value].filter(x => !isBlank(x))}
                            hideCheckboxes={true}
                            isSingleSelectMode={true}
                            isCloseOnSelect={true}
                            options={countries.map(country => ({
                              value: country.code,
                              label: country.name,
                            }))}
                            disabled={invoiceAddresseeSave === ENetworkRequestStatus.PENDING}
                          />
                        )}
                      </Field>
                    </FormLabel>

                    <FormLabel className="email" text="Email" style={{ width: '360px' }}>
                      <Field
                        as={TextInput}
                        name="email-field"
                        disabled={invoiceAddresseeSave === ENetworkRequestStatus.PENDING}
                        className={classNames({
                          fieldError: form.touched['email-field'] && form.errors['email-field'],
                          fieldDisabled: invoiceAddresseeSave === ENetworkRequestStatus.PENDING,
                        })}
                        inputClassName="bg-ivory border-gray-40"
                      />
                      {/* @ts-ignore */}
                      <ErrorMessage name="email" className="errorMessage" component="div" />
                    </FormLabel>
                  </div>
                  <div className="vat-and-iata flex justify-start">
                    <FormLabel className="vat" text="VAT" style={{ width: '290px' }} isOptional>
                      <Field
                        as={TextInput}
                        name="vat"
                        disabled={invoiceAddresseeSave === ENetworkRequestStatus.PENDING}
                        className={classNames({
                          fieldError: form.touched.vat && form.errors.vat,
                          fieldDisabled: invoiceAddresseeSave === ENetworkRequestStatus.PENDING,
                        })}
                        inputClassName="bg-ivory border-gray-40"
                      />
                      {/* @ts-ignore */}
                      <ErrorMessage name="vat" className="errorMessage" component="div" />
                    </FormLabel>

                    <FormLabel className="iata ml-5" text="IATA" style={{ width: '290px' }} isOptional>
                      <Field
                        as={TextInput}
                        name="iata"
                        disabled={invoiceAddresseeSave === ENetworkRequestStatus.PENDING}
                        className={classNames({
                          fieldError: form.touched.iata && form.errors.iata,
                          fieldDisabled: invoiceAddresseeSave === ENetworkRequestStatus.PENDING,
                        })}
                        inputClassName="bg-ivory border-gray-40"
                      />
                      {/* @ts-ignore */}
                      <ErrorMessage name="iata" className="errorMessage" component="div" />
                    </FormLabel>
                  </div>
                  <div className="edit-addressee-buttons flex mt-30px">
                    <FluidButton type="secondary" onClick={handleCloseModal}>
                      Cancel
                    </FluidButton>
                    <FluidButton
                      type="primary"
                      className="ml-10px"
                      submit
                      isLoading={invoiceAddresseeSave === ENetworkRequestStatus.PENDING}
                      disabled={form.isValid === false}
                      onClick={form.submitForm}
                    >
                      Save
                    </FluidButton>
                  </div>
                </form>
              );
            }}
          </Formik>
        </AddresseeModalStyles>
      </StandardModal>
    );
  }
);

export default AddresseeModal;
