import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BookingBuilder, ENetworkRequestStatus, ICompany } from "services/BackendApi";
import { getCompanySelector } from "store/modules/auth";
import { companyDataSelector, companyLoadSelector, fetchCompanyDataRequestAction } from "store/modules/companyInfo";
import { ledgerDepositStatementTotalsSelector, ledgerDepositTotalsLoadingSelector } from "store/modules/ledger/selectors";

export const useDepositAndCreditLimitInfo = (booking: BookingBuilder) => {
    const company = useSelector(getCompanySelector) as ICompany;
    const companyData = useSelector(companyDataSelector) as ICompany;
    const depositTotals = useSelector(ledgerDepositStatementTotalsSelector);
    const depositTotalsLoading = useSelector(ledgerDepositTotalsLoadingSelector);
    const depositAccountItem = depositTotals.find(item => item.currency === booking.response.currency);
    const depositAccountBalanceCents = depositAccountItem ? -depositAccountItem.netTotal : 0;
    const cannotGetDepositAccountBalance = depositTotalsLoading === ENetworkRequestStatus.ERROR;

    const dispatch = useDispatch();
    useLayoutEffect(() => {
        dispatch(fetchCompanyDataRequestAction(company.uuid));
    }, [dispatch]);

    const creditLimitMapping = {
      'USD': companyData?.usdCreditLimit,
      'EUR': companyData?.eurCreditLimit,
    };
    const creditLimit = creditLimitMapping[booking.response.currency];
    const isDefinedCreditLimit = !!creditLimitMapping[booking.response.currency];
    const creditLimitCents = typeof creditLimit === 'string' ? parseFloat(creditLimit) * 100 : 0;
    const isEnoughDepositAndCredit = !booking.response.totals.oneOrMoreItemsOnRequest && booking.response.totals.totalForPricedItemsCents <= depositAccountBalanceCents + creditLimitCents;

    return { depositAccountBalanceCents, cannotGetDepositAccountBalance, creditLimitCents, isDefinedCreditLimit, isEnoughDepositAndCredit };
}
