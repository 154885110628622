import React, { useCallback } from 'react';
import CopyIcon from 'ui/Icons/copy.component.svg';
import { enqueueNotification } from 'store/modules/ui';
import { AppliedSuplement, BookingBuilder, Fine, GroundService, IHotel, ProductSetAccommodation, Transfer } from 'services/BackendApi';
import { useDispatch, useSelector } from 'react-redux';
import { addDays } from 'date-fns';
import format from 'date-fns/format';
import { formatDateDisplay, formatPrice, formatPriceCents, getCurrencySymbol, numberOfNights, offsetDate } from 'utils';
import * as HotelAccommodationProductSelectors from 'store/modules/hotelAccommodationProducts/selectors';
import { pluralize } from 'utils/string';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import { composeCancellationPolicyFromRoomExpenseInfo, extractCancellationPolicies } from 'common-lib/cancellation-policy-composer';
import { booking } from 'api/schema';
import { IHAPFormattedAccommodationProduct } from 'store/modules/hotelAccommodationProducts/types';

const getMealPlan = (accommodation: ProductSetAccommodation) => {
  return accommodation.availableSubProductSets['Meal Plan'].find(item => item.selected);
}

const getHotelAndRegion = (hotel: IHotel) => {
  const lines: (string | null)[] = [hotel.name];
  if (hotel.region) {
    const hotelRegion = hotel.region ? '(' + hotel.region?.toUpperCase() + ')' : null;
    lines.push(hotelRegion);
  }
  return lines.filter(Boolean).join(' ');
};

const getDates = (checkInDate: string, checkOutDate: string) => {
  return `${ checkInDate ? formatDateDisplay(checkInDate) : '' } - ${ checkOutDate ? formatDateDisplay(checkOutDate) : ''
  } (${numberOfNights(checkInDate, checkOutDate)} ${pluralize(numberOfNights(checkInDate, checkOutDate), 'night')})`;
}

const getRateType = (booking: BookingBuilder) => {
  return booking.response.potentialBooking.Accommodation[0].isLiveRate ? 'LIVE RATE' : 'STATIC RATE';
}

const getTotal = (currencySymbol: string, product: any) => {
  if (product?.isOnRequestOrPartiallyOnRequest) {
    return 'On Request';
  }
  return product?.total ? `${currencySymbol} ${formatPrice(product.total)}` : '';
}

const getCancellationPolicies = (currencySymbol: string, booking: BookingBuilder) => {
  const cancellationPolicyLines: string[] = [];
  booking.response.expenseInfosGroupedByRoom.forEach((item, index) => {
    const accommodation = [
      `- Accommodation ${index + 1}`,
      composeCancellationPolicyFromRoomExpenseInfo(item, {
        currencySymbol: currencySymbol,
        appendLines: [' (at 00.00 time at destination)']
      }).join('. '),
    ].join(': ');
    cancellationPolicyLines.push(accommodation);
  });

  extractCancellationPolicies(booking.response.potentialBooking.Fine).forEach(cancellationPolicy => {
    cancellationPolicyLines.push('- ' + cancellationPolicy);
  });
  extractCancellationPolicies(booking.response.potentialBooking['Ground Service']).forEach(cancellationPolicy => {
    cancellationPolicyLines.push('- ' + cancellationPolicy);
  });
  extractCancellationPolicies(booking.response.potentialBooking.Supplement).forEach(cancellationPolicy => {
    cancellationPolicyLines.push('- ' + cancellationPolicy);
  });
  extractCancellationPolicies(booking.response.potentialBooking.Transfer).forEach(cancellationPolicy => {
    cancellationPolicyLines.push('- ' + cancellationPolicy.replace(/\n/g, ' '));
  });
  
  return cancellationPolicyLines;
}

const getCost = (booking: BookingBuilder, currencySymbol: string) => {
  if (booking.response.totals.oneOrMoreItemsOnRequest) {
    return `On Request ${getRateType(booking)}`;
  }
  return `*${currencySymbol} ${formatPriceCents(booking.response.totals.totalForPricedItemsCents)}* (Before ${currencySymbol} ${formatPriceCents(booking.response.totals.totalBeforeDiscountForPricedItemsCents)}) ${getRateType(booking)}`;
}

const generateOtherItems = (currencySymbol: string, otherItems: Transfer[] | AppliedSuplement[] | GroundService[] | Fine[]) => {
  return otherItems.filter(item => item.selected)
    .map(transfer => `- ${transfer.products[0].name || ''} - ${getTotal(currencySymbol, transfer)}`)
    .join('\n');
}

const getRefundableText = (isRefundable: boolean | null | undefined) => {
  if (isRefundable === true) {
    return '- Refundable';
  }
  if (isRefundable === false) {
    return '- Not Refundable';
  }
  return '';
}

const getBookingInfo = (booking: BookingBuilder) => {
  const numberOfRooms = booking.response.availableProductSets.Accommodation?.length ?? 0;
  const numberOfGuests = booking.request.guestAges.numberOfAdults + (booking.request.guestAges.agesOfAllChildren?.length ?? 0);
  const numberOfTransfers = booking.response.availableProductSets.Transfer?.filter(item => item.selected).length ?? 0; 
  const numberOfGroundServices = booking.response.availableProductSets['Ground Service']?.filter(item => item.selected).length ?? 0;
  const numberOfOtherItems = (booking.response.availableProductSets.Supplement?.filter(item => item.selected).length ?? 0) + (booking.response.availableProductSets.Fine?.filter(item => item.selected).length ?? 0);

  const quantity = [
    { quantity: numberOfGuests, text: `${numberOfGuests} ${pluralize(numberOfGuests, 'Guest')}` },
    { quantity: numberOfRooms, text: `${numberOfRooms} ${pluralize(numberOfRooms, 'Room')}` },
    { quantity: numberOfTransfers, text: `${numberOfTransfers} ${pluralize(numberOfTransfers, 'Transfer')}` },
    { quantity: numberOfGroundServices, text: `${numberOfGroundServices} ${pluralize(numberOfGroundServices, 'Ground Service')}` },
    { quantity: numberOfOtherItems, text: `${numberOfOtherItems} ${pluralize(numberOfOtherItems, 'Other Item')}` },
  ];
  return quantity.filter(item => item.quantity > 0).map(item => item.text).join(' | ');
}

const getBookingConfirmationClipboardFormat = (booking: BookingBuilder, paymentTerms: string[], formattedAccommodationProducts: IHAPFormattedAccommodationProduct[]) => {
  const hotel = booking.response.hotel;
  const currencySymbol = getCurrencySymbol(booking.response.currency);
  const checkInDate = booking.request.startDate;
  const checkOutDate = format(addDays(offsetDate(booking.request.endDate), 1), 'yyyy-MM-dd');

  return `*${getHotelAndRegion(hotel)}*
${getDates(checkInDate, checkOutDate)} ${getBookingInfo(booking)}
Total cost: ${getCost(booking, currencySymbol)}

${booking.response.availableProductSets.Accommodation
.map(
  (accommodation, accommodationIndex) => {
    const requestedAccommodation = booking.request.Accommodation[accommodationIndex];
    const isRefundable = booking.response.expenseInfosGroupedByRoom[accommodationIndex].isRefundable;
    const mealPlan = getMealPlan(accommodation);
    return `- 1 x ${accommodation.products[0].name || ''} - ${getDates(requestedAccommodation.startDate, format(addDays(offsetDate(requestedAccommodation.endDate), 1), 'yyyy-MM-dd'))} - ${getTotal(currencySymbol, accommodation)}
${mealPlan?.products[0]?.name || ''} - ${getTotal(currencySymbol, mealPlan)} ${getRefundableText(isRefundable)}`
})
.join('\n')}
${[
  generateOtherItems(currencySymbol, booking.response.availableProductSets.Transfer),
  generateOtherItems(currencySymbol, booking.response.availableProductSets['Ground Service']),
  generateOtherItems(currencySymbol, booking.response.availableProductSets.Supplement),
  generateOtherItems(currencySymbol, booking.response.availableProductSets.Fine),
].filter(Boolean).join('\n')}

${paymentTerms.length > 0 ? ['*Payment Terms*', ...paymentTerms.map(item => '- ' + item)].join('\n') : ''}

*Cancellation Policy*
${getCancellationPolicies(currencySymbol, booking).join('\n')}
`;

};

interface ICopyButtonProps {
  booking: BookingBuilder;
}

export const CopyButton: React.FC<ICopyButtonProps> = React.memo(({ booking }) => {
  const dispatch = useDispatch();
  const paymentTerms = useSelector(BookingBuilderSelectors.bookingPaymentTermsSelector);
  const formattedAccommodationProducts: IHAPFormattedAccommodationProduct[] = useSelector(
    HotelAccommodationProductSelectors.getHotelAccommodationProductsSelector
  );
  const handleCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(getBookingConfirmationClipboardFormat(booking, paymentTerms, formattedAccommodationProducts));
      dispatch(
        enqueueNotification({
          message: `Copied booking information to clipboard`,
          options: { variant: 'success' },
        })
      );
    } catch (e) {
      dispatch(
        enqueueNotification({
          message: `Failed to copy booking information to clipboard`,
          options: { variant: 'error' },
        })
      );
    }
  }, [booking, dispatch, paymentTerms]);

  return (
    <button
        className="text-brown-prime h-20px cursor-pointer bg-white p-0 active:bg-gray-20 rounded outline-none"
        onClick={handleCopy}
      >
        <CopyIcon className="w-[20px] fill-brown-prime" />
    </button>
  );
});