import { prop, pipe, path } from 'ramda';
import { createSelector } from 'reselect';
import { IHotelAccommodationProductsDomain } from './types';

export const getCurrentHotelAccommodationProductsError = pipe(prop('hotelAccommodationProducts'), path(['error']));

export const getCurrentHotelAccommodationLiveRates = pipe(prop('hotelAccommodationProducts'), path(['liveRatesData']));

export const getCurrentHotelAccommodationLiveRatesCurrency = pipe(
  prop('hotelAccommodationProducts'),
  path(['liveRatesCurrency'])
);
export const getCurrentHotelAccommodationLiveRatesError = pipe(
  prop('hotelAccommodationProducts'),
  path(['liveRatesError'])
);

export const hotelAccommodationProductsDomainSelector = (state: any): IHotelAccommodationProductsDomain =>
  state.hotelAccommodationProducts;

export const getHotelAccommodationProductsSelector = createSelector(
  hotelAccommodationProductsDomainSelector,
  domain => domain.formattedAccommodationProducts
);

export const loadStatusSelector = createSelector(hotelAccommodationProductsDomainSelector, domain => domain.loadStatus);

export const bookingComRatesDataSelector = createSelector(
  hotelAccommodationProductsDomainSelector,
  domain => domain.bookingComRatesData
);

export const isLiveRateLoadingErrorSelector = createSelector(
  hotelAccommodationProductsDomainSelector,
  domain => domain.isLiveRateLoadingError
);
