import { IGuest } from 'services/BackendApi';

export const guestTitles = ['No title ', 'Brother', 'Dr', 'Father', 'Master', 'Miss', 'Mme', 'Mr', 'Mrs', 'Ms', 'Prof', 'Prof Dr', 'Rev', 'Hrh'];
export const guestTypes = ['Mother', 'Father', 'Child', 'Friend', 'Security', 'Nanny', 'Chef', 'Other Staff'];

export const guestToString = (guest: IGuest) => {
  // concatonate the title, first name, and last name with spaces inbetween,
  // ignoring any empty strings or undefineds along the way
  return [guest.title, guest.firstName, guest.lastName]
    .filter(x => x !== '')
    .filter(x => x != undefined)
    .join(' ');
};

export const renderFullName = (title: string | undefined, firstName: string | undefined, lastName: string | undefined) => {
  return [title, firstName, lastName]
    .filter(Boolean)
    .join(' ')
    .trim();
}
