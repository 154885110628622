import React, { FormEvent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OfferEditPackagesStyles } from './OfferEditPackagesStyles';
import { ActionButton, CloseButton } from 'pureUi/Buttons';
import { Text, Heading } from 'pureUi/typography';
import RadioButton from 'pureUi/RadioButton';
import { Fieldset, Legend } from '../../pureUi/forms/Fieldset/index';

import {
  offerHasPackageDiscountErrorsSelector,
  packageDiscountsSelectorWithAgeNameAndInterpolatedDates,
  accommodationProductsForHotelSelector,
  offerHotelUuidSelector,
  offerPackageDiscountsValidationSelector,
  offerIsPristineSelector,
  packageDiscountDuplicateProductsSelector,
  packagesHaveExtraPersonLimitsSelector,
  packageDiscountStackingSelector,
} from 'store/modules/offer/selectors';

import { offerSetPackageDiscountStacking } from 'store/modules/offer/subdomains/offer/actions';

import {
  offerAddPackageAction,
  offerRemovePackageAction,
  offerSetDateRangeAction,
  offerAddAccommodationProductAction,
  offerSetAccommodationProductAction,
  offerRemoveAccommodationProductAction,
  offerSetPackageNights,
  offerSetPackageRate,
  offerSetPackageExtraNightRate,
  offerAddPackageExtraPersonRate,
  offerRemovePackageExtraPersonRate,
  offerSetPackageExtraPersonRate,
  offerSetPackageExtraPersonRateAgeName,
  offerAddAccommodationProductPackage,
  offerRemoveAccommodationProductPackage,
  offerSetPackageExtraPersonMaxAction,
} from 'store/modules/offer/subdomains/offer/actions.packages';

import { PureSelect } from 'pureUi/forms/PureSelect';
import {
  IPackageDiscountUI,
  IAccommodationProductPackageUI,
  IExtraPersonNightRateUI,
} from 'services/BackendApi/types/PackageOffer';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import DateRangeInput from 'pureUi/DateRangeInput';
import Label from 'pureUi/Label';
import TextInput from 'pureUi/TextInput';
import { IAgeName, EOfferUiPackageDiscountStacking } from 'services/BackendApi';
import { ErrorList } from 'pureUi/ErrorList';
import { WarningBanner, InfoBanner } from 'pureUi/Banners';

export class OfferEditPackagesContainer extends React.Component<IOfferEditPackagesProps, {}> {
  handlePackageDateChange = (uuid: string) => (dates: string[]) => {
    const datesWithoutTime = dates.map(date => date.split('T')[0]);
    const startDate = datesWithoutTime[0];
    const endDate = datesWithoutTime[datesWithoutTime.length - 1];
    this.props.offerSetDateRangeAction(uuid, { startDate, endDate });
  };

  handleAccomodationProductChange = (packageUuid, productIndex) => (e: FormEvent<HTMLSelectElement>) => {
    this.props.offerSetAccommodationProductAction(packageUuid, productIndex, e.currentTarget.value);
  };

  handleRemovePackageDiscount = (uuid: string) => () => {
    this.props.offerRemovePackageAction(uuid);
  };

  handleRemoveAccommodationPackage = (uuid: string, index: number) => () => {
    this.props.offerRemoveAccommodationProductAction(uuid, index);
  };

  handleNightsChange = (packageUuid: string, accomondatinPackageIndex: number, ppIdx: number) => (
    e: FormEvent<HTMLInputElement>
  ) => {
    this.props.offerSetPackageNights(packageUuid, accomondatinPackageIndex, ppIdx, e.currentTarget.value);
  };

  handleRateChange = (packageUuid: string, accomondatinPackageIndex: number, ppIdx: number) => (
    e: FormEvent<HTMLInputElement>
  ) => {
    this.props.offerSetPackageRate(packageUuid, accomondatinPackageIndex, ppIdx, e.currentTarget.value);
  };

  handleAddAccommodationProductPackageRate = (packageUuid: string, accommodationPackageIndex: number) => () => {
    this.props.offerAddAccommodationProductPackage(packageUuid, accommodationPackageIndex);
  };

  handleAddExtraPersonNightRate = (packageUuid: string, accommodationPackageIndex: number) => () => {
    this.props.offerAddPackageExtraPersonRate(packageUuid, accommodationPackageIndex);
  };

  handleRemoveAccommodationProductPackageRate = (
    packageUuid: string,
    accommodationPackageIndex: number,
    accommodationProductPackageIndex: number
  ) => () => {
    this.props.offerRemoveAccommodationProductPackage(
      packageUuid,
      accommodationPackageIndex,
      accommodationProductPackageIndex
    );
  };

  handleRemoveExtraPersonNightRate = (
    packageUuid: string,
    accommodationPackageIndex: number,
    epnrIndex: number
  ) => () => {
    this.props.offerRemovePackageExtraPersonRate(packageUuid, accommodationPackageIndex, epnrIndex);
  };

  handleExtraPersonAgeNameChange = (packageUuid: string, accommodationPackageIndex: number, epnrIdx: number) => (
    e: FormEvent<HTMLSelectElement>
  ) => {
    this.props.offerSetPackageExtraPersonRateAgeName(
      packageUuid,
      accommodationPackageIndex,
      epnrIdx,
      e.currentTarget.value
    );
  };

  handleExtraPersonRateChange = (packageUuid: string, accommodationPackageIndex: number, epnrIdx: number) => (
    e: FormEvent<HTMLInputElement>
  ) => {
    this.props.offerSetPackageExtraPersonRate(packageUuid, accommodationPackageIndex, epnrIdx, e.currentTarget.value);
  };

  handleExtraNightRateChange = (packageUuid: string, accommodationPackageIndex: number) => (
    e: FormEvent<HTMLInputElement>
  ) => {
    this.props.offerSetPackageExtraNightRate(packageUuid, accommodationPackageIndex, e.currentTarget.value);
  };

  handleExtraPersonMaxChange = (packageUuid: string, accommodationPackageIndex: number, epnrIdx: number) => (
    e: FormEvent<HTMLInputElement>
  ) => {
    this.props.offerSetPackageExtraPersonMaxAction(
      packageUuid,
      accommodationPackageIndex,
      epnrIdx,
      e.currentTarget.value
    );
  };

  renderAccommodationPackages = (packageDiscount: IPackageDiscountUI) => {
    const addButton = (
      <ActionButton
        action="add"
        className="addApButton"
        onClick={() => this.props.offerAddAccommodationProductAction(packageDiscount.uuid)}
      >
        Add Accomodation Product
      </ActionButton>
    );

    return (
      <div className="accommodationPackages" key={packageDiscount.uuid}>
        {packageDiscount.accommodationPackages.map((ap, apIdx) => (
          <div className="accommodationPackage" key={ap.uuid}>
            <PureSelect
              className="accSelect"
              value={ap.accommodationProductUuid || ''}
              onChange={this.handleAccomodationProductChange(packageDiscount.uuid, apIdx)}
            >
              <option value={''} disabled>
                Select an accommodation product
              </option>
              {this.props.accomodationProducts.map(ap => (
                <option key={ap.uuid} value={ap.uuid}>
                  {ap.name}
                </option>
              ))}
            </PureSelect>
            <div className="accommodationProductPackages">
              <ul className="accommodationProductPackagesList">
                {this.renderAccommodationProductPackages(packageDiscount.uuid, apIdx, ap.accommodationProductPackages)}
              </ul>
              <span className="addAccommodationProductPackageButton">
                <ActionButton
                  action="add"
                  onClick={this.handleAddAccommodationProductPackageRate(packageDiscount.uuid, apIdx)}
                >
                  Add Rate
                </ActionButton>
              </span>
              <Label className="extraNightRate" text="Extra Night Rate">
                <TextInput
                  value={ap.extraNightRate || ''}
                  onChange={this.handleExtraNightRateChange(packageDiscount.uuid, apIdx)}
                />
              </Label>
            </div>
            <div className="extraPersonNightRates">
              {ap.extraPersonNightRates && ap.extraPersonNightRates.length > 0 && (
                <Heading className="epnrHeader" level="h3">
                  Extra Person Night Rates
                </Heading>
              )}
              <ul className="epnrList">
                {this.renderExtraPersonRates(
                  packageDiscount.uuid,
                  apIdx,
                  ap.availableAgeNames,
                  ap.extraPersonNightRates || []
                )}
              </ul>
              <span className="addEpnrButton">
                <ActionButton action="add" onClick={this.handleAddExtraPersonNightRate(packageDiscount.uuid, apIdx)}>
                  Add Extra Person Night Rate
                </ActionButton>
              </span>
            </div>
            <span className="removeAccButton">
              <CloseButton onClick={this.handleRemoveAccommodationPackage(packageDiscount.uuid, apIdx)} />
            </span>
          </div>
        ))}

        <ActionButton
          action="add"
          className="addApButton"
          onClick={() => this.props.offerAddAccommodationProductAction(packageDiscount.uuid)}
        >
          Add Accomodation Product
        </ActionButton>
      </div>
    );
  };

  renderAccommodationProductPackages = (
    packageUuid: string,
    accommodationPackageIndex: number,
    productPackages?: IAccommodationProductPackageUI[]
  ) => {
    return productPackages!.map((app, appIdx) => {
      if (appIdx === 0) {
        return (
          <li className="accommodationProductPackage" key={`app-${app.uuid}`}>
            <Label text="Nights">
              <TextInput
                value={app.nights}
                onChange={this.handleNightsChange(packageUuid, accommodationPackageIndex, appIdx)}
              />
            </Label>
            <Label text="Rate">
              <TextInput
                value={app.packageRate}
                onChange={this.handleRateChange(packageUuid, accommodationPackageIndex, appIdx)}
              />
            </Label>
            <span className="removeAppButton">
              {appIdx > 0 && (
                <CloseButton
                  onClick={this.handleRemoveAccommodationProductPackageRate(
                    packageUuid,
                    accommodationPackageIndex,
                    appIdx
                  )}
                />
              )}
            </span>
          </li>
        );
      }

      return (
        <li className="accommodationProductPackage" key={`app-${app.uuid}`}>
          <span>
            <TextInput
              value={app.nights}
              onChange={this.handleNightsChange(packageUuid, accommodationPackageIndex, appIdx)}
            />
          </span>
          <span>
            <TextInput
              value={app.packageRate}
              onChange={this.handleRateChange(packageUuid, accommodationPackageIndex, appIdx)}
            />
          </span>
          <span className="removeAppButton">
            {appIdx > 0 && (
              <CloseButton
                onClick={this.handleRemoveAccommodationProductPackageRate(
                  packageUuid,
                  accommodationPackageIndex,
                  appIdx
                )}
              />
            )}
          </span>
        </li>
      );
    });
  };

  renderExtraPersonRates = (
    packageUuid: string,
    accommodationPackageIndex: number,
    ageNames: IAgeName[],
    extraPersonNightRates: IExtraPersonNightRateUI[]
  ) => {
    return extraPersonNightRates.map((epnr, epnrIdx) => {
      if (epnrIdx === 0) {
        return (
          <li className="extraPersonNightRate" key={`epnr-${epnr.uuid}`}>
            <Label text="Age Name">
              <PureSelect
                className="ageNameSelect"
                value={epnr.ageName || ''}
                onChange={this.handleExtraPersonAgeNameChange(packageUuid, accommodationPackageIndex, epnrIdx)}
              >
                <option value="" disabled>
                  Select Age name
                </option>
                {ageNames.map(an => (
                  <option key={an.name} value={an.name}>
                    {an.name}
                  </option>
                ))}
              </PureSelect>
            </Label>
            <Label text="Extra Person/Night Rate">
              <TextInput
                className="enprRate"
                value={epnr.extraPersonRate}
                onChange={this.handleExtraPersonRateChange(packageUuid, accommodationPackageIndex, epnrIdx)}
              />
            </Label>

            <Label text={`Max Persons`}>
              <TextInput
                className="epnrMaxPersons"
                value={epnr.maximumBeforeDefaultRate || ''}
                onChange={this.handleExtraPersonMaxChange(packageUuid, accommodationPackageIndex, epnrIdx)}
                disabled={epnr.ageName === 'Adult'}
              />
            </Label>

            <span className="removeEpnrButton">
              <CloseButton
                onClick={this.handleRemoveExtraPersonNightRate(packageUuid, accommodationPackageIndex, epnrIdx)}
              />
            </span>
          </li>
        );
      }
      return (
        <li className="extraPersonNightRate" key={`epnr-${epnr.uuid}`}>
          <PureSelect
            className="ageNameSelect"
            value={epnr.ageName || ''}
            onChange={this.handleExtraPersonAgeNameChange(packageUuid, accommodationPackageIndex, epnrIdx)}
          >
            <option value="" disabled>
              Select Age name
            </option>
            {ageNames.map(an => (
              <option key={an.name} value={an.name}>
                {an.name}
              </option>
            ))}
          </PureSelect>
          <TextInput
            className="enprRate"
            value={epnr.extraPersonRate}
            onChange={this.handleExtraPersonRateChange(packageUuid, accommodationPackageIndex, epnrIdx)}
          />
          <TextInput
            className="epnrMaxPersons"
            value={epnr.maximumBeforeDefaultRate || ''}
            onChange={this.handleExtraPersonMaxChange(packageUuid, accommodationPackageIndex, epnrIdx)}
            disabled={epnr.ageName === 'Adult'}
          />

          <span className="removeEpnrButton">
            <CloseButton
              onClick={this.handleRemoveExtraPersonNightRate(packageUuid, accommodationPackageIndex, epnrIdx)}
            />
          </span>
        </li>
      );
    });
  };

  render() {
    if (!this.props.hotelUuid) {
      return <Text className="noHotel">Select a hotel to add package discounts to this offer</Text>;
    }

    return (
      <OfferEditPackagesStyles>
        <Fieldset>
          <Legend>Package Discount Stacking</Legend>
          {this.props.packageDiscounts.length <= 0 && (
            <div>
              <Text>In order to set the Package Discount Stacking, add 1 or more Package Discounts</Text>
            </div>
          )}

          {this.props.packageDiscounts.length >= 1 && (
            <div>
              <span className="block mb-4 pb-4">
                <RadioButton
                  name="packageDiscountStacking-none"
                  value={EOfferUiPackageDiscountStacking.NONE}
                  data-role="radioTrue"
                  checked={this.props.packageDiscountStacking === EOfferUiPackageDiscountStacking.NONE}
                  onChange={() => {
                    this.props.offerSetPackageDiscountStacking(EOfferUiPackageDiscountStacking.NONE);
                  }}
                />{' '}
                <label htmlFor="packageDiscountStacking-none">
                  <Text className="inline uppercase">NONE</Text>
                </label>
                <Text>Use largest available then extra nights.</Text>
              </span>
              <span className="block mb-4 pb-4">
                <RadioButton
                  name="packageDiscountStacking-highest-to-lowest"
                  value={EOfferUiPackageDiscountStacking.HIGHEST_TO_LOWEST}
                  data-role="radioTrue"
                  checked={this.props.packageDiscountStacking === EOfferUiPackageDiscountStacking.HIGHEST_TO_LOWEST}
                  onChange={() => {
                    this.props.offerSetPackageDiscountStacking(EOfferUiPackageDiscountStacking.HIGHEST_TO_LOWEST);
                  }}
                />{' '}
                <Text className="inline uppercase">HIGHEST TO LOWEST</Text>
                <Text>Stack largest to smallest then extra nights.</Text>
              </span>
              <span className="block">
                <RadioButton
                  name="packageDiscountStacking-best-fit"
                  value={EOfferUiPackageDiscountStacking.BEST_FIT}
                  data-role="radioTrue"
                  checked={this.props.packageDiscountStacking === EOfferUiPackageDiscountStacking.BEST_FIT}
                  onChange={() => {
                    this.props.offerSetPackageDiscountStacking(EOfferUiPackageDiscountStacking.BEST_FIT);
                  }}
                />{' '}
                <Text className="inline uppercase">BEST FIT</Text>
                <Text>Stack best fit then extra nights.</Text>
              </span>
            </div>
          )}
        </Fieldset>

        {this.props.packageDiscounts?.map((packageDiscount, idx) => {
          return (
            <div className="packageDiscount" key={`package-${packageDiscount.uuid}`}>
              <div className="dateSelect">
                <DatePickerStateProvider
                  defaultSelectedDates={packageDiscount.interpolatedDates}
                  onDateChange={this.handlePackageDateChange(packageDiscount.uuid)}
                  render={(params: IDatePickerSateParams) => {
                    return (
                      <DateRangeInput
                        totalNights={params.selectedDates.length}
                        displayString={params.displayString}
                        currentDate={params.datePickerCurrentDate}
                        selectedDates={params.selectedDates}
                        onDayClick={params.handleDayClick}
                        onDayMouseOver={params.handleDateMouseOver}
                        showDatePicker={params.showDatePicker}
                        onNextClick={params.incrementDate}
                        onPrevClick={params.decrementDate}
                        onMouseDown={params.toggleDatePicker}
                        onClickOutside={params.hideDatePicker}
                        placeholder="Select Package Date Range"
                        enablePastDates
                      />
                    );
                  }}
                />
              </div>
              <span className="removePDButton">
                <CloseButton
                  className="removePDCloseButton"
                  onClick={this.handleRemovePackageDiscount(packageDiscount.uuid)}
                />
              </span>
              {this.renderAccommodationPackages(packageDiscount)}

              {this.props.packagesHaveExtraPersonLimits && (
                <InfoBanner className="epnrInfo">
                  Extra Person Night Rates: Guests that exceed the Max Persons for a given age name will be charged the
                  Adult Extra Person Night Rate.
                </InfoBanner>
              )}

              <div className="errorlist">
                {this.props.duplicateProducts[idx]?.length > 0 && (
                  <WarningBanner>
                    {this.props.duplicateProducts[idx].map(dupe => (
                      <p key={dupe}>{dupe}</p>
                    ))}
                  </WarningBanner>
                )}
                <ErrorList>
                  {!this.props.isPristine &&
                    this.props.validations.errors
                      .filter(error => error.index === idx)
                      .map((error, i) => <li key={i}>{error.message}</li>)}
                </ErrorList>
              </div>
            </div>
          );
        })}

        <div>
          <ActionButton className="addPd" action="add" onClick={this.props.offerAddPackageAction}>
            Add New Block Of Dates
          </ActionButton>
          {!this.props.packageDiscounts && <Text>Adding a package discount will disable...</Text>}
        </div>

        {this.props.packageDiscounts.length === 0 && (
          <Text>
            Note: Adding a package discount will clear any minimum stay lengths, accomodation prerequisites, stay
            between dates, accomodation product discounts, and stepping that may have been defined elsewhere.
          </Text>
        )}
      </OfferEditPackagesStyles>
    );
  }
}

// -----------------------------------------------------------------------------
// Prop Typings
// -----------------------------------------------------------------------------
export type StateToProps = ReturnType<typeof mapStateToProps>;
export type DispatchToProps = typeof actionCreators;

export interface IOfferEditPackagesProps extends StateToProps, DispatchToProps {}

const mapStateToProps = createStructuredSelector({
  hotelUuid: offerHotelUuidSelector,
  hasValidationErros: offerHasPackageDiscountErrorsSelector,
  packageDiscounts: packageDiscountsSelectorWithAgeNameAndInterpolatedDates,
  accomodationProducts: accommodationProductsForHotelSelector,
  validations: offerPackageDiscountsValidationSelector,
  isPristine: offerIsPristineSelector,
  duplicateProducts: packageDiscountDuplicateProductsSelector,
  packagesHaveExtraPersonLimits: packagesHaveExtraPersonLimitsSelector,
  packageDiscountStacking: packageDiscountStackingSelector,
});

const actionCreators = {
  offerAddPackageAction,
  offerRemovePackageAction,
  offerSetDateRangeAction,
  offerAddAccommodationProductAction,
  offerSetAccommodationProductAction,
  offerRemoveAccommodationProductAction,
  offerSetPackageNights,
  offerSetPackageRate,
  offerSetPackageExtraNightRate,
  offerAddPackageExtraPersonRate,
  offerRemovePackageExtraPersonRate,
  offerSetPackageExtraPersonRate,
  offerSetPackageExtraPersonRateAgeName,
  offerAddAccommodationProductPackage,
  offerRemoveAccommodationProductPackage,
  offerSetPackageExtraPersonMaxAction,
  offerSetPackageDiscountStacking,
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

// -----------------------------------------------------------------------------
// Connected
// -----------------------------------------------------------------------------
const withConnect = connect<StateToProps, DispatchToProps, IOfferEditPackagesProps>(
  mapStateToProps,
  mapDispatchToProps
);

// @ts-ignore
export const OfferEditPackagesContainerConnected = withConnect(OfferEditPackagesContainer);
