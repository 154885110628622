import { __, propSatisfies, defaultTo, pipe, includes, props, prop, equals } from 'ramda';

import { AuthTypes } from 'config/enums';
import { createSelector } from 'store/utils';
import { EUserType } from 'services/BackendApi';

// List of roles that are SR or above
const qualifiesAsSr = [EUserType.SR, EUserType.ADMIN, EUserType.RL];

/**
 * SR Check
 *
 * Checks a user role if it is SR+
 *
 * @param {string}
 * @returns {boolean}
 */
const srCheck = pipe(defaultTo({}), propSatisfies(includes(__, props(qualifiesAsSr, AuthTypes)), 'type'));

/**
 * Get auth selector
 *
 * @param {object}
 * @returns {*}
 */
export const getAuth = prop('auth');

/**
 * Get auth status selector
 *
 * @param {object}
 * @returns {string}
 */
export const getAuthStatus = createSelector(getAuth, prop('status'));

/**
 * Get auth data selector
 *
 * @param {object}
 * @returns {*}
 */
export const getAuthData = createSelector(getAuth, prop('data'));

/**
 * Get auth error selector
 *
 * @param {object}
 * @returns {*}
 */
export const getAuthError = createSelector(getAuth, prop('error'));

/**
 * Get auth token selector
 *
 * @param {object}
 * @returns {string}
 */
export const getAuthToken = createSelector(getAuth, prop('token'));

export const getLoggedIn = createSelector(getAuth, prop('loggedIn'));

/**
 * Get current user selector
 *
 * @param {object}
 * @returns {object}
 */
export const getCurrentUser = createSelector(getAuthData, prop('user'));

/**
 * Get current user uuid selector
 *
 * @param {object}
 * @returns {string}
 */
export const getCurrentUserUuid = createSelector(getCurrentUser, prop('uuid'));

/**
 * Get current user type selector
 *
 * @param {object}
 * @returns {string}
 */
export const getCurrentUserType = createSelector(getCurrentUser, prop('type'));

/**
 * Is company booking manager  selector
 *
 * @param {object}
 * @returns {string}
 */
export const isCompanyBookingManager = createSelector(getCurrentUser, prop('companyBookingManager'));

/**
 * Is authenticated selector
 *
 * @param {object}
 * @returns {boolean}
 */
export const isAuthenticated = createSelector(getAuthToken, Boolean);

/**
 * LEGACY Is SR selector
 *
 * Returns if the current user is EFFECTIVELY an SR, e.g is an SR OR ADMIN OR RL
 *
 * EDIT: This has been renamed to isInternalUser to better represent that it returns true
 * for Srs, Admins and RLs
 *
 * @param {object}
 * @returns {boolean}
 */
export const isInternalUser = createSelector(
  state => ({ loggedInUserType: state.auth?.data?.user?.type }),
  args => qualifiesAsSr.includes(args.loggedInUserType)
);

export const isInternalUserOrActingAsInternalUser = createSelector(
  state => {
    return {
      loggedInUserType: state.auth?.data?.user?.type,
      actingOnBehalfOf: state.actingOnBehalfOf,
    };
  },
  args => {
    /**
     * args looks like
     * {
        "loggedInUserType": "admin",
        "actingOnBehalfOf": {
          "aoboUuid": null,
          "aoboRole": null,
          "aoboAssociatedTaUuid": null
        }
      }
     */
    if (args.actingOnBehalfOf.aoboUuid != null) {
      return qualifiesAsSr.includes(args.actingOnBehalfOf.aoboRole);
    }
    return qualifiesAsSr.includes(args.loggedInUserType);
  }
);

/**
 * Is SR selector
 *
 * Returns if the current user is an SR
 *
 * @param {object}
 * @returns {boolean}
 */
export const isSr = createSelector(
  state => ({ loggedInUserType: state.auth?.data?.user?.type }),
  args => args.loggedInUserType === EUserType.SR
);
export const isSrOrActingAsSr = createSelector(
  state => {
    return {
      loggedInUserType: state.auth?.data?.user?.type,
      actingOnBehalfOf: state.actingOnBehalfOf,
    };
  },
  args => {
    /**
     * args looks like
     * {
        "loggedInUserType": "admin",
        "actingOnBehalfOf": {
          "aoboUuid": null,
          "aoboRole": null,
          "aoboAssociatedTaUuid": null
        }
      }
     */
    if (args.actingOnBehalfOf.aoboUuid != null) {
      return args.actingOnBehalfOf.aoboRole === AuthTypes.SR;
    }
    return args.loggedInUserType === AuthTypes.Sr;
  }
);
/**
 * Is RL selector
 *
 * Returns if the current user is an RL
 *
 * @param {object}
 * @returns {boolean}
 */
export const isRL = createSelector(
  state => ({ loggedInUserType: state.auth?.data?.user?.type }),
  args => args.loggedInUserType === EUserType.RL
);
export const isRlOrActingAsRl = createSelector(
  state => {
    return {
      loggedInUserType: state.auth?.data?.user?.type,
      actingOnBehalfOf: state.actingOnBehalfOf,
    };
  },
  args => {
    /**
     * args looks like
     * {
        "loggedInUserType": "admin",
        "actingOnBehalfOf": {
          "aoboUuid": null,
          "aoboRole": null,
          "aoboAssociatedTaUuid": null
        }
      }
     */
    if (args.actingOnBehalfOf.aoboUuid != null) {
      return args.actingOnBehalfOf.aoboRole === AuthTypes.RL;
    }
    return args.loggedInUserType === AuthTypes.RL;
  }
);

/**
 * Is Admin selector
 *
 * Returns if the current user is an Admin
 *
 * @param {object}
 * @returns {boolean}
 */
export const isAdmin = createSelector(
  state => ({ loggedInUserType: state.auth?.data?.user?.type }),
  args => args.loggedInUserType === EUserType.ADMIN
);
export const isAdminOrActingAsAdmin = createSelector(
  state => {
    return {
      loggedInUserType: state.auth?.data?.user?.type,
      actingOnBehalfOf: state.actingOnBehalfOf.aoboUuid,
    };
  },
  args => {
    /**
     * args looks like
     * {
        "loggedInUserType": "admin",
        "actingOnBehalfOf": {
          "aoboUuid": null,
          "aoboRole": null,
          "aoboAssociatedTaUuid": null
        }
      }
     */
    if (args.actingOnBehalfOf.aoboUuid != null) {
      return args.actingOnBehalfOf.aoboRole === AuthTypes.ADMIN;
    }
    return args.loggedInUserType === AuthTypes.ADMIN;
  }
);

/**
 * Is TA selector
 *
 * Returns if the current user is an TA
 *
 * @param {object}
 * @returns {boolean}
 */
export const isTA = createSelector(
  state => ({ loggedInUserType: state.auth?.data?.user?.type }),
  args => args.loggedInUserType === EUserType.TA
);
export const isTAOrActingAsTa = createSelector(
  state => {
    return {
      loggedInUserType: state.auth?.data?.user?.type,
      actingOnBehalfOf: state.actingOnBehalfOf,
    };
  },
  args => {
    /**
     * args looks like
     * {
        "loggedInUserType": "admin",
        "actingOnBehalfOf": {
          "aoboUuid": null,
          "aoboRole": null,
          "aoboAssociatedTaUuid": null
        }
      }
     */
    if (args.actingOnBehalfOf.aoboUuid != null) {
      return args.actingOnBehalfOf.aoboRole === AuthTypes.TA;
    }
    return args.loggedInUserType === AuthTypes.TA;
  }
);

export const isFinanceUser = createSelector(getCurrentUserType, equals(AuthTypes.FINANCE));

export const getUpdatePasswordData = createSelector(getAuthData, prop('updatePassword'));

/**
 * Returns if the current user is a ta manager
 *
 * @param {object}
 * @returns {boolean}
 */
export const isCompanyTaManager = createSelector(getCurrentUser, prop('companyTaManager'));

/**
 * Get current company selector
 *
 * @param {object}
 * @returns {object}
 */
export const getCompanySelector = createSelector(getCurrentUser, prop('company'));

export const hideFinanceSelector = createSelector(
  getCurrentUser,
  user => user.type === AuthTypes.TA && !user.companyTaManager && user.company?.disableTaAccessToFinance
);

export const showHeaderBookingSearchSelector = createSelector(getCurrentUser, user =>
  [EUserType.SR, EUserType.ADMIN, EUserType.FINANCE].includes(user.type)
);
