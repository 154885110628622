import { IExternalIds } from 'services/BookingManagerApi';
import { ENetworkRequestStatus } from 'services/BackendApi';

export interface IEditing {
  travelPartnerRef: string;
}

export interface IExternalIdsSubdomain {
  stored: IExternalIds | null;
  editing: IEditing;
  request: {
    get: ENetworkRequestStatus;
    patch: ENetworkRequestStatus;
  };
}

export const initialState: IExternalIdsSubdomain = {
  stored: null,
  editing: { travelPartnerRef: '' },
  request: {
    get: ENetworkRequestStatus.IDLE,
    patch: ENetworkRequestStatus.IDLE,
  },
};
