import { call, takeLatest, put, all } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBackendApi } from 'services/BackendApi';
import * as Actions from './actions';

export function* fetchStaticRatesAvailabilitySaga(
  action: ReturnType<typeof Actions.fetchStaticRatesAvailabilityRequestAction>
) {
  const backendApi = makeBackendApi();
  try {
    const res: AxiosResponse = yield call(
      backendApi.getStaticRatesAvailabilityData,
      [action.accommodationUuid],
      action.startDate,
      action.endDate
    );
    yield put(Actions.fetchStaticRatesAvailabilitySuccessAction(action.accommodationUuid, res.data.data[0]));
  } catch (e) {
    yield put(Actions.fetchStaticRatesAvailabilityFailureAction(e.message));
  }
}

export function* fetchStaticRatesAvailabilityAggregateSaga(
  action: ReturnType<typeof Actions.fetchStaticRatesAvailabilityAggregateRequestAction>
) {
  const backendApi = makeBackendApi();
  try {
    const res: AxiosResponse = yield call(
      backendApi.getStaticRatesAvailabilityData,
      action.accommodationUuids,
      action.startDate,
      action.endDate
    );

    const mappedData = res.data.data.map((d, idx) => {
      return {
        accommodationUuid: action.accommodationUuids[idx],
        availableDates: d.availableDates,
        unavailableDates: d.unavailableDates,
        requestDates: d.requestDates,
      };
    });
    yield put(Actions.fetchStaticRatesAvailabilityAggregateSuccessAction(mappedData));
  } catch (e) {
    yield put(Actions.fetchStaticRatesAvailabilityAggregateFailureAction(e.message));
  }
}

export function* watchStaticRatesAvailabilitySaga() {
  yield all([
    takeLatest(Actions.FETCH_STATIC_RATES_AVAILABILITY_REQUEST, fetchStaticRatesAvailabilitySaga),
    takeLatest(Actions.FETCH_STATIC_RATES_AVAILABILITY_AGGREGATE_REQUEST, fetchStaticRatesAvailabilityAggregateSaga),
  ]);
}
