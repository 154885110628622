import { BookingBuilderResponse, ENetworkRequestStatus } from 'services/BackendApi';

export type IResponseSubdomain = {
  // eslint-disable-next-line no-undef
  response?: Omit<BookingBuilderResponse, 'hotel'>;
  networkRequests: {
    responseLoad: ENetworkRequestStatus;
  }
}

export const responseInitialState: IResponseSubdomain = {
  response: undefined,
  networkRequests: {
    responseLoad: ENetworkRequestStatus.IDLE,
  },
};
