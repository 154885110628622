import React from 'react';
import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const ArrowDownIcon: React.FC<IIconProps> = React.memo(({ className = '', icon = 'brown-100' }) => (
    <svg width="12" height="8" viewBox="0 0 12 8" fill={theme.colors[icon]} xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M1.40625 0.578125L6 5.17188L10.5938 0.578125L12 1.98438L6 7.98438L0 1.98438L1.40625 0.578125Z" fill={theme.colors[icon]}/>
    </svg>
));

export default ArrowDownIcon;
