import {
  ERateApplicationTypes,
  IGetRateBreakResponse,
  IHotelAccommodationProduct,
  IPostRateBreakResponse,
  IRateBreakListItem,
} from './types';

export const SET_RATE_BREAK_UUID = 'rateBreak/SET_RATE_BREAK_UUID';
export const SET_RATE_BREAK_NAME = 'rateBreak/SET_RATE_BREAK_NAME';
export const SET_RATE_BREAK_PERCENTAGE = 'rateBreak/SET_RATE_BREAK_PERCENTAGE';
export const SET_SELECTED_HOTEL_UUID = 'rateBreak/SET_SELECTED_HOTEL_UUID';
export const SET_SELECTED_RATE_APPLICATION = 'rateBreak/SET_SELECTED_RATE_APPLICATION';
export const SET_IS_PRE_DISCOUNT = 'rateBreak/SET_IS_PRE_DISCOUNT';
export const SET_DISCOUNT_EXTRA_PERSON_SUPPLEMENT = 'rateBreak/SET_DISCOUNT_EXTRA_PERSON_SUPPLEMENT';
export const SET_EXCLUDE_SERVICE_AND_VALUE_TAXES = 'rateBreak/SET_EXCLUDE_SERVICE_AND_VALUE_TAXES';
export const ADD_NEW_STAY_BETWEEN = 'rateBreak/ADD_NEW_STAY_BETWEEN';
export const DELETE_STAY_BETWEEN = 'rateBreak/DELETE_STAY_BETWEEN';
export const SET_STAY_BETWEEN_SELECTED_DATES = 'rateBreak/SET_STAY_BETWEEN_SELECTED_DATES';

export const TOGGLE_SELECTED_ACCOMMODATION_UUID = 'rateBreak/TOGGLE_SELECTED_ACCOMMODATION_UUID';
export const TOGGLE_SELECTED_TA_COUNTRY_CODE = 'rateBreak/TOGGLE_SELECTED_TA_COUNTRY_CODE';

export const CLEAR_AVAILABLE_ACCOMMODATIONS = 'rateBreak/CLEAR_AVAILABLE_ACCOMMODATIONS';
export const CLEAR_FORM = 'rateBreak/CLEAR_FORM';

export const RESET_SELECTED_ACCOMMODATION_UUIDS = 'rateBreak/RESET_SELECTED_ACCOMMODATION_UUIDS';
export const RESET_SELECTED_TA_COUNTRIES = 'rateBreak/RESET_SELECTED_TA_COUNTRIES';

export const GET_HOTEL_ACCOMMODATIONS_REQUEST = 'rateBreak/GET_HOTEL_ACCOMMODATIONS_REQUEST';
export const GET_HOTEL_ACCOMMODATIONS_SUCCESS = 'rateBreak/GET_HOTEL_ACCOMMODATIONS_SUCCESS';
export const GET_HOTEL_ACCOMMODATIONS_FAILURE = 'rateBreak/GET_HOTEL_ACCOMMODATIONS_FAILURE';

export const SAVE_RATE_BREAK_REQUEST = 'rateBreak/SAVE_RATE_BREAK_REQUEST';
export const SAVE_RATE_BREAK_SUCCESS = 'rateBreak/SAVE_RATE_BREAK_SUCCESS';
export const SAVE_RATE_BREAK_FAILURE = 'rateBreak/SAVE_RATE_BREAK_FAILURE';

export const GET_RATE_BREAK_REQUEST = 'rateBreak/GET_RATE_BREAK_REQUEST';
export const GET_RATE_BREAK_SUCCESS = 'rateBreak/GET_RATE_BREAK_SUCCESS';
export const GET_RATE_BREAK_FAILURE = 'rateBreak/GET_RATE_BREAK_FAILURE';

// listing page
export const GET_RATE_BREAK_LIST_REQUEST = 'rateBreak/GET_RATE_BREAK_LIST_REQUEST';
export const GET_RATE_BREAK_LIST_SUCCESS = 'rateBreak/GET_RATE_BREAK_LIST_SUCCESS';
export const GET_RATE_BREAK_LIST_FAILURE = 'rateBreak/GET_RATE_BREAK_LIST_FAILURE';

export const SET_UUIDS_FOR_DELETION = 'rateBreak/SET_UUIDS_FOR_DELETION';
export const SET_FILTER_HOTEL_UUIDS = 'rateBreak/SET_FILTER_HOTEL_UUIDS';
export const SET_CURRENT_PAGINATION_PAGE = 'rateBreak/SET_CURRENT_PAGINATION_PAGE';
export const SET_TOTAL_RESULTS_COUNT = 'rateBreak/SET_TOTAL_RESULTS_COUNT';
export const SET_ITEMS_PER_PAGE = 'rateBreak/SET_ITEMS_PER_PAGE';

export const DELETE_RATE_BREAK_LIST_REQUEST = 'rateBreak/DELETE_RATE_BREAK_LIST_REQUEST';
export const DELETE_RATE_BREAK_LIST_SUCCESS = 'rateBreak/DELETE_RATE_BREAK_LIST_SUCCESS';
export const DELETE_RATE_BREAK_LIST_FAILURE = 'rateBreak/DELETE_RATE_BREAK_LIST_FAILURE';

export const SET_RATE_BREAK_HAS_CHANGES = 'rateBreak/SET_RATE_BREAK_HAS_CHANGES';

export type SetRateBreakUuidAction = ReturnType<typeof setRateBreakUuidAction>;
export const setRateBreakUuidAction = (uuid: string) => ({
  type: SET_RATE_BREAK_UUID as typeof SET_RATE_BREAK_UUID,
  uuid,
});

export type SetRateBreakNameAction = ReturnType<typeof setRateBreakNameAction>;
export const setRateBreakNameAction = (name: string) => ({
  type: SET_RATE_BREAK_NAME as typeof SET_RATE_BREAK_NAME,
  name,
});

export type SetRateBreakPercentageAction = ReturnType<typeof setRateBreakPercentageAction>;
export const setRateBreakPercentageAction = (percentage: number) => ({
  type: SET_RATE_BREAK_PERCENTAGE as typeof SET_RATE_BREAK_PERCENTAGE,
  percentage,
});

export type SetSelectedHotelUuidAction = ReturnType<typeof setSelectedHotelUuidAction>;
export const setSelectedHotelUuidAction = (selectedHotelUuid: string | null) => ({
  type: SET_SELECTED_HOTEL_UUID as typeof SET_SELECTED_HOTEL_UUID,
  selectedHotelUuid,
});

export type SetSelectedRateApplicationAction = ReturnType<typeof setSelectedRateApplicationAction>;
export const setSelectedRateApplicationAction = (selectedApplication: ERateApplicationTypes | null) => ({
  type: SET_SELECTED_RATE_APPLICATION as typeof SET_SELECTED_RATE_APPLICATION,
  selectedApplication,
});

export type GetHotelAccommodationsRequestAction = ReturnType<typeof getHotelAccommodationsRequestAction>;
export const getHotelAccommodationsRequestAction = () => ({
  type: GET_HOTEL_ACCOMMODATIONS_REQUEST as typeof GET_HOTEL_ACCOMMODATIONS_REQUEST,
});

export type GetHotelAccommodationsSuccessAction = ReturnType<typeof getHotelAccommodationsSuccessAction>;
export const getHotelAccommodationsSuccessAction = (accommodations: IHotelAccommodationProduct[]) => ({
  type: GET_HOTEL_ACCOMMODATIONS_SUCCESS as typeof GET_HOTEL_ACCOMMODATIONS_SUCCESS,
  accommodations,
});

export type GetHotelAccommodationsFailureAction = ReturnType<typeof getHotelAccommodationsFailureAction>;
export const getHotelAccommodationsFailureAction = (error: string) => ({
  type: GET_HOTEL_ACCOMMODATIONS_FAILURE as typeof GET_HOTEL_ACCOMMODATIONS_FAILURE,
  error,
});

export type AddNewStayBetweenAction = ReturnType<typeof addNewStayBetweenAction>;
export const addNewStayBetweenAction = () => ({
  type: ADD_NEW_STAY_BETWEEN as typeof ADD_NEW_STAY_BETWEEN,
});

export type DeleteStayBetweenAction = ReturnType<typeof deleteStayBetweenAction>;
export const deleteStayBetweenAction = (uuid: string) => ({
  type: DELETE_STAY_BETWEEN as typeof DELETE_STAY_BETWEEN,
  uuid,
});

export type SetStayBetweenSelectedDates = ReturnType<typeof setStayBetweenSelectedDates>;
export const setStayBetweenSelectedDates = (uuid: string, selectedDates: string[]) => ({
  type: SET_STAY_BETWEEN_SELECTED_DATES as typeof SET_STAY_BETWEEN_SELECTED_DATES,
  uuid,
  selectedDates,
});

export type SetIsPreDiscountAction = ReturnType<typeof setIsPreDiscountAction>;
export const setIsPreDiscountAction = (value: boolean) => ({
  type: SET_IS_PRE_DISCOUNT as typeof SET_IS_PRE_DISCOUNT,
  value,
});

export type SetDiscountExtraPersonSupplement = ReturnType<typeof setDiscountExtraPersonSupplementAction>;
export const setDiscountExtraPersonSupplementAction = (value: boolean) => ({
  type: SET_DISCOUNT_EXTRA_PERSON_SUPPLEMENT as typeof SET_DISCOUNT_EXTRA_PERSON_SUPPLEMENT,
  value,
});
export type SetExcludeServiceAndValueTaxes = ReturnType<typeof setExcludeServiceAndValueTaxesAction>;
export const setExcludeServiceAndValueTaxesAction = (value: boolean) => ({
  type: SET_EXCLUDE_SERVICE_AND_VALUE_TAXES as typeof SET_EXCLUDE_SERVICE_AND_VALUE_TAXES,
  value,
});

export type ToggleSelectedAccommodationUuidAction = ReturnType<typeof toggleSelectedAccommodationUuidAction>;
export const toggleSelectedAccommodationUuidAction = (uuid: string, checked: boolean) => ({
  type: TOGGLE_SELECTED_ACCOMMODATION_UUID as typeof TOGGLE_SELECTED_ACCOMMODATION_UUID,
  uuid,
  checked,
});

export type ToggleSelectedTaCountryCodeAction = ReturnType<typeof toggleSelectedTaCountryCodeAction>;
export const toggleSelectedTaCountryCodeAction = (code: string, checked: boolean) => ({
  type: TOGGLE_SELECTED_TA_COUNTRY_CODE as typeof TOGGLE_SELECTED_TA_COUNTRY_CODE,
  code,
  checked,
});

export type ResetSelectedAccommodationUuidsAction = ReturnType<typeof resetSelectedAccommodationUuidsAction>;
export const resetSelectedAccommodationUuidsAction = () => ({
  type: RESET_SELECTED_ACCOMMODATION_UUIDS as typeof RESET_SELECTED_ACCOMMODATION_UUIDS,
});

export type ResetSelectedTaCountriesAction = ReturnType<typeof resetSelectedTaCountriesAction>;
export const resetSelectedTaCountriesAction = () => ({
  type: RESET_SELECTED_TA_COUNTRIES as typeof RESET_SELECTED_TA_COUNTRIES,
});

export type SaveRateBreakRequestAction = ReturnType<typeof saveRateBreakRequestAction>;
export const saveRateBreakRequestAction = () => ({
  type: SAVE_RATE_BREAK_REQUEST as typeof SAVE_RATE_BREAK_REQUEST,
});

export type SaveRateBreakSuccessAction = ReturnType<typeof saveRateBreakSuccessAction>;
export const saveRateBreakSuccessAction = (rateBreakResponse: IPostRateBreakResponse) => ({
  type: SAVE_RATE_BREAK_SUCCESS as typeof SAVE_RATE_BREAK_SUCCESS,
  rateBreakResponse,
});

export type SaveRateBreakFailureAction = ReturnType<typeof saveRateBreakFailureAction>;
export const saveRateBreakFailureAction = (error: string) => ({
  type: SAVE_RATE_BREAK_FAILURE as typeof SAVE_RATE_BREAK_FAILURE,
  error,
});

export type ClearAvailableAccommodationsAction = ReturnType<typeof clearAvailableAccommodationsAction>;
export const clearAvailableAccommodationsAction = () => ({
  type: CLEAR_AVAILABLE_ACCOMMODATIONS as typeof CLEAR_AVAILABLE_ACCOMMODATIONS,
});

export type ClearFormAction = ReturnType<typeof clearFormAction>;
export const clearFormAction = () => ({
  type: CLEAR_FORM as typeof CLEAR_FORM,
});

export type GetRateBreakRequestAction = ReturnType<typeof getRateBreakRequestAction>;
export const getRateBreakRequestAction = () => ({
  type: GET_RATE_BREAK_REQUEST as typeof GET_RATE_BREAK_REQUEST,
});

export type GetRateBreakSuccessAction = ReturnType<typeof getRateBreakSuccessAction>;
export const getRateBreakSuccessAction = (rateBreakResponse: IGetRateBreakResponse) => ({
  type: GET_RATE_BREAK_SUCCESS as typeof GET_RATE_BREAK_SUCCESS,
  rateBreakResponse,
});

export type GetRateBreakFailureAction = ReturnType<typeof getRateBreakFailureAction>;
export const getRateBreakFailureAction = (error: string) => ({
  type: GET_RATE_BREAK_FAILURE as typeof GET_RATE_BREAK_FAILURE,
  error,
});

export type GetRateBreakListRequestAction = ReturnType<typeof getRateBreakListRequestAction>;
export const getRateBreakListRequestAction = () => ({
  type: GET_RATE_BREAK_LIST_REQUEST as typeof GET_RATE_BREAK_LIST_REQUEST,
});

export type GetRateBreakListSuccessAction = ReturnType<typeof getRateBreakListSuccessAction>;
export const getRateBreakListSuccessAction = (rateBreakListItems: IRateBreakListItem[], totalResultsCount: number) => ({
  type: GET_RATE_BREAK_LIST_SUCCESS as typeof GET_RATE_BREAK_LIST_SUCCESS,
  rateBreakListItems,
  totalResultsCount,
});

export type GetRateBreakListFailureAction = ReturnType<typeof getRateBreakListFailureAction>;
export const getRateBreakListFailureAction = (error: string) => ({
  type: GET_RATE_BREAK_LIST_FAILURE as typeof GET_RATE_BREAK_LIST_FAILURE,
  error,
});

export type SetUuidsForDeletion = ReturnType<typeof setUuidsForDeletion>;
export const setUuidsForDeletion = (uuids: string[]) => ({
  type: SET_UUIDS_FOR_DELETION as typeof SET_UUIDS_FOR_DELETION,
  uuids,
});

export type SetFilterHotelUuids = ReturnType<typeof setFilterHotelUuids>;
export const setFilterHotelUuids = (uuids: string[]) => ({
  type: SET_FILTER_HOTEL_UUIDS as typeof SET_FILTER_HOTEL_UUIDS,
  uuids,
});

export type SetCurrentPaginationPage = ReturnType<typeof setCurrentPaginationPage>;
export const setCurrentPaginationPage = (page: number) => ({
  type: SET_CURRENT_PAGINATION_PAGE as typeof SET_CURRENT_PAGINATION_PAGE,
  page,
});

export type SetItemsPerPage = ReturnType<typeof setItemsPerPage>;
export const setItemsPerPage = (itemsPerPage: number) => ({
  type: SET_ITEMS_PER_PAGE as typeof SET_ITEMS_PER_PAGE,
  itemsPerPage,
});

export type DeleteRateBreakListRequestAction = ReturnType<typeof deleteRateBreakListRequestAction>;
export const deleteRateBreakListRequestAction = () => ({
  type: DELETE_RATE_BREAK_LIST_REQUEST as typeof DELETE_RATE_BREAK_LIST_REQUEST,
});

export type DeleteRateBreakListSuccessAction = ReturnType<typeof deleteRateBreakListSuccessAction>;
export const deleteRateBreakListSuccessAction = () => ({
  type: DELETE_RATE_BREAK_LIST_SUCCESS as typeof DELETE_RATE_BREAK_LIST_SUCCESS,
});

export type DeleteRateBreakListFailureAction = ReturnType<typeof deleteRateBreakListFailureAction>;
export const deleteRateBreakListFailureAction = (error: string) => ({
  type: DELETE_RATE_BREAK_LIST_FAILURE as typeof DELETE_RATE_BREAK_LIST_FAILURE,
  error,
});

export type SetTotalResultsCountAction = ReturnType<typeof setTotalResultsCountAction>;
export const setTotalResultsCountAction = (totalResultsCount: number) => ({
  type: SET_TOTAL_RESULTS_COUNT as typeof SET_TOTAL_RESULTS_COUNT,
  totalResultsCount,
});

export type SetRateBreakHasChangesAction = ReturnType<typeof setRateBreakHasChangesAction>;
export const setRateBreakHasChangesAction = (rateBreakHasChanges: boolean) => ({
  type: SET_RATE_BREAK_HAS_CHANGES as typeof SET_RATE_BREAK_HAS_CHANGES,
  rateBreakHasChanges,
});

export type RateBreakDomainAction =
  | SetRateBreakUuidAction
  | SetRateBreakNameAction
  | SetRateBreakPercentageAction
  | SetSelectedHotelUuidAction
  | SetSelectedRateApplicationAction
  | GetHotelAccommodationsRequestAction
  | GetHotelAccommodationsSuccessAction
  | GetHotelAccommodationsFailureAction
  | AddNewStayBetweenAction
  | DeleteStayBetweenAction
  | SetStayBetweenSelectedDates
  | SetIsPreDiscountAction
  | SetDiscountExtraPersonSupplement
  | SetExcludeServiceAndValueTaxes
  | ToggleSelectedAccommodationUuidAction
  | ToggleSelectedTaCountryCodeAction
  | ResetSelectedAccommodationUuidsAction
  | ResetSelectedTaCountriesAction
  | SaveRateBreakRequestAction
  | SaveRateBreakSuccessAction
  | SaveRateBreakFailureAction
  | ClearAvailableAccommodationsAction
  | GetRateBreakListRequestAction
  | GetRateBreakListSuccessAction
  | GetRateBreakListFailureAction
  | SetUuidsForDeletion
  | SetFilterHotelUuids
  | SetCurrentPaginationPage
  | DeleteRateBreakListRequestAction
  | DeleteRateBreakListSuccessAction
  | DeleteRateBreakListFailureAction
  | SetTotalResultsCountAction
  | SetItemsPerPage
  | ClearFormAction
  | SetRateBreakHasChangesAction
  | GetRateBreakRequestAction
  | GetRateBreakSuccessAction
  | GetRateBreakFailureAction;
