import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  makeBookingManagerApi,
  IOwnershipResponse,
  IOwnershipTravelAgentMovements,
  IOwnershipPatchBody,
} from 'services/BookingManagerApi';
import { makeBackendApi, ISalesRepresentativesResponse } from 'services/BackendApi';
import { enqueueNotification } from 'store/modules/ui';

import {
  GET_OWNERSHIP_REQUEST,
  GetOwnershipRequestAction,
  getOwnershipSuccessAction,
  getOwnershipFailureAction,
  UPDATE_OWNERSHIP_REQUEST,
  UpdateOwnershipRequestAction,
  updateOwnershipSuccessAction,
  updateOwnershipFailureAction,
  GET_ASSIGNED_SALES_REPRESENTATIVES_REQUEST,
  GetAssignedSalesRepresentativesRequestAction,
  getAssignedSalesRepresentativesSuccessAction,
  getAssignedSalesRepresentativesFailureAction,
  GET_TP_MOVEMENTS_REQUEST,
  GetTravelPartnerMovementsRequestAction,
  getTravelPartnerMovementsSuccessAction,
  getTravelPartnerMovementsFailureAction,
} from './actions';

import { bookingUuidSelector } from '../../selectors';
import { ownershipDataSelector } from './selectors';
import { managedBySelector, selectedTaUserUuidSelector } from '../bookingSettings/selectors';

function* successNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'success' } }));
}

function* errorNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'error' } }));
}

export function* getOwnershipSaga(action: GetOwnershipRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IOwnershipResponse> = yield call(bookingManagerApi.getOwnership, bookingUuid);
    yield put(getOwnershipSuccessAction(result.data.ownershipData));
  } catch (e) {
    yield put(getOwnershipFailureAction(e.message));
    yield errorNotification('Failed to fetch contact info');
  }
}

export function* updateOwnershipSaga(action: UpdateOwnershipRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);
    const managedBy = yield select(managedBySelector);
    const taUuid = yield select(selectedTaUserUuidSelector);

    const ownershipData: IOwnershipPatchBody = {
      managedBy: managedBy
        .filter(x => x.uuid)
        .map(x => ({ ...x, description: x.description || '' }))
    };

    if (taUuid) {
      ownershipData.taInfo = {
        uuid: taUuid,
      };
    }

    const result: AxiosResponse<IOwnershipResponse> = yield call(
      bookingManagerApi.updateOwnership,
      bookingUuid,
      ownershipData
    );

    yield put(updateOwnershipSuccessAction(result.data.ownershipData));
    yield successNotification('Booking settings updated successfully');
  } catch (e) {
    const message = e.response.data.errors[0].message ?? e.message;
    yield put(updateOwnershipFailureAction(message));
    yield errorNotification(message);
  }
}

export function* getAssignedSalesRepresentativesSaga(action: GetAssignedSalesRepresentativesRequestAction) {
  try {
    const selectedTravelAgent = yield select(selectedTaUserUuidSelector);
    const backendApi = makeBackendApi();

    const ownershipData = yield select(ownershipDataSelector);

    const result: AxiosResponse<ISalesRepresentativesResponse> = yield call(
      backendApi.getAssignedSalesRepresentatives,
      selectedTravelAgent ?? ownershipData.taInfo.uuid
    );

    yield put(getAssignedSalesRepresentativesSuccessAction(result.data.data));
  } catch (e) {
    yield put(getAssignedSalesRepresentativesFailureAction(e.message));
    yield errorNotification('Failed to fetch assigned sales representatives list');
  }
}

export function* getTravelPartnerMovementsSaga(action: GetTravelPartnerMovementsRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IOwnershipTravelAgentMovements[]> = yield call(
      bookingManagerApi.getOwnershipVersions,
      bookingUuid
    );

    yield put(getTravelPartnerMovementsSuccessAction(result.data));
  } catch (e) {
    yield put(getTravelPartnerMovementsFailureAction(e.message));
    yield errorNotification('Failed to fetch travel partner movements list');
  }
}

export function* watchBookingManagerOwnership() {
  yield takeLatest(GET_OWNERSHIP_REQUEST, getOwnershipSaga);
  yield takeLatest(UPDATE_OWNERSHIP_REQUEST, updateOwnershipSaga);
  yield takeLatest(GET_ASSIGNED_SALES_REPRESENTATIVES_REQUEST, getAssignedSalesRepresentativesSaga);
  yield takeLatest(GET_TP_MOVEMENTS_REQUEST, getTravelPartnerMovementsSaga);
}
