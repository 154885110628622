import { connect } from 'react-redux';

import { getUiHeaderMenu } from 'store/modules/ui';
import { getLoggedIn, isInternalUser, isTA } from 'store/modules/auth';
import { pendingProposalsCountSelector } from 'store/modules/proposalsList/subdomains/pendingProposals/selectors';
import { getCurrentUser, isAdmin } from 'store/modules/auth';

export const mapStateToProps = state => ({
  isSR: isInternalUser(state),
  isTA: isTA(state),
  menu: getUiHeaderMenu(state),
  loggedIn: getLoggedIn(state),
  pendingProposalsCount: pendingProposalsCountSelector(state),
  currentUser: getCurrentUser(state),
  isAdmin: isAdmin(state),
});

export default connect(mapStateToProps);
