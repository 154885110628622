import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Actions from './actions';
import { IInventoryStaysDomain, initialState } from './model';
import { update } from 'utils/immutable';
import { TCountryCode } from 'interfaces';
import { EInventoryStayListingContractPaymentType, EInventoryStayListingRateType } from 'services/InventoryAPI';

export const inventoryStaysReducer = (
  state: IInventoryStaysDomain = initialState,
  action: Actions.AllActions
): IInventoryStaysDomain => {
  switch (action.type) {
    case Actions.LIST_ACTIONS.GET_REQUEST:
      return update(state, s => {
        s.request.listingPageGet = ENetworkRequestStatus.PENDING;
      });

    case Actions.LIST_ACTIONS.GET_SUCCESS:
      return update(state, s => {
        s.request.listingPageGet = ENetworkRequestStatus.SUCCESS;

        s.listingPage.items = action.data;
        s.listingPage.filter.page_total = action.total;
      });

    case Actions.LIST_ACTIONS.GET_FAILURE:
      return update(state, s => (s.request.listingPageGet = ENetworkRequestStatus.ERROR));

    case Actions.LIST_ACTIONS.SET_FILTER_COUNTRY_CODE:
      return update(state, s => {
        s.listingPage.filter.page = 1;
        s.listingPage.filter.country_iso = action.countryCode;
      });
    case Actions.LIST_ACTIONS.SET_FILTER_NAME:
      return update(state, s => {
        s.listingPage.filter.page = 1;
        s.listingPage.filter.name = action.name;
      });
    case Actions.LIST_ACTIONS.SET_FILTER_PAYMENT:
      return update(state, s => {
        s.listingPage.filter.page = 1;
        s.listingPage.filter.payment_type = action.payment as EInventoryStayListingContractPaymentType | null;
      });
    case Actions.LIST_ACTIONS.SET_FILTER_RATES:
      return update(state, s => {
        s.listingPage.filter.page = 1;
        s.listingPage.filter.rate_type = action.rates as EInventoryStayListingRateType | null;
      });
    case Actions.LIST_ACTIONS.SET_FILTER_IS_ENABLED:
      return update(state, s => {
        s.listingPage.filter.page = 1;
        s.listingPage.filter.is_enabled = action.isEnabled;
      });

    case Actions.LIST_ACTIONS.SET_LISTING_PAGE_GET:
      return update(state, s => {
        s.request.listingPageGet = action.val;
      });

    case Actions.LIST_ACTIONS.SET_PAGE:
      return update(state, s => {
        s.listingPage.filter.page = action.val;
      });

    case Actions.LIST_ACTIONS.SET_PER_PAGE:
      return update(state, s => {
        s.listingPage.filter.page = 1;
        s.listingPage.filter.page_size = action.val;
      });

    case Actions.LIST_ACTIONS.SET_SORT_DATA:
      return update(state, s => {
        s.listingPage.filter.page = 1;
        s.listingPage.filter.order_by = action.sortBy;
        s.listingPage.filter.order_type = action.sortOrder;
      });
    // case Actions.LIST_ACTIONS.SET_SORT_BY:
    //   return update(state, s => {
    //     s.listingPage.filter.page = 1;
    //     s.listingPage.filter.sortBy = action.val;
    //   });
    // case Actions.LIST_ACTIONS.SET_SORT_ORDER:
    //   return update(state, s => {
    //     s.listingPage.filter.page = 1;
    //     s.listingPage.filter.sortOrder = action.val;
    //   });

    case Actions.LIST_ACTIONS.SEARCH_SUPPLIERS_REQUEST:
      return update(state, s => {
        s.request.suppliersSearch = ENetworkRequestStatus.PENDING;
      });

    case Actions.LIST_ACTIONS.SEARCH_SUPPLIERS_SUCCESS:
      return update(state, s => {
        s.request.suppliersSearch = ENetworkRequestStatus.SUCCESS;
        s.createEditPage.suppliers = action.suppliers;
      });

    case Actions.LIST_ACTIONS.SEARCH_SUPPLIERS_FAILURE:
      return update(state, s => (s.request.suppliersSearch = ENetworkRequestStatus.ERROR));

    case Actions.CREATE_EDIT_ACTIONS.GET_RATINGS_REQUEST:
      return update(state, s => {
        s.request.ratingsGet = ENetworkRequestStatus.PENDING;
      });

    case Actions.CREATE_EDIT_ACTIONS.GET_RATINGS_SUCCESS:
      return update(state, s => {
        s.request.ratingsGet = ENetworkRequestStatus.SUCCESS;

        s.createEditPage.ratings = action.data;
      });

    case Actions.CREATE_EDIT_ACTIONS.GET_RATINGS_FAILURE:
      return update(state, s => (s.request.ratingsGet = ENetworkRequestStatus.ERROR));

    case Actions.CREATE_EDIT_ACTIONS.GET_REGIONS_REQUEST:
      return update(state, s => {
        s.request.regionsGet = ENetworkRequestStatus.PENDING;
      });

    case Actions.CREATE_EDIT_ACTIONS.GET_REGIONS_SUCCESS:
      return update(state, s => {
        s.request.regionsGet = ENetworkRequestStatus.SUCCESS;
        s.createEditPage.regions = action.data;
      });

    case Actions.CREATE_EDIT_ACTIONS.GET_REGIONS_FAILURE:
      return update(state, s => (s.request.regionsGet = ENetworkRequestStatus.ERROR));

    case Actions.CREATE_EDIT_ACTIONS.GET_STAY_REQUEST:
      return update(state, s => {
        s.request.stayGet = ENetworkRequestStatus.PENDING;
      });

    case Actions.CREATE_EDIT_ACTIONS.GET_STAY_SUCCESS:
      return update(state, s => {
        s.request.stayGet = ENetworkRequestStatus.SUCCESS;
        s.createEditPage.stay = action.stay;
      });

    case Actions.CREATE_EDIT_ACTIONS.GET_STAY_FAILURE:
      return update(state, s => (s.request.stayGet = ENetworkRequestStatus.ERROR));

    case Actions.CREATE_EDIT_ACTIONS.RESET_STAY:
      return update(state, s => {
        s.request.stayGet = ENetworkRequestStatus.IDLE;
        s.createEditPage.stay = null;
      });

    case Actions.CREATE_EDIT_ACTIONS.POST_STAY_REQUEST:
      return update(state, s => {
        s.request.stayPost = ENetworkRequestStatus.PENDING;
      });

    case Actions.CREATE_EDIT_ACTIONS.POST_STAY_SUCCESS:
      return update(state, s => {
        s.request.stayPost = ENetworkRequestStatus.SUCCESS;
      });

    case Actions.CREATE_EDIT_ACTIONS.POST_STAY_FAILURE:
      return update(state, s => (s.request.stayPost = ENetworkRequestStatus.ERROR));

    case Actions.CREATE_EDIT_ACTIONS.PUT_STAY_REQUEST:
      return update(state, s => {
        s.request.stayPut = ENetworkRequestStatus.PENDING;
      });

    case Actions.CREATE_EDIT_ACTIONS.PUT_STAY_SUCCESS:
      return update(state, s => {
        s.request.stayPut = ENetworkRequestStatus.SUCCESS;
      });

    case Actions.CREATE_EDIT_ACTIONS.PUT_STAY_FAILURE:
      return update(state, s => (s.request.stayPut = ENetworkRequestStatus.ERROR));

    case Actions.CREATE_EDIT_ACTIONS.GET_BEST_FOR_FILTERS_REQUEST:
      return update(state, s => {
        s.request.bestforFiltersGet = ENetworkRequestStatus.PENDING;
      });

    case Actions.CREATE_EDIT_ACTIONS.GET_BEST_FOR_FILTERS_SUCCESS:
      return update(state, s => {
        s.request.bestforFiltersGet = ENetworkRequestStatus.SUCCESS;
        s.createEditPage.bestforFilters = action.data;
      });

    case Actions.CREATE_EDIT_ACTIONS.GET_BEST_FOR_FILTERS_FAILURE:
      return update(state, s => (s.request.bestforFiltersGet = ENetworkRequestStatus.ERROR));

    case Actions.CREATE_EDIT_ACTIONS.GET_AMENITY_FILTERS_REQUEST:
      return update(state, s => {
        s.request.amenityFiltersGet = ENetworkRequestStatus.PENDING;
      });

    case Actions.CREATE_EDIT_ACTIONS.GET_AMENITY_FILTERS_SUCCESS:
      return update(state, s => {
        s.request.amenityFiltersGet = ENetworkRequestStatus.SUCCESS;
        s.createEditPage.amenityFilters = action.data;
      });

    case Actions.CREATE_EDIT_ACTIONS.GET_AMENITY_FILTERS_FAILURE:
      return update(state, s => (s.request.amenityFiltersGet = ENetworkRequestStatus.ERROR));

    case Actions.CREATE_EDIT_ACTIONS.GET_OTHER_FILTERS_REQUEST:
      return update(state, s => {
        s.request.otherFiltersGet = ENetworkRequestStatus.PENDING;
      });

    case Actions.CREATE_EDIT_ACTIONS.GET_OTHER_FILTERS_SUCCESS:
      return update(state, s => {
        s.request.otherFiltersGet = ENetworkRequestStatus.SUCCESS;
        s.createEditPage.otherFilters = action.data;
      });

    case Actions.CREATE_EDIT_ACTIONS.GET_OTHER_FILTERS_FAILURE:
      return update(state, s => (s.request.otherFiltersGet = ENetworkRequestStatus.ERROR));

    default:
      return state;
  }
};

export default inventoryStaysReducer;
