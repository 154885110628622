import React from 'react';
import { EImportEntity } from '../../store/modules/importer/model';
import { staticRatesAvailabilityImportDomainSelector } from '../../store/modules/staticRatesAvailabilityImport/selectors';
import Importer, { IImportProps } from 'pureUi/Importer';
import { WithImporter } from 'hoc/WithImporter';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

const StaticRatesAvailabilityImportContainer = (props: Omit<IImportProps, 'entityName'>) => {
  const { dynamicParameters } = useDynamicParameters();

  return (
    <Importer
      entityName="Static Rates Availability"
      confirmationCopy="This operation takes usually around 1 minute. It cannot be cancelled before completion and it is irreversible."
      {...props}
      dynamicParameters={dynamicParameters}
      pageTitle={
        <h1 className="font-normal font-noe-display text-[36px] leading-46px">
          Products - <span className="text-[26px]">Hotels - Static Rates Availability</span>
        </h1>
      }
    />
  );
};

export const StaticRatesAvailabilityImportContainerConnected = WithImporter(
  EImportEntity.STATIC_RATES_AVAILABILITY,
  staticRatesAvailabilityImportDomainSelector
)(StaticRatesAvailabilityImportContainer);
