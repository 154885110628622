import { ICompany, ENetworkRequestStatus } from 'services/BackendApi';

export interface ICompanyListPagination {
  page: number;
  perPage: number;
  total: number | null;
}

export interface ICompanyListFilter {
  search: string | null;
  countryCode: string | null;
}

export interface ICompanyListDomain {
  list: ICompany[] | null;
  filter: ICompanyListFilter;
  pagination: ICompanyListPagination;
  request: {
    csv: ENetworkRequestStatus;
    get: ENetworkRequestStatus;
  }
}

export const initialState: ICompanyListDomain = {
  list: null,
  filter: {
    search: null,
    countryCode: null
  },
  pagination: {
    page: 0,
    perPage: 10,
    total: null
  },
  request: {
    get: ENetworkRequestStatus.IDLE,
    csv: ENetworkRequestStatus.IDLE
  }
};
