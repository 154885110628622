import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  makeBookingManagerApi,
  IPoliciesAndRestrictionsResponse,
  IHeadlineBreakdownVersionListVersion,
} from 'services/BookingManagerApi';
import {
  GET_POLICIES_AND_RESTRICTIONS_REQUEST,
  getPoliciesAndRestrictionsSuccessAction,
  getPoliciesAndRestrictionsFailureAction,
} from '../actions';

import { bookingUuidSelector } from '../../../selectors';
import * as BreakdownSelectors from '../selectors';
import * as BreakdownActions from '../actions';

export function* getPoliciesAndRestrictionsSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid: string = yield select(bookingUuidSelector);
    const selectedVersion: IHeadlineBreakdownVersionListVersion | null = yield select(
      BreakdownSelectors.headlineBreakdownSelectedVersionSelector
    );

    const result: AxiosResponse<IPoliciesAndRestrictionsResponse> = yield call(
      bookingManagerApi.getPoliciesAndRestrictions,
      bookingUuid,
      selectedVersion?.timestamp
    );

    yield put(getPoliciesAndRestrictionsSuccessAction(result.data.policiesAndRestrictions));
  } catch (e) {
    yield put(getPoliciesAndRestrictionsFailureAction(e));
  }
}

export function* watchBookingManagerPoliciesAndRestrictions() {
  yield takeLatest(
    [GET_POLICIES_AND_RESTRICTIONS_REQUEST, BreakdownActions.SET_HEADLINE_BREAKDOWN_SELECTED_VERSION],
    getPoliciesAndRestrictionsSaga
  );
}
