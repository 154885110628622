import React from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import * as Inputs from '../Inputs';
import FluidButton from 'ui/FluidButton';
import {
  IAccommodationProduct,
  IHotel,
  IMealPlanProduct,
  ISeason,
  ISupplementProduct,
  ITransferProduct,
} from 'services/BackendApi';
import { Room } from '../child-product-forms/Room';
import { produce } from 'immer';
import * as _ from 'lodash-es';
import { MealPlan } from '../child-product-forms/MealPlan';
import { Transfer } from '../child-product-forms/Transfer';
import { Supplement } from '../child-product-forms/Supplement';
import { validateSupplement } from 'containers/HotelAdmin/validation';
import { useDispatch } from 'react-redux';
import { enqueueNotification } from 'store/modules/ui';

export const CreateSupplementModal = ({
  onConfirm,
  onClose,
  hotel,
}: {
  onConfirm: (data: any) => void;
  onClose: () => void;
  hotel: IHotel;
}) => {
  const dispatch = useDispatch();
  const [draftSupplement, setDraftSupplement] = React.useState<Partial<ISupplementProduct>>({
    meta: {
      description: '',
    },
    options: {
      ages: [],
    },
  });

  return (
    <GeneralModal
      onClose={() => onClose()}
      modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-scroll font-pt-sans"
      shouldCloseByClickingOutside={false}
    >
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">Create new Supplement</h1>
      <Supplement
        supplement={draftSupplement}
        onUpdate={(field, value) => {
          setDraftSupplement({
            ...produce(draftSupplement, _draftRoom => {
              _.set(_draftRoom, field, value);
            }),
          });
        }}
        onCta={() => {
          const validation = validateSupplement(draftSupplement);
          if (validation.isValid === false) {
            dispatch(
              enqueueNotification({
                message: `Supplement is not valid: ${validation.message}`,
                options: { variant: 'warning' },
              })
            );
            return;
          }
          onConfirm(draftSupplement);
        }}
        ctaLabel="Create Supplement"
      />
    </GeneralModal>
  );
};
