import { ICompanyDepartment } from "services/BookingManagerApi/types/CompanyDepartmentsResponse";

export const FETCH_COMPANY_DEPARTMENTS_REQUEST = 'companyDepartment/FETCH_COMPANY_DEPARTMENTS_REQUEST';
export const FETCH_COMPANY_DEPARTMENTS_SUCCESS = 'companyDepartment/FETCH_COMPANY_DEPARTMENTS_SUCCESS';
export const FETCH_COMPANY_DEPARTMENTS_FAILURE = 'companyDepartment/FETCH_COMPANY_DEPARTMENTS_FAILURE';
export const RESET_COMPANY_DEPARTMENTS = 'companyDepartment/RESET_COMPANY_DEPARTMENTS_REQUEST';

export const CREATE_COMPANY_DEPARTMENT_REQUEST = 'companyDepartment/CREATE_COMPANY_DEPARTMENT_REQUEST';
export const CREATE_COMPANY_DEPARTMENT_SUCCESS = 'companyDepartment/CREATE_COMPANY_DEPARTMENT_SUCCESS';
export const CREATE_COMPANY_DEPARTMENT_FAILURE = 'companyDepartment/CREATE_COMPANY_DEPARTMENT_FAILURE';

export const EDIT_COMPANY_DEPARTMENT_REQUEST = 'companyDepartment/EDIT_COMPANY_DEPARTMENT_REQUEST';
export const EDIT_COMPANY_DEPARTMENT_SUCCESS = 'companyDepartment/EDIT_COMPANY_DEPARTMENT_SUCCESS';
export const EDIT_COMPANY_DEPARTMENT_FAILURE = 'companyDepartment/EDIT_COMPANY_DEPARTMENT_FAILURE';

export const DELETE_COMPANY_DEPARTMENT_REQUEST = 'companyDepartment/DELETE_COMPANY_DEPARTMENT_REQUEST';
export const DELETE_COMPANY_DEPARTMENT_SUCCESS = 'companyDepartment/DELETE_COMPANY_DEPARTMENT_SUCCESS';
export const DELETE_COMPANY_DEPARTMENT_FAILURE = 'companyDepartment/DELETE_COMPANY_DEPARTMENT_FAILURE';
export const SET_UUID_TO_DELETE = 'companyDepartment/SET_UUID_TO_DELETE';
export const SET_DEPARTMENT_TO_EDIT = 'companyDepartment/SET_DEPARTMENT_TO_EDIT';

export const OPEN_CREATE_DEPARTMENT_MODAL = 'companyDepartment/OPEN_CREATE_DEPARTMENT_MODAL';
export const CLOSE_CREATE_DEPARTMENT_MODAL = 'companyDepartment/CLOSE_CREATE_DEPARTMENT_MODAL';

export type FetchCompanyDepartmentsRequestAction = ReturnType<typeof fetchCompanyDepartmentsRequestAction>;
export const fetchCompanyDepartmentsRequestAction = (companyUuid: string) => ({
  type: FETCH_COMPANY_DEPARTMENTS_REQUEST as typeof FETCH_COMPANY_DEPARTMENTS_REQUEST,
  companyUuid,
});

export type FetchCompanyDepartmentsSuccessAction = ReturnType<typeof fetchCompanyDepartmentsSuccessAction>;
export const fetchCompanyDepartmentsSuccessAction = (departments: ICompanyDepartment[]) => ({
  type: FETCH_COMPANY_DEPARTMENTS_SUCCESS as typeof FETCH_COMPANY_DEPARTMENTS_SUCCESS,
  departments,
});

export type FetchCompanyDepartmentsFailureAction = ReturnType<typeof fetchCompanyDepartmentsFailureAction>;
export const fetchCompanyDepartmentsFailureAction = (error: Error) => ({
  type: FETCH_COMPANY_DEPARTMENTS_FAILURE as typeof FETCH_COMPANY_DEPARTMENTS_FAILURE,
  error,
});

export type ResetCompanyDepartmentsAction = ReturnType<typeof resetCompanyDepartmentsAction>;
export const resetCompanyDepartmentsAction = () => ({
  type: RESET_COMPANY_DEPARTMENTS as typeof RESET_COMPANY_DEPARTMENTS,
});

export type CreateCompanyDepartmentRequestAction = ReturnType<typeof createCompanyDepartmentRequestAction>;
export const createCompanyDepartmentRequestAction = (
    companyUuid: string, departmentName: string, responsibleName: string, emails: string
) => ({
  type: CREATE_COMPANY_DEPARTMENT_REQUEST as typeof CREATE_COMPANY_DEPARTMENT_REQUEST,
  companyUuid, departmentName, responsibleName, emails, 
});

export type CreateCompanyDepartmentSuccessAction = ReturnType<typeof createCompanyDepartmentSuccessAction>;
export const createCompanyDepartmentSuccessAction = () => ({
  type: CREATE_COMPANY_DEPARTMENT_SUCCESS as typeof CREATE_COMPANY_DEPARTMENT_SUCCESS,
});

export type CreateCompanyDepartmentFailureAction = ReturnType<typeof createCompanyDepartmentFailureAction>;
export const createCompanyDepartmentFailureAction = (error: Error) => ({
  type: CREATE_COMPANY_DEPARTMENT_FAILURE as typeof CREATE_COMPANY_DEPARTMENT_FAILURE,
  error
});

export type OpenCreateDepartmentModalAction = ReturnType<typeof openCreateDepartmentModalAction>;
export const openCreateDepartmentModalAction = () => ({
  type: OPEN_CREATE_DEPARTMENT_MODAL as typeof OPEN_CREATE_DEPARTMENT_MODAL
})

export type CloseCreateDepartmentModalAction = ReturnType<typeof closeCreateDepartmentModalAction>;
export const closeCreateDepartmentModalAction = () => ({
  type: CLOSE_CREATE_DEPARTMENT_MODAL as typeof CLOSE_CREATE_DEPARTMENT_MODAL
})

export type EditCompanyDepartmentRequestAction = ReturnType<typeof editCompanyDepartmentRequestAction>;
export const editCompanyDepartmentRequestAction = (
    departmentUuid: string, companyUuid: string, departmentName: string, responsibleName: string, emails: string
) => ({
  type: EDIT_COMPANY_DEPARTMENT_REQUEST as typeof EDIT_COMPANY_DEPARTMENT_REQUEST,
  departmentUuid, companyUuid, departmentName, responsibleName, emails, 
});

export type EditCompanyDepartmentSuccessAction = ReturnType<typeof editCompanyDepartmentSuccessAction>;
export const editCompanyDepartmentSuccessAction = () => ({
  type: EDIT_COMPANY_DEPARTMENT_SUCCESS as typeof EDIT_COMPANY_DEPARTMENT_SUCCESS,
});

export type EditCompanyDepartmentFailureAction = ReturnType<typeof editCompanyDepartmentFailureAction>;
export const editCompanyDepartmentFailureAction = (error: Error) => ({
  type: EDIT_COMPANY_DEPARTMENT_FAILURE as typeof EDIT_COMPANY_DEPARTMENT_FAILURE,
  error,
});

export type DeleteCompanyDepartmentRequestAction = ReturnType<typeof deleteCompanyDepartmentRequestAction>;
export const deleteCompanyDepartmentRequestAction = (departmentUuid: string) => ({
  type: DELETE_COMPANY_DEPARTMENT_REQUEST as typeof DELETE_COMPANY_DEPARTMENT_REQUEST,
  departmentUuid,
});

export type DeleteCompanyDepartmentSuccessAction = ReturnType<typeof deleteCompanyDepartmentSuccessAction>;
export const deleteCompanyDepartmentSuccessAction = () => ({
  type: DELETE_COMPANY_DEPARTMENT_SUCCESS as typeof DELETE_COMPANY_DEPARTMENT_SUCCESS,
});

export type DeleteCompanyDepartmentFailureAction = ReturnType<typeof deleteCompanyDepartmentFailureAction>;
export const deleteCompanyDepartmentFailureAction = (error: Error) => ({
  type: DELETE_COMPANY_DEPARTMENT_FAILURE as typeof DELETE_COMPANY_DEPARTMENT_FAILURE,
  error,
});

export type SetUuidToDeleteAction = ReturnType<typeof setUuidToDeleteAction>;
export const setUuidToDeleteAction = (uuidToDelete: string | null) => ({
  type: SET_UUID_TO_DELETE as typeof SET_UUID_TO_DELETE,
  uuidToDelete,
});

export type SetDepartmentToEditAction = ReturnType<typeof setDepartmentToEditAction>;
export const setDepartmentToEditAction = (department: ICompanyDepartment | null) => ({
  type: SET_DEPARTMENT_TO_EDIT as typeof SET_DEPARTMENT_TO_EDIT,
  department,
});

export type CompanyDepartmentsAction =
  | FetchCompanyDepartmentsRequestAction
  | FetchCompanyDepartmentsSuccessAction
  | FetchCompanyDepartmentsFailureAction
  | CreateCompanyDepartmentRequestAction
  | CreateCompanyDepartmentSuccessAction
  | CreateCompanyDepartmentFailureAction
  | EditCompanyDepartmentRequestAction
  | EditCompanyDepartmentSuccessAction
  | EditCompanyDepartmentFailureAction
  | ResetCompanyDepartmentsAction
  | DeleteCompanyDepartmentRequestAction
  | DeleteCompanyDepartmentSuccessAction
  | DeleteCompanyDepartmentFailureAction
  | SetUuidToDeleteAction
  | SetDepartmentToEditAction
  | OpenCreateDepartmentModalAction
  | CloseCreateDepartmentModalAction;
