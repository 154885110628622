import * as React from 'react';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ENetworkRequestStatus, EUserType, EVoucherLang } from 'services/BackendApi/types';
import { EBookingStatus } from 'services/BookingManagerApi/types';
import TopNavigationBar from 'pureUi/TopNavigationBar';
import {
  topNavigationDataDashboardSelector,
  bookingInformationReservationTeamDashboardSelector,
  dashboardNetworkRequestsSelector,
} from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import { getTopNavigationDataRequestAction } from 'store/modules/bookingManager/subdomains/dashboard/actions';

import { VoucherForm } from '../../ui/VoucherForm';
import {
  getVoucherFormViewRequestAction,
  setVoucherFormDataAction,
  downloadVoucherRequestAction,
  setVoucherLogoAction,
  setVoucherLangAction,
} from 'store/modules/bookingManager/subdomains/voucher/actions';
import {
  formDataSelector,
  formErrorsSelector,
  voucherDownloadLoadSelector,
  voucherFormViewLoadSelector,
  voucherLogoSelector,
  langSelector,
} from 'store/modules/bookingManager/subdomains/voucher/selectors';
import { EPdfLogo } from 'store/modules/bookingManager/subdomains/breakdown/model';
import FluidButton from 'ui/FluidButton';
import LogoSelector from 'ui/LogoSelector';
import { Select } from 'ui/Select';
import {
  taLogoNetworkRequestsSelector,
  taLogoSelector,
} from 'store/modules/bookingManager/subdomains/taLogo/selectors';
import { getTaLogoRequestAction } from 'store/modules/bookingManager/subdomains/taLogo/actions';
import { LoadingBar, ErrorBar } from 'ui/NetworkStatusBar';
import { getHotelDetailsRequestAction } from 'store/modules/bookingManager/subdomains/hotelDetails/actions';
import { networkRequestsSelector as hotelDetailsNetworkRequestsSelector } from 'store/modules/bookingManager/subdomains/hotelDetails/selectors';
import { getCurrentUserType } from 'store/modules/auth';

const langOptions = [
  { value: EVoucherLang.EN, label: 'English' },
  { value: EVoucherLang.RU, label: 'Russian' },
];

export class BookingManagerVouchers extends React.Component<IBookingManagerVouchersProps, {}> {
  handleLogoChange = (logoType: EPdfLogo) => {
    this.props.setVoucherLogo(logoType);
  };

  handleLangChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.setVoucherLang(ev.target.value as EVoucherLang);
  };

  componentDidMount() {
    this.props.getTopNavigationDataRequest();
    this.props.getVoucherFormViewRequest();
    this.props.getTaLogoRequest();
    this.props.getHotelDetailsRequest();
  }

  componentDidUpdate() {
    // In case we change booking from one with TALogo to another without TALogo we
    // need to reset voucherLogo to defaultValue (MAIN_COMPANY_LOGO) to avoid OWA-2981 bug
    if (this.props.taLogo === undefined && this.props.voucherLogo === EPdfLogo.TA_LOGO) {
      this.props.setVoucherLogo(EPdfLogo.MAIN_COMPANY_LOGO);
    }
  }

  render() {
    const { topNavigationDataLoad } = this.props.dashboardNetworkRequests;

    const notConfirmedError =
      topNavigationDataLoad === ENetworkRequestStatus.SUCCESS &&
      (this.props.topNavigationData?.bookingStatus as unknown) !== EBookingStatus.CONFIRMED;

    if (
      topNavigationDataLoad === ENetworkRequestStatus.PENDING ||
      this.props.voucherFormViewLoad === ENetworkRequestStatus.PENDING ||
      this.props.hotelDetailsNetworkRequests.hotelDetailsLoad === ENetworkRequestStatus.PENDING
    ) {
      return (
        <div className="mt-5">
          <LoadingBar />
        </div>
      );
    }

    return (
      <div>
        <TopNavigationBar
          userRole={this.props.userRole as EUserType}
          data={this.props.topNavigationData}
          bookingInformationReservationTeamData={this.props.bookingInformationReservationTeamData}
          isError={topNavigationDataLoad === ENetworkRequestStatus.ERROR}
        />
        <h1 className="text-4xl font-noe-display font-normal mt-0 mb-2 self-center">Vouchers</h1>

        {this.props.voucherFormViewLoad === ENetworkRequestStatus.ERROR ||
          (this.props.hotelDetailsNetworkRequests.hotelDetailsLoad === ENetworkRequestStatus.ERROR && <ErrorBar />)}

        {this.props.voucherFormViewLoad === ENetworkRequestStatus.SUCCESS &&
          this.props.hotelDetailsNetworkRequests.hotelDetailsLoad === ENetworkRequestStatus.SUCCESS && (
            <React.Fragment>
              <VoucherForm
                formErrors={this.props.formErrors}
                {...this.props.formData}
                onChange={(path, value) => {
                  this.props.setVoucherFormData(path, value);
                }}
                arrivalDate={this.props.topNavigationData?.arrivalDate}
                departureDate={this.props.topNavigationData?.departureDate}
              />
              <div className="flex justify-between items-center mt-5">
                <LogoSelector
                  selectedLogo={this.props.voucherLogo}
                  hasTaLogo={this.props.taLogo !== undefined}
                  onChange={this.handleLogoChange}
                  isLoading={this.props.taLogoNetworkRequests.taLogo === ENetworkRequestStatus.PENDING}
                  isError={this.props.taLogoNetworkRequests.taLogo === ENetworkRequestStatus.ERROR}
                />
                <div className="flex items-center font-pt-sans">
                  <label className="mr-2 text-13px leading-14px text-black">Voucher language:</label>
                  <Select
                    value={this.props.lang}
                    onChange={this.handleLangChange}
                    className="lang-select min-w-130px bg-ivory"
                    options={langOptions}
                  />
                </div>
              </div>
              <div className="flex items-center mt-40px">
                <FluidButton
                  className="voucher-download-button"
                  onClick={() => this.props.downloadVoucherRequest(EPdfLogo.MAIN_COMPANY_LOGO)}
                  type="secondary"
                  isLoading={this.props.voucherDownloadLoad === ENetworkRequestStatus.PENDING}
                  disabled={Object.keys(this.props.formErrors).length >= 1 || notConfirmedError}
                >
                  Download
                </FluidButton>
                {notConfirmedError && (
                  <span className="booking-not-confirmed error text-red-100 font-pt-sans ml-4">
                    To generate voucher, please confirm the booking
                  </span>
                )}
              </div>
            </React.Fragment>
          )}
      </div>
    );
  }
}

export type StateToProps = ReturnType<typeof mapStateToProps>;
export type DispatchToProps = typeof actionCreators;

export interface IBookingManagerVouchersProps extends StateToProps, DispatchToProps {}

const mapStateToProps = createStructuredSelector({
  topNavigationData: topNavigationDataDashboardSelector,
  bookingInformationReservationTeamData: bookingInformationReservationTeamDashboardSelector,
  dashboardNetworkRequests: dashboardNetworkRequestsSelector,
  formData: formDataSelector,
  formErrors: formErrorsSelector,
  voucherDownloadLoad: voucherDownloadLoadSelector,
  voucherFormViewLoad: voucherFormViewLoadSelector,
  voucherLogo: voucherLogoSelector,
  lang: langSelector,
  taLogo: taLogoSelector,
  taLogoNetworkRequests: taLogoNetworkRequestsSelector,
  hotelDetailsNetworkRequests: hotelDetailsNetworkRequestsSelector,
  userRole: getCurrentUserType,
});

const actionCreators = {
  getTopNavigationDataRequest: getTopNavigationDataRequestAction,
  getVoucherFormViewRequest: getVoucherFormViewRequestAction,
  setVoucherFormData: setVoucherFormDataAction,
  downloadVoucherRequest: downloadVoucherRequestAction,
  setVoucherLogo: setVoucherLogoAction,
  getTaLogoRequest: getTaLogoRequestAction,
  getHotelDetailsRequest: getHotelDetailsRequestAction,
  setVoucherLang: setVoucherLangAction,
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

// -----------------------------------------------------------------------------
// Connected
// -----------------------------------------------------------------------------
const withConnect = connect<StateToProps, DispatchToProps, IBookingManagerVouchersProps>(
  mapStateToProps,
  mapDispatchToProps
);

// @ts-ignore
export const BookingManagerVouchersConnected = compose(withConnect)(BookingManagerVouchers);
export default BookingManagerVouchersConnected;
