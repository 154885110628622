import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { SvgIcon } from 'ui/SvgIcon';
import OpenEyeIcon from 'ui/Icons/openEye.component.svg';
import ClosedEyeIcon from 'ui/Icons/closedEye.component.svg';
import Checkbox from 'ui/Checkbox';
import { INotification } from 'services/BackendApi/types/Notification';
import { IMultiselectValueLabelPair, Multiselect } from 'ui/Multiselect';
import * as CompanyDepartmentsSelectors from 'store/modules/companyDepartments/selectors';
import { theme } from '../../../../tailwind.config';

interface ITravelAgentNotificationRowProps {
  notification: INotification;
  onChangeEnabled: (notificationCode: string, enabled: boolean) => void;
  onChangeDepartmentsUuids: (notificationCode: string, departmentsUuids: string[]) => void;
  disabled?: boolean;
}

export const TravelAgentNotificationRow: React.FC<ITravelAgentNotificationRowProps> = React.memo(
  ({ notification, disabled = false, onChangeEnabled, onChangeDepartmentsUuids }) => {
    const handleNotificationChange = useCallback(() => {
      onChangeEnabled(notification.code, !notification.enabled);
    }, [notification, onChangeEnabled]);

    const handleNotificationDepartmentsChange = (ccToDepartmentsUuids: string[]) => {
      onChangeDepartmentsUuids(notification.code, ccToDepartmentsUuids);
    };

    const departments = useSelector(CompanyDepartmentsSelectors.companyDepartmentsSelector) || [];

    const departmentSelectOptions: IMultiselectValueLabelPair[] = departments.map(d => {
      return {
        value: d.uuid,
        label: d.name,
      };
    });

    return (
      <tr
        key={notification.code}
        className={classnames('notification-list-row text-15px', {
          'text-gray-100': !notification.enabled,
        })}
      >
        <td className="notification-title pl-5 py-4 border-solid border-b border-gray-20">
          <label className="notification-list-row-checkbox-label flex flex-row items-center">
            <Checkbox checked={notification.enabled} onChange={handleNotificationChange} disabled={disabled} />
            <span className="ml-2 font-bold">{notification.title}</span>
          </label>
        </td>
        <td className="notification-description pl-5 py-4 border-solid border-b border-gray-20">
          {notification.description}
        </td>
        <td className="notification-cc-to-department pl-5 py-4 border-solid border-b border-gray-20">
          <Multiselect
            disabled={!notification.enabled}
            isIncludeClearButton={true}
            className="type-select bg-ivory text-15px"
            itemCtaClassName="hover:bg-teal-20 text-left"
            itemsClassname="bg-ivory"
            placeholderText="None"
            options={departmentSelectOptions}
            onUpdate={selectedValues => {
              if (selectedValues.length <= 0) {
                handleNotificationDepartmentsChange([]);
              } else {
                handleNotificationDepartmentsChange(selectedValues);
              }
            }}
            selectedValues={notification.ccToDepartmentsUuids ? notification.ccToDepartmentsUuids : []}
          />
        </td>
        <td className="notification-status pl-5 py-4 pr-12 items-center flex justify-between border-solid border-b border-gray-20 min-h-70px">
          <span className="italic">{notification.enabled ? 'Active' : 'Inactive'}</span>
          <span className="float-right">
            {(notification.enabled && (
              <SvgIcon
                key={notification.code + 'open'}
                IconComponent={OpenEyeIcon}
                width="22px"
                height="22px"
                className="pt-2px"
                defaultFill={theme.colors['green-prime']}
                hoverFill={theme.colors['green-prime']}
                activeFill={theme.colors['green-prime']}
              />
            )) || (
              <SvgIcon
                key={notification.code + 'closed'}
                IconComponent={ClosedEyeIcon}
                width="22px"
                height="22px"
                defaultFill={theme.colors['gray-60']}
                hoverFill={theme.colors['gray-60']}
                activeFill={theme.colors['gray-60']}
              />
            )}
          </span>
        </td>
      </tr>
    );
  }
);
