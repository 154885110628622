import { RateBreakDomainAction } from './actions';
import { IRateBreakDomain } from './types';
import { initialState } from './model';
import * as Actions from './actions';
import { ENetworkRequestStatus } from 'services/BackendApi';
import * as uuid from 'uuid';
import { produce } from 'immer';
import { uniq } from 'ramda';
import { generateArrayOfDatesBetween } from 'utils';

export const rateBreakReducer = (
  state: IRateBreakDomain = initialState,
  action: RateBreakDomainAction
): IRateBreakDomain => {
  switch (action.type) {
    case Actions.SET_RATE_BREAK_UUID:
      return produce(state, draftState => {
        draftState.uuid = action.uuid;
        return draftState;
      });

    case Actions.SET_RATE_BREAK_NAME:
      return {
        ...state,
        rateBreakName: action.name,
        rateBreakHasChanges: true,
      };

    case Actions.SET_RATE_BREAK_PERCENTAGE:
      return {
        ...state,
        rateBreakPercentage: action.percentage,
        rateBreakHasChanges: true,
      };

    case Actions.SET_SELECTED_HOTEL_UUID:
      return produce(state, draftState => {
        draftState.selectedHotelUuid = action.selectedHotelUuid;
        draftState.selectedHotelAccommodationUuids = [];
        draftState.availableHotelAccommodations = [];
        draftState.selectedRateApplication = null;
        draftState.networkRequests.getHotelAccommodations = ENetworkRequestStatus.IDLE;
        return draftState;
      });

    case Actions.SET_SELECTED_RATE_APPLICATION:
      return {
        ...state,
        selectedRateApplication: action.selectedApplication,
        rateBreakHasChanges: true,
      };

    case Actions.SET_IS_PRE_DISCOUNT:
      return {
        ...state,
        isPreDiscount: action.value,
        rateBreakHasChanges: true,
      };


    case Actions.SET_DISCOUNT_EXTRA_PERSON_SUPPLEMENT:
      return {
        ...state,
        discountExtraPersonSupplement: action.value,
        rateBreakHasChanges: true,
      };

    case Actions.SET_EXCLUDE_SERVICE_AND_VALUE_TAXES:
      return {
        ...state,
        excludeServiceAndValueTaxes: action.value,
        rateBreakHasChanges: true,
      };

    case Actions.GET_HOTEL_ACCOMMODATIONS_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.getHotelAccommodations = ENetworkRequestStatus.PENDING;
        draftState.selectedHotelAccommodationUuids = [];
        return draftState;
      });

    case Actions.GET_HOTEL_ACCOMMODATIONS_SUCCESS:
      return {
        ...state,
        availableHotelAccommodations: action.accommodations,
        networkRequests: {
          ...state.networkRequests,
          getHotelAccommodations: ENetworkRequestStatus.SUCCESS,
        },
      };

    case Actions.GET_HOTEL_ACCOMMODATIONS_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.getHotelAccommodations = ENetworkRequestStatus.ERROR;
        draftState.selectedHotelAccommodationUuids = [];
        return draftState;
      });

    case Actions.ADD_NEW_STAY_BETWEEN:
      return produce(state, draftState => {
        draftState.stayBetweenDateRanges.push({
          uuid: uuid.v4(),
          selectedDates: [],
        });
        draftState.rateBreakHasChanges = true;

        return draftState;
      });

    case Actions.DELETE_STAY_BETWEEN:
      return produce(state, draftState => {
        const index = draftState.stayBetweenDateRanges.findIndex(sb => sb.uuid === action.uuid);
        if (index > -1) {
          draftState.stayBetweenDateRanges.splice(index, 1);
          draftState.rateBreakHasChanges = true;
        }

        return draftState;
      });

    case Actions.SET_STAY_BETWEEN_SELECTED_DATES:
      return produce(state, draftState => {
        const indexOf = draftState.stayBetweenDateRanges.findIndex(sb => sb.uuid === action.uuid);
        draftState.stayBetweenDateRanges[indexOf].selectedDates = action.selectedDates;
        draftState.rateBreakHasChanges = true;
        return draftState;
      });

    case Actions.TOGGLE_SELECTED_ACCOMMODATION_UUID:
      return produce(state, draftState => {
        if (action.checked) {
          draftState.selectedHotelAccommodationUuids.push(action.uuid);
        } else {
          const index = draftState.selectedHotelAccommodationUuids.indexOf(action.uuid);
          if (index > -1) {
            draftState.selectedHotelAccommodationUuids.splice(index, 1);
          }
        }

        draftState.selectedHotelAccommodationUuids = uniq(draftState.selectedHotelAccommodationUuids);
        draftState.rateBreakHasChanges = true;

        return draftState;
      });

    case Actions.TOGGLE_SELECTED_TA_COUNTRY_CODE:
      return produce(state, draftState => {
        if (action.checked) {
          draftState.selectedTravelAgentCountryCodes.push(action.code);
        } else {
          const index = draftState.selectedTravelAgentCountryCodes.indexOf(action.code);
          if (index > -1) {
            draftState.selectedTravelAgentCountryCodes.splice(index, 1);
          }
        }

        draftState.selectedTravelAgentCountryCodes = uniq(draftState.selectedTravelAgentCountryCodes);
        draftState.rateBreakHasChanges = true;

        return draftState;
      });

    case Actions.RESET_SELECTED_ACCOMMODATION_UUIDS:
      return produce(state, draftState => {
        draftState.selectedHotelAccommodationUuids = [];
        draftState.rateBreakHasChanges = true;
        return draftState;
      });

    case Actions.RESET_SELECTED_TA_COUNTRIES:
      return produce(state, draftState => {
        draftState.selectedTravelAgentCountryCodes = [];
        draftState.rateBreakHasChanges = true;
        return draftState;
      });

    case Actions.SAVE_RATE_BREAK_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.saveRateBreak = ENetworkRequestStatus.PENDING;
        draftState.rateBreakHasChanges = false;
        return draftState;
      });

    case Actions.SAVE_RATE_BREAK_SUCCESS:
      return produce(state, draftState => {
        draftState.networkRequests.saveRateBreak = ENetworkRequestStatus.SUCCESS;

        draftState.uuid = action.rateBreakResponse.uuid;
        draftState.createdAt = action.rateBreakResponse.createdAt;
        draftState.updatedAt = action.rateBreakResponse.updatedAt;

        draftState.stayBetweenDateRanges = action.rateBreakResponse.dates.map((responseDateRange, index) => {
          return {
            uuid: uuid.v4(), // always set a new UUID for sanity's sake
            selectedDates: generateArrayOfDatesBetween(responseDateRange.startDate, responseDateRange.endDate),
          };
        });

        return draftState;
      });

    case Actions.SAVE_RATE_BREAK_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.saveRateBreak = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.CLEAR_AVAILABLE_ACCOMMODATIONS:
      return produce(state, draftState => {
        draftState.availableHotelAccommodations = [];
        return draftState;
      });

    case Actions.CLEAR_FORM:
      return produce(state, draftState => {
        draftState = {
          ...initialState,
        };

        draftState.uuid = undefined;
        draftState.updatedAt = undefined;
        draftState.createdAt = undefined;

        draftState.networkRequests = {
          ...state.networkRequests,
          getHotelAccommodations: ENetworkRequestStatus.IDLE,
          saveRateBreak: ENetworkRequestStatus.IDLE,
        };
        draftState.rateBreakListingPage = {
          ...state.rateBreakListingPage,
        };
        return draftState;
      });

    case Actions.GET_RATE_BREAK_LIST_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.getRateBreakList = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_RATE_BREAK_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.networkRequests.getRateBreakList = ENetworkRequestStatus.SUCCESS;
        draftState.rateBreakListingPage.rateBreakList = action.rateBreakListItems;
        draftState.rateBreakListingPage.totalResultsCount = action.totalResultsCount;
        return draftState;
      });

    case Actions.GET_RATE_BREAK_LIST_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.getRateBreakList = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_UUIDS_FOR_DELETION:
      return produce(state, draftState => {
        draftState.rateBreakListingPage.uuidsForDeletion = action.uuids;
        return draftState;
      });

    case Actions.SET_FILTER_HOTEL_UUIDS:
      return produce(state, draftState => {
        draftState.rateBreakListingPage.filterHotelUuids = action.uuids;
        draftState.networkRequests.getRateBreakList = ENetworkRequestStatus.PENDING;
        draftState.rateBreakListingPage.uuidsForDeletion = [];
        return draftState;
      });

    case Actions.SET_CURRENT_PAGINATION_PAGE:
      return produce(state, draftState => {
        draftState.rateBreakListingPage.paginationCurrentPage = action.page;
        draftState.networkRequests.getRateBreakList = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.SET_ITEMS_PER_PAGE:
      return produce(state, draftState => {
        draftState.rateBreakListingPage.paginationItemsPerPage = action.itemsPerPage;
        return draftState;
      });

    case Actions.DELETE_RATE_BREAK_LIST_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.deleteRateBreakList = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.DELETE_RATE_BREAK_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.networkRequests.deleteRateBreakList = ENetworkRequestStatus.SUCCESS;
        draftState.rateBreakListingPage.uuidsForDeletion = [];
        return draftState;
      });

    case Actions.DELETE_RATE_BREAK_LIST_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.deleteRateBreakList = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_TOTAL_RESULTS_COUNT:
      return produce(state, draftState => {
        draftState.rateBreakListingPage.totalResultsCount = action.totalResultsCount;
        return draftState;
      });

    case Actions.GET_RATE_BREAK_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.getRateBreak = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_RATE_BREAK_SUCCESS:
      return produce(state, draftState => {
        draftState.networkRequests.getRateBreak = ENetworkRequestStatus.SUCCESS;

        draftState.uuid = action.rateBreakResponse.uuid;
        draftState.updatedAt = action.rateBreakResponse.updatedAt;
        draftState.createdAt = action.rateBreakResponse.createdAt;

        draftState.rateBreakName = action.rateBreakResponse.name;
        draftState.isPreDiscount = action.rateBreakResponse.preDiscount;
        draftState.discountExtraPersonSupplement = action.rateBreakResponse.discountExtraPersonSupplement;
        draftState.excludeServiceAndValueTaxes = action.rateBreakResponse.excludeServiceAndValueTaxes;
        draftState.rateBreakPercentage = parseInt(action.rateBreakResponse.discountPercentage);
        draftState.selectedHotelUuid = action.rateBreakResponse.hotelUuid;

        if (action.rateBreakResponse.dates.length) {
          draftState.stayBetweenDateRanges = action.rateBreakResponse.dates.map((responseDateRange, index) => {
            return {
              uuid: uuid.v4(), // always set a new UUID for sanity's sake
              selectedDates: generateArrayOfDatesBetween(responseDateRange.startDate, responseDateRange.endDate),
            };
          });
        }

        if (action.rateBreakResponse.greenTaxDiscountApproach) {
          draftState.selectedRateApplication = action.rateBreakResponse.greenTaxDiscountApproach;
        }

        if (action.rateBreakResponse.accommodationProducts) {
          draftState.selectedHotelAccommodationUuids = [...action.rateBreakResponse.accommodationProducts];
        }

        if (action.rateBreakResponse.countryCodes) {
          draftState.selectedTravelAgentCountryCodes = [...action.rateBreakResponse.countryCodes];
        }

        return draftState;
      });

    case Actions.GET_RATE_BREAK_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.getRateBreak = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    default:
      return state;
  }
};
