import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AnonymousAvatarComponent as AnonymousAvatarIcon,
} from 'ui/Icons';
import { theme } from '../../../../tailwind.config';
import { TextInput } from 'ui/TextInput';

interface ITaskCommentCreateProps {
  onFocus: () => void;
}

export const TaskCommentCreate: React.FC<ITaskCommentCreateProps> = ({ onFocus }) => {
  const noop = useCallback(() => {}, []);

  return (
    <div className="edit-comment flex w-full items-start gap-[10px]">
      <div className="user-picture">
        <AnonymousAvatarIcon icon={theme.colors['gray-100']} fill={theme.colors['gray-40']} />
      </div>
      <div className="comment-container flex-1">
        <TextInput 
          id="comment" 
          placeholder="Add a comment here ..."
          value="" 
          onFocus={onFocus}
          onChange={noop} 
          className='w-full font-pt-sans placeholder:text-[15px]'
          inputClassName="border-gray-17 bg-white-true focus:border-teal-80 focus:border-2 focus:py-[6px]"
        />        
      </div>
    </div>
  )
};
