import React, { useCallback, ReactNode } from 'react';
import classNames from 'classnames';
import { isNil, pick } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';
import { formatDate } from 'utils/date';
import { ENetworkRequestStatus } from 'services/BackendApi';
import {
  IFinanceRow,
  EPurchaseCostReviewStatus,
  IHeadlineLineItemBreakdown,
  EFinanceRowTypes,
} from 'services/BookingManagerApi/types';
import { StandardModal } from 'pureUi/Modal';
import { FinanceTableModal } from 'ui/FinanceTableModal';
import { PurchaseCostReviewStatus } from 'ui/PurchaseCostReviewStatus';
import { EFinanceTableTypes, EFinanceRowSummingType } from 'store/modules/bookingManager/subdomains/finance/types';

import { headlineLineItemBreakdownSelector } from '../../store/modules/bookingManager/subdomains/breakdown/selectors';
import { bookingCurrencySymbolSelector, bookingCurrencySelector } from '../../store/modules/bookingManager/selectors';

import {
  requestedStatusSelector,
  financePurchaseRowSelector,
  commentSelector,
  uploadFileRequestStatusSelector,
  patchPurchaseCostReviewRequestStatusSelector,
} from 'store/modules/bookingManager/subdomains/purchaseCostReview/selectors';

import {
  confirmStatusChangeAction,
  cancelStatusChangeAction,
} from 'store/modules/bookingManager/subdomains/purchaseCostReview/actions';

interface IPurchaseCostReviewFinanceModalProps {
  className?: string;
}

interface IInfoProps {
  className?: string;
  icon: ReactNode;
  label: string;
  content: string;
}

const Info: React.FC<IInfoProps> = ({ className, icon, label, content }) => (
  <div className={classNames(className, 'p-3 mb-4 rounded text-black font-pt-sans leading-sm')}>
    <div className="flex items-center">
      <span className="text-base font-bold mr-1">{label}</span>
      {icon}
    </div>
    <span className="text-15px">{content}</span>
  </div>
);

const ApprovedInfo: React.FC = () => (
  <Info
    className="bg-green-25"
    icon={<PurchaseCostReviewStatus status={EPurchaseCostReviewStatus.APPROVED} />}
    label="Approved"
    content="The Hotel Purchase Cost was approved."
  />
);

const RejectedInfo: React.FC = () => (
  <Info
    className="bg-red-25"
    icon={<PurchaseCostReviewStatus status={EPurchaseCostReviewStatus.REJECTED} />}
    label="Rejected"
    content="The Hotel Purchase Cost of this booking was rejected."
  />
);

export const PurchaseCostReviewFinanceModal: React.FC<IPurchaseCostReviewFinanceModalProps> = props => {
  const headlineLineItemBreakdown =
    useSelector(headlineLineItemBreakdownSelector) || ({} as IHeadlineLineItemBreakdown);
  const requestedStatus = useSelector(requestedStatusSelector);
  const financePurchaseRow = useSelector(financePurchaseRowSelector);
  const comment = useSelector(commentSelector);

  const patchPurchaseCostReviewRequestStatus = useSelector(patchPurchaseCostReviewRequestStatusSelector);
  const uploadFileRequestStatus = useSelector(uploadFileRequestStatusSelector);
  const currencySymbol = useSelector(bookingCurrencySymbolSelector);
  const bookingCurrency = useSelector(bookingCurrencySelector);

  const isApproved = requestedStatus === EPurchaseCostReviewStatus.APPROVED;
  const isRejected = requestedStatus === EPurchaseCostReviewStatus.REJECTED;

  const amountCents = isApproved ? calcAmountCents(headlineLineItemBreakdown) : financePurchaseRow?.amountCents;

  const commentSpec = isRejected ? { label: 'Rejection reason', required: true, value: comment?.text } : undefined;

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (newRowWithoutUpload: IFinanceRow, files: File[], comment?: string) => {
      const newRow =
        !files.length && financePurchaseRow
          ? {
              ...newRowWithoutUpload,
              ...pick(financePurchaseRow, ['uploadUuid', 'uploadName', 'uploadUrl']),
            }
          : newRowWithoutUpload;

      dispatch(confirmStatusChangeAction(newRow, files[0], comment));
    },
    [dispatch, confirmStatusChangeAction, financePurchaseRow]
  );

  return (
    <StandardModal
      className={classNames(props.className, 'purchase-cost-review-finance-modal font-pt-sans z-11')}
      frameClassName="w-700px px-9 py-8 border border-solid"
      showCloseButton={true}
      removePadding={true}
      closeOnOutsideClick={true}
      onClose={() => dispatch(cancelStatusChangeAction(requestedStatus as EPurchaseCostReviewStatus))}
    >
      <FinanceTableModal
        modalTitle={financePurchaseRow ? 'Editing Finance Row' : 'Adding Finance Row'}
        submitButtonLabel={isApproved ? 'Approve' : 'Reject'}
        financeModalType={EFinanceTableTypes.PURCHASE}
        disableRowTypeSelection
        extraInfo={isApproved ? <ApprovedInfo /> : <RejectedInfo />}
        onSubmit={onSubmit}
        submitButtonLoading={
          uploadFileRequestStatus === ENetworkRequestStatus.PENDING ||
          patchPurchaseCostReviewRequestStatus === ENetworkRequestStatus.PENDING
        }
        date={financePurchaseRow?.date || formatDate(new Date())}
        description={financePurchaseRow?.description || 'Hotel Invoice'}
        rowType={EFinanceRowTypes.Purchase_Cost_Review_Hotel_Invoice}
        amountCents={amountCents || undefined}
        currencySymbol={currencySymbol || undefined}
        invoiceDueDates={isApproved ? [] : undefined}
        uploadName={financePurchaseRow?.uploadName || undefined}
        commentSpec={commentSpec}
        hotelCurrency={bookingCurrency}
        paymentMethods={[]}
      />
    </StandardModal>
  );
};

const calcAmountCents = (b: IHeadlineLineItemBreakdown): number | null => {
  const cost = b.PurchaseCostSubtotalCents;
  const gs = b['Ground Service'];

  if (isNil(cost) || isNil(gs) || gs.isOnRequest || isNil(gs.purchaseCostSubtotalCents)) {
    return null;
  }

  return cost - gs.purchaseCostSubtotalCents;
};
