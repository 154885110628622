import {
  IPurchaseCostReviewResponse,
  IPurchaseCostReviewPatchRequest,
  IFinanceRow,
  EPurchaseCostReviewStatus
} from 'services/BookingManagerApi';

import { IUploadFileInfo } from 'services/BackendApi';

export const GET_PURCHASE_COST_REVIEW_REQUEST = 'bookingManager_purchaseCostReview/GET_PURCHASE_COST_REVIEW_REQUEST';
export const GET_PURCHASE_COST_REVIEW_SUCCESS = 'bookingManager_purchaseCostReview/GET_PURCHASE_COST_REVIEW_SUCCESS';
export const GET_PURCHASE_COST_REVIEW_FAILURE = 'bookingManager_purchaseCostReview/GET_PURCHASE_COST_REVIEW_FAILURE';

export const PATCH_PURCHASE_COST_REVIEW_REQUEST = 'bookingManager_purchaseCostReview/PATCH_PURCHASE_COST_REVIEW_REQUEST';
export const PATCH_PURCHASE_COST_REVIEW_SUCCESS = 'bookingManager_purchaseCostReview/PATCH_PURCHASE_COST_REVIEW_SUCCESS';
export const PATCH_PURCHASE_COST_REVIEW_FAILURE = 'bookingManager_purchaseCostReview/PATCH_PURCHASE_COST_REVIEW_FAILURE';

export const REQUEST_STATUS_CHANGE = 'bookingManager_purchaseCostReview/REQUEST_STATUS_CHANGE';
export const CONFIRM_STATUS_CHANGE = 'bookingManager_purchaseCostReview/CONFIRM_STATUS_CHANGE';
export const CANCEL_STATUS_CHANGE = 'bookingManager_purchaseCostReview/CANCEL_STATUS_CHANGE';

export const UPLOAD_FILE_REQUEST = 'bookingManager_purchaseCostReview/UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'bookingManager_purchaseCostReview/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'bookingManager_purchaseCostReview/UPLOAD_FILE_FAILURE';

export type GetPurchaseCostReviewRequestAction = ReturnType<typeof getPurchaseCostReviewRequestAction>;
export const getPurchaseCostReviewRequestAction = () => ({
  type: GET_PURCHASE_COST_REVIEW_REQUEST as typeof GET_PURCHASE_COST_REVIEW_REQUEST
});

export type GetPurchaseCostReviewSuccessAction = ReturnType<typeof getPurchaseCostReviewSuccessAction>;
export const getPurchaseCostReviewSuccessAction = (response: IPurchaseCostReviewResponse) => ({
  type: GET_PURCHASE_COST_REVIEW_SUCCESS as typeof GET_PURCHASE_COST_REVIEW_SUCCESS,
  response
});

export type GetPurchaseCostReviewFailureAction = ReturnType<typeof getPurchaseCostReviewFailureAction>;
export const getPurchaseCostReviewFailureAction = (error: string) => ({
  type: GET_PURCHASE_COST_REVIEW_FAILURE as typeof GET_PURCHASE_COST_REVIEW_FAILURE,
  error
});

export type PatchPurchaseCostReviewRequestAction = ReturnType<typeof patchPurchaseCostReviewRequestAction>;
export const patchPurchaseCostReviewRequestAction = (request: IPurchaseCostReviewPatchRequest) => ({
  type: PATCH_PURCHASE_COST_REVIEW_REQUEST as typeof PATCH_PURCHASE_COST_REVIEW_REQUEST,
  request
});

export type PatchPurchaseCostReviewSuccessAction = ReturnType<typeof patchPurchaseCostReviewSuccessAction>;
export const patchPurchaseCostReviewSuccessAction = (response: IPurchaseCostReviewResponse) => ({
  type: PATCH_PURCHASE_COST_REVIEW_SUCCESS as typeof PATCH_PURCHASE_COST_REVIEW_SUCCESS,
  response
});

export type PatchPurchaseCostReviewFailureAction = ReturnType<typeof patchPurchaseCostReviewFailureAction>;
export const patchPurchaseCostReviewFailureAction = (error: string) => ({
  type: PATCH_PURCHASE_COST_REVIEW_FAILURE as typeof PATCH_PURCHASE_COST_REVIEW_FAILURE,
  error
});

export type RequestStatusChangeAction = ReturnType<typeof requestStatusChangeAction>;
export const requestStatusChangeAction = (requestedStatus: EPurchaseCostReviewStatus) => ({
  type: REQUEST_STATUS_CHANGE as typeof REQUEST_STATUS_CHANGE,
  requestedStatus,
});

export type ConfirmStatusChangeAction = ReturnType<typeof confirmStatusChangeAction>;
export const confirmStatusChangeAction = (
  financePurchaseRow?: IFinanceRow | null,
  file?: File | null,
  comment?: string | null
) => ({
  type: CONFIRM_STATUS_CHANGE as typeof CONFIRM_STATUS_CHANGE,
  financePurchaseRow,
  file,
  comment
});

export type CancelStatusChangeAction = ReturnType<typeof cancelStatusChangeAction>;
export const cancelStatusChangeAction = (requestedStatus: EPurchaseCostReviewStatus) => ({
  type: CANCEL_STATUS_CHANGE as typeof CANCEL_STATUS_CHANGE,
  requestedStatus
});

export type UploadFileRequestAction = ReturnType<typeof uploadFileRequestAction>;
export const uploadFileRequestAction = (file: File) => ({
  type: UPLOAD_FILE_REQUEST as typeof UPLOAD_FILE_REQUEST,
  file
});

export type UploadFileSuccessAction = ReturnType<typeof uploadFileSuccessAction>;
export const uploadFileSuccessAction = (upload: IUploadFileInfo) => ({
  type: UPLOAD_FILE_SUCCESS as typeof UPLOAD_FILE_SUCCESS,
  upload
});

export type UploadFileFailureAction = ReturnType<typeof uploadFileFailureAction>;
export const uploadFileFailureAction = (error: string) => ({
  type: UPLOAD_FILE_FAILURE as typeof UPLOAD_FILE_FAILURE,
  error
});

export type PurchaseCostReviewAction =
  | GetPurchaseCostReviewRequestAction
  | GetPurchaseCostReviewSuccessAction
  | GetPurchaseCostReviewFailureAction
  | PatchPurchaseCostReviewRequestAction
  | PatchPurchaseCostReviewSuccessAction
  | PatchPurchaseCostReviewFailureAction
  | RequestStatusChangeAction
  | ConfirmStatusChangeAction
  | CancelStatusChangeAction
  | UploadFileRequestAction
  | UploadFileSuccessAction
  | UploadFileFailureAction;
