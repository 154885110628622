import React, { useMemo } from 'react';
import AnimateHeight from 'react-animate-height';
import DownloadIcon from 'ui/Icons/download.arrow.component.svg';
import { IStatementPdf } from 'services/BookingManagerApi';
import { theme } from '../../../tailwind.config';

interface IPdfDownloadHistoryProps {
  isOpen: boolean;
  pdfs: IStatementPdf[];
  loading: boolean;
}

export const DOWNLOAD_PDF_HISTORY_ANIMATION_DURATION = 300;

export const PdfDownloadHistory: React.FC<IPdfDownloadHistoryProps> = ({ pdfs, loading, isOpen }) => {
  const contentHeight = useMemo(() => (isOpen ? 'auto' : 0), [isOpen]);
  const pdfNameClass =
    'underline hover:no-decoration hover:text-black active:underline active:text-black-true font-pt-sans text-16px leading-21px text-black ml-10px';

  if (pdfs.length === 0) {
    return (
      // @ts-ignore
      <AnimateHeight
        className="animate-height"
        duration={DOWNLOAD_PDF_HISTORY_ANIMATION_DURATION}
        height={contentHeight}
      >
        <div className="booking-ledger-download-history mx-15px px-10px py-15px bg-ivory rounded mb-5">
          <h2 className="download-history-title text-17px font-bold mt-0">Download History</h2>
          <p className="font-pt-sans text-15px leading-19px font-normal italic text-black mt-15px mb-0">
            No downloads in the history.
          </p>
        </div>
      </AnimateHeight>
    );
  }

  return (
    // @ts-ignore
    <AnimateHeight className="animate-height" duration={300} height={contentHeight}>
      <div className="download-history px-5 py-15px bg-ivory rounded mb-5">
        <h2 className="download-history-title text-17px font-bold mt-0">
          Download History <span className="font-normal">({pdfs.length})</span>
        </h2>

        {loading ? (
          <div className="download-history-loading flex justify-center items-center">
            <i className="text-4xl fas fa-circle-notch fa-spin text-gray-30" />
          </div>
        ) : (
          <div className="download-history-container">
            {pdfs.map(pdf => {
              return (
                <div
                  key={pdf.uuid}
                  className="download-history-row flex items-center mt-15px pb-5px border-solid border-b border-gray-20"
                >
                  <DownloadIcon fill={theme.colors['brown-80']} />
                  <a href={pdf.url} className="pdf-link w-420px" target="_blank" rel="noopener noreferrer">
                    <span className={pdfNameClass}>{pdf.filename}</span>
                  </a>
                  <div className="pdf-creator-email ml-50px">
                    <span className="break-all">{pdf.creatorEmail}</span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </AnimateHeight>
  );
};
