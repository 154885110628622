import { ENetworkRequestStatus } from 'services/BackendApi';
import { Proposal } from 'services/BookingManagerApi';

export interface IProposalEditorDomain {
  proposal: Proposal | null;
  googleDocDraftId: string | null;
  request: {
    get: ENetworkRequestStatus;
    draft: ENetworkRequestStatus;
    commit: ENetworkRequestStatus;
  };
}

export const initialState: IProposalEditorDomain = {
  proposal: null,
  googleDocDraftId: null,
  request: {
    get: ENetworkRequestStatus.IDLE,
    draft: ENetworkRequestStatus.IDLE,
    commit: ENetworkRequestStatus.IDLE,
  }
};
