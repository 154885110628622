
import { call, takeLatest, select, put } from 'redux-saga/effects';
import { makeBackendApi } from 'services/BackendApi';
import { 
  EDIT_COMPANY_DEPARTMENT_REQUEST,
  EditCompanyDepartmentRequestAction,
  editCompanyDepartmentFailureAction,
  editCompanyDepartmentSuccessAction,
  fetchCompanyDepartmentsRequestAction,
  setDepartmentToEditAction,
} from '../actions';
import { enqueueNotification } from 'store/modules/ui';
import { companyDataSelector } from 'store/modules/companyInfo';

function* editDepartmentSaga(action: EditCompanyDepartmentRequestAction) {
  try {
    const backendApi = makeBackendApi();
    yield call(
      backendApi.updateCompanyDepartment,
      action.departmentUuid,
      action.companyUuid,
      action.departmentName,
      action.responsibleName,
      action.emails,
    );

    yield put(editCompanyDepartmentSuccessAction());
    yield put(setDepartmentToEditAction(null));
    const company = yield select(companyDataSelector);
    yield put(fetchCompanyDepartmentsRequestAction(company.uuid));
    yield put(enqueueNotification({
      message: 'Company department updated.',
      options: { variant: 'success' },
    }));
  } catch (e) {
    yield put(editCompanyDepartmentFailureAction(e));
    yield put(enqueueNotification({
      message: 'There was an error updating company department',
      options: { variant: 'error' },
    }))}
}

export function* watchEditDepartmentSaga() {
  yield takeLatest(
    [EDIT_COMPANY_DEPARTMENT_REQUEST],
    editDepartmentSaga
  );
}
