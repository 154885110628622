import { createSelector } from 'reselect';
import { bookingConfirmationDomain } from '../../selectors';

export const quoteSubdomainSelector = createSelector(
  bookingConfirmationDomain,
  domain => domain.quote
);

export const isQuoteDownloadingSelector = createSelector(
  quoteSubdomainSelector,
  domain => domain.isQuoteDownloading
);
