import React from 'react';
import classnames from 'classnames';
import { ProductLineWithPrice } from './ProductLineWithPrice';
import { TCurrencyCode } from 'interfaces';
import { AppliedSuplement, Fine, GroundService, Product, Transfer } from 'services/BackendApi';

export interface IBookingProduct {
  total: number;
  totalBeforeDiscount: number;
  isOnRequestOrPartiallyOnRequest?: boolean;
  selected: boolean;
  products: Product[];
}

interface IBookingNonAccommodationProductProps {
  products: Transfer[] | GroundService[] | Fine[] | AppliedSuplement[];
  title: string;
  currencyCode: TCurrencyCode;
  className?: string;
}

export const BookingNonAccommodationProduct: React.FC<IBookingNonAccommodationProductProps> = React.memo(
  ({ products, title, currencyCode, className }) => {
    return (
      <div className="booking-non-accommodation-product px-5 py-15px border-solid border-b border-gray-20">
        <div className="nap-data flex items-center justify-between">
          <p className="nap-title font-hurmegeometric-sans text-flint text-13px leading-16px uppercase font-bold m-0">
            {title}
          </p>
        </div>

        <div className="product-lines mt-3px">
          {products.filter(item => item.selected).map((product, index) => {
            return (
              <ProductLineWithPrice
                key={index}
                name={product.products[0].name}
                total={product.total}
                totalBeforeDiscount={product.totalBeforeDiscount}
                currencyCode={currencyCode}
                isOnRequest={product.isOnRequestOrPartiallyOnRequest}
                className={classnames('non-accommodation-product', className)}
              />
            );
          })}
        </div>
      </div>
    );
  }
);
