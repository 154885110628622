import React, { useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { useRouteMatch, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BackLink } from 'ui/BackLink';
import { PageTitle } from 'ui/PageTitle';
import { Multiselect } from 'ui/Multiselect';
import { Radio } from 'ui/Radio';
import Checkbox from 'ui/Checkbox';
import { TextInput } from 'pureUi/TextInput';
import FluidButton from 'ui/FluidButton';
import Docs from 'ui/Icons/docs.component.svg';
import { IValueLabelPair } from '../../interfaces';
import { EProposalTemplate, EProposalLang } from 'services/BookingManagerApi';

import * as Selectors from 'store/modules/proposalGenerate/selectors';
import * as Actions from 'store/modules/proposalGenerate/actions';

import { ENetworkRequestStatus } from 'services/BackendApi';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { UITextArea } from 'ui/UITextArea';

const templateOptions = [
  { value: EProposalTemplate.ITINERARY, label: 'Itinerary' },
  { value: EProposalTemplate.PROPOSAL, label: 'Proposal' },
];

const langOptions = [
  { value: EProposalLang.EN, label: 'English' },
  { value: EProposalLang.RU, label: 'Russian' },
];

const titleOptions = [
  { value: null, label: 'None' },
  { value: 'Master', label: 'Master' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Mr', label: 'Mr.' },
  { value: 'Mr.', label: 'Mr.' },
  { value: 'Mrs', label: 'Mrs.' },
  { value: 'Mrs.', label: 'Mrs.' },
  { value: 'Ms', label: 'Ms.' },
  { value: 'Ms.', label: 'Ms.' },
];

const somePending = (...xs: ENetworkRequestStatus[]): boolean => xs.some(x => x === ENetworkRequestStatus.PENDING);

const someError = (...xs: ENetworkRequestStatus[]): boolean => xs.some(x => x === ENetworkRequestStatus.ERROR);

interface IProposalRouteParams {
  proposalSelectionUuid: string;
}

export const ProposalGeneratePage: React.FC = React.memo(() => {
  const dispatch = useDispatch();

  const location = useLocation();
  const match = useRouteMatch<IProposalRouteParams>();
  const { proposalSelectionUuid } = match.params;
  const formData = useSelector(Selectors.formData);
  const formValid = useSelector(Selectors.formValid);
  const request = useSelector(Selectors.request);
  const proposal = useSelector(Selectors.proposal);
  const proposalSelection = useSelector(Selectors.proposalSelection);
  const logoOptions = useSelector(Selectors.proposalLogoOptionsSelector);

  const generationInProgress = somePending(request.save, request.download);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const bookingUuids = search.get('bookings')?.split(',') || [];
    const proposalUuid = search.get('proposal') || undefined;

    dispatch(Actions.initFormAction(proposalSelectionUuid, bookingUuids, proposalUuid));
  }, [dispatch]);

  const handleTemplateSelect = useCallback(v => dispatch(Actions.setTemplateAction(v)), [dispatch]);

  const handleLangSelect = useCallback(v => dispatch(Actions.setLangAction(v)), [dispatch]);

  const handleNameChange = useCallback(v => dispatch(Actions.setNameAction(v)), [dispatch]);

  const handleLogoChange = useCallback(v => dispatch(Actions.setLogoAction(v)), [dispatch]);

  const handleTaMarginIncludedChange = useCallback(v => dispatch(Actions.toggleTaMarginIncludedAction()), [dispatch]);

  const handleTitleSelect = useCallback(v => dispatch(Actions.setGuestTitleAction(v)), [dispatch]);

  const handleClientFirstNameChange = useCallback(v => dispatch(Actions.setGuestFirstNameAction(v)), [dispatch]);

  const handleClientLastNameChange = useCallback(v => dispatch(Actions.setGuestLastNameAction(v)), [dispatch]);

  const handleGeneralCommentsChange = useCallback(v => dispatch(Actions.setGeneralCommentsAction(v)), [dispatch]);

  const handleOtherCommentsChange = useCallback(v => dispatch(Actions.setOtherCommentsAction(v)), [dispatch]);

  const handleHotelCommentsChange = useCallback((idx, v) => dispatch(Actions.setHotelCommentsAction(idx, v)), [
    dispatch,
  ]);

  const handleSave = useCallback(() => dispatch(Actions.saveRequestAction()), [dispatch]);

  const handleDownload = useCallback(() => dispatch(Actions.downloadRequestAction()), [dispatch]);

  if (someError(request.selection, request.get)) {
    return (
      <div className="mt-5">
        <ErrorBar />
      </div>
    );
  }

  if (somePending(request.selection, request.get)) {
    return (
      <div className="mt-5">
        <LoadingBar />
      </div>
    );
  }

  return (
    <div className="proposal-generate container mx-auto max-w-1280px font-pt-sans text-black">
      <BackLink type="internal" href="edit">
        Back to proposal
      </BackLink>
      <PageTitle primaryText="Generate Proposal PDF" />
      <div className="content border border-solid border-gray-40 mt-8 p-5">
        <div className="flex">
          <div className="form w-3/5 py-20px">
            <Panel text="Document Details" className="document-details">
              <div className="flex">
                <Label className="hidden" text="Type">
                  <Select
                    className="w-150px"
                    options={templateOptions}
                    selected={[formData.template]}
                    onSelect={handleTemplateSelect}
                  />
                </Label>
                <Label className="ml-5 hidden" text="Language">
                  <Select
                    className="w-150px"
                    options={langOptions}
                    selected={[formData.lang]}
                    onSelect={handleLangSelect}
                  />
                </Label>
                <Label className="w-full" text="Title">
                  <Input value={formData.name} onChange={handleNameChange} />
                </Label>
              </div>
              <Label className="mt-20px block" text="Logo">
                <div className="flex items-center mt-2">
                  {logoOptions.map(x => (
                    <label
                      className="inline-flex font-pt-sans text-base text-black cursor-pointer mr-20px"
                      onClick={() => handleLogoChange(x.value)}
                    >
                      <Radio checked={x.value === formData.logo} />
                      <span className="ml-2">{x.label}</span>
                    </label>
                  ))}
                </div>
              </Label>
              {(proposal?.taMarginIncluded || proposalSelection?.taMarginIncluded) && (
                <label className="inline-flex font-pt-sans text-base select-none cursor-pointer text-black mt-30px">
                  <Checkbox checked={formData.taMarginIncluded} onClick={handleTaMarginIncludedChange} />
                  <span className="ml-2">TA Margin Included</span>
                </label>
              )}
            </Panel>
            <Panel text="Client" className="client mt-30px">
              <div className="flex">
                <Label text="Title">
                  <Select
                    className="w-150px"
                    options={titleOptions}
                    selected={[formData.guest.title!]}
                    onSelect={handleTitleSelect}
                  />
                </Label>
                <Label className="ml-5 w-1/2" text="Name">
                  <Input value={formData.guest.firstName} onChange={handleClientFirstNameChange} />
                </Label>
                <Label className="ml-5 w-1/2" text="Last Name">
                  <Input value={formData.guest.lastName} onChange={handleClientLastNameChange} />
                </Label>
              </div>
            </Panel>
            <Separator className="mt-30px mb-30px" />
            <div className="uppercase font-pt-sans text-gray-100 text-xs tracking-2xs leading-16px">
              Comments and notes (optional)
            </div>
            <Panel text="General Comments" className="general-comments mt-30px">
              <TextArea value={formData.generalComments} onChange={handleGeneralCommentsChange} />
            </Panel>
            {formData.hotelComments.map((x, i) => (
              <Panel key={i} text={x.name} className="mt-30px">
                <TextArea value={x.content} onChange={v => handleHotelCommentsChange(i, v)} />
              </Panel>
            ))}
            <Panel text="Other Comments" className="other-comments mt-30px">
              <TextArea value={formData.otherComments} onChange={handleOtherCommentsChange} />
            </Panel>
          </div>
          <div className="preview bg-gray-20 flex-1 ml-35px px-30px py-20px relative">
            {!proposal && (
              <div className="flex flex-col items-center justify-center h-full">
                <div className="max-w-170px text-center">
                  <Docs className="mb-25px fill-gray-100" />
                  <div className="font-pt-sans text-gray-100 text-15px leading-22px mb-25px">
                    Generating PDF Preview, please wait a moment ...
                  </div>
                  <i className="fas fa-circle-notch fa-spin text-teal-100 text-3xl" />
                </div>
              </div>
            )}
            {proposal && (
              <>
                <div className="flex flex-col h-full">
                  <div className="font-pt-sans font-bold text-17px leading-sm test-black-true">PDF Preview</div>
                  <iframe className="w-full flex-1 border-0 mt-20px" src={`${proposal.pdfUrl}#toolbar=0`} />
                </div>
                <Link className="absolute right-20px bottom-40px" to={`/proposals-v2/${proposal.uuid}/editor`}>
                  <EditButton disabled={generationInProgress} loading={generationInProgress} />
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="controls flex mt-30px">
          <FluidButton
            onClick={handleSave}
            type="secondary"
            className="save"
            isLoading={request.save === ENetworkRequestStatus.PENDING}
            disabled={!formValid || generationInProgress}
          >
            Save
          </FluidButton>
          <FluidButton
            onClick={handleDownload}
            className="download ml-10px"
            type="primary"
            isLoading={request.download === ENetworkRequestStatus.PENDING}
            disabled={!formValid || generationInProgress}
          >
            Download PDF
          </FluidButton>
        </div>
      </div>
    </div>
  );
});

interface IPanelProps {
  className?: string;
  text: string;
  children?: React.ReactNode;
}

const Panel: React.FC<IPanelProps> = props => (
  <div className={classNames(props.className, 'panel')}>
    <label className="inline-block text-base font-pt-sans font-bold tracking-2xs leading-21px mb-10px">
      {props.text}
    </label>
    <div>{props.children}</div>
  </div>
);

interface ILabelProps {
  className?: string;
  text: string;
  children?: React.ReactNode;
}

const Label: React.FC<ILabelProps> = props => {
  return (
    <label className={classNames(props.className, 'label')}>
      <span className="inline-block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">
        {props.text}
      </span>
      {props.children}
    </label>
  );
};

interface ISelectProps {
  className?: string;
  options: IValueLabelPair[];
  onSelect: (string) => void;
  selected: string[];
}

const Select: React.FC<ISelectProps> = props => {
  const contentClass = 'font-pt-sans text-15px text-black';

  const handleSelect = useCallback(([selected]) => props.onSelect(selected), [props.onSelect]);

  return (
    <Multiselect
      className={classNames(props.className, 'select bg-ivory')}
      itemsClassname="bg-ivory"
      itemCtaClassName="hover:bg-teal-40"
      labelClassName={contentClass}
      itemContentClassName={contentClass}
      selectedItemContentClassName="font-bold"
      options={props.options}
      isCloseOnSelect
      hideCheckboxes
      isSingleSelectMode
      onUpdate={handleSelect}
      selectedValues={props.selected}
    />
  );
};

interface IInputProps {
  className?: string;
  value: string | null;
  onChange: (string) => void;
}

const Input: React.FC<IInputProps> = props => {
  const handleChange = useCallback(e => props.onChange(e.target.value), [props.onChange]);

  return (
    <TextInput
      className={classNames(props.className, 'text-input h-37px')}
      inputClassName="bg-ivory"
      value={props.value || undefined}
      onChange={handleChange}
    />
  );
};

interface ITextAreaProps {
  className?: string;
  value: string | null;
  onChange: (string) => void;
}

const TextArea: React.FC<ITextAreaProps> = props => {
  const handleChange = useCallback(value => props.onChange(value), [props.onChange]);

  return (
    <UITextArea
      value={props.value || ''}
      onChange={handleChange}
      className={classNames(props.className, 'min-h-80px')}
    />
  );
};

interface ISeparatorProps {
  className?: string;
}

const Separator: React.FC<ISeparatorProps> = props => (
  <div className={classNames(props.className, 'separator min-h-1px w-full bg-gray-20')} />
);

interface IEditButtonProps {
  className?: string;
  loading: boolean;
  disabled: boolean;
}

const EditButton: React.FC<IEditButtonProps> = props => (
  <button
    className={classNames(
      props.className,
      'edit h-50px w-50px bg-brown-100 rounded-full border-0 flex items-center justify-center',
      props.disabled ? 'cursor-default' : 'cursor-pointer'
    )}
    disabled={props.disabled}
  >
    <i className={classNames('text-white text-xl', props.loading ? 'fas fa-circle-notch fa-spin' : 'fas fa-pen')} />
  </button>
);
