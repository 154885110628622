import { call, takeLatest, select, put, all } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBookingManagerApi } from 'services/BookingManagerApi';

import { bookingUuidSelector } from '../../selectors';
import {
  GET_GUEST_INFORMATION_REQUEST,
  GET_AIRLINES_REQUEST,
  getGuestInformationFailureAction,
  getGuestInformationSuccessAction,
  SAVE_GUEST_INFORMATION_REQUEST,
  saveGuestInformationFailureAction,
  SaveGuestInformationRequestAction,
  saveGuestInformationSuccessAction,
  getAirlinesSuccessAction,
  getAirlinesFailureAction,
} from './actions';
import { enqueueNotification } from 'store/modules/ui/actions';
import { getTopNavigationDataRequestAction } from '../dashboard/actions';
import { CONST_AIRLINE_OTHER_VALUE } from '../../../../../services/BackendApi/types/GuestInformationResponse';
import { isStringNilOrBlank, isNil } from 'utils/string';

export function* getAirlinesRequestSaga() {
  const bookingManagerApi = makeBookingManagerApi();
  const bookingUuid = yield select(bookingUuidSelector);
  try {
    const result: AxiosResponse = yield call(bookingManagerApi.getAirlines, bookingUuid);

    if (result.data.airlineList) {
      yield put(getAirlinesSuccessAction(result.data.airlineList));
    }
  } catch (e) {
    yield put(getAirlinesFailureAction('There was an error loading airlines data'));
    yield put(
      enqueueNotification({
        message: 'There was an error loading airlines data',
        options: { variant: 'error' },
      })
    );
  }
}

export function* getGuestInformationSaga() {
  const bookingManagerApi = makeBookingManagerApi();
  const bookingUuid = yield select(bookingUuidSelector);
  try {
    const result: AxiosResponse = yield call(bookingManagerApi.getGuestInformation, bookingUuid);
    yield put(getGuestInformationSuccessAction(result.data ? result.data.guests : null));
  } catch (e) {
    yield put(getGuestInformationFailureAction('Failed to load guest details'));
    yield put(
      enqueueNotification({
        message: 'There was an error getting data',
        options: { variant: 'error' },
      })
    );
  }
}

export function* saveGuestInformationSaga(action: SaveGuestInformationRequestAction) {
  const bookingManagerApi = makeBookingManagerApi();
  const bookingUuid = yield select(bookingUuidSelector);
  try {
    let guests = action.guests.map(guest => {
      // if we've deleted all of them, remove the custom ones too
      if (isNil(guest.arrivalAirlineName) && isNil(guest.arrivalAirlineIataCode)) {
        delete guest.customArrivalAirlineName;
        delete guest.customArrivalAirlineIataCode;
      }
      if (isNil(guest.departureAirlineName) && isNil(guest.departureAirlineIataCode)) {
        delete guest.customDepartureAirlineName;
        delete guest.customDepartureAirlineIataCode;
      }

      // if we've set custom ones, use them to populate the "real" ones
      // and delete the custom ones
      if (
        !isStringNilOrBlank(guest.customArrivalAirlineIataCode) &&
        !isStringNilOrBlank(guest.customArrivalAirlineName)
      ) {
        guest.arrivalAirlineName = guest.customArrivalAirlineName;
        guest.arrivalAirlineIataCode = guest.customArrivalAirlineIataCode;
      }
      if (
        !isStringNilOrBlank(guest.customDepartureAirlineIataCode) &&
        !isStringNilOrBlank(guest.customDepartureAirlineName)
      ) {
        guest.departureAirlineName = guest.customDepartureAirlineName;
        guest.departureAirlineIataCode = guest.customDepartureAirlineIataCode;
      }
      delete guest.customArrivalAirlineName;
      delete guest.customArrivalAirlineIataCode;
      delete guest.customDepartureAirlineName;
      delete guest.customDepartureAirlineIataCode;

      // and if, after all of it, they're set to other, delete it all
      // (this would be if they chose OTHER then didnt fill anything out)
      if (guest.arrivalAirlineIataCode === CONST_AIRLINE_OTHER_VALUE) {
        delete guest.arrivalAirlineIataCode;
        delete guest.arrivalAirlineName;
      }
      if (guest.departureAirlineIataCode === CONST_AIRLINE_OTHER_VALUE) {
        delete guest.departureAirlineIataCode;
        delete guest.departureAirlineName;
      }
      return guest;
    });

    guests = guests.map(g => {
      return {
        ...g,
        // @ts-ignore
        manualAge: g.manualAge ? parseInt(g.manualAge) : null,
      };
    });

    // fix for https://pureescapes.atlassian.net/browse/OWA-4476 see ticket for context
    guests = JSON.parse(JSON.stringify(guests));

    const result: AxiosResponse = yield call(bookingManagerApi.saveGuestInformation, bookingUuid, guests);
    yield put(saveGuestInformationSuccessAction(result.data ? result.data.guests : null));
    // top navigation data could have been changed if the user changed the Lead guest name
    yield put(getTopNavigationDataRequestAction());
    yield put(
      enqueueNotification({
        message: 'Data saved successfully',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(saveGuestInformationFailureAction(e));
    const errors = e?.response?.data?.errors ?? [];
    yield put(
      enqueueNotification({
        message: errors[0]?.message ?? 'There was an error saving data',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchGuestInformationSaga() {
  yield all([
    takeLatest([GET_GUEST_INFORMATION_REQUEST], getGuestInformationSaga),
    takeLatest([SAVE_GUEST_INFORMATION_REQUEST], saveGuestInformationSaga),
    takeLatest([GET_AIRLINES_REQUEST], getAirlinesRequestSaga),
  ]);
}
