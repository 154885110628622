import styled from 'styled-components';
import { pureUiTheme } from 'pureUi/pureUiTheme';

export const ErrorList = styled.ul`
  margin: 0;
  padding: 10px;
  border: ${pureUiTheme.colorRoles.errorBorder} 1px solid;
  background-color: ${pureUiTheme.colorRoles.errorBackground};
  font-size: ${pureUiTheme.typography.sizes.less};
  margin: 10px 0;
  li {
    list-style: none;
    color: ${pureUiTheme.colorRoles.errorText};
  }

  &:empty {
    visibility: hidden;
    padding: 0;
    margin: 0;
  }
`;
