import { update } from 'utils/immutable';
import { initialState, IExternalIdsSubdomain } from './model';
import * as Actions from './actions';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';

export const externalIdsReducer = (state: IExternalIdsSubdomain = initialState, action: Actions.Any) => {
  switch (action.type) {
    case Actions.GET_REQUEST:
      return update(state, draftState => draftState.request.get = ENetworkRequestStatus.PENDING);

    case Actions.GET_SUCCESS:
      return update(state, draftState => {
        draftState.request.get = ENetworkRequestStatus.SUCCESS;
        draftState.stored = action.value;
        draftState.editing.travelPartnerRef = action.value.travelPartnerRef || '';
      });
      
    case Actions.GET_FAILURE:
      return update(state, draftState => draftState.request.get = ENetworkRequestStatus.ERROR);

    case Actions.UPDATE_REQUEST:
      return update(state, draftState => draftState.request.patch = ENetworkRequestStatus.PENDING);

    case Actions.UPDATE_SUCCESS:
      return update(state, draftState => {
        draftState.request.patch = ENetworkRequestStatus.SUCCESS;
        draftState.stored = action.value;
        draftState.editing.travelPartnerRef = action.value.travelPartnerRef || '';
      });

    case Actions.UPDATE_FAILURE:
      return update(state, draftState => draftState.request.patch = ENetworkRequestStatus.ERROR);
    
    case Actions.SET_TRAVEL_PARTNER_REF:
      return update(state, draftState => draftState.editing.travelPartnerRef = action.value);
    
    default:
      return state;
  }
};
