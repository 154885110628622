import { initialState, IChecklistSubdomain } from './model';
import * as Actions from './actions';
import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';

const checklistReducer = (state: IChecklistSubdomain = initialState, action: Actions.ChecklistAction) => {
  switch (action.type) {
    case Actions.GET_CHECKLIST_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.checklistLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_CHECKLIST_SUCCESS:
      return produce(state, draftState => {
        draftState.checklist = action.checklist;
        draftState.networkRequests.checklistLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_CHECKLIST_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.checklistLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.UPDATE_CHECKLIST_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.checklistUpdate = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.UPDATE_CHECKLIST_SUCCESS:
      return produce(state, draftState => {
        draftState.checklist = action.checklist;
        draftState.networkRequests.checklistUpdate = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.UPDATE_CHECKLIST_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.checklistUpdate = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    default:
      return state;
  }
};

export default checklistReducer;
