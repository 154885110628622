import { ENetworkRequestStatus } from 'services/BackendApi';
import {
  IOwnershipTravelAgent,
  IOwnershipManagedBy,
 } from 'services/BookingManagerApi';
 import {
  ISalesRepresentative
 } from 'services/BackendApi';

export enum EBookingSettingsSelectedValues {
  SR = 'selectedManagedByUuid',
  COMPANY = 'selectedTaCompanyUuid',
  TA = 'selectedTaUserUuid'
}

export interface ISettingsSubdomain {
  taUsers: IOwnershipTravelAgent[];
  assignedSalesRepresentatives: ISalesRepresentative[];
  managedBy: Partial<IOwnershipManagedBy>[];
  selectedTaCompanyUuid: string | null;
  selectedTaUserUuid?: string | null;
  hasEditedWithoutSaving: boolean;
  taMovementsModalOpen: boolean;
  network: {
    getTaUsers: ENetworkRequestStatus;
  };
}

export const initialState: ISettingsSubdomain = {
  taUsers: [],
  assignedSalesRepresentatives: [],
  managedBy: [],
  selectedTaCompanyUuid: null,
  selectedTaUserUuid: undefined,
  hasEditedWithoutSaving: false,
  taMovementsModalOpen: false,
  network: {
    getTaUsers: ENetworkRequestStatus.IDLE,
  },
};
