import countries from 'config/data/countries.json';

export const countryOptions = Object.values(countries).map(country => ({
  value: country.code,
  label: country.name,
}));

export enum EInternalUserFormMode {
  CREATE = 'create',
  EDIT = 'edit',
} 
