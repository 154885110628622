import React, { useState, useEffect } from 'react';
import { EImportEntity, IImportModel } from '../../store/modules/importer/model';
import { ancillarySuppliersImportDomainSelector } from '../../store/modules/ancillarySuppliersImport/selectors';
import { MainStyles } from '../../pureUi/Importer/styles';
// import ConfirmationModal from '../../pureUi/Importer/components/ConfirmationModal';
import { ConfirmationModal, EConfirmationModalType } from '../../ui/ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import * as ImportActions from '../../store/modules/importer/actions';
import FluidButton from 'ui/FluidButton';
import { theme } from '../../../tailwind.config';
import ClockIcon from 'ui/Icons/clock.component.svg';
import { SvgIcon } from 'ui/SvgIcon';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { formatDateTimeDisplayWithoutOffset } from 'utils';
import { isNil } from 'lodash-es';

enum eImportStatus {
  COMPLETED = 'completed',
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed',
}

interface IImportStatusObject {
  workbookId: string;
  job?: {
    jobId: number;
    status: eImportStatus;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
    totalRows: number;
    failures?: IImportFailure[];
  };
}

interface IImportFailure {
  row: number;
  errors: string[];
}

const ImportStatusToLabelMap = {
  [eImportStatus.COMPLETED]: 'Success',
  [eImportStatus.PENDING]: 'Pending',
  [eImportStatus.IN_PROGRESS]: 'In Progress',
  [eImportStatus.FAILED]: 'Fail',
};

const ImportStatusToColorMap = {
  [eImportStatus.COMPLETED]: theme.colors['status-confirmed'],
  [eImportStatus.PENDING]: theme.colors['yellow-30'],
  [eImportStatus.IN_PROGRESS]: theme.colors['yellow-30'],
  [eImportStatus.FAILED]: theme.colors['red-95'],
};

const MessagePanel = ({ message, domainName }: { message: string; domainName: string }) => {
  return (
    <div className="bg-ivory px-20px py-15px flex flex-col space-y-10px rounded-[4px]">
      {/* status and label */}
      <p className="font-pt-sans text-17px leading-22px">
        <span className="font-bold text-black">Status:</span>
        <span
          style={{
            color: ImportStatusToColorMap[eImportStatus.FAILED],
          }}
          className="ml-2 font-bold"
        >
          Error
        </span>
      </p>
      {/* row and error count */}
      <p className="flex font-pt-sans text-17px leading-22px items-center">
        <span
          style={{
            borderColor: ImportStatusToColorMap[eImportStatus.FAILED],
          }}
          className="border-l-[5px] pl-2 text-black"
        >
          {domainName.toLocaleLowerCase()}
        </span>
        <span className="ml-2 text-gray-100">{message}</span>
      </p>
    </div>
  );
};

const StatusPanel = ({ statusObject, domainName }: { statusObject: IImportStatusObject; domainName: string }) => {
  const { job } = statusObject;

  if (!job) {
    return null;
  }

  const rowZeroErrors = (job.failures || []).filter(failure => failure.row === 0).flatMap(r => r.errors);
  const otherRows = (job.failures || []).filter(failure => failure.row !== 0);

  return (
    <div className="bg-ivory px-20px py-15px flex flex-col space-y-10px rounded-[4px]">
      {/* status and label */}
      <p className="font-pt-sans text-17px leading-22px">
        <span className="font-bold text-black">Status:</span>
        <span
          style={{
            color: ImportStatusToColorMap[job.status],
          }}
          className="ml-2 font-bold"
        >
          {ImportStatusToLabelMap[job.status]}
        </span>
      </p>
      {/* row and error count */}
      <p className="flex font-pt-sans text-17px leading-22px items-center">
        <span
          style={{
            borderColor: ImportStatusToColorMap[job.status],
          }}
          className="border-l-[5px] pl-2 text-black"
        >
          {domainName.toLocaleLowerCase()}
        </span>
        {job.status === eImportStatus.PENDING && <span className="ml-2 text-gray-100">The import is pending</span>}
        {job.status === eImportStatus.IN_PROGRESS && (
          <span className="ml-2 text-gray-100">The import is in progress</span>
        )}
        {job.status === eImportStatus.COMPLETED && (
          <span className="ml-2 text-gray-100">{job.totalRows} insertable</span>
        )}
        {job.status === eImportStatus.FAILED && (
          <>
            <span className="ml-2 text-gray-100">{job.totalRows} insertable</span>
            {otherRows.length > 0 && <span className="ml-2 text-gray-100">| {otherRows.length} failed rows</span>}
          </>
        )}
      </p>

      {/* listing out if theres a generic failure */}
      {job.status === eImportStatus.FAILED && rowZeroErrors.length > 0 && (
        <div className="flex flex-col">
          <span className="font-pt-sans text-15px leading-22px italic text-red-95">Generic Failure:</span>
          <ul className="list-none list-inside m-0">
            {rowZeroErrors.map(error => (
              <li key={error} className="font-pt-sans text-15px leading-22px text-black">
                {error}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* listing out failures */}
      {job.status === eImportStatus.FAILED &&
        otherRows.map(row => {
          return (
            <div key={row.row} className="flex flex-col">
              <span className="font-pt-sans text-15px leading-22px italic text-red-95">Row {row.row}:</span>
              <ul className="list-none list-inside m-0">
                {row.errors.map(error => (
                  <li key={error} className="font-pt-sans text-15px leading-22px text-black">
                    {error}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
    </div>
  );
};

const jobDoneOrInProgress = (
  importConfirmedWaitingForLatestStatus: boolean,
  latestStatus: eImportStatus
): 'Done' | 'In Progress' => {
  if (
    importConfirmedWaitingForLatestStatus ||
    latestStatus === eImportStatus.PENDING ||
    latestStatus === eImportStatus.IN_PROGRESS
  ) {
    return 'In Progress';
  } else {
    return 'Done';
  }
};

export interface iAncillaryImporterTemplateContainerProps {
  importerEntity: EImportEntity;
  importerData: IImportModel;
  importerDomainName: string;
  importerPageTitle?: JSX.Element;
}

/**
 * You shouldn't render this component directly at a route level.
 * Instead, make use of it as a child component in a route.
 * See src/containers/AncillarySuppliers/importer.tsx for an example.
 */
export const AncillaryImporterTemplateContainer = (props: iAncillaryImporterTemplateContainerProps) => {
  const { importerEntity, importerData, importerDomainName, importerPageTitle } = props;
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const dispatch = useDispatch();

  const { latestStatus, importConfirmedWaitingForLatestStatus, error, message } = (importerData as unknown) as {
    latestStatus: IImportStatusObject | null;
    importConfirmedWaitingForLatestStatus: boolean;
    error: string | null;
    message?: string;
  };

  useEffect(() => {
    dispatch(ImportActions.importPageLoaded(importerEntity));
    return () => {
      dispatch(ImportActions.importPageUnloaded(importerEntity));
    };
  }, []);

  const workbookUrl = latestStatus?.workbookId
    ? `https://docs.google.com/spreadsheets/d/${latestStatus.workbookId}/edit`
    : null;

  return (
    <div className="container w-full max-w-1280px mx-auto space-y-20px">
      <div>
        <div className="flex justify-between items-center mb-5">
          {importerPageTitle && <>{importerPageTitle}</>}

          <div className="flex space-x-10px text-right">
            {latestStatus && latestStatus.job && (
              <span className="flex items-center space-x-1 font-pt-sans text-15px text-gray-100 leading-19px">
                <SvgIcon
                  className="block w-[20px] h-[20px] "
                  IconComponent={ClockIcon}
                  width="20px"
                  height="20px"
                  defaultFill={theme.colors['gray-80']}
                  hoverFill={theme.colors['gray-80']}
                  activeFill={theme.colors['gray-80']}
                />
                <span>
                  Latest Import:{' '}
                  <span className="uppercase">
                    {jobDoneOrInProgress(importConfirmedWaitingForLatestStatus, latestStatus.job.status)}
                  </span>{' '}
                  {importConfirmedWaitingForLatestStatus === false
                    ? `(${formatDateTimeDisplayWithoutOffset(latestStatus.job.updatedAt)})`
                    : ''}
                </span>
              </span>
            )}
            <FluidButton
              className="self-end"
              onClick={() => {
                setConfirmationModalOpen(true);
              }}
              type="primary"
              disabled={
                importConfirmedWaitingForLatestStatus ||
                latestStatus?.job?.status === eImportStatus.IN_PROGRESS ||
                latestStatus?.job?.status === eImportStatus.PENDING
              }
            >
              Import
            </FluidButton>
          </div>
        </div>

        {error && <ErrorBar message={error} />}
        {error === null && latestStatus === null && <LoadingBar />}

        {message && <MessagePanel message={message} domainName={importerDomainName} />}
        {isNil(message) && latestStatus && latestStatus.job && (
          <StatusPanel
            domainName={props.importerDomainName}
            statusObject={{
              ...latestStatus,
              job: {
                ...latestStatus.job,
                // if we're waiting on a status after kicking off an import, show it as pending
                status: importConfirmedWaitingForLatestStatus ? eImportStatus.PENDING : latestStatus.job.status,
              },
            }}
          />
        )}

        {confirmationModalOpen && (
          <ConfirmationModal
            type={EConfirmationModalType.WARNING}
            className="confirm-import-modal"
            title="This operation takes usually around 1 minute. It cannot be cancelled before completion and it is irreversible."
            message={`Are you sure you want to Import ${importerDomainName}?`}
            confirmButtonLabel="Yes"
            cancelButtonLabel="No"
            onConfirm={() => {
              dispatch(ImportActions.confirmImportIntent(importerEntity));
              setConfirmationModalOpen(false);
            }}
            onCancel={() => {
              setConfirmationModalOpen(false);
            }}
            isConfirmLoading={false}
          />
        )}
      </div>

      {workbookUrl && (
        <div className="editor">
          <iframe className="w-full h-[100vh] border-0" src={workbookUrl} />
        </div>
      )}
    </div>
  );
};
