import React, { FC, useCallback } from 'react';
import { formatCreditNoteDateDisplay } from 'utils';
import CreditNoteWithDate from 'ui/Icons/CreditNoteWithDate.component.svg';
import CreditNote from 'ui/Icons/CreditNote.component.svg';
import { HidingTooltip } from 'ui/Tooltip';
import { SvgIcon } from 'ui/SvgIcon';

interface ICreditNoteIndicatorProps {
  creditNoteExpiryDate: string | null;
  isCreditNoteSpecificToHotel: boolean | null;
  isCreditNoteSpecificToGuest: boolean | null;
}

export const CreditNoteIndicator: FC<ICreditNoteIndicatorProps> = ({
  creditNoteExpiryDate,
  isCreditNoteSpecificToGuest,
  isCreditNoteSpecificToHotel,
}) => {
  const renderTooltip = useCallback(() => {
    let specificity = <></>;
    if (isCreditNoteSpecificToGuest) {
      specificity = (
        <>
          {' '}
          specific to the <span className="font-bold">booking guest</span>
        </>
      );
    }
    if (isCreditNoteSpecificToHotel) {
      specificity = (
        <>
          {' '}
          specific to <span className="font-bold">hotel</span>
        </>
      );
    }

    const expiration = creditNoteExpiryDate ? (
      <>
        Expiration:
        <br />
        <span className="text-brown-100">{formatCreditNoteDateDisplay(creditNoteExpiryDate)}</span>
      </>
    ) : (
      <>No expiration.</>
    );

    return (
      <div
        className="credit-note-tooltip font-pt-sans text-13px leading-17px text-black px-10px py-5px"
        style={{ width: '102px' }}
      >
        Credit Note{specificity}.<br />
        {expiration}
      </div>
    );
  }, [creditNoteExpiryDate, isCreditNoteSpecificToGuest, isCreditNoteSpecificToHotel]);

  return (
    <HidingTooltip renderTooltipContent={renderTooltip} position="bottom">
      {creditNoteExpiryDate ? (
        <CreditNoteWithDate />
      ) : (
        <SvgIcon className="fill-brown-80 w-24px" IconComponent={CreditNote} />
      )}
    </HidingTooltip>
  );
};
