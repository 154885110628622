import { produce } from 'immer';

import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import * as OwnershipActions from 'store/modules/bookingManager/subdomains/ownership/actions';
import { initialState, ISettingsSubdomain } from './model';
import * as Actions from './actions';

const settingsReducer = (state: ISettingsSubdomain = initialState, action: Actions.SettingsAction | OwnershipActions.OwnershipAction) => {
  switch (action.type) {
    case Actions.GET_TA_USERS_REQUEST:
      return produce(state, draftState => {
        draftState.network.getTaUsers = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_TA_USERS_SUCCESS:
      return produce(state, draftState => {
        draftState.network.getTaUsers = ENetworkRequestStatus.SUCCESS;
        draftState.taUsers = action.taUsers;
        return draftState;
      });

    case Actions.GET_TA_USERS_FAILURE:
      return produce(state, draftState => {
        draftState.network.getTaUsers = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_MANAGED_BY_UUID:
      return produce(state, draftState => {
        const sr = draftState
          .assignedSalesRepresentatives
          .find(x => x.uuid === action.managedByUuid);

        if(sr) {
          draftState.managedBy[action.idx].uuid = sr.uuid;
          draftState.managedBy[action.idx].email = sr.email;
          draftState.hasEditedWithoutSaving = true;
        }
        return draftState;
      });

    case Actions.SET_MANAGED_BY_DESCRIPTION:
      return produce(state, draftState => {
        draftState.managedBy[action.idx].description = action.description;
        draftState.hasEditedWithoutSaving = true;
        return draftState;
      });

    case Actions.ADD_MANAGED_BY:
      return produce(state, draftState => {
        draftState.managedBy = draftState.managedBy || [];
        draftState.managedBy.push({});
        return draftState;
      });
    
    case Actions.REMOVE_MANAGED_BY:
      return produce(state, draftState => {
        draftState.managedBy.splice(action.idx, 1);
        draftState.hasEditedWithoutSaving = true;
        return draftState;
      });

    case Actions.SET_SELECTED_TA_COMPANY_UUID:
      return produce(state, draftState => {
        draftState.selectedTaCompanyUuid = action.taCompanyUuid;
        return draftState;
      });

    case Actions.SET_SELECTED_TA_USER_UUID:
      return produce(state, draftState => {
        draftState.selectedTaUserUuid = action.taUserUuid;
        draftState.hasEditedWithoutSaving = true;
        return draftState;
      });

    case Actions.CLEAR_SELECTED_VALUES_UUID:
      return produce(state, draftState => {
        if (!action.values) {
          draftState.hasEditedWithoutSaving = false;
          draftState.managedBy = [];
          draftState.selectedTaUserUuid = null;
          draftState.selectedTaCompanyUuid = null;
        } else {
          action.values.forEach(val => {
            draftState[val] = null;
          });
        }
        return draftState;
      });

    case Actions.OPEN_TA_MOVEMENTS_MODAL:
      return produce(state, draftState => {
        draftState.taMovementsModalOpen = true;
        return draftState;
      });

    case Actions.CLOSE_TA_MOVEMENTS_MODAL:
      return produce(state, draftState => {
        draftState.taMovementsModalOpen = false;
        return draftState;
      });

    case OwnershipActions.GET_OWNERSHIP_SUCCESS:
      return produce(state, draftState => {
        draftState.managedBy = !action.ownershipData.managedBy?.length
          ? [{}]
          : action.ownershipData.managedBy;
        return draftState;
      });

    case OwnershipActions.UPDATE_OWNERSHIP_SUCCESS:
      return produce(state, draftState => {
        draftState.hasEditedWithoutSaving = false;
        draftState.managedBy = action.ownershipData.managedBy || [];
        draftState.selectedTaUserUuid = null;
        draftState.selectedTaCompanyUuid = null;
        return draftState;
      });
    
    case OwnershipActions.GET_ASSIGNED_SALES_REPRESENTATIVES_SUCCESS:
      return produce(state, draftState => {
        draftState.assignedSalesRepresentatives = action.salesRepresentatives;
        
        const set = new Set(action.salesRepresentatives.map(x => x.uuid));
        draftState.managedBy = draftState.managedBy.filter(
          x => !x.uuid || set.has(x.uuid)
        );

        return draftState;
      });

    default:
      return state;
  }
};

export default settingsReducer;
