import { auth, page, notFound, user, root } from 'routing/common';

export default [
  ...root,

  // Order is important here
  ...auth,
  ...user,

  // Page is a catch all /:path
  ...page,
  ...notFound,
];
