import React, { useCallback } from 'react';
import FluidButton from 'ui/FluidButton';
import UserIcon from 'ui/Icons/person-alt.component.svg';
import { formatDateDisplayFourDigitYear, formatPrice, getCurrencyBySymbol, numberOfNights, rateStringToCents } from 'utils';
import classNames from 'classnames';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { isNil, sortBy } from 'lodash-es';
import CalendarIcon from 'ui/Icons/calendar-today.component.svg';
import { SvgIcon } from 'ui/SvgIcon';

import { BookingBuilderResponse, IHotelAccommodationsSearchRequest, ProductSetAccommodation } from 'services/BackendApi';
import { useDispatch, useSelector } from 'react-redux';
import { BAMBookingBuildRequestSelector, BAMEndDateFilterSelector, BAMLastRequestSelector, BAMSelectedOccasionsSelector, BAMSelectedRepeatGuestSelector, BAMStartDateFilterSelector } from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import { MealPlanDropdown } from './MealPlanDropdown/MealPlanDropdown';
import { IStaticRate } from './MealPlanDropdown/types';
import { RHPSubtitle } from './RHPSubtitle';
import { Multiselect } from 'ui/Multiselect';
import { bamBookingBuildRequestAction, setBAMSelectedMealPlanAction, setBAMSelectedOccasionsAction, setBAMSelectedRepeatGuestAction } from 'store/modules/bookingManager/subdomains/breakdown/actions';
import Checkbox from 'ui/Checkbox';
import { ITopNavigationData } from 'services/BookingManagerApi';
import { AddAncillaryRightHandPanelTotalCost } from 'ui/AddAncillarySharedComponents/AddAncillaryRightHandPanelTotalCost';
import { convertMealPlanToStaticRate } from './helpers';

const GuestAges: React.FC<{ lastSearchRequest: IHotelAccommodationsSearchRequest }> = ({ lastSearchRequest }) => {
  return (
    <div className="guest-ages flex items-center mt-[5px]">
      <UserIcon className="w-[16px] h-[16px] stroke-black fill-black" />
      <span className="ml-[5px]">x{lastSearchRequest?.numberOfAdults}</span>
      {lastSearchRequest?.agesOfAllChildren &&
        lastSearchRequest.agesOfAllChildren.length >= 1 && (
          <>
            <UserIcon className="w-[12px] h-[12px] stroke-black fill-black" />
            <span className="ml-[5px]">x{lastSearchRequest.agesOfAllChildren.length}</span>
            <span>&nbsp;({sortBy(lastSearchRequest.agesOfAllChildren).join(', ')}&nbsp;years)</span>
          </>
        )}
    </div>
  )
}

const occasionsMultiselectOptions = [
  { value: 'honeymoon', label: 'Honeymoon' },
  { value: 'birthday', label: 'Birthday' },
  { value: 'anniversary', label: 'Anniversary' },
  { value: 'wedding', label: 'Wedding' },
];

export interface IAccommodationRightHandPanelProps {
  selectedBuild: BookingBuilderResponse;
  currencySymbol: string;
  bookingUuid: string;
  topNavigationData: ITopNavigationData;
  loadingStatus: ENetworkRequestStatus;
}

export const AccommodationRightHandPanel: React.FC<IAccommodationRightHandPanelProps> = ({ currencySymbol, selectedBuild, loadingStatus, topNavigationData, bookingUuid }) => {
  const dispatch = useDispatch();
  const lastSearchRequest = useSelector(BAMLastRequestSelector);
  const startDate = useSelector(BAMStartDateFilterSelector);
  const endDate = useSelector(BAMEndDateFilterSelector);
  const bookingBuildRequest = useSelector(BAMBookingBuildRequestSelector);
  const selectedAccommodation = selectedBuild.availableProductSets.Accommodation[0];
  const mealPlanOptions: IStaticRate[] = selectedAccommodation?.availableSubProductSets['Meal Plan'].map(
    item => convertMealPlanToStaticRate(item)
  ) as unknown as IStaticRate[];
  const selectedMealPlan = selectedAccommodation?.availableSubProductSets['Meal Plan'].find(
    item => item.selected
  );
  const selectedOccasions = useSelector(BAMSelectedOccasionsSelector);
  const isRepeatGuest = useSelector(BAMSelectedRepeatGuestSelector);
  const rightPanelClassname = 'right-panel font-pt-sans border-solid border-1 border-gray-40 rounded-[4px] p-20px flex-[260] flex flex-col justify-between w-[321px] space-y-20px';
  
  const handleMealPlanSelect = useCallback((mealPlan: IStaticRate) => {
    dispatch(setBAMSelectedMealPlanAction(mealPlan.mealPlan.uuids));
    dispatch(bamBookingBuildRequestAction(bookingUuid));
  }, [dispatch, bookingUuid]);
  
  const handleOccasionsSelect = useCallback(selectedValues => {
    dispatch(
      setBAMSelectedOccasionsAction(selectedValues)
    );
    dispatch(bamBookingBuildRequestAction(bookingUuid));
  }, [dispatch, bookingUuid, selectedMealPlan]);

  const handleRepeatGuestSelect = useCallback(e => {
    dispatch(setBAMSelectedRepeatGuestAction(e.target.checked));
    dispatch(bamBookingBuildRequestAction(bookingUuid));
  }, [dispatch, bookingUuid, selectedMealPlan]);

  if (!selectedMealPlan || !selectedAccommodation) {
    return (
      <div className={rightPanelClassname}>
        <ErrorBar message="Something went wrong" />
      </div>
    )
  }

  return (
    <div className={rightPanelClassname}>
      {loadingStatus === ENetworkRequestStatus.PENDING && <LoadingBar />}

      {loadingStatus === ENetworkRequestStatus.ERROR && <ErrorBar />}

      {loadingStatus === ENetworkRequestStatus.SUCCESS && !isNil(selectedAccommodation) && (
        <React.Fragment>
          <div className="your-choice rhp-top-part flex flex-col ">
            <span className="text-xs text-gray-100 uppercase">Your Choice</span>
            <span className="mt-[5px] text-17px leading-22px text-black font-bold">
              {topNavigationData.hotelName} - {selectedAccommodation.products[0].name}
            </span>
            {lastSearchRequest && <GuestAges lastSearchRequest={lastSearchRequest} />}
            
            <span className="dates flex mt-[5px] justify-between items-center space-x-2 w-full">
              <div className="flex items-center gap-[5px]">
                <SvgIcon width="16px" height="16px" IconComponent={CalendarIcon} defaultFill="#413E3B" />
                <span>{formatDateDisplayFourDigitYear(startDate)} - {formatDateDisplayFourDigitYear(endDate)}</span>
              </div>
              <span className="flex justify-center items-center bg-teal-40 px-[10px] h-[27px]">{numberOfNights(startDate, endDate)}&nbsp;N</span>
            </span>

            <div className="flex flex-col gap-[5px] mt-[20px]">
              <RHPSubtitle>Meal Plan</RHPSubtitle>
              {bookingBuildRequest === ENetworkRequestStatus.PENDING ? (
                <div className="min-h-[35px] mt-[5px]">Loading...</div>
              ) : (
                <MealPlanDropdown
                  value={convertMealPlanToStaticRate(selectedMealPlan)}
                  options={mealPlanOptions}
                  currency={getCurrencyBySymbol(currencySymbol).code}
                  onChoose={handleMealPlanSelect}
                  maxVisibleItems={5}
                  className="mt-[5px]"
                />
              )}
            </div>

            <div className="flex flex-col gap-[5px] mt-[20px]">
              <RHPSubtitle>Occasion</RHPSubtitle>
              <Multiselect
                className=" mt-5px bg-ivory w-full"
                onUpdate={handleOccasionsSelect}
                itemsClassname="bg-ivory"
                itemCtaClassName="hover:bg-gray-10"
                options={occasionsMultiselectOptions}
                selectedValues={selectedOccasions}
                placeholderText="None"
                placeholderClasses="text-black"
              />
            </div>

            <div className="checkbox mt-[20px]">
              <label className="flex items-center justify-start text-black cursor-pointer">
                <Checkbox
                  checked={isRepeatGuest}
                  onChange={handleRepeatGuestSelect}
                />
                &nbsp;&nbsp;Repeat Guest
              </label>
            </div>
          </div>

          {bookingBuildRequest === ENetworkRequestStatus.PENDING && <LoadingBar />}
          {bookingBuildRequest !== ENetworkRequestStatus.PENDING && (
            <div className="rhp-bottom-part space-y-10px pt-10px">

              <AddAncillaryRightHandPanelTotalCost
                price={selectedBuild.totals.total}
                isOnRequest={selectedBuild.totals.oneOrMoreItemsOnRequest}
                currencySymbol={currencySymbol}
              />

              <FluidButton
                onClick={() => {
                  // props.handleAddProduct();
                }}
                className="block w-full"
                type="primary"
              >
                Continue &gt;
              </FluidButton>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
