import { call, takeLatest, select, put } from 'redux-saga/effects';
import {
  UpdateCompanyRequestAction,
  updateCompanySuccessAction,
  updateCompanyFailureAction,
  UPDATE_COMPANY_REQUEST,
  fetchCompanyDataRequestAction,
} from '../actions';
import { ICompanyResponse, makeBackendApi } from 'services/BackendApi';
import { selectedTaSelector } from '../../agents';
import { enqueueNotification } from '../../ui';
import { AxiosResponse } from 'axios';

export function* updateCompanySaga(action: UpdateCompanyRequestAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);

    const companyResponse: AxiosResponse<ICompanyResponse> = yield call(backendApi.updateCompany, action.companyUuid, action.company);
    yield put(updateCompanySuccessAction(companyResponse.data.data));
    yield put(
      enqueueNotification({
        message: 'Company information updated',
        options: { variant: 'success' },
      })
    );
    yield put (fetchCompanyDataRequestAction(action.companyUuid))
  } catch (e) {
    yield put(updateCompanyFailureAction(e));
    const errors = e.response.data?.errors ?? [];
    for (let error of errors) {
      yield put(
        enqueueNotification({
          message: error.detail,
          options: { variant: 'error' },
        })
      );
    }
  }
}

export function* watchUpdateCompanySaga() {
  yield takeLatest(UPDATE_COMPANY_REQUEST, updateCompanySaga);
}
