import { AxiosResponse } from 'axios';
import { call, takeLatest, put, delay } from 'redux-saga/effects';
import {
  fetchProductsWithCompanyHotelMarkupSuccessAction,
  fetchProductsWithCompanyHotelMarkupFailureAction,
  FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_REQUEST,
} from '../actions';
import { makeBackendApi } from 'services/BackendApi';

export function* fetchProductsWithCHMarkupSaga() {
  try {
    const backendApi = makeBackendApi();
    const result: AxiosResponse<{ productTypesWithCompanyHotelMarkup: { [key: string]: string }}> = yield call(
      backendApi.fetchProductsWithCompanyHotelMarkup,
    );
    const productTypesMap = result.data?.productTypesWithCompanyHotelMarkup ?? {};
    const productTypesWithCompanyHotelMarkups = Object.keys(productTypesMap)
      .filter(key => !!productTypesMap[key]);
    yield put(fetchProductsWithCompanyHotelMarkupSuccessAction(productTypesWithCompanyHotelMarkups));
  } catch (e) {
    yield put(fetchProductsWithCompanyHotelMarkupFailureAction(e));
  }
}

export function* watchFetchProductsWithCHMarkupSaga() {
  yield takeLatest([ FETCH_PRODUCTS_WITH_COMPANY_HOTEL_MARKUP_REQUEST ], fetchProductsWithCHMarkupSaga);
}
