import { takeLatest, select, put, take } from 'redux-saga/effects';

import {
  LOAD_STATE,
  LoadStateAction,
  getTravelAgentsRequestAction,
  GET_TRAVEL_AGENTS_SUCCESS,
  GET_TRAVEL_AGENTS_FAILURE,
  selectedTaChangeAction
} from '../actions';
import { agentsSelector } from '../selectors';

export function* loadStateSaga(action: LoadStateAction) {
  yield put(getTravelAgentsRequestAction());
  
  yield take([
    GET_TRAVEL_AGENTS_SUCCESS,
    GET_TRAVEL_AGENTS_FAILURE
  ]);
  
  const agents = yield select(agentsSelector);
  const found = (agents || []).find(x => x.uuid === action.travelAgentUuid);

  yield put(selectedTaChangeAction(found || null));
}

export function* watchLoadState() {
  yield takeLatest([LOAD_STATE], loadStateSaga);
}
