import React from 'react';

interface IHorizontalLineProps {
  text: string;
}

export const HorizontalLine: React.FC<IHorizontalLineProps> = React.memo(({ text }) => {

  return (
    <div className="flex items-center gap-[20px]">
      <hr className="flex-1 h-[1px] border-t border-solid border-gray-20" />
      <span className="font-hurmegeometric-sans text-gray-100 text-[12px] uppercase">{text}</span>
      <hr className="flex-1 h-[1px] border-t border-solid border-gray-20" />
    </div>
  );
});