import React from 'react';
import { Wrapper } from './RateMixingWarning.styles';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

interface IRateMixingWarningProps {
  isSr: boolean;
}

export const RateMixingWarning: React.FC<IRateMixingWarningProps> = ({ isSr }) => {
  const { dynamicParameters } = useDynamicParameters();
  const isLiveRatesEnabled =
    dynamicParameters.ENABLE_V1_LIVE_RATES || (dynamicParameters.ENABLE_V1_LIVE_RATES_SRS && isSr);
  if (!isLiveRatesEnabled) {
    return null;
  }

  return (
    <Wrapper className="rate-mixing-warning">
      You are unable to combine Live and Static rates in the basket.
      <br />
      Please select one or the other.
    </Wrapper>
  );
};
