import React from 'react';
import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const AdultIcon: React.FC<IIconProps> = React.memo(({ icon = theme.colors['gray-100'] }) => (
  <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.01196 0C1.88696 0 1.01196 0.792969 1.01196 1.75C1.01196 2.73438 1.88696 3.5 3.01196 3.5C4.10571 3.5 5.01196 2.73438 5.01196 1.75C5.01196 0.792969 4.10571 0 3.01196 0ZM4.51196 3.9375H4.13696C3.41821 4.23828 2.60571 4.23828 1.85571 3.9375H1.51196C0.668213 3.9375 0.0119629 4.53906 0.0119629 5.25V8.96875C0.0119629 9.35156 0.324463 9.625 0.761963 9.625H1.26196V13.3438C1.26196 13.7266 1.57446 14 2.01196 14H4.01196C4.41821 14 4.76196 13.7266 4.76196 13.3438V9.625H5.26196C5.66821 9.625 6.01196 9.35156 6.01196 8.96875V5.25C6.01196 4.53906 5.32446 3.9375 4.51196 3.9375Z"
      fill={icon}
    />
  </svg>
));

export default AdultIcon;
