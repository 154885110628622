import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENetworkRequestStatus, IHotel, IUploadFileInfo } from 'services/BackendApi';
import currencies from 'config/data/currencies.json';
import * as BootstrapSelectors from 'store/modules/bootstrap/selectors';
import * as Inputs from '../Inputs';
import { isUploadAnImage } from 'containers/HotelAdmin/utils';
import { Uploads } from '../Uploads';
import FluidButton from 'ui/FluidButton';
import { validateHotelDetails } from 'containers/HotelAdmin/validation';
import { enqueueNotification } from 'store/modules/ui';

export const HotelDetailsTab = ({
  hotel,
  onUpdate,
  filtersCategories,
  starRatings,
  onCta,
  networkRequest,
  setFeaturedPhoto,
  deleteUpload,
  mode,
  ctaLabel,
  onUploadImage,
  onUploadDocument,
}: {
  hotel: Partial<IHotel>;
  onUpdate: (field: keyof IHotel, value: any) => void;
  filtersCategories: any[];
  starRatings: string[];
  onCta: (updatedData: Partial<IHotel>) => void;
  networkRequest: ENetworkRequestStatus;
  setFeaturedPhoto: (featuredPhoto: IUploadFileInfo) => void;
  deleteUpload: (upload: IUploadFileInfo) => void;
  mode: 'create' | 'edit';
  ctaLabel: string;
  onUploadImage?: () => void;
  onUploadDocument?: () => void;
}) => {
  const bootstrapCountries = useSelector(BootstrapSelectors.getBootstrapCountriesSelector);

  const dispatch = useDispatch();
  const currenciesArray = useMemo(
    () =>
      Object.keys(currencies).map(key => {
        return {
          code: key,
          name: currencies[key].name,
          symbol: currencies[key].symbol,
        };
      }),
    [currencies]
  );
  const countriesOptions = bootstrapCountries.map(country => {
    return {
      value: country.code,
      label: country.name,
    };
  });

  const imageUploads = hotel.uploads?.filter(isUploadAnImage);
  const documentUploads = hotel.uploads?.filter(upload => !isUploadAnImage(upload));

  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Input label={'Name'} value={hotel.name || ''} onChange={val => onUpdate('name', val)} />

      <Inputs.Dropdown
        label={'Country Code'}
        values={hotel.countryCode ? [hotel.countryCode] : []}
        options={countriesOptions}
        onChange={sv => {
          if (sv.length <= 0) {
            onUpdate('countryCode', null);
          } else {
            onUpdate('countryCode', sv[0]);
          }
        }}
        multiselectProps={{
          isSingleSelectMode: true,
          isCloseOnSelect: true,
          hideCheckboxes: true,
          isEnableFuzzySearch: true,
          hideNonMatchingOptions: true,
        }}
      />

      <Inputs.Input label={'Hotel ID'} value={hotel.hotelId || ''} onChange={val => onUpdate('hotelId', val)} />

      <Inputs.Checkbox
        label={'Enabled'}
        value={hotel.enabled || false}
        onChange={newBool => {
          onUpdate('enabled', newBool);
        }}
      />

      <Inputs.Input label={'Region'} value={hotel.region || ''} onChange={val => onUpdate('region', val)} />

      <Inputs.TextArea
        label={'Description'}
        value={hotel.description || ''}
        onChange={val => onUpdate('description', val)}
      />

      <Inputs.TextArea
        label={'Additional Info'}
        value={hotel.additionalInfo || ''}
        onChange={val => onUpdate('additionalInfo', val)}
        rows={5}
      />

      <Inputs.TextArea
        label={'In Love With'}
        value={hotel.inLoveWith || ''}
        onChange={val => onUpdate('inLoveWith', val)}
        rows={2}
      />

      <Inputs.CheckboxCollection
        label={'Filters'}
        collection={filtersCategories.map(fc => {
          return { isChecked: (hotel.filters || []).includes(fc.filterEnumName), label: fc.filterEnumName };
        })}
        onChange={(filterName, checked) => {
          if (checked) {
            onUpdate('filters', [...(hotel.filters || []), filterName]);
          } else {
            onUpdate(
              'filters',
              (hotel.filters || []).filter(f => f !== filterName)
            );
          }
        }}
      />

      <Inputs.RadioCollection
        label={'Star Rating'}
        collection={starRatings.map(sr => {
          const isChecked = hotel.starRating === sr;
          return { isChecked, value: sr, label: sr };
        })}
        onChange={starRating => {
          onUpdate('starRating', starRating);
        }}
      />

      <Inputs.RadioCollection
        label={'Default Currency'}
        collection={['USD', 'EUR'].map(currency => {
          const isChecked = hotel.defaultCurrency === currency;
          return { isChecked, value: currency, label: currency };
        })}
        onChange={newDefaultCurrency => {
          onUpdate('defaultCurrency', newDefaultCurrency);
        }}
      />

      <Inputs.Checkbox
        label={'Suitable for honeymooners'}
        value={hotel.suitableForHoneymooners || false}
        onChange={newBool => {
          onUpdate('suitableForHoneymooners', newBool);
        }}
      />

      <Inputs.Checkbox
        label={'Preferred'}
        value={hotel.preferred || false}
        onChange={newBool => {
          onUpdate('preferred', newBool);
        }}
      />

      <Inputs.Checkbox
        label={'Disabled for travel partners'}
        value={hotel.disabledForTa || false}
        onChange={newBool => {
          onUpdate('disabledForTa', newBool);
        }}
      />

      <Inputs.RadioCollection
        label={'External System'}
        collection={[
          {
            value: null,
            label: 'None',
          },
          {
            value: 'derbysoft',
            label: 'Derbysoft',
          },
          {
            value: 'illusions',
            label: 'Illusions',
          },
          {
            value: 'mock',
            label: 'Mock',
          },
        ].map(es => {
          const isChecked = hotel.externalSystem === es.value;
          return { isChecked, value: es.value, label: es.label };
        })}
        onChange={externalSystem => {
          onUpdate('externalSystem', externalSystem);
        }}
      />

      <Inputs.Input
        label={'External Supplier ID'}
        value={hotel.externalSupplierId || ''}
        onChange={val => onUpdate('externalSupplierId', val)}
      />

      <Inputs.Input
        label={'External Hotel ID'}
        value={hotel.externalHotelId || ''}
        onChange={val => onUpdate('externalHotelId', val)}
      />

      {/* <Inputs.Dropdown
        label={'External Rate Currency'}
        values={hotel.externalCurrency ? [hotel.externalCurrency] : []}
        options={currenciesArray.map(c => {
          return { value: c.code, label: `(${c.code}) ${c.name}` };
        })}
        onChange={sv => {
          if (sv.length <= 0) {
            onUpdate('externalCurrency', null);
          } else {
            onUpdate('externalCurrency', sv[0]);
          }
        }}
        multiselectProps={{
          isSingleSelectMode: true,
          isCloseOnSelect: true,
          hideCheckboxes: true,
          isEnableFuzzySearch: true,
          hideNonMatchingOptions: true,
        }}
      /> */}

      <Inputs.Input
        label={'Exchange rate of external currency to default currency'}
        value={hotel.externalCurrencyExchangeRate || ''}
        onChange={val => onUpdate('externalCurrencyExchangeRate', val)}
      />

      <Inputs.Input
        label={'Fuel Charge'}
        value={hotel.fuelCharge || ''}
        onChange={val => onUpdate('fuelCharge', val)}
      />

      <Inputs.Input
        label={'Location (in lat/long format)'}
        value={hotel.location || ''}
        onChange={val => onUpdate('location', val)}
      />

      {mode === 'edit' && (
        <>
          <details className="border border-solid border-gray-20 p-2">
            <summary className="cursor-pointer">Images</summary>
            <Uploads uploads={imageUploads || []} setFeaturedPhoto={setFeaturedPhoto} deleteUpload={deleteUpload} />
            <FluidButton
              type="secondary"
              className="w-[300px] mt-4"
              onClick={() => {
                onUploadImage && onUploadImage();
              }}
            >
              Add Image
            </FluidButton>
          </details>

          <details className="border border-solid border-gray-20 p-2">
            <summary className="cursor-pointer">Documents</summary>
            <Uploads uploads={documentUploads || []} setFeaturedPhoto={setFeaturedPhoto} deleteUpload={deleteUpload} />
            <FluidButton
              type="secondary"
              className="w-[300px] mt-4"
              onClick={() => {
                onUploadDocument && onUploadDocument();
              }}
            >
              Add Document
            </FluidButton>
          </details>
        </>
      )}

      {mode === 'create' && (
        <>
          <span className="bg-gray-20 p-2">
            You can add uploads, amenities, highlights, overview, filters, etc after creating the hotel.
          </span>
        </>
      )}

      <hr />
      <FluidButton
        type="primary"
        className="self-end w-[300px] mt-4"
        isLoading={networkRequest === ENetworkRequestStatus.PENDING}
        disabled={networkRequest === ENetworkRequestStatus.PENDING}
        onClick={() => {
          const validation = validateHotelDetails(hotel as IHotel);
          if (validation.isValid === false) {
            dispatch(
              enqueueNotification({
                message: `Hotel is not valid: ${validation.message}`,
                options: { variant: 'warning' },
              })
            );
            return;
          }
          onCta({
            name: hotel.name,
            countryCode: hotel.countryCode,
            hotelId: hotel.hotelId,
            enabled: hotel.enabled,
            region: hotel.region,
            description: hotel.description,
            additionalInfo: hotel.additionalInfo,
            inLoveWith: hotel.inLoveWith,
            filters: hotel.filters,
            starRating: hotel.starRating,
            defaultCurrency: hotel.defaultCurrency,
            suitableForHoneymooners: hotel.suitableForHoneymooners,
            preferred: hotel.preferred,
            disabledForTa: hotel.disabledForTa,
            externalSystem: hotel.externalSystem,
            externalSupplierId: hotel.externalSupplierId,
            externalHotelId: hotel.externalHotelId,
            externalCurrency: hotel.externalCurrency,
            externalCurrencyExchangeRate: hotel.externalCurrencyExchangeRate,
            fuelCharge: hotel.fuelCharge,
            location: hotel.location,
          });
        }}
      >
        {ctaLabel}
      </FluidButton>
    </div>
  );
};
