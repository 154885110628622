import { BookingStatusTypes } from '../../enums';

export default {
  none: 'None',
  dashboard: 'Dashboard',

  search: 'Destination or resort',
  dates: 'Dates',
  lodging: 'Room',
  lodgings: 'Rooms',
  room: 'Room',
  roomWithNumber: 'Room {{number}}',
  guest: 'Guest',
  honeymooners: 'Honeymooners',
  searching: 'Search',
  allRegions: 'All regions',
  specifyRegions: 'Specify regions',
  backToHome: 'back to homepage',
  backToSearch: 'back to search results',
  roomSize: 'Room size',
  standardOccupancy: 'Standard occupancy',
  maxOccupancy: 'Max occupancy',
  moreInfo: 'More information',
  lessInfo: 'Less information',
  filterByAmenities: 'Filter by amenities',
  seeAmenities: 'See Amenities',
  hideAmenities: 'Hide Amenities',
  seeOverview: 'See Overview',
  hideOverview: 'Hide Overview',
  seeHighlights: 'See Highlights',
  showHighlights: 'Show Highlights',
  hideHighlights: 'Hide Highlights',
  filterByCategoryTypes: 'Filter by room types',
  noRooms: 'No rooms available',
  hotelDetails: 'Hotel Details',
  yourSelection: 'Your Selection',
  totalNet: 'Total net cost',
  totalExcludingCommission: 'Total cost to you excluding your commission',
  returnTransfers: 'Return transfers',
  groundService: 'Ground service',
  addOns: 'Other Items',
  addAccommodation: 'Add Accommodation',
  addCommission:
    'Add your Commission if client is paying Pure Escapes directly or if you wish to send this proposal directly to your clients.',
  includesTaxes: 'Includes all applicable taxes.',
  savingOfPrefix: 'You will save ',
  savingOfSuffix: ' compared to booking directly with this location.',
  applyMargin: 'Apply a margin',
  currentMargin: 'Your current margin will be',
  currentMarginPercentageSuffix: 'of the total cost shown above.',
  enterGuestDetails: 'Enter guest details',
  reviewAndConfirm: 'Review and confirm',
  booking: 'Booking',
  bookingWithId: 'Reference #{{id}}',
  included: 'inc.',
  extra: 'Extra',
  min: 'min',
  max: 'max',
  squareMeters: 'Square Meters',
  addons: 'Other Items',
  onRequest: 'On Request',
  out: 'Outbound',
  in: 'Inbound',
  notReady: 'Booking Not Ready',
  termsAndConditions: 'Terms and Conditions',
  currentPassword: 'Current password',
  password: 'Password',
  repeatPassword: 'Repeat password',
  rememberMe: 'Remember me',

  yes: 'Yes',
  no: 'No',

  from: 'From',
  to: 'To',

  payByCC: 'Pay by credit card',
  payByCCConfirmed: 'Booking Confirmation',
  payByBT: 'Pay by bank transfer',
  payByBTConfirmed: 'Provisional Booking Confirmation',
  bookingConfirm: 'Confirm booking request',
  bookingConfirmed: 'Booking Confirmation',
  bookingConfirmedOnRequest: 'Booking Request Confirmation',
  bookingConfirmOnRequest: 'Confirm Your booking on request',

  proposalWithId: 'Proposal #{{id}}',
  proposals: 'Proposals',
  newProposal: 'New proposal',
  proposalId: 'Proposal',
  proposalName: 'New Proposal Name',

  resortsIncluded: 'Resorts included',
  reviewAndGenerate: 'Review and Generate',

  propertiesAndRooms: 'Properties and rooms included in this proposal',
  concludeBooking: 'By concluding the booking process, I accept the ',
  agreeTo: 'I agree to ',

  createdAt: 'Created on',
  leadGuestInfo: 'Lead Guest Info',
  flightInformation: 'Flight information',
  specialRequests: 'Special Requests',
  guestsDetails: 'Guest Details',
  totalNetCost: 'Total Net Cost',

  commission: 'Commission',
  yourCommission: 'Your Commission',
  notApplied: 'Not Applied',
  additionalResources: 'Other Resources',
  additionalInfo: 'Additional Information',
  thingsToBeAwareOf: 'Things to be aware of with this resort',
  policiesAndRestrictions: 'Policies & Restrictions',
  cancellationPolicy: 'Cancellation Policy',
  paymentTerms: 'Payment Terms',
  offersTerms: 'Offers Terms',

  expiresIn: 'Expires in',

  accommodationPricesInfo:
    'Accommodation prices given for full stay with standard occupancy, default meal plan and includes all taxes. Excludes transfers, extra person supplements and any further add ons',
  resortDetails: 'Resort details',
  repeatGuest: 'Client is a repeating guest',
  isRepeat: 'Repeat Guest',
  fullRepeatGuest: 'This client is a repeating guest',

  priceBasedOn: 'Price based on:',
  nightsIn: '{{count}} {{nights}} in {{title}}, {{mealPlan}} ({{startDate}} - {{endDate}})',

  searchForTA: 'Search for Travel Agent',

  enquiry: 'Enquiry',
  potential: 'Enquiry',
  requested: 'Requested',
  confirmed: 'Confirmed',
  cancelled: 'Cancelled',
  completed: 'Completed',
  discarded: 'Discarded',

  latestTa: {
    [BookingStatusTypes.POTENTIAL]: "Latest TA's Enquiries Bookings",
    [BookingStatusTypes.REQUESTED]: "Latest TA's Requested Bookings",
    [BookingStatusTypes.CONFIRMED]: "Latest TA's Confirmed Bookings",
    [BookingStatusTypes.CANCELLED]: "Latest TA's Cancelled Bookings",
    [BookingStatusTypes.ENQUIRY]: "Latest TA's Enquiries Bookings",
    [BookingStatusTypes.COMPLETED]: "Latest TA's Completed Bookings",
    [BookingStatusTypes.DISCARDED]: "Latest TA's Discarded Bookings",
  },

  details: 'Details',
  uuid: 'UUID',
  status: 'Status',

  latestTaBookings: 'Latest TA Bookings',
  viewAllBookings: 'View all bookings',
  overrideTotal: 'Override Total',
  bookingComments: 'Booking comments',
  internalComments: 'Internal comments',
  cancelBooking: 'Cancel Booking',

  includesOffer: 'Includes {{count}} offer',
  includesOffer_plural: 'Includes {{count}} offers',

  preferred: 'Preferred',

  occasions: {
    honeymoon: 'Honeymoon',
    birthday: 'Birthday',
    anniversary: 'Anniversary',
    wedding: 'Wedding',
  },

  title: 'Title',
  titleOptional: 'Title (optional)',

  firstName: 'First Name',
  lastName: 'Last Name',
  emailAddress: 'Email address',
  existingPartner: 'Are you an existing partner?',
  company: {
    uuid: 'Company UUID',
    name: 'Company name',
    country: 'Company country',
    billingAddress: 'Billing Address',
    website: 'Website',
    logo: 'Company Logo',
    logoPosition: 'Company Logo Position in Proposal',
    phone: 'Company Phone',
  },
  landline: 'Landline',
  mobile: 'Mobile',

  arrivalFlightNumber: 'Arrival Flight Number (Optional)',
  departureFlightNumber: 'Departure Flight Number (Optional)',
  arrivalDate: 'Arrival Date (optional)',
  departureDate: 'Departure Date (optional)',
  commentsOptional: 'Comments (optional)',

  specialRequestOptions: {
    cribCob: 'Crib Cob',
    bedGuard: 'Bed Guard',
    adjacentRooms: 'Adjacent Rooms',
    connectingRooms: 'Connecting Rooms',
    accessibleRoom: 'Accessible Room',
    dietary: 'Dietary',
  },

  personTitles: {
    noTitle: 'No Title',
    master: 'Master',
    miss: 'Miss',
    mr: 'Mr.',
    mrs: 'Mrs.',
    ms: 'Ms.',
  },

  selectAvailableAccommodations: 'Accommodations',
  availableOnline: 'Available Online',

  logout: 'Logout',
  settings: 'Settings',

  profileDetails: 'Profile Details',
  companyDetails: 'Company Details',

  receiveEmailAlerts: 'Receive Email Alerts',

  accountRep: 'Your Representative',
  accountStatus: 'Your Account Status',
  accountRole: 'Your Account Role',
  environment: 'Environment',

  availability: 'Availability',

  specifyAges: 'Please specifiy ages:',

  noResults: 'No results found',
  roomsAndGuestSelection: 'Rooms & Guests Selection',
  mealPlanOffersAvailable: 'Meal plan offers available',
  invalidPriceRange: 'Min must be greater than Max',

  totalCostBreakdown: 'Total Cost Breakdown',
  transfers: 'Transfers',
  groundServices: 'Ground Services',
  updateOccupancy: 'Update Occupancy',
  pleaseSpecifyAgesOfAllChildren: 'Please specify ages of all children',
  noMealPlansForDatesOccupancy: 'There are no available meal plans for the selected dates/occupancy',
  numberOfChildren: 'Number of Children',
  numberOfAdults: 'Number of Adults',
  removeLodging: 'Remove Rooms',
  appliedOffers: 'Applied Offers',
  appliedSupplements: 'Applied Supplements',
  nightSigular: 'night',
  nightPlural: 'nights',
  datePicker: 'Date Picker',
  itemNoTotal: 'This item has no total',
  priceAvailableOnRequest: 'Price available on request',

  lodgingCountPrefix: 'Room Count:',
  proposalFormNotes: {
    pleaseNote: 'Please note:',
    point1: 'You can add another property to your proposal by navigating back to Search results',
    point2: "Once you have generated a pdf proposal you can't add or amend it but can send a new pdf proposal",
    point3: 'You can add up to 3 options in any one proposal',
  },

  addLodging: 'Add Room',

  summaryForYourChosenOptionsIn: 'Summary for your chosen options in',
  bookingStatus: 'Booking Status',
  holds: 'Holds',
  cancellation: 'Cancellation',
  requestToBook: 'Request to Book',
  uploads: 'Uploads',
  ta: 'Travel Agent',
  sr: 'Sales Representative',
  rl: 'Rates Loader',
  admin: 'Admin',
  finance: 'Finance',

  prerequisites: {
    dates: 'Stay Between',
    maximumLodgingsInBooking: 'Maximum Lodgings in Booking',
    advance: 'Advance',
    stayLength: 'Stay Length',
    countryCodes: 'TA Country Codes',
    accommodationProducts: 'Accommodation Products',
    anniversary: 'Anniversary',
    birthday: 'Birthday',
    honeymoon: 'Honeymoon',
    repeatCustomer: 'Repeat Customer',
    wedding: 'Wedding',
  },
  applications: {
    discountCheapest: 'Discount Cheapest?',
  },
  pricing: {
    from: 'From',
    basedOn: 'Based on standard occupancy and base meal plan',
  },
};
