import { sortBy } from 'lodash-es';
import { createSelector } from 'reselect';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { INotificationsDomain } from './model';

export const domainSelector = (state: any): INotificationsDomain => state.notifications;

export const possibleNotificationsSelector = createSelector(
  domainSelector,
  (domain: INotificationsDomain) => domain.possibleNotifications
);

export const getPossibleNotificationsRequestSelector = createSelector(
  domainSelector,
  (domain: INotificationsDomain) => domain.request.getPossibleNotifications
);

export const enabledNotificationsSelector = createSelector(
  domainSelector,
  (domain: INotificationsDomain) => domain.enabledNotifications
);

export const getEnabledNotificationsRequestSelector = createSelector(
  domainSelector,
  (domain: INotificationsDomain) => domain.request.getEnabledNotifications
);

export const notificationsSelector = createSelector(
  possibleNotificationsSelector,
  enabledNotificationsSelector,
  (
    possibleNotifications: INotificationsDomain['possibleNotifications'],
    enabledNotifications: INotificationsDomain['enabledNotifications']
  ) => {
    const notifications =
      possibleNotifications?.map(n => {
        const enabledNotification = enabledNotifications?.find(en => en.code === n.code);
        const enabled = enabledNotification?.enabled === true;
        const ccToDepartmentsUuids = enabledNotification?.ccToDepartmentsUuids ?? [];
        return { ...n, enabled, ccToDepartmentsUuids };
      }) ?? [];
    return sortBy(notifications, ['order']);
  }
);

export const areNotificationsLoadingSelector = createSelector(
  getPossibleNotificationsRequestSelector,
  getEnabledNotificationsRequestSelector,
  (
    getPossibleNotificationsRequest: ENetworkRequestStatus,
    getEnabledNotificationsRequestSelector: ENetworkRequestStatus
  ) =>
    getPossibleNotificationsRequest === ENetworkRequestStatus.PENDING ||
    getEnabledNotificationsRequestSelector === ENetworkRequestStatus.PENDING
);

export const setNotificationsForCompanyUsersSelector = createSelector(
  domainSelector,
  (domain: INotificationsDomain) => domain.request.setNotificationsForCompanyUsers
);

export const isNotificationSettingsModalVisibleSelector = createSelector(
  domainSelector,
  (domain: INotificationsDomain) => domain.ui.isNotificationSettingsModalVisible
);

export const isNotificationConfirmationModalVisibleSelector = createSelector(
  domainSelector,
  (domain: INotificationsDomain) => domain.ui.isConfirmationModalVisible
);

export const assignNotificationsActionSelector = createSelector(
  domainSelector,
  (domain: INotificationsDomain) => domain.assignNotificationsAction
);
