import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Actions from './actions';
import { EAssignNotificationsAction, initialState, INotificationsDomain } from './model';
import { update } from 'utils/immutable';

export const notificationsReducer = (
  state: INotificationsDomain = initialState,
  action: Actions.NotificationsAction
): INotificationsDomain => {
  switch (action.type) {
    case Actions.GET_POSSIBLE_NOTIFICATIONS_REQUEST:
      return update(state, s => {
        s.request.getPossibleNotifications = ENetworkRequestStatus.PENDING;
      });

    case Actions.GET_POSSIBLE_NOTIFICATIONS_SUCCESS:
      return update(state, s => {
        s.request.getPossibleNotifications = ENetworkRequestStatus.SUCCESS;
        s.possibleNotifications = action.notifications;
      });

    case Actions.GET_POSSIBLE_NOTIFICATIONS_FAILURE:
      return update(state, s => {
        s.request.getPossibleNotifications = ENetworkRequestStatus.ERROR;
      });

    case Actions.GET_ENABLED_NOTIFICATIONS_REQUEST:
      return update(state, s => {
        s.request.getEnabledNotifications = ENetworkRequestStatus.PENDING;
      });

    case Actions.GET_ENABLED_NOTIFICATIONS_SUCCESS:
      return update(state, s => {
        s.request.getEnabledNotifications = ENetworkRequestStatus.SUCCESS;
        s.enabledNotifications = action.notifications;
      });

    case Actions.GET_ENABLED_NOTIFICATIONS_FAILURE:
      return update(state, s => {
        s.request.getEnabledNotifications = ENetworkRequestStatus.ERROR;
      });

    case Actions.RESET_NOTIFICATIONS:
      return update(state, s => {
        s.possibleNotifications = null;
        s.enabledNotifications = null;
        s.request.getPossibleNotifications = ENetworkRequestStatus.IDLE;
        s.request.getEnabledNotifications = ENetworkRequestStatus.IDLE;
      });

    case Actions.SET_NOTIFICATIONS_FOR_COMPANY_USERS_REQUEST:
      return update(state, s => {
        s.request.setNotificationsForCompanyUsers = ENetworkRequestStatus.PENDING;
      });

    case Actions.SET_NOTIFICATIONS_FOR_COMPANY_USERS_SUCCESS:
      return update(state, s => {
        s.request.setNotificationsForCompanyUsers = ENetworkRequestStatus.SUCCESS;
        s.ui.isNotificationSettingsModalVisible = false;
        s.ui.isConfirmationModalVisible = false;
        s.assignNotificationsAction = null;
      });
      
      case Actions.SET_NOTIFICATIONS_FOR_COMPANY_USERS_FAILURE:
        return update(state, s => {
          s.request.setNotificationsForCompanyUsers = ENetworkRequestStatus.ERROR;
        });

    case Actions.SET_NOTIFICATION_SETTINGS_MODAL_VISIBILITY:
      return update(state, s => {
        s.ui.isNotificationSettingsModalVisible = action.isVisible;
      });

    case Actions.SET_NOTIFICATION_CONFIRMATION_MODAL_VISIBILITY:
      return update(state, s => {
        s.ui.isConfirmationModalVisible = action.isVisible;
        s.assignNotificationsAction = action.assignNotificationsAction;
      });

    default:
      return state;
  }
};

export default notificationsReducer;
