import React, { useCallback, useMemo } from 'react';
import SingleSelect, { ISingleSelectOption, ISingleSelectProps } from 'ui/SingleSelect';
import { ETravelAgentStatus } from 'services/BackendApi/types/TravelAgentResponse';
import classnames from 'classnames';
import { UserStatus, statuses } from 'ui/UserStatus';

export interface IUserStatusSelectProps {
  className?: string;
  label?: string;
  fieldId?: string;
  labelWhenNothingSelected?: string;
  value: ETravelAgentStatus | null;
  allowedStatuses: ETravelAgentStatus[];
  errorMessage?: string | null;
  errorClassName?: string;
  onChange: (v: ETravelAgentStatus | null) => void;
  disabled?: boolean;
}

export const UserStatusSelect: React.FC<IUserStatusSelectProps> = props => {
  const options: ISingleSelectOption[] = useMemo(
    () =>
      statuses
        .filter(status => props.allowedStatuses.includes(status.value))
        .map(s => ({
          value: s.value,
          renderItem: (className?: string) => (
            <UserStatus className={classnames(className, 'min-h-[33px]', { 'text-gray-100': props.disabled })} value={s.value} />
          ),
        })),
    []
  );

  const handleChange = useCallback((value?: string) => props.onChange((value as ETravelAgentStatus) || null), [
    props.onChange,
  ]);

  const optionalProps: Partial<ISingleSelectProps> = {
    ...(props.label ? { label: props.label } : {}),
    ...(props.errorClassName ? { errorClassName: props.errorClassName } : {}),
    ...(props.errorMessage ? { errorMessage: props.errorMessage } : {}),
    ...(props.fieldId ? { fieldId: props.fieldId } : {}),
  };

  return (
    <SingleSelect
      fieldId="filterStatus"
      className={props.className}
      value={props.value || undefined}
      options={options}
      disabled={props.disabled}
      onChange={handleChange}
      maxVisibleItems={6}
      showSelectedOption
      labelWhenNothingSelected={props.labelWhenNothingSelected}
      {...optionalProps}
    />
  );
};
