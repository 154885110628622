import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { interpose } from 'utils/array';

import { ENetworkRequestStatus, EUserType } from 'services/BackendApi';

import * as CompanyManagersSelectors from 'store/modules/companyManagers/selectors';
import * as CompanyManagersActions from 'store/modules/companyManagers/actions';
import { ECompanyManagerType, SortableFields, SortOrder } from 'store/modules/companyManagers/model';

import FluidButton from 'ui/FluidButton';
import Checkbox from 'ui/Checkbox';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import ManagerIcon from 'ui/Icons/manager.component.svg';
import { NotificationSettingsModal } from './NotificationSettingsModal';
import {
  assignNotificationsActionSelector,
  isNotificationConfirmationModalVisibleSelector,
  isNotificationSettingsModalVisibleSelector,
  setNotificationsForCompanyUsersSelector,
} from 'store/modules/notifications/selectors';
import {
  setNotificationConfirmationModalVisibilityAction,
  setNotificationSettingsModalVisibilityAction,
  setNotificationsForCompanyUsersRequestAction,
} from 'store/modules/notifications/actions';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { EAssignNotificationsAction } from 'store/modules/notifications/model';
import InfoIcon from 'ui/Icons/Info.component.svg';
import { theme } from '../../../../tailwind.config';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';

interface CompanyManagersProps {
  companyUuid: string;
  isEditMode: boolean;
}

export const CompanyManagers: React.FC<CompanyManagersProps> = ({ companyUuid, isEditMode }) => {
  const sortedList = useSelector(CompanyManagersSelectors.sortedListSelector);

  const sortBy = useSelector(CompanyManagersSelectors.sortBySelector);
  const sortOrder = useSelector(CompanyManagersSelectors.sortOrderSelector);
  const selectedBookingManagers = useSelector(CompanyManagersSelectors.selectedBookingManagersSelector);
  const selectedTaManagers = useSelector(CompanyManagersSelectors.selectedTaManagersSelector);
  const savedTaManagers = useSelector(CompanyManagersSelectors.savedTaManagersSelector);
  const request = useSelector(CompanyManagersSelectors.requestSelector);
  const dirty = useSelector(CompanyManagersSelectors.dirtySelector);

  const dispatch = useDispatch();

  const isNotificationModalVisible = useSelector(isNotificationSettingsModalVisibleSelector);
  const isNotificationConfirmationModalVisible = useSelector(isNotificationConfirmationModalVisibleSelector);
  const setNotificationsForCompanyUsersRequest = useSelector(setNotificationsForCompanyUsersSelector);
  const assignNotificationsAction = useSelector(assignNotificationsActionSelector);
  const [selectedNotificationCodes, setSelectedNotificationCodes] = useState<string[]>([]);
  const notificationModalConfirmationTitle =
    assignNotificationsAction === EAssignNotificationsAction.ASSIGN_TO_ALL
      ? 'You are going to assign the selected notifications to all the Travel Agents of the Company.'
      : 'You are going to remove selected notifications from all the Travel Agents of the Company.';


  const isSavingChanges = useMemo(
    () => [request.post, request.patch].some(x => x === ENetworkRequestStatus.PENDING),
    [request.post, request.patch]
  );

  useLayoutEffect(() => {
    if (companyUuid) {
      dispatch(CompanyManagersActions.getRequestInTCAction(companyUuid));
    }
  }, [dispatch, companyUuid]);

  const handleNotificationSettingsModalOpen = useCallback(() => {
    dispatch(setNotificationSettingsModalVisibilityAction(true));
    setSelectedNotificationCodes([]);
  }, [dispatch]);

  const handleNotificationSettingsModalClose = useCallback(() => {
    dispatch(setNotificationSettingsModalVisibilityAction(false));
  }, [dispatch]);

  const handleNotificationConfirmationModalClose = useCallback(() => {
    dispatch(setNotificationConfirmationModalVisibilityAction(false, null));
    dispatch(setNotificationSettingsModalVisibilityAction(true));
  }, [dispatch]);

  const handleNotificationSettingsConfirm = useCallback(() => {
    dispatch(
      setNotificationsForCompanyUsersRequestAction(
        companyUuid,
        EUserType.TA,
        selectedNotificationCodes,
        assignNotificationsAction === EAssignNotificationsAction.ASSIGN_TO_ALL
      )
    );
  }, [companyUuid, selectedNotificationCodes, dispatch, assignNotificationsAction]);

  const handleNotificationSelect = useCallback(
    (notificationCode: string) => {
      if (selectedNotificationCodes.includes(notificationCode)) {
        const updatedCodes = selectedNotificationCodes.filter(item => item !== notificationCode);
        setSelectedNotificationCodes(updatedCodes);
      } else {
        const updatedCodes = [...selectedNotificationCodes, notificationCode];
        setSelectedNotificationCodes(updatedCodes);
      }
    },
    [selectedNotificationCodes]
  );

  const handleSort = useCallback(
    (sortField: SortableFields) => () => {
      if (sortField === sortBy) {
        dispatch(CompanyManagersActions.toggleSortOrderAction());
      } else {
        dispatch(CompanyManagersActions.toggleSortByAction(sortField));
      }
    },
    [dispatch, sortBy]
  );

  const handleSelect = (travelAgentUuid: string, managerType: ECompanyManagerType) =>
    dispatch(CompanyManagersActions.toggleItemAction(travelAgentUuid, managerType));

  const handleSave = useCallback(() => dispatch(CompanyManagersActions.saveChangesAction(companyUuid)), [
    dispatch,
    companyUuid,
  ]);

  return (
    <div className="company-booking-managers w-full">
      <p className="title font-pt-sans text-black-true font-bold text-17px leading-md mt-25px mb-0">
        Travel Agents Settings
      </p>
      <div className="flex items-center justify-between mt-5px mb-0">
        <p className="description font-pt-sans text-black text-15px leading-sm m-0">
          Travel Agent selection to give them, on one part, the possibility to manage other TAs bookings, and, on the
          other, give them managerial status.
        </p>
      </div>
      <div className={classNames("flex items-center justify-between mt-5 mb-5", { 'flex-row-reverse': !isEditMode })}>
        {isEditMode && (
          <FluidButton
            type="secondary"
            className="notifications-button"
            onClick={handleNotificationSettingsModalOpen}
            disabled={isNotificationModalVisible || !isEditMode}
          >
            Notification Settings
          </FluidButton>
        )}
        <Counters
          className="font-pt-sans text-brown-100 text-13px leading-sm m-0"
          travelAgents={sortedList.length}
          companyBookingManagers={selectedBookingManagers.size}
          companyTaManagers={selectedTaManagers.size}
        />
      </div>
      {request.get === ENetworkRequestStatus.PENDING && (
        <div className="mt-20px">
          <LoadingBar />
        </div>
      )}
      {request.get === ENetworkRequestStatus.ERROR && (
        <div className="mt-20px">
          <ErrorBar />
        </div>
      )}
      {request.get === ENetworkRequestStatus.SUCCESS && (
        <>
          <table className="headers w-full table-fixed border-collapse text-left font-pt-sans mt-15px" cellSpacing="0">
            <thead>
              <tr className="bg-ivory border border-solid border-gray-40 shadow-pe2 h-10 uppercase text-xs text-gray-120 leading-16px tracking-2xs">
                <TH
                  style={{ width: '250px' }}
                  className="travel-agent pl-6"
                  sortable
                  sortActive={sortBy === SortableFields.NAME}
                  sortOrder={sortOrder}
                  onClick={handleSort(SortableFields.NAME)}
                >
                  Travel Agent
                </TH>
                <TH
                  style={{ width: '100px' }}
                  className="country"
                  sortable
                  sortActive={sortBy === SortableFields.COUNTRY}
                  sortOrder={sortOrder}
                  onClick={handleSort(SortableFields.COUNTRY)}
                >
                  Country
                </TH>
                <TH
                  style={{ width: '320px' }}
                  className="contact"
                  sortable
                  sortActive={sortBy === SortableFields.EMAIL}
                  sortOrder={sortOrder}
                  onClick={handleSort(SortableFields.EMAIL)}
                >
                  Contact
                </TH>
                <TH className="company-booking-manager justify-center">Booking Sharing</TH>
                <TH className="company-ta-manager justify-center">Ta Manager</TH>
                <TH className="notifications-header justify-center">Notifications</TH>
                <TH className="company-ta-manager-icon justify-center" />
              </tr>
            </thead>
            <tbody>
              {!sortedList.length && (
                <tr className="h-50px text-15px leading-19px text-black border-solid border-b border-b-gray-10">
                  <TD className="pl-4">No data available.</TD>
                </tr>
              )}
              {sortedList.map((tAgent, i) => (
                <tr
                  key={tAgent.uuid}
                  className={classNames(
                    'h-50px text-15px leading-19px text-black border-solid border-b border-b-gray-10',
                    { 'bg-ivory': i % 2 }
                  )}
                >
                  <TD className="travel-agent pl-6">
                    <Name {...tAgent} />
                  </TD>
                  <TD className="country">
                    <span className="pl-4">{tAgent.countryCode}</span>
                  </TD>
                  <TD className="contact">{tAgent.email}</TD>
                  <TD className="company-booking-manager">
                    <label className="flex items-center justify-center cursor-pointer">
                      <Checkbox
                        checked={tAgent.companyBookingManager}
                        onChange={() => handleSelect(tAgent.uuid, ECompanyManagerType.BOOKING)}
                        disabled={!isEditMode}
                      />
                    </label>
                  </TD>
                  <TD className="company-ta-manager">
                    <label className="flex items-center justify-center cursor-pointer">
                      <Checkbox
                        checked={tAgent.companyTaManager}
                        onChange={() => handleSelect(tAgent.uuid, ECompanyManagerType.TA_MANAGER)}
                        disabled={!isEditMode}
                      />
                    </label>
                  </TD>
                  <TD className="notifications">
                    <label className="flex items-center justify-center cursor-pointer">
                      {tAgent.notifications?.length || 0}
                    </label>
                  </TD>
                  <TD className="company-ta-manager-icon flex flex-row-reverse pr-8 relative" overflow>
                    {savedTaManagers?.has(tAgent.uuid) && (
                      <HidingTextTooltip
                        tooltipContent="Manager"
                        position="bottom"
                        tooltipClassname="mt-1 py-5px px-3px"
                      >
                        <ManagerIcon className="cursor-pointer" />
                      </HidingTextTooltip>
                    )}
                  </TD>
                </tr>
              ))}
            </tbody>
          </table>
          {isEditMode && (<FluidButton
            type="primary"
            className="save mt-25px"
            onClick={handleSave}
            disabled={!dirty}
            isLoading={isSavingChanges}
          >
            Save Changes
          </FluidButton>)}
        </>
      )}
      <LeaveWithoutSavingModal when={dirty} />
      {isNotificationModalVisible && (
        <NotificationSettingsModal
          selectedNotificationCodes={selectedNotificationCodes}
          onNotificationSelect={handleNotificationSelect}
          onClose={handleNotificationSettingsModalClose}
        />
      )}
      {isNotificationConfirmationModalVisible && (
        <ConfirmationModal
          className="notification-settings-assign-warning"
          type={EConfirmationModalType.WARNING}
          isOpen
          title={notificationModalConfirmationTitle}
          message="Do you want to continue?"
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          isConfirmLoading={setNotificationsForCompanyUsersRequest === ENetworkRequestStatus.PENDING}
          onConfirm={handleNotificationSettingsConfirm}
          onCancel={handleNotificationConfirmationModalClose}
        />
      )}
    </div>
  );
};

interface THProps {
  className?: string;
  sortActive?: boolean;
  sortable?: boolean;
  sortOrder?: SortOrder;
  onClick?: () => void;
  // size: number;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const TH: React.FC<THProps> = props => {
  const { style = {} } = props;
  return (
    <th style={{ ...style }}>
      {!props.sortable ? (
        <div className={classNames(props.className, 'header group flex font-normal uppercase items-center px-2 h-8')}>
          {props.children}
        </div>
      ) : (
        <div
          className={classNames(
            props.className,
            'header group flex font-normal uppercase items-center px-2 justify-between h-8 hover:bg-gray-10 cursor-pointer rounded',
            { 'bg-gray-15': props.sortActive }
          )}
          onClick={props.onClick}
        >
          <span>{props.children}</span>
          {props.sortActive && (
            <span
              style={{ marginRight: '-18px', width: '38px' }}
              className={classNames('fa-stack fa-xs', {
                'group-hover:text-gray-40 text-ivory': !props.sortOrder,
              })}
            >
              <i
                style={{ position: 'relative', top: '-5px' }}
                className={classNames('fas fa-stack-1x fa-chevron-up', {
                  'text-gray-100': props.sortOrder === SortOrder.ASC,
                  'text-gray-40': props.sortOrder === SortOrder.DESC,
                })}
              ></i>
              <i
                style={{ position: 'relative', top: '-12px' }}
                className={classNames('fas fa-stack-1x fa-chevron-down', {
                  'text-gray-40': props.sortOrder === SortOrder.ASC,
                  'text-gray-100': props.sortOrder === SortOrder.DESC,
                })}
              ></i>
            </span>
          )}
        </div>
      )}
    </th>
  );
};

interface TDProps {
  className?: string;
  // size: number;
  overflow?: boolean;
  children?: React.ReactNode;
}

const TD: React.FC<TDProps> = ({ className, overflow = false, children }) => (
  <td>
    <div
      className={classNames(className, 'content px-2 py-2', {
        'text-ellipsis': !overflow,
        'overflow-hidden': !overflow,
        'whitespace-nowrap': !overflow,
      })}
    // style={}
    >
      {children}
    </div>
  </td>
);

interface NameProps {
  className?: string;
  firstName?: string;
  lastName?: string;
}

const Name: React.FC<NameProps> = props => (
  <span className={props.className}>{[props.firstName, props.lastName].filter(x => x).join(' ')}</span>
);

interface PluralProps {
  className?: string;
  noun: string;
  count: number;
}

const Plural: React.FC<PluralProps> = ({ className, noun, count }) => (
  <span className={className}>{count === 1 ? noun : `${noun}s`}</span>
);

interface CountersProps {
  className?: string;
  travelAgents: number;
  companyBookingManagers: number;
  companyTaManagers: number;
}

const Counters: React.FC<CountersProps> = props => {
  const items = [
    <span>
      <span className="font-bold">{props.travelAgents}</span> <Plural noun="Travel Agent" count={props.travelAgents} />
    </span>,
  ];

  if (props.companyBookingManagers > 0) {
    items.push(
      <span>
        <span className="font-bold">{props.companyBookingManagers}</span> TA Sharing
      </span>
    );
  }

  if (props.companyTaManagers > 0) {
    items.push(
      <span>
        <span className="font-bold">{props.companyTaManagers}</span> TA Managers
      </span>
    );
  }

  return (
    <span className={classNames(props.className, 'counters font-pt-sans text-brown-100 text-13px leading-sm')}>
      {interpose(items, <span className="text-gray-40"> | </span>)}
    </span>
  );
};
