import { ITravelAgent, IInternalUsersFilter, ENetworkRequestStatus } from 'services/BackendApi';
import { ISR } from '../user/model';

export interface IInternalUsersListPagination {
  page: number;
  perPage: number;
  total: number | null;
}
export interface IAssignTAModal {
  assignedTAs: ITravelAgent[];
  unAssignedTAs: ITravelAgent[];
  data: string[];
  selectedSR: ISR | null;
  getRequest: ENetworkRequestStatus,
  postRequest: ENetworkRequestStatus,
  filter: {
    salesRepUuid: string | undefined,
    search: string | undefined,
    countryCode: string | undefined,
    companyUuid: string | undefined,
    createdBy: string | undefined,
    status: string | undefined,
  },
  checkAll: boolean,
  isAssignedTab: boolean,
  confirmationModal: boolean,
}

export interface IInternalUsersListDomain {
  list: ITravelAgent[] | null;
  filter: IInternalUsersFilter;
  pagination: IInternalUsersListPagination;
  toDelete: ITravelAgent | null;
  request: {
    get: ENetworkRequestStatus;
    delete: ENetworkRequestStatus;
    csv: ENetworkRequestStatus;
  };
  assignTAModal: IAssignTAModal,
}

export const initialState: IInternalUsersListDomain = {
  list: null,
  filter: {
    userRole: null,
    search: null,
    countryCode: null,
    status: null,
    email: null,
  },
  pagination: {
    page: 0,
    perPage: 10,
    total: null,
  },
  toDelete: null,
  request: {
    get: ENetworkRequestStatus.IDLE,
    delete: ENetworkRequestStatus.IDLE,
    csv: ENetworkRequestStatus.IDLE,
  },
  assignTAModal: {
    assignedTAs: [],
    unAssignedTAs: [],
    data: [],
    selectedSR: null,
    getRequest: ENetworkRequestStatus.IDLE,
    postRequest: ENetworkRequestStatus.IDLE,
    filter: {
      salesRepUuid: undefined,
      search: undefined,
      countryCode: undefined,
      companyUuid: undefined,
      createdBy: undefined,
      status: undefined,
    },
    checkAll: false,
    isAssignedTab: true,
    confirmationModal: false,
  }
};
