import React from 'react';
import { EImportEntity } from '../../store/modules/importer/model';
import { ratesImportDomainSelector } from '../../store/modules/ratesImport/selectors';
import Importer, { IImportProps } from 'pureUi/Importer';
import { WithImporter } from 'hoc/WithImporter';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

const RatesImportContainer = (props: Omit<IImportProps, 'entityName'>) => {
  const { dynamicParameters } = useDynamicParameters();

  return (
    <Importer
      entityName="Rates"
      confirmationCopy="This operation takes usually around 4 minutes. It cannot be cancelled before completion and it is irreversible."
      {...props}
      dynamicParameters={dynamicParameters}
      pageTitle={
        <h1 className="font-normal font-noe-display text-[36px] leading-46px">
          Products - <span className="text-[26px]">Hotels - Rates</span>
        </h1>
      }
    />
  );
};

export const RatesImportContainerConnected = WithImporter(
  EImportEntity.RATES,
  ratesImportDomainSelector
)(RatesImportContainer);
