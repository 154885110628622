import React, { useState, useCallback } from 'react';
import { IHAPFormattedAccommodationProductStaticRate } from 'store/modules/hotelAccommodationProducts/types';
import { composeCancellationPolicyFromRoomExpenseInfo } from '../../common-lib/cancellation-policy-composer';
import { ERefundabilityTagStatus, RefundabilityTag } from 'ui/RefundabilityTag/RefundabilityTag';

const linesLimit = 3;

export interface IStaticRateCancellationPolicyProps {
  staticRate: IHAPFormattedAccommodationProductStaticRate;
  currencyCode: string;
}

export const StaticRateCancellationPolicy = (props: IStaticRateCancellationPolicyProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const { cancellationInfo } = props.staticRate.potentialBooking.Accommodation[0];
  if (!cancellationInfo.texts.length) {
    return null;
  }

  const texts = composeCancellationPolicyFromRoomExpenseInfo(props.staticRate.expenseInfosGroupedByRoom[0], {
    currencySymbol: props.currencyCode,
    appendLines: ['*at 00.00 time at destination'],
  });

  const isLong = texts.length > linesLimit;

  const toShow = !isLong || isOpen ? texts : texts.slice(0, linesLimit - 1);

  return (
    <div className="static-rate-cancellation-policy font-hurmegeometric-sans text-xs">
      {props.staticRate.isRefundable !== null && (
        <RefundabilityTag
          refundabilityStatus={
            props.staticRate.isRefundable ? ERefundabilityTagStatus.REFUNDABLE : ERefundabilityTagStatus.NON_REFUNDABLE
          }
          className="acc-free-sale-rates-tag flex items-center"
        />
      )}
      <div className="content text-flint leading-18px mt-1">
        {toShow.map((t, i) => (
          <p key={i}>{t}</p>
        ))}
        {isLong && (
          <span className="toggle text-brown-100 underline hover:no-decoration cursor-pointer" onClick={toggle}>
            {isOpen ? 'Show less' : 'Show more'}
          </span>
        )}
      </div>
    </div>
  );
};
