import { rootSagaFactory } from '../importer/sagas';
import { EImportEntity } from '../importer/model';

import { ancillaryGroundServicesRatesImportDomainSelector } from './selectors';

export default rootSagaFactory({
  entity: EImportEntity.ANCILLARY_GROUND_SERVICES_RATES,
  domainSelector: ancillaryGroundServicesRatesImportDomainSelector,

  importApiCall: (backendApi, bookingManagerApi) => bookingManagerApi.postImportAncillaryGroundServicesRates(),

  importStatusApiCall: async (backendApi, bookingManagerApi) => {
    const result = await bookingManagerApi.getImportAncillaryGroundServicesRatesStatus();

    // massage the result into the expected shape
    return {
      ...result,
      data: {
        ...result.data,
        meta: {
          workbookId: result.data.workbookId,
        },
        data: {
          ...result.data,
        },
      },
    };
  },
});
