import React, { useEffect, useState } from 'react';
import { roundToDecimalPlaces } from 'utils';
interface IDecimalInput {
  value: string | number;
  className?: string;
  onBlur: (updatedValue: number | null) => void;
  decimalPlaces: number;
  disabled?: boolean;
  max?: number;
  min?: number;
  allowNull?: boolean; // if true, allows the user to delete the value and the `onBlur` will be called with `null`
  validRegex?: RegExp;
}

export const DecimalInput = (props: IDecimalInput) => {
  const { value, allowNull = false } = props;
  const { disabled = false } = props;

  const [localValue, setLocalValue] = useState(props.value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <input
      disabled={disabled}
      className={`${props.className}`}
      value={localValue}
      onChange={event => {
        const validRegex = props.validRegex || /^[0-9.]*$/;
        if (!validRegex.test(event.target.value)) {
          return;
        }
        setLocalValue(event.target.value);
      }}
      onBlur={() => {
        if ((localValue === '' || localValue === null) && allowNull) {
          props.onBlur(null);
          setLocalValue('');
          return;
        }
        let val = parseFloat(String(localValue));
        if (isNaN(val)) {
          val = 0;
        }

        if (props.min && val < props.min) {
          val = props.min;
        }

        if (props.max && val > props.max) {
          val = props.max;
        }

        val = roundToDecimalPlaces(val, props.decimalPlaces); // round the number...
        val = parseFloat(val.toFixed(props.decimalPlaces)); //...then toFixed it afterwards, as toFixed rounding is broke

        setLocalValue(val);
        props.onBlur(val);
      }}
    />
  );
};
