/**
 * These are all copy pasted from the BMS server/common/api-interface.ts
 *
 * As and when that changes, we can just manually update this, and help ensure the FE is working with the right types
 */

import { TCountryCode } from 'interfaces';

export interface AncillaryRatesProductsSearchResponse {
  resultCount: number;
  results: AncillaryRatesProductsSearchItem[];
}

export interface AncillaryRegionsSearchResponse {
  name: string;
  id: number;
}

export interface AncillaryRatesProductsSearchItem {
  resultType: 'product' | 'product-with-variants';
  baseProductInternalWarnings: string | null;
  variantInternalWarnings: string | null;
  bestRateTotal: string | null;
  bestRateProfit: string | null;
  baseProductIsPreferred: boolean;
  baseProductId: number;
  baseProductCode: string;
  baseProductName: string;
  currencyISOCode: string;
  productWithRate?: AncillaryProductWithRate;
  variantsWithRate?: AncillaryProductWithRate[];
}

export interface AncillaryProductWithRate {
  supplierId: number;
  supplierName: string;
  supplierCode: string;
  supplierCurrencyISOCode: string;
  baseProductId: number;
  baseProductCode: string;
  baseProductName: string;
  baseProductInternalWarnings: string | null;
  variantInternalWarnings: string | null;
  variantId: number | null;
  variantCode: string | null;
  variantName: string | null;
  rateIsOnRequest: boolean;
  rateCancellationPolicy: string | null;
  ratePaymentTerms: string | null;
  restrictionMinimumAge: number | null;
  restrictionMaximumPeople: number | null;
  hasGuestsBelowMinimumAge: boolean;
  bestRateTotal: string | null;
  bestRateProfit: string | null;
  rateType: 'per_person' | 'per_hour' | 'per_unit';
  quantity: number;
}

export interface AncillaryProductWithRates {
  product: AncillaryProductResult;
  rates: AncillaryAllRatesDatesSet;
}

export interface AncillaryProductResult {
  productType: EAncillaryProductType;
  baseProductId: number;
  baseProductCode: string;
  baseProductName: string;
  isPreferred: boolean;
  description: string;
  restrictions: string;
  overview?: string | null;
  highlights?: string | null;
  imagesDirectoryURL?: string | null;
  vimeoVideoId?: string | null;
  variantId?: number | null;
  variantCode?: string | null;
  variantName?: string | null;
  supplier: AncillarySupplier;
  country: {
    isoCode: string;
    name: string;
  };
  region?: {
    name?: string;
  } | null;
  productRestrictions: {
    minimumAge: number | null;
    maximumPeople: number | null;
  };
  activity?: {
    location?: {
      x: number;
      y: number;
    } | null;
  } | null;
  flexibleAttributes: {
    id: number;
    type: string;
    name: string;
    values: {
      value?: string;
    }[];
  }[];
  filters: {
    code: string;
    name: string;
    values: {
      value?: string;
      filterOrder?: number;
    }[];
  }[];
}

export interface AncillaryAllRatesDatesSet {
  [key: string]: AncillaryRateForDate;
}

export interface AncillarySupplier {
  supplierId: number;
  supplierName: string;
  supplierCode: string;
  supplierAddress: string;
  supplierAdditionalInformation: string | null;
  currency: {
    isoCode: string;
    name: string;
  };
  country: {
    isoCode: string;
    name: string;
  };
}

export type AncillaryRateForDate = AncillaryRatedResult | AncillaryUnratedResult;

export const isAncillaryRatedResult = (rate: AncillaryRateForDate): rate is AncillaryRatedResult => {
  return (rate as AncillaryRatedResult).hasRateForDate === true;
};

export const isAncillaryRatedResultPerUnit = (rate: AncillaryRateForDate): rate is AncillaryRatedResult => {
  return (
    (rate as AncillaryRatedResult).hasRateForDate === true && (rate as AncillaryRatedResult).rateType === 'per_unit'
  );
};

// These are the two types of rates we can get back from the server
export interface AncillaryRatedResult {
  rateType: string;
  price: string | null;
  profit: string | null;
  purchaseCost: string | null;
  rateCancellationPolicy: string | null;
  ratePaymentTerms: string | null;
  rateIsOnRequest: boolean;
  quantity: number;
  date: string /** Format: YYYY-MM-DD */;
  hasRateForDate: boolean;
  numberOfAdults: number;
  agesOfAllChildren: number[];
  minimumUnitQuantity?: number;
  hash: string;
}
export interface AncillaryUnratedResult {
  date: string /** Format: YYYY-MM-DD */;
  hasRateForDate: boolean;
  numberOfAdults: number;
  agesOfAllChildren: number[];
}

/**
 * This is the user made stuff, NOT copied from the BMS
 * Mostly for holding state before we send it up to an API, etc.
 */

export interface AncillaryProductWithRatesWithUserData extends AncillaryProductWithRates {
  userData: {
    rate?: AncillaryRateForDate;
    optionSelections: {
      [key: string]: string | boolean; // string for regular options, boolean for the option-boolean ones
    };
    unitCount?: number;
    minUnitCount?: number;
  };
}

export interface SelectedAncillarySearchResult {
  baseProductId: string;
  variantId?: string;
}

export enum EAncillaryTransferTripType {
  ONE_WAY = 'one_way',
  RETURN = 'return',
}
export enum EAncillaryProductType {
  ACTIVITY = 'activity',
  TRANSFER = 'transfer',
  GROUND_SERVICE = 'ground-service',
}
export enum EAncillaryLocationType {
  AIRPORT = 'airport',
  HOTEL = 'hotel',
  PLACE = 'place',
}

export interface LocationFromSearchLocationsWithType {
  id: number;
  compositeId: string;
  name: string;
  type: EAncillaryLocationType;
  iata_code?: string | null;
  countryCode: TCountryCode;
}

export interface LocationDirectionWithCountry {
  id: number;
  compositeId: string;
  type: EAncillaryLocationType;
  countryCode?: TCountryCode;
  placeName?: string;
}

export interface AncillaryFilter {
  id: number;
  value: string;
}

export enum EAncillaryFilterCode {
  TYPE = 'TYPE',
}

export interface SuppliersSearchItem {
  id: number;
  code: string;
  name: string;
  country_iso_code: string;
  currency_iso_code: string;
  address: string;
  additional_information: string | null;
  is_enabled: boolean;
}
