import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FluidButton from 'ui/FluidButton';
import { ICompany } from 'services/BackendApi/types';
import { StandardModal } from 'pureUi/Modal';
import { TextInput } from 'ui/TextInput';
import { CountrySelect } from 'ui/CountrySelect';
import { TravelCompaniesListHeader } from './TravelCompaniesListHeader';
import { TravelCompaniesListRow } from './TravelCompaniesListRow';
import countries from 'config/data/countries.json';
import {
  setCompanyFilterCountryAction,
  setCompanyFilterNameAction,
  setSelectedTaCompanyAction,
  setTaCompaniesModalVisibleAction,
} from 'store/modules/travelAgent/actions';
import {
  companyFilterCountrySelector,
  companyFilterNameSelector,
  filteredTaCompaniesSelector,
  selectedTaCompanySelector,
} from 'store/modules/travelAgent/selectors';

export interface ITravelCompaniesListModalProps {
  onCompanySelect: (company: ICompany | undefined) => void;
  selectedCompanyUuid: string | null;
  companyName?: string;
  countryCode?: string | null;
  onClose: () => void;
}

export const TravelCompaniesListModal = React.memo((props: ITravelCompaniesListModalProps) => {
  const { onClose } = props;

  const dispatch = useDispatch();
  const countryCodeFilter = useSelector(companyFilterCountrySelector);
  const companyNameFilter = useSelector(companyFilterNameSelector);
  const selectedCompanyUuid = useSelector(selectedTaCompanySelector);
  const filteredTaCompanies = useSelector(filteredTaCompaniesSelector);
  const selectedCompany = filteredTaCompanies?.find(company => company.uuid === selectedCompanyUuid);

  useEffect(() => {
    if (props.countryCode) {
      dispatch(setCompanyFilterCountryAction(props.countryCode));
    }
    if (props.selectedCompanyUuid) {
      dispatch(setSelectedTaCompanyAction(props.selectedCompanyUuid));
    }
    if (props.companyName) {
      dispatch(setCompanyFilterNameAction(props.companyName ?? ''));
    }
  }, [props.selectedCompanyUuid, props.countryCode, props.companyName, dispatch]);

  const handleCountryCodeChange = useCallback((countryCode: string) => {
    dispatch(setCompanyFilterCountryAction(countryCode));
  }, []);

  const handleCompanyNameChange = useCallback(
    e => {
      dispatch(setCompanyFilterNameAction(e.target.value));
    },
    [dispatch]
  );

  const handleCompanyChange = useCallback((changedCompany: ICompany) => {
    dispatch(setSelectedTaCompanyAction(changedCompany.uuid));
  }, []);

  const handleSave = useCallback(() => {
    props.onCompanySelect(selectedCompany);
    dispatch(setTaCompaniesModalVisibleAction(false));
  }, [selectedCompany, props.onCompanySelect]);

  return (
    <StandardModal
      className="travel-companies-modal-container font-pt-sans"
      frameClassName="w-540px px-9 py-8 border border-solid"
      showCloseButton
      removePadding
      closeOnOutsideClick
      onClose={onClose}
    >
      <div className="travel-companies-modal relative">
        <h3 className="m-0 font-noe-display mb-25px font-normal text-black text-21px">Travel Companies List</h3>
        {!props.selectedCompanyUuid && filteredTaCompanies?.length && filteredTaCompanies?.length > 0 ? (
          <p className="font-pt-sans text-15px leading-22px text-black m-0">
            List of Travel Companies that match the information provided by the Travel Agent. Select one to assign to
            the Travel Agent or use the filters for more results:
          </p>
        ) : null}
        <div className="travel-companies-dropdowns flex items-center gap-20px">
          <div className="travel-companies-dropdowns-country max-w-200px">
            <p className="font-pt-sans text-16px leading-21px text-black m-0 font-bold mt-25px mb-5px">Country</p>
            <CountrySelect
              value={countryCodeFilter ?? null}
              onChange={handleCountryCodeChange}
              labelWhenNothingSelected="All"
              className="w-200px"
            />
          </div>
          <div className="travel-companies-dropdowns-company">
            <p className="font-pt-sans text-16px leading-21px text-black m-0 font-bold mt-25px">Company Name</p>
            <TextInput
              type="text"
              id="travel-company-name"
              value={companyNameFilter}
              className="travel-company-name w-250px"
              onChange={handleCompanyNameChange}
            />
          </div>
        </div>

        <div className="mt-25px pl-3 border border-solid border-gray-20 shadow-pe2">
          <TravelCompaniesListHeader />
          <div className="travel-companies-rows max-h-320px min-h-320px overflow-y-scroll pr-6">
            {filteredTaCompanies?.map(company => (
              <TravelCompaniesListRow
                key={company.uuid}
                country={countries[company.countryCode]}
                company={company}
                enabled={company.uuid === selectedCompany?.uuid}
                onChange={handleCompanyChange}
              />
            ))}
            {filteredTaCompanies?.length === 0 && (
              <>
                <p className="font-pt-sans text-black text-15px leading-19px italic m-0 mt-2">
                  There are no Travel Companies that match.
                </p>
                <p className="font-pt-sans text-black text-15px leading-19px  m-0 italic">
                  Use the filters to get existing Travel Companies.
                </p>
              </>
            )}
          </div>
        </div>

        <div className="travel-companies-buttons flex gap-10px mt-25px">
          <FluidButton className="travel-companies-cancel" type="secondary" onClick={onClose}>
            Cancel
          </FluidButton>
          <FluidButton
            className="travel-companies-save"
            type="primary"
            onClick={handleSave}
            disabled={!selectedCompany}
          >
            Done
          </FluidButton>
        </div>
      </div>
    </StandardModal>
  );
});
