import React from 'react';
import styled from 'styled-components';
import { IconButton } from '../Buttons/index';
import * as theme from 'pureUi/pureUiTheme';

interface IDismissableNotificationProps extends React.HTMLAttributes<HTMLDivElement> {
  type: string;
  onDismiss: Function;
  className?: string; // from styled components
}

const _DismissableNotification = (props: IDismissableNotificationProps) => {
  const { type, children, onDismiss, className } = props;
  return (
    <div className={`${className} ${type} dismissable-notification`}>
      <div className="children">{children}</div>

      <IconButton
        onClick={e => {
          onDismiss(e);
        }}
      >
        {/* close icon */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path
            fillRule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
            clipRule="evenodd"
          />
        </svg>
      </IconButton>
    </div>
  );
};

export const DismissableNotification = styled(_DismissableNotification)`
  display: flex;
  padding: 8px;
  margin-bottom: 8px;

  .children {
    flex: 1;
  }

  &.error {
    border-bottom: 2px solid red;
    background: ${theme.colors.redLight};
    color: ${theme.colors.redFade};
  }
`;

export default DismissableNotification;
