import { initialState, IDownloadsSubdomain } from './model';
import * as Actions from './actions';
import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';

const downloadsReducer = (state: IDownloadsSubdomain = initialState, action: Actions.DownloadsAction) => {
  switch (action.type) {
    case Actions.GET_DOWNLOADS_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.downloadsLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_DOWNLOADS_SUCCESS:
      return produce(state, draftState => {
        draftState.downloads = action.downloads;
        draftState.networkRequests.downloadsLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_DOWNLOADS_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.downloadsLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    default:
      return state;
  }
};

export default downloadsReducer;
