import { useCallback, useEffect, useState } from 'react';
import { getDateRangeDisplayString, dateToMidnightDate } from './utils';
import { isEqual } from 'lodash-es';
import { addDays, addMonths, parseISO, differenceInCalendarDays } from 'date-fns';
import { DateHelper } from 'pureUi/DatePicker';

export interface IDatePickerState {
  showDatePicker: boolean;
  datePickerCurrentDate: string;
  dateSelectionInProgress: boolean;
  anchorDate?: string | undefined;
  startDate: string;
  endDate: string;
  selectedDates: string[];
  totalNights: number;
  displayString: string;
  isPristine: boolean;
}

export interface IDatePickerSateParams extends IDatePickerState {
  handleDayClick: (date: string) => void;
  handleDateMouseOver?: (date: string) => void;
  toggleDatePicker: () => void;
  hideDatePicker: () => void;
  incrementDate: (step: number) => void;
  decrementDate: (step: number) => void;
  resetDatePickerState: () => void;
}

export interface IDatePickerSateProviderProps {
  isSingleDateSelection?: boolean;
  defaultSelectedDates: string[];
  onDateChange?: (dateStrings: string[]) => any;
  render: (state: IDatePickerSateParams) => any;
  placeholder?: string;
}

const _incrementOrDecrementDate = (state: IDatePickerState, step: number): IDatePickerState => {
  const currentDateObj = parseISO(state.datePickerCurrentDate);
  return {
    ...state,
    datePickerCurrentDate:
      step > 0
        ? dateToMidnightDate(addMonths(currentDateObj, 1)).toISOString()
        : dateToMidnightDate(addMonths(currentDateObj, -1)).toISOString(),
  };
};
const _dateRangeSelectStart = (
  state: IDatePickerState,
  date: string,
  isSingleDateSelection: boolean
): IDatePickerState => {
  return {
    ...state,
    dateSelectionInProgress: isSingleDateSelection ? false : true,
    showDatePicker: isSingleDateSelection ? false : true,
    anchorDate: date,
    startDate: date,
    endDate: date,
    displayString: getDateRangeDisplayString(date, date),
    selectedDates: [date],
    totalNights: 0,
    isPristine: false,
  };
};
const _toggleDatePicker = (state: IDatePickerState): IDatePickerState => {
  return {
    ...state,
    showDatePicker: !state.showDatePicker,
  };
};
const _setDatePickerVisibility = (state: IDatePickerState, visible: boolean): IDatePickerState => {
  return {
    ...state,
    showDatePicker: visible,
  };
};
const _dateRangeSelectChangeOrEnd = (
  state: IDatePickerState,
  type: 'change' | 'end',
  date: string
): IDatePickerState => {
  const isFutureDate = !state.anchorDate || date <= state.anchorDate! ? false : true;
  const newStartDate = isFutureDate ? state.anchorDate! : date;
  const newEndDate = isFutureDate ? date : state.anchorDate!;
  const adjustedEndDate =
    type === 'end' && newStartDate === newEndDate ? addDays(new Date(newEndDate), 1).toISOString() : newEndDate;

  const totalNights = differenceInCalendarDays(new Date(adjustedEndDate), new Date(newStartDate!));
  const firstTimestamp = new Date(newStartDate!).getTime();
  const selectedDates = DateHelper.generateDatesFrom(firstTimestamp, totalNights + 1, 'en-US').map(d => d.dateString);

  return {
    ...state,
    dateSelectionInProgress: type === 'change' ? true : false,
    showDatePicker: type === 'end' ? false : true,
    startDate: newStartDate,
    endDate: adjustedEndDate,
    selectedDates,
    totalNights,
    displayString: getDateRangeDisplayString(newStartDate, adjustedEndDate),
  };
};
const _updateSelectedDatesFromDefaultSelectedDates = (
  state: IDatePickerState,
  defaultSelectedDates: string[],
  placeholder: string
): IDatePickerState => {
  return {
    ...state,
    startDate: defaultSelectedDates[0],
    endDate: defaultSelectedDates[defaultSelectedDates.length - 1],
    selectedDates: defaultSelectedDates,
    totalNights: Math.max(0, defaultSelectedDates.length - 1),
    displayString: getDateRangeDisplayString(
      defaultSelectedDates[0],
      defaultSelectedDates[defaultSelectedDates.length - 1],
      placeholder
    ),
  };
};

export const DatePickerStateProvider = (props: IDatePickerSateProviderProps) => {
  const { isSingleDateSelection, defaultSelectedDates } = props;

  const initialState: IDatePickerState = {
    isPristine: true,
    showDatePicker: false,
    datePickerCurrentDate: props.defaultSelectedDates[0] || dateToMidnightDate(new Date()).toISOString(),
    dateSelectionInProgress: false,
    anchorDate: undefined,
    startDate: props.defaultSelectedDates[0],
    endDate: props.defaultSelectedDates[props.defaultSelectedDates.length - 1],
    selectedDates: props.defaultSelectedDates,
    totalNights: Math.max(0, props.defaultSelectedDates.length - 1),
    displayString: getDateRangeDisplayString(
      props.defaultSelectedDates[0],
      props.defaultSelectedDates[props.defaultSelectedDates.length - 1],
      props.placeholder
    ),
  };

  const [state, setState] = useState(initialState);

  const incrementDate = (step: number) => {
    setState(_incrementOrDecrementDate(state, step));
  };

  const toggleDatePicker = () => {
    setState(_toggleDatePicker);
  };

  const hideDatePicker = () => {
    !state.dateSelectionInProgress && setState(_setDatePickerVisibility(state, false));
  };

  const handleDayClick = (date: string) => {
    if (!state.dateSelectionInProgress) {
      const newState = _dateRangeSelectStart(state, date, Boolean(isSingleDateSelection));
      if (isSingleDateSelection) {
        props.onDateChange && props.onDateChange(newState.selectedDates);
      }
      setState(newState);
    } else {
      const newState = _dateRangeSelectChangeOrEnd(state, 'end', date);
      props.onDateChange && props.onDateChange(newState.selectedDates);
      setState(newState);
    }
  };

  const handleDateMouseOver = (date: string) => {
    if (state.dateSelectionInProgress) {
      setState(_dateRangeSelectChangeOrEnd(state, 'change', date));
    }
  };

  const resetDatePickerState = () => {
    setState(initialState);
  };

  // if we change the default selected dates, or we reset it to empty, update the selected dates to match
  useEffect(() => {
    if (!isEqual(defaultSelectedDates, state.selectedDates) || defaultSelectedDates.length === 0) {
      setState(
        _updateSelectedDatesFromDefaultSelectedDates(state, defaultSelectedDates, props.placeholder || 'Select dates')
      );
    }
  }, [defaultSelectedDates]);

  return props.render({
    ...state,
    toggleDatePicker,
    hideDatePicker,
    incrementDate: () => incrementDate(1),
    decrementDate: () => incrementDate(-1),
    handleDayClick,
    handleDateMouseOver,
    resetDatePickerState,
  });
};
