import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBackendApi, ITravelAgentResponse } from 'services/BackendApi';
import {
  GetTravelAgentRequestAction,
  loadSelectedTaViaUuidFailureAction,
  LoadSelectedTaViaUuidRequestAction,
  GetMembershipsRequestAction,
  getMembershipsSuccessAction,
  getMembershipsFailureAction,
} from '../actions';
import {
  getTravelAgentsSuccessAction,
  getTravelAgentsFailureAction,
  GET_TRAVEL_AGENTS_REQUEST,
  LOAD_SELECTED_TA_VIA_UUID_REQUEST,
  loadSelectedTaViaUuidSuccessAction,
  GET_MEMBERSHIPS_REQUEST,
} from '../actions';
import { agentsSelector, selectedTaSelector } from '../selectors';
import * as BookingBuilderActions from 'store/modules/bookingBuilder/subdomains/search/actions';

export function* getTravelAgentsRequestSaga(action: GetTravelAgentRequestAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const currentAgents = yield select(agentsSelector);

    if (!currentAgents || (currentAgents && action.forceLoad)) {
      const result: AxiosResponse<ITravelAgentResponse> = yield call(backendApi.getTravelAgents);
      yield put(getTravelAgentsSuccessAction(result.data.data));
    }

    if (currentAgents && !action.forceLoad) {
      yield put(getTravelAgentsSuccessAction(currentAgents));
    }
  } catch (e) {
    yield put(getTravelAgentsFailureAction(e.message));
  }
}

export function* loadSelectedTaViaUuidSaga(action: LoadSelectedTaViaUuidRequestAction) {
  try {
    const backendApi = makeBackendApi(action.tauuid);

    const result: AxiosResponse<ITravelAgentResponse> = yield call(backendApi.getTravelAgentViaUuid, action.tauuid);
    yield put(loadSelectedTaViaUuidSuccessAction(result.data.data));
  } catch (e) {
    yield put(loadSelectedTaViaUuidFailureAction('Couldnt load travel agent'));
  }
}

export function* getMembershipsSaga(action: GetMembershipsRequestAction) {
  try {
    const backendApi = makeBackendApi();
    const result: AxiosResponse = yield call(backendApi.fetchCompanyMemberships, action.companyUuid, {
      filter: {
        companyMembership: {
          companyUuid: action.companyUuid,
          status: true,
        },
      },
    });

    yield put(getMembershipsSuccessAction(result.data.data, action.resetSelected));
    if (action.setInBookingBuilder) {
      yield put(BookingBuilderActions.setTravelCompanyMembershipAction(result.data.data[0]));
    }
  } catch (e) {
    yield put(getMembershipsFailureAction());
  }
}

export function* watchGetTravelAgentsRequest() {
  yield takeLatest([GET_TRAVEL_AGENTS_REQUEST], getTravelAgentsRequestSaga);
  yield takeLatest([LOAD_SELECTED_TA_VIA_UUID_REQUEST], loadSelectedTaViaUuidSaga);
  yield takeLatest([GET_MEMBERSHIPS_REQUEST], getMembershipsSaga);
}
