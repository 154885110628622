import { createSelector } from 'reselect';
import { guestInformationSelector } from '../../selectors';
import { IGuestInformationSubdomain } from './model';
import { isNilOrEmpty } from 'ramda-adjunct';
import * as DateFNS from 'date-fns';
import { DAYS_AFTER_LAST_CHECKOUT_DISABLE_SR_EDITING } from 'config';
import { isSr } from 'store/modules/auth';

export const guestsSelector = createSelector(
  guestInformationSelector,
  (subdomain: IGuestInformationSubdomain) => subdomain.guests
);

export const guestsLoadingSelector = createSelector(
  guestInformationSelector,
  (subdomain: IGuestInformationSubdomain) => subdomain.loading
);

export const guestsSavingSelector = createSelector(
  guestInformationSelector,
  (subdomain: IGuestInformationSubdomain) => subdomain.saving
);

export const guestsErrorSelector = createSelector(
  guestInformationSelector,
  (subdomain: IGuestInformationSubdomain) => subdomain.error
);

export const airlinesSelector = createSelector(
  guestInformationSelector,
  (subdomain: IGuestInformationSubdomain) => subdomain.airlines
);

export const airlinesLoadSelector = createSelector(
  guestInformationSelector,
  (subdomain: IGuestInformationSubdomain) => subdomain.airlinesLoad
);

export const lastDepartureDateSelector = createSelector(
  guestsSelector,
  (guests: IGuestInformationSubdomain['guests']) => {
    if (guests === null || guests.length <= 0) {
      return undefined;
    }

    const departureDates = guests
      .map(g => g.departureDate)
      .filter(d => !isNilOrEmpty(d))
      .sort((a: string, b: string) => (a > b ? -1 : a < b ? 1 : 0));

    return departureDates[0];
  }
);

export const isSRBlockedViaLastCheckoutSelector = createSelector(
  lastDepartureDateSelector,
  (lastDateString: string | undefined) => {
    if (lastDateString === undefined) {
      return false;
    }

    const lastDate = new Date(lastDateString);

    const today = new Date(Date.now());

    if (!DateFNS.isAfter(today, lastDate)) {
      return false;
    }

    return DateFNS.differenceInDays(today, lastDate) > DAYS_AFTER_LAST_CHECKOUT_DISABLE_SR_EDITING;
  }
);

export const isSRAndIsBlockedViaLastCheckoutSelector = createSelector(
  isSr,
  isSRBlockedViaLastCheckoutSelector,
  (isSr, isSRBlocked) => {
    return isSr && isSRBlocked;
  }
);
