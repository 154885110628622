export const OPEN_HOTEL_MODAL = 'bookingBuilder/OPEN_HOTEL_MODAL';
export const CLOSE_HOTEL_MODAL = 'bookingBuilder/CLOSE_HOTEL_MODAL';
export const TOGGLE_ACCORDION_ITEM_STATUS = 'bookingBuilder/SET_ACCORDION_ITEM_STATUS';

export type OpenHotelModalAction = ReturnType<typeof openHotelModalAction>;
export const openHotelModalAction = () => ({
  type: OPEN_HOTEL_MODAL as typeof OPEN_HOTEL_MODAL,
});

export type CloseHotelModalAction = ReturnType<typeof closeHotelModalAction>;
export const closeHotelModalAction = () => ({
  type: CLOSE_HOTEL_MODAL as typeof CLOSE_HOTEL_MODAL,
});

export type ToggleAccordionItemStatusAction = ReturnType<typeof toggleAccordionItemStatusAction>;
export const toggleAccordionItemStatusAction = (itemIndex: number) => ({
  type: TOGGLE_ACCORDION_ITEM_STATUS as typeof TOGGLE_ACCORDION_ITEM_STATUS,
  itemIndex,
});


export type BookingBuilderHotelModalAction =
  | OpenHotelModalAction
  | CloseHotelModalAction
  | ToggleAccordionItemStatusAction;
