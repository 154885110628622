import { call, takeLatest, select, put } from 'redux-saga/effects';
import { IUploadCloudResponse, makeBackendApi } from 'services/BackendApi';
import { enqueueNotification } from "store/modules/ui";
import { closeUploadAttachmentModalAction, listAttachmentRequestAction, UPLOAD_ATTACHMENT_REQUEST, uploadAttachmentFailureAction, UploadAttachmentRequestAction, uploadAttachmentSuccessAction } from '../actions';
import { newTaskAttachmentPayloadSelector } from '../selectors';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';

// eslint-disable-next-line no-unused-vars
export function* uploadTaskAttachmentSaga(action: UploadAttachmentRequestAction) {
  try {
    const taskManagementApi = makeTaskManagementApi();
    const backendApi = makeBackendApi();
    const payload = yield select(newTaskAttachmentPayloadSelector);
    
    const formData = new FormData();
    formData.append('file', payload.file);
    
    const result: IUploadCloudResponse = yield call(backendApi.uploadFileCloud, formData);
    const { url } = result.data;
    
    
    yield call(taskManagementApi.addAttachmentToTask, action.taskId, payload.displayName, url);
    yield put(uploadAttachmentSuccessAction());
    yield put(listAttachmentRequestAction(action.taskId));
    yield put(closeUploadAttachmentModalAction());
    yield put(
      enqueueNotification({
        message: 'The file has been uploaded successfully.',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(closeUploadAttachmentModalAction());
    yield put(uploadAttachmentFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Error uploading the file.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchUploadTaskAttachmentSaga() {
  yield takeLatest(
    [UPLOAD_ATTACHMENT_REQUEST],
    uploadTaskAttachmentSaga
  );
}
