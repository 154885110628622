import { all } from 'redux-saga/effects';
import { watchInitializeQuery } from './initializeQuerySaga';
import { watchGetCompaniesRequest } from './getCompaniesSaga';
import { watchGetTravelAgentBycompanyIdRequest } from './getTravelAgentsForCompanySaga';
import { watchDestinationChange } from './nameSearchSaga';
import { watchExecute } from './executeSaga';

export function* watchBookingBuilderSearch() {
  yield all([
    watchInitializeQuery(),
    watchGetCompaniesRequest(),
    watchGetTravelAgentBycompanyIdRequest(),
    watchDestinationChange(),
    watchExecute()
  ]);
}
