import * as React from 'react';
import { formatDateDisplay } from 'utils';
import classNames from 'classnames';
import { IGuest } from 'services/BackendApi/types';
import { GuestDetailsOverviewStyles } from './GuestDetailsOverviewStyles';
import { EBookingManageRoutes } from 'containers/BookingManager/routes';
import SectionTitle from 'ui/SectionTitle';

const renderDates = (date1: string | undefined, date2: string | undefined) => {
  if (!date1 && !date2) {
    return '';
  }
  const dates = [date1 ? formatDateDisplay(date1) : '...', date2 ? formatDateDisplay(date2) : '...'];

  return dates.join(' - ');
};

interface IGuestDetailsWrapperProps {
  hasContent?: boolean;
  children?: React.ReactNode;
}

const GuestDetailsWrapper: React.FC<IGuestDetailsWrapperProps> = ({ hasContent, children }) => {
  return (
    <GuestDetailsOverviewStyles className="guest-details-overview font-pt-sans">
      <div className="table font-pt-sans w-full">
        <div className="header">
          <SectionTitle>Guest Details</SectionTitle>
        </div>
        <div className={`${hasContent && 'cells'} content text-black min-h-70px flex items-center`}>{children}</div>
        <div className="footer bg-brown-10">
          <a className="view" href={EBookingManageRoutes.GuestInformation}>
            View guest information
          </a>
        </div>
      </div>
    </GuestDetailsOverviewStyles>
  );
};
interface IGuestDetailsOverviewProps {
  guests: IGuest[] | null;
  isLoading: boolean;
  error: string | null;
}

export const GuestDetailsOverview: React.FC<IGuestDetailsOverviewProps> = ({ guests, isLoading, error }) => {
  if (error !== null) {
    return (
      <GuestDetailsWrapper>
        <span className="info error text-red-100">{error}</span>
      </GuestDetailsWrapper>
    );
  }

  if (isLoading || !guests) {
    return (
      <GuestDetailsWrapper>
        <span className="info loading">Loading...</span>
      </GuestDetailsWrapper>
    );
  }

  // The lead guest should be first in the list
  const leadGuest = guests.filter(guest => guest.isLeadGuest);
  const otherGuests = guests.filter(guest => !guest.isLeadGuest);
  const orderedGuests = [...leadGuest, ...otherGuests];

  const renderGuests = (guests: IGuest[]) => {
    return guests.map((guest: IGuest, guestIndex: number) => {
      const cellClassName: string = classNames({
        lastRow: guests.length - 1 === guestIndex,
      });

      return (
        <React.Fragment key={guest.uuid}>
          <div className={`name tableCell ${cellClassName}`}>
            {guest.firstName} {guest.lastName}
          </div>
          <div className={`arrival-flight tableCell text-right ${cellClassName}`}>{guest.arrivalFlightNo}</div>
          <div className={`departure-flight tableCell text-right ${cellClassName}`}>{guest.departureFlightNo}</div>
          <div className={`stay-dates tableCell text-right ${cellClassName}`}>
            {renderDates(guest.arrivalDate, guest.departureDate)}
          </div>
        </React.Fragment>
      );
    });
  };

  return <GuestDetailsWrapper hasContent>{renderGuests(orderedGuests)}</GuestDetailsWrapper>;
};

export default GuestDetailsOverview;
