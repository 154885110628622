import { IHotel } from 'services/BackendApi';

export const SET_HOTEL = 'bookingBuilder/SET_HOTEL';
export const UNSET_HOTEL = 'bookingBuilder/UNSET_HOTEL';
export const GET_HOTEL_REQUEST = 'bookingBuilder/GET_HOTEL_REQUEST';
export const GET_HOTEL_SUCCESS = 'bookingBuilder/GET_HOTEL_SUCCESS';
export const GET_HOTEL_FAILURE = 'bookingBuilder/GET_HOTEL_FAILURE';

export type SetHotelAction = ReturnType<typeof setHotelAction>;
export const setHotelAction = (hotel: IHotel) => ({
  type: SET_HOTEL as typeof SET_HOTEL,
  hotel,
});

export type UnsetHotelAction = ReturnType<typeof unsetHotelAction>;
export const unsetHotelAction = () => ({
  type: UNSET_HOTEL as typeof UNSET_HOTEL,
});

export type GetHotelRequestAction = ReturnType<typeof getHotelRequestAction>;
export const getHotelRequestAction = (hotelUuid: string) => ({
  type: GET_HOTEL_REQUEST as typeof GET_HOTEL_REQUEST,
  hotelUuid,
});

export type GetHotelSuccessAction = ReturnType<typeof getHotelSuccessAction>;
export const getHotelSuccessAction = (hotel: IHotel) => ({
  type: GET_HOTEL_SUCCESS as typeof GET_HOTEL_SUCCESS,
  hotel,
});

export type GetHotelFailureAction = ReturnType<typeof getHotelFailureAction>;
export const getHotelFailureAction = (errorMessage: string) => ({
  type: GET_HOTEL_FAILURE as typeof GET_HOTEL_FAILURE,
  errorMessage,
});

export type BookingBuilderHotelAction =
| SetHotelAction
| UnsetHotelAction
| GetHotelRequestAction
| GetHotelSuccessAction
| GetHotelFailureAction;
