import React from 'react';
import { IHotel, ISeasonalProductRate, ISupplementProduct } from 'services/BackendApi';
import { IBootstrapCountry } from 'store/modules/bootstrap/model';
import * as _ from 'lodash-es';
import * as Inputs from './Inputs';
import FluidButton from 'ui/FluidButton';

export const SupplementRateDetails = ({
  hotel,
  supplement,
  seasonalProductRate,
  bootstrapCountries,
  onUpdateSeasonalProductRate,
  onCta,
  ctaLabel,
}: {
  hotel: IHotel;
  supplement: ISupplementProduct;
  seasonalProductRate: Partial<ISeasonalProductRate>;
  bootstrapCountries: IBootstrapCountry[];
  onUpdateSeasonalProductRate: (field: any, value: any) => void;
  onCta: () => void;
  ctaLabel: string;
}) => {
  const allSeasons = hotel.seasons || [];

  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Select
        label="Season"
        value={seasonalProductRate.seasonUuid || ''}
        onChange={val => {
          onUpdateSeasonalProductRate('seasonUuid', val);
        }}
        options={allSeasons.map(season => {
          return {
            value: season.uuid,
            label: season.name,
          };
        })}
      />

      <Inputs.Checkbox
        label="Is on request"
        value={seasonalProductRate.isOnRequest || false}
        onChange={val => {
          onUpdateSeasonalProductRate('isOnRequest', val);
        }}
      />

      {seasonalProductRate.isOnRequest === false && (
        <Inputs.Input
          label="Rate"
          value={seasonalProductRate.rate || ''}
          onChange={val => {
            onUpdateSeasonalProductRate('rate', val);
          }}
        />
      )}

      <Inputs.Input
        label="Markup Amount"
        value={seasonalProductRate.markupAmount || ''}
        onChange={val => {
          onUpdateSeasonalProductRate('markupAmount', val);
        }}
      />

      <Inputs.RadioCollection
        label="Markup Type"
        onChange={val => {
          onUpdateSeasonalProductRate('markupType', val);
        }}
        collection={Inputs.markupTypes.map(markupType => {
          return {
            isChecked: markupType.value === seasonalProductRate.markupType,
            value: markupType.value,
            label: markupType.label,
          };
        })}
      />

      <Inputs.Input
        label="Internal description"
        value={seasonalProductRate.internalDescription || ''}
        onChange={val => {
          onUpdateSeasonalProductRate('internalDescription', val);
        }}
      />

      <Inputs.Input
        label="Payment terms"
        value={seasonalProductRate.paymentTerms || ''}
        onChange={val => {
          onUpdateSeasonalProductRate('paymentTerms', val);
        }}
      />

      <Inputs.Input
        label="Cancellation policy"
        value={seasonalProductRate.cancellationPolicy || ''}
        onChange={val => {
          onUpdateSeasonalProductRate('cancellationPolicy', val);
        }}
      />

      <span className="text-sm">Alternate Rates</span>
      {(supplement.options?.ages || []).map(ageBracket => {
        const matchingAlternateRate = _.get(seasonalProductRate, `alternateRates.${ageBracket.name}`, undefined);

        return (
          <div key={ageBracket.name} className="grid grid-cols-4 gap-4">
            <span className="mt-2">{ageBracket.name}</span>
            <span className="-mt-10px">
              <span className="text-sm">Rate</span>
              <Inputs.Input
                value={matchingAlternateRate?.rate || ''}
                onChange={val => {
                  onUpdateSeasonalProductRate(`alternateRates.${ageBracket.name}.rate`, val);
                }}
              />
            </span>
            <span className="-mt-10px">
              <span className="text-sm">Markup Amount</span>
              <Inputs.Input
                value={matchingAlternateRate?.markupAmount || ''}
                onChange={val => {
                  onUpdateSeasonalProductRate(`alternateRates.${ageBracket.name}.markupAmount`, val);
                }}
              />
            </span>
            <Inputs.RadioCollection
              collection={Inputs.markupTypes.map(mt => {
                return {
                  isChecked: matchingAlternateRate?.markupType === mt.value,
                  value: mt.value,
                  label: mt.label,
                };
              })}
              onChange={val => {
                onUpdateSeasonalProductRate(`alternateRates.${ageBracket.name}.markupType`, val);
              }}
            />
          </div>
        );
      })}

      {(supplement.options?.ages || []).length <= 0 && (
        <span className="text-sm text-gray-100 italic">No age brackets defined</span>
      )}

      <Inputs.CountryList
        selectedCountries={seasonalProductRate.countries ? seasonalProductRate.countries : []}
        label="Countries"
        bootstrapCountries={bootstrapCountries}
        onChange={val => {
          onUpdateSeasonalProductRate('countries', val);
        }}
      />

      <FluidButton
        type="primary"
        className="w-[250px] self-end"
        onClick={() => {
          onCta();
        }}
      >
        {ctaLabel}
      </FluidButton>
    </div>
  );
};
