import { EUserType } from 'services/BackendApi';

export const FILTER_BY_COMPANY_PERMISSIONS = 'FILTER_BY_COMPANY_PERMISSION';
export const FILTER_BY_CONTACT_PERMISSIONS = 'FILTER_BY_CONTACT_PERMISSIONS';
export const USE_ADDITIONAL_FILTERS_PERMISSIONS = 'USE_ADDITIONAL_FILTERS_PERMISSIONS';
export const GROUND_TEAM_EXPORT_PERMISSIONS = 'GROUND_TEAM_EXPORT_PERMISSIONS';
export const FINANCE_TEAM_EXPORT_PERMISSIONS = 'FINANCE_TEAM_EXPORT_PERMISSIONS';

const ROLES_AND_PERMISSIONS = {
  [EUserType.ADMIN]: [
    FILTER_BY_COMPANY_PERMISSIONS,
    FILTER_BY_CONTACT_PERMISSIONS,
    USE_ADDITIONAL_FILTERS_PERMISSIONS,
    GROUND_TEAM_EXPORT_PERMISSIONS,
    FINANCE_TEAM_EXPORT_PERMISSIONS
  ],
  [EUserType.FINANCE]: [
    FILTER_BY_COMPANY_PERMISSIONS,
    FILTER_BY_CONTACT_PERMISSIONS,
    USE_ADDITIONAL_FILTERS_PERMISSIONS,
    GROUND_TEAM_EXPORT_PERMISSIONS,
    FINANCE_TEAM_EXPORT_PERMISSIONS
  ],
  [EUserType.RL]: [
    FILTER_BY_COMPANY_PERMISSIONS,
    FILTER_BY_CONTACT_PERMISSIONS,
    USE_ADDITIONAL_FILTERS_PERMISSIONS
  ],
  [EUserType.SR]: [
    FILTER_BY_COMPANY_PERMISSIONS,
    FILTER_BY_CONTACT_PERMISSIONS,
    USE_ADDITIONAL_FILTERS_PERMISSIONS,
    GROUND_TEAM_EXPORT_PERMISSIONS
  ],
  [EUserType.TA]: [
    FILTER_BY_CONTACT_PERMISSIONS,
  ],
};

export const hasPermissions = (userType: EUserType, permissions: string): boolean =>
  (ROLES_AND_PERMISSIONS[userType] || []).includes(permissions);
