import React from 'react';

interface IBlockingOverlayProps {
  showWhen: boolean;
  children?: React.ReactNode;
}

export const BlockingOverlay: React.FC<IBlockingOverlayProps> = ({ children, showWhen }) => {
  return (
    <div className="relative">
      {children}
      {showWhen && <div className="blocking-overlay absolute top-0 bottom-0 left-0 right-0"></div>}
    </div>
  );
};
