import * as Actions from './actions';
import { hotelModalInitialState, IHotelModalSubdomain } from './model';

export const bookingBuilderHotelModalReducer = (
  state: IHotelModalSubdomain = hotelModalInitialState,
  action: Actions.BookingBuilderHotelModalAction
): IHotelModalSubdomain => {
  switch (action.type) {
    case Actions.OPEN_HOTEL_MODAL:
      return {
        ...state,
        isOpen: true,
      };

    case Actions.CLOSE_HOTEL_MODAL:
      return {
        ...state,
        isOpen: false,
        accordionItemsStatus: hotelModalInitialState.accordionItemsStatus,
      };

    case Actions.TOGGLE_ACCORDION_ITEM_STATUS:
      // eslint-disable-next-line no-case-declarations
      const isClosed = state.accordionItemsStatus[action.itemIndex] === false;
      return {
        ...state,
        accordionItemsStatus: state.accordionItemsStatus.map((item, index) => {
          return isClosed ? index === action.itemIndex : false;
        })
      };

    default:
      return state;
  }
};
