import React, { useMemo } from 'react';
import classNames from 'classnames';
import { ETravelAgentStatus } from 'services/BackendApi/types/TravelAgentResponse';

export const statuses = [
  { value: ETravelAgentStatus.PENDING, label: 'Pending', color: '#4D98FE' },
  { value: ETravelAgentStatus.ACCEPTED, label: 'Accepted', color: '#F49C20' },
  { value: ETravelAgentStatus.VERIFIED, label: 'Verified', color: '#27BE58' },
  { value: ETravelAgentStatus.REJECTED, label: 'Rejected', color: '#FD4B4C' },
];

export interface IUserStatusProps {
  className?: string;
  value: ETravelAgentStatus;
}

export const UserStatus: React.FC<IUserStatusProps> = (props) => {
  const status = useMemo(
    () => statuses.find(x => x.value === props.value),
    [props.value]
  );

  return (
    <div className={classNames(props.className, 'flex items-center justify-between w-full')}>
      <span>{status?.label ?? ''}</span>
      <div className="indicator" style={{
        height: '12px',
        width: '12px',
        borderRadius: '50%',
        backgroundColor: status?.color || '#000'
      }}/>
    </div>
  )
};
