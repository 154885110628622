import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBookingManagerApi, ICompanyMembershipMicrodocResponse } from 'services/BookingManagerApi';
import {
  GET_MEMBERSHIP_REQUEST,
  getMembershipSuccessAction,
  getMembershipFailureAction,
} from '../actions';

import { bookingUuidSelector } from '../../../selectors';

export function* getMembershipSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<ICompanyMembershipMicrodocResponse> = yield call(
      bookingManagerApi.getCompanyMembership,
      bookingUuid
    );

    yield put(getMembershipSuccessAction(result.data.membershipData));
  } catch (e) {
    yield put(getMembershipFailureAction(e));
  }
}

export function* watchBookingManagerMembership() {
  yield takeLatest([GET_MEMBERSHIP_REQUEST], getMembershipSaga);
}
