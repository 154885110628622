import { ENetworkRequestStatus } from 'services/BackendApi';

// Your module domain should `extends` this interfac
export interface IExportCsvModel {
  exportCsvNamespace: {
    triggerExportCsvLoad: ENetworkRequestStatus;
    pollExportCsvLoad: ENetworkRequestStatus;
    exportUuid: string | null;
    exportSignedUrl: string | null;
    isWaitingForExport: boolean;
  };
}

//... and inside your `initialState` object, spead this object
export const exportCsvInitialState: IExportCsvModel = {
  exportCsvNamespace: {
    triggerExportCsvLoad: ENetworkRequestStatus.IDLE,
    pollExportCsvLoad: ENetworkRequestStatus.IDLE,
    exportUuid: '',
    exportSignedUrl: '',
    isWaitingForExport: false,
  },
}
