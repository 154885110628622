import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const SaveIcon: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors['brown-100'], height = 19, width = 19 }) => (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0833 2.75L4.08325 2.75C3.25075 2.75 2.58325 3.425 2.58325 4.25L2.58325 14.75C2.58325 15.575 3.25075 16.25 4.08325 16.25H14.5833C15.4083 16.25 16.0833 15.575 16.0833 14.75V5.75L13.0833 2.75ZM9.33325 14.75C8.08825 14.75 7.08325 13.745 7.08325 12.5C7.08325 11.255 8.08825 10.25 9.33325 10.25C10.5783 10.25 11.5833 11.255 11.5833 12.5C11.5833 13.745 10.5783 14.75 9.33325 14.75ZM4.08325 7.25L11.5833 7.25V4.25L4.08325 4.25L4.08325 7.25Z"
        fill={fill}
      />
    </svg>
  )
);

export default SaveIcon;
