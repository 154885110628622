import { CompanyManagerUserField, ICompanyManagersDomain, initialState, SortOrder } from './model';
import * as Actions from './actions';
import * as CompanyInfoActions from '../companyInfo/actions';

import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';

export const companyManagersReducer = (
  state: ICompanyManagersDomain = initialState,
  action: Actions.Any | CompanyInfoActions.CompanyInfoAction
) => {
  switch (action.type) {
    case Actions.GET_REQUEST_IN_TC:
    case Actions.GET_REQUEST:
      return produce(state, draftState => {
        draftState.request.get = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    
    case Actions.GET_SUCCESS_IN_TC:
    case Actions.GET_SUCCESS:
      return produce(state, draftState => {
        draftState.request.get = ENetworkRequestStatus.SUCCESS;
        draftState.travelAgents = action.travelAgents.map(agent => {
          if (agent.notifications) {
            agent.notifications = agent.notifications.filter(n => n.role === 'ta') || [];
            return { ...agent };
          } else {
            return { ...agent };
          }
        });
        const companyBookingManagers: string[] = [];
        const companyTaManagers: string[] = [];
        action.travelAgents.forEach(ta => {
          if (ta.companyBookingManager) {
            companyBookingManagers.push(ta.uuid);
          }
          if (ta.companyTaManager) {
            companyTaManagers.push(ta.uuid);
          }
        });

        draftState.savedBookingManagers = new Set(companyBookingManagers);
        draftState.savedTaManagers = new Set(companyTaManagers);
        return draftState;
      });

    case Actions.GET_FAILURE_IN_TC:
    case Actions.GET_FAILURE:
      return produce(state, draftState => {
        draftState.request.get = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.UPDATE_REQUEST:
      return produce(state, draftState => {
        draftState.request.post = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.UPDATE_SUCCESS:
      return produce(state, draftState => {
        draftState.request.post = ENetworkRequestStatus.SUCCESS;
        const companyBookingManagers: string[] = [];
        const companyTaManagers: string[] = [];
        draftState.travelAgents!.forEach(ta => {
          if (ta.companyBookingManager) {
            companyBookingManagers.push(ta.uuid);
          }
          if (ta.companyTaManager) {
            companyTaManagers.push(ta.uuid);
          }
        });

        draftState.savedBookingManagers = new Set(companyBookingManagers);
        draftState.savedTaManagers = new Set(companyTaManagers);
        return draftState;
      });

    case Actions.UPDATE_FAILURE:
      return produce(state, draftState => {
        draftState.request.post = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.TOGGLE_ITEM:
      return produce(state, draftState => {
        const x = draftState.travelAgents!.find(x => x.uuid === action.uuid);
        x![CompanyManagerUserField[action.managerType]] = !x![CompanyManagerUserField[action.managerType]];
        return draftState;
      });

    case CompanyInfoActions.PATCH_COMPANY_REQUEST:
      return produce(state, draftState => {
        draftState.request.patch = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    
    case CompanyInfoActions.PATCH_COMPANY_SUCCESS:
      return produce(state, draftState => {
        draftState.request.patch = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case CompanyInfoActions.PATCH_COMPANY_FAILURE:
      return produce(state, draftState => {
        draftState.request.patch = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.TOGGLE_SORT_BY:
      return produce(state, draftState => {
        draftState.sortBy = action.sortBy;
        return draftState;
      });

    case Actions.TOGGLE_SORT_ORDER:
      return produce(state, draftState => {
        draftState.sortOrder = draftState.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        return draftState;
      });

    default:
      return state;
  }
};
