import React, { Fragment, useCallback } from 'react';
import Home from 'pages/Home';

import { propTypes } from './PasswordReset.props';
import { ForgotPasswordModal } from 'ui/ForgotPasswordModal';

export const PasswordReset = ({ history, ...props }) => {
  const onClose = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <Fragment>
      <Home history={history} {...props} />
      <ForgotPasswordModal isOpen onClose={onClose} />
    </Fragment>
  );
};

PasswordReset.propTypes = propTypes;

export default PasswordReset;
