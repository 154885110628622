import { ENetworkRequestStatus } from 'services/BackendApi';

import * as Actions from './actions';
import { ICompanyDocumentsDomain, initialState } from './model';

export const companyDocumentsReducer = (
  state: ICompanyDocumentsDomain = initialState,
  action: Actions.CompanyDocumentsAction
) => {
  switch (action.type) {
    case Actions.FETCH_COMPANY_DOCUMENTS_REQUEST:
      return {
        ...state,
        documents: [],
        documentsLoad: ENetworkRequestStatus.PENDING,
        documentsLoadError: undefined,
      };

    case Actions.FETCH_COMPANY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.documents,
        documentsLoad: ENetworkRequestStatus.SUCCESS,
        documentsLoadError: undefined,
      };

    case Actions.FETCH_COMPANY_DOCUMENTS_FAILURE:
      return {
        ...state,
        documents: [],
        documentsLoad: ENetworkRequestStatus.ERROR,
        documentsLoadError: action.error,
      };

    case Actions.SET_COMPANY_DOCUMENTS_SORT_FIELD:
      return {
        ...state,
        documentsSortField: action.sortField,
        documentsSortOrder: action.sortOrder,
      };

    case Actions.OPEN_UPLOAD_COMPANY_DOCUMENT_MODAL:
      return {
        ...state,
        uploadDocumentModalOpen: true,
        newDocument: {
          payload: {
            file: null,
            displayName: null,
            tag: null,
          },
        },
      };

    case Actions.CLOSE_UPLOAD_COMPANY_DOCUMENT_MODAL:
      return {
        ...state,
        uploadDocumentModalOpen: false,
        newDocument: {
          ...state.newDocument,
          payload: null,
        },
      };

    case Actions.SET_COMPANY_DOCUMENT_TO_UPLOAD:
      return {
        ...state,
        newDocument: {
          ...state.newDocument,
          payload: {
            ...state.newDocument.payload,
            file: action.document,
            displayName: action.document.name,
          },
        },
      };

    case Actions.SET_COMPANY_DOCUMENT_DISPLAY_NAME:
      return {
        ...state,
        newDocument: {
          ...state.newDocument,
          payload: {
            ...state.newDocument.payload,
            displayName: action.displayName,
          },
        },
      };

    case Actions.SET_COMPANY_DOCUMENT_TAG:
      return {
        ...state,
        newDocument: {
          ...state.newDocument,
          payload: {
            ...state.newDocument.payload,
            tag: action.tag,
          },
        },
      };

    case Actions.UPLOAD_COMPANY_DOCUMENT_REQUEST:
      return {
        ...state,
        newDocument: {
          ...state.newDocument,
          request: ENetworkRequestStatus.PENDING,
          error: null,
        },
      };

    case Actions.UPLOAD_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        newDocument: {
          ...state.newDocument,
          request: ENetworkRequestStatus.SUCCESS,
          error: null,
        },
        uploadDocumentModalOpen: false,
      };

    case Actions.UPLOAD_COMPANY_DOCUMENT_FAILURE:
      return {
        ...state,
        newDocument: {
          ...state.newDocument,
          request: ENetworkRequestStatus.ERROR,
          error: action.error,
        },
      };

    case Actions.SET_DELETE_COMPANY_DOCUMENT:
      return {
        ...state,
        deleteDocument: {
          uuid: action.uuid,
          request: ENetworkRequestStatus.IDLE,
        },
      };

    case Actions.CANCEL_DELETE_COMPANY_DOCUMENT:
      return {
        ...state,
        deleteDocument: initialState.deleteDocument,
      };

    case Actions.DELETE_COMPANY_DOCUMENT_REQUEST:
      return {
        ...state,
        deleteDocument: {
          ...state.deleteDocument,
          request: ENetworkRequestStatus.PENDING,
        },
      };

    case Actions.DELETE_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteDocument: {
          error: null,
          uuid: null,
          request: ENetworkRequestStatus.SUCCESS,
        },
      };

    case Actions.DELETE_COMPANY_DOCUMENT_FAILURE:
      return {
        ...state,
        deleteDocument: {
          error: action.error,
          uuid: null,
          request: ENetworkRequestStatus.ERROR,
        },
      };

    default:
      return state;
  }
};
