import { IHeadlineLineItemBreakdownLineItem } from 'services/BookingManagerApi';

export const calculateItemsPurchaseCostSubtotalCents = (
  accomSupplementItemsArray: IHeadlineLineItemBreakdownLineItem[]
) => {
  const epsPurchaseCostSubtotalCents = accomSupplementItemsArray.reduce((acc, item) => {
    return item.purchaseCostCents ? acc + item.purchaseCostCents : acc;
  }, 0);

  return epsPurchaseCostSubtotalCents;
};

export const calculateItemsSaleCostSubtotalCents = (
  accomSupplementItemsArray: IHeadlineLineItemBreakdownLineItem[]
) => {
  const epsSubtotalCents = accomSupplementItemsArray.reduce((acc, item) => {
    return item.saleCostCents ? acc + item.saleCostCents : acc;
  }, 0);

  return epsSubtotalCents;
};
