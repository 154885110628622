import React from 'react';
import { IGroundServiceProduct, ISupplementProduct } from 'services/BackendApi';
import * as Inputs from '../Inputs';
import FluidButton from 'ui/FluidButton';
import { validateGroundService } from 'containers/HotelAdmin/validation';
import { useDispatch } from 'react-redux';
import { enqueueNotification } from 'store/modules/ui';
export const GroundService = ({
  groundService,
  onUpdate,
  onCta,
  ctaLabel,
}: {
  groundService: Partial<IGroundServiceProduct>;
  onUpdate: (field: keyof any, value: any) => void;
  onCta: () => void;
  ctaLabel: string;
}) => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Input
        label={'Name'}
        onChange={val => {
          onUpdate('name', val);
        }}
        value={groundService.name || ''}
      />

      <Inputs.TextArea
        label={'Description'}
        onChange={val => {
          onUpdate('meta', {
            ...groundService.meta,
            description: val,
          });
        }}
        value={groundService.meta.description}
        rows={15}
      />

      <Inputs.RadioCollection
        label="Category"
        collection={[...Inputs.groundServiceCategoryTypes].map(mt => {
          return {
            isChecked: groundService.category === mt.value,
            value: mt.value,
            label: mt.label,
          };
        })}
        onChange={val => {
          onUpdate('category', val);
        }}
      />

      {(groundService.category === 'perPerson' || groundService.category === 'perPersonPerNight') && (
        <Inputs.AgeGroups
          label={'Age Groups'}
          collection={groundService.options?.ages || []}
          onChange={newList => {
            onUpdate('options', {
              ...groundService.options,
              ages: newList,
            });
          }}
        />
      )}

      <FluidButton
        type="primary"
        className="self-end w-[200px] mt-4"
        onClick={() => {
          const validation = validateGroundService(groundService);
          if (validation.isValid === false) {
            dispatch(
              enqueueNotification({
                message: `Ground service is not valid: ${validation.message}`,
                options: { variant: 'warning' },
              })
            );
            return;
          }
          onCta();
        }}
      >
        {ctaLabel}
      </FluidButton>
    </div>
  );
};
