export interface IInventorySupplierSearchContractingParams {
  name?: string;
  public_id?: string;
  country_iso?: string;
  page: number;
  page_size: number;
  order_by?:
    | "created_at"
    | "public_id"
    | "name"
    | "country_iso"
    | "currency_iso";
  order_type?: "asc" | "desc";
}

export interface IInventorySupplierSearchContractingResponse {
  results: IInventorySupplierContracting[];
  page_size: number;
  total_pages: number;
  total_items: number;
}

export enum EInventorySupplierContactItemType {
  'e-mail' = 'e-mail',
  'Phone' = 'Phone',
  'Whatsapp' = 'Whatsapp',
}

export interface IInventorySupplierWithContacts {
  uuid?: string;
  name: string;
  public_id: string;
  country: string | null;
  currency: string | null;
  additional_info: string;
  address: string;
  contacts?: IInventoryContactForSaving[];
  created_at?: string;
}

export interface IInventorySupplierForSaving {
  uuid?: string;
  name: string;
  public_id: string;
  country: string | null;
  currency: string | null;
  additional_info: string;
  address: string;
}

export interface IInventorySupplierSimple {
  uuid: string;
  name: string;
  public_id: string;
  country: {
    name: string;
    code: string;
  };
}

export interface IInventorySupplierContracting {
  uuid: string;
  public_id: string;
  name: string;
  created_at: string;
  additional_data: {
    geographic: {
      country_iso: string;
      country: string;
    };
    financial: {
      currency_iso: string;
      currency: string;
      currency_symbol: string;
    };
  };
}

export interface IInventoryContactItemForSaving {
  type: EInventorySupplierContactItemType;
  value: string;
  additional_info: string;
}

export interface IInventoryContactForSaving {
  uuid?: string;
  name: string;
  description: string;
  category: string;
  is_main: boolean;
  items: IInventoryContactItemForSaving[];
}
