import { call, takeLatest, select, put } from 'redux-saga/effects';
import { DELETE_ATTACHMENT_REQUEST, DeleteAttachmentRequestAction, deleteAttachmentFailureAction, deleteAttachmentSuccessAction, listAttachmentRequestAction } from '../actions';
import { enqueueNotification } from 'store/modules/ui';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';
import { selectedAttachmentIdSelector } from '../selectors';

export function* deleteAttachmentSaga(action: DeleteAttachmentRequestAction) {
  try {
    const attachmentId = yield select(selectedAttachmentIdSelector);
    const taskManagementApi = makeTaskManagementApi();
    yield call(taskManagementApi.deleteAttachmentOfTask, attachmentId); 
    yield put(deleteAttachmentSuccessAction());
    yield put(enqueueNotification({ message: 'Attachment deleted', options: { variant: 'success' } }));
    yield put(listAttachmentRequestAction(action.taskId));
  } catch (e) {
    yield put(deleteAttachmentFailureAction());
    yield put(enqueueNotification({ message: 'Unable to delete attachment', options: { variant: 'error' } }));
  }
}

export function* watchDeleteAttachmentSaga() {
  yield takeLatest(
    [DELETE_ATTACHMENT_REQUEST],
    deleteAttachmentSaga
  );
}
