import React from 'react'

interface IVerticalSpaceProps {
  height: string;
}

export const VerticalSpace: React.FC<IVerticalSpaceProps> = ({ height }) => {
  return (
    <div style={{ minHeight: height }}></div>
  );
}