import { createGlobalStyle } from 'styled-components';

export const GlobalUtilityClasses = createGlobalStyle`
.bg-red-gradient { background: linear-gradient(0deg, rgba(212, 36, 78, 0.05), rgba(212, 36, 78, 0.05)), #FBFBFA; }

.no-webkit-arrows::-webkit-outer-spin-button,
.no-webkit-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.no-webkit-arrows[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
`;
