import React from 'react';
import { EFinanceRowTypes } from 'services/BookingManagerApi';
import { IMultiselectValueLabelPair } from 'ui/Multiselect';
import { NegativeIcon } from '../TransactionIcons/NegativeIcon';
import { PositiveIcon } from '../TransactionIcons/PositiveIcon';

const typeLabelMap = {
  [EFinanceRowTypes.Initial_Balance_Carried_Forward_Positive]: '+ Manual Balance Carried Forward',
  [EFinanceRowTypes.Initial_Balance_Carried_Forward_Negative]: '- Manual Balance Carried Forward',
  [EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Positive]: 'Positive Adjustment to Balance Carried Forward',
  [EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Negative]: 'Negative Adjustment to Balance Carried Forward',
};

export const getManualBookingLedgerRowLabel = (rowType: EFinanceRowTypes) => {
  return typeLabelMap[rowType];
};

export const initialBalanceCarriedForwardTypes: IMultiselectValueLabelPair[] = [
  {
    label: getManualBookingLedgerRowLabel(EFinanceRowTypes.Initial_Balance_Carried_Forward_Positive),
    value: EFinanceRowTypes.Initial_Balance_Carried_Forward_Positive,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getManualBookingLedgerRowLabel(EFinanceRowTypes.Initial_Balance_Carried_Forward_Negative),
    value: EFinanceRowTypes.Initial_Balance_Carried_Forward_Negative,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
];

export const adjustmentBalanceCarriedForwardTypes: IMultiselectValueLabelPair[] = [
  {
    label: getManualBookingLedgerRowLabel(EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Positive),
    value: EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Positive,
    iconHtml: PositiveIcon,
    iconAlign: 'end',
  },
  {
    label: getManualBookingLedgerRowLabel(EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Negative),
    value: EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Negative,
    iconHtml: NegativeIcon,
    iconAlign: 'end',
  },
];
