import { EBookingType } from "services/BookingManagerApi/types/BookingTypeResponse";
import { IMultiselectValueLabelPair } from "ui/Multiselect";

export const bookingTypeOptions: IMultiselectValueLabelPair[] = [
	{
	  label: 'TA Booking',
	  value: EBookingType.TA_BOOKING,
	},
	{
	  label: 'Internal Test',
	  value: EBookingType.INTERNAL_TEST,
	},
	{
	  label: 'Educational Trips',
	  value: EBookingType.EDUCATIONAL_TRIP,
	},
	{
	  label: 'Advanced Purchase',
	  value: EBookingType.ADVANCED_PURCHASE,
	},
	{
	  label: 'Other',
	  value: EBookingType.OTHER,
	},
  ];