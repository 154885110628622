import React from 'react';
import { IFineProduct, ISupplementProduct } from 'services/BackendApi';
import * as Inputs from '../Inputs';
import FluidButton from 'ui/FluidButton';

export const Fine = ({
  fine,
  onUpdate,
  onCta,
  ctaLabel,
}: {
  fine: Partial<IFineProduct>;
  onUpdate: (field: keyof any, value: any) => void;
  onCta: () => void;
  ctaLabel: string;
}) => {
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Input
        label={'Name'}
        onChange={val => {
          onUpdate('name', val);
        }}
        value={fine.name || ''}
      />

      <Inputs.TextArea
        label={'Description'}
        onChange={val => {
          onUpdate('meta', {
            ...fine.meta,
            description: val,
          });
        }}
        value={fine.meta.description}
        rows={5}
      />

      <Inputs.RadioCollection
        label="Category"
        collection={[...Inputs.categoryTypes].map(mt => {
          return {
            isChecked: fine.category === mt.value,
            value: mt.value,
            label: mt.label,
          };
        })}
        onChange={val => {
          onUpdate('category', val);
        }}
      />

      {(fine.category === 'perPerson' || fine.category === 'perPersonPerNight') && (
        <Inputs.AgeGroups
          label={'Age Groups'}
          collection={fine.options?.ages || []}
          onChange={newList => {
            onUpdate('options', {
              ...fine.options,
              ages: newList,
            });
          }}
        />
      )}

      <FluidButton
        type="primary"
        className="self-end w-[200px] mt-4"
        onClick={() => {
          onCta();
        }}
      >
        {ctaLabel}
      </FluidButton>
    </div>
  );
};
