import axios, { AxiosInstance, AxiosResponse } from 'axios';
import qs from 'qs';
import { injectJwtTokenIntoHeaders } from '../tokenLocalStorage';
import {
  ITokenSetResponse,
} from './types';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';
import { COGNITO_ENABLE_FLOW, ENVIRONMENT, REDIRECT_COGNITO_URI_IF_LOCALHOST } from 'config';

export enum CognitoEndpoints {
  AWS_TOKEN_ENDPOINT = '/oauth2/token', // The same endpoint for getTokens and refreshTokens
  AWS_LOGOUT_ENDPOINT = '/logout',
}

export interface AWSQuery {
  client_id: string;
  redirect_uri?: string;
  response_type?: string;
  scope?: string;
  logout_uri?: string;
}

export class CognitoApiService<T extends AxiosInstance> {
  client: T;

  constructor(client: T) {
    this.client = client;
  }

  getTokens = async ( code : string | null ): Promise<AxiosResponse<ITokenSetResponse>> => {
    const dynamicParameters = ParameterService.getParameters();
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    let domain = location.host;
    let redirectUri = dynamicParameters.COGNITO_CALLBACK_FOR_SIGNINUP;
    if (domain === 'localhost:8080') {
      redirectUri = REDIRECT_COGNITO_URI_IF_LOCALHOST;
    }

    const urlEncodedBody = `grant_type=authorization_code&client_id=${dynamicParameters.COGNITO_CLIENT_ID}&code=${code}&redirect_uri=${redirectUri}`;
    return this.client.post(
      CognitoEndpoints.AWS_TOKEN_ENDPOINT, 
      urlEncodedBody, 
      config
    );
  }    

  refreshTokens = async ( refreshCode : string | null ): Promise<AxiosResponse<ITokenSetResponse>> => {
    const dynamicParameters = ParameterService.getParameters();
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    const urlEncodedBody = `grant_type=refresh_token&client_id=${dynamicParameters.COGNITO_CLIENT_ID}&refresh_token=${refreshCode}`;
    return this.client.post(
      CognitoEndpoints.AWS_TOKEN_ENDPOINT, // For refresh is the same endpoint with diff params
      urlEncodedBody, 
      config
    );
  }

  // It's not an axios call with req/res. Just browser GET and expects a browser redirect.
  logout = (): void => {
    const dynamicParameters = ParameterService.getParameters();
    if (ENVIRONMENT === 'local') {
      const logoutEndpoint = `${dynamicParameters.COGNITO_DOMAIN}/logout?client_id=${dynamicParameters.COGNITO_CLIENT_ID}&redirect_uri=${REDIRECT_COGNITO_URI_IF_LOCALHOST}&response_type=code&scope=email+openid`
      window.location.href = COGNITO_ENABLE_FLOW ? logoutEndpoint : '/login';
    } else {
      // Remove trailing slash or cognito will fail
      const marketingUrl = dynamicParameters.MARKETING_APP_URL?.endsWith('/') ? dynamicParameters.MARKETING_APP_URL.slice(0,-1) : dynamicParameters.MARKETING_APP_URL;
      const logoutEndpoint = `${dynamicParameters.COGNITO_DOMAIN}/logout?client_id=${dynamicParameters.COGNITO_CLIENT_ID}&logout_uri=${marketingUrl}`
      window.location.href = COGNITO_ENABLE_FLOW ? logoutEndpoint : '/login';
    }
  }

}

export const makeCognitoApi = (): CognitoApiService<AxiosInstance> => {
  const dynamicParameters = ParameterService.getParameters();
  const headers: any = {};

  const client = axios.create({
    baseURL: dynamicParameters.COGNITO_DOMAIN,
    headers,
    withCredentials: false,
    paramsSerializer: params => {
      return qs.stringify(params);
    },
  });

  return new CognitoApiService<AxiosInstance>(client);
};
