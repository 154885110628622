import { isNil } from 'lodash-es';
import { createSelector } from 'reselect';
import { IInventorySuppliersDomain, IInventoryList } from './model';

export const domainSelector = (state: any): IInventorySuppliersDomain => state.inventorySuppliers;

export const inventorySupplierSelector = createSelector(
  domainSelector,
  (domain: IInventorySuppliersDomain) => domain.supplier
);

export const listSelector = createSelector(
  domainSelector,
  (domain: IInventorySuppliersDomain) => domain.list
);

export const pageCountSelector = createSelector(
  listSelector,
  ({ count, filter }: IInventoryList) => 
    isNil(count) ? null : Math.ceil(count / filter.page_size)
);

export const requestSelector = createSelector(
  domainSelector,
  (domain: IInventorySuppliersDomain) => domain.request
);

export const requestSupplierForStayGetSelector = createSelector(
  domainSelector,
  (domain: IInventorySuppliersDomain) => domain.request.supplierForStayGet
);
