import { useSelector } from "react-redux";
import { handleSaveBooking } from "../helpers";
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import { ModalModes } from "containers/SummaryForm/const";
import { useCallback } from "react";

export const useSaveBooking = ({
  setRequestInProgress,
  backendApi,
  selectedCompanyMembership,
  clientCountryCode,
  closeModalRatesWarning,
  containsLiveRates,
  setShouldShowLeaveAlert,
  setRedirectLocation,
  openModalRatesWarning,
}) => {
  const bookingDomain = useSelector(BookingBuilderSelectors.bookingSelector);

  const handleSaveBookingButton = useCallback(async () => {
    setRequestInProgress(true);
    setShouldShowLeaveAlert(false);
    try {
      await handleSaveBooking({ backendApi, bookingDomain, clientCountryCode, selectedCompanyMembership, setShouldShowLeaveAlert, setRedirectLocation });
    } catch (e) {
      setRequestInProgress(false);
      setShouldShowLeaveAlert(true);
    }
    closeModalRatesWarning();
  }, [backendApi, bookingDomain, clientCountryCode, closeModalRatesWarning, selectedCompanyMembership, setRedirectLocation, setRequestInProgress, setShouldShowLeaveAlert]);
  
  const handleSaveBookingButtonTrigger = useCallback(() => {
    if (containsLiveRates) {
      openModalRatesWarning(ModalModes.SAVE);
    } else {
      handleSaveBookingButton();
    }
  }, [containsLiveRates, handleSaveBookingButton, openModalRatesWarning]);

  return { handleSaveBookingButton, handleSaveBookingButtonTrigger }
}
