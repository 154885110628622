import { ENetworkRequestStatus } from 'services/BackendApi';
import { IInventorySupplierContracting, IInventorySupplierSearchContractingParams } from 'services/InventoryAPI';
import { IInventorySupplierWithContacts } from 'services/InventoryAPI';
import { ESortOrder } from 'store/common/types';

export interface IInventoryList {
  items: IInventorySupplierContracting[] | null;
  count: number | null;
  filter: IInventorySupplierSearchContractingParams;
}

export interface IInventorySuppliersDomain {
  list: IInventoryList;
  supplier: IInventorySupplierWithContacts | null;
  request: {
    list: ENetworkRequestStatus;
    supplierPost: ENetworkRequestStatus;
    supplierGet: ENetworkRequestStatus;
    supplierForStayGet: ENetworkRequestStatus;
    supplierPut: ENetworkRequestStatus;
  };
}

export const initialState: IInventorySuppliersDomain = {
  supplier: null,
  list: {
    items: null,
    count: null,
    filter: {
      page: 1,
      page_size: 10,
      order_by: 'name',
      order_type: ESortOrder.ASC
    },
  },
  request: {
    list: ENetworkRequestStatus.IDLE,
    supplierGet: ENetworkRequestStatus.IDLE,
    supplierForStayGet: ENetworkRequestStatus.IDLE,
    supplierPost: ENetworkRequestStatus.IDLE,
    supplierPut: ENetworkRequestStatus.IDLE,
  },
}
