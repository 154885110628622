import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { closeCreateMarkupModalAction } from 'store/modules/companyHotelMarkup';
import { StandardModal } from 'pureUi/Modal';
import FluidButton from 'ui/FluidButton';
import Checkbox from 'ui/Checkbox';
import { NumericMaskedInput } from 'ui/stateful/MaskedInput';
import { ENetworkRequestStatus, makeBackendApi } from 'services/BackendApi';
import { getCountryName } from 'utils/country';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { Radio } from 'ui/Radio';
import * as CompanyHotelMarkupActions from 'store/modules/companyHotelMarkup/actions';
import * as CompanyHotelMarkupSelectors from 'store/modules/companyHotelMarkup/selectors';
import { iCompanyMarkupOption } from 'store/modules/companyHotelMarkup/model';
import { Table } from 'ui/Table';
import { ESortOrder } from 'store/common/types';
import { enqueueNotification } from 'store/modules/ui';
import { ITableColumn, ITableRow } from 'ui/Table/types';

interface AddMarkupModalProps {
  isOpen: boolean;
}

export const MarkupOption = ({ name, countryCode, checked, check }) => {
  const className = ['markup', ...name.split(' ')].join('-').toLowerCase();

  return (
    <div className="flex font-normal my-2 justify-between items-end">
      <label className="flex cursor-pointer items-end" onClick={check}>
        <Checkbox className={className} checked={checked} onClick={check} onChange={check} />
        <span className="ml-2">{name}</span>
      </label>
      <span className="text-13px">{getCountryName(countryCode)}</span>
    </div>
  );
};

const markupToTypeLabelMap = {
  hotel: 'Hotels',
  supplier: 'Suppliers',
};

export const AddMarkupModal: React.FC<AddMarkupModalProps> = React.memo(({ isOpen }) => {
  const backendApi = makeBackendApi();

  const [selectedMarkupOptionIds, setSelectedMarkupOptionIds] = useState<string[]>([]);
  const [markupPercentage, setMarkupPercentage] = useState<string>('00.00');
  const [markupToType, setMarkupToType] = useState<'hotel' | 'supplier'>('hotel');
  const [markupToOptions, setMarkupToOptions] = useState<iCompanyMarkupOption[]>([]);
  const [sortBy, setSortBy] = useState('countryName');
  const [sortOrder, setSortOrder] = useState('asc' as ESortOrder);
  const [getMarkupToOptionsRequest, setGetMarkupToOptionsRequest] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [createMarkupRequest, setCreateMarkupRequest] = useState<ENetworkRequestStatus>(ENetworkRequestStatus.IDLE);

  const companyUuid = useSelector(CompanyHotelMarkupSelectors.travelCompanyUuidSelector);

  const dispatch = useDispatch();

  const hotelListClassName = classnames(
    'available-options rounded overflow-y-scroll border border-solid border-gray-20',
    {
      'flex justify-center items-center': getMarkupToOptionsRequest === ENetworkRequestStatus.PENDING,
    }
  );

  // reset all the data
  useEffect(() => {
    return () => {
      setSelectedMarkupOptionIds([]);
      setMarkupPercentage('');
      setMarkupToType('hotel');
      setMarkupToOptions([]);
      setSortBy('countryName');
      setSortOrder('asc' as ESortOrder);
      setGetMarkupToOptionsRequest(ENetworkRequestStatus.IDLE);
      setCreateMarkupRequest(ENetworkRequestStatus.IDLE);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!companyUuid || !isOpen) {
      return;
    }
    setGetMarkupToOptionsRequest(ENetworkRequestStatus.PENDING);

    if (markupToType === 'hotel') {
      backendApi
        .fetchCompanyHotelAvailableToCreateMarkups(companyUuid)
        .then(res => {
          setGetMarkupToOptionsRequest(ENetworkRequestStatus.SUCCESS);
          setMarkupToOptions(res.data);
        })
        .catch(e => {
          setGetMarkupToOptionsRequest(ENetworkRequestStatus.ERROR);
          console.error('e', e);
        });
    } else if (markupToType === 'supplier') {
      backendApi
        .fetchCompanySupplierAvailableToCreateMarkups(companyUuid)
        .then(res => {
          setGetMarkupToOptionsRequest(ENetworkRequestStatus.SUCCESS);
          setMarkupToOptions(res.data);
        })
        .catch(e => {
          setGetMarkupToOptionsRequest(ENetworkRequestStatus.ERROR);
          console.error('e', e);
        });
    }
  }, [isOpen, companyUuid, markupToType]);

  const handleSelectMarkupTo = (val: 'hotel' | 'supplier') => {
    setSelectedMarkupOptionIds([]);
    setMarkupToType(val);
  };

  const handleCloseClick = useCallback(() => {
    dispatch(closeCreateMarkupModalAction());
  }, [dispatch]);

  const handleCreateMarkup = () => {
    if (!companyUuid) {
      return;
    }
    setCreateMarkupRequest(ENetworkRequestStatus.PENDING);
    backendApi
      .postCompanyHotelSupplierMarkups(companyUuid, Number(markupPercentage), selectedMarkupOptionIds, markupToType)
      .then(res => {
        dispatch(closeCreateMarkupModalAction());
        dispatch(CompanyHotelMarkupActions.fetchCHMarkupListRequestAction());
        setCreateMarkupRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            options: { variant: 'success' },
            message: 'This markup has been created',
          })
        );
      })
      .catch(e => {
        console.error(e);
        setCreateMarkupRequest(ENetworkRequestStatus.ERROR);
      });
  };

  const toggleSelectAll = (e?: Event) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (markupToOptions.length === selectedMarkupOptionIds.length) {
      setSelectedMarkupOptionIds([]);
      return;
    }

    setSelectedMarkupOptionIds(
      markupToOptions.map(option => ('uuid' in option ? option.uuid : option.supplierId.toString()))
    );
  };

  const toggleSelectedOption = (checked: boolean, effectiveId: string, event?: Event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (checked === false) {
      setSelectedMarkupOptionIds([...selectedMarkupOptionIds, effectiveId]);
    } else {
      setSelectedMarkupOptionIds(selectedMarkupOptionIds.filter(id => id !== effectiveId));
    }
  };

  const isSelectAllChecked = markupToOptions.length === selectedMarkupOptionIds.length;

  const tableColumns = [
    {
      id: 'name',
      label: (
        <label
          className="flex cursor-pointer items-end px-2"
          onClick={e => {
            toggleSelectAll((e as unknown) as Event);
          }}
        >
          <Checkbox
            checked={isSelectAllChecked}
            onClick={e => {
              toggleSelectAll((e as unknown) as Event);
            }}
            onChange={() => {}}
          />
          <span className="ml-2">Name</span>
        </label>
      ),
      width: '200px',
    },
    markupToType === 'supplier'
      ? {
          id: 'type',
          label: 'Type',
          width: '100px',
        }
      : undefined,
    {
      id: 'countryName',
      label: 'Location',
      width: '100px',
      fieldToSortBy: 'countryName',
    },
  ].filter(column => !!column);

  const rows = useMemo(() => {
    return markupToOptions.map(muo => {
      const effectiveId = 'uuid' in muo ? muo.uuid : muo.supplierId.toString();
      const checked = selectedMarkupOptionIds.findIndex(id => id === effectiveId) > -1;

      return {
        id: effectiveId,
        className: 'o:h-[40px]',
        cells: [
          {
            id: 'name',
            value: (
              <span className="flex flex-row items-center space-x-1">
                <label
                  className="flex cursor-pointer items-end"
                  onClick={e => {
                    toggleSelectedOption(checked, effectiveId, (e as unknown) as Event);
                  }}
                >
                  <Checkbox
                    checked={checked}
                    onClick={e => {
                      toggleSelectedOption(checked, effectiveId, (e as unknown) as Event);
                    }}
                    onChange={() => {}}
                  />
                  <span className="ml-2">{muo.name}</span>
                </label>
              </span>
            ),
            className: 'text-md',
          },
          markupToType === 'supplier' ? { id: 'type', value: muo.type, contentClassName: 'text-13px' } : undefined,
          { id: 'countryName', value: muo.countryName, contentClassName: 'text-13px' },
        ].filter(cell => !!cell),
      };
    });
  }, [markupToType, markupToOptions, selectedMarkupOptionIds, sortBy, sortOrder]);

  if (!isOpen) {
    return null;
  }

  return (
    <StandardModal
      className="items-start bg-black-true-transparent-60 z-11"
      frameClassName={`pt-4 px-8 pb-8 border border-solid text-black border-black-true font-pt-sans overflow-y-hidden`}
      showCloseButton={false}
      closeOnOutsideClick={true}
      onClose={handleCloseClick}
      removePadding
    >
      <div
        className="text-base text-brown-100 absolute top-4 right-4 cursor-pointer outline-none"
        onClick={handleCloseClick}
      >
        <i className="fas fa-times"></i>
      </div>
      <h3 className="font-noe-display font-normal text-21px leading-29px mt-0 mb-0">Add New Mark Up</h3>

      <div className="my-2">
        <span className="font-bold">Mark-Up</span>
        <span className="ml-1">(%)</span>
        <div className="flex text-black items-baseline my-2">
          <div className="w-16">
            <NumericMaskedInput
              inputClassName="markup-input"
              initialValue="0.00"
              value={markupPercentage}
              mask="##.##"
              onChange={setMarkupPercentage}
              allowNegative
            />
          </div>
          <span className="ml-2 text-15px">Including two decimals (##.##)</span>
        </div>
      </div>

      <div className="my-2 space-y-2">
        <span className="font-bold">Mark-Up To</span>
        <div className="flex items-center space-x-4">
          <label onClick={() => handleSelectMarkupTo('hotel')} className="flex items-center space-x-2 cursor-pointer">
            <Radio checked={markupToType === 'hotel'} onClick={() => handleSelectMarkupTo('hotel')} />
            <span>Hotel</span>
          </label>
          <label
            onClick={() => handleSelectMarkupTo('supplier')}
            className="flex items-center space-x-2 cursor-pointer"
          >
            <Radio checked={markupToType === 'supplier'} onClick={() => handleSelectMarkupTo('supplier')} />
            <span>Supplier</span>
          </label>
        </div>
      </div>

      <div className="mb-2">
        <span className="font-bold">Select {markupToTypeLabelMap[markupToType]}</span>
        <span className="ml-1">
          {selectedMarkupOptionIds.length > 0 && `(${selectedMarkupOptionIds.length} selected)`}
        </span>
      </div>

      <div className={hotelListClassName} style={{ width: '600px', minHeight: '366px' }}>
        {getMarkupToOptionsRequest === ENetworkRequestStatus.PENDING && <LoadingBar />}
        {getMarkupToOptionsRequest === ENetworkRequestStatus.SUCCESS && (
          <>
            <Table
              columns={tableColumns as ITableColumn[]}
              messageWhenEmpty="No results"
              stickyHeader
              rows={rows as ITableRow[]}
              onSort={(field, order) => {
                setSortOrder(order as ESortOrder);
                setSortBy(field);
                setMarkupToOptions(
                  markupToOptions.sort((a, b) => {
                    if (a[field] < b[field]) {
                      return order === 'asc' ? -1 : 1;
                    }
                    if (a[field] > b[field]) {
                      return order === 'asc' ? 1 : -1;
                    }
                    return 0;
                  })
                );
              }}
              sortBy={sortBy}
              sortOrder={sortOrder as ESortOrder}
              loading={false}
            />
          </>
        )}
      </div>

      <div className="flex mt-8">
        <FluidButton
          type="secondary"
          className="cancel-button ml-3"
          disabled={createMarkupRequest === ENetworkRequestStatus.PENDING}
          onClick={handleCloseClick}
        >
          Cancel
        </FluidButton>
        <FluidButton
          type="primary"
          className="create-markup-button ml-3"
          isLoading={createMarkupRequest === ENetworkRequestStatus.PENDING}
          disabled={Number(markupPercentage) === 0 || selectedMarkupOptionIds.length === 0}
          onClick={handleCreateMarkup}
        >
          Create Mark-up
        </FluidButton>
      </div>
    </StandardModal>
  );
});
