import { responseInitialState, IResponseSubdomain } from './model';
import * as Actions from './actions';

export const bookingBuilderResponseReducer = (
  state: IResponseSubdomain = responseInitialState, 
  action: Actions.BookingBuilderResponseAction
): IResponseSubdomain => {
  switch (action.type) {
    case Actions.COPY_BOOKING_BUILDER_RESPONSE:
      return {
        ...responseInitialState,
        response: action.bookingBuilderResponse,
      };
    
    default:
      return state;

  }
};
