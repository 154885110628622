import React, { FormEvent, useCallback, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISingleSelectOption } from 'ui/SingleSelect';

import { Pagination } from 'pureUi/Pagination';
import {
  requestPendingSelector,
  proposalsSelector,
  nameFilterSelector,
  clientFilterSelector,
  currentPageSelector,
  totalResultsSelector,
  pageCountSelector,
  selectedTravelAgentUuidSelector,
  itemsPerPageSelector,
  errorSelector,
  tableLoadingSelector,
} from 'store/modules/proposalsList/selectors';
import { travelAgentSelectOptionsSelector } from '../../store/modules/agents/selectors';
import { isInternalUser } from 'store/modules/auth';
import {
  setNameFilterAction,
  setPageNumberAction,
  setSelectedTravelAgentAction,
  setItemsPerPageAction,
  setClientFilterAction,
} from 'store/modules/proposalsList/actions';
import { getTravelAgentsRequestAction } from '../../store/modules/agents/actions';
import { isNil } from 'lodash-es';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { ProposalListFilters } from './ProposalListFilters';
import { ProposalListTable } from './ProposalListTable';
import { PageTitle } from 'ui/PageTitle';

export const ProposalList: React.FC = () => {
  const dispatch = useDispatch();
  const proposals = useSelector(proposalsSelector);
  const isLoading = useSelector(requestPendingSelector);
  const isTableLoading = useSelector(tableLoadingSelector);
  const isError = useSelector(errorSelector);
  const nameFilter = useSelector(nameFilterSelector);
  const clientFilter = useSelector(clientFilterSelector);
  const totalResults = useSelector(totalResultsSelector);
  const currentPage = useSelector(currentPageSelector);
  const pageCount = useSelector(pageCountSelector);
  const isInitialized = !isNil(totalResults);
  const itemsPerPage = useSelector(itemsPerPageSelector);
  const selectedTravelAgentUuid = useSelector(selectedTravelAgentUuidSelector);
  const isSr = useSelector(isInternalUser);
  const travelAgentSelectOptions = useSelector(travelAgentSelectOptionsSelector);

  useLayoutEffect(() => {
    dispatch(setPageNumberAction(0));

    if (isSr) {
      dispatch(getTravelAgentsRequestAction());
    }
  }, []);

  const handleNameFilterChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      dispatch(setNameFilterAction(e.currentTarget.value));
    },
    [dispatch]
  );

  const handleClientFilterChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      dispatch(setClientFilterAction(e.currentTarget.value));
    },
    [dispatch]
  );

  const handleTAFilterChange = useCallback(
    value => {
      dispatch(setSelectedTravelAgentAction(value));
    },
    [dispatch]
  );

  const handleItemsPerPageChange = useCallback((itemsPerPage: number) => {
    dispatch(setItemsPerPageAction(itemsPerPage));
  }, []);

  const handlePageChange = useCallback((pageNumber: number) => {
    dispatch(setPageNumberAction(pageNumber - 1));
  }, []);

  const getResultsText = () => {
    if (!isLoading && totalResults > 0) {
      return `${totalResults} ${totalResults === 1 ? 'result' : 'results'} found`;
    }

    if (!isLoading && totalResults === 0) {
      return 'no results found';
    }

    return '';
  };

  return (
    <div className="proposals-list-page flex justify-center">
      <div className="proposals-list-content min-w-1280px max-w-1280px">
        <PageTitle
          primaryText="Proposals"
          secondaryCondition={isInitialized && !isTableLoading}
          secondaryText={`(${getResultsText()})`}
          loadingCondition={isTableLoading && !isLoading}
          loadingText="Loading ..."
        />
        {isError && <ErrorBar />}
        {isLoading && <LoadingBar />}
        {!isLoading && (
          <ProposalListFilters
            nameFilter={nameFilter}
            clientFilter={clientFilter}
            isSr={isSr}
            selectedTravelAgentUuid={selectedTravelAgentUuid}
            travelAgentSelectOptions={travelAgentSelectOptions as ISingleSelectOption[]}
            onNameFilterChange={handleNameFilterChange}
            onClientFilterChange={handleClientFilterChange}
            onTAFilterChange={handleTAFilterChange}
          />
        )}
        {!isLoading && <ProposalListTable proposals={proposals} isSr={isSr} />}
        {proposals?.length > 0 && !isLoading && (
          <Pagination
            className="pagination mt-30px"
            onPageSelect={handlePageChange}
            pageCount={pageCount}
            currentPage={currentPage + 1}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
      </div>
    </div>
  );
};
