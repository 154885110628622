import { IBasketBuild } from 'services/BackendApi/types/Basket';

export const SET_BASKET_BUILD = 'basket/SET_BASKET_BUILD';
export const SET_SHOULD_SHOW_LEAVE_WARNING_IN_BOOKING_BUILDER =
  'basket/SET_SHOULD_SHOW_LEAVE_WARNING_IN_BOOKING_BUILDER';
  
export type SetBasketBuildAction = ReturnType<typeof setBasketBuildAction>;
export const setBasketBuildAction = (basketBuild: IBasketBuild | null) => ({
  type: SET_BASKET_BUILD as typeof SET_BASKET_BUILD,
  basketBuild,
});

export type SetShouldShowLeaveWarningInBookingBuilder = ReturnType<
  typeof setShouldShowLeaveWarningInBookingBuilderAction
>;
export const setShouldShowLeaveWarningInBookingBuilderAction = (value: boolean) => ({
  type: SET_SHOULD_SHOW_LEAVE_WARNING_IN_BOOKING_BUILDER as typeof SET_SHOULD_SHOW_LEAVE_WARNING_IN_BOOKING_BUILDER,
  value,
});

export type TBasketActions = 
  SetBasketBuildAction | 
  SetShouldShowLeaveWarningInBookingBuilder;
