import React from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import * as Inputs from './Inputs';
import FluidButton from 'ui/FluidButton';
import { EUploadTag } from 'services/BackendApi';

export const UploadModal = ({
  onConfirm,
  onClose,
  tags = [
    { value: EUploadTag.PHOTO, label: 'Photo' },
    { value: EUploadTag.FEATURED_PHOTO, label: 'Featured Photo' },
    { value: EUploadTag.BROCHURE, label: 'Brochure' },
    { value: EUploadTag.FLOOR_PLAN, label: 'Floor Plan' },
  ],
  acceptString,
}: {
  onConfirm: (data: any) => void;
  onClose: () => void;
  tags?: {
    value: EUploadTag;
    label: string;
  }[];
  acceptString?: string;
}) => {
  const [name, setName] = React.useState('');
  const [file, setFile] = React.useState<File | null>(null);
  const [tag, setTag] = React.useState<EUploadTag>(EUploadTag.PHOTO);

  return (
    <GeneralModal
      onClose={() => onClose()}
      modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-clip"
      shouldCloseByClickingOutside={false}
    >
      <div className="flex flex-col space-y-4">
        <h1 className="font-noe-display text-[36px] leading-[46px] text-black">Upload File</h1>
        <Inputs.Input
          label="Name"
          value={name}
          onChange={value => {
            setName(value);
          }}
        />
        <span>
          <span className="text-sm">File</span>
          <input
            type="file"
            name="file"
            accept={acceptString}
            className="border border-solid border-gray-40 p-2 w-full"
            onChange={e => setFile(e.target.files?.[0] || null)}
          />
        </span>

        {tags.length >= 1 && (
          <Inputs.RadioCollection
            label="Tag"
            collection={tags.map(t => {
              return {
                ...t,
                isChecked: tag === t.value,
              };
            })}
            onChange={value => {
              setTag(value as EUploadTag);
            }}
          />
        )}
        <div className="flex justify-between items-center">
          <FluidButton type="secondary" onClick={() => onClose()}>
            Cancel
          </FluidButton>
          <FluidButton
            type="primary"
            onClick={() => {
              onConfirm({
                name,
                file,
                tag,
              });
            }}
          >
            Upload File
          </FluidButton>
        </div>
      </div>
    </GeneralModal>
  );
};
