import React, { FC } from 'react';

interface IProductOfferProps {
  offer: string;
}

export const ProductOffer: FC<IProductOfferProps> = React.memo(({ offer }) => (
  <div className="offer flex w-fit bg-teal-15 text-brown-100 mt-1 rounded" style={{ padding: '3px' }}>
    <span className="offer-label font-bold">Offer: </span>
    <span className="offer-name ml-1">{offer}</span>
  </div>
));
