import React from 'react';

export interface IFormLabelProps {
  className?: string;
  text: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const FormLabel: React.FC<IFormLabelProps> = ({ className, style, text, children }) => {
  return (
    <label className={className} style={style}>
      <span className="inline-block mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">{text}</span>
      {children}
    </label>
  );
};

export default FormLabel;
