import React, { useState } from 'react';
import classNames from 'classnames';
import { Multiselect } from 'ui/Multiselect';
import { useHideOnClickOutside } from 'hooks/useHideOnClickOutside';

export interface iGuestAgesPickerProps {
  numberOfAdults: number;
  agesOfAllChildren?: number[];
  maxChildAge?: number;
  onUpdate: (numberOfAdults: number, agesOfAllChildren?: number[]) => void;
  childAgePickerItemsToDisplayBeforeScroll?: number;
  disabled?: boolean;
}

export const GuestAgesPicker = (props: iGuestAgesPickerProps) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const { instanceClassname } = useHideOnClickOutside(setIsPanelOpen, 'guest-age-picker');

  let displayString = `${props.numberOfAdults} Adults`;
  if (props.agesOfAllChildren && props.agesOfAllChildren.length > 0) {
    displayString += `, ${props.agesOfAllChildren.length} ${
      props.agesOfAllChildren.length === 1 ? 'Child' : 'Children'
    }`;
  }

  return (
    <div className={classNames(instanceClassname, 'font-pt-sans text-[14px] leading-19px relative')}>
      <div
        className={classNames('bg-ivory px-10px py-8px border-1 border-gray-40 cursor-pointer h-37px', {
          'outline-teal-100 outline-2 outline': isPanelOpen,
          'pointer-events-none o:cursor-auto o:bg-gray-10 o:border-gray-10 o:text-gray-100': props.disabled,
        })}
        onClick={() => {
          setIsPanelOpen(!isPanelOpen ? true : false);
        }}
      >
        {displayString}
      </div>

      {isPanelOpen && (
        <div className="bg-ivory border-1 border-gray-40 p-10px absolute top-[39px] left-0 w-full z-10">
          <div className="flex flex-col">
            {/* adults */}
            <div className="flex justify-between items-center">
              <p>Adults</p>
              <div className="flex space-x-6px items-center">
                <span
                  onClick={e => {
                    props.onUpdate(Math.max(props.numberOfAdults - 1, 1), props.agesOfAllChildren);
                  }}
                  className="bg-brown-100 text-white w-24px h-24px rounded-full cursor-pointer flex items-center justify-around"
                >
                  <i className="fas fa-minus"></i>
                </span>
                <p>{props.numberOfAdults}</p>
                <span
                  onClick={() => {
                    props.onUpdate(props.numberOfAdults + 1, props.agesOfAllChildren);
                  }}
                  className="bg-brown-100 text-white w-24px h-24px rounded-full cursor-pointer flex items-center justify-around"
                >
                  <i className="fas fa-plus"></i>
                </span>
              </div>
            </div>

            {/* children */}
            <div className="flex justify-between items-center">
              <p>Children</p>
              <div className="flex space-x-6px items-center">
                <span
                  onClick={() => {
                    props.onUpdate(props.numberOfAdults, props.agesOfAllChildren?.slice(0, -1));
                  }}
                  className="bg-brown-100 text-white w-24px h-24px rounded-full cursor-pointer flex items-center justify-around"
                >
                  <i className="fas fa-minus"></i>
                </span>
                <p>{props.agesOfAllChildren ? props.agesOfAllChildren.length : 0}</p>
                <span
                  onClick={() => {
                    props.onUpdate(props.numberOfAdults, [...(props.agesOfAllChildren || []), 0]);
                  }}
                  className="bg-brown-100 text-white w-24px h-24px rounded-full cursor-pointer flex items-center justify-around"
                >
                  <i className="fas fa-plus"></i>
                </span>
              </div>
            </div>

            {/* the actual children ages */}
            {props.agesOfAllChildren && props.agesOfAllChildren.length >= 1 && (
              <div className="flex items-start justify-between">
                <p>Children Ages</p>
                <div className="flex flex-wrap max-w-[128px] justify-end gap-2">
                  {props.agesOfAllChildren.map((age, index) => (
                    <Multiselect
                      key={`${age}-${index}`}
                      className="bg-ivory w-[60px]"
                      itemsClassname="bg-ivory"
                      itemCtaClassName="hover:bg-gray-10"
                      labelClassName="pr-1 text-center"
                      hideCheckboxes
                      isSingleSelectMode
                      isCloseOnSelect
                      hideDropdownArrow
                      itemsToDisplayBeforeScroll={props.childAgePickerItemsToDisplayBeforeScroll || 10}
                      onUpdate={value => {
                        const newAges = [...props.agesOfAllChildren!];
                        newAges[index] = parseInt(value[0]);
                        props.onUpdate(props.numberOfAdults, newAges);
                      }}
                      options={Array.from({ length: (props.maxChildAge ?? 18) + 1 }, (_, i) => i + 0).map(i => ({
                        value: i.toString(),
                        label: i.toString(),
                      }))}
                      selectedValues={[age.toString()]}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
