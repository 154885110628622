import styled from 'styled-components';
import { Multiselect } from 'ui/Multiselect';

export const StyledMultiSelect = styled(Multiselect)`
  > .relative {
    border-color: #f6f4f1 !important;
    border-bottom: 0 !important;
  }

  > .relative.is-open {
    border-color: #ceccc9 !important;
  }

  .multiselect-items {
    border-color: #f6f4f1 !important;
    border-top: 0 !important;
  }

  .multiselect-items.is-open {
    border-color: #ceccc9 !important;
  }

  .border-l {
    border-color: #f6f4f1 !important;
  }

  .multiselect-item-cta:hover i {
    color: #3eaf8c !important;
  }
`;
