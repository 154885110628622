import React from 'react';

export interface ILoadingBarProps {
  label?: string;
}

export const LoadingBar = (props: ILoadingBarProps) => {
  return (
    <div
      className="loading-bar w-full bg-yellow-20 p-4 text-center font-pt-sans mx-auto"
      style={{
        // hardcoded colour as `yellow-20` converted to RGB
        background: `linear-gradient(
            to left,
            rgba(251, 245, 229, 0) 0%,
            rgba(251, 245, 229, 1) 10%,
            rgba(251, 245, 229, 1) 90%,
            rgba(251, 245, 229, 0) 100%
          )`,
        maxWidth: '300px', // specific rule from the style guide
      }}
    >
      <i className="fas fa-circle-notch fa-spin text-brown-140"></i>
      <span className="ml-2 text-yellow-100 text-sm">{props.label || 'Loading content...'}</span>
    </div>
  );
};

export interface IErrorBarProps {
  message?: string;
  ctaCaption?: string;
  onCtaClick?: () => void;
}

export const ErrorBar = React.memo((props: IErrorBarProps) => {
  return (
    <div
      className="error-bar w-full bg-red-20 p-4 text-center font-pt-sans mx-auto"
      style={{
        maxWidth: '300px', // specific rule from the style guide
      }}
    >
      <span className="error-bar-message block text-red-100 text-sm">{props.message || 'Failed to load content'}</span>
      <a
        className="mt-2 block text-red-100 text-sm underline cursor-pointer hover:text-red-100"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          if (props.onCtaClick) {
            props.onCtaClick();
          } else {
            window.location.reload();
          }
        }}
      >
        {props.ctaCaption ? props.ctaCaption : 'Reload page'}
      </a>
    </div>
  );
});
