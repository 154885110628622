import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IBookingManagerBreakdownSubdomain } from 'store/modules/bookingManager/subdomains/breakdown/model';
import { EditAncillaryProductModal } from 'ui/EditAncillaryProductModal/EditAncillaryProductModal';
import { IHeadlineLineItemBreakdownComponent } from 'ui/HeadlineLineItemBreakdown';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import {
  IAncillaryData,
  IHeadlineLineItemBreakdown,
  IHeadlineLineItemBreakdownLineItem,
  makeBookingManagerApi,
} from 'services/BookingManagerApi';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { DateHelper } from 'pureUi/DatePicker';
import { isNil, flatMap } from 'lodash-es';
import {
  AncillaryAllRatesDatesSet,
  AncillaryProductResult,
  AncillaryProductWithRates,
  AncillaryProductWithRatesWithUserData,
  AncillaryRatedResult,
  EAncillaryLocationType,
  EAncillaryProductType,
  EAncillaryTransferTripType,
  isAncillaryRatedResult,
  LocationFromSearchLocationsWithType,
} from 'services/BookingManagerApi/types/AncillaryService';
import { usePrevious } from 'hooks/usePrevious';
import {
  generateBreakdownItemDataForAncillaryProduct,
  generateBreakdownItemDataForAncillaryTransfers,
} from 'ui/AddAncillarySharedComponents/utils';
import * as UiActions from 'store/modules/ui/actions';
import { AxiosResponse } from 'axios';

export interface IEditAncillaryProductModalContainerProps {
  bookingUuid: string;
  bookingCurrencySymbol: IHeadlineLineItemBreakdownComponent['bookingCurrency'];
  currentlyEditingLineItem: IBookingManagerBreakdownSubdomain['currentlyEditingLineItem'];
  arrivalDate: string;
  departureDate: string;
  currencySymbol: string;
  currentlyEditingLineItemIndex: number;
  headlineLineItemBreakdown: IHeadlineLineItemBreakdown;
}

export interface IEditAncillaryProductModalContainerState {
  getProductNetworkRequest: ENetworkRequestStatus;
  getLocationsNetworkRequest: ENetworkRequestStatus;
  startDate: string;
  endDate: string;
  numberOfAdults: number;
  agesOfAllChildren: number[];
  selectedProduct: AncillaryProductWithRatesWithUserData | null;
  fetchedRates: AncillaryAllRatesDatesSet;
  fetchedProduct: AncillaryProductResult | null;
  selectedDate: string;
  errorIds: number[];
  locations: LocationFromSearchLocationsWithType[];
  groundService_pickupTime?: string; // for ground services and transfers
  transfer_tripType?: EAncillaryTransferTripType; // for transfers
  transfer_pickupTime?: string; // for transfers
  transfer_pickupDate?: string; // for transfers
  transfer_returnDate?: string; // for transfers
  transfer_returnTime?: string; // for transfers
}

export const EditAncillaryProductModalContainer = (props: IEditAncillaryProductModalContainerProps) => {
  const dispatch = useDispatch();
  const bookingManagerApi = makeBookingManagerApi();

  const rate = props.currentlyEditingLineItem?.ancillaryData?.rateForDate as AncillaryRatedResult;

  const optionSelections = props.currentlyEditingLineItem?.ancillaryData?.product?.flexibleAttributes!.reduce(
    (acc, option) => {
      const selectedValue = props.currentlyEditingLineItem?.ancillaryData?.userSelections.flexibleAttributesNonValidatedSelections?.find(
        selection => selection.id === option.id
      );
      if (option.type === 'option' || option.type === 'option-boolean') {
        acc[option.id] = selectedValue?.selectedValue.value;
      }
      return acc;
    },
    {} as any
  );

  const productType = props.currentlyEditingLineItem?.ancillaryData?.product?.productType!;
  const airportId = props.currentlyEditingLineItem?.ancillaryData?.userSelections.airportId;

  // if we're editing a transfer thats a return, we need to gte the matching return line item
  // that line item is the line item that is a transfer, has the same returnHash as the one we're editing
  // and is a DIFFERENT index than the one we're editing
  // these will, ofc, remain undefined if we're not editing a transfer
  let matchingReturnLineItem: IHeadlineLineItemBreakdownLineItem | undefined;
  let matchingReturnLineItemIndex: number | undefined;

  if (
    !isNil(props.currentlyEditingLineItem?.ancillaryData?.userSelections.returnHash) &&
    props.currentlyEditingLineItem?.ancillaryData?.product?.productType === EAncillaryProductType.TRANSFER
  ) {
    props.headlineLineItemBreakdown.Transfer.items.forEach((ti, tiIndex) => {
      if (
        ti.ancillaryData?.userSelections.returnHash ===
          props.currentlyEditingLineItem?.ancillaryData?.userSelections.returnHash &&
        tiIndex !== props.currentlyEditingLineItemIndex
      ) {
        matchingReturnLineItem = ti;
        matchingReturnLineItemIndex = tiIndex;
      }
    });
  }

  // determine if the one we're editing is the inbound or the outbound
  // the one we're editing is the inbound if its date is earlier than the matching return line item
  // the one we're editing is the outbound if its date is later than the matching return line item
  const isCurrentlyEditingInboundOrOneWay =
    isNil(matchingReturnLineItem) ||
    new Date(props.currentlyEditingLineItem?.ancillaryData?.userSelections.selectedDate!).getTime() <
      new Date(matchingReturnLineItem?.ancillaryData?.userSelections.selectedDate!).getTime();

  const isCurrentlyEditingOriginallyOneWay = isNil(
    props.currentlyEditingLineItem?.ancillaryData?.userSelections.returnHash
  );

  const [state, setState] = React.useState<IEditAncillaryProductModalContainerState>({
    getProductNetworkRequest: ENetworkRequestStatus.IDLE,
    getLocationsNetworkRequest: ENetworkRequestStatus.IDLE,
    startDate: props.currentlyEditingLineItem?.ancillaryData?.userSelections.selectedDate!,
    endDate: props.currentlyEditingLineItem?.ancillaryData?.userSelections.selectedDate!,

    numberOfAdults: props.currentlyEditingLineItem?.ancillaryData?.userSelections.numberOfAdults!,
    agesOfAllChildren: props.currentlyEditingLineItem?.ancillaryData?.userSelections.agesOfAllChildren!,

    selectedDate: props.currentlyEditingLineItem?.ancillaryData?.userSelections.selectedDate!,
    fetchedProduct: null,
    selectedProduct: {
      product: props.currentlyEditingLineItem?.ancillaryData?.product!,
      rates: [rate] as any,
      userData: {
        rate: {
          ...rate,
          price: props.currentlyEditingLineItem?.saleCostCents
            ? String(props.currentlyEditingLineItem?.saleCostCents / 100)
            : '0',
        },
        unitCount: rate.quantity,
        optionSelections,
      },
    },

    errorIds: [],

    fetchedRates: {},

    groundService_pickupTime: props.currentlyEditingLineItem?.ancillaryData?.userSelections.time,

    locations: [],
    transfer_tripType: !isNil(props.currentlyEditingLineItem?.ancillaryData?.userSelections.returnHash)
      ? EAncillaryTransferTripType.RETURN
      : EAncillaryTransferTripType.ONE_WAY,

    // the transfer specific data gets set based on if the one we're editing is inbound or outbound
    transfer_pickupDate: isCurrentlyEditingInboundOrOneWay
      ? props.currentlyEditingLineItem?.ancillaryData?.userSelections.selectedDate
      : matchingReturnLineItem?.ancillaryData?.userSelections.selectedDate,
    transfer_returnDate: isCurrentlyEditingInboundOrOneWay
      ? matchingReturnLineItem?.ancillaryData?.userSelections.selectedDate
      : props.currentlyEditingLineItem?.ancillaryData?.userSelections.selectedDate,
    transfer_pickupTime: isCurrentlyEditingInboundOrOneWay
      ? props.currentlyEditingLineItem?.ancillaryData?.userSelections.time
      : matchingReturnLineItem?.ancillaryData?.userSelections.time,
    transfer_returnTime: isCurrentlyEditingInboundOrOneWay
      ? matchingReturnLineItem?.ancillaryData?.userSelections.time
      : props.currentlyEditingLineItem?.ancillaryData?.userSelections.time,
  });

  const fetchProductData = async () => {
    let response: AxiosResponse<AncillaryProductWithRates> | undefined;

    if (productType === EAncillaryProductType.ACTIVITY || productType === EAncillaryProductType.GROUND_SERVICE) {
      response = await bookingManagerApi.getAncillaryRatesProduct({
        bookingUuid: props.bookingUuid,
        baseProductId: props.currentlyEditingLineItem?.ancillaryData?.userSelections.baseProductId,
        dates: [props.arrivalDate, props.departureDate],
        variantId: props.currentlyEditingLineItem?.ancillaryData?.userSelections.variantId || undefined,
        numberOfAdults: state.numberOfAdults,
        agesOfAllChildren: state.agesOfAllChildren,
        perUnitQuantityOverride: state.selectedProduct?.userData.unitCount,
        airportId: props.currentlyEditingLineItem?.ancillaryData?.userSelections.airportId,
      });
    }

    if (productType === EAncillaryProductType.TRANSFER) {
      let dates: string[] = [];
      if (isNil(state.transfer_returnDate)) {
        dates = [state.transfer_pickupDate!];
      } else {
        dates = [state.transfer_pickupDate!, state.transfer_returnDate!];
      }
      response = await bookingManagerApi.getAncillaryRatesProduct({
        bookingUuid: props.bookingUuid,
        baseProductId: props.currentlyEditingLineItem?.ancillaryData?.userSelections.baseProductId,
        dates,
        variantId: props.currentlyEditingLineItem?.ancillaryData?.userSelections.variantId || undefined,
        numberOfAdults: state.numberOfAdults,
        agesOfAllChildren: state.agesOfAllChildren,
        perUnitQuantityOverride: state.selectedProduct?.userData.unitCount,
        // @ts-ignore - both properties WILL be set if we're editing a transfer
        transferLocations: props.currentlyEditingLineItem?.ancillaryData?.userSelections.transferLocations!,
      });
    }
    return response;
  };

  const fetchLocations = async () => {
    const response = await bookingManagerApi.postAncillaryLocationsSearch(productType, [
      EAncillaryLocationType.AIRPORT,
      EAncillaryLocationType.HOTEL,
      EAncillaryLocationType.PLACE,
    ]);
    return response;
  };

  // the enabled dates are
  // - the arrival date to the departure date
  // - but then also any dates that have been rated
  let enabledDates: string[] = [];
  enabledDates = DateHelper.generateSequenceOfDates(props.arrivalDate, props.departureDate).map(
    date => date.split('T')[0]
  );
  // transfers DONT get limited by the date that we get back
  if ([EAncillaryProductType.ACTIVITY, EAncillaryProductType.GROUND_SERVICE].includes(productType)) {
    enabledDates = enabledDates.filter(date => state.fetchedRates[date] && state.fetchedRates[date].hasRateForDate);
  }

  // this calculates which attributes now have an invalid selection (because either their
  // selected value doesnt exist anymore, or the attribute itself doesnt exist anymore)
  const flexibleAttributesWithAnInvalidSelection: any[] = [];
  Object.keys(state.selectedProduct?.userData.optionSelections!).forEach(optionId => {
    // get the name of the option from the original product on the state
    const optionName = state.selectedProduct?.product.flexibleAttributes.find(option => option.id === Number(optionId))
      ?.name;
    // get the option from the fetched product
    const apiOption = state.fetchedProduct?.flexibleAttributes.find(option => option.name === optionName);

    // now get the value we have for this option from the state.selectedProduct
    const selectedValue = state.selectedProduct?.userData.optionSelections[optionId] as string;
    const exists = flatMap(apiOption?.values.map(v => v.value)).includes(selectedValue);

    if (isNil(apiOption)) {
      flexibleAttributesWithAnInvalidSelection.push({
        id: Number(optionId),
        name: optionName!,
        selectedValue: selectedValue,
        invalidType: 'attribute',
        errorMessage: `Note: "${optionName}" no longer exists in the system for this product.`,
      });
    }
    if ((apiOption?.type === 'option' || apiOption?.type === 'option-boolean') && !exists) {
      flexibleAttributesWithAnInvalidSelection.push({
        id: Number(optionId),
        name: optionName!,
        selectedValue: selectedValue,
        invalidType: 'selection',
        errorMessage: `Note: "${selectedValue}" no longer exists in the system for attribute "${optionName}". Please consider selecting a new option.`,
      });
    }
  });

  const baseProductId = props.currentlyEditingLineItem?.ancillaryData?.product?.baseProductId;
  const airport = state.locations.find(l => l.compositeId === `${airportId}::${EAncillaryLocationType.AIRPORT}`);

  const prevBaseProductId = usePrevious(baseProductId);
  const prevUnitCount = usePrevious(state.selectedProduct?.userData.unitCount, true);
  const prevNumberOfAdults = usePrevious(state.numberOfAdults, true);
  const prevAgesOfAllChildren = usePrevious(state.agesOfAllChildren, true);

  // this useEffect acts as a master "API caller" handler.
  // we do it all in one useEffect because otherwise the setState
  // calls overwrite each other
  // this way lets us do a single setState inside the Promise.all().then()
  // all the conditionals (e.g shouldFetchLocations) are because we dont
  // always want to be fetching everything
  useEffect(() => {
    if (
      prevBaseProductId === baseProductId &&
      prevNumberOfAdults === state.numberOfAdults &&
      prevAgesOfAllChildren === state.agesOfAllChildren &&
      prevUnitCount !== state.selectedProduct?.userData.unitCount &&
      isAncillaryRatedResult(state.selectedProduct?.userData.rate!) &&
      state.selectedProduct?.userData.rate?.rateType !== 'per_unit'
    ) {
      // so the only thing that has changed is the unit count, and its NOT a per unit rate -
      // just return because a changing unit count for per person isnt a thing
      return;
    }

    const shouldFetchLocations =
      baseProductId !== prevBaseProductId &&
      [EAncillaryProductType.GROUND_SERVICE, EAncillaryProductType.TRANSFER].includes(productType);

    setState({
      ...state,
      getProductNetworkRequest: ENetworkRequestStatus.PENDING,
      getLocationsNetworkRequest: shouldFetchLocations
        ? ENetworkRequestStatus.PENDING
        : state.getLocationsNetworkRequest,
    });

    Promise.all([shouldFetchLocations ? fetchLocations() : null, fetchProductData()])
      .then(responses => {
        const newState = { ...state };
        if (!isNil(responses[0])) {
          newState.locations = [
            ...responses[0].data.airports.map(x => ({
              ...x,
              compositeId: `${x.id}::${EAncillaryLocationType.AIRPORT}`,
              type: EAncillaryLocationType.AIRPORT,
            })),
            ...responses[0].data.hotels.map(x => ({
              ...x,
              compositeId: `${x.id}::${EAncillaryLocationType.HOTEL}`,
              type: EAncillaryLocationType.HOTEL,
            })),
            ...responses[0].data.places.map(x => ({
              ...x,
              compositeId: `${x.id}::${EAncillaryLocationType.PLACE}`,
              type: EAncillaryLocationType.PLACE,
            })),
          ];
          newState.getLocationsNetworkRequest = ENetworkRequestStatus.SUCCESS;
        }
        if (!isNil(responses[1])) {
          const ratedDateFromLineItem = props.currentlyEditingLineItem?.ancillaryData?.rateForDate?.date!;
          const newRateForDate = responses[1].data.rates[ratedDateFromLineItem];
          newState.getProductNetworkRequest = ENetworkRequestStatus.SUCCESS;
          newState.fetchedRates = responses[1].data.rates;
          newState.fetchedProduct = responses[1].data.product;

          // we need to merge together the flexibleAttributes from state.selectedProduct
          // and the fetched product, but only if they dont already exist in the selectedProduct
          // this is to ensure they get any new flexible attributes as well as the "original" ones
          if (state.selectedProduct) {
            const newFlexibleAttributes = responses[1].data.product.flexibleAttributes.filter(
              fa => !state.selectedProduct?.product.flexibleAttributes.some(fa2 => fa2.name === fa.name)
            );

            newState.selectedProduct = {
              ...state.selectedProduct,
              product: {
                ...state.selectedProduct.product,
                flexibleAttributes: [...state.selectedProduct.product.flexibleAttributes, ...newFlexibleAttributes],
                productRestrictions: responses[1].data.product.productRestrictions,
              },
            };
          }

          // this is so that we dont update the rate/price on intial load
          // (remember - useEffect runs on changes AND initial render)
          if (!isNil(newRateForDate) && isAncillaryRatedResult(newRateForDate)) {
            if (
              prevUnitCount !== state.selectedProduct?.userData.unitCount ||
              prevNumberOfAdults !== state.numberOfAdults ||
              prevAgesOfAllChildren !== state.agesOfAllChildren
            ) {
              newState.selectedProduct = {
                ...state.selectedProduct!,
                userData: {
                  ...state.selectedProduct!.userData,
                  rate: newRateForDate,
                  unitCount: newRateForDate.quantity,
                },
              };
            }
          }
        }
        if (newState.transfer_pickupDate) {
          const newRate = newState.fetchedRates[newState.transfer_pickupDate];
          if (newRate && isAncillaryRatedResult(newRate)) {
            newState.transfer_pickupDate = newRate.date;
            newState.selectedProduct = {
              ...newState.selectedProduct!,
              userData: {
                ...newState.selectedProduct!.userData,
                rate: newRate,
                unitCount: newRate.quantity,
              },
            };
            // @ts-ignore
            newState.price = newRate.price!;
            // @ts-ignore
            newState.isOnRequest = newRate.rateIsOnRequest;
          }
        }
        setState(newState);
      })
      .catch(e => {
        console.error(e);
        setState({
          ...state,
          getProductNetworkRequest: ENetworkRequestStatus.ERROR,
          getLocationsNetworkRequest: shouldFetchLocations
            ? ENetworkRequestStatus.ERROR
            : state.getLocationsNetworkRequest,
        });
      });
  }, [
    baseProductId,
    state.selectedProduct?.userData.unitCount,
    state.numberOfAdults,
    state.agesOfAllChildren,
    state.transfer_pickupDate,
    state.transfer_returnDate,
  ]);

  // when the user changes the selected date, we need to get the new rate from the fetched rates
  useEffect(() => {
    if (state.selectedDate) {
      const newRate = state.fetchedRates[state.selectedDate];
      if (newRate && isAncillaryRatedResult(newRate)) {
        setState(state => ({
          ...state,
          selectedDate: newRate.date,
          selectedProduct: {
            ...state.selectedProduct!,
            userData: {
              ...state.selectedProduct!.userData,
              rate: newRate,
              unitCount: newRate.quantity,
            },
          },
          price: newRate.price!,
          isOnRequest: newRate.rateIsOnRequest,
        }));
      }
    }
  }, [state.selectedDate]);

  const handleOnSave = () => {
    // need to replace the line item that we're editing with the new one
    // ...unless, you guessed it, we're editing a transfer

    if (isNil(state.selectedProduct) || !isAncillaryRatedResult(state.selectedProduct.userData.rate!)) {
      return;
    }

    const newErrorIds: number[] = [];
    state.selectedProduct.product.flexibleAttributes.forEach((fa, i) => {
      if (fa.type === 'option' && isNil(state.selectedProduct!.userData.optionSelections[fa.id])) {
        newErrorIds.push(fa.id);
      }
    });

    if (newErrorIds.length > 0) {
      setState(state => ({ ...state, errorIds: newErrorIds }));
      return;
    }

    let productPath;
    let toastMessageNoun;
    switch (productType) {
      case EAncillaryProductType.ACTIVITY:
        productPath = 'Bespoke';
        toastMessageNoun = 'Activity';
        break;
      case EAncillaryProductType.GROUND_SERVICE:
        productPath = 'Ground Service';
        toastMessageNoun = 'Ground Service';
        break;
      case EAncillaryProductType.TRANSFER:
        productPath = 'Transfer';
        toastMessageNoun = 'Transfer';
        break;
      default:
        throw new Error('Unknown product type');
    }

    // if we're editing an activity or ground service, do the basic stuff
    if (productType === EAncillaryProductType.ACTIVITY || productType === EAncillaryProductType.GROUND_SERVICE) {
      const {
        title,
        tertiaryText,
        purchaseCostCents,
        saleCostCents,
        flexibleAttributesNonValidatedSelections,
      } = generateBreakdownItemDataForAncillaryProduct(
        state.selectedProduct,
        {
          numberOfAdults: state.numberOfAdults,
          agesOfAllChildren: state.agesOfAllChildren,
        },
        {
          airport,
          pickupTime: state.groundService_pickupTime,
        }
      );

      if (!isAncillaryRatedResult(state.selectedProduct.userData.rate!)) {
        return;
      }

      dispatch(
        BreakdownActions.replaceHeadlineLineItemAction(`${productPath}.items`, props.currentlyEditingLineItemIndex, {
          title,
          tertiaryText,
          saleCostCents,
          purchaseCostCents,
          costBeforeDiscountCents: saleCostCents,
          ancillaryData: {
            isNew: false,
            product: state.selectedProduct.product,
            rateForDate: state.selectedProduct.userData.rate,
            userSelections: {
              selectionHash: state.selectedProduct.userData.rate.hash,
              selectedDate: state.selectedDate,
              numberOfAdults: state.numberOfAdults,
              agesOfAllChildren: state.agesOfAllChildren || [],
              baseProductId: state.selectedProduct.product.baseProductId,
              variantId: !isNil(state.selectedProduct.product.variantId)
                ? state.selectedProduct.product.variantId
                : undefined,
              perUnitQuantityOverride: state.selectedProduct.userData.unitCount,
              flexibleAttributesNonValidatedSelections,
              flexibleAttributesValidatedSelections:
                props.currentlyEditingLineItem?.ancillaryData?.userSelections.flexibleAttributesValidatedSelections,
              airportId,
              time: state.groundService_pickupTime,
            },
          },
        })
      );
    }

    if (productType === EAncillaryProductType.TRANSFER) {
      // of course, transfers is different...

      const pickupFromLocation = state.locations.find(
        location =>
          location.compositeId ===
          `${props.currentlyEditingLineItem?.ancillaryData?.userSelections.transferLocations?.from?.id}::${props.currentlyEditingLineItem?.ancillaryData?.userSelections.transferLocations?.from?.type}`
      );

      const dropOffLocation = state.locations.find(
        location =>
          location.compositeId ===
          `${props.currentlyEditingLineItem?.ancillaryData?.userSelections.transferLocations?.to?.id}::${props.currentlyEditingLineItem?.ancillaryData?.userSelections.transferLocations?.to?.type}`
      );

      const {
        inboundInitialData,
        outboundInitialData,
        inboundAncillaryRequestData,
        outboundAncillaryRequestData,
      } = generateBreakdownItemDataForAncillaryTransfers({
        selectedTransferProduct: state.selectedProduct!,
        guestAges: {
          numberOfAdults: state.numberOfAdults,
          agesOfAllChildren: state.agesOfAllChildren,
        },
        pickupTime: state.transfer_pickupTime!,
        pickupFromLocationName: pickupFromLocation?.name!,
        dropofflocationName: dropOffLocation?.name!,
        pickupFromLocation: pickupFromLocation!,
        dropOffLocation: dropOffLocation!,
        dates: [state.transfer_pickupDate!, state.transfer_returnDate!].filter(date => !!date),
        returnTime: state.transfer_returnTime!,
        isReturn: state.transfer_tripType === EAncillaryTransferTripType.RETURN,
      });

      if (isCurrentlyEditingOriginallyOneWay && state.transfer_tripType === EAncillaryTransferTripType.ONE_WAY) {
        // if it was originally one way and still is one way, we need to replace the line item
        dispatch(
          BreakdownActions.replaceHeadlineLineItemAction(`${productPath}.items`, props.currentlyEditingLineItemIndex, {
            title: inboundInitialData.title,
            tertiaryText: inboundInitialData.tertiaryText,
            saleCostCents: inboundInitialData.saleCostCents,
            purchaseCostCents: inboundInitialData.purchaseCostCents,
            costBeforeDiscountCents: inboundInitialData.saleCostCents,
            ancillaryData: inboundAncillaryRequestData as IAncillaryData,
          })
        );
      } else if (!isCurrentlyEditingOriginallyOneWay && state.transfer_tripType === EAncillaryTransferTripType.RETURN) {
        dispatch(
          BreakdownActions.replaceHeadlineLineItemAction(
            `${productPath}.items`,
            Math.min(props.currentlyEditingLineItemIndex, matchingReturnLineItemIndex!), // the inbound is always "first"
            {
              title: inboundInitialData.title,
              tertiaryText: inboundInitialData.tertiaryText,
              saleCostCents: inboundInitialData.saleCostCents,
              purchaseCostCents: inboundInitialData.purchaseCostCents,
              costBeforeDiscountCents: inboundInitialData.saleCostCents,
              ancillaryData: inboundAncillaryRequestData as IAncillaryData,
            }
          )
        );

        dispatch(
          BreakdownActions.replaceHeadlineLineItemAction(
            `${productPath}.items`,
            Math.max(props.currentlyEditingLineItemIndex, matchingReturnLineItemIndex!), // the outbound is always "last"
            {
              title: outboundInitialData.title,
              tertiaryText: outboundInitialData.tertiaryText,
              saleCostCents: outboundInitialData.saleCostCents,
              purchaseCostCents: outboundInitialData.purchaseCostCents,
              costBeforeDiscountCents: outboundInitialData.saleCostCents,
              ancillaryData: outboundAncillaryRequestData as IAncillaryData,
            }
          )
        );
      } else if (isCurrentlyEditingOriginallyOneWay && state.transfer_tripType === EAncillaryTransferTripType.RETURN) {
        // it was originally a one way, and now its a return.
        // so we need to replace the line item that we're editing with the new one
        // and also add a new one at the index after for the return
        dispatch(
          BreakdownActions.replaceHeadlineLineItemAction(`${productPath}.items`, props.currentlyEditingLineItemIndex, {
            title: inboundInitialData.title,
            tertiaryText: inboundInitialData.tertiaryText,
            saleCostCents: inboundInitialData.saleCostCents,
            purchaseCostCents: inboundInitialData.purchaseCostCents,
            costBeforeDiscountCents: inboundInitialData.saleCostCents,
            ancillaryData: inboundAncillaryRequestData as IAncillaryData,
          })
        );

        dispatch(
          BreakdownActions.addHeadlineLineItemAction(
            `${productPath}.items`,
            outboundInitialData,
            outboundAncillaryRequestData as IAncillaryData,
            undefined,
            props.currentlyEditingLineItemIndex + 1
          )
        );
      } else if (
        !isCurrentlyEditingOriginallyOneWay &&
        state.transfer_tripType === EAncillaryTransferTripType.ONE_WAY
      ) {
        const minIndex = Math.min(props.currentlyEditingLineItemIndex, matchingReturnLineItemIndex!);
        const maxIndex = Math.max(props.currentlyEditingLineItemIndex, matchingReturnLineItemIndex!);
        // it was originally a return, and now its a one way.
        // so we need to replace the lowest index of the 2 indexes that we're editing with the new one
        // and delete the highest index of the 2 indexes that we're editing with the new one
        dispatch(
          BreakdownActions.replaceHeadlineLineItemAction(`${productPath}.items`, minIndex, {
            title: inboundInitialData.title,
            tertiaryText: inboundInitialData.tertiaryText,
            saleCostCents: inboundInitialData.saleCostCents,
            purchaseCostCents: inboundInitialData.purchaseCostCents,
            costBeforeDiscountCents: inboundInitialData.saleCostCents,
            ancillaryData: inboundAncillaryRequestData as IAncillaryData,
          })
        );

        dispatch(BreakdownActions.removeHeadlineLineItemAction(`${productPath}.items`, maxIndex!));
      }
    }

    dispatch(BreakdownActions.setCurrentlyEditingLineItemAction(null, null));
    dispatch(
      UiActions.enqueueNotification({
        message: `${toastMessageNoun} updated successfully`,
        options: { variant: 'success' },
      })
    );
  };

  return (
    <EditAncillaryProductModal
      flexibleAttributesWithAnInvalidSelection={flexibleAttributesWithAnInvalidSelection}
      currencySymbol={props.currencySymbol}
      currentlyEditingLineItem={props.currentlyEditingLineItem}
      containerState={state}
      updateContainerState={setState}
      enabledDates={enabledDates}
      airport={airport}
      onClose={() => {
        dispatch(BreakdownActions.setCurrentlyEditingLineItemAction(null, null));
      }}
      onSave={handleOnSave}
    />
  );
};
