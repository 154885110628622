import { ICompanyMembership } from 'services/BookingManagerApi/types/CompanyMemberships';
import { ICompanyMembershipDataPayload } from './model';

export const FETCH_COMPANY_MEMBERSHIPS_REQUEST = 'companyMemberships/FETCH_COMPANY_MEMBERSHIPS_REQUEST';
export const FETCH_COMPANY_MEMBERSHIPS_SUCCESS = 'companyMemberships/FETCH_COMPANY_MEMBERSHIPS_SUCCESS';
export const FETCH_COMPANY_MEMBERSHIPS_FAILURE = 'companyMemberships/FETCH_COMPANY_MEMBERSHIPS_FAILURE';
export const RESET_COMPANY_MEMBERSHIPS = 'companyMemberships/RESET_COMPANY_MEMBERSHIPS_REQUEST';

export const CREATE_COMPANY_MEMBERSHIP_REQUEST = 'companyMemberships/CREATE_COMPANY_MEMBERSHIP_REQUEST';
export const CREATE_COMPANY_MEMBERSHIP_SUCCESS = 'companyMemberships/CREATE_COMPANY_MEMBERSHIP_SUCCESS';
export const CREATE_COMPANY_MEMBERSHIP_FAILURE = 'companyMemberships/CREATE_COMPANY_MEMBERSHIP_FAILURE';

export const EDIT_COMPANY_MEMBERSHIP_REQUEST = 'companyMemberships/EDIT_COMPANY_MEMBERSHIP_REQUEST';
export const EDIT_COMPANY_MEMBERSHIP_SUCCESS = 'companyMemberships/EDIT_COMPANY_MEMBERSHIP_SUCCESS';
export const EDIT_COMPANY_MEMBERSHIP_FAILURE = 'companyMemberships/EDIT_COMPANY_MEMBERSHIP_FAILURE';

export const DELETE_COMPANY_MEMBERSHIP_REQUEST = 'companyMemberships/DELETE_COMPANY_MEMBERSHIP_REQUEST';
export const DELETE_COMPANY_MEMBERSHIP_SUCCESS = 'companyMemberships/DELETE_COMPANY_MEMBERSHIP_SUCCESS';
export const DELETE_COMPANY_MEMBERSHIP_FAILURE = 'companyMemberships/DELETE_COMPANY_MEMBERSHIP_FAILURE';

export const SET_UUID_TO_DELETE = 'companyMemberships/SET_UUID_TO_DELETE';
export const SET_MEMBERSHIP_TO_EDIT = 'companyMemberships/SET_MEMBERSHIP_TO_EDIT';

export const OPEN_CREATE_MEMBERSHIP_MODAL = 'companyMemberships/OPEN_CREATE_MEMBERSHIP_MODAL';
export const CLOSE_CREATE_MEMBERSHIP_MODAL = 'companyMemberships/CLOSE_CREATE_MEMBERSHIP_MODAL';

export type FetchCompanyMembershipsRequestAction = ReturnType<typeof fetchCompanyMembershipsRequestAction>;
export const fetchCompanyMembershipsRequestAction = (companyUuid: string) => ({
  type: FETCH_COMPANY_MEMBERSHIPS_REQUEST as typeof FETCH_COMPANY_MEMBERSHIPS_REQUEST,
  companyUuid,
});

export type FetchCompanyMembershipsSuccessAction = ReturnType<typeof fetchCompanyMembershipsSuccessAction>;
export const fetchCompanyMembershipsSuccessAction = (memberships: ICompanyMembership[]) => ({
  type: FETCH_COMPANY_MEMBERSHIPS_SUCCESS as typeof FETCH_COMPANY_MEMBERSHIPS_SUCCESS,
  memberships,
});

export type FetchCompanyMembershipsFailureAction = ReturnType<typeof fetchCompanyMembershipsFailureAction>;
export const fetchCompanyMembershipsFailureAction = (error: Error) => ({
  type: FETCH_COMPANY_MEMBERSHIPS_FAILURE as typeof FETCH_COMPANY_MEMBERSHIPS_FAILURE,
  error,
});

export type ResetCompanyMembershipsAction = ReturnType<typeof resetCompanyMembershipsAction>;
export const resetCompanyMembershipsAction = () => ({
  type: RESET_COMPANY_MEMBERSHIPS as typeof RESET_COMPANY_MEMBERSHIPS,
});

export type CreateCompanyMembershipRequestAction = ReturnType<typeof createCompanyMembershipRequestAction>;
export const createCompanyMembershipRequestAction = (membershipPayload: ICompanyMembershipDataPayload) => ({
  type: CREATE_COMPANY_MEMBERSHIP_REQUEST as typeof CREATE_COMPANY_MEMBERSHIP_REQUEST,
  membershipPayload,
});

export type CreateCompanyMembershipSuccessAction = ReturnType<typeof createCompanyMembershipSuccessAction>;
export const createCompanyMembershipSuccessAction = () => ({
  type: CREATE_COMPANY_MEMBERSHIP_SUCCESS as typeof CREATE_COMPANY_MEMBERSHIP_SUCCESS,
});

export type CreateCompanyMembershipFailureAction = ReturnType<typeof createCompanyMembershipFailureAction>;
export const createCompanyMembershipFailureAction = (error: Error) => ({
  type: CREATE_COMPANY_MEMBERSHIP_FAILURE as typeof CREATE_COMPANY_MEMBERSHIP_FAILURE,
  error,
});

export type OpenCreateMembershipModalAction = ReturnType<typeof openCreateMembershipModalAction>;
export const openCreateMembershipModalAction = () => ({
  type: OPEN_CREATE_MEMBERSHIP_MODAL as typeof OPEN_CREATE_MEMBERSHIP_MODAL,
});

export type CloseCreateMembershipModalAction = ReturnType<typeof closeCreateMembershipModalAction>;
export const closeCreateMembershipModalAction = () => ({
  type: CLOSE_CREATE_MEMBERSHIP_MODAL as typeof CLOSE_CREATE_MEMBERSHIP_MODAL,
});

export type EditCompanyMembershipRequestAction = ReturnType<typeof editCompanyMembershipRequestAction>;
export const editCompanyMembershipRequestAction = (membershipPayload: ICompanyMembershipDataPayload) => ({
  type: EDIT_COMPANY_MEMBERSHIP_REQUEST as typeof EDIT_COMPANY_MEMBERSHIP_REQUEST,
  membershipPayload,
});

export type EditCompanyMembershipSuccessAction = ReturnType<typeof editCompanyMembershipSuccessAction>;
export const editCompanyMembershipSuccessAction = () => ({
  type: EDIT_COMPANY_MEMBERSHIP_SUCCESS as typeof EDIT_COMPANY_MEMBERSHIP_SUCCESS,
});

export type EditCompanyMembershipFailureAction = ReturnType<typeof editCompanyMembershipFailureAction>;
export const editCompanyMembershipFailureAction = (error: Error) => ({
  type: EDIT_COMPANY_MEMBERSHIP_FAILURE as typeof EDIT_COMPANY_MEMBERSHIP_FAILURE,
  error,
});

export type DeleteCompanyMembershipRequestAction = ReturnType<typeof deleteCompanyMembershipRequestAction>;
export const deleteCompanyMembershipRequestAction = (membershipUuid: string, companyUuid: string) => ({
  type: DELETE_COMPANY_MEMBERSHIP_REQUEST as typeof DELETE_COMPANY_MEMBERSHIP_REQUEST,
  membershipUuid,
  companyUuid,
});

export type DeleteCompanyMembershipSuccessAction = ReturnType<typeof deleteCompanyMembershipSuccessAction>;
export const deleteCompanyMembershipSuccessAction = () => ({
  type: DELETE_COMPANY_MEMBERSHIP_SUCCESS as typeof DELETE_COMPANY_MEMBERSHIP_SUCCESS,
});

export type DeleteCompanyMembershipFailureAction = ReturnType<typeof deleteCompanyMembershipFailureAction>;
export const deleteCompanyMembershipFailureAction = (error: Error) => ({
  type: DELETE_COMPANY_MEMBERSHIP_FAILURE as typeof DELETE_COMPANY_MEMBERSHIP_FAILURE,
  error,
});

export type SetUuidToDeleteAction = ReturnType<typeof setUuidToDeleteAction>;
export const setUuidToDeleteAction = (uuidToDelete: string | null) => ({
  type: SET_UUID_TO_DELETE as typeof SET_UUID_TO_DELETE,
  uuidToDelete,
});

export type SetMembershipToEditAction = ReturnType<typeof setMembershipToEditAction>;
export const setMembershipToEditAction = (membership: ICompanyMembership | null) => ({
  type: SET_MEMBERSHIP_TO_EDIT as typeof SET_MEMBERSHIP_TO_EDIT,
  membership,
});

export type CompanyMembershipsAction =
  | FetchCompanyMembershipsRequestAction
  | FetchCompanyMembershipsSuccessAction
  | FetchCompanyMembershipsFailureAction
  | CreateCompanyMembershipRequestAction
  | CreateCompanyMembershipSuccessAction
  | CreateCompanyMembershipFailureAction
  | EditCompanyMembershipRequestAction
  | EditCompanyMembershipSuccessAction
  | EditCompanyMembershipFailureAction
  | ResetCompanyMembershipsAction
  | DeleteCompanyMembershipRequestAction
  | DeleteCompanyMembershipSuccessAction
  | DeleteCompanyMembershipFailureAction
  | SetUuidToDeleteAction
  | SetMembershipToEditAction
  | OpenCreateMembershipModalAction
  | CloseCreateMembershipModalAction;
