import { ENetworkRequestStatus, IInvoiceAddressee } from 'services/BackendApi';

export const emptyInvoiceAddressee = (): IInvoiceAddressee => ({
  name: null,
  companyName: null,
  address: null,
  countryCode: null,
  email: null,
  vat: null,
  iata: null
});

export interface ICompanyInvoiceAddresseeDomain {
  editable: IInvoiceAddressee | null,
  request: {
    get: ENetworkRequestStatus;
    update: ENetworkRequestStatus;
  };
}

export const initialState: ICompanyInvoiceAddresseeDomain = {
  editable: null,
  request: {
    get: ENetworkRequestStatus.IDLE,
    update: ENetworkRequestStatus.IDLE
  }
};
