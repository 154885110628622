import { call, takeLatest, select, put, debounce } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBookingManagerApi, IBookingSearchResponse } from 'services/BookingManagerApi';
import { bookingsListQuerySelector } from '../selectors';
import * as BookingListActions from '../actions';

export function* getBookingsListSaga(action: any) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const query = yield select(bookingsListQuerySelector);
    yield put(BookingListActions.setIsRefetchingBookingsAction(true));
    const result: AxiosResponse<IBookingSearchResponse> = yield call(bookingManagerApi.bookingSearch, query);
    yield put(BookingListActions.getBookingListSuccessAction(result.data.bookings, result.data.count));
    yield put(BookingListActions.setIsRefetchingBookingsAction(false));
  } catch (e) {
    yield put(BookingListActions.getBookingListFailureAction(e));
    yield put(BookingListActions.setIsRefetchingBookingsAction(false));
  }
}

export function* watchGetBookingsList() {
  yield takeLatest(
    [
      BookingListActions.GET_BOOKING_LIST_REQUEST,
      BookingListActions.SET_HUMAN_READABLE_ID_FILTER,
      BookingListActions.SET_GUEST_NAME_FILTER,
      BookingListActions.SET_TRAVEL_PARTNER_REF_FILTER,
      BookingListActions.SET_SORT,
      BookingListActions.SET_PAGE_NUMBER,
      BookingListActions.SET_ITEMS_PER_PAGE,
      BookingListActions.SET_TRAVEL_AGENT_UUID,
      BookingListActions.SET_TRAVEL_AGENT_COUNTRY_CODE,
      BookingListActions.SET_COMPANY_UUID,
      BookingListActions.SET_DESTINATION,
      BookingListActions.SET_SALES_REPRESENTATIVE_UUID,
      BookingListActions.SET_STATUSES,
      BookingListActions.SET_BOOKING_TYPE,
      BookingListActions.SET_DATE_RANGE_VALUE,
      BookingListActions.SET_DATE_RANGE_TYPE,
      BookingListActions.CLEAR_DATE_RANGE_FILTER,
      BookingListActions.SET_ONLY_SHOW_WITH_INVOICE_FILTER,
      BookingListActions.SET_ONLY_SHOW_WITH_GROUND_SERVICES_FILTER,
      BookingListActions.SET_HAS_OVERDUE_PAYMENTS_FILTER,
      BookingListActions.SET_HAS_PROFORMA_CREATED_FILTER,
      BookingListActions.SET_HAS_INVOICE_CREATED_FILTER,
      BookingListActions.SET_SHOW_FULLY_PAID_BY_TA_FILTER,
      BookingListActions.SET_ONLY_SHOW_MY_BOOKINGS_FILTER,
      BookingListActions.SET_RATE_SOURCE_FILTER,
      BookingListActions.RESET_FILTERS,
      BookingListActions.SET_HAS_HOTEL_COMMISSION_AFTER_CHECKOUT_FILTER,
    ],
    getBookingsListSaga
  );

  yield debounce(
    200,
    [BookingListActions.SET_GUEST_NAME_FILTER, BookingListActions.SET_PRODUCT_CONFIRMATION_NUMBER_FILTER],
    getBookingsListSaga
  );
}
