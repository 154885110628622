import { IBootstrapModule, initialState } from './model';
import { BoostrapDomainAction, BOOTSTRAP_DYNAMIC_PARAMETERS_FAILURE, BOOTSTRAP_DYNAMIC_PARAMETERS_SUCCESS, SET_MAIN_COMPANY_INFO, SAVE_BOOTSTRAP_DATA } from './actions';
import { produce } from 'immer';

export const bootstrap = (state: IBootstrapModule = initialState, action: BoostrapDomainAction): IBootstrapModule => {
  switch (action.type) {
    case BOOTSTRAP_DYNAMIC_PARAMETERS_SUCCESS:
      return produce(state, draftState => {
        draftState.dynamicParameters = action.dynamicParameters;
        return draftState;
      });
      
    case SAVE_BOOTSTRAP_DATA:
      return produce(state, draftState => {
        draftState.countries = action.countries;
        draftState.hotels = action.hotels;
        draftState.extraPersonSupplementProduct = action.extraPersonSupplementProduct;
        return draftState;
      });

    case BOOTSTRAP_DYNAMIC_PARAMETERS_FAILURE:
      return produce(state, draftState => {
        draftState.error = action.error;
        return draftState;
      });

    case SET_MAIN_COMPANY_INFO:
      return produce(state, draftState => {
        draftState.mainCompany = action.mainCompany.data.info;
        draftState.mainCompanyLogo = action.mainCompany.data.logo;
        return draftState;
      });

    default:
      return state;
  }
};
