import { initialState, IOwnershipSubdomain } from './model';
import * as Actions from './actions';
import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';

const ownershipReducer = (state: IOwnershipSubdomain = initialState, action: Actions.OwnershipAction) => {
  switch (action.type) {
    case Actions.GET_OWNERSHIP_REQUEST:
      return produce(state, draftState => {
        draftState.request.getOwnership = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_OWNERSHIP_SUCCESS:
      return produce(state, draftState => {
        draftState.request.getOwnership = ENetworkRequestStatus.SUCCESS;
        draftState.ownershipData = action.ownershipData;
        return draftState;
      });

    case Actions.GET_OWNERSHIP_FAILURE:
      return produce(state, draftState => {
        draftState.request.getOwnership = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.UPDATE_OWNERSHIP_REQUEST:
      return produce(state, draftState => {
        draftState.request.patchOwnership = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.UPDATE_OWNERSHIP_SUCCESS:
      return produce(state, draftState => {
        draftState.request.patchOwnership = ENetworkRequestStatus.SUCCESS;
        draftState.ownershipData = action.ownershipData;
        return draftState;
      });

    case Actions.UPDATE_OWNERSHIP_FAILURE:
      return produce(state, draftState => {
        draftState.request.patchOwnership = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.GET_ASSIGNED_SALES_REPRESENTATIVES_REQUEST:
      return produce(state, draftState => {
        draftState.request.getAssignedSalesRepresentatives = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_ASSIGNED_SALES_REPRESENTATIVES_SUCCESS:
      return produce(state, draftState => {
        draftState.request.getAssignedSalesRepresentatives = ENetworkRequestStatus.SUCCESS;
        draftState.assignedSalesRepresentatives = action.salesRepresentatives;
        return draftState;
      });

    case Actions.GET_ASSIGNED_SALES_REPRESENTATIVES_FAILURE:
      return produce(state, draftState => {
        draftState.request.getAssignedSalesRepresentatives = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.GET_TP_MOVEMENTS_REQUEST:
      return produce(state, draftState => {
        draftState.request.getTravelPartnerMovements = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_TP_MOVEMENTS_SUCCESS:
      return produce(state, draftState => {
        draftState.request.getTravelPartnerMovements = ENetworkRequestStatus.SUCCESS;
        draftState.travelPartnerMovements = action.movements;
        return draftState;
      });

    case Actions.GET_TP_MOVEMENTS_FAILURE:
      return produce(state, draftState => {
        draftState.request.getTravelPartnerMovements = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    default:
      return state;
  }
};

export default ownershipReducer;
