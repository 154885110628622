import React from 'react';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

interface IActionButtonProps {
  action: (removePath: string, index: number) => void;
  pathPrefix: string;
  productType: string;
  index: number;
}

const GA_FLOW_TAG = 'act-bkd';

export const RemoveHeadlineButton = (props: IActionButtonProps) => {
  const { dynamicParameters } = useDynamicParameters();

  const { action: removeHeadlineLineItemAction, pathPrefix, productType, index } = props;

  return (
    <span className="w-6">
      <button
        className="delete rounded-full p-0 w-6 h-6 bg-white hover:bg-brown-40 border border-solid border-brown-80 cursor-pointer font-pt-sans"
        onClick={() => {
          if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID && productType === 'Bespoke') {
            // @ts-ignore
            window.gtag('event', `${GA_FLOW_TAG}_remove-item`, {});
          }
          removeHeadlineLineItemAction(`${pathPrefix}${productType}.items`, index);
        }}
      >
        <i className="fas fa-times text-brown-80"></i>
      </button>
    </span>
  );
};

export const EditHeadlineButton = (props: IActionButtonProps) => {
  const { action, pathPrefix, productType, index } = props;

  return (
    <span className="w-6">
      <button
        className="delete rounded-full p-0 w-6 h-6 bg-white hover:bg-brown-40 border border-solid border-brown-80 cursor-pointer font-pt-sans"
        onClick={() => {
          action(`${pathPrefix}${productType}.items`, index);
        }}
      >
        <i className="fas fa-pen text-brown-80"></i>
      </button>
    </span>
  );
};
