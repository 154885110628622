import classNames from 'classnames';
import React from 'react';
import { formatPrice } from 'utils';

interface ISubTotalProductsRow {
  subTotalCents: number;
  bookingCurrencySymbol: string | null | undefined;
  productDisplayType: string;
  cta?: React.ReactElement;
  hasItems: boolean;
  isEditable: boolean;
}

export const ClosingProductsRow = (props: ISubTotalProductsRow) => {
  const { subTotalCents, bookingCurrencySymbol, cta, hasItems, isEditable } = props;

  return (
    (hasItems && (
      <div
        className={classNames('flex flex-row items-center justify-between', {
          'w-[calc(100%-69px)]': isEditable,
          'w-full': !isEditable,
        })}
      >
        <span>{cta}</span>
        <div className="flex items-center space-x-4">
          <span className="uppercase text-xs">Subtotal</span>
          <span className="subtotal block w-[130px] bg-teal-20 p-2 text-base text-right font-bold font-pt-sans">
            {bookingCurrencySymbol}
            {subTotalCents != undefined && formatPrice(subTotalCents / 100)}
            {subTotalCents == undefined && '0.00'}
          </span>
        </div>
      </div>
    )) || (
      <div>
        <span>
          <div>{cta}</div>
        </span>
      </div>
    )
  );
};
