import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { TravelCompanyList } from './TravelCompanyList';
import { TravelCompanyCreate } from './TravelCompanyCreate';
import { TravelCompanySettings } from 'containers/TravelCompanies/TravelCompanySettings';
import AuthRoute from 'containers/AuthRoute';
import { EUserType } from 'services/BackendApi';

export interface TravelCompaniesRoutingProps extends RouteComponentProps {}

export const TravelCompaniesRoutingComponent = (props: TravelCompaniesRoutingProps) => {
  return (
    <Switch>
      <>
        <Route
          path={`${props.match.path}/:companyUuid/settings`} 
          component={TravelCompanySettings}
        />
        <AuthRoute
          allow={[EUserType.ADMIN]}
          path={`${props.match.path}/`}
          component={TravelCompanyList}
          exact
        />
        <AuthRoute
          allow={[EUserType.ADMIN]}
          path={`${props.match.path}/create`}
          component={TravelCompanyCreate}
        />
      </>
    </Switch>
  );
};

export const TravelCompaniesRouting = compose(withRouter)(TravelCompaniesRoutingComponent);
