import React, { useCallback, useMemo } from 'react';
import { ESortOrder } from 'store/common/types';
import { CircleIconButton } from 'ui/CircleIconButton';
import { deleteIcon } from 'ui/Icons';
import { edit } from 'ui/Icons';
import { Table as BaseTable } from 'ui/Table';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';
import { setUuidToDeleteAction, setDepartmentToEditAction } from 'store/modules/companyDepartments';
import { useDispatch } from 'react-redux';
import { ICompanyDepartment } from 'services/BookingManagerApi/types/CompanyDepartmentsResponse';

export interface IDepartmentsTableProps {
  className?: string;
  departments: ICompanyDepartment[];
  loading: boolean;
}

export const DepartmentsTable: React.FC<IDepartmentsTableProps> = props => {
  const columns = useMemo(
    () => [
      {
        id: 'department-name',
        label: 'Department',
        width: '250px',
        className: 'pl-2',
      },
      {
        id: 'responsible',
        label: 'Responsible',
        width: '250px',
        className: 'pl-2',
      },
      {
        id: 'emails',
        label: 'Emails',
        width: '580px',
        className: 'pl-2',
      },
      {
        id: 'actions',
        label: '',
        width: '120px',
      },
    ],
    []
  );

  const dispatch = useDispatch();
  const handleEditClick = useCallback(
    (department: ICompanyDepartment) => {
      dispatch(setDepartmentToEditAction(department));
    },
    [dispatch]
  );
  const handleDeleteClick = useCallback(
    (uuid: string | null) => {
      dispatch(setUuidToDeleteAction(uuid));
    },
    [dispatch]
  );

  const rows = useMemo(() => {
    return props.departments?.map((x: ICompanyDepartment) => {
      const rowItemClassName = 'text-ellipsis overflow-hidden whitespace-nowrap';

      const actions = (
        <div className="item-actions flex items-center relative">
          <div className="w-4px h-34px rounded bg-brown-15 mr-10px" />
          <HidingTextTooltip width="42px" tooltipContent="Edit">
            <CircleIconButton
              type="secondary"
              className="edit-action mx-1"
              iconClass="inline relative left-0 top-1px w-12px h-12px"
              iconSrc={edit}
              onClick={() => handleEditClick(x)}
            />
          </HidingTextTooltip>
          <HidingTextTooltip width="57px" tooltipContent="Delete">
            <CircleIconButton
              type="secondary"
              className="delete-action mx-1"
              iconClass="inline relative left-0 top-1px w-12px h-12px"
              iconSrc={deleteIcon}
              onClick={() => handleDeleteClick(x.uuid)}
            />
          </HidingTextTooltip>
        </div>
      );

      return {
        id: x.uuid,
        cells: [
          {
            id: 'department-name',
            value: x.name,
            title: x.name,
            className: `${rowItemClassName} pl-4`,
          },
          {
            id: 'responsible',
            value: x.responsibleName,
            title: x.responsibleName,
            className: `${rowItemClassName} pl-4`,
          },
          {
            id: 'emails',
            value: x.emails,
            title: x.emails,
            className: `${rowItemClassName} pl-4`,
          },
          {
            id: 'actions',
            value: actions,
          },
        ],
      };
    });
  }, [handleDeleteClick, handleEditClick, props.departments]);

  return (
    <div className="mt-5">
      <BaseTable
        className={props.className}
        columns={columns}
        rows={rows}
        sortBy="name"
        sortOrder={ESortOrder.ASC}
        onSort={() => {}}
        messageWhenEmpty="No results"
        stickyHeader
        headerRowClassName="shadow-pe2 border border-solid border-gray-40"
        loading={props.loading}
      />
    </div>
  );
};
