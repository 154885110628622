import { EBankAccount, TCurrencyCode } from 'interfaces';

export interface IPaymentMethod {
  code: string;
  name: EBankAccount; // legacy naming - it should be PaymentMethodName
  currency?: TCurrencyCode | null;
  warning?: string | null;
}

export interface IPaymentMethodsGetInContextResponse {
  paymentMethods: IPaymentMethod[];
  defaultPaymentMethodCode?: string | null;
};

export enum EPaymentContextCode {
  INVOICE = 'INVOICE'
}
