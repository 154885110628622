import * as PackageActions from './actions.packages';
import { IPackageDiscountUI } from 'services/BackendApi/types/PackageOffer';
import { OfferPackageDiscountAction } from './actions.packages';
import {
  createEmptyPackageDiscount,
  createEmptyAccommodationPackage,
  createEmptyAccommodationProductPackage,
  createEmptyExtraPersonRate,
} from '../../utils';

export const packageDiscountsReducer = (
  state: IPackageDiscountUI[],
  action: OfferPackageDiscountAction
): IPackageDiscountUI[] => {
  switch (action.type) {
    case PackageActions.OFFER_ADD_PACKAGE:
      return state ? [...state, createEmptyPackageDiscount()] : [createEmptyPackageDiscount()];

    case PackageActions.OFFER_REMOVE_PACKAGE:
      return state?.filter(packageDiscount => packageDiscount.uuid !== action.packageUuid);

    case PackageActions.OFFER_SET_PACKAGE_DATE_RANGE:
      return state.map(packageDiscount => {
        return packageDiscount.uuid === action.packageUuid
          ? { ...packageDiscount, dateRange: action.dateRange }
          : packageDiscount;
      });

    case PackageActions.OFFER_ADD_PACKAGE_ACCOMMODATION_PRODUCT:
      return state.map(packageDiscount => {
        return packageDiscount.uuid === action.packageUuid
          ? {
              ...packageDiscount,
              accommodationPackages: [...packageDiscount.accommodationPackages, createEmptyAccommodationPackage()],
            }
          : packageDiscount;
      });

    case PackageActions.OFFER_SET_PACKAGE_ACCOMMODATION_PRODUCT:
      return state.map(packageDiscount => {
        if (packageDiscount.uuid !== action.packageUuid) {
          return packageDiscount;
        }

        const newAccomodationPackages = packageDiscount.accommodationPackages.map((ap, idx) => {
          return idx === action.accommodationPackageIndex
            ? { ...ap, accommodationProductUuid: action.accommodationProductUuid }
            : ap;
        });

        return {
          ...packageDiscount,
          accommodationPackages: newAccomodationPackages,
        };
      });

    case PackageActions.OFFER_REMOVE_ACCOMMODATION_PRODUCT:
      return state.map(packageDiscount => {
        if (packageDiscount.uuid !== action.packageUuid) {
          return packageDiscount;
        }

        return {
          ...packageDiscount,
          accommodationPackages: packageDiscount.accommodationPackages.filter(
            (_, idx) => idx !== action.accommodationPackageIndex
          ),
        };
      });

    case PackageActions.OFFER_ADD_ACCOMMODATON_PRODUCT_PACKAGE:
      return state.map(packageDiscount => {
        if (packageDiscount.uuid !== action.packageUuid) {
          return packageDiscount;
        }
        return {
          ...packageDiscount,
          accommodationPackages: packageDiscount.accommodationPackages.map((ap, idx) => {
            if (idx !== action.accommodationPackageIndex) {
              return ap;
            }
            return {
              ...ap,
              accommodationProductPackages: [
                ...ap.accommodationProductPackages!,
                createEmptyAccommodationProductPackage(),
              ],
            };
          }),
        };
      });

    case PackageActions.OFFER_REMOVE_ACCOMMODATON_PRODUCT_PACKAGE:
      return state.map(packageDiscount => {
        if (packageDiscount.uuid !== action.packageUuid) {
          return packageDiscount;
        }
        return {
          ...packageDiscount,
          accommodationPackages: packageDiscount.accommodationPackages.map((ap, idx) => {
            if (idx !== action.accommodationPackageIndex) {
              return ap;
            }
            return {
              ...ap,
              accommodationProductPackages: ap.accommodationProductPackages?.filter(
                (_, appIdx) => appIdx !== action.accommodationProductPackageIndex
              ),
            };
          }),
        };
      });

    case PackageActions.OFFER_SET_PACKAGE_NIGHTS:
      return state.map(packageDiscount => {
        if (packageDiscount.uuid !== action.packageUuid) {
          return packageDiscount;
        }
        return {
          ...packageDiscount,
          accommodationPackages: packageDiscount.accommodationPackages.map((ap, idx) => {
            if (idx !== action.accommodationPackageIndex) {
              return ap;
            }
            return {
              ...ap,
              accommodationProductPackages: ap.accommodationProductPackages?.map((app, appIdx) => {
                if (appIdx !== action.accommodationProductPackageIndex) {
                  return app;
                }
                return {
                  ...app,
                  nights: action.nights,
                };
              }),
            };
          }),
        };
      });

    case PackageActions.OFFER_SET_PACKAGE_RATE:
      return state.map(packageDiscount => {
        if (packageDiscount.uuid !== action.packageUuid) {
          return packageDiscount;
        }
        return {
          ...packageDiscount,
          accommodationPackages: packageDiscount.accommodationPackages.map((ap, idx) => {
            if (idx !== action.accommodationPackageIndex) {
              return ap;
            }
            return {
              ...ap,
              accommodationProductPackages: ap.accommodationProductPackages?.map((app, appIdx) => {
                if (appIdx !== action.accommodationProductPackageIndex) {
                  return app;
                }
                return {
                  ...app,
                  packageRate: action.rate,
                };
              }),
            };
          }),
        };
      });

    case PackageActions.OFFER_SET_PACKAGE_EXTRA_NIGHT_RATE:
      return state.map(packageDiscount => {
        if (packageDiscount.uuid !== action.packageUuid) {
          return packageDiscount;
        }
        return {
          ...packageDiscount,
          accommodationPackages: packageDiscount.accommodationPackages.map((ap, idx) => {
            if (idx !== action.accommodationPackageIndex) {
              return ap;
            }
            return {
              ...ap,
              extraNightRate: action.rate,
            };
          }),
        };
      });

    case PackageActions.OFFER_ADD_PACKAGE_EXTRA_PERSON_RATE:
      return state.map(packageDiscount => {
        if (packageDiscount.uuid !== action.packageUuid) {
          return packageDiscount;
        }
        return {
          ...packageDiscount,
          accommodationPackages: packageDiscount.accommodationPackages.map((ap, idx) => {
            if (idx !== action.accommodationPackageIndex) {
              return ap;
            }
            return {
              ...ap,
              extraPersonNightRates: [...ap.extraPersonNightRates!, createEmptyExtraPersonRate()],
            };
          }),
        };
      });
    case PackageActions.OFFER_REMOVE_PACKAGE_EXTRA_PERSON_RATE:
      return state.map(packageDiscount => {
        if (packageDiscount.uuid !== action.packageUuid) {
          return packageDiscount;
        }
        return {
          ...packageDiscount,
          accommodationPackages: packageDiscount.accommodationPackages.map((ap, idx) => {
            if (idx !== action.accommodationPackageIndex) {
              return ap;
            }
            return {
              ...ap,
              extraPersonNightRates: ap.extraPersonNightRates?.filter((_, idx) => idx !== action.extraPersonRateIndex),
            };
          }),
        };
      });

    case PackageActions.OFFER_SET_PACKAGE_EXTRA_PERSON_RATE:
      return state.map(packageDiscount => {
        if (packageDiscount.uuid !== action.packageUuid) {
          return packageDiscount;
        }
        return {
          ...packageDiscount,
          accommodationPackages: packageDiscount.accommodationPackages.map((ap, idx) => {
            if (idx !== action.accommodationPackageIndex) {
              return ap;
            }
            return {
              ...ap,
              extraPersonNightRates: ap.extraPersonNightRates?.map((epnr, idx) => {
                if (idx !== action.extraPersonRateIndex) {
                  return epnr;
                }
                return {
                  ...epnr,
                  extraPersonRate: action.rate,
                };
              }),
            };
          }),
        };
      });

    case PackageActions.OFFER_SET_PACKAGE_EXTRA_PERSON_RATE_AGENAME:
      return state.map(packageDiscount => {
        if (packageDiscount.uuid !== action.packageUuid) {
          return packageDiscount;
        }
        return {
          ...packageDiscount,
          accommodationPackages: packageDiscount.accommodationPackages.map((ap, idx) => {
            if (idx !== action.accommodationPackageIndex) {
              return ap;
            }
            return {
              ...ap,
              extraPersonNightRates: ap.extraPersonNightRates?.map((epnr, idx) => {
                if (idx !== action.extraPersonRateIndex) {
                  return epnr;
                }
                return {
                  ...epnr,
                  ageName: action.ageName,
                  maximumBeforeDefaultRate: action.ageName === 'Adult' ? '' : epnr.maximumBeforeDefaultRate,
                };
              }),
            };
          }),
        };
      });

    case PackageActions.OFFER_SET_PACKAGE_EXTRA_PERSON_MAX:
      return state.map(packageDiscount => {
        if (packageDiscount.uuid !== action.packageUuid) {
          return packageDiscount;
        }
        return {
          ...packageDiscount,
          accommodationPackages: packageDiscount.accommodationPackages.map((ap, idx) => {
            if (idx !== action.accommodationPackageIndex) {
              return ap;
            }
            return {
              ...ap,
              extraPersonNightRates: ap.extraPersonNightRates?.map((epnr, idx) => {
                if (idx !== action.extraPersonRateIndex) {
                  return epnr;
                }
                return {
                  ...epnr,
                  maximumBeforeDefaultRate: action.max,
                };
              }),
            };
          }),
        };
      });

    default:
      return state;
  }
};
