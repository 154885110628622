export const SET_QUOTE_DOWNLOADING = 'bookingBuilder/quote/SET_QUOTE_DOWNLOADING';

export type SetQuoteDownloadingAction = ReturnType<typeof setQuoteDownloadingAction>;
export const setQuoteDownloadingAction = (value: boolean) => ({
  type: SET_QUOTE_DOWNLOADING as typeof SET_QUOTE_DOWNLOADING,
  value
});

export type QuoteAction =
  | SetQuoteDownloadingAction;
