import { IUser } from 'services/BackendApi';
import { ESortOrder, IPagination } from 'store/common/types';

export interface ITaskCategory {
  id: string;
  name: string;
}

export type ITaskUser = Pick<IUser, 'uuid' | 'firstName' | 'lastName'>;

export interface ITaskTeam {
  id: string;
  name: string;
}

export interface ITaskPerson {
  id: string;
  type: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface ITaskAPI {
  id: string;
  key: string;
  subject: string;
  description: string;
  status: ETaskStatus;
  priority: ETaskPriority;
  startBy: Date;
  deadline: Date;
  category: {
    id: string;
    name: string;
  };
  team: {
    id: string;
    name: string;
  };
  assignee: ITaskPerson;
  bookingRef: string;
  booking: {
    id: string;
    humanReadableId: string;
    leadGuestFirstName: string;
    leadGuestLastName: string;
  } | null,
  createdBy: ITaskPerson;
  createdAt: string;
  updatedBy: ITaskPerson | null;
  updatedAt: string;
  reminders: ITaskReminder[]
}

export interface ISearchTasksResponse {
  tasks: ITaskAPI[];
  pagination: IPagination;
  count: number;
}

export interface ITask {
  id: string;
  key: string;
  subject: string;
  description: string;
  status: ETaskStatus;
  priority: ETaskPriority;
  startBy: Date;
  deadline: Date;
  category: {
    id: string;
    name: string;
  };
  team: {
    id: string;
    name: string;
  };
  assignee: ITaskPerson;
  bookingRef?: string;
  booking?: {
    id: string;
    humanReadableId: string;
    leadGuestFirstName: string;
    leadGuestLastName: string;
  } | null,
  reporter: ITaskPerson;
  createdAt: string;
  updatedBy: ITaskPerson | null;
  updatedAt: string | null;
  reminders: ITaskReminder[]
}

export enum ETasksSortableKey {
  KEY = 'key',
  SUBJECT = 'subject',
  STATUS = 'status',
  PRIORITY = 'priority',
  DEADLINE = 'deadline',
  CATEGORY = 'category',
  TEAM = 'team',
  ASSIGNEE = 'assignee',
  BOOKING_HUMAN_READABLE_ID = 'bookingHumanReadableId',
  REPORTER = 'createdBy',
}

export enum ETaskStatus {
  BACKLOG = 'backlog',
  TO_DO = 'to-do',
  IN_PROGRESS = 'in-progress',
  BLOCKED = 'blocked',
  DONE = 'done',
}

export enum ETaskPriority {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export interface ITasksListSort {
  key: ETasksSortableKey;
  order: ESortOrder;
}

export interface ITaskSearchFilter {
  key: string;
  statuses: ETaskStatus[];
  priority?: ETaskPriority;
  deadlineFrom: string;
  deadlineTo: string;
  categoryIds: string[];
  teamId?: string;
  bookingId?: string;
  assigneeId?: string;
  bookingHumanReadableId: string;
  bookingLeadGuestName: string;
  createdById?: string;
}

export type TReminderChannel = 'email';
export type TReminderOffsetUnit = 'minute' | 'hour' | 'day' | 'week';

export interface ITaskReminder {
  id: string;
  taskId?: string;
  channel: TReminderChannel;
  offset: number;
  offsetUnit: TReminderOffsetUnit;
}
export interface ICreateTaskRequest {
  subject: string;
  description: string | null;
  status: ETaskStatus;
  priority: ETaskPriority;
  /** Format: date-time */
  startBy: string | null;
  /** Format: date-time */
  deadline: string;
  /** Format: uuid */
  categoryId: string;
  /** Format: uuid */
  teamId: string;
  /** Format: uuid */
  assigneeId: string | null;
  bookingHumanReadableId: string | null;
  reminders: ITaskReminder[];
};

export interface ITaskAttachment {
  id: string;
  name: string;
  url: string;
  createdBy: ITaskPerson;
  createdAt: string;
}

export interface ITaskComment {
  id: string;
  content: string;
  createdBy: ITaskPerson;
  createdAt: string;
}

export interface ICreateTaskCommentResponse {
  comment: ITaskComment;
  warnings: string[];
}