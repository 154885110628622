import { createSelector } from 'reselect';
import { IStaticRatesAvailabilityDomain } from './types';

export const staticRatesAvailabilityDomainSelector = (state: any): IStaticRatesAvailabilityDomain =>
  state.staticRatesAvailability;

export const aggregateSelector = createSelector(
  staticRatesAvailabilityDomainSelector,
  domain => domain.aggregate
);

export const singleSelector = createSelector(
  staticRatesAvailabilityDomainSelector,
  domain => domain.single
);

export const networkRequestsSelector = createSelector(
  staticRatesAvailabilityDomainSelector,
  domain => domain.networkRequests
);

export const errorSelector = createSelector(staticRatesAvailabilityDomainSelector, domain => domain.error);
