import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Radio } from 'ui/Radio';
import { ICompany } from 'services/BackendApi/types';
import { ICountry } from 'ui/CountrySelect/types';

interface ITravelCompaniesListRowProps {
  company: ICompany;
  country: ICountry;
  enabled: boolean;
  onChange: (changedCompany: ICompany) => void;
}

export const TravelCompaniesListRow: React.FC<ITravelCompaniesListRowProps> = React.memo(
  ({ company, country, enabled, onChange }) => {
    const handleCompanySelect = useCallback(() => {
      onChange(company);
    }, [company, onChange]);

    return (
      <div
        className={classnames('travel-company-list-row text-black text-15px h-30px flex items-center mt-5px mb-5px', {
          'bg-green-25': enabled,
        })}
      >
        <label
          className="travel-company-list-row-checkbox-label flex flex-row items-center pl-3px cursor-pointer w-200px"
          onClick={handleCompanySelect}
        >
          <Radio onClick={handleCompanySelect} checked={enabled} />
          <span className="ml-2 overflow-hidden whitespace-nowrap text-ellipsis" title={company.name}>
            {company.name}
          </span>
        </label>
        <span className="travel-company-country ml-5">{country?.name ?? ''}</span>
      </div>
    );
  }
);
