import { EBankAccount } from 'interfaces';

export enum EInvoiceType {
  PROFORMA = 'proforma',
  FINAL = 'final',
}

export interface IGetFinanceDocumentResponse {
  bookingUuid: string;
  version: number;
  createdAt: string;
  rows: IFinanceRow[];
  updatedAt: string;
}

export interface IPostFinanceDocumentResponse {
  bookingUuid: string;
  version: number;
  createdAt: string;
  rows: IFinanceRow[];
  updatedAt: string;
}

export interface IInvoiceDueDate {
  date: string | null;
  percentage: number | null;
  amountCents: number | null;
  isConfirmed: boolean;
}

export interface IManualInvoiceDueDate {
  date: string;
  amountCents?: number;
}

export enum EFinanceRowTypes {
  // Sales Debit Manual
  Manual_Invoice_to_Travel_Agent = 'Manual Invoice to Travel Agent',
  Manual_Invoice_to_Final_Client = 'Manual Invoice to Final Client',
  Payment_to_Travel_Agent = 'Payment to Travel Agent',
  Payment_to_Final_Client = 'Payment to Final Client',
  Deposit_Transfer_In = 'Deposit Transfer In',
  Manual_Cancellation_Fee = 'Manual Cancellation Fee',

  // sales debit automatic
  Automatic_Invoice_to_Travel_Agent = 'Automatic Invoice to Travel Agent',
  Automatic_Invoice_to_Final_Client = 'Automatic Invoice to Final Client',
  Automatic_Proforma_to_Travel_Agent = 'Automatic Proforma to Travel Agent',
  Automatic_Proforma_to_Final_Client = 'Automatic Proforma to Final Client',

  // sales credit manual
  Manual_Margin_to_Travel_Agent = 'Manual Margin to Travel Agent',
  Payment_from_Travel_Agent = 'Payment from Travel Agent',
  Payment_from_Final_Client = 'Payment from Final Client',
  Payment_from_Final_Client_to_hotel = 'Payment from Final Client to Hotel',
  Deposit_Transfer_Out = 'Deposit Transfer Out',
  Manual_Cancellation_of_Invoice = 'Manual Cancellation of Invoice',
  Manual_Credit_Note = 'Manual Credit Note',

  // purchase debit manual
  Payment_to_Hotel = 'Payment to Hotel',
  Payment_to_Hotel_from_Final_Client = 'Payment to Hotel from Final Client',
  Payment_to_Hotel_from_Travel_Agent = 'Payment to Hotel from Travel Agent',

  // purchase credit manual
  Manual_Hotel_Invoice = 'Manual Hotel Invoice',
  Manual_Invoice_from_Supplier = 'Manual Invoice from Supplier',

  // purchase credit automatic
  Purchase_Cost_Review_Hotel_Invoice = 'Purchase Cost Review Hotel Invoice',

  // manual adjustments used in sales and purchase
  Sales_Positive_Adjustment = 'Positive Adjustment',
  Sales_Negative_Adjustment = 'Negative Adjustment',
  Purchase_Positive_Adjustment = 'Positive Adjustment',
  Purchase_Negative_Adjustment = 'Negative Adjustment',
  Payment_Other = 'Payment - Other',

  // OWA-3126
  Automatic_Cancellation_of_Invoice = 'Automatic Cancellation of Invoice',
  Automatic_Cancellation_Fee = 'Automatic Cancellation Fee',
  Automatic_Discard_of_Invoice = 'Automatic Discard of Invoice',

  // Balance Carried Forward rows
  Initial_Balance_Carried_Forward_Positive = 'Initial Balance Carried Forward Positive',
  Initial_Balance_Carried_Forward_Negative = 'Initial Balance Carried Forward Negative',
  Adjustment_Balance_Carried_Forward_Positive = 'Adjustment to Balance Carried Forward Positive',
  Adjustment_Balance_Carried_Forward_Negative = 'Adjustment to Balance Carried Forward Negative',

  // OWA-3709/3720
  Automatic_Cancellation_Fee_Live = 'Automatic Cancellation Fee (Live)',

  // OWA-3835
  Bank_Charges_Positive = 'Bank Charges Positive',
  Bank_Charges_Negative = 'Bank Charges Negative',
  Payment_To_Non_Hotel_Supplier = 'Payment To Non Hotel Supplier',

  Automatic_Deposit_Transfer_Out = 'Automatic Deposit Transfer Out',

  // OWA-4443
  Credit_Note_For_Guest_In_Negative = 'Credit Note For Guest In',
  Credit_Note_For_Guest_Out_Positive = 'Credit Note For Guest Out',
  Credit_Note_For_Travel_Partner_In_Negative = 'Credit Note For Travel Partner In',
  Credit_Note_For_Travel_Partner_Out_Positive = 'Credit Note For Travel Partner Out',

  // OWA-4444
  Credit_Note_From_Hotel_Negative = 'Credit Note From Hotel',
  Credit_Note_From_Non_Hotel_Supplier_Negative = 'Credit Note From Non Hotel Supplier',
  Credit_Note_From_Hotel_Used_Positive = 'Credit Note From Hotel Used',
  Credit_Note_From_Non_Hotel_Supplier_Used_Positive = 'Credit Note From Non Hotel Supplier Used',

  Breakdown_Finance_Adjustment_Positive = 'Breakdown Finance Adjustment Positive',
  Breakdown_Finance_Adjustment_Negative = 'Breakdown Finance Adjustment Negative',
}

export type EManualBookingLedgerRowType = Extract<
  EFinanceRowTypes,
  | EFinanceRowTypes.Initial_Balance_Carried_Forward_Positive
  | EFinanceRowTypes.Initial_Balance_Carried_Forward_Negative
  | EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Positive
  | EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Negative
>;

export interface IFinanceRow {
  uuid?: string;
  entryDate?: string;
  date: string;
  // referenceNumber: string | null;
  description: string | null;
  amountCents: number | null;
  uploadUuid: string | null;
  uploadUrl: string | null;
  uploadName: string | null;
  // transactionType: 'Credit' | 'Debit';
  // isAutomatedInvoice: boolean;
  bankAccount?: EBankAccount | null;
  invoiceDueDates?: IInvoiceDueDate[];
  rowType: EFinanceRowTypes;
  creditNoteExpiryDate: string | null;
  isCreditNoteSpecificToHotel: boolean | null;
  isCreditNoteSpecificToGuest: boolean | null;
}

export interface IGetFinanceDocumentBalanceResponse {
  bookingUuid: string;
  version: number;
  createdAt: string;
  updatedAt: string;
  totalCreditCents: number;
  totalDebitCents: number;
  totalOutstandingCents: number;
}
