import styled from 'styled-components';
import { EBreakpoints, breakpointValue } from 'pureUi/breakpoints';

export const BookingManagerDownloadsStyles = styled.div`
  height: 100%;

  .downloads {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3fr 1fr;
    height: 100%;

    > .list-container {
      overflow: scroll;
    }

    > .preview-container > .preview {
      width: 100%;
      height: 100%;
    }

    @media screen and (min-width: ${breakpointValue(EBreakpoints.TABLET_UP)}px) {
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 10px;
      grid-template-rows: auto;

      > .list-container {
        order: 0;
        grid-column: span 4;
      }

      > .preview-container {
        order: 1;
        grid-column: span 8;
      }
    }
  }

  .info, .error {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .error {
    button {
      flex-grow: 0;
    }
  }
`;
