export default {
  'Young Child_plural': 'Young Children',
  'Young Child': 'Young Child',
  addon_plural: 'Addons',
  addon: 'Addon',
  adult_plural: 'Adults',
  adult: 'Adult',
  booking_plural: 'Bookings',
  enquiryBooking_plural: 'Enquiries and Bookings',
  booking: 'Booking',
  brochure_plural: 'Brochures',
  brochure: 'Brochure',
  Child_plural: 'Children',
  Child: 'Child',
  children_plural: 'Children',
  children: 'Child',
  comment_plural: 'Comments',
  comment: 'Comment',
  date_plural: 'Dates',
  date: 'Date',
  feature_plural: 'Features',
  feature: 'Feature',
  flight_plural: 'Flights',
  flight: 'Flight',
  groundService_plural: 'Ground Services',
  groundService: 'Ground Service',
  guest_plural: 'Guests',
  guest: 'Guest',
  hotel_plural: 'Hotels',
  hotel: 'Hotel',
  Infant_plural: 'Infants',
  Infant: 'Infant',
  mealPlan_plural: 'Meal Plans',
  mealPlan: 'Meal Plan',
  night_plural: 'Nights',
  night: 'Night',
  occasion_plural: 'Occasions',
  occasion: 'Occasion',
  offer_plural: 'Offers',
  offer: 'Offer',
  offerWithCount_plural: '{{count}} Offers',
  offerWithCount: '{{count}} Offer',
  priceRange_plural: 'Price Ranges',
  priceRange: 'Price Range',
  region_plural: 'Regions',
  region: 'Region',
  result_plural: 'properties found',
  result: 'property found',
  room_plural: 'Rooms',
  room: 'Room',
  roomType_plural: 'Room Types',
  roomType: 'Room Type',
  roomWithCount_plural: '{{count}} Rooms',
  roomWithCount: '{{count}} Room',
  slee_plural: 'Sleeps',
  sleep: 'Sleep',
  star_plural: 'Stars',
  star: 'Star',
  starRating_plural: 'Acommodations',
  starRating: 'Accommodation',
  teen_plural: 'Teens',
  teen: 'Teen',
  transfer_plural: 'Transfers',
  transfer: 'Transfer',
  travelAgent_plural: 'Travel agents',
  travelAgent: 'Travel agent',
};
