import React from 'react';
import classNames from 'classnames';
import { interpose } from 'utils/array';

export interface IManagedByProps {
  className?: string;
  items: string[];
}

export const ManagedBy: React.FC<IManagedByProps> = ({ className, items }) => {
  return (
    <div className={classNames(className, 'managed-by bg-gray-10 p-9px min-h-39px')}>
      {interpose(
        items.map(x => <span className="inline-block">{x}</span>),
        <span className="text-brown-100"> | </span>
      )}
    </div>
  )
};
