import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import * as BreakdownSelectors from 'store/modules/bookingManager/selectors';
import { DecimalInput } from 'ui/stateful/DecimalInput';
import classNames from 'classnames';

export interface IBarCommissionProps {
  className?: string;
}

export const BarCommission: React.FC<IBarCommissionProps> = ({ className = '' }) => {
  const dispatch = useDispatch();
  const barCommissionPercentage = useSelector(BreakdownSelectors.barCommissionPercentageSelector);
  const barCommissionAmount = useSelector(BreakdownSelectors.barCommissionAmountSelector);
  const barCommissionErrors = useSelector(BreakdownSelectors.barCommissionErrorsSelector);

  const barCommissionPercentageError = barCommissionErrors.find(error => error.field === 'barCommissionPercentage');
  const barCommissionAmountError = barCommissionErrors.find(error => error.field === 'barCommissionAmount');

  return (
    <div className="bar-commission flex flex-col w-full mt-10px pt-10px mb-30px">
      <span className="block text-black text-16px font-bold font-pt-sans pb-5px">Commission After Checkout</span>
      <div className="flex">
        <div className="flex flex-col">
          <span className="pb-5px text-13px text-black">Percentage</span>
          <DecimalInput
            decimalPlaces={2}
            max={100}
            min={0}
            allowNull={true}
            onBlur={val => {
              dispatch(BreakdownActions.setBookingBarCommissionPercentageAction(val));
            }}
            value={barCommissionPercentage === null ? '' : barCommissionPercentage}
            className={classNames(
              'bar-commission-percentage max-w-70px focus:outline-gray-80 p-2 border border-solid h-35px w-full font-pt-sans text-base text-black mr-10px',
              {
                'bg-ivory border-gray-40': !barCommissionPercentageError,
                'bg-red-25 border-red-95': barCommissionPercentageError,
              }
            )}
          />
          {barCommissionPercentageError && (
            <span className="text-red-95 text-xs">{barCommissionPercentageError.message}</span>
          )}
        </div>
        <div className="flex flex-col">
          <span className="pb-5px text-13px text-black">Commission Amount</span>
          <DecimalInput
            decimalPlaces={5}
            allowNull={true}
            onBlur={val => {
              dispatch(BreakdownActions.setBookingBarCommissionAmountAction(val));
            }}
            value={barCommissionAmount === null ? '' : barCommissionAmount}
            className={classNames(
              'bar-commission-amount max-w-150px focus:outline-gray-80 p-2 border border-solid h-35px w-full font-pt-sans text-base text-black mr-10px',
              {
                'bg-ivory border-gray-40': !barCommissionAmountError,
                'bg-red-25 border-red-95': barCommissionAmountError,
              }
            )}
          />
          {barCommissionAmountError && <span className="text-red-95 text-xs">{barCommissionAmountError.message}</span>}
        </div>
      </div>
    </div>
  );
};

export default BarCommission;
