import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import { TextInput } from 'ui/TextInput';
import { GeneralModal } from 'ui/GeneralModal';
import { useCurrentWidth } from 'effects';
import { Field, Formik, FormikErrors, FormikProps } from 'formik';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { IReduxDomainStatus } from '../../interfaces';
import { CloseXComponent as CloseXIcon } from 'ui/Icons';
import { LoadingBar } from 'ui/NetworkStatusBar';

export interface iProposalModalProps {
  isLoading: boolean;
  proposals: any;
  hotelUuid: string;
  proposalStatus: IReduxDomainStatus;
  createNewProposal: Function;
  addToProposal: Function;
  onClose?: () => void;
  onSuccess?: () => void;
  onError?: Function;
}

export interface iProposalFormValues {
  proposalUuid: string | null;
  proposalName?: string;
}

const validateForm = (values: iProposalFormValues) => {
  const errors: FormikErrors<iProposalFormValues> = {};
  if (values.proposalUuid === PROPOSAL_NEW && !values.proposalName?.trim().length) {
    errors.proposalName = 'Proposal name should not be empty';
  }

  return errors;
};

const PROPOSAL_NEW = 'new';

export const ProposalModal = (props: iProposalModalProps) => {
  const {
    proposals,
    hotelUuid,
    isLoading,
    proposalStatus,
    createNewProposal,
    addToProposal,
    onClose,
    onSuccess,
    onError,
  } = props;

  const selectOptions: ISingleSelectOption[] = [];
  selectOptions.push({
    value: PROPOSAL_NEW,
    label: 'New proposal',
  });
  Object.keys(proposals).map(pKey =>
    selectOptions.push({
      value: pKey,
      label: proposals[pKey],
    })
  );

  const loading = proposalStatus === 'LOADING' || isLoading;
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { isMobile, isMobileLandscape, isTablet, isDesktop, isDesktopHD } = useCurrentWidth();
  const modalWidthClassname = classnames('flex justify-center', {
    'max-w-330px': isMobile || isMobileLandscape,
    'max-w-480px': isTablet || isDesktop || isDesktopHD,
    'min-h-270px': !loading,
  });
  const proposalModalContentClassname = classnames('proposal-modal-content flex flex-col flex-1');

  const initialValues: iProposalFormValues = {
    proposalUuid: PROPOSAL_NEW,
    proposalName: '',
  };

  const handleFormSubmit = useCallback((proposalFormValues: iProposalFormValues) => {
    const { proposalUuid, proposalName } = proposalFormValues;
    setIsSubmitted(true);
    if (proposalUuid === PROPOSAL_NEW) {
      const isProposalNameValid = !!proposalName?.trim();
      if (!isProposalNameValid) {
        return;
      }

      createNewProposal(proposalName, hotelUuid, false);
    } else {
      addToProposal(proposalUuid, hotelUuid, false);
    }
  }, []);

  useEffect(() => {
    if (!isSubmitted) {
      return;
    }

    if (proposalStatus === 'SUCCESS' && onSuccess) {
      onSuccess();
    }

    if (proposalStatus === 'ERROR' && onError) {
      onError();
    }
  }, [isSubmitted, onError, onSuccess, proposalStatus]);

  const fieldContainerClassname = classnames('grid grid-cols-1 gap-x-5');

  const buttonClassName = classnames(
    'font-pt-sans bg-brown-prime w-full h-10 border-none px-33px text-white-true tracking-wider',
    'hover:bg-brown-hard active:bg-brown-hard cursor-pointer max-w-220px',
    { 'mt-20px': isMobile, 'mt-25px': !isMobile }
  );

  const renderContent = (isVisible: boolean) => {
    return (
      <div className={classnames({ hidden: !isVisible, 'max-w-250px': isMobile || isMobileLandscape })}>
        <Formik
          initialValues={initialValues}
          validate={validateForm}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
        >
          {(form: FormikProps<iProposalFormValues>) => {
            return (
              <form onSubmit={form.handleSubmit}>
                <div className={fieldContainerClassname}>
                  {selectOptions.length > 1 && (
                    <Field name="proposalUuid">
                      {({ field: { name, value }, form: { setFieldValue } }) => (
                        <SingleSelect
                          fieldId="proposalUuid"
                          label="PROPOSAL"
                          className={classnames('title', { 'mt-20px': isMobile, 'mt-25px': !isMobile })}
                          value={value ?? selectOptions[0].value}
                          options={selectOptions}
                          onChange={value => {
                            setFieldValue(name, value);
                          }}
                          maxVisibleItems={3}
                        />
                      )}
                    </Field>
                  )}

                  {form.values.proposalUuid === PROPOSAL_NEW && (
                    <Field
                      as={TextInput}
                      type="text"
                      name="proposalName"
                      id="proposalName"
                      className={classnames('proposalName', { 'mt-20px': isMobile, 'mt-25px': !isMobile })}
                      label="NEW PROPOSAL NAME"
                      errorMessage={
                        form.touched.proposalName && form.errors.proposalName ? form.errors.proposalName : null
                      }
                      errorClassName="proposalName-error"
                    />
                  )}

                  <div className="flex flex-col items-center">
                    <button id="add-to-proposal-confirm-button" className={buttonClassName} disabled={loading}>
                      ADD TO PROPOSAL
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  };

  return (
    <GeneralModal
      modalWindowClassName={modalWidthClassname}
      shouldCloseByClickingOutside
      isCloseButtonVisible={false}
    >
      <div className={classnames('proposal-modal flex justify-center p-10 w-screen', modalWidthClassname)}>
        <div className={proposalModalContentClassname}>
          <div className="proposal-modal-title flex items-center justify-between">
            <p className="proposal-modal-title text-black text-21px leading-27px font-noe-display mt-5px my-0">
              Add to Proposal
            </p>
            <div
              className="modal-close-button bg-ivory w-14px h-14px flex justify-center items-center cursor-pointer"
              onClick={onClose}
            >
              <CloseXIcon />
            </div>
          </div>

          {loading && (
            <span className="modal-loading mt-5 flex justify-center">
              <LoadingBar />
            </span>
          )}

          {renderContent(!loading)}
        </div>
      </div>
    </GeneralModal>
  );
};
