import * as Yup from 'yup';

export const TravelCompanyFormValidationSchema = Yup.object().shape({
  companyId: Yup.string().required('Field is required'),
  companyName: Yup.string().required('Field is required'),
  countryCode: Yup.string().required('Field is required').nullable(),
  address: Yup.string().required('Field is required'),
  phone: Yup.string(),
  website: Yup.string(),
  marketingSource: Yup.string().nullable(),
  salesRepresentative: Yup.string().nullable(),
  companyType: Yup.string().nullable(),
  comment: Yup.string(),
  logoPosition: Yup.string().nullable()
});
