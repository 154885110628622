import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Upload } from 'services/BackendApi/types';
import { pureUiTheme } from 'pureUi/pureUiTheme';

export interface FileNavProps extends HTMLAttributes<HTMLElement> {
  files: Pick<Upload, 'uuid' | 'displayName'>[];
  basePath: string;
}

const FileNav = (props: FileNavProps) => {
  const { className, files, basePath } = props;

  return (
    <nav className={className}>
      {files.map((f, fIndex) => (
        <NavLink
          className={`file-nav-link index-${fIndex}`}
          key={f.uuid}
          activeClassName="selected"
          to={`${basePath}/${f.uuid}`}
        >
          {f.displayName}
        </NavLink>
      ))}
    </nav>
  );
};

export default styled(FileNav)`
  border: ${pureUiTheme.colorRoles.lightGreyBorder} 1px solid;
  cursor: pointer;

  > a {
    display: block;
    padding: 15px
    color: ${pureUiTheme.colorRoles.grayLabel};
    font-size: 12px;
    
    border-bottom: ${pureUiTheme.colorRoles.lightGreyBorder} 1px solid;
    &:last-of-type {
      border-bottom: 0;
    }

    &.selected {
      font-weight: bold;
      background-color: ${pureUiTheme.colors.grayLight};
    }
  }

`;
