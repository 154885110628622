import React, { useCallback, useEffect, useMemo, CSSProperties, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import FluidButton from 'ui/FluidButton';
import TextInput from 'pureUi/TextInput';
import { Select } from 'ui/Select';

import * as Actions from 'store/modules/companyInvoiceAddressee/actions';
import * as Selectors from 'store/modules/companyInvoiceAddressee/selectors';
import { getBootstrapCountriesSelector } from 'store/modules/bootstrap/selectors';

interface InvoiceAddresseeProps {
  companyUuid: string;
  isEditMode: boolean;
}

const selectStyles: CSSProperties = {
  textTransform: 'none',
  width: '100%',
  backgroundColor: '#FBFBFA',
  borderColor: '#E0E0E0',
  fontSize: '14px',
  color: '#373C46',
  maxHeight: '39px',
  height: '39px',
};

export const InvoiceAddressee: React.FC<InvoiceAddresseeProps> = ({ companyUuid, isEditMode }) => {
  const editable = useSelector(Selectors.editableSelector);
  const reqs = useSelector(Selectors.requestSelector);

  const countries = useSelector(getBootstrapCountriesSelector);
  const countryOptions = useMemo(
    () => [{ value: null, label: 'Select...' }, ...countries.map(c => ({ value: c.code, label: c.name }))],
    [countries]
  );

  const dispatch = useDispatch();

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  useEffect(() => {
    dispatch(Actions.getRequestAction(companyUuid));
  }, [dispatch, companyUuid]);

  const handleSave = useCallback(() => {
    dispatch(Actions.updateRequestAction(companyUuid));
    setHasChanges(false);
  }, [dispatch, companyUuid]);

  const handleNameChange = useCallback(
    e => {
      dispatch(Actions.setNameAction(e.target.value || null));
      setHasChanges(true);
    },
    [dispatch, setHasChanges]
  );

  const handleCompanyChange = useCallback(
    e => {
      dispatch(Actions.setCompanyNameAction(e.target.value || null));
      setHasChanges(true);
    },
    [dispatch, setHasChanges]
  );

  const handleAddressChange = useCallback(
    e => {
      dispatch(Actions.setAddressAction(e.target.value || null));
      setHasChanges(true);
    },
    [dispatch, setHasChanges]
  );

  const handleCountryCodeChange = useCallback(
    e => {
      dispatch(Actions.setCountryCodeAction(e.target.value));
      setHasChanges(true);
    },
    [dispatch, setHasChanges]
  );

  const handleEmailChange = useCallback(
    e => {
      dispatch(Actions.setEmailAction(e.target.value || null));
      setHasChanges(true);
    },
    [dispatch, setHasChanges]
  );

  const handleVatChange = useCallback(
    e => {
      dispatch(Actions.setVatAction(e.target.value || null));
      setHasChanges(true);
    },
    [dispatch, setHasChanges]
  );

  const handleIataChange = useCallback(
    e => {
      dispatch(Actions.setIataAction(e.target.value || null));
      setHasChanges(true);
    },
    [dispatch, setHasChanges]
  );

  return (
    <div className="invoice-addressee w-full font-pt-sans">
      <p className="title text-black-true font-bold text-17px leading-md mb-0">Invoice Addressee</p>
      <p className="description text-black text-15px leading-sm mt-5px mb-0">
        Invoice Addressee default configuration for this company.
      </p>
      <p className="description text-black text-15px leading-sm mt-0 mb-20px">
        Remember, this information can be changed at a booking level if needed.
      </p>
      {reqs.get === ENetworkRequestStatus.PENDING && <LoadingBar />}
      {reqs.get === ENetworkRequestStatus.ERROR && <ErrorBar />}
      {reqs.get === ENetworkRequestStatus.SUCCESS && (
        <div className="form text-13px leading-17px text-black">
          <div className="row flex">
            <label className="name block grow max-w-250px mr-20px">
              Name
              <TextInput
                className="mt-5px"
                inputClassName="bg-ivory"
                value={editable?.name || ''}
                onChange={handleNameChange}
                disabled={!isEditMode}
              />
            </label>
            <label className="company block grow max-w-250px mr-20px">
              Company
              <TextInput
                className="mt-5px"
                inputClassName="bg-ivory"
                value={editable?.companyName || ''}
                onChange={handleCompanyChange}
                disabled={!isEditMode}
              />
            </label>
            <label className="address block grow max-w-400px mr-20px">
              Address
              <TextInput
                className="mt-5px"
                inputClassName="bg-ivory"
                value={editable?.address || ''}
                onChange={handleAddressChange}
                disabled={!isEditMode}
              />
            </label>
            <label className="country block grow max-w-250px">
              Country
              <Select
                wrapperClassName="max-w-250px mt-5px"
                styles={selectStyles}
                value={editable?.countryCode || null}
                options={countryOptions}
                onChange={handleCountryCodeChange}
                disabled={!isEditMode}
              />
            </label>
          </div>
          <div className="row flex mt-3">
            <label className="email block grow max-w-250px mr-20px">
              E-mail
              <TextInput
                className="mt-5px"
                inputClassName="bg-ivory"
                value={editable?.email || ''}
                onChange={handleEmailChange}
                disabled={!isEditMode}
              />
            </label>
            <label className="vat block grow max-w-250px mr-20px">
              VAT
              <TextInput
                className="mt-5px"
                inputClassName="bg-ivory"
                value={editable?.vat || ''}
                onChange={handleVatChange}
                disabled={!isEditMode}
              />
            </label>
            <label className="iata block grow max-w-250px mr-20px">
              IATA
              <TextInput
                className="mt-5px"
                inputClassName="bg-ivory"
                value={editable?.iata || ''}
                onChange={handleIataChange}
                disabled={!isEditMode}
              />
            </label>
          </div>
          {isEditMode && (<FluidButton
            type="primary"
            className="save mt-15px"
            onClick={handleSave}
            disabled={reqs.update === ENetworkRequestStatus.PENDING || !isEditMode}
          >
            Save addressee
          </FluidButton>)}
        </div>
      )}
      <LeaveWithoutSavingModal when={hasChanges} />
    </div>
  );
};
