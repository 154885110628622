import { ENetworkRequestStatus } from 'services/BackendApi';
import { update } from 'utils/immutable';
import * as Actions from './actions';
import { ICompanyInvoiceAddresseeDomain, initialState, emptyInvoiceAddressee } from './model';

export const companyInvoiceAddresseeReducer = (
  state: ICompanyInvoiceAddresseeDomain = initialState,
  action: Actions.Any
): ICompanyInvoiceAddresseeDomain => {
  switch (action.type) {
    
    case Actions.GET_REQUEST:
      return update(state, s => s.request.get = ENetworkRequestStatus.PENDING);
    
    case Actions.GET_SUCCESS:
      return update(state, s => {
        s.request.get = ENetworkRequestStatus.SUCCESS;
        s.editable = action.addressee ? { ...action.addressee } : emptyInvoiceAddressee();
      });

    case Actions.GET_FAILURE:
      return update(state, s => s.request.get = ENetworkRequestStatus.ERROR);
    
    case Actions.UPDATE_REQUEST:
      return update(state, s => s.request.update = ENetworkRequestStatus.PENDING);
    
    case Actions.UPDATE_SUCCESS:
      return update(state, s => {
        s.request.update = ENetworkRequestStatus.SUCCESS;
        s.editable = { ...action.addressee };
      });

    case Actions.UPDATE_FAILURE:
      return update(state, s => s.request.update = ENetworkRequestStatus.ERROR);
    
    case Actions.SET_NAME:
      return update(state, s => {
        if (!s.editable) return;
        s.editable.name = action.value;
      });
    
    case Actions.SET_COMPANY_NAME:
      return update(state, s => {
        if (!s.editable) return;
        s.editable.companyName = action.value;
      });
    
    case Actions.SET_ADDRESS:
      return update(state, s => {
        if (!s.editable) return;
        s.editable.address = action.value;
      });
    
    case Actions.SET_COUNTRY_CODE:
      return update(state, s => {
        if (!s.editable) return;
        s.editable.countryCode = action.value;
      });
    
    case Actions.SET_EMAIL:
      return update(state, s => {
        if (!s.editable) return;
        s.editable.email = action.value;
      });
    
    case Actions.SET_VAT:
      return update(state, s => {
        if (!s.editable) return;
        s.editable.vat = action.value;
      });
    
    case Actions.SET_IATA:
      return update(state, s => {
        if (!s.editable) return;
        s.editable.iata = action.value;
      });

    default:
      return state;
  }
};

export default companyInvoiceAddresseeReducer;
