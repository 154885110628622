import { IBookingManagerCommentsSubdomain } from '../../model';
import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { uniq } from 'ramda';
import * as Actions from './actions';

export const commentsSubdomainReducer = (state: IBookingManagerCommentsSubdomain, action) => {
  switch (action.type) {
    case Actions.SET_BOOKING_COMMENT_DRAFT:
      return SetBookingCommentDraftReducer(state, action);

    case Actions.ADD_BOOKING_COMMENT_REQUEST:
      return AddBookingCommentRequestReducer(state, action);

    case Actions.ADD_BOOKING_COMMENT_SUCCESS:
      return AddBookingCommentSuccessReducer(state, action);

    case Actions.ADD_BOOKING_COMMENT_FAILURE:
      return AddBookingCommentFailureReducer(state, action);

    case Actions.GET_BOOKING_COMMENTS_REQUEST:
      return produce(state, draftState => {
        draftState.getBookingCommentsLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_BOOKING_COMMENTS_SUCCESS:
      return GetBookingCommentsSuccessReducer(state, action);

    case Actions.GET_BOOKING_COMMENTS_FAILURE:
      return GetBookingCommentsFailureReducer(state, action);

    case Actions.UPDATE_BOOKING_COMMENT_SUCCESS:
      return UpdateBookingCommentSuccessReducer(state, action);
    case Actions.UPDATE_BOOKING_COMMENT_REQUEST:
      return UpdateBookingCommentRequestReducer(state, action);

    case Actions.DELETE_BOOKING_COMMENT_REQUEST:
      return DeleteBookingCommentRequestReducer(state, action);
    case Actions.DELETE_BOOKING_COMMENT_SUCCESS:
      return DeleteBookingCommentSuccessReducer(state, action);

    case Actions.ON_START_EDITING_COMMENT:
      return OnStartEditingCommentReducer(state, action);

    case Actions.ON_CANCEL_EDITING_COMMENT:
      return onCancelEditingCommentReducer(state, action);

    case Actions.ON_COMPLETE_EDITING_COMMENT:
      return onCompleteEditingCommentReducer(state, action);

    case Actions.SET_EDITING_COMMENT_DRAFT:
      return SetEditingCommentDraftReducer(state, action);
    default:
      return state;
  }
};

const AddBookingCommentRequestReducer = (
  state: IBookingManagerCommentsSubdomain,
  action: Actions.AddBookingCommentRequestAction
): IBookingManagerCommentsSubdomain => {
  return produce(state, draftState => {
    draftState.addBookingCommentLoad = ENetworkRequestStatus.PENDING;
    return draftState;
  });
};

const AddBookingCommentSuccessReducer = (
  state: IBookingManagerCommentsSubdomain,
  action: Actions.AddBookingCommentSuccessAction
): IBookingManagerCommentsSubdomain => {
  return produce(state, draftState => {
    draftState.addBookingCommentLoad = ENetworkRequestStatus.SUCCESS;

    draftState.comments = action.comments;
    return draftState;
  });
};

const AddBookingCommentFailureReducer = (
  state: IBookingManagerCommentsSubdomain,
  action: Actions.AddBookingCommentFailureAction
): IBookingManagerCommentsSubdomain => {
  return produce(state, draftState => {
    draftState.addBookingCommentLoad = ENetworkRequestStatus.ERROR;
    return draftState;
  });
};

const SetBookingCommentDraftReducer = (
  commentsSubdomain: IBookingManagerCommentsSubdomain,
  action: Actions.SetBookingCommentDraftAction
): IBookingManagerCommentsSubdomain => {
  return produce(commentsSubdomain, draftState => {
    switch (action.commentType) {
      case 'public':
        draftState.publicCommentDraft = action.comment;
        break;
      case 'private':
        draftState.privateCommentDraft = action.comment;
        break;
    }
    return draftState;
  });
};

const GetBookingCommentsSuccessReducer = (
  state: IBookingManagerCommentsSubdomain,
  action: Actions.GetBookingCommentsSuccessAction
): IBookingManagerCommentsSubdomain => {
  return produce(state, draftState => {
    draftState.getBookingCommentsLoad = ENetworkRequestStatus.SUCCESS;
    draftState.comments = action.comments;
    return draftState;
  });
};

const GetBookingCommentsFailureReducer = (
  state: IBookingManagerCommentsSubdomain,
  action: Actions.GetBookingCommentsFailureAction
): IBookingManagerCommentsSubdomain => {
  return produce(state, draftState => {
    draftState.getBookingCommentsLoad = ENetworkRequestStatus.ERROR;
    return draftState;
  });
};

const UpdateBookingCommentRequestReducer = (
  state: IBookingManagerCommentsSubdomain,
  action: Actions.UpdateBookingCommentRequestAction
): IBookingManagerCommentsSubdomain => {
  return produce(state, draftState => {
    draftState.bookingCommentUuidsLoading.push(action.comment.uuid);
    draftState.bookingCommentUuidsLoading = uniq(draftState.bookingCommentUuidsLoading);
    return draftState;
  });
};

const UpdateBookingCommentSuccessReducer = (
  state: IBookingManagerCommentsSubdomain,
  action: Actions.UpdateBookingCommentSuccessAction
): IBookingManagerCommentsSubdomain => {
  return produce(state, draftState => {
    draftState.comments = action.comments;

    // remove it from the loading array
    const uuidIndex = draftState.bookingCommentUuidsLoading.findIndex(uuid => uuid === action.comment.uuid);
    if (uuidIndex > -1) {
      draftState.bookingCommentUuidsLoading.splice(uuidIndex, 1);
      draftState.bookingCommentUuidsLoading = uniq(draftState.bookingCommentUuidsLoading);
    }

    // remove it from the editing array
    const editIndex = draftState.bookingCommentUuidsEditing.findIndex(uuid => uuid === action.comment.uuid);
    if (editIndex > -1) {
      draftState.bookingCommentUuidsEditing.splice(editIndex, 1);
      draftState.bookingCommentUuidsEditing = uniq(draftState.bookingCommentUuidsEditing);
    }

    return draftState;
  });
};

const DeleteBookingCommentRequestReducer = (
  state: IBookingManagerCommentsSubdomain,
  action: Actions.DeleteBookingCommentRequestAction
): IBookingManagerCommentsSubdomain => {
  return produce(state, draftState => {
    draftState.bookingCommentUuidsLoading.push(action.comment.uuid);
    draftState.bookingCommentUuidsLoading = uniq(draftState.bookingCommentUuidsLoading);
    return draftState;
  });
};

const DeleteBookingCommentSuccessReducer = (
  state: IBookingManagerCommentsSubdomain,
  action: Actions.DeleteBookingCommentSuccessAction
): IBookingManagerCommentsSubdomain => {
  return produce(state, draftState => {
    draftState.comments = action.comments;

    // remove it from the loading array
    const uuidIndex = draftState.bookingCommentUuidsLoading.findIndex(uuid => uuid === action.comment.uuid);
    if (uuidIndex > -1) {
      draftState.bookingCommentUuidsLoading.splice(uuidIndex, 1);
      draftState.bookingCommentUuidsLoading = uniq(draftState.bookingCommentUuidsLoading);
    }
    return draftState;
  });
};

const OnStartEditingCommentReducer = (
  state: IBookingManagerCommentsSubdomain,
  action: Actions.OnStartEditingCommentAction
): IBookingManagerCommentsSubdomain => {
  return produce(state, draftState => {
    // add it to the array of things being edited
    draftState.bookingCommentUuidsEditing.push(action.comment.uuid);
    draftState.bookingCommentUuidsEditing = uniq(draftState.bookingCommentUuidsEditing);

    // create a draft for it, with the base comment text as the draft
    draftState.bookingCommentUuidsEditingDrafts[action.comment.uuid] = action.comment.text;
    return draftState;
  });
};

const onCancelEditingCommentReducer = (
  state: IBookingManagerCommentsSubdomain,
  action: Actions.OnCancelEditingCommentAction
) => {
  return produce(state, draftState => {
    // remove it from the editing array
    const uuidIndex = draftState.bookingCommentUuidsEditing.findIndex(uuid => uuid === action.comment.uuid);
    if (uuidIndex > -1) {
      draftState.bookingCommentUuidsEditing.splice(uuidIndex, 1);
      draftState.bookingCommentUuidsEditing = uniq(draftState.bookingCommentUuidsEditing);
    }

    // and delete its draft
    delete draftState.bookingCommentUuidsEditingDrafts[action.comment.uuid];
    return draftState;
  });
};

const onCompleteEditingCommentReducer = (
  state: IBookingManagerCommentsSubdomain,
  action: Actions.OnCompleteEditingCommentAction
) => {
  return produce(state, draftState => {
    // add it to the loading array
    draftState.bookingCommentUuidsLoading.push(action.comment.uuid);
    draftState.bookingCommentUuidsLoading = uniq(draftState.bookingCommentUuidsLoading);
    return draftState;
  });
};

const SetEditingCommentDraftReducer = (
  state: IBookingManagerCommentsSubdomain,
  action: Actions.SetEditingCommentDraftAction
) => {
  return produce(state, draftState => {
    draftState.bookingCommentUuidsEditingDrafts[action.comment.uuid] = action.draft;
    return draftState;
  });
};
