import React, { useState, useCallback, useLayoutEffect } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { BackLink } from 'ui/BackLink';
import { ProposalItem } from './ProposalItem';
import { PageTitle } from 'ui/PageTitle';
import * as ProposalDetailsActions from 'store/modules/proposalDetails/actions';
import * as ProposalDetailsSelectors from 'store/modules/proposalDetails/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { formatDateDisplay } from 'utils';
import { renderFullName } from 'utils/guestInformation';
import SingleSelect from 'ui/SingleSelect';
import FluidButton from 'ui/FluidButton';
import { EProposalSortBy } from 'store/modules/proposalDetails/model';
import { ESortOrder } from 'store/common/types';
import { ArrowDownComponent as ArrowDownIcon } from 'ui/Icons';
import classnames from 'classnames';
import DownloadIcon from '../../ui/Icons//download.arrow.component.svg';
import { theme } from '../../../tailwind.config';

interface IProposalDatesProps {
  created: string;
  modified: string;
  clientName: string;
}

const sortByOptions = [
  {
    label: 'Order by Created On',
    value: EProposalSortBy.CREATED,
  },
  {
    label: 'Order by Total Cost',
    value: EProposalSortBy.TOTAL,
  },
];

const sortOrderOptions = [
  {
    label: 'Ascending',
    value: ESortOrder.ASC,
  },
  {
    label: 'Descending',
    value: ESortOrder.DESC,
  },
];

const ProposalDates: React.FC<IProposalDatesProps> = ({ created, modified, clientName }) => {
  return (
    <div className="proposal-dates flex font-pt-sans text-black text-base leading-21px">
      <p className="date-created m-0 pr-7px">
        <span className="font-bold">Created&nbsp;</span>
        <span className="font-normal">{created}</span>
      </p>
      <p className="date-modified m-0 pr-7px pl-7px border-l-2 border-solid border-brown-60">
        <span className="font-bold">Modified&nbsp;</span>
        <span className="font-normal">{modified}</span>
      </p>
      <p className="client-name m-0 pl-7px border-l-2 border-solid border-brown-60">
        <span className="font-bold">Client&nbsp;</span>
        <span className="font-normal">{clientName}</span>
      </p>
    </div>
  );
};

const GenerateButton: React.FC<{ count: number }> = ({ count }) => (
  <FluidButton className="generate-pdf uppercase" type="primary" rounded={false} fixedWidth={count ? '240px' : '140px'}>
    Generate PDF {count ? `selected (${count})` : ''}
  </FluidButton>
);

const getFileName = (fileUrl: string): string => {
  const fileName = fileUrl?.substring(fileUrl?.lastIndexOf('/') + 1);
  return decodeURIComponent(fileName);
};

interface IProposalRouteParams {
  proposalSelectionUuid: string;
  stage: string;
}

export const ProposalDetailsPage: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const match = useRouteMatch<IProposalRouteParams>();
  const { proposalSelectionUuid } = match.params;
  const history = useSelector(ProposalDetailsSelectors.proposalDownloadHistorySelector);
  const proposal = useSelector(ProposalDetailsSelectors.proposalDetailsSelector);
  const bookings = useSelector(ProposalDetailsSelectors.proposalBookingsSelector);
  const selectedBookingUuids = useSelector(ProposalDetailsSelectors.selectedBookingUuidsSelector);
  const generatePdfSelectedBookingUuids = useSelector(ProposalDetailsSelectors.generatePdfSelectedBookingUuidsSelector);
  const requests = useSelector(ProposalDetailsSelectors.proposalDetailsRequestsSelector);
  const sortBy = useSelector(ProposalDetailsSelectors.proposalDetailsSortBySelector);
  const sortOrder = useSelector(ProposalDetailsSelectors.proposalDetailsSortOrderSelector);
  const isLoading = requests.proposalDetailsLoad === ENetworkRequestStatus.PENDING;
  const isError = requests.proposalDetailsLoad === ENetworkRequestStatus.ERROR;

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSelectSortBy = useCallback(
    newSortBy => {
      dispatch(ProposalDetailsActions.setProposalDetailsSortByAction(newSortBy, ESortOrder.ASC));
    },
    [dispatch]
  );

  const handleSelectSortOrder = useCallback(
    newSortOrder => {
      dispatch(ProposalDetailsActions.setProposalDetailsSortByAction(sortBy, newSortOrder));
    },
    [dispatch, sortBy]
  );

  useLayoutEffect(() => {
    dispatch(ProposalDetailsActions.getProposalDetailsRequestAction(proposalSelectionUuid));
  }, [dispatch, proposalSelectionUuid]);

  const handleSelectProposalItem = useCallback(
    (bookingUuid: string) => {
      dispatch(ProposalDetailsActions.toggleSelectionProposalBookingAction(bookingUuid));
    },
    [dispatch]
  );

  return (
    <div className="proposal-details container mx-auto max-w-1280px font-pt-sans text-black">
      <BackLink type="internal" href="/proposals-v2">
        Back to proposals result
      </BackLink>

      <div className="page-title-container flex items-baseline justify-between">
        {proposal && <PageTitle primaryText={proposal.name} />}
        {!proposal && <PageTitle primaryText="Proposal Details" />}
        {proposal && (
          <ProposalDates
            created={formatDateDisplay(proposal.createdAt)}
            modified={formatDateDisplay(proposal.updatedAt)}
            clientName={renderFullName(proposal.guestTitle, proposal.guestFirstName, proposal.guestLastName)}
          />
        )}
      </div>
      {isError && <ErrorBar />}
      {isLoading && (
        <div className="mt-5">
          <LoadingBar />
        </div>
      )}

      {!isLoading && (
        <div className="flex justify-between items-center mt-8">
          <div className="flex gap-20px">
            <SingleSelect
              fieldId="sort-by"
              className="sort-by w-[75px] md:w-[220px] lg:w-[220px]"
              value={sortBy}
              options={sortByOptions}
              onChange={handleSelectSortBy}
            />

            <SingleSelect
              fieldId="sort-order"
              className="sort-order w-[75px] md:w-[150px] lg:w-[150px]"
              value={sortOrder}
              options={sortOrderOptions}
              onChange={handleSelectSortOrder}
            />
          </div>
          <div className="flex items-center">
            <div className="flex items-center mr-4 ml-1" onClick={toggle}>
              <div className="text-brown-prime text-15px underline cursor-pointer mr-10px">See PDFs History</div>
              <ArrowDownIcon
                icon={theme.colors['brown-80']}
                className={classnames('transition-rotate duration-500 ease-in-out', 'fill-brown-80', {
                  'rotate-180': isOpen,
                  'rotate-0': !isOpen,
                })}
              />
            </div>
            <div>
              <Link to={`generate?bookings=${generatePdfSelectedBookingUuids.join(',')}`}>
                <GenerateButton count={selectedBookingUuids.length} />
              </Link>
            </div>
          </div>
        </div>
      )}

      {isOpen && (
        <div className="bg-ivory flex flex-col py-20px px-20px mt-10px">
          <div className="mb-10px">
            <span className="font-bold">PDFs History</span> ({history.length})
          </div>

          {history.map(({ pdfUrl, email = '' }, index) => (
            <div
              key={index}
              className="flex flex-row p-10px justify-start border-b-2 border-r-0 border-l-0 border-t-0 border-solid border-gray-20"
            >
              <div className="w-2/5 underline">
                <DownloadIcon fill="#ABA5A2" />
                <span className="ml-10px">
                  <a href={pdfUrl}>{getFileName(pdfUrl)}</a>
                </span>
              </div>
              <div>{email}</div>
            </div>
          ))}
        </div>
      )}

      {bookings.map(booking => {
        return (
          <ProposalItem
            key={booking.uuid}
            booking={booking}
            selected={selectedBookingUuids.includes(booking.uuid)}
            onSelectionToggle={handleSelectProposalItem}
            className="mt-5"
          />
        );
      })}
    </div>
  );
});
