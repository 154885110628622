import React from 'react';

export const TravelPartnerPrivacyNote = React.memo(() => {
  return (
    <div className="travel-agent-privacy-note bg-brown-15 p-4 mb-25px">
      <div className="flex items-center ">
        <span className="block text-brown-100 font-pt-sans text-base font-bold mb-1">Privacy</span>
        <i className="block text-brown-100 fas fa-exclamation-triangle ml-1"></i>
      </div>
      <span className="block font-pt-sans text-15px">
        NOTE: The <span className="italic">Travel Partner</span> linked to this booking will be able to see this
        data.
      </span>
    </div>
  );
});
