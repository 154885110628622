import { IBookingCommentType, IBookingComment } from 'services/BookingManagerApi';

// Comments
export const SET_BOOKING_COMMENT_DRAFT = 'bookingManager/SET_BOOKING_COMMENT_DRAFT';

export const GET_BOOKING_COMMENTS_REQUEST = 'bookingManager/GET_BOOKING_COMMENTS_REQUEST';
export const GET_BOOKING_COMMENTS_SUCCESS = 'bookingManager/GET_BOOKING_COMMENTS_SUCCESS';
export const GET_BOOKING_COMMENTS_FAILURE = 'bookingManager/GET_BOOKING_COMMENTS_FAILURE';

export const ADD_BOOKING_COMMENT_REQUEST = 'bookingManager/ADD_BOOKING_COMMENT_REQUEST';
export const ADD_BOOKING_COMMENT_SUCCESS = 'bookingManager/ADD_BOOKING_COMMENT_SUCCESS';
export const ADD_BOOKING_COMMENT_FAILURE = 'bookingManager/ADD_BOOKING_COMMENT_FAILURE';

export const UPDATE_BOOKING_COMMENT_REQUEST = 'bookingManager/UPDATE_BOOKING_COMMENT_REQUEST';
export const UPDATE_BOOKING_COMMENT_SUCCESS = 'bookingManager/UPDATE_BOOKING_COMMENT_SUCCESS';
export const UPDATE_BOOKING_COMMENT_FAILURE = 'bookingManager/UPDATE_BOOKING_COMMENT_FAILURE';

export const DELETE_BOOKING_COMMENT_REQUEST = 'bookingManager/DELETE_BOOKING_COMMENT_REQUEST';
export const DELETE_BOOKING_COMMENT_SUCCESS = 'bookingManager/DELETE_BOOKING_COMMENT_SUCCESS';
export const DELETE_BOOKING_COMMENT_FAILURE = 'bookingManager/DELETE_BOOKING_COMMENT_FAILURE';

export const ON_START_EDITING_COMMENT = 'bookingManager/ON_START_EDITING_COMMENT';
export const ON_CANCEL_EDITING_COMMENT = 'bookingManager/ON_CANCEL_EDITING_COMMENT';
export const ON_COMPLETE_EDITING_COMMENT = 'bookingManager/ON_COMPLETE_EDITING_COMMENT';
export const SET_EDITING_COMMENT_DRAFT = 'bookingManager/SET_EDITING_COMMENT_DRAFT';

export type SetBookingCommentDraftAction = ReturnType<typeof setBookingCommentDraftAction>;
export const setBookingCommentDraftAction = (comment: string, commentType: string) => ({
  type: SET_BOOKING_COMMENT_DRAFT as typeof SET_BOOKING_COMMENT_DRAFT,
  comment,
  commentType,
});

export type GetBookingCommentsRequestAction = ReturnType<typeof getBookingCommentsRequestAction>;
export const getBookingCommentsRequestAction = () => ({
  type: GET_BOOKING_COMMENTS_REQUEST as typeof GET_BOOKING_COMMENTS_REQUEST,
});

export type GetBookingCommentsSuccessAction = ReturnType<typeof getBookingCommentsSuccessAction>;
export const getBookingCommentsSuccessAction = (comments: IBookingComment[]) => ({
  type: GET_BOOKING_COMMENTS_SUCCESS as typeof GET_BOOKING_COMMENTS_SUCCESS,
  comments,
});

export type GetBookingCommentsFailureAction = ReturnType<typeof getBookingCommentsFailureAction>;
export const getBookingCommentsFailureAction = (error: string) => ({
  type: GET_BOOKING_COMMENTS_FAILURE as typeof GET_BOOKING_COMMENTS_FAILURE,
  error,
});

export type AddBookingCommentRequestAction = ReturnType<typeof addBookingCommentRequestAction>;
export const addBookingCommentRequestAction = (
  bookingUuid: string,
  commentText: string,
  commentType: IBookingCommentType
) => ({
  type: ADD_BOOKING_COMMENT_REQUEST as typeof ADD_BOOKING_COMMENT_REQUEST,
  bookingUuid,
  commentText,
  commentType,
});

export type AddBookingCommentSuccessAction = ReturnType<typeof addBookingCommentSuccessAction>;
export const addBookingCommentSuccessAction = (comments: IBookingComment[]) => ({
  type: ADD_BOOKING_COMMENT_SUCCESS as typeof ADD_BOOKING_COMMENT_SUCCESS,
  comments,
});

export type AddBookingCommentFailureAction = ReturnType<typeof addBookingCommentFailureAction>;
export const addBookingCommentFailureAction = (error: string) => ({
  type: ADD_BOOKING_COMMENT_FAILURE as typeof ADD_BOOKING_COMMENT_FAILURE,
  error,
});

export type UpdateBookingCommentRequestAction = ReturnType<typeof updateBookingCommentRequestAction>;
export const updateBookingCommentRequestAction = (comment: IBookingComment, newText: string) => ({
  type: UPDATE_BOOKING_COMMENT_REQUEST as typeof UPDATE_BOOKING_COMMENT_REQUEST,
  comment,
  newText,
});

export type UpdateBookingCommentSuccessAction = ReturnType<typeof updateBookingCommentSuccessAction>;
export const updateBookingCommentSuccessAction = (comment: IBookingComment, comments: IBookingComment[]) => ({
  type: UPDATE_BOOKING_COMMENT_SUCCESS as typeof UPDATE_BOOKING_COMMENT_SUCCESS,
  comment,
  comments,
});

export type UpdateBookingCommentFailureAction = ReturnType<typeof updateBookingCommentFailureAction>;
export const updateBookingCommentFailureAction = (error: string) => ({
  type: UPDATE_BOOKING_COMMENT_FAILURE as typeof UPDATE_BOOKING_COMMENT_FAILURE,
  error,
});

export type DeleteBookingCommentRequestAction = ReturnType<typeof deleteBookingCommentRequestAction>;
export const deleteBookingCommentRequestAction = (comment: IBookingComment) => ({
  type: DELETE_BOOKING_COMMENT_REQUEST as typeof DELETE_BOOKING_COMMENT_REQUEST,
  comment,
});

export type DeleteBookingCommentSuccessAction = ReturnType<typeof deleteBookingCommentSuccessAction>;
export const deleteBookingCommentSuccessAction = (comment: IBookingComment, comments: IBookingComment[]) => ({
  type: DELETE_BOOKING_COMMENT_SUCCESS as typeof DELETE_BOOKING_COMMENT_SUCCESS,
  comment,
  comments,
});

export type DeleteBookingCommentFailureAction = ReturnType<typeof deleteBookingCommentFailureAction>;
export const deleteBookingCommentFailureAction = (error: string) => ({
  type: DELETE_BOOKING_COMMENT_FAILURE as typeof DELETE_BOOKING_COMMENT_FAILURE,
  error,
});

export type OnStartEditingCommentAction = ReturnType<typeof onStartEditingCommentAction>;
export const onStartEditingCommentAction = (comment: IBookingComment) => ({
  type: ON_START_EDITING_COMMENT as typeof ON_START_EDITING_COMMENT,
  comment,
});

export type OnCancelEditingCommentAction = ReturnType<typeof onCancelEditingCommentAction>;
export const onCancelEditingCommentAction = (comment: IBookingComment) => ({
  type: ON_CANCEL_EDITING_COMMENT as typeof ON_CANCEL_EDITING_COMMENT,
  comment,
});

export type OnCompleteEditingCommentAction = ReturnType<typeof onCompleteEditingCommentAction>;
export const onCompleteEditingCommentAction = (comment: IBookingComment, newText: string) => ({
  type: ON_COMPLETE_EDITING_COMMENT as typeof ON_COMPLETE_EDITING_COMMENT,
  comment,
  newText,
});

export type SetEditingCommentDraftAction = ReturnType<typeof setEditingCommentDraftAction>;
export const setEditingCommentDraftAction = (comment: IBookingComment, draft: string) => ({
  type: SET_EDITING_COMMENT_DRAFT as typeof SET_EDITING_COMMENT_DRAFT,
  comment,
  draft,
});

export type CommentsSubdomainAction =
  | SetBookingCommentDraftAction
  | GetBookingCommentsRequestAction
  | GetBookingCommentsSuccessAction
  | GetBookingCommentsFailureAction
  | AddBookingCommentRequestAction
  | AddBookingCommentSuccessAction
  | AddBookingCommentFailureAction
  | UpdateBookingCommentRequestAction
  | UpdateBookingCommentSuccessAction
  | UpdateBookingCommentFailureAction
  | DeleteBookingCommentRequestAction
  | DeleteBookingCommentSuccessAction
  | DeleteBookingCommentFailureAction
  | OnStartEditingCommentAction
  | OnCancelEditingCommentAction
  | OnCompleteEditingCommentAction
  | SetEditingCommentDraftAction;
