import React, { useCallback } from 'react';

import Checkbox from 'ui/Checkbox';
import { IChecklistItem } from 'services/BookingManagerApi/types';

export interface ChecklistItemProps {
  label: string;
  checked: boolean;
  className?: string;
  testId?: string;
  onChange: (value: string, checked: boolean) => void;
}

export const ChecklistItem = (props: ChecklistItemProps) => {
  const { checked, className, onChange, label, testId = 'checklist-item' } = props;

  const handleChange = useCallback(
    e => {
      onChange(label, e.currentTarget.checked);
    },
    [onChange, label]
  );

  return (
    <li data-testid={testId} className={`${className || ''} flex items-center px-5 py-3 odd:bg-brown-10`}>
      <Checkbox checked={checked || false} onChange={handleChange} />
      <label className="block font-pt-sans text-sm leading-xs text-black ml-2">{label}</label>
    </li>
  );
};

export interface ChecklistProps {
  list: IChecklistItem[];
  onChange: (value: string, checked: boolean) => void;
  className?: string;
  testId?: string;
}

const Checklist = (props: ChecklistProps) => {
  const { className, list, onChange, testId = 'checklist' } = props;

  return (
    <ul data-testid={testId} className={`${className || ''} m-0 p-0`}>
      {list.map(({ label, isChecked }) => (
        <ChecklistItem
          data-testid={'checklist-item'}
          key={label}
          checked={isChecked}
          onChange={onChange}
          label={label}
        />
      ))}
    </ul>
  );
};

export default Checklist;
