import React from 'react';
import classNames from 'classnames';

import { formatPrice } from 'utils';

import SingleDateInput from 'pureUi/SingleDateInput';
import { DecimalInput } from 'ui/stateful/DecimalInput';
import { PriceAsCentsInput } from 'ui/stateful/PriceAsCentsInput';

interface ICancellationDueDateProps {
  bookingCurrencySymbol: string | null;
  dueDate: string;
  percentage: number;
  amountCents: number;
  isConfirmed?: boolean;
  initialOutstandingCents?: number;
  outstandingBalanceCents: number;
  onChangeDate: (date: Date) => void;
  onChangePercentage: (percentage: number) => void;
  setAmountCents: (e: any) => void;
}

export const CancellationDueDate: React.FC<ICancellationDueDateProps> = React.memo(
  ({
    bookingCurrencySymbol,
    dueDate,
    percentage,
    amountCents,
    initialOutstandingCents,
    outstandingBalanceCents,
    onChangeDate,
    onChangePercentage,
    setAmountCents,
  }) => (
    <>
      <div className="bg-brown-15 flex justify-between py-3 px-5">
        <span className="m-0 text-13px uppercase">Cost To Travel Partner</span>
        {initialOutstandingCents !== undefined && (
          <span className="m-0 text-16px font-bold">
            {bookingCurrencySymbol}
            {formatPrice((initialOutstandingCents || 0) / 100)}
          </span>
        )}
      </div>
      <div className="flex py-3 px-5 text-15px items-center justify-between">
        <span className="font-bold">Cancellation Fee:</span>

        <span className="flex items-center">
          <span className="mr-5px">Due Date</span>
          <SingleDateInput
            value={dueDate && dueDate !== '' ? new Date(dueDate) : null}
            onChange={onChangeDate}
            disableDates={[]}
            showYearDropdown
            enablePastDates={true}
            internalClassName={classNames({
              dateInputField: true,
              dateNotSelected: dueDate === '',
            })}
          />
        </span>

        <span>
          <span className="mr-5px">Percentage (%)</span>
          <DecimalInput
            decimalPlaces={2}
            className="w-50px border border-solid border-gray-40 bg-ivory min-h-35px"
            onBlur={onChangePercentage}
            value={percentage}
          />
        </span>

        <span>
          <span className="mr-5px">{bookingCurrencySymbol}</span>
          <PriceAsCentsInput
            cents={amountCents}
            className="w-117px border border-solid border-gray-40 bg-ivory min-h-35px"
            onBlurUpdate={setAmountCents}
            disabled={false}
          />
        </span>
      </div>
      <div className="bg-brown-15 flex justify-between py-3 px-5">
        <span>Outstanding Balance</span>
        <span>
          <span>{bookingCurrencySymbol}</span>
          {formatPrice(outstandingBalanceCents / 100)}
        </span>
      </div>
    </>
  )
);
