import React from 'react';
import styled from 'styled-components';
import FocusTrap from 'focus-trap-react';
import classNames from 'classnames';
import { pureUiTheme } from 'pureUi/pureUiTheme';
import { IconButton } from '../Buttons/index';
import { renderPortal, PortalType } from '../../utils/portals';

export const ModalWrapper = styled.div`
  position: fixed !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  background-color: ${pureUiTheme.colors.grayOpacity1} !important;
`;

interface IModalFrameComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  closeOnOutsideClick?: boolean;
  overflowY?: 'auto' | 'visible' | 'hidden' | 'scroll';
  focusTrapActive: boolean;
}

export const ModalFrameComponent = (props: IModalFrameComponentProps) => {
  // eslint-disable-next-line no-unused-vars
  const { children, closeOnOutsideClick, overflowY, focusTrapActive, ...otherProps } = props;

  return (
    // @ts-ignore
    <FocusTrap
      focusTrapOptions={{
        clickOutsideDeactivates: closeOnOutsideClick ? true : false,
      }}
      active={focusTrapActive}
    >
      <div {...otherProps}>{children}</div>
    </FocusTrap>
  );
};

export const ModalFrame = styled(({ removePadding, ...rest }) => <ModalFrameComponent {...rest} />)`
  display: flex !important;
  flex-direction: column !important;
  background-color: ${pureUiTheme.colors.white} !important;
  position: fixed !important;
  overflow-y: ${props => props.overflowY} !important;
  ${props => (props.removePadding ? '' : 'padding: 80px 0 70px')} !important;
  max-height: 82vh !important;
  max-width: 90vw !important;
`;

export interface IModalHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  noLogo?: boolean;
}
export const ModalHeader = styled.div<IModalHeaderProps>`
  padding: 0 100px !important;
  padding-top: ${props => (props.noLogo ? 0 : '50px')} !important;
  background-image: none !important;
  background-position: top center !important;
  background-repeat: no-repeat !important;
  text-align: center !important;
`;

export const ModalContent = styled.div`
  overflow: scroll !important;
  padding: 0 100px !important;
`;

export const ModalFooter = styled.div`
  margin-top: 30px !important;
  padding: 0 100px !important;
`;

const ModalCloseButtonComponent = (props: React.HTMLAttributes<HTMLButtonElement>) => {
  return (
    <IconButton {...props}>
      {/* close icon */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
        <path
          fillRule="evenodd"
          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
          clipRule="evenodd"
        />
      </svg>
    </IconButton>
  );
};
export const ModalCloseButton = styled(ModalCloseButtonComponent)`
  position: absolute !important;
  top: 20px !important;
  right: 20px !important;
  width: 40px !important;
  height: 40px !important;
  border: none !important;
  background: none !important;
  color: ${pureUiTheme.colors.brown100} !important;
  border-radius: 40px !important;
  cursor: pointer !important;
`;

export interface IStandardModalProps {
  className?: string;
  children?: React.ReactNode;
  onClose?: () => void;
  showCloseButton?: boolean;
  removePadding?: boolean;
  frameClassName?: string;
  closeOnOutsideClick?: boolean;
  overflowY?: 'auto' | 'visible' | 'hidden' | 'scroll';
  focusTrapActive?: boolean;
}
export const StandardModal = (props: IStandardModalProps) => {
  const {
    className = '',
    frameClassName = '',
    showCloseButton = true,
    removePadding = false,
    closeOnOutsideClick = false,
    overflowY = 'auto',
    focusTrapActive = true,
  } = props;

  const closeProxy = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  return renderPortal(
    <ModalWrapper
      className={classNames('modal z-2000', className)}
      onClick={e => {
        e.preventDefault();
        if (closeOnOutsideClick && e.target === e.currentTarget) {
          closeProxy();
        }
      }}
    >
      <ModalFrame
        removePadding={removePadding}
        className={`${frameClassName}`}
        closeOnOutsideClick={closeOnOutsideClick}
        overflowY={overflowY}
        focusTrapActive={focusTrapActive}
      >
        {props.children}
        {showCloseButton && <ModalCloseButton onClick={closeProxy} />}
      </ModalFrame>
    </ModalWrapper>,
    PortalType.Modal
  );
};
