import { call, takeLatest, put } from 'redux-saga/effects';
import { SEARCH_BOOKING_REF_REQUEST,searchBookingRefFailureAction,SearchBookingRefRequestAction, searchBookingRefSuccessAction } from '../actions';
import { IBookingSearchResponse, makeBookingManagerApi } from 'services/BookingManagerApi';
import { AxiosResponse } from 'axios';

export function* searchBookingRefRequestSaga(action: SearchBookingRefRequestAction) {
    const bookingManagerApi = makeBookingManagerApi();
    const query = { filter: { humanReadableIdFilter: action.bookingRefNumber } };
  
    try {
      const result: AxiosResponse<IBookingSearchResponse> = yield call(bookingManagerApi.bookingSearch, query);
      const bookingRefs = result.data.bookings.map(booking => booking.humanReadableId);
      yield put(searchBookingRefSuccessAction(bookingRefs));
    } catch (e) {
      yield put(searchBookingRefFailureAction());
    }
  }

export function* watchSearchBookingRefRequestSaga() {
  yield takeLatest(
    [SEARCH_BOOKING_REF_REQUEST],
    searchBookingRefRequestSaga
  );
}
