import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  makeBookingManagerApi,
  IOfferTermsResponse,
  IHeadlineBreakdownVersionListVersion,
} from 'services/BookingManagerApi';
import { getOfferTermsSuccessAction, getOfferTermsFailureAction, GET_OFFER_TERMS_REQUEST } from '../actions';
import * as BreakdownSelectors from '../selectors';
import * as BreakdownActions from '../actions';

import { bookingUuidSelector } from '../../../selectors';

export function* getOfferTermsSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);
    const selectedVersion: IHeadlineBreakdownVersionListVersion | null = yield select(
      BreakdownSelectors.headlineBreakdownSelectedVersionSelector
    );

    const result: AxiosResponse<IOfferTermsResponse> = yield call(
      bookingManagerApi.getOfferTerms,
      bookingUuid,
      selectedVersion?.timestamp
    );

    yield put(getOfferTermsSuccessAction(result.data.offerTerms));
  } catch (e) {
    yield put(getOfferTermsFailureAction(e));
  }
}

export function* watchBookingManagerOfferTerms() {
  yield takeLatest(
    [GET_OFFER_TERMS_REQUEST, BreakdownActions.SET_HEADLINE_BREAKDOWN_SELECTED_VERSION],
    getOfferTermsSaga
  );
}
