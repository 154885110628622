import styled from 'styled-components';
import AccommodationCard from './AccommodationCard';
import { theme } from 'styles';
import { colors } from 'pureUi/pureUiTheme';

export default styled(AccommodationCard)`
  position: relative;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  background-color: ${theme.backgrounds.secondary};
  color: ${theme.palette.light};
  line-height: 20px;
  margin-bottom: 1rem;

  hr {
    width: 90%;
    border-top: 1px solid ${colors.grayDark};
  }

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .innerWrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 0 1rem;
    margin-top: 15px;

    .accommodationIcons {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .accommodationIconsRow {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-left: 16px;
      height: 24px;
    }
  }

  .title-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem auto 0 auto;
    width: 90%;
    min-height: 108px;
    .title {
      margin: 0;
      color: ${theme.palette.primary};
      font-family: ${theme.fonts.headingFont};
      font-size: 20px;
      line-height: 32px;
    }
    .title-with-live-rates {
      max-width: 50%;
    }
  }

  .pricing,
  .info {
    display: flex;
    flex-direction: column;
  }

  ul.pricing {
    text-transform: uppercase;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    color: ${theme.palette.light};
    font-size: 14px;
  }

  .info {
    text-transform: uppercase;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .actions {
    list-style: none;
    margin: 1rem 0;
    padding: 0;
  }

  .numberSelect {
    margin-bottom: 17px;
    width: 88px;
  }

  ul.occupancy {
    margin: 1rem 0 0 0;
    padding: 0;
    list-style: none;
  }

  ul.minMax,
  ul.amenities {
    padding-left: 1rem;
  }

  .tooltip-content {
    text-align: left;
    padding: 10px;
    font-size: 12px;
  }

  .tooltip-content p {
    font-weight: bold;
    margin: 5px 10px 5px 25px;
    line-height: 14px;
  }

  .tooltip-content ul {
    margin: 5px 10px 5px 25px;
    padding: 0;
    line-height: 20px;
  }

  .tooltip-content ul li {
    margin-bottom: 5px;
  }

  @media (min-width: 600px) {
    .title {
      margin: 1rem 2rem;
      margin-bottom: 0;
    }

    .innerWrapper {
      flex-direction: row;
      align-items: flex-start;
    }

    .addLodgingButton {
      display: block;
    }

    .adultsChildren {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: ${colors.brown20};
      text-transform: none;
    }

    ul.pricing {
      text-align: right;
      padding-right: 16px;
      padding-bottom: 16px;

      li {
        font-size: 12px;
      }

      .price {
        font-size: 20px;
        margin-top: 0;
        margin-bottom: 6px;
      }

      .offerName {
        color: ${theme.colors['red-fade']};
      }

      .priceBeforeDiscount {
        font-size: 19.2px;
        text-decoration: line-through;
      }

      .add-lodging-button-wrapper {
        position: absolute;
        bottom: 28px;
        right: 28px;
      }
    }

    .info {
      width: 50%;
      padding: 0 0 1rem 1rem;
    }

    .numberSelect {
      margin-top: 7px;
      width: auto;
    }
  }
`;
