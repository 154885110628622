import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash-es';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { travelAgentSelectOptionsSelector, companySelectOptionsSelector } from '../../store/modules/agents/selectors';
import { getTravelAgentsRequestAction, getCompaniesRequestAction } from '../../store/modules/agents/actions';
import { filtersSelector } from '../../store/modules/credentialsInfo/selectors';
import {
  setFilterTaUuidAction,
  setFilterCompanyUuidAction,
  setFilterSentAction,
} from '../../store/modules/credentialsInfo/actions';
import { Multiselect } from 'ui/Multiselect';
export const Filters: React.FC = () => {
  const filters = useSelector(filtersSelector);
  const taOptions = useSelector(travelAgentSelectOptionsSelector);
  const companyOptions = useSelector(companySelectOptionsSelector);

  const statusOptions = useMemo(
    () => [
      { label: 'All statuses', value: '' },
      { label: 'Not Sent', value: 'false' },
      { label: 'Sent', value: 'true' },
    ],
    []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTravelAgentsRequestAction());
    dispatch(getCompaniesRequestAction());
  }, []);

  const handleTaChange = useCallback(
    value => {
      dispatch(setFilterTaUuidAction(value || undefined));
    },
    [dispatch]
  );

  const handleCompanyChange = useCallback(
    value => {
      dispatch(setFilterCompanyUuidAction(value || undefined));
    },
    [dispatch]
  );

  const handleStatusChange = useCallback(
    value => {
      let b;
      if (value === 'true') b = true;
      if (value === 'false') b = false;

      dispatch(setFilterSentAction(b));
    },
    [dispatch]
  );

  return (
    <div className="filters mt-5 mb-5">
      <p className="header font-pt-sans font-bold text-17px leading-22px">Filter Results</p>
      <div className="flex gap-20px mt-10px">
        <div>
          <label
            htmlFor={'travel-agent'}
            className="block font-pt-sans mb-5px text-black text-13px leading-17px tracking-2xs"
          >
            Travel Agent
          </label>
          <Multiselect
            placeholderText="Select..."
            height={33}
            placeholderClasses="font-pt-sans text-normal text-gray-100"
            className="bg-ivory travel-agent min-w-400px"
            itemsClassname="bg-ivory"
            itemCtaClassName="hover:bg-teal-40"
            itemContentClassName="font-pt-sans text-15px"
            itemCtaPaddingClassName="px-10px py-2"
            selectedValues={filters.taUuid ? [filters.taUuid] : []}
            options={taOptions}
            onUpdate={sv => {
              if (sv) {
                handleTaChange(sv[0]);
              }
            }}
            isEnableFuzzySearch
            isCloseOnSelect
            isSingleSelectMode
            itemsToDisplayBeforeScroll={6}
            hideCheckboxes
            singleItemHeightPixels={35.5}
          />
        </div>

        <div>
          <label
            htmlFor={'company'}
            className="block font-pt-sans mb-5px text-black text-13px leading-17px tracking-2xs"
          >
            Company
          </label>
          <Multiselect
            placeholderText="Select..."
            height={33}
            placeholderClasses="font-pt-sans text-normal text-gray-100"
            className="bg-ivory company min-w-400px"
            itemsClassname="bg-ivory"
            itemCtaClassName="hover:bg-teal-40"
            itemContentClassName="font-pt-sans text-15px"
            itemCtaPaddingClassName="px-10px py-2"
            selectedValues={filters.companyUuid ? [filters.companyUuid] : []}
            options={companyOptions}
            onUpdate={sv => {
              if (sv) {
                handleCompanyChange(sv[0]);
              }
            }}
            isEnableFuzzySearch
            isCloseOnSelect
            isSingleSelectMode
            itemsToDisplayBeforeScroll={6}
            hideCheckboxes
            singleItemHeightPixels={35.5}
          />
        </div>
        <SingleSelect
          fieldId="status"
          label="Status"
          className="status min-w-200px"
          value={!isNil(filters.sent) ? `${filters.sent}` : undefined}
          options={statusOptions as ISingleSelectOption[]}
          onChange={handleStatusChange}
        />
      </div>
    </div>
  );
};
