import styled from 'styled-components';
import { pureUiTheme } from 'pureUi/pureUiTheme';

export const OfferEditPackagesStyles = styled.section`

    .packageDiscount {
        border-bottom: ${pureUiTheme.colorRoles.lightGreyBorder} 1px solid;
        margin-bottom: 20px;
        padding-bottom: 20px;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: auto 50px;
        grid-template-areas: 
            'dateSelect removePDButton'
            'accommodationPackages .'
            'errorlist .'
            'epnrInfo .';

        .epnrInfo { grid-area: epnrInfo; }
        .dateSelect { grid-area: dateSelect; }
        .removePDButton { grid-area: removePDButton; text-align: right; padding-top: 8px;}
        .accommodationPackages { grid-area: accommodationPackages; }
        .errorlist { grid-area: errorlist; }
    }

    .accommodationPackage {
        margin-bottom: 20px;
        padding-bottom: 20px;
        display: grid;
        grid-gap: 10px;
        padding-left: 2%;
        grid-template-columns: 1fr 1fr 50px;
        grid-template-areas: 
            'accSelect accSelect removeAccButton'
            'accommodationProductPackages accommodationProductPackages .'
            'extraPersonNightRates extraPersonNightRates .';
        .accSelect { grid-area: accSelect; }
        .accommodationProductPackages { grid-area: accommodationProductPackages; }
        .extraPersonNightRates { grid-area: extraPersonNightRates; }
        .removeAccButton { grid-area: removeAccButton; text-align: right;     padding-top: 8px;}
    }

    .accommodationProductPackages {
        display: grid;
        grid-gap: 10px;
        padding-left: 5%;
        grid-template-columns: 1fr 1fr 50px;
        grid-template-areas: 
            'accommodationProductPackagesList accommodationProductPackagesList accommodationProductPackagesList'
            'extraNightRate . .'
            'addAccommodationProductPackageButton . .';
        .addAccommodationProductPackageButton { grid-area: addAccommodationProductPackageButton; }
        .extraNightRate { grid-area: extraNightRate; }
        .accommodationProductPackagesList { 
            grid-area: accommodationProductPackagesList; 
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }

    .extraPersonNightRates {
        display: grid;
        grid-gap: 10px;
        padding-left: 5%;
        grid-template-columns: 1fr 1fr 50px;
        grid-template-areas: 
            'epnrHeader epnrHeader .'
            'epnrList epnrList epnrList'
            'addEpnrButton . .';
        .epnrHeader {
            margin-top: 10px;
            padding-top: 10px;
        }
        .addEpnrButton { grid-area: addEpnrButton; }
        .epnrList { 
            grid-area: epnrList; 
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }

    .accommodationProductPackage {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr 50px;
        margin-bottom: 10px;
        .removeAppButton {
            text-align: right;
            padding-top: 8px;
            &:first-of-type {
                padding-top: 31px;
            }
        }
    }

    .extraPersonNightRate {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr 1fr 50px;
        margin-bottom: 10px;
        .removeEpnrButton {
            text-align: right;
            padding-top: 8px;
            &:first-of-type {
                padding-top: 31px;
            }
        }
    }
`;
