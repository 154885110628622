import urljoin from 'url-join';
import { schema as nSchema } from 'normalizr';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';

const schema = (name, sub = {}, ops = {}) => new nSchema.Entity(name, sub, { idAttribute: 'uuid', ...ops });

const normalizeURL = url => {
  const dynamicParameters = ParameterService.getParameters();
  return /^https?:\/\//i.test(url) ? url : urljoin(dynamicParameters.BACKEND_BASE_URL, '/upload/content', url);
};

const uploadsByTag = (xs, tag) => (xs || []).filter(x => x.tag === tag);

export const booking = schema('bookings');

export const proposal = schema('proposals', { bookings: [booking] });

export const upload = schema(
  'uploads',
  {},
  {
    processStrategy: ({ url, ...rest }) => ({
      ...rest,
      url: normalizeURL(url),
    }),
  }
);

export const content = schema('content', { uploads: [upload] });

export const rate = schema('rates');

export const product = schema('products', { uploads: [upload], rate });

export const country = schema('countries', {}, { idAttribute: 'code' });

export const hotel = schema(
  'hotels',
  {
    countryCode: country,
    accommodationProducts: [product],
    transferProducts: [product],
    groundServiceProducts: [product],
    fineProducts: [product],
    supplementProducts: [product],
    uploads: [upload],
    photos: [upload],
    featuredPhotos: [upload],
    brochures: [upload],
  },
  {
    processStrategy: x => ({
      ...x,
      photos: uploadsByTag(x.uploads, 'photo'),
      featuredPhotos: uploadsByTag(x.uploads, 'featuredPhoto'),
      brochures: uploadsByTag(x.uploads, 'brochure'),
    }),
  }
);
