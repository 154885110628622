import React from 'react';
import classnames from 'classnames';

export enum ERefundabilityTagStatus {
  REFUNDABLE,
  NON_REFUNDABLE,
}

export interface IRefundabilityTagProps {
  className: string;
  refundabilityStatus: ERefundabilityTagStatus;
}

export const RefundabilityTag = (props: IRefundabilityTagProps) => {
  const { className, refundabilityStatus } = props;
  return (
    <span
      className={classnames(
        `${className} font-hurmegeometric-sans text-xs leading-14px tracking-2xs rounded-2px not-italic font-normal my-5px`,
        {
          'text-green-message': refundabilityStatus === ERefundabilityTagStatus.REFUNDABLE,
          'text-status-requested': refundabilityStatus === ERefundabilityTagStatus.NON_REFUNDABLE,
        }
      )}
    >
      {refundabilityStatus === ERefundabilityTagStatus.REFUNDABLE ? 'Refundable' : 'Non-Refundable'}
    </span>
  );
};
