import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { makeBookingManagerApi } from 'services/BookingManagerApi';

import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import * as DashboardSelectors from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import { TCountryCode } from 'interfaces';
import { isNil } from 'lodash-es';
import { IHeadlineLineItemBreakdownComponent } from '../../ui/HeadlineLineItemBreakdown';
import { IMultiselectValueLabelPair } from 'ui/Multiselect';
import { ENetworkRequestStatus, IGuestAges } from 'services/BackendApi';
import {
  AncillaryFilter,
  EAncillaryLocationType,
  AncillaryProductWithRatesWithUserData,
  AncillaryRatedResult,
  LocationDirectionWithCountry,
  LocationFromSearchLocationsWithType,
  isAncillaryRatedResultPerUnit,
  EAncillaryProductType,
  SelectedAncillarySearchResult,
  isAncillaryRatedResult,
} from 'services/BookingManagerApi/types/AncillaryService';
import { AddGroundServiceModal } from 'ui/AddGroundServiceModal/AddGroundServiceModal';
import { formatDateDisplay, getCurrencyBySymbol } from 'utils';
import {
  generateBreakdownItemDataForAncillaryProduct,
  getErrorIdsMissingFlexibleAttributes,
} from 'ui/AddAncillarySharedComponents/utils';

export interface IAddGroundServiceModalContainerProps {
  bookingUuid: string;
  bookingCurrencySymbol: IHeadlineLineItemBreakdownComponent['bookingCurrency'];
}

export const AddGroundServiceModalContainer = (props: IAddGroundServiceModalContainerProps) => {
  const isGroundServiceModalOpen = useSelector(BreakdownSelectors.breakdownAncillaryGroundServiceModalToggleSelector);
  const dispatch = useDispatch();
  const topNavigationData = useSelector(DashboardSelectors.topNavigationDataDashboardSelector);
  const bookingManagerApi = makeBookingManagerApi();
  const existingCancellationPolicies = useSelector(BreakdownSelectors.cancellationPoliciesSelector);
  const existingPaymentTerms = useSelector(BreakdownSelectors.paymentTermsSelector);

  const currencyData = getCurrencyBySymbol(props.bookingCurrencySymbol!);
  const currencyCode = currencyData?.code;

  const [airport, setAirport] = React.useState<LocationFromSearchLocationsWithType | null>(null);
  const [locations, setLocations] = React.useState<LocationFromSearchLocationsWithType[]>([]);

  const [date, setDate] = React.useState<string | null>(null);

  const [filters, setFilters] = useState<AncillaryFilter[]>([]);
  const [selectedFilterIds, setSelectedFilterIds] = useState<string[]>(['-1']); // defaults to -1 to represent "All"
  const [selectedSearchResult, setSelectedSearchResult] = useState<SelectedAncillarySearchResult | null>(null);

  const [guestAges, setGuestAges] = useState<IGuestAges>({
    numberOfAdults: topNavigationData?.guestCount! - topNavigationData!.childrenAges.length,
    agesOfAllChildren: topNavigationData!.childrenAges,
  });

  const [selectedGroundServiceOrSupplier, setSelectedTransferOrSupplier] = useState<{
    id: string;
    type: 'ground_service' | 'supplier';
  } | null>(null);

  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [errorIds, setErrorIds] = useState<string[]>([]);

  // the pickup time on the right hand panel
  const [pickupTime, setPickupTime] = useState<string>('');

  const [getSearchResultsNetworkStatus, setGetSearchResultsNetworkStatus] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [getFiltersNetworkStatus, setGetFiltersNetworkStatus] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [getTransferProductNetwork, setGetTransferProductNetwork] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );

  const [getLocationsNetwork, setGetLocationsNetwork] = useState<ENetworkRequestStatus>(ENetworkRequestStatus.IDLE);

  // we need state variables for the given instance of a search
  const [searchInstanceVars, setSearchInstanceVars] = useState<{
    date?: string | null;
    guestAges?: IGuestAges;
    airport?: LocationFromSearchLocationsWithType | null;
  }>({});

  // this is the one that comes down from the API, and is the one that we'll be updating
  const [
    selectedGroundServiceProduct,
    setSelectedGroundServiceProduct,
  ] = useState<AncillaryProductWithRatesWithUserData | null>(null);

  // we mount open modal, we need to get the locations that are going to power the pickup and dropoff
  useEffect(() => {
    async function fetchLocations() {
      setGetLocationsNetwork(ENetworkRequestStatus.PENDING);
      try {
        const c = await bookingManagerApi.postAncillaryLocationsSearch(EAncillaryProductType.GROUND_SERVICE, [
          EAncillaryLocationType.AIRPORT,
        ]);
        setLocations([
          ...c.data.airports.map(x => ({
            ...x,
            compositeId: `${x.id}::${EAncillaryLocationType.AIRPORT}`,
            type: EAncillaryLocationType.AIRPORT,
          })),
        ]);
        setGetLocationsNetwork(ENetworkRequestStatus.SUCCESS);
      } catch (e) {
        setLocations([]);
        setGetLocationsNetwork(ENetworkRequestStatus.ERROR);
      }
    }
    if (isGroundServiceModalOpen) {
      fetchLocations();
    }
  }, [isGroundServiceModalOpen]);

  // when we change the pickup or drop off location, get new filter ids
  useEffect(() => {
    async function fetchFilterCodes() {
      if (isNil(airport)) {
        return;
      }
      setGetFiltersNetworkStatus(ENetworkRequestStatus.PENDING);
      const countryCodes: TCountryCode[] = [];
      if (airport) {
        countryCodes.push(airport.countryCode);
      }
      try {
        const r = await bookingManagerApi.getAncillaryGroundServicesFilterIds({
          currencyCode,
          countryCodes,
        });
        setFilters(r.data);
        setGetFiltersNetworkStatus(ENetworkRequestStatus.SUCCESS);
      } catch (e) {
        setFilters([]);
        setGetFiltersNetworkStatus(ENetworkRequestStatus.ERROR);
      }
    }

    fetchFilterCodes();
  }, [airport]);

  const handleSearchButtonClick = async () => {
    setGetSearchResultsNetworkStatus(ENetworkRequestStatus.PENDING);
    setSelectedSearchResult(null);
    if (isNil(date) || isNil(airport)) {
      setGetSearchResultsNetworkStatus(ENetworkRequestStatus.IDLE);
      setSelectedSearchResult(null);
      return;
    }

    try {
      const searchResults = await bookingManagerApi.getAncillaryGroundServicesSearchResults({
        bookingUuid: props.bookingUuid,
        date: date,
        numberOfAdults: guestAges.numberOfAdults,
        agesOfAllChildren: guestAges.agesOfAllChildren,
        airportId: airport.id,
        filterValueIds: selectedFilterIds.map(f => parseInt(f)),
        supplierId:
          selectedGroundServiceOrSupplier?.type === 'supplier'
            ? parseInt(selectedGroundServiceOrSupplier.id)
            : undefined,
        baseProductId:
          selectedGroundServiceOrSupplier?.type === 'ground_service'
            ? parseInt(selectedGroundServiceOrSupplier.id)
            : undefined,
      });

      setGetSearchResultsNetworkStatus(ENetworkRequestStatus.SUCCESS);
      setSearchResults(searchResults.data.results);
      setSearchInstanceVars({
        date,
        guestAges,
        airport,
      });
    } catch (e) {
      setGetSearchResultsNetworkStatus(ENetworkRequestStatus.ERROR);
      setSearchResults([]);
    }
  };

  const handleCloseModal = () => {
    dispatch(BreakdownActions.setAddAncillaryGroundServiceModalToggleAction(false));
    // also reset all the data
    setDate(null);
    setAirport(null);
    setSelectedFilterIds(['-1']);
    setSelectedTransferOrSupplier(null);
    setSearchResults([]);
    setSelectedSearchResult(null);
    setGetSearchResultsNetworkStatus(ENetworkRequestStatus.IDLE);
    setPickupTime('');
    setGuestAges({
      numberOfAdults: topNavigationData?.guestCount! - topNavigationData!.childrenAges.length,
      agesOfAllChildren: topNavigationData!.childrenAges,
    });
    setErrorIds([]);
    setSelectedGroundServiceProduct(null);
    setGetTransferProductNetwork(ENetworkRequestStatus.IDLE);
  };

  const handleSetSelectedActivityOrSupplier = d => {
    if (isNil(d)) {
      setSelectedTransferOrSupplier(null);
      return;
    }
    setSelectedTransferOrSupplier({ id: d.id, type: d.type });
  };

  const handleGroundServiceOrSupplierSelectOnLookup = async (searchTerm: string, signal: AbortSignal) => {
    if (isNil(airport) || isNil(date)) {
      return [];
    }
    const as = await bookingManagerApi.getAncillaryGroundServiceOrSupplier({
      currencyCode,
      searchTerm,
      signal,
      date,
      countryCodes: [airport.countryCode as TCountryCode],
    });

    const options: IMultiselectValueLabelPair[] = [];

    as.data.products.forEach(p => {
      options.push({ value: `activity-${p.id.toString()}`, label: p.name });
    });

    as.data.suppliers.forEach(s => {
      options.push({ value: `supplier-${s.id.toString()}`, label: s.name });
    });

    return options;
  };

  // retrieves the activity product for the current state
  // and updates the selectedActivityProduct state as well as the
  // network state variables
  const retrieveAndUpdateSelectedProduct = async (
    selectedSearchResult: SelectedAncillarySearchResult,
    perUnitQuantityOverride?: number,
    retainUserData?: boolean
  ) => {
    if (
      isNil(searchInstanceVars.date) ||
      isNil(selectedSearchResult) ||
      isNil(searchInstanceVars.airport) ||
      isNil(searchInstanceVars.guestAges)
    ) {
      return;
    }

    setGetTransferProductNetwork(ENetworkRequestStatus.PENDING);

    try {
      const t = await bookingManagerApi.getAncillaryRatesProduct({
        bookingUuid: props.bookingUuid,
        dates: [searchInstanceVars.date],
        numberOfAdults: searchInstanceVars.guestAges.numberOfAdults,
        agesOfAllChildren: searchInstanceVars.guestAges.agesOfAllChildren,
        baseProductId: parseInt(selectedSearchResult!.baseProductId),
        variantId: selectedSearchResult!.variantId ? parseInt(selectedSearchResult!.variantId) : undefined,
        perUnitQuantityOverride,
        airportId: searchInstanceVars.airport.id,
      });

      // the selected rate is:
      //  if we already have a selected product, its the one with the same date
      // if we dont, its the first one that has a rate
      let selectedRateDateIndex = selectedGroundServiceProduct
        ? Object.values(t.data.rates).findIndex(r => r.date === selectedGroundServiceProduct!.userData?.rate?.date)
        : Object.values(t.data.rates).findIndex(r => r.hasRateForDate);

      // if, after that, we still dont have a selectedRateDateIndex
      // (probably because we already had a product, but we couldn't get a matching new rate)
      // then just select the first one that does have a rate after all
      if (selectedRateDateIndex === -1) {
        selectedRateDateIndex = Object.values(t.data.rates).findIndex(r => r.hasRateForDate);
      }

      const selectedRate = Object.values(t.data.rates)[selectedRateDateIndex] as AncillaryRatedResult;

      const originalUserData = selectedGroundServiceProduct?.userData;

      if (retainUserData && originalUserData) {
        setSelectedGroundServiceProduct({
          product: t.data.product,
          rates: t.data.rates,
          userData: {
            ...originalUserData,

            // this is "set unit count to perUnitQuantityOverride if we have it...
            // ...if we dont, set it to the rates quantity; if we dont have THAT,
            // ...set it to undefined"
            unitCount: perUnitQuantityOverride
              ? perUnitQuantityOverride
              : isAncillaryRatedResultPerUnit(selectedRate)
              ? selectedRate.quantity
              : undefined,

            // update the rate, but keep the original selected date
            rate: selectedRate,
          },
        });
      } else {
        setSelectedGroundServiceProduct({
          product: t.data.product,
          rates: t.data.rates,
          userData: {
            rate: selectedRate,
            optionSelections: {},
            unitCount: isAncillaryRatedResultPerUnit(selectedRate) ? selectedRate.quantity : undefined,
          },
        });
      }
      setGetTransferProductNetwork(ENetworkRequestStatus.SUCCESS);
    } catch (e) {
      setSelectedGroundServiceProduct(null);
      setGetTransferProductNetwork(ENetworkRequestStatus.ERROR);
    }
  };

  const handleAddGroundService = () => {
    if (isNil(selectedGroundServiceProduct)) {
      return;
    }

    if (!isAncillaryRatedResult(selectedGroundServiceProduct.userData.rate!)) {
      return;
    }

    const newErrorIds = getErrorIdsMissingFlexibleAttributes(selectedGroundServiceProduct);

    if (!searchInstanceVars.airport || !searchInstanceVars.airport?.id) {
      newErrorIds.push('airport');
    }
    setErrorIds(newErrorIds);

    if (newErrorIds.length > 0) {
      return;
    }

    const {
      title,
      tertiaryText,
      purchaseCostCents,
      saleCostCents,
      flexibleAttributesNonValidatedSelections,
    } = generateBreakdownItemDataForAncillaryProduct(selectedGroundServiceProduct, guestAges, {
      pickupTime,
      airport: searchInstanceVars.airport,
    });

    dispatch(
      BreakdownActions.addHeadlineLineItemAction(
        'Ground Service.items',
        {
          title,
          tertiaryText,
          saleCostCents,
          purchaseCostCents,
        },
        {
          isNew: true,
          product: selectedGroundServiceProduct.product,
          rateForDate: selectedGroundServiceProduct.userData.rate!,
          userSelections: {
            selectedDate: selectedGroundServiceProduct.userData.rate!.date,
            numberOfAdults: searchInstanceVars.guestAges!.numberOfAdults,
            agesOfAllChildren: searchInstanceVars.guestAges!.agesOfAllChildren,
            baseProductId: selectedGroundServiceProduct.product.baseProductId,
            variantId: !isNil(selectedGroundServiceProduct.product.variantId)
              ? selectedGroundServiceProduct.product.variantId
              : undefined,
            perUnitQuantityOverride: selectedGroundServiceProduct.userData.unitCount,
            flexibleAttributesNonValidatedSelections,
            selectionHash: selectedGroundServiceProduct.userData.rate!.hash,
            time: pickupTime,
            airportId: searchInstanceVars.airport?.id,
          },
        }
      )
    );

    dispatch(
      BreakdownActions.setCancellationPoliciesAction(`${
        isNil(existingCancellationPolicies) ? '' : existingCancellationPolicies
      }${
        isNil(existingCancellationPolicies) || existingCancellationPolicies === '' ? '' : '\n\n'
      }Ground Service: ${title}
${selectedGroundServiceProduct.userData.rate.rateCancellationPolicy}`)
    );
    dispatch(
      BreakdownActions.setPaymentTermsAction(`${isNil(existingPaymentTerms) ? '' : existingPaymentTerms}${
        isNil(existingPaymentTerms) || existingPaymentTerms === '' ? '' : '\n\n'
      }Ground Service: ${title}
${selectedGroundServiceProduct.userData.rate.ratePaymentTerms}`)
    );

    handleCloseModal();
  };

  const handleSelectSearchResult = async (selectedSearchResult: SelectedAncillarySearchResult | null) => {
    if (isNil(selectedSearchResult)) {
      setSelectedGroundServiceProduct(null);
      return;
    }
    setSelectedSearchResult(selectedSearchResult);
    await retrieveAndUpdateSelectedProduct(selectedSearchResult);
  };

  const handleUpdateGroundServiceProductWithUserData = async (
    data: Partial<AncillaryProductWithRatesWithUserData['userData']>
  ) => {
    setSelectedGroundServiceProduct({
      ...selectedGroundServiceProduct!,
      userData: {
        ...selectedGroundServiceProduct!.userData,
        ...data,
      },
    });

    // if they've just updated the unit count...
    if (!isNil(data['unitCount']) && !isNil(selectedSearchResult)) {
      // ...retrieve the product again
      await retrieveAndUpdateSelectedProduct(selectedSearchResult, data['unitCount'], true);
    }
  };

  return (
    <AddGroundServiceModal
      isOpen={isGroundServiceModalOpen}
      currencySymbol={props.bookingCurrencySymbol!}
      onClose={handleCloseModal}
      // pickup from and dropoff stuff
      airport={airport}
      handleSelectAirport={(airpotLocationCompositeId: string) => {
        const location = locations.find(location => location.compositeId === airpotLocationCompositeId);
        if (isNil(location)) {
          setAirport(null);
          return;
        }
        setAirport({
          id: location.id,
          compositeId: airpotLocationCompositeId,
          type: location.type,
          countryCode: location.countryCode,
          iata_code: location.iata_code,
          name: location.name,
        });
      }}
      locationOptions={locations.map(l => {
        return {
          value: l.compositeId,
          label: `${l.name}${l.iata_code ? ` (${l.iata_code})` : ''}`,
        };
      })}
      // date stuff
      date={date}
      setDate={setDate}
      //
      getSearchResultsNetwork={getSearchResultsNetworkStatus}
      onSearchButtonClick={handleSearchButtonClick}
      searchResults={searchResults}
      selectedSearchResult={selectedSearchResult}
      setSelectedSearchResult={(selectedSearchResult: SelectedAncillarySearchResult | null) => {
        handleSelectSearchResult(selectedSearchResult);
      }}
      typesOptions={filters.map(f => {
        return {
          value: f.id.toString(),
          label: f.value,
        };
      })}
      selectedFilterIds={selectedFilterIds}
      setSelectedFilterIds={setSelectedFilterIds}
      selectedGroundServiceOrSupplier={selectedGroundServiceOrSupplier}
      setSelectedGroundServiceOrSupplier={handleSetSelectedActivityOrSupplier}
      groundServiceOrSupplierSelectOnLookup={handleGroundServiceOrSupplierSelectOnLookup}
      guestAges={guestAges}
      searchInstanceVars={searchInstanceVars}
      setGuestAges={(a, b) => {
        setGuestAges({ numberOfAdults: a, agesOfAllChildren: b ? b : [] });
      }}
      getTypesResultsNetworkStatus={getFiltersNetworkStatus}
      errorIds={errorIds}
      handleAddGroundService={handleAddGroundService}
      selectedGroundServiceProduct={selectedGroundServiceProduct}
      updateSelectedGroundServiceProductWithUserData={handleUpdateGroundServiceProductWithUserData}
      getGroundServiceProductNetwork={getTransferProductNetwork}
      pickupTime={pickupTime}
      setPickupTime={setPickupTime}
      getLocationsNetwork={getLocationsNetwork}
    />
  );
};
