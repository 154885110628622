// todo this needs to be the unit counter from the current right hand so that it can be used in the edit as well

import classNames from 'classnames';
import React from 'react';
import {
  AncillaryProductWithRatesWithUserData,
  isAncillaryRatedResultPerUnit,
} from 'services/BookingManagerApi/types/AncillaryService';
import { useDynamicParameters } from 'hooks/useDynamicParameters';

export interface IAddAncillaryRightHandPanelUnitCounterProps {
  selectedProduct: AncillaryProductWithRatesWithUserData;
  updateSelectedProductWithUserData: (data: Partial<AncillaryProductWithRatesWithUserData['userData']>) => void;
}

const GA_FLOW_TAG = 'act-modal';

export const AddAncillaryRightHandPanelUnitCounter = (props: IAddAncillaryRightHandPanelUnitCounterProps) => {
  const { dynamicParameters } = useDynamicParameters();

  return (
    <div>
      {props.selectedProduct?.userData?.rate && isAncillaryRatedResultPerUnit(props.selectedProduct?.userData?.rate) && (
        <div className="units-wrapper flex items-center justify-between">
          <span className="text-gray-100 uppercase text-xs leading-16px">Confirm Units</span>
          <span className="flex items-center justify-around space-x-6px">
            <span
              onClick={() => {
                if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
                  // @ts-ignore
                  window.gtag('event', `${GA_FLOW_TAG}_change-units`, {
                    'selected-units': props.selectedProduct!.userData.unitCount!,
                  });
                }
                props.updateSelectedProductWithUserData({
                  unitCount: props.selectedProduct!.userData.unitCount! - 1,
                });
              }}
              className={classNames(
                'font-mono cursor-pointer bg-brown-100 rounded-full text-white w-6 h-6 text-[20px] text-center leading-[1.15em]',
                {
                  'opacity-50 pointer-events-none':
                    props.selectedProduct!.userData.rate?.minimumUnitQuantity ===
                    props.selectedProduct!.userData.unitCount,
                }
              )}
            >
              -
            </span>
            <span>{props.selectedProduct!.userData.unitCount}</span>
            <span
              onClick={() => {
                if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
                  // @ts-ignore
                  window.gtag('event', `${GA_FLOW_TAG}_change-units`, {
                    'selected-units': props.selectedProduct!.userData.unitCount!,
                  });
                }
                props.updateSelectedProductWithUserData({
                  unitCount: props.selectedProduct!.userData.unitCount! + 1,
                });
              }}
              className="font-mono cursor-pointer bg-brown-100 rounded-full text-white w-6 h-6 text-[20px] text-center leading-[1.15em]"
            >
              +
            </span>
          </span>
        </div>
      )}
    </div>
  );
};
