import React from 'react';
import { HotelContainer } from 'containers';

export interface IBookingBuilderV1Props {
  match: {
    params: {
      id: string;
    };
  };
}

export const BookingBuilderV1 = (props: IBookingBuilderV1Props) => {
  return <HotelContainer id={props.match.params.id} />;
};

export default BookingBuilderV1;
