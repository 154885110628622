import { ENetworkRequestStatus } from 'services/BackendApi';
import * as Actions from './actions';
import { ITravelAgentListDomain, initialState } from './model';
import { update } from 'utils/immutable';
import { EMassAssignTaSrMode } from 'containers/TravelAgents/List/MassAssignModal';

export const travelAgentListReducer = (
  state: ITravelAgentListDomain = initialState,
  action: Actions.TravelAgentListAction
): ITravelAgentListDomain => {
  switch (action.type) {
    case Actions.GET_REQUEST:
      return update(state, s => {
        s.request.get = ENetworkRequestStatus.PENDING;
      });

    case Actions.GET_SUCCESS:
      return update(state, s => {
        s.request.get = ENetworkRequestStatus.SUCCESS;

        s.list = action.travelAgents;
        s.pagination.total = action.total;
      });

    case Actions.GET_FAILURE:
      return update(state, s => (s.request.get = ENetworkRequestStatus.ERROR));

    case Actions.CSV_REQUEST:
      return update(state, s => (s.request.csv = ENetworkRequestStatus.PENDING));

    case Actions.CSV_SUCCESS:
      return update(state, s => (s.request.csv = ENetworkRequestStatus.SUCCESS));

    case Actions.CSV_FAILURE:
      return update(state, s => (s.request.csv = ENetworkRequestStatus.ERROR));

    case Actions.DELETE_REQUEST:
      return update(state, s => (s.request.delete = ENetworkRequestStatus.PENDING));

    case Actions.DELETE_SUCCESS:
      return update(state, s => {
        s.request.delete = ENetworkRequestStatus.SUCCESS;
        s.selectedTravelAgent.toDelete = null;
      });

    case Actions.DELETE_FAILURE:
      return update(state, s => {
        s.request.delete = ENetworkRequestStatus.ERROR;
      });

    case Actions.SET_SEARCH_FILTER:
      return update(state, s => {
        s.pagination.page = 0;
        s.filter.search = action.value;
      });

    case Actions.SET_EMAIL_FILTER:
      return update(state, s => {
        s.pagination.page = 0;
        s.filter.email = action.value;
      });

    case Actions.SET_COUNTRY_CODE_FILTER:
      return update(state, s => {
        s.pagination.page = 0;
        s.filter.countryCode = action.value;
        s.request.get = ENetworkRequestStatus.PENDING;
      });

    case Actions.SET_COMPANY_UUID_FILTER:
      return update(state, s => {
        s.pagination.page = 0;
        s.filter.companyUuid = action.value;
        s.request.get = ENetworkRequestStatus.PENDING;
      });

    case Actions.SET_STATUS_FILTER:
      return update(state, s => {
        s.pagination.page = 0;
        s.filter.status = action.value;
        s.request.get = ENetworkRequestStatus.PENDING;
      });

    case Actions.SET_CREATED_BY_FILTER:
      return update(state, s => {
        s.pagination.page = 0;
        s.filter.createdBy = action.value;
        s.request.get = ENetworkRequestStatus.PENDING;
      });

    case Actions.SET_SORT_ORDER:
      return update(state, s => {
        s.pagination.page = 0;
        s.sort.order = action.value;
        s.request.get = ENetworkRequestStatus.PENDING;
      });

    case Actions.SET_PAGE:
      return update(state, s => {
        s.pagination.page = action.value;
      });

    case Actions.SET_PER_PAGE:
      return update(state, s => {
        s.pagination.page = 0;
        s.pagination.perPage = action.value;
      });

    case Actions.SET_TO_DELETE:
      return update(state, s => {
        s.selectedTravelAgent.toDelete = action.value;
      });

    case Actions.SET_MASS_ASSIGN_MODAL_VISIBLE:
      return update(state, s => {
        s.massAssign.massAssignModalVisible = action.visible;
      });

    case Actions.SET_MASS_ASSIGN_CONFIRM_MODAL_VISIBLE:
      return update(state, s => {
        s.massAssign.massAssignConfirmModalVisible = action.visible;
      });

    case Actions.SET_MASS_ASSIGN_MODE:
      return update(state, s => {
        s.massAssign.massAssignMode = action.mode;
      });

    case Actions.SET_MASS_ASSIGN_SRS:
      return update(state, s => {
        s.massAssign.selectedSrUuids = action.srs;
      });

    case Actions.INIT_MASS_ASSIGN:
      return update(state, s => {
        s.request.massAssign = ENetworkRequestStatus.IDLE;
        s.massAssign.massAssignMode = EMassAssignTaSrMode.ADD;
        s.massAssign.selectedSrUuids = [];
      });

    case Actions.GET_SALES_REPS_SUCCESS:
      return update(state, s => {
        s.salesReps = action.salesReps;
      });

    case Actions.SET_SALES_REP_FILTER:
      return update(state, s => {
        s.pagination.page = 0;
        s.filter.salesRepUuid = action.salesRepUuid;
      });

    case Actions.MASS_ASSIGN_REQUEST:
      return update(state, s => {
        s.request.massAssign = ENetworkRequestStatus.PENDING;
      });

    case Actions.MASS_ASSIGN_SUCCESS:
      return update(state, s => {
        s.request.massAssign = ENetworkRequestStatus.SUCCESS;
        s.massAssign.massAssignModalVisible = false;
        s.massAssign.massAssignConfirmModalVisible = false;
        s.massAssign.massAssignMode = EMassAssignTaSrMode.ADD;
        s.massAssign.selectedSrUuids = [];
      });

    case Actions.MASS_ASSIGN_FAILURE:
      return update(state, s => {
        s.request.massAssign = ENetworkRequestStatus.ERROR;
        s.massAssign.massAssignModalVisible = true;
        s.massAssign.massAssignConfirmModalVisible = false;
      });

    case Actions.TA_HAS_BOOKINGS_REQUEST:
      return update(state, s => {
        s.request.taHasBookings = ENetworkRequestStatus.PENDING;
      });

    case Actions.TA_HAS_BOOKINGS_SUCCESS:
      return update(state, s => {
        s.request.taHasBookings = ENetworkRequestStatus.SUCCESS;
        s.selectedTravelAgent.hasBookings = action.hasBookings;
      });
      
    case Actions.TA_HAS_BOOKINGS_FAILURE:
      return update(state, s => {
        s.request.taHasBookings = ENetworkRequestStatus.ERROR;
      });
  
    default:
      return state;
  }
};

export default travelAgentListReducer;
