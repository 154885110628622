export type StringOrNumber = string | number;

export const PAGINATION_DOTS = '...';

const MAX_PAGES_TO_SHOW = 10;
const PAGES_TO_SHOW_OFFSET = 2;
const LEFT_PAGES_TO_SHOW = 3;
const RIGHT_PAGES_TO_SHOW = 3;

export const pageRange = (begin: number, end: number): number[] => {
  const list: number[] = [];
  for (let i = begin; i <= end; i++) {
    list.push(i);
  }
  return list;
};

export const usePageNumbers = (pageCount, currentPage) => {
  const leftPagesQty = currentPage - 1;
  const rightPagesQty = pageCount - currentPage;
  let leftPages: StringOrNumber[] = [];
  if (leftPagesQty <= MAX_PAGES_TO_SHOW / 2 || leftPagesQty + rightPagesQty <= MAX_PAGES_TO_SHOW) {
    leftPages = pageRange(1, currentPage - 1);
  } else {
    leftPages = [1, PAGINATION_DOTS, ...pageRange(Math.min(currentPage - LEFT_PAGES_TO_SHOW, currentPage - MAX_PAGES_TO_SHOW + PAGES_TO_SHOW_OFFSET + rightPagesQty), currentPage - 1)];
  }

  let rightPages: StringOrNumber[] = [];
  if (rightPagesQty <= MAX_PAGES_TO_SHOW / 2 || leftPagesQty + rightPagesQty <= MAX_PAGES_TO_SHOW) {
    rightPages = pageRange(currentPage + 1, pageCount);
  } else {
    rightPages = [...pageRange(currentPage + 1, Math.max(currentPage + RIGHT_PAGES_TO_SHOW, currentPage + MAX_PAGES_TO_SHOW - PAGES_TO_SHOW_OFFSET - leftPagesQty)), PAGINATION_DOTS, pageCount];
  }
  const pageNumbers = [...leftPages, currentPage, ...rightPages];
  return [pageNumbers];
};
