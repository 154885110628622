import { ENetworkRequestStatus, IHotel } from 'services/BackendApi';

export interface IHotelSubdomain {
  hotel?: IHotel;
  errorMessage?: string;
  networkRequests: {
    hotelLoad: ENetworkRequestStatus,
  }
}

export const hotelInitialState: IHotelSubdomain = {
  hotel: undefined,
  errorMessage: undefined,
  networkRequests: {
    hotelLoad: ENetworkRequestStatus.IDLE,
  },
};
