import * as Actions from './actions';
import { ICompanyMembershipsDomain, initialState } from './model';
import { ENetworkRequestStatus } from 'services/BackendApi';

export const companyMembershipsReducer = (
  state: ICompanyMembershipsDomain = initialState,
  action: Actions.CompanyMembershipsAction
): ICompanyMembershipsDomain => {
  switch (action.type) {
    case Actions.FETCH_COMPANY_MEMBERSHIPS_REQUEST:
      return {
        ...state,
        memberships: null,
        requests: {
          ...state.requests,
          membershipListLoad: ENetworkRequestStatus.PENDING,
        },
      };

    case Actions.FETCH_COMPANY_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        memberships: action.memberships,
        requests: {
          ...state.requests,
          membershipListLoad: ENetworkRequestStatus.SUCCESS,
        },
      };

    case Actions.FETCH_COMPANY_MEMBERSHIPS_FAILURE:
      return {
        ...state,
        memberships: [],
        requests: {
          ...state.requests,
          membershipListLoad: ENetworkRequestStatus.ERROR,
        },
      };

    case Actions.RESET_COMPANY_MEMBERSHIPS:
      return {
        ...state,
        memberships: null,
      };

    case Actions.CREATE_COMPANY_MEMBERSHIP_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          membershipSave: ENetworkRequestStatus.PENDING,
        },
      };

    case Actions.CREATE_COMPANY_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        modals: {
          ...state.modals,
          isMembershipModalOpen: false,
        },
        requests: {
          ...state.requests,
          membershipSave: ENetworkRequestStatus.SUCCESS,
        },
      };

    case Actions.CREATE_COMPANY_MEMBERSHIP_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          membershipSave: ENetworkRequestStatus.ERROR,
        },
      };

    case Actions.EDIT_COMPANY_MEMBERSHIP_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          membershipSave: ENetworkRequestStatus.PENDING,
        },
      };

    case Actions.EDIT_COMPANY_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        membershipToEdit: null,
        modals: {
          ...state.modals,
          isMembershipModalOpen: false,
        },
        requests: {
          ...state.requests,
          membershipSave: ENetworkRequestStatus.SUCCESS,
        },
      };

    case Actions.EDIT_COMPANY_MEMBERSHIP_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          membershipSave: ENetworkRequestStatus.ERROR,
        },
      };

    case Actions.DELETE_COMPANY_MEMBERSHIP_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          membershipDelete: ENetworkRequestStatus.PENDING,
        },
      };

    case Actions.DELETE_COMPANY_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        uuidToDelete: null,
        requests: {
          ...state.requests,
          membershipDelete: ENetworkRequestStatus.SUCCESS,
        },
      };

    case Actions.DELETE_COMPANY_MEMBERSHIP_FAILURE:
      return {
        ...state,
        requests: {
          ...state.requests,
          membershipDelete: ENetworkRequestStatus.ERROR,
        },
      };

    case Actions.SET_UUID_TO_DELETE:
      return {
        ...state,
        uuidToDelete: action.uuidToDelete,
      };

    case Actions.SET_MEMBERSHIP_TO_EDIT:
      return {
        ...state,
        membershipToEdit: action.membership,
      };

    case Actions.OPEN_CREATE_MEMBERSHIP_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          isMembershipModalOpen: true,
        },
      };

    case Actions.CLOSE_CREATE_MEMBERSHIP_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          isMembershipModalOpen: false,
        },
      };

    default:
      return state;
  }
};

export default companyMembershipsReducer;
