import { TCountryCode } from 'interfaces';
import { ENetworkRequestStatus, ICompany, IUploadFileInfo } from 'services/BackendApi';
import { IBootstrapHotel } from '../bootstrap/model';
import { ICompanyLogoPosition } from './model';
import { History } from 'history';
import { ILedgerTotals } from '../ledger/model';

export const FETCH_COMPANY_LOGO = 'companyInfo/FETCH_COMPANY_LOGO';
export const FETCH_COMPANY_LOGO_SUCCESS = 'companyInfo/FETCH_COMPANY_LOGO_SUCCESS';
export const FETCH_COMPANY_LOGO_FAILURE = 'companyInfo/FETCH_COMPANY_LOGO_FAILURE';
export const UPDATE_COMPANY_LOGO_POSITION = 'companyInfo/UPDATE_COMPANY_LOGO_POSITION';
export const UPDATE_COMPANY_LOGO_POSITION_SUCCESS = 'companyInfo/UPDATE_COMPANY_LOGO_POSITION_SUCCESS';
export const UPDATE_COMPANY_LOGO = 'companyInfo/UPDATE_COMPANY_LOGO';
export const REMOVE_COMPANY_LOGO = 'companyInfo/REMOVE_COMPANY_LOGO';
export const REMOVE_COMPANY_LOGO_SUCCESS = 'companyInfo/REMOVE_COMPANY_LOGO_SUCCESS';
export const FETCH_COMPANY_DATA_REQUEST = 'companyInfo/FETCH_COMPANY_DATA_REQUEST';
export const FETCH_COMPANY_DATA_SUCCESS = 'companyInfo/FETCH_COMPANY_DATA_SUCCESS';
export const FETCH_COMPANY_DATA_FAILURE = 'companyInfo/FETCH_COMPANY_DATA_FAILURE';
export const UPDATE_COMPANY_REQUEST = 'companyInfo/UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'companyInfo/UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = 'companyInfo/UPDATE_COMPANY_FAILURE';
export const PATCH_COMPANY_REQUEST = 'companyInfo/PATCH_COMPANY_REQUEST';
export const PATCH_COMPANY_SUCCESS = 'companyInfo/PATCH_COMPANY_SUCCESS';
export const PATCH_COMPANY_FAILURE = 'companyInfo/PATCH_COMPANY_FAILURE';
export const CREATE_COMPANY_REQUEST = 'companyInfo/CREATE_COMPANY_REQUEST';
export const CREATE_COMPANY_SUCCESS = 'companyInfo/CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAILURE = 'companyInfo/CREATE_COMPANY_FAILURE';
export const INIT_COMPANY_SAVE_STATUS = 'companyInfo/INIT_COMPANY_SAVE_STATUS';
export const FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_REQUEST = 'companyInfo/FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_REQUEST';
export const FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_SUCCESS = 'companyInfo/FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_SUCCESS';
export const FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_FAILURE = 'companyInfo/FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_FAILURE';

export const SET_COMPANY_LOGO_NETWORK_POST_LOGO = 'companyInfo/SET_COMPANY_LOGO_NETWORK_POST_LOGO';
export const SET_COMPANY_LOGO_NETWORK_DELETE_LOGO = 'companyInfo/SET_COMPANY_LOGO_NETWORK_DELETE_LOGO';

// hotel list stuff
export const SET_ACTIVE_HOTEL = 'companyInfo/SET_ACTIVE_HOTEL';
export const SET_ACTIVE_DESTINATION = 'companyInfo/SET_ACTIVE_DESTINATION';

// hotel list network
export const GET_HOTEL_LIST_DATA_REQUEST = 'companyInfo/GET_HOTEL_LIST_DATA_REQUEST';
export const GET_HOTEL_LIST_DATA_SUCCESS = 'companyInfo/GET_HOTEL_LIST_DATA_SUCCESS';
export const GET_HOTEL_LIST_DATA_FAILURE = 'companyInfo/GET_HOTEL_LIST_DATA_FAILURE';

export const POST_HOTEL_LIST_DATA_REQUEST = 'companyInfo/POST_HOTEL_LIST_DATA_REQUEST';
export const POST_HOTEL_LIST_DATA_SUCCESS = 'companyInfo/POST_HOTEL_LIST_DATA_SUCCESS';
export const POST_HOTEL_LIST_DATA_FAILURE = 'companyInfo/POST_HOTEL_LIST_DATA_FAILURE';

export const SET_TRAVEL_COMPANY_EDIT_MODE = 'companyInfo/SET_TRAVEL_COMPANY_EDIT_MODE';

// extra data that is set against a company
export const UPDATE_COMPANY_EXTERNAL_MARKUP_PERCENTAGE = 'companyInfo/UPDATE_COMPANY_EXTERNAL_MARKUP_PERCENTAGE';

export const CLEAR_COMPANY_LOGO_INFO = 'companyInfo/CLEAR_COMPANY_LOGO_INFO';

export type FetchCompanyLogoAction = ReturnType<typeof fetchCompanyLogoAction>;
export const fetchCompanyLogoAction = (companyUuid: string) => ({
  type: FETCH_COMPANY_LOGO as typeof FETCH_COMPANY_LOGO,
  companyUuid,
});

export type FetchCompanyLogoSuccessAction = ReturnType<typeof fetchCompanyLogoSuccessAction>;
export const fetchCompanyLogoSuccessAction = (companyLogoInfo: IUploadFileInfo) => ({
  type: FETCH_COMPANY_LOGO_SUCCESS as typeof FETCH_COMPANY_LOGO_SUCCESS,
  companyLogoInfo,
});

export type FetchCompanyLogoFailureAction = ReturnType<typeof fetchCompanyLogoFailureAction>;
export const fetchCompanyLogoFailureAction = (error: Error) => ({
  type: FETCH_COMPANY_LOGO_FAILURE as typeof FETCH_COMPANY_LOGO_FAILURE,
  error,
});

export type UpdateCompanyLogoPositionAction = ReturnType<typeof updateCompanyLogoPositionAction>;
export const updateCompanyLogoPositionAction = (companyUuid: string, position: ICompanyLogoPosition) => ({
  type: UPDATE_COMPANY_LOGO_POSITION as typeof UPDATE_COMPANY_LOGO_POSITION,
  companyUuid,
  position,
});

export type UpdateCompanyLogoPositionSuccessAction = ReturnType<typeof updateCompanyLogoPositionSuccessAction>;
export const updateCompanyLogoPositionSuccessAction = (position: ICompanyLogoPosition) => ({
  type: UPDATE_COMPANY_LOGO_POSITION_SUCCESS as typeof UPDATE_COMPANY_LOGO_POSITION_SUCCESS,
  position,
});

export type UpdateCompanyLogoAction = ReturnType<typeof updateCompanyLogoAction>;
export const updateCompanyLogoAction = (formData: FormData) => ({
  type: UPDATE_COMPANY_LOGO as typeof UPDATE_COMPANY_LOGO,
  formData,
});

export type RemoveCompanyLogoAction = ReturnType<typeof removeCompanyLogoAction>;
export const removeCompanyLogoAction = (companyUuid: string) => ({
  type: REMOVE_COMPANY_LOGO as typeof REMOVE_COMPANY_LOGO,
  companyUuid,
});

export type RemoveCompanyLogoSuccessAction = ReturnType<typeof removeCompanyLogoSuccessAction>;
export const removeCompanyLogoSuccessAction = () => ({
  type: REMOVE_COMPANY_LOGO_SUCCESS as typeof REMOVE_COMPANY_LOGO_SUCCESS,
});

export type FetchCompanyDataRequestAction = ReturnType<typeof fetchCompanyDataRequestAction>;
export const fetchCompanyDataRequestAction = (companyUuid: string) => ({
  type: FETCH_COMPANY_DATA_REQUEST as typeof FETCH_COMPANY_DATA_REQUEST,
  companyUuid,
});

export type FetchCompanyDataSuccessAction = ReturnType<typeof fetchCompanyDataSuccessAction>;
export const fetchCompanyDataSuccessAction = (companyData: ICompany) => ({
  type: FETCH_COMPANY_DATA_SUCCESS as typeof FETCH_COMPANY_DATA_SUCCESS,
  companyData,
});

export type FetchCompanyDataFailureAction = ReturnType<typeof fetchCompanyDataFailureAction>;
export const fetchCompanyDataFailureAction = (error: Error) => ({
  type: FETCH_COMPANY_DATA_FAILURE as typeof FETCH_COMPANY_DATA_FAILURE,
  error,
});

export type SetCompanyLogoNetworkPostLogoAction = ReturnType<typeof setCompanyLogoNetworkPostLogoAction>;
export const setCompanyLogoNetworkPostLogoAction = (newNetworkStatus: ENetworkRequestStatus) => ({
  type: SET_COMPANY_LOGO_NETWORK_POST_LOGO as typeof SET_COMPANY_LOGO_NETWORK_POST_LOGO,
  newNetworkStatus,
});

export type SetCompanyLogoNetworkDeleteLogoAction = ReturnType<typeof setCompanyLogoNetworkDeleteLogoAction>;
export const setCompanyLogoNetworkDeleteLogoAction = (newNetworkStatus: ENetworkRequestStatus) => ({
  type: SET_COMPANY_LOGO_NETWORK_DELETE_LOGO as typeof SET_COMPANY_LOGO_NETWORK_DELETE_LOGO,
  newNetworkStatus,
});

export type SetActiveHotelAction = ReturnType<typeof setActiveHotelAction>;
export const setActiveHotelAction = (isActive: boolean, hotel: IBootstrapHotel) => ({
  type: SET_ACTIVE_HOTEL as typeof SET_ACTIVE_HOTEL,
  isActive,
  hotel,
});

export type SetActiveDestinationAction = ReturnType<typeof setActiveDestinationAction>;
export const setActiveDestinationAction = (
  isActive: boolean,
  countryCode: TCountryCode,
  allPossibleHotels: IBootstrapHotel[]
) => ({
  type: SET_ACTIVE_DESTINATION as typeof SET_ACTIVE_DESTINATION,
  isActive,
  countryCode,
  allPossibleHotels,
});

export type UpdateCompanyRequestAction = ReturnType<typeof updateCompanyRequestAction>;
export const updateCompanyRequestAction = (companyUuid: string, company: Partial<ICompany>) => ({
  type: UPDATE_COMPANY_REQUEST as typeof UPDATE_COMPANY_REQUEST,
  companyUuid,
  company,
});

export type UpdateCompanySuccessAction = ReturnType<typeof updateCompanySuccessAction>;
export const updateCompanySuccessAction = (company: Partial<ICompany>) => ({
  type: UPDATE_COMPANY_SUCCESS as typeof UPDATE_COMPANY_SUCCESS,
  company,
});

export type UpdateCompanyFailureAction = ReturnType<typeof updateCompanyFailureAction>;
export const updateCompanyFailureAction = (error: Error) => ({
  type: UPDATE_COMPANY_FAILURE as typeof UPDATE_COMPANY_FAILURE,
  error,
});

export type PatchCompanyRequestAction = ReturnType<typeof patchCompanyRequestAction>;
export const patchCompanyRequestAction = (companyUuid: string, company: Partial<ICompany>) => ({
  type: PATCH_COMPANY_REQUEST as typeof PATCH_COMPANY_REQUEST,
  companyUuid,
  company,
});

export type PatchCompanySuccessAction = ReturnType<typeof patchCompanySuccessAction>;
export const patchCompanySuccessAction = (company: ICompany) => ({
  type: PATCH_COMPANY_SUCCESS as typeof PATCH_COMPANY_SUCCESS,
  company,
});

export type PatchCompanyFailureAction = ReturnType<typeof patchCompanyFailureAction>;
export const patchCompanyFailureAction = (error: Error) => ({
  type: PATCH_COMPANY_FAILURE as typeof PATCH_COMPANY_FAILURE,
  error,
});

export type CreateCompanyRequestAction = ReturnType<typeof createCompanyRequestAction>;
export const createCompanyRequestAction = (company: Partial<ICompany>, history: History) => ({
  type: CREATE_COMPANY_REQUEST as typeof CREATE_COMPANY_REQUEST,
  company,
  history,
});

export type CreateCompanySuccessAction = ReturnType<typeof createCompanySuccessAction>;
export const createCompanySuccessAction = (company: Partial<ICompany>) => ({
  type: CREATE_COMPANY_SUCCESS as typeof CREATE_COMPANY_SUCCESS,
  company,
});

export type InitCompanyNetworkStatusAction = ReturnType<typeof initCompanySaveStatusAction>;
export const initCompanySaveStatusAction = () => ({
  type: INIT_COMPANY_SAVE_STATUS as typeof INIT_COMPANY_SAVE_STATUS,
});

export type CreateCompanyFailureAction = ReturnType<typeof createCompanyFailureAction>;
export const createCompanyFailureAction = (error: Error) => ({
  type: CREATE_COMPANY_FAILURE as typeof CREATE_COMPANY_FAILURE,
  error,
});

export type GetHotelListDataRequestAction = ReturnType<typeof getHotelListDataRequestAction>;
export const getHotelListDataRequestAction = () => ({
  type: GET_HOTEL_LIST_DATA_REQUEST as typeof GET_HOTEL_LIST_DATA_REQUEST,
});

export type GetHotelListDataSuccessAction = ReturnType<typeof getHotelListDataSuccessAction>;
export const getHotelListDataSuccessAction = (allHotels: IBootstrapHotel[], disabledHotelUuids: string[]) => ({
  type: GET_HOTEL_LIST_DATA_SUCCESS as typeof GET_HOTEL_LIST_DATA_SUCCESS,
  allHotels,
  disabledHotelUuids,
});

export type GetHotelListDataFailureAction = ReturnType<typeof getHotelListDataFailureAction>;
export const getHotelListDataFailureAction = () => ({
  type: GET_HOTEL_LIST_DATA_FAILURE as typeof GET_HOTEL_LIST_DATA_FAILURE,
});

export type PostHotelListDataRequestAction = ReturnType<typeof postHotelListDataRequestAction>;
export const postHotelListDataRequestAction = () => ({
  type: POST_HOTEL_LIST_DATA_REQUEST as typeof POST_HOTEL_LIST_DATA_REQUEST,
});

export type PostHotelListDataSuccessAction = ReturnType<typeof postHotelListDataSuccessAction>;
export const postHotelListDataSuccessAction = () => ({
  type: POST_HOTEL_LIST_DATA_SUCCESS as typeof POST_HOTEL_LIST_DATA_SUCCESS,
});

export type PostHotelListDataFailureAction = ReturnType<typeof postHotelListDataFailureAction>;
export const postHotelListDataFailureAction = () => ({
  type: POST_HOTEL_LIST_DATA_FAILURE as typeof POST_HOTEL_LIST_DATA_FAILURE,
});

export type SetTravelCompanyEditModeAction = ReturnType<typeof setTravelCompanyEditModeAction>;
export const setTravelCompanyEditModeAction = (isEditMode: boolean) => ({
  type: SET_TRAVEL_COMPANY_EDIT_MODE as typeof SET_TRAVEL_COMPANY_EDIT_MODE,
  isEditMode,
});

export type UpdateCompanyExternalMarkupPercentageAction = ReturnType<
  typeof updateCompanyExternalMarkupPercentageAction
>;
export const updateCompanyExternalMarkupPercentageAction = (val: string | null) => ({
  type: UPDATE_COMPANY_EXTERNAL_MARKUP_PERCENTAGE as typeof UPDATE_COMPANY_EXTERNAL_MARKUP_PERCENTAGE,
  val,
});

export type ClearCompanyLogoInfo = ReturnType<typeof clearCompanyLogoInfo>;
export const clearCompanyLogoInfo = () => ({
  type: CLEAR_COMPANY_LOGO_INFO as typeof CLEAR_COMPANY_LOGO_INFO,
});

export type FetchCompanyDepositAccountBalanceRequest = ReturnType<typeof fetchCompanyDepositAccountBalanceRequest>;
export const fetchCompanyDepositAccountBalanceRequest = () => ({
  type: FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_REQUEST as typeof FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_REQUEST,
});

export type FetchCompanyDepositAccountBalanceSuccess = ReturnType<typeof fetchCompanyDepositAccountBalanceSuccess>;
export const fetchCompanyDepositAccountBalanceSuccess = (eurCreditLimit: string | null, usdCreditLimit: string | null, totals: ILedgerTotals[]) => ({
  type: FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_SUCCESS as typeof FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_SUCCESS,
  eurCreditLimit,
  usdCreditLimit,
  totals,
});

export type FetchCompanyDepositAccountBalanceFailure = ReturnType<typeof fetchCompanyDepositAccountBalanceFailure>;
export const fetchCompanyDepositAccountBalanceFailure = () => ({
  type: FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_FAILURE as typeof FETCH_COMPANY_DEPOSIT_ACCOUNT_BALANCE_FAILURE,
});

export type CompanyInfoAction =
  | FetchCompanyLogoAction
  | FetchCompanyLogoSuccessAction
  | FetchCompanyLogoFailureAction
  | UpdateCompanyLogoPositionAction
  | UpdateCompanyLogoPositionSuccessAction
  | UpdateCompanyLogoAction
  | RemoveCompanyLogoAction
  | RemoveCompanyLogoSuccessAction
  | FetchCompanyDataRequestAction
  | FetchCompanyDataSuccessAction
  | FetchCompanyDataFailureAction
  | SetCompanyLogoNetworkPostLogoAction
  | SetCompanyLogoNetworkDeleteLogoAction
  | SetActiveHotelAction
  | SetActiveDestinationAction
  | GetHotelListDataRequestAction
  | GetHotelListDataSuccessAction
  | GetHotelListDataFailureAction
  | PostHotelListDataRequestAction
  | PostHotelListDataSuccessAction
  | PostHotelListDataFailureAction
  | UpdateCompanyRequestAction
  | UpdateCompanySuccessAction
  | UpdateCompanyFailureAction
  | PatchCompanyRequestAction
  | PatchCompanySuccessAction
  | PatchCompanyFailureAction
  | CreateCompanyRequestAction
  | CreateCompanySuccessAction
  | CreateCompanyFailureAction
  | InitCompanyNetworkStatusAction
  | SetTravelCompanyEditModeAction
  | UpdateCompanyExternalMarkupPercentageAction
  | ClearCompanyLogoInfo
  | FetchCompanyDepositAccountBalanceRequest
  | FetchCompanyDepositAccountBalanceSuccess
  | FetchCompanyDepositAccountBalanceFailure;
