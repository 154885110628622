import {
  InventoryReference,
} from '../../../interfaces';
import { IHeaderBookingSearch } from './model';

export const SET_IS_MENU_OPEN = 'inventoryHeader/SET_IS_MENU_OPEN';
export const SET_INVENTORY_OPTION_SELECTED = 'inventoryHeader/SET_INVENTORY_OPTION_SELECTED';
export const RESET_INVENTORY_OPTION_SELECTED = 'inventoryHeader/RESET_INVENTORY_OPTION_SELECTED';
export const SEARCH_BOOKINGS_REQUEST = 'inventoryHeader/SEARCH_BOOKINGS_REQUEST';
export const SEARCH_BOOKINGS_SUCCESS = 'inventoryHeader/SEARCH_BOOKINGS_SUCCESS';
export const SEARCH_BOOKINGS_FAILURE = 'inventoryHeader/SEARCH_BOOKINGS_FAILURE';
export const SET_SEARCH_BOOKINGS = 'inventoryHeader/SET_SEARCH_BOOKINGS';

export type SetIsMenuOpenAction = ReturnType<typeof setIsMenuOpenAction>;
export const setIsMenuOpenAction = (val: boolean) => ({
  type: SET_IS_MENU_OPEN as typeof SET_IS_MENU_OPEN,
  val,
});

export type SetInventoryOptionSelectedAction = ReturnType<typeof setInventoryOptionSelectedAction>;
export const setInventoryOptionSelectedAction = (
  path: InventoryReference[], 
  url : string | null,
) => ({
  type: SET_INVENTORY_OPTION_SELECTED as typeof SET_INVENTORY_OPTION_SELECTED,
  path,
  url,
});

export type ResetInventoryOptionSelectedAction = ReturnType<typeof resetInventoryOptionSelectedAction>;
export const resetInventoryOptionSelectedAction = () => ({
  type: RESET_INVENTORY_OPTION_SELECTED as typeof RESET_INVENTORY_OPTION_SELECTED,
});

export type SearchBookingsRequestAction = ReturnType<typeof searchBookingsRequestAction>;
export const searchBookingsRequestAction = (bookingRefNumber: string) => ({
  type: SEARCH_BOOKINGS_REQUEST as typeof SEARCH_BOOKINGS_REQUEST,
  bookingRefNumber,
});

export type SearchBookingsSuccessAction = ReturnType<typeof searchBookingsSuccessAction>;
export const searchBookingsSuccessAction = (bookings: IHeaderBookingSearch[]) => ({
  type: SEARCH_BOOKINGS_SUCCESS as typeof SEARCH_BOOKINGS_SUCCESS,
  bookings,
});

export type SearchBookingsFailureAction = ReturnType<typeof searchBookingsFailureAction>;
export const searchBookingsFailureAction = () => ({
  type: SEARCH_BOOKINGS_FAILURE as typeof SEARCH_BOOKINGS_FAILURE,
});


export type TInventoryHeaderActions = 
  SetIsMenuOpenAction |
  SetInventoryOptionSelectedAction |
  ResetInventoryOptionSelectedAction |
  SearchBookingsRequestAction |
  SearchBookingsSuccessAction |
  SearchBookingsFailureAction;
