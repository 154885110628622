import { EPaymentMethod } from "store/modules/bookingBuilder/types";

export const SET_PAYMENT_METHOD = 'bookingBuilder/paymentMethod/SET_PAYMENT_METHOD';

export type SetPaymentMethodAction = ReturnType<typeof setPaymentMethodAction>;
export const setPaymentMethodAction = (value: EPaymentMethod | null) => ({
  type: SET_PAYMENT_METHOD as typeof SET_PAYMENT_METHOD,
  value
});

export type PaymentAction =
  | SetPaymentMethodAction;