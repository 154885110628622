import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBookingManagerApi, ISpecialRequestsResponse } from 'services/BookingManagerApi';
import {
  GET_SPECIAL_REQUESTS_REQUEST,
  getSpecialRequestsSuccessAction,
  getSpecialRequestsFailureAction,
} from '../actions';

import { bookingUuidSelector } from '../../../selectors';

export function* getDashboardSpecialRequestsSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<ISpecialRequestsResponse> = yield call(
      bookingManagerApi.getSpecialRequests,
      bookingUuid
    );

    yield put(getSpecialRequestsSuccessAction(result.data.specialRequests));
  } catch (e) {
    yield put(getSpecialRequestsFailureAction(e));
  }
}

export function* watchBookingManagerDashboardSpecialRequests() {
  yield takeLatest([GET_SPECIAL_REQUESTS_REQUEST], getDashboardSpecialRequestsSaga);
}
