import { createSelector } from 'reselect';
import { IInventoryStaysDomain } from './model';

export const domainSelector = (state: any): IInventoryStaysDomain => state.inventoryStays;

export const listingPageSelector = createSelector(
  domainSelector,
  (domain: IInventoryStaysDomain) => domain.listingPage
);
export const createEditPageSelector = createSelector(
  domainSelector,
  (domain: IInventoryStaysDomain) => domain.createEditPage
);

export const listSelector = createSelector(
  listingPageSelector,
  (listingPage: IInventoryStaysDomain['listingPage']) => listingPage.items
);
export const sortBySelector = createSelector(
  listingPageSelector,
  (listingPage: IInventoryStaysDomain['listingPage']) => listingPage.filter.order_by
);
export const sortOrderSelector = createSelector(
  listingPageSelector,
  (listingPage: IInventoryStaysDomain['listingPage']) => listingPage.filter.order_type
);

export const filterSelector = createSelector(
  listingPageSelector,
  (listingPage: IInventoryStaysDomain['listingPage']) => listingPage.filter
);

export const requestSelector = createSelector(
  domainSelector, (domain: IInventoryStaysDomain) => domain.request
);

export const requestStayPostSelector = createSelector(
  domainSelector, (domain: IInventoryStaysDomain) => domain.request.stayPost
);

export const requestStayPutSelector = createSelector(
  domainSelector, (domain: IInventoryStaysDomain) => domain.request.stayPut
);

export const requestRegionsGetSelector = createSelector(
  domainSelector, (domain: IInventoryStaysDomain) => domain.request.regionsGet
);

export const requestRatingsGetSelector = createSelector(
  domainSelector, (domain: IInventoryStaysDomain) => domain.request.ratingsGet
);

export const requestStayGetSelector = createSelector(
  domainSelector, (domain: IInventoryStaysDomain) => domain.request.stayGet
);

export const stayRegionsSelector = createSelector(
  createEditPageSelector,
  (createEditPage: IInventoryStaysDomain['createEditPage']) => createEditPage.regions
);
export const stayRatingsSelector = createSelector(
  createEditPageSelector,
  (createEditPage: IInventoryStaysDomain['createEditPage']) => createEditPage.ratings
);

export const suppliersSelector = createSelector(
  createEditPageSelector,
  (createEditPage: IInventoryStaysDomain['createEditPage']) => createEditPage.suppliers
);

export const inventoryStaySelector = createSelector(
  createEditPageSelector,
  (createEditPage: IInventoryStaysDomain['createEditPage']) => createEditPage.stay
);

export const bestforFiltersSelector = createSelector(
  createEditPageSelector,
  (createEditPage: IInventoryStaysDomain['createEditPage']) => createEditPage.bestforFilters
);

export const amenityFiltersSelector = createSelector(
  createEditPageSelector,
  (createEditPage: IInventoryStaysDomain['createEditPage']) => createEditPage.amenityFilters
);

export const otherFiltersSelector = createSelector(
  createEditPageSelector,
  (createEditPage: IInventoryStaysDomain['createEditPage']) => createEditPage.otherFilters
);
