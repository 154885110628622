import React from 'react';
import { Helmet } from 'react-helmet';

export interface IHotjarSnippetProps {
  appId: string;
  sessionUuid: string;
  currentUserUuid?: string;
}

export const HotjarSnippet = ({ appId, sessionUuid, currentUserUuid = undefined }: IHotjarSnippetProps) => {
  return (
    // @ts-ignore
    <Helmet>
      <script type="text/javascript">
        {`
      (function(h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function() {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: ${appId}, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

      
    `}
      </script>
      <script type="text/javascript">
        {`
      <!-- Install this snippet AFTER the Hotjar tracking code. -->
      window.hj('identify', '${currentUserUuid || sessionUuid}', {
          'sessionUuid': '${sessionUuid}'
      });
    `}
      </script>
    </Helmet>
  );
};
