import React from 'react';
import classnames from 'classnames';
import { IMultiselectValueLabelPair } from "ui/Multiselect";
import { taskPriorities } from "./common";
import { TaskPriority } from "./TaskPriority";
import { ISingleSelectOption } from 'ui/SingleSelect';
import { ETaskStatus } from 'services/TaskManagementApi/types/TaskManagementResponse';

export const taskStatusOptions: IMultiselectValueLabelPair[] = [
  {
    value: ETaskStatus.BLOCKED,
    label: 'Blocked',
    iconHtml: <i className="fas fa-circle text-status-cancelled"></i>,
    iconAlign: 'end',
  },
  {
    value: ETaskStatus.BACKLOG,
    label: 'Backlog',
    iconHtml: <i className="fas fa-circle text-gray-100"></i>,
    iconAlign: 'end',
  },
  {
    value: ETaskStatus.TO_DO,
    label: 'To Do',
    iconHtml: <i className="fas fa-circle text-purple"></i>,
    iconAlign: 'end',
  },
  {
    value: ETaskStatus.IN_PROGRESS,
    label: 'In Progress',
    iconHtml: <i className="fas fa-circle text-status-enquiry"></i>,
    iconAlign: 'end',
  },
  {
    value: ETaskStatus.DONE,
    label: 'Done',
    iconHtml: <i className="fas fa-circle text-green-hard"></i>,
    iconAlign: 'end',
  },
];

export const taskPriorityOptions: ISingleSelectOption[] = taskPriorities.map(priority => ({
  value: priority.value,
  renderItem: (className?: string) => (
    <TaskPriority className={classnames(className, 'min-h-[33px]')} value={priority.value} />
  ),
}));
