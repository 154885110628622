import React, { useState, useCallback, ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { addYears } from 'date-fns';
import { sumBy } from 'lodash-es';
import { PriceAsCentsInput } from 'ui/stateful/PriceAsCentsInput';
import { EFinanceTableTypes } from 'store/modules/bookingManager/subdomains/finance/types';
import FluidButton from 'ui/FluidButton';
import {
  EFinanceRowTypes,
  IFinanceRow,
  IInvoiceDueDate,
  ILedgerTotalsResponse,
  IPaymentMethod,
} from 'services/BookingManagerApi/types';
import { makeBookingManagerApi } from 'services/BookingManagerApi/BookingManagerApi';
import { EBankAccount } from 'interfaces';
import { formatDate } from '../../utils';
import { sales as salesOptions, purchase as purchaseOptions } from './type_options';
import {
  isNegativeFinanceRow,
  isPositiveFinanceRow,
  isNeedingFileAttachment,
} from 'store/modules/bookingManager/subdomains/finance/utils';
import { bookingSelector } from 'store/modules/bookingManager/selectors';
import { InvoiceDueDates } from './InvoiceDueDates';
import { RowTypeSelect } from '../RowTypeSelect';
import { FilePicker } from '../FilePicker';
import { ENetworkRequestStatus } from 'services/BackendApi/types';
import { AxiosResponse } from 'axios';
import { InsufficientFundsWarning } from './InsufficientFundsWarning';
import { TravelPartnerPrivacyNote } from './TravelPartnerPrivacyNote';
import { ErrorMessage } from './ErrorMessage';
import { StyledSingleDateInput } from './StyledSingleDateInput';
import { CreditNoteExpiryDate } from './CreditNoteExpiryDate';
import { UITextArea } from 'ui/UITextArea';
import SingleSelect from 'ui/SingleSelect';
import { isAdmin } from 'store/modules/auth/selectors';

type CommentSpec = {
  label: string;
  required: boolean;
  value?: string;
};

export interface IFinanceTableModalProps {
  onSubmit: (newRow: IFinanceRow, files: File[], comment?: string) => void;
  submitButtonLabel: string;
  submitButtonLoading: boolean;
  modalTitle: string;
  financeModalType: EFinanceTableTypes;
  extraInfo?: ReactNode;
  errorMessage?: string;
  disableRowTypeSelection?: boolean;
  commentSpec?: CommentSpec;
  // values for editing
  date?: string | null;
  recordNumber?: string;
  description?: string;
  rowType?: EFinanceRowTypes | null;
  amountCents?: number;
  uploadName?: string;
  isAutomatedInvoice?: boolean;
  bankAccount?: EBankAccount;
  invoiceDueDates?: IInvoiceDueDate[];
  currencySymbol?: string;
  hotelCurrency?: string;
  creditNoteFlowAnswer1?: boolean | null;
  creditNoteExpiryDate?: string | null;
  paymentMethods: IPaymentMethod[];
  defaultPaymentMethodName?: string | null;
}

export const FinanceTableModal = (props: IFinanceTableModalProps) => {
  const { date, description, rowType, amountCents, bankAccount, commentSpec, currencySymbol, paymentMethods, defaultPaymentMethodName } = props;

  const [stateDate, setDate] = useState<string | null>(date || null);
  const [stateDescription, setDescription] = useState<string>(description || '');
  const [stateRowType, setStateRowType] = useState<EFinanceRowTypes | null>(rowType ? rowType : null);
  const [stateAmount, setAmount] = useState<number>(amountCents || 0);
  const [stateUploadName, setUploadName] = useState<string | null>(props.uploadName || null);

  const [showInsufficientFundsWarning, setShowInsufficientFundsWarning] = useState<boolean>(false);
  const [stateDepositTotalLoad, setStateDepositTotalLoad] = useState<ENetworkRequestStatus>(ENetworkRequestStatus.IDLE);
  const bookingSearchItem = useSelector(bookingSelector);

  const canProceedWithNegativeFunds = useSelector(isAdmin);  

  const [stateBankAccount, setStateBankAccount] = useState<EBankAccount>(
    (bankAccount != null ? bankAccount : defaultPaymentMethodName) as EBankAccount
  );

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const [stateComment, setComment] = useState<string>(commentSpec?.value || '');

  const [stateInvoiceDueDates, setInvoiceDueDates] = useState<IInvoiceDueDate[] | undefined>(props.invoiceDueDates);

  const isFileAttachmentSet = !((stateUploadName === '' || stateUploadName == null) && selectedFiles.length <= 0);
  const isAmountGreaterThanZero = stateAmount > 0;
  const isDateSet = stateDate != null;
  
  const isDescriptionSet = stateDescription != null && stateDescription !== '';
  const isCommentSet = !!stateComment;

  const creditNoteFlowQuestion1 =
    props.financeModalType === EFinanceTableTypes.SALES
      ? 'Is the Credit Note specific to the Hotel in the Booking?'
      : 'Is the Credit Note specific to the Guest in the booking?';
  const creditNoteFlowQuestion2 = 'Does the Credit Note have an expiry date';
  const [creditNoteFlowAnswer1, setCreditNoteFlowAnswer1] = useState<boolean>(props.creditNoteFlowAnswer1 ?? false);
  const [creditNoteFlowAnswer2, setCreditNoteFlowAnswer2] = useState<boolean>(!!props.creditNoteExpiryDate);
  const [creditNoteExpiryDate, setCreditNoteExpiryDate] = useState<string | null>(props.creditNoteExpiryDate ?? null);
  const shouldShowCreditNoteFlow = [
    EFinanceRowTypes.Credit_Note_For_Guest_In_Negative,
    EFinanceRowTypes.Credit_Note_For_Guest_Out_Positive,
    EFinanceRowTypes.Credit_Note_For_Travel_Partner_In_Negative,
    EFinanceRowTypes.Credit_Note_For_Travel_Partner_Out_Positive,
    EFinanceRowTypes.Credit_Note_From_Hotel_Negative,
    EFinanceRowTypes.Credit_Note_From_Non_Hotel_Supplier_Negative,
    EFinanceRowTypes.Credit_Note_From_Hotel_Used_Positive,
    EFinanceRowTypes.Credit_Note_From_Non_Hotel_Supplier_Used_Positive,
  ].includes(stateRowType as EFinanceRowTypes);

  // easy validation logic - submit button is disabled if fields are blank...
  // ...if we're doing Purchase AND it's Credit, its also disabled if theres no file attached
  let isButtonDisabled = !isAmountGreaterThanZero || !isDateSet || !isDescriptionSet;

  let isFileAttachmentRequired = false;
  if (
    isNeedingFileAttachment({
      rowType: stateRowType,
    } as IFinanceRow)
  ) {
    isButtonDisabled = isButtonDisabled || !isFileAttachmentSet;
    isFileAttachmentRequired = true;
  }

  if (commentSpec?.required) {
    isButtonDisabled = isButtonDisabled || !isCommentSet;
  }

  if (props.disableRowTypeSelection !== true && stateRowType === null) {
    isButtonDisabled = true;
  }

  if (creditNoteFlowAnswer2 && creditNoteExpiryDate === null) {
    isButtonDisabled = true;
  }

  if (props.invoiceDueDates) {
    const rest =
      stateAmount -
      sumBy(
        stateInvoiceDueDates?.filter(x => x.isConfirmed),
        'amountCents'
      );

    isButtonDisabled = isButtonDisabled || rest !== 0;
  }

  const isNeedingBankInfoRecord = stateRowType === EFinanceRowTypes.Deposit_Transfer_In;

  const handleSelectBankAccount = useCallback((bankAccount: EBankAccount) => {
    setStateBankAccount(bankAccount);
  }, []);

  const handleRowTypeSelect = useCallback(sv => {
    setStateRowType(sv[0] as EFinanceRowTypes);
  }, []);

  const isPositive = isPositiveFinanceRow({
    rowType: stateRowType,
  } as IFinanceRow);

  const isNegative = isNegativeFinanceRow({
    rowType: stateRowType,
  } as IFinanceRow);

  const handleFileRemove = useCallback(() => {
    setUploadName(null);
  }, []);

  const handleFileSelect = useCallback((files: File[]) => {
    setSelectedFiles(files);
  }, []);

  const addNewRow = () => {
    if (stateDate === null) {
      return;
    }

    // build a row
    const newRow: IFinanceRow = {
      amountCents: stateAmount,
      date: stateDate,
      rowType: stateRowType!, // its technically allowed to be null, based on the special endpoint for PCR approval
      description: stateDescription,
      uploadUrl: null,
      uploadUuid: null,
      uploadName: stateUploadName,
      invoiceDueDates: stateInvoiceDueDates,
      creditNoteExpiryDate: null,
      isCreditNoteSpecificToHotel: null,
      isCreditNoteSpecificToGuest: null,
    };
    if (isNeedingBankInfoRecord) {
      newRow.bankAccount = stateBankAccount;
    }
    if (shouldShowCreditNoteFlow) {
      if (props.financeModalType === EFinanceTableTypes.SALES) {
        newRow.isCreditNoteSpecificToHotel = creditNoteFlowAnswer1;
      }
      if (props.financeModalType === EFinanceTableTypes.PURCHASE) {
        newRow.isCreditNoteSpecificToGuest = creditNoteFlowAnswer1;
      }
      newRow.creditNoteExpiryDate = creditNoteExpiryDate;
    }

    // call props.onSubmit with the new row
    props.onSubmit(newRow, selectedFiles, stateComment || undefined);
  };

  const handleFundsWarningCancel = useCallback(() => {
    setShowInsufficientFundsWarning(false);
  }, []);

  const handleFundsWarningConfirm = useCallback(() => {
    setShowInsufficientFundsWarning(false);
    addNewRow();
  }, [addNewRow]);

  const paymentMethodOptions = useMemo(() =>
    paymentMethods.map(x => ({
      value: x.name,
      label: x.name,
    })),
    [paymentMethods]
  );

  const selectedPaymentMethod = useMemo(() =>
    paymentMethods.find(x => x.name === stateBankAccount),
    [paymentMethods, stateBankAccount]
  );

  return (
    <div className="finance-table-modal relative">
      {showInsufficientFundsWarning && canProceedWithNegativeFunds && (
        <InsufficientFundsWarning
          message1="Please note there is insufficient funds in the Deposit Account."
          message2="Do you still wish to continue?"
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          onConfirm={handleFundsWarningConfirm}
          onCancel={handleFundsWarningCancel}
        />
      )}
      {showInsufficientFundsWarning && !canProceedWithNegativeFunds && (
        <InsufficientFundsWarning
          message1="There are insufficient funds in the Deposit Account. Speak to your Admin user or add funds to the Deposit Account."
          cancelButtonLabel="Accept"
          onCancel={handleFundsWarningCancel}
        />
      )}
      {props.errorMessage && <ErrorMessage message={props.errorMessage} />}
      <h3
        className={`${props.errorMessage != undefined ? 'mt-20' : ''
          } m-0 font-noe-display mb-25px font-normal text-21px`}
      >
        {props.modalTitle}
      </h3>
      {props.extraInfo}
      {props.financeModalType === EFinanceTableTypes.SALES && <TravelPartnerPrivacyNote />}

      {/* row types for sales */}
      {props.disableRowTypeSelection !== true && props.financeModalType === EFinanceTableTypes.SALES && (
        <RowTypeSelect
          selectedValue={stateRowType}
          isSelectedValuePositive={isPositive}
          isSelectedValueNegative={isNegative}
          options={salesOptions}
          onSelect={handleRowTypeSelect}
        />
      )}

      {/* row types for purchase */}
      {props.disableRowTypeSelection !== true && props.financeModalType === EFinanceTableTypes.PURCHASE && (
        <RowTypeSelect
          selectedValue={stateRowType}
          isSelectedValuePositive={isPositive}
          isSelectedValueNegative={isNegative}
          options={purchaseOptions}
          onSelect={handleRowTypeSelect}
        />
      )}

      {shouldShowCreditNoteFlow && (
        <CreditNoteExpiryDate
          question1={creditNoteFlowQuestion1}
          answer1={creditNoteFlowAnswer1}
          onAnswer1Change={setCreditNoteFlowAnswer1}
          question2={creditNoteFlowQuestion2}
          answer2={creditNoteFlowAnswer2}
          onAnswer2Change={setCreditNoteFlowAnswer2}
          date={creditNoteExpiryDate}
          onDateChange={setCreditNoteExpiryDate}
        />
      )}

      {isNeedingBankInfoRecord && (
        <>
          <SingleSelect
            fieldId="invoice-payment-method"
            label="Payment Method"
            className="invoice-payment-method max-w-[730px] mt-[30px]"
            labelClassName="text-[16px] leading-[21px] font-bold"
            value={stateBankAccount}
            options={paymentMethodOptions}
            onChange={handleSelectBankAccount}
            maxVisibleItems={5}
          />
          {!!selectedPaymentMethod?.warning && (
            <div className="mt-[5px] mb-5 min-h-[100px] max-h-[100px] overflow-y-scroll max-w-fit border border-solid border-gray-20 rounded p-4 font-pt-sans leading-xs text-13px text-black">
              {selectedPaymentMethod.warning}
            </div>
          )}
        </>
      )}

      {/* date and amount */}
      <div className="flex mt-25px">
        <label className="date-field w-1/5 mr-20px">
          <span className="block mb-2 text-black text-base font-bold leading-14px tracking-2xs font-pt-sans">Date</span>

          <StyledSingleDateInput
            value={stateDate ? new Date(stateDate) : null}
            onChange={value => {
              setDate(formatDate(value));
            }}
            showYearDropdown
            enablePastDates
            minDate={addYears(new Date(), -150)}
            maxDate={addYears(new Date(), 150)}
          />
        </label>

        <label className="amount-field block w-1/5 mr-20px">
          <span className="block mb-2 text-black text-base font-bold leading-14px tracking-2xs font-pt-sans">
            Amount
          </span>
          <PriceAsCentsInput
            cents={stateAmount}
            className="focus:outline-gray-80 h-39px p-2 border border-solid border-gray-40 min-h-35px w-full font-pt-sans text-sm bg-ivory"
            disabled={showInsufficientFundsWarning}
            onBlurUpdate={v => setAmount(v)}
          />
        </label>
      </div>

      <div className="flex mt-25px">
        <label className="description-field block w-full">
          <span className="block mb-2 text-black text-base font-bold leading-14px tracking-2xs font-pt-sans">
            Description
          </span>
          <input
            type="text"
            className="focus:outline-gray-80 h-39px p-2 border border-solid border-gray-40 min-h-35px w-full font-pt-sans text-sm bg-ivory"
            value={stateDescription}
            placeholder="Description..."
            disabled={showInsufficientFundsWarning}
            onChange={e => {
              if (e.target.value.length > 100) {
                return;
              }
              setDescription(e.target.value);
            }}
          />
        </label>
      </div>

      {!!stateInvoiceDueDates && (
        <InvoiceDueDates
          className="mt-25px"
          amountCents={stateAmount}
          currencySymbol={currencySymbol || ''}
          invoiceDueDates={stateInvoiceDueDates}
          onChange={setInvoiceDueDates}
        />
      )}

      <FilePicker
        isAttachmentRequired={isFileAttachmentRequired}
        uploadName={props.uploadName}
        onFileSelect={handleFileSelect}
        onFileRemove={handleFileRemove}
      />

      {/* comment */}
      {!!commentSpec && (
        <label className="block comment w-full mt-25px">
          <span className="block mb-1 text-black text-base font-bold leading-14px tracking-2xs font-pt-sans">
            <span>{commentSpec.label}</span>
            {commentSpec.required && <span className="font-normal ml-1 text-sm">(Required)</span>}
          </span>
          <UITextArea
            placeholder="Comment..."
            rows={3}
            value={stateComment}
            onChange={value => {
              setComment(value);
            }}
          />
        </label>
      )}

      <div className="flex justify-between">
        <FluidButton
          type="primary"
          className="mt-25px"
          isLoading={props.submitButtonLoading || stateDepositTotalLoad === ENetworkRequestStatus.PENDING}
          disabled={props.submitButtonLoading || isButtonDisabled}
          onClick={async () => {
            if (!bookingSearchItem) {
              return;
            }

            // if its not of the specific type we care about, just do the regular add row
            if (stateRowType !== EFinanceRowTypes.Deposit_Transfer_In) {
              return addNewRow();
            }

            // otherwise, make a BMS client and get the deposit account totals
            setStateDepositTotalLoad(ENetworkRequestStatus.PENDING);
            const bookingManagerApi = makeBookingManagerApi();
            let depositAccountTotals: AxiosResponse<ILedgerTotalsResponse> | null;
            try {
              depositAccountTotals = await bookingManagerApi.depositStatementTotals(
                bookingSearchItem.travelAgentCompanyUuid
              );
              setStateDepositTotalLoad(ENetworkRequestStatus.SUCCESS);

              // get the currency we care about (based on the booking)
              const currencyToCheck = depositAccountTotals.data.find(x => x.currency === props.hotelCurrency);

              if (stateRowType === EFinanceRowTypes.Deposit_Transfer_In && currencyToCheck!.netTotal > 0) {
                // they definitely don't have money
                // remember - if the amount is POSITIVE, they DONT have money
                setShowInsufficientFundsWarning(true);
              } else if (
                // so we already know its negative, so just make sure they have enough
                stateAmount > Math.abs(currencyToCheck!.netTotal) &&
                stateRowType === EFinanceRowTypes.Deposit_Transfer_In
              ) {
                setShowInsufficientFundsWarning(true);
              } else {
                // otherwise, they DO have enough, just add the row
                addNewRow();
              }
            } catch (error) {
              setStateDepositTotalLoad(ENetworkRequestStatus.ERROR);
            }
          }}
        >
          {props.submitButtonLabel}
        </FluidButton>
      </div>
    </div>
  );
};
