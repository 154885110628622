import React from 'react';
import { Price } from 'ui/Price';
import { EProductLineDisplayMode, ProductLineWithPrice } from './ProductLineWithPrice';
import { TCurrencyCode } from 'interfaces';
import { IProposalBookingAccommodation } from 'store/modules/proposalDetails/types';

interface IProposalAccommodationProps {
  accommodation: IProposalBookingAccommodation;
  currencyCode: TCurrencyCode;
}

export const ProposalAccommodation: React.FC<IProposalAccommodationProps> = React.memo(
  ({ accommodation, currencyCode }) => {
    const priceClassName = 'font-hurmegeometric-sans text-15px leading-18px text-right mt-5px m-0';

    return (
      <div className="proposal-accommodation px-5 py-15px border-solid border-b border-gray-20">
        <div className="accommodation-data flex items-center justify-between">
          <div className="accommodation-data-left">
            <p className="accommodation-title font-hurmegeometric-sans text-flint text-13px leading-16px uppercase font-bold m-0">
              {accommodation.title}
            </p>
            <div className="mt-2 flex gap-20px">
              <p className="accommodation-dates-offers font-hurmegeometric-sans text-13px leading-16px text-flint uppercase m-0 max-w-670px">
                {accommodation.tertiaryText}
              </p>
            </div>
          </div>
          <div className="accommodation-data-right">
            <Price
              total={accommodation.total}
              totalBeforeDiscount={accommodation.totalBeforeDiscount}
              currencyCode={currencyCode}
              isOnRequest={accommodation.isOnRequest}
              totalClassName={`${priceClassName} text-flint`}
              totalBeforeDiscountClassName={`${priceClassName} text-gold-light line-through`}
              totalAfterDiscountClassName={`${priceClassName} text-red-15`}
              isOnRequestClassName={`${priceClassName} text-flint`}
            />
          </div>
        </div>

        <div className="meal-plans mt-4">
          {accommodation['Meal Plan'].map((mealPlan, index) => (
            <ProductLineWithPrice
              key={index}
              name={mealPlan.title}
              tertiaryText={mealPlan.tertiaryText}
              total={mealPlan.total}
              totalBeforeDiscount={mealPlan.totalBeforeDiscount}
              currencyCode={currencyCode}
              isOnRequest={mealPlan.isOnRequest}
              displayMode={EProductLineDisplayMode.ONE_LINE}
              className="meal-plan"
            />
          ))}
        </div>
      </div>
    );
  }
);
