import { Upload } from 'services/BackendApi';
import { CompanyDocumentsSortField, CompanyDocumentsSortOrder } from './model';

export const FETCH_COMPANY_DOCUMENTS_REQUEST = 'companyDocuments/FETCH_COMPANY_DOCUMENTS_REQUEST';
export const FETCH_COMPANY_DOCUMENTS_SUCCESS = 'companyDocuments/FETCH_COMPANY_DOCUMENTS_SUCCESS';
export const FETCH_COMPANY_DOCUMENTS_FAILURE = 'companyDocuments/FETCH_COMPANY_DOCUMENTS_FAILURE';

export const SET_COMPANY_DOCUMENTS_SORT_FIELD = 'companyDocuments/SET_COMPANY_DOCUMENTS_SORT_FIELD';

export const SET_COMPANY_DOCUMENT_TO_UPLOAD = 'companyDocuments/SET_COMPANY_DOCUMENTS_TO_UPLOAD';
export const SET_COMPANY_DOCUMENT_DISPLAY_NAME = 'companyDocuments/SET_COMPANY_DOCUMENTS_DISPLAY_NAME';
export const SET_COMPANY_DOCUMENT_TAG = 'companyDocuments/SET_COMPANY_DOCUMENT_TAG';

export const UPLOAD_COMPANY_DOCUMENT_REQUEST = 'companyDocuments/UPLOAD_COMPANY_DOCUMENT_REQUEST';
export const UPLOAD_COMPANY_DOCUMENT_SUCCESS = 'companyDocuments/UPLOAD_COMPANY_DOCUMENT_SUCCESS';
export const UPLOAD_COMPANY_DOCUMENT_FAILURE = 'companyDocuments/UPLOAD_COMPANY_DOCUMENT_FAILURE';

export const SET_DELETE_COMPANY_DOCUMENT = 'companyDocuments/SET_DELETE_COMPANY_DOCUMENT';
export const CANCEL_DELETE_COMPANY_DOCUMENT = 'companyDocuments/CANCEL_DELETE_COMPANY_DOCUMENT';

export const DELETE_COMPANY_DOCUMENT_REQUEST = 'companyDocuments/DELETE_COMPANY_DOCUMENT_REQUEST';
export const DELETE_COMPANY_DOCUMENT_SUCCESS = 'companyDocuments/DELETE_COMPANY_DOCUMENT_SUCCESS';
export const DELETE_COMPANY_DOCUMENT_FAILURE = 'companyDocuments/DELETE_COMPANY_DOCUMENT_FAILURE';

export const OPEN_UPLOAD_COMPANY_DOCUMENT_MODAL = 'companyDocuments/OPEN_UPLOAD_COMPANY_DOCUMENT_MODAL';
export const CLOSE_UPLOAD_COMPANY_DOCUMENT_MODAL = 'companyDocuments/CLOSE_UPLOAD_COMPANY_DOCUMENT_MODAL';

export type FetchCompanyDocumentsRequestAction = ReturnType<typeof fetchCompanyDocumentsRequestAction>;
export const fetchCompanyDocumentsRequestAction = () => ({
  type: FETCH_COMPANY_DOCUMENTS_REQUEST as typeof FETCH_COMPANY_DOCUMENTS_REQUEST,
});

export type FetchCompanyDocumentsSuccessAction = ReturnType<typeof fetchCompanyDocumentsSuccessAction>;
export const fetchCompanyDocumentsSuccessAction = (documents: Upload[]) => ({
  type: FETCH_COMPANY_DOCUMENTS_SUCCESS as typeof FETCH_COMPANY_DOCUMENTS_SUCCESS,
  documents,
});

export type FetchCompanyDocumentsFailureAction = ReturnType<typeof fetchCompanyDocumentsFailureAction>;
export const fetchCompanyDocumentsFailureAction = (error: Error) => ({
  type: FETCH_COMPANY_DOCUMENTS_FAILURE as typeof FETCH_COMPANY_DOCUMENTS_FAILURE,
  error,
});

export type SetCompanyDocumentsSortFieldAction = ReturnType<typeof setCompanyDocumentsSortFieldAction>;
export const setCompanyDocumentsSortFieldAction = (sortField: CompanyDocumentsSortField, sortOrder: CompanyDocumentsSortOrder) => ({
  type: SET_COMPANY_DOCUMENTS_SORT_FIELD as typeof SET_COMPANY_DOCUMENTS_SORT_FIELD,
  sortField,
  sortOrder,
});

export type SetCompanyDocumentToUploadAction = ReturnType<typeof setCompanyDocumentToUploadAction>;
export const setCompanyDocumentToUploadAction = (document: File) => ({
  type: SET_COMPANY_DOCUMENT_TO_UPLOAD as typeof SET_COMPANY_DOCUMENT_TO_UPLOAD,
  document
});

export type SetCompanyDocumentDisplayNameAction = ReturnType<typeof setCompanyDocumentDisplayNameAction>;
export const setCompanyDocumentDisplayNameAction = (displayName: string) => ({
  type: SET_COMPANY_DOCUMENT_DISPLAY_NAME as typeof SET_COMPANY_DOCUMENT_DISPLAY_NAME,
  displayName
});

export type SetCompanyDocumentTagAction = ReturnType<typeof setCompanyDocumentTagAction>;
export const setCompanyDocumentTagAction = (tag: string) => ({
  type: SET_COMPANY_DOCUMENT_TAG as typeof SET_COMPANY_DOCUMENT_TAG,
  tag
});

export type UploadCompanyDocumentRequestAction = ReturnType<typeof uploadCompanyDocumentRequestAction>;
export const uploadCompanyDocumentRequestAction = () => ({
  type: UPLOAD_COMPANY_DOCUMENT_REQUEST as typeof UPLOAD_COMPANY_DOCUMENT_REQUEST
});

export type UploadCompanyDocumentSuccessAction = ReturnType<typeof uploadCompanyDocumentSuccessAction>;
export const uploadCompanyDocumentSuccessAction = () => ({
  type: UPLOAD_COMPANY_DOCUMENT_SUCCESS as typeof UPLOAD_COMPANY_DOCUMENT_SUCCESS,
});

export type UploadCompanyDocumentFailureAction = ReturnType<typeof uploadCompanyDocumentFailureAction>;
export const uploadCompanyDocumentFailureAction = (error: Error) => ({
  type: UPLOAD_COMPANY_DOCUMENT_FAILURE as typeof UPLOAD_COMPANY_DOCUMENT_FAILURE,
  error,
});

export type SetDeleteCompanyDocumentAction = ReturnType<typeof setDeleteCompanyDocumentAction>;
export const setDeleteCompanyDocumentAction = (uuid: string) => ({
  type: SET_DELETE_COMPANY_DOCUMENT as typeof SET_DELETE_COMPANY_DOCUMENT,
  uuid,
});

export type CancelDeleteCompanyDocumentAction = ReturnType<typeof cancelDeleteCompanyDocumentAction>;
export const cancelDeleteCompanyDocumentAction = () => ({
  type: CANCEL_DELETE_COMPANY_DOCUMENT as typeof CANCEL_DELETE_COMPANY_DOCUMENT
});

export type DeleteCompanyDocumentRequestAction = ReturnType<typeof deleteCompanyDocumentRequestAction>;
export const deleteCompanyDocumentRequestAction = () => ({
  type: DELETE_COMPANY_DOCUMENT_REQUEST as typeof DELETE_COMPANY_DOCUMENT_REQUEST
});

export type DeleteCompanyDocumentSuccessAction = ReturnType<typeof deleteCompanyDocumentSuccessAction>;
export const deleteCompanyDocumentSuccessAction = () => ({
  type: DELETE_COMPANY_DOCUMENT_SUCCESS as typeof DELETE_COMPANY_DOCUMENT_SUCCESS
});

export type DeleteCompanyDocumentFailureAction = ReturnType<typeof deleteCompanyDocumentFailureAction>;
export const deleteCompanyDocumentFailureAction = (error: Error) => ({
  type: DELETE_COMPANY_DOCUMENT_FAILURE as typeof DELETE_COMPANY_DOCUMENT_FAILURE,
  error
});

export type OpenUploadCompanyDocumentModalAction = ReturnType<typeof openUploadCompanyDocumentModalAction>;
export const openUploadCompanyDocumentModalAction = () => ({
  type: OPEN_UPLOAD_COMPANY_DOCUMENT_MODAL as typeof OPEN_UPLOAD_COMPANY_DOCUMENT_MODAL,
});

export type CloseUploadCompanyDocumentModalAction = ReturnType<typeof closeUploadCompanyDocumentModalAction>;
export const closeUploadCompanyDocumentModalAction = () => ({
  type: CLOSE_UPLOAD_COMPANY_DOCUMENT_MODAL as typeof CLOSE_UPLOAD_COMPANY_DOCUMENT_MODAL,
});

export type CompanyDocumentsAction =
  | FetchCompanyDocumentsRequestAction
  | FetchCompanyDocumentsSuccessAction
  | FetchCompanyDocumentsFailureAction
  | SetCompanyDocumentsSortFieldAction
  | SetCompanyDocumentToUploadAction
  | SetCompanyDocumentDisplayNameAction
  | SetCompanyDocumentTagAction
  | UploadCompanyDocumentRequestAction
  | UploadCompanyDocumentSuccessAction
  | UploadCompanyDocumentFailureAction
  | SetDeleteCompanyDocumentAction
  | CancelDeleteCompanyDocumentAction
  | DeleteCompanyDocumentRequestAction
  | DeleteCompanyDocumentSuccessAction
  | DeleteCompanyDocumentFailureAction
  | OpenUploadCompanyDocumentModalAction
  | CloseUploadCompanyDocumentModalAction;
