import { ENetworkRequestStatus } from 'services/BackendApi';
import { EInventoryReferenceL1, InventoryReference } from '../../../interfaces';

export interface IHeaderBookingSearch {
  bookingRefNumber: string,
  hotelName: string,
  price: string,
  tcUuid: string,
  bookingUuid: string,
  bookingCurrency: string,
  urls: {
    breakdown: string,
    finance: string,
    tcStatements: string,
    tcDepositAccount: string,
  }
}

export interface IInventoryHeaderDomain {
  isMenuOpen: boolean;
  currentPathOptions: InventoryReference[];
  lastSetPathOptions: InventoryReference[];
  currentURL: string | null; // Will help to detect changed URLs in browser.
  lastSetUrl: string | null; // Will help to detect changed URLs in browser.
  bookings: IHeaderBookingSearch[];
  networkRequests:{
    fetchingBookings: ENetworkRequestStatus,
  }
}

export const initialState: IInventoryHeaderDomain = {
  isMenuOpen: true,
  currentPathOptions: [EInventoryReferenceL1.RESERVATION, null, null],
  lastSetPathOptions: [EInventoryReferenceL1.RESERVATION, null, null],
  currentURL: '/',
  lastSetUrl: '/',
  bookings: [],
  networkRequests: {
    fetchingBookings: ENetworkRequestStatus.IDLE,
  }
};
