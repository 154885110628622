import React from 'react';
import { Price } from 'ui/Price';
import { ProductLineWithPrice } from './ProductLineWithPrice';
import { BookingBuilder, ICompany, ProductSetAccommodation } from 'services/BackendApi';
import { PlainDates } from './PlainDates';
import { ERateType, RateTag } from 'ui/RateTag/RateTag';
import { isNil, uniq } from 'lodash-es';
import { LodgingSummary } from 'interfaces';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { addDays, format } from 'date-fns';
import { useSelector } from 'react-redux';
import { companyDataSelector } from 'store/modules/companyInfo';
import { isRefundable as isRefundableFn } from 'common-lib/cancellation-policy-composer';
import { offsetDate } from 'utils';
import { ERefundabilityTagStatus, RefundabilityTag } from 'ui/RefundabilityTag/RefundabilityTag';

interface IBookingAccommodationProps {
  booking: BookingBuilder;
  accommodation: ProductSetAccommodation;
  lodgingSummaries: LodgingSummary[];
  startDate: string;
  endDate: string;
  accommodationIndex: number;
}

export const BookingAccommodation: React.FC<IBookingAccommodationProps> = React.memo(
  ({ booking, startDate, endDate, lodgingSummaries, accommodation, accommodationIndex }) => {
    const company = useSelector(companyDataSelector) as ICompany;
    const isTa: boolean = useSelector(AuthSelectors.isTA);
    const selectedMealPlan = accommodation.availableSubProductSets['Meal Plan'].find(item => item.selected)!;
    const extraPersonSupplements = accommodation.availableSubProductSets['Supplement'].filter(item => item.selected);

    const priceClassName = 'font-hurmegeometric-sans text-15px leading-18px text-right mt-5px m-0';
    const isInstantBook =
      lodgingSummaries[accommodationIndex].availableToInstantBook && (!isTa || (isTa && company?.enableInstantBooking));
    const requestedAccommodation = booking.request.Accommodation[accommodationIndex];
    const offers = booking.response.appliedOfferNames;

    const expenseInfo = booking.response.expenseInfosGroupedByRoom?.[accommodationIndex];
    const isRefundable = isNil(expenseInfo) ? false : isRefundableFn(expenseInfo);

    return (
      <div className="confirmation-accommodation px-5 py-15px border-solid border-b border-gray-20">
        <div className="flex justify-between items-center">
          <p className="accommodation-title font-hurmegeometric-sans text-flint text-13px leading-16px uppercase font-bold m-0">
            {accommodation.products[0].name}
          </p>
          {isInstantBook ? <RateTag rateType={ERateType.INSTANT_BOOK} /> : null}
        </div>
        <div className="refundability-tag">
          {isRefundable !== null && (
            <RefundabilityTag
              refundabilityStatus={
                isRefundable ? ERefundabilityTagStatus.REFUNDABLE : ERefundabilityTagStatus.NON_REFUNDABLE
              }
              className="acc-free-sale-rates-tag flex items-center m-0"
            />
          )}
        </div>
        <div className="accommodation-data flex items-start justify-between">
          <div className="accommodation-data-left">
            <div className="dates-line flex justify-start mt-5px">
              <PlainDates
                checkInDate={requestedAccommodation.startDate}
                checkOutDate={format(addDays(offsetDate(requestedAccommodation.endDate), 1), 'yyyy-MM-dd')}
              />
              <p className="booking-adults font-hurmegeometric-sans text-13px leading-16px text-flint uppercase ml-5 m-0">
                {requestedAccommodation.guestAges?.numberOfAdults} x{' '}
                {requestedAccommodation.guestAges?.numberOfAdults === 1 ? 'ADULT' : 'ADULTS'}
              </p>
              {requestedAccommodation.guestAges?.agesOfAllChildren?.length ? (
                <p className="booking-children font-hurmegeometric-sans text-13px leading-16px text-flint uppercase ml-5 m-0">
                  {requestedAccommodation.guestAges.agesOfAllChildren?.length} x{' '}
                  {requestedAccommodation.guestAges.agesOfAllChildren?.length === 1 ? 'CHILD' : 'CHILDREN'}
                </p>
              ) : null}
              {requestedAccommodation.guestAges.agesOfAllChildren?.length >= 1 && (
                <span className="font-hurmegeometric-sans text-13px leading-16px text-flint uppercase ml-2 m-0">
                  ({requestedAccommodation.guestAges.agesOfAllChildren.map(ageNum => `${ageNum}`).join(', ')} YO)
                </span>
              )}
            </div>
            {offers.map((offer, offerIndex) => (
              <div
                key={`offer-${accommodation.products[0].uuid}${offerIndex}`}
                className="offer-item font-hurmegeometric-sans text-13px leading-16px text-red-92 uppercase mt-[5px] mr-[30px]"
              >
                {offer}
              </div>
            ))}
          </div>
          <div className="accommodation-data-right">
            <Price
              total={accommodation.total}
              totalBeforeDiscount={accommodation.totalBeforeDiscount}
              currencyCode={booking.response.currency}
              isOnRequest={accommodation.isOnRequestOrPartiallyOnRequest}
              totalClassName={`${priceClassName} text-flint`}
              totalBeforeDiscountClassName={`${priceClassName} text-gold-light line-through`}
              totalAfterDiscountClassName={`${priceClassName} text-red-15`}
              isOnRequestClassName={`${priceClassName} text-flint`}
            />
          </div>
        </div>

        {selectedMealPlan && (
          <div className="meal-plans mt-[10px]">
            <ProductLineWithPrice
              name={selectedMealPlan.products.map(p => p.name).join(', ')}
              total={selectedMealPlan.total}
              totalBeforeDiscount={selectedMealPlan.totalBeforeDiscount}
              currencyCode={booking.response.currency}
              isOnRequest={selectedMealPlan.isOnRequestOrPartiallyOnRequest}
              className="meal-plan"
            />
          </div>
        )}

        {extraPersonSupplements.length > 0 &&
          extraPersonSupplements.map(supplement => {
            return (
              <div key={supplement.products[0].uuid} className="meal-plans mt-[10px]">
                <ProductLineWithPrice
                  name={supplement.products.map(p => p.name).join(', ')}
                  total={supplement.total}
                  totalBeforeDiscount={supplement.totalBeforeDiscount}
                  currencyCode={booking.response.currency}
                  isOnRequest={supplement.isOnRequestOrPartiallyOnRequest}
                  className="supplement"
                />
              </div>
            );
          })}
      </div>
    );
  }
);
