import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import SingleDateInput from 'pureUi/SingleDateInput';
import { addDays, addYears, subDays } from 'date-fns';
import { PriceAsCentsInput } from 'ui/stateful/PriceAsCentsInput';
import FluidButton from 'ui/FluidButton';
import { formatDate } from 'utils';
import { adjustmentBalanceCarriedForwardTypes, initialBalanceCarriedForwardTypes } from './type_options';
import { FilePicker } from '../FilePicker';
import { RowTypeSelect } from '../RowTypeSelect';
import { colors } from 'pureUi/pureUiTheme';
import { Radio } from '../Radio';
import {
  bcfDataSelector,
  firstRowDateSelector,
  initialBCFRowExistsSelector,
  initialBCFRowLoadingSelector,
} from 'store/modules/ledger/selectors';
import { useSelector } from 'react-redux';
import { IManualBookingLedgerRowForSave } from 'store/modules/ledger/model';
import { isNegativeFinanceRow, isPositiveFinanceRow } from 'store/modules/bookingManager/subdomains/finance/utils';
import { EManualBookingLedgerRowType, IFinanceRow } from 'services/BookingManagerApi';
import { isAdjustmentType, isInitialType } from 'store/modules/ledger/utils';

export interface IBalanceCarriedForwardModalProps {
  onSubmit: (newRow: IManualBookingLedgerRowForSave, files: File[]) => void;
  submitButtonLoading: boolean;
  onClose: () => void;
}

export const StyledSingleDateInput = styled(SingleDateInput)`
  .pseudoSelect {
    height: 35px;
    background-color: ${colors.ivory};
  }
`;

export const BalanceCarriedForwardModal: React.FC<IBalanceCarriedForwardModalProps> = ({
  onClose,
  submitButtonLoading,
  onSubmit,
}) => {
  const data = useSelector(bcfDataSelector);
  const preselectedType = data?.type;
  const isEditing = data?.type !== undefined;
  const [stateRowType, setStateRowType] = useState<EManualBookingLedgerRowType | null>(preselectedType ?? null);
  const [stateDate, setDate] = useState<string | null>(data?.date || null);
  const [stateAmount, setAmount] = useState<number>(data?.amountCents || 0);
  const [stateUploadName, setUploadName] = useState<string | null>(data?.uploadName || null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const initialBCFRowLoading = useSelector(initialBCFRowLoadingSelector);
  const initialBCFRowExists =
    useSelector(initialBCFRowExistsSelector) || (preselectedType && isAdjustmentType(preselectedType));
  const typeOptions = useMemo(() => {
    return initialBCFRowExists ? adjustmentBalanceCarriedForwardTypes : initialBalanceCarriedForwardTypes;
  }, [initialBCFRowExists, preselectedType]);

  const firstRowDate = useSelector(firstRowDateSelector);
  const minDate = initialBCFRowExists && firstRowDate ? addDays(new Date(firstRowDate), 1) : addYears(new Date(), -150);
  const maxDate = !initialBCFRowExists && firstRowDate ? subDays(new Date(firstRowDate), 1) : addYears(new Date(), 150);

  const handleFileRemove = useCallback(() => {
    setUploadName(null);
  }, []);

  const handleFileSelect = useCallback((files: File[]) => {
    setSelectedFiles(files);
    const newUploadName = files.length > 0 ? files[0].name : null;
    setUploadName(newUploadName);
  }, []);

  const handleRowTypeSelect = useCallback((values: EManualBookingLedgerRowType[]) => {
    setStateRowType(values[0]);
  }, []);

  const isPositive = isPositiveFinanceRow({
    rowType: stateRowType,
  } as IFinanceRow);

  const isNegative = isNegativeFinanceRow({
    rowType: stateRowType,
  } as IFinanceRow);

  const isAmountGreaterThanOrEqualZero = stateAmount >= 0;
  const isDateSet = stateDate != null;
  let isButtonDisabled = !isAmountGreaterThanOrEqualZero || !isDateSet || !stateRowType;

  const handleAddClick = useCallback(() => {
    if (!stateDate || !stateRowType) {
      return;
    }

    const fileNotChanged = stateUploadName && stateUploadName === data?.uploadName;
    const newRow: IManualBookingLedgerRowForSave = {
      uuid: data?.uuid,
      date: stateDate,
      type: stateRowType,
      amountCents: stateAmount,
      uploadUuid: fileNotChanged ? data?.uploadUuid : undefined,
      uploadName: fileNotChanged ? data?.uploadName : undefined,
      uploadUrl: fileNotChanged ? data?.uploadUrl : undefined,
    };

    onSubmit(newRow, selectedFiles);
  }, [stateDate, selectedFiles, stateAmount, stateRowType, stateUploadName]);

  return (
    <div>
      <h3 className="modal-title m-0 font-noe-display mb-4 font-normal text-21px">Balance Carried Forward</h3>

      <div className="bcf-radio-container flex flex-col mt-25px mb-5">
        {!initialBCFRowExists || (preselectedType && isInitialType(preselectedType)) ? (
          <label className="initial-bcf cursor-pointer mb-10px">
            <Radio checked={true} />
            <span className="ml-2 text-black leading-21px">Add Initial Balance Carried Forward</span>
          </label>
        ) : (
          <label className="adjustment-bcf cursor-pointer mb-10px">
            <Radio checked={true} />
            <span className="ml-2 text-black leading-21px">Make Adjustments to the Balance Carried Forward</span>
          </label>
        )}
      </div>

      <RowTypeSelect
        className="bcf-row-type"
        selectedValue={stateRowType}
        isSelectedValuePositive={isPositive}
        isSelectedValueNegative={isNegative}
        options={typeOptions}
        onSelect={handleRowTypeSelect}
      />

      {/* date and amount */}
      <div className="flex mt-5">
        <label style={{ width: '122px' }}>
          <span className="block mb-5px text-black text-base font-bold leading-21px tracking-2xs font-pt-sans">
            Date
          </span>

          {initialBCFRowLoading ? (
            <div className="date-loading inline-flex justify-center items-center">
              <i className="text-xl fas fa-circle-notch fa-spin text-gray-30" />
            </div>
          ) : (
            <StyledSingleDateInput
              className="bcf-row-date"
              value={stateDate ? new Date(stateDate) : null}
              onChange={value => {
                setDate(formatDate(value));
              }}
              showYearDropdown
              enablePastDates
              minDate={minDate}
              maxDate={maxDate}
            />
          )}
        </label>

        <label className="bcf-row-amount w-116px block ml-20px">
          <span className="block mb-5px text-black text-base font-bold leading-21px tracking-2xs font-pt-sans">
            Amount
          </span>
          <PriceAsCentsInput
            cents={Math.abs(stateAmount)}
            className="focus:outline-gray-80 p-2 border border-solid border-gray-40 min-h-35px w-full font-pt-sans text-sm bg-ivory"
            disabled={false}
            onBlurUpdate={v => setAmount(v)}
          />
        </label>
      </div>

      <FilePicker
        isAttachmentRequired={false}
        uploadName={data?.uploadName}
        onFileSelect={handleFileSelect}
        onFileRemove={handleFileRemove}
      />

      <div className="bcf-buttons flex justify-start mt-25px">
        <FluidButton type="secondary" className="bcf-modal-cancel" onClick={onClose}>
          Cancel
        </FluidButton>
        <FluidButton
          type="primary"
          className="bcf-modal-save ml-10px"
          isLoading={submitButtonLoading}
          disabled={submitButtonLoading || isButtonDisabled}
          onClick={handleAddClick}
        >
          {isEditing ? 'Save' : 'Add'}
        </FluidButton>
      </div>
    </div>
  );
};
