import React, { useMemo } from 'react';
import classNames from 'classnames';
import { theme } from '../../../tailwind.config';
import { ETaskStatus } from 'services/TaskManagementApi/types/TaskManagementResponse';

export const statuses = [
  { value: ETaskStatus.BLOCKED, label: 'Blocked', color: theme.colors['status-cancelled'] },
  { value: ETaskStatus.BACKLOG, label: 'Backlog', color: theme.colors['gray-100'] },
  { value: ETaskStatus.TO_DO, label: 'To Do', color: theme.colors['purple'] },
  { value: ETaskStatus.IN_PROGRESS, label: 'In Progress', color: theme.colors['status-enquiry'] },
  { value: ETaskStatus.DONE, label: 'Done', color: theme.colors['green-hard'] },
];

export interface ITaskStatusProps {
  className?: string;
  value: ETaskStatus;
}

export const TaskStatus: React.FC<ITaskStatusProps> = (props) => {
  const status = useMemo(
    () => statuses.find(x => x.value === props.value),
    [props.value]
  );

  return (
    <div className={classNames(props.className, 'flex items-center justify-between w-full')}>
      <span>{status?.label ?? ''}</span>
      <div className="indicator" style={{
        height: '14px',
        width: '14px',
        borderRadius: '50%',
        backgroundColor: status?.color || '#000'
      }}/>
    </div>
  )
};
