import { IFinanceRow, EFinanceRowTypes } from 'services/BookingManagerApi/types';

const positiveTypes = [
  EFinanceRowTypes.Manual_Invoice_to_Travel_Agent,
  EFinanceRowTypes.Manual_Invoice_to_Final_Client,
  EFinanceRowTypes.Payment_to_Travel_Agent,
  EFinanceRowTypes.Payment_to_Final_Client,
  EFinanceRowTypes.Deposit_Transfer_Out,
  EFinanceRowTypes.Sales_Positive_Adjustment,
  EFinanceRowTypes.Purchase_Positive_Adjustment,
  EFinanceRowTypes.Manual_Cancellation_Fee,
  EFinanceRowTypes.Payment_to_Hotel,
  EFinanceRowTypes.Payment_to_Hotel_from_Final_Client,
  EFinanceRowTypes.Payment_to_Hotel_from_Travel_Agent,
  EFinanceRowTypes.Automatic_Invoice_to_Final_Client,
  EFinanceRowTypes.Automatic_Invoice_to_Travel_Agent,
  EFinanceRowTypes.Automatic_Proforma_to_Final_Client,
  EFinanceRowTypes.Automatic_Proforma_to_Travel_Agent,
  EFinanceRowTypes.Automatic_Cancellation_Fee,
  EFinanceRowTypes.Initial_Balance_Carried_Forward_Positive,
  EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Positive,
  EFinanceRowTypes.Automatic_Cancellation_Fee_Live,
  EFinanceRowTypes.Bank_Charges_Positive,
  EFinanceRowTypes.Payment_To_Non_Hotel_Supplier,
  EFinanceRowTypes.Automatic_Deposit_Transfer_Out,
  EFinanceRowTypes.Credit_Note_For_Guest_Out_Positive,
  EFinanceRowTypes.Credit_Note_For_Travel_Partner_Out_Positive,
  EFinanceRowTypes.Credit_Note_From_Hotel_Used_Positive,
  EFinanceRowTypes.Credit_Note_From_Non_Hotel_Supplier_Used_Positive,
  EFinanceRowTypes.Breakdown_Finance_Adjustment_Positive,
];

const negativeTypes = [
  EFinanceRowTypes.Manual_Margin_to_Travel_Agent,
  EFinanceRowTypes.Payment_from_Travel_Agent,
  EFinanceRowTypes.Payment_from_Final_Client,
  EFinanceRowTypes.Payment_from_Final_Client_to_hotel,
  EFinanceRowTypes.Deposit_Transfer_In,
  EFinanceRowTypes.Sales_Negative_Adjustment,
  EFinanceRowTypes.Purchase_Negative_Adjustment,
  EFinanceRowTypes.Manual_Cancellation_of_Invoice,
  EFinanceRowTypes.Manual_Credit_Note,
  EFinanceRowTypes.Manual_Hotel_Invoice,
  EFinanceRowTypes.Manual_Invoice_from_Supplier,
  EFinanceRowTypes.Purchase_Cost_Review_Hotel_Invoice,
  EFinanceRowTypes.Payment_Other,
  EFinanceRowTypes.Automatic_Discard_of_Invoice,
  EFinanceRowTypes.Automatic_Cancellation_of_Invoice,
  EFinanceRowTypes.Initial_Balance_Carried_Forward_Negative,
  EFinanceRowTypes.Adjustment_Balance_Carried_Forward_Negative,
  EFinanceRowTypes.Bank_Charges_Negative,
  EFinanceRowTypes.Credit_Note_For_Guest_In_Negative,
  EFinanceRowTypes.Credit_Note_For_Travel_Partner_In_Negative,
  EFinanceRowTypes.Credit_Note_From_Hotel_Negative,
  EFinanceRowTypes.Credit_Note_From_Non_Hotel_Supplier_Negative,
  EFinanceRowTypes.Breakdown_Finance_Adjustment_Negative,
];

const automaticTypes = [
  EFinanceRowTypes.Automatic_Invoice_to_Travel_Agent,
  EFinanceRowTypes.Automatic_Invoice_to_Final_Client,
  EFinanceRowTypes.Automatic_Proforma_to_Travel_Agent,
  EFinanceRowTypes.Automatic_Proforma_to_Final_Client,
  EFinanceRowTypes.Purchase_Cost_Review_Hotel_Invoice,
  EFinanceRowTypes.Automatic_Discard_of_Invoice,
  EFinanceRowTypes.Automatic_Cancellation_of_Invoice,
  EFinanceRowTypes.Automatic_Cancellation_Fee,
  EFinanceRowTypes.Automatic_Cancellation_Fee_Live,
  EFinanceRowTypes.Automatic_Deposit_Transfer_Out,
];

const proformaOrInvoiceTypes = [
  EFinanceRowTypes.Automatic_Invoice_to_Travel_Agent,
  EFinanceRowTypes.Automatic_Invoice_to_Final_Client,
  EFinanceRowTypes.Automatic_Proforma_to_Travel_Agent,
  EFinanceRowTypes.Automatic_Proforma_to_Final_Client,
];

export const isPositiveFinanceRow = (financeRow: IFinanceRow) => {
  return positiveTypes.includes(financeRow.rowType);
};

export const isNegativeFinanceRow = (financeRow: IFinanceRow) => {
  return negativeTypes.includes(financeRow.rowType);
};

export const isAutomaticFinanceRow = (financeRow: IFinanceRow) => {
  return automaticTypes.includes(financeRow.rowType);
};

export const isProformaOrInvoiceRow = (financeRow: IFinanceRow) => {
  return proformaOrInvoiceTypes.includes(financeRow.rowType);
};

export const isProformaInvoiceFinanceRow = (financeRow: IFinanceRow) => {
  return [
    EFinanceRowTypes.Automatic_Proforma_to_Final_Client,
    EFinanceRowTypes.Automatic_Proforma_to_Travel_Agent,
  ].includes(financeRow.rowType);
};

// OWA-4522
// For the first time there are Bank Account options that depend on payment type itself, rather than table type (purchase/slaes)
// Notice: We asume here that both "purchase" and "sales" special options need the same extra options, so we use a single boolean,
// May come the day when we need different options regarding table type, in which case we could split boolean cases here.
export const isNeedingExtraBankInfo = (financeRow: IFinanceRow) => {
  return [
    EFinanceRowTypes.Deposit_Transfer_In, // This is about "sales"
    EFinanceRowTypes.Payment_to_Hotel, // This is about "purchase"
  ].includes(financeRow.rowType);
};

export const isNeedingFileAttachment = (financeRow: IFinanceRow) => {
  return [
    EFinanceRowTypes.Manual_Invoice_to_Travel_Agent,
    EFinanceRowTypes.Manual_Invoice_to_Final_Client,
    EFinanceRowTypes.Manual_Cancellation_of_Invoice,
    EFinanceRowTypes.Manual_Hotel_Invoice,
    EFinanceRowTypes.Manual_Invoice_from_Supplier,
    EFinanceRowTypes.Purchase_Cost_Review_Hotel_Invoice,
  ].includes(financeRow.rowType);
};
