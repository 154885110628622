import { EBookingSettingsSelectedValues } from "./model";

export const GET_TA_USERS_REQUEST = 'bookingManager/settings/GET_TA_USERS_REQUEST';
export const GET_TA_USERS_SUCCESS = 'bookingManager/settings/GET_TA_USERS_SUCCESS';
export const GET_TA_USERS_FAILURE = 'bookingManager/settings/GET_TA_USERS_FAILURE';

export const SET_MANAGED_BY_UUID = 'bookingManager/settings/SET_MANAGED_BY_UUID';
export const SET_MANAGED_BY_DESCRIPTION = 'bookingManager/settings/SET_MANAGED_BY_DESCRIPTION';
export const ADD_MANAGED_BY = 'bookingManager/settings/ADD_MANAGED_BY';
export const REMOVE_MANAGED_BY = 'bookingManager/settings/REMOVE_MANAGED_BY';
export const SET_SELECTED_TA_COMPANY_UUID = 'bookingManager/settings/SET_SELECTED_TA_COMPANY_UUID';
export const SET_SELECTED_TA_USER_UUID = 'bookingManager/settings/SET_SELECTED_TA_USER_UUID';
export const CLEAR_SELECTED_VALUES_UUID = 'bookingManager/settings/CLEAR_SELECTED_VALUES_UUID';

export const OPEN_TA_MOVEMENTS_MODAL = 'bookingManager/OPEN_TA_MOVEMENTS_MODAL';
export const CLOSE_TA_MOVEMENTS_MODAL = 'bookingManager/CLOSE_TA_MOVEMENTS_MODAL';

// ta users
export type GetTaUsersRequestAction = ReturnType<typeof getTaUsersRequestAction>;
export const getTaUsersRequestAction = () => ({
  type: GET_TA_USERS_REQUEST as typeof GET_TA_USERS_REQUEST,
});

export type GetTaUsersSuccessAction = ReturnType<typeof getTaUsersSuccessAction>;
export const getTaUsersSuccessAction = (taUsers: any[]) => ({
  type: GET_TA_USERS_SUCCESS as typeof GET_TA_USERS_SUCCESS,
  taUsers,
});

export type GetTaUsersFailureAction = ReturnType<typeof getTaUsersFailureAction>;
export const getTaUsersFailureAction = (error: string) => ({
  type: GET_TA_USERS_FAILURE as typeof GET_TA_USERS_FAILURE,
  error,
});

export type SetManagedByUuidAction = ReturnType<typeof setManagedByUuidAction>;
export const setManagedByUuidAction = (idx: number, managedByUuid: string) => ({
  type: SET_MANAGED_BY_UUID as typeof SET_MANAGED_BY_UUID,
  managedByUuid,
  idx
});

export type SetManagedByDescriptionAction = ReturnType<typeof setManagedByDescriptionAction>;
export const setManagedByDescriptionAction = (idx: number, description: string) => ({
  type: SET_MANAGED_BY_DESCRIPTION as typeof SET_MANAGED_BY_DESCRIPTION,
  description,
  idx
});

export type AddManagedByAction = ReturnType<typeof addManagedByAction>;
export const addManagedByAction = () => ({
  type: ADD_MANAGED_BY as typeof ADD_MANAGED_BY,
});

export type RemoveManagedByAction = ReturnType<typeof removeManagedByAction>;
export const removeManagedByAction = (idx: number) => ({
  type: REMOVE_MANAGED_BY as typeof REMOVE_MANAGED_BY,
  idx
});

export type SetSelectedTaCompanyUuidAction = ReturnType<typeof setSelectedTaCompanyUuidAction>;
export const setSelectedTaCompanyUuidAction = (taCompanyUuid: string) => ({
  type: SET_SELECTED_TA_COMPANY_UUID as typeof SET_SELECTED_TA_COMPANY_UUID,
  taCompanyUuid,
});

export type SetSelectedTaUserUuidAction = ReturnType<typeof setSelectedTaUserUuidAction>;
export const setSelectedTaUserUuidAction = (taUserUuid: string) => ({
  type: SET_SELECTED_TA_USER_UUID as typeof SET_SELECTED_TA_USER_UUID,
  taUserUuid,
});

export type ClearSelectedValuesAction = ReturnType<typeof clearSelectedValuesAction>;
export const clearSelectedValuesAction = (values?: EBookingSettingsSelectedValues[]) => ({
  type: CLEAR_SELECTED_VALUES_UUID as typeof CLEAR_SELECTED_VALUES_UUID,
  values
});

export type OpenTaMovementsModalAction = ReturnType<typeof openTaMovementsModalAction>;
export const openTaMovementsModalAction = () => ({
  type: OPEN_TA_MOVEMENTS_MODAL as typeof OPEN_TA_MOVEMENTS_MODAL,
});

export type CloseTaMovementsModalAction = ReturnType<typeof closeTaMovementsModalAction>;
export const closeTaMovementsModalAction = () => ({
  type: CLOSE_TA_MOVEMENTS_MODAL as typeof CLOSE_TA_MOVEMENTS_MODAL,
});

export type SettingsAction =
  | GetTaUsersRequestAction
  | GetTaUsersSuccessAction
  | GetTaUsersFailureAction
  | SetManagedByUuidAction
  | SetManagedByDescriptionAction
  | AddManagedByAction
  | RemoveManagedByAction
  | SetSelectedTaCompanyUuidAction
  | SetSelectedTaUserUuidAction
  | ClearSelectedValuesAction
  | OpenTaMovementsModalAction
  | CloseTaMovementsModalAction;
