import { History } from 'history';
import { IInventoryContactForSaving, IInventorySupplierContracting, IInventorySupplierForSaving, IInventorySupplierSearchContractingParams, IInventorySupplierWithContacts } from "services/InventoryAPI/types/InventorySuppliers";

export const LIST_REQUEST = 'inventorySuppliers/LIST_REQUEST' as const;
export const LIST_SUCCESS = 'inventorySuppliers/LIST_SUCCESS' as const;
export const LIST_FAILURE = 'inventorySuppliers/LIST_FAILURE' as const;
  
export const SET_FILTER_ID = 'inventorySuppliers/SET_FILTER_ID' as const;
export const SET_FILTER_NAME = 'inventorySuppliers/SET_FILTER_NAME' as const;
export const SET_FILTER_COUNTRY_CODE = 'inventorySuppliers/SET_FILTER_COUNTRY_CODE' as const;

export const SET_ORDER = 'inventorySuppliers/SET_ORDER' as const;
export const SET_PAGE = 'inventorySuppliers/SET_PAGE' as const;
export const SET_PER_PAGE = 'inventorySuppliers/SET_PER_PAGE' as const;

export const GET_SUPPLIER_REQUEST = 'inventorySuppliers/GET_SUPPLIER_REQUEST';
export const GET_SUPPLIER_SUCCESS = 'inventorySuppliers/GET_SUPPLIER_SUCCESS';
export const GET_SUPPLIER_FAILURE = 'inventorySuppliers/GET_SUPPLIER_FAILURE';
export const GET_SUPPLIER_BY_PUBLIC_ID_REQUEST = 'inventorySuppliers/GET_SUPPLIER_BY_PUBLIC_ID_REQUEST';
export const GET_SUPPLIER_BY_PUBLIC_ID_SUCCESS = 'inventorySuppliers/GET_SUPPLIER_BY_PUBLIC_ID_SUCCESS';
export const GET_SUPPLIER_BY_PUBLIC_ID_FAILURE = 'inventorySuppliers/GET_SUPPLIER_BY_PUBLIC_ID_FAILURE';
export const RESET_SUPPLIER = 'inventorySuppliers/RESET_SUPPLIER';

export const PUT_SUPPLIER_REQUEST = 'inventorySuppliers/PUT_SUPPLIER_REQUEST';
export const PUT_SUPPLIER_SUCCESS = 'inventorySuppliers/PUT_SUPPLIER_SUCCESS';
export const PUT_SUPPLIER_FAILURE = 'inventorySuppliers/PUT_SUPPLIER_FAILURE';

export const POST_SUPPLIER_REQUEST = 'inventorySuppliers/POST_SUPPLIER_REQUEST';
export const POST_SUPPLIER_SUCCESS = 'inventorySuppliers/POST_SUPPLIER_SUCCESS';
export const POST_SUPPLIER_FAILURE = 'inventorySuppliers/POST_SUPPLIER_FAILURE';

export type ListRequestAction = ReturnType<typeof listRequestAction>;
export const listRequestAction = () => ({
  type: LIST_REQUEST as typeof LIST_REQUEST
});

export type ListSuccessAction = ReturnType<typeof listSuccessAction>;
export const listSuccessAction = (value: IInventorySupplierContracting[], count: number) => ({
  type: LIST_SUCCESS as typeof LIST_SUCCESS,
  value,
  count
});

export type ListFailueAction = ReturnType<typeof listFailureAction>;
export const listFailureAction = (error: string) => ({
  type: LIST_FAILURE as typeof LIST_FAILURE,
  error
});

export type SetFilterIdAction = ReturnType<typeof setFilterIdAction>;
export const setFilterIdAction = (value: string | undefined) => ({
  type: SET_FILTER_ID as typeof SET_FILTER_ID,
  value
});

export type SetFilterNameAction = ReturnType<typeof setFilterNameAction>;
export const setFilterNameAction = (value: string | undefined) => ({
  type: SET_FILTER_NAME as typeof SET_FILTER_NAME,
  value
});

export type SetFilterCountryCodeAction = ReturnType<typeof setFilterCountryCodeAction>;
export const setFilterCountryCodeAction = (value: string | undefined) => ({
  type: SET_FILTER_COUNTRY_CODE as typeof SET_FILTER_COUNTRY_CODE,
  value
});

export type SetOrderAction = ReturnType<typeof setOrderAction>;
export const setOrderAction = (
  orderBy: IInventorySupplierSearchContractingParams['order_by'],
  orderType: IInventorySupplierSearchContractingParams['order_type'],
) => ({
  type: SET_ORDER as typeof SET_ORDER,
  orderBy,
  orderType
});

export type SetPageAction = ReturnType<typeof setPageAction>;
export const setPageAction = (value: number) => ({
  type: SET_PAGE as typeof SET_PAGE,
  value
});

export type SetPerPageAction = ReturnType<typeof setPerPageAction>;
export const setPerPageAction = (value: number) => ({
  type: SET_PER_PAGE as typeof SET_PER_PAGE,
  value
});

export type PostSupplierRequestAction = ReturnType<typeof postSupplierRequestAction>;
export const postSupplierRequestAction = (supplierForSaving: IInventorySupplierForSaving, contacts: IInventoryContactForSaving[], history: History) => ({
  type: POST_SUPPLIER_REQUEST as typeof POST_SUPPLIER_REQUEST,
  supplierForSaving,
  contacts,
  history,
});

export type PostSupplierSuccessAction = ReturnType<typeof postSupplierSuccessAction>;
export const postSupplierSuccessAction = () => ({
  type: POST_SUPPLIER_SUCCESS as typeof POST_SUPPLIER_SUCCESS,
});

export type PostSupplierFailureAction = ReturnType<typeof postSupplierFailureAction>;
export const postSupplierFailureAction = (error: any) => ({
  type: POST_SUPPLIER_FAILURE as typeof POST_SUPPLIER_FAILURE,
  error,
});

export type PutSupplierRequestAction = ReturnType<typeof putSupplierRequestAction>;
export const putSupplierRequestAction = (supplierForSaving: IInventorySupplierForSaving, contacts: IInventoryContactForSaving[], history: History) => ({
  type: PUT_SUPPLIER_REQUEST as typeof PUT_SUPPLIER_REQUEST,
  supplierForSaving,
  contacts,
  history,
});

export type PutSupplierSuccessAction = ReturnType<typeof putSupplierSuccessAction>;
export const putSupplierSuccessAction = () => ({
  type: PUT_SUPPLIER_SUCCESS as typeof PUT_SUPPLIER_SUCCESS,
});

export type PutSupplierFailureAction = ReturnType<typeof putSupplierFailureAction>;
export const putSupplierFailureAction = (error: any) => ({
  type: PUT_SUPPLIER_FAILURE as typeof PUT_SUPPLIER_FAILURE,
  error,
});

export type GetSupplierRequestAction = ReturnType<typeof getSupplierRequestAction>;
export const getSupplierRequestAction = (supplierUuid: string) => ({
  type: GET_SUPPLIER_REQUEST as typeof GET_SUPPLIER_REQUEST,
  supplierUuid,
});

export type GetSupplierSuccessAction = ReturnType<typeof getSupplierSuccessAction>;
export const getSupplierSuccessAction = (supplier: IInventorySupplierWithContacts) => ({
  type: GET_SUPPLIER_SUCCESS as typeof GET_SUPPLIER_SUCCESS,
  supplier,
});

export type GetSupplierFailureAction = ReturnType<typeof getSupplierFailureAction>;
export const getSupplierFailureAction = (error: any) => ({
  type: GET_SUPPLIER_FAILURE as typeof GET_SUPPLIER_FAILURE,
  error,
});

export type GetSupplierByPublicIdRequestAction = ReturnType<typeof getSupplierByPublicIdRequestAction>;
export const getSupplierByPublicIdRequestAction = (supplierPublicId: string) => ({
  type: GET_SUPPLIER_BY_PUBLIC_ID_REQUEST as typeof GET_SUPPLIER_BY_PUBLIC_ID_REQUEST,
  supplierPublicId,
});

export type GetSupplierByPublicIdSuccessAction = ReturnType<typeof getSupplierByPublicIdSuccessAction>;
export const getSupplierByPublicIdSuccessAction = () => ({
  type: GET_SUPPLIER_BY_PUBLIC_ID_SUCCESS as typeof GET_SUPPLIER_BY_PUBLIC_ID_SUCCESS,
});

export type GetSupplierByPublicIdFailureAction = ReturnType<typeof getSupplierByPublicIdFailureAction>;
export const getSupplierByPublicIdFailureAction = (error: any) => ({
  type: GET_SUPPLIER_BY_PUBLIC_ID_FAILURE as typeof GET_SUPPLIER_BY_PUBLIC_ID_FAILURE,
  error,
});

export type ResetSupplierAction = ReturnType<typeof resetSupplierAction>;
export const resetSupplierAction = () => ({
  type: RESET_SUPPLIER as typeof RESET_SUPPLIER,
});

export type InventorySuppliersAction =
  | ListRequestAction
  | ListSuccessAction
  | ListFailueAction
  | SetFilterIdAction
  | SetFilterNameAction
  | SetFilterCountryCodeAction
  | SetOrderAction
  | SetPageAction
  | SetPerPageAction
  | PostSupplierRequestAction
  | PostSupplierSuccessAction
  | PostSupplierFailureAction
  | GetSupplierRequestAction
  | GetSupplierSuccessAction
  | GetSupplierFailureAction
  | GetSupplierByPublicIdRequestAction
  | GetSupplierByPublicIdSuccessAction
  | GetSupplierByPublicIdFailureAction
  | PutSupplierRequestAction
  | PutSupplierSuccessAction
  | PutSupplierFailureAction
  | ResetSupplierAction
;
