import { initialState, successReducer, errorReducer, sendingReducer } from 'store/common';
import { createReducer, getErrorActionName, getSuccessActionName } from 'store/utils';
import { USER_UPDATE } from './actions';

export default createReducer(
  {
    [USER_UPDATE]: sendingReducer,
    [getSuccessActionName(USER_UPDATE)]: successReducer,
    [getErrorActionName(USER_UPDATE)]: errorReducer,
  },
  initialState
);
