import { AxiosResponse } from 'axios';
import { produce } from 'immer';
import { call, takeLatest, select, put, all } from 'redux-saga/effects';
import {
  makeBookingManagerApi,
  IGetFinanceDocumentResponse,
  IPostFinanceDocumentResponse,
  IFinanceRow,
  IGetFinanceDocumentBalanceResponse,
  IPaymentMethodsGetInContextResponse,
  EFinanceRowTypes,
} from 'services/BookingManagerApi';
import { makeBackendApi, IUploadResponse, EUploadTag } from 'services/BackendApi';
import * as Actions from './actions';
import { bookingUuidSelector } from '../../selectors';
import * as Selectors from './selectors';
import { enqueueNotification, enqueueNotificationMultiple } from 'store/modules/ui';
import { EFinanceTableTypes, IFinanceDocument, IFinanceDocumentBalance } from './types';
import { selectedTaSelector } from '../../../agents';
import { getTopNavigationDataInlineRequestAction } from '../dashboard/actions';

const getFinanceUploadTag = (financeTableType: EFinanceTableTypes) => {
  return financeTableType === EFinanceTableTypes.SALES ? EUploadTag.FINANCE_SALES : EUploadTag.FINANCE_PURCHASE;
};

export function* getFinanceDocumentSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const financeTableType = yield select(Selectors.financeTableTypeToRenderSelector);

    const financeDocumentVersionList = yield select(Selectors.financeDocumentVersionListSelector);
    const activeVersion = yield select(Selectors.financeDocumentActiveVersionSelector);

    const activeVersionTimestamp = financeDocumentVersionList.find(v => v.version === activeVersion)?.timestamp;

    let result: AxiosResponse<IGetFinanceDocumentResponse>;
    switch (financeTableType) {
      case EFinanceTableTypes.SALES:
        result = yield call(bookingManagerApi.getFinanceSales, bookingUuid, activeVersionTimestamp);
        break;
      case EFinanceTableTypes.PURCHASE:
        result = yield call(bookingManagerApi.getFinancePurchase, bookingUuid, activeVersionTimestamp);
        break;
      default:
        result = yield call(bookingManagerApi.getFinanceSales, bookingUuid, activeVersionTimestamp);
        break;
    }

    const financeDocument: IFinanceDocument = {
      rows: result.data.rows,
    };

    yield put(Actions.getFinanceDocumentSuccessAction(financeDocument));
  } catch (e) {
    yield put(Actions.getFinanceDocumentFailureAction(e));
  }
}

export function* getFinanceDocumentBalanceSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const financeTableType = yield select(Selectors.financeTableTypeToRenderSelector);
    const financeDocumentVersionList = yield select(Selectors.financeDocumentVersionListSelector);
    const activeVersion = yield select(Selectors.financeDocumentActiveVersionSelector);

    const activeVersionTimestamp = financeDocumentVersionList.find(v => v.version === activeVersion)?.timestamp;

    let result: AxiosResponse<IGetFinanceDocumentBalanceResponse>;
    switch (financeTableType) {
      case EFinanceTableTypes.SALES:
        result = yield call(bookingManagerApi.getFinanceSalesBalance, bookingUuid, activeVersionTimestamp);
        break;
      case EFinanceTableTypes.PURCHASE:
        result = yield call(bookingManagerApi.getFinancePurchaseBalance, bookingUuid, activeVersionTimestamp);
        break;
      default:
        result = yield call(bookingManagerApi.getFinanceSalesBalance, bookingUuid, activeVersionTimestamp);
        break;
    }

    const financeDocument: IFinanceDocumentBalance = {
      totalCreditCents: result.data.totalCreditCents,
      totalDebitCents: result.data.totalDebitCents,
      totalOutstandingCents: result.data.totalOutstandingCents,
    };

    yield put(Actions.getFinanceDocumentBalanceSuccessAction(financeDocument));
  } catch (e) {
    yield put(Actions.getFinanceDocumentBalanceFailureAction(e));
  }
}

export function* addRowAndSaveSaga(action: Actions.AddRowAndSaveRequestAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const financeDocument: IFinanceDocument = yield select(Selectors.financeDocumentSelector);

    const financeRow = action.financeRowWithoutUpload;

    if (action.files.length >= 1) {
      // if we have a file, upload it
      const financeTableType = yield select(Selectors.financeTableTypeToRenderSelector);
      const file = action.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('tag', getFinanceUploadTag(financeTableType));
      formData.append('displayName', file.name);
      formData.append('ownerType', 'Booking');
      formData.append('ownerUuid', bookingUuid);

      // if the upload works, attach the upload uuid and url to the row
      const uploadResult: AxiosResponse<IUploadResponse> = yield call(backendApi.uploadFile, formData);
      financeRow.uploadUuid = uploadResult.data.data.uuid;
      financeRow.uploadUrl = uploadResult.data.data.url;
      financeRow.uploadName = uploadResult.data.data.filename;
    }

    // make a new finance document, with the new row
    const newFinanceDocument = produce(financeDocument, draft => {
      draft.rows.push(financeRow);
      return draft;
    });

    // patch the document
    const financeTableType = yield select(Selectors.financeTableTypeToRenderSelector);
    let patchFinanceDocumentResponse: AxiosResponse<IPostFinanceDocumentResponse>;
    switch (financeTableType) {
      case EFinanceTableTypes.SALES:
        patchFinanceDocumentResponse = yield call(bookingManagerApi.patchFinanceSales, bookingUuid, newFinanceDocument);
        break;
      case EFinanceTableTypes.PURCHASE:
        patchFinanceDocumentResponse = yield call(
          bookingManagerApi.patchFinancePurchase,
          bookingUuid,
          newFinanceDocument
        );
        break;
      default:
        patchFinanceDocumentResponse = yield call(bookingManagerApi.patchFinanceSales, bookingUuid, newFinanceDocument);
        break;
    }

    yield put(Actions.addRowAndSaveSuccessAction(patchFinanceDocumentResponse.data.rows));
    yield put(Actions.getFinanceDocumentVersionListRequestAction());
    yield put(getTopNavigationDataInlineRequestAction());
    if (
      financeRow.rowType === EFinanceRowTypes['Breakdown_Finance_Adjustment_Positive'] ||
      financeRow.rowType === EFinanceRowTypes['Breakdown_Finance_Adjustment_Negative']
    ) {
      yield put(
        enqueueNotificationMultiple([
          {
            message: 'Finance row added successfully',
            options: { variant: 'success' },
          },
          {
            message: 'Breakdown updated successfully',
            options: { variant: 'success' },
          },
        ])
      );
    } else {
      yield put(
        enqueueNotification({
          message: 'Finance row added successfully',
          options: { variant: 'success' },
        })
      );
    }
  } catch (e) {
    yield put(Actions.addRowAndSaveFailureAction('Failed to add finance row. Please try again.'));
  }
}

export function* deleteRowAndSaveSaga(action: Actions.DeleteRowAndSaveRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const financeDocument: IFinanceDocument = yield select(Selectors.financeDocumentSelector);
    const deleteRowIndex = yield select(Selectors.deleteRowIndexSelector);

    // we cant do anything without an index with which to delete
    if (deleteRowIndex == null) {
      yield put(Actions.deleteRowAndSaveFailureAction('Cant delete a row without an index'));
      yield put(
        enqueueNotification({
          message: 'Failed to delete finance row. Please try again.',
          options: { variant: 'error' },
        })
      );
      return;
    }

    // looking for the "delete uploads here" code?
    // @see https://pureescapes.atlassian.net/browse/OWA-2534
    // tl;dr we no longer delete uploads for historical archive reasons

    // make a new finance document, without the row
    const newFinanceDocument = produce(financeDocument, draft => {
      draft.rows.splice(deleteRowIndex, 1);
      return draft;
    });

    // patch the document
    const financeTableType = yield select(Selectors.financeTableTypeToRenderSelector);
    let patchFinanceDocumentResponse: AxiosResponse<IPostFinanceDocumentResponse>;
    switch (financeTableType) {
      case EFinanceTableTypes.SALES:
        patchFinanceDocumentResponse = yield call(bookingManagerApi.patchFinanceSales, bookingUuid, newFinanceDocument);
        break;
      case EFinanceTableTypes.PURCHASE:
        patchFinanceDocumentResponse = yield call(
          bookingManagerApi.patchFinancePurchase,
          bookingUuid,
          newFinanceDocument
        );
        break;
      default:
        patchFinanceDocumentResponse = yield call(bookingManagerApi.patchFinanceSales, bookingUuid, newFinanceDocument);
        break;
    }

    yield put(Actions.deleteRowAndSaveSuccessAction(patchFinanceDocumentResponse.data.rows));
    yield put(Actions.getFinanceDocumentVersionListRequestAction());
    yield put(getTopNavigationDataInlineRequestAction());
    yield put(
      enqueueNotification({
        message: 'Finance row deleted successfully',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(Actions.deleteRowAndSaveFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Failed to delete finance row. Please try again.',
        options: { variant: 'error' },
      })
    );
    console.log(e);
  }
}

export function* editRowAndSaveSaga(action: Actions.EditRowAndSaveRequestAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const financeDocument: IFinanceDocument = yield select(Selectors.financeDocumentSelector);
    const editRowIndex = yield select(Selectors.editRowIndexSelector);

    // we cant do anything without an index with which to edit
    if (editRowIndex == null) {
      yield put(Actions.editRowAndSaveFailureAction('Failed to edit finance row. Please try again.'));
      yield put(
        enqueueNotification({
          message: 'Failed to edit finance row. Please try again.',
          options: { variant: 'error' },
        })
      );
      return;
    }

    const amendedFinanceRow = action.amendedFinanceRow;
    const existingFinanceRow: IFinanceRow = {
      ...financeDocument.rows[editRowIndex],
    };

    // if we have any `action.files`, it means they've either;
    //    - added a file to a row that didn't have one
    //    - replaced a file on a row that had one already
    //    either way, we need to upload it
    if (action.files.length >= 1) {
      const financeTableType = yield select(Selectors.financeTableTypeToRenderSelector);
      const file = action.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('tag', getFinanceUploadTag(financeTableType));
      formData.append('displayName', file.name);
      formData.append('ownerType', 'Booking');
      formData.append('ownerUuid', bookingUuid);

      // if the upload works, attach the upload data to the row
      const uploadResult: AxiosResponse<IUploadResponse> = yield call(backendApi.uploadFile, formData);
      amendedFinanceRow.uploadUuid = uploadResult.data.data.uuid;
      amendedFinanceRow.uploadUrl = uploadResult.data.data.url;
      amendedFinanceRow.uploadName = uploadResult.data.data.filename;
    } else if (amendedFinanceRow.uploadName === null && existingFinanceRow.uploadName !== null) {
      // if the existing row had an upload, and they've deleted it - we need to delete
      // that upload and set the properties to null
      amendedFinanceRow.uploadUuid = null;
      amendedFinanceRow.uploadUrl = null;
      amendedFinanceRow.uploadName = null;
    } else {
      // if we're NOT uploading a new file, then the upload data is the same as
      // whats currently in the store anyway
      amendedFinanceRow.uploadUuid = existingFinanceRow.uploadUuid;
      amendedFinanceRow.uploadUrl = existingFinanceRow.uploadUrl;
      amendedFinanceRow.uploadName = existingFinanceRow.uploadName;
    }

    // make a new finance document, and replace the row at `action.index` with the one we just edited
    const newFinanceDocument = produce(financeDocument, draft => {
      draft.rows[editRowIndex] = amendedFinanceRow;
      return draft;
    });

    // patch the document
    const financeTableType = yield select(Selectors.financeTableTypeToRenderSelector);
    let patchFinanceDocumentResponse: AxiosResponse<IPostFinanceDocumentResponse>;
    switch (financeTableType) {
      case EFinanceTableTypes.SALES:
        patchFinanceDocumentResponse = yield call(bookingManagerApi.patchFinanceSales, bookingUuid, newFinanceDocument);
        break;
      case EFinanceTableTypes.PURCHASE:
        patchFinanceDocumentResponse = yield call(
          bookingManagerApi.patchFinancePurchase,
          bookingUuid,
          newFinanceDocument
        );
        break;
      default:
        patchFinanceDocumentResponse = yield call(bookingManagerApi.patchFinanceSales, bookingUuid, newFinanceDocument);
        break;
    }

    // looking for the "delete old uploads here" code?
    // @see https://pureescapes.atlassian.net/browse/OWA-2534
    // tl;dr we no longer delete old uploads for historical archive reasons

    yield put(Actions.editRowAndSaveSuccessAction(patchFinanceDocumentResponse.data.rows));
    yield put(Actions.getFinanceDocumentVersionListRequestAction());
    yield put(getTopNavigationDataInlineRequestAction());
    yield put(
      enqueueNotification({
        message: 'Finance row edited successfully',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(Actions.editRowAndSaveFailureAction('Failed to edit finance row. Please try again.'));
  }
}

export function* setFinanceTableTypeSaga() {
  // when we change the type of finance table, we need to load the data again
  yield put(Actions.getFinanceDocumentRequestAction());
  yield put(Actions.getFinanceDocumentBalanceRequestAction());
}

export function* getFinanceDocumentVersionListSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const financeTableType = yield select(Selectors.financeTableTypeToRenderSelector);

    let result: AxiosResponse;
    switch (financeTableType) {
      case EFinanceTableTypes.SALES:
        result = yield call(bookingManagerApi.getFinanceSalesVersionList, bookingUuid);
        break;
      case EFinanceTableTypes.PURCHASE:
        result = yield call(bookingManagerApi.getFinancePurchasesVersionList, bookingUuid);
        break;
      default:
        result = yield call(bookingManagerApi.getFinanceSalesVersionList, bookingUuid);
        break;
    }

    yield put(Actions.getFinanceDocumentVersionListSuccessAction(result.data));
    yield put(Actions.setFinanceDocumentActiveVersionRequestAction(result.data[0].version));
  } catch (e) {
    yield put(Actions.getFinanceDocumentVersionListFailureAction(e));
  }
}

export function* getPaymentMethodsSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IPaymentMethodsGetInContextResponse> = yield call(
      bookingManagerApi.getBookingPaymentMethods,
      bookingUuid
    );
    yield put(Actions.getPaymentMethodsSuccessAction(result.data.paymentMethods, result.data.defaultPaymentMethodCode));
  } catch (e) {
    yield put(Actions.getPaymentMethodsFailureAction());
    yield put(
      enqueueNotification({
        message: 'Failed to fetch booking payment methods',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchBookingManagerFinance() {
  yield takeLatest([Actions.GET_FINANCE_DOCUMENT_REQUEST], getFinanceDocumentSaga);
  yield takeLatest([Actions.GET_FINANCE_DOCUMENT_BALANCE_REQUEST], getFinanceDocumentBalanceSaga);
  yield takeLatest([Actions.ADD_ROW_AND_SAVE_REQUEST], addRowAndSaveSaga);
  yield takeLatest([Actions.DELETE_ROW_AND_SAVE_REQUEST], deleteRowAndSaveSaga);
  yield takeLatest([Actions.EDIT_ROW_AND_SAVE_REQUEST], editRowAndSaveSaga);
  yield takeLatest([Actions.SET_FINANCE_TABLE_TYPE_TO_RENDER], setFinanceTableTypeSaga);
  yield takeLatest([Actions.GET_FINANCE_DOCUMENT_VERSION_LIST_REQUEST], getFinanceDocumentVersionListSaga);
  yield takeLatest([Actions.GET_PAYMENT_METHODS_REQUEST], getPaymentMethodsSaga);
}
