import { LiveRatesSubdomain, liveRatesInitialState } from './model';
import * as Actions from './actions';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { produce } from 'immer';

export const liveRatesReducer = (
  state: LiveRatesSubdomain = liveRatesInitialState,
  action: Actions.LiveRatesAction
): LiveRatesSubdomain => {
  switch (action.type) {
    case Actions.GET_LIVE_RATES_REQUEST: {
      return produce(state, draftState => {
        draftState.networkRequests.getLiveRates = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    }

    case Actions.GET_LIVE_RATES_SUCCESS: {
      return produce(state, draftState => {
        draftState.networkRequests.getLiveRates = ENetworkRequestStatus.SUCCESS;

        draftState.currency = action.liveRatesData.currency;
        draftState.liveRates = action.liveRatesData.Accommodation;

        return draftState;
      });
    }

    case Actions.GET_LIVE_RATES_FAILURE: {
      return produce(state, draftState => {
        draftState.networkRequests.getLiveRates = ENetworkRequestStatus.ERROR;
        return draftState;
      });
    }

    default:
      return state;
  }
};
