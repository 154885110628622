import { ENetworkRequestStatus, EUploadTag } from 'services/BackendApi';

import { initialState, loadingReducer, errorReducer } from 'store/common';
import { createReducer, getErrorActionName } from 'store/utils';
import { IUpload } from '../bookingManager/sagas/bookingTransforms/BookingModel';

import * as HotelActions from './actions';
import { IHotelDomain } from './types';

export default createReducer(
  {
    [HotelActions.FETCH_HOTEL_REQUEST]: loadingReducer,
    [HotelActions.FETCH_HOTEL_SUCCESS]: (
      state: IHotelDomain,
      action: ReturnType<typeof HotelActions.fetchHotelSuccessAction>
    ): IHotelDomain => {
      return {
        ...state,
        status: ENetworkRequestStatus.SUCCESS,
        hotel: {
          ...action.normalizedData.entities.hotels[state.hotelUuid],
        },
        photos: Object.values(action.normalizedData.entities.uploads || {}).filter((u: IUpload) => u.tag === 'photo'),
        brochures: [
          ...Object.values(action.normalizedData.entities.uploads || {}).filter((u: IUpload) => u.tag === 'resortMap'),
          ...Object.values(action.normalizedData.entities.uploads || {}).filter((u: IUpload) => u.tag === 'brochure'),
        ],
      };
    },
    [HotelActions.FETCH_HOTEL_FAILURE]: (
      state: IHotelDomain,
      action: ReturnType<typeof HotelActions.fetchHotelFailureAction>
    ): IHotelDomain => {
      return {
        ...state,
        error: action.error,
      };
    },
    // @ts-ignore legacy
    [getErrorActionName(HotelActions.HOTEL)]: errorReducer,
    [HotelActions.SET_HOTEL_UUID]: (
      state: IHotelDomain,
      action: ReturnType<typeof HotelActions.setHotelUuidAction>
    ): IHotelDomain => {
      return {
        ...state,
        hotelUuid: action.uuid,
      };
    },
    [HotelActions.CLEAR_HOTEL]: (
      state: IHotelDomain,
      action: ReturnType<typeof HotelActions.setHotelUuidAction>
    ): IHotelDomain => {
      return {
        ...state,
        hotel: null,
      };
    },
  },
  initialState
);
