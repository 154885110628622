export enum EBespokeNegotiationDiscountType {
  FLAT = 'flat',
  PERCENTAGE = 'percentage',
}

export enum EBespokeNegotiationApplication {
  PURCHASE_COST = 'Purchase Cost',
  COST_TO_TRAVEL_PARTNER = 'Cost to Travel Partner',
}

export enum EBespokeNegotiationAffectedItems {
  ACCOMMODATIONS = 'Accommodations',
  MEAL_PLANS = 'Meal Plans',
  EXTRA_PERSON_SUPPLEMENTS = 'Extra Person Supplements',
  TRANSFERS = 'Transfers',
  SUPPLEMENTS = 'Supplements',
  EXTRA_ITEMS = 'Activities',
}

export interface IBespokeNegotiationDiscount {
  type: EBespokeNegotiationDiscountType;
  amount: number;
}

export interface IBespokeNegotiationLead {
  uuid: string;
  email: string;
}

export interface IBespokeNegotiationItem {
  version?: string;
  lead: IBespokeNegotiationLead;
  discount: IBespokeNegotiationDiscount;
  appliedTo: EBespokeNegotiationApplication[];
  affectedItems: EBespokeNegotiationAffectedItems[];
}

export interface IBespokeNegotiationResponse {
  bespokeNegotiation: IBespokeNegotiationItem[];
}
