import React from 'react';
import classNames from 'classnames';

import { BookingAccommodation } from './BookingAccommodation';
import { BookingNonAccommodationProduct } from './BookingNonAccommodationProduct';
import { ShowMoreText } from 'ui/ShowMoreText/ShowMoreText';
import { BulletPoints } from 'ui/BulletPoints/BulletPoints';
import { composeCancellationPolicyFromRoomExpenseInfo, extractCancellationPolicies } from 'common-lib/cancellation-policy-composer';
import { addDays, format } from 'date-fns';
import { BookingBuilder } from 'services/BackendApi';
import { LodgingSummary } from 'interfaces';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import { useSelector } from 'react-redux';
import { getCurrencySymbol } from 'utils';

interface ITitleProps {
  children: React.ReactNode;
  className?: string;
}

const Title: React.FC<ITitleProps> = React.memo(
  ({ children, className }) => {
    return (
      <p className={classNames('nap-title font-hurmegeometric-sans text-brown-prime text-13px leading-16px uppercase font-semibold m-0', className)}>
        {children}
      </p>
    );
  }
);

type OfferTermsItem = {
  name: string;
  termsAndConditions: string;
}

interface IBookingAccommodationExpandInfoProps {
  booking: BookingBuilder;
  lodgingSummaries: LodgingSummary[];
}

export const BookingHotelExpandableInfo: React.FC<IBookingAccommodationExpandInfoProps> = React.memo(({ booking, lodgingSummaries }) => {
  const startDate = booking.request.startDate;
  const endDate = format(addDays(new Date(booking.request.endDate), 1), 'yyyy-MM-dd');
  const otherItems = [...booking.response.availableProductSets.Supplement, ...booking.response.availableProductSets.Fine];
  const cancellationPolicies = useSelector(BookingBuilderSelectors.bookingCancellationPoliciesSelector);
  const paymentTerms = useSelector(BookingBuilderSelectors.bookingPaymentTermsSelector);
  const offerTerms = useSelector(BookingBuilderSelectors.bookingOffersTermsSelector) as OfferTermsItem[];
  
  return (
    <div className="booking-expand-info mt-5 bg-white border border-solid border-gray-20">
      {booking.response.availableProductSets.Accommodation.map((accommodation, accommodationIndex) => (
        <BookingAccommodation
          key={accommodation.products[0].uuid}
          booking={booking}
          accommodation={accommodation}
          accommodationIndex={accommodationIndex}
          lodgingSummaries={lodgingSummaries}
          startDate={startDate}
          endDate={endDate}
        />
      ))}

      {booking.response.availableProductSets.Transfer.filter(item => item.selected).length > 0 && (
        <BookingNonAccommodationProduct
          title="Transfers"
          products={booking.response.availableProductSets.Transfer}
          currencyCode={booking.response.currency}
          className="transfer"
        />
      )}

      {booking.response.availableProductSets['Ground Service'].filter(item => item.selected).length > 0 && (
        <BookingNonAccommodationProduct
          title="Ground Services"
          products={booking.response.availableProductSets['Ground Service']}
          currencyCode={booking.response.currency}
          className="ground-service"
        />
      )}

      {otherItems.filter(item => item.selected).length > 0 && (
        <BookingNonAccommodationProduct
          title="Other Items"
          products={otherItems}
          currencyCode={booking.response.currency}
          className="other-items"
        />
      )}

      {booking.response.hotel.additionalInfo ? (
        <div className="booking-long-text-container flex flex-col gap-[15px] py-15px border-solid border-b border-gray-20">
          <div className="booking-long-text px-5">
            <Title>
              THINGS TO BE AWARE OF WITH THIS RESORT:
            </Title>
  
            <div className="mt-[5px]">
              <ShowMoreText
                linkClassname="font-hurmegeometric-sans text-[13px] leading[20px] text-brown-prime cursor-pointer underline ml-[5px]"
                textClassname="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint m-0"
                text={booking.response.hotel.additionalInfo}
                linkLabelWhenCollapsed="Show more"
                linkLabelWhenOpen="Show less"
                maxVisibleCharacters={383}
              />
            </div>
          </div>
        </div>
      ) : null}

      {booking.response.hotel.policiesAndRestrictions?.length ? (
        <div className="booking-long-text-container flex flex-col gap-[15px] py-15px border-solid border-b border-gray-20">
          <div className="booking-long-text px-5   ">
            <Title>
              POLICIES & RESTRICTIONS:
            </Title>

            <div className="policies-restrictions mt-[5px]">
              <BulletPoints
                textLines={booking.response.hotel.policiesAndRestrictions}
                ulClassname=" pl-[12px] mt-[5px]"
                liClassname="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint m-0"
              />
            </div>
          </div>
        </div>
      ) : null}

      {cancellationPolicies ? (
        <div className="booking-long-text-container flex flex-col gap-[15px] py-15px border-solid border-b border-gray-20">
          <div className="booking-long-text px-5">
            <Title>
              CANCELLATION POLICY:
            </Title>

            <div className="cancellation-policies mt-[5px]">
              <ul className="mt-[5px] pl-[12px]">
                {booking.response.expenseInfosGroupedByRoom.map((item, index) => {
                  const cancellationPoliciesLines = composeCancellationPolicyFromRoomExpenseInfo(item, {
                    currencySymbol: getCurrencySymbol(booking.response.currency),
                    appendLines: ['*at 00.00 time at destination'],
                  });
                  return (
                    <li key={index} className="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint mt-[7px] p-0">Accommodation {index + 1}
                      <BulletPoints
                        textLines={cancellationPoliciesLines}
                        ulClassname=" pl-[12px] mt-[5px] list-disc m-0 p-0"
                        liClassname="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint m-0"
                      />
                    </li>
                  )
                })}
                {extractCancellationPolicies(booking.response.potentialBooking.Fine).map((product, index) => {
                  return (
                    <li key={index} className="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint mt-[7px] p-0">
                      {product.cancellationPolicy}
                    </li>
                  )
                })}
                {extractCancellationPolicies(booking.response.potentialBooking['Ground Service']).map((cp, index) => {
                  return (
                    <li key={index} className="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint mt-[7px] p-0">
                      {cp}
                    </li>
                  )
                })}
                {extractCancellationPolicies(booking.response.potentialBooking.Supplement).map((cp, index) => {
                  return (
                    <li key={index} className="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint mt-[7px] p-0">
                      {cp}
                    </li>
                  )
                })}
                {extractCancellationPolicies(booking.response.potentialBooking.Transfer).map((cp, index) => {
                  return (
                    <li key={index} className="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint mt-[7px] p-0">
                      {cp}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      ) : null}

      {paymentTerms?.length ? (
        <div className="booking-long-text-container flex flex-col gap-[15px] py-15px border-solid border-b border-gray-20">
          <div className="booking-long-text px-5">
            <Title>
              PAYMENT TERMS:
            </Title>

            <div className="payment-terms mt-[5px]">
              <BulletPoints
                textLines={paymentTerms}
                ulClassname=" pl-[12px] mt-[5px]"
                liClassname="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint m-0"
              />
            </div>
          </div>
        </div>
      ) : null}

      {offerTerms?.length ? (
        <div className="booking-long-text-container flex flex-col gap-[15px] py-15px border-solid border-b border-gray-20">
          <div className="booking-long-text px-5">
            <Title>
              OFFER TERMS:
            </Title>

            <div className="offer-terms mt-[5px]">
              <ul className="mt-[5px] pl-[12px]">
                {offerTerms.map((item, index) => {
                  return (
                    <li key={index} className="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint mt-[7px] p-0">{item.name}
                      <BulletPoints
                        textLines={item.termsAndConditions.split('\n')}
                        ulClassname="pl-[12px] mt-[5px] list-disc m-0 p-0"
                        liClassname="font-hurmegeometric-sans text-[13px] leading-[20px] text-flint m-0"
                      />
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
});
