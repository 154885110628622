import styled from 'styled-components';

export const BookingManagerDashboardStyles = styled.div`
  display: grid;
  grid-gap: 30px;

  &.twoColumns {
    grid-template-columns: 489fr 461fr;
  }

  &.threeColumns {
    grid-template-columns: minmax(0, 489fr) minmax(0, 283fr) minmax(0, 178fr);
  }
    
  &.tasksColumn {
    grid-template-columns: 489fr 461fr;
  }
`;
