import * as React from 'react';
import { TextBox } from 'pureUi/Textbox/Textbox';

export interface IBreakdownTextAreaProps {
  className?: string;
  title: React.ReactNode;
  text: string;
  onChange: (text: string) => void;
  disabled?: boolean;
}

export const BreakdownTextArea: React.FC<IBreakdownTextAreaProps> = (props) => {
    return (
      <div className={`textarea-container ${props.className ?? ''} flex`}>
        <p className="flex flex-20 m-0 font-bold text-17px leading-md text-black-true">
          {props.title}
        </p>
        <div className="flex flex-80">
          <TextBox
            className="m-0 w-full"
            text={props.text}
            disabled={props.disabled || false}
            onTextChange={props.onChange}
            buttonVisible={false}
            isSaving={false}
          />
        </div>
      </div>
    );
};

export default BreakdownTextArea;