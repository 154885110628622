import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const MapIcon: React.FC<IIconProps> = React.memo(
  ({ background = 'white', border = 'brown-100', icon = 'brown-100' }) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0.52832C7.705 0.52832 1 7.23332 1 15.5283C1 23.8233 7.705 30.5283 16 30.5283C24.295 30.5283 31 23.8233 31 15.5283C31 7.23332 24.295 0.52832 16 0.52832Z"
        fill={theme.colors[background]}
        stroke={theme.colors[border]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 20.886L13.9756 22.5283L18.8538 19.768L23 21.4103V10.2132L18.7462 8.52832L13.8244 11.3132L9 9.72078V20.886ZM17.4 18.994L14.6 20.5783V12.471L17.4 10.8867V18.994ZM18.8 18.2536L21.6 19.3627V11.1517L18.8538 10.064L18.8 10.0945V18.2536ZM10.4 19.8869L13.2 20.8111V12.5683L10.4 11.6442V19.8869Z"
        fill={theme.colors[icon]}
      />
    </svg>
  )
);

export default MapIcon;
