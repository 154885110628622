import { createSelector } from 'reselect';
import { IFinancesDomain } from './model';

export const domainSelector = (state: any): IFinancesDomain => state.finances;

export const disableTaAccessToFinanceSelector = createSelector(
  domainSelector,
  domain => domain.disableTaAccessToFinance
);

export const enableAccessForManagersToFinanceSelector = createSelector(
  domainSelector,
  domain => domain.enableAccessForManagersToFinance
);

export const enableInstantBookingSelector = createSelector(
  domainSelector,
  domain => domain.enableInstantBooking
);

export const creditLimitUsdSelector = createSelector(
  domainSelector,
  domain => domain.creditLimitUsd
);

export const creditLimitEurSelector = createSelector(
  domainSelector,
  domain => domain.creditLimitEur
);

export const isTouchedSelector = createSelector(
  domainSelector,
  domain => domain.isTouched
);

export const requestSelector = createSelector(
  domainSelector, 
  domain => domain.request
);
