import React, { useMemo } from 'react';

import UsdIcon from 'ui/Icons/currency.usd.component.svg';
import EurIcon from 'ui/Icons/currency.eur.component.svg';

import { format } from 'ui/Ledger/utils';
import { TCurrencyCode } from 'interfaces';
import { SvgIcon } from 'ui/SvgIcon';

interface ITCBookingLedgerHeadline {
  currency: TCurrencyCode;
  invoiceAmountTotal: number;
  paidAmountTotal: number;
  balanceToPayTotal: number;
  overdueTotal: number;
}

export const TCBookingLedgerHeadline: React.FC<ITCBookingLedgerHeadline> = React.memo(
  ({ currency, invoiceAmountTotal, paidAmountTotal, balanceToPayTotal, overdueTotal }) => {
    const Classes = useMemo(
      () => ({
        container: 'flex w-full h-20 py-10px text-black items-center mb-35px',
        column: 'flex flex-col px-30px justify-center',
        columnBorder: 'border-r border-solid border-r-gray-20',
        columnLabel: 'text-xs text-gray-100 tracking-2xs uppercase',
        columnValue: 'text-21px text-left font-bold mt-5px',
        currencyValue: 'flex text-center font-bold mt-2px m-auto',
      }),
      []
    );

    const formatAmount = useMemo(() => format.amount(currency), [currency]);

    return (
      <div className={`tc-booking-ledger-headline ${Classes.container}`}>
        <div className="flex grow justify-between items-center">
          <div className="left-side flex">
            <div
              className={`tc-booking-ledger-total-invoiced flex flex-col pl-15px pr-30px justify-center ${Classes.columnBorder}`}
            >
              <span className={Classes.columnLabel}>TOTAL INVOICED</span>
              <span className={Classes.columnValue}>{formatAmount(invoiceAmountTotal)}</span>
            </div>

            <div className={`tc-booking-ledger-paid-amount-total ${Classes.column} ${Classes.columnBorder}`}>
              <span className={Classes.columnLabel}>TOTAL PAID</span>
              <span className={Classes.columnValue}>{formatAmount(paidAmountTotal)}</span>
            </div>

            <div className={`tc-booking-ledger-balance-to-pay ${Classes.column} ${Classes.columnBorder}`}>
              <span className={Classes.columnLabel}>TOTAL TO PAY</span>
              <span className={Classes.columnValue} style={balanceToPayTotal < 0 ? { color: '#FD4B4C' } : {}}>
                {formatAmount(balanceToPayTotal)}
              </span>
            </div>

            <div className={`tc-booking-ledger-overdue-total ${Classes.column} ${Classes.columnBorder} border-r-0`}>
              <span className={Classes.columnLabel}>TOTAL OVER DUE</span>
              <span className={Classes.columnValue}>{formatAmount(overdueTotal)}</span>
            </div>
          </div>

          <div className="right-side flex">
            <div className={`tc-booking-ledger-headline-currency flex flex-col px-15px justify-center`}>
              <span className={Classes.columnLabel}>Currency</span>
              <span className={Classes.currencyValue}>
                {currency === 'USD' && <SvgIcon IconComponent={UsdIcon} className="w-30px h-30px fill-none" />}
                {currency === 'EUR' && <SvgIcon IconComponent={EurIcon} className="w-30px h-30px fill-none" />}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
