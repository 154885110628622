import React from 'react';
import { ISalesRepresentative, ITravelAgent } from 'services/BackendApi';
import { INotification } from 'services/BackendApi/types/Notification';
import { SimpleTabs } from 'ui/SimpleTabs';
import { TravelAgentNotifications } from './TravelAgentNotifications';
import { TravelAgentOtherOptions } from './TravelAgentOtherOptions';
import { TravelAgentSRAssignment } from './TravelAgentSRAssignment';

interface ITravelAgentSettingsTabsProps {
  travelAgentFormData: Partial<ITravelAgent>;
  assignedSrs: ISalesRepresentative[];
  notifications: INotification[];
  onChange: (fieldName: string, fieldValue: boolean) => void;
  onSRAssignmentChange: (srUuids: ISalesRepresentative[]) => void;
  onNotificationSettingsChange: (notifications: INotification[]) => void;
}

export const TravelAgentSettingsTabs: React.FC<ITravelAgentSettingsTabsProps> = React.memo(({ 
  travelAgentFormData, assignedSrs, notifications, onChange, onSRAssignmentChange, onNotificationSettingsChange
}) => {
  const travelAgentSettingsTabs = [
    {
      title: 'Other Options',
      name: 'other-options',
      styles: 'min-w-150px',
      renderContent: () => <TravelAgentOtherOptions travelAgentData={travelAgentFormData} onClick={onChange} />,
    },
    {
      title: 'Notifications',
      name: 'notifications',
      styles: 'min-w-150px',
      renderContent: () => <TravelAgentNotifications notifications={notifications} onChange={onNotificationSettingsChange} />,
    },
    {
      title: 'SR Assignment',
      name: 'sr-assignment',
      styles: 'min-w-150px',
      renderContent: () => <TravelAgentSRAssignment assignedSrs={assignedSrs} onChange={onSRAssignmentChange} />,
    },
  ];

  return (
    <SimpleTabs tabConfig={travelAgentSettingsTabs} />
  );
});
