import { zip, sortBy } from 'lodash-es';
import { createSelector } from 'reselect';
import { ICredentialsInfoDomain } from './model';

export const domainSelector = (state: any): ICredentialsInfoDomain => state.credentialsInfo;

export const storedListSelector = createSelector(
  domainSelector,
  domain => domain.storedList
);

export const draftListSelector = createSelector(
  domainSelector,
  domain => domain.draftList
);

export const filtersSelector = createSelector(
  domainSelector,
  domain => domain.filters
);

export const sortFieldSelector = createSelector(
  domainSelector,
  domain => domain.sortField
);

export const sortDirectionSelector = createSelector(
  domainSelector,
  domain => domain.sortDirection
);

export const requestSelector = createSelector(
  domainSelector,
  domain => domain.request
);

export const modifiedSelector = createSelector(
  storedListSelector,
  draftListSelector,
  (stored, draft) => zip(
    sortBy(stored, 'uuid'),
    sortBy(draft, 'uuid'),
  )
    .filter(([s, d]) =>
      (s!.date || undefined) !== (d!.date || undefined)
    )
    .map(([s, d]) => d)
);
