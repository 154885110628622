import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { EUserType, ITravelAgent, IUser } from 'services/BackendApi';
import { ESortOrder } from 'store/common/types';
import { CircleIconButton } from 'ui/CircleIconButton';
import { edit } from 'ui/Icons';
import { arrowNext } from 'ui/Icons';
import { Table as BaseTable } from 'ui/Table';
import { Tooltip } from './Tooltip';
import { getCurrentUser as getCurrentUserSelector, isAdmin as isAdminSelector } from 'store/modules/auth';
import { useSelector, useDispatch } from 'react-redux';
import { formatDateDisplay } from 'utils';
import * as Selectors from 'store/modules/travelAgentList/selectors';
import * as Actions from 'store/modules/travelAgentList/actions';
import { UserStatus } from 'ui/UserStatus';
import { renderFullName } from 'utils/guestInformation';

export interface ITableProps {
  className?: string;
  travelAgents: ITravelAgent[];
  loading: boolean;
  onDelete: (ITravelAgent) => void;
}

export const RenderCreatedByBadge = ({ createdBy }: { createdBy: ITravelAgent['createdBy'] }) => {
  let initials = '';
  if (createdBy) {
    if (createdBy && createdBy.type === EUserType.SR) {
      initials = 'sr';
    } else if (createdBy && createdBy.type === EUserType.ADMIN) {
      initials = 'in';
    }
  } else {
    initials = 'ex';
  }

  const badge = (
    <span className="cursor-pointer text-center w-24px h-20px bg-gray-10 rounded-1px text-xs font-pt-sans uppercase py-2px px-5px">
      {initials}
    </span>
  );
  return badge;
};

export const Table: React.FC<ITableProps> = props => {
  const isAdmin = useSelector(isAdminSelector);
  const loggedInUser = useSelector(getCurrentUserSelector) as IUser;
  const sortObject = useSelector(Selectors.sortSelector);

  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        id: 'createdAt',
        label: 'Created At',
        width: '100px',
        fieldToSortBy: 'createdAt',
      },
      {
        id: 'name',
        label: 'Travel Agent',
        width: '180px',
        className: 'pl-4',
      },
      {
        id: 'company',
        label: 'Company Name',
        width: '200px',
      },
      {
        id: 'country',
        label: 'Country',
        width: '80px',
        className: 'pl-4',
      },
      {
        id: 'email',
        label: 'Email',
        width: '200px',
      },
      {
        id: 'salesRep',
        label: 'Sales Rep',
        width: '170px',
      },
      {
        id: 'status',
        label: 'Status',
        width: '80px',
      },
      {
        id: 'createdBy',
        label: '',
        width: '50px',
      },
      {
        id: 'actions',
        label: '',
        width: isAdmin ? '90px' : '60px',
      },
    ],
    [isAdmin]
  );

  const rows = useMemo(() => {
    return props.travelAgents.map(x => {
      const rowItemClassName = 'text-ellipsis overflow-hidden whitespace-nowrap';
      const fullName = [x.firstName, x.lastName].join(' ');
      let chosenSalesRep = x.assignedSalesRepresentatives.find(sr => sr.uuid === loggedInUser.uuid);
      if (!chosenSalesRep) {
        chosenSalesRep = x.assignedSalesRepresentatives[0];
      }

      const chosenSalesRepFullName = renderFullName(undefined, chosenSalesRep?.firstName, chosenSalesRep?.lastName);

      const actions = (
        <div className="item-actions flex items-center relative">
          <div className="w-4px h-34px rounded bg-brown-15 mr-10px" />
          {!isAdmin && (
            <Tooltip content="View">
              <Link to={`/travel-agents/${x.uuid}/edit`}>
                <CircleIconButton
                  type="secondary"
                  className="view mx-1"
                  iconClass="inline relative left-0 top-1px w-12px h-12px"
                  iconSrc={arrowNext}
                />
              </Link>
            </Tooltip>
          )}
          {isAdmin && (
            <Tooltip content="Edit">
              <Link to={`/travel-agents/${x.uuid}/edit`}>
                <CircleIconButton
                  type="secondary"
                  className="edit-action mx-1"
                  iconClass="inline relative left-0 top-1px w-12px h-12px"
                  iconSrc={edit}
                />
              </Link>
            </Tooltip>
          )}
          {isAdmin && (
            <Tooltip content="Remove">
              <CircleIconButton
                type="secondary"
                className="delete-action mx-1"
                iconClass="far fa-trash-alt"
                onClick={() => props.onDelete(x)}
              />
            </Tooltip>
          )}
        </div>
      );

      return {
        id: x.uuid,
        cells: [
          {
            id: 'createdAt',
            value: formatDateDisplay(x.createdAt),
            className: `createdAt ${rowItemClassName}`,
          },
          {
            id: 'name',
            value: fullName,
            title: fullName,
            className: `name ${rowItemClassName} pl-4`,
          },
          {
            id: 'company',
            value: x.company?.name,
            title: x.company?.name,
            className: `company ${rowItemClassName}`,
          },
          {
            id: 'country',
            value: x.countryCode,
            className: `country ${rowItemClassName} text-center`,
          },
          {
            id: 'email',
            value: x.email,
            title: x.email,
            className: `email ${rowItemClassName}`,
          },
          {
            id: 'salesRep',
            value: (
              <span className="sales-rep flex flex-row items-center justify-between">
                <span className="text-ellipsis overflow-hidden whitespace-nowrap">{chosenSalesRepFullName}</span>
                {x.assignedSalesRepresentatives.length > 1 && (
                  <span className="text-13px text-brown-prime flex flex-col text-center">
                    <span>{x.assignedSalesRepresentatives.length - 1}</span>
                    <span>More</span>
                  </span>
                )}
              </span>
            ),
            className: rowItemClassName,
          },
          {
            id: 'status',
            value: <UserStatus value={x.status} />,
            className: `status ${rowItemClassName}`,
          },
          {
            id: 'createdBy',
            value: x.createdBy ? (
              <Tooltip content={x.createdBy.email}>
                <RenderCreatedByBadge createdBy={x.createdBy} />
              </Tooltip>
            ) : (
              <RenderCreatedByBadge createdBy={x.createdBy} />
            ),
            className: 'createdBy text-ellipsis whitespace-nowrap',
          },
          {
            id: 'actions',
            value: actions,
          },
        ],
      };
    });
  }, [isAdmin, props.travelAgents]);

  return (
    <BaseTable
      columns={columns}
      rows={rows}
      sortBy={sortObject.field}
      sortOrder={sortObject.order as ESortOrder}
      onSort={(field, order) => {
        dispatch(Actions.setSortOrderAction(order as 'asc' | 'desc'));
      }}
      messageWhenEmpty="No results"
      loading={props.loading}
    />
  );
};
