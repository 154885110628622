import React, { Dispatch, SetStateAction, useCallback,  useLayoutEffect } from 'react';
import classnames from 'classnames';
import FluidButton from 'ui/FluidButton';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import { useCurrentWidth } from 'effects';
import { useDispatch, useSelector } from 'react-redux';
import { RIGHT_COLUMN_BREAKPOINT } from './const';
import { BookingBuilder, ENetworkRequestStatus, ICompany, SelectedAccommodation, makeBackendApi } from 'services/BackendApi';
import * as ProposalsSelectors from 'store/modules/proposals/selectors';
import * as ProposalsSelectorsV2 from 'store/modules/proposalsList/selectors';
import { LodgingSummary } from 'interfaces';
import { theme } from '../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';
import { HorizontalLine } from './components/HorizontalLine';
import { FourBookButtons } from './FourBookButtons';
import { isQuoteDownloadingSelector } from 'store/modules/bookingConfirmation/subdomains/quote/selectors';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { PaymentMethod } from './PaymentMethod';
import { InstantBookButton } from './InstantBookButton';
import { VerticalSpace } from 'ui/VerticalSpace';
import { useDepositAndCreditLimitInfo } from './hooks/useDepositAndCreditLimitInfo';
import { paymentMethodSelector } from 'store/modules/bookingConfirmation/subdomains/payment/selectors';
import { EPaymentMethod } from 'store/modules/bookingBuilder/types';
import { companyDataSelector, fetchCompanyDataRequestAction } from 'store/modules/companyInfo';
import { LoadingBar } from 'ui/NetworkStatusBar';

interface IButtonWithIconProps {
  className: string;
  caption: string;
  Icon: React.FC<IIconProps>;
  isLoading: boolean;
  onClick: () => void;
  disabled: boolean;
}

export const ButtonWithIcon: React.FC<IButtonWithIconProps> = ({
  caption, Icon, isLoading, className, onClick, disabled
}) => {
  const { currentWidth } = useCurrentWidth();
  return (
    <FluidButton
      type="secondary"
      className={className}
      onClick={onClick}
      isLoading={isLoading}
      disabled={disabled}
    >
      {currentWidth >= RIGHT_COLUMN_BREAKPOINT ? (
        <span className="relative top-[4px]">
          <Icon fill={disabled ? theme.colors['brown-40'] : theme.colors['brown-100']} />
        </span>
      ) : null} {caption}
    </FluidButton>
  )
}

interface IBookingButtonsProps {
  booking: BookingBuilder;
  lodgingSummaries: LodgingSummary[];
  className?: string;
  canBook: boolean;
  isRequestInProgress: boolean;
  setRequestInProgress: Dispatch<SetStateAction<boolean>>;
  setRedirectLocation: React.Dispatch<React.SetStateAction<string | null>>;
  setShouldShowLeaveAlert: Dispatch<SetStateAction<boolean>>;
  isLiveRatePricesModalOpen: boolean;
  setIsLiveRatePricesModalOpen: Dispatch<SetStateAction<boolean>>;
  liveRatePricesModalData: any;
  setLiveRatePricesModalData: Dispatch<SetStateAction<any>>;
}

export const BookingButtons: React.FC<IBookingButtonsProps> = React.memo(({ 
  booking, lodgingSummaries, className, canBook, isRequestInProgress, setRequestInProgress, setRedirectLocation, setShouldShowLeaveAlert, isLiveRatePricesModalOpen, setIsLiveRatePricesModalOpen, liveRatePricesModalData, setLiveRatePricesModalData
}) => {
  const dispatch = useDispatch();
  const company = useSelector(companyDataSelector) as ICompany;
  const { depositAccountBalanceCents, cannotGetDepositAccountBalance, creditLimitCents, isDefinedCreditLimit, isEnoughDepositAndCredit } = useDepositAndCreditLimitInfo(booking);
  const selectedPaymentMethod = useSelector(paymentMethodSelector);
  const guestInfo = useSelector(BookingBuilderSelectors.guestInfoSelector);
  const guestInfoFilledIn = guestInfo.guestFirstName?.trim() && guestInfo.guestLastName?.trim();
  const proposalStatus = useSelector(ProposalsSelectors.getProposalsStatus);
  const isProposalDownloading = useSelector(ProposalsSelectorsV2.requestPendingSelector);
  const isQuoteDownloading = useSelector(isQuoteDownloadingSelector);
  const isLoading = isQuoteDownloading || isProposalDownloading || isRequestInProgress || proposalStatus === ENetworkRequestStatus.PENDING;
  const buttonsDisabled = !guestInfoFilledIn || !canBook || isLoading;

  const isTa: boolean = useSelector(AuthSelectors.isTA);
  const requestedBookingAccommodations: SelectedAccommodation[] = booking?.request?.Accommodation ?? [];
  const containsInstantBook = requestedBookingAccommodations.some(accommodation => accommodation.availableToInstantBook);
  const isAllRoomsStatic = requestedBookingAccommodations.every(accommodation => !accommodation.liveRate);
  const allRoomsInstantBook = isAllRoomsStatic && requestedBookingAccommodations.every(accommodation => accommodation.availableToInstantBook);
  const containsLiveRates = booking.response.potentialBooking.Accommodation.some(requestedAccommodation => {
    return requestedAccommodation.isLiveRate;
  });
  const allStaticRates = !containsLiveRates && !containsInstantBook;
  const waitingForCompanyInfo = isTa && !company;
  
  useLayoutEffect(() => {
    if (isTa && company?.uuid) {
      dispatch(fetchCompanyDataRequestAction(company?.uuid));
    }
  }, []);

  if (waitingForCompanyInfo || isLoading) {
    return <div className="mt-[45px] mb-[50px]"><LoadingBar /></div>
  }

  const shouldShowBookNowOption = (containsLiveRates || containsInstantBook) && (!isTa || (isTa && company?.enableInstantBooking));
  const isBookNowButtonDisabled = (selectedPaymentMethod === EPaymentMethod.DEPOSIT_ACCOUNT && !isEnoughDepositAndCredit)
    || (selectedPaymentMethod === EPaymentMethod.BANK_TRANSFER && !booking.response.isRefundable);

  return (
    <div className="booking-buttons-container">
      {shouldShowBookNowOption && <HorizontalLine text="PAY NOW" />}
      {isTa && company?.enableInstantBooking && (containsLiveRates || containsInstantBook) && (
        <PaymentMethod
          booking={booking}
          depositAccountBalanceCents={depositAccountBalanceCents}
          cannotGetDepositAccountBalance={cannotGetDepositAccountBalance}
          creditLimitCents={creditLimitCents}
          isDefinedCreditLimit={isDefinedCreditLimit}
          isEnoughDepositAndCredit={isEnoughDepositAndCredit}
          selectedPaymentMethod={selectedPaymentMethod}
      />
      )}
      {shouldShowBookNowOption && <InstantBookButton
        booking={booking}
        lodgingSummaries={lodgingSummaries}
        setShouldShowLeaveAlert={setShouldShowLeaveAlert}
        setRequestInProgress={setRequestInProgress}
        setRedirectLocation={setRedirectLocation}
        isLoading={isLoading}
        buttonsDisabled={isBookNowButtonDisabled || buttonsDisabled}
        cannotGetDepositAccountBalance={cannotGetDepositAccountBalance}
      />}
      {shouldShowBookNowOption && !containsLiveRates && <VerticalSpace height="30px" />}
      {(allStaticRates || (isTa && !company?.enableInstantBooking && containsInstantBook)) && <HorizontalLine text="PAY LATER" />}
      {((isTa && company?.enableInstantBooking) || !isTa) && containsInstantBook && <HorizontalLine text="OR PAY LATER" />}
      <FourBookButtons
        booking={booking}
        lodgingSummaries={lodgingSummaries}
        isRequestInProgress={isRequestInProgress}
        setShouldShowLeaveAlert={setShouldShowLeaveAlert}
        setRequestInProgress={setRequestInProgress}
        setRedirectLocation={setRedirectLocation}
        canBook={canBook}
        isLoading={isLoading}
        isPrimaryButtonDisabled={containsLiveRates && isBookNowButtonDisabled}
        buttonsDisabled={buttonsDisabled}
        isLiveRatePricesModalOpen={isLiveRatePricesModalOpen}
        setIsLiveRatePricesModalOpen={setIsLiveRatePricesModalOpen}
        liveRatePricesModalData={liveRatePricesModalData}
        setLiveRatePricesModalData={setLiveRatePricesModalData}
      />
    </div>
  );
});