import { call, takeLatest, select, put } from 'redux-saga/effects';
import { enqueueNotification } from "store/modules/ui";
import { LIST_ATTACHMENTS_REQUEST, listAttachmentFailureAction, listAttachmentSuccessAction, uploadAttachmentFailureAction, UploadAttachmentRequestAction, uploadAttachmentSuccessAction } from '../actions';
import { AxiosResponse } from 'axios';
import { makeTaskManagementApi } from 'services/TaskManagementApi/TaskManagementApi';
import { ITaskAttachment } from 'services/TaskManagementApi/types/TaskManagementResponse';

// eslint-disable-next-line no-unused-vars
export function* listTaskAttachmentSaga(action: UploadAttachmentRequestAction) {
  try {
    const taskManagementApi = makeTaskManagementApi();
    
    const taskAttachmentsResponse: AxiosResponse<ITaskAttachment[]> = yield call(taskManagementApi.listAttachmentsOfTask, action.taskId);
    yield put(listAttachmentSuccessAction(taskAttachmentsResponse.data));
  } catch (e) {
    yield put(listAttachmentFailureAction(e.message));
    yield put(
      enqueueNotification({
        message: 'Can not get attachments',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchListTaskAttachmentSaga() {
  yield takeLatest(
    [LIST_ATTACHMENTS_REQUEST],
    listTaskAttachmentSaga
  );
}
