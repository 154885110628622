import { createSelector } from 'reselect';
import { IBookingManagerDashboardSubdomain } from './model';
import { dashboardSubdomainSelector } from '../../selectors';

export const specialRequestsDashboardSelector = createSelector(
  dashboardSubdomainSelector,
  (subdomain: IBookingManagerDashboardSubdomain) => subdomain.specialRequests
);

export const topNavigationDataDashboardSelector = createSelector(
  dashboardSubdomainSelector,
  (subdomain: IBookingManagerDashboardSubdomain) => subdomain.topNavigationData
);

export const dashboardNetworkRequestsSelector = createSelector(
  dashboardSubdomainSelector,
  (subdomain: IBookingManagerDashboardSubdomain) => subdomain.networkRequests
);

export const isSpecialRequestsEditedWithoutSavingSelector = createSelector(
  dashboardSubdomainSelector,
  (subdomain: IBookingManagerDashboardSubdomain) => subdomain.isSpecialRequestsEditedWithoutSaving
);

export const bookingTypeSelector = createSelector(
  dashboardSubdomainSelector,
  (subdomain: IBookingManagerDashboardSubdomain) => subdomain.bookingType
);

export const bookingInformationReservationTeamDashboardSelector = createSelector(
  dashboardSubdomainSelector,
  (subdomain: IBookingManagerDashboardSubdomain) => subdomain.bookingInformationReservationTeamData
);

export const bookingTasksSelector = createSelector(
  dashboardSubdomainSelector,
  (subdomain: IBookingManagerDashboardSubdomain) => subdomain.bookingTasks
);

export const bookingTasksRequestSelector = createSelector(
  dashboardSubdomainSelector,
  (subdomain: IBookingManagerDashboardSubdomain) => subdomain.networkRequests.bookingTasksLoad
);
