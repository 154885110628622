import React from 'react';
import { Price } from 'ui/Price';
import classnames from 'classnames';
import { TCurrencyCode } from 'interfaces';

export enum EProductLineDisplayMode {
  ONE_LINE = 'one_line',
  TWO_LINES = 'two_lines',
}

interface IProductLineWithPriceProps {
  name: string;
  tertiaryText?: string;
  total?: number | string | null;
  totalBeforeDiscount?: number | string | null;
  currencyCode: TCurrencyCode;
  isOnRequest?: boolean;
  className?: string;
  displayMode: EProductLineDisplayMode;
}

export const ProductLineWithPrice: React.FC<IProductLineWithPriceProps> = React.memo(
  ({ name, tertiaryText, total, totalBeforeDiscount, currencyCode, isOnRequest, className, displayMode }) => {
    const priceClassName = 'font-hurmegeometric-sans text-15px leading-18px text-right mt-5px m-0';
    const titleClassName = classnames(
      'product-title font-hurmegeometric-sans text-13px leading-16px  uppercase mb-0 mt-5px max-w-670px',
      {
        'text-flint': displayMode === EProductLineDisplayMode.ONE_LINE,
        'text-brown-100': displayMode === EProductLineDisplayMode.TWO_LINES,
      }
    );
    const firstLineText =
      displayMode === EProductLineDisplayMode.ONE_LINE ? [name, tertiaryText?.trim()].join(', ') : name;

    return (
      <div className={classnames(className, 'product-line')}>
        <div className="first-line flex items-start justify-between">
          <div className="product-text-container">
            <p className={titleClassName}>{firstLineText}</p>
            {tertiaryText && displayMode === EProductLineDisplayMode.TWO_LINES && (
              <p className="product-tertiary-text font-hurmegeometric-sans text-13px leading-16px text-flint uppercase mb-0 mt-5px max-w-670px">
                {tertiaryText}
              </p>
            )}
          </div>
          <div className="product-price">
            <Price
              total={total}
              totalBeforeDiscount={totalBeforeDiscount}
              currencyCode={currencyCode}
              isOnRequest={isOnRequest}
              totalClassName={`${priceClassName} text-flint`}
              totalBeforeDiscountClassName={`${priceClassName} text-gold-light line-through`}
              totalAfterDiscountClassName={`${priceClassName} text-red-15`}
              isOnRequestClassName={`${priceClassName} text-flint`}
            />
          </div>
        </div>
      </div>
    );
  }
);
