import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ENetworkRequestStatus, IContentEntity, makeBackendApi } from 'services/BackendApi';
import { Link } from 'ui/Link';
import { ContentEntityDetailsTab } from './ContentEntityDetailsTab';
import produce from 'immer';
import { enqueueNotification } from 'store/modules/ui';

export const Create = () => {
  const [draftContentEntity, setDraftContentEntity] = useState<Partial<IContentEntity>>({
    title: '',
    subtitle: '',
    type: 'latest-offer',
    body: '',
    ctaText: '',
    ctaUrl: '',
    uploads: [],
  });

  const backendApi = makeBackendApi();
  const [createContentEntityRequest, setCreateContentEntityRequest] = useState(ENetworkRequestStatus.IDLE);

  const dispatch = useDispatch();

  return (
    <div className="container w-1280px mx-auto">
      <Link to="/content-admin">Back to Content</Link>
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">
        Content - <span className="text-[26px]">Creating new content item</span>
      </h1>
      <ContentEntityDetailsTab
        contentEntity={draftContentEntity}
        onUpdate={(field, val) => {
          setDraftContentEntity({
            ...produce(draftContentEntity, _draftContentEntity => {
              _draftContentEntity[field] = val;
            }),
          });
        }}
        onCta={async () => {
          setCreateContentEntityRequest(ENetworkRequestStatus.PENDING);
          try {
            const newContentEntity = await backendApi.contentAdminPostContentEntity(draftContentEntity);
            setCreateContentEntityRequest(ENetworkRequestStatus.SUCCESS);
            dispatch(
              enqueueNotification({
                message: `Content created successfully`,
                options: { variant: 'success' },
              })
            );
            window.location.href = `/content-admin/${newContentEntity.data.data.uuid}/edit`;
          } catch (error) {
            setCreateContentEntityRequest(ENetworkRequestStatus.ERROR);
            dispatch(
              enqueueNotification({
                message: `Failed to create content`,
                options: { variant: 'error' },
              })
            );
          }
        }}
        ctaLabel="Create Content"
        mode="create"
      />
    </div>
  );
};
