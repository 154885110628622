import { TCountryCode } from 'interfaces';
import React from 'react';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { IInvoiceAddressee } from 'services/BookingManagerApi/types';
import { ErrorBar } from 'ui/NetworkStatusBar';
import { getCountryName } from 'utils/country';

export interface IInvoiceAddresseeText {
  invoiceAddressee?: IInvoiceAddressee;
  invoiceAddresseeLoad: ENetworkRequestStatus;
}

export const InvoiceAddresseeText: React.FC<IInvoiceAddresseeText> = React.memo(props => {


  return (
      <div className="flex justify-between mb-10px mt-5px">
        {props.invoiceAddresseeLoad === ENetworkRequestStatus.PENDING && (
          <span className="text-brown-120">
            <i className="text-xl fas fa-circle-notch fa-spin"></i>
          </span>
        )}

        {props.invoiceAddresseeLoad === ENetworkRequestStatus.ERROR && (
          <ErrorBar message="Could not load invoice addressee information" />
        )}

        {props.invoiceAddresseeLoad === ENetworkRequestStatus.SUCCESS && props.invoiceAddressee && (
          <div className="invoice-addressee flex items-start justify-between w-full">
            <div className="invoice-addressee-left-part flex-1">
              {props.invoiceAddressee.name && (
                <p className="font-pt-sans text-black text-15px leading-sm m-0">{props.invoiceAddressee.name}</p>
              )}
              {props.invoiceAddressee.company && (
                <p className="font-pt-sans text-black text-15px leading-sm m-0">{props.invoiceAddressee.company}</p>
              )}
              {props.invoiceAddressee.address && (
                <p className="font-pt-sans text-black text-15px leading-sm m-0">{props.invoiceAddressee.address}</p>
              )}
              {props.invoiceAddressee.countryCode && (
                <p className="font-pt-sans text-black text-15px leading-sm m-0">
                  {getCountryName(props.invoiceAddressee.countryCode as TCountryCode) ||
                    props.invoiceAddressee.countryCode}
                </p>
              )}
            </div>
            <div className="invoice-addressee-right-part flex-1">
              {props.invoiceAddressee.email && (
                <p className="font-pt-sans text-black text-15px leading-sm m-0">{props.invoiceAddressee.email}</p>
              )}
              {props.invoiceAddressee.vat && (
                <p className="font-pt-sans text-black text-15px leading-sm m-0">VAT {props.invoiceAddressee.vat}</p>
              )}
              {props.invoiceAddressee.iata && (
                <p className="font-pt-sans text-black text-15px leading-sm m-0">IATA {props.invoiceAddressee.iata}</p>
              )}
            </div>
          </div>
        )}
      </div>
  );
});
