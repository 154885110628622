import * as Actions from './actions';
import * as CompanyInfoActions from '../companyInfo/actions';

import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { IFinancesDomain } from './model';
import { initialState } from './model';

export const financesReducer = (
  state: IFinancesDomain = initialState,
  action: Actions.Any | CompanyInfoActions.CompanyInfoAction
) => {
  switch (action.type) {
    case Actions.GET_REQUEST:
      return produce(state, draftState => {
        draftState.request.get = ENetworkRequestStatus.PENDING;
        return draftState;
      });
    
    case Actions.GET_SUCCESS:
      return produce(state, draftState => {
        draftState.request.get = ENetworkRequestStatus.SUCCESS;

        return draftState;
      });

    case Actions.GET_FAILURE:
      return produce(state, draftState => {
        draftState.request.get = ENetworkRequestStatus.ERROR;
        return draftState;
      });
        
    case CompanyInfoActions.FETCH_COMPANY_DATA_SUCCESS:
      return produce(state, draftState => {
        draftState.enableInstantBooking = action.companyData.enableInstantBooking;
        draftState.disableTaAccessToFinance = action.companyData.disableTaAccessToFinance;
        draftState.isTouched = false;
        draftState.creditLimitUsd = action.companyData.usdCreditLimit === null ? null : Number(action.companyData.usdCreditLimit) ;
        draftState.creditLimitEur = action.companyData.eurCreditLimit === null ? null : Number(action.companyData.eurCreditLimit);
        return draftState;
      });

    case CompanyInfoActions.PATCH_COMPANY_REQUEST:
      return produce(state, draftState => {
        draftState.request.patch = ENetworkRequestStatus.PENDING;
        return draftState;
      });
  
    case CompanyInfoActions.PATCH_COMPANY_SUCCESS:
      return produce(state, draftState => {
        draftState.request.patch = ENetworkRequestStatus.SUCCESS;
        draftState.disableTaAccessToFinance = action.company.disableTaAccessToFinance;
        draftState.enableInstantBooking = action.company.enableInstantBooking;
        draftState.isTouched = false;
        return draftState;
      });

    case CompanyInfoActions.PATCH_COMPANY_FAILURE:
      return produce(state, draftState => {
        draftState.request.patch = ENetworkRequestStatus.ERROR;
        return draftState;
      });
  
    case Actions.TOGGLE_DISABLE_TA_ACCESS_TO_FINANCE:
      return produce(state, draftState => {
        draftState.isTouched = true;
        draftState.disableTaAccessToFinance = !draftState.disableTaAccessToFinance;
        return draftState;
      });    

    case Actions.TOGGLE_ENABLE_INSTANT_BOOKING:
      return produce(state, draftState => {
        draftState.enableInstantBooking = !draftState.enableInstantBooking;
        draftState.isTouched = true;
        draftState.creditLimitUsd = 0;
        draftState.creditLimitEur = 0;
        return draftState;
      });    
  
    case Actions.SET_CREDIT_LIMIT_USD:
      return produce(state, draftState => {
        draftState.isTouched = true;
        draftState.creditLimitUsd = action.amount;
        return draftState;
      });    
  
    case Actions.SET_CREDIT_LIMIT_EUR:
      return produce(state, draftState => {
        draftState.creditLimitEur = action.amount;
        draftState.isTouched = true;
        return draftState;
      });    
    
      case Actions.SET_TOUCHED:
        return produce(state, draftState => {
          draftState.isTouched = action.isTouched;
          return draftState;
        });    
  
    default:
      return state;
  }
};
