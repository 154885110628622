import React from 'react';
import { Helmet } from 'react-helmet';

export interface SnippetProps {
  src: string;
}

export const ChatWidgetSnippet = ({ src }: SnippetProps) =>
  // @ts-ignore
  <Helmet>
    <script src={src} defer></script>
  </Helmet>;

export default ChatWidgetSnippet;