import React, { useCallback } from 'react';
import * as TableCardUI from 'pureUi/TableCard';
import classnames from 'classnames';
import { SummaryFormMargin } from 'components';
import { updateIsTAMarginAppliedAction, updateTAMarginAmountAction, updateTAMarginTypeAction } from 'store/modules/bookingBuilder';
import { useDispatch } from 'react-redux';

export const BookingMargin = (
  { t, onMarginChange, grandTotal, canBook, currencyCode, isTAMarginApplied, taMarginType, taMarginAmount, textClassname = '', marginClassname = '', titleClassname = '', depth = 3 }
) => {
  if (!canBook) return null;

  return (
    <TableCardUI.TableCardBox className="mt-4 mb-4">
      <TableCardUI.TableCardRow depth={depth}>
        <h3 className={classnames('default:font-pt-sans default:text-[12px] default:pb-[15px] default:uppercase default:font-semibold default:tracking-[0.5px] default:leading-[14px] default:text-flint default:border-b default:border-gray-17', titleClassname)}>{t('labels.commission')}</h3>
        <SummaryFormMargin
          className={''}
          checked={isTAMarginApplied}
          currencyCode={currencyCode}
          onChange={onMarginChange}
          total={grandTotal}
          type={taMarginType}
          value={taMarginAmount}
          textClassname={textClassname}
          marginClassname={marginClassname}
        />
      </TableCardUI.TableCardRow>
    </TableCardUI.TableCardBox>
  );
};

export const useMarginChange = (id) => {
  const dispatch = useDispatch();
  const onMarginChange = useCallback(
    (e, marginType, marginValue, shouldUpdateCheckbox = undefined) => {
      if (shouldUpdateCheckbox === true || shouldUpdateCheckbox === false) {
        dispatch(updateIsTAMarginAppliedAction(id, shouldUpdateCheckbox));

        if (shouldUpdateCheckbox === false) {
          dispatch(updateTAMarginTypeAction(id, undefined));
          dispatch(updateTAMarginAmountAction(id, undefined));
        }
      }

      dispatch(updateTAMarginTypeAction(id, 'percentage'));
      if (marginValue === null) {
        dispatch(updateTAMarginAmountAction(id, '0'));
      } else {
        dispatch(updateTAMarginAmountAction(id, marginValue));
      }
    },
    [dispatch, id]
  );
  
  return { onMarginChange };
};

export const onMarginChangeCreator = (
  id, updateIsTAMarginAppliedAction, updateTAMarginTypeAction, updateTAMarginAmountAction
) => (
  e, marginType, marginValue, shouldUpdateCheckbox = undefined,
) => {
  if (shouldUpdateCheckbox === true || shouldUpdateCheckbox === false) {
    updateIsTAMarginAppliedAction(id, shouldUpdateCheckbox);

    if (shouldUpdateCheckbox === false) {
      updateTAMarginTypeAction(id, undefined);
      updateTAMarginAmountAction(id, undefined);
    }
  }

  updateTAMarginTypeAction(id, 'percentage');
  if (marginValue === null) {
    updateTAMarginAmountAction(id, 0);
  } else {
    updateTAMarginAmountAction(id, marginValue);
  }
};