import React from 'react';
import { useState, useEffect, CSSProperties, useRef } from 'react';
import classNames from 'classnames';
import { IValueLabelPair } from '../../interfaces';
import Checkbox from '../Checkbox';
import { useHideOnClickOutside } from 'hooks/useHideOnClickOutside';
import { isEqual, isNil } from 'lodash-es';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import * as axios from 'axios';
import { isString } from 'lodash-es';
import { SvgIcon } from 'ui/SvgIcon';
import PlusHollow from '../../ui/Icons/plus-hollow.component.svg';
import { usePrevious } from 'hooks/usePrevious';

export interface IMultiselectValueLabelPair extends IValueLabelPair {
  icon?: string;
  iconHtml?: JSX.Element;
  iconAlign?: 'left' | 'right' | 'end';
  isDivider?: boolean;
}

export interface IMultiselectProps {
  id?: string;
  options: IMultiselectValueLabelPair[];
  selectedValues: string[];
  onUpdate: (selectedValues: string[], isCustomSelection?: boolean, options?: any) => void;

  className?: string;
  openClassName?: string;
  placeholderText?: string;
  overwriteLabelText?: string;
  itemsClassname?: string; // the "wrapper" class for all the items
  primaryCtaClassName?: string; // the class on each CTA itself
  itemCtaClassName?: string; // the class on each CTA itself
  itemCtaPaddingClassName?: string; // a class to apply paddings and spacings, has a default
  itemContentClassName?: string; // the class on each span inside each item
  selectedItemContentClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  isSingleSelectMode?: boolean;
  isCloseOnSelect?: boolean;
  isIncludeClearButton?: boolean;
  itemsToDisplayBeforeScroll?: number; // how many items should be displayed before the box scrolls
  hideCheckboxes?: boolean; // if true, just simply don't show the checkboxes for each option
  isEnableFuzzySearch?: boolean; // if true, the multiselect will have a fuzzy text search
  fuzzySearchInputInactiveClass?: string; // a class that will get applied to the input that the fuzzy text search uses when it DOESNT have any value in
  fuzzySearchInputActiveClass?: string; // a class that will get applied to the input that the fuzzy text search uses ONLY when there is some actual value in the input
  borderColourClass?: string; // a class name like "border-red-50" that sets all the border colours
  fontClass?: string; // a class name like "font-hurmegeometric-sans" that sets all fonts
  placeholderClasses?: string; // the classes that determine how the placeholder looks
  height?: number; // the height of the element, defaults to 35px
  singleItemHeightPixels?: number; // used for calculating the height of the open box (in conjunction with `itemsToDisplayBeforeScroll`). adjust if you need crazy margins or paddings or text sizes on the items
  dropdownPosition?: 'bottom' | 'top'; // if set to "top", the dropdown box will appear above the multiselect itself
  hideDropdownArrow?: boolean; // if true, the dropdown arrow will not be shown
  isEnableLookupSearch?: boolean; // if true, the multiselect will have a lookup search
  lookupSearchDebounceDelay?: number; // the delay in ms before the lookup search is called
  noOptionsAvailableText?: string; // the text that will be displayed when there are no options available
  /**
   * The function that runs when the user types in the lookup search.
   *
   * This function should update the options that are passed into this component.
   *
   * *REMEMBER*: debounce this function if you're hitting an API. See Storybook for an example.
   * @param searchTerm what the user has typed in the lookup search
   * @param signal an AbortSignal that you can make use of. if you're hitting an endpoint, pass this signal to the fetch call and this component will cancel the previous call for you.
   * @returns
   */

  onLookupSearch?: (
    searchTerm: string,
    signal: AbortSignal,
    cancelTokenSource: axios.CancelTokenSource
  ) => Promise<IValueLabelPair[]>; // the function that will be called when the user types in the lookup search
  staticOptions?: IMultiselectValueLabelPair[]; // options that are always available, regardless of what lookup search returns
  hideNonMatchingOptions?: boolean; // OWA-7139
  selectedValuesRenderer?: (rawLabelText: string) => string;
  isEnableCreateNewButton?: boolean;
  createNewButtonMinimumChars?: number;
  lookupInitialOptions?: IMultiselectValueLabelPair[];
  optionsLabelRenderer?: (option: IMultiselectValueLabelPair) => string;
}

const scrollToElement = (multiSelectId: string, elementIndex: number): void => {
  const itemsContainer = document.querySelector(`.multiselect-${multiSelectId} .multiselect-items`);
  const selectedItemContainer = document.querySelector(
    `.multiselect-${multiSelectId} .multiselect-items .multiselect-item-wrapper.index-${elementIndex}`
  ) as HTMLElement;

  if (itemsContainer && selectedItemContainer) {
    itemsContainer.scrollTop = selectedItemContainer.offsetTop;
  }
};

export interface iMultiselectInternalState {
  isOpen: boolean;
  internalStateOptions: IMultiselectValueLabelPair[];
  internalStateSelectedValues: string[];
  fuzzySearchText: string;
  lookupSearchText: string;
  lookupSearchSearchTerm: string;
  lookupSearchTimeoutId: number | null;
  lookupSearchNetworkStatus: ENetworkRequestStatus;
  createNewCustomValue: string | null;
}

export const Multiselect = (props: IMultiselectProps) => {
  if (props.isEnableCreateNewButton && !props.isEnableFuzzySearch) {
    throw new Error('If isEnableSetCustomTypedValue is true, you must set isEnableFuzzySearch to true');
  }
  if (props.isEnableLookupSearch && !props.onLookupSearch) {
    throw new Error('If isEnableLookupSearch is true, you must provide an onLookupSearch function');
  }
  if (props.lookupInitialOptions && !props.isEnableLookupSearch) {
    throw new Error('If you provide an lookupInitialOptions, you must set isEnableLookupSearch to true');
  }
  if (props.onLookupSearch && !props.isEnableLookupSearch) {
    throw new Error('If you provide an onLookupSearch function, you must set isEnableLookupSearch to true');
  }
  if (props.isEnableFuzzySearch && !props.isSingleSelectMode) {
    throw new Error('If isEnableFuzzySearch is true, you must set isSingleSelectMode to true');
  }

  const {
    dropdownPosition = 'bottom',
    borderColourClass = 'border-gray-40',
    height = 35,
    fontClass = 'font-pt-sans',
    placeholderClasses = 'italic text-gray-80',
    itemCtaPaddingClassName = 'p-3',
    singleItemHeightPixels = 44,
    fuzzySearchInputInactiveClass = 'bg-transparent',
    fuzzySearchInputActiveClass = 'bg-ivory',
    lookupSearchDebounceDelay = 1000,
    staticOptions = [],
    noOptionsAvailableText = 'No options available',
    placeholderText = 'Select',
    createNewButtonMinimumChars = 3,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const [internalStateInitialOptions, setInternalStateInitialOptions] = useState(props.lookupInitialOptions || []);

  const [internalStateOptions, setInternalStateOptions] = useState([...props.options, ...internalStateInitialOptions]);
  const [internalStateSelectedValues, setInternalStateSelectedValues] = useState(props.selectedValues);
  const [fuzzySearchText, setFuzzySearchText] = useState(
    props.selectedValues.length >= 1 ? props.selectedValues.join(', ') : ''
  );
  const [lookupSearchText, setLookupSearchText] = useState(
    props.selectedValues.length >= 1 ? props.selectedValues.join(', ') : ''
  );
  const [lookupSearchSearchTerm, setLookupSearchSearchTerm] = useState('');
  const [lookupSearchTimeoutId, setLookupSearchTimeoutId] = useState<number | null>(null);
  const [lookupSearchNetworkStatus, setLookupSearchNetworkStatus] = useState(ENetworkRequestStatus.IDLE);
  const [createNewCustomValue, setCreateNewCustomValue] = useState<string | null>(null);

  const [heightOfARow, setHeightOfARow] = useState<number>(0);
  // this is basically for ease of reading: so that anywhere below you can read `state.<>` and know
  // that its an internal state variable, and not a prop, runtime thing, etc.
  const state = {
    isOpen,
    internalStateOptions,
    internalStateSelectedValues,
    fuzzySearchText,
    lookupSearchText,
    lookupSearchSearchTerm,
    lookupSearchTimeoutId,
    lookupSearchNetworkStatus,
    createNewCustomValue,
    internalStateInitialOptions,
  };

  // make a whole list of options, including the static ones
  // this is so we can support something like: an API lookup dropdown whos options is populated
  // from the API, but it ALSO has static options that are always available
  const optionsWithStaticsIncluded = [
    ...staticOptions,
    ...state.internalStateInitialOptions,
    ...state.internalStateOptions,
  ];

  const { instanceClassname, id } = useHideOnClickOutside(() => {
    if (!state.isOpen) {
      return;
    }
    setIsOpen(false);

    if (props.isEnableFuzzySearch && state.internalStateSelectedValues.length <= 0) {
      setFuzzySearchText('');
    }
    if (props.isEnableCreateNewButton && state.createNewCustomValue) {
      setFuzzySearchText(state.createNewCustomValue);
    }
    if (props.isEnableLookupSearch && state.internalStateSelectedValues.length <= 0) {
      setLookupSearchText('');
      setInternalStateSelectedValues([]);
      setInternalStateOptions(props.options);
    }

    if (state.internalStateSelectedValues.length <= 0 && isNil(state.createNewCustomValue)) {
      handleCallOnUpdate([], false);
    }
    // get the label for the selected values
    const currentLabels = state.internalStateSelectedValues.map(
      v => optionsWithStaticsIncluded.find(o => o.value === v)?.label
    );

    // if we've got fuzzy search enable, AND we have something selected BUT the current fuzzy search
    // doesnt match it, its because they've started typing then clicked off; so just wipe everything
    if (
      props.isEnableFuzzySearch &&
      state.internalStateSelectedValues.length >= 1 &&
      state.fuzzySearchText !== currentLabels[0] &&
      isNil(state.createNewCustomValue)
    ) {
      handleCallOnUpdate([], false);
    }
  }, 'multiselect');

  // ...from that list, we work out what we actually have selected based on the internal state
  // and grab their labels
  const newLabels = state.internalStateSelectedValues.map(
    v => optionsWithStaticsIncluded.find(o => o.value === v)?.label
  );

  // this forms our label text
  const labelText =
    newLabels.length >= 1 ? (
      <span>
        {newLabels
          .filter(l => l !== undefined)
          .map(l => (props.selectedValuesRenderer ? props.selectedValuesRenderer(l as string) : l))
          .join(', ')}
      </span>
    ) : (
      <span className={placeholderClasses}>{placeholderText}</span>
    );

  const abortControllerRef = useRef<AbortController | null>(null);
  const cancelTokenSourceRef = useRef<axios.CancelTokenSource | null>(null);

  // Cleanup function to abort any pending requests
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel();
      }
    };
  }, []);

  const optionsAsString = props.options.map(o => o.value).join(',');
  const selectedValuesAsString = props.selectedValues.join(',');

  // when the prop options change
  useEffect(() => {
    // update the internal state options...
    if (!isEqual({ ...state, internalStateOptions: props.options }, state)) {
      setInternalStateOptions(props.options);
    }

    // if the new options plus the static options DONT have what we currently have selected as
    // available, we need to clear the selected values
    if (
      !state.internalStateSelectedValues.every(v =>
        [...staticOptions, ...state.internalStateInitialOptions, ...props.options].map(o => o.value).includes(v)
      )
    ) {
      if (props.isEnableFuzzySearch) {
        setFuzzySearchText('');
      }
      if (props.isEnableLookupSearch) {
        setLookupSearchText('');
        setLookupSearchSearchTerm('');
      }
      props.onUpdate([]);
    }
  }, [optionsAsString]);

  // when the prop selected values change
  useEffect(() => {
    setInternalStateSelectedValues(props.selectedValues.map(sv => (isString(sv) ? sv.toString() : sv)));
  }, [selectedValuesAsString]);

  const optionsThatMatchFuzzySearch = props.options.filter(o => {
    return o.label.toLocaleLowerCase().includes(state.fuzzySearchText.toLocaleLowerCase().trim());
  });
  const prevOptionsThatMatchFuzzySearch = usePrevious(optionsThatMatchFuzzySearch);

  // when the fake label text changes, scroll to the fuzzy matched item
  // and hide any non-matching options based on props
  useEffect(() => {
    if (props.isEnableFuzzySearch) {
      if (state.fuzzySearchText === '') {
        scrollToElement(id, 0);
        // if we have selected values OR a custom value, clear them out
        if (state.internalStateSelectedValues.length >= 1 || state.createNewCustomValue) {
          handleCallOnUpdate([], false);
        }
        if (props.hideNonMatchingOptions) {
          setInternalStateOptions(props.options);
        }
        if (props.isEnableFuzzySearch) {
          setCreateNewCustomValue(null);
        }
      } else {
        if (props.hideNonMatchingOptions && !isEqual(optionsThatMatchFuzzySearch, prevOptionsThatMatchFuzzySearch)) {
          setInternalStateOptions(optionsThatMatchFuzzySearch);
          return;
        }

        const foundOptionIndex = optionsWithStaticsIncluded.findIndex(o =>
          o.label.toLocaleLowerCase().includes(state.fuzzySearchText.toLocaleLowerCase().trim())
        );
        scrollToElement(id, foundOptionIndex);
      }
    }
  }, [state.fuzzySearchText]);

  // when the lookup search search term changes, call the lookup search function
  useEffect(() => {
    if (props.isEnableLookupSearch && props.onLookupSearch) {
      // if the text is blank, we don't need to do any searching, etc.
      // we just reset the options back to the original ones
      if (state.lookupSearchSearchTerm === '') {
        setLookupSearchText('');
        setInternalStateOptions(props.options);
        setLookupSearchNetworkStatus(ENetworkRequestStatus.IDLE);
        return;
      }
      // Abort any pending requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel();
      }

      // Create new abort controller
      const newAbortController = new AbortController();
      abortControllerRef.current = newAbortController;

      // Create new cancel token source
      const newCancelTokenSource = axios.default.CancelToken.source();
      cancelTokenSourceRef.current = newCancelTokenSource;

      let searchTerm = state.lookupSearchSearchTerm;

      setLookupSearchNetworkStatus(ENetworkRequestStatus.PENDING);

      props
        .onLookupSearch(searchTerm, newAbortController.signal, newCancelTokenSource)
        .then(results => {
          setInternalStateInitialOptions([]); // Clear the initial options afetr look up
          setInternalStateOptions(results);
          setLookupSearchNetworkStatus(ENetworkRequestStatus.SUCCESS);
        })
        .catch(error => {
          if (error.name !== 'AbortError' && !axios.default.isCancel(error)) {
            // error from something OTHER than deliberate signal cancel or canceled token
            setLookupSearchNetworkStatus(ENetworkRequestStatus.ERROR);
            return;
          }
          // error from deliberate signal cancel or cancel token - don't do anything
        });
    }
  }, [state.lookupSearchSearchTerm]);

  // when we change the _text_ of the lookup search
  useEffect(() => {
    if (props.isEnableLookupSearch && state.lookupSearchText === '') {
      setLookupSearchNetworkStatus(ENetworkRequestStatus.IDLE);
      setInternalStateSelectedValues([]);
      setInternalStateInitialOptions([]);
      setInternalStateOptions(props.options);
    }
  }, [state.lookupSearchText]);

  // when we change just the selected values also
  // // update the label text
  const prevInternalStateSelectedValues = usePrevious(state.internalStateSelectedValues);

  useEffect(() => {
    if (isEqual(prevInternalStateSelectedValues, state.internalStateSelectedValues)) {
      return;
    }

    let newLabels = [
      ...state.internalStateSelectedValues.map(v => optionsWithStaticsIncluded.find(o => o.value === v)?.label),
      state.createNewCustomValue ? state.createNewCustomValue : undefined,
    ].filter(l => !isNil(l));

    if (props.selectedValuesRenderer) {
      newLabels = newLabels.filter(l => l !== undefined).map(props.selectedValuesRenderer);
    }
    if (newLabels.length >= 1) {
      if (props.isEnableFuzzySearch) {
        setFuzzySearchText(newLabels.join(', '));
      }
      if (props.isEnableLookupSearch) {
        setLookupSearchText(newLabels.join(', '));
      }
    } else {
      if (props.isEnableFuzzySearch) {
        setFuzzySearchText('');
      }
    }
  }, [state.internalStateSelectedValues]);

  // every time you open the box, scroll to the first selected item
  // everytime you close it, clean some stuff up
  useEffect(() => {
    const rowOffsetHeight = (document.querySelector('.multiselect-item-wrapper')! as HTMLElement)?.offsetHeight;
    if (rowOffsetHeight !== 0 && !Number.isNaN(rowOffsetHeight) && !isNil(rowOffsetHeight)) {
      setHeightOfARow(rowOffsetHeight);
    }

    if (state.internalStateSelectedValues.length <= 0 || !state.isOpen) {
      return;
    }

    const newHeightOfRow = (document.querySelector('.multiselect-item-wrapper')! as HTMLElement)?.offsetHeight;
    if (newHeightOfRow !== 0 || !Number.isNaN(newHeightOfRow)) {
      setHeightOfARow(newHeightOfRow);
    }

    const firstSelectedValue = [...state.internalStateSelectedValues].sort((v1, v2) => {
      return (
        optionsWithStaticsIncluded.findIndex(o => o.value === v1) -
        optionsWithStaticsIncluded.findIndex(o => o.value === v2)
      );
    })[0];

    const indexInList = optionsWithStaticsIncluded.findIndex(op => op.value === firstSelectedValue);

    scrollToElement(id, indexInList);
  }, [state.isOpen]);

  const handleCallOnUpdate = (values: string[], isCustom: boolean) => {
    const cleanedValues = [...values].sort((v1, v2) => {
      return (
        optionsWithStaticsIncluded.findIndex(o => o.value === v1) -
        optionsWithStaticsIncluded.findIndex(o => o.value === v2)
      );
    });

    if (cleanedValues.length === 0) {
      if (props.isEnableFuzzySearch) {
        setFuzzySearchText('');
      }
      if (props.isEnableLookupSearch) {
        setLookupSearchText('');
        setLookupSearchSearchTerm('');
      }
    }

    props.onUpdate(cleanedValues, isCustom, internalStateOptions);
  };

  const handleMultiselectClick = e => {
    e.stopPropagation();
    e.preventDefault();
    if (!props.disabled) {
      setIsOpen(!state.isOpen);
    }
  };

  const handleCheckboxClick = (option: IValueLabelPair, index: number) => {
    // if our selected options already includes the one we just selected...

    if (state.internalStateSelectedValues.includes(`${option.value}`)) {
      // ...re-set the selected options to all of the current selected
      // options, EXCEPT the one we selected
      handleCallOnUpdate(
        state.internalStateSelectedValues.filter(v => v !== `${option.value}`),
        false
      );
    } else {
      // if we've just selected one thats NOT already selected
      if (props.isSingleSelectMode) {
        // ...if we can only select one, just set the new array to the one we selected
        handleCallOnUpdate([`${option.value}`], false);
      } else {
        //...otherwise, set the selected to a new array of all
        // the current selected ones, PLUS our new one
        handleCallOnUpdate([...state.internalStateSelectedValues, `${option.value}`], false);
      }
    }

    if (props.isCloseOnSelect) {
      setIsOpen(false);
    }

    if (props.isEnableCreateNewButton) {
      setCreateNewCustomValue(null);
    }
  };

  const { itemsToDisplayBeforeScroll = 10 } = props;
  let additionalStyleBlock: CSSProperties = {};

  let openPanelHeight =
    heightOfARow * Math.min(Math.max(1, optionsWithStaticsIncluded.length), itemsToDisplayBeforeScroll);

  if (optionsWithStaticsIncluded.length > itemsToDisplayBeforeScroll) {
    additionalStyleBlock = {
      overflow: 'hidden',
      overflowY: 'scroll',
      maxHeight: `${singleItemHeightPixels * itemsToDisplayBeforeScroll}px`,
    };
  }

  const showCreateCustomButton =
    props.isEnableCreateNewButton &&
    state.fuzzySearchText &&
    state.createNewCustomValue !== state.fuzzySearchText &&
    state.fuzzySearchText !== state.internalStateSelectedValues[0] &&
    state.fuzzySearchText.length >= createNewButtonMinimumChars;

  return (
    <div
      onKeyDown={e => {
        if (e.keyCode === 13) handleMultiselectClick(e);
      }}
      tabIndex={0}
      className={`focus-visible:shadow-shadow-marine focus-visible:outline-none multiselect-wrapper ${
        props.className ? props.className : ''
      } ${state.isOpen ? props.openClassName : ''} ${instanceClassname} relative ${fontClass} default:text-sm`}
    >
      <div className={`relative border border-solid ${borderColourClass} ${state.isOpen ? 'is-open' : ''}`}>
        {/* if we enable a fuzzy search, render an input for that */}
        {props.isEnableFuzzySearch && (
          <input
            disabled={props.disabled}
            style={{
              minHeight: height + 'px',
              maxHeight: height + 'px',
              maxWidth: props.isIncludeClearButton ? 'calc(100% - 70px)' : 'calc(100% - 35px)',
            }}
            className={classNames(
              `multiselect-fuzzy-text-search-input truncate default:text-sm default:absolute default:w-full default:border-none default:pl-3 default:text-black default:outline-none`,
              fontClass,
              {
                [`${fuzzySearchInputActiveClass}`]: state.fuzzySearchText !== '',
                [`${fuzzySearchInputInactiveClass}`]: state.fuzzySearchText === '',
              }
            )}
            onChange={e => {
              setFuzzySearchText(e.target.value);
              setCreateNewCustomValue(null);
            }}
            onClick={e => {
              if (!state.isOpen) {
                handleMultiselectClick(e);
              }
            }}
            value={state.fuzzySearchText}
          />
        )}

        {/* if we enable lookup search, render an input for that */}
        {props.isEnableLookupSearch && (
          <input
            disabled={props.disabled}
            style={{
              minHeight: height + 'px',
              maxHeight: height + 'px',
              maxWidth: props.isIncludeClearButton ? 'calc(100% - 70px)' : 'calc(100% - 35px)',
            }}
            className={classNames(
              `multiselect-lookup-search-text-search-input ${fontClass} truncate default:text-sm default:absolute default:w-full default:border-none default:pl-3 default:text-black default:outline-none`,
              {
                [`${fuzzySearchInputActiveClass}`]: state.lookupSearchText !== '',
                [`${fuzzySearchInputInactiveClass}`]: state.lookupSearchText === '',
              }
            )}
            onChange={e => {
              setLookupSearchText(e.target.value);

              if (state.lookupSearchTimeoutId) {
                clearTimeout(state.lookupSearchTimeoutId);
              }

              const timeoutId = setTimeout(() => {
                setInternalStateOptions(props.options);
                setLookupSearchText(e.target.value);
                setLookupSearchSearchTerm(e.target.value);
                setCreateNewCustomValue(null);
              }, lookupSearchDebounceDelay);

              setLookupSearchTimeoutId(timeoutId);
            }}
            onClick={e => {
              // text box click only opens, never closes
              if (!state.isOpen) {
                handleMultiselectClick(e);
              }
            }}
            value={state.lookupSearchText}
          />
        )}
        <div
          style={{
            minHeight: height + 'px',
          }}
          className={classNames(`multiselect-cta flex items-center cursor-pointer`, props.primaryCtaClassName)}
          onClick={handleMultiselectClick}
        >
          <span
            style={{
              maxWidth: props.isIncludeClearButton ? 'calc(100% - 35px)' : 'calc(100% - 5px)',
            }}
            className={classNames(
              'multiselect-label truncate default:block default:pl-3 default:pr-9',
              props.labelClassName
            )}
          >
            {(props.isEnableFuzzySearch && state.fuzzySearchText === '') ||
            (props.isEnableLookupSearch && state.lookupSearchText === '')
              ? props.overwriteLabelText
                ? props.overwriteLabelText
                : labelText
              : labelText}
          </span>

          {props.isIncludeClearButton && (
            <div
              style={{
                right: props.hideDropdownArrow ? '1px' : '35px',
                top: '1px',
                minHeight: height + 'px',
                maxHeight: height + 'px',
              }}
              className="absolute flex justify-center items-center min-w-35px max-w-35px cursor-pointer"
            >
              <i
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsOpen(false);
                  setFuzzySearchText('');
                  setLookupSearchText('');
                  setLookupSearchSearchTerm('');
                  setInternalStateSelectedValues([]);
                  handleCallOnUpdate([], false);
                }}
                className="fas fa-times-circle fa-lg relative text-gray-100 hover:text-teal-100"
              ></i>
            </div>
          )}

          <div
            style={{
              minHeight: height + 'px',
              maxHeight: height + 'px',
            }}
            className={classNames(
              `absolute flex justify-center items-center top-0 right-0 min-w-35px max-w-35px cursor-pointer border-l border-solid ${borderColourClass}`,
              {
                hidden: props.hideDropdownArrow,
              }
            )}
          >
            <i
              className={classNames(
                'fas relative text-gray-100 transition-rotate duration-500 ease-in-out fa-chevron-up',
                {
                  '-rotate-180': !state.isOpen,
                }
              )}
            ></i>
          </div>
        </div>
      </div>

      {state.isOpen && (
        <>
          <div
            className={classNames(
              'multiselect-items absolute border border-solid font-pt-sans w-full z-50',
              borderColourClass,
              props.itemsClassname,
              {
                'is-open': state.isOpen,
                'border-t-0': dropdownPosition === 'bottom',
                'border-b-0 bottom-37px': dropdownPosition === 'top',
              }
            )}
            style={additionalStyleBlock}
          >
            {state.lookupSearchNetworkStatus === ENetworkRequestStatus.PENDING && (
              <div className="flex items-center min-h-11 outline-none">
                <span className="p-3 text-gray-80 italic flex items-center space-x-2">
                  <i className="text-xl fas fa-circle-notch fa-spin text-brown-140"></i>
                  <span>Searching...</span>
                </span>
              </div>
            )}
            {state.lookupSearchNetworkStatus === ENetworkRequestStatus.ERROR && (
              <div className="flex items-center min-h-11 outline-none">
                <span className="p-3 text-red-95 italic flex items-center space-x-2">
                  <i className="text-xl fas fa-times text-red-95"></i>
                  <span>There was an error loading results. Please try again.</span>
                </span>
              </div>
            )}
            {optionsWithStaticsIncluded.length <= 0 && (
              <div className="flex items-center min-h-11 outline-none">
                <span className="p-3 text-gray-80 italic">{noOptionsAvailableText}</span>
              </div>
            )}
            {optionsWithStaticsIncluded.map((option, optionIndex) => {
              if (option.isDivider) {
                return (
                  <div key={`divider-${optionIndex}`}>
                    <hr
                      style={{
                        borderTop: '1px solid #D5D2D1', // styling HRs with classes is weird, unfortunately
                      }}
                      className="m-0"
                    />
                  </div>
                );
              }

              const optionLabel = props.optionsLabelRenderer ? props.optionsLabelRenderer(option) : option.label;

              const selectedItemContentClassName = props.selectedItemContentClassName || 'text-teal-100 font-bold';

              return (
                <div
                  key={`${option.value}-${optionIndex}`}
                  style={{
                    minHeight: height + 'px',
                  }}
                  className={classNames(
                    `multiselect-item-wrapper index-${optionIndex} flex flex-col items-center outline-none`,
                    {
                      selected: state.internalStateSelectedValues.includes(`${option.value}`),
                    }
                  )}
                >
                  <label
                    className={`multiselect-item-cta default:cursor-pointer default:flex default:flex-1 default:items-center default:w-full ${itemCtaPaddingClassName} ${props.itemCtaClassName ||
                      ''}`}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleCheckboxClick(option, optionIndex);
                    }}
                  >
                    {!props.hideCheckboxes && (
                      <React.Fragment>
                        <Checkbox
                          checked={state.internalStateSelectedValues.includes(`${option.value}`)}
                          onChange={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleCheckboxClick(option, optionIndex);
                          }}
                        />
                        <span
                          className={classNames(
                            `flex flex-row items-center ml-2 text-sm cursor-pointer`,
                            {
                              [selectedItemContentClassName]: state.internalStateSelectedValues.includes(
                                `${option.value}`
                              ),
                              'w-full justify-between': option.iconAlign === 'end',
                            },
                            props.itemContentClassName
                          )}
                        >
                          {option.iconAlign && option.iconAlign === 'right' && (
                            <React.Fragment>
                              <span className="item-label">{optionLabel}</span>
                              {option.icon && <i className={`mr-2 ${option.icon}`}></i>}
                              {option.iconHtml && option.iconHtml}
                            </React.Fragment>
                          )}

                          {option.iconAlign && option.iconAlign === 'end' && (
                            <React.Fragment>
                              <span className="item-label">{optionLabel}</span>
                              {option.icon && <i className={`mr-2 ${option.icon}`}></i>}
                              {option.iconHtml && option.iconHtml}
                            </React.Fragment>
                          )}

                          {option.iconAlign && option.iconAlign === 'left' && (
                            <React.Fragment>
                              {option.icon && <i className={`mr-2 ${option.icon}`}></i>}
                              {option.iconHtml && option.iconHtml}
                              <span className="item-label">{optionLabel}</span>
                            </React.Fragment>
                          )}

                          {option.iconAlign == null && (
                            <React.Fragment>
                              {option.icon && <i className={`mr-2 ${option.icon}`}></i>}
                              {option.iconHtml && option.iconHtml}
                              <span className="item-label">{optionLabel}</span>
                            </React.Fragment>
                          )}
                        </span>
                      </React.Fragment>
                    )}
                    {props.hideCheckboxes && (
                      <React.Fragment>
                        <button
                          className={classNames(
                            `flex flex-row items-center p-0 bg-transparent cursor-pointer border-none outline-none text-sm text-left`,
                            {
                              [selectedItemContentClassName]: state.internalStateSelectedValues.includes(
                                `${option.value}`
                              ),
                              'w-full justify-between': option.iconAlign === 'end',
                            },
                            props.itemContentClassName
                          )}
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleCheckboxClick(option, optionIndex);
                          }}
                        >
                          {option.iconAlign && option.iconAlign === 'right' && (
                            <React.Fragment>
                              <span className="item-label">{optionLabel}</span>
                              {option.icon && <i className={`mr-2 ${option.icon}`}></i>}
                              {option.iconHtml && option.iconHtml}
                            </React.Fragment>
                          )}

                          {option.iconAlign && option.iconAlign === 'end' && (
                            <React.Fragment>
                              <span className="item-label">{optionLabel}</span>
                              {option.icon && <i className={`mr-2 ${option.icon}`}></i>}
                              {option.iconHtml && option.iconHtml}
                            </React.Fragment>
                          )}

                          {option.iconAlign && option.iconAlign === 'left' && (
                            <React.Fragment>
                              {option.icon && <i className={`mr-2 ${option.icon}`}></i>}
                              {option.iconHtml && option.iconHtml}
                              <span className="item-label">{optionLabel}</span>
                            </React.Fragment>
                          )}

                          {option.iconAlign == null && (
                            <React.Fragment>
                              {option.icon && <i className={`mr-2 ${option.icon}`}></i>}
                              {option.iconHtml && option.iconHtml}
                              <span className="item-label">{optionLabel}</span>
                            </React.Fragment>
                          )}
                        </button>
                      </React.Fragment>
                    )}
                  </label>
                </div>
              );
            })}
          </div>
          {showCreateCustomButton && (
            <div
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                handleCallOnUpdate([state.fuzzySearchText], true);

                setCreateNewCustomValue(state.fuzzySearchText);
                setIsOpen(false);
              }}
              className={classNames(
                'absolute border border-solid font-pt-sans w-full z-50',
                borderColourClass,
                props.itemsClassname,
                {
                  'border-t-0': dropdownPosition === 'bottom',
                  'border-b-0 bottom-37px': dropdownPosition === 'top',
                }
              )}
              style={{
                marginTop: `${openPanelHeight}px`,
                height: `${singleItemHeightPixels}px`,
              }}
            >
              <div className={classNames(`cursor-pointer flex space-x-2 items-center w-full`, itemCtaPaddingClassName)}>
                <span className="text-brown-100">Create New:</span>
                <span className="font-bold truncate max-w-[calc(100%-110px)]">{state.fuzzySearchText}</span>
                <SvgIcon
                  className="fill-transparent stroke-brown-100 stroke-1 w-[22px] h-[22px]"
                  IconComponent={PlusHollow}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
