import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import FluidButton from 'ui/FluidButton';
import { DetailsLink } from 'ui/DetailsLink';
import { MenuButton } from 'ui/MenuButton/stateful';

interface IAction {
  handler: () => void;
  loading: boolean;
  label?: string;
}

interface IToggle {
  handler: (isOpen: boolean) => void;
  isOpen: boolean;
}

interface IControls {
  className?: string;
  csvExport?: IAction[];
  balanceCarriedForward?: IAction;
  generatePdf?: IAction;
  filters?: IToggle;
  downloadHistory?: IToggle;
  children?: React.ReactNode;
}

export const Controls: React.FC<IControls> = React.memo(
  ({ className, csvExport, generatePdf, balanceCarriedForward, filters, downloadHistory }) => {
    const buttons = [csvExport, generatePdf, balanceCarriedForward];

    const getButtonType = (idx: number): 'primary' | 'secondary' =>
      buttons.slice(idx + 1).some(x => x) ? 'secondary' : 'primary';

    return (
      <div className={classNames(className, 'ledger-controls mb-5 mx-15px flex justify-between')}>
        <div className="toggles flex items-end">
          {filters && (
            <DetailsLink
              className="filters-link mr-8"
              text="See Filters"
              isOpen={filters.isOpen}
              onClick={filters.handler}
            />
          )}
          {downloadHistory && (
            <DetailsLink
              className="download-history-link"
              text="See Download History"
              isOpen={downloadHistory.isOpen}
              onClick={downloadHistory.handler}
            />
          )}
        </div>
        <div className="btns flex">
          {csvExport && <CsvExport type={getButtonType(0)} actions={csvExport} />}
          {generatePdf && (
            <FluidButton
              className="pdf-btn ml-10px"
              type={getButtonType(1)}
              isLoading={generatePdf.loading}
              disabled={generatePdf.loading}
              onClick={generatePdf.handler}
            >
              Download PDF Statement
            </FluidButton>
          )}
          {balanceCarriedForward && (
            <FluidButton
              className="balance-carried-forward-btn ml-10px"
              type={getButtonType(2)}
              isLoading={balanceCarriedForward.loading}
              disabled={balanceCarriedForward.loading}
              onClick={balanceCarriedForward.handler}
            >
              Balance Carried Forward
            </FluidButton>
          )}
        </div>
      </div>
    );
  }
);

interface ICsvExport {
  type: 'primary' | 'secondary';
  actions: IAction[];
}

const CsvExport: React.FC<ICsvExport> = React.memo(({ actions, type }) => {
  if (actions.length === 0) {
    return null;
  }

  const isLoading = actions.some(x => x.loading);

  if (actions.length === 1) {
    return (
      <FluidButton
        className="export-csv"
        type={type}
        isLoading={isLoading}
        disabled={isLoading}
        onClick={actions[0].handler}
      >
        {actions[0].label || 'Export CSV'}
      </FluidButton>
    );
  }

  const options = useMemo(
    () =>
      actions.map((x, i) => ({
        key: `${i}`,
        label: x.label as string,
        handler: x.handler,
      })),
    [actions]
  );

  const handleSelect = useCallback(ops => options.find(x => x.key === ops.key)?.handler(), [options]);

  return (
    <MenuButton
      className="export-csv"
      fixedWidth="150px"
      options={options}
      onSelect={handleSelect}
      isLoading={isLoading}
      disabled={isLoading}
    >
      Export CSV
    </MenuButton>
  );
});
