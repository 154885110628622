import { ICompanyLogoPosition } from 'store/modules/companyInfo/model';
import { IUploadFileInfo } from './UploadResponse';

export interface ICompany {
  uuid: string;
  companyId: string;
  name: string;
  countryCode: string;
  address: string;
  phoneNumber: string | null;
  website: string | null;
  logoPosition: ICompanyLogoPosition | null;
  marketingSource: EMarketingSource | null;
  companyType: ECompanyType | null;
  comment: string | null;
  salesRepresentative: string | null;
  serviceChargeOverride: string | null;
  disableTaAccessToFinance: boolean;
  externalMarkupPercentage: string | null;
  enableInstantBooking: boolean;
  usdCreditLimit: string | null;
  eurCreditLimit: string | null;
  createdAt?: string;
  updatedAt?: string;
}

export interface ICompanyResponse {
  meta: {
    type: string;
    id: string;
    associations?: string[];
  };
  data: ICompany;
}

export interface ICompaniesResponse {
  meta: {
    type: string;
    total: number;
    // TODO: array of what is it? always empty:/
    associations?: any[];
  };
  data: ICompany[];
}

export interface ICompanyLogoResponse {
  data: IUploadFileInfo;
}

export interface IMainCompanyInfoResponse {
  data: {
    logo: IUploadFileInfo;
    info: ICompany;
  }
}

export enum EMarketingSource {
  HOTEL_REFERRAL = 'Hotel Referral',
  PARTNER_REFERRAL = 'Partner Referral',
  SALES_TEAM_REFERRAL = 'Sales Team Referral',
  TRADE_SHOW = 'Trade Show',
  EVENT = 'Event',
  CAMPAIGN = 'Campaign',
  WEB_2_LEAD = 'Web 2 Lead',
  NEWSLETTER = 'Newsletter',
  EMAIL = 'Email',
  SOCIAL_MEDIA = 'Social Media',
}

export enum ECompanyType {
  TOUR_OPERATOR = 'Tour Operator',
  TRAVEL_AGENT = 'Travel Agent',
  CONCIERGE = 'Concierge',
  PERSONAL_ASSISTANT = 'Personal Assistant',
  CONSORTIA = 'Consortia',
  OTHER = 'Other',
}
