import { IAirlineListItem, IGuest } from 'services/BackendApi/types';

export const GET_GUEST_INFORMATION_REQUEST = 'bookingManager/GET_GUEST_INFORMATION_REQUEST';
export const GET_GUEST_INFORMATION_SUCCESS = 'bookingManager/GET_GUEST_INFORMATION_SUCCESS';
export const GET_GUEST_INFORMATION_FAILURE = 'bookingManager/GET_GUEST_INFORMATION_FAILURE';

export const SAVE_GUEST_INFORMATION_REQUEST = 'bookingManager/SAVE_GUEST_INFORMATION_REQUEST';
export const SAVE_GUEST_INFORMATION_SUCCESS = 'bookingManager/SAVE_GUEST_INFORMATION_SUCCESS';
export const SAVE_GUEST_INFORMATION_FAILURE = 'bookingManager/SAVE_GUEST_INFORMATION_FAILURE';

export const GET_AIRLINES_REQUEST = 'bookingManager/GET_AIRLINES_REQUEST';
export const GET_AIRLINES_SUCCESS = 'bookingManager/GET_AIRLINES_SUCCESS';
export const GET_AIRLINES_FAILURE = 'bookingManager/GET_AIRLINES_FAILURE';

export type GetGuestInformationRequestAction = ReturnType<typeof getGuestInformationRequestAction>;
export const getGuestInformationRequestAction = () => ({
  type: GET_GUEST_INFORMATION_REQUEST as typeof GET_GUEST_INFORMATION_REQUEST,
});

export type GetGuestInformationSuccessAction = ReturnType<typeof getGuestInformationSuccessAction>;
export const getGuestInformationSuccessAction = (guests: IGuest[]) => ({
  type: GET_GUEST_INFORMATION_SUCCESS as typeof GET_GUEST_INFORMATION_SUCCESS,
  guests,
});

export type GetGuestInformationFailureAction = ReturnType<typeof getGuestInformationFailureAction>;
export const getGuestInformationFailureAction = (error: string) => ({
  type: GET_GUEST_INFORMATION_FAILURE as typeof GET_GUEST_INFORMATION_FAILURE,
  error,
});

export type SaveGuestInformationRequestAction = ReturnType<typeof saveGuestInformationRequestAction>;
export const saveGuestInformationRequestAction = (guests: IGuest[]) => ({
  type: SAVE_GUEST_INFORMATION_REQUEST as typeof SAVE_GUEST_INFORMATION_REQUEST,
  guests,
});

export type SaveGuestInformationSuccessAction = ReturnType<typeof saveGuestInformationSuccessAction>;
export const saveGuestInformationSuccessAction = (guests: IGuest[]) => ({
  type: SAVE_GUEST_INFORMATION_SUCCESS as typeof SAVE_GUEST_INFORMATION_SUCCESS,
  guests,
});

export type SaveGuestInformationFailureAction = ReturnType<typeof saveGuestInformationFailureAction>;
export const saveGuestInformationFailureAction = (error: string) => ({
  type: SAVE_GUEST_INFORMATION_FAILURE as typeof SAVE_GUEST_INFORMATION_FAILURE,
  error,
});

export type GetAirlinesRequestAction = ReturnType<typeof getAirlinesRequestAction>;
export const getAirlinesRequestAction = () => ({
  type: GET_AIRLINES_REQUEST as typeof GET_AIRLINES_REQUEST,
});

export type GetAirlinesSuccessAction = ReturnType<typeof getAirlinesSuccessAction>;
export const getAirlinesSuccessAction = (airlines: IAirlineListItem[]) => ({
  type: GET_AIRLINES_SUCCESS as typeof GET_AIRLINES_SUCCESS,
  airlines,
});

export type GetAirlinesFailureAction = ReturnType<typeof getAirlinesFailureAction>;
export const getAirlinesFailureAction = (error: string) => ({
  type: GET_AIRLINES_FAILURE as typeof GET_AIRLINES_FAILURE,
  error,
});

export type GuestInformationAction =
  | GetGuestInformationRequestAction
  | GetGuestInformationSuccessAction
  | GetGuestInformationFailureAction
  | SaveGuestInformationRequestAction
  | SaveGuestInformationSuccessAction
  | SaveGuestInformationFailureAction
  | GetAirlinesRequestAction
  | GetAirlinesSuccessAction
  | GetAirlinesFailureAction;
