import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const AddToPhotosIcon: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors['brown-100'], width = 19, height = 19 }) => (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6665 2L6.6665 2C5.8415 2 5.1665 2.675 5.1665 3.5L5.1665 12.5C5.1665 13.325 5.8415 14 6.6665 14L15.6665 14C16.4915 14 17.1665 13.325 17.1665 12.5V3.5C17.1665 2.675 16.4915 2 15.6665 2ZM3.6665 5H2.1665L2.1665 15.5C2.1665 16.325 2.8415 17 3.6665 17H14.1665V15.5L3.6665 15.5L3.6665 5ZM11.9165 8.75L14.9165 8.75V7.25L11.9165 7.25L11.9165 4.25H10.4165L10.4165 7.25L7.4165 7.25V8.75L10.4165 8.75V11.75H11.9165V8.75Z"
        fill={fill}
      />
    </svg>
  )
);

export default AddToPhotosIcon;
