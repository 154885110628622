import { ITravelAgent } from 'services/BackendApi';
import { ECompanyManagerType, SortableFields } from './model';

export const GET_REQUEST = 'companyManagers/GET_REQUEST';
export const GET_SUCCESS = 'companyManagers/GET_SUCCESS';
export const GET_FAILURE = 'companyManagers/GET_FAILURE';

export const GET_REQUEST_IN_TC = 'companyManagers/GET_REQUEST_IN_TC';
export const GET_SUCCESS_IN_TC = 'companyManagers/GET_SUCCESS_IN_TC';
export const GET_FAILURE_IN_TC = 'companyManagers/GET_FAILURE_IN_TC';

export const UPDATE_REQUEST = 'companyManagers/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'companyManagers/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'companyManagers/UPDATE_FAILURE';

export const TOGGLE_ITEM = 'companyManagers/TOGGLE_ITEM';
export const TOGGLE_SORT_BY = 'companyManagers/TOGGLE_BY';
export const TOGGLE_SORT_ORDER = 'companyManagers/TOGGLE_SORT_ORDER';

export const SAVE_CHANGES = 'companyManagers/SAVE_CHANGES';

export type GetRequestAction = ReturnType<typeof getRequestAction>;
export const getRequestAction = (companyUuid: string) => ({
  type: GET_REQUEST as typeof GET_REQUEST,
  companyUuid,
});

export type GetSuccessAction = ReturnType<typeof getSuccessAction>;
export const getSuccessAction = (travelAgents: ITravelAgent[]) => ({
  type: GET_SUCCESS as typeof GET_SUCCESS,
  travelAgents,
});

export type GetFailureAction = ReturnType<typeof getFailureAction>;
export const getFailureAction = (error: string) => ({
  type: GET_FAILURE as typeof GET_FAILURE,
  error,
});

export type GetRequestInTCAction = ReturnType<typeof getRequestInTCAction>;
export const getRequestInTCAction = (companyUuid: string) => ({
  type: GET_REQUEST_IN_TC as typeof GET_REQUEST_IN_TC,
  companyUuid,
});

export type GetSuccessInTCAction = ReturnType<typeof getSuccessInTCAction>;
export const getSuccessInTCAction = (travelAgents: ITravelAgent[]) => ({
  type: GET_SUCCESS_IN_TC as typeof GET_SUCCESS_IN_TC,
  travelAgents,
});

export type GetFailureInTCAction = ReturnType<typeof getFailureInTCAction>;
export const getFailureInTCAction = (error: string) => ({
  type: GET_FAILURE_IN_TC as typeof GET_FAILURE_IN_TC,
  error,
});

export type UpdateRequestAction = ReturnType<typeof updateRequestAction>;
export const updateRequestAction = (companyUuid: string) => ({
  type: UPDATE_REQUEST as typeof UPDATE_REQUEST,
  companyUuid,
});

export type UpdateSuccessAction = ReturnType<typeof updateSuccessAction>;
export const updateSuccessAction = () => ({
  type: UPDATE_SUCCESS as typeof UPDATE_SUCCESS,
});

export type UpdateFailureAction = ReturnType<typeof updateFailureAction>;
export const updateFailureAction = (error: string) => ({
  type: UPDATE_FAILURE as typeof UPDATE_FAILURE,
  error,
});

export type ToggleItemAction = ReturnType<typeof toggleItemAction>;
export const toggleItemAction = (uuid: string, managerType: ECompanyManagerType) => ({
  type: TOGGLE_ITEM as typeof TOGGLE_ITEM,
  uuid,
  managerType,
});

export type ToggleSortByAction = ReturnType<typeof toggleSortByAction>;
export const toggleSortByAction = (sortBy: SortableFields) => ({
  type: TOGGLE_SORT_BY as typeof TOGGLE_SORT_BY,
  sortBy,
});

export type ToggleSortOrderAction = ReturnType<typeof toggleSortOrderAction>;
export const toggleSortOrderAction = () => ({
  type: TOGGLE_SORT_ORDER as typeof TOGGLE_SORT_ORDER,
});

export type SaveChangesAction = ReturnType<typeof saveChangesAction>;
export const saveChangesAction = (companyUuid: string) => ({
  type: SAVE_CHANGES as typeof SAVE_CHANGES,
  companyUuid
});

export type Any =
  | GetRequestAction
  | GetSuccessAction
  | GetFailureAction
  | GetRequestInTCAction
  | GetSuccessInTCAction
  | GetFailureInTCAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | ToggleItemAction
  | ToggleSortByAction
  | ToggleSortOrderAction
  | SaveChangesAction;
