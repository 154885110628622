import React from 'react';

import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const AnonymousAvatarIcon: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors['brown-100'], background = 'transparent' }) => (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ backgroundColor: background }}
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5ZM15 6.25C17.075 6.25 18.75 7.925 18.75 10C18.75 12.075 17.075 13.75 15 13.75C12.925 13.75 11.25 12.075 11.25 10C11.25 7.925 12.925 6.25 15 6.25ZM7.5 19.975C9.1125 22.4 11.875 24 15 24C18.125 24 20.8875 22.4 22.5 19.975C22.4625 17.4875 17.4875 16.125 15 16.125C12.5 16.125 7.5375 17.4875 7.5 19.975Z"
        fill={fill}
      />
    </svg>
  )
);

export default AnonymousAvatarIcon;

