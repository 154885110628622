import { call, put } from 'redux-saga/effects';
import { IGetExportResponse, IPostExportResponse } from 'services/BookingManagerApi';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { enqueueNotification } from 'store/modules/ui/actions';

/*

Copy and paste these 2 functions into your module's `saga.ts`

export function* postTriggerExport() {
  const bookingManagerApi = makeBookingManagerApi();
  const query: IGlobalStatementQuery = yield select(Selectors.tableDataQuerySelector);

  yield ExportCsvPollingSaga.postTriggerExportCsv(
    bookingManagerApi.postGlobalStatementExportCsv,
    Actions.triggerExportCsvSuccessAction,
    Actions.triggerExportCsvFailureAction,
    query.filter
  );
}

export function* getPollExportCsv() {
  const bookingManagerApi = makeBookingManagerApi();
  const exportCsvNamespace = yield select(Selectors.exportCsvNamespaceSelector);

  yield ExportCsvPollingSaga.getPollExportCsv(
    bookingManagerApi.getGlobalStatementExportCsvPoll,
    `Global Statement Export - ${formatDateTimeIncludeTimezoneDisplay(new Date())}`,
    exportCsvNamespace.exportUuid,
    Actions.pollExportCsvSuccessAction,
    Actions.pollExportCsvFailureAction,
    Actions.pollExportCsvContinueAction
  );
}

And paste this into your `saga.ts` watch function

yield takeLatest([Actions.TRIGGER_EXPORT_CSV_REQUEST], postTriggerExport);
yield takeLatest([Actions.POLL_EXPORT_CSV_REQUEST], getPollExportCsv);

*/
export function* postTriggerExportCsv(httpPostFunc, triggerSuccessFunc, triggerFailureFunc, filter?) {
  const result: AxiosResponse<IPostExportResponse> = filter
    ? yield call(httpPostFunc, filter)
    : yield call(httpPostFunc);

  if (result.status === 200) {
    yield put(triggerSuccessFunc(result.data.exportUuid));
  } else {
    yield put(triggerFailureFunc());
    yield put(
      enqueueNotification({
        message: 'There was a problem triggering the export',
        options: { variant: 'error' },
      })
    );
  }
}

export function* getPollExportCsv(
  httpPollFunc,
  csvFileName,
  exportUuid,
  pollSuccessFunc,
  pollFailureFunc,
  pollContinueFunc
) {
  try {
    const result: AxiosResponse<IGetExportResponse> = yield call(httpPollFunc, exportUuid);

    switch (result.data.status) {
      case 200:
        yield put(pollSuccessFunc(result.data.data.signedUrl));
        saveAs(result.data.data.signedUrl, csvFileName);
        break;
      case 404:
      case 422:
        // theres been an issue somewhere - stop everything
        yield put(pollFailureFunc());
        yield put(
          enqueueNotification({
            message: 'There was a problem downloading the export',
            options: { variant: 'error' },
          })
        );
        break;
      case 202:
      default:
        // deliberately nothing - we keep polling
        yield put(pollContinueFunc());
        break;
    }
  } catch (e) {
    yield put(pollFailureFunc());
  }
}
