import { IChecklistItem } from 'services/BookingManagerApi';

export const GET_CHECKLIST_REQUEST = 'bookingManager/GET_CHECKLIST_REQUEST';
export const GET_CHECKLIST_SUCCESS = 'bookingManager/GET_CHECKLIST_SUCCESS';
export const GET_CHECKLIST_FAILURE = 'bookingManager/GET_CHECKLIST_FAILURE';

export const UPDATE_CHECKLIST_REQUEST = 'bookingManager/UPDATE_CHECKLIST_REQUEST';
export const UPDATE_CHECKLIST_SUCCESS = 'bookingManager/UPDATE_CHECKLIST_SUCCESS';
export const UPDATE_CHECKLIST_FAILURE = 'bookingManager/UPDATE_CHECKLIST_FAILURE';

export type GetChecklistRequestAction = ReturnType<typeof getChecklistRequestAction>;
export const getChecklistRequestAction = () => ({
  type: GET_CHECKLIST_REQUEST as typeof GET_CHECKLIST_REQUEST
});

export type GetChecklistSuccessAction = ReturnType<typeof getChecklistSuccessAction>;
export const getChecklistSuccessAction = (checklist: IChecklistItem[]) => ({
  type: GET_CHECKLIST_SUCCESS as typeof GET_CHECKLIST_SUCCESS,
  checklist,
});

export type GetChecklistFailureAction = ReturnType<typeof getChecklistFailureAction>;
export const getChecklistFailureAction = (error: string) => ({
  type: GET_CHECKLIST_FAILURE as typeof GET_CHECKLIST_FAILURE,
  error,
});

export type UpdateChecklistRequestAction = ReturnType<typeof updateChecklistRequestAction>;
export const updateChecklistRequestAction = (label: string, checked: boolean) => ({
  type: UPDATE_CHECKLIST_REQUEST as typeof UPDATE_CHECKLIST_REQUEST,
  label,
  checked
});

export type UpdateChecklistSuccessAction = ReturnType<typeof updateChecklistSuccessAction>;
export const updateChecklistSuccessAction = (checklist: IChecklistItem[]) => ({
  type: UPDATE_CHECKLIST_SUCCESS as typeof UPDATE_CHECKLIST_SUCCESS,
  checklist,
});

export type UpdateChecklistFailureAction = ReturnType<typeof updateChecklistFailureAction>;
export const updateChecklistFailureAction = (error: string) => ({
  type: UPDATE_CHECKLIST_FAILURE as typeof UPDATE_CHECKLIST_FAILURE,
  error,
});

export type ChecklistAction =
  | GetChecklistRequestAction
  | GetChecklistSuccessAction
  | GetChecklistFailureAction
  | UpdateChecklistRequestAction
  | UpdateChecklistSuccessAction
  | UpdateChecklistFailureAction;
