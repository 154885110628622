import { omit } from 'lodash-es';
import { call, takeLatest, debounce, select, put, all } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { IInventorySupplierSearchContractingResponse, makeInventoryApi } from 'services/InventoryAPI';

import * as Actions from './actions';
import * as Selectors from './selectors';
import { IInventoryList } from './model';

import { enqueueNotification } from '../ui';

export function* postInventorySupplierRequestSaga(action: Actions.PostSupplierRequestAction) {
  const inventoryApi = makeInventoryApi();

  try {
    const response = yield call(inventoryApi.postInventorySupplier, action.supplierForSaving);
    const supplierUuid = response.data?.uuid;
    if (!supplierUuid) {
      throw new Error('There was a problem creating a new supplier.');
    }

    const contactRequests = action.contacts.map(contactForSaving => {
      return call(inventoryApi.postInventoryContact, contactForSaving)
    }); 
    const contactResponses: AxiosResponse[] = yield all(contactRequests);
    const contactUuids = contactResponses.map(response => response.data?.uuid);

    yield call(inventoryApi.addContactsToSupplier, supplierUuid, contactUuids);

    yield put(Actions.postSupplierSuccessAction());
    yield put(enqueueNotification({
      message: 'Supplier created successfully.',
      options: { variant: 'success' },
    }));
    yield call(action.history.push, `/inventory/suppliers/${supplierUuid}/edit`);
  } catch (e) {
    yield put(Actions.postSupplierFailureAction(e.message));
    if (Number(e?.response?.status) === 422) {
      yield put(enqueueNotification({
        message: 'This name or ID has already been registered.',
        options: { variant: 'error' },
      }));
    } else {
      yield put(enqueueNotification({
        message: 'There was a problem creating a new supplier.',
        options: { variant: 'error' },
      }));
    }
  }
}

export function* listRequestSaga() {
  const inventoryApi = makeInventoryApi();
  const { filter }: IInventoryList = yield select(Selectors.listSelector);

  try {
    const { data }: AxiosResponse<IInventorySupplierSearchContractingResponse> = yield call(
      inventoryApi.searchInventorySuppliersContracting,
      omit(filter, 'page_total')
    );
    yield put(Actions.listSuccessAction(data.results, data.total_items));
  } catch (e) {
    yield put(Actions.listFailureAction(e.message));

    yield put(enqueueNotification({
      message: 'There was a problem listing inventory suppliers.',
      options: { variant: 'error' },
    }));
  }
}

export function* getInventorySupplierRequestSaga(action: Actions.GetSupplierRequestAction) {
  const inventoryApi = makeInventoryApi();

  try {
    const response = yield call(inventoryApi.getInventorySupplier, action.supplierUuid);
    yield put(Actions.getSupplierSuccessAction(response.data));
  } catch (e) {
    yield put(Actions.getSupplierFailureAction(e.message));
    yield put(enqueueNotification({
      message: 'There was a problem getting supplier information.',
      options: { variant: 'error' },
    }));
  }
}

export function* getSupplierByPublicIdSaga(action: Actions.GetSupplierByPublicIdRequestAction) {
  const inventoryApi = makeInventoryApi();

  try {
    if (!action.supplierPublicId) {
      throw new Error('Supplier id is incorrect');
    }

    const { data }: AxiosResponse<IInventorySupplierSearchContractingResponse> = yield call(
      inventoryApi.searchInventorySuppliersContracting,
      { public_id: action.supplierPublicId, page: 1, page_size: 10 }
    );
    const supplierUuid = data.results[0].uuid;
    yield call(getInventorySupplierRequestSaga, Actions.getSupplierRequestAction(supplierUuid));
    yield put(Actions.getSupplierByPublicIdSuccessAction());
  } catch (e) {
    yield put(Actions.getSupplierByPublicIdFailureAction(e.message));

    yield put(enqueueNotification({
      message: 'There was a problem getting supplier information.',
      options: { variant: 'error' },
    }));
  }
}

export function* putInventorySupplierRequestSaga(action: Actions.PutSupplierRequestAction) {
  const inventoryApi = makeInventoryApi();

  try {
    const supplierUuid = action.supplierForSaving.uuid;
    if (!supplierUuid) {
      throw new Error('There was a problem updating supplier.');
    }

    yield call(inventoryApi.putInventorySupplier, action.supplierForSaving);
    yield call(inventoryApi.deleteContactsFromSupplier, supplierUuid);

    const contactRequests = action.contacts.map(contactForSaving => {
      return call(inventoryApi.postInventoryContact, contactForSaving)
    }); 
    const contactResponses: AxiosResponse[] = yield all(contactRequests);
    const contactUuids = contactResponses.map(response => response.data?.uuid);

    yield call(inventoryApi.addContactsToSupplier, supplierUuid, contactUuids);

    yield put(Actions.putSupplierSuccessAction());
    yield put(Actions.getSupplierRequestAction(supplierUuid));
    yield put(enqueueNotification({
      message: 'Supplier updated successfully.',
      options: { variant: 'success' },
    }));
  } catch (e) {
    yield put(Actions.putSupplierFailureAction(e.message));
    yield put(enqueueNotification({
      message: 'There was a problem updating a new supplier.',
      options: { variant: 'error' },
    }))
  }
}

export function* watchInventorySuppliersSaga() {
  yield takeLatest([ Actions.PUT_SUPPLIER_REQUEST ], putInventorySupplierRequestSaga);
  yield takeLatest([ Actions.GET_SUPPLIER_REQUEST ], getInventorySupplierRequestSaga);
  yield takeLatest([ Actions.GET_SUPPLIER_BY_PUBLIC_ID_REQUEST ], getSupplierByPublicIdSaga);
  yield takeLatest([ Actions.POST_SUPPLIER_REQUEST ], postInventorySupplierRequestSaga);

  yield takeLatest(
    [
      Actions.LIST_REQUEST,
      Actions.SET_FILTER_COUNTRY_CODE,
      Actions.SET_ORDER,
      Actions.SET_PAGE,
      Actions.SET_PER_PAGE,
    ],
    listRequestSaga
  );

  yield debounce(
    200,
    [
      Actions.SET_FILTER_ID,
      Actions.SET_FILTER_NAME,
    ],
    listRequestSaga
  );
}
