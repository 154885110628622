import React from 'react';

import { AdditionalMarkups } from './AdditionalMarkups';
import { InvoiceAddressee } from './InvoiceAddressee';
import { CompanyLogo } from './CompanyLogo';
import { CompanyDocuments } from './CompanyDocuments';
import { HotelList } from './HotelList';
import { CompanyManagers } from './CompanyManagers';
import { CompanyIncentives } from './CompanyIncentives';
import { SimpleTabs } from 'ui/SimpleTabs';
import { Departments } from './Departments';
import { Memberships } from './Memberships';
import { Finances } from './Finances';

interface ICompanySettingsTabsProps {
  companyUuid: string;
  isEditMode: boolean;
}

export const CompanySettingsTabs: React.FC<ICompanySettingsTabsProps> = ({ companyUuid, isEditMode }) => {
  const companySettingsTabs = [
    {
      title: 'Mark-Ups',
      name: 'additional-markups',
      styles: '',
      styleObject: { minWidth: '121px' },
      renderContent: () => <AdditionalMarkups isEditMode={isEditMode} />,
    },
    {
      title: 'Hotels',
      name: 'hotel-list',
      styles: '',
      styleObject: { minWidth: '93px' },
      renderContent: () => <HotelList companyUuid={companyUuid} isEditMode={isEditMode} />,
    },
    {
      title: 'Travel Agents',
      name: 'travel-agents',
      styles: '',
      styleObject: { minWidth: '146px' },
      renderContent: () => <CompanyManagers companyUuid={companyUuid} isEditMode={isEditMode} />,
    },
    {
      title: 'Incentives',
      name: 'incentives',
      styles: '',
      styleObject: { minWidth: '125px' },
      renderContent: () => <CompanyIncentives companyUuid={companyUuid} isEditMode={isEditMode} />,
    },
    {
      title: 'Invoices',
      name: 'invoice-addressee',
      styles: '',
      styleObject: { minWidth: '98px' },
      renderContent: () => <InvoiceAddressee companyUuid={companyUuid} isEditMode={isEditMode} />,
    },
    {
      title: 'Departments',
      name: 'departments',
      styles: '',
      styleObject: { minWidth: '144px' },
      renderContent: () => <Departments companyUuid={companyUuid} isEditMode={isEditMode} />,
    },
    {
      title: 'Memberships',
      name: 'memberships',
      styles: '',
      styleObject: { minWidth: '152px' },
      renderContent: () => <Memberships companyUuid={companyUuid} isEditMode={isEditMode} />,
    },
    {
      title: 'Finances',
      name: 'finances',
      styles: '',
      styleObject: { minWidth: '116px' },
      renderContent: () => <Finances companyUuid={companyUuid} isEditMode={isEditMode} />,
    },
    {
      title: 'Logo',
      name: 'company-logo',
      styles: '',
      styleObject: { minWidth: '104px' },
      renderContent: () => <CompanyLogo companyUuid={companyUuid} isEditMode={isEditMode} />,
    },
    {
      title: 'Uploads',
      name: 'uploads',
      styles: '',
      styleObject: { minWidth: '113px' },
      renderContent: () => <CompanyDocuments isEditMode={isEditMode} />,
    },
  ];

  return <SimpleTabs tabConfig={companySettingsTabs} />;
};
