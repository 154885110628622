import { createSelector } from 'reselect';
import { ICompanyInvoiceAddresseeDomain } from './model';

export const domainSelector = (state: any) => state.companyInvoiceAddressee;

export const editableSelector = createSelector(
  domainSelector,
  (domain: ICompanyInvoiceAddresseeDomain) => domain.editable
);

export const requestSelector = createSelector(
  domainSelector,
  (domain: ICompanyInvoiceAddresseeDomain) => domain.request
);


