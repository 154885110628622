import React from 'react';
import { theme } from '../../../../tailwind.config';
import { IIconProps } from 'ui/CircleIconButton';

export const ArrowUpIcon: React.FC<IIconProps> = React.memo(({ className = '', icon = 'brown-100' }) => (
  <svg width="11" height="7" className={className} viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.08112 0.238868L10.7576 4.84681C11.0808 5.1653 11.0808 5.6803 10.7576 5.9954L9.98046 6.76113C9.65724 7.07962 9.13457 7.07962 8.81479 6.76113L5.5 3.49492L2.18521 6.76113C1.86199 7.07962 1.33932 7.07962 1.01954 6.76113L0.242419 5.9954C-0.0808066 5.67691 -0.0808066 5.16191 0.242419 4.8468L4.91888 0.238868C5.23523 -0.0796223 5.75789 -0.0796223 6.08112 0.238868Z"
      fill={theme.colors[icon]}
    />
  </svg>
));

export default ArrowUpIcon;
