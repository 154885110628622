import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import * as TableCardUI from 'pureUi/TableCard';
import * as SummaryFormExtrasStyles from './SummaryFormExtras.styles';
import * as AgentsSelectors from 'store/modules/agents/selectors';
import { isNilOrEmpty } from 'ramda-adjunct';
import { getTaFullName } from 'store/utils';
import { useSelector } from 'react-redux';

interface IBookingHotelProps {
  className?: string;
}

export const TravelAgentInfo: React.FC<IBookingHotelProps> = React.memo(({ className = '' }) => {
  const travelAgent = useSelector(AgentsSelectors.selectedTaSelector);

  return (
    <TableCardUI.TableCardBox className="mt-4 mb-4">
    <TableCardUI.TableCardRow depth={3}>
      <SummaryFormExtrasStyles.Title>Travel agent</SummaryFormExtrasStyles.Title>
      {!isNilOrEmpty(travelAgent) && (
        <SummaryFormExtrasStyles.TravelAgent>
          <SummaryFormExtrasStyles.TravelAgentName>
            {travelAgent && getTaFullName(travelAgent)}
          </SummaryFormExtrasStyles.TravelAgentName>
        </SummaryFormExtrasStyles.TravelAgent>
      )}
    </TableCardUI.TableCardRow>
  </TableCardUI.TableCardBox>
	);
});