import React, { Fragment, HTMLAttributes } from 'react';
import { Route, Switch, withRouter, RouteProps } from 'react-router';
import { compose } from 'redux';
import { withBreakpoints } from 'react-breakpoints';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { EBreakpoints, breakpointValue, WithBreakpointsProps } from 'pureUi/breakpoints';
import { Container } from './styles';

interface NavigationLayoutProps extends HTMLAttributes<HTMLDivElement> {
  menu: JSX.Element;
  menuPath: string;
}

export interface NavigationLayoutConnectedProps extends NavigationLayoutProps, WithBreakpointsProps, Omit<RouteProps, 'children'> {    
}

export const NavigationLayout = (props: NavigationLayoutConnectedProps) => {
  const { className, menu, menuPath, children, currentBreakpoint, location } = props;
  const isMobileView = breakpointValue(currentBreakpoint) < breakpointValue(EBreakpoints.TABLET_UP);
  const isMenuView = menuPath === location?.pathname;

  return (
    <Container className={classNames(className, { mobile: isMobileView })}>
      {isMobileView ? (
          <main>
            <TransitionGroup className="animation-container">
              <CSSTransition
                key={location?.key}
                classNames={`animate ${isMenuView ? 'in' : 'out'}`}
                timeout={300}
              >
                {/* @ts-ignore */}
                <Switch location={location}>
                  <Route exact path={menuPath}>{menu}</Route>
                  {children}
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </main>
        )
        : (
          <Fragment>
            <aside>{menu}</aside>
            <main>{children}</main>
          </Fragment>
        )
      }
    </Container>
  );
};


export const NavigationLayoutConnected = compose<React.ComponentType<NavigationLayoutProps>>(
  withRouter,
  withBreakpoints
)(NavigationLayout);

