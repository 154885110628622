import React, { useCallback, useMemo } from 'react';
import { formatDateDisplay, formatDateRangeDisplay } from 'utils';
import { renderFullName } from 'utils/guestInformation';
import { useDispatch, useSelector } from 'react-redux';
import * as ProposalListSelectors from 'store/modules/proposalsList/selectors';
import { setSortAction } from 'store/modules/proposalsList/actions';
import { Table } from 'ui/Table';
import { ESortOrder } from 'store/common/types';
import { GoToDetails } from 'ui/GoToDetails';
import { EProposalSelectionSortableKey, IProposalSelection } from 'services/BookingManagerApi/types/ProposalSelection';

interface IProposalListTableProps {
  proposals: IProposalSelection[];
  isSr: boolean;
}

export const ProposalListTable: React.FC<IProposalListTableProps> = React.memo(({ proposals, isSr }) => {
  const dispatch = useDispatch();
  const loading = useSelector(ProposalListSelectors.tableLoadingSelector);
  const sortBy = useSelector(ProposalListSelectors.sortBySelector);
  const sortOrder = useSelector(ProposalListSelectors.sortOrderSelector);

  const columns = useMemo(
    () => [
      {
        id: 'name',
        label: 'Name',
        width: '200px',
        fieldToSortBy: EProposalSelectionSortableKey.NAME,
        className: 'pl-2',
      },
      {
        id: 'travel-agent',
        label: 'Travel Agent',
        width: '200px',
        isHidden: !isSr,
        fieldToSortBy: EProposalSelectionSortableKey.TRAVEL_AGENT_NAME,
      },
      {
        id: 'travel-dates',
        label: 'Travel Dates',
        width: '150px',
        fieldToSortBy: EProposalSelectionSortableKey.TRAVEL_DATES,
      },
      {
        id: 'client',
        label: 'Client',
        width: '200px',
        fieldToSortBy: EProposalSelectionSortableKey.GUEST_NAME,
      },
      {
        id: 'createdAt',
        label: 'Created',
        width: '70px',
        fieldToSortBy: EProposalSelectionSortableKey.CREATED_AT,
      },
      {
        id: 'updatedAt',
        label: 'Modified',
        width: '70px',
        fieldToSortBy: EProposalSelectionSortableKey.UPDATED_AT,
      },
      {
        id: 'bookings',
        label: 'Bookings',
        width: '60px',
      },
      {
        id: 'hotels',
        label: 'Hotels',
        width: '44px',
      },
      {
        id: 'actions',
        label: '',
        width: '70px',
      },
    ],
    [isSr]
  );

  const rows = useMemo(() => {
    return proposals.map(proposal => {
      const rowItemClassName = 'text-ellipsis overflow-hidden whitespace-nowrap';
      const travelAgentFullName = isSr
        ? renderFullName(proposal.travelAgentTitle, proposal.travelAgentFirstName, proposal.travelAgentLastName)
        : '';

      const travelDate = formatDateRangeDisplay(proposal.earliestBookingArrival, proposal.latestBookingDeparture) || '';

      const clientFullName = (
        <span className="break-words">
          {renderFullName(proposal.guestTitle, proposal.guestFirstName, proposal.guestLastName)}
        </span>
      );

      const detailsLink = `/proposals-v2/${proposal.uuid}`;

      const actions = (
        <div className="flex items-center">
          <span className="border-l-4 border-solid border-gray-10 h-30px pl-5 rounded">
            <GoToDetails link={detailsLink} />
          </span>
        </div>
      );

      return {
        id: proposal.uuid,
        cells: [
          {
            id: 'name',
            title: proposal.name,
            value: proposal.name,
            className: `${rowItemClassName} pl-4`,
          },
          {
            id: 'travel-agent',
            title: travelAgentFullName,
            value: travelAgentFullName,
            className: rowItemClassName,
            isHidden: !isSr,
          },
          {
            id: 'travel-date',
            title: travelDate,
            value: travelDate,
          },
          {
            id: 'client',
            value: clientFullName,
            className: rowItemClassName,
          },
          {
            id: 'created-at',
            value: formatDateDisplay(proposal.createdAt),
          },
          {
            id: 'updated-at',
            value: formatDateDisplay(proposal.updatedAt),
          },
          {
            id: 'bookings',
            value: proposal.bookingUuids.length,
            className: 'text-center',
          },
          {
            id: 'hotels',
            value: proposal.numberOfHotels,
            className: 'text-center',
          },
          {
            id: 'actions',
            value: actions,
          },
        ],
      };
    });
  }, [proposals, isSr]);

  const handleSort = useCallback(
    (newSortBy: EProposalSelectionSortableKey, newSortOrder: ESortOrder) => {
      dispatch(setSortAction(newSortBy, newSortOrder));
    },
    [dispatch]
  );

  return (
    <Table
      columns={columns}
      rows={rows}
      sortBy={sortBy}
      sortOrder={sortOrder}
      onSort={handleSort}
      messageWhenEmpty="No data available."
      loading={loading}
    />
  );
});
