import { ENetworkRequestStatus } from 'services/BackendApi';

export interface IDates {
  startDate: string | null;
  endDate: string | null;
}

export interface IAvailability {
  availableDates: string[] | null;
  unavailableDates: string[] | null;
  requestDates: string[] | null;
}

export interface IAccommodationAvailability extends IAvailability {
  accommodationUuid: string | null;
}

export interface IAggregate extends IDates {
  accommodationsAggregates: IAccommodationAvailability[];
}

export interface ISingle extends IDates, IAccommodationAvailability {}

export interface IStaticRatesAvailabilityDomain {
  aggregate: IAggregate | null;
  single: ISingle | null;
  error: string | null;
  networkRequests: {
    aggregate: ENetworkRequestStatus;
    single: ENetworkRequestStatus;
  };
}

export const initialState: IStaticRatesAvailabilityDomain = {
  aggregate: null,
  single: null,
  error: null,
  networkRequests: {
    aggregate: ENetworkRequestStatus.IDLE,
    single: ENetworkRequestStatus.IDLE,
  },
};
