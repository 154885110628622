import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import QuoteMarginSelector from 'components/QuoteMarginSelector';
import * as TaLogoSelectors from 'store/modules/bookingManager/subdomains/taLogo/selectors';
import { EPdfLogo } from 'store/modules/bookingManager/subdomains/breakdown/model';
import { getTaLogoRequestAction } from 'store/modules/bookingManager/subdomains/taLogo/actions';

type QuoteOption = EPdfLogo | null;

interface IDownloadQuoteMarginModal {
    isQuoteDownloading?: boolean;
    onDownload: (quoteOption: QuoteOption) => void;
    onClose: () => void;
};

export const DownloadQuoteMarginModal = ({isQuoteDownloading, onDownload, onClose}: IDownloadQuoteMarginModal) => {
    const [quoteMarginLogoOption, setQuoteMarginLogoOption] = useState<QuoteOption>(null);
    const companyLogo = useSelector(TaLogoSelectors.taLogoSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTaLogoRequestAction());
    }, []);

    const handleSelectCreateQuoteWithMarginOption = useCallback(() => {
        setQuoteMarginLogoOption(EPdfLogo.MAIN_COMPANY_LOGO);
    }, [setQuoteMarginLogoOption]);

    const handleSelectCreateQuoteWithoutMarginWithLogoOption = useCallback(() => {
        setQuoteMarginLogoOption(EPdfLogo.TA_LOGO);
    }, [setQuoteMarginLogoOption]);

    const handleSelectCreateQuoteWithoutMarginWithoutLogoOption = useCallback(() => {
        setQuoteMarginLogoOption(EPdfLogo.NO_LOGO);
    }, [setQuoteMarginLogoOption]);

    const handleDownload = useCallback(() => {
        onDownload(quoteMarginLogoOption)
    }, [onDownload, quoteMarginLogoOption]);

    return (
      <QuoteMarginSelector
        isQuoteDownloading={isQuoteDownloading}
        downloadQuoteButtonTitle='Download'
        hasCompanyLogo={Boolean(companyLogo)}
        selectedLogoOption={quoteMarginLogoOption}
        onClose={onClose}
        onDownloadQuote={handleDownload}
        onQuoteWithMarginOptionSelect={handleSelectCreateQuoteWithMarginOption}
        onQuoteWithoutMarginOptionWithLogoSelect={handleSelectCreateQuoteWithoutMarginWithLogoOption}
        onQuoteWithoutMarginOptionWithoutLogoSelect={handleSelectCreateQuoteWithoutMarginWithoutLogoOption}
      />
    );
};

export default DownloadQuoteMarginModal;