import { initialState, IGuestInformationSubdomain } from './model';
import * as Actions from './actions';
import { ENetworkRequestStatus } from 'services/BackendApi';

const guestInformationReducer = (
  state: IGuestInformationSubdomain = initialState,
  action: Actions.GuestInformationAction
): IGuestInformationSubdomain => {
  switch (action.type) {
    case Actions.GET_GUEST_INFORMATION_REQUEST:
      return { ...state, loading: true, error: null };

    case Actions.GET_GUEST_INFORMATION_SUCCESS:
      return { ...state, loading: false, guests: action.guests, error: null };

    case Actions.GET_GUEST_INFORMATION_FAILURE:
      return { ...state, loading: false, error: action.error };

    case Actions.SAVE_GUEST_INFORMATION_REQUEST:
      return { ...state, saving: true, error: null };

    case Actions.SAVE_GUEST_INFORMATION_SUCCESS:
      return { ...state, saving: false, guests: action.guests, error: null };

    case Actions.SAVE_GUEST_INFORMATION_FAILURE:
      return { ...state, saving: false, error: action.error };

    case Actions.GET_AIRLINES_REQUEST:
      return { ...state, airlinesLoad: ENetworkRequestStatus.PENDING };

    case Actions.GET_AIRLINES_SUCCESS:
      return { ...state, airlines: action.airlines, airlinesLoad: ENetworkRequestStatus.SUCCESS };

    case Actions.GET_AIRLINES_FAILURE:
      return { ...state, airlinesLoad: ENetworkRequestStatus.ERROR };

    default:
      return state;
  }
};

export default guestInformationReducer;
