import React, { useLayoutEffect, useRef, useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { EPurchaseCostReviewStatus } from 'services/BookingManagerApi/types';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { patchPurchaseCostReviewRequestStatusSelector } from 'store/modules/bookingManager/subdomains/purchaseCostReview/selectors';
import { useCurrentWidth } from 'effects';
import { warning, approved, rejected } from 'ui/Icons';
import { FluidButton } from 'ui/FluidButton';

type StatusToggler = (status: EPurchaseCostReviewStatus) => void;

interface IPurchaseCostCTAProps {
  status: EPurchaseCostReviewStatus | null;
  handler: StatusToggler;
  showDetailsHandler: () => void;
  disabled?: boolean;
}

const NAV_BREAKPOINT = 1280;
const NAV_HEIGHT = 70;
const CTA_HEIGHT = 50;
const CTA_MARGIN = 16;

const getBreakdown = (element: HTMLDivElement | null) => {
  const defValue = { top: 0, width: 0 };
  if (!element) {
    return defValue;
  }

  const breakdown = element.nextSibling;
  if (!breakdown) {
    return defValue;
  }

  return {
    top: breakdown['offsetTop'] - CTA_HEIGHT - CTA_MARGIN,
    width: breakdown['offsetWidth'],
  };
};

const sharedClasses = {
  'purchase-cost-cta flex text-base font-pt-sans justify-center items-center px-2': true,
  'border border-solid rounded': true,
};

const useIsLoading = () => {
  const isLoading = useSelector(patchPurchaseCostReviewRequestStatusSelector);
  return isLoading === ENetworkRequestStatus.PENDING;
};

interface PurchaseCostBannerProps {
  className: string;
  status: EPurchaseCostReviewStatus;
  icon: string;
  cta?: React.ReactElement;
  children?: React.ReactNode;
}

const PurchaseCostBanner: React.FC<PurchaseCostBannerProps> = React.memo(props => {
  const classes = classNames({ ...sharedClasses, [props.className]: true });

  return (
    <div className={classes} style={{ height: '100%' }}>
      <img
        className="purchase-cost-cta-icon block file-icon mr-2"
        src={props.icon}
        width="28"
        height="28"
        alt={`${props.status} icon`}
      />
      <span className="purchase-cost-cta-message mr-2">{props.children}</span>
      {props.cta ? <div className="purchase-cost-cta-buttons flex">{props.cta}</div> : null}
    </div>
  );
});

const PurchaseCostApproved: React.FC<{
  handler: StatusToggler;
  disabled?: boolean;
}> = React.memo(({ handler, disabled }) => {
  const isLoading = useIsLoading();

  const moveToReviewHandler = useCallback(() => {
    handler(EPurchaseCostReviewStatus.PENDING);
  }, [handler]);

  const cta = (
    <FluidButton
      type="primary"
      className="mr-2"
      isLoading={isLoading}
      onClick={moveToReviewHandler}
      disabled={disabled}
    >
      Move to Review
    </FluidButton>
  );

  return (
    <PurchaseCostBanner
      className="bg-green-25 border-green-50"
      status={EPurchaseCostReviewStatus.APPROVED}
      icon={approved}
      cta={cta}
    >
      <b>The Hotel Purchase Cost is approved</b>
    </PurchaseCostBanner>
  );
});

const PurchaseCostRejected: React.FC<{
  handler: StatusToggler;
  showDetailsHandler: () => void;
  disabled?: boolean;
}> = React.memo(({ handler, showDetailsHandler, disabled }) => {
  const isLoading = useIsLoading();

  const moveToReviewHandler = useCallback(() => {
    handler(EPurchaseCostReviewStatus.PENDING);
  }, [handler]);

  const cta = (
    <FluidButton
      type="primary"
      className="mr-2"
      isLoading={isLoading}
      onClick={moveToReviewHandler}
      disabled={disabled}
    >
      Move to Review
    </FluidButton>
  );

  return (
    <PurchaseCostBanner
      className="bg-red-25 border-red-95"
      status={EPurchaseCostReviewStatus.REJECTED}
      icon={rejected}
      cta={cta}
    >
      <b>The Hotel Purchase Cost is rejected.</b> Please, correct it and move to Review.{' '}
      <a className="underline text-red-95 hover:text-red-95 cursor-pointer" onClick={showDetailsHandler}>
        Show Details
      </a>
    </PurchaseCostBanner>
  );
});

const PurchaseCostPendingReview: React.FC<{ handler: StatusToggler; disabled?: boolean }> = React.memo(
  ({ handler, disabled }) => {
    const approveHandler = useCallback(() => handler(EPurchaseCostReviewStatus.APPROVED), [handler]);

    const rejectHandler = useCallback(() => handler(EPurchaseCostReviewStatus.REJECTED), [handler]);

    const cta = (
      <>
        <FluidButton type="primary" className="mr-2" onClick={approveHandler} disabled={disabled}>
          Approve
        </FluidButton>
        <FluidButton type="secondary" onClick={rejectHandler} disabled={disabled}>
          Reject
        </FluidButton>
      </>
    );

    return (
      <PurchaseCostBanner
        className="bg-yellow-20 border-yellow-50"
        status={EPurchaseCostReviewStatus.PENDING}
        icon={warning}
        cta={cta}
      >
        <b>The Hotel Purchase Cost is pending review.</b> Please, check to Approve or Reject
      </PurchaseCostBanner>
    );
  }
);

const PurchaseCostPendingCancellation: React.FC<{ handler: StatusToggler }> = React.memo(({ handler }) => {
  const handlerCbk = useCallback(() => handler(EPurchaseCostReviewStatus.CANCELLED), [handler]);

  const cta = (
    <FluidButton type="primary" className="mr-2" onClick={handlerCbk} disabled={false}>
      Cancel
    </FluidButton>
  );

  return (
    <PurchaseCostBanner
      className="bg-yellow-20 border-yellow-50"
      status={EPurchaseCostReviewStatus.PENDING_CANCELLATION}
      icon={warning}
      cta={cta}
    >
      <b>The Hotel Purchase Cost is Pending Cancellation.</b> Please move to Cancelled once the Finance table is correct
    </PurchaseCostBanner>
  );
});

const PurchaseCostCancelled: React.FC = React.memo(() => {
  return (
    <PurchaseCostBanner
      className="bg-yellow-20 border-yellow-50"
      status={EPurchaseCostReviewStatus.CANCELLED}
      icon={warning}
    >
      <b>The Hotel Purchase Cost is cancelled</b>
    </PurchaseCostBanner>
  );
});

export const PurchaseCostCTA: React.FC<IPurchaseCostCTAProps> = ({ status, handler, showDetailsHandler, disabled }) => {
  const { currentWidth } = useCurrentWidth();
  const [isSticky, setSticky] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const isNavigationFixed = currentWidth < NAV_BREAKPOINT;

  const onScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    if (window.pageYOffset > getBreakdown(container).top - (isNavigationFixed ? NAV_HEIGHT : 0)) {
      if (!isSticky) {
        setSticky(true);
        container.style.width = `${getBreakdown(container).width}px`;
      }
    } else {
      if (isSticky) {
        setSticky(false);
      }
    }
  }, [isSticky, isNavigationFixed]);

  const onResize = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    containerRef.current.style.width = 'auto';
    if (isSticky) {
      containerRef.current.style.width = `${getBreakdown(containerRef.current).width}px`;
    }
  }, [isSticky]);

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onResize);
    };
  }, [onScroll, onResize]);

  const containerClasses = classNames({
    'purchase-cost-cta-wrapper': true,
    'w-full mb-4 z-1': true,
    relative: !isSticky,
    fixed: isSticky,
  });

  const containerStyles = { height: `${CTA_HEIGHT}px`, width: 'inherit' };

  if (isSticky) {
    containerStyles['top'] = isNavigationFixed ? `${NAV_HEIGHT}px` : '0px';
  }

  if (status === EPurchaseCostReviewStatus.INITIAL) {
    return null;
  }

  return (
    <div ref={containerRef} style={{ minHeight: `${CTA_HEIGHT}px`, opacity: 0.99 }}>
      <div className={containerClasses} style={containerStyles}>
        {status === EPurchaseCostReviewStatus.APPROVED && (
          <PurchaseCostApproved handler={handler} disabled={disabled} />
        )}
        {status === EPurchaseCostReviewStatus.REJECTED && (
          <PurchaseCostRejected handler={handler} showDetailsHandler={showDetailsHandler} disabled={disabled} />
        )}
        {status === EPurchaseCostReviewStatus.PENDING && (
          <PurchaseCostPendingReview handler={handler} disabled={disabled} />
        )}
        {status === EPurchaseCostReviewStatus.PENDING_CANCELLATION && (
          <PurchaseCostPendingCancellation handler={handler} />
        )}
        {status === EPurchaseCostReviewStatus.CANCELLED && <PurchaseCostCancelled />}
      </div>
    </div>
  );
};
