import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { companiesSelector } from 'store/modules/agents/selectors';

import { TCurrencyCode } from 'interfaces';
import { BookingLedger } from './AllTransactions/BookingLedger';
import { TCBookingStatementLedger } from './BookingsStatement/TCBookingLedger';
import { ELedgerType } from 'ui/Ledger/types';
import { BookingLedgerTitle } from 'ui/Ledger';
import { Link } from 'ui/Link';
import { RouterTabs } from 'ui/RouterTabs';

const BookingLedgerFrame: React.FC = React.memo(() => {
  const params = useParams<Record<string, string>>();
  const { companyUuid } = params;
  const currency: TCurrencyCode = params.currency.toUpperCase() as TCurrencyCode;

  const companies = useSelector(companiesSelector);
  const selectedCompanyName = useMemo(() => companies?.find(company => company.uuid === companyUuid)?.name, [
    companies,
    companyUuid,
  ]);

  const bookingStatementsTabs = [
    {
      title: 'All Transactions',
      route: 'all-transactions',
      styles: 'px-15px min-w-150px',
      renderContent: () => <BookingLedger companyUuid={companyUuid} currency={currency} />,
    },
    {
      title: 'Bookings Statement',
      route: 'bookings-statement',
      styles: 'px-15px min-w-180px',
      renderContent: () => <TCBookingStatementLedger companyUuid={companyUuid} currency={currency} />,
    },
  ];

  return (
    <div className="booking-ledger-container container mx-auto max-w-1280px font-pt-sans text-black">
      <Link to="/statement">
        <span className="inline-block cursor-pointer font-bold mb-10px underline text-brown-100">
          Back to statement summary
        </span>
      </Link>

      <BookingLedgerTitle
        currency={currency}
        selectedCompanyName={selectedCompanyName}
        type={ELedgerType.BOOKING_LEDGER}
      />

      <RouterTabs
        tabConfig={bookingStatementsTabs}
        routeContainerClassname="flex border border-solid border-gray-40 border-t-0 pt-15px pb-30px"
      />
    </div>
  );
});

export { BookingLedgerFrame };
