import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchBookingsRequestAction } from "store/modules/inventoryHeader/actions";
import { getHeaderBookingsSelector, loadingHeaderBookingsSelector } from "store/modules/inventoryHeader/selectors";
import { ENetworkRequestStatus } from "services/BackendApi";
import CircleCloseIcon from 'ui/Icons/circleClose.component.svg';
import MagnifyingIcon from 'ui/Icons/magnifying-glass.component.svg';
import Loading from 'ui/AsyncSearchDropdown/Loading';
import { without } from "ramda";
import TextInput from 'pureUi/TextInput';
import classNames from "classnames";
import AnimateHeight from "react-animate-height";
import { Link } from "react-router-dom";


export const HeaderBookingSearch: React.FC = () => {
  const dispatch = useDispatch();
  const [bookingSearchValue, setBookingSearchValue] = useState('');
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const headerBookings = useSelector(getHeaderBookingsSelector);
  const loadingHeaderBookings = useSelector(loadingHeaderBookingsSelector);

  const handleOnMouseEnter = useCallback((key: string) => {
    if (!openKeys.includes(key)) {
      setOpenKeys([...openKeys, key]);
    }
  }, [openKeys]);

  const handleOnMouseOut = useCallback((key: string) => {
    if (openKeys.includes(key)) {
      setOpenKeys(without([key], openKeys));
    }
  }, [openKeys]);

  useEffect(() => {
    if (bookingSearchValue.length >= 3) {
      dispatch(searchBookingsRequestAction(bookingSearchValue));
    }
  }, [bookingSearchValue]);

  return (
    <div className="w-[380px] p-[10px] bg-white-hard flex flex-col">
      {/* @ts-ignore */}
      <TextInput
        onChange={e => setBookingSearchValue(e.currentTarget.value)}
        placeholder='Enter Booking Reference ...'
        className='flex flex-col relative'
        value={bookingSearchValue}
        inputClassName='!py-[10px] !px-[30px] !text-black !bg-white-hard'
      >
        <CircleCloseIcon onClick={() => setBookingSearchValue('')} className={classNames("block cursor-pointer fill-brown-80 hover:fill-brown-120 absolute w-[18px] top-[10px] right-[10px]", { "hidden": bookingSearchValue === '' })} />
        <MagnifyingIcon className='absolute w-[18px] top-[10px] left-[10px] fill-light-gray rotate-90' />
      </TextInput>
      {
        loadingHeaderBookings === ENetworkRequestStatus.PENDING ?
          (
            <div className="py-2">
              <Loading />
            </div>
          ) : (
            headerBookings.length && bookingSearchValue.length >= 3 ? (
              <div className="flex flex-col flex-1 max-h-[295px] overflow-auto">
                <div className="text-gray-100 text-[13px] mt-[10px] font-normal font-pt-sans leading-[15px] tracking-wide">BOOKINGS ({`${headerBookings.length > 10 ? '10+' : headerBookings.length}`})</div>
                <div className='search-options'>
                  {headerBookings.slice(0, 10).map((option, index, arr) => {
                    const bookingUuid = option.bookingUuid;
                    const urls = option.urls;
                    return (
                      <>
                        <div key={`search-option-${option.bookingUuid}`} onMouseEnter={() => handleOnMouseEnter(bookingUuid)} onMouseLeave={() => handleOnMouseOut(bookingUuid)} className={classNames("p-[5px] my-[5px] flex flex-col justify-center font-pt-sans rounded-[3px] hover:bg-gray-10")}>
                          <span className='h-[19px]'>{option.bookingRefNumber}</span>
                          <AnimateHeight duration={300} height={openKeys.includes(bookingUuid) ? 'auto' : 0} className='pt-[5px]'>
                            <div className="text-gray-100 text-[13px] font-bold">{option.hotelName}</div>
                            <div className="text-gray-100 text-[13px] font-normal">{option.bookingCurrency} {option.price}</div>
                            <div className="h-[15px] justify-start items-start gap-2.5 inline-flex pt-[5px]">
                              <Link className="!text-brown-100 text-[13px] font-normal underline leading-[15px]" to={urls.breakdown}>Breakdown</Link>
                              <Link className="!text-brown-100 text-[13px] font-normal underline leading-[15px]" to={urls.finance}>Finance</Link>
                              <Link className="!text-brown-100 text-[13px] font-normal underline leading-[15px]" to={urls.tcStatements}>TC Statements</Link>
                              <Link className="!text-brown-100 text-[13px] font-normal underline leading-[15px]" to={urls.tcDepositAccount}>TC Deposit Account</Link>
                            </div>
                          </AnimateHeight>
                        </div>
                        {index + 1 < arr.length && <div className="h-[0px] border border-gray-10" />}
                      </>
                    )
                  })}
                </div>
              </div>
            ) : (
              loadingHeaderBookings === ENetworkRequestStatus.SUCCESS && bookingSearchValue.length >= 3 && <div className="text-black text-[15px] font-normal mt-[10px] italic font-pt-sans">No results found.</div>
            )
          )
      }
    </div>
  );
};