import styled from 'styled-components';
import { Container, BackButton, Modal } from '@pure-escapes/webapp-ui-components';

import { Breadcrumbs } from 'components';

import { theme } from 'styles';
import { pureUiTheme } from 'pureUi/pureUiTheme';

export const StyledHotelContainer = styled(Container)`
  // margin-bottom: ${theme.spacing.gutter * 2}px;

  // ${props => props.theme.breakpoints.tablet`
  //   padding-top: ${theme.spacing.gutter * 6.5}px;
  // `}
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-left: ${theme.spacing.gutter}px;
  margin-right: ${theme.spacing.gutter}px;
  margin-top: 0;
  margin-bottom: 0;
`;

export const Full = styled.div`
  display: flex;
`;

export const Back = styled(BackButton)`
  display: flex;
  padding: 0 ${theme.spacing.gutter * 2}px !important;
`;

export const Title = styled.h3`
  font-size: ${theme.fonts.sizes.default}px;
  padding: 0 0 ${theme.spacing.gutter * 1.45}px;
  text-transform: uppercase;
  font-weight: ${theme.fonts.bold};
  letter-spacing: ${theme.fonts.letterSpacing.medium}px;
  line-height: 14px;
  color: ${theme.palette.neutral};
  border-bottom: 1px solid ${theme.borders.default};
`;

export const Aside = styled.aside``;

export const AsideDetails = styled.div`
  margin: 0 ${theme.spacing.gutter * 2}px ${theme.spacing.gutter * 3.5}px;

  ${props => props.theme.breakpoints.tablet`
    margin-left: 0;
    margin-right: 0;
  `}
`;

export const Brochure = styled.a`
  display: block;
  cursor: pointer;
  color: ${theme.palette.primary};
  font-size: ${theme.fonts.sizes.default}px;
  font-weight: ${theme.fonts.bold};
  text-transform: uppercase;
  line-height: 14px;
  letter-spacing: 0.38px;
  padding: ${theme.spacing.gutter * 0.75}px 0;

  :before {
    content: '+ ';
  }
`;

export const StyledModal = styled(Modal)``;

export const SummaryActions = styled.div`
  display: flex;
  margin: ${theme.spacing.gutter * 1.5}px 0;
`;

export const Text = styled.p`
  color: ${theme.palette.secondary};
  font-size: ${theme.fonts.sizes.normal}px;
`;

export const AddToProposalStyles = styled.div`
  margin: 10px 0;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-row-gap: 20px;
  min-width: 400px;
  font-size: 10px;
`;

export const LiveRatePricesModalStyles = styled.div`
  padding: 20px 40px;
  max-width: 490px;
  min-width: 400px;

  .icon {
    display: flex;
    justify-content: center;
  }

  .title {
    text-align: center;
    margin-top: 20px;
    font-weight: 700;
  }

  .message {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${pureUiTheme.colors.goldDark};
    margin-top: 20px;
    margin-bottom: 0;
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-top: 35px;
  }

  .liveRatePricesModalTotals {
    padding: 10px;
    border-left: 3px solid ${pureUiTheme.colors.teal};
    background-color: ${pureUiTheme.colors.grayDepth2};
    margin-top: 20px;

    .totalCostBefore {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .totalCostAfter {
      margin-top: 3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .totalCostTitle {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: ${pureUiTheme.colors.goldNeutral};
    }
    .totalCostRate {
      font-size: 17px;
      line-height: 20px;
      font-weight: 700;
      color: ${pureUiTheme.colors.goldDark};
    }
    .totalCostRateNow {
      font-size: 19px;
      line-height: 23px;
      font-weight: 700;
      color: ${pureUiTheme.colors.redFade};
    }
  }

  .liveRateTotalPhrase {
    color: ${pureUiTheme.colors.goldNeutral};
    font-size: 12px;
    line-height: 14px;
    margin-top: 20px;
  }
`;

export const LiveRateItemStyles = styled.div`
  .liveRateItem {
    margin-top: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${pureUiTheme.colors.grayDepth45};

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    .headingTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: ${pureUiTheme.colors.goldDark}
      margin-top: 0;
      margin-bottom: 3px;
    }
    
    .guests {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .guestsLeft {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: ${pureUiTheme.colors.brownHard};
        margin-bottom: 0;
        margin-top: 0;

        .guestsDetails {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          text-transform: none;
        }
      }
    }

    .rate {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .rateLeft { 
        .rateLeftNumber {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: ${pureUiTheme.colors.brown100};
        }

        .rateLeftMealPlan {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: ${pureUiTheme.colors.goldNeutral};
          text-transform: uppercase;
        }
        
        .rateLeftNumber.rateLeftNew, .rateLeftMealPlan.rateLeftNew {
            color: ${pureUiTheme.colors.redFade};
          }
      }
    }

    .rateRight {
      .rateRightPrice {
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        text-align: right;
        color: ${pureUiTheme.colors.goldDark};
      }

      .rateRightPriceNew {
        color: ${pureUiTheme.colors.redFade};
      }

      .rateRightNow {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        text-align: right;
        color: ${pureUiTheme.colors.redFade};
        margin-right: 5px;
      }

      .rateRightNotAvailable {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        text-align: right;
        color: ${pureUiTheme.colors.goldDark};
      }
    }

    .line-through {
      text-decoration: line-through;
    }
  }
`;

export const RatesWarningModalStyles = styled.div`
  padding: 20px 40px;
  max-width: 490px;
  min-width: 400px;
  
  .icon {
    display: flex;
    justify-content: center;
  }
  
  .message {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${pureUiTheme.colors.goldDark};
    margin-top: 20px;
    margin-bottom: 0;
  }
  .question {
    text-align: center;
    margin-top: 30px
    font-weight: 700;
  }
  
  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-top: 30px
  }
`;
