import { call, takeLatest, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBackendApi, ISalesRepresentativesResponse } from 'services/BackendApi';
import { getSRListFailureAction, GetSRListRequestAction, getSRListSuccessAction, GET_LIST_REQUEST } from './actions';

export function* getSrsSaga(action: GetSRListRequestAction) {
  try {
    const backendApi = makeBackendApi();

    const result: AxiosResponse<ISalesRepresentativesResponse> = yield call(backendApi.getSRs);
    
    yield put(getSRListSuccessAction(result.data.data));
  } catch (e) {
    yield put(getSRListFailureAction(e.message));
  }
}

export function* watchGetSrsSaga() {
  yield takeLatest([GET_LIST_REQUEST], getSrsSaga);
}
