import { rootSagaFactory } from '../importer/sagas';
import { EImportEntity }  from '../importer/model';

import { productsImportDomainSelector } from './selectors';

export default rootSagaFactory({
  entity: EImportEntity.PRODUCTS,
  domainSelector: productsImportDomainSelector,
  importApiCall: backendApi => backendApi.importProducts(),
  importStatusApiCall: backendApi => backendApi.getProductsImportStatus()
});
