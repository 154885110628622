import { createSelector } from 'reselect';
import { IPurchaseCostReviewSubdomain } from './model';
import { purchaseCostReviewSubdomainSelector } from '../../selectors';

export const statusSelector = createSelector(
  purchaseCostReviewSubdomainSelector,
  (subdomain: IPurchaseCostReviewSubdomain) => subdomain.status
);

export const financePurchaseRowSelector = createSelector(
  purchaseCostReviewSubdomainSelector,
  (subdomain: IPurchaseCostReviewSubdomain) => subdomain.financePurchaseRow
);

export const commentSelector = createSelector(
  purchaseCostReviewSubdomainSelector,
  (subdomain: IPurchaseCostReviewSubdomain) => subdomain.comment
);

export const requestedStatusSelector = createSelector(
  purchaseCostReviewSubdomainSelector,
  (subdomain: IPurchaseCostReviewSubdomain) => subdomain.requestedStatus
);

export const getPurchaseCostReviewRequestStatusSelector = createSelector(
  purchaseCostReviewSubdomainSelector,
  (subdomain: IPurchaseCostReviewSubdomain) => subdomain.request.getPurchaseCostReview
);

export const patchPurchaseCostReviewRequestStatusSelector = createSelector(
  purchaseCostReviewSubdomainSelector,
  (subdomain: IPurchaseCostReviewSubdomain) => subdomain.request.patchPurchaseCostReview
);

export const uploadFileRequestStatusSelector = createSelector(
  purchaseCostReviewSubdomainSelector,
  (subdomain: IPurchaseCostReviewSubdomain) => subdomain.request.uploadFile
);
