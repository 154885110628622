import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { EDepositAccountRowType, IDepositAccountRow, IDepositAccountSortableItems } from 'services/BookingManagerApi';
import { IPagination } from 'store/modules/ledger/model';
import { depositAccountSetSortAction } from 'store/modules/ledger/actions';
import { depositAccountSortingSelector } from 'store/modules/ledger/selectors';

import { format } from './utils';
import { LedgerTable, renderHeaderSpan } from './LedgerTable';
import { TransactionDateColumn } from './TransactionDateColumn';
import { TransactionSymbol } from './TransactionSymbolColumn';
import { TransactionTypeColumn } from './TransactionTypeColumn';
import { TransactionBookingColumn } from './TransactionBookingColumn';
import { TransactionAmountColumn } from './TransactionAmountColumn';
import { Pagination } from 'pureUi/Pagination';
import FluidButton from '../FluidButton';
import { SvgIcon } from '../SvgIcon';
import PencilIcon from '../Icons/pencil.component.svg';
import TrashIcon from '../Icons/trash.component.svg';
import FileIcon from '../Icons/file.component.svg';
import { isDepositAccountRowNegative, isDepositAccountRowPositive } from 'store/modules/ledger/utils';
import { TCurrencyCode } from 'interfaces';
import { depositAccountRowTypeLabelMap } from '../DepositAccountModal/depositAccountTypeOptions';
import { theme } from '../../../tailwind.config';
import { HidingTooltip } from 'ui/Tooltip';

interface IUserPermission {
  userCanAddRows: boolean;
}

export enum ETransactionSymbolType {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NONE = 'NONE',
}

import PiggyBankIcon from 'ui/Icons/piggyBank.gray.component.svg';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';

interface IDepositAccountTableProps extends IUserPermission {
  currency: TCurrencyCode;
  balanceCarriedForward: number;
  isModalLoading: boolean;
  handleAddDepositRow: () => void;
  handleEditDepositRow: (rowEdited: IDepositAccountRow) => void;
  handleDeleteDepositRow: (row: IDepositAccountRow) => void;
  pagination: IPagination;
  depositAccountRows: { balance: number; row: IDepositAccountRow }[];
  handlePageChange: (pageNumber: number) => void;
  handleItemsPerPageChange: (pageNumber: number) => void;
}

interface IDepositAccountTableBodyProps extends IDepositAccountTableProps, IUserPermission {}

interface IDepositAccountTableRowProps extends IUserPermission {
  row: IDepositAccountRow;
  rowIndex: number;
  balance: number;
  formatAmount: (amount: number) => string;
  formatDate: (date: string) => string;
  handleEditDepositRow: (rowEdited: IDepositAccountRow) => void;
  handleDeleteDepositRow: (row: IDepositAccountRow) => void;
}

const DepositAccountTableHeader: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const sort = useSelector(depositAccountSortingSelector);

  const Classes = useMemo(
    () => ({
      container:
        'bg-ivory border-solid border-b border-gray-20 uppercase text-xs text-gray-100 leading-16px tracking-2xs uppercase',
      bordered: 'border-r border-solid border-r-gray-20',
    }),
    []
  );

  const handleSort = useCallback(
    (sortBy: IDepositAccountSortableItems) => () => {
      if (sort) {
        const newSortOrder = sortBy === sort.sortBy && sort.sortOrder === 'asc' ? 'desc' : 'asc';
        dispatch(depositAccountSetSortAction(sortBy, newSortOrder));
      }
    },
    [dispatch, sort]
  );

  return (
    <thead className={Classes.container}>
      <tr className="h-10">
        <th id="dates-header" style={{ width: '108px' }} onClick={handleSort('date')}>
          {renderHeaderSpan({
            currentSortBy: sort.sortBy,
            currentSortOrder: sort.sortOrder,
            label: 'Value Date',
            sortKey: 'date',
            headerClasses: 'ml-7px',
          })}
        </th>
        <th id="entryDate-header" style={{ width: '84px', textAlign: 'right' }}>
          {renderHeaderSpan({
            currentSortBy: sort.sortBy,
            currentSortOrder: sort.sortOrder,
            label: 'Entry Date',
            headerClasses: 'ml-7px',
          })}
        </th>

        <th id="transaction-type-header" style={{ width: '190px' }}>
          {renderHeaderSpan({ currentSortBy: sort.sortBy, currentSortOrder: sort.sortOrder, label: 'Type' })}
        </th>

        {/* 
        <th id="type-header" style={{ width: '160px' }}>
          {renderHeaderSpan({ currentSortBy: sort.sortBy, currentSortOrder: sort.sortOrder, label: 'Type' })}
        </th> */}

        <th id="booking-header" style={{ width: '210px' }}>
          {renderHeaderSpan({ currentSortBy: sort.sortBy, currentSortOrder: sort.sortOrder, label: 'Booking' })}
        </th>

        <th id="guest-header" style={{ width: '76px' }}>
          {renderHeaderSpan({
            currentSortBy: sort.sortBy,
            currentSortOrder: sort.sortOrder,
            label: 'Guest',
            labelClasses: '',
          })}
        </th>

        <th id="description-bank-account" style={{ width: '80px' }}>
          {renderHeaderSpan({
            currentSortBy: sort.sortBy,
            currentSortOrder: sort.sortOrder,
            label: 'Bank',
            labelClasses: '',
          })}
        </th>

        <th id="description-header" className={Classes.bordered} style={{ width: '142px' }}>
          {renderHeaderSpan({
            currentSortBy: sort.sortBy,
            currentSortOrder: sort.sortOrder,
            label: 'Description',
            labelClasses: '',
          })}
        </th>

        <th id="transaction-amount-header" className={Classes.bordered} style={{ width: '126px' }}>
          {renderHeaderSpan({
            currentSortBy: sort.sortBy,
            currentSortOrder: sort.sortOrder,
            label: 'Transaction Amount',
            labelClasses: 'text-center',
          })}
        </th>

        <th id="total-balance-header" className={Classes.bordered} style={{ width: '126px' }}>
          {renderHeaderSpan({
            currentSortBy: sort.sortBy,
            currentSortOrder: sort.sortOrder,
            label: 'Total Balance',
            labelClasses: 'text-center',
          })}
        </th>

        <th id="actions-header" style={{ width: '136px' }} />
      </tr>
    </thead>
  );
});

const DepositAccountTableRow: React.FC<IDepositAccountTableRowProps> = React.memo(
  ({
    row,
    rowIndex,
    balance,
    formatAmount,
    formatDate,
    handleEditDepositRow,
    handleDeleteDepositRow,
    userCanAddRows,
  }) => {
    const depositTypes = [
      EDepositAccountRowType.Transfer_In,
      EDepositAccountRowType.Transfer_Out,
      EDepositAccountRowType.Automatic_Credit_From_Booking_IN,
      EDepositAccountRowType.Automatic_Credit_From_Booking_OUT,
    ];
    const isDepositRow = depositTypes.includes(row.type);
    let transactionSymbolType = ETransactionSymbolType.NONE;
    if (isDepositAccountRowPositive(row.type)) {
      transactionSymbolType = ETransactionSymbolType.POSITIVE;
    }
    if (isDepositAccountRowNegative(row.type)) {
      transactionSymbolType = ETransactionSymbolType.NEGATIVE;
    }

    const rowCanBeEdited =
      userCanAddRows &&
      [
        EDepositAccountRowType.Transfer_In,
        EDepositAccountRowType.Transfer_Out,
        EDepositAccountRowType.Transfer_Between_USD_EUR_Deposit_IN,
        EDepositAccountRowType.Transfer_Between_USD_EUR_Deposit_OUT,
        EDepositAccountRowType.Transfer_Between_EUR_USD_Deposit_IN,
        EDepositAccountRowType.Transfer_Between_EUR_USD_Deposit_OUT,
        EDepositAccountRowType.Initial_Balance_Carried_Forward_Positive,
        EDepositAccountRowType.Initial_Balance_Carried_Forward_Negative,
        EDepositAccountRowType.Adjustment_Balance_Carried_Forward_Positive,
        EDepositAccountRowType.Adjustment_Balance_Carried_Forward_Negative,
        EDepositAccountRowType.Finance_Adjustment_Positive,
        EDepositAccountRowType.Finance_Adjustment_Negative,
        EDepositAccountRowType.Credit_Note_For_Travel_Partner_IN,
        EDepositAccountRowType.Credit_Note_For_Travel_Partner_OUT,
      ].includes(row.type);

    const handleEditDepositAccountRowClick = useCallback(() => {
      handleEditDepositRow(row);
    }, [row, handleEditDepositRow]);

    const handleDeleteDepositAccountRowClick = useCallback(() => {
      handleDeleteDepositRow(row);
    }, [row, handleDeleteDepositRow]);

    const Classes = useMemo(
      () => ({
        row: 'h-58px text-black text-15px leading-19px border-t border-solid border-gray-20 odd:bg-ivory',
        guest: 'px-2 overflow-hidden whitespace-nowrap text-ellipsis',
        bordered: 'border-r border-solid border-r-gray-20',
        amount: 'text-right pl-2 pr-15px',
        amountsBg: (rowIndex + 1) % 2 === 0 ? 'bg-teal-20' : 'bg-green-25',
        bookingBalanceBg: (rowIndex + 1) % 2 === 0 ? 'bg-gray-10' : 'bg-ivory',
      }),
      [rowIndex]
    );

    return (
      <tr className={Classes.row}>
        <TransactionDateColumn
          className={`text-center deposit-account-table-column-date-${rowIndex} px-7px`}
          date={row.date}
          formatter={formatDate}
        />
        <td className={`deposit-account-table-column-entry-date-${rowIndex} px-7px ${Classes.bordered} border-r-0`}>
          <span className="font-pt-sans text-15px leading-19px text-black">
            {row.entryDate ? formatDate(row.entryDate) : ''}
          </span>
        </td>
        <td className={`deposit-account-table-column-transaction-type-${rowIndex} px-7px`}>
          <div className="flex flex-row pl-2">
            <div className="flex flex-col">
              <TransactionSymbol transactionType={transactionSymbolType} />
              {isDepositRow && (
                <span className="w-28px h-19px mt-1">
                  <PiggyBankIcon />
                </span>
              )}
            </div>

            <span
              className={classnames({
                italic:
                  row.type === EDepositAccountRowType.Amount_Before_From_Date ||
                  row.type === EDepositAccountRowType.Amount_After_To_Date,
              })}
            >
              {depositAccountRowTypeLabelMap[row.type]}
            </span>
          </div>
        </td>
        <TransactionBookingColumn
          className={`deposit-account-table-column-booking-reference-${rowIndex} px-7px`}
          bookingUuid={row.bookingUuid}
          bookingReference={row.humanReadableId}
          bookingStatus={row.status}
        />

        <td className={`deposit-account-table-column-guest-${rowIndex} px-7px`}>
          <span className="font-pt-sans text-15px leading-19px text-black line-clamp-2">{row.leadGuestName ?? ''}</span>
        </td>

        <td className={`deposit-account-table-column-bank-account-${rowIndex} px-7px`}>
          <span className="font-pt-sans text-15px leading-19px text-black">{row.bankAccount ?? ''}</span>
        </td>

        <td className={`deposit-account-table-column-notes-${rowIndex} ${Classes.bordered} px-7px`}>
          <HidingTextTooltip
            tooltipContent={row.notes ?? ''}
            position="bottom-right"
            tooltipContentClassname="description-tooltip"
            width="250px"
          >
            <span className="font-pt-sans text-15px leading-19px pr-2 line-clamp-2">{row.notes ?? ''}</span>
          </HidingTextTooltip>
        </td>

        <TransactionAmountColumn
          amount={row.amountCents}
          formatter={formatAmount}
          className={`deposit-account-table-column-transaction-amount-${rowIndex} ${Classes.amount} ${Classes.bordered} ${Classes.amountsBg} px-7px`}
        />

        <TransactionAmountColumn
          amount={balance}
          formatter={formatAmount}
          className={`deposit-account-table-column-booking-balance-${rowIndex} ${Classes.amount} ${Classes.bordered} ${Classes.amountsBg} px-7px`}
        />

        <td className={`deposit-account-table-column-actions-${rowIndex} px-7px`}>
          <div className="deposit-account-actions flex items-center justify-center">
            <a
              className={classnames('deposit-account-file cursor-pointer text-lg mr-2', { invisible: !row.uploadUrl })}
              target="_blank"
              href={row.uploadUrl}
              rel="noopener noreferrer"
            >
              <SvgIcon
                IconComponent={FileIcon}
                width="31px"
                height="31px"
                defaultFill={theme.colors['white-true']}
                hoverFill={theme.colors['gray-30']}
                activeFill={theme.colors['ivory']}
              />
            </a>
            {rowCanBeEdited && (
              <>
                <div className="deposit-account-edit cursor-pointer mr-2" onClick={handleEditDepositAccountRowClick}>
                  <SvgIcon
                    IconComponent={PencilIcon}
                    width="30px"
                    height="30px"
                    defaultFill={theme.colors['white-true']}
                    hoverFill={theme.colors['gray-30']}
                    activeFill={theme.colors['ivory']}
                  />
                </div>
                <div className="deposit-account-delete cursor-pointer" onClick={handleDeleteDepositAccountRowClick}>
                  <SvgIcon
                    IconComponent={TrashIcon}
                    width="30px"
                    height="30px"
                    defaultFill={theme.colors['white-true']}
                    hoverFill={theme.colors['gray-30']}
                    activeFill={theme.colors['ivory']}
                  />
                </div>
              </>
            )}
          </div>
        </td>
      </tr>
    );
  }
);

const DepositAccountTableBody: React.FC<IDepositAccountTableBodyProps> = React.memo(
  ({ depositAccountRows, currency, handleEditDepositRow, handleDeleteDepositRow, userCanAddRows }) => {
    const formatDate = useMemo(() => format.date, []);
    const formatAmount = useMemo(() => format.amount(currency), [currency]);

    return (
      <tbody>
        {depositAccountRows.map((depositAccountRow, rowIndex) => (
          <DepositAccountTableRow
            formatAmount={formatAmount}
            formatDate={formatDate}
            row={depositAccountRow.row}
            balance={depositAccountRow.balance}
            rowIndex={rowIndex}
            key={`deposit-account-table-row-${rowIndex}`}
            handleEditDepositRow={handleEditDepositRow}
            handleDeleteDepositRow={handleDeleteDepositRow}
            userCanAddRows={userCanAddRows}
          />
        ))}
      </tbody>
    );
  }
);

export const DepositAccountTable: React.FC<IDepositAccountTableProps> = React.memo(props => {
  const { pagination, userCanAddRows } = props;
  const { currentPage, itemsPerPage, totalPages } = pagination;

  return (
    <>
      <LedgerTable className="deposit-account-table">
        <DepositAccountTableHeader />
        <DepositAccountTableBody
          depositAccountRows={props.depositAccountRows}
          balanceCarriedForward={props.balanceCarriedForward}
          currency={props.currency}
          isModalLoading={props.isModalLoading}
          handleAddDepositRow={props.handleAddDepositRow}
          handleEditDepositRow={props.handleEditDepositRow}
          handleDeleteDepositRow={props.handleDeleteDepositRow}
          pagination={props.pagination}
          handlePageChange={props.handlePageChange}
          handleItemsPerPageChange={props.handleItemsPerPageChange}
          userCanAddRows={userCanAddRows}
        />
      </LedgerTable>
      <div className="pagination-container relative mt-8">
        {userCanAddRows && (
          <div className="absolute mt-1">
            <FluidButton slim type="secondary" onClick={props.handleAddDepositRow} isLoading={props.isModalLoading}>
              Add Deposit Row
            </FluidButton>
          </div>
        )}
        <Pagination
          className="pagination"
          onPageSelect={props.handlePageChange}
          pageCount={totalPages}
          currentPage={currentPage + 1}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={props.handleItemsPerPageChange}
        />
      </div>
    </>
  );
});
