import { initialState, IBookingManagerDashboardSubdomain } from './model';
import * as Actions from './actions';
import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';

const dashboardReducer = (state: IBookingManagerDashboardSubdomain = initialState, action: Actions.DashboardAction) => {
  switch (action.type) {
    case Actions.GET_SPECIAL_REQUESTS_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.specialRequestsLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_SPECIAL_REQUESTS_SUCCESS:
      return produce(state, draftState => {
        draftState.specialRequests = action.specialRequests;
        draftState.networkRequests.specialRequestsLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_SPECIAL_REQUESTS_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.specialRequestsLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.UPDATE_SPECIAL_REQUESTS_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.specialRequestsUpdate = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.UPDATE_SPECIAL_REQUESTS_SUCCESS:
      return produce(state, draftState => {
        draftState.specialRequests = action.specialRequests;
        draftState.networkRequests.specialRequestsUpdate = ENetworkRequestStatus.SUCCESS;
        draftState.isSpecialRequestsEditedWithoutSaving = false;
        return draftState;
      });

    case Actions.UPDATE_SPECIAL_REQUESTS_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.specialRequestsUpdate = ENetworkRequestStatus.ERROR;
        draftState.isSpecialRequestsEditedWithoutSaving = false;
        return draftState;
      });

    case Actions.GET_TOP_NAVIGATION_DATA_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.topNavigationDataLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_TOP_NAVIGATION_DATA_INLINE_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.topNavigationDataInlineLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_TOP_NAVIGATION_DATA_SUCCESS:
      return produce(state, draftState => {
        draftState.topNavigationData = action.topNavigationData;
        draftState.bookingInformationReservationTeamData = action.bookingInformationReservationTeamData;
        draftState.networkRequests.topNavigationDataLoad = ENetworkRequestStatus.SUCCESS;
        draftState.networkRequests.topNavigationDataInlineLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_TOP_NAVIGATION_DATA_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.topNavigationDataLoad = ENetworkRequestStatus.ERROR;
        draftState.networkRequests.topNavigationDataInlineLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.UPDATE_TOP_NAVIGATION_STATE_HISTORY:
      return produce(state, draftState => {
        if (draftState.topNavigationData !== null) {
          draftState.topNavigationData.bookingStateHistory = action.stateHistory;
        }
        return draftState;
      });

    case Actions.UPDATE_TOP_NAVIGATION_BOOKING_STATUS:
      return produce(state, draftState => {
        if (draftState.topNavigationData !== null) {
          draftState.topNavigationData.bookingStatus = action.status;
        }
        return draftState;
      });

    case Actions.SET_IS_SPECIAL_REQUESTS_EDITED_WITHOUT_SAVING:
      return produce(state, draftState => {
        draftState.isSpecialRequestsEditedWithoutSaving = action.toggle;
        return draftState;
      });

    case Actions.SET_SPECIAL_REQUESTS:
      return produce(state, draftState => {
        draftState.specialRequests = action.text;
        return draftState;
      });

    case Actions.GET_BOOKING_TYPE_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.bookingTypeLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_BOOKING_TYPE_SUCCESS:
      return produce(state, draftState => {
        draftState.bookingType = action.bookingType;
        draftState.networkRequests.bookingTypeLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_BOOKING_TYPE_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.bookingTypeLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.SET_BOOKING_TYPE_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.bookingTypeUpdate = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.SET_BOOKING_TYPE_SUCCESS:
      return produce(state, draftState => {
        draftState.bookingType = action.bookingType;
        draftState.networkRequests.bookingTypeUpdate = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.SET_BOOKING_TYPE_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.bookingTypeUpdate = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.GET_BOOKING_TASKS_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.bookingTasksLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_BOOKING_TASKS_SUCCESS:
      return produce(state, draftState => {
        draftState.bookingTasks = action.bookingTasks;
        draftState.networkRequests.bookingTasksLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_BOOKING_TASKS_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.bookingTasksLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });
  
    default:
      return state;
  }
};

export default dashboardReducer;
