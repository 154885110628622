import React from "react";
import { WarningComponent as WarningIcon } from 'ui/Icons';

export interface iAlertProps {
  message: string | React.ReactElement;
  className?: string;
}

export const Warning = (props: iAlertProps) => {
  const { message, className } = props;

  return (
    <div className={`flex items-center justify-center px-4 py-10px mt-25px bg-yellow-advert-light border border-yellow-advert ${className ?? ''}`}>
      <WarningIcon />
      <p className="text-black text-16px leading-21px font-pt-sans ml-10px my-0 flex-1">{message}</p>
    </div>
  );
};
