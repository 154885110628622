import React, { useCallback, useState } from 'react';
import classnames from 'classnames';

interface IBulletPointsProps {
  textLines: string[];
  ulClassname: string;
  liClassname: string;
}

export const BulletPoints: React.FC<IBulletPointsProps> = React.memo(
  ({ ulClassname, liClassname, textLines }) => {
    return (
      <ul className={classnames("bullet-points", ulClassname)}>
        {textLines.map(line => (
          <li key={line} className={liClassname}>{line}</li>
        ))}
      </ul>
    );
  }
);
