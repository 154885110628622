import React from 'react';

import { StyledNotFound, NotFoundHeading, NotFoundImage } from './NotFound.styles';

export const NotFound = () => (
  <StyledNotFound>
    <NotFoundHeading>Page not found</NotFoundHeading>
  </StyledNotFound>
);

export default NotFound;
