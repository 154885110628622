import { call, takeLatest, select, put } from 'redux-saga/effects';
import {
  RemoveCompanyLogoAction,
  REMOVE_COMPANY_LOGO,
  removeCompanyLogoSuccessAction,
  fetchCompanyLogoFailureAction,
  setCompanyLogoNetworkDeleteLogoAction,
} from '../actions';
import { ENetworkRequestStatus, makeBackendApi } from 'services/BackendApi';
import { selectedTaSelector } from '../../agents';
import { enqueueNotification } from '../../ui';

export function* removeCompanyLogoSaga(action: RemoveCompanyLogoAction) {
  try {
    yield put(setCompanyLogoNetworkDeleteLogoAction(ENetworkRequestStatus.PENDING));
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    yield call(backendApi.removeCompanyLogo, action.companyUuid);
    yield put(removeCompanyLogoSuccessAction());
    yield put(setCompanyLogoNetworkDeleteLogoAction(ENetworkRequestStatus.SUCCESS));
    yield put(
      enqueueNotification({
        message: 'Company logo has been removed',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(fetchCompanyLogoFailureAction(e));
    yield put(setCompanyLogoNetworkDeleteLogoAction(ENetworkRequestStatus.ERROR));
    yield put(
      enqueueNotification({
        message: 'Error removing company logo',
        options: { variant: 'error' },
      })
    );
  }
}

export function* watchRemoveCompanyLogo() {
  yield takeLatest(REMOVE_COMPANY_LOGO, removeCompanyLogoSaga);
}
