import React, { useCallback, ReactNode } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { StandardModal } from 'pureUi/Modal';
import FluidButton from 'ui/FluidButton';
import { PurchaseCostReviewStatus } from 'ui/PurchaseCostReviewStatus';
import { EPurchaseCostReviewStatus } from 'services/BookingManagerApi/types';
import { closePcrDetailsModalAction } from '../../store/modules/bookingManager/subdomains/breakdown/actions';

import { commentSelector } from 'store/modules/bookingManager/subdomains/purchaseCostReview/selectors';

interface IPurchaseCostReviewDetailsModalProps {
  className?: string;
}

export const PurchaseCostReviewDetailsModal: React.FC<IPurchaseCostReviewDetailsModalProps> = props => {
  const comment = useSelector(commentSelector);

  const dispatch = useDispatch();

  const onClose = useCallback(() => dispatch(closePcrDetailsModalAction()), [dispatch]);

  const onOk = useCallback(() => {
    dispatch(closePcrDetailsModalAction());
    dispatch(push('comments'));
  }, [dispatch]);

  return (
    <StandardModal
      className={classNames(props.className, 'purchase-cost-review-details-modal font-pt-sans z-11')}
      frameClassName="w-500px px-9 py-8 border border-solid"
      showCloseButton={true}
      removePadding={true}
      closeOnOutsideClick={true}
      onClose={onClose}
    >
      <h3 className="title m-0 font-noe-display mb-2 font-normal text-21px">Reject Details</h3>
      <div className="content bg-red-25 p-3 mb-6 rounded text-black font-pt-sans leading-sm">
        <div className="flex items-center mb-2">
          <span className="text-base font-bold mr-1">Rejected</span>
          <PurchaseCostReviewStatus status={EPurchaseCostReviewStatus.REJECTED} />
        </div>
        <span className="text-15px">{comment?.text}</span>
      </div>
      <div className="controls flex">
        <FluidButton className="mr-10px" type="secondary" onClick={onClose}>
          Close
        </FluidButton>
        <FluidButton type="primary" onClick={onOk}>
          Go to comments
        </FluidButton>
      </div>
    </StandardModal>
  );
};
