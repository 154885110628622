import { Proposal } from 'services/BookingManagerApi';

export const GET_REQUEST = 'proposalEditor/GET_REQUEST';
export const GET_SUCCESS = 'proposalEditor/GET_SUCCESS';
export const GET_FAILURE = 'proposalEditor/GET_FAILURE';

export const DRAFT_REQUEST = 'proposalEditor/DRAFT_REQUEST';
export const DRAFT_SUCCESS = 'proposalEditor/DRAFT_SUCCESS';
export const DRAFT_FAILURE = 'proposalEditor/DRAFT_FAILURE';

export const COMMIT_REQUEST = 'proposalEditor/COMMIT_REQUEST';
export const COMMIT_SUCCESS = 'proposalEditor/COMMIT_SUCCESS';
export const COMMIT_FAILURE = 'proposalEditor/COMMIT_FAILURE';

export type GetRequestAction = ReturnType<typeof getRequestAction>;
export const getRequestAction = (uuid: string) => ({
  type: GET_REQUEST as typeof GET_REQUEST,
  uuid
});

export type GetSuccessAction = ReturnType<typeof getSuccessAction>;
export const getSuccessAction = (value: Proposal) => ({
  type: GET_SUCCESS as typeof GET_SUCCESS,
  value
});

export type GetFailureAction = ReturnType<typeof getFailureAction>;
export const getFailureAction = () => ({
  type: GET_FAILURE as typeof GET_FAILURE,
});

export type DraftRequestAction = ReturnType<typeof draftRequestAction>;
export const draftRequestAction = (proposalUuid?: string) => ({
  type: DRAFT_REQUEST as typeof DRAFT_REQUEST,
  proposalUuid
});

export type DraftSuccessAction = ReturnType<typeof draftSuccessAction>;
export const draftSuccessAction = (googleDocId: string) => ({
  type: DRAFT_SUCCESS as typeof DRAFT_SUCCESS,
  value: googleDocId
});

export type DraftFailureAction = ReturnType<typeof draftFailureAction>;
export const draftFailureAction = () => ({
  type: DRAFT_FAILURE as typeof DRAFT_FAILURE,
});

export type CommitRequestAction = ReturnType<typeof commitRequestAction>;
export const commitRequestAction = () => ({
  type: COMMIT_REQUEST as typeof COMMIT_REQUEST,
});

export type CommitSuccessAction = ReturnType<typeof commitSuccessAction>;
export const commitSuccessAction = (value: Proposal) => ({
  type: COMMIT_SUCCESS as typeof COMMIT_SUCCESS,
  value
});

export type CommitFailureAction = ReturnType<typeof commitFailureAction>;
export const commitFailureAction = () => ({
  type: COMMIT_FAILURE as typeof COMMIT_FAILURE,
});

export type Any =
  | GetRequestAction
  | GetSuccessAction
  | GetFailureAction
  | DraftRequestAction
  | DraftSuccessAction
  | DraftFailureAction
  | CommitRequestAction
  | CommitSuccessAction
  | CommitFailureAction;
