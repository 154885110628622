import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import uniqBy from 'lodash/uniqBy';
import classNames from 'classnames';

import { ENetworkRequestStatus, EUserType } from 'services/BackendApi/types';
import { IOwnershipTravelAgent } from 'services/BookingManagerApi';
import { getTopNavigationDataRequestAction } from 'store/modules/bookingManager/subdomains/dashboard/actions';
import * as AuthSelectors from 'store/modules/auth';
import {
  topNavigationDataDashboardSelector,
  dashboardNetworkRequestsSelector,
  bookingInformationReservationTeamDashboardSelector,
} from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import {
  assignedSalesRepresentativesSelector,
  getAssignedSalesRepresentativesRequestStatusSelector,
  getOwnershipRequestStatusSelector,
  ownershipDataSelector,
  patchOwnershipRequestStatusSelector,
  getTravelPartnerMovementsSelector,
} from 'store/modules/bookingManager/subdomains/ownership/selectors';
import {
  getAssignedSalesRepresentativesRequestAction,
  getOwnershipRequestAction,
  updateOwnershipRequestAction,
  getTravelPartnerMovementsRequestAction,
} from 'store/modules/bookingManager/subdomains/ownership/actions';
import {
  managedBySelector,
  selectedTaCompanyUuidSelector,
  taUsersSelector,
  selectedTaUserUuidSelector,
  hasEditedWithoutSavingSelector,
  isTaMovementsModalOpen,
  networkSelector,
} from 'store/modules/bookingManager/subdomains/bookingSettings/selectors';
import {
  getTaUsersRequestAction,
  setManagedByUuidAction,
  setManagedByDescriptionAction,
  addManagedByAction,
  removeManagedByAction,
  setSelectedTaUserUuidAction,
  setSelectedTaCompanyUuidAction,
  openTaMovementsModalAction,
  clearSelectedValuesAction,
} from 'store/modules/bookingManager/subdomains/bookingSettings/actions';

import TopNavigationBar from 'pureUi/TopNavigationBar';
import { Multiselect } from 'ui/Multiselect';
import FluidButton from 'ui/FluidButton';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';

import { TravelAgentMovementsModal } from './TravelAgentMovementsModal';
import { EBookingSettingsSelectedValues } from 'store/modules/bookingManager/subdomains/bookingSettings/model';
import { LoadingBar } from 'ui/NetworkStatusBar';

const BookingSettingsMultiSelect = styled(Multiselect)`
  span {
    font-size: 16px;
    font-family: 'PT Sans', sans-serif;
  }

  .multiselect-items {
    .selected {
      background-color: #c6efe3;
      button,
      span {
        color: #413e3b;
        font-weight: normal;
      }
    }
  }

  .multiselect-item-wrapper {
    min-height: 28px;
  }

  .multiselect-item-cta {
    padding: 5px 10px;
  }
`;

interface IBasicEntity {
  uuid: string;
  name: string;
}

interface ISelectedCompany {
  selectedCompany: string | null;
}

interface IBookingCompanyProps extends ISelectedCompany {
  companys: IBasicEntity[];
  currentCompany: IBasicEntity;
  disabled?: boolean;
}

interface IBookingTravelAgentProps extends ISelectedCompany {
  taUsers: IOwnershipTravelAgent[];
  currentTAUuid: string | null;
}

const useIsLoading = () => {
  const getOwnershipRequestStatus = useSelector(getOwnershipRequestStatusSelector);
  const patchOwnershipRequestStatus = useSelector(patchOwnershipRequestStatusSelector);
  const getAssignedSalesRepresentativesRequestStatus = useSelector(
    getAssignedSalesRepresentativesRequestStatusSelector
  );

  return [getOwnershipRequestStatus, patchOwnershipRequestStatus, getAssignedSalesRepresentativesRequestStatus].some(
    x => x === ENetworkRequestStatus.PENDING
  );
};

const DescriptionInput = styled.input`
  &::placeholder {
    font-style: italic;
    color: #aba5a2;
  }
`;

const BookingManagedBy: React.FC = () => {
  const managedBy = useSelector(managedBySelector);
  const assignedSalesRepresentatives = useSelector(assignedSalesRepresentativesSelector);
  const selectedTravelAgentFromSettings = useSelector(selectedTaUserUuidSelector);

  const ownershipData = useSelector(ownershipDataSelector);
  const travelAgent = ownershipData?.taInfo?.uuid;

  const isLoading = useIsLoading();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOwnershipRequestAction());
  }, [dispatch]);

  useEffect(() => {
    if (travelAgent) {
      dispatch(getAssignedSalesRepresentativesRequestAction());
    }
  }, [dispatch, travelAgent]);

  useEffect(() => {
    if (selectedTravelAgentFromSettings) {
      dispatch(getAssignedSalesRepresentativesRequestAction());
    }
  }, [dispatch, selectedTravelAgentFromSettings]);

  const assignedSrs = assignedSalesRepresentatives || [];

  const options = assignedSrs.map(item => ({
    value: item.uuid,
    label: item.email,
  }));

  return (
    <div className="booking-managed-by mt-10">
      <h2 className="font-bold text-black-true text-17px mb-2">Contacts</h2>
      <div className="list">
        {managedBy.map((x, idx) => (
          <div className="item flex items-end mb-5">
            <div className="sr-selector w-2/5 mr-15px">
              <label className="flex justify-between">
                <span className="mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Contact Info</span>
              </label>

              <BookingSettingsMultiSelect
                onUpdate={val => dispatch(setManagedByUuidAction(idx, val[0]))}
                options={options}
                selectedValues={x.uuid ? [x.uuid] : []}
                hideCheckboxes={true}
                isCloseOnSelect={true}
                isSingleSelectMode={true}
                className="bg-ivory"
                itemsClassname="bg-ivory booking-managed-by-option"
                disabled={isLoading}
              />
            </div>

            <DescriptionInput
              className="description w-2/5 h-37px bg-white-hard focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 font-pt-sans"
              value={x.description || ''}
              placeholder="Description Line"
              onChange={event => dispatch(setManagedByDescriptionAction(idx, event.target.value))}
            />

            <div className="flex-1 text-right pb-8px">
              <button
                className="delete rounded-full p-0 w-6 h-6 bg-white hover:bg-brown-40 border border-solid border-brown-80 cursor-pointer font-pt-sans self-end"
                onClick={() => dispatch(removeManagedByAction(idx))}
              >
                <i className="fas fa-times text-brown-80"></i>
              </button>
            </div>
          </div>
        ))}
      </div>
      <FluidButton type="secondary" className="add mt-5" onClick={() => dispatch(addManagedByAction())}>
        Add Contact
      </FluidButton>
    </div>
  );
};

const BookingCompany: React.FC<IBookingCompanyProps> = ({ companys, currentCompany, selectedCompany, disabled }) => {
  const isLoading = useIsLoading();
  const dispatch = useDispatch();

  const handleUpdate = useCallback(
    val => {
      if (val.length && selectedCompany !== val[0]) {
        dispatch(setSelectedTaCompanyUuidAction(val[0]));
        dispatch(clearSelectedValuesAction([EBookingSettingsSelectedValues.SR, EBookingSettingsSelectedValues.TA]));
      }
    },
    [selectedCompany, dispatch]
  );

  const selectValue = selectedCompany || currentCompany.uuid;
  const taCompaniesOptions = companys.map(c => ({ value: c.uuid, label: c.name }));

  return (
    <div className="booking-company w-2/5 mr-15px">
      <label className="flex justify-between">
        <span className="mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Company</span>
      </label>

      <BookingSettingsMultiSelect
        onUpdate={handleUpdate}
        options={taCompaniesOptions}
        selectedValues={selectValue ? [selectValue] : []}
        hideCheckboxes={true}
        isCloseOnSelect={true}
        isSingleSelectMode={true}
        className={classNames('bg-ivory', { 'pointer-events-none text-gray-80': disabled })}
        itemsClassname="bg-ivory booking-company-option"
        disabled={disabled || isLoading}
      />
    </div>
  );
};

const BookingTravelPartner: React.FC<IBookingTravelAgentProps> = ({ taUsers, currentTAUuid, selectedCompany }) => {
  const modalOpen = useSelector(isTaMovementsModalOpen);
  const selectedTaUserUuidFromSettings = useSelector(selectedTaUserUuidSelector);
  const tpMovements = useSelector(getTravelPartnerMovementsSelector);
  const isLoading = useIsLoading();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTravelPartnerMovementsRequestAction());
  }, [currentTAUuid]);

  useEffect(() => {
    if (selectedCompany && taUsers.length === 1) {
      dispatch(setSelectedTaUserUuidAction(taUsers[0].uuid));
    }
  }, [selectedCompany, taUsers]);

  const handleUpdate = useCallback(
    val => {
      if (val.length && selectedTaUserUuidFromSettings !== val[0]) {
        dispatch(setSelectedTaUserUuidAction(val[0]));
      }
    },
    [dispatch, selectedTaUserUuidFromSettings]
  );

  const handleViewTpMovements = useCallback(() => {
    dispatch(openTaMovementsModalAction());
  }, [dispatch]);

  const taUsersOptions = taUsers.map(u => ({
    value: u.uuid,
    label: `${u.firstName} ${u.lastName}`,
  }));

  const selectValue = selectedTaUserUuidFromSettings || taUsers.find(ta => ta.uuid === currentTAUuid)?.uuid;

  return (
    <div className="booking-travel-partner w-2/5">
      <label className="flex justify-between">
        <span className="mb-1 text-black text-13px leading-14px tracking-2xs font-pt-sans">Travel Partner</span>
        <span
          className="mb-1 text-brown-100 text-13px leading-14px tracking-2xs font-pt-sans cursor-pointer underline hover:no-decoration hover:text-brown-130 open-taMovements-modal"
          onClick={handleViewTpMovements}
        >
          View TP Movements
        </span>
      </label>

      <BookingSettingsMultiSelect
        onUpdate={handleUpdate}
        options={taUsersOptions}
        selectedValues={selectValue ? [selectValue] : []}
        hideCheckboxes={true}
        isCloseOnSelect={true}
        isSingleSelectMode={true}
        className="bg-ivory"
        itemsClassname="bg-ivory booking-travel-partner-option"
        disabled={isLoading}
      />

      <TravelAgentMovementsModal movements={tpMovements ?? []} isOpen={modalOpen} />
    </div>
  );
};

export const BookingManagerSettings: React.FC = () => {
  const topNavigationData = useSelector(topNavigationDataDashboardSelector);
  const bookingInformationReservationTeamData = useSelector(bookingInformationReservationTeamDashboardSelector);

  const userRole = useSelector(AuthSelectors.getCurrentUserType);
  const dashboardNetworkRequests = useSelector(dashboardNetworkRequestsSelector);
  const isSubmitting = useSelector(patchOwnershipRequestStatusSelector);
  const hasEditedWithoutSaving = useSelector(hasEditedWithoutSavingSelector);
  const ownershipData = useSelector(ownershipDataSelector);
  const selectableTravelAgents = useSelector(taUsersSelector);
  const selectedCompany = useSelector(selectedTaCompanyUuidSelector);
  const network = useSelector(networkSelector);

  const companys = uniqBy(
    selectableTravelAgents.map(ta => ({ uuid: ta.companyUuid, name: ta.companyName })),
    'uuid'
  );

  const currentTa = selectableTravelAgents.find(ta => ta.uuid === ownershipData?.taInfo?.uuid);
  const taUsers = selectableTravelAgents.filter(ta => ta.companyUuid === (selectedCompany ?? currentTa?.companyUuid));

  const currentCompany = { uuid: currentTa?.companyUuid, name: currentTa?.companyName };

  const dispatch = useDispatch();

  companys.sort(function(a, b) {
    return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
  });

  taUsers.sort(function(a, b) {
    return a.lastName.toUpperCase().localeCompare(b.lastName.toUpperCase());
  });

  useEffect(() => {
    dispatch(getTaUsersRequestAction());
    return () => {
      dispatch(clearSelectedValuesAction());
    };
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAssignedSalesRepresentativesRequestAction());
    }
  }, [selectedCompany]);

  const handleUpdateOwnershipData = useCallback(() => {
    dispatch(updateOwnershipRequestAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTopNavigationDataRequestAction());
  }, [dispatch, ownershipData?.taInfo]);

  if (network.getTaUsers === ENetworkRequestStatus.PENDING) {
    return (
      <div className="mt-5">
        <LoadingBar />
      </div>
    );
  }

  return (
    <div className="text-black font-pt-sans">
      <LeaveWithoutSavingModal when={hasEditedWithoutSaving} />

      <TopNavigationBar
        userRole={userRole as EUserType}
        data={topNavigationData}
        bookingInformationReservationTeamData={bookingInformationReservationTeamData}
        isLoading={dashboardNetworkRequests.topNavigationDataLoad === ENetworkRequestStatus.PENDING}
        isError={dashboardNetworkRequests.topNavigationDataLoad === ENetworkRequestStatus.ERROR}
      />
      <h1 className="font-noe-display font-normal text-4xl mt-0">Booking Settings</h1>
      <div className="panel p-5 border border-solid border-gray-40">
        <h2 className="font-bold text-black-true text-17px mb-2 mt-0">User Settings</h2>
        <p className="text-15px mt-0">
          Below you will find the main users for this booking.{' '}
          {userRole === EUserType.ADMIN
            ? 'The Travel Partner can be changed to any other Travel Partner from the same country as the original one.'
            : 'The Travel Agent can be changed to another at the same Travel Company. If you need to change the Travel Company please contact Admin user.'}
        </p>
        <div className="flex">
          <BookingCompany
            companys={companys}
            currentCompany={currentCompany as IBasicEntity}
            selectedCompany={selectedCompany}
            disabled={userRole !== EUserType.ADMIN}
          />
          <BookingTravelPartner
            currentTAUuid={currentTa ? currentTa.uuid : null}
            taUsers={taUsers}
            selectedCompany={selectedCompany}
          />
        </div>
        <BookingManagedBy />
      </div>
      <div>
        <FluidButton
          type="primary"
          className="mt-12 save-button"
          onClick={handleUpdateOwnershipData}
          isLoading={isSubmitting === ENetworkRequestStatus.PENDING}
          disabled={!hasEditedWithoutSaving || isSubmitting === ENetworkRequestStatus.PENDING}
        >
          Save
        </FluidButton>
      </div>
    </div>
  );
};

export default BookingManagerSettings;
