import React, { Dispatch, SetStateAction, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { BookingBuilder, ENetworkRequestStatus, ICompany, makeBackendApi } from 'services/BackendApi';
import { Radio } from 'ui/Radio';
import { theme } from '../../../tailwind.config';
import { setPaymentMethodAction } from 'store/modules/bookingConfirmation/subdomains/payment/actions';
import { EPaymentMethod } from 'store/modules/bookingBuilder/types';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { ErrorMessageLine } from './ErrorMessageLine';
import { formatPrice, formatPriceCents, getCurrencySymbol } from 'utils';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { ledgerDepositTotalsLoadingSelector } from 'store/modules/ledger/selectors';
import { depositStatementTotalsRequestAction } from 'store/modules/ledger/actions';
import { getCompanySelector } from 'store/modules/auth';
import { companyLoadSelector } from 'store/modules/companyInfo';

interface IPaymentMethodProps {
  selectedPaymentMethod: EPaymentMethod | null;
  bookingBuild: BookingBuilder | null;
  depositAccountBalanceCents: number;
  cannotGetDepositAccountBalance: boolean;
  creditLimitCents: number;
  isDefinedCreditLimit: boolean;
  isEnoughDepositAndCredit: boolean;
}

export const PaymentMethod: React.FC<IPaymentMethodProps> = React.memo(
  ({
    bookingBuild,
    depositAccountBalanceCents,
    cannotGetDepositAccountBalance,
    creditLimitCents,
    isDefinedCreditLimit,
    isEnoughDepositAndCredit,
    selectedPaymentMethod,
  }) => {
    const dispatch = useDispatch();
    const depositTotalsLoading = useSelector(ledgerDepositTotalsLoadingSelector);
    const company = useSelector(getCompanySelector) as ICompany;
    const companyDataLoading = useSelector(companyLoadSelector);
    const isTa: boolean = useSelector(AuthSelectors.isTA);

    const onDepositAccountSelect = useCallback(() => {
      dispatch(setPaymentMethodAction(EPaymentMethod.DEPOSIT_ACCOUNT));
    }, [dispatch]);

    const onBankTransferSelect = useCallback(() => {
      dispatch(setPaymentMethodAction(EPaymentMethod.BANK_TRANSFER));
    }, [dispatch]);

    // doesnt need bookingBuild in the deps
    useLayoutEffect(() => {
      if (bookingBuild?.response.totals.oneOrMoreItemsOnRequest || cannotGetDepositAccountBalance) {
        dispatch(setPaymentMethodAction(EPaymentMethod.BANK_TRANSFER));
      } else {
        dispatch(setPaymentMethodAction(EPaymentMethod.DEPOSIT_ACCOUNT));
      }

      return () => {
        dispatch(setPaymentMethodAction(null));
      };
    }, [dispatch, cannotGetDepositAccountBalance]);

    useEffect(() => {
      if (isTa && company.enableInstantBooking) {
        dispatch(depositStatementTotalsRequestAction(company.uuid));
      }
    }, [company.uuid]);

    if (
      depositTotalsLoading === ENetworkRequestStatus.PENDING ||
      companyDataLoading === ENetworkRequestStatus.PENDING
    ) {
      return <LoadingBar />;
    }

    const availableInDepositAccount = depositAccountBalanceCents < 0 ? 0 : depositAccountBalanceCents;

    const credit = creditLimitCents + depositAccountBalanceCents - availableInDepositAccount;
    const availableCredit = credit >= 0 ? credit : 0;

    return (
      <div className="book-payment-method flex flex-col my-[15px] p-0">
        <div className="payment-method-radio-container flex items-center gap-[20px]">
          <label className="block cursor-pointer" onClick={onDepositAccountSelect}>
            <Radio
              onClick={onDepositAccountSelect}
              checked={selectedPaymentMethod === EPaymentMethod.DEPOSIT_ACCOUNT}
              color={theme.colors['brown-100']}
            />
            <span className="ml-2 mr-5">Deposit Account</span>
          </label>
          <label className="block cursor-pointer" onClick={onDepositAccountSelect}>
            <Radio
              onClick={onBankTransferSelect}
              checked={selectedPaymentMethod === EPaymentMethod.BANK_TRANSFER}
              color={theme.colors['brown-100']}
            />
            <span className="ml-2 mr-5">Bank transfer</span>
          </label>
        </div>
        {selectedPaymentMethod === EPaymentMethod.DEPOSIT_ACCOUNT && (
          <div className="mt-[15px]">
            {bookingBuild && !isEnoughDepositAndCredit && !cannotGetDepositAccountBalance && (
              <ErrorMessageLine>Your current availability is not enough for the booking</ErrorMessageLine>
            )}
            {cannotGetDepositAccountBalance && (
              <ErrorMessageLine>Unable to get deposit account information</ErrorMessageLine>
            )}
            {bookingBuild && !cannotGetDepositAccountBalance && (
              <p className="font-hurmegeometric-sans text-[14px] leading-[17px] text-black m-0">
                Available in Deposit Account{' '}
                <span className="font-bold">
                  {getCurrencySymbol(bookingBuild.response.currency)} {formatPriceCents(availableInDepositAccount)}
                </span>
              </p>
            )}
            {bookingBuild && isDefinedCreditLimit && (
              <p className="font-hurmegeometric-sans text-[14px] leading-[17px] text-black m-0 mt-[5px]">
                Available Credit{' '}
                <span className="font-bold">
                  {getCurrencySymbol(bookingBuild.response.currency)} {formatPriceCents(availableCredit)}
                </span>
              </p>
            )}
          </div>
        )}
        {selectedPaymentMethod === EPaymentMethod.BANK_TRANSFER && (
          <div className="mt-[15px]">
            {bookingBuild && !bookingBuild.response.isRefundable && (
              <ErrorMessageLine>Only enabled for 100% refundable bookings</ErrorMessageLine>
            )}
          </div>
        )}
      </div>
    );
  }
);
