import { createSelector } from 'reselect';
import { IActingOnBehalfOfDomain, iActingOnBehalfOfUser } from './model';
import { EUserType } from 'services/BackendApi';

export const domainSelector = (state: any): IActingOnBehalfOfDomain => state.actingOnBehalfOf;

export const actingOnBehalfOfUuidSelector = createSelector(
  domainSelector,
  (domain: IActingOnBehalfOfDomain) => domain.aoboUuid
);

export const actingOnBehalfOfRoleSelector = createSelector(
  domainSelector,
  (domain: IActingOnBehalfOfDomain) => domain.aoboRole
);

export const actingOnBehalfOfUserSelector = createSelector(
  domainSelector,
  (domain: IActingOnBehalfOfDomain): iActingOnBehalfOfUser | null => {
    if (domain.aoboUuid === null) {
      return null;
    }
    return {
      uuid: domain.aoboUuid,
      role: domain.aoboRole,
    };
  }
);
