import { call, takeLatest, select, put, all } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { IChecklistItem, IChecklistResponse, makeBookingManagerApi } from 'services/BookingManagerApi';
import {
  GET_CHECKLIST_REQUEST,
  getChecklistSuccessAction,
  getChecklistFailureAction,

  UPDATE_CHECKLIST_REQUEST,
  UpdateChecklistRequestAction,
  updateChecklistSuccessAction,
  updateChecklistFailureAction,
} from './actions';
import { bookingUuidSelector } from  '../../selectors';
import { checklistSelector } from './selectors';
import { enqueueNotification } from '../../../ui';

export function* getChecklistSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IChecklistResponse> = yield call(bookingManagerApi.getChecklist, bookingUuid);
    yield put(getChecklistSuccessAction(result.data.checklistItems));

  } catch (e) {
    yield put(getChecklistFailureAction(e));
  }
}

export function* updateChecklistSaga(action: UpdateChecklistRequestAction) {
  const bookingManagerApi = makeBookingManagerApi();

  const bookingUuid = yield select(bookingUuidSelector);
  const currentChecklist = yield select(checklistSelector);
  const updatedChecklist = currentChecklist.map((item: IChecklistItem) => {
    if (item.label === action.label) {
      return { ...item, isChecked: action.checked }
    }
    return item;
  })

  try {
    yield put(updateChecklistSuccessAction(updatedChecklist));

    const result: AxiosResponse<IChecklistResponse> = yield call(
      bookingManagerApi.updateChecklist,
      bookingUuid,
      updatedChecklist
    );
    yield put(updateChecklistSuccessAction(result.data.checklistItems));

    yield put(enqueueNotification({
      message: 'Checklist data saved successfully',
      options: { variant: 'success' },
    }));
  } catch (e) {
    yield put(updateChecklistFailureAction(e));
    yield put(enqueueNotification({
      message: 'There was an error saving checklist data',
      options: { variant: 'error' },
    }));
    yield put(updateChecklistSuccessAction(currentChecklist))
  }
}

export default function* checklistRootSaga() {
  yield all([
    takeLatest([GET_CHECKLIST_REQUEST], getChecklistSaga),
    takeLatest([UPDATE_CHECKLIST_REQUEST], updateChecklistSaga),
  ]);
}
