import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { RateBreakFormContainerConnected } from './RateBreakForm/index';

import { RateBreakList } from './RateBreakList';

import { compose } from 'redux';
import { RouteComponentProps } from 'react-router';

export interface RateBreakRoutingProps extends RouteComponentProps {}

export const RateBreakRoutingComponent = (props: RateBreakRoutingProps) => {
  return (
    <Switch>
      <Route
        path={[`${props.match.path}/:uuid/edit`, `${props.match.path}/create`]}
        component={RateBreakFormContainerConnected}
      />
      <Route path={[`${props.match.path}/`, `${props.match.path}`]} component={RateBreakList} />
    </Switch>
  );
};

export const RateBreakRouting = compose(withRouter)(RateBreakRoutingComponent);
