import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ENetworkRequestStatus } from 'services/BackendApi';

import * as FinancesSelectors from 'store/modules/finances/selectors';
import * as FinanceActions from 'store/modules/finances/actions';

import Checkbox from 'ui/Checkbox';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import InfoIcon from 'ui/Icons/Info.component.svg';
import { theme } from '../../../../tailwind.config';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';
import { DecimalInput } from 'ui/stateful/DecimalInput';
import FluidButton from 'ui/FluidButton';

interface IFinancesProps {
  companyUuid: string;
  isEditMode: boolean;
}

export const Finances: React.FC<IFinancesProps> = ({ companyUuid, isEditMode }) => {
  const enableInstantBooking = useSelector(FinancesSelectors.enableInstantBookingSelector);
  const disableTaAccessToFinance = useSelector(FinancesSelectors.disableTaAccessToFinanceSelector);
  const creditLimitUsd = useSelector(FinancesSelectors.creditLimitUsdSelector);
  const creditLimitEur = useSelector(FinancesSelectors.creditLimitEurSelector);
  const request = useSelector(FinancesSelectors.requestSelector);
  const isTouched = useSelector(FinancesSelectors.isTouchedSelector);

  const dispatch = useDispatch();

  const isSavingChanges = useMemo(() => [request.post, request.patch].some(x => x === ENetworkRequestStatus.PENDING), [
    request.post,
    request.patch,
  ]);

  const handleSave = useCallback(() => dispatch(FinanceActions.saveChangesAction(companyUuid)), [
    dispatch,
    companyUuid,
  ]);

  const handleDisableTaAccessToFinance = useCallback(() => {
    dispatch(FinanceActions.toggleDisableTaAccessToFinanceAction());
  }, [dispatch]);

  const handleEnableInstantBooking = useCallback(() => {
    dispatch(FinanceActions.toggleEnableInstantBookingAction());
  }, [dispatch]);

  const handleSetCreditLimitUsd = useCallback(
    val => {
      dispatch(FinanceActions.setCreditLimitUsdAction(!val ? '0.00' : val));
    },
    [dispatch]
  );

  const handleSetCreditLimitEur = useCallback(
    val => {
      dispatch(FinanceActions.setCreditLimitEurAction(!val ? '0.00' : val));
    },
    [dispatch]
  );

  return (
    <div className="company-booking-managers w-full">
      <p className="title font-pt-sans text-black-true font-bold text-17px leading-md mt-25px mb-0">
        Finances Settings
      </p>
      <div className="flex items-center justify-between mt-5px mb-0">
        <p className="description font-pt-sans text-black text-15px leading-sm m-0">
          Select the following options to enable finance features for the Travel Company.
        </p>
      </div>
      <LeaveWithoutSavingModal when={isTouched} />
      {isEditMode && (
        <div className="flex items-center justify-between mt-5 mb-5">
          <div className="flex">
            <label className="font-pt-sans text-black text-16px flex items-center cursor-pointer w-fit mr-4">
              <Checkbox
                checked={Boolean(disableTaAccessToFinance)}
                onChange={handleDisableTaAccessToFinance}
                disabled={!isEditMode}
              />
              <span className="ml-2 mr-2 select-none">Disable Access to Finance Sales Tables</span>
              <HidingTextTooltip
                tooltipContent="Selecting this option allows only Travel Agents Managers to access Finance Sales tables."
                position="right"
                tooltipClassname="py-5px px-2"
                width="150px"
              >
                <InfoIcon className="w-[18px]" fill={theme.colors['gray-80']} />
              </HidingTextTooltip>
            </label>
          </div>
        </div>
      )}
      {isEditMode && (
        <div className="flex items-center justify-between mt-5 mb-5">
          <div className="flex">
            <label className="font-pt-sans text-black text-16px flex items-center cursor-pointer w-fit mr-4">
              <Checkbox
                checked={Boolean(enableInstantBooking)}
                onChange={handleEnableInstantBooking}
                disabled={!isEditMode}
              />
              <span className="ml-2 mr-2 select-none">Enable Instant Booking</span>
              <HidingTextTooltip
                tooltipContent="Allow all Travel Agents to use Instant book feature."
                position="right"
                tooltipClassname="py-5px px-2"
                width="150px"
              >
                <InfoIcon className="w-[18px]" fill={theme.colors['gray-80']} />
              </HidingTextTooltip>
            </label>
          </div>
        </div>
      )}
      {enableInstantBooking && (
        <div className="flex gap-[30px]">
          <div className="flex flex-col">
            <span className="pb-5px text-13px text-black">Credit Limit - USD $</span>
            <DecimalInput
              decimalPlaces={2}
              min={0}
              allowNull={true}
              onBlur={handleSetCreditLimitUsd}
              value={creditLimitUsd === 0 ? '0.00' : String(creditLimitUsd)}
              className={classNames(
                'credit-limit-usd max-w-70px focus:outline focus:outline-teal-80 p-2 border border-solid h-35px w-full font-pt-sans text-base text-black mr-10px min-w-[160px] bg-ivory border-gray-40 focus:border-teal-80'
              )}
              validRegex={/^\d{0,8}(?:\.\d{0,2})?$/}
            />
          </div>
          <div className="flex flex-col">
            <span className="pb-5px text-13px text-black">Credit Limit - EUR €</span>
            <DecimalInput
              decimalPlaces={2}
              min={0}
              allowNull={true}
              onBlur={handleSetCreditLimitEur}
              value={creditLimitEur === 0 ? '0.00' : String(creditLimitEur)}
              className={classNames(
                'credit-limit-eur max-w-150px focus:outline focus:outline-teal-80 p-2 border border-solid h-35px w-full font-pt-sans text-base text-black mr-10px min-w-[160px] bg-ivory border-gray-40 focus:border-teal-80'
              )}
              validRegex={/^\d{0,8}(?:\.\d{0,2})?$/}
            />
          </div>
        </div>
      )}
      {isEditMode && (
        <FluidButton
          type="primary"
          className="save mt-25px"
          onClick={handleSave}
          disabled={!isTouched}
          isLoading={isSavingChanges}
        >
          Save Changes
        </FluidButton>
      )}
      {request.get === ENetworkRequestStatus.PENDING && (
        <div className="mt-20px">
          <LoadingBar />
        </div>
      )}
      {request.get === ENetworkRequestStatus.ERROR && (
        <div className="mt-20px">
          <ErrorBar />
        </div>
      )}
    </div>
  );
};
