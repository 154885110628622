import { createSelector } from 'reselect';
import { IChecklistSubdomain } from './model';
import { checklistSubdomainSelector } from '../../selectors';

export const checklistSelector = createSelector(
  checklistSubdomainSelector,
  (subdomain: IChecklistSubdomain) => subdomain.checklist
);

export const networkRequestsSelector = createSelector(
  checklistSubdomainSelector,
  (subdomain: IChecklistSubdomain) => subdomain.networkRequests
);
