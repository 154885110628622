import {  ENetworkRequestStatus } from 'services/BackendApi';

export interface IFinancesDomain {
  disableTaAccessToFinance: boolean | null;
  enableAccessForManagersToFinance: boolean | null;
  isTouched: boolean;
  enableInstantBooking: boolean | null;
  creditLimitUsd: number | null;
  creditLimitEur: number | null;
  request: {
    get: ENetworkRequestStatus;
    post: ENetworkRequestStatus;
    patch: ENetworkRequestStatus;
  };
}

export const initialState: IFinancesDomain = {
  disableTaAccessToFinance: null,
  enableAccessForManagersToFinance: null,
  isTouched: false,
  enableInstantBooking: null,
  creditLimitUsd: 0,
  creditLimitEur: 0,
  request: {
    get: ENetworkRequestStatus.IDLE,
    post: ENetworkRequestStatus.IDLE,
    patch: ENetworkRequestStatus.IDLE,
  },
};
