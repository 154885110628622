import {
  IHeadlineLineItemBreakdown,
  IHeadlineLineItemBreakdownLineItem
} from 'services/BookingManagerApi';
import { ProductTypes } from 'config/enums';

const MAIN_PRODUCT_TYPES = [
  ProductTypes.ACCOMMODATION,
  ProductTypes.TRANSFER,
  ProductTypes.GROUND_SERVICE,
  ProductTypes.SUPPLEMENT,
  ProductTypes.FINE,
  ProductTypes.BESPOKE,
];

const SUB_PRODUCT_TYPES = [
  ProductTypes.MEAL_PLAN,
  ProductTypes.SUPPLEMENT,
];

type LineItemIdentity = (lineItem: IHeadlineLineItemBreakdownLineItem) => string;

const itemsHashFn = (lineItemIdentity: LineItemIdentity) => (
  items: IHeadlineLineItemBreakdownLineItem[],
  prefix: string,
): string[] => items.map(
  (item, idx) => `${prefix}-${idx}-${lineItemIdentity(item)}`,
);

const hashFn = (lineItemIdentity: LineItemIdentity) => {
  const computeItemsHash = itemsHashFn(lineItemIdentity);

  return (breakdown: IHeadlineLineItemBreakdown): string[] => {

    const mainHash = MAIN_PRODUCT_TYPES.flatMap(
      productType => computeItemsHash(breakdown[productType].items, productType)
    );
      
    const subHash = breakdown[ProductTypes.ACCOMMODATION].items.flatMap(
      (accomm, idx) => SUB_PRODUCT_TYPES.flatMap(
        subType => computeItemsHash(
          accomm[subType].items,
          `${ProductTypes.ACCOMMODATION}-${idx}-${subType}`,
        )
      )
    );
  
    return [...mainHash, ...subHash];
  };
};

export const computeSalesCostHash = hashFn(item => `${item.saleCostCents}`);

export const computeTextHash = hashFn(
  item => `${item.title.replace(/-/g, '_')}-${item.tertiaryText.replace(/-/g, '_')}`
);

const computeItemsSalesCost = (items: IHeadlineLineItemBreakdownLineItem[]): number =>
  items.reduce(
    (acc, item) => acc + (item.saleCostCents || 0),
    0
  );

export const computeSalesCost = (breakdown: IHeadlineLineItemBreakdown): number => {
  let cost = MAIN_PRODUCT_TYPES.reduce(
    (acc, productType) => acc +  computeItemsSalesCost(breakdown[productType].items),
    0
  );

  return breakdown[ProductTypes.ACCOMMODATION].items.reduce(
    (acc, item) => acc + SUB_PRODUCT_TYPES.reduce(
      (acc, subType) => acc + computeItemsSalesCost(item[subType].items),
      0
    ),
    cost
  );

};
