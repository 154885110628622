import { produce } from 'immer';

import * as Actions from './actions';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { IBookingStatusOptionsSubdomain, initialState } from './model';

export const bookingStatusOptionsSubdomainReducer = (
  state: IBookingStatusOptionsSubdomain = initialState,
  action: Actions.BookingStatusOptionsSubdomainAction
) => {
  switch (action.type) {
    case Actions.GET_BOOKING_STATUS_OPTIONS_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.bookingStatusOptionsLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_BOOKING_STATUS_OPTIONS_SUCCESS:
      return produce(state, draftState => {
        draftState.bookingStatusOptions = action.bookingStatusOptions;
        draftState.networkRequests.bookingStatusOptionsLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_BOOKING_STATUS_OPTIONS_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.bookingStatusOptionsLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.OPEN_BOOKING_STATUS_MODAL:
      return produce(state, draftState => {
        draftState.modal = { ...action.bookingStatusModalData, isOpen: true };
        return draftState;
      });

    case Actions.CLOSE_BOOKING_STATUS_MODAL:
      return produce(state, draftState => {
        draftState.modal = initialState.modal;
        return draftState;
      });

    case Actions.SET_BOOKING_STATUS_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.bookingStatusOptionsLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.SET_BOOKING_STATUS_SUCCESS:
      return produce(state, draftState => {
        draftState.networkRequests.bookingStatusOptionsLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.SET_BOOKING_STATUS_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.bookingStatusOptionsLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.OPEN_CANCELLATION_INVOICE_MODAL:
      return produce(state, draftState => {
        draftState.isCancellationModalOpen = true;
        draftState.cancellationModalData.bookingStatus = action.bookingStatus;
        return draftState;
      });

    case Actions.CLOSE_CANCELLATION_INVOICE_MODAL:
      return produce(state, draftState => {
        draftState.isCancellationModalOpen = false;
        return draftState;
      });

    case Actions.SET_CANCELLATION_MODAL_DUE_DATE:
      return produce(state, draftState => {
        draftState.cancellationModalData.dueDate = action.dueDate;
        return draftState;
      });

    case Actions.SET_CANCELLATION_MODAL_PERCENTAGE:
      return produce(state, draftState => {
        draftState.cancellationModalData.percentage = action.percentage;
        return draftState;
      });

    case Actions.SET_CANCELLATION_MODAL_AMOUNT_CENTS:
      return produce(state, draftState => {
        draftState.cancellationModalData.amountCents = action.amountCents;
        return draftState;
      });

    case Actions.SET_CANCELLATION_MODAL_OUTSTANDING_BALANCE_CENTS:
      return produce(state, draftState => {
        draftState.cancellationModalData.outstandingBalanceCents = action.outstandingBalanceCents;
        return draftState;
      });

    case Actions.SET_CANCELLATION_MODAL_DATA:
      return produce(state, draftState => {
        draftState.cancellationModalData = {
          ...draftState.cancellationModalData,
          ...action.data,
        };
        return draftState;
      });

    case Actions.POST_CANCELLATION_INVOICE_REQUEST:
      return produce(state, draftState => {
        draftState.networkRequests.postCancellationInvoiceLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.POST_CANCELLATION_INVOICE_SUCCESS:
      return produce(state, draftState => {
        draftState.networkRequests.postCancellationInvoiceLoad = ENetworkRequestStatus.SUCCESS;
        // TODO do we need to do anything with the data here?
        return draftState;
      });

    case Actions.POST_CANCELLATION_INVOICE_FAILURE:
      return produce(state, draftState => {
        draftState.networkRequests.postCancellationInvoiceLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    default:
      return state;
  }
};

export default bookingStatusOptionsSubdomainReducer;
