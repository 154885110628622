import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBackendApi, ITravelAgentResponse } from 'services/BackendApi';
import {
  getFailureAction,
  GetRequestAction,
  getSuccessAction,
  GET_REQUEST,
  SAVE_CHANGES,
  SaveChangesAction,
} from './actions';
import * as AuthSelectors from 'store/modules/auth';
import * as Selectors from './selectors';
import { enqueueNotification } from '../ui';
import { patchCompanyRequestAction } from '../companyInfo';

function* successNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'success' } }));
}

function* errorNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'error' } }));
}

export function* getSaga(action: GetRequestAction) {
  try {
    const api = makeBackendApi();
    const isAdmin = yield select(AuthSelectors.isAdmin);

    let result: AxiosResponse<ITravelAgentResponse> = yield call(
      api.getTravelAgentsByCompanyId,
      action.companyUuid,
      isAdmin
    );
    yield put(getSuccessAction(result.data.data));
  } catch (e) {
    yield put(getFailureAction(e.message));
    yield errorNotification('Error fetching TA settings, plese try again.');
  }
}

export function* saveChangesSaga(action: SaveChangesAction) {
  const enableInstantBooking = yield select(Selectors.enableInstantBookingSelector);
  const disableTaAccessToFinance = yield select(Selectors.disableTaAccessToFinanceSelector);
  const usdCreditLimit = yield select(Selectors.creditLimitUsdSelector);
  const eurCreditLimit = yield select(Selectors.creditLimitEurSelector);
  yield put(patchCompanyRequestAction(action.companyUuid, { 
    enableInstantBooking,
    disableTaAccessToFinance, 
    usdCreditLimit: String(usdCreditLimit), 
    eurCreditLimit: String(eurCreditLimit),
  }));
}

export function* watchFinancesCompanySettings() {
  yield takeLatest(GET_REQUEST, getSaga);
  yield takeLatest(SAVE_CHANGES, saveChangesSaga);
}
