import { TCurrencyCode } from 'interfaces';
import { ILedgerTotals, IBookingLedger } from 'store/modules/ledger/model';

import { Pagination } from './BookingSearchResponse';
import { EManualBookingLedgerRowType } from './FinanceResponse';

export type IBookingLedgerSortableItems =
  | 'humanReadableId'
  | 'date'
  | 'type'
  | 'travelAgentEmail'
  | 'status'
  | 'amountCents'
  | 'parentNetTotal';

// for now it's only 1 field, but in the future business may ask to sort by other fields as well
// like in Booking ledger
export type IDepositAccountSortableItems = 'date';

export interface IBookingLedgerRequestData {
  pagination?: Pagination;
  sort?: {
    field: IBookingLedgerSortableItems;
    order: 'asc' | 'desc';
  };
  filter?: {
    type?: string[];
    humanReadableId?: string[];
    fromDate?: string;
    toDate?: string;
    status?: string[];
    amountMinCents?: number;
    amountMaxCents?: number;
  };
}

export interface ILedgerTotalsResponse extends Array<ILedgerTotals> {}
export interface IBookingLedgerResponse extends IBookingLedger {}

export interface IDepositAccountRequestData {
  pagination?: Pagination;
  sort?: {
    field: IDepositAccountSortableItems;
    order: 'asc' | 'desc';
  };
  filter?: {
    humanReadableIds?: string[];
    fromDate?: string;
    toDate?: string;
    status?: string[];
  };
}

export enum EDepositAccountRowType {
  Transfer_In = 'From Travel Partner In',
  Transfer_Out = 'To Travel Partner Out',
  Initial_Balance_Carried_Forward_Positive = 'Initial Balance Carried Forward Positive',
  Initial_Balance_Carried_Forward_Negative = 'Initial Balance Carried Forward Negative',
  Adjustment_Balance_Carried_Forward_Positive = 'Adjustment to Balance Carried Forward Positive',
  Adjustment_Balance_Carried_Forward_Negative = 'Adjustment to Balance Carried Forward Negative',
  Transfer_Between_USD_EUR_Deposit_IN = 'Transfer between USD/EUR In',
  Transfer_Between_USD_EUR_Deposit_OUT = 'Transfer between USD/EUR Out',
  Transfer_Between_EUR_USD_Deposit_IN = 'Transfer between EUR/USD In',
  Transfer_Between_EUR_USD_Deposit_OUT = 'Transfer between EUR/USD Out',
  Finance_Adjustment_Positive = 'Finance Adjustment Positive',
  Finance_Adjustment_Negative = 'Finance Adjustment Negative',
  Automatic_Deposit_Transfer_IN = 'Automatic Deposit Transfer In',
  Automatic_Credit_From_Booking_IN = 'Automatic Credit from Booking In',
  Automatic_Credit_From_Booking_OUT = 'Automatic Credit from Booking Out',
  Transfer_Between_Travel_Companies_IN = 'Transfer between Travel Companies In',
  Transfer_Between_Travel_Companies_OUT = 'Transfer between Travel Companies Out',
  Credit_Note_For_Travel_Partner_IN = 'Credit Note for Travel Partner (IN)',
  Credit_Note_For_Travel_Partner_OUT = 'Credit Note for Travel Partner (OUT)',
  Amount_Before_From_Date = 'Net movements before period and Balance Brought Forward',
  Amount_After_To_Date = 'Net movements after period and Closing Balance',
}

export interface IDepositAccountRow {
  uuid?: string;
  version: number;
  travelCompanyUuid: string;
  amountCents: number;
  currency: TCurrencyCode;
  date: string;
  entryDate: string;
  leadGuestName?: string;
  type: EDepositAccountRowType;
  bookingUuid?: string;
  humanReadableId?: string;
  status?: string;
  notes?: string;
  uploadUrl?: string;
  bankAccount?: string;
}

export interface IDepositAccountRowWithBalance {
  balance: number;
  row: IDepositAccountRow;
}

export interface IDepositAccountResponse {
  balanceCarriedForward: number;
  positiveTotal: number;
  negativeTotal: number;
  netTotal: number;
  rows: IDepositAccountRowWithBalance[];
  pagination: {
    limit: number;
    offset: number;
    totalRows: number;
  };
}

export interface IManualBookingLedgerRowResponse {
  amountCents: number;
  companyUuid: string;
  currency: string;
  date: string;
  parentNetTotal: number;
  type: EManualBookingLedgerRowType;
  uploadName: string;
  uploadUrl: string;
  uploadUuid: string;
  uuid: string;
  version: number;
}

export interface ISaveDepositAccountRowResponse {
  amountCents: number;
  companyUuid: string;
  currency: string;
  date: string;
  parentNetTotal: number;
  type: EDepositAccountRowType;
  uploadName: string;
  uploadUrl: string;
  uploadUuid: string;
  uuid: string;
  version: number;
}
