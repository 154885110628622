import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import TopNavigationBar from 'pureUi/TopNavigationBar';
import * as DashboardSelectors from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import { getTopNavigationDataRequestAction } from 'store/modules/bookingManager/subdomains/dashboard/actions';
import * as FinanceActions from 'store/modules/bookingManager/subdomains/finance/actions';
import { FinanceTable } from 'ui/FinanceTable';
import * as AuthSelectors from 'store/modules/auth';
import * as FinanceSelectors from 'store/modules/bookingManager/subdomains/finance/selectors';
import * as PurchaseCostReviewSelectors from 'store/modules/bookingManager/subdomains/purchaseCostReview/selectors';
import * as PurchaseCostReviewActions from 'store/modules/bookingManager/subdomains/purchaseCostReview/actions';
import * as HotelDetailsSelectors from 'store/modules/bookingManager/subdomains/hotelDetails/selectors';
import FluidButton from 'ui/FluidButton';
import { LoadingBar, ErrorBar } from 'ui/NetworkStatusBar';
import { StandardModal } from 'pureUi/Modal';
import { FinanceTableModal } from 'ui/FinanceTableModal';
import { IFinanceRow } from 'services/BookingManagerApi/types';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { EFinanceTableTypes } from 'store/modules/bookingManager/subdomains/finance/types';
import { bookingCurrencySymbolSelector, bookingCurrencySelector } from 'store/modules/bookingManager/selectors';
import { SlideToggle } from 'ui/SlideToggle';
import classNames from 'classnames';
import { isAutomaticFinanceRow } from 'store/modules/bookingManager/subdomains/finance/utils';
import { Select } from '../../ui/Select';
import { formatDateDisplay } from 'utils';
import { EUserType } from 'services/BackendApi';

export const BookingManagerFinance = () => {
  const dispatch = useDispatch();
  const userRole = useSelector(AuthSelectors.getCurrentUserType);
  const topNavigationData = useSelector(DashboardSelectors.topNavigationDataDashboardSelector);
  const bookingInformationReservationTeamData = useSelector(
    DashboardSelectors.bookingInformationReservationTeamDashboardSelector
  );
  const dashboardNetworkRequests = useSelector(DashboardSelectors.dashboardNetworkRequestsSelector);
  const financeDocument = useSelector(FinanceSelectors.financeDocumentSelector);
  const networkRequests = useSelector(FinanceSelectors.networkRequestsSelector);
  const isAddModalOpen = useSelector(FinanceSelectors.isAddModalOpenSelector);
  const isDeleteModalOpen = useSelector(FinanceSelectors.isDeleteModalOpenSelector);
  const isEditModalOpen = useSelector(FinanceSelectors.isEditModalOpenSelector);
  const editRowIndex = useSelector(FinanceSelectors.editRowIndexSelector);
  const errorMessages = useSelector(FinanceSelectors.errorMessagesSelector);
  const isSr = useSelector(AuthSelectors.isInternalUser);
  const isAdmin = useSelector(AuthSelectors.isAdmin);
  const isFinanceUser = useSelector(AuthSelectors.isFinanceUser);
  const financeDocumentBalance = useSelector(FinanceSelectors.financeDocumentBalanceSelector);
  const bookingCurrencySymbol = useSelector(bookingCurrencySymbolSelector);
  const bookingCurrency = useSelector(bookingCurrencySelector);
  const financeTableTypeToRender = useSelector(FinanceSelectors.financeTableTypeToRenderSelector);
  const purchaseCostReviewStatus = useSelector(PurchaseCostReviewSelectors.statusSelector);
  const purchaseCostReviewFinancePurchaseRow = useSelector(PurchaseCostReviewSelectors.financePurchaseRowSelector);
  const getPurchaseCostReviewRequestStatus = useSelector(
    PurchaseCostReviewSelectors.getPurchaseCostReviewRequestStatusSelector
  );

  const financeDocumentVersionList = useSelector(FinanceSelectors.financeDocumentVersionListSelector);
  const activeVersion = useSelector(FinanceSelectors.financeDocumentActiveVersionSelector);

  const hotelDetails = useSelector(HotelDetailsSelectors.hotelDetailsSelector);

  const paymentMethods = useSelector(FinanceSelectors.paymentMethodsSelector).list;
  const defaultPaymentMethodName = useSelector(FinanceSelectors.defaultPaymentMethodSelector)?.name;

  const { financeDocumentVersionListLoad } = networkRequests;
  useEffect(() => {
    // load the top data
    dispatch(getTopNavigationDataRequestAction());
    // get the versions of the selected finance view
    dispatch(FinanceActions.getFinanceDocumentVersionListRequestAction());
    dispatch(FinanceActions.getPaymentMethodsRequestAction());
    // get either the sales or purchase document and balance (whichever one is active)
    // this.props.getFinanceDocumentRequest();
    // this.props.getFinanceDocumentBalanceRequest();
    // if get the purchase cost review request if we're allowed
    if (isSr || isAdmin || isFinanceUser) {
      dispatch(PurchaseCostReviewActions.getPurchaseCostReviewRequestAction());
    }
  }, []);

  // when the type of finance table we're looking at changes, reload the version list
  useEffect(() => {
    dispatch(FinanceActions.getFinanceDocumentVersionListRequestAction());
  }, [financeTableTypeToRender]);

  useEffect(() => {
    if (activeVersion === null) {
      return;
    }
    if (financeDocumentVersionListLoad !== ENetworkRequestStatus.SUCCESS) {
      return;
    }
    // when the version changes, load the finance document
    // the saga will make sure the correct version is loaded
    dispatch(FinanceActions.getFinanceDocumentRequestAction());
    dispatch(FinanceActions.getFinanceDocumentBalanceRequestAction());
  }, [activeVersion, financeDocumentVersionListLoad]);

  const handleEditRow = useCallback(index => {
    dispatch(FinanceActions.setEditRowIndexAction(index));
    dispatch(FinanceActions.setIsEditModalOpenAction(true));
  }, []);

  const handleDeleteRow = useCallback(index => {
    dispatch(FinanceActions.setDeleteRowIndexAction(index));
    dispatch(FinanceActions.setIsDeleteModalOpenAction(true));
  }, []);

  const purchaseCostReview =
    getPurchaseCostReviewRequestStatus === ENetworkRequestStatus.SUCCESS
      ? {
          status: purchaseCostReviewStatus,
          financePurchaseRow: purchaseCostReviewFinancePurchaseRow,
        }
      : null;

  const activeVersionTimestamp = financeDocumentVersionList.find(v => v.version === activeVersion)?.timestamp;
  const isLatestVersion =
    financeDocumentVersionList.length >= 1 ? financeDocumentVersionList[0].version === activeVersion : false;

  if (dashboardNetworkRequests.topNavigationDataLoad === ENetworkRequestStatus.PENDING) {
    return (
      <div className="mt-5">
        <LoadingBar />
      </div>
    );
  }

  return (
    <div>
      <TopNavigationBar
        userRole={userRole as EUserType}
        data={topNavigationData}
        bookingInformationReservationTeamData={bookingInformationReservationTeamData}
        isError={dashboardNetworkRequests.topNavigationDataLoad === ENetworkRequestStatus.ERROR}
      />

      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <h1 className="text-4xl font-noe-display font-normal mt-0 mb-2 self-center">Finance</h1>

          {financeTableTypeToRender === EFinanceTableTypes.SALES && (
            <span className="ml-2 font-noe-display text-xl">- Sales</span>
          )}

          {financeTableTypeToRender === EFinanceTableTypes.PURCHASE && (
            <span className="ml-2 font-noe-display text-xl">- Purchase</span>
          )}
        </div>

        {(isSr || isAdmin || isFinanceUser) && (
          <div className="flex items-center">
            <span
              className={classNames('font-pt-sans text-15px uppercase', {
                'font-bold': financeTableTypeToRender === EFinanceTableTypes.SALES,
              })}
            >
              Sales
            </span>
            <SlideToggle
              className="finance-slide-toggle mx-2"
              checked={financeTableTypeToRender === EFinanceTableTypes.PURCHASE}
              id={'finance-slide-toggle'}
              labelClassName={'bg-teal-40'}
              buttonClassName={'bg-teal-100'}
              onActivate={() => {
                dispatch(FinanceActions.setFinanceTableTypeToRenderAction(EFinanceTableTypes.PURCHASE));
              }}
              onDeactivate={() => {
                dispatch(FinanceActions.setFinanceTableTypeToRenderAction(EFinanceTableTypes.SALES));
              }}
            />
            <span
              className={classNames('font-pt-sans text-15px uppercase', {
                'font-bold': financeTableTypeToRender === EFinanceTableTypes.PURCHASE,
              })}
            >
              Purchase
            </span>
          </div>
        )}
      </div>

      {(networkRequests.financeDocumentLoad === ENetworkRequestStatus.ERROR ||
        networkRequests.financeDocumentBalanceLoad === ENetworkRequestStatus.ERROR ||
        networkRequests.paymentMethodsLoad === ENetworkRequestStatus.ERROR ||
        networkRequests.financeDocumentVersionListLoad === ENetworkRequestStatus.ERROR) && <ErrorBar />}

      {networkRequests.financeDocumentVersionListLoad === ENetworkRequestStatus.SUCCESS &&
        networkRequests.financeDocumentLoad === ENetworkRequestStatus.SUCCESS &&
        networkRequests.paymentMethodsLoad === ENetworkRequestStatus.SUCCESS &&
        networkRequests.financeDocumentBalanceLoad === ENetworkRequestStatus.SUCCESS && (
          <React.Fragment>
            <div className="flex flex-row items-center justify-end mb-4">
              {activeVersionTimestamp && (
                <span className="last-modified self-center">
                  Date last changed: {formatDateDisplay(new Date(parseInt(activeVersionTimestamp, 10)))}
                </span>
              )}

              <Select
                className="version-selector min-w-170px max-w-255px pl-10px pr-45px ml-4 text-ellipsis normal-case"
                value={activeVersion ? activeVersion.toString() : null}
                onChange={event => {
                  const selectedVersion = financeDocumentVersionList.find(
                    v => v.version === parseInt(event.target.value)
                  );
                  if (selectedVersion !== undefined) {
                    dispatch(FinanceActions.setFinanceDocumentActiveVersionRequestAction(selectedVersion.version));
                  }
                }}
                options={financeDocumentVersionList.map(version => {
                  const v = `V${version.version}, ${formatDateDisplay(new Date(parseInt(version.timestamp, 10)))}`;
                  const u = version.user?.email;
                  return {
                    value: String(version.version),
                    label: [v, u].filter(Boolean).join(' / '),
                  };
                })}
              />
            </div>
            <FinanceTable
              financeTableType={financeTableTypeToRender}
              currencySymbol={bookingCurrencySymbol || ''}
              financeDocument={financeDocument}
              financeDocumentBalance={financeDocumentBalance}
              onEdit={handleEditRow}
              onDelete={handleDeleteRow}
              canEdit={(isFinanceUser || isAdmin) && isLatestVersion}
              canDelete={(isFinanceUser || isAdmin) && isLatestVersion}
              //@ts-ignore
              purchaseCostReview={purchaseCostReview}
            />

            {(isFinanceUser || isAdmin) && isLatestVersion && (
              <FluidButton
                className="finance-add-row-button mt-4"
                type="primary"
                onClick={() => dispatch(FinanceActions.setIsAddModalOpenAction(true))}
              >
                Add Row
              </FluidButton>
            )}
          </React.Fragment>
        )}

      {isAddModalOpen && (
        <StandardModal
          className="finance-table-add-modal font-pt-sans"
          frameClassName="w-800px px-9 py-8 border border-solid"
          showCloseButton={true}
          removePadding={true}
          closeOnOutsideClick={false}
          onClose={() => dispatch(FinanceActions.setIsAddModalOpenAction(false))}
        >
          <FinanceTableModal
            modalTitle={
              financeTableTypeToRender === EFinanceTableTypes.SALES ? 'Add New Sales Row' : 'Add New Purchase Row'
            }
            submitButtonLabel="Add row"
            financeModalType={financeTableTypeToRender}
            onSubmit={(newRowWithoutUpload: IFinanceRow, files: File[]) => {
              dispatch(FinanceActions.addRowAndSaveRequestAction(newRowWithoutUpload, files));
            }}
            submitButtonLoading={networkRequests.addRowAndSaveLoad === ENetworkRequestStatus.PENDING}
            errorMessage={errorMessages.addRowAndSaveError || undefined}
            hotelCurrency={bookingCurrency}
            paymentMethods={paymentMethods || []}
            defaultPaymentMethodName={defaultPaymentMethodName}
          />
        </StandardModal>
      )}

      {isEditModalOpen && (
        <StandardModal
          className="finance-table-edit-modal font-pt-sans"
          frameClassName="w-700px px-9 py-8 border border-solid"
          showCloseButton={true}
          removePadding={true}
          closeOnOutsideClick={false}
          onClose={() => dispatch(FinanceActions.setIsEditModalOpenAction(false))}
        >
          <FinanceTableModal
            modalTitle={financeTableTypeToRender === EFinanceTableTypes.SALES ? 'Edit Sales Row' : 'Edit Purchase Row'}
            submitButtonLabel="Save"
            financeModalType={financeTableTypeToRender}
            onSubmit={(amendedRow: IFinanceRow, files: File[]) => {
              dispatch(FinanceActions.editRowAndSaveRequestAction(amendedRow, files));
            }}
            submitButtonLoading={networkRequests.editRowAndSaveLoad === ENetworkRequestStatus.PENDING}
            errorMessage={errorMessages.editRowAndSaveError || undefined}
            date={financeDocument.rows[editRowIndex!].date}
            uploadName={financeDocument.rows[editRowIndex!].uploadName || undefined}
            rowType={financeDocument.rows[editRowIndex!].rowType}
            description={financeDocument.rows[editRowIndex!].description || ''}
            amountCents={financeDocument.rows[editRowIndex!].amountCents || 0}
            isAutomatedInvoice={isAutomaticFinanceRow(financeDocument.rows[editRowIndex!])}
            bankAccount={financeDocument.rows[editRowIndex!].bankAccount || undefined}
            hotelCurrency={bookingCurrency}
            creditNoteFlowAnswer1={
              financeTableTypeToRender === EFinanceTableTypes.SALES
                ? financeDocument.rows[editRowIndex!].isCreditNoteSpecificToHotel
                : financeDocument.rows[editRowIndex!].isCreditNoteSpecificToGuest
            }
            creditNoteExpiryDate={financeDocument.rows[editRowIndex!].creditNoteExpiryDate}
            paymentMethods={paymentMethods || []}
            defaultPaymentMethodName={defaultPaymentMethodName}
          />
        </StandardModal>
      )}

      {isDeleteModalOpen && (
        <ConfirmationModal
          className="finance-table-delete-warning"
          type={EConfirmationModalType.WARNING}
          title={`Are you sure you want to delete this row?`}
          message="This change CANNOT be undone"
          confirmButtonLabel="Delete"
          cancelButtonLabel="Cancel"
          isConfirmLoading={networkRequests.deleteRowAndSaveLoad === ENetworkRequestStatus.PENDING}
          onConfirm={() => {
            dispatch(FinanceActions.deleteRowAndSaveRequestAction());
          }}
          onCancel={() => {
            dispatch(FinanceActions.setIsDeleteModalOpenAction(false));
          }}
        />
      )}
    </div>
  );
};
const BookingManagerFinanceConnected = BookingManagerFinance;
export default BookingManagerFinanceConnected;
