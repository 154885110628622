import React from 'react';
import classnames from 'classnames';
import Checkbox from 'ui/Checkbox';
import { ITravelAgent } from 'services/BackendApi';

interface IAssignTAModalHeader {
  onCheckAll: () => void;
  onUnCheckAll: () => void;
  isCheckAll: boolean;
  assignedTAs: ITravelAgent[];
}

export const AssignTAModalHeader: React.FC<IAssignTAModalHeader> = React.memo(({ onCheckAll,
  onUnCheckAll,
  isCheckAll,
  assignedTAs }) => {

  return (
    <div className="assign-ta-list-header bg-ivory uppercase h-10 flex items-center pl-3">
      <div className="assign-ta-list-header-title w-200px">
        <label className="assign-ta-list-row-checkbox-label flex flex-row items-center pl-3px">
          <Checkbox disabled={!assignedTAs.length} onClick={isCheckAll ? onUnCheckAll : onCheckAll} checked={isCheckAll&&!!assignedTAs.length} />

          <span className='ml-2 text-gray-100 tracking-2xs text-xs leading-16px'>Name</span>
        </label>
      </div>
      <div className="assign-ta-list-header-country w-135px ml-5">
        <span className='text-gray-100 tracking-2xs text-xs leading-16px'>COUNTRY</span>
      </div>
    </div>
  );
});
