import React from 'react';
import { SimpleTabs } from 'ui/SimpleTabs';
import { getCurrencySymbol } from 'utils';
import * as _ from 'lodash-es';

interface iChildItemProps {
  title: any;
  childItem: any;
  contentRenderer: (childItem: any) => React.ReactElement;
  onOpen: (childItem: any) => void;
  renderChildSubProducts: any[];
  editedUnsavedDetailsItemUuids: string[];
}

export const ChildItem = (props: iChildItemProps) => {
  return (
    <details
      className="group font-pt-sans"
      onToggle={e => {
        if (e.currentTarget.open) {
          props.onOpen(props.childItem);
        }
      }}
    >
      <summary className="bg-gray-20 text-black group-open:bg-brown-prime group-open:text-white p-2 cursor-pointer">
        {props.title}
      </summary>

      <SimpleTabs
        className="o:w-full o:pt-0 o:pb-0"
        tabConfig={[
          {
            name: 'Details',
            title: 'Details',
            hasUnsavedChanges: props.editedUnsavedDetailsItemUuids.includes(props.childItem.uuid),
            styles: 'min-w-150px o:p-2',
            renderContent: () => {
              return props.contentRenderer(props.childItem);
            },
          },
          ...props.renderChildSubProducts.map(subProductMappingItem => {
            const hasUnsavedChanges = subProductMappingItem.hasUnsavedChanges
              ? subProductMappingItem.hasUnsavedChanges(props.childItem)
              : false;

            return {
              title: subProductMappingItem.title,
              name: subProductMappingItem.title,
              hasUnsavedChanges,
              styles: 'min-w-150px o:p-2',
              renderContent: () => {
                if (subProductMappingItem.tabContentRenderer) {
                  return subProductMappingItem.tabContentRenderer(
                    props.childItem,
                    props.childItem[subProductMappingItem.accessKey]
                  );
                }
                const items = props.childItem[subProductMappingItem.accessKey] || [];
                return (
                  <div className="flex flex-col space-y-2 w-full">
                    {_.orderBy(items, ['uuid'], ['desc'])
                      .filter(subProduct =>
                        subProductMappingItem.listFilter ? subProductMappingItem.listFilter(subProduct) : true
                      )
                      .map(subProduct => {
                        return (
                          <details
                            className="group/nest"
                            key={subProduct.uuid}
                            onToggle={e => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <summary className="bg-gray-20 text-black group-open/nest:bg-brown-prime group-open/nest:text-white p-2 cursor-pointer text-13px">
                              {subProductMappingItem.titleRenderer(props.childItem, subProduct)}
                            </summary>
                            <div>{subProductMappingItem.contentRenderer(props.childItem, subProduct)}</div>
                          </details>
                        );
                      })}

                    {subProductMappingItem.bottomContent && subProductMappingItem.bottomContent(props.childItem)}
                  </div>
                );
              },
            };
          }),
        ]}
      />
    </details>
  );
};
