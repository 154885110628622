import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  EFinanceRowTypes,
  EManualBookingLedgerRowType,
  IBookingLedgerSortableItems,
  IFinanceRow,
} from 'services/BookingManagerApi';
import { IBookingLedgerRow, IBookingLedgerPageRow, IDueDate } from 'store/modules/ledger/model';
import {
  bookingLedgerSetItemsPerPageAction,
  bookingLedgerSetPageNumberAction,
  bookingLedgerSetSortAction,
  checkInitialBCFRequestAction,
  openBalanceCarriedForwardModalAction,
} from 'store/modules/ledger/actions';
import {
  ledgerBookingLedgerItemsPerPageSelector,
  ledgerBookingLedgerPageCountSelector,
  ledgerBookingLedgerPageSelector,
  ledgerBookingLedgerSortSelector,
} from 'store/modules/ledger/selectors';

import { HidingTooltip } from 'ui/Tooltip';

import { format } from './utils';
import { LedgerTable } from './LedgerTable';
import { TransactionDateColumn } from './TransactionDateColumn';
import { ETransactionSymbolType, TransactionSymbolColumn } from './TransactionSymbolColumn';
import { TransactionTypeColumn } from './TransactionTypeColumn';
import { TransactionBookingColumn } from './TransactionBookingColumn';
import { TransactionAmountColumn } from './TransactionAmountColumn';
import { Pagination } from 'pureUi/Pagination';
import { isNegativeFinanceRow, isPositiveFinanceRow } from 'store/modules/bookingManager/subdomains/finance/utils';
import { TCurrencyCode } from 'interfaces';
import { CircleIconButton } from '../CircleIconButton';
import { isManualBookingLedgerRow } from 'store/modules/ledger/utils';
import { getManualBookingLedgerRowLabel } from '../BalanceCarriedForwardModal/type_options';

interface ICurrency {
  currency: TCurrencyCode;
}
interface IBalanceCarrierForward {
  balanceCarriedForward: number;
}

interface IBookingLedgerTableProps extends ICurrency, IBalanceCarrierForward {
  financeRows: IBookingLedgerPageRow[];
  tableLoading: boolean;
}

interface IFormatters {
  formatAmount: (amount: number) => string;
  formatDate: (date: string) => string;
}

interface IBookingLedgerTableBodyProps extends IBookingLedgerTableProps, ICurrency {
  onRowEdit: (row: IBookingLedgerRow) => void;
}
interface IBookingLedgerTableRowProps extends IFormatters {
  row: IBookingLedgerRow;
  rowIndex: number;
  balance: number;
  onEdit: (row: IBookingLedgerRow) => void;
}

interface IDueDatesTooltipProps extends IFormatters {
  amountCents: number;
  dueDates: IDueDate[];
  humanReadableId: string;
}

interface IRenderHeaderSpan {
  label: string;
  sortKey?: IBookingLedgerSortableItems;
  labelClasses?: string;
  headerClasses?: string;
}

const BookingLedgerTableHeader: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const sort = useSelector(ledgerBookingLedgerSortSelector);

  const Classes = useMemo(
    () => ({
      container: 'bg-ivory border-solid border-b border-gray-20 uppercase text-xs text-gray-120 border-l-0 border-r-0',
      header: 'group flex font-normal items-center justify-between h-8',
      bordered: 'border-r border-solid border-r-gray-20',
    }),
    []
  );

  const handleSort = useCallback(
    (sortBy: IBookingLedgerSortableItems) => () => {
      if (sort) {
        const newSortOrder = sortBy === sort.field && sort.order === 'asc' ? 'desc' : 'asc';
        dispatch(bookingLedgerSetSortAction(sortBy, newSortOrder));
      }
    },
    [dispatch, sort]
  );

  const renderSortIcon = useCallback(
    (sortBy: IBookingLedgerSortableItems) => {
      if (sortBy !== sort.field) {
        return (
          <span className="group-hover:text-gray-40 text-ivory fa-stack fa-lg w-20px ml-5px">
            <i className="fas fa-stack-1x fa-sort-up"></i>
            <i className="fas fa-stack-1x fa-sort-down"></i>
          </span>
        );
      }
      if (sort.order === 'asc') {
        return (
          <span className="fa-stack fa-lg w-20px ml-5px">
            <i className="fas fa-stack-1x fa-sort-up text-gray-100"></i>
            <i className="fas fa-stack-1x fa-sort-down text-gray-40"></i>
          </span>
        );
      } else if (sort.order === 'desc') {
        return (
          <span className="fa-stack fa-lg w-20px ml-5px">
            <i className="fas fa-stack-1x fa-sort-up text-gray-40"></i>
            <i className="fas fa-stack-1x fa-sort-down text-gray-100 "></i>
          </span>
        );
      }
    },
    [sort]
  );

  const renderHeaderSpan = useCallback(
    ({ label, labelClasses, headerClasses, sortKey }: IRenderHeaderSpan) => {
      if (sortKey === undefined) {
        return (
          <span className={Classes.header}>
            <span className={classNames('w-full', labelClasses ?? 'pl-2')}>{label}</span>
          </span>
        );
      }

      return (
        <span
          className={classNames(`${Classes.header} hover:bg-gray-10 cursor-pointer rounded ${headerClasses}`, {
            'bg-gray-10': sort.field === sortKey,
          })}
        >
          <span className={classNames('w-full', labelClasses ?? 'pl-2')}>{label}</span>
          {renderSortIcon(sortKey)}
        </span>
      );
    },
    [Classes.header, renderSortIcon, sort.field]
  );

  return (
    <thead className={Classes.container}>
      <tr className="h-10">
        <th id="value-header" style={{ width: '100px' }} onClick={handleSort('date')}>
          {renderHeaderSpan({ label: 'Value Date', sortKey: 'date', headerClasses: 'ml-7px' })}
        </th>

        <th id="type-header" style={{ width: '125px' }} onClick={handleSort('type')}>
          {renderHeaderSpan({ label: 'Type', sortKey: 'type' })}
        </th>

        <th id="booking-header" style={{ width: '140px' }} onClick={handleSort('humanReadableId')}>
          {renderHeaderSpan({ label: 'Booking', sortKey: 'humanReadableId' })}
        </th>

        <th id="guest-header" style={{ width: '120px' }}>
          {renderHeaderSpan({ label: 'Guest' })}
        </th>

        <th id="travel-partner-header" style={{ width: '120px' }}>
          {renderHeaderSpan({ label: 'Travel Partner' })}
        </th>

        <th id="due-dates-header" className={Classes.bordered} style={{ width: '50px' }}>
          {renderHeaderSpan({ label: 'Due Dates', labelClasses: 'text-center pl-0' })}
        </th>

        <th id="transactions-amount-header" className={Classes.bordered} style={{ width: '97px' }}>
          {renderHeaderSpan({ label: 'Transaction Amount', labelClasses: 'text-center pl-0' })}
        </th>

        <th id="total-balance-header" className={Classes.bordered} style={{ width: '110px' }}>
          {renderHeaderSpan({ label: 'Total Balance', labelClasses: 'text-center pl-0' })}
        </th>

        <th id="booking-balance-header" className={Classes.bordered} style={{ width: '110px' }}>
          {renderHeaderSpan({ label: 'Booking Balance', labelClasses: 'text-center pl-0' })}
        </th>

        <th id="actions" style={{ width: '80px' }} />
      </tr>
    </thead>
  );
});

const BookingLedgerTableRow: React.FC<IBookingLedgerTableRowProps> = React.memo(
  ({ row, rowIndex, balance, formatAmount, formatDate, onEdit }) => {
    const isDepositRow = [EFinanceRowTypes.Deposit_Transfer_In, EFinanceRowTypes.Deposit_Transfer_Out].includes(
      row.type
    );

    const transactionType = isManualBookingLedgerRow(row) ? getManualBookingLedgerRowLabel(row.type) : row.type;

    const financeRow = { rowType: row.type } as IFinanceRow;
    let transactionSymbolType = ETransactionSymbolType.NONE;
    if (isPositiveFinanceRow(financeRow)) {
      transactionSymbolType = ETransactionSymbolType.POSITIVE;
    }
    if (isNegativeFinanceRow(financeRow)) {
      transactionSymbolType = ETransactionSymbolType.NEGATIVE;
    }
    const guestName = [row.guestTitle, row.guestFirstName, row.guestLastName].filter(item => !!item).join(' ');

    const Classes = useMemo(
      () => ({
        row: 'h-58px text-black text-15px border-t border-solid border-gray-20 even:bg-ivory',
        guest: 'px-2 overflow-hidden whitespace-nowrap text-ellipsis',
        bordered: 'border-r border-solid border-r-gray-20',
        amount: 'text-right pl-2 pr-15px',
        amountsBg: (rowIndex + 1) % 2 === 0 ? 'bg-teal-20' : 'bg-green-25',
        bookingBalanceBg: (rowIndex + 1) % 2 === 0 ? 'bg-gray-10' : 'bg-ivory',
      }),
      [rowIndex]
    );

    const renderDueDatesTooltip = useCallback(() => {
      if (!row.dueDates || !row.humanReadableId) {
        return null;
      }

      return (
        <DueDatesTooltip
          amountCents={row.amountCents}
          dueDates={row.dueDates}
          formatAmount={formatAmount}
          formatDate={formatDate}
          humanReadableId={row.humanReadableId}
        />
      );
    }, [row, formatDate, formatAmount]);

    const handleRowEdit = useCallback(() => {
      onEdit(row);
    }, [row]);

    return (
      <tr className={Classes.row}>
        <TransactionDateColumn
          className={`booking-ledger-table-column-date-${rowIndex} pl-15px`}
          date={row.date}
          formatter={formatDate}
        />
        <TransactionTypeColumn
          className={`booking-ledger-table-column-transaction-type-${rowIndex}`}
          transactionType={transactionType}
          transactionSymbolType={transactionSymbolType}
          isDepositRow={isDepositRow}
        />
        <TransactionBookingColumn
          className={`booking-ledger-table-column-booking-reference-${rowIndex}`}
          bookingUuid={row.bookingUuid}
          bookingReference={row.humanReadableId}
          bookingStatus={row.status}
          canViewBooking={row.canViewBooking}
        />

        <td className={`booking-ledger-table-column-guest-${rowIndex} ${Classes.guest}`}>
          <span>{guestName}</span>
        </td>

        <td className={`booking-ledger-table-column-travel-agent-email-${rowIndex} px-2`}>
          <span className="break-all">{row.travelAgentEmail}</span>
        </td>

        <td className={`booking-ledger-table-column-due-dates-${rowIndex} px-2 ${Classes.bordered}`}>
          {row.dueDates && row.dueDates.length > 0 && (
            <span className="relative">
              <HidingTooltip renderTooltipContent={renderDueDatesTooltip} position="left" tooltipClassname="mt-15">
                <span className="w-full text-center">
                  <i className="cursor-pointer fas fa-info-circle text-gray-60 hover:text-gray-90" />
                </span>
              </HidingTooltip>
            </span>
          )}
        </td>

        <TransactionAmountColumn
          amount={row.amountCents}
          formatter={formatAmount}
          className={`booking-ledger-table-column-transaction-amount booking-ledger-table-column-transaction-amount-${rowIndex} ${Classes.amount} ${Classes.bordered} ${Classes.amountsBg}`}
        />
        <TransactionAmountColumn
          amount={balance}
          formatter={formatAmount}
          className={`booking-ledger-table-column-total-balance booking-ledger-table-column-total-balance-${rowIndex} ${Classes.amount} ${Classes.bordered} ${Classes.amountsBg}`}
        />
        {isManualBookingLedgerRow(row) ? (
          <td
            className={`booking-ledger-table-column-booking-balance booking-ledger-table-column-booking-balance-${rowIndex} ${Classes.bordered} ${Classes.bookingBalanceBg}`}
          ></td>
        ) : (
          <TransactionAmountColumn
            amount={row.parentNetTotal}
            formatter={formatAmount}
            className={`booking-ledger-table-column-booking-balance booking-ledger-table-column-booking-balance-${rowIndex} ${Classes.amount} ${Classes.bordered} ${Classes.bookingBalanceBg}`}
          />
        )}

        <td
          className={`booking-ledger-table-column-actions booking-ledger-table-column-actions-${rowIndex} px-10px ${Classes.bordered} border-r-0`}
        >
          <div className="flex justify-end items-center">
            {!!row.uploadUrl && (
              <a className="text-lg" target="_blank" href={row.uploadUrl}>
                <CircleIconButton type="secondary" className="finance-row-edit-button mx-1" iconClass="far fa-file" />
              </a>
            )}
            {isManualBookingLedgerRow(row) && (
              <div>
                <CircleIconButton
                  type="secondary"
                  className="finance-row-edit-button mx-1"
                  iconClass="fas fa-pen"
                  onClick={handleRowEdit}
                />
              </div>
            )}
          </div>
        </td>
      </tr>
    );
  }
);

const BookingLedgerTableBody: React.FC<IBookingLedgerTableBodyProps> = React.memo(
  ({ financeRows, currency, onRowEdit }) => {
    const formatDate = useMemo(() => format.date, []);
    const formatAmount = useMemo(() => format.amount(currency), [currency]);

    return (
      <tbody>
        {financeRows.map((financeRow, rowIndex) => (
          <BookingLedgerTableRow
            formatAmount={formatAmount}
            formatDate={formatDate}
            row={financeRow.row}
            balance={financeRow.balance}
            rowIndex={rowIndex}
            key={`booking-ledger-table-row-${rowIndex}`}
            onEdit={onRowEdit}
          />
        ))}
      </tbody>
    );
  }
);

export const DueDatesTooltip: React.FC<IDueDatesTooltipProps> = React.memo(
  ({ amountCents, humanReadableId, dueDates, formatAmount, formatDate }) => (
    <div className="due-dates-tooltip px-10px py-20px font-pt-sans text-black w-310px">
      <div className="flex justify-between items-center">
        <div className="title font-bold">Proforma Invoice</div>
        <div>{humanReadableId}</div>
      </div>
      <div className="flex justify-between items-center px-15px py-10px bg-teal-20 mt-20px">
        <div className="label text-xs uppercase">Invoice Total</div>
        <div className="value font-bold text-15px">{formatAmount(amountCents)}</div>
      </div>
      <div className="due-dates bg-green-25 text-13px py-5px px-15px">
        <div className="title text-gray-100 pb-1px">Due dates:</div>
        <div className="items">
          {dueDates.map(item => (
            <div key={item.date} className="flex justify-between items-center py-1">
              <div className="label">{formatDate(item.date)}</div>
              <div className="value">{formatAmount(item.amountCents)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
);

export const BookingLedgerTable: React.FC<IBookingLedgerTableProps> = React.memo(props => {
  const dispatch = useDispatch();
  const currentPage = useSelector(ledgerBookingLedgerPageSelector);
  const itemsPerPage = useSelector(ledgerBookingLedgerItemsPerPageSelector);
  const pageCount = useSelector(ledgerBookingLedgerPageCountSelector);

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      dispatch(bookingLedgerSetPageNumberAction(pageNumber - 1));
    },
    [dispatch]
  );

  const handleItemsPerPageChange = useCallback(
    (items: number) => {
      dispatch(bookingLedgerSetItemsPerPageAction(items));
    },
    [dispatch]
  );

  const handleRowEdit = useCallback((row: IBookingLedgerRow) => {
    if (!row.uuid || !isManualBookingLedgerRow(row)) {
      return;
    }
    dispatch(checkInitialBCFRequestAction(row));
    dispatch(
      openBalanceCarriedForwardModalAction({
        uuid: row.uuid,
        type: row.type as EManualBookingLedgerRowType,
        date: row.date,
        amountCents: row.amountCents,
        uploadUuid: row.uploadUuid,
        uploadUrl: row.uploadUrl,
        uploadName: row.uploadName,
      })
    );
  }, []);

  return (
    <>
      <LedgerTable className={classNames('booking-ledger-table-by-currency', { 'opacity-30': props.tableLoading })}>
        <BookingLedgerTableHeader />
        <BookingLedgerTableBody {...props} onRowEdit={handleRowEdit} />
      </LedgerTable>

      <Pagination
        className="pagination mt-8 mr-15px"
        onPageSelect={handlePageChange}
        pageCount={pageCount}
        currentPage={currentPage + 1}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  );
});
