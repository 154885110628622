import * as React from 'react';

import SectionTitle from 'ui/SectionTitle';
import Checklist from '../Checklist';
import { IChecklistItem } from 'services/BookingManagerApi/types';

interface IOverviewChecklistProps {
  checklist: IChecklistItem[];
  isLoading: boolean;
  isError: boolean;
  onChange: (value: string, checked: boolean) => void;
  children?: React.ReactNode;
}

export const OverviewChecklist: React.FC<IOverviewChecklistProps> = ({ checklist, onChange, isLoading, isError }) => {
  const containerClassName = `
    checklist-overview
    flex items-center justify-center min-h-150px font-pt-sans
    p-5 border border-solid border-gray-40 shadow-pe1 font-pt-sans"
  `;

  if (isLoading) {
    return (
      <div className={`${containerClassName} text-lg text-black`}>
        <span className="info loading">Loading...</span>
      </div>
    );
  }

  if (isError) {
    return (
      <div className={`${containerClassName} text-sm text-red-100`}>
        <span className="info error">Failed to load Checklist</span>
      </div>
    );
  }

  return (
    <div className="checklist-overview border border-solid border-gray-40 shadow-pe1 font-pt-sans">
      <SectionTitle testId={'section-title'} className="p-5">
        Checklist
      </SectionTitle>
      <Checklist data-role={'checklist'} list={checklist} onChange={onChange} />
    </div>
  );
};

export default OverviewChecklist;
