import classNames from 'classnames';
import { isNil } from 'lodash-es';
import React from 'react';
import { ESortOrder } from 'store/common/types';
import { Radio } from 'ui/Radio';
import { Table } from 'ui/Table';
import { ITableColumn, ITableRow } from 'ui/Table/types';
import { HidingTooltip } from 'ui/Tooltip';
import { SvgIcon } from 'ui/SvgIcon';
import RejectedIcon from 'ui/Icons/rejected.component.svg';
import {
  AncillaryRatesProductsSearchItem,
  SelectedAncillarySearchResult,
} from 'services/BookingManagerApi/types/AncillaryService';
import UserAlert from 'ui/Icons/user-alert.component.svg';
import { formatPrice } from 'utils';
import StarOutlineIcon from 'ui/Icons/star-outline.component.svg';

export interface IAddAncillarySearchResultsTableProps {
  primaryColumnId: string;
  primaryColumnName: string;
  searchResults?: AncillaryRatesProductsSearchItem[];
  currencySymbol: string;
  selectedSearchResult: SelectedAncillarySearchResult | null;
  handleSelectSearchResult: (baseProductId: string, variantId?: string) => void;
  isLoading: boolean;
  baseProductNameRenderer: (searchResult: AncillaryRatesProductsSearchItem) => string;
  className?: string;
}

interface IRenderIconsWithTooltipsProps {
  baseProductInternalWarnings: string | null;
  baseProductIsPreferred?: boolean;
  hasGuestsBelowMinimumAge?: boolean;
  restrictionMinimumAge?: number | null;
}
const RenderIconsWithTooltips = (props: IRenderIconsWithTooltipsProps) => {
  const {
    baseProductInternalWarnings,
    baseProductIsPreferred,
    hasGuestsBelowMinimumAge,
    restrictionMinimumAge,
  } = props;
  return (
    <span className="flex items-center space-x-2 justify-end flex-row">
      {!isNil(baseProductInternalWarnings) ? (
        <HidingTooltip
          renderTooltipContent={() => (
            <div className="max-w-[300px] p-2 text-sm">
              <span className="font-normal">{baseProductInternalWarnings}</span>
            </div>
          )}
          position="bottom"
        >
          <SvgIcon className="fill-red-95" width="16px" height="16px" IconComponent={RejectedIcon} />
        </HidingTooltip>
      ) : (
        <span className="block min-h-[16px] min-w-[16px]"></span>
      )}
      {!isNil(baseProductIsPreferred) && baseProductIsPreferred ? (
        <HidingTooltip
          renderTooltipContent={() => (
            <div className="p-2 text-sm min-w-[80px] text-center">
              <span className="font-normal">Preferred</span>
            </div>
          )}
          position="bottom"
        >
          <SvgIcon className="fill-status-requested" width="18px" height="18px" IconComponent={StarOutlineIcon} />
        </HidingTooltip>
      ) : (
        <span className="block min-h-[18px] min-w-[18px]"></span>
      )}
      {hasGuestsBelowMinimumAge ? (
        <HidingTooltip
          renderTooltipContent={() => (
            <div className="p-2 text-sm min-w-[240px]">
              <span className="font-normal">
                The minimum age for this ground service is {restrictionMinimumAge} years old. There are participants not
                being included.
              </span>
            </div>
          )}
          position="bottom"
        >
          <SvgIcon IconComponent={UserAlert} />
        </HidingTooltip>
      ) : (
        <span className="block min-h-[16px] min-w-[28px]"></span>
      )}
    </span>
  );
};

export const AddAncillarySearchResultsTable = (props: IAddAncillarySearchResultsTableProps) => {
  const { className = '' } = props;
  const tableColumnDefinitions: ITableColumn[] = [
    {
      id: props.primaryColumnId,
      label: props.primaryColumnName,
      width: '100%',
      className: '',
    },
    {
      id: 'tertiary-items',
      label: '',
      width: '100px',
      className: '',
    },
    {
      id: 'profit',
      label: 'profit',
      width: '100px',
      className: 'text-right',
    },
    {
      id: 'totalCostFrom',
      label: 'Total Cost From',
      width: '150px',
      className: 'text-right',
    },
    {
      id: 'actions',
      label: '',
      width: '80px',
      className: '',
    },
  ];

  const isSelectedSearchResultAVariant = !isNil(props.selectedSearchResult?.variantId);

  const searchResultToTableRow = (searchResult: AncillaryRatesProductsSearchItem): ITableRow => {
    const isRowSelected =
      !isNil(props.selectedSearchResult) &&
      props.selectedSearchResult.baseProductId === searchResult.baseProductId.toString() &&
      !isSelectedSearchResultAVariant;

    return {
      id: searchResult.baseProductId.toString(),
      className: classNames('search-result-row o:h-[50px]', {
        'o:bg-green-25 font-bold': isRowSelected,
      }),
      cells: [
        // these are the top level cells
        {
          id: 'name',
          value: (
            <span className="flex flex-row space-x-2">
              {isNil(searchResult.variantsWithRate) || searchResult.variantsWithRate.length <= 0 ? (
                // no variants
                <label className="flex items-center justify-between">
                  <span className="flex items-center space-x-2 cursor-pointer">
                    <span className="w-5 block">
                      <Radio
                        onClick={() => {
                          props.handleSelectSearchResult(searchResult.baseProductId.toString());
                        }}
                        checked={isRowSelected}
                      />
                    </span>
                    <span>{props.baseProductNameRenderer(searchResult)}</span>
                  </span>
                </label>
              ) : (
                // variants
                <label className="flex items-center w-[272px] justify-between">
                  <span>{props.baseProductNameRenderer(searchResult)}</span>
                </label>
              )}
            </span>
          ),
        },
        {
          id: 'tertiary-items',
          value: (
            <RenderIconsWithTooltips
              baseProductInternalWarnings={searchResult.baseProductInternalWarnings}
              baseProductIsPreferred={searchResult.baseProductIsPreferred}
              hasGuestsBelowMinimumAge={searchResult.productWithRate?.hasGuestsBelowMinimumAge}
              restrictionMinimumAge={searchResult.productWithRate?.restrictionMinimumAge}
            />
          ),
        },
        {
          id: 'profit',
          value: searchResult.bestRateProfit
            ? `+ ${props.currencySymbol}${formatPrice(searchResult.bestRateProfit)}`
            : '',
          className: 'text-right relative',
          contentClassName: 'absolute top-4 right-2 text-green-message',
        },
        {
          id: 'totalCostFrom',
          value: searchResult.bestRateTotal
            ? props.currencySymbol + formatPrice(searchResult.bestRateTotal)
            : 'On Request',
          className: 'text-right relative',
          contentClassName: 'absolute top-4 right-2',
        },
        {
          id: 'actions',
          className: 'text-right relative',
          contentClassName: 'absolute top-4 right-6',
          value: (
            <span className="flex items-center space-x-5">
              {(!isNil(searchResult.productWithRate?.ratePaymentTerms) ||
                !isNil(searchResult.productWithRate?.rateCancellationPolicy)) && (
                <HidingTooltip
                  renderTooltipContent={() => (
                    <div className="max-w-[500px] text-left px-4">
                      {!isNil(searchResult.productWithRate?.rateCancellationPolicy) && (
                        <>
                          <p className="font-bold">Cancellation Policy:</p>
                          <p className="font-normal">{searchResult.productWithRate?.rateCancellationPolicy}</p>
                        </>
                      )}
                      {!isNil(searchResult.productWithRate?.ratePaymentTerms) && (
                        <>
                          <p className="font-bold">Payment Terms:</p>
                          <p className="font-normal">{searchResult.productWithRate?.ratePaymentTerms}</p>
                        </>
                      )}
                    </div>
                  )}
                  position="left"
                  tooltipClassname=""
                >
                  <span className="border border-solid text-gray-80 border-gray-80 rounded-full w-4 h-4 flex justify-around items-center text-[8px]">
                    <i className="fas fa-info"></i>
                  </span>
                </HidingTooltip>
              )}
              {/* Note: Commented out for now, will be reintroduced with some more functionality in later ticket */}

              {/* <span className="border border-solid text-brown-100 border-brown-100 rounded-full w-5 h-5 flex justify-around items-center text-xs">
                <i className="fas fa-chevron-right ml-2px"></i>
              </span> */}
            </span>
          ),
        },
      ],
      subRows: isNil(searchResult.variantsWithRate)
        ? []
        : searchResult.variantsWithRate.map(variant => {
            const isVariantRowSelected =
              !isNil(props.selectedSearchResult) &&
              props.selectedSearchResult.baseProductId === variant.baseProductId.toString() &&
              isSelectedSearchResultAVariant &&
              props.selectedSearchResult.variantId === variant.variantId!.toString();

            return {
              id: variant.variantId!.toString(),
              className: classNames('search-result-row', {
                'o:bg-green-25 font-bold': isVariantRowSelected,
              }),
              cells: [
                // these are the sub row cells
                {
                  id: 'ground_service',
                  value: (
                    <label className="flex items-center cursor-pointer justify-between ml-6">
                      <span className="flex items-center space-x-2">
                        <Radio
                          onClick={() => {
                            props.handleSelectSearchResult(
                              variant.baseProductId.toString(),
                              variant.variantId!.toString()
                            );
                          }}
                          checked={isVariantRowSelected}
                        />
                        <span className="w-[250px]">{variant.variantName!}</span>
                      </span>
                    </label>
                  ),
                },
                {
                  id: 'tertiary-items',
                  value: (
                    <RenderIconsWithTooltips
                      baseProductInternalWarnings={variant.variantInternalWarnings}
                      // baseProductIsPreferred={searchResult.baseProductIsPreferred}
                      hasGuestsBelowMinimumAge={variant.hasGuestsBelowMinimumAge}
                      restrictionMinimumAge={variant.restrictionMinimumAge}
                    />
                  ),
                },
                {
                  id: 'profit',
                  value: variant.bestRateProfit
                    ? `+ ${props.currencySymbol}${formatPrice(variant.bestRateProfit)}`
                    : '',
                  className: 'text-right relative',
                  contentClassName: 'text-green-message',
                },
                {
                  id: 'totalCostFrom',
                  value: variant.bestRateTotal
                    ? props.currencySymbol + formatPrice(variant.bestRateTotal)
                    : 'On Request',
                  className: 'text-right relative',
                },
                {
                  id: 'actions',
                  className: 'text-right relative',
                  contentClassName: 'absolute top-3 right-6',
                  value: (
                    <span>
                      {(!isNil(variant.ratePaymentTerms) || !isNil(variant.rateCancellationPolicy)) && (
                        <HidingTooltip
                          renderTooltipContent={() => (
                            <div className="w-[500px] text-left px-4">
                              {!isNil(variant.rateCancellationPolicy) && (
                                <>
                                  <p className="font-bold">Cancellation Policy:</p>
                                  <p className="font-normal">{variant.rateCancellationPolicy}</p>
                                </>
                              )}
                              {!isNil(variant.ratePaymentTerms) && (
                                <>
                                  <p className="font-bold">Payment Terms:</p>
                                  <p className="font-normal">{variant.ratePaymentTerms}</p>
                                </>
                              )}
                            </div>
                          )}
                          position="left"
                          tooltipClassname=""
                        >
                          <span className="border border-solid text-gray-80 border-gray-80 rounded-full w-4 h-4 flex justify-around items-center text-[8px]">
                            <i className="fas fa-info"></i>
                          </span>
                        </HidingTooltip>
                      )}
                    </span>
                  ),
                },
              ],
            };
          }),
    };
  };

  const searchResultsAsTableRows: ITableRow[] = props.searchResults
    ? props.searchResults.map(searchResult => searchResultToTableRow(searchResult))
    : [];

  return (
    <div
      className={classNames(`search-results font-pt-sans flex flex-col space-y-4 ${className}`, {
        'opacity-50': props.isLoading,
      })}
    >
      <h4 className="font-bold m-0">
        Search Results{' '}
        <span className="font-normal">
          (
          {props.isLoading
            ? 'Searching...'
            : isNil(props.searchResults) || props.searchResults.length <= 0
            ? 'There are no products for the searched criteria'
            : props.searchResults.length}
          )
        </span>
      </h4>

      <div className="table-wrapper overflow-y-scroll">
        <Table
          className="add-ground-service-table"
          columns={tableColumnDefinitions}
          stickyHeader
          rows={props.isLoading ? [] : searchResultsAsTableRows}
          sortBy={'ground_service'}
          sortOrder={ESortOrder.ASC}
          onSort={(field, order) => {}}
          messageWhenEmpty={props.isLoading ? '' : 'No Results'}
          loading={false}
        />
      </div>
    </div>
  );
};
