import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { ENetworkRequestStatus } from 'services/BackendApi';
import FluidButton from 'ui/FluidButton';
import { closeCreateDepartmentModalAction, companyDepartmentsRequestsSelector, companyDepartmentsSelector, fetchCompanyDepartmentsRequestAction, resetCompanyDepartmentsAction, setUuidToDeleteAction, setDepartmentToEditAction, uuidToDeleteSelector, departmentToEditSelector, openCreateDepartmentModalAction, deleteCompanyDepartmentRequestAction, createDepartmentModalSelector } from 'store/modules/companyDepartments';
import { DepartmentsTable } from './DepartmentsTable';
import { EditDepartmentModal } from './EditDepartmentModal';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';

export interface IDepartmentProps {
  companyUuid: string;
  isEditMode: boolean;
}

export const Departments: React.FC<IDepartmentProps> = ({ companyUuid, isEditMode }) => {
  const departments = useSelector(companyDepartmentsSelector);
  const dispatch = useDispatch();
  const requests = useSelector(companyDepartmentsRequestsSelector)
  const isLoading = requests.departmentListLoad === ENetworkRequestStatus.PENDING
    || requests.departmentCreate === ENetworkRequestStatus.PENDING
    || requests.departmentDelete === ENetworkRequestStatus.PENDING
    || requests.departmentSave === ENetworkRequestStatus.PENDING;
  const uuidToDelete = useSelector(uuidToDeleteSelector);
  const departmentToEdit = useSelector(departmentToEditSelector);
  const isCreateDepartmentModalOpen = useSelector(createDepartmentModalSelector);

  const handleAddDepartmentClick = useCallback(() => {
    dispatch(openCreateDepartmentModalAction());
  }, [dispatch]);

  const handleDeleteDepartmentConfirm = useCallback(() => {
    if (uuidToDelete) {
      dispatch(deleteCompanyDepartmentRequestAction(uuidToDelete));
    }
  }, [dispatch, uuidToDelete]);

  const handleDeleteDepartmentCancel = useCallback(() => {
    dispatch(setUuidToDeleteAction(null));
  }, [dispatch]);

  const handleEditDepartmentCancel = useCallback(() => {
    if (departmentToEdit) {
      dispatch(setDepartmentToEditAction(null));
    } else {
      dispatch(closeCreateDepartmentModalAction())
    }
  }, [departmentToEdit, dispatch]);
  
  useEffect(() => {
    dispatch(fetchCompanyDepartmentsRequestAction(companyUuid));

    return () => {
      dispatch(resetCompanyDepartmentsAction());
    };
  }, [companyUuid, dispatch]);

  return (
    <div className="departments-section w-full">
      <p className="departments-title font-pt-sans text-black-true font-bold text-17px leading-md mt-25px mb-0">
        Company Departments
      </p>
      <p className="departments-description font-pt-sans text-black text-15px leading-sm mt-5px mb-0">
        Departments can be set to receive notifications configured for travel agents.
      </p>
      
      {requests.departmentListLoad === ENetworkRequestStatus.PENDING && departments === null && (
        <div className="mt-20px">
          <LoadingBar />
        </div>
      )}

      {requests.departmentListLoad === ENetworkRequestStatus.ERROR && (
        <div className="mt-20px">
          <ErrorBar />
        </div>
      )}

      {!!departments?.length && (
        <DepartmentsTable
          departments={departments}
          loading={isLoading}
          className='departments-table'
        />
      )}

      {isEditMode && (<FluidButton
        className="add-department-button mt-25px"
        onClick={handleAddDepartmentClick}
        disabled={isLoading || !isEditMode}
        type="secondary"
      >Add Department</FluidButton>)}

      {uuidToDelete && (
        <ConfirmationModal
          type={EConfirmationModalType.WARNING}
          className="delete-modal"
          title="You are going to delete a Company Department."
          message="Are you sure want to delete this department?"
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          onConfirm={handleDeleteDepartmentConfirm}
          onCancel={handleDeleteDepartmentCancel}
          isConfirmLoading={requests.departmentDelete === ENetworkRequestStatus.PENDING}
        />
      )}

      {(departmentToEdit || isCreateDepartmentModalOpen) && (
        <EditDepartmentModal
          department={departmentToEdit}
          onCancel={handleEditDepartmentCancel}
        />
      )}
    </div>
  );
};
