import React, { useCallback } from 'react';
import { EUserType } from 'services/BackendApi';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';

export interface IUserRoleSelectProps {
  className?: string;
  value: string | null;
  onChange: (v: string | null) => void;
}

export const UserRoleSelect: React.FC<IUserRoleSelectProps> = props => {
  const selectOptions: ISingleSelectOption[] = [
    { value: EUserType.ADMIN, label: 'Admin' },
    { value: EUserType.FINANCE, label: 'Finance' },
    { value: EUserType.SR, label: 'Sales Representative' },
    { value: EUserType.RL, label: 'Rates Loader' },
  ];

  const handleChange = useCallback((value?: string) => props.onChange(value ?? null), [props.onChange]);

  return (
    <SingleSelect
      fieldId="filterCountry"
      className={props.className}
      value={props.value ?? undefined}
      options={selectOptions}
      onChange={handleChange}
      maxVisibleItems={6}
      showSelectedOption
      labelWhenNothingSelected="All user roles"
    />
  );
};
