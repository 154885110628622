import { call, takeLatest, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  SEARCH_BAM_ACCOMMODATIONS_REQUEST,
  searchBAMAccommodationsFailureAction,
  SearchBAMAccommodationsRequestAction,
  searchBAMAccommodationsSuccessAction,
  setBAMLastRequestAction,
  setBAMSelectedAccommodationAction,
} from '../actions';
import { subDays, format } from 'date-fns';

import { IHotel, IHotelAccommodationsSearchRequest, makeBackendApi } from 'services/BackendApi';
import { IBookingCountryResponse, IOwnershipResponse, makeBookingManagerApi } from 'services/BookingManagerApi';
import { BAMEndDateFilterSelector, BAMGuestAgesFilterSelector, BAMHotelFilterSelector, BAMStartDateFilterSelector } from '../selectors';

export function* searchHotelRoomsSaga(action: SearchBAMAccommodationsRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    yield put(setBAMSelectedAccommodationAction(null));
    const ownershipData: AxiosResponse<IOwnershipResponse> = yield call(bookingManagerApi.getOwnership, action.bookingUuid);
    const travelAgentUuid = ownershipData.data.ownershipData.taInfo.uuid;
    const bookingCountryData: AxiosResponse<IBookingCountryResponse> = yield call(bookingManagerApi.getBookingCountryCode, action.bookingUuid);
    const clientCountryCode = bookingCountryData.data.countryCode;

    const backendApi = makeBackendApi(travelAgentUuid);
    const startDate = yield select(BAMStartDateFilterSelector);
    const endDate = yield select(BAMEndDateFilterSelector);
    const hotelUuid = yield select(BAMHotelFilterSelector);
    const guestAges = yield select(BAMGuestAgesFilterSelector);
    const params: IHotelAccommodationsSearchRequest = {
      startDate,
      endDate: format(subDays(new Date(endDate), 1), 'yyyy-MM-dd'),
      numberOfAdults: guestAges.numberOfAdults,
      agesOfAllChildren: guestAges.agesOfAllChildren,
      hotelUuid,
    };
    const response: AxiosResponse<IHotel[]> = yield call(
      backendApi.searchHotelRooms,
      clientCountryCode,
      params
    );
    yield put(setBAMLastRequestAction(params));

    yield put(searchBAMAccommodationsSuccessAction(response.data));
  } catch (e) {
    yield put(searchBAMAccommodationsFailureAction(e));
  }
}

export function* watchSearchHotelRoomsSaga() {
  yield takeLatest([SEARCH_BAM_ACCOMMODATIONS_REQUEST], searchHotelRoomsSaga);
}
