import React from 'react';

import { BackLink } from 'ui/BackLink';
import { TravelAgentForm } from '../TravelAgentForm';

export const Create: React.FC = () => {
  return (
    <div className="travel-agent-create w-1280px mx-auto">
      <BackLink type="internal" href="/travel-agents">
        Back to travel agents
      </BackLink>
      <h1 className="title font-noe-display font-normal text-black my-0">
        <span className="text-4xl leading-46px">New Travel Agent</span>
      </h1>
      <TravelAgentForm />
    </div>
  );
};
