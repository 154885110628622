import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBackendApi, ITravelAgentResponse } from 'services/BackendApi';
import { enqueueNotification } from 'store/modules/ui';
import {
  getFailureAction,
  getFailureInTCAction,
  GetRequestAction,
  getSuccessAction,
  getSuccessInTCAction,
  GET_REQUEST,
  GET_REQUEST_IN_TC,
  updateFailureAction,
  updateRequestAction,
  UpdateRequestAction,
  updateSuccessAction,
  UPDATE_REQUEST,
  SAVE_CHANGES,
  SaveChangesAction,
} from './actions';
import { patchCompanyRequestAction } from '../companyInfo/actions';
import * as AuthSelectors from 'store/modules/auth';
import * as Selectors from './selectors';

function* successNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'success' } }));
}

function* errorNotification(message: string) {
  yield put(enqueueNotification({ message, options: { variant: 'error' } }));
}

export function* getSaga(action: GetRequestAction) {
  try {
    const api = makeBackendApi();
    const isAdmin = yield select(AuthSelectors.isAdmin);

    let result: AxiosResponse<ITravelAgentResponse> = yield call(
      api.getTravelAgentsByCompanyId,
      action.companyUuid,
      isAdmin
    );
    yield put(getSuccessAction(result.data.data));
  } catch (e) {
    yield put(getFailureAction(e.message));
    yield errorNotification('Error fetching TA settings, plese try again.');
  }
}

export function* getSagaInTC(action: GetRequestAction) {
  try {
    const api = makeBackendApi();

    let result: AxiosResponse<ITravelAgentResponse> = yield call(
      api.getTravelAgentsByCompanyIdInTC,
      action.companyUuid,
    );
    yield put(getSuccessInTCAction(result.data.data));
  } catch (e) {
    yield put(getFailureInTCAction(e.message));
    yield errorNotification('Error fetching TA settings, plese try again.');
  }
}

export function* updateSaga(action: UpdateRequestAction) {
  try {
    const api = makeBackendApi();
    const selectedBookingManagers = yield select(Selectors.selectedBookingManagersSelector);
    const selectedTaManagers = yield select(Selectors.selectedTaManagersSelector);

    yield call(
      api.postCompanyManagers,
      action.companyUuid,
      Array.from(selectedBookingManagers),
      Array.from(selectedTaManagers)
    );
    yield put(updateSuccessAction());
    yield successNotification('TA settings has beed updated successfully.');
  } catch (e) {
    console.log(e);
    yield put(updateFailureAction(e.message));
    yield errorNotification('Error saving TA settings, plese try again.');
  }
}

export function* saveChangesSaga(action: SaveChangesAction) {
  const dirtyBookingManagers = yield select(Selectors.dirtyBookingManagersSelector);
  const dirtyTaManagers = yield select(Selectors.dirtyTaManagersSelector);
  if(dirtyBookingManagers || dirtyTaManagers) {
    yield put(updateRequestAction(action.companyUuid));
  }
}

export function* watchCompanyManagers() {
  yield takeLatest(GET_REQUEST, getSaga);
  yield takeLatest(GET_REQUEST_IN_TC, getSagaInTC);
  yield takeLatest(UPDATE_REQUEST, updateSaga);
  yield takeLatest(SAVE_CHANGES, saveChangesSaga);
}
