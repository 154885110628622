import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Radio } from 'ui/Radio';
import { ICompany, ITravelAgent } from 'services/BackendApi/types';
import { ICountry } from 'ui/CountrySelect/types';
import Checkbox from 'ui/Checkbox';

interface IAssignTAModalRowRowProps {
  travelAgent: ITravelAgent;
  country: ICountry;
  enabled: boolean;
  onAdd: (changedTravelAgent: ITravelAgent) => void;
  onRemove: (changedTravelAgent: ITravelAgent) => void;
}

export const AssignTAModalRow: React.FC<IAssignTAModalRowRowProps> = React.memo(
  ({ travelAgent, country, enabled, onAdd, onRemove }) => {
    const handleTravelAgentSelect = useCallback(() => {
      onAdd(travelAgent);
    }, [travelAgent, onAdd]);

    const handleTravelAgentUnSelect = useCallback(() => {
      onRemove(travelAgent);
    }, [travelAgent, onRemove]);

    return (
      <div
        className={classnames('travel-company-list-row text-black text-15px h-30px flex items-center mt-5px mb-5px', {
          'bg-green-25': enabled,
        })}
      >
        <label
          className="travel-company-list-row-checkbox-label flex flex-row items-center pl-3px cursor-pointer w-200px"
          onClick={enabled ? handleTravelAgentUnSelect : handleTravelAgentSelect}
        >
          <Checkbox checked={enabled} />
          <span className="ml-2 overflow-hidden whitespace-nowrap text-ellipsis" title={`${travelAgent.firstName} ${travelAgent.lastName}`}>
            {travelAgent.firstName} {travelAgent.lastName}
          </span>
        </label>
        <span className="travel-company-country ml-5">{country?.name ?? ''}</span>
      </div>
    );
  }
);
