import { call, select, takeLatest, put } from 'redux-saga/effects';

import { makeBackendApi } from 'services/BackendApi';
import { enqueueNotification } from 'store/modules/ui';
import {
  DELETE_MARKUP_REQUEST,
  deleteCHMarkupSuccessAction,
  deleteCHMarkupFailureAction,
  fetchCHMarkupListRequestAction,
} from '../actions';
import {
  chmMarkupToDeleteSelector,
  travelCompanyUuidSelector,
  bulkDeleteSelector,
  companyHotelMarkupListSelector,
} from '../selectors';
import { ChMarkupToDelete, ICompanyMarkupOptionModel } from '../model';

function* deleteMany() {
  const companyUuid = yield select(travelCompanyUuidSelector);
  const markupList: ICompanyMarkupOptionModel[] = yield select(companyHotelMarkupListSelector);
  const selected = markupList.filter(x => x.selected);
  if (!selected.length) return;

  try {
    yield call(makeBackendApi().deleteCompanyHotelMarkups, companyUuid, selected);

    yield put(deleteCHMarkupSuccessAction());
    yield put(
      enqueueNotification({
        message: 'Mark-Ups deleted successfully.',
        options: { variant: 'success' },
      })
    );
    yield put(fetchCHMarkupListRequestAction());
  } catch (e) {
    yield put(deleteCHMarkupFailureAction(e));
    yield put(enqueueNotification({ message: 'Error while deleting mark-ups', options: { variant: 'error' } }));
  }
}

function* deleteOne() {
  const companyUuid = yield select(travelCompanyUuidSelector);
  const markupToDelete: ChMarkupToDelete | null = yield select(chmMarkupToDeleteSelector);
  if (!markupToDelete) {
    return;
  }

  try {
    yield call(makeBackendApi().deleteCompanyHotelMarkups, companyUuid, [markupToDelete.option]);

    yield put(deleteCHMarkupSuccessAction());
    yield put(enqueueNotification({ message: 'This mark up has been deleted', options: { variant: 'success' } }));
    yield put(fetchCHMarkupListRequestAction());
  } catch (e) {
    yield put(deleteCHMarkupFailureAction(e));
    yield put(enqueueNotification({ message: 'Error while deleting mark up', options: { variant: 'error' } }));
  }
}

function* deleteCompanyHotelMarkupSaga() {
  const bulkDelete = yield select(bulkDeleteSelector);
  bulkDelete ? yield deleteMany() : yield deleteOne();
}

export function* watchDeleteCHMarkupSaga() {
  yield takeLatest(DELETE_MARKUP_REQUEST, deleteCompanyHotelMarkupSaga);
}
