import { IEnabledNotification } from './Notification';
import { EUserType, ITravelAgent } from 'services/BackendApi';

export enum EInternalUserStatus {
  PENDING = 'pending',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  VERIFIED = 'verified',
}

export interface IInternalUser {
  createdAt: string;
  updatedAt: string;
  uuid: string;
  title: string;
  firstName: string;
  lastName: string;
  countryCode: string | null;
  companyUuid: string | null;
  email: string;
  password: string;
  mobileNumber: string | null;
  status: EInternalUserStatus | null;
  type: EUserType | null;
  notifications?: IEnabledNotification[];
  assignedTravelAgents?: ITravelAgent[];
  newlyCreatedTemporaryPassword?: string;
}

export interface IInternalUserResponse {
  data: IInternalUser[];
}

export interface IOneInternalUserResponse {
  data: IInternalUser;
}
