import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FluidButton from 'ui/FluidButton';

import { requestSelector, modifiedSelector } from '../../store/modules/credentialsInfo/selectors';
import { postRequestAction } from '../../store/modules/credentialsInfo/actions';
import { ENetworkRequestStatus } from 'services/BackendApi';

export const Controls:  React.FC = () => {
  const modified = useSelector(modifiedSelector);
  const request = useSelector(requestSelector);

  const dispatch = useDispatch();

  const handleSave = useCallback(
    () => dispatch(postRequestAction()),
    [dispatch]
  );

  return (
    <div className="controls mt-5 mb-5">
      <FluidButton
        type="primary"
        className="save mt-25px"
        onClick={handleSave}
        disabled={!modified.length}
        isLoading={request.post === ENetworkRequestStatus.PENDING}
      >
        Save Changes
      </FluidButton>
    </div>
  );
};
