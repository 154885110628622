import React, { useState, useCallback, ReactNode, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash-es';
import FluidButton from 'ui/FluidButton';
import { renderFullName } from 'utils/guestInformation';
import classnames from 'classnames';
import { ENetworkRequestStatus, ETravelAgentStatus, ISalesRepresentative } from 'services/BackendApi';
import { MassAssignRow } from './MassAssignRow';
import { Radio } from 'ui/Radio';
import { StandardModal } from 'pureUi/Modal';
import { MassAssignRowHeader } from './MassAssignRowHeader';
import {
  massAssignModeSelector,
  massAssignSrsSelector,
  salesRepsSelector,
} from 'store/modules/travelAgentList/selectors';
import {
  setMassAssignConfirmModalVisibleAction,
  setMassAssignModalVisibleAction,
  setMassAssignModeAction,
  setMassAssignSelectedSrsAction,
} from 'store/modules/travelAgentList/actions';
import { CountrySelect } from 'ui/CountrySelect';

export interface IMassAssignModalProps {
  onClose: () => void;
}

export enum EMassAssignTaSrMode {
  OVERWRITE = 'overwrite',
  ADD = 'add',
}

export const MassAssignModal = (props: IMassAssignModalProps) => {
  const { onClose } = props;

  const massAssignMode = useSelector(massAssignModeSelector);
  const massAssignSrs = useSelector(massAssignSrsSelector);

  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState<string | undefined>(undefined);
  const handleCountryCodeChange = useCallback((v: string) => {
    setCountryCode(v);
    dispatch(setMassAssignSelectedSrsAction([]));
  }, []);

  const srs = useSelector(salesRepsSelector);

  const filteredSrs = useMemo(() => {
    let result = (srs || []).filter(sr => sr.status === ETravelAgentStatus.VERIFIED);
    if (countryCode) {
      result = result.filter(sr => sr.countryCode === countryCode);
    }
    result = sortBy(
      result,
      (sr: ISalesRepresentative) =>
        renderFullName(undefined, sr.firstName, sr.lastName)
          ?.toLowerCase()
          ?.trim() ?? ''
    );
    return result;
  }, [countryCode, srs]);

  const handleSrSelect = useCallback(
    (srUuid: string, enabled: boolean) => {
      const newMassAssignSrs = massAssignSrs.includes(srUuid)
        ? massAssignSrs.filter(uuid => uuid !== srUuid)
        : massAssignSrs.concat(srUuid);
      dispatch(setMassAssignSelectedSrsAction(newMassAssignSrs));
    },
    [massAssignSrs]
  );

  const handleSetAddMode = useCallback(() => {
    dispatch(setMassAssignModeAction(EMassAssignTaSrMode.ADD));
  }, []);

  const handleSetOverwriteMode = useCallback(() => {
    dispatch(setMassAssignModeAction(EMassAssignTaSrMode.OVERWRITE));
  }, []);

  const handleSave = useCallback(() => {
    dispatch(setMassAssignConfirmModalVisibleAction(true));
    dispatch(setMassAssignModalVisibleAction(false));
  }, []);

  const handleChangeAllSrs = useCallback(() => {
    if (massAssignSrs.length < filteredSrs.length) {
      dispatch(setMassAssignSelectedSrsAction(filteredSrs.map(sr => sr.uuid)));
    } else {
      dispatch(setMassAssignSelectedSrsAction([]));
    }
  }, [massAssignSrs]);

  return (
    <StandardModal
      className="mass-assign-modal-container font-pt-sans"
      frameClassName="px-9 py-8 border border-solid"
      showCloseButton={true}
      removePadding={true}
      closeOnOutsideClick={true}
      onClose={onClose}
    >
      <div className="mass-assign-modal relative min-w-460px max-w-460px">
        <h3 className="m-0 font-noe-display mb-25px font-normal text-black text-21px">Assign Sales Representative</h3>
        <p className="font-pt-sans text-15px leading-22px text-black m-0">
          Select the Sales Representatives to assign to all the Travel Agents listed:
        </p>
        <p className="font-pt-sans text-16px leading-21px text-black m-0 font-bold mt-25px mb-5px">SR Country</p>
        <CountrySelect
          value={countryCode ?? null}
          onChange={handleCountryCodeChange}
          labelWhenNothingSelected="All"
          className="w-250px"
        />

        <div className="mt-25px pl-3 border border-solid border-gray-20 shadow-pe2">
          <MassAssignRowHeader
            enabled={massAssignSrs.length >= filteredSrs.length && filteredSrs.length > 0}
            onChange={handleChangeAllSrs}
          />
          <div className="mass-assign-rows max-h-320px min-h-320px overflow-y-scroll pr-6">
            {filteredSrs.map(sr => (
              <MassAssignRow sr={sr} enabled={massAssignSrs.includes(sr.uuid)} onChange={handleSrSelect} />
            ))}
            {filteredSrs.length === 0 && (
              <p className="font-pt-sans text-black text-15px leading-19px italic m-0 mt-2">
                No Sales Representative in the country selected.
              </p>
            )}
          </div>
        </div>

        <p className="font-pt-sans text-16px leading-21px text-black m-0 font-bold mt-25px mb-5px">Advanced Options</p>

        <label
          className="mass-assign-type-add inline-flex font-pt-sans text-base leading-21px text-black cursor-pointer"
          onClick={handleSetAddMode}
        >
          <Radio onClick={handleSetAddMode} checked={massAssignMode === EMassAssignTaSrMode.ADD} />
          <span className="ml-1 mr-5">Add this selection to current Assignment</span>
        </label>
        <label
          className="mass-assign-type-overwrite inline-flex font-pt-sans text-base leading-21px text-black cursor-pointer mt-10px"
          onClick={handleSetOverwriteMode}
        >
          <Radio onClick={handleSetOverwriteMode} checked={massAssignMode === EMassAssignTaSrMode.OVERWRITE} />
          <span className="ml-1 mr-5">Overwrite current Assignment with this selection</span>
        </label>

        <div className="mass-assign-buttons flex gap-10px mt-25px">
          <FluidButton className="mass-assign-cancel" type="secondary" onClick={onClose}>
            Cancel
          </FluidButton>
          <FluidButton
            className="mass-assign-save"
            type="primary"
            onClick={handleSave}
            disabled={massAssignSrs.length === 0}
          >
            Save
          </FluidButton>
        </div>
      </div>
    </StandardModal>
  );
};
