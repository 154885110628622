import React from 'react';

import { BackLink } from 'ui/BackLink';
import { TravelCompanyForm } from '../TravelCompanyForm';

export const TravelCompanyCreate: React.FC = () => {
  return (
    <div className="travel-company-create w-1280px mx-auto">
      <BackLink type="internal" href="/travel-companies">
        Back to travel companies
      </BackLink>
      <h1 className="title font-noe-display font-normal text-black my-0">
        <span className="text-4xl leading-46px">New Travel Company</span>
      </h1>
      <TravelCompanyForm isEditMode={true} />
    </div>
  );
};
