import { BookingConfirmationAction } from '../../actions';
import { initialState, QuoteSubdomain } from '../../model';
import * as Actions from './actions';

export const quoteReducer = (state: QuoteSubdomain = initialState.quote, action: BookingConfirmationAction): QuoteSubdomain => {
  switch (action.type) {
    case Actions.SET_QUOTE_DOWNLOADING:
      return {
        ...state,
        isQuoteDownloading: action.value,
      };
    
    default:
      return state;

  }
};
