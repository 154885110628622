import React, { useCallback, CSSProperties } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import * as Actions from 'store/modules/travelAgentList/actions';
import * as Selectors from 'store/modules/travelAgentList/selectors';

import { FormLabel } from 'ui/FormLabel';
import TextInput from 'pureUi/TextInput';
import { CompanySelect } from './CompanySelect';
import { SalesRepSelect } from './SalesRepSelect';
import { CountrySelect } from 'ui/CountrySelect';
import { isAdmin as isAdminSelector } from 'store/modules/auth';
import { Multiselect } from 'ui/Multiselect';
import { ETravelAgentCreatedBy, ETravelAgentStatus } from 'services/BackendApi';
import { UserStatusSelect } from 'ui/UserStatusSelect';
export interface FilterProps {
  className?: string;
}

const textInputStyles: CSSProperties = {
  fontFamily: "'PT Sans', sans-serif",
  height: '35px',
  fontSize: '16px',
  backgroundColor: '#FBFBFA',
};

const spanTextInputStyles: CSSProperties = {
  borderColor: '#D5D2D1',
};

export const Filter: React.FC<FilterProps> = props => {
  const value = useSelector(Selectors.filterSelector);
  const isAdmin = useSelector(isAdminSelector);

  const dispatch = useDispatch();

  const handleSearch = useCallback(e => dispatch(Actions.setSearchFilterAction(e.target.value || null)), [dispatch]);

  const handleEmail = useCallback(e => dispatch(Actions.setEmailFilterAction(e.target.value || null)), [dispatch]);

  const handleCountryCodeChange = useCallback(v => dispatch(Actions.setCountryCodeFilterAction(v)), [dispatch]);

  const handleSalesRepUuidChange = useCallback(v => dispatch(Actions.setSalesRepFilterAction(v)), [dispatch]);

  const handleStatusChange = useCallback(v => dispatch(Actions.setStatusFilterAction(v)), [dispatch]);

  const handleCompanyUuidChange = useCallback(v => dispatch(Actions.setCompanyUuidFilterAction(v)), [dispatch]);

  return (
    <div className={classNames(props.className, 'filter font-pt-sans')}>
      <p className="text-17px text-black font-bold leading-22px m-0">Filter Results</p>
      <div className="mt-10px flex items-end flex-wrap">
        <FormLabel className="search inline-block w-300px" text="Travel Agent Name">
          <TextInput
            style={textInputStyles}
            spanStyle={spanTextInputStyles}
            value={value.search || ''}
            onChange={handleSearch}
          />
        </FormLabel>

        <FormLabel className="company inline-block w-250px ml-20px" text="Company">
          <CompanySelect value={value.companyUuid} onChange={handleCompanyUuidChange} />
        </FormLabel>

        <FormLabel className="country-code inline-block w-200px ml-20px" text="Country">
          <CountrySelect
            value={value.countryCode}
            onChange={handleCountryCodeChange}
            labelWhenNothingSelected="All countries"
          />
        </FormLabel>


        <FormLabel className="email inline-block w-250px ml-20px" text="Email">
          <TextInput
            style={textInputStyles}
            spanStyle={spanTextInputStyles}
            value={value.email || ''}
            onChange={handleEmail}
          />
        </FormLabel>

        {isAdmin && (
          <FormLabel className="sales-representative inline-block w-200px ml-20px" text="Sales Representative">
            <SalesRepSelect value={value.salesRepUuid} onChange={handleSalesRepUuidChange} />
          </FormLabel>
        )}

        <FormLabel className={classNames("status inline-block", { 'ml-20px': !isAdmin, 'w-150px': isAdmin, 'w-200px': !isAdmin })} text="Status">
          <UserStatusSelect
            value={value.status}
            allowedStatuses={[ETravelAgentStatus.PENDING, ETravelAgentStatus.ACCEPTED, ETravelAgentStatus.VERIFIED, ETravelAgentStatus.REJECTED]}
            onChange={handleStatusChange}
            labelWhenNothingSelected="All statuses"
          />
        </FormLabel>

        <FormLabel className={classNames("requester inline-block w-200px",{'ml-20px': isAdmin})} text="Requester">
          <Multiselect
            className="requester-multiselect bg-ivory font-pt-sans type-select text-15px"
            fontClass="font-pt-sans text-15px"
            itemCtaClassName="hover:bg-teal-20 bg-ivory text-left"
            itemsClassname="bg-ivory"
            isSingleSelectMode={true}
            isCloseOnSelect={true}
            hideCheckboxes={true}
            placeholderClasses="italic text-gray-80 text-15px"
            onUpdate={selectedValues => {
              if (selectedValues.length <= 0) {
                dispatch(Actions.setCreatedByFilterAction(null));
              } else {
                if (selectedValues[0] === 'all') {
                  dispatch(Actions.setCreatedByFilterAction(null));
                } else {
                  dispatch(Actions.setCreatedByFilterAction(selectedValues[0] as ETravelAgentCreatedBy));
                }
              }
            }}
            options={[
              {
                value: 'all',
                label: 'All',
              },
              {
                value: ETravelAgentCreatedBy.INTERNAL,
                label: 'Internal',
              },
              {
                value: ETravelAgentCreatedBy.EXTERNAL,
                label: 'External',
              },
              {
                value: ETravelAgentCreatedBy.SR,
                label: 'SR',
              },
            ]}
            selectedValues={value.createdBy ? [value.createdBy] : []}
          />
        </FormLabel>
      </div>
    </div>
  );
};
