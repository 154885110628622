import React from 'react';
import DatePicker from 'pureUi/DatePicker';
import { DimensionsProvider } from 'pureUi/DimensionsProvider';
import { AutoPosition } from 'pureUi/AutoPosition';
import { renderPortal, PortalType } from 'utils/portals';
import { Frame } from 'pureUi/Frame/index';

export interface IStaticRateAvailabilityCalendarProps {
  date: string;
  onNextClick: () => void;
  onPrevClick: () => void;
  availableDates: string[];
  unavailableDates: string[];
  requestDates: string[];
  wrapperClassName?: string;
  error?: string;
}

const availabilityStatusToColourMap = {
  available: '#d4f3ee',
  unavailable: '#fcecec',
  request: '#ffeeb2',
  'no-data': 'none',
};
interface ILegendColourBlockProps {
  availabilityStatus: 'available' | 'unavailable' | 'request' | 'no-data';
}
export const LegendColourBlock = (props: ILegendColourBlockProps) => {
  const { availabilityStatus } = props;
  return (
    <div
      className="border-2 border-solid border-gray-7"
      style={{
        display: 'inline-block',
        background: availabilityStatusToColourMap[availabilityStatus],
        width: '14px',
        height: '10px',
      }}
    ></div>
  );
};
export const StaticRateAvailabilityCalendar = (props: IStaticRateAvailabilityCalendarProps) => {
  return (
    <div className="opacity-50">
      <DimensionsProvider
        display="inline"
        render={(ancestorDimensions, viewportDimensions) => (
          <div>
            {renderPortal(
              <AutoPosition
                ignoreAncestorWidth
                ancestorDimensions={ancestorDimensions!}
                viewportDimensions={viewportDimensions!}
              >
                <Frame className="datePickerWrapper">
                  <DatePicker
                    calendarCount={2}
                    currentDate={props.date}
                    selectedDates={[]}
                    onDayClick={() => {}}
                    onDayMouseOver={() => {}}
                    onNextClick={() => {
                      props.onNextClick();
                    }}
                    onPrevClick={() => {
                      props.onPrevClick();
                    }}
                    minDate={'2000-01-01'}
                    maxDate={'2100-01-01'} // we can worry about this being problem in 78 years
                    enablePastDates={true}
                    availableDates={props.availableDates}
                    unavailableDates={props.unavailableDates}
                    requestDates={props.requestDates}
                    readonly
                  />
                  {props.error && (
                    <React.Fragment>
                      <p className="text-red-95 text-center text-13px my-2">
                        There was an error loading the static rate availability information for this hotel.
                      </p>
                      <p className="text-red-95 text-center text-13px my-2">Please refresh the page and try again.</p>
                    </React.Fragment>
                  )}
                  {props.error === undefined && (
                    <div className="flex items-center justify-center space-x-15px pt-10px pb-20px font-hurmegeometric-sans text-13px">
                      <span>
                        <LegendColourBlock availabilityStatus="available" />
                        <span className="text-flint ml-5px">Available</span>
                      </span>
                      <span>
                        <LegendColourBlock availabilityStatus="unavailable" />
                        <span className="text-flint ml-5px">Not Available</span>
                      </span>
                      <span>
                        <LegendColourBlock availabilityStatus="request" />
                        <span className="text-flint ml-5px">On Request</span>
                      </span>
                      <span>
                        <LegendColourBlock availabilityStatus="no-data" />
                        <span className="text-flint ml-5px">No Data</span>
                      </span>
                    </div>
                  )}
                </Frame>
              </AutoPosition>,
              PortalType.Overlay
            )}
          </div>
        )}
      />
    </div>
  );
};
