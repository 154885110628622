import { ENetworkRequestStatus } from 'services/BackendApi';
import { IInventoryCurrency } from 'services/InventoryAPI';

export interface IBootstrapDomain {
  countries: {code: string; name: string}[];
  allCountries: any[];
  currencies: IInventoryCurrency[];
  request: {
    getCountries: ENetworkRequestStatus;
    getAllCountries: ENetworkRequestStatus;
    currenciesGet: ENetworkRequestStatus;
  };
}
export const initialState: IBootstrapDomain = {
  countries: [],
  allCountries: [],
  currencies: [],
  request: {
    getCountries: ENetworkRequestStatus.IDLE,
    getAllCountries: ENetworkRequestStatus.IDLE,
    currenciesGet: ENetworkRequestStatus.IDLE,
  },
};
