import { TCurrencyCode } from 'interfaces';
import React from 'react';
import { ELedgerType } from './types';

interface IBookingLedgerTitleProps {
  currency: TCurrencyCode;
  selectedCompanyName?: string;
  type: ELedgerType;
}

export const BookingLedgerTitle: React.FC<IBookingLedgerTitleProps> = React.memo(
  ({ currency, type, selectedCompanyName }) => {
    const titleMap = {
      [ELedgerType.BOOKING_LEDGER]: 'Bookings Statement',
      [ELedgerType.DEPOSIT_ACCOUNT]: 'Deposit Account',
    };
    const title = titleMap[type];

    return (
      <div className="booking-ledger-title flex font-noe-display items-baseline mb-20px">
        <h1 className="booking-ledger-title-currency font-normal text-4xl m-0">
          {currency} {title}
        </h1>
        {selectedCompanyName && (
          <h2 className="booking-ledger-title-company font-normal text-black text-26px m-0 ml-2">
            - {selectedCompanyName}
          </h2>
        )}
      </div>
    );
  }
);
