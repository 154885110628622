import { AxiosResponse } from 'axios';
import { call, takeLatest, select, put } from 'redux-saga/effects';
import {
  FetchCompanyLogoAction,
  FETCH_COMPANY_LOGO,
  fetchCompanyLogoSuccessAction,
  fetchCompanyLogoFailureAction,
} from '../actions';
import { ICompanyLogoResponse, makeBackendApi } from 'services/BackendApi';
import { selectedTaSelector } from '../../agents';

export function* fetchCompanyLogoSaga(action: FetchCompanyLogoAction) {
  try {
    const selectedTa = yield select(selectedTaSelector);
    const backendApi = makeBackendApi(selectedTa?.uuid);
    const result: AxiosResponse<ICompanyLogoResponse> = yield call(backendApi.fetchCompanyLogo, action.companyUuid);
    yield put(fetchCompanyLogoSuccessAction(result.data.data));
  } catch (e) {
    yield put(fetchCompanyLogoFailureAction(e));
  }
}

export function* watchFetchCompanyLogo() {
  yield takeLatest(FETCH_COMPANY_LOGO, fetchCompanyLogoSaga);
}
