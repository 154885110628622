import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    getCompaniesRequestAction, 
    searchCompanyByNameAction, 
    showCompanyDropdownAction, 
} from 'store/modules/agents/actions';
import { 
    companiesSelector, 
    companyNamesSelector, 
    companyNameSearchSelector,
    showCompanyDropdownSelector
 } from 'store/modules/agents/selectors';

import PredictiveTextInput from 'pureUi/PredictiveTextInput';

export interface ICompanySelectProps {
  className?: string;
  value: string | null;
  onChange: (uuid: string | null) => void;
}

export const CompanySelect: React.FC<ICompanySelectProps> = (props) => {
  const companies = useSelector(companiesSelector) || [];
  const companiesNames = useSelector(companyNamesSelector);
  const companyNameSearch = useSelector(companyNameSearchSelector);
  const showCompanyDropdown = useSelector(showCompanyDropdownSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompaniesRequestAction());
  }, [dispatch]);

  const handleChange = useCallback(
    (value: string) => dispatch(searchCompanyByNameAction(value)),
    [dispatch]
  );

  const handleSelect = useCallback((name) => {
    const selectedCompany = companies.find(c => c.name === name) || null;
    handleChange(selectedCompany?.name || '');
    if (selectedCompany?.uuid) {
        props.onChange(selectedCompany?.uuid);
    } else {
      props.onChange(null); // Clear the Company selection when All Companies is selected
    }
  }, [companies, handleChange, props])
  
  const handleCompanyDropdownChange = useCallback((value) => {
    dispatch(showCompanyDropdownAction(value));
  }, [dispatch]);

  const handleBlur = useCallback(() => {
    handleCompanyDropdownChange(false);
    if (!companyNameSearch) {
      handleSelect('All Companies');
    }
  }, [handleCompanyDropdownChange, handleSelect, companyNameSearch]);

  return (
    <PredictiveTextInput
      placeholder="Select company..."
      value={companyNameSearch}
      onChange={e => handleChange(e.currentTarget.value)}
      options={[['All Companies'].concat(companiesNames)]}
      onOptionSelect={handleSelect}
      showDropDown={showCompanyDropdown}
      onFocus={() => handleCompanyDropdownChange(true)}
      onBlur={handleBlur}
    />
  );
};
