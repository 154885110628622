import React, { useCallback, useMemo } from 'react';
import { ESortOrder } from 'store/common/types';
import { CircleIconButton } from 'ui/CircleIconButton';
import { deleteIcon } from 'ui/Icons';
import { edit } from 'ui/Icons';
import { Table as BaseTable } from 'ui/Table';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';
import { useDispatch } from 'react-redux';
import { ICompanyMembership } from 'services/BookingManagerApi/types/CompanyMemberships';
import * as Actions from 'store/modules/companyMemberships/actions';
import { SvgIcon } from 'ui/SvgIcon';
import CheckIcon from 'ui/Icons/check.component.svg';
import CrossIcon from 'ui/Icons/close.component.svg';
import { theme } from '../../../../../tailwind.config';
import OpenEyeIcon from 'ui/Icons/openEye.component.svg';
import ClosedEyeIcon from 'ui/Icons/closedEye.component.svg';

export interface IMembershipsTableProps {
  className?: string;
  memberships: ICompanyMembership[];
  loading: boolean;
  isEditMode: boolean;
}

export const MembershipsTable: React.FC<IMembershipsTableProps> = props => {
  const columns = useMemo(
    () => [
      {
        id: 'name',
        label: 'Name',
        width: '150px',
        className: 'pl-2',
      },
      {
        id: 'logo',
        label: 'Logo',
        width: '150px',
        className: 'pl-2',
      },
      {
        id: 'contact',
        label: 'Contact',
        width: '170px',
        className: 'pl-2',
      },
      {
        id: 'phone',
        label: 'Phone',
        width: '150px',
        className: 'pl-2',
      },
      {
        id: 'contactInfo',
        label: 'Contact Info',
        width: '150px',
        className: 'pl-2',
      },
      {
        id: 'status',
        label: 'Status',
        width: '150px',
        className: 'pl-2',
      },
      {
        id: 'actions',
        label: '',
        width: '90px',
      },
    ],
    []
  );

  const dispatch = useDispatch();
  const handleEditClick = useCallback(
    (membership: ICompanyMembership) => {
      dispatch(Actions.setMembershipToEditAction(membership));
    },
    [dispatch]
  );
  const handleDeleteClick = useCallback(
    (uuid: string | null) => {
      dispatch(Actions.setUuidToDeleteAction(uuid));
    },
    [dispatch]
  );

  const rows = useMemo(() => {
    return props.memberships?.map((x: ICompanyMembership) => {
      const rowItemClassName = 'text-ellipsis overflow-hidden whitespace-nowrap';

      const actions = (
        <div className="item-actions flex items-center relative">
          <div className="w-4px h-34px rounded bg-brown-15 mr-10px" />
          {props.isEditMode && (<HidingTextTooltip width="42px" tooltipContent="Edit">
            <CircleIconButton
              type="secondary"
              className="edit-action mx-1"
              iconClass="inline relative left-0 top-1px w-12px h-12px"
              iconSrc={edit}
              onClick={() => handleEditClick(x)}
            />
          </HidingTextTooltip>)}
          {props.isEditMode && (<HidingTextTooltip width="57px" tooltipContent="Delete">
            <CircleIconButton
              disabled
              // Commented out until there is a custom backend endpoint
              // disabled={x.bookings && x.bookings.length > 0}
              type="secondary"
              className="delete-action mx-1"
              iconClass="inline relative left-0 top-1px w-12px h-12px"
              iconSrc={deleteIcon}
              onClick={() => handleDeleteClick(x.uuid)}
            />
          </HidingTextTooltip>)}
        </div>
      );

      return {
        id: x.uuid,
        cells: [
          {
            id: 'name',
            value: x.name,
            title: x.name,
            className: `${rowItemClassName} pl-4`,
          },
          {
            id: 'logo',
            value: x.logoUrl ? (
              <span className="block ml-10px mt-10px">
                <SvgIcon
                  IconComponent={CheckIcon}
                  defaultFill={theme.colors['black']}
                  hoverFill={theme.colors['black']}
                  activeFill={theme.colors['black']}
                  width="20px"
                  height="20px"
                />
              </span>
            ) : (
              <span></span>
            ),
            className: `${rowItemClassName} pl-3`,
          },
          {
            id: 'contactEmail',
            value: x.contactEmail,
            className: `${rowItemClassName} pl-4`,
          },
          {
            id: 'contactPhone',
            value: x.contactPhone,
            className: `${rowItemClassName} pl-4`,
          },
          {
            id: 'contactInfo',
            value: x.contactInfo,
            className: `${rowItemClassName} pl-4`,
          },
          {
            id: 'status',
            value: (
              <div className="flex flex-row items-center justify-between w-80px">
                <span className="italic">{x.status ? 'Active' : 'Inactive'}</span>
                <SvgIcon
                  key={x.uuid + 'open'}
                  IconComponent={x.status ? OpenEyeIcon : ClosedEyeIcon}
                  width="22px"
                  height="22px"
                  className="pt-2px"
                  defaultFill={x.status ? theme.colors['green-prime'] : theme.colors['gray-60']}
                  hoverFill={x.status ? theme.colors['green-prime'] : theme.colors['gray-60']}
                  activeFill={x.status ? theme.colors['green-prime'] : theme.colors['gray-60']}
                />
              </div>
            ),
            className: `${rowItemClassName} pl-4`,
          },
          {
            id: 'actions',
            value: actions,
          },
        ],
      };
    });
  }, [handleDeleteClick, handleEditClick, props.memberships]);

  return (
    <div style={{ maxHeight: '280px', overflowY: 'scroll' }} className="mt-5">
      <BaseTable
        columns={columns}
        rows={rows}
        sortBy="name"
        sortOrder={ESortOrder.ASC}
        onSort={() => {}}
        messageWhenEmpty="No results"
        stickyHeader
        loading={props.loading}
        className={props.className}
        headerRowClassName="border border-solid border-gray-20"
      />
    </div>
  );
};
